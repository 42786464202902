import { CharacterModel } from '../../../character/model/CharacterModel';
import { PrimaryCharacteristicModifierFieldModel } from '../../../character/model/parts/primaries/fields/common/PrimaryCharacteristicModifierFieldModel';
import { WeaponEquippedHandType } from '../Weapon.types';
import { WeaponItemModel } from '../WeaponItemModel';

export const calculateWeaponStrengthModifier = (
  weapon: WeaponItemModel,
  character: CharacterModel,
) => {
  const hasOnlyOneEquippedHandMultiplier =
    weapon.oneOrTwoHanded === WeaponEquippedHandType.OneHanded;

  const equippedHandMultiplier = hasOnlyOneEquippedHandMultiplier ? 1 : 2;

  if (weapon.ownStrength !== undefined && weapon.ownStrength > 0) {
    return PrimaryCharacteristicModifierFieldModel.calculateModifier(
      weapon.ownStrength,
    );
  }

  return character.primaries.strength.modifier.final * equippedHandMultiplier;
};
