import { Button } from '../../../../../../shared/application/design-system/components/Button/Button';
import { Wrapper } from './Actions.styled';
import { useCreateCharacter } from '../../../../../../shared/application/hooks/useCreateCharacter';
import { useCharacterSync } from '../../../../../../shared/application/hooks/useCharacterSync';
import { useModal } from '../../../../../../shared/application/design-system/components/Modal/hooks/useModal';
import { VTTCharacterListModal } from '../VTTCharacterListModal/VTTCharacterListModal';
import { FoundryVTTActorTransformer } from '../../../../../../embed/infrastructure/mutators/anima/foundry/FoundryVTTActorTransformer';
import { useSaveCharacter } from '../../../../../../shared/application/hooks/useSaveCharacter';
import { observer } from 'mobx-react';
import { FoundryVTTActor } from '../../../../../../embed/infrastructure/mutators/anima/foundry/FoundryVTTActor';
import { FoundryMessagingService } from '../../../../../../embed/infrastructure/FoundryMessagingService';
import { MouseEvent } from 'react';
import { useCreateFolderModal } from '../../../../../../folder/application/hooks/useCreateFolderModal';
import { useParams } from 'react-router-dom';

interface ActionsProps {
  onFolderCreated: () => void;
}

export const Actions = observer(({ onFolderCreated }: ActionsProps) => {
  const { folderId } = useParams<{ folderId: string }>();

  const { openModal, closeModal, Modal } = useModal();

  const { createCharacter, creatingCharacter } = useCreateCharacter();

  const { saveCharacter, savingCharacter } = useSaveCharacter();

  const { getActors } = useCharacterSync();

  const { openCreateFolderModal, CreateFolderModal } = useCreateFolderModal();

  const handleCreateCharacter = () => {
    createCharacter({ folderId });
  };

  const handleCreateFolderClick = async (e: MouseEvent) => {
    e.stopPropagation();

    const folderCreated = await openCreateFolderModal();

    if (folderCreated) {
      onFolderCreated();
    }
  };

  const handleImportCharacter = async () => {
    try {
      const actors = await getActors();

      const handleActorSelection = async (actor: FoundryVTTActor) => {
        const newCharacter = await createCharacter({ noNavigate: true });

        const { character: mergedCharacter } = FoundryVTTActorTransformer({
          actor,
          character: newCharacter,
        }).toCharacter();

        await saveCharacter(mergedCharacter);

        closeModal();
      };

      openModal(
        <VTTCharacterListModal
          actors={actors}
          onActorSelected={handleActorSelection}
        />,
      );
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Wrapper>
      <Button
        onClick={handleCreateCharacter}
        loading={creatingCharacter || savingCharacter}
      >
        Nuevo personaje
      </Button>
      <Button onClick={handleCreateFolderClick}>Crear carpeta</Button>
      {FoundryMessagingService.isActive.get() ? (
        <Button
          onClick={handleImportCharacter}
          loading={creatingCharacter || savingCharacter}
        >
          Importar
        </Button>
      ) : undefined}
      <Modal />
      <CreateFolderModal />
    </Wrapper>
  );
});
