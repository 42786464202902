import { observer } from 'mobx-react';
import { Section } from '../../../../../common/Section/Section';
import {
  BackgroundDisadvantageSelector,
  CommonDisadvantageSelector,
  DonDisadvantageSelector,
  PsychicDisadvantageSelector,
} from './DisadvantageSelector/DisadvantageSelector';

export const Disadvantages = observer(() => {
  return (
    <Section>
      <Section.Title>Desventajas</Section.Title>
      <Section.Subtitle>Comunes</Section.Subtitle>
      <CommonDisadvantageSelector />
      <Section.Divider />
      <Section.Subtitle>Don</Section.Subtitle>
      <DonDisadvantageSelector />
      <Section.Divider />
      <Section.Subtitle>Psíquicos</Section.Subtitle>
      <PsychicDisadvantageSelector />
      <Section.Divider />
      <Section.Subtitle>Trasfondo</Section.Subtitle>
      <BackgroundDisadvantageSelector />
    </Section>
  );
});
