export const createBaseFinalField = (value: number, final?: number) => {
  return {
    base: {
      value,
    },
    final: {
      value: final ?? 0,
    },
  };
};
