import { forwardRef, MouseEvent, ReactNode } from 'react';
import { Loading } from '../Loading/Loading';
import { StyledButton, StyledParagraph } from './Button.styled';
import { ButtonSize, ButtonVariant } from './Button.types';

type ButtonProps = {
  children: ReactNode;
  onClick: (e: MouseEvent) => void;

  disabled?: boolean;
  loading?: boolean;

  className?: string;

  size?: ButtonSize;
  rounded?: boolean;
  variant?: ButtonVariant;
};

export const Button = forwardRef<HTMLDivElement, ButtonProps>(
  (
    {
      children,
      className,
      onClick,
      loading,
      disabled,
      size = ButtonSize.Small,
      rounded = false,
      variant = ButtonVariant.Normal,
      ...rest
    },
    ref,
  ) => {
    const handleClick = (e: MouseEvent) => {
      if (disabled || loading) {
        e.preventDefault();
        return;
      }

      onClick(e);
    };

    return (
      <StyledButton
        ref={ref}
        className={className}
        role="button"
        tabIndex={0}
        onClick={handleClick}
        $disabled={disabled}
        $size={size}
        $rounded={rounded}
        $variant={variant}
        {...rest}
      >
        {loading ? (
          <Loading size={1} color="white" />
        ) : (
          <StyledParagraph>{children}</StyledParagraph>
        )}
      </StyledButton>
    );
  },
);
