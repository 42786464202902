import styled from 'styled-components';

import { StyledIcon } from '@styled-icons/styled-icon';

import { Download, Upgrade, Warning } from '@styled-icons/material';

export const addIconStyles = (icon: StyledIcon) =>
  styled(icon)(({ theme: { size, color } }) => ({
    width: size.units(3),
    height: size.units(3),

    color: color.white(),

    cursor: 'pointer',

    transition: 'color 0.2s ease-in-out',

    '&:hover': {
      color: color.primaryDark(),
    },
  }));

export const StyledDownloadIcon = addIconStyles(Download);

export const StyledUpgradeIcon = addIconStyles(Upgrade);

export const StyledWarningIcon = addIconStyles(Warning);

export const Divider = styled.div(({ theme: { size, color } }) => ({
  width: '1px',
  height: size.units(2),

  backgroundColor: color.white(),
}));

export const Wrapper = styled.div(
  ({ theme: { size, color, borderRadius, mixins } }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    gap: size.units(2),

    width: '100%',

    padding: '0.5rem',

    boxSizing: 'border-box',

    backgroundColor: color.black(),

    borderRadius: borderRadius.medium,

    ...mixins.responsive.xs.downward({
      borderRadius: '0',
    }),
  }),
);
