import { useEffect, useState } from 'react';
import { Character } from '../../../../../../domain/character/Character';
import { CharacterService } from '../../../../../../infrastructure/CharacterService';
import { NotificationManager } from '../../../../../../../shared/application/shared/NotificationManager';

let cachedCharacters: Character[] | undefined = undefined;

export const useFetchCharactersCollaborations = () => {
  const [loading, setLoading] = useState(false);

  const [characters, setCharacters] = useState<Character[]>(
    cachedCharacters ?? [],
  );

  const fetch = () => {
    setLoading(!cachedCharacters);

    CharacterService.getCharactersCollaborations()
      .then((data) => {
        const fetchedCharacters = data.characters;

        if (data.characterErrorNames.length > 0) {
          NotificationManager.error(
            `Los siguientes personajes no se han podido cargar: ${data.characterErrorNames.join(
              ', ',
            )}`,
          );
        }

        setCharacters(fetchedCharacters);
        cachedCharacters = fetchedCharacters;
      })
      .catch((error) => {
        NotificationManager.error(
          'Ha ocurrido un error al cargar las colaboraciones',
        );

        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  return { characters, loadingCharacters: loading, fetchCharacters: fetch };
};
