export enum StyleTableType {
  Disarmament = 'Disarmament',
  UnusualCatch = 'UnusualCatch',
  BattoIaiJutsu = 'BattoIaiJutsu',
  Area = 'Area',
  Aim = 'Aim',
  ChainedAttack = 'ChainedAttack',
  Deflection = 'Deflection',
  ArmorReduction = 'ArmorReduction',
  BlindCombat = 'BlindCombat',
  ShootInMovement = 'ShootInMovement',
  TargetInMovement = 'TargetInMovement',
  MultipleTargets = 'MultipleTargets',
  ProjectileDefense = 'ProjectileDefense',
  AdditionalAttack = 'AdditionalAttack',
  ProjectileAccumulation = 'ProjectileAccumulation',
  DefensiveStyle2ndWeapon = 'DefensiveStyle2ndWeapon',
  IncrementedCritic = 'IncrementedCritic',
  UnusualAttack = 'UnusualAttack',
  Bodyguard = 'Bodyguard',
  SmallSpacesMovement = 'SmallSpacesMovement',
  ShieldWithDodge = 'ShieldWithDodge',
  Subjection = 'Subjection',
}

export abstract class StyleTable {
  abstract readonly name: string;

  abstract type: StyleTableType;

  abstract cost: number;

  abstract effect: string;
}

export class AdditionalAttackTable extends StyleTable {
  readonly name = 'Tabla de Ataque Adicional';

  type = StyleTableType.AdditionalAttack;

  cost = 40;

  effect = 'Permite 1 ataque adicional con el penalizador correspondiente';
}

export class AimTable extends StyleTable {
  readonly name = 'Tabla de Precisión';

  type = StyleTableType.Aim;

  cost = 50;

  effect =
    'Reduce a la mitad el penalizador de la maniobra Engatillar, por lo que un personaje sufre sólo un -50 a su habilidad al ejecutarla.';
}

export class AreaTable extends StyleTable {
  readonly name = 'Tabla de Área';

  type = StyleTableType.Area;

  cost = 50;

  effect =
    'Reduce a la mitad el penalizador de la maniobra Ataque en área, por lo que al ejecutarla, un personaje aplica sólo un -25 a su habilidad.';
}

export class ArmorReductionTable extends StyleTable {
  readonly name = 'Tabla de Reducción de Armadura';

  type = StyleTableType.ArmorReduction;

  cost = 40;

  effect = 'Permite restar 1 punto a la armadura del enemigo';
}

export class BattoIaiJutsuTable extends StyleTable {
  readonly name = 'Batto Jutsu / Iai Jutsu';

  type = StyleTableType.BattoIaiJutsu;

  cost = 30;

  effect =
    'El personaje puede desenfundar su arma sin aplicar el penalizador de -25 a su habilidad de ataque o parada. No tiene efectos sobre armas a dos manos.';
}

export class BlindCombatTable extends StyleTable {
  readonly name = 'Tabla de Combate a Ciegas';

  type = StyleTableType.BlindCombat;

  cost = 40;

  effect = 'Mitad de penalizador por Ceguera parcial y Ceguera completa';
}

export class BodyguardTable extends StyleTable {
  readonly name = 'Tabla de Guardaespaldas';

  type = StyleTableType.Bodyguard;

  cost = 30;

  effect = 'Penalizador -10 para cubrir o apartar a otros';
}

export class ChainedAttackTable extends StyleTable {
  readonly name = 'Tabla de Ataque Encadenado';

  type = StyleTableType.ChainedAttack;

  cost = 40;

  effect =
    'Al realizar ataques adicionales, el personaje puede usar las armas de tamaño Grande aplicando el penalizador de armas de tamaño Medio (es decir, un -30), y las de tamaño Medio como si fueran Pequeñas (un -20). No tiene efectos sobre armas Pequeñas.';
}

export class DefensiveStyle2ndWeaponTable extends StyleTable {
  readonly name = 'Tabla de 2º Arma: Estilo Defensivo';

  type = StyleTableType.DefensiveStyle2ndWeapon;

  cost = 30;

  effect = 'Defensa extra con arma adicional si no se emplea para atacar';
}

export class DeflectionTable extends StyleTable {
  readonly name = 'Tabla de Desvío';

  type = StyleTableType.Deflection;

  cost = 30;

  effect = 'Permite sumar DES a la Entereza del arma';
}

export class DisarmamentTable extends StyleTable {
  readonly name = 'Tabla de Desarme';

  type = StyleTableType.Disarmament;

  cost = 40;

  effect = 'Mitad de penalizador a la maniobra de Desarmar.';
}

export class IncrementedCriticTable extends StyleTable {
  readonly name = 'Tabla de Crítico Incrementado';

  type = StyleTableType.IncrementedCritic;

  cost = 20;

  effect = 'Añade 10 para calcular el Nivel de Crítico';
}

export class MultipleTargetsTable extends StyleTable {
  readonly name = 'Tabla de Varios Blancos';

  type = StyleTableType.MultipleTargets;

  cost = 40;

  effect = 'Sin penalizador por cambio de blanco en disparos o lanzamientos.';
}

export class ProjectileAccumulationTable extends StyleTable {
  readonly name = 'Tabla de Acumulación de Proyectiles';

  type = StyleTableType.ProjectileAccumulation;

  cost = 20;

  effect = 'Permite usar Acumulación de Proyectiles sin penalizador a la HA';
}

export class ProjectileDefenseTable extends StyleTable {
  readonly name = 'Tabla de Defensa contra Proyectiles';

  type = StyleTableType.ProjectileDefense;

  cost = 40;

  effect = 'Uso de armas tipo Mandoble como si fuesen de tipo Escudo';
}

export class ShieldWithDodgeTable extends StyleTable {
  readonly name = 'Tabla de Esquivar con Escudo';

  type = StyleTableType.ShieldWithDodge;

  cost = 30;

  effect = 'Usa el bono de parada del escudo como bono de esquiva';
}

export class ShootInMovementTable extends StyleTable {
  readonly name = 'Tabla de Disparo en Movimiento';

  type = StyleTableType.ShootInMovement;

  cost = 40;

  effect = 'Reducción de penalizador por disparo en movimiento';
}

export class SmallSpacesMovementTable extends StyleTable {
  readonly name = 'Tabla de Movimiento en Espacios Reducidos';

  type = StyleTableType.SmallSpacesMovement;

  cost = 30;

  effect = 'Mitad de penalizador por combatir en Espacios reducidos';
}

export class SubjectionTable extends StyleTable {
  readonly name = 'Tabla de Sujeción';

  type = StyleTableType.Subjection;

  cost = 10;

  effect = 'Bono de +3 al control para evitar ser Desarmado';
}

export class TargetInMovementTable extends StyleTable {
  readonly name = 'Tabla de Blanco en Movimiento';

  type = StyleTableType.TargetInMovement;

  cost = 40;

  effect = 'Reducción de penalizador por blanco en movimiento';
}

export class UnusualAttackTable extends StyleTable {
  readonly name = 'Tabla de Ataque Inusual';

  type = StyleTableType.UnusualAttack;

  cost = 20;

  effect = 'Permite atacar con el Crítico Secundario sin penalizador';
}

export class UnusualCatchTable extends StyleTable {
  readonly name = 'Tabla de Presa Inusual';

  type = StyleTableType.UnusualCatch;

  cost = 40;

  effect =
    'Permite usar Presa con armas sin ella. -60 a la HA y -3 al control.';
}

export const mapStyleTableTypeToTable: Record<
  StyleTableType,
  { new (): StyleTable }
> = {
  [StyleTableType.Disarmament]: DisarmamentTable,
  [StyleTableType.UnusualCatch]: UnusualCatchTable,
  [StyleTableType.BattoIaiJutsu]: BattoIaiJutsuTable,
  [StyleTableType.Area]: AreaTable,
  [StyleTableType.Aim]: AimTable,
  [StyleTableType.ChainedAttack]: ChainedAttackTable,
  [StyleTableType.Deflection]: DeflectionTable,
  [StyleTableType.ArmorReduction]: ArmorReductionTable,
  [StyleTableType.BlindCombat]: BlindCombatTable,
  [StyleTableType.ShootInMovement]: ShootInMovementTable,
  [StyleTableType.TargetInMovement]: TargetInMovementTable,
  [StyleTableType.MultipleTargets]: MultipleTargetsTable,
  [StyleTableType.ProjectileDefense]: ProjectileDefenseTable,
  [StyleTableType.AdditionalAttack]: AdditionalAttackTable,
  [StyleTableType.ProjectileAccumulation]: ProjectileAccumulationTable,
  [StyleTableType.DefensiveStyle2ndWeapon]: DefensiveStyle2ndWeaponTable,
  [StyleTableType.IncrementedCritic]: IncrementedCriticTable,
  [StyleTableType.UnusualAttack]: UnusualAttackTable,
  [StyleTableType.Bodyguard]: BodyguardTable,
  [StyleTableType.SmallSpacesMovement]: SmallSpacesMovementTable,
  [StyleTableType.ShieldWithDodge]: ShieldWithDodgeTable,
  [StyleTableType.Subjection]: SubjectionTable,
};
