import { ActorMutator } from '../../../ActorCharacterTransformer';
import { FoundryVTTActor } from '../FoundryVTTActor';

export const mutateActorFlags: ActorMutator<FoundryVTTActor> = ({
  actor,
  character,
}) => {
  actor.setFlags({
    syncVersion: FoundryVTTActor.CurrentSyncVersion,
    fromCharacterId: character.id,
  });

  return { actor };
};
