import { observer } from 'mobx-react';
import { MouseEvent, useState } from 'react';
import { AllPsychicPowers } from '../../../../../../../../../domain/aggregations/psychic-power/AllPsychicPowers';
import { PsychicPower } from '../../../../../../../../../domain/aggregations/psychic-power/PsychicPower';
import { getUnusedPsychicPower } from '../../../../../../../../../domain/aggregations/psychic-power/utils/getUnusedPsychicPower';
import {
  createModelFromPsychicPower,
  PsychicPowerModel,
} from '../../../../../../../../../domain/character/model/parts/psychic/parts/PsychicPowerModel';
import { useCharacterContext } from '../../../../hooks/useCharacterContext';
import { AddItemButton } from '../../../common/AddItemButton/AddItemButton';
import { Input } from '../../../common/Input/Input';
import { useCharacterMenu } from '../../../common/CharacterMenu/useCharacterMenu';
import { transformToMenuItems } from '../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { RemoveItemButton } from '../../../common/RemoveItemButton/RemoveItemButton';
import { Section } from '../../../common/Section/Section';
import { Table } from '../../../common/Table/Table';
import { MenuItem } from '../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';

export const PsychicPowers = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { handleMenuOpen, Menu } = useCharacterMenu<PsychicPower>();

  const [selectedPsychicPowerModel, setSelectedPsychicPowerModel] = useState<
    PsychicPowerModel | undefined
  >(undefined);

  const addPsychicPower = () => {
    const unusedPower = getUnusedPsychicPower(
      character.psychic.powers.map((p) => p.toObject()),
    );

    if (!unusedPower) return;

    character.psychic.addPsychicPower(unusedPower);
  };

  const removePsychicPower = (power: PsychicPowerModel) => () => {
    character.psychic.removePsychicPower(power);
  };

  const powerItems: MenuItem<PsychicPower>[] = transformToMenuItems({
    fromItems: AllPsychicPowers,
    generateItem: (item) => ({
      key: item.id,
      name: item.name,
      value: item,
      onClick: () => {
        if (selectedPsychicPowerModel !== undefined) {
          character.psychic.replacePsychicPower(
            selectedPsychicPowerModel,
            createModelFromPsychicPower(item),
          );
        }
      },
    }),
    generateDivider: (i) => i.discipline,
  });

  const handlePsychicPowerClick =
    (powerModel: PsychicPowerModel) => (e: MouseEvent<HTMLDivElement>) => {
      setSelectedPsychicPowerModel(powerModel);
      handleMenuOpen(powerModel)(e);
    };

  return (
    <Section maxHeight="fit">
      <Section.Title>Poderes psíquicos</Section.Title>
      <Table>
        <Table.Header>
          <Table.Header.Cell>Nombre</Table.Header.Cell>
          <Table.Header.Cell>Disciplina</Table.Header.Cell>
          <Table.Header.Cell>Nivel</Table.Header.Cell>
          <Table.Header.Cell>CVs</Table.Header.Cell>
          <Table.Header.Cell>Bono</Table.Header.Cell>
        </Table.Header>
        {character.psychic.powers.map((powerModel) => (
          <Table.Row key={powerModel.$modelId}>
            <Table.Row.Cell
              $interactable
              onClick={handlePsychicPowerClick(powerModel)}
            >
              {powerModel.name}
            </Table.Row.Cell>
            <Table.Row.Cell>{powerModel.discipline}</Table.Row.Cell>
            <Table.Row.Cell>{powerModel.level}</Table.Row.Cell>
            <Table.Row.Cell>
              <Input
                value={powerModel.psychicPointsUsedToIncrease}
                setValue={(v) => powerModel.setPsychicPointsUsedToIncrease(v)}
              />
            </Table.Row.Cell>
            <Table.Row.Cell $bold>
              {powerModel.bonusFromPsychicPointsUsedToIncrease}
            </Table.Row.Cell>
            <Table.Row.Cell>
              <RemoveItemButton onClick={removePsychicPower(powerModel)} />
            </Table.Row.Cell>
          </Table.Row>
        ))}
      </Table>

      <AddItemButton onClick={addPsychicPower} />

      <Menu items={powerItems} />
    </Section>
  );
});
