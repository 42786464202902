import {
  applySnapshot,
  getSnapshot,
  model,
  Model,
  modelAction,
  prop,
} from 'mobx-keystone';
import { v4 as uuid } from 'uuid';
import { PrecalculatedFieldModel } from '../../character/model/utils/fields/PrecalculatedFieldModel';
import { ArmorLocation, ArmorType } from './Armor.types';
import { ArmorIntegrityFieldModel } from './fields/ArmorIntegrityFieldModel';
import { ArmorMovementRestrictionFieldModel } from './fields/ArmorMovementRestrictionFieldModel';
import { ArmorNaturalPenaltyFieldModel } from './fields/ArmorNaturalPenaltyFieldModel';
import { ArmorPresenceFieldModel } from './fields/ArmorPresenceFieldModel';
import { ArmorWearArmorFieldModel } from './fields/ArmorWearArmorRequirementFieldModel';
import { ATFieldModel } from './fields/ATFieldModel';
import { EnergyATFieldModel } from './fields/EnergyATFieldModel';
import { PredefinedArmor, PredefinedArmors } from './PredefinedArmors';
import { computed } from 'mobx';

@model('ArmorItem')
export class ArmorItemModel extends Model({
  id: prop(() => uuid()),
  name: prop<string>().withSetter(),

  originalId: prop<string | undefined>(undefined).withSetter(),
  isCustom: prop<boolean>(false).withSetter(),

  at: prop(() => ({
    cut: new ATFieldModel({}),
    impact: new ATFieldModel({}),
    thrust: new ATFieldModel({}),
    heat: new ATFieldModel({}),
    cold: new ATFieldModel({}),
    electricity: new ATFieldModel({}),
    energy: new EnergyATFieldModel({}),
  })),

  integrity: prop(() => new ArmorIntegrityFieldModel({})),
  presence: prop(() => new ArmorPresenceFieldModel({})),
  quality: prop(() => new PrecalculatedFieldModel({})),
  equipped: prop(() => false).withSetter(),

  wearArmorRequirement: prop(() => new ArmorWearArmorFieldModel({})),
  movementRestriction: prop(() => new ArmorMovementRestrictionFieldModel({})),
  naturalPenalty: prop(() => new ArmorNaturalPenaltyFieldModel({})),

  type: prop<ArmorType>(ArmorType.Natural).withSetter(),
  localization: prop<ArmorLocation>(ArmorLocation.Head).withSetter(),

  isEnchanted: prop(() => false),
}) {
  @computed
  get template(): PredefinedArmor | undefined {
    return PredefinedArmors.find(
      (w) => w.id === (this.isCustom ? this.originalId : this.id),
    );
  }

  @modelAction
  transformToCustom() {
    if (!this.isCustom) {
      this.isCustom = true;
      this.originalId = this.id;
      this.id = uuid();
    }
  }

  @modelAction
  resetToDefault() {
    if (!this.template) {
      return;
    }

    applySnapshot<ArmorItemModel>(
      this,
      getSnapshot(createArmorItemModelFromPredefinedArmor(this.template)),
    );
  }
}

export const createArmorItemModelFromPredefinedArmor = (
  predefinedArmor: PredefinedArmor,
): ArmorItemModel => {
  return new ArmorItemModel({
    id: predefinedArmor.id,
    name: predefinedArmor.name,
    type: predefinedArmor.type,
    localization: predefinedArmor.localization,
    at: {
      cut: new ATFieldModel({ base: predefinedArmor.at.cut }),
      impact: new ATFieldModel({ base: predefinedArmor.at.impact }),
      thrust: new ATFieldModel({ base: predefinedArmor.at.thrust }),
      heat: new ATFieldModel({ base: predefinedArmor.at.heat }),
      cold: new ATFieldModel({ base: predefinedArmor.at.cold }),
      electricity: new ATFieldModel({ base: predefinedArmor.at.electricity }),
      energy: new EnergyATFieldModel({ base: predefinedArmor.at.energy }),
    },
    integrity: new ArmorIntegrityFieldModel({
      base: predefinedArmor.integrity,
    }),
    presence: new ArmorPresenceFieldModel({
      base: predefinedArmor.presence,
    }),
    wearArmorRequirement: new ArmorWearArmorFieldModel({
      base: predefinedArmor.wearArmorRequirement,
    }),
    movementRestriction: new ArmorMovementRestrictionFieldModel({
      base: predefinedArmor.movementRestriction,
    }),
    naturalPenalty: new ArmorNaturalPenaltyFieldModel({
      base: predefinedArmor.naturalPenalty,
    }),
  });
};
