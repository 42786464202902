import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';
import { getMentalPatternFromType } from '../../../../../../../../aggregations/mental-patterns/getMentalPatternFromType';
import {
  MentalPattern,
  MentalPatternType,
} from '../../../../../../../../aggregations/mental-patterns/MentalPattern';

@model('MentalPattern')
export class MentalPatternModel extends Model({
  type: prop<MentalPatternType>().withSetter(),
}) {
  @computed
  get table(): MentalPattern {
    return getMentalPatternFromType(this.type);
  }

  @computed
  get cost(): number {
    return this.table.cost;
  }
}
