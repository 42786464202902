import { model } from 'mobx-keystone';
import { WeaponShotType } from '../Weapon.types';
import { WeaponFieldModel } from './WeaponFieldModel';

@model('WeaponItem/WeaponReloadField')
export class WeaponReloadFieldModel extends WeaponFieldModel {
  get final(): number {
    if (!this.character || !this.weapon) return 0;

    if (this.weapon.shotType !== WeaponShotType.Shot) {
      return 0;
    }

    const sleightOfHand =
      this.character.secondaries.creative.sleightOfHand.final;

    const attack = this.character.combat.attackAbility.final;

    let reload = this.base + this.special;

    reload -= Math.floor(Math.max(attack, sleightOfHand) / 100);

    return Math.max(0, reload);
  }
}
