import { MartialArt, MartialArtType } from './MartialArt';
import { AikidoMartialArt } from './implementations/basics/AikidoMartialArt';
import { BoxingMartialArt } from './implementations/basics/BoxingMartialArt';
import { CapoeiraMartialArt } from './implementations/basics/CapoeiraMartialArt';
import { KungFuMartialArt } from './implementations/basics/KungFuMartialArt';
import { MallaYuddhaMartialArt } from './implementations/basics/MallaYuddhaMartialArt';
import { MoaiThaiMartialArt } from './implementations/basics/MoaiThaiMartialArt';
import { PankrationMartialArt } from './implementations/basics/PankrationMartialArt';
import { ShotokanMartialArt } from './implementations/basics/ShotokanMartialArt';
import { SamboMartialArt } from './implementations/basics/SamboMartialArt';
import { KenpoMartialArt } from './implementations/basics/KenpoMartialArt';
import { TaeKwonDoMartialArt } from './implementations/basics/TaeKwonDoMartialArt';
import { LamaMartialArt } from './implementations/basics/LamaMartialArt';
import { GrapplingMartialArt } from './implementations/basics/GrapplingMartialArt';
import { TaiChiMartialArt } from './implementations/basics/TaiChiMartialArt';
import { KardadMartialArt } from './implementations/basics/KardadMartialArt';
import { XingQuanMartialArt } from './implementations/basics/XingQuanMartialArt';
import { KuanMartialArt } from './implementations/basics/KuanMartialArt';
import { SooBahkMartialArt } from './implementations/basics/SooBahkMartialArt';
import { EmpMartialArt } from './implementations/advanced/EmpMartialArt';
import { SeleneMartialArt } from './implementations/advanced/SeleneMartialArt';
import { SuyantaMartialArt } from './implementations/advanced/SuyantaMartialArt';
import { VelezMartialArt } from './implementations/advanced/VelezMartialArt';
import { AsakusenMartialArt } from './implementations/advanced/AsakusenMartialArt';
import { LamaTsuMartialArt } from './implementations/advanced/LamaTsuMartialArt';
import { SeraphiteMartialArt } from './implementations/advanced/SeraphiteMartialArt';
import { MelkaiahMartialArt } from './implementations/advanced/MelkaiahMartialArt';
import { DumahMartialArt } from './implementations/advanced/DumahMartialArt';
import { MushinMartialArt } from './implementations/advanced/MushinMartialArt';
import { HakyoukukenMartialArt } from './implementations/advanced/HakyoukukenMartialArt';
import { ShephonMartialArt } from './implementations/advanced/ShephonMartialArt';
import { EnuthMartialArt } from './implementations/advanced/EnuthMartialArt';
import { HanjaMartialArt } from './implementations/advanced/HanjaMartialArt';
import { GodhandMartialArt } from './implementations/advanced/GodhandMartialArt';
import { RexFrameMartialArt } from './implementations/advanced/RexFrameMartialArt';
import { ExelionMartialArt } from './implementations/advanced/ExelionMartialArt';

const mapMartialArtTypeToTable: Record<MartialArtType, { new (): MartialArt }> =
  {
    [MartialArtType.Aikido]: AikidoMartialArt,
    [MartialArtType.Boxing]: BoxingMartialArt,
    [MartialArtType.Capoeira]: CapoeiraMartialArt,
    [MartialArtType.Grappling]: GrapplingMartialArt,
    [MartialArtType.Kardad]: KardadMartialArt,
    [MartialArtType.Kenpo]: KenpoMartialArt,
    [MartialArtType.Kuan]: KuanMartialArt,
    [MartialArtType.KungFu]: KungFuMartialArt,
    [MartialArtType.Lama]: LamaMartialArt,
    [MartialArtType.MallaYuddha]: MallaYuddhaMartialArt,
    [MartialArtType.MoaiThai]: MoaiThaiMartialArt,
    [MartialArtType.Pankration]: PankrationMartialArt,
    [MartialArtType.Sambo]: SamboMartialArt,
    [MartialArtType.Shotokan]: ShotokanMartialArt,
    [MartialArtType.SooBahk]: SooBahkMartialArt,
    [MartialArtType.TaeKwonDo]: TaeKwonDoMartialArt,
    [MartialArtType.TaiChi]: TaiChiMartialArt,
    [MartialArtType.XingQuan]: XingQuanMartialArt,
    [MartialArtType.Asakusen]: AsakusenMartialArt,
    [MartialArtType.Dumah]: DumahMartialArt,
    [MartialArtType.Emp]: EmpMartialArt,
    [MartialArtType.Enuth]: EnuthMartialArt,
    [MartialArtType.Exelion]: ExelionMartialArt,
    [MartialArtType.Godhand]: GodhandMartialArt,
    [MartialArtType.Hakyoukuken]: HakyoukukenMartialArt,
    [MartialArtType.Hanja]: HanjaMartialArt,
    [MartialArtType.LamaTsu]: LamaTsuMartialArt,
    [MartialArtType.Melkaiah]: MelkaiahMartialArt,
    [MartialArtType.Mushin]: MushinMartialArt,
    [MartialArtType.RexFrame]: RexFrameMartialArt,
    [MartialArtType.Selene]: SeleneMartialArt,
    [MartialArtType.Seraphite]: SeraphiteMartialArt,
    [MartialArtType.Shephon]: ShephonMartialArt,
    [MartialArtType.Suyanta]: SuyantaMartialArt,
    [MartialArtType.Velez]: VelezMartialArt,
  };

export const getMartialArtFromType = (type: MartialArtType): MartialArt => {
  return new mapMartialArtTypeToTable[type]();
};
