import { model } from 'mobx-keystone';
import { NoNaturalBonusDisadvantage } from '../../../../../../aggregations/disadvantage/CommonDisadvantages';
import { BaseFinalFieldModel } from '../../../../utils/fields/BaseFinalFieldModel';

@model('Character/Secondaries/NaturalBonusPoints')
export class NaturalBonusPoints extends BaseFinalFieldModel {
  get final(): number {
    if (
      this.character?.creationPoints.containsDisadvantage(
        NoNaturalBonusDisadvantage,
      )
    ) {
      return 0;
    }

    return this.base + this.special;
  }
}
