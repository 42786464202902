import { model, Model, prop } from 'mobx-keystone';
import { AlchemySkillModel } from './fields/AlchemySkillModel';
import { AnimismSkillModel } from './fields/AnimismSkillModel';
import { ArtSkillModel } from './fields/ArtSkillModel';
import { DanceSkillModel } from './fields/DanceSkillModel';
import { ForgingSkillModel } from './fields/ForgingSkillModel';
import { JewelrySkillModel } from './fields/JewelrySkillModel';
import { MusicSkillModel } from './fields/MusicSkillModel';
import { PuppetMakingSkillModel } from './fields/PuppetMakingSkillModel';
import { RitualCalligraphySkillModel } from './fields/RitualCalligraphySkillModel';
import { RunesSkillModel } from './fields/RunesSkillModel';
import { SleightOfHandSkillModel } from './fields/SleightOfHandSkillModel';
import { TailoringSkillModel } from './fields/TailoringSkillModel';

@model('Character/Secondaries/CreativeSkills')
export class CreativeSkillsModel extends Model({
  art: prop(() => new ArtSkillModel({})),
  dance: prop(() => new DanceSkillModel({})),
  forging: prop(() => new ForgingSkillModel({})),
  runes: prop(() => new RunesSkillModel({})),
  alchemy: prop(() => new AlchemySkillModel({})),
  animism: prop(() => new AnimismSkillModel({})),
  music: prop(() => new MusicSkillModel({})),
  sleightOfHand: prop(() => new SleightOfHandSkillModel({})),
  ritualCalligraphy: prop(() => new RitualCalligraphySkillModel({})),
  jewelry: prop(() => new JewelrySkillModel({})),
  tailoring: prop(() => new TailoringSkillModel({})),
  puppetMaking: prop(() => new PuppetMakingSkillModel({})),
}) {
  get usedPhysicalNaturalBonusPoints(): number {
    return (
      this.dance.naturalBonusPoints.base +
      this.forging.naturalBonusPoints.base +
      this.runes.naturalBonusPoints.base +
      this.sleightOfHand.naturalBonusPoints.base +
      this.ritualCalligraphy.naturalBonusPoints.base +
      this.jewelry.naturalBonusPoints.base +
      this.tailoring.naturalBonusPoints.base
    );
  }

  get usedMentalNaturalBonusPoints(): number {
    return (
      this.art.naturalBonusPoints.base +
      this.alchemy.naturalBonusPoints.base +
      this.animism.naturalBonusPoints.base +
      this.music.naturalBonusPoints.base +
      this.puppetMaking.naturalBonusPoints.base
    );
  }

  get usedNaturalAbilityPoints(): number {
    return (
      this.art.naturalAbilityPoints +
      this.dance.naturalAbilityPoints +
      this.forging.naturalAbilityPoints +
      this.runes.naturalAbilityPoints +
      this.alchemy.naturalAbilityPoints +
      this.animism.naturalAbilityPoints +
      this.music.naturalAbilityPoints +
      this.sleightOfHand.naturalAbilityPoints +
      this.ritualCalligraphy.naturalAbilityPoints +
      this.jewelry.naturalAbilityPoints +
      this.tailoring.naturalAbilityPoints +
      this.puppetMaking.naturalAbilityPoints
    );
  }

  get usedNovelBonusPoints(): number {
    return (
      this.art.novelBonus +
      this.dance.novelBonus +
      this.forging.novelBonus +
      this.runes.novelBonus +
      this.alchemy.novelBonus +
      this.animism.novelBonus +
      this.music.novelBonus +
      this.sleightOfHand.novelBonus +
      this.ritualCalligraphy.novelBonus +
      this.jewelry.novelBonus +
      this.tailoring.novelBonus +
      this.puppetMaking.novelBonus
    );
  }
}
