import { observer } from 'mobx-react';
import { CategoryModel } from '../../../../../../../../../../domain/character/model/parts/pd/parts/categories/CategoryModel';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';
import { FieldHeader } from '../common/FieldRow/components/FieldHeader/FieldHeader';
import { PDFieldRow } from '../common/FieldRow/PDFieldRow';
import { MentalPatternSelector } from './MentalPatternSelector/MentalPatternSelector';
import { PsychicTableSelector } from './PsychicTableSelector/PsychicTableSelector';
import { CenteredContainer, PDSectionDivider } from '../../PDsTab.styled';
import { PsychicInfo } from './PsychicInfo/PsychicInfo';

type PsychicPDsProps = {
  category: CategoryModel;
};

export const PsychicPDs = observer(({ category }: PsychicPDsProps) => {
  const {
    character: { content: character },
  } = useCharacterContext();

  return (
    <Section>
      <Section.Title>Psíquico</Section.Title>
      <Table>
        <FieldHeader />
        <PDFieldRow
          name="CV"
          pdField={category.psychic.psychicPoints}
          field={character.psychic.psychicPoints}
        />
        <PDFieldRow
          name="Proyección psíquica"
          pdField={category.psychic.psychicProjection}
          field={character.psychic.psychicProjection}
        />
      </Table>

      <Section.Subtitle>Tablas psíquicas</Section.Subtitle>
      <PsychicTableSelector psychic={category.psychic} />
      <Section.Subtitle>Patrones mentales</Section.Subtitle>
      <MentalPatternSelector psychic={category.psychic} />

      <PDSectionDivider />

      <CenteredContainer>
        <PsychicInfo category={category} />
      </CenteredContainer>
    </Section>
  );
});
