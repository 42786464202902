import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';
import { getStyleTableFromType } from '../../../../../aggregations/tables/style-tables/getStyleTableFromType';
import {
  StyleTable,
  StyleTableType,
} from '../../../../../aggregations/tables/style-tables/StyleTable';
import { getParentCharacter } from '../../../../../utils/parenting/getParentCharacter';
import { CategoryType } from '../../../../../aggregations/category-templates/CategoryTemplate';

@model('Character/Development/Category/Combat/CombatTables/StyleTable')
export class StyleTableModel extends Model({
  type: prop<StyleTableType>(StyleTableType.Disarmament).withSetter(),
}) {
  @computed
  get table(): StyleTable {
    return getStyleTableFromType(this.type);
  }

  @computed
  get cost(): number {
    if (!this.character) return 0;

    if (this.character.pd.containsCategory(CategoryType.WeaponMaster)) {
      return Math.floor(this.table.cost / 2);
    }

    return this.table.cost;
  }

  @computed
  get name() {
    return this.table.name;
  }

  @computed
  get effect() {
    return this.table.effect;
  }

  get character() {
    return getParentCharacter(this);
  }
}
