import { SecondarySkill } from '../../character/model/types/SecondarySkill';
import { Archetype, CategoryTemplate, CategoryType } from './CategoryTemplate';

export class WarriorCategoryTemplate extends CategoryTemplate {
  name = 'Guerrero';
  archetypes = [Archetype.Fighter];

  type = CategoryType.Warrior;

  readonly lifeMultiplier = 15;
  lifePoints = 15;
  protected turn = 5;
  protected martialKnowledge = 25;
  protected nativePsychicPoints = 1;

  offensiveSkillsLimitPercentage = 0.6;
  attackPDsCost = 2;
  blockPDsCost = 2;
  dodgePDsCost = 3;
  wearArmorPDsCost = 3;
  kiPDsCost = 2;
  kiAccumMultiplierPDsCost = 20;

  magicSkillsLimitPercentage = 0.5;
  zeonPDsCost = 3;
  aCTMultiplierPDsCost = 70;
  magicProjectionPDsCost = 3;
  summonPDsCost = 3;
  controlPDsCost = 3;
  bindPDsCost = 3;
  banishPDsCost = 3;

  psychicSkillsLimitPercentage = 0.5;
  psychicPointsPDsCost = 20;
  psychicProjectionPDsCost = 3;

  athleticSecondariesPDsCost = 2;
  socialSecondariesPDsCost = 2;
  perceptionSecondariesPDsCost = 2;
  intellectualSecondariesPDsCost = 3;
  vigorSecondariesPDsCost = 2;
  subterfugeSecondariesPDsCost = 2;
  creativeSecondariesPDsCost = 2;

  attackBonus = 5;
  blockBonus = 5;
  wearArmorBonus = 5;

  calculateSecondarySkillPDReduction(skill: SecondarySkill): number {
    if (skill === SecondarySkill.FeatsOfStrength) return 1;

    return 0;
  }

  calculateSecondarySkillBonusPerCategory(skill: SecondarySkill): number {
    if (skill === SecondarySkill.FeatsOfStrength) return 5;

    return 0;
  }
}
