import { CharacterModel } from '../../../CharacterModel';

export const calculateCMUsedExceededIssue = (
  character: CharacterModel,
): boolean => {
  const { martialKnowledge } = character.ki;
  const { usedMartialKnowledge } = character.ki;

  return usedMartialKnowledge > martialKnowledge.final;
};
