import { Calculation } from '../../../../../../../../domain/character/model/utils/fields/NumberField';
import { CalculationText, Wrapper } from './Calculations.styled';

const getCalculationValue = (calculation: Calculation) => {
  if (calculation.operator === '+' && calculation.value < 0) {
    return `- ${Math.abs(calculation.value)}`;
  }

  return `${calculation.operator} ${calculation.value}`;
};

export const Calculations = ({
  calculations,
}: {
  calculations: Calculation[];
}) => {
  const calculationsToShow = calculations.filter(
    (calculation) =>
      calculation.value !== 0 || !calculation.options?.hideOnZero,
  );

  return (
    <Wrapper>
      {calculationsToShow.map((calculation) => (
        <CalculationText key={calculation.reason}>
          <b>{calculation.reason}</b>: {getCalculationValue(calculation)}
        </CalculationText>
      ))}
    </Wrapper>
  );
};
