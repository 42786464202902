import { CharacterModel } from '../../../../character/model/CharacterModel';
import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class KungFuMartialArt extends BasicMartialArt {
  readonly name = 'Kung Fu';

  readonly complexity = 2;

  readonly type = MartialArtType.KungFu;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      calculateDamage: (character: CharacterModel) => {
        return character.primaries.strength.modifier.final + 20;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character: CharacterModel) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 20) {
          errors.push('Trucos de manos 20+');
        }

        if (character.secondaries.athletic.acrobatics.final < 20) {
          errors.push('Acrobacias 20+');
        }

        if (character.secondaries.social.style.final < 10) {
          errors.push('Estilo 10+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      effects:
        'Cada turno puede aplicar +10 a HA, HE, daño o turno. Se decide antes de la iniciativa.',
      getRequirementsErrors: (character: CharacterModel) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 40) {
          errors.push('Trucos de manos 40+');
        }

        if (character.secondaries.athletic.acrobatics.final < 40) {
          errors.push('Acrobacias 40+');
        }

        if (character.secondaries.social.style.final < 20) {
          errors.push('Estilo 20+');
        }

        if (character.combat.attackAbility.final < 120) {
          errors.push('Ataque 120+');
        }

        if (character.combat.defensiveAbility < 120) {
          errors.push('Bloqueo o esquiva 120+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      effects: 'Igual que el efecto anterior pero con +20',
      calculateHitType: () => [HitType.Impact, HitType.Thrust],
      getRequirementsErrors: (character: CharacterModel) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 120) {
          errors.push('Trucos de manos 120+');
        }

        if (character.secondaries.athletic.acrobatics.final < 120) {
          errors.push('Acrobacias 120+');
        }

        if (character.secondaries.social.style.final < 100) {
          errors.push('Estilo 100+');
        }

        if (character.combat.attackAbility.final < 200) {
          errors.push('Ataque 200+');
        }

        if (character.combat.defensiveAbility < 200) {
          errors.push('Bloqueo o esquiva 200+');
        }

        return errors;
      },
    }),
  ];
}
