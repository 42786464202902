import { v4 as uuid } from 'uuid';
import {
  CreateAction,
  DeleteAction,
} from '../../../../IframeMessagingService.types';
import { ActorMutator } from '../../../ActorCharacterTransformer';
import { FoundryVTTActor } from '../FoundryVTTActor';
import {
  ABFItems,
  ABFSpellGradeNames,
  SpellDataSource,
} from '../FoundryVTTActor.types';

export const mutateActorSpells: ActorMutator<FoundryVTTActor> = ({
  actor,
  character,
}) => {
  const deleteAction: DeleteAction = {
    type: 'delete',
    ids: actor.data.mystic.spells.reduce(
      (acc, i) => [...acc, i._id],
      [] as string[],
    ),
  };

  const createAction: CreateAction<SpellDataSource> = {
    type: 'create',
    data: character.content.mystic.knownSpells.map((i) => ({
      _id: uuid(),
      type: ABFItems.SPELL,
      name: i.name,
      data: {
        actionType: {
          value: i.actionType,
        },
        description: {
          value: i.description ?? '',
        },
        level: {
          value: i.level,
        },
        via: {
          value: i.magicPath,
        },
        hasDailyMaintenance: {
          value: i.isDaily,
        },
        spellType: {
          value: i.activationType.join(', '),
        },
        grades: {
          base: {
            description: {
              value: i.casts.base.effect,
            },
            name: {
              value: ABFSpellGradeNames.BASE,
            },
            intRequired: {
              value: i.casts.base.intelligenceRequirement,
            },
            zeon: {
              value: i.casts.base.zeonCost,
            },
            maintenanceCost: {
              value: i.casts.base.maintainedZeonCost ?? 0,
            },
          },
          intermediate: {
            description: {
              value: i.casts.intermediate.effect,
            },
            name: {
              value: ABFSpellGradeNames.INTERMEDIATE,
            },
            intRequired: {
              value: i.casts.intermediate.intelligenceRequirement,
            },
            zeon: {
              value: i.casts.intermediate.zeonCost,
            },
            maintenanceCost: {
              value: i.casts.intermediate.maintainedZeonCost ?? 0,
            },
          },
          advanced: {
            description: {
              value: i.casts.advanced.effect,
            },
            name: {
              value: ABFSpellGradeNames.ADVANCED,
            },
            intRequired: {
              value: i.casts.advanced.intelligenceRequirement,
            },
            zeon: {
              value: i.casts.advanced.zeonCost,
            },
            maintenanceCost: {
              value: i.casts.advanced.maintainedZeonCost ?? 0,
            },
          },
          arcane: {
            description: {
              value: i.casts.arcane.effect,
            },
            name: {
              value: ABFSpellGradeNames.ARCANE,
            },
            intRequired: {
              value: i.casts.arcane.intelligenceRequirement,
            },
            zeon: {
              value: i.casts.arcane.zeonCost,
            },
            maintenanceCost: {
              value: i.casts.arcane.maintainedZeonCost ?? 0,
            },
          },
        },
      },
    })),
  };

  actor.data.mystic.spells = [];

  return {
    actor,
    actions: [deleteAction, createAction],
  };
};
