import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { Wrapper } from './CharacterPrimaries.styled';
import { PrimaryCharacteristicField } from './components/PrimaryCharacteristicField/PrimaryCharacteristicField';
import { observer } from 'mobx-react';

export const CharacterPrimaries = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  return (
    <Wrapper>
      <PrimaryCharacteristicField
        name="Agilidad"
        field={character.primaries.agility}
      />
      <PrimaryCharacteristicField
        name="Constitución"
        field={character.primaries.constitution}
      />
      <PrimaryCharacteristicField
        name="Destreza"
        field={character.primaries.dexterity}
      />
      <PrimaryCharacteristicField
        name="Fuerza"
        field={character.primaries.strength}
      />
      <PrimaryCharacteristicField
        name="Inteligencia"
        field={character.primaries.intelligence}
      />
      <PrimaryCharacteristicField
        name="Percepción"
        field={character.primaries.perception}
      />
      <PrimaryCharacteristicField
        name="Poder"
        field={character.primaries.power}
      />
      <PrimaryCharacteristicField
        name="Voluntad"
        field={character.primaries.willPower}
      />
    </Wrapper>
  );
});
