import { model, Model, prop } from 'mobx-keystone';
import { AcrobaticsSkillModel } from './fields/AcrobaticsSkillModel';
import { AthleticismSkillModel } from './fields/AthleticismSkillModel';
import { ClimbSkillModel } from './fields/ClimbSkillModel';
import { JumpSkillModel } from './fields/JumpSkillModel';
import { PilotingSkillModel } from './fields/PilotingSkillModel';
import { RideSkillModel } from './fields/RideSkillModel';
import { SwimSkillModel } from './fields/SwimSkillModel';

@model('Character/Secondaries/AthleticSkills')
export class AthleticSkillsModel extends Model({
  acrobatics: prop(() => new AcrobaticsSkillModel({})),
  athleticism: prop(() => new AthleticismSkillModel({})),
  ride: prop(() => new RideSkillModel({})),
  swim: prop(() => new SwimSkillModel({})),
  climb: prop(() => new ClimbSkillModel({})),
  jump: prop(() => new JumpSkillModel({})),
  piloting: prop(() => new PilotingSkillModel({})),
}) {
  get usedPhysicalNaturalBonusPoints(): number {
    return (
      this.acrobatics.naturalBonusPoints.base +
      this.athleticism.naturalBonusPoints.base +
      this.ride.naturalBonusPoints.base +
      this.swim.naturalBonusPoints.base +
      this.climb.naturalBonusPoints.base +
      this.jump.naturalBonusPoints.base +
      this.piloting.naturalBonusPoints.base
    );
  }

  get usedMentalNaturalBonusPoints(): number {
    return 0;
  }

  get usedNaturalAbilityPoints(): number {
    return (
      this.acrobatics.naturalAbilityPoints +
      this.athleticism.naturalAbilityPoints +
      this.ride.naturalAbilityPoints +
      this.swim.naturalAbilityPoints +
      this.climb.naturalAbilityPoints +
      this.jump.naturalAbilityPoints +
      this.piloting.naturalAbilityPoints
    );
  }

  get usedNovelBonusPoints(): number {
    return (
      this.acrobatics.novelBonus +
      this.athleticism.novelBonus +
      this.ride.novelBonus +
      this.swim.novelBonus +
      this.climb.novelBonus +
      this.jump.novelBonus +
      this.piloting.novelBonus
    );
  }
}
