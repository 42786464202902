import { MagicSpellModel } from './parts/magic-spell/MagicSpellModel';

export const getMagicLevelOfSelectedSpell = (spell: MagicSpellModel) => {
  const level = spell.level;

  if (level <= 10) return 2;
  if (level <= 20) return 4;
  if (level <= 30) return 6;
  if (level <= 40) return 8;
  if (level <= 50) return 10;
  if (level <= 60) return 12;
  if (level <= 70) return 14;
  if (level <= 80) return 16;
  if (level <= 90) return 18;

  return 20;
};
