import { model, Model, prop } from 'mobx-keystone';
import { DisguiseSkillModel } from './fields/DisguiseSkillModel';
import { HideSkillModel } from './fields/HideSkillModel';
import { LockPickingSkillModel } from './fields/LockPickingSkillModel';
import { PoisonsSkillModel } from './fields/PoisonsSkillModel';
import { StealSkillModel } from './fields/StealSkillModel';
import { StealthSkillModel } from './fields/StealthSkillModel';
import { TrapsSkillModel } from './fields/TrapsSkillModel';

@model('Character/Secondaries/SubterfugeSkills')
export class SubterfugeSkillsModel extends Model({
  lockPicking: prop(() => new LockPickingSkillModel({})),
  disguise: prop(() => new DisguiseSkillModel({})),
  hide: prop(() => new HideSkillModel({})),
  steal: prop(() => new StealSkillModel({})),
  stealth: prop(() => new StealthSkillModel({})),
  traps: prop(() => new TrapsSkillModel({})),
  poisons: prop(() => new PoisonsSkillModel({})),
}) {
  get usedPhysicalNaturalBonusPoints(): number {
    return (
      this.lockPicking.naturalBonusPoints.base +
      this.disguise.naturalBonusPoints.base +
      this.steal.naturalBonusPoints.base +
      this.stealth.naturalBonusPoints.base +
      this.traps.naturalBonusPoints.base
    );
  }

  get usedMentalNaturalBonusPoints(): number {
    return (
      this.hide.naturalBonusPoints.base + this.poisons.naturalBonusPoints.base
    );
  }

  get usedNaturalAbilityPoints(): number {
    return (
      this.lockPicking.naturalAbilityPoints +
      this.disguise.naturalAbilityPoints +
      this.hide.naturalAbilityPoints +
      this.steal.naturalAbilityPoints +
      this.stealth.naturalAbilityPoints +
      this.traps.naturalAbilityPoints +
      this.poisons.naturalAbilityPoints
    );
  }

  get usedNovelBonusPoints(): number {
    return (
      this.lockPicking.novelBonus +
      this.disguise.novelBonus +
      this.hide.novelBonus +
      this.steal.novelBonus +
      this.stealth.novelBonus +
      this.traps.novelBonus +
      this.poisons.novelBonus
    );
  }
}
