import { Calculation, NumberField } from './NumberField';
import { computed } from 'mobx';

export abstract class DevelopedPDFieldModel extends NumberField {
  abstract get fromCategories(): number;

  @computed
  get fromCategoriesCalculations(): Calculation[] {
    const calculations: Calculation[] = [];

    calculations.push(this.sum('Por categorías', this.fromCategories));

    return calculations;
  }

  @computed
  get bonusFromAdvantages(): number {
    return 0;
  }

  @computed
  get primariesBonus(): number {
    return 0;
  }

  @computed
  get other(): number {
    return 0;
  }

  @computed
  get allActionsPenalty(): number {
    if (!this.character) return 0;

    return this.character.modifiers.allActions.final;
  }

  get finalWithoutPenalties(): number {
    return this.final - this.allActionsPenalty;
  }

  get allBonuses(): Calculation[] {
    const calculations: Calculation[] = [];

    calculations.push(this.sum('Primarias', this.primariesBonus));

    calculations.push(this.sum('Ventajas', this.bonusFromAdvantages));

    calculations.push(this.sum('Otros', this.other));

    return calculations;
  }

  getCalculations(): Calculation[] {
    const calculations: Calculation[] = [];

    calculations.push(this.sum('Especial', this.special));

    calculations.push(...this.fromCategoriesCalculations);

    calculations.push(...this.allBonuses);

    if (this.allActionsPenalty !== 0) {
      calculations.push(
        this.sum('Penalizador a todas las acciones', this.allActionsPenalty),
      );
    }

    return calculations;
  }
}
