import { MyProfile } from './components/MyProfile/MyProfile';
import { AuthorizedPageLayout } from '../../../../shared/application/design-system/components/PageLayout/PageLayout';
import { DefaultNavbar } from '../../../../shared/application/design-system/components/TopNavbar/implementations/DefaultNavbar';

export const MyProfilePage = () => {
  return (
    <AuthorizedPageLayout Header={<DefaultNavbar />}>
      <MyProfile />
    </AuthorizedPageLayout>
  );
};
