import { CharacterModelV2 } from './CharacterModelV2';
import { CharacterModelV1 } from '../v1/CharacterModelV1';

export const migrateFromCharacterModelV1ToV2 = (
  characterModelV1: CharacterModelV1,
): CharacterModelV2 => {
  const migration: CharacterModelV2 =
    characterModelV1 as unknown as CharacterModelV2;

  migration.version = 2;

  migration.pd.categories[0] = {
    ...migration.pd.categories[0],

    mystic: {
      ...migration.pd.categories[0].mystic,

      tables: characterModelV1.mystic.tables,
    },

    psychic: {
      ...migration.pd.categories[0].psychic,

      tables: characterModelV1.psychic.tables,
      mentalPatterns: characterModelV1.psychic.mentalPatterns,
    },
  };

  return migration;
};
