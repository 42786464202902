import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import { CategoryType } from '../../../../../../../../../../domain/aggregations/category-templates/CategoryTemplate';
import { getCategoryTemplateFromType } from '../../../../../../../../../../domain/aggregations/category-templates/utils/getCategoryTemplateFromType';
import { CategoryModel } from '../../../../../../../../../../domain/character/model/parts/pd/parts/categories/CategoryModel';
import { AddItemButton } from '../../../../common/AddItemButton/AddItemButton';
import DoubleInput from '../../../../common/DoubleInput/DoubleInput';
import { Input } from '../../../../common/Input/Input';
import { LabelPosition } from '../../../../common/Input/Input.types';
import { useCharacterMenu } from '../../../../common/CharacterMenu/useCharacterMenu';
import { RemoveItemButton } from '../../../../common/RemoveItemButton/RemoveItemButton';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { MenuItem } from '../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';

export const CharacterCategoriesList = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { handleMenuOpen, Menu } = useCharacterMenu<CategoryType>();

  const [selectedDevelopedCategory, setSelectedDevelopedCategory] = useState<
    CategoryModel | undefined
  >(undefined);

  const handleAddCategory = () => {
    const unusedCategory = Object.values(CategoryType).find(
      (category) => !character.categories.some((c) => c.category === category),
    );

    if (!unusedCategory) return;

    character.pd.addCategory(
      new CategoryModel({
        category: unusedCategory as CategoryType,
      }),
    );
  };

  const handleDeleteCategory = (category: CategoryModel) => () => {
    character.pd.removeCategory(category);
  };

  const unusedCategory: MenuItem<CategoryType>[] = (
    Object.values(CategoryType).filter(
      (category) => !character.categories.find((c) => c.category === category),
    ) as CategoryType[]
  ).map((category) => {
    const item: MenuItem<CategoryType> = {
      key: category,
      name: getCategoryTemplateFromType(category).name,
      value: category as CategoryType,
      onClick: () => {
        selectedDevelopedCategory?.setCategory?.(category);
      },
    };
    return item;
  });

  return (
    <Section>
      <Section.Title>Categorías</Section.Title>
      <Table>
        <Table.Header>
          <Table.Header.Cell />
          <Table.Header.Cell>Nivel</Table.Header.Cell>
          <Table.Header.Cell>PDs totales</Table.Header.Cell>
          <Table.Header.Cell>PDs consumidos</Table.Header.Cell>
          {character.categories.length > 0 ? (
            <>
              <Table.Header.Cell $hideOnMobile>Coste</Table.Header.Cell>
              <Table.Header.Cell $hideOnMobile>Desde</Table.Header.Cell>
              <Table.Header.Cell $hideOnMobile>Hasta</Table.Header.Cell>
            </>
          ) : undefined}
        </Table.Header>
        {character.categories.map((developedCategory, index) => {
          const handleDevelopedCategoryClick =
            (dc: CategoryModel) => (e: MouseEvent<HTMLDivElement>) => {
              setSelectedDevelopedCategory(dc);
              handleMenuOpen(dc.category)(e);
            };

          return (
            <Table.Row key={`${developedCategory.category}-${index}`}>
              <Table.Row.Cell
                onClick={handleDevelopedCategoryClick(developedCategory)}
              >
                {developedCategory.template.name}
              </Table.Row.Cell>
              <Table.Row.Cell>
                <DoubleInput field={developedCategory.level} />
              </Table.Row.Cell>
              <Table.Row.Cell>{developedCategory.pds}</Table.Row.Cell>
              <Table.Row.Cell
                $danger={developedCategory.pdsSpent > developedCategory.pds}
              >
                {developedCategory.pdsSpent}
              </Table.Row.Cell>
              {developedCategory.categoryChange ? (
                <>
                  <Table.Row.Cell $hideOnMobile>
                    {developedCategory.categoryChange.cost}
                  </Table.Row.Cell>
                  <Table.Row.Cell $hideOnMobile>
                    <Input
                      labelPosition={LabelPosition.Top}
                      label={developedCategory.categoryChange.fromTemplate.name}
                      value={developedCategory.categoryChange.expendFrom}
                      setValue={(v) =>
                        developedCategory.categoryChange?.setExpendFrom?.(v)
                      }
                    />
                  </Table.Row.Cell>
                  <Table.Row.Cell $hideOnMobile>
                    <Input
                      labelPosition={LabelPosition.Top}
                      label={developedCategory.categoryChange.toTemplate.name}
                      value={developedCategory.categoryChange.expendTo}
                      setValue={(v) =>
                        developedCategory.categoryChange?.setExpendTo?.(v)
                      }
                    />
                  </Table.Row.Cell>
                </>
              ) : (
                <>
                  <Table.Row.Cell $hideOnMobile colSpan={3}></Table.Row.Cell>
                </>
              )}
              {character.categories.length > 1 ? (
                <RemoveItemButton
                  onClick={handleDeleteCategory(developedCategory)}
                />
              ) : undefined}
            </Table.Row>
          );
        })}
      </Table>

      {unusedCategory.length > 0 ? <Menu items={unusedCategory} /> : undefined}

      {character.categories.length < 5 && (
        <AddItemButton onClick={handleAddCategory} />
      )}
    </Section>
  );
});
