import { observer } from 'mobx-react';
import React from 'react';
import {
  NephilimEffect,
  NephilimEffectsContainer,
  NephilimEffectsList,
  Wrapper,
} from './NephilimEffects.styled';
import { NephilimType } from '../../../../../../../../../../../../domain/aggregations/nephilim-type/Nephilim';

type MartialArtSelectorProps = {
  nephilim: NephilimType;
};

export const NephilimEffects = observer(
  ({ nephilim }: MartialArtSelectorProps) => {
    return (
      <Wrapper>
        <NephilimEffectsContainer>
          <NephilimEffectsList>
            {nephilim.effects?.map((effect) => {
              if (typeof effect === 'string') {
                return <NephilimEffect key={effect}>{effect}</NephilimEffect>;
              }

              return (
                <NephilimEffectsList key={effect[0]}>
                  {effect.map((e) => (
                    <NephilimEffect key={e}>{e}</NephilimEffect>
                  ))}
                </NephilimEffectsList>
              );
            })}
          </NephilimEffectsList>
        </NephilimEffectsContainer>
      </Wrapper>
    );
  },
);
