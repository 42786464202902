import { ReactNode, useEffect, useRef } from 'react';
import { Wrapper } from './FolderDragging.styled';
import { DraggableItemTypes } from '../../../../shared/application/shared/constants/DraggableItemTypes';
import {
  DragEndEvent,
  useDndMonitor,
  useDraggable,
  useDroppable,
} from '@dnd-kit/core';
import { v4 as uuid } from 'uuid';

export type FolderDraggingProps = {
  folderId: string;
  onFolderDrop: (params: {
    destinationFolderId: string;
    draggedFolderId: string;
  }) => void;

  onCharacterDrop: (params: { characterId: string; folderId: string }) => void;

  children: ({
    isDragging,
    isOver,
  }: {
    isDragging: boolean;
    isOver: boolean;
  }) => ReactNode;

  disableDrag?: boolean;
  disableDrop?: boolean;
};

export const FolderDragging = ({
  folderId,

  onFolderDrop,
  onCharacterDrop,

  children,

  disableDrag,
  disableDrop,
}: FolderDraggingProps) => {
  const uniqueId = useRef(uuid());

  const ref = useRef(null);

  const { setNodeRef: setDroppableNodeRef, isOver } = useDroppable({
    id: uniqueId.current,
    data: {
      accepts: [DraggableItemTypes.Folder, DraggableItemTypes.Character],
    },
    disabled: disableDrop,
  });

  const {
    setNodeRef: setDraggableNodeRef,
    isDragging,
    transform,
    attributes,
    listeners,
  } = useDraggable({
    id: uniqueId.current,
    data: {
      folderId,
      type: DraggableItemTypes.Folder,
    },
    disabled: disableDrag,
  });

  useDndMonitor({
    onDragEnd(event: DragEndEvent) {
      if (isOver) {
        const data = event.active.data.current;

        if (!data) return;

        if (data.folderId === folderId) return;

        if (data.type === DraggableItemTypes.Character) {
          onCharacterDrop({
            characterId: data.characterId,
            folderId,
          });
        }

        if (data.type === DraggableItemTypes.Folder) {
          onFolderDrop({
            destinationFolderId: folderId,
            draggedFolderId: data.folderId,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (ref.current) {
      setDroppableNodeRef(ref.current);
      setDraggableNodeRef(ref.current);
    }
  }, [setDraggableNodeRef, setDroppableNodeRef]);

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <Wrapper ref={ref} style={style} {...listeners} {...attributes}>
      {children({ isDragging, isOver })}
    </Wrapper>
  );
};
