import { ExtendedModel, model, prop } from 'mobx-keystone';
import { SecondarySkill } from '../../../../../../../types/SecondarySkill';
import { SecondarySkillPDFieldModel } from '../SecondarySkillPDFieldModel';
import { SecondarySkillBonusType } from '../SecondarySkillPDFieldModel.types';
import { SecondarySkillsPDModel } from './SecondarySkillsPDModel';

@model('Character/Development/SecondarySkills/SocialSkillsPD')
export class SocialSkillsPDModel extends ExtendedModel(SecondarySkillsPDModel, {
  style: prop(
    () =>
      new SecondarySkillPDFieldModel({
        skill: SecondarySkill.Style,
        type: SecondarySkillBonusType.Power,
      }),
  ),
  intimidate: prop(
    () =>
      new SecondarySkillPDFieldModel({
        skill: SecondarySkill.Intimidate,
        type: SecondarySkillBonusType.WillPower,
      }),
  ),
  leadership: prop(
    () =>
      new SecondarySkillPDFieldModel({
        skill: SecondarySkill.Leadership,
        type: SecondarySkillBonusType.Power,
      }),
  ),
  persuasion: prop(
    () =>
      new SecondarySkillPDFieldModel({
        skill: SecondarySkill.Persuasion,
        type: SecondarySkillBonusType.Intelligence,
      }),
  ),
  trading: prop(
    () =>
      new SecondarySkillPDFieldModel({
        skill: SecondarySkill.Trading,
        type: SecondarySkillBonusType.Intelligence,
      }),
  ),
  streetwise: prop(
    () =>
      new SecondarySkillPDFieldModel({
        skill: SecondarySkill.Streetwise,
        type: SecondarySkillBonusType.Intelligence,
      }),
  ),
  etiquette: prop(
    () =>
      new SecondarySkillPDFieldModel({
        skill: SecondarySkill.Etiquette,
        type: SecondarySkillBonusType.Intelligence,
      }),
  ),
}) {
  get fields(): SecondarySkillPDFieldModel[] {
    return [
      this.style,
      this.intimidate,
      this.leadership,
      this.persuasion,
      this.trading,
      this.streetwise,
      this.etiquette,
    ];
  }
}
