import { color } from './colors/color';
import { interactable } from './mixins/interactable';
import { responsive } from './mixins/responsive';
import { size } from './size/size';
import { font } from './font/font';
import { border, borderRadius } from './border/border';
import { shadow } from './shadow/shadow';

export const theme = {
  color: color,
  size: size,
  font: font,
  border: border,
  borderRadius: borderRadius,
  shadow: shadow,

  transition: {
    standard: 'all ease-in-out 300ms',
  },

  mixins: {
    responsive,
    interactable,
  },
};

export type Theme = typeof theme;
