import { ApiClient } from '../../shared/infrastructure/api/ApiClient';
import { CharacterCollaboration } from '../domain/CharacterCollaboration';

const getCharacterCollaborationsByCharacterId = async (characterId: string) => {
  const params = new URLSearchParams({
    character_id: characterId,
  });

  const collaborations = await ApiClient.get<any[]>(
    `/character-collaboration/get?${params.toString()}`,
  ).then((r) => r.data);

  return collaborations.map((cc) => CharacterCollaboration.fromPrimitives(cc));
};

const addCharacterCollaboration = async ({
  characterId,
  collaboratorEmail,
}: {
  characterId: string;
  collaboratorEmail: string;
}) => {
  const params = new URLSearchParams({
    character_id: characterId,
    collaborator_email: collaboratorEmail,
  });

  await ApiClient.post(`/character-collaboration/add?${params.toString()}`);
};

const removeCharacterCollaboration = async ({
  collaborationId,
  characterId,
}: {
  collaborationId: string;
  characterId: string;
}) => {
  const params = new URLSearchParams({
    collaboration_id: collaborationId,
    character_id: characterId,
  });

  await ApiClient.delete(
    `/character-collaboration/delete?${params.toString()}`,
  );
};

export const CharacterCollaborationService = {
  addCharacterCollaboration,
  removeCharacterCollaboration,
  getCharacterCollaborationsByCharacterId,
};
