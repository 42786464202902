import { useCallback, useEffect, useState } from 'react';
import { Folder } from '../../domain/folder/Folder';
import { FolderService } from '../../infrastructure/FolderService';
import { useNavigate } from '../../../shared/application/hooks/useNavigate';

export const useFetchFolders = (folderId?: string) => {
  const { navigateBack } = useNavigate();

  const [loading, setLoading] = useState(false);

  const [currentFolder, setCurrentFolder] = useState<Folder | undefined>();

  const [folders, setFolders] = useState<Folder[]>([]);

  const fetch = useCallback(
    (params?: { keepLoadingOnFalse?: boolean }) => {
      setLoading(!params?.keepLoadingOnFalse);

      FolderService.getFolderNavigation(
        folderId !== 'root' ? folderId : undefined,
      )
        .then((navigation) => {
          setCurrentFolder(navigation.folder);

          setFolders(navigation.children);
        })
        .catch((error) => {
          // NotificationManager.error(
          //   'Ha ocurrido un error al cargar las carpetas',
          // );

          console.error(error);

          navigateBack();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [folderId, navigateBack],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    folders,
    loadingFolders: loading,
    currentFolder,
    fetchFolders: fetch,
  };
};
