import styled from 'styled-components';
import { BigTitle } from '../../../../../../shared/application/design-system/components/Texts/Texts.styled';

export const StyledBigTitle = styled(BigTitle)({});

export const Wrapper = styled.div(({ theme: { size, mixins } }) => ({
  display: 'flex',
  flexDirection: 'column',

  gap: size.units(1),

  ...mixins.responsive.xs.downward({
    paddingLeft: size.units(1),
    paddingRight: size.units(1),
  }),
}));
