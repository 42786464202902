import { MagicPath } from '../magic-path/MagicPath';

export enum SpellActivationType {
  Effect = 'Effect',
  Animic = 'Animic',
  Automatic = 'Automatic',
  Attack = 'Attack',
  Defense = 'Defense',
  Detection = 'Detection',
}

export enum SpellActionType {
  Active = 'Active',
  Passive = 'Passive',
}

export type MagicSpellCast = {
  intelligenceRequirement: number;
  zeonCost: number;
  maintainedZeonCost?: number;
  effect: string;
};

export type MagicSpellId = string;

export type MagicSpellCasts = {
  base: MagicSpellCast;
  intermediate: MagicSpellCast;
  advanced: MagicSpellCast;
  arcane: MagicSpellCast;
};

export type MagicSpell = {
  id: MagicSpellId;
  name: string;
  magicPath: MagicPath;
  level: number;
  isDaily: boolean;
  activationType: SpellActivationType[];
  actionType: SpellActionType;
  description?: string;

  casts: MagicSpellCasts;
};
