import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { AllBackgroundDisadvantages } from '../../../../../../../../../../../../domain/aggregations/disadvantage/BackgroundDisadvantages';
import { AllCommonDisadvantages } from '../../../../../../../../../../../../domain/aggregations/disadvantage/CommonDisadvantages';
import {
  Disadvantage,
  DisadvantageType,
} from '../../../../../../../../../../../../domain/aggregations/disadvantage/Disadvantage.types';
import { getUnusedDisadvantageOfType } from '../../../../../../../../../../../../domain/aggregations/disadvantage/Disadvantages';
import { AllDonDisadvantages } from '../../../../../../../../../../../../domain/aggregations/disadvantage/DonDisadvantages';
import { AllPsychicDisadvantages } from '../../../../../../../../../../../../domain/aggregations/disadvantage/PsychicDisadvantages';
import {
  createModelFromDisadvantage,
  DisadvantageModel,
} from '../../../../../../../../../../../../domain/character/model/parts/creation-points/DisadvantageModel';
import { filterUndefined } from '../../../../../../../../../../../../../shared/utils/filterUndefined';
import { useCharacterContext } from '../../../../../../../hooks/useCharacterContext';
import { Selector } from '../../../common/Selector/Selector';

type DisadvantageSelectorProps = {
  type: DisadvantageType;
};

export const DisadvantageSelector = observer(
  ({ type }: DisadvantageSelectorProps) => {
    const {
      character: { content: character },
    } = useCharacterContext();

    const allDisadvantages = useMemo(() => {
      switch (type) {
        case DisadvantageType.Common:
          return AllCommonDisadvantages;
        case DisadvantageType.Don:
          return AllDonDisadvantages;
        case DisadvantageType.Psychics:
          return AllPsychicDisadvantages;
        case DisadvantageType.Background:
          return AllBackgroundDisadvantages;
      }
    }, [type]);

    const handleAddDisadvantage = () => {
      const unusedDisadvantage = getUnusedDisadvantageOfType(
        character.creationPoints.disadvantages
          .map((a) => a.id)
          .filter(filterUndefined()),
        type,
      );

      if (unusedDisadvantage) {
        character.creationPoints.addDisadvantage(unusedDisadvantage);
      }
    };

    const handleRemoveDisadvantage = (table: DisadvantageModel) => {
      character.creationPoints.removeDisadvantage(table);
    };

    const handleDisadvantageChange = (
      from: DisadvantageModel,
      to: Disadvantage,
    ) => {
      character.creationPoints.replaceDisadvantage(
        from,
        createModelFromDisadvantage(to),
      );
    };

    const disadvantages = character.creationPoints.getDisadvantagesOfType(type);

    return (
      <Selector
        elements={disadvantages}
        allElements={allDisadvantages}
        onElementAdd={handleAddDisadvantage}
        onElementRemove={handleRemoveDisadvantage}
        onElementChange={handleDisadvantageChange}
      />
    );
  },
);

export const CommonDisadvantageSelector = observer(() => {
  return <DisadvantageSelector type={DisadvantageType.Common} />;
});

export const DonDisadvantageSelector = observer(() => {
  return <DisadvantageSelector type={DisadvantageType.Don} />;
});

export const PsychicDisadvantageSelector = observer(() => {
  return <DisadvantageSelector type={DisadvantageType.Psychics} />;
});

export const BackgroundDisadvantageSelector = observer(() => {
  return <DisadvantageSelector type={DisadvantageType.Background} />;
});
