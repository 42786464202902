import { HTMLProps, ReactNode } from 'react';
import { Info, StyledTextArea, Wrapper } from './TextArea.styled';
import { TextAreaSize, TextAreaState } from './TextArea.types';

type InputProps = Omit<HTMLProps<HTMLTextAreaElement>, 'size'> & {
  state?: TextAreaState;
  size?: TextAreaSize;

  info?: string | ReactNode;

  className?: string;
};

export const TextArea = ({
  info,
  size = TextAreaSize.Default,
  state = TextAreaState.Default,
  ...rest
}: InputProps) => {
  const input = (
    <>
      <StyledTextArea
        $size={size}
        $state={state}
        value={rest.value}
        onChange={rest.onChange}
        className={rest.className}
        onKeyUp={rest.onKeyUp}
        onKeyDown={rest.onKeyDown}
        placeholder={rest.placeholder}
        maxLength={rest.maxLength}
        disabled={rest.disabled}
        autoFocus={rest.autoFocus}
        inputMode={rest.inputMode}
        rows={rest.rows}
        readOnly={rest.readOnly}
      />
    </>
  );

  if (info) {
    return (
      <Wrapper>
        {input}
        <Info $state={state}>{info}</Info>
      </Wrapper>
    );
  }

  return input;
};

TextArea.State = TextAreaState;
TextArea.Size = TextAreaSize;
