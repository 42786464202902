import { observer } from 'mobx-react';
import { Input } from '../../../../common/Input/Input';
import { Section } from '../../../../common/Section/Section';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { PrimaryCharacteristicBonusRow } from './CreationPoints.styled';

export const CreationPoints = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const creationPoints = character.creationPoints;
  const primaryBonuses = creationPoints.primaryBonuses;

  return (
    <Section>
      <Section.Title>Puntos de Creación</Section.Title>

      <Input
        label="Puntos de creación libres"
        value={creationPoints.remainingPoints}
        disabled
      />

      <Input
        label={`Puntos de creación liberalizados (Máx. ${creationPoints.bonusPoints})`}
        value={creationPoints.liberalizedPoints}
        setValue={(v) => creationPoints.setLiberalizedPoints(v)}
      />

      <Section.Subtitle>Bonos a características</Section.Subtitle>
      <PrimaryCharacteristicBonusRow>
        <Input
          label="Agilidad"
          value={primaryBonuses.agility}
          setValue={(v) => primaryBonuses.setAgility(v)}
        />
        <Input
          label="Constitución"
          value={primaryBonuses.constitution}
          setValue={(v) => primaryBonuses.setConstitution(v)}
        />
      </PrimaryCharacteristicBonusRow>
      <PrimaryCharacteristicBonusRow>
        <Input
          label="Percepción"
          value={primaryBonuses.perception}
          setValue={(v) => primaryBonuses.setPerception(v)}
        />
        <Input
          label="Fuerza"
          value={primaryBonuses.strength}
          setValue={(v) => primaryBonuses.setStrength(v)}
        />
      </PrimaryCharacteristicBonusRow>
      <PrimaryCharacteristicBonusRow>
        <Input
          label="Destreza"
          value={primaryBonuses.dexterity}
          setValue={(v) => primaryBonuses.setDexterity(v)}
        />
        <Input
          label="Inteligencia"
          value={primaryBonuses.intelligence}
          setValue={(v) => primaryBonuses.setIntelligence(v)}
        />
      </PrimaryCharacteristicBonusRow>
      <PrimaryCharacteristicBonusRow>
        <Input
          label="Poder"
          value={primaryBonuses.power}
          setValue={(v) => primaryBonuses.setPower(v)}
        />
        <Input
          label="Voluntad"
          value={primaryBonuses.willPower}
          setValue={(v) => primaryBonuses.setWillPower(v)}
        />
      </PrimaryCharacteristicBonusRow>
    </Section>
  );
});
