import { MouseEvent } from 'react';
import { vibrate } from '../../../../../../utils/vibrate';
import { ButtonText, Wrapper } from './TabButton.styled';

type TabButtonProps = {
  name: string;
  selected: boolean;
  onClick: (e: MouseEvent) => void;
};

export const TabButton = ({ name, onClick, selected }: TabButtonProps) => {
  const handleButtonClick = (e: MouseEvent) => {
    vibrate();
    onClick(e);
  };

  return (
    <Wrapper onClick={handleButtonClick} $selected={selected}>
      <ButtonText $selected={selected}>{name}</ButtonText>
    </Wrapper>
  );
};
