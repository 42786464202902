import React from 'react';
import { Selector } from './LevelSelectorFilter.styled';
import {
  MenuItem,
  useMenu,
} from '../../../../../../../../shared/application/design-system/components/Menu/useMenu';

export type OptionalLevel = number | undefined;

export const LevelSelectorFilter = ({
  onLevelSelected,
  selectedLevel,
}: {
  selectedLevel: OptionalLevel;
  onLevelSelected: (level: OptionalLevel) => void;
}) => {
  const { handleMenuOpen, Menu } = useMenu<OptionalLevel>();

  const levels = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];

  const allLevelsMenuItem: MenuItem<undefined> = {
    key: 'undefined',
    name: 'Todos los niveles',
    value: undefined,
    onClick: () => {
      onLevelSelected(undefined);
    },
  };

  const menuItemLevels: MenuItem<OptionalLevel>[] = [undefined, ...levels].map(
    (level) => {
      if (level === undefined) {
        return allLevelsMenuItem;
      }

      const levelAsString = level.toString();

      const item: MenuItem<number> = {
        key: levelAsString,
        name: levelAsString,
        value: level,
        onClick: () => {
          onLevelSelected(level);
        },
      };
      return item;
    },
  );

  const categoryName = selectedLevel
    ? `Nivel ${selectedLevel}`
    : allLevelsMenuItem.name;

  return (
    <>
      <Selector onClick={handleMenuOpen(selectedLevel)}>
        {categoryName}
      </Selector>
      <Menu items={menuItemLevels} />
    </>
  );
};
