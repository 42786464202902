import { Breakpoint } from '../theme/mixins/responsive';

export const breakpoints: Record<Breakpoint, number> = {
  [Breakpoint.xs]: 575, // mobile
  [Breakpoint.sm]: 767, // tablet
  [Breakpoint.ipad]: 769, // ipad
  [Breakpoint.md]: 991, // laptop
  [Breakpoint.lg]: 1200, // desktop,
  [Breakpoint.xlg]: 1600, // desktop,
  [Breakpoint.wide]: 2100, // desktop,
};

export const getBreakpointBySize = (size: number) => {
  const breakpointEntries = Object.entries(breakpoints) as [
    Breakpoint,
    number,
  ][];

  let breakpoint = Breakpoint.xs;

  // eslint-disable-next-line no-restricted-syntax
  for (const [breakpointName, breakpointSize] of breakpointEntries) {
    if (breakpointSize <= size) {
      breakpoint = breakpointName;
    } else {
      break;
    }
  }

  return breakpoint;
};

export const getCurrentBreakpoint = () => {
  const width = window.innerWidth;

  return getBreakpointBySize(width);
};
