import { observer } from 'mobx-react';
import { transformToMenuItems } from '../../../../../../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { Selector } from '../../../../../../../../common/Selector/Selector';
import { ArmorItemModel } from '../../../../../../../../../../../../../../domain/aggregations/armors/ArmorItemModel';
import {
  ArmorType,
  armorTypeTranslation,
} from '../../../../../../../../../../../../../../domain/aggregations/armors/Armor.types';

export const ArmorTypeSelector = observer(
  ({ armor }: { armor: ArmorItemModel }) => {
    const items = transformToMenuItems({
      fromItems: Object.values(ArmorType),
      generateItem: (armorType) => ({
        key: armorType,
        name: armorTypeTranslation[armorType],
        value: armorType,
        onClick: () => {
          armor.setType(armorType);
        },
      }),
    });

    return (
      <Selector
        value={armor.type}
        valueAsText={armorTypeTranslation[armor.type]}
        items={items}
      />
    );
  },
);
