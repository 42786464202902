export enum System {
  AnimaBeyondFantasy = 'AnimaBeyondFantasy',
  DnD5 = 'DnD5',
}

export const getSystemFromString = (system: string): System => {
  switch (system) {
    case System.AnimaBeyondFantasy:
      return System.AnimaBeyondFantasy;
    case System.DnD5:
      return System.DnD5;
    default:
      throw new Error(`System ${system} not found`);
  }
};
