import { KiTechniqueCost } from '../../../../../../../../../../../../../../domain/aggregations/ki-technique/KiTechnique';
import {
  Container,
  CostContainer,
  CostTitle,
  StyledInput,
  Wrapper,
} from './KiTechniqueCostInput.styled';
import { KiType } from '../../../../../../../../../../../../../../domain/character/model/types/KiType';
import { ChangeEvent } from 'react';
import { Input } from '../../../../../../../../../../../../../../../shared/application/design-system/components/Input/Input';
import { fromSnapshot, getSnapshot } from 'mobx-keystone';

export const KiTechniqueCostInput = ({
  currentCost,
  onCostChange,
}: {
  currentCost: KiTechniqueCost;
  onCostChange: (kiCost: KiTechniqueCost) => void;
}) => {
  const getKiCostHandler =
    (kiType: KiType) => (e: ChangeEvent<HTMLInputElement>) => {
      const newCost = getSnapshot(currentCost);

      const value = e.target.valueAsNumber;

      const kiCost = newCost[kiType];

      if (!kiCost) {
        newCost[kiType] = {
          cost: value,
        };
      } else {
        kiCost.cost = value;
      }

      onCostChange(fromSnapshot(newCost));
    };

  const getKiMaintainedCostHandler =
    (kiType: KiType) => (e: ChangeEvent<HTMLInputElement>) => {
      const newCost = getSnapshot(currentCost);

      const inputValue = e.target.valueAsNumber;
      const value = inputValue > 0 ? inputValue : undefined;

      const kiCost = newCost[kiType];

      if (!kiCost) {
        newCost[kiType] = {
          cost: 0,
          maintainedCost: value,
        };
      } else {
        kiCost.maintainedCost = value;
      }

      onCostChange(fromSnapshot(newCost));
    };

  return (
    <Wrapper>
      <Container>
        <>
          <CostContainer>
            <CostTitle>Agilidad</CostTitle>
            <StyledInput
              type="number"
              size={Input.Size.Small}
              value={currentCost[KiType.Agility]?.cost ?? 0}
              onChange={getKiCostHandler(KiType.Agility)}
            />
            <StyledInput
              type="number"
              size={Input.Size.Small}
              value={currentCost[KiType.Agility]?.maintainedCost ?? 0}
              onChange={getKiMaintainedCostHandler(KiType.Agility)}
              info="Coste mantenido"
            />
          </CostContainer>
          <CostContainer>
            <CostTitle>Constitución</CostTitle>
            <StyledInput
              type="number"
              size={Input.Size.Small}
              value={currentCost[KiType.Constitution]?.cost ?? 0}
              onChange={getKiCostHandler(KiType.Constitution)}
            />
            <StyledInput
              type="number"
              size={Input.Size.Small}
              value={currentCost[KiType.Constitution]?.maintainedCost ?? 0}
              onChange={getKiMaintainedCostHandler(KiType.Constitution)}
              info="Coste mantenido"
            />
          </CostContainer>
          <CostContainer>
            <CostTitle>Destreza</CostTitle>
            <StyledInput
              type="number"
              size={Input.Size.Small}
              value={currentCost[KiType.Dexterity]?.cost ?? 0}
              onChange={getKiCostHandler(KiType.Dexterity)}
            />
            <StyledInput
              type="number"
              size={Input.Size.Small}
              value={currentCost[KiType.Dexterity]?.maintainedCost ?? 0}
              onChange={getKiMaintainedCostHandler(KiType.Dexterity)}
              info="Coste mantenido"
            />
          </CostContainer>
        </>
        <>
          <CostContainer>
            <CostTitle>Poder</CostTitle>
            <StyledInput
              type="number"
              size={Input.Size.Small}
              value={currentCost[KiType.Power]?.cost ?? 0}
              onChange={getKiCostHandler(KiType.Power)}
            />
            <StyledInput
              type="number"
              size={Input.Size.Small}
              value={currentCost[KiType.Power]?.maintainedCost ?? 0}
              onChange={getKiMaintainedCostHandler(KiType.Power)}
              info="Coste mantenido"
            />
          </CostContainer>
          <CostContainer>
            <CostTitle>Fuerza</CostTitle>
            <StyledInput
              type="number"
              size={Input.Size.Small}
              value={currentCost[KiType.Strength]?.cost ?? 0}
              onChange={getKiCostHandler(KiType.Strength)}
            />
            <StyledInput
              type="number"
              size={Input.Size.Small}
              value={currentCost[KiType.Strength]?.maintainedCost ?? 0}
              onChange={getKiMaintainedCostHandler(KiType.Strength)}
              info="Coste mantenido"
            />
          </CostContainer>
          <CostContainer>
            <CostTitle>Voluntad</CostTitle>
            <StyledInput
              type="number"
              size={Input.Size.Small}
              value={currentCost[KiType.WillPower]?.cost ?? 0}
              onChange={getKiCostHandler(KiType.WillPower)}
            />
            <StyledInput
              type="number"
              size={Input.Size.Small}
              value={currentCost[KiType.WillPower]?.maintainedCost ?? 0}
              onChange={getKiMaintainedCostHandler(KiType.WillPower)}
              info="Coste mantenido"
            />
          </CostContainer>
        </>
      </Container>
    </Wrapper>
  );
};
