import { MouseEvent } from 'react';
import { Tooltip } from '@mui/material';
import { StyledWarningIcon } from '../../pages/CharacterPage/components/CharacterSheet/components/CharacterToolbar/CharacterToolbar.styled';
import { useCharacterIssuesModal } from '../CharacterIssuesModal/CharacterIssuesModal';
import { Character } from '../../../domain/character/Character';

export const CharacterIssuesButton = ({
  character,

  onClick,
}: {
  character: Character;

  onClick?: (e: MouseEvent) => void;
}) => {
  const { openCharacterIssuesModal, CharacterIssuesModal } =
    useCharacterIssuesModal();

  const handleClick = (e: MouseEvent) => {
    onClick?.(e);

    openCharacterIssuesModal(character);
  };

  return (
    <>
      <Tooltip title="Hay problemas en tu personaje">
        <StyledWarningIcon onClick={handleClick} />
      </Tooltip>

      <CharacterIssuesModal />
    </>
  );
};
