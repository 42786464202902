import { Model, model, modelAction, prop } from 'mobx-keystone';
import { v4 as uuid } from 'uuid';
import { getRandomItemFromArray } from '../../../../../../shared/utils/getRandomItemFromArray';
import { MagicPath } from '../../../../aggregations/magic-path/MagicPath';
import { DefaultMagicSpells } from '../../../../aggregations/magic-spell/DefaultMagicSpells';
import { MagicSpell } from '../../../../aggregations/magic-spell/MagicSpell';
import {
  getMetamagicFromId,
  Metamagic,
  MetamagicId,
} from '../../../../aggregations/metamagic/Metamagic';
import { Summon } from '../../../../aggregations/summons/Summon';
import {
  AttackAsMagicProjectionTable,
  DefenseAsMagicProjectionTable,
} from '../../../../aggregations/tables/mystic-tables/MysticTable';
import { getParentCharacter } from '../../../../utils/parenting/getParentCharacter';
import { ActFieldModel } from './parts/ActFieldModel';
import { BanishFieldModel } from './parts/BanishFieldModel';
import { BindFieldModel } from './parts/BindFieldModel';
import { ControlFieldModel } from './parts/ControlFieldModel';
import { FreeAccessSpellsLimitFieldModel } from './parts/FreeAccessSpellsLimitFieldModel';
import { InnateMagicFieldModel } from './parts/InnateMagicFieldModel';
import {
  createModelFromMagicSpell,
  MagicSpellModel,
} from './parts/magic-spell/MagicSpellModel';
import { MagicLevelFieldModel } from './parts/MagicLevelFieldModel';
import { MagicPathLevelModel } from './parts/MagicPathModel/MagicPathLevelModel';
import { MagicPathModel } from './parts/MagicPathModel/MagicPathModel';
import { MagicProjectionFieldModel } from './parts/MagicProjectionFieldModel';
import { SummonFieldModel } from './parts/SummonFieldModel';
import { SummoningModel } from './parts/SummoningModel';
import { ZeonFieldModel } from './parts/ZeonFieldModel';
import { ZeonRegenerationFieldModel } from './parts/ZeonRegenerationFieldModel';
import { getMagicLevelOfSelectedSpell } from './getMagicLevelOfSelectedSpell';

@model('Character/Mystic')
export class MysticModel extends Model({
  magicLevel: prop(() => new MagicLevelFieldModel({})),
  magicProjection: prop(() => new MagicProjectionFieldModel({})),

  summon: prop(() => new SummonFieldModel({})),
  banish: prop(() => new BanishFieldModel({})),
  bind: prop(() => new BindFieldModel({})),
  control: prop(() => new ControlFieldModel({})),

  zeon: prop(() => new ZeonFieldModel({})),
  zeonRegeneration: prop(() => new ZeonRegenerationFieldModel({})),
  act: prop(() => new ActFieldModel({})),

  magicProjectionUnbalance: prop(0).withSetter(),

  innateMagic: prop(() => new InnateMagicFieldModel({})),

  zeonContainer: prop(0).withSetter(),
  zeonAmplifier: prop(0).withSetter(),

  metamagics: prop<MetamagicId[]>(() => []),

  summoning: prop(() => new SummoningModel({})),

  summons: prop<Summon[]>(() => []),

  paths: prop<MagicPathModel[]>(() => []),

  selectedSpells: prop<MagicSpellModel[]>(() => []),

  customSpells: prop<MagicSpellModel[]>(() => []),

  freeAccessSpells: prop<MagicSpellModel[]>(() => []),
  freeAccessSpellsLimit: prop(() => new FreeAccessSpellsLimitFieldModel({})),
}) {
  @modelAction
  addPath(): void {
    this.paths.push(
      new MagicPathModel({
        path: MagicPath.Light,
        level: new MagicPathLevelModel({ base: 0 }),
      }),
    );
  }

  @modelAction
  removePath(path: MagicPathModel): void {
    this.paths = this.paths.filter((p) => p !== path);
  }

  @modelAction
  addSummon(summon: Summon): void {
    this.summons.push(summon);
  }

  @modelAction
  replaceSummon(index: number, summon: Summon): void {
    this.summons[index] = summon;
  }

  @modelAction
  removeSummon(summon: Summon): void {
    this.summons = this.summons.filter((s) => s !== summon);
  }

  @modelAction
  addMetamagic(metamagic: Metamagic): void {
    if (this.metamagics.includes(metamagic.id)) return;

    this.metamagics.push(metamagic.id);
  }

  @modelAction
  replaceMetamagic(index: number, metamagic: Metamagic): void {
    this.metamagics[index] = metamagic.id;
  }

  @modelAction
  removeMetamagic(metamagic: Metamagic): void {
    this.metamagics = this.metamagics.filter((s) => s !== metamagic.id);
  }

  @modelAction
  addSelectedSpell(spell: MagicSpell): void {
    this.selectedSpells.push(createModelFromMagicSpell(spell));
  }

  @modelAction
  replaceSelectedSpell(from: MagicSpellModel, to: MagicSpellModel): void {
    this.selectedSpells = this.selectedSpells.map((spell) =>
      spell === from ? to : spell,
    );
  }

  @modelAction
  removeSelectedSpell(spell: MagicSpellModel): void {
    this.selectedSpells = this.selectedSpells.filter((s) => s !== spell);
  }

  @modelAction
  addFreeAccessSpell(freeAccessSpell: MagicSpell): void {
    this.freeAccessSpells.push(createModelFromMagicSpell(freeAccessSpell));
  }

  @modelAction
  replaceFreeAccessSpell(from: MagicSpellModel, to: MagicSpellModel): void {
    this.freeAccessSpells = this.freeAccessSpells.map((spell) =>
      spell === from ? to : spell,
    );
  }

  @modelAction
  removeFreeAccessSpell(freeAccessSpell: MagicSpellModel): void {
    this.freeAccessSpells = this.freeAccessSpells.filter(
      (s) => s !== freeAccessSpell,
    );
  }

  @modelAction
  addCustomSpell(): void {
    this.customSpells.push(
      createModelFromMagicSpell({
        ...getRandomItemFromArray(DefaultMagicSpells),
        id: uuid(),
      }),
    );
  }

  @modelAction
  replaceCustomSpell(from: MagicSpellModel, to: MagicSpellModel): void {
    this.customSpells = this.customSpells.map((spell) =>
      spell === from ? to : spell,
    );
  }

  @modelAction
  removeCustomSpell(customSpell: MagicSpellModel): void {
    this.customSpells = this.customSpells.filter((s) => s !== customSpell);
  }

  get usedMagicLevel(): number {
    return (
      this.paths.reduce((acc, path) => acc + path.level.used, 0) +
      this.metamagics
        .map(getMetamagicFromId)
        .reduce((acc, metamagic) => acc + metamagic.cost, 0) +
      this.selectedSpells
        .map(getMagicLevelOfSelectedSpell)
        .reduce((acc, level) => acc + level, 0)
    );
  }

  get offensiveMagicProjection(): number {
    if (!this.character) return 0;

    if (
      this.character.pd.categories.some((c) =>
        c.mystic.tables.some(
          (t) => t.type === AttackAsMagicProjectionTable.type,
        ),
      )
    ) {
      return (
        this.character.combat.attackAbility.boughtFromCategories +
        this.character.combat.attackAbility.primariesBonus
      );
    }

    return this.magicProjection.final + this.magicProjectionUnbalance;
  }

  get defensiveMagicProjection(): number {
    if (!this.character) return 0;

    if (
      this.character.pd.categories.some((c) =>
        c.mystic.tables.some(
          (t) => t.type === DefenseAsMagicProjectionTable.type,
        ),
      )
    ) {
      return (
        this.character.combat.attackAbility.boughtFromCategories +
        this.character.combat.attackAbility.primariesBonus
      );
    }

    return this.magicProjection.final - this.magicProjectionUnbalance;
  }

  get knownSpells(): MagicSpell[] {
    let spells = this.paths.reduce<MagicSpell[]>(
      (acc, path) => [...acc, ...path.knownSpells],
      [],
    );

    spells = [...spells, ...this.selectedSpells.map((s) => s.toObject())];
    spells = [...spells, ...this.freeAccessSpells.map((s) => s.toObject())];

    spells = spells.filter(
      (spell, index) => spells.findIndex((s) => s.id === spell.id) === index,
    );

    return spells;
  }

  get character() {
    return getParentCharacter(this);
  }
}
