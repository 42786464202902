import { observer } from 'mobx-react';
import React from 'react';
import { CategoryModel } from '../../../../../../../../../../domain/character/model/parts/pd/parts/categories/CategoryModel';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { FieldHeader } from '../common/FieldRow/components/FieldHeader/FieldHeader';
import { PDFieldRow } from '../common/FieldRow/PDFieldRow';

type LifePDsProps = {
  category: CategoryModel;
};

export const LifePDs = observer(({ category }: LifePDsProps) => {
  const {
    character: { content: character },
  } = useCharacterContext();

  return (
    <Section>
      <Section.Title>Puntos de vida</Section.Title>
      <Table>
        <FieldHeader />
        <PDFieldRow
          name="Puntos de vida"
          field={character.lifePoints}
          pdField={category.lifePoints}
        />
      </Table>
    </Section>
  );
});
