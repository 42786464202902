import { StyledBigTitle, Wrapper } from './LastNews.styled';
import { NewsList } from './components/NewsList/NewsList';
import { NewsService } from '../../../../../infrastructure/NewsService';
import { useEffect } from 'react';
import { LastNews as LastNewsItems } from './assets/LastNews';

export const LastNews = () => {
  const lastVersion = NewsService.getLastNewsVersion();
  const lastRead = NewsService.getLastNewsVersionRead();

  useEffect(() => {
    if (lastVersion !== lastRead) {
      NewsService.saveLastNewsVersionRead(lastVersion);
    }
  }, [lastVersion, lastRead]);

  return (
    <Wrapper>
      <StyledBigTitle>Últimos cambios</StyledBigTitle>
      <NewsList news={LastNewsItems} />
    </Wrapper>
  );
};
