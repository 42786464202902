import { model } from 'mobx-keystone';
import { SecondarySkill } from '../../../../../types/SecondarySkill';
import { getTotalOf } from '../../../../../utils/getTotalOf';
import { SecondarySkillPDFieldModel } from '../../../../pd/parts/categories/parts/secondaries/SecondarySkillPDFieldModel';
import { PhysicalSecondarySkillFieldModel } from '../../../utils/fields/PhysicalSecondarySkillFieldModel';

@model('Character/Secondaries/AthleticSkills/Ride')
export class RideSkillModel extends PhysicalSecondarySkillFieldModel {
  type = SecondarySkill.Ride;

  get primariesBonus(): number {
    if (!this.character) return 0;

    return this.character.primaries.agility.modifier.final;
  }

  getPDFields(): SecondarySkillPDFieldModel[] {
    return (
      this.character?.pd.categories.map((c) => c.secondaries.athletic.ride) ??
      []
    );
  }

  get fromCategories(): number {
    return getTotalOf(this.character, (c) => c.secondaries.athletic.ride.final);
  }
}
