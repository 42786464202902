import styled from 'styled-components';
import { Close } from '@styled-icons/material';

export const StyledClose = styled(Close)(({ theme: { size } }) => ({
  width: size.units(4),
  height: size.units(4),
  cursor: 'pointer',
}));

export const Wrapper = styled.div<{ fullScreenInMobile?: boolean }>(
  ({ theme: { size, color, borderRadius, mixins }, fullScreenInMobile }) => ({
    backgroundColor: color.white(),
    padding: size.units(4),
    borderRadius: borderRadius.medium,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    maxHeight: '90%',
    overflowY: 'auto',

    [StyledClose]: {
      position: 'absolute',
      top: size.units(1),
      right: size.units(1),
    },

    ...mixins.responsive.xs.downward({
      top: 'unset',

      transform: 'translate(-50%,0%)',
      bottom: '0',
      width: '100%',
      height: fullScreenInMobile ? '90%' : undefined,
      boxSizing: 'border-box',
      borderRadius: 0,
    }),
  }),
);
