import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { Wrapper } from './SecondarySkillBonusesInfo.styled';
import { StateSmallParagraph } from '../../PDsTab.styled';
import { observer } from 'mobx-react';

export const SecondarySkillBonusesInfo = observer(
  ({ isNovel }: { isNovel: boolean }) => {
    const { character } = useCharacterContext();

    const usedPhysicalNaturalBonusPoints =
      character.content.secondaries.usedPhysicalNaturalBonusPoints;
    const maximumPhysicalNaturalBonusPoints =
      character.content.secondaries.maximumPhysicalNaturalBonusPoints;

    const usedMentalNaturalBonusPoints =
      character.content.secondaries.usedMentalNaturalBonusPoints;
    const maximumMentalNaturalBonusPoints =
      character.content.secondaries.maximumMentalNaturalBonusPoints;

    const usedNaturalAbilityPoints =
      character.content.secondaries.usedNaturalAbilityPoints;
    const maximumNaturalAbilityPoints =
      character.content.secondaries.maximumNaturalAbilityPoints;

    const usedNovelBonusPoints =
      character.content.secondaries.usedNovelBonusPoints;
    const maximumNovelBonusPoints =
      character.content.secondaries.maximumNovelBonusPoints;

    return (
      <Wrapper>
        <StateSmallParagraph
          $errored={
            maximumPhysicalNaturalBonusPoints < usedPhysicalNaturalBonusPoints
          }
        >
          Bono natural físico
          <span>
            ({usedPhysicalNaturalBonusPoints}/
            {maximumPhysicalNaturalBonusPoints})
          </span>
        </StateSmallParagraph>
        <StateSmallParagraph
          $errored={
            maximumMentalNaturalBonusPoints < usedMentalNaturalBonusPoints
          }
        >
          Bono natural mental
          <span>
            ({usedMentalNaturalBonusPoints}/{maximumMentalNaturalBonusPoints})
          </span>
        </StateSmallParagraph>
        <StateSmallParagraph
          $errored={maximumNaturalAbilityPoints < usedNaturalAbilityPoints}
        >
          Habilidad natural
          <span>
            ({usedNaturalAbilityPoints}/{maximumNaturalAbilityPoints})
          </span>
        </StateSmallParagraph>
        {isNovel && (
          <>
            <StateSmallParagraph
              $errored={maximumNovelBonusPoints < usedNovelBonusPoints}
            >
              Puntos de novel
              <span>
                ({usedNovelBonusPoints}/{maximumNovelBonusPoints})
              </span>
            </StateSmallParagraph>
          </>
        )}
      </Wrapper>
    );
  },
);
