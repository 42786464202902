import React from 'react';
import {
  ContainerSubscription,
  EmailInformation,
  FooterContainer,
  ParagraphInformation,
  PatreonContainer,
  SmallParagraphInformation,
  TitleInformation,
  Wrapper,
} from './SubscriptionConfirmation.styled';
import { BetaService } from '../../../../../../../infrastructure/SignUpBetaService';
import useScript from '../../../../../../../infrastructure/hook/useScript/useScript';

const Information = {
  titleInformation: 'Agradecemos tu confianza',
  information: 'Lo antes posible recibiras acceso en :',
  additionalText: 'Dudas o modificación del email, contáctanos en :',
  patreonText: 'Ayudanos a mejorar para ti apoyandonos',
  patreonLink: 'https://www.patreon.com/CharactersVaultForAll',
  corporateEmail: 'info@charactersvault.com',
};

export const SubscriptionConfirmation = () => {
  const { getBetaUserEmail } = BetaService;
  useScript();
  return (
    <Wrapper>
      <ContainerSubscription>
        <TitleInformation
          dangerouslySetInnerHTML={{
            __html: Information.titleInformation,
          }}
        />
        <ParagraphInformation
          dangerouslySetInnerHTML={{
            __html: Information.information,
          }}
        />
        <EmailInformation>{getBetaUserEmail()}</EmailInformation>;
        <SmallParagraphInformation
          dangerouslySetInnerHTML={{
            __html: Information.patreonText,
          }}
        />
        <PatreonContainer>
          <a
            href="https://www.patreon.com/bePatron?u=96466192"
            data-patreon-widget-type="become-patron-button"
          >
            Become a Patron!
          </a>
        </PatreonContainer>
      </ContainerSubscription>
      <FooterContainer>
        <SmallParagraphInformation
          dangerouslySetInnerHTML={{
            __html: Information.additionalText,
          }}
        />
        <EmailInformation
          dangerouslySetInnerHTML={{
            __html: Information.corporateEmail,
          }}
        />
      </FooterContainer>
    </Wrapper>
  );
};
