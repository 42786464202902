import {
  AdvancedMartialArtGrade,
  BasicMartialArtGrade,
} from './MartialArtGrades';

export enum MartialArtType {
  Aikido = 'Aikido',
  Boxing = 'Boxing',
  Capoeira = 'Capoeira',
  Grappling = 'Grappling',
  Kardad = 'Kardad',
  Kenpo = 'Kenpo',
  Kuan = 'Kuan',
  KungFu = 'KungFu',
  Lama = 'Lama',
  MallaYuddha = 'MallaYuddha',
  MoaiThai = 'MoaiThai',
  Pankration = 'Pankration',
  Sambo = 'Sambo',
  Shotokan = 'Shotokan',
  SooBahk = 'SooBahk',
  TaeKwonDo = 'TaeKwonDo',
  TaiChi = 'TaiChi',
  XingQuan = 'ZingQuan',

  Asakusen = 'Asakusen',
  Dumah = 'Dumah',
  Emp = 'Emp',
  Enuth = 'Enuth',
  Exelion = 'Exelion',
  Godhand = 'Godhand',
  Hakyoukuken = 'Hakyoukuken',
  Hanja = 'Hanja',
  LamaTsu = 'LamaTsu',
  Melkaiah = 'Melkaiah',
  Mushin = 'Mushin',
  RexFrame = 'RexFrame',
  Selene = 'Selene',
  Seraphite = 'Seraphite',
  Shephon = 'Shephon',
  Suyanta = 'Suyanta',
  Velez = 'Velez',
}

export enum MartialArtLevelType {
  Basic = 'Basic',
  Advanced = 'Advanced',
}

export abstract class BasicMartialArt {
  abstract readonly name: string;

  abstract readonly complexity: number;

  public readonly level = MartialArtLevelType.Basic;

  abstract readonly type: MartialArtType;

  abstract readonly grades: BasicMartialArtGrade[];
}

export abstract class AdvancedMartialArt {
  abstract readonly name: string;

  abstract readonly complexity: number;

  public readonly level = MartialArtLevelType.Advanced;

  abstract readonly type: MartialArtType;

  abstract readonly grades: AdvancedMartialArtGrade[];
}

export type MartialArt = BasicMartialArt | AdvancedMartialArt;
