import { observer } from 'mobx-react';
import { Fragment, useState } from 'react';
import {
  PsychicPower,
  PsychicPowerId,
} from '../../../../../../../../../../domain/aggregations/psychic-power/PsychicPower';
import {
  Difficulty,
  getDifficultyAsString,
} from '../../../../../../../../../../domain/character/model/types/Difficulty';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { HideButton, ShowButton } from './KnownPsychicPowers.styled';

export const KnownPsychicPowers = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const [expandedPsychicPowersIds, setExpandedPsychicPowerIds] = useState<
    PsychicPowerId[]
  >([]);

  const sortedPsychicPowers = [...character.psychic.knownPsychicPowers].sort(
    (a, b) => a.discipline.localeCompare(b.discipline),
  );

  const handleHidePsychicPowerDifficulties = (power: PsychicPower) => () => {
    setExpandedPsychicPowerIds(
      expandedPsychicPowersIds.filter((id) => id !== power.id),
    );
  };

  const handleShowPsychicPowerDifficulties = (power: PsychicPower) => () => {
    setExpandedPsychicPowerIds([...expandedPsychicPowersIds, power.id]);
  };

  return (
    <Section maxHeight="fit">
      <Section.Title>Poderes psíquicos conocidos</Section.Title>
      <Fragment>
        <Table>
          <Table.Header>
            <Table.Header.Cell />
            <Table.Header.Cell $width={7}>Nombre</Table.Header.Cell>
            <Table.Header.Cell $width={4} $hideOnMobile>
              Disciplina
            </Table.Header.Cell>
            <Table.Header.Cell $hideOnMobile>Nivel</Table.Header.Cell>
            <Table.Header.Cell $width={4}>Mant.</Table.Header.Cell>
            <Table.Header.Cell>Acción</Table.Header.Cell>
          </Table.Header>
          {sortedPsychicPowers.map((power) => (
            <Fragment key={power.id}>
              <Table.Row>
                <Table.Row.Cell $textAlign="right">
                  {expandedPsychicPowersIds.includes(power.id) ? (
                    <HideButton
                      onClick={handleHidePsychicPowerDifficulties(power)}
                    />
                  ) : (
                    <ShowButton
                      onClick={handleShowPsychicPowerDifficulties(power)}
                    />
                  )}
                </Table.Row.Cell>
                <Table.Row.Cell $textAlign="left">{power.name}</Table.Row.Cell>
                <Table.Row.Cell $hideOnMobile>
                  {power.discipline}
                </Table.Row.Cell>
                <Table.Row.Cell $hideOnMobile>{power.level}</Table.Row.Cell>
                <Table.Row.Cell>
                  {power.hasMaintenance ? 'Sí' : 'No'}
                </Table.Row.Cell>
                <Table.Row.Cell>{power.actionType}</Table.Row.Cell>
              </Table.Row>
              {expandedPsychicPowersIds.includes(power.id)
                ? Object.keys(power.casts)
                    .map((c) => parseInt(c) as Difficulty)
                    .map((difficulty) => {
                      const difficultyKey =
                        difficulty as keyof PsychicPower['casts'];

                      const description = power.casts[difficultyKey];

                      return (
                        <Fragment key={`${power.id}-${difficulty}`}>
                          <Table.Row>
                            <Table.Row.Cell />
                            <Table.Row.Cell
                              $bold
                              $textAlign="right"
                              $verticalAlign="sub"
                              $danger={
                                power.minimalDifficultyToSuccess > difficulty
                              }
                            >
                              {getDifficultyAsString(difficulty)} ({difficulty})
                            </Table.Row.Cell>
                            <Table.Row.Cell colSpan={3} $textAlign="left">
                              {description}
                            </Table.Row.Cell>
                          </Table.Row>
                        </Fragment>
                      );
                    })
                : undefined}
            </Fragment>
          ))}
        </Table>
      </Fragment>
    </Section>
  );
});
