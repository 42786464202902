import { computed } from 'mobx';
import { model } from 'mobx-keystone';
import { BaseFinalFieldModel } from '../../utils/fields/BaseFinalFieldModel';
import { calculateMovementInMetersFromMovementType } from './util/calculateMovementInMetersFromMovementType';

@model('MovementTypeField')
export class MovementTypeField extends BaseFinalFieldModel {
  @computed
  get info() {
    const value = this.final;

    const maximumMovementInMeters =
      calculateMovementInMetersFromMovementType(value);

    if (value === 0) {
      return {
        maximumMovementInMeters: 0,
        runningMovementInMeters: 0,
        passiveMovementInMeters: 0,
      };
    } else {
      return {
        maximumMovementInMeters: maximumMovementInMeters,
        runningMovementInMeters: calculateMovementInMetersFromMovementType(
          value - 2,
        ),
        passiveMovementInMeters: maximumMovementInMeters / 4,
      };
    }
  }

  get allActionsPenalty(): number {
    if (!this.character) return 0;

    return Math.min(
      0,
      Math.ceil(this.character.modifiers.allActions.final / 20),
    );
  }

  get movementRestrictionPenalty(): number {
    if (!this.character) return 0;

    return this.character.modifiers.movementRestriction.final;
  }

  @computed
  get finalWithoutPenalties(): number {
    if (!this.character) return 0;

    return this.base + this.special + this.character.primaries.agility.final;
  }

  get final(): number {
    if (!this.character) return 0;

    const value =
      this.finalWithoutPenalties +
      this.allActionsPenalty -
      this.movementRestrictionPenalty;

    return Math.max(0, value);
  }
}
