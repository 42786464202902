import { Model, model, modelAction, prop } from 'mobx-keystone';
import { PsychicDisciplineType } from '../../../../aggregations/psychic-disciplines/PsychicDiscipline';
import { PsychicPower } from '../../../../aggregations/psychic-power/PsychicPower';
import { getParentCharacter } from '../../../../utils/parenting/getParentCharacter';
import { MentalPatternModel } from '../pd/parts/categories/parts/psychic/MentalPatternModel';
import { InnatePsychicPowerModel } from './parts/InnatePsychicPowerModel';
import { PsychicPointsModel } from './parts/PsychicPointsModel';
import { PsychicPotentialModel } from './parts/PsychicPotentialModel';
import {
  createModelFromPsychicPower,
  PsychicPowerModel,
} from './parts/PsychicPowerModel';
import { PsychicProjectionModel } from './parts/PsychicProjectionModel';
import { computed } from 'mobx';

@model('Character/Psychic')
export class PsychicModel extends Model({
  psychicPoints: prop(() => new PsychicPointsModel({})),

  psychicPotential: prop(() => new PsychicPotentialModel({})),
  psychicProjection: prop(() => new PsychicProjectionModel({})),

  psychicCrystalPotential: prop(0).withSetter(),

  disciplines: prop<PsychicDisciplineType[]>(() => []),

  powers: prop<PsychicPowerModel[]>(() => []),

  innatePowersSlots: prop(0).withSetter(),
  innatePowers: prop<InnatePsychicPowerModel[]>(() => []),

  naturalPower: prop<PsychicPower | undefined>().withSetter(),
}) {
  get usedPsychicPoints(): number {
    const byPowers =
      this.powers.reduce((acc, p) => acc + p.psychicPointsUsedToIncrease, 0) +
      this.powers.length;

    const byInnatePowers =
      this.innatePowers.reduce(
        (acc, p) => acc + p.psychicPointsUsedToIncrease,
        0,
      ) +
      this.innatePowersSlots * 2;

    return (
      byPowers +
      byInnatePowers +
      this.disciplines.length +
      this.psychicPotential.ppUsedToIncrease
    );
  }

  @modelAction
  addPsychicDiscipline(psychicDiscipline: PsychicDisciplineType): void {
    if (this.disciplines.includes(psychicDiscipline)) {
      return;
    }

    this.disciplines.push(psychicDiscipline);
  }

  @modelAction
  replacePsychicDiscipline(
    index: number,
    psychicDiscipline: PsychicDisciplineType,
  ): void {
    this.disciplines[index] = psychicDiscipline;
  }

  @modelAction
  removePsychicDiscipline(psychicDiscipline: PsychicDisciplineType): void {
    this.disciplines = this.disciplines.filter((s) => s !== psychicDiscipline);
  }

  @modelAction
  addPsychicPower(psychicPower: PsychicPower): void {
    this.powers.push(createModelFromPsychicPower(psychicPower));
  }

  @modelAction
  replacePsychicPower(from: PsychicPowerModel, to: PsychicPowerModel): void {
    this.powers = this.powers.map((p) => (p === from ? to : p));
  }

  @modelAction
  removePsychicPower(psychicPower: PsychicPowerModel): void {
    this.powers = this.powers.filter((s) => s !== psychicPower);
  }

  @modelAction
  addInnatePsychicPower(psychicPower: PsychicPower): void {
    this.innatePowers.push(
      new InnatePsychicPowerModel({ power: psychicPower }),
    );
  }

  @modelAction
  removeInnatePsychicPower(psychicPower: InnatePsychicPowerModel): void {
    this.innatePowers = this.innatePowers.filter((s) => s !== psychicPower);
  }

  @computed
  get mentalPatterns(): MentalPatternModel[] {
    if (!this.character) return [];

    return this.character.pd.categories.flatMap(
      (c) => c.psychic.mentalPatterns,
    );
  }

  get knownPsychicPowers(): PsychicPower[] {
    let powers = this.powers
      .map((p) => p.toObject())
      .concat(this.innatePowers.map((p) => p.power));

    // filter out duplicates
    powers = powers.filter((power, index) => {
      return powers.findIndex((p) => p.name === power.name) === index;
    });

    return powers;
  }

  get character() {
    return getParentCharacter(this);
  }
}
