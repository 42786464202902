import { model } from 'mobx-keystone';
import { WeaponSizeProportion } from '../Weapon.types';
import { WeaponFieldModel } from './WeaponFieldModel';

@model('WeaponItem/WeaponIntegrityField')
export class WeaponIntegrityFieldModel extends WeaponFieldModel {
  get final(): number {
    if (!this.weapon) return 0;

    let integrity = this.base + this.special + this.weapon.quality.final * 2;

    if (this.weapon.sizeProportion === WeaponSizeProportion.Enormous) {
      integrity += 6;
    }

    if (this.weapon.sizeProportion === WeaponSizeProportion.Enormous) {
      integrity += 16;
    }

    return Math.max(integrity, 0);
  }
}
