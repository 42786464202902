import { model, Model, prop } from 'mobx-keystone';
import { AgilityKiAccumulationPointsFieldModel } from './fields/AgilityKiAccumulationPointsFieldModel';
import { ConstitutionKiAccumulationPointsFieldModel } from './fields/ConstitutionKiAccumulationPointsFieldModel';
import { DexterityKiAccumulationPointsFieldModel } from './fields/DexterityKiAccumulationPointsFieldModel';
import { PowerKiAccumulationPointsFieldModel } from './fields/PowerKiAccumulationPointsFieldModel';
import { StrengthKiAccumulationPointsFieldModel } from './fields/StrengthKiAccumulationPointsFieldModel';
import { WillPowerKiAccumulationPointsFieldModel } from './fields/WillPowerKiAccumulationPointsFieldModel';

@model('Character/Ki/KiAccumulationPoints')
export class KiAccumulationPointsModel extends Model({
  agility: prop(() => new AgilityKiAccumulationPointsFieldModel({})),
  strength: prop(() => new StrengthKiAccumulationPointsFieldModel({})),
  dexterity: prop(() => new DexterityKiAccumulationPointsFieldModel({})),
  constitution: prop(() => new ConstitutionKiAccumulationPointsFieldModel({})),
  power: prop(() => new PowerKiAccumulationPointsFieldModel({})),
  willPower: prop(() => new WillPowerKiAccumulationPointsFieldModel({})),
}) {
  get total() {
    return (
      this.power.final +
      this.agility.final +
      this.strength.final +
      this.dexterity.final +
      this.willPower.final +
      this.constitution.final
    );
  }
}
