import { Model, model, prop } from 'mobx-keystone';
import { CategoryTemplate } from '../../../../../../../../aggregations/category-templates/CategoryTemplate';
import { WarriorCategoryTemplate } from '../../../../../../../../aggregations/category-templates/WarriorCategoryTemplate';
import { getParentDevelopedCategory } from '../../../../../../../../utils/parenting/getParentDevelopedCategory';
import type { CategoryModel } from '../../CategoryModel';
import { AthleticSkillsPDModel } from './parts/AthleticSkillsPDModel';
import { CreativeSkillsPDModel } from './parts/CreativeSkillsPDModel';
import { IntellectualSkillsPDModel } from './parts/IntellectualSkillsPDModel';
import { PerceptiveSkillsPDModel } from './parts/PerceptiveSkillsPDModel';
import { SocialSkillsPDModel } from './parts/SocialSkillsPDModel';
import { SubterfugeSkillsPDModel } from './parts/SubterfugeSkillsPDModel';
import { VigorSkillsPDModel } from './parts/VigorSkillsPDModel';

@model('Character/Development/SecondarySkillsPD')
export class SecondarySkillsPDModel extends Model({
  athletic: prop(() => new AthleticSkillsPDModel({})),
  social: prop(() => new SocialSkillsPDModel({})),
  perceptive: prop(() => new PerceptiveSkillsPDModel({})),
  intellectual: prop(() => new IntellectualSkillsPDModel({})),
  vigor: prop(() => new VigorSkillsPDModel({})),
  subterfuge: prop(() => new SubterfugeSkillsPDModel({})),
  creative: prop(() => new CreativeSkillsPDModel({})),
}) {
  get pdsSpent(): number {
    return (
      this.athletic.pdsSpent +
      this.social.pdsSpent +
      this.perceptive.pdsSpent +
      this.intellectual.pdsSpent +
      this.vigor.pdsSpent +
      this.subterfuge.pdsSpent +
      this.creative.pdsSpent
    );
  }

  get template(): CategoryTemplate {
    if (!this.category) return new WarriorCategoryTemplate(0);

    return this.category.template;
  }

  get category(): CategoryModel | undefined {
    return getParentDevelopedCategory(this);
  }
}
