import styled from 'styled-components';

export const Wrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  gap: size.units(2),
}));
