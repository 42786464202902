import {
  BasicMartialArtGradeIds,
  buildAdvancedMartialArtArcaneGrade,
  buildAdvancedMartialArtBasicGrade,
} from '../../MartialArtGrades';
import {
  AdvancedMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';
import { calculateMartialArtsRequirements } from './utils/calculateMartialArtsRequirements';
import { KiSkillType } from '../../../ki-skill/KiSkill';

const BasicMartialArtsRequirement = [
  {
    type: MartialArtType.Kardad,
    grade: BasicMartialArtGradeIds.Advanced,
  },
  {
    type: MartialArtType.TaiChi,
    grade: BasicMartialArtGradeIds.Advanced,
  },
];

const ArcaneMartialArtsRequirement = [
  {
    type: MartialArtType.Kardad,
    grade: BasicMartialArtGradeIds.Supreme,
  },
  {
    type: MartialArtType.TaiChi,
    grade: BasicMartialArtGradeIds.Supreme,
  },
];

export class ExelionMartialArt extends AdvancedMartialArt {
  readonly name = 'Exelion';

  readonly complexity = 5;

  readonly type = MartialArtType.Exelion;

  readonly level = MartialArtLevelType.Advanced;

  readonly grades = [
    buildAdvancedMartialArtBasicGrade({
      cmBonus: 15,
      combatBonus: {
        attack: 10,
      },
      calculateDamage: (character) => {
        return (
          character.presence.final * 2 +
          character.primaries.power.modifier.final
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          BasicMartialArtsRequirement,
        );

        if (!character.ki.hasKiSkill(KiSkillType.AuraExtensionToWeapon)) {
          errors.push('Habilidad de Ki: Extensión de aura al arma');
        }

        if (character.combat.attackAbility.final < 200) {
          errors.push('Ataque 200+');
        }

        return errors;
      },
    }),
    buildAdvancedMartialArtArcaneGrade({
      cmBonus: 35,
      masterBonus: {
        attack: 25,
      },
      calculateDamage: (character) => {
        return (
          character.presence.final * 2 +
          character.primaries.power.modifier.final
        );
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          ArcaneMartialArtsRequirement,
        );

        if (!character.hasInhumanity) {
          errors.push('Inhumanidad');
        }

        if (character.combat.attackAbility.final < 300) {
          errors.push('Ataque 300+');
        }

        return errors;
      },
    }),
  ];
}
