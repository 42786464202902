import { MagicPath } from '../magic-path/MagicPath';
import type { MagicSpell } from './MagicSpell';
import { SpellActionType, SpellActivationType } from './MagicSpell';

export const DefaultMagicSpells: MagicSpell[] = [
  {
    id: '1',
    name: 'Crear luz',
    magicPath: MagicPath.Light,
    level: 2,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 20,
        maintainedZeonCost: 5,
        effect: '5 metros de zona iluminada',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '25 metros de zona iluminada',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '100 metros de zona iluminada',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: '500 metros de zona iluminada',
      },
    },
  },
  {
    id: '2',
    name: 'Imbuir calma',
    magicPath: MagicPath.Light,
    level: 6,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        effect: 'RM o RP 80 / 10 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: 'RM o RP 100 / 25 metros de radio.',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        effect: 'RM o RP 120 / 50 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 160,
        effect: 'RM o RP 150 / 100 metros de radio',
      },
    },
  },
  {
    id: '3',
    name: 'Flash cegador',
    magicPath: MagicPath.Light,
    level: 8,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        effect: '10 metros de radio / 140 RF',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        effect: '25 metros de radio / 140 RF.',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 150,
        effect: '50 metros de radio / 140 RF.',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        effect: '100 metros de radio / 160 RF',
      },
    },
  },
  {
    id: '4',
    name: 'Escudo de luz',
    magicPath: MagicPath.Light,
    level: 10,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'El escudo tiene 300 puntos de Resistencia',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'El escudo tiene 1.000 puntos de Resistencia',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'El escudo tiene 1.800 puntos de Resistencia',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'El escudo tiene 3.000 puntos de Resistencia',
      },
    },
  },
  {
    id: '5',
    name: 'Percibir',
    magicPath: MagicPath.Light,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '+50 Advertir, Buscar y Valoración mágica.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '+150 Advertir, Buscar y Valoración mágica',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '+200 Advertir, Buscar y Valoración mágica',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: '+250 Advertir, Buscar y Valoración mágica',
      },
    },
  },
  {
    id: '6',
    name: 'Armadura de luz',
    magicPath: MagicPath.Light,
    level: 16,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'TA 2 en Energía y TA 1 en el resto',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'TA 5 en Energía y TA 2 en el resto',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'TA 8 en Energía y TA 4 en el resto.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 20,
        effect: 'TA 12 en Energía y TA 6 en el resto.',
      },
    },
  },
  {
    id: '7',
    name: 'Destrucción de sombras',
    magicPath: MagicPath.Light,
    level: 18,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '10 metros de radio / 140 RM.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '100 metros de radio / 180 RM',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '250 metros de radio / 220 RM.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: '500 metros de radio / 280 RM',
      },
    },
  },
  {
    id: '8',
    name: 'Detectar lo negativo',
    magicPath: MagicPath.Light,
    level: 20,
    isDaily: false,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '25 metros de radio / 80 RM para resistirse.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '150 metros de radio / 140 RM para resistirse',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: '500 metros de radio / 160 RM para resistirse.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 280,
        maintainedZeonCost: 30,
        effect: '1 kilómetro de radio / 200 RM para resistirse',
      },
    },
  },
  {
    id: '9',
    name: 'Descarga de luz',
    magicPath: MagicPath.Light,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        effect: 'Daño 60.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        effect: 'Daño 90.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: 'Daño 120.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        effect: 'Daño 150.',
      },
    },
  },
  {
    id: '10',
    name: 'Holograma',
    magicPath: MagicPath.Light,
    level: 26,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: 'La imagen tiene un tamaño máximo de un metro cuadrados',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 150,
        maintainedZeonCost: 20,
        effect: 'La imagen tiene un tamaño máximo de 15 metros cuadrados',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'La imagen tiene un tamaño máximo de 50 metros cuadrados',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        maintainedZeonCost: 30,
        effect:
          'máximo 100 metros² y dificultad de Advertir (IMP) y Buscar (ABS).',
      },
    },
  },
  {
    id: '11',
    name: 'Lazos de luz',
    magicPath: MagicPath.Light,
    level: 28,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'Disponen de una Fuerza base 8 para los controles',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Disponen de una Fuerza base 12 para los controles',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect:
          'Disponen de una Fuerza base 15 para los controles y tienen Entereza 30',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 180,
        maintainedZeonCost: 15,
        effect:
          'Disponen de una Fuerza base 18 para los controles y tienen Entereza 35',
      },
    },
  },
  {
    id: '12',
    name: 'Dominio lumínico',
    magicPath: MagicPath.Light,
    level: 30,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'Radio de 20 metros / RM 80',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 150,
        maintainedZeonCost: 20,
        effect: 'Radio de 150 metros / RM 140',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'Radio de 300 metros y RM 180.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 250,
        maintainedZeonCost: 30,
        effect: 'Radio de 500 metros / RM 220',
      },
    },
  },
  {
    id: '13',
    name: 'Detectar vida',
    magicPath: MagicPath.Light,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'Radio de 25 metros / RM 140',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Radio de 50 metros / RM 180',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Radio de 150 metros / RM 220',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Radio de 500 metros / RM 280.',
      },
    },
  },
  {
    id: '14',
    name: 'Espía de luz',
    magicPath: MagicPath.Light,
    level: 36,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 100,
        maintainedZeonCost: 20,
        effect: '100 en Advertir y Buscar / 1 Kilómetro de distancia máxima',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect: '150 en Advertir y Buscar / 10 Kilómetros de distancia máxima.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: '200 en Advertir y Buscar / 50 Kilómetros de distancia máxima.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 400,
        maintainedZeonCost: 80,
        effect: '250 en Advertir y Buscar / 500 Kilómetros de distancia máxima',
      },
    },
  },
  {
    id: '15',
    name: 'Éxtasis',
    magicPath: MagicPath.Light,
    level: 38,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'RM 80 / 10 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'RM 100 / 50 metros de radio.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 120 / 100 metros de radio.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'RM 160 / 250 metros de radio',
      },
    },
  },
  {
    id: '16',
    name: 'Dest. sentimientos negativos',
    magicPath: MagicPath.Light,
    level: 40,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        effect: 'RM o RP 100 / 100 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        effect: 'RM o RP 150 / 500 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: 'RM o RP 180 / 1 kilómetro de radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        effect: 'RM o RP 220 / 5 Kilómetros de radio',
      },
    },
  },
  {
    id: '17',
    name: 'Luz sanadora',
    magicPath: MagicPath.Light,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 70,
        effect: '40 Puntos de Vida.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: '80 Puntos de Vida',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 150,
        effect: '120 Puntos de Vida',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 200,
        effect: '250 Puntos de Vida',
      },
    },
  },
  {
    id: '18',
    name: 'Esfera buscadora',
    magicPath: MagicPath.Light,
    level: 46,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Daño Base 100 / Proyección Mágica 150',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'Daño Base de 120 / Proyección Mágica 180',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Daño Base de 160 / Proyección Mágica 210',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Daño Base de 200 / Proyección Mágica 240',
      },
    },
  },
  {
    id: '19',
    name: 'Zona de detección',
    magicPath: MagicPath.Light,
    level: 48,
    isDaily: true,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: 'RM 180 / 20 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'RM 240 / 80 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 280,
        maintainedZeonCost: 15,
        effect: 'RM 280 / 150 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 360,
        maintainedZeonCost: 15,
        effect: 'RM 340 / 250 metros de rad',
      },
    },
  },
  {
    id: '20',
    name: 'Introducirse en los sueños',
    magicPath: MagicPath.Light,
    level: 50,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM o RP 140 / 10 metros de distancia',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: 'RM o RP 160 / 80 metros de distancia',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'RM o RP 200 / 140 metros de distancia',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: 'RM o RP 240 / 200 metros de distancia',
      },
    },
  },
  {
    id: '21',
    name: 'Cuerpo a luz',
    magicPath: MagicPath.Light,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '+50 en Advertir y Buscar / +20 a sus Resistencias',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '+60 en Advertir y Buscar / +30 a sus Resistencias',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect:
          'el bono será  contra todo efecto sobrenatural que no sea oscuridad',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect:
          'Como arriba, pero no sufre doble daño por ataques basado en oscuridad',
      },
    },
  },
  {
    id: '22',
    name: 'Bendición',
    magicPath: MagicPath.Light,
    level: 56,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: ' +10 a toda acción / +10 resistencias / Radio de 5 metros',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: ' +20 a toda acción / +20 resistencias / Radio de 25 metros',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: ' +20 a toda acción / +30 resistencias / Radio de 50 metros',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: ' +30 a toda acción / +30 resistencias / Radio de 150 metros',
      },
    },
  },
  {
    id: '23',
    name: 'Crear sentimientos positivos',
    magicPath: MagicPath.Light,
    level: 58,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Radio de 20 metros / RM o RP 120.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'Radio de 100 metros / RM o RP 160',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Radio de 250 metros / RM o RP 180.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Radio de 500 metros / RM o RP 220',
      },
    },
  },
  {
    id: '24',
    name: 'Ver realmente',
    magicPath: MagicPath.Light,
    level: 60,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect:
          'Verá magia, matrices psíquicas y seres invisibles / +50 RM contra Ilusiónes.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Como en Base, pero permite ver también seres espirituales',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 180,
        maintainedZeonCost: 15,
        effect: 'Como en Intermedio, pero obtiene un +75 RM contra Ilusiónes.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'Como avanzado, pero obtiene un +100 RM contra Ilusiónes',
      },
    },
  },
  {
    id: '25',
    name: 'Escudar contra lo negativo',
    magicPath: MagicPath.Light,
    level: 62,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM 120 / radio de 20 metros',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM 140 / radio de 100 metros',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM 160 / radio de 250 metros',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'RM 180 / radio de 500 metros',
      },
    },
  },
  {
    id: '26',
    name: 'Encontrar',
    magicPath: MagicPath.Light,
    level: 66,
    isDaily: false,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 160,
        effect: 'RM 140.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: 'RM 180',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 260,
        effect: 'RM 220.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        effect: 'RM 260.',
      },
    },
  },
  {
    id: '27',
    name: 'Restituir',
    magicPath: MagicPath.Light,
    level: 68,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        effect:
          'Elimina un -40 a toda acción y restituye 2 puntos de Cansancio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect:
          'Elimina un -80 a toda acción y restituye 5 puntos de Cansancio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        effect:
          'Elimina un -120 a toda acción y restituye 10 puntos de Cansancio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect:
          'Restituye cualquier penalizador y cantidad de Puntos de Cansancio',
      },
    },
  },
  {
    id: '28',
    name: 'Esquema hipnótico',
    magicPath: MagicPath.Light,
    level: 70,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        maintainedZeonCost: 5,
        effect: 'Visible en 1 kilómetro de radio / RM o RP 120',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'Visible en 5 kilómetros de radio / RM o RP 150',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 280,
        maintainedZeonCost: 10,
        effect: 'Visible en 15 kilómetros de radio / RM o RP 180.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 360,
        maintainedZeonCost: 15,
        effect: 'Visible en 25 kilómetros de radio / RM o RP 220',
      },
    },
  },
  {
    id: '29',
    name: 'Luz catastrófica',
    magicPath: MagicPath.Light,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        effect: 'Daño 120 / 25 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        effect: 'Daño 150 / 100 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        effect: 'Daño 200 / 150 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 350,
        effect: 'Daño 250 / 250 metros de radio.',
      },
    },
  },
  {
    id: '30',
    name: 'Objetos luminosos materiales',
    magicPath: MagicPath.Light,
    level: 76,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Presencia 60 / Calidad +5',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Presencia 100 / Calidad +10',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Presencia 140 / Calidad +10',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Presencia 180 / Calidad +15',
      },
    },
  },
  {
    id: '31',
    name: 'Transmisión por luz',
    magicPath: MagicPath.Light,
    level: 78,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        effect: '100 kilómetros / 250 de presencia / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 360,
        effect: '1.000 kilómetros / 500 de presencia / RM 140',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 450,
        effect: '5.000 kilómetros / 1.000 de presencia / RM 180',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 600,
        effect: '15.000 kilómetros / 2.000 de presencia / RM contra 200',
      },
    },
  },
  {
    id: '32',
    name: 'Señor de los sueños',
    magicPath: MagicPath.Light,
    level: 80,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: 'RM 140.',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        maintainedZeonCost: 65,
        effect: 'RM 150 / En Vigilia, obtiene Gnosis 40. En zona neutra, 30.',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        maintainedZeonCost: 70,
        effect:
          'RM 160 / como intermedio, pero el Gnosis en zonas neutras es de 35.',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 750,
        maintainedZeonCost: 80,
        effect: 'RM 180 / Como Avanzado, sin límites espacial.',
      },
    },
  },
  {
    id: '33',
    name: 'Creación de luz',
    magicPath: MagicPath.Light,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        maintainedZeonCost: 50,
        effect: 'Nivel 1.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        maintainedZeonCost: 70,
        effect: 'Nivel 3',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: 'Nivel 6.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 700,
        maintainedZeonCost: 140,
        effect: 'Nivel 10.',
      },
    },
  },
  {
    id: '34',
    name: 'Prisma reflectante',
    magicPath: MagicPath.Light,
    level: 86,
    isDaily: true,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: '100 contra choques / Tiene 800 puntos de Resistencia',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: '120 contra choques / Tiene 1.500 puntos de Resistencia',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '140 contra choques / Tiene 3.000 puntos de Resistencia',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: '180 contra choques / Tiene 6.000 puntos de Resistencia',
      },
    },
  },
  {
    id: '35',
    name: 'Omnisciencia radial',
    magicPath: MagicPath.Light,
    level: 88,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect: '500 metros de radio / presencia máxima 60.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 50,
        effect: '2 kilómetros de radio / presencia máxima 80',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 400,
        maintainedZeonCost: 60,
        effect: '10 kilómetros de radio / presencia máxima 100',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 800,
        maintainedZeonCost: 65,
        effect: '50 kilómetros de radio / presencia máxima 120',
      },
    },
  },
  {
    id: '36',
    name: 'Predecir',
    magicPath: MagicPath.Light,
    level: 90,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: 'Un año',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        effect: 'Cinco años',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 450,
        effect:
          'Cincuenta años / Si ocurrirá en menos de un día, será muy exacta',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 600,
        effect:
          'Un siglo / Si ocurrirá en menos de un año, será bastante exacta.',
      },
    },
  },
  {
    id: '37',
    name: 'Prisión de luz',
    magicPath: MagicPath.Light,
    level: 92,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect: 'RM 140 / La prisión posee 10.000 puntos de resistencia',
      },
      intermediate: {
        intelligenceRequirement: 16,
        zeonCost: 350,
        maintainedZeonCost: 70,
        effect: 'RM 180 / La prisión posee 250.000 puntos de resistencia',
      },
      advanced: {
        intelligenceRequirement: 18,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: 'RM 220 / La prisión posee 500.000 puntos de resistencia',
      },
      arcane: {
        intelligenceRequirement: 20,
        zeonCost: 800,
        maintainedZeonCost: 160,
        effect:
          'RM 240 / no puede romperse desde adentro, 500.000 puntos de resistencia.',
      },
    },
  },
  {
    id: '38',
    name: 'Esencia de luz',
    magicPath: MagicPath.Light,
    level: 96,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        effect: 'Un día',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        effect: 'Una semana',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 600,
        effect: 'Un mes',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 800,
        effect: 'Un año',
      },
    },
  },
  {
    id: '39',
    name: 'Ascensión',
    magicPath: MagicPath.Light,
    level: 98,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 14,
        zeonCost: 500,
        maintainedZeonCost: 30,
        effect: 'Gnosis máximo 30',
      },
      intermediate: {
        intelligenceRequirement: 16,
        zeonCost: 1000,
        maintainedZeonCost: 40,
        effect: 'Gnosis máximo 35',
      },
      advanced: {
        intelligenceRequirement: 18,
        zeonCost: 2000,
        maintainedZeonCost: 45,
        effect: 'Gnosis máximo 40',
      },
      arcane: {
        intelligenceRequirement: 20,
        zeonCost: 5000,
        maintainedZeonCost: 50,
        effect: 'Gnosis máximo 45.',
      },
    },
  },
  {
    id: '40',
    name: 'Holocausto de luz',
    magicPath: MagicPath.Light,
    level: 100,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 14,
        zeonCost: 600,
        effect: 'Daño 350 / 100 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 16,
        zeonCost: 1000,
        effect: 'Daño 500 / 100 kilómetros de radio',
      },
      advanced: {
        intelligenceRequirement: 18,
        zeonCost: 2500,
        effect: 'Daño 800 / 10.000 kilómetros de radio',
      },
      arcane: {
        intelligenceRequirement: 20,
        zeonCost: 10000,
        effect: 'Daño 1.000 / 1 ua de radio',
      },
    },
  },
  {
    id: '41',
    name: 'Crear oscuridad',
    magicPath: MagicPath.Darkness,
    level: 2,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 20,
        maintainedZeonCost: 5,
        effect: '5 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '25 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '100 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: '500 metros de radio',
      },
    },
  },
  {
    id: '42',
    name: 'Imbuir miedo',
    magicPath: MagicPath.Darkness,
    level: 6,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        effect: 'RM o RP 80 / 10 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: 'RM o RP 100 / 50 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        effect: 'RM o RP 120 / 100 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        effect: 'RM o RP 140 / 250 metros de radio',
      },
    },
  },
  {
    id: '43',
    name: 'Ver en la oscuridad',
    magicPath: MagicPath.Darkness,
    level: 8,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 80',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 100',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 120.',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect:
          'Presencia máxima 140 / Permite ver en la oscuridad sobrenatural',
      },
    },
  },
  {
    id: '44',
    name: 'Escudo oscuro',
    magicPath: MagicPath.Darkness,
    level: 10,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'El escudo tiene 300 puntos de Resistencia',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'El escudo tiene 1.000 puntos de Resistencia',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'El escudo tiene 1.800 puntos de Resistencia',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'El escudo tiene 3.000 puntos de Resistencia',
      },
    },
  },
  {
    id: '45',
    name: 'Sombra',
    magicPath: MagicPath.Darkness,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '+50 en Sigilo, Ocultarse y Valoración Mágica',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '+150 en Sigilo, Ocultarse y Valoración Mágica',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '+200 en Sigilo, Ocultarse y Valoración Mágica',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '+250 en Sigilo, Ocultarse y Valoración Mágica',
      },
    },
  },
  {
    id: '46',
    name: 'Armadura oscura',
    magicPath: MagicPath.Darkness,
    level: 16,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'TA 2 en Energía y TA 1 en el resto',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'TA 5 en Energía y TA 2 en el resto',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'TA 8 en Energía y TA 4 en el resto',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 20,
        effect: 'TA 12 en Energía y TA 6 en el resto',
      },
    },
  },
  {
    id: '47',
    name: 'Destrucción de luz',
    magicPath: MagicPath.Darkness,
    level: 18,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '10 metros de radio / 140 RM.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '100 metros de radio / 180 RM.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '250 metros de radio / 220 RM',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 250,
        maintainedZeonCost: 15,
        effect: '500 metros de radio / 280 RM',
      },
    },
  },
  {
    id: '48',
    name: 'Ocultación de magia',
    magicPath: MagicPath.Darkness,
    level: 20,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '–100 a Valoración Mágica',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '–180 a Valoración Mágica',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '–240 a Valoración Mágica',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '–320 a Valoración Mágica',
      },
    },
  },
  {
    id: '49',
    name: 'Descarga oscura',
    magicPath: MagicPath.Darkness,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        effect: 'Daño 60.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        effect: 'Daño 90.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: 'Daño 120',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        effect: 'Daño 150',
      },
    },
  },
  {
    id: '50',
    name: 'Oscuridad ambiental',
    magicPath: MagicPath.Darkness,
    level: 26,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'Radio de 20 metros',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: 'Radio de 250 metros',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'Radio de 500 metros',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: 'Radio de 1 kilómetro',
      },
    },
  },
  {
    id: '51',
    name: 'Lazos oscuros',
    magicPath: MagicPath.Darkness,
    level: 28,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'Disponen de una Fuerza base 8 para los controles',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Disponen de una Fuerza base 12 para los controles',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect:
          'Disponen de una Fuerza base 15 para los controles y tienen Entereza 30',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 180,
        maintainedZeonCost: 15,
        effect:
          'Disponen de una Fuerza base 18 para los controles y tienen Entereza 35',
      },
    },
  },
  {
    id: '52',
    name: 'Dominio oscuro',
    magicPath: MagicPath.Darkness,
    level: 30,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'Radio de 20 metros / RM 80',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 150,
        maintainedZeonCost: 20,
        effect: 'Radio de 150 metros / RM 140',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'Radio de 300 metros y RM 180',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 350,
        maintainedZeonCost: 30,
        effect: 'Radio de 500 metros / RM 220',
      },
    },
  },
  {
    id: '53',
    name: 'Ocultación',
    magicPath: MagicPath.Darkness,
    level: 32,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '+50 a la RM o RP / +50 Ocultación del Ki',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '+140 a la RM o RP / +150 Ocultación del Ki',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '+220 a la RM o RP / +200 Ocultación del Ki',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '+280 a la RM o RP / +250 Ocultación del Ki',
      },
    },
  },
  {
    id: '54',
    name: 'Ofuscar',
    magicPath: MagicPath.Darkness,
    level: 36,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 100,
        maintainedZeonCost: 20,
        effect: 'Base de 100 en Sigilo, Ocultarse y Ocultación del Ki',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 40,
        effect: 'Base de 150 en Sigilo, Ocultarse y Ocultación del Ki',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 50,
        effect: 'Base de 200 en Sigilo, Ocultarse y Ocultación del Ki',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: 'Base de 250 en Sigilo, Ocultarse y Ocultación del Ki',
      },
    },
  },
  {
    id: '55',
    name: 'Rabia',
    magicPath: MagicPath.Darkness,
    level: 38,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '5 metros de radio / RM 80',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: '20 metros de radio / RM 100',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '50 metros de radio / RM 120',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: '100 metros de radio / RM 140',
      },
    },
  },
  {
    id: '56',
    name: 'Destruir sentimientos positivos',
    magicPath: MagicPath.Darkness,
    level: 40,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        effect: 'RM o RP 100 / 100 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        effect: 'RM o RP 150 / 500 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: 'RM o RP 180 / 1 kilómetro de radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        effect: 'RM o RP 220 / 5 Kilómetros de radio',
      },
    },
  },
  {
    id: '57',
    name: 'Noche',
    magicPath: MagicPath.Darkness,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Radio 25 metros',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'Radio 250 metros',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Radio 500 metros/ La oscuridad se considera sobrenatural',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Radio 1 kilómetro / La oscuridad se considera sobrenatural',
      },
    },
  },
  {
    id: '58',
    name: 'Esfera oscura',
    magicPath: MagicPath.Darkness,
    level: 46,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Daño Base 100 / Proyección Mágica 150.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'Daño Base de 120 / Proyección Mágica 180',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Daño Base de 160 / Proyección Mágica 210',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Daño Base de 200 / Proyección Mágica 240',
      },
    },
  },
  {
    id: '59',
    name: 'Zona de ocultación',
    magicPath: MagicPath.Darkness,
    level: 48,
    isDaily: true,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: '20 metros de radio, RM +100, -140 a Habilidades de detección.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: '50 metros de radio, RM +150, -180 a Habilidades de detección.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: '250 metros de radio, RM +200, -240 a Habilidades de detección',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: '500 metros de radio, RM +300, -320 a Habilidades de detección',
      },
    },
  },
  {
    id: '60',
    name: 'Introducirse en las pesadillas',
    magicPath: MagicPath.Darkness,
    level: 50,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM o RP 140 / 10 metros de distancia',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: 'RM o RP 160 / 80 metros de distancia',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'RM o RP 200 / 140 metros de distancia',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: 'RM o RP 240 / 200 metros de distancia',
      },
    },
  },
  {
    id: '61',
    name: 'Cuerpo a oscuridad',
    magicPath: MagicPath.Darkness,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '+50 en Advertir y Buscar / +20 a sus Resistencias',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '+60 en Advertir y Buscar / +30 a sus Resistencias',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect:
          'Como arriba y el bono sirve contra todo efecto sobrenatural que no sea luz.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect:
          'Como el Avanzado, pero no sufre doble daño por ataques basados en Luz.',
      },
    },
  },
  {
    id: '62',
    name: 'Perdición',
    magicPath: MagicPath.Darkness,
    level: 56,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'RM 120 / Radio de 5 metros.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: 'RM 160 / 25 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'RM 180 / 50 metros de radio / -40 a toda acción',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: 'RM 200 / 150 metros de radio / -50 a toda acción',
      },
    },
  },
  {
    id: '63',
    name: 'Crear sentimientos negativos',
    magicPath: MagicPath.Darkness,
    level: 58,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Radio de 20 metros / RM o RP 120',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'Radio de 100 metros / RM o RP 160',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Radio de 250 metros / RM o RP 180',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Radio de 500 metros / RM o RP 220',
      },
    },
  },
  {
    id: '64',
    name: 'Eliminar residuos',
    magicPath: MagicPath.Darkness,
    level: 60,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        effect: '50 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: '250 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 300,
        effect:
          '1 km de radio / Borra acciones de alguien al ver el pasado de objetos o lugares.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 400,
        effect:
          '5 km de radio / Como Avanzado y puede borrar todo rastro del pasado del lugar.',
      },
    },
  },
  {
    id: '65',
    name: 'Escudar contra lo positivo',
    magicPath: MagicPath.Darkness,
    level: 62,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM 120 / radio de 20 metros',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM 140 / radio de 100 metros',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM 160 / radio de 250 metros',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'RM 180 / radio de 500 metros',
      },
    },
  },
  {
    id: '66',
    name: 'Oscuridad devoradora',
    magicPath: MagicPath.Darkness,
    level: 66,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'Daño del choque 80 y 600 puntos de Resistencia',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: 'Daño del choque 110 y 1200 puntos de Resistencia',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'Daño del choque 140 y 1800 puntos de Resistencia',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: 'Daño del choque 170 y 2200 puntos de Resistencia',
      },
    },
  },
  {
    id: '67',
    name: 'Destrozar',
    magicPath: MagicPath.Darkness,
    level: 68,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 120.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM 160',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM 200',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'RM 240',
      },
    },
  },
  {
    id: '68',
    name: 'Marca del miedo',
    magicPath: MagicPath.Darkness,
    level: 70,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        maintainedZeonCost: 5,
        effect: 'Visible en 1 kilómetro de radio / RM o RP 120',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'Visible en 5 kilómetros de radio / RM o RP 150',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 280,
        maintainedZeonCost: 10,
        effect: 'Visible en 15 kilómetros de radio / RM o RP 180',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 360,
        maintainedZeonCost: 15,
        effect: 'Visible en 25 kilómetros de radio / RM o RP 220',
      },
    },
  },
  {
    id: '69',
    name: 'Oscuridad catastrófica',
    magicPath: MagicPath.Darkness,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        effect: 'Daño 120 / 25 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        effect: 'Daño 150 / 100 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        effect: 'Daño 200 / 150 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 350,
        effect: 'Daño 250 / 250 metros de radio',
      },
    },
  },
  {
    id: '70',
    name: 'Objetos oscuros',
    magicPath: MagicPath.Darkness,
    level: 76,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Presencia 60 / Calidad +5.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Presencia 100 / Calidad +10',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Presencia 140 / Calidad +10',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Presencia 180 / Calidad +15',
      },
    },
  },
  {
    id: '71',
    name: 'Transmisión por sombras',
    magicPath: MagicPath.Darkness,
    level: 78,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        effect: '100 kilómetros / 250 de presencia / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 360,
        effect: '1.000 kilómetros / 500 de presencia / RM 140',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 450,
        effect: '5.000 kilómetros / 1.000 de presencia / RM 180',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 600,
        effect: '15.000 kilómetros / 2.000 de presencia / RM contra 200',
      },
    },
  },
  {
    id: '72',
    name: 'Rey de pesadillas',
    magicPath: MagicPath.Darkness,
    level: 80,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: 'RM 140.',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        maintainedZeonCost: 65,
        effect:
          'RM 150 / En la Vigilia, obtiene Gnosis 40. En zona neutra, 30.',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        maintainedZeonCost: 70,
        effect:
          'RM 160 / Como Intermedio, pero el Gnosis del brujo en zonas neutras es de 35.',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 750,
        maintainedZeonCost: 80,
        effect: 'RM 180 / Como Avanzado, sin límites espacial.',
      },
    },
  },
  {
    id: '73',
    name: 'Creación oscura',
    magicPath: MagicPath.Darkness,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        maintainedZeonCost: 50,
        effect: 'Nivel 1.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        maintainedZeonCost: 70,
        effect: 'Nivel 3.',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: 'Nivel 6.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 700,
        maintainedZeonCost: 140,
        effect: 'Nivel 10.',
      },
    },
  },
  {
    id: '74',
    name: 'Ocultarse ante la magia',
    magicPath: MagicPath.Darkness,
    level: 86,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'Afecta a conjuros Automáticos de Grado Base',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 12,
        effect: 'Afecta a conjuros Automáticos de Grado Intermedio',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 280,
        maintainedZeonCost: 14,
        effect: 'Afecta a conjuros Automáticos de Grado Avanzado',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        maintainedZeonCost: 16,
        effect: 'Afecta a conjuros Automáticos de Grado Arcano',
      },
    },
  },
  {
    id: '75',
    name: 'Reino de tinieblas',
    magicPath: MagicPath.Darkness,
    level: 88,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: '50 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 360,
        maintainedZeonCost: 20,
        effect: '500 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 430,
        maintainedZeonCost: 25,
        effect: '1 kilómetro de radio',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 480,
        maintainedZeonCost: 25,
        effect: '1 kilómetros de radio / +30 al ACT al lanzar conjuros oscuros',
      },
    },
  },
  {
    id: '76',
    name: 'Indetección',
    magicPath: MagicPath.Darkness,
    level: 90,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        maintainedZeonCost: 80,
        effect:
          'El personaje se vuelve indetectable ante conjuros y habilidades psíquicas.',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 450,
        maintainedZeonCost: 90,
        effect:
          'Como en grado Base, pero se vuelve indetectable ante habilidades del Ki.',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 600,
        maintainedZeonCost: 105,
        effect:
          'También es indetectable ante todas las habilidades sobrenaturales.',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 800,
        maintainedZeonCost: 115,
        effect: 'Ignora cualquier detección que no sean los sentidos naturales',
      },
    },
  },
  {
    id: '77',
    name: 'Prisión de oscuridad',
    magicPath: MagicPath.Darkness,
    level: 92,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect: 'RM 140 / La prisión posee 10.000 puntos de resistencia',
      },
      intermediate: {
        intelligenceRequirement: 16,
        zeonCost: 350,
        maintainedZeonCost: 70,
        effect: 'RM 180 / La prisión posee 250.000 puntos de resistencia',
      },
      advanced: {
        intelligenceRequirement: 18,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: 'RM 220 / La prisión posee 500.000 puntos de resistencia',
      },
      arcane: {
        intelligenceRequirement: 20,
        zeonCost: 800,
        maintainedZeonCost: 160,
        effect:
          'RM 240 / no se rompe desde dentro, 500.000 puntos de resistencia.',
      },
    },
  },
  {
    id: '78',
    name: 'Esencia oscura',
    magicPath: MagicPath.Darkness,
    level: 96,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        effect: 'Un día',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        effect: 'Una semana',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 600,
        effect: 'Un mes',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 800,
        effect: 'Un año',
      },
    },
  },
  {
    id: '79',
    name: 'Ascensión oscura',
    magicPath: MagicPath.Darkness,
    level: 98,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 14,
        zeonCost: 500,
        maintainedZeonCost: 30,
        effect: 'Gnosis máximo 30',
      },
      intermediate: {
        intelligenceRequirement: 16,
        zeonCost: 1000,
        maintainedZeonCost: 40,
        effect: 'Gnosis máximo 35',
      },
      advanced: {
        intelligenceRequirement: 18,
        zeonCost: 2000,
        maintainedZeonCost: 45,
        effect: 'Gnosis máximo 40',
      },
      arcane: {
        intelligenceRequirement: 20,
        zeonCost: 5000,
        maintainedZeonCost: 50,
        effect: 'Gnosis máximo 45.',
      },
    },
  },
  {
    id: '80',
    name: 'Holocausto de oscuridad',
    magicPath: MagicPath.Darkness,
    level: 100,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 14,
        zeonCost: 600,
        effect: 'Daño 350 / 100 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 16,
        zeonCost: 1000,
        effect: 'Daño 500 / 100 kilómetros de radio',
      },
      advanced: {
        intelligenceRequirement: 18,
        zeonCost: 2500,
        effect: 'Daño 800 / 10.000 kilómetros de radio',
      },
      arcane: {
        intelligenceRequirement: 20,
        zeonCost: 10000,
        effect: 'Daño 1.000 / 1 ua de radio',
      },
    },
  },
  {
    id: '81',
    name: 'Creación menor',
    magicPath: MagicPath.Creation,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: '1 obj. / pres. max 25',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '5 obj. / pres. max 25.',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '1 obj. / pres max. 30',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '5 obj. / pres. max 25',
      },
    },
  },
  {
    id: '82',
    name: 'Reconstruir',
    magicPath: MagicPath.Creation,
    level: 6,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        effect: 'Pres. max. afectable 20',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 140,
        effect: 'Pres. max. afectable 60',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: 'Pres. max. Afec. 100',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 280,
        effect: 'Pres. max. Afect. 120',
      },
    },
  },
  {
    id: '83',
    name: 'Crear energía',
    magicPath: MagicPath.Creation,
    level: 8,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: '1 intensidad',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '5 intensidades',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '10 intensidades',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: '20 intensidades',
      },
    },
  },
  {
    id: '84',
    name: 'Regeneración',
    magicPath: MagicPath.Creation,
    level: 10,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'Regeneración 4',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Regeneración 8',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Regeneración 12',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'Regeneración 16',
      },
    },
  },
  {
    id: '85',
    name: 'Modificación inorgánica',
    magicPath: MagicPath.Creation,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 20',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 30',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 40',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 50',
      },
    },
  },
  {
    id: '86',
    name: 'Aumentar resistencias',
    magicPath: MagicPath.Creation,
    level: 16,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 15,
        effect: 'Mas 10 a Resistencias',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 20,
        effect: 'Mas 20 a Resistencias',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 30,
        effect: 'Mas 30 a Resistencias',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect: 'Mas 40 a Resistencias',
      },
    },
  },
  {
    id: '87',
    name: 'Escudo real',
    magicPath: MagicPath.Creation,
    level: 18,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: '500 Puntos de Resistencia',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '3.000 Puntos de Resistencia',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 260,
        maintainedZeonCost: 15,
        effect: '5.000 Puntos de Resistencia',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 400,
        maintainedZeonCost: 20,
        effect: '10.000 Puntos de Resistencia',
      },
    },
  },
  {
    id: '88',
    name: 'Curación',
    magicPath: MagicPath.Creation,
    level: 20,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: '50 Puntos de vida.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        effect: '150 Puntos de vida.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        effect: '250 Puntos de vida.',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        effect: '350 Puntos de vida.',
      },
    },
  },
  {
    id: '89',
    name: 'Barrera de daño',
    magicPath: MagicPath.Creation,
    level: 22,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'Barrera de Daño 30.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'Barrera de Daño 50.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Barrera de Daño 80.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Barrera de Daño 100.',
      },
    },
  },
  {
    id: '90',
    name: 'Crear homúnculo',
    magicPath: MagicPath.Creation,
    level: 26,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '1 Homúnculo',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '10 Homúnculos',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: '25 Homúnculos',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: '100 Homúnculos',
      },
    },
  },
  {
    id: '91',
    name: 'Cambio menor',
    magicPath: MagicPath.Creation,
    level: 28,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'RM 80 / Máxima Presencia 60',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'RM 100 / Máxima Presencia 90',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'RM 140 / Máxima Presencia 120',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'RM 180 / Máxima Presencia 180',
      },
    },
  },
  {
    id: '92',
    name: 'Imitar',
    magicPath: MagicPath.Creation,
    level: 30,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 30',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 80',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: 'Presencia máxima 120',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 400,
        maintainedZeonCost: 20,
        effect: 'Presencia máxima 160',
      },
    },
  },
  {
    id: '93',
    name: 'Inmunidad',
    magicPath: MagicPath.Creation,
    level: 32,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '5 Intensidades',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '15 Intensidades',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '25 Intensidades',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '35 Intensidades',
      },
    },
  },
  {
    id: '94',
    name: 'Reducción de daño',
    magicPath: MagicPath.Creation,
    level: 36,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: 'Menos 40 al daño del ataque',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        effect: 'Menos 60 al daño del ataque',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 160,
        effect: 'Menos 80 al daño del ataque',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        effect: 'Menos 120 al daño del ataque',
      },
    },
  },
  {
    id: '95',
    name: 'Control físico',
    magicPath: MagicPath.Creation,
    level: 38,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 24,
        effect: 'RM 80',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 40,
        effect: 'RM 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 50,
        effect: 'RM 140',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '96',
    name: 'Adquirir habilidades',
    magicPath: MagicPath.Creation,
    level: 40,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'Mas 50 al bonificador',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: 'Mas 150 al bonificador',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 280,
        maintainedZeonCost: 15,
        effect: 'Mas 250 al bonificador',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        maintainedZeonCost: 20,
        effect: 'Mas 400 al bonificador',
      },
    },
  },
  {
    id: '97',
    name: 'Fusionar',
    magicPath: MagicPath.Creation,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'Presencia máxima 80 / RM 80',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'Presencia máxima 100 / RM 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Presencia máxima 150 / RM 140',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        maintainedZeonCost: 30,
        effect: 'Presencia máxima 200 / RM 180',
      },
    },
  },
  {
    id: '98',
    name: 'Crear recuerdos',
    magicPath: MagicPath.Creation,
    level: 46,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 140,
        effect: 'RM o RP 100',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        effect: 'RM o RP 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: 'RM o RP 160',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        effect: 'RM o RP 200',
      },
    },
  },
  {
    id: '99',
    name: 'Recuperar',
    magicPath: MagicPath.Creation,
    level: 48,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 250,
        effect: '500 Puntos de vida',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 300,
        effect: '750 Puntos de vida',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        effect: '1.000 Puntos de vida',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        effect: '1.500 Puntos de vida',
      },
    },
  },
  {
    id: '100',
    name: 'Adquirir poderes',
    magicPath: MagicPath.Creation,
    level: 50,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 100,
        maintainedZeonCost: 20,
        effect: '100 PD',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect: '200 PD',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 300,
        maintainedZeonCost: 50,
        effect: '300 PD',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 400,
        maintainedZeonCost: 60,
        effect: '400 PD / Gnosis 30',
      },
    },
  },
  {
    id: '101',
    name: 'Crear engendro',
    magicPath: MagicPath.Creation,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Nivel 2',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Nivel 4',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'Nivel 8',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        maintainedZeonCost: 50,
        effect: 'Nivel 12',
      },
    },
  },
  {
    id: '102',
    name: 'Aura de protección',
    magicPath: MagicPath.Creation,
    level: 56,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'Mas 20 a las Resistencias / 100 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 15,
        effect: 'Mas 50 a las Resistencias / 500 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Mas 80 a las Resistencias / 1 kilometro de radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: 'Mas 120 a las Resistencias / 10 kilometros de radio',
      },
    },
  },
  {
    id: '103',
    name: 'Estancar esencia',
    magicPath: MagicPath.Creation,
    level: 58,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'RM 100',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'RM 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'RM 140',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '104',
    name: 'Escudo perfecto',
    magicPath: MagicPath.Creation,
    level: 60,
    isDaily: true,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Aguanta 100 puntos de daño',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Aguanta 250 puntos de daño',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Aguanta 500 puntos de daño',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: 'Aguanta 1.000 puntos de daño',
      },
    },
  },
  {
    id: '105',
    name: 'Vitalidad',
    magicPath: MagicPath.Creation,
    level: 62,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Mas 50 puntos de vida',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Mas 75 puntos de vida',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'Mas 100 puntos de vida',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: 'Mas 150 puntos de vida',
      },
    },
  },
  {
    id: '106',
    name: 'Creación completa',
    magicPath: MagicPath.Creation,
    level: 66,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Presencia máxima 50',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Presencia máxima 80',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Presencia máxima 120',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: 'Presencia máxima 150',
      },
    },
  },
  {
    id: '107',
    name: 'Potenciar magia',
    magicPath: MagicPath.Creation,
    level: 68,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Afecta la los conjuros de Grado Base',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Afecta la los conjuros de Grado Intermedio',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Afecta la los conjuros de Grado Avanzado',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: 'Afecta la los conjuros de Grado Arcano',
      },
    },
  },
  {
    id: '108',
    name: 'Transmutar',
    magicPath: MagicPath.Creation,
    level: 70,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 250,
        effect: 'RM 120 / Presencia Máxima 50',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        effect: 'RM 160 / Presencia Máxima 100',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        effect: 'RM 220 / Presencia Máxima 150',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 800,
        effect: 'RM 260 / Presencia Máxima 200',
      },
    },
  },
  {
    id: '109',
    name: 'Metamorfismo',
    magicPath: MagicPath.Creation,
    level: 72,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: 'RM 100',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'RM 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 15,
        effect: 'RM 160',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 350,
        maintainedZeonCost: 20,
        effect: 'RM 200',
      },
    },
  },
  {
    id: '110',
    name: 'Recrear',
    magicPath: MagicPath.Creation,
    level: 76,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 300,
        effect: 'Presencia máxima 60',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 500,
        effect: 'Presencia máxima 120',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 750,
        effect: 'Presencia máxima 180',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1500,
        effect: 'Presencia máxima 240',
      },
    },
  },
  {
    id: '111',
    name: 'Crear ser',
    magicPath: MagicPath.Creation,
    level: 78,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 250,
        maintainedZeonCost: 50,
        effect: 'Nivel 1',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 400,
        maintainedZeonCost: 80,
        effect: 'Nivel 5',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 600,
        maintainedZeonCost: 120,
        effect: 'Nivel 9',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 1000,
        maintainedZeonCost: 200,
        effect: 'Nivel 12',
      },
    },
  },
  {
    id: '112',
    name: 'Quimera',
    magicPath: MagicPath.Creation,
    level: 80,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        effect: 'Má 100 PD / hasta 100 PD opcionales en desventajas',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 500,
        effect: 'Má 200 PD / hasta 100 PD opcionales en desventajas',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 1000,
        effect: 'Má 300 PD / hasta 200 PD opcionales en desventajas',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 2500,
        effect: 'Má 400 PD / hasta 200 PD opcionales en desventajas',
      },
    },
  },
  {
    id: '113',
    name: 'Zona de salvaguardia',
    magicPath: MagicPath.Creation,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: 'RM 140 / radio de 100 metros',
      },
      intermediate: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        maintainedZeonCost: 50,
        effect: 'RM 180 / radio de 500 metros',
      },
      advanced: {
        intelligenceRequirement: 17,
        zeonCost: 800,
        maintainedZeonCost: 80,
        effect: 'RM 220 / radio de 1 kilómetro',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1500,
        maintainedZeonCost: 150,
        effect: 'RM 250 / radio de 5 kilómetros',
      },
    },
  },
  {
    id: '114',
    name: 'Mantenimiento',
    magicPath: MagicPath.Creation,
    level: 86,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        effect: '500 Puntos de Zeon',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 500,
        effect: '2.000 Puntos de Zeon',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 900,
        effect: '5.000 Puntos de Zeon',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 1600,
        effect: '10.000 Puntos de Zeon',
      },
    },
  },
  {
    id: '115',
    name: 'Otorgar alma',
    magicPath: MagicPath.Creation,
    level: 88,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 13,
        zeonCost: 500,
        effect: 'Presencia máxima 30',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 800,
        effect: 'Presencia máxima 50',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 1200,
        effect: 'Presencia máxima 80',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 2000,
        effect: 'Presencia máxima 100',
      },
    },
  },
  {
    id: '116',
    name: 'Creación mayor',
    magicPath: MagicPath.Creation,
    level: 90,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 400,
        maintainedZeonCost: 20,
        effect: '500 Puntos de Presencia / Presencia máxima 100',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 800,
        maintainedZeonCost: 25,
        effect: '1.000 Puntos de Presencia / Presencia máxima 120',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 1200,
        maintainedZeonCost: 25,
        effect: '2.000 Puntos de Presencia / Presencia máxima 140',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 2000,
        maintainedZeonCost: 30,
        effect: '3.000 Puntos de Presencia / Presencia máxima 180',
      },
    },
  },
  {
    id: '117',
    name: 'Magia eterna',
    magicPath: MagicPath.Creation,
    level: 92,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 600,
        effect: 'Afecta a un conjuro de Grado Base',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 1000,
        effect: 'Afecta a un conjuro de Grado Intermedio',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 2500,
        effect: 'Afecta a un conjuro de Grado Avanzado',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 5000,
        effect: 'Afecta a un conjuro de Grado Arcano',
      },
    },
  },
  {
    id: '118',
    name: 'La barrera',
    magicPath: MagicPath.Creation,
    level: 96,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 15,
        zeonCost: 800,
        maintainedZeonCost: 40,
        effect: 'RM 120 / 100 kilómetros cuadrados o línea',
      },
      intermediate: {
        intelligenceRequirement: 16,
        zeonCost: 2500,
        maintainedZeonCost: 45,
        effect: 'RM 180 / 1.000 kilómetros cuadrados o línea',
      },
      advanced: {
        intelligenceRequirement: 17,
        zeonCost: 5000,
        maintainedZeonCost: 45,
        effect: 'RM 240 / 100.000 kilómetros cuadrados o línea',
      },
      arcane: {
        intelligenceRequirement: 19,
        zeonCost: 10000,
        maintainedZeonCost: 50,
        effect: 'RM 300 / Sin límite de espacio',
      },
    },
  },
  {
    id: '119',
    name: 'Crear la vida',
    magicPath: MagicPath.Creation,
    level: 98,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 16,
        zeonCost: 800,
        effect: 'Nivel 1 y 50 PD en el caso de que se trate de un ser natural',
      },
      intermediate: {
        intelligenceRequirement: 17,
        zeonCost: 2000,
        effect: 'Nivel 6 y 100 PD en el caso de que se trate de un ser natural',
      },
      advanced: {
        intelligenceRequirement: 18,
        zeonCost: 4000,
        effect:
          'Nivel 11 y 150 PD en el caso de que se trate de un ser natural',
      },
      arcane: {
        intelligenceRequirement: 19,
        zeonCost: 8000,
        effect:
          'Nivel 16 y 200 PD en el caso de que se trate de un ser natural',
      },
    },
  },
  {
    id: '120',
    name: 'Crear',
    magicPath: MagicPath.Creation,
    level: 100,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 17,
        zeonCost: 1000,
        effect:
          '1.000 Puntos de presencia / Presencia máxima 180 / 1 regla existencial',
      },
      intermediate: {
        intelligenceRequirement: 18,
        zeonCost: 3000,
        effect:
          '10.000 Puntos de presencia / Presencia máxima 220 / 5 reglas existenciales',
      },
      advanced: {
        intelligenceRequirement: 19,
        zeonCost: 6000,
        effect:
          '10..000 Puntos de presencia / Presencia máxima 260 / 10 reglas existenciales',
      },
      arcane: {
        intelligenceRequirement: 20,
        zeonCost: 12000,
        effect:
          '100.000 de presencia / Presencia máxima 320 / Sin límite reglas existenciales.',
      },
    },
  },
  {
    id: '121',
    name: 'Fragilidad',
    magicPath: MagicPath.Destruction,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: '-2 a su entereza / Presencia máxima afectable 30',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '-4 a su entereza / Presencia máxima afectable 60',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '-8 a su entereza / Presencia máxima afectable 90',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '-12 a su entereza / Presencia máxima afectable 120',
      },
    },
  },
  {
    id: '122',
    name: 'Desmantelar',
    magicPath: MagicPath.Destruction,
    level: 6,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        effect: 'Presencia máxima 20',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: 'Presencia máxima 40',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        effect: 'Presencia máxima 60',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        effect: 'Presencia máxima 80',
      },
    },
  },
  {
    id: '123',
    name: 'Destruir intensidades',
    magicPath: MagicPath.Destruction,
    level: 8,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        effect: '1 intensidad / 100 RM',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: '5 intensidades / 120 RM',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        effect: '10 intensidades / 140 RM',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        effect: '15 intensidades / 160 RM',
      },
    },
  },
  {
    id: '124',
    name: 'Destrucción menor',
    magicPath: MagicPath.Destruction,
    level: 10,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        effect: 'Presencia máxima 20',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        effect: 'Presencia máxima 40',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 140,
        effect: 'Presencia máxima 60',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 180,
        effect: 'Presencia máxima 80',
      },
    },
  },
  {
    id: '125',
    name: 'Esfera de destrucción',
    magicPath: MagicPath.Destruction,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        effect: 'Un ataque',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        effect: 'Tres ataques',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: 'Cinco ataques',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 150,
        effect: 'Siete ataques',
      },
    },
  },
  {
    id: '126',
    name: 'Incrementar debilidad',
    magicPath: MagicPath.Destruction,
    level: 16,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '120 RM',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '140 RM',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '160 RM',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '200 RM',
      },
    },
  },
  {
    id: '127',
    name: 'Destrucción de magia',
    magicPath: MagicPath.Destruction,
    level: 18,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        effect: 'Valor zeónico 50',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 150,
        effect: 'Valor zeónico 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        effect: 'Valor zeónico 200',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        effect: 'Valor zeónico 350',
      },
    },
  },
  {
    id: '128',
    name: 'Agravar daño',
    magicPath: MagicPath.Destruction,
    level: 20,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        effect: 'Daño +30',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        effect: 'Daño +50',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        effect: 'Daño +90',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 220,
        effect: 'Daño +120',
      },
    },
  },
  {
    id: '129',
    name: 'Destrucción de matrices',
    magicPath: MagicPath.Destruction,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        effect: 'Dificultad Media (80)',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        effect: 'Dificultad Muy Difícil (140)',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: 'Dificultad Casi Imposible (240)',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        effect: 'Dificultad Inhumana (320)',
      },
    },
  },
  {
    id: '130',
    name: 'Herir',
    magicPath: MagicPath.Destruction,
    level: 26,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        effect: '20% de Puntos de Vida / 120 RM',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        effect: '40% de Puntos de Vida / 140 RM',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        effect: '60% de Puntos de Vida / 160 RM',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: '80% de Puntos de Vida / 200 RM',
      },
    },
  },
  {
    id: '131',
    name: 'Destrucción de ki',
    magicPath: MagicPath.Destruction,
    level: 28,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        effect: '140 RM',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        effect: '160 RM',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 220,
        effect: '180 RM',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: '220 RM',
      },
    },
  },
  {
    id: '132',
    name: 'Producir daño',
    magicPath: MagicPath.Destruction,
    level: 30,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        effect: '50 puntos de daño / 120 RM',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        effect: '100 puntos de daño / 140 RM',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        effect: '180 puntos de daño / 160 RM',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: '250 puntos de daño / 200 RM',
      },
    },
  },
  {
    id: '133',
    name: 'Destrucción de sentidos',
    magicPath: MagicPath.Destruction,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: '100 RM',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: '120 RM',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: '140 RM',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 280,
        maintainedZeonCost: 15,
        effect: '180 RM',
      },
    },
  },
  {
    id: '134',
    name: 'Descarga',
    magicPath: MagicPath.Destruction,
    level: 36,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        effect: 'Daño 100',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        effect: 'Daño 150',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 220,
        effect: 'Daño 200',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: 'Daño 250',
      },
    },
  },
  {
    id: '135',
    name: 'Desatar vínculos',
    magicPath: MagicPath.Destruction,
    level: 38,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        effect: '120 RM',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: '140 RM',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 300,
        effect: '160 RM',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        effect: '200 RM',
      },
    },
  },
  {
    id: '136',
    name: 'Destruir resistencias',
    magicPath: MagicPath.Destruction,
    level: 40,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '120 RM',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: '160 RM',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '200 RM',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '240 RM',
      },
    },
  },
  {
    id: '137',
    name: 'Deshacer estados',
    magicPath: MagicPath.Destruction,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 120,
        effect: '120 RM / Presencia máxima 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        effect: '140 RM / Presencia máxima 200',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: '160 RM / Presencia máxima 300',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: '200 RM / Presencia máxima 400',
      },
    },
  },
  {
    id: '138',
    name: 'Cúpula de destrucción',
    magicPath: MagicPath.Destruction,
    level: 46,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 100,
        effect: '10 metros de radio / Daño 80',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        effect: '50 metros de radio / Daño 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: '100 metros de radio / Daño 160',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: '150 metros de radio / Daño 200',
      },
    },
  },
  {
    id: '139',
    name: 'Área de decaimiento',
    magicPath: MagicPath.Destruction,
    level: 48,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '10 metros de radio / RM 100',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '30 metros de radio / RM 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '60 metros de radio / RM 160',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '100 metros de radio / RM 200',
      },
    },
  },
  {
    id: '140',
    name: 'Aura de destrucción',
    magicPath: MagicPath.Destruction,
    level: 50,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'RM 80 / Presencia máxima 60 / 1 metro de diámetro',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'RM 100 / Presencia máxima 90 / 5 metros de diámetro',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'RM 120 / Presencia máxima 120 / 15 metros de diámetro',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        maintainedZeonCost: 30,
        effect: 'RM 150 / Presencia máxima 150 / 25 metros de diámetro',
      },
    },
  },
  {
    id: '141',
    name: 'Destruir recuerdos',
    magicPath: MagicPath.Destruction,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 140,
        effect: 'RM o RP 100',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        effect: 'RM o RP 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: 'RM o RP 160',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: 'RM o RP 200',
      },
    },
  },
  {
    id: '142',
    name: 'Bloquear aprendizaje',
    magicPath: MagicPath.Destruction,
    level: 56,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 15,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM 160',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 25,
        effect: 'RM 200',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        maintainedZeonCost: 30,
        effect: 'RM 240',
      },
    },
  },
  {
    id: '143',
    name: 'Negar',
    magicPath: MagicPath.Destruction,
    level: 58,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 15,
        effect: 'RM 160',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM 200',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'RM 240',
      },
    },
  },
  {
    id: '144',
    name: 'Destruir poderes',
    magicPath: MagicPath.Destruction,
    level: 60,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM 180',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'RM 220',
      },
    },
  },
  {
    id: '145',
    name: 'Descarga mayor',
    magicPath: MagicPath.Destruction,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        effect: 'Daño 150',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 300,
        effect: 'Daño 300',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 450,
        effect: 'Daño 450',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 600,
        effect: 'Daño 500',
      },
    },
  },
  {
    id: '146',
    name: 'Destruir la voluntad',
    magicPath: MagicPath.Destruction,
    level: 66,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'RM 120 / 10 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'RM 140 / 25 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM 160 / 50 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 280,
        maintainedZeonCost: 30,
        effect: 'RM 180 / 100 metros de radio',
      },
    },
  },
  {
    id: '147',
    name: 'Zona de debilidad',
    magicPath: MagicPath.Destruction,
    level: 68,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '25 metros de radio / RM 140',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '100 metros de radio / RM 160',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: '250 metros de radio / RM 180',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        maintainedZeonCost: 50,
        effect: '500 metros de radio / RM 200',
      },
    },
  },
  {
    id: '148',
    name: 'Esencia de destrucción',
    magicPath: MagicPath.Destruction,
    level: 70,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Daño equivalnente al nivel de fracaso',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect:
          'Daño y negativo a toda acción equivalentes al nivel de fracaso',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect:
          'Daño x 2 de nivel de fracaso / Negativo a acción igual a nivel de fracaso',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect:
          'Daño y negativo a toda acción equivalentes al doble del nivel de fracaso',
      },
    },
  },
  {
    id: '149',
    name: 'Muerte',
    magicPath: MagicPath.Destruction,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: 'RM o RF 120',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        effect: 'RM o RF 140',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 300,
        effect: 'RM o RF 160',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 350,
        effect: 'RM o RF 180',
      },
    },
  },
  {
    id: '150',
    name: 'Zona devoradora',
    magicPath: MagicPath.Destruction,
    level: 76,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'RM o RF 140 y 500 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 360,
        maintainedZeonCost: 40,
        effect: 'RM o RF 195 y 6000 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 450,
        maintainedZeonCost: 45,
        effect: 'RM o RF 240 y 10500 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 600,
        maintainedZeonCost: 55,
        effect: 'RM o RF 270 y 13500 metros de radio',
      },
    },
  },
  {
    id: '151',
    name: 'Destruir capacidades',
    magicPath: MagicPath.Destruction,
    level: 78,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        effect: '-50 PD, RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        effect: '-100 PD, RM 160',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        effect: '-150 PD, RM 200',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        effect: '-200 PD, RM 240',
      },
    },
  },
  {
    id: '152',
    name: 'Sesgar la existencia',
    magicPath: MagicPath.Destruction,
    level: 80,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        effect: 'RM 120 / 10 metros de línea',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 500,
        effect: 'RM 160 / 100 metros de línea',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 600,
        effect: 'RM 200 / 250 metros de línea',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 800,
        effect: 'RM 240 / 1 kilómetro metros de línea',
      },
    },
  },
  {
    id: '153',
    name: 'Lluvia de destrucción',
    magicPath: MagicPath.Destruction,
    level: 82,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        effect: 'Daño 200 / RM 140 / 50 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 350,
        effect: 'Daño 250 / RM 180 / 150 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 450,
        effect: 'Daño 300 / RM 220 / 500 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 600,
        effect: 'Daño 400 / RM 260 / 1 kilómetro de radio',
      },
    },
  },
  {
    id: '154',
    name: 'Destrucción de zeon',
    magicPath: MagicPath.Destruction,
    level: 86,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect:
          '-50 al Zeon del Conjuro / Sólo afecta a conjuros de grado base',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 400,
        effect:
          '-150 al Zeon del Conjuro/ Afecta a conjuros hasta grado Intermedio',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 600,
        effect:
          '-250 al Zeon del Conjuro/ Afecta a conjuros hasta grado Avanzado',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 700,
        effect:
          '-350 al Zeon del Conjuro/ Afecta a conjuros hasta grado arcano',
      },
    },
  },
  {
    id: '155',
    name: 'Arrojar de los cielos',
    magicPath: MagicPath.Destruction,
    level: 88,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: '-5 a su Gnosis, RM 120',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 600,
        maintainedZeonCost: 30,
        effect: '-10 a su Gnosis, RM 160',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 1000,
        maintainedZeonCost: 50,
        effect: '-15 a su Gnosis, RM 200',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 2000,
        maintainedZeonCost: 100,
        effect: '-20 a su Gnosis, RM 260',
      },
    },
  },
  {
    id: '156',
    name: 'Vacío',
    magicPath: MagicPath.Destruction,
    level: 90,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: '5 metros de radio / 50 metros de succión / 120 de RM y RF',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 350,
        maintainedZeonCost: 40,
        effect: '15 metros de radio / 500 metros de succión / 160 de RM y RF',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        maintainedZeonCost: 45,
        effect: '25 metros de radio / 1 kilómetro de succión / 200 de RM y RF',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 800,
        maintainedZeonCost: 55,
        effect: '50 metros de radio / 3 kilómetros de succión / 240 de RM y RF',
      },
    },
  },
  {
    id: '157',
    name: 'Destrucción mayor',
    magicPath: MagicPath.Destruction,
    level: 92,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        effect: 'Presencia máxima 100',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 600,
        effect: 'Presencia máxima 160',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 900,
        effect: 'Presencia máxima 200',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1500,
        effect: 'Presencia máxima 240',
      },
    },
  },
  {
    id: '158',
    name: 'Destruir almas',
    magicPath: MagicPath.Destruction,
    level: 96,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 13,
        zeonCost: 500,
        effect: 'RM 100 / 5 kilómetros de radio',
      },
      intermediate: {
        intelligenceRequirement: 15,
        zeonCost: 800,
        effect: 'RM 140 /50 kilómetros de radio',
      },
      advanced: {
        intelligenceRequirement: 17,
        zeonCost: 1500,
        effect: 'RM 180 / 250 kilómetros de radio',
      },
      arcane: {
        intelligenceRequirement: 19,
        zeonCost: 2500,
        effect: 'RM 220 / 1.000 kilómetros de radio',
      },
    },
  },
  {
    id: '159',
    name: 'Caos',
    magicPath: MagicPath.Destruction,
    level: 98,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 14,
        zeonCost: 700,
        maintainedZeonCost: 70,
        effect: '100 kilómetros de radio / Afecta hasta Gnosis 10',
      },
      intermediate: {
        intelligenceRequirement: 16,
        zeonCost: 1200,
        maintainedZeonCost: 80,
        effect: '1.000 kilómetros de radio / Afecta hasta Gnosis 20',
      },
      advanced: {
        intelligenceRequirement: 18,
        zeonCost: 2000,
        maintainedZeonCost: 90,
        effect: '10.000 kilómetros de radio / Afecta hasta Gnosis 30',
      },
      arcane: {
        intelligenceRequirement: 20,
        zeonCost: 5000,
        maintainedZeonCost: 100,
        effect: 'Afecta a toda la creación / Afecta hasta Gnosis 40',
      },
    },
  },
  {
    id: '160',
    name: 'Descrear',
    magicPath: MagicPath.Destruction,
    level: 100,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 17,
        zeonCost: 1000,
        effect: 'RM 140',
      },
      intermediate: {
        intelligenceRequirement: 18,
        zeonCost: 2500,
        effect: 'RM 160',
      },
      advanced: {
        intelligenceRequirement: 19,
        zeonCost: 5000,
        effect: 'RM 200',
      },
      arcane: {
        intelligenceRequirement: 20,
        zeonCost: 10000,
        effect: 'RM 240',
      },
    },
  },
  {
    id: '161',
    name: 'Crear viento',
    magicPath: MagicPath.Air,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: '20 Km/h / 25 metros de anchura',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '40 Km/h / 50 metros de anchura',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: '80 Km/h / 75 metros de anchura',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '100 Km/h / 100 metros de anchura',
      },
    },
  },
  {
    id: '162',
    name: 'Mover',
    magicPath: MagicPath.Air,
    level: 6,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: 'Peso maximo 10 Kg',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'Peso maximo 50 Kg ',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'Peso maximo 100 Kg ',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Peso maximo 250 Kg',
      },
    },
  },
  {
    id: '163',
    name: 'Reducir peso',
    magicPath: MagicPath.Air,
    level: 10,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        maintainedZeonCost: 2,
        effect: '-20 Kg',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '-150 Kg',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '-300  Kg',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: '-500 Kg',
      },
    },
  },
  {
    id: '164',
    name: 'No respirar',
    magicPath: MagicPath.Air,
    level: 12,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: 'Presencia maxima 80',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Presencia maxima 150',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 110,
        maintainedZeonCost: 10,
        effect: 'Presencia maxima 200',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Presencia maxima 350',
      },
    },
  },
  {
    id: '165',
    name: 'Movimiento libre',
    magicPath: MagicPath.Air,
    level: 16,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'Presencia maxima 80',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Presencia maxima 120',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 110,
        maintainedZeonCost: 10,
        effect: 'presencia maxima 160',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Presencia maxima 240',
      },
    },
  },
  {
    id: '166',
    name: 'Golpe de aire',
    magicPath: MagicPath.Air,
    level: 20,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        effect: '5 metros de anchura / fuerza 6',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        effect: '20 metros de anchura / fuerza 9',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 140,
        effect: '30 metros de anchura / fuerza 12',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 200,
        effect: '50 metros de anchura / fuerza 14',
      },
    },
  },
  {
    id: '167',
    name: 'Pantalla de aire',
    magicPath: MagicPath.Air,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '300 puntos de resistencia',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: '1500 puntos de resistencia',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '2000 puntos de resistencia',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '3500 puntos de resistencia',
      },
    },
  },
  {
    id: '168',
    name: 'Transporte automático',
    magicPath: MagicPath.Air,
    level: 26,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        effect: '50 metros / presencia maxima 60',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: '250 metros / presencia maxima 90',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        effect: '400 metros / presencia maxima 120',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        effect: '1 kilometro / presencia maxima 150',
      },
    },
  },
  {
    id: '169',
    name: 'Vuelo',
    magicPath: MagicPath.Air,
    level: 30,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 15,
        effect: 'tipo vuelo 4',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 20,
        effect: 'tipo vuelo 8',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 25,
        effect: 'tipo vuelo 12',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 240,
        maintainedZeonCost: 30,
        effect: 'tipo vuelo 15',
      },
    },
  },
  {
    id: '170',
    name: 'Incremento de reacción',
    magicPath: MagicPath.Air,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: '+30 al turno',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect: '+60 al turno',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '+90 al turno',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '+120 al turno',
      },
    },
  },
  {
    id: '171',
    name: 'Electrificar',
    magicPath: MagicPath.Air,
    level: 36,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RF 100 / Presencia máxima 30 / 1 metro de longitud',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RF 120 / Presencia máxima 40 / 3 metros de longitud',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'RF 140 / Presencia máxima 60 / 5 metros de longitud',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RF 160 / Presencia máxima 80 / 10 metros de longitud',
      },
    },
  },
  {
    id: '172',
    name: 'Tajo de aire',
    magicPath: MagicPath.Air,
    level: 40,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        effect: 'linea de 3 metros de longitud',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 150,
        effect: 'linea de 12 metros de lingitud',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: 'linea de 25 metros de longitud',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        effect: 'linea de 50 metros de longitud',
      },
    },
  },
  {
    id: '173',
    name: 'Velocidad',
    magicPath: MagicPath.Air,
    level: 42,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'presencia maxima 50',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'presencia maxima 80',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'presencia maxima 120',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'presencia maxima 160',
      },
    },
  },
  {
    id: '174',
    name: 'Rayo',
    magicPath: MagicPath.Air,
    level: 46,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        effect: '1 rebote o +10 al daño',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        effect: '10 rebotes o +40 al daño',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 280,
        effect: '15 rebotes o +80 al daño',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 400,
        effect: '25 rebotes o +150 al daño',
      },
    },
  },
  {
    id: '175',
    name: 'Remolino',
    magicPath: MagicPath.Air,
    level: 50,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 140,
        maintainedZeonCost: 30,
        effect: '3 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 40,
        effect: '6 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 50,
        effect: '12 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: '25 metros de radio',
      },
    },
  },
  {
    id: '176',
    name: 'Forma etérea',
    magicPath: MagicPath.Air,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Las habilidades descritas',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect:
          'Se puede mover por el aire a velocidadigual a Tipo de movimiento natural',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect:
          'Idem arriba, Si ve magia superar Advertir a 140 o Buscar a 80 para verle',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect:
          'Idem arriba, ataques de FIL o PEN basados en ENE le hacen mitad de daño',
      },
    },
  },
  {
    id: '177',
    name: 'Control del aire',
    magicPath: MagicPath.Air,
    level: 56,
    isDaily: false,
    activationType: [SpellActivationType.Effect, SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'radio de 50 metros / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 150,
        maintainedZeonCost: 20,
        effect: 'radio de 300 metros / RM 140',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'radio de 500 metros / RM 180',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: 'radio de 1 kilometro / RM 220',
      },
    },
  },
  {
    id: '178',
    name: 'Control de la electricidad',
    magicPath: MagicPath.Air,
    level: 60,
    isDaily: false,
    activationType: [SpellActivationType.Effect, SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RM 120 / 5 intensidades',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 150,
        maintainedZeonCost: 20,
        effect: 'RM 160 / 15 intensidades',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM 180 / 25 intensidades',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: 'RM 220 / 40 intensidades',
      },
    },
  },
  {
    id: '179',
    name: 'Movimiento defensivo',
    magicPath: MagicPath.Air,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '3 esquivas / tipo de movimiento 8',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '9 esquivas / tipo de movimiento 12',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '15 esquivas / tipo de movimiento 16',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'sin limite de esquivas / tipo de movimiento 18',
      },
    },
  },
  {
    id: '180',
    name: 'Teletransportación',
    magicPath: MagicPath.Air,
    level: 66,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 180,
        effect: 'presencia maxima 80 / 10 kilometros',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 300,
        effect: 'presencia maxima 150 / 10000 kilometros',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 450,
        effect: 'presencia maxima 240 / 100000 kilometros',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 600,
        effect: 'presencia maxima 350 / cualquier distancia',
      },
    },
  },
  {
    id: '181',
    name: 'Inmaterialidad',
    magicPath: MagicPath.Air,
    level: 70,
    isDaily: true,
    activationType: [SpellActivationType.Effect, SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 100 / presencia maxima 80',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM 140 / presencia maxima 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM 160 / presencia maxima 160',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        maintainedZeonCost: 30,
        effect: 'RM 200 / presencia maxima 200',
      },
    },
  },
  {
    id: '182',
    name: 'Huracán',
    magicPath: MagicPath.Air,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Animic, SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: '500 metros de radio  ',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: '1 kilometros de radio',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 450,
        maintainedZeonCost: 20,
        effect: '2 kilometros de radio',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 600,
        maintainedZeonCost: 25,
        effect: '5 kilometros de radio / el control de fuerza es contra 14',
      },
    },
  },
  {
    id: '183',
    name: 'Aire sólido',
    magicPath: MagicPath.Air,
    level: 76,
    isDaily: false,
    activationType: [SpellActivationType.Effect, SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: '25 metros de radio  ',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: '150 metros de radio  ',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 260,
        maintainedZeonCost: 15,
        effect: '300 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 350,
        maintainedZeonCost: 25,
        effect: '500 metros de radio / si se usa para apresar, tiene fuerza 16',
      },
    },
  },
  {
    id: '184',
    name: 'Control del clima',
    magicPath: MagicPath.Air,
    level: 80,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 250,
        maintainedZeonCost: 50,
        effect: '5 kilometros',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: '25 kilometros',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 380,
        maintainedZeonCost: 80,
        effect: '100 kilometros',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: '1000 kilometros',
      },
    },
  },
  {
    id: '185',
    name: 'Crear silfo',
    magicPath: MagicPath.Air,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        maintainedZeonCost: 50,
        effect: 'Nivel 1.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        maintainedZeonCost: 70,
        effect: 'Nivel 3',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: 'Nivel 6.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 700,
        maintainedZeonCost: 140,
        effect: 'Nivel 10.',
      },
    },
  },
  {
    id: '186',
    name: 'Telequinesis superior',
    magicPath: MagicPath.Air,
    level: 86,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 160,
        maintainedZeonCost: 35,
        effect: 'RM 100 / 100 toneladas',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 280,
        maintainedZeonCost: 40,
        effect: 'RM 120 / 10000 toneladas',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        maintainedZeonCost: 50,
        effect: 'RM 140 / 250000 toneladas',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 550,
        maintainedZeonCost: 60,
        effect: 'RM 160 / 500000 toneladas',
      },
    },
  },
  {
    id: '187',
    name: 'Ubicar magia',
    magicPath: MagicPath.Air,
    level: 90,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'conjuros de hasta 100 puntos de zeon',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 270,
        maintainedZeonCost: 30,
        effect: 'conjuros de hasta 200 puntos de zeon',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 360,
        maintainedZeonCost: 40,
        effect: 'conjuros de hasta 300 puntos de zeon',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 450,
        maintainedZeonCost: 45,
        effect: 'conjuros de hasta 400 puntos de zeon',
      },
    },
  },
  {
    id: '188',
    name: 'Magia pasiva',
    magicPath: MagicPath.Air,
    level: 92,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Afecta a conjuros de grado base',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: 'Afecta a conjuros Automáticos de Grado Intermedio',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 550,
        maintainedZeonCost: 55,
        effect: 'Afecta a conjuros Automáticos de Grado Avanzado',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 800,
        maintainedZeonCost: 80,
        effect: 'Afecta a conjuros Automáticos de Grado Arcano',
      },
    },
  },
  {
    id: '189',
    name: 'Señor del aire',
    magicPath: MagicPath.Air,
    level: 96,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '100 kilometros de radio / 140 RM',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 450,
        maintainedZeonCost: 45,
        effect: '1000 kilometros de radio / 180 RM',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: '10000 kilometros de radio / 200 RM',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 1000,
        maintainedZeonCost: 100,
        effect: '100000 kilometros de radio / 240 RM',
      },
    },
  },
  {
    id: '190',
    name: 'Un lugar en el mundo',
    magicPath: MagicPath.Air,
    level: 100,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 450,
        maintainedZeonCost: 45,
        effect: '50 kilometros de radio / RM 140',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 800,
        maintainedZeonCost: 80,
        effect: '250 kilometros de radio / RM 180',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 1200,
        maintainedZeonCost: 120,
        effect: '500 kilometros de radio / RM 240',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1600,
        maintainedZeonCost: 200,
        effect: '1000 kilometros de radio / RM 280',
      },
    },
  },
  {
    id: '191',
    name: 'Manantial',
    magicPath: MagicPath.Water,
    level: 2,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: '100 metros.',
      },
      intermediate: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '250 metros.',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '500 metros.',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '1 kilómetro.',
      },
    },
  },
  {
    id: '192',
    name: 'Crear frío',
    magicPath: MagicPath.Water,
    level: 6,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: '1 Intensidad.',
      },
      intermediate: {
        intelligenceRequirement: 7,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '3 Intensidades.',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: '5 Intensidades.',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '8 Intensidades.',
      },
    },
  },
  {
    id: '193',
    name: 'Capacidad acuática',
    magicPath: MagicPath.Water,
    level: 10,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 50,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 50.',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 70,
        maintainedZeonCost: 20,
        effect: 'Presencia máxima 100.',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 20,
        effect: 'Presencia máxima 200.',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 25,
        effect: 'Presencia máxima 350.',
      },
    },
  },
  {
    id: '194',
    name: 'Inmunidad al frío',
    magicPath: MagicPath.Water,
    level: 12,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '5 intensidades de frío.',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: '12 intensidades de frío.',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: '20 intensidades de frío.',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: '30 intensidades de frío.',
      },
    },
  },
  {
    id: '195',
    name: 'Burbuja protectora',
    magicPath: MagicPath.Water,
    level: 16,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: 'Hasta Daño base 40.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect: 'Hasta Daño base 90.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: 'Hasta Daño base 120.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 220,
        maintainedZeonCost: 10,
        effect: 'Hasta Daño base 160.',
      },
    },
  },
  {
    id: '196',
    name: 'Impacto de agua',
    magicPath: MagicPath.Water,
    level: 20,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 50,
        effect: 'Daño 40 / Fuerza 8.',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 90,
        effect: 'Daño 60 / Fuerza 10.',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        effect: 'Daño 80 / Fuerza 12.',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        effect: 'Daño 100 / Fuerza 14.',
      },
    },
  },
  {
    id: '197',
    name: 'Control sobre los líquidos',
    magicPath: MagicPath.Water,
    level: 22,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'RM o RF 100 / 5 litros.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM o RF 120 / 50 litros.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'RM o RF 140 / 500 litros.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 220,
        maintainedZeonCost: 15,
        effect: 'RM o RF 180 / 5.000 litros.',
      },
    },
  },
  {
    id: '198',
    name: 'Congelar las emociones',
    magicPath: MagicPath.Water,
    level: 26,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'Afecta a estados naturales.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect:
          'Como en grado base, pero el afectado deja de sentir también dolor.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect:
          'Como en Intermedio, pero también afecta a todos los estados sobrenaturales.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect:
          'Como en Avanzado, pero el mago puede elegir qué sentimientos congelar.',
      },
    },
  },
  {
    id: '199',
    name: 'Controlar el frío',
    magicPath: MagicPath.Water,
    level: 30,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'RM 100 / 5 intensidades.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RM 120 / 8 intensidades.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 140 / 12 intensidades.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM 180 / 15 intensidades.',
      },
    },
  },
  {
    id: '200',
    name: 'Congelar',
    magicPath: MagicPath.Water,
    level: 32,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'RM 120 / 5 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 140/ 10 metros de radio.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM 160 / 25 metros de radio.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 220,
        maintainedZeonCost: 15,
        effect: 'RM 180 / 50 metros de radio.',
      },
    },
  },
  {
    id: '201',
    name: 'Pantalla de hielo',
    magicPath: MagicPath.Water,
    level: 36,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '400 puntos de Resistencia.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: '1.500 puntos de Resistencia.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: '2.500 puntos de Resistencia.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: '4.000 puntos de Resistencia.',
      },
    },
  },
  {
    id: '202',
    name: 'Crear líquidos',
    magicPath: MagicPath.Water,
    level: 40,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '50 litros de agua.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        maintainedZeonCost: 20,
        effect: '500 litros de agua.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '5.000 litros de agua.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 350,
        maintainedZeonCost: 30,
        effect: '50.000 litros de agua.',
      },
    },
  },
  {
    id: '203',
    name: 'Ataque de hielo',
    magicPath: MagicPath.Water,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        effect: 'Daño 100.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 160,
        effect: 'Daño 150.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: 'Daño 200.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: 'Daño 250.',
      },
    },
  },
  {
    id: '204',
    name: 'Cristalización',
    magicPath: MagicPath.Water,
    level: 46,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'RM o RF 140.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM o RF 160.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'RM o RF 180.',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'RM o RF 200.',
      },
    },
  },
  {
    id: '205',
    name: 'Control reflejo',
    magicPath: MagicPath.Water,
    level: 50,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'RM 80.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 160,
        maintainedZeonCost: 10,
        effect: 'RM 130.',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'RM 160.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        maintainedZeonCost: 20,
        effect: 'RM 200.',
      },
    },
  },
  {
    id: '206',
    name: 'Cuerpo líquido',
    magicPath: MagicPath.Water,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Las habilidades descritas.',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect:
          'Podrá moverse por el agua a velocidad igual a su Tipo de movimiento natural.',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'Como el Intermedio, pero la calidad de las armas es +10.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect:
          'Idem arriba, pero ataques FIL o PEN basados en energía hacen mitad de daño',
      },
    },
  },
  {
    id: '207',
    name: 'Reflejar estados',
    magicPath: MagicPath.Water,
    level: 56,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 120,
        effect: 'RM 120.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        effect: 'RM 150.',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        effect: 'RM 180.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        effect: 'RM 220.',
      },
    },
  },
  {
    id: '208',
    name: 'Tormenta de hielo',
    magicPath: MagicPath.Water,
    level: 60,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '50 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: '150 metros de radio.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: '500 metros de radio / La RF se incrementa hasta 160.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 320,
        maintainedZeonCost: 15,
        effect: '1 kilómetro de radio / La RF se incrementa hasta 180.',
      },
    },
  },
  {
    id: '209',
    name: 'Control de las mareas',
    magicPath: MagicPath.Water,
    level: 62,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '500 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '1 kilómetro de radio.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 450,
        maintainedZeonCost: 45,
        effect: '3 kilómetros de radio.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: '5 kilómetros de radio.',
      },
    },
  },
  {
    id: '210',
    name: 'Prisión de agua',
    magicPath: MagicPath.Water,
    level: 66,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: '10 metros cúbicos.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: '50 metros cúbicos.',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 280,
        maintainedZeonCost: 15,
        effect: '100 metros cúbicos.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 350,
        maintainedZeonCost: 20,
        effect: '150 metros cúbicos / El control es contra Fuerza 15.',
      },
    },
  },
  {
    id: '211',
    name: 'Glacial',
    magicPath: MagicPath.Water,
    level: 70,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect: '1 Kilómetro de radio.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: '3 Kilómetros de radio.',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        maintainedZeonCost: 80,
        effect: '5 Kilómetros de radio.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: '10 Kilómetros de radio.',
      },
    },
  },
  {
    id: '212',
    name: 'Tsunami',
    magicPath: MagicPath.Water,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        effect: '1 kilómetro de longitud.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        effect: '10 kilómetros de longitud.',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 450,
        effect: '20 kilómetros de longitud.',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 550,
        effect:
          '"30 kilómetros de longitud / Arrasa construcciones de hasta barrera de daño 90.',
      },
    },
  },
  {
    id: '214',
    name: 'Reflejo del alma',
    magicPath: MagicPath.Water,
    level: 76,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'RM 140 / Copia entidades de hasta Nivel 3.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 280,
        maintainedZeonCost: 30,
        effect: 'RM 160 / Copia entidades de hasta Nivel 5.',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 320,
        maintainedZeonCost: 35,
        effect:
          'RM 180 / Copia entidades de hasta Nivel 8 / Imita poderes de hasta Gnosis 25.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect:
          'RM 200 / Copia entidades de hasta Nivel 12 / Imita poderes de hasta Gnosis 30.',
      },
    },
  },
  {
    id: '215',
    name: 'Enlentecer el tiempo',
    magicPath: MagicPath.Water,
    level: 80,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect:
          '100 metros de radio / RM 120 / El tiempo se reduce una décima parte.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 320,
        maintainedZeonCost: 25,
        effect:
          '200 metros de radio / RM 140 / El tiempo se reduce una centésima parte.',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 450,
        maintainedZeonCost: 30,
        effect:
          '500 metros de radio / RM 160 / El tiempo se reduce una milésima parte.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 600,
        maintainedZeonCost: 35,
        effect:
          '1 kilómetro de radio / RM 180 / El tiempo se reduce una millonésima parte.',
      },
    },
  },
  {
    id: '216',
    name: 'Crear ondina',
    magicPath: MagicPath.Water,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        maintainedZeonCost: 50,
        effect: 'Nivel 1.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        maintainedZeonCost: 70,
        effect: 'Nivel 3.',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: 'Nivel 6.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 700,
        maintainedZeonCost: 140,
        effect: 'Nivel 10.',
      },
    },
  },
  {
    id: '217',
    name: 'Congelar la magia',
    magicPath: MagicPath.Water,
    level: 86,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 250,
        maintainedZeonCost: 50,
        effect: 'Valor zeónico máximo 150.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 400,
        maintainedZeonCost: 75,
        effect: 'Valor zeónico máximo 250.',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 550,
        maintainedZeonCost: 90,
        effect: 'Valor zeónico máximo 300.',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 800,
        maintainedZeonCost: 100,
        effect: 'Valor zeónico máximo 400.',
      },
    },
  },
  {
    id: '218',
    name: 'En el interior del espejo',
    magicPath: MagicPath.Water,
    level: 90,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '500 metros de radio máximo.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 480,
        maintainedZeonCost: 50,
        effect: '2 kilómetros de radio máximo.',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: '5 kilómetros de radio máximo.',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 800,
        maintainedZeonCost: 70,
        effect:
          '10 kilómetros de radio máximo / Se pueden imitar seres de hasta presencia 30.',
      },
    },
  },
  {
    id: '219',
    name: 'Señor de los hielos',
    magicPath: MagicPath.Water,
    level: 92,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '100 kilómetros de radio / 140 RM.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 450,
        maintainedZeonCost: 45,
        effect: '1.000 kilómetros de radio / 180 RM.',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: '10.000 kilómetros de radio / 200 RM.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 1000,
        maintainedZeonCost: 100,
        effect: '100.000 kilómetros de radio / 240 RM.',
      },
    },
  },
  {
    id: '220',
    name: 'Señor de las aguas',
    magicPath: MagicPath.Water,
    level: 96,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '100 kilómetros de radio / 140 RM.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 450,
        maintainedZeonCost: 45,
        effect: '1.000 kilómetros de radio / 180 RM.',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: '10.000 kilómetros de radio / 200 RM.',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1000,
        maintainedZeonCost: 100,
        effect: 'Afecta cualquier sustancia líquida existente / 240 RM.',
      },
    },
  },
  {
    id: '221',
    name: 'Un mundo perfecto',
    magicPath: MagicPath.Water,
    level: 100,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 450,
        maintainedZeonCost: 90,
        effect: 'RM 120.',
      },
      intermediate: {
        intelligenceRequirement: 15,
        zeonCost: 800,
        maintainedZeonCost: 115,
        effect: 'RM 180.',
      },
      advanced: {
        intelligenceRequirement: 17,
        zeonCost: 1200,
        maintainedZeonCost: 130,
        effect: 'RM 220.',
      },
      arcane: {
        intelligenceRequirement: 20,
        zeonCost: 1600,
        maintainedZeonCost: 145,
        effect: 'RM 260.',
      },
    },
  },
  {
    id: '222',
    name: 'Crear fuego',
    magicPath: MagicPath.Fire,
    level: 2,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: '1 Intensidad de fuego',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '6 intensidades de fuego',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '8 Intensidades de fuego',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '10 intensidades de fuego',
      },
    },
  },
  {
    id: '223',
    name: 'Mitigar fuego',
    magicPath: MagicPath.Fire,
    level: 6,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        effect: '-1 intesidad de fuego',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: '-5 Intensidades de fuego',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        effect: '-10 Intensidades fuego',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 200,
        effect: '-15 Intensidades de fuego',
      },
    },
  },
  {
    id: '224',
    name: 'Inmunidad contra el fuego',
    magicPath: MagicPath.Fire,
    level: 10,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '5 Intensidades de calor',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: '12 Intensidades de calor',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: '20 Intensidades de fuego',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: '30 Intensidades de fuego',
      },
    },
  },
  {
    id: '225',
    name: 'Sentir el calor',
    magicPath: MagicPath.Fire,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '25 metros / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '50 metros / RM 150',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '100 metros / RM 180',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: '250 metros / RM 220',
      },
    },
  },
  {
    id: '226',
    name: 'Bola de fuego',
    magicPath: MagicPath.Fire,
    level: 16,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        effect: 'Daño Base 50 / Área de 5 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        effect: 'Daño Base 100 / Área de 25 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 160,
        effect: 'Daño Base 140 / Área de 80 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 250,
        effect: 'Daño Base 160 / Área de 150 metros de radio',
      },
    },
  },
  {
    id: '227',
    name: 'Control sobre el fuego',
    magicPath: MagicPath.Fire,
    level: 20,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'RM 100/ 5 intensidades',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RM 120 / 8 intensidades',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 140/ 12 intensidades',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM 180/ 15 intensidades',
      },
    },
  },
  {
    id: '228',
    name: 'Barrera de fuego',
    magicPath: MagicPath.Fire,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'Daño 80 / 2 metros de extensión / 300 puntos de Resistencia',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'Daño 90 / 5 metros de extensión / 500 puntos de Resistencia',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect:
          'Daño 100 / 10 metros / 800 puntos / Puede detener ataques basados en energía',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect:
          'Daño 100 /15 metros/1000 puntos/Ataque a IMP/ Puede con toda clase ataque',
      },
    },
  },
  {
    id: '229',
    name: 'Arma ígnea',
    magicPath: MagicPath.Fire,
    level: 26,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '+10 daño base',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '+20 daño base',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '+30 daño base',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '+40 daño base',
      },
    },
  },
  {
    id: '230',
    name: 'Descarga de calor',
    magicPath: MagicPath.Fire,
    level: 30,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        effect: 'Daño 50',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        effect: 'Daño 70',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: 'Daño 90',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        effect: 'Daño 110',
      },
    },
  },
  {
    id: '231',
    name: 'Ver en las cenizas',
    magicPath: MagicPath.Fire,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        effect: 'Retrocede una hora',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        effect: 'Retrocede un día',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 220,
        effect: 'Retrocede un mes',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 340,
        effect: 'Retrocede un año',
      },
    },
  },
  {
    id: '232',
    name: 'Aumentar temp. ambiental',
    magicPath: MagicPath.Fire,
    level: 36,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 60,
        maintainedZeonCost: 15,
        effect: '+5 grados/ 1 km de radio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        maintainedZeonCost: 40,
        effect: '+10 grados / 5 km de radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 50,
        effect: '+20 grados / 10km de radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: '+30 grados / 15 km de  radio',
      },
    },
  },
  {
    id: '233',
    name: 'Mina de fuego',
    magicPath: MagicPath.Fire,
    level: 40,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 20,
        effect: '10 metros de radio/ Daño 80',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 160,
        maintainedZeonCost: 40,
        effect: '50 metros de radio/ Daño 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 50,
        effect: '150 metros de radio/ Daño 180',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 320,
        maintainedZeonCost: 60,
        effect: '250 metros de radio/ Daño 240',
      },
    },
  },
  {
    id: '234',
    name: 'Aumentar el crítico',
    magicPath: MagicPath.Fire,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        effect: '+20 al nivel de Crítico',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        effect: '+40 al nivel de Crítico',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: '+60 al nivel de Crítico',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        effect: '+80 al nivel de Crítico',
      },
    },
  },
  {
    id: '235',
    name: 'Secar',
    magicPath: MagicPath.Fire,
    level: 46,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: '5 metros de radio/ RM o RF 100',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        effect: '15 metros de radio / RM o RF 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        effect: '25 metros de radio / RM o RF 140',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        effect: '35 metros de radio / RM o RF 160',
      },
    },
  },
  {
    id: '236',
    name: 'Fundir',
    magicPath: MagicPath.Fire,
    level: 50,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '10 metros de radio/ RF 80',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '50 metros de radio / RF 100',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        maintainedZeonCost: 15,
        effect: '100 metros de radio / RF 120',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: '150 metros de radio / RF 140',
      },
    },
  },
  {
    id: '237',
    name: 'Cuerpo a fuego',
    magicPath: MagicPath.Fire,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Las habilidades descritas',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect:
          'Se gana +30 a sus Resistencias contra efectos basados en calor',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect:
          'Idem arriba, el sujeto puede pasar por donde pueda pasar el fuego',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect:
          'Idem arriba, los ataques de frío o agua no sobrenatural no causan daño',
      },
    },
  },
  {
    id: '238',
    name: 'Sacrificio vital',
    magicPath: MagicPath.Fire,
    level: 56,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'Sacrificio máximo de 50 PV por asalto',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: 'Sacrificio máximo de 100 PV por asalto',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'Sacrificio máximo de 150 PV por asalto',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: 'Sacrificio máximo de 200 PV por asalto',
      },
    },
  },
  {
    id: '239',
    name: 'Incinerar',
    magicPath: MagicPath.Fire,
    level: 60,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 140/ +100 a la Tabla 76/ 50 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'RM 160/ +120 a la Tabla 76/ 100 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: 'RM 180/ +140 a la Tabla 76/ 150 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 260,
        maintainedZeonCost: 20,
        effect: 'RM 200/ +160 a la Tabla 76/ 200 metros de radio',
      },
    },
  },
  {
    id: '240',
    name: 'Consumir esencia',
    magicPath: MagicPath.Fire,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        effect: '10 metros de radio/ RM 120',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        effect: '25 metros de radio/ RM 160',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        effect: '50 metros de radio/ RM 200',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: '150 metros de radio / RM 220',
      },
    },
  },
  {
    id: '241',
    name: 'Sacrificio de poder',
    magicPath: MagicPath.Fire,
    level: 66,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '20 puntos de Zeon por asalto',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: '50 puntos de Zeon por asalto',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: '80 puntos de Zeon por asalto',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: '120 puntos de Zeon por asalto',
      },
    },
  },
  {
    id: '242',
    name: 'Crítico directo',
    magicPath: MagicPath.Fire,
    level: 70,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        effect: 'Crítico 120/ RM 140',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 140,
        effect: 'Crítico 140/ RM 160',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 200,
        effect: 'Crítico 180/ RM 180',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 280,
        effect: 'Crítico 220/ RM 200',
      },
    },
  },
  {
    id: '243',
    name: 'Magia por capacidades',
    magicPath: MagicPath.Fire,
    level: 72,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '50 puntos de Zeon por asalto',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: '100 puntos de Zeon por asalto',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: '150 puntos de Zeon por asalto',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: '200 puntos de Zeon por asalto',
      },
    },
  },
  {
    id: '244',
    name: 'Tormenta de fuego',
    magicPath: MagicPath.Fire,
    level: 76,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '25 metros de radio/ HA Absurdo (180)',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '150 metros de radio/ HA CIM (240)',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '200 metros de radio/ HA IMP (280)',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 280,
        maintainedZeonCost: 30,
        effect: '250 metros de radio/ HA INH (320)',
      },
    },
  },
  {
    id: '245',
    name: 'Consumir vida por magia',
    magicPath: MagicPath.Fire,
    level: 80,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '20 PV por asalto',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: '80 PV por asalto',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: '140 PV por asalto',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: '200 PV por asalto',
      },
    },
  },
  {
    id: '246',
    name: 'Crear ifreet',
    magicPath: MagicPath.Fire,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        maintainedZeonCost: 50,
        effect: 'Nivel 1.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        maintainedZeonCost: 70,
        effect: 'Nivel 3',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: 'Nivel 6.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 700,
        maintainedZeonCost: 140,
        effect: 'Nivel 10.',
      },
    },
  },
  {
    id: '247',
    name: 'Pira',
    magicPath: MagicPath.Fire,
    level: 86,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: '15 intensidades',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '25 intensidades',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 350,
        maintainedZeonCost: 30,
        effect: '35 intensidades',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 400,
        maintainedZeonCost: 35,
        effect: '45 intensidades',
      },
    },
  },
  {
    id: '248',
    name: 'Devastación',
    magicPath: MagicPath.Fire,
    level: 90,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: '1 km de radio',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 300,
        effect: '5 km de radio',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 400,
        effect: '10 km de radio',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 500,
        effect: '15 km de radio',
      },
    },
  },
  {
    id: '249',
    name: 'Sacrificar a otros',
    magicPath: MagicPath.Fire,
    level: 92,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 13,
        zeonCost: 250,
        maintainedZeonCost: 50,
        effect: 'RM 120/ 1 km de area',
      },
      intermediate: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        maintainedZeonCost: 70,
        effect: 'RM 140/ 2km de area',
      },
      advanced: {
        intelligenceRequirement: 17,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: 'RM 160/ 3km de area',
      },
      arcane: {
        intelligenceRequirement: 19,
        zeonCost: 750,
        maintainedZeonCost: 150,
        effect: 'RM 180/ 5 km de area',
      },
    },
  },
  {
    id: '250',
    name: 'Señor del fuego',
    magicPath: MagicPath.Fire,
    level: 96,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '100 km/ 140 RM',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 450,
        maintainedZeonCost: 45,
        effect: '1.000 km/ 180RM',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: '10.000 km / 200 RM',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1000,
        maintainedZeonCost: 100,
        effect: 'Afecta a cualquier fuente de calor / RM 240',
      },
    },
  },
  {
    id: '251',
    name: 'Armagedón',
    magicPath: MagicPath.Fire,
    level: 100,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 15,
        zeonCost: 450,
        maintainedZeonCost: 90,
        effect: '10 km de radio / RM 240',
      },
      intermediate: {
        intelligenceRequirement: 16,
        zeonCost: 800,
        maintainedZeonCost: 100,
        effect: '25 km de radio / RM 150',
      },
      advanced: {
        intelligenceRequirement: 17,
        zeonCost: 1200,
        maintainedZeonCost: 105,
        effect: '50 km de radio / RM 160',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1600,
        maintainedZeonCost: 110,
        effect: '150 km de radio / RM 180',
      },
    },
  },
  {
    id: '252',
    name: 'Detectar minerales',
    magicPath: MagicPath.Earth,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 20,
        effect: '10 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        effect: '50 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: '150 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        effect: '500 metros de radio',
      },
    },
  },
  {
    id: '253',
    name: 'Control mineral',
    magicPath: MagicPath.Earth,
    level: 6,
    isDaily: true,
    activationType: [SpellActivationType.Effect, SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 30 / RM 100',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 60 / RM 120',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 90 / RM 130',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'Presencia máxima 120 / RM 140',
      },
    },
  },
  {
    id: '254',
    name: 'Aumentar peso',
    magicPath: MagicPath.Earth,
    level: 10,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: '+20 kilogramos',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        maintainedZeonCost: 25,
        effect: '+120 kilogramos',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 30,
        effect: '+200 kilogramos',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 320,
        maintainedZeonCost: 40,
        effect: '+300 kilogramos',
      },
    },
  },
  {
    id: '255',
    name: 'Transformar mineral',
    magicPath: MagicPath.Earth,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 30 / hasta 10 kilos de masa',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 50 / hasta 50 kilos de masa',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 70 / hasta 100 kilos de masa',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 160,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 90 / hasta 250 kilos de masa',
      },
    },
  },
  {
    id: '256',
    name: 'Firmeza',
    magicPath: MagicPath.Earth,
    level: 16,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'RF +20 / Entereza +3',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RF +30 / Entereza +5',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RF +45 / Entereza +7',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'RF +60 / Entereza +9',
      },
    },
  },
  {
    id: '257',
    name: 'Barrera de piedra',
    magicPath: MagicPath.Earth,
    level: 20,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'Barrera de daño 60 / 600 puntos de resistencia',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'Barrera de daño 100 / 1.600 puntos de resistencia',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Barrera de daño 150 / 3.000 puntos de resistencia',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Barrera de daño 200 / 5.000 Puntos de Resistencia',
      },
    },
  },
  {
    id: '258',
    name: 'Lentitud',
    magicPath: MagicPath.Earth,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'RM 120 / -50 al turno y -2 al Tipo de Movimiento',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'RM 140 / -70 al turno y -4 al Tipo de Movimiento',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 160 / -90 al turno y -6 al Tipo de Movimiento',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'RM 180 / -120 al turno y -10 al Tipo de Movimiento',
      },
    },
  },
  {
    id: '259',
    name: 'Coraza',
    magicPath: MagicPath.Earth,
    level: 26,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'TA 2',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'TA 4',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'TA 6',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: 'TA 8',
      },
    },
  },
  {
    id: '260',
    name: 'Escudo magnético',
    magicPath: MagicPath.Earth,
    level: 30,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '300 puntos de resistencia',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: '600 puntos de resistencia',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '900 puntos de resistencia',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: '1.200 puntos de resistencia',
      },
    },
  },
  {
    id: '261',
    name: 'Atravesar lo sólido',
    magicPath: MagicPath.Earth,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 100',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 140',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 180',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 240',
      },
    },
  },
  {
    id: '262',
    name: 'Espina de la tierra',
    magicPath: MagicPath.Earth,
    level: 36,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: '2 espinas',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        effect: '4 espinas',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        effect: '6 espinas',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        effect: '8 espinas',
      },
    },
  },
  {
    id: '263',
    name: 'Rotura',
    magicPath: MagicPath.Earth,
    level: 40,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'Rotura +4',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'Rotura +8',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Rotura +12',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Rotura +15',
      },
    },
  },
  {
    id: '264',
    name: 'Telemetría',
    magicPath: MagicPath.Earth,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        effect: 'RM 80 / 1 mes',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        effect: 'RM 120 / 1 año',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: 'RM 140 / 10 años',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: 'RM 160 / 1 siglo',
      },
    },
  },
  {
    id: '265',
    name: 'Control magnético',
    magicPath: MagicPath.Earth,
    level: 46,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '25 metros de radio / Fuerza 10',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '150 metros de radio / Fuerza 12',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '350 metros de radio / Fuerza 13',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 320,
        maintainedZeonCost: 30,
        effect: '500 metros de radio / Fuerza 14',
      },
    },
  },
  {
    id: '266',
    name: 'Forja',
    magicPath: MagicPath.Earth,
    level: 50,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 160,
        effect: 'Forja 120',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 270,
        effect: 'Forja 180',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 360,
        effect: 'Forja 240',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 450,
        effect: 'Forja 280',
      },
    },
  },
  {
    id: '267',
    name: 'Cuerpo sólido',
    magicPath: MagicPath.Earth,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Las habilidades descritas / TA natural 6 / +1 Fuerza',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Las habilidades descritas / TA natural 8 / +2 Fuerza',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect:
          'TA natural 10 / No dañan ataques físicos no basados en energía / +3 a Fuerza',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect:
          'Idem arriba, TA 12 /Ataques físicos basados en ENE mitad daño /+4 a Fuerza',
      },
    },
  },
  {
    id: '268',
    name: 'Resistencia',
    magicPath: MagicPath.Earth,
    level: 56,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '500 puntos de vida',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '1.200 puntos de vida',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '2.000 puntos de vida',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '3.000 puntos de vida',
      },
    },
  },
  {
    id: '269',
    name: 'Petrificar',
    magicPath: MagicPath.Earth,
    level: 60,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'RM 150',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 260,
        maintainedZeonCost: 15,
        effect: 'RM 180',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        maintainedZeonCost: 20,
        effect: 'RM 220',
      },
    },
  },
  {
    id: '270',
    name: 'Grieta',
    magicPath: MagicPath.Earth,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        effect:
          '10 metros de longitud / 3 de anchura / Construcciones con barrera de 40',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect:
          '25 metros de longitud / 8 de anchura / Construcciones con barrera de 60',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        effect:
          '36 metros de longitud/ 12 de anchura/ Construcciones con barrera de 80',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 300,
        effect:
          '48 metros de longitud/ 15 de anchura/ Construcciones con barrera de 100',
      },
    },
  },
  {
    id: '271',
    name: 'Invertir la gravedad',
    magicPath: MagicPath.Earth,
    level: 66,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect: '25 metros de radio / 50 de altura / 120 RM',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 50,
        effect: '50 metros de radio / 100 de altura / 140 RM',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 280,
        maintainedZeonCost: 60,
        effect: '100 metros de radio / 200 de altura / 160 RM',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        maintainedZeonCost: 65,
        effect: '150 metros de radio / 300 de altura / 180 RM',
      },
    },
  },
  {
    id: '272',
    name: 'Creación mineral',
    magicPath: MagicPath.Earth,
    level: 70,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Presencia Máxima 40',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'Presencia Máxima 70',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Presencia Máxima 100',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Presencia Máxima 140',
      },
    },
  },
  {
    id: '273',
    name: 'Erudición del terreno',
    magicPath: MagicPath.Earth,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 120,
        effect: '500 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 270,
        effect: '3 kilómetros de radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 360,
        effect: '10 kilómetros de radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 450,
        effect: '15 kilómetros de radio',
      },
    },
  },
  {
    id: '274',
    name: 'Terremoto',
    magicPath: MagicPath.Earth,
    level: 76,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '500 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '3 kilómetros de radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 25,
        effect: '10 kilómetros de radio',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        maintainedZeonCost: 30,
        effect: '15 kilómetros de radio',
      },
    },
  },
  {
    id: '275',
    name: 'Destrucción gravitacional',
    magicPath: MagicPath.Earth,
    level: 80,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '20 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: '50 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 320,
        maintainedZeonCost: 30,
        effect: '100 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: '150 metros de radio',
      },
    },
  },
  {
    id: '276',
    name: 'Crear golem',
    magicPath: MagicPath.Earth,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        maintainedZeonCost: 50,
        effect: 'Nivel 1',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        maintainedZeonCost: 70,
        effect: 'Nivel 3',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: 'Nivel 6',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 700,
        maintainedZeonCost: 140,
        effect: 'Nivel 10',
      },
    },
  },
  {
    id: '277',
    name: 'Aumento de gravedad',
    magicPath: MagicPath.Earth,
    level: 86,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Dobla el peso / 100 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Triplica el peso / 200 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 280,
        maintainedZeonCost: 30,
        effect: 'Quintuplica el peso / 300 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        maintainedZeonCost: 35,
        effect: 'Decuplica el peso / 400 metros de radio',
      },
    },
  },
  {
    id: '278',
    name: 'Meteoro',
    magicPath: MagicPath.Earth,
    level: 90,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: '1 meteoro',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 250,
        effect: '5 meteoros',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        effect: '10 meteoros',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 450,
        effect: '15 meteoros',
      },
    },
  },
  {
    id: '279',
    name: 'Control de la gravedad',
    magicPath: MagicPath.Earth,
    level: 92,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        maintainedZeonCost: 70,
        effect: '100 kilómetros de radio',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: '750 kilómetros de radio',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 650,
        maintainedZeonCost: 130,
        effect: '1.500 kilómetros de radio',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 800,
        maintainedZeonCost: 160,
        effect: '5.000 kilómetros de radio',
      },
    },
  },
  {
    id: '280',
    name: 'Uno con la tierra',
    magicPath: MagicPath.Earth,
    level: 96,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '100 kilómetros de radio / 140 RM',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 450,
        maintainedZeonCost: 45,
        effect: '1.000 kilómetros de radio / 180 RM',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: '10.000 kilómetros de radio / 200 RM',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1000,
        maintainedZeonCost: 100,
        effect: 'Afecta cualquier elemento mineral existente / 240 RM',
      },
    },
  },
  {
    id: '281',
    name: 'Control atómico',
    magicPath: MagicPath.Earth,
    level: 100,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 450,
        maintainedZeonCost: 45,
        effect: 'RM o RF 140 / 100 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 800,
        maintainedZeonCost: 80,
        effect: 'RM o RF 160 / 250 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 1200,
        maintainedZeonCost: 120,
        effect: 'RM o RF 200 / 500 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1600,
        maintainedZeonCost: 160,
        effect: 'RM o RF 240 / 1 kilómetro de radio',
      },
    },
  },
  {
    id: '282',
    name: 'Afinidad natural',
    magicPath: MagicPath.Essence,
    level: 2,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: 'Afecta a animales naturales (caballos, conejos, lobos, etc)',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Afecta a toda clase de criatura natural',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Afecta a seres naturales y a criaturas entre mundos',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect:
          'Afecta a cualquier criatura, incluyendo seres sobrenaturales de alto poder',
      },
    },
  },
  {
    id: '283',
    name: 'Detectar esencia',
    magicPath: MagicPath.Essence,
    level: 6,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: 'RM 100 / 10 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'RM 140 / 25 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 160 y 50 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM 200 / 100 metros de radio',
      },
    },
  },
  {
    id: '284',
    name: 'Comunicación por esencia',
    magicPath: MagicPath.Essence,
    level: 10,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 30,
        maintainedZeonCost: 10,
        effect: 'Permite comunicarse con animales o plantas',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 70,
        maintainedZeonCost: 20,
        effect: 'Permite comunicarte con cualquier clase de ser natural',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 20,
        effect:
          'Permite comunicarse con cualquier clase de ser natural o entre mundos',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 150,
        maintainedZeonCost: 25,
        effect: 'Permite comunicarse con cualquier clase de entidad',
      },
    },
  },
  {
    id: '285',
    name: 'Conocimiento natural',
    magicPath: MagicPath.Essence,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        effect: 'Analiza las propiedades básicas de una planta o animal',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 60,
        effect: 'Analiza todas las propiedades de una planta o animal',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        effect: 'Analiza todas las propiedades de una raza natural',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        effect:
          'Analiza todas las propiedades y poderes místicos de un ser natural.',
      },
    },
  },
  {
    id: '286',
    name: 'Sanación',
    magicPath: MagicPath.Essence,
    level: 16,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: '20% puntos de vida',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: '40% puntos de vida',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        effect: '60% puntos de vida',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        effect: '80% puntos de vida',
      },
    },
  },
  {
    id: '287',
    name: 'Barrera de almas',
    magicPath: MagicPath.Essence,
    level: 20,
    isDaily: true,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: 'Hasta 140 de Resistencia',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'Hasta 160 de Resistencia',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'Hasta 200 de Resistencia',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Hasta 240 de Resistencia',
      },
    },
  },
  {
    id: '288',
    name: 'Compartir sentidos',
    magicPath: MagicPath.Essence,
    level: 22,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect:
          'Presencia máxima 100 / RM o RP 100 / 1 kilómetro de distancia máxima',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect:
          'Presencia máxima 160 / RM o RP 160 / 10 kilómetros de distancia máxima.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect:
          'Presencia máxima 200 / RM o RP 190 / 50 kilómetros de distancia máxima',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect:
          'Presencia máxima 240 / RM o RP 220 / 150 kilómetros de distancia máxima',
      },
    },
  },
  {
    id: '289',
    name: 'Modificar la esencia',
    magicPath: MagicPath.Essence,
    level: 26,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'RM 140',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RM 160',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 180',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM 200',
      },
    },
  },
  {
    id: '290',
    name: 'Veneno de almas',
    magicPath: MagicPath.Essence,
    level: 30,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        effect: 'Veneno de nivel 40',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        effect: 'Veneno de nivel 50',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: 'Veneno de nivel 60',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        effect: 'Veneno de nivel 70',
      },
    },
  },
  {
    id: '291',
    name: 'Analizar el alma',
    magicPath: MagicPath.Essence,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 60,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        effect: 'RM 180',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        effect: 'RM 200',
      },
    },
  },
  {
    id: '292',
    name: 'Adq. capacidades naturales',
    magicPath: MagicPath.Essence,
    level: 36,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 120,
        maintainedZeonCost: 25,
        effect: '50 PD',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect: '100 PD',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 280,
        maintainedZeonCost: 60,
        effect: '150 PD',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 360,
        maintainedZeonCost: 80,
        effect: '200 PD',
      },
    },
  },
  {
    id: '293',
    name: 'Revitalizar',
    magicPath: MagicPath.Essence,
    level: 40,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '50 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '200 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '500 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '1 kilómetro de radio',
      },
    },
  },
  {
    id: '294',
    name: 'Mente de vida',
    magicPath: MagicPath.Essence,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM 80 / 500 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: 'RM 120 / 1 kilómetro de radio',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'RM 140 / 2 kilómetros de radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: 'RM 180 / 3 kilómetros de radio',
      },
    },
  },
  {
    id: '295',
    name: 'Alterar el crecimiento',
    magicPath: MagicPath.Essence,
    level: 46,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'x2 al ritmo de crecimiento / RM 100',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'X 10 al ritmo de crecimiento / RM 120',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 180,
        maintainedZeonCost: 15,
        effect: 'x50 al ritmo de crecimiento / RM 140',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 240,
        maintainedZeonCost: 20,
        effect: 'x100 al ritmo de crecimiento / RM 160',
      },
    },
  },
  {
    id: '296',
    name: 'Imitación natural',
    magicPath: MagicPath.Essence,
    level: 50,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '2 niveles',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: '10 niveles',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: '20 niveles',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: '50 niveles',
      },
    },
  },
  {
    id: '297',
    name: 'Forma espiritual',
    magicPath: MagicPath.Essence,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'El mago se vuelve intangible. ',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect:
          'Como en grado base, pero también carece de necesidades físicas. ',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect:
          'Como en grado intermedio, pero obtiene invisibilidad espiritual',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect:
          'como avanzada, pero también obtiene el poder Interacción con el mundo.',
      },
    },
  },
  {
    id: '298',
    name: 'Control natural',
    magicPath: MagicPath.Essence,
    level: 56,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 20,
        effect: 'RM 80',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 40,
        effect: 'RM 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 50,
        effect: 'RM 150',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '299',
    name: 'Inducción de estados',
    magicPath: MagicPath.Essence,
    level: 60,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        effect: 'RM 100',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: 'RM 180',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: 'RM 200',
      },
    },
  },
  {
    id: '300',
    name: 'Devolver al flujo',
    magicPath: MagicPath.Essence,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        effect: 'RM 180',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: 'RM 240',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: 'RM 300',
      },
    },
  },
  {
    id: '301',
    name: 'Escudar',
    magicPath: MagicPath.Essence,
    level: 66,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 120 / 20 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM 160 / 30 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM 180 / 40 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'RM 200 / 50 metros de radio',
      },
    },
  },
  {
    id: '302',
    name: 'Control sobrenatural',
    magicPath: MagicPath.Essence,
    level: 70,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        maintainedZeonCost: 25,
        effect: 'RM 100',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 40,
        effect: 'RM 120',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 50,
        effect: 'RM 140',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '303',
    name: 'Compartir esencia',
    magicPath: MagicPath.Essence,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'Presencia máxima 100 / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'Presencia máxima 140 / RM 140',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Presencia máxima 200 / RM 170',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Presencia máxima 260 / RM 200',
      },
    },
  },
  {
    id: '304',
    name: 'Transmigrar almas',
    magicPath: MagicPath.Essence,
    level: 76,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        effect: 'RM 100 / Presencia máxima 60',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: 'RM 140 / Presencia máxima 100',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: 'RM 160 / Presencia máxima 140',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 540,
        effect: 'RM 200 / Presencia máxima 180',
      },
    },
  },
  {
    id: '305',
    name: 'Existencia espiritual',
    magicPath: MagicPath.Essence,
    level: 80,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        effect: 'Hasta 100 PD opcionales en desventajas',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 500,
        effect: '+100 PD / hasta 100 PD opcionales en desventajas',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 1000,
        effect: '+200 PD / hasta 200 PD opcionales en desventajas',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 2500,
        effect: '+300 PD / hasta 200 PD opcionales en desventajas',
      },
    },
  },
  {
    id: '306',
    name: 'Creación de espíritus',
    magicPath: MagicPath.Essence,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        maintainedZeonCost: 50,
        effect: 'Nivel 1',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        maintainedZeonCost: 70,
        effect: 'Nivel 3',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: 'Nivel 6',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 700,
        maintainedZeonCost: 140,
        effect: 'Nivel 10',
      },
    },
  },
  {
    id: '307',
    name: 'Atar esencia vital',
    magicPath: MagicPath.Essence,
    level: 86,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: 'RM 100 / 1 kilómetro de distancia',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        effect: 'RM 120 / 5 kilómetros de distancia',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        effect: 'RM 140 / 50 kilómetros de distancia',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        effect: 'RM 160 / 150 kilómetros de distancia',
      },
    },
  },
  {
    id: '308',
    name: 'Verdor',
    magicPath: MagicPath.Essence,
    level: 90,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        effect: '10 kilómetros de radio',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 450,
        effect: '150 kilómetros de radio',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 600,
        effect: '350 kilómetros de radio',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 750,
        effect: '600 kilómetros de radio',
      },
    },
  },
  {
    id: '309',
    name: 'Dominio de la vida',
    magicPath: MagicPath.Essence,
    level: 92,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: 'RM 100 / 100 kilómetros de radio',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 500,
        maintainedZeonCost: 75,
        effect: 'RM 130 / 500 kilómetros de radio',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 750,
        maintainedZeonCost: 85,
        effect: 'RM 160 / 1.500 kilómetros de radio',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1000,
        maintainedZeonCost: 100,
        effect: 'RM 200 / 2.500 kilómetros de radio',
      },
    },
  },
  {
    id: '310',
    name: 'Resurrección',
    magicPath: MagicPath.Essence,
    level: 96,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 400,
        effect: 'Presencia máxima 30 / 1 mes',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 500,
        effect: 'Presencia máxima 60 / 1 año',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 600,
        effect: 'Presencia máxima 120 / 1 década',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 700,
        effect: 'Presencia máxima 150 / 1 siglo',
      },
    },
  },
  {
    id: '311',
    name: 'Señor de las almas',
    magicPath: MagicPath.Essence,
    level: 100,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 600,
        maintainedZeonCost: 120,
        effect: 'RM 120 / 100 kilómetros',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 800,
        maintainedZeonCost: 160,
        effect: 'RM 140 / 1.000 kilómetros',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 1200,
        maintainedZeonCost: 240,
        effect: 'RM 180 / 2.500 kilómetros',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1500,
        maintainedZeonCost: 300,
        effect: 'RM 200 / 5.000 kilómetros',
      },
    },
  },
  {
    id: '312',
    name: 'Ilusión sonora',
    magicPath: MagicPath.Illusion,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: 'RM 100 / radio de 20 metros',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'RM 120 / radio de 50 metros',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'RM 140 / radio de 100 metros',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM 160 / radio de 250 metros',
      },
    },
  },
  {
    id: '313',
    name: 'Ilusión olfativa',
    magicPath: MagicPath.Illusion,
    level: 6,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: 'RM 100 / radio de 20 metros',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'RM 120 / radio de 50 metros',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'RM 140 / radio de 100 metros',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM 160 / radio de 250 metros',
      },
    },
  },
  {
    id: '314',
    name: 'Ilusión táctil',
    magicPath: MagicPath.Illusion,
    level: 10,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: 'RM 100 / radio de 20 metros',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'RM 120 / radio de 50 metros',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'RM 140 / radio de 100 metros',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM 160 / radio de 250 metros',
      },
    },
  },
  {
    id: '315',
    name: 'Ilusión visual',
    magicPath: MagicPath.Illusion,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: 'RM 100 / radio de 10 metros',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 70,
        maintainedZeonCost: 5,
        effect: 'RM 120 / radio de 25 metros',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 140 / radio de 50 metros',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM 160 / radio de 100 metros',
      },
    },
  },
  {
    id: '316',
    name: 'Detectar ilusiones',
    magicPath: MagicPath.Illusion,
    level: 16,
    isDaily: false,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'Afecta a conjuros de grado base.',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'Afecta a conjuros de grado intermedio.',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Afecta a conjuros de grado avanzado.',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Afecta a conjuros de grado arcano.',
      },
    },
  },
  {
    id: '317',
    name: 'Engatusar',
    magicPath: MagicPath.Illusion,
    level: 20,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '+50 a Liderazgo y Persuasión.',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '+80 a Liderazgo y Persuasión.',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '+100 a Liderazgo y Persuasión.',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '+120 a Liderazgo y Persuasión.',
      },
    },
  },
  {
    id: '318',
    name: 'Alterar apariencia',
    magicPath: MagicPath.Illusion,
    level: 22,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'RM 160',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 200',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'RM 240',
      },
    },
  },
  {
    id: '319',
    name: 'Invisibilidad ilusoria',
    magicPath: MagicPath.Illusion,
    level: 26,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'RM 120 / Presencia máxima 140.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect: 'RM 150 / Presencia máxima 200.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM 180 / Presencia máxima 260.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: 'RM 210 / Presencia máxima 320.',
      },
    },
  },
  {
    id: '320',
    name: 'Imagen espejo',
    magicPath: MagicPath.Illusion,
    level: 30,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'RM 120 / 5 imágenes.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'RM 140 / 10 imágenes.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 160 / 20 imágenes.',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'RM 180 / 50 imágenes.',
      },
    },
  },
  {
    id: '321',
    name: 'Ilusión total',
    magicPath: MagicPath.Illusion,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: '120 RM.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 5,
        effect: '160 RM.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 10,
        effect: '200 RM.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 10,
        effect: '240 RM.',
      },
    },
  },
  {
    id: '322',
    name: 'Confusión',
    magicPath: MagicPath.Illusion,
    level: 36,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '140 RM.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 70,
        maintainedZeonCost: 5,
        effect: '160 RM.',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect: '180 RM.',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '200 RM.',
      },
    },
  },
  {
    id: '323',
    name: 'Crear ser ilusorio',
    magicPath: MagicPath.Illusion,
    level: 40,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'RM 120 / Nivel 2 / 20 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect: 'RM 140 / Nivel 4 / 50 metros de radio.',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM 160 / Nivel 7 / 100 metros de radio.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: 'RM 180 / Nivel 10 / 250 metros de radio.',
      },
    },
  },
  {
    id: '324',
    name: 'Resistencia a las ilusiones',
    magicPath: MagicPath.Illusion,
    level: 42,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '+20 al RM',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '+40 al RM',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '+60 al RM',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '+80 al RM',
      },
    },
  },
  {
    id: '325',
    name: 'Detectar mentira',
    magicPath: MagicPath.Illusion,
    level: 46,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RM o RP 120.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM o RP 140.',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'RM o RP 160.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'RM o RP 180.',
      },
    },
  },
  {
    id: '326',
    name: 'Ilusión fantasmal',
    magicPath: MagicPath.Illusion,
    level: 50,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM 120 / Presencia máxima 60',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: 'RM 150 / Presencia máxima 80',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'RM 180 / Presencia máxima 100',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: 'RM 210 / Presencia máxima 120',
      },
    },
  },
  {
    id: '327',
    name: 'Falsear detección',
    magicPath: MagicPath.Illusion,
    level: 52,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 120 / 10 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM 140 / 50 metros de radio.',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM 180 / 250 metros de radio.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'RM 220 / 500 metros de radio.',
      },
    },
  },
  {
    id: '328',
    name: 'Mentira',
    magicPath: MagicPath.Illusion,
    level: 56,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 100',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 120',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM 140',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'RM 160',
      },
    },
  },
  {
    id: '329',
    name: 'Destruir ilusiones',
    magicPath: MagicPath.Illusion,
    level: 60,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        effect: 'Zeón máximo 80.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        effect: 'Zeón máximo 140.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        effect: 'Zeón máximo 200.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        effect: 'Zeón máximo 300.',
      },
    },
  },
  {
    id: '330',
    name: 'Ser fantasmal',
    magicPath: MagicPath.Illusion,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 120 / Nivel 2 / 20 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 140 / Nivel 4 / 50 metros de radio.',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'RM 160 / Nivel 7 / 100 metros de radio.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'RM 180 / Nivel 10 / 250 metros de radio.',
      },
    },
  },
  {
    id: '331',
    name: 'Credulidad',
    magicPath: MagicPath.Illusion,
    level: 66,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'RM 140',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 160',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM 180',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: 'RM 200',
      },
    },
  },
  {
    id: '332',
    name: 'Ataque fantasmal',
    magicPath: MagicPath.Illusion,
    level: 70,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        effect: 'RM 140 / Daño 100',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        effect: 'RM 160 / Daño 180',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 220,
        effect: 'RM 180 / Daño 250',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: 'RM 200 / Daño 350',
      },
    },
  },
  {
    id: '333',
    name: 'El don de la mentira',
    magicPath: MagicPath.Illusion,
    level: 72,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM o RP 140',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM o RP 160',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM o RP 180',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        maintainedZeonCost: 35,
        effect: 'RM o RP 200',
      },
    },
  },
  {
    id: '334',
    name: 'Vida ilusoria',
    magicPath: MagicPath.Illusion,
    level: 76,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM o RP 140',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'RM o RP 160',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 260,
        maintainedZeonCost: 30,
        effect: 'RM o RP 180',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        maintainedZeonCost: 35,
        effect: 'RM o RP 200',
      },
    },
  },
  {
    id: '335',
    name: 'Ilusión mayor',
    magicPath: MagicPath.Illusion,
    level: 80,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'RM 120 / 1kilómetro de radio',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: 'RM 160 / 5 kilómetros de radio',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        maintainedZeonCost: 50,
        effect: 'RM 200 / 10 kilómetros de radio',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 700,
        maintainedZeonCost: 70,
        effect: 'RM 240 / 20 kilómetros de radio',
      },
    },
  },
  {
    id: '336',
    name: 'Fijar ilusión',
    magicPath: MagicPath.Illusion,
    level: 82,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 250,
        effect: '+ 1.000 puntos de Zeón al mantenimiento.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 360,
        effect: '+ 1.500 puntos de Zeón al mantenimiento.',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 450,
        effect: '+ 3.000 puntos de Zeón al mantenimiento.',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 600,
        effect: '+ 5.000 puntos de Zeón al mantenimiento.',
      },
    },
  },
  {
    id: '337',
    name: 'Ilusión de sentidos',
    magicPath: MagicPath.Illusion,
    level: 86,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'RM o RP 120.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'RM o RP 150',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'RM o RP 190',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: 'RM o RP 220',
      },
    },
  },
  {
    id: '338',
    name: 'Inexistencia',
    magicPath: MagicPath.Illusion,
    level: 90,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 11,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: 'RM 160',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 500,
        maintainedZeonCost: 50,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '339',
    name: 'Engañar a la muerte',
    magicPath: MagicPath.Illusion,
    level: 92,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 13,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: 'Nivel 5.',
      },
      intermediate: {
        intelligenceRequirement: 15,
        zeonCost: 800,
        maintainedZeonCost: 160,
        effect: 'Nivel 10.',
      },
      advanced: {
        intelligenceRequirement: 17,
        zeonCost: 1200,
        maintainedZeonCost: 240,
        effect: 'Nivel 15.',
      },
      arcane: {
        intelligenceRequirement: 19,
        zeonCost: 1500,
        maintainedZeonCost: 300,
        effect: 'Nivel 20.',
      },
    },
  },
  {
    id: '340',
    name: 'Mundo de mentiras',
    magicPath: MagicPath.Illusion,
    level: 96,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 13,
        zeonCost: 500,
        maintainedZeonCost: 50,
        effect: 'RM 140 / 10 Km de radio / 100 niveles a repartir entre seres.',
      },
      intermediate: {
        intelligenceRequirement: 15,
        zeonCost: 900,
        maintainedZeonCost: 90,
        effect:
          'RM 180 / 100 Km de radio / 500 niveles a repartir entre seres.',
      },
      advanced: {
        intelligenceRequirement: 17,
        zeonCost: 1400,
        maintainedZeonCost: 140,
        effect:
          'RM 220 / 1000 Km de radio/ 1.500 niveles a repartir entre seres.',
      },
      arcane: {
        intelligenceRequirement: 19,
        zeonCost: 2000,
        maintainedZeonCost: 200,
        effect:
          'RM 260 / 10.000 Km de radio / 5.000 niveles a repartir entre seres',
      },
    },
  },
  {
    id: '341',
    name: 'La falsa realidad',
    magicPath: MagicPath.Illusion,
    level: 100,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 14,
        zeonCost: 600,
        effect: 'RM 140',
      },
      intermediate: {
        intelligenceRequirement: 16,
        zeonCost: 1000,
        effect: 'RM 180 ',
      },
      advanced: {
        intelligenceRequirement: 18,
        zeonCost: 2000,
        effect: 'RM 220',
      },
      arcane: {
        intelligenceRequirement: 19,
        zeonCost: 3000,
        effect: 'RM 240',
      },
    },
  },
  {
    id: '342',
    name: 'Sentir la muerte',
    magicPath: MagicPath.Necromancy,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: '100 metros de radio / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '250 metros de radio / RM 140',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: '500 metros de radio / RM 160',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '1 kilometro de radio / RM 180',
      },
    },
  },
  {
    id: '343',
    name: 'Ver el mas allá',
    magicPath: MagicPath.Necromancy,
    level: 6,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: 'Permite ver seres espectrales',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect:
          'Permite ver seres espectrales y almas que esperan El Llamamiento.',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'Permite ver toda clase de ser espiritual',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Permite ver toda clase de ser espiritual o sobrenatural',
      },
    },
  },
  {
    id: '344',
    name: 'Dominar a los carroñeros',
    magicPath: MagicPath.Necromancy,
    level: 8,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: '10 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '150 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: '500 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: '2 kilometros de radio',
      },
    },
  },
  {
    id: '345',
    name: 'Escudo espectral',
    magicPath: MagicPath.Necromancy,
    level: 10,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: 'Detiene Resistencias de hasta 140',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'Detiene Resistencias de hasta 180',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Detiene Resistencias de hasta 220',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 100,
        maintainedZeonCost: 15,
        effect: 'Detiene Resistencias de hasta 260',
      },
    },
  },
  {
    id: '346',
    name: 'Drenar vida',
    magicPath: MagicPath.Necromancy,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        effect: 'RM 100',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 230,
        effect: 'RM 180',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 320,
        effect: 'RM 240',
      },
    },
  },
  {
    id: '347',
    name: 'Detección nigromántica',
    magicPath: MagicPath.Necromancy,
    level: 16,
    isDaily: false,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'RM 120 / 20 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RM 160 / 50 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 200 / 100 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 240 / 150 metros de radio',
      },
    },
  },
  {
    id: '348',
    name: 'Hablar con los muertos',
    magicPath: MagicPath.Necromancy,
    level: 18,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'Nivel 4',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect: 'Nivel 8',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 5,
        effect: 'Nivel 12',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 5,
        effect: 'Nivel 16',
      },
    },
  },
  {
    id: '349',
    name: 'Paralización Nigromántica',
    magicPath: MagicPath.Necromancy,
    level: 20,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '10 metros de radio / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: '25 metros de radio / RM 140',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '50 metros de radio / RM 160',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '100 metros de radio / RM 180',
      },
    },
  },
  {
    id: '350',
    name: 'Necromitud',
    magicPath: MagicPath.Necromancy,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: '50 puntos de vida',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: '100 puntos de vida',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: '150 puntos de vida',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        effect: '250 puntos de vida',
      },
    },
  },
  {
    id: '351',
    name: 'Descarga de muerte',
    magicPath: MagicPath.Necromancy,
    level: 26,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        effect: 'Daño 80',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        effect: 'Daño 100',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: 'Daño 140',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        effect: 'Daño 180',
      },
    },
  },
  {
    id: '352',
    name: 'Levantar cadáveres',
    magicPath: MagicPath.Necromancy,
    level: 28,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '100 puntos de presencia (Nivel máximo 0)',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '300 puntos de presencia (Nivel máximo 1)',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 300,
        maintainedZeonCost: 25,
        effect: '600 puntos de presencia (Nivel máximo 2)',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 450,
        maintainedZeonCost: 30,
        effect: '1.000 puntos de presencia (Nivel máximo 3)',
      },
    },
  },
  {
    id: '353',
    name: 'Cuerpo muerto',
    magicPath: MagicPath.Necromancy,
    level: 30,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Personajes de Nivel 3',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Personajes de Nivel 6',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Personajes de Nivel 12',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'Personajes de Nivel 18',
      },
    },
  },
  {
    id: '354',
    name: 'Drenar magia',
    magicPath: MagicPath.Necromancy,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        effect: 'RM 140',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        effect: 'RM 180',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 220,
        effect: 'RM 220',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: 'RM 260',
      },
    },
  },
  {
    id: '355',
    name: 'Destruir no muertos',
    magicPath: MagicPath.Necromancy,
    level: 36,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: 'RM 140',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 160,
        effect: 'RM 180',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        effect: 'RM 220',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 320,
        effect: 'RM 260',
      },
    },
  },
  {
    id: '356',
    name: 'Drenar características',
    magicPath: MagicPath.Necromancy,
    level: 38,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'RM 140',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: 'RM 180',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'RM 220',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 320,
        maintainedZeonCost: 15,
        effect: 'RM 260',
      },
    },
  },
  {
    id: '357',
    name: 'Controlar a los muertos',
    magicPath: MagicPath.Necromancy,
    level: 40,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '20 metros de radio / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '50 metros de radio / RM 140',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '100 metros de radio / RM 160',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: '150 metros de radio / RM 180',
      },
    },
  },
  {
    id: '358',
    name: 'Marchitar la vida',
    magicPath: MagicPath.Necromancy,
    level: 42,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '10 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '20 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '30 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 220,
        maintainedZeonCost: 25,
        effect: '50 metros de radio',
      },
    },
  },
  {
    id: '359',
    name: 'Escudo nigromántico',
    magicPath: MagicPath.Necromancy,
    level: 46,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: '1.000 puntos de Resistencia',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 160,
        maintainedZeonCost: 10,
        effect: '2.000 puntos de Resistencia',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: '3.500 puntos de Resistencia',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: '5.000 puntos de Resistencia',
      },
    },
  },
  {
    id: '360',
    name: 'Dominar la vida',
    magicPath: MagicPath.Necromancy,
    level: 48,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        maintainedZeonCost: 30,
        effect: 'RM 100',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 180,
        maintainedZeonCost: 40,
        effect: 'RM 120',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 50,
        effect: 'RM 140',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: 'RM 160',
      },
    },
  },
  {
    id: '361',
    name: 'Estigma vampírico',
    magicPath: MagicPath.Necromancy,
    level: 50,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '20% de absorción',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '40% de absorción',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '60% de absorción',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '100% de absorción',
      },
    },
  },
  {
    id: '362',
    name: 'Forma espectral',
    magicPath: MagicPath.Necromancy,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect:
          'El brujo es inmaterial y sólo puede ser dañado por ataques que afecten energia',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect:
          'si lo tocan, RF o RM (PREx2) o negativo acción y vida = ½ fracaso',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'pero gana vida = a la cantidad que pierde ser que lo toco.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Si falla la RM o RF por más de 40 puntos muere',
      },
    },
  },
  {
    id: '363',
    name: 'Modificación nigromántica',
    magicPath: MagicPath.Necromancy,
    level: 56,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '100 PD',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '200 PD',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '300 PD',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: '400 PD',
      },
    },
  },
  {
    id: '364',
    name: 'Llamar a los muertos',
    magicPath: MagicPath.Necromancy,
    level: 58,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        effect: 'Nivel máximo 3',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        effect: 'Nivel máximo 6',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 160,
        effect: 'Nivel máximo 9',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 180,
        effect: 'Nivel máximo 12',
      },
    },
  },
  {
    id: '365',
    name: 'Levantar espectros',
    magicPath: MagicPath.Necromancy,
    level: 60,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Nivel máximo del espíritu 1 / Suma total de presencias 100',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Nivel máximo del espíritu 2 / Suma total de presencias 160',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 280,
        maintainedZeonCost: 30,
        effect: 'Nivel máximo del espíritu 4 / Suma total de presencias 220',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        maintainedZeonCost: 35,
        effect: 'Nivel máximo del espíritu 6 / Suma total de presencias 280',
      },
    },
  },
  {
    id: '366',
    name: 'Drenar fuerza vital',
    magicPath: MagicPath.Necromancy,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        effect: 'RM 100',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: 'RM 130',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: 'RM 160',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 360,
        effect: 'RM 190',
      },
    },
  },
  {
    id: '367',
    name: 'Matar',
    magicPath: MagicPath.Necromancy,
    level: 66,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: 'RM o RF 80',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        effect: 'RM o RF 100',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 180,
        effect: 'RM o RF 120',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 220,
        effect: 'RM o RF 140',
      },
    },
  },
  {
    id: '368',
    name: 'Descarga de almas',
    magicPath: MagicPath.Necromancy,
    level: 68,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        effect: 'Daño 100',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 260,
        effect: 'Daño 200',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 380,
        effect: 'Daño 300',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        effect: 'Daño 400',
      },
    },
  },
  {
    id: '369',
    name: 'Quimera nigromántica',
    magicPath: MagicPath.Necromancy,
    level: 70,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 250,
        maintainedZeonCost: 50,
        effect: 'Nivel 2',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 360,
        maintainedZeonCost: 80,
        effect: 'Nivel 6',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: 'Nivel 10',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 800,
        maintainedZeonCost: 160,
        effect: 'Nivel 13',
      },
    },
  },
  {
    id: '370',
    name: 'Perversion de vida',
    magicPath: MagicPath.Necromancy,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        effect: 'RM o RF 100',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        effect: 'RM o RF 140',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: 'RM o RF 180',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 360,
        effect: 'RM o RF 220',
      },
    },
  },
  {
    id: '371',
    name: 'Vasallaje',
    magicPath: MagicPath.Necromancy,
    level: 76,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        effect: 'RM o RF 100',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 360,
        effect: 'RM o RF 140',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 450,
        effect: 'RM o RF 180',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 540,
        effect: 'RM o RF 220',
      },
    },
  },
  {
    id: '372',
    name: 'Drenar almas',
    magicPath: MagicPath.Necromancy,
    level: 78,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 280,
        effect: 'RM 160',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '373',
    name: 'Superar la muerte',
    magicPath: MagicPath.Necromancy,
    level: 80,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        effect: 'Mas 100 PD / hasta 100 PD opcionales en desventajas',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 500,
        effect: 'Mas 200 PD / hasta 100 PD opcionales en desventajas',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 1000,
        effect: 'Mas 300 PD / hasta 200 PD opcionales en desventajas',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 2500,
        effect: 'Mas 400 PD / hasta 200 PD opcionales en desventajas',
      },
    },
  },
  {
    id: '374',
    name: 'Alzamiento',
    magicPath: MagicPath.Necromancy,
    level: 82,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 350,
        effect: 'Nivel máximo 3',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 500,
        effect: 'Nivel máximo 6',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 800,
        effect: 'Nivel máximo 9',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 1200,
        effect: 'Nivel máximo 12',
      },
    },
  },
  {
    id: '375',
    name: 'Pozo de vida',
    magicPath: MagicPath.Necromancy,
    level: 86,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: '50 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 400,
        maintainedZeonCost: 20,
        effect: '250 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 500,
        maintainedZeonCost: 25,
        effect: '500 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 600,
        maintainedZeonCost: 30,
        effect: '1 kilometro de radio',
      },
    },
  },
  {
    id: '376',
    name: 'Tierra maldita',
    magicPath: MagicPath.Necromancy,
    level: 88,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: '1 kilómetro de radio',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: '10 kilómetros de radio',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 900,
        maintainedZeonCost: 90,
        effect: '100 kilómetros de radio',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 1500,
        maintainedZeonCost: 150,
        effect: '1.000 kilómetros de radio',
      },
    },
  },
  {
    id: '377',
    name: 'Sostenimiento',
    magicPath: MagicPath.Necromancy,
    level: 90,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: 'Hasta 60 de presencia',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 300,
        effect: 'Hasta 120 de presencia',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 400,
        effect: 'Hasta 240 de presencia',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 500,
        effect: 'Hasta 480 de presencia',
      },
    },
  },
  {
    id: '378',
    name: 'Materia prima',
    magicPath: MagicPath.Necromancy,
    level: 92,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        effect: '1.000 cuerpos humanos',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 500,
        effect: '10.000 cuerpos humanos',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 900,
        effect: '100.000 cuerpos humanos',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1500,
        effect: '1.000.000 cuerpos humanos',
      },
    },
  },
  {
    id: '379',
    name: 'Señor de los muertos',
    magicPath: MagicPath.Necromancy,
    level: 96,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '100 kilómetros de radio / 140 RM',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: '1.000 kilómetros de radio / 160 RM',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 1000,
        maintainedZeonCost: 100,
        effect: '10.000 kilómetros de radio / 180 RM',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 2000,
        maintainedZeonCost: 200,
        effect: '100.000 kilómetros de radio / 200 RM',
      },
    },
  },
  {
    id: '380',
    name: 'Regresar de entre los muertos',
    magicPath: MagicPath.Necromancy,
    level: 98,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 16,
        zeonCost: 400,
        effect: 'Nivel 4 máximo / Un mes como máximo desde su muerte',
      },
      intermediate: {
        intelligenceRequirement: 17,
        zeonCost: 800,
        effect: 'Nivel 8 máximo / Un año como máximo desde su muerte',
      },
      advanced: {
        intelligenceRequirement: 18,
        zeonCost: 1600,
        effect: 'Nivel 12 máximo / Una decada como máximo desde su muerte',
      },
      arcane: {
        intelligenceRequirement: 19,
        zeonCost: 3200,
        effect: 'Nivel 16 máximo / Un siglo como máximo desde su muerte',
      },
    },
  },
  {
    id: '381',
    name: 'El despertar',
    magicPath: MagicPath.Necromancy,
    level: 100,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 17,
        zeonCost: 900,
        maintainedZeonCost: 45,
        effect: 'Nivel 4',
      },
      intermediate: {
        intelligenceRequirement: 18,
        zeonCost: 2000,
        maintainedZeonCost: 100,
        effect: 'Nivel 8',
      },
      advanced: {
        intelligenceRequirement: 19,
        zeonCost: 3500,
        maintainedZeonCost: 175,
        effect: 'Nivel 12',
      },
      arcane: {
        intelligenceRequirement: 20,
        zeonCost: 5000,
        maintainedZeonCost: 250,
        effect: 'Nivel 15',
      },
    },
  },
  {
    id: '382',
    name: 'Apertura',
    magicPath: MagicPath.FreeAccess,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        effect: '80 en Cerrajería',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 70,
        effect: '140 en Cerrajería',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: '240 en Cerrajería',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        effect: '280 en Cerrajería',
      },
    },
  },
  {
    id: '383',
    name: 'Mover objetos',
    magicPath: MagicPath.FreeAccess,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: '10 kilogramos',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 70,
        maintainedZeonCost: 10,
        effect: '50 kilogramos',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: '100 kilogramos',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '150 kilogramos',
      },
    },
  },
  {
    id: '384',
    name: 'Aseamiento',
    magicPath: MagicPath.FreeAccess,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        effect: 'Presencia máxima 40',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        effect: 'Presencia máxima 80',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: 'Presencia máxima 120',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        effect: 'Presencia máxima 140',
      },
    },
  },
  {
    id: '385',
    name: 'Detección mágica',
    magicPath: MagicPath.FreeAccess,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: '25 metros de radio / 140 Valoración mágica',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: '100 metros de radio / 180 Valoración mágica',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '200 metros de radio / 200 Valoración mágica',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: '300 metros de radio / 240 Valoración mágica',
      },
    },
  },
  {
    id: '386',
    name: 'Crear música',
    magicPath: MagicPath.FreeAccess,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '10 metros de radio / 80 en Música',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: '50 metros de radio / 12 en Música',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: '150 metros de radio / 180 en Música',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '250 metros de radio / 240 en Música',
      },
    },
  },
  {
    id: '387',
    name: 'Atar',
    magicPath: MagicPath.FreeAccess,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        effect: '120 en Trucos de Manos',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: '140 en Trucos de Manos',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        effect: '180 en Trucos de Manos',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 160,
        effect: '240 en Trucos de Manos',
      },
    },
  },
  {
    id: '388',
    name: 'Crear llamas',
    magicPath: MagicPath.FreeAccess,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: '1 intensidad',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '3 intensidades',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 110,
        maintainedZeonCost: 10,
        effect: '6 intensidades',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 130,
        maintainedZeonCost: 15,
        effect: '9 intensidades',
      },
    },
  },
  {
    id: '389',
    name: 'Deshacer escritura',
    magicPath: MagicPath.FreeAccess,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        effect:
          'Presencia máxima del objeto 30 / Hace desaparecer 500 caracteres',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect:
          'Presencia máxima del objeto 60 / Hace desaparecer 5.000 caracteres',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        effect:
          'Presencia máxima del objeto 90 / Hace desaparecer 50.000 caracteres',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 160,
        effect:
          'Presencia máxima del objeto 120 / Hace desaparecer 250.000 caracteres',
      },
    },
  },
  {
    id: '390',
    name: 'Detener caída',
    magicPath: MagicPath.FreeAccess,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: '50 metros de caída / Presencia máxima 60',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 15,
        effect: '150 metros de caída / Presencia máxima 160',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: '500 metros de caída / Presencia máxima 240',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Cualquier distancia de caída / Presencia máxima 320',
      },
    },
  },
  {
    id: '391',
    name: 'Mensaje estático',
    magicPath: MagicPath.FreeAccess,
    level: 2,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: 'Máximo de 50 palabras',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 70,
        maintainedZeonCost: 10,
        effect: 'Máximo de 150 palabras',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Máximo de 250 palabras',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'Máximo de 500 palabras',
      },
    },
  },
  {
    id: '392',
    name: 'Saltar',
    magicPath: MagicPath.FreeAccess,
    level: 2,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '+50 a Saltar',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '+100 a Saltar',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect:
          '+150  a Saltar / Pernite alcanzar la dificultad de Inhumano en los controles',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect:
          '+200 a Saltar/ Permite alcanzar la dificultad de Zen en los controles',
      },
    },
  },
  {
    id: '393',
    name: 'Cambiar de color',
    magicPath: MagicPath.FreeAccess,
    level: 2,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 40 / RM 100',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 60 / RM 120',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 80 / RM 140',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 100 / RM 160',
      },
    },
  },
  {
    id: '394',
    name: 'Crear sonidos',
    magicPath: MagicPath.FreeAccess,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: 'A 50 metros',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'A 200 metros',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'A 500 metros',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'A 1 kilometro',
      },
    },
  },
  {
    id: '395',
    name: 'Recrear imagen',
    magicPath: MagicPath.FreeAccess,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: 'Imagen de 1 metro cuadrado',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 70,
        maintainedZeonCost: 10,
        effect: 'Imagen de 5 metros cuadrados',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Imagen de 10 metros cuadrados',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 130,
        maintainedZeonCost: 15,
        effect:
          '15 metros² /Imagen realista, Advertir a 140 o Buscar a 80 para darse cuenta',
      },
    },
  },
  {
    id: '396',
    name: 'Encantar',
    magicPath: MagicPath.FreeAccess,
    level: 12,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 40',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 60',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 110,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 90',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 130,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 120',
      },
    },
  },
  {
    id: '397',
    name: 'Zona resbaladiza',
    magicPath: MagicPath.FreeAccess,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '5 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '25 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 160,
        maintainedZeonCost: 10,
        effect: '50 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect:
          '100 metros de radio / Pasar lentamente control contra 140, y corriendo a 280',
      },
    },
  },
  {
    id: '398',
    name: 'Reparación',
    magicPath: MagicPath.FreeAccess,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        effect: 'Presencia máxima 30',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        effect: 'Presencia máxima 50',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        effect: 'Presencia máxima 70',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 150,
        effect: 'Presencia máxima 90',
      },
    },
  },
  {
    id: '399',
    name: 'Trepar',
    magicPath: MagicPath.FreeAccess,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'Más 50 a Trepar',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Más 100 a Trepar',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect:
          'Más 150  a Trepar / Pernite alcanzar la dificultad de Inhumano en los controles',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect:
          'Más 200 a Trepar/ Permite alcanzar la dificultad de Zen en los controles',
      },
    },
  },
  {
    id: '400',
    name: 'Bolsa infinita',
    magicPath: MagicPath.FreeAccess,
    level: 12,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: 'x10 su capacidad',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'x30 su capacidad',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'x40 su capacidad',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'x50 su capacidad',
      },
    },
  },
  {
    id: '401',
    name: 'Atraer alimañas menores',
    magicPath: MagicPath.FreeAccess,
    level: 12,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: 'Atrae hasta a quinientos seres',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'Atrae hasta diez mil seres',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: 'Atrae hasta cien mil seres',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 180,
        maintainedZeonCost: 15,
        effect: 'Atrae a varios millones de seres',
      },
    },
  },
  {
    id: '402',
    name: 'Respirar líquidos',
    magicPath: MagicPath.FreeAccess,
    level: 12,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 60',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 100',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 200',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 160,
        maintainedZeonCost: 15,
        effect: 'Presencia máxima 320',
      },
    },
  },
  {
    id: '403',
    name: 'Inhumanidad',
    magicPath: MagicPath.FreeAccess,
    level: 12,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'Permite alcanzar Inhumanidad en una habilidad determinada',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'Permite alcanzar Inhumanidad en cualquier habilidad',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 110,
        maintainedZeonCost: 10,
        effect:
          'Permite alcanzar Inhumanidad en cualquier habilidad y Zen en una habilidad',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Permite alcanzar Zen en cualquier habilidad',
      },
    },
  },
  {
    id: '404',
    name: 'Pasar sin dejar marca',
    magicPath: MagicPath.FreeAccess,
    level: 12,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 120',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'Presencia máxima 180',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 220,
        maintainedZeonCost: 25,
        effect:
          'Presencia máxima 240 / La dificultad para rastrearle aumenta a Inhumano',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 340,
        maintainedZeonCost: 35,
        effect:
          'Presencia máxima 320 / La dificultad para rastrearle aumenta a Zen',
      },
    },
  },
  {
    id: '405',
    name: 'Niebla',
    magicPath: MagicPath.FreeAccess,
    level: 12,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '100 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        maintainedZeonCost: 20,
        effect: '250 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '500 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '1 kilómetro de radio',
      },
    },
  },
  {
    id: '406',
    name: 'Causar miedo',
    magicPath: MagicPath.FreeAccess,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '5 metros de radio / RM 100',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '15 metros de radio / RM 120',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '25 metros de radio / RM 140',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: '50 metros de radio / RM 160',
      },
    },
  },
  {
    id: '407',
    name: 'Serenidad',
    magicPath: MagicPath.FreeAccess,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 70,
        maintainedZeonCost: 10,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 160',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 130,
        maintainedZeonCost: 15,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '408',
    name: 'Cerrar mágicamente',
    magicPath: MagicPath.FreeAccess,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 100,
        effect: 'Más 1 nivel de dificultad',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        effect: 'Más 2 niveles de dificultad',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 140,
        effect: 'Más 3 niveles de dificultad',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 160,
        effect: 'Más 4 niveles de dificultad',
      },
    },
  },
  {
    id: '409',
    name: 'Escudo mágico',
    magicPath: MagicPath.FreeAccess,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '300 puntos de resistencia',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        maintainedZeonCost: 20,
        effect: '1.000 puntos de resistencia',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '2.000 puntos de resistencia',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '3.000 puntos de resistencia',
      },
    },
  },
  {
    id: '410',
    name: 'Protección mágica',
    magicPath: MagicPath.FreeAccess,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'TA 2',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'TA 4',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'TA 6',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'TA 8',
      },
    },
  },
  {
    id: '411',
    name: 'Comprensión de idiomas',
    magicPath: MagicPath.FreeAccess,
    level: 22,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 100,
        maintainedZeonCost: 20,
        effect: 'Afecta a idiomas básicos, conocidos y hablados comúnmente',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 160,
        maintainedZeonCost: 35,
        effect:
          'Idiomas extraños, hablados por pocos grupos o que no se usan en sociedad',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect: 'Afecta a idiomas únicos y olvidados',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 240,
        maintainedZeonCost: 50,
        effect: 'Afecta a cualquier idioma',
      },
    },
  },
  {
    id: '412',
    name: 'Nubes',
    magicPath: MagicPath.FreeAccess,
    level: 22,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '100 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '250 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '500 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 260,
        maintainedZeonCost: 30,
        effect: '1 kilómetro de radio',
      },
    },
  },
  {
    id: '413',
    name: 'Red',
    magicPath: MagicPath.FreeAccess,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Effect, SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: '3 metros cuadrados / 500 Puntos de vida',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect: '6 metros cuadrados / 750 Puntos de vida',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '9 metros cuadrados / 1.000 Puntos de vida',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect:
          '12 metros cuadrados / 1.500 Puntos de vida / Presa con Fuerza 12',
      },
    },
  },
  {
    id: '414',
    name: 'Enviar mensaje',
    magicPath: MagicPath.FreeAccess,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        effect: '10 kilómetros / Hasta 500 palabras',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        effect: '100 kilómetros / Hasta 1.000 palabras',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        effect: '150 kilómetros / Hasta 2.500 palabras',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 140,
        effect: '1.000 kilómetros / Hasta 5.000 palabras',
      },
    },
  },
  {
    id: '415',
    name: 'Celeridad',
    magicPath: MagicPath.FreeAccess,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Más 1 al Tipo de movimiento / +20 al turno',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'Más 2 al tipo de movimiento / +40 al turno',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 160,
        maintainedZeonCost: 15,
        effect: 'Más 4 al Tipo de movimiento / +60 al turno',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: 'Más 6 al Tipo de movimiento / +80 al turno',
      },
    },
  },
  {
    id: '416',
    name: 'Levitación',
    magicPath: MagicPath.FreeAccess,
    level: 22,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'Tipo de vuelo 4',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Tipo de vuelo 6',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Tipo de vuelo 8',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Tipo de vuelo 10',
      },
    },
  },
  {
    id: '417',
    name: 'Protección anticonceptiva',
    magicPath: MagicPath.FreeAccess,
    level: 22,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 80',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 180',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 260',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 380',
      },
    },
  },
  {
    id: '418',
    name: 'Resistencia al dolor',
    magicPath: MagicPath.FreeAccess,
    level: 32,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'Más 50 a Resistir el dolor',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'Más 100 a Resistir el dolor',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Más 150 a Resistir el dolor / Permite alcanzar Inhumanidad',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Más 200 a Resistir el dolor / Permite alcanzar Zen',
      },
    },
  },
  {
    id: '419',
    name: 'Eliminar conjuros',
    magicPath: MagicPath.FreeAccess,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 150,
        effect: 'Valor zeónico máximo 60',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: 'Valor zeónico máximo 80',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect: 'Valor zeónico máximo 100',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 280,
        effect: 'Valor zeónico máximo 120',
      },
    },
  },
  {
    id: '420',
    name: 'Extender la presencia',
    magicPath: MagicPath.FreeAccess,
    level: 32,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '5 metros',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '25 metros',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '50 metros',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect:
          '100 metros / Crea copias de la presencia de los objetos que lleve encima',
      },
    },
  },
  {
    id: '421',
    name: 'Invocar agresividad',
    magicPath: MagicPath.FreeAccess,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '20 metros de radio / RM 80',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '40 metros de radio / RM 100',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 160,
        maintainedZeonCost: 15,
        effect: '60 metros de radio / RM 120',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: '80 metros de radio / RM 140',
      },
    },
  },
  {
    id: '422',
    name: 'Purificación',
    magicPath: MagicPath.FreeAccess,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        effect: 'Nivel máximo del veneno 30',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: 'Nivel máximo del veneno 50',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: 'Nivel máximo del veneno 70',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        effect: 'Nivel máximo del veneno 90',
      },
    },
  },
  {
    id: '423',
    name: 'Curar enfermedades',
    magicPath: MagicPath.FreeAccess,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: 'Nivel máximo de la enfermedad 30 / Presencia máxima 80',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 140,
        effect: 'Nivel máximo de la enfermedad 50 / Presencia máxima 120',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 200,
        effect: 'Nivel máximo de la enfermedad 70 / Presencia máxima 180',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 300,
        effect: 'Nivel máximo de la enfermedad 100 / Presencia máxima 240',
      },
    },
  },
  {
    id: '424',
    name: 'Descarga de magia',
    magicPath: MagicPath.FreeAccess,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        effect: 'Daño 40',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        effect: 'Daño 60',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        effect: 'Daño 80',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 150,
        effect: 'Daño 100',
      },
    },
  },
  {
    id: '425',
    name: 'Cerrar realmente',
    magicPath: MagicPath.FreeAccess,
    level: 32,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 20',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 160,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 40',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 60',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'Presencia máxima 80',
      },
    },
  },
  {
    id: '426',
    name: 'Eliminar sueños',
    magicPath: MagicPath.FreeAccess,
    level: 32,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 160',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'RM 200',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: 'RM 240',
      },
    },
  },
  {
    id: '427',
    name: 'Percibir sentimientos',
    magicPath: MagicPath.FreeAccess,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: '50 metros de radio / RM o RP 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect: '100 metros de radio / RM o RP 140',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '250 metros de radio / RM o RP 160',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: '500 metros de radio / RM o RP 180',
      },
    },
  },
  {
    id: '428',
    name: 'Modificar el tamaño',
    magicPath: MagicPath.FreeAccess,
    level: 32,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Altera 2 puntos de Tamaño/ RM 100',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Altera 4 puntos de Tamaño/ RM 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'Altera 6 puntos de Tamaño/ RM 140',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'Altera 8 puntos de Tamaño/ RM 160',
      },
    },
  },
  {
    id: '429',
    name: 'Cambio de aspecto',
    magicPath: MagicPath.FreeAccess,
    level: 32,
    isDaily: true,
    activationType: [SpellActivationType.Animic, SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RM 100',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 110',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 120',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM 130',
      },
    },
  },
  {
    id: '430',
    name: 'Transporte rápido',
    magicPath: MagicPath.FreeAccess,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        effect: '25 metros',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        effect: '100 metros',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: '200 metros',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        effect: '350 metros',
      },
    },
  },
  {
    id: '431',
    name: 'Enviar sueños',
    magicPath: MagicPath.FreeAccess,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        effect:
          'Sueños vagos,poco claros,imágenes fragmentadas,frases inconexas y sonidos',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        effect:
          'Los Sueños son vagos, pero permiten mostrar secuencias y lugares al soñador',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        effect:
          'Sueños claros con mensaje comprensible, imágenes ideadas por el mago',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect:
          'Sueños claros, permitiendo al soñador preguntar o interactuar con lo que ve',
      },
    },
  },
  {
    id: '432',
    name: 'Maldición',
    magicPath: MagicPath.FreeAccess,
    level: 42,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect:
          'actos concretos y crea infortunio, que no pueda lograr sus objetivos /RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 350,
        maintainedZeonCost: 20,
        effect:
          'Actos genéricos, mala suerte, maldecir una habilidad (–60 por uso) / RM 140',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 500,
        maintainedZeonCost: 25,
        effect:
          'acto o condición, toda clase de efectos negativos, habilidad (–80) / RM 160',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 700,
        maintainedZeonCost: 35,
        effect:
          'Complejidad imaginable y produce efectos mayores, incluso muerte / RM 180',
      },
    },
  },
  {
    id: '433',
    name: 'Mostrar lo invisible',
    magicPath: MagicPath.FreeAccess,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'Radio de 25 metros / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect: 'Radio de 50 metros / RM 160',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'Radio de 100 metros / RM 200',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: 'Radio de 250 metros / RM 240',
      },
    },
  },
  {
    id: '434',
    name: 'Deshacer',
    magicPath: MagicPath.FreeAccess,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        effect: '50 kilos / Resistencia 80',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        effect: '100 kilos / Resistencia 100',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        effect: '250 kilos / Resistencia 100',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 240,
        effect: '500 kilos / Resistencia 140',
      },
    },
  },
  {
    id: '435',
    name: 'Causar enfermedad',
    magicPath: MagicPath.FreeAccess,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        effect: 'Enfermedad de Nivel 30.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: 'Enfermedad de Nivel 50.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        effect: 'Enfermedad de Nivel 70.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 200,
        effect: 'Enfermedad de Nivel 90.',
      },
    },
  },
  {
    id: '436',
    name: 'Anulación de magia',
    magicPath: MagicPath.FreeAccess,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: 'Valor zeónico máximo 60 / 10 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'Valor zeónico máximo 100 / 25 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'Valor zeónico máximo 140 / 50 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 280,
        maintainedZeonCost: 15,
        effect: 'Valor zeónico máximo 180 / 100 metros de radio',
      },
    },
  },
  {
    id: '437',
    name: 'Leer la mente',
    magicPath: MagicPath.FreeAccess,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM o RP 80',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'RM o RP 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'RM o RP 140',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM o RP 180',
      },
    },
  },
  {
    id: '438',
    name: 'Enlentecer',
    magicPath: MagicPath.FreeAccess,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'Menos 2 al Tipo de movimiento / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'Menos 4 al Tipo de movimiento / RM 140',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Menos 8 al Tipo de movimiento / RM 160',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Menos 12 al Tipo de movimiento / RM 180',
      },
    },
  },
  {
    id: '439',
    name: 'Amistad',
    magicPath: MagicPath.FreeAccess,
    level: 42,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RM o RP 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM o RP 140',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM o RP 106',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 220,
        maintainedZeonCost: 25,
        effect: 'RM o RP 180',
      },
    },
  },
  {
    id: '440',
    name: 'Absorber información',
    magicPath: MagicPath.FreeAccess,
    level: 42,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: 'Un libro corto y no muy complejo',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        effect: 'Un grueso volumen de gran complejidad',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 320,
        effect: 'El equivalente a una enciclopedia',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        effect: 'El conocimiento de una biblioteca entera',
      },
    },
  },
  {
    id: '441',
    name: 'Alterar energia',
    magicPath: MagicPath.FreeAccess,
    level: 42,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '10 intensidades / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: '15 intensidades / RM 140',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '20 intensidades / RM 160',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '25 intensidades / RM 180',
      },
    },
  },
  {
    id: '442',
    name: 'Dormir',
    magicPath: MagicPath.FreeAccess,
    level: 52,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '10 metros de radio / RM 80',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '25 metros de radio / RM 100',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 160,
        maintainedZeonCost: 15,
        effect: '50 metros de radio / RM 120',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: '100 metros de radio / RM 140',
      },
    },
  },
  {
    id: '443',
    name: 'Ceguera',
    magicPath: MagicPath.FreeAccess,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Radio de 5 metros / RM 100',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Radio de 25 metros / RM 120',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'Radio de 50 metros / RM 140',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Radio de 100 metros / RM 160',
      },
    },
  },
  {
    id: '444',
    name: 'Sordera',
    magicPath: MagicPath.FreeAccess,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'Radio de 5 metros / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'Radio de 25 metros / RM 140',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'Radio de 50 metros / RM 160',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: 'Radio de 100 metros / RM 180',
      },
    },
  },
  {
    id: '445',
    name: 'Mudez',
    magicPath: MagicPath.FreeAccess,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'Radio de 5 metros / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'Radio de 25 metros / RM 150',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'Radio de 50 metros / RM 190',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: 'Radio de 100 metros / RM 220',
      },
    },
  },
  {
    id: '446',
    name: 'Curar heridas',
    magicPath: MagicPath.FreeAccess,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        effect: '40 Puntos de vida',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        effect: '80 Puntos de vida',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 180,
        effect: '160 Puntos de vida',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 220,
        effect: '320 Puntos de vida',
      },
    },
  },
  {
    id: '447',
    name: 'Visualizar cartografía',
    magicPath: MagicPath.FreeAccess,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        effect: '25 kilómetros de radio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        effect: '100 kilómetros de radio',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        effect: '250 kilómetros de radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        effect: '1.000 kilómetros de radio',
      },
    },
  },
  {
    id: '448',
    name: 'Fusionar con el cuerpo',
    magicPath: MagicPath.FreeAccess,
    level: 52,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 15,
        effect: 'Presencia máxima 80',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 20,
        effect: 'Presencia máxima 180',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 25,
        effect: 'Presencia máxima 280',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 30,
        effect: 'Presencia máxima 320',
      },
    },
  },
  {
    id: '449',
    name: 'Andar por las paredes',
    magicPath: MagicPath.FreeAccess,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'A una cuarta parte del Tipo de Movimiento como máximo',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'A mitad de Tipo de Movimiento como máximo',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Tipo de Movimiento pleno',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect:
          'Puede moverse o quedarse quieto por superficies completamente imposibles.',
      },
    },
  },
  {
    id: '450',
    name: 'Eliminar cansancio',
    magicPath: MagicPath.FreeAccess,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: '1 Punto de Cansancio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: '3 Puntos de Cansancio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: '5 Puntos de Cansancio',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        effect: '7 Puntos de Cansancio',
      },
    },
  },
  {
    id: '451',
    name: 'Montura mágica',
    magicPath: MagicPath.FreeAccess,
    level: 52,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Tipo de movimiento 10, 500 PV, Fuerza 10, Tamaño máximo 20',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'Tipo de movimiento 12, 1.000 PV, Fuerza 12, Tamaño máximo 22',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        maintainedZeonCost: 15,
        effect: 'Tipo de movimiento 14, 1.500 PV, Fuerza 14, Tamaño máximo 24',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 220,
        maintainedZeonCost: 20,
        effect: 'Tipo de movimiento 15, 2.000 PV, Fuerza 15, Tamaño máximo 28',
      },
    },
  },
  {
    id: '452',
    name: 'Nube ácida',
    magicPath: MagicPath.FreeAccess,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RF 120 / 5 metros de radio / Tipo de Vuelo 6',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'RF 140 / 10 metros de radio / Tipo de Vuelo 8',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RF 160 / 20 metros de radio / Tipo de Vuelo 10',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        maintainedZeonCost: 35,
        effect: 'RF 180 / 50 metros de radio / Tipo de Vuelo 12',
      },
    },
  },
  {
    id: '453',
    name: 'Desproteger',
    magicPath: MagicPath.FreeAccess,
    level: 52,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '-2 a la TA / RM 140',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '-4 a la TA / RM 160',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '-6 a la TA / RM 180',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '-8 a la TA / RM 200',
      },
    },
  },
  {
    id: '454',
    name: 'Paralizar',
    magicPath: MagicPath.FreeAccess,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '10 metros de Radio / RM 80',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '25 metros de Radio / RM 100',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '50 metros de Radio / RM 120',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 280,
        maintainedZeonCost: 30,
        effect: '100 metros de Radio / RM 140',
      },
    },
  },
  {
    id: '455',
    name: 'Debilidad',
    magicPath: MagicPath.FreeAccess,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 160',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '456',
    name: 'Arma mágica',
    magicPath: MagicPath.FreeAccess,
    level: 62,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'Calidad +5',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Calidad +10',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Calidad +15',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 280,
        maintainedZeonCost: 30,
        effect: 'Calidad +20',
      },
    },
  },
  {
    id: '457',
    name: 'Resistir',
    magicPath: MagicPath.FreeAccess,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 20,
        effect: '+20 a una Resistencia',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 100,
        maintainedZeonCost: 20,
        effect: '+40 a una Resistencia',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        maintainedZeonCost: 25,
        effect: '+60 a una Resistencia',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 140,
        maintainedZeonCost: 30,
        effect: '+80 a una Resistencia',
      },
    },
  },
  {
    id: '458',
    name: 'Alteración menor',
    magicPath: MagicPath.FreeAccess,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 30.',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 160,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 50.',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'Presencia máxima 70.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        maintainedZeonCost: 20,
        effect: 'Presencia máxima 100.',
      },
    },
  },
  {
    id: '459',
    name: 'Rechazo',
    magicPath: MagicPath.FreeAccess,
    level: 62,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 30 / Fuerza 8',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 5,
        effect: 'Presencia máxima 40 / Fuerza 10',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 50 / Fuerza 12',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 220,
        maintainedZeonCost: 15,
        effect: 'Presencia máxima 60 / Fuerza 14',
      },
    },
  },
  {
    id: '460',
    name: 'Plaga',
    magicPath: MagicPath.FreeAccess,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 140,
        effect: '1 Kilómetro de radio / Nivel máximo de enfermedad 20',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        effect: '5 Kilómetros de radio / Nivel máximo de enfermedad 40',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        effect: '10 Kilómetros de radio / Nivel máximo de enfermedad 60',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 280,
        effect:
          '25 Km de radio / Nivel máximo de enfermedad 80 / La RE es más 10 puntos',
      },
    },
  },
  {
    id: '461',
    name: 'Cuerpo a magia',
    magicPath: MagicPath.FreeAccess,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect:
          'El objetivo es inmune a todos los ataques no basados en energía',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect:
          'el objetivo puede atravesar cualquier materia física no basada en energía',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'el objetivo obtiene un +10 a su ACT',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect:
          'todo daño basado en habilidad mágica o conjuro queda reducido a la mitad',
      },
    },
  },
  {
    id: '462',
    name: 'Aumentar car. físicas',
    magicPath: MagicPath.FreeAccess,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '+1 a la característica',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '+3 a la característica',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '+5 a la característica',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: '+7 a la característica',
      },
    },
  },
  {
    id: '463',
    name: 'Olvido',
    magicPath: MagicPath.FreeAccess,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        effect: 'RM o RP 120',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 160,
        effect: 'RM o RP 140',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 200,
        effect: 'RM o RP 160',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 240,
        effect: 'RM o RP 180',
      },
    },
  },
  {
    id: '464',
    name: 'Crear emociones',
    magicPath: MagicPath.FreeAccess,
    level: 62,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM o RP 120',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM o RP 140',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 220,
        maintainedZeonCost: 25,
        effect: 'RM o RP 160',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 280,
        maintainedZeonCost: 30,
        effect: 'RM o RP 180',
      },
    },
  },
  {
    id: '465',
    name: 'Aumentar car. psíquicas',
    magicPath: MagicPath.FreeAccess,
    level: 62,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '+1 a la característica',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '+3 a la característica',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '+5 a la característica',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: '+7 a la característica',
      },
    },
  },
  {
    id: '466',
    name: 'Esfera de levitación',
    magicPath: MagicPath.FreeAccess,
    level: 72,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'RM 80 / 25 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'RM 100 / 150 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'RM 120 / 250 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'RM 140 / 350 metros de radio',
      },
    },
  },
  {
    id: '467',
    name: 'Estancar conjuro',
    magicPath: MagicPath.FreeAccess,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 150,
        effect: 'Zeón máximo 80',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        effect: 'Zeón máximo 120',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 250,
        effect: 'Zeón máximo 180',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 300,
        effect: 'Zeón máximo 240',
      },
    },
  },
  {
    id: '468',
    name: 'Inutilidad',
    magicPath: MagicPath.FreeAccess,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM 180',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'RM 220',
      },
    },
  },
  {
    id: '469',
    name: 'Desviar trayectoria',
    magicPath: MagicPath.FreeAccess,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Effect, SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        effect: 'RM 140',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 160,
        effect: 'RM 160',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 220,
        effect: 'RM 200',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 300,
        effect: 'RM 240',
      },
    },
  },
  {
    id: '470',
    name: 'Invisibilidad',
    magicPath: MagicPath.FreeAccess,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'Advertir contra Imposible y Buscar contra Absurdo',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Advertir contra Inhumano y Buscar contra Casi Imposible',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Advertir contra Zen y Buscar contra Imposible',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 280,
        maintainedZeonCost: 30,
        effect: 'El blanco no puede ser percibido por el sentido de la vista',
      },
    },
  },
  {
    id: '471',
    name: 'Dominio',
    magicPath: MagicPath.FreeAccess,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'RM o RP 100',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'RM o RP 120',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM o RP 140',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 280,
        maintainedZeonCost: 30,
        effect: 'RM o RP 160',
      },
    },
  },
  {
    id: '472',
    name: 'Marca de detección',
    magicPath: MagicPath.FreeAccess,
    level: 72,
    isDaily: true,
    activationType: [SpellActivationType.Effect, SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '10 kilómetros / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '100 kilómetros / RM 140',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '1.000 kilómetros / RM 160',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'Cualquier distancia / RM 180',
      },
    },
  },
  {
    id: '473',
    name: 'Vuelo',
    magicPath: MagicPath.FreeAccess,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'Tipo de Vuelo Místico 8',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'Tipo de Vuelo Místico 10',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: 'Tipo de Vuelo Místico 12',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 10,
        effect: 'Tipo de Vuelo Místico 14',
      },
    },
  },
  {
    id: '474',
    name: 'Erudición ofensiva',
    magicPath: MagicPath.FreeAccess,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '+20 a la Proyección Mágica Ofensiva',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '+30 a la Proyección Mágica Ofensiva',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 160,
        maintainedZeonCost: 15,
        effect: '+40 a la Proyección mágica ofensiva',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: '+50 a la Proyección mágica ofensiva',
      },
    },
  },
  {
    id: '475',
    name: 'Erudición defensiva',
    magicPath: MagicPath.FreeAccess,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '+20 a la Proyección mágica defensiva',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '+30 a la Proyección mágica defensiva',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 160,
        maintainedZeonCost: 15,
        effect: '+40 a la Proyección mágica defensiva',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: '+50 a la Proyección mágica defensiva',
      },
    },
  },
  {
    id: '476',
    name: 'Contención',
    magicPath: MagicPath.FreeAccess,
    level: 72,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect: '10 metros de radio / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 240,
        maintainedZeonCost: 50,
        effect: '25 metros de radio / RM 140',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 280,
        maintainedZeonCost: 60,
        effect: '50 metros de radio / RM 160',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        maintainedZeonCost: 65,
        effect: '100 metros de radio / RM 180',
      },
    },
  },
  {
    id: '477',
    name: 'Blanco perfecto',
    magicPath: MagicPath.FreeAccess,
    level: 72,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        effect: 'Bono de Habilidad +40',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 100,
        effect: 'Bono de Habilidad +60',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        effect: 'Bono de Habilidad +80',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 140,
        effect: 'Bono de Habilidad +100',
      },
    },
  },
  {
    id: '478',
    name: 'Inmortalidad',
    magicPath: MagicPath.FreeAccess,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: 'Las habilidades descritas',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 400,
        maintainedZeonCost: 20,
        effect:
          'Idem base, invulnerable a venenos o enfermedades que no sean sobrenaturales',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        maintainedZeonCost: 25,
        effect:
          'Idem intermedio, invulnerable a desangramiento o daños físicos sobrenaturales',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 600,
        maintainedZeonCost: 30,
        effect:
          'Idem avanzado, invulnerable a todo daño físico, si no es crítico mortal',
      },
    },
  },
  {
    id: '479',
    name: 'Localización',
    magicPath: MagicPath.FreeAccess,
    level: 82,
    isDaily: false,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 300,
        effect: '100 kilómetros / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 360,
        effect: '1.000 kilómetros / RM 140',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 420,
        effect: '10.000 kilómetros / RM 180',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 480,
        effect: 'Cualquier distancia / RM 220',
      },
    },
  },
  {
    id: '480',
    name: 'Desencantamiento',
    magicPath: MagicPath.FreeAccess,
    level: 82,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: 'Presencia máxima 80',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 250,
        effect: 'Presencia máxima 100',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 320,
        effect: 'Presencia máxima 120',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 400,
        effect: 'Presencia máxima 140',
      },
    },
  },
  {
    id: '481',
    name: 'Eliminar necesidades',
    magicPath: MagicPath.FreeAccess,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: 'Las habilidades descritas',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 360,
        maintainedZeonCost: 20,
        effect:
          'ignora los penalizadores por efectos climáticos naturales como calor o frío',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 420,
        maintainedZeonCost: 25,
        effect:
          'ignorará penalizador natural no provocado por efectos sobrenaturales',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 480,
        maintainedZeonCost: 25,
        effect: 'pero el personaje recupera un punto de Cansancio por asalto',
      },
    },
  },
  {
    id: '482',
    name: 'Inmunidad física',
    magicPath: MagicPath.FreeAccess,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'Presencia máxima 60',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'Presencia máxima 80',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 280,
        maintainedZeonCost: 15,
        effect: 'Presencia máxima 100',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        maintainedZeonCost: 20,
        effect: 'Presencia máxima 120',
      },
    },
  },
  {
    id: '483',
    name: 'Preparar conjuro',
    magicPath: MagicPath.FreeAccess,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Zeon máximo del conjuro 100',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Zeon máximo del conjuro 200',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 360,
        maintainedZeonCost: 40,
        effect: 'Zeon máximo del conjuro 300',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 420,
        maintainedZeonCost: 45,
        effect: 'Zeon máximo del conjuro 400',
      },
    },
  },
  {
    id: '484',
    name: 'Robar conjuro',
    magicPath: MagicPath.FreeAccess,
    level: 82,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: 'Valor zeónico máximo 120 / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 280,
        effect: 'Valor zeónico máximo 180 / RM 140',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 340,
        effect: 'Valor zeónico máximo 240 / RM 160',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 400,
        effect: 'Valor zeónico máximo 300 / RM 180',
      },
    },
  },
  {
    id: '485',
    name: 'Conjuro natural',
    magicPath: MagicPath.FreeAccess,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 350,
        maintainedZeonCost: 70,
        effect: 'Valor zeónico máximo 100',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 420,
        maintainedZeonCost: 85,
        effect: 'Valor zeónico máximo 140',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 480,
        maintainedZeonCost: 100,
        effect: 'Valor zeónico máximo 180',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 540,
        maintainedZeonCost: 110,
        effect: 'Valor zeónico máximo 220',
      },
    },
  },
  {
    id: '486',
    name: 'Prisma de magia',
    magicPath: MagicPath.FreeAccess,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: '400 puntos de Zeon',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: '800 puntos de Zeon',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 280,
        maintainedZeonCost: 20,
        effect: '1.500 puntos de Zeon',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        maintainedZeonCost: 20,
        effect: '3.000 puntos de Zeon',
      },
    },
  },
  {
    id: '487',
    name: 'Devolución de conjuro',
    magicPath: MagicPath.FreeAccess,
    level: 82,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 150,
        effect: 'Zeón máximo del conjuro 100',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        effect: 'Zeón máximo del conjuro 120',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        effect: 'Zeón máximo del conjuro 140',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 280,
        effect: 'Zeón máximo del conjuro 160',
      },
    },
  },
  {
    id: '488',
    name: 'Teletransporte',
    magicPath: MagicPath.FreeAccess,
    level: 82,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 300,
        effect: '50 kilómetros / Presencia máxima 80',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 480,
        effect: '1.000 kilómetros / Presencia máxima 180',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 560,
        effect: '5.000 kilómetros / Presencia máxima 240',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 640,
        effect: '10.000 kilómetros / Presencia máxima 320',
      },
    },
  },
  {
    id: '489',
    name: 'Portal',
    magicPath: MagicPath.FreeAccess,
    level: 82,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 500,
        maintainedZeonCost: 25,
        effect:
          '5 metros de abertura / 1.000 kilómetros de distancia / 500 presencias al día',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 600,
        maintainedZeonCost: 30,
        effect:
          '15 metros de abertura / 5.000 kilómetros de distancia / 1.000 presencias al día',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 700,
        maintainedZeonCost: 35,
        effect:
          '25 metros de abertura / 25.000 kilómetros de distancia / 2.000 presencias al día',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 800,
        maintainedZeonCost: 40,
        effect:
          '50 metros de abertura/cualquier distancia /Sin límite de presencias',
      },
    },
  },
  {
    id: '490',
    name: 'Imitar conjuro',
    magicPath: MagicPath.FreeAccess,
    level: 92,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: 'Valor zeónico máximo 100',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        effect: 'Valor zeónico máximo 150',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 360,
        effect: 'Valor zeónico máximo 200',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 420,
        effect: 'Valor zeónico máximo 250',
      },
    },
  },
  {
    id: '491',
    name: 'Condicionamiento',
    magicPath: MagicPath.FreeAccess,
    level: 92,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 11,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Zeón máximo 100',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: 'Zeón máximo 150',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        maintainedZeonCost: 50,
        effect: 'Zeón máximo 200',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: 'Zeón máximo 250',
      },
    },
  },
  {
    id: '492',
    name: 'Ojo del tiempo',
    magicPath: MagicPath.FreeAccess,
    level: 92,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'Una década',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: 'Un siglo',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 400,
        maintainedZeonCost: 20,
        effect: 'Una milenio',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 500,
        maintainedZeonCost: 25,
        effect: 'Cualquier periodo de tiempo',
      },
    },
  },
  {
    id: '493',
    name: 'Sellar',
    magicPath: MagicPath.FreeAccess,
    level: 92,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        effect: 'Estanca un conjuro de grado base',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 360,
        effect: 'Estanca un conjuro de grado intermedio',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 420,
        effect: 'Estanca un conjuro de grado avanzado',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 480,
        effect: 'Estanca un conjuro de grado arcano',
      },
    },
  },
  {
    id: '494',
    name: 'Vincular mantenimiento',
    magicPath: MagicPath.FreeAccess,
    level: 92,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        effect: 'RM 160',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 250,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '495',
    name: 'Fortalecer la magia',
    magicPath: MagicPath.FreeAccess,
    level: 92,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '+50 al valor zeónico falso de los conjuros',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 360,
        maintainedZeonCost: 40,
        effect: '+100 al valor zeónico falso de los conjuros',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 480,
        maintainedZeonCost: 50,
        effect: '+150 al valor zeónico falso de los conjuros',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: '+250 al valor zeónico falso de los conjuros',
      },
    },
  },
  {
    id: '496',
    name: 'El don del conocimiento',
    magicPath: MagicPath.FreeAccess,
    level: 92,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 30,
        effect: '+100 de bono',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 45,
        effect: '+250 de bono',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        maintainedZeonCost: 60,
        effect: '+400 de bono',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        maintainedZeonCost: 75,
        effect: '+600 de bono',
      },
    },
  },
  {
    id: '497',
    name: 'Posesión',
    magicPath: MagicPath.FreeAccess,
    level: 92,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 500,
        maintainedZeonCost: 50,
        effect: 'RM 180',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: 'RM 220',
      },
    },
  },
  {
    id: '498',
    name: 'Escudar contra poderes',
    magicPath: MagicPath.FreeAccess,
    level: 92,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 11,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect:
          '50m radio / Zeon 100 / Potencial Psí. 140 / Ki 8 / Convocación inferior a 180',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 360,
        maintainedZeonCost: 40,
        effect:
          '150m radio / Zeon 150 / Potencial Psíquico 180 / Ki 14 / Convocación 240',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 420,
        maintainedZeonCost: 45,
        effect:
          '300m radio / Zeon 200 / Potencial Psíquico 240 / Ki 22 / Convocación 280',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 480,
        maintainedZeonCost: 50,
        effect:
          '500m de radio / Zeon 250 / Potencial Psíquico 280 / Ki 30 / Convocación 320',
      },
    },
  },
  {
    id: '499',
    name: 'El magistrado',
    magicPath: MagicPath.FreeAccess,
    level: 92,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 450,
        maintainedZeonCost: 45,
        effect: '50 metros de radio / RM 140',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: '100 metros de radio / RM 180',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 800,
        maintainedZeonCost: 80,
        effect: '500 metros de radio / RM 220',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1000,
        maintainedZeonCost: 100,
        effect: '1 kilómetro de radio / RM 260',
      },
    },
  },
  {
    id: '500',
    name: 'Magia innata',
    magicPath: MagicPath.FreeAccess,
    level: 92,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 50,
        effect: '25 metros de radio / +10 al valor zeónico de la magia innata',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: '100 metros de radio/ +20 al valor zeónico de la magia innata',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        maintainedZeonCost: 70,
        effect: '250 metros de radio / +30 al valor zeónico de la magia innata',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        maintainedZeonCost: 80,
        effect: '500 metros de radio / +40 al valor zeónico de la magia innata',
      },
    },
  },
  {
    id: '501',
    name: 'Predestinación',
    magicPath: MagicPath.FreeAccess,
    level: 92,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 600,
        effect: 'RM 140',
      },
      intermediate: {
        intelligenceRequirement: 15,
        zeonCost: 900,
        effect: 'RM 170',
      },
      advanced: {
        intelligenceRequirement: 17,
        zeonCost: 1200,
        effect: 'RM 200',
      },
      arcane: {
        intelligenceRequirement: 19,
        zeonCost: 1500,
        effect: 'RM 240',
      },
    },
  },
  {
    id: '502',
    name: 'Sentir el caos',
    magicPath: MagicPath.Chaos,
    level: 4,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '50 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '100 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '250 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 12,
        effect:
          '500 metros de radio/Percibe gnosis > 20 o Natura > 10, si no superan RM 160',
      },
    },
  },
  {
    id: '503',
    name: 'Aura de caos',
    magicPath: MagicPath.Chaos,
    level: 14,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 15,
        effect: 'RM 80 / 10 metros de radio / Presencia máxima 100',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 20,
        effect: 'RM 100 / 20 metros de radio / Presencia máxima 120',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 25,
        effect: 'RM 120 / 40 metros de radio / Presencia máxima 140',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 30,
        effect: 'RM 140 / 60 metros de radio / Presencia máxima 160',
      },
    },
  },
  {
    id: '504',
    name: 'Alternancia de la probabilidad',
    magicPath: MagicPath.Chaos,
    level: 24,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 20,
        effect: 'más 2 al rango de pifia / menos 5 al rango de tirada abierta',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 20,
        effect: 'más 4 al rango de pifia / menos 10 al rango de tirada abierta',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 25,
        effect: 'más 6 al rango de pifia / menos 15 al rango de tirada abierta',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 30,
        effect: 'más 8 al rango de pifia / menos 20 al rango de tirada abierta',
      },
    },
  },
  {
    id: '505',
    name: 'Alterar la suerte',
    magicPath: MagicPath.Chaos,
    level: 34,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: ' RM 140                  ',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 15,
        effect:
          'RM 160 / La suerte concedida es algo desproporcionado, rozando lo absurdo',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        maintainedZeonCost: 15,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '506',
    name: 'Caminos del caos',
    magicPath: MagicPath.Chaos,
    level: 44,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 120,
        maintainedZeonCost: 20,
        effect: 'Una tirada por asalto',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'Dos tiradas por asalto',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 280,
        maintainedZeonCost: 25,
        effect: 'Tres tiradas por asalto',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        maintainedZeonCost: 30,
        effect: 'Cuatro tiradas por asalto o una que sea pifia',
      },
    },
  },
  {
    id: '507',
    name: 'Aberración caótica',
    magicPath: MagicPath.Chaos,
    level: 54,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        effect: 'Nivel 2',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: 'Nivel 4',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        effect: 'Nivel 8',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 700,
        effect: 'Nivel 12',
      },
    },
  },
  {
    id: '508',
    name: 'Manipulación del caos',
    magicPath: MagicPath.Chaos,
    level: 64,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: 'RM 140',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        effect: 'RM 180 / puede obligar a repetir la tirada dos veces',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 300,
        effect: 'RM 220 /  Puede obligar a repetir la tirada tres veces',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 400,
        effect: 'RM 260/ Puede obligar a repetir la tirada cuatro veces',
      },
    },
  },
  {
    id: '509',
    name: 'Mutación caótica',
    magicPath: MagicPath.Chaos,
    level: 74,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 200,
        effect: '100 PD / 100 RM',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 400,
        effect: '200 PD / 120 RM',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 800,
        effect: '300 PD / 140 RM',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 1200,
        effect: '400 PD / Gnosis 30 / 160 RM',
      },
    },
  },
  {
    id: '510',
    name: 'Control del caos',
    magicPath: MagicPath.Chaos,
    level: 84,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 11,
        zeonCost: 300,
        maintainedZeonCost: 50,
        effect: 'Hasta Gnosis 30 / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 500,
        maintainedZeonCost: 60,
        effect: 'Hasta Gnosis 35 / RM 160.',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 800,
        maintainedZeonCost: 67,
        effect: 'Hasta Gnosis 40 / RM 200',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 1200,
        maintainedZeonCost: 75,
        effect: 'Hasta Gnosis 45 / RM 260',
      },
    },
  },
  {
    id: '511',
    name: 'Caos primario',
    magicPath: MagicPath.Chaos,
    level: 94,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: '500 metros',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 900,
        maintainedZeonCost: 180,
        effect: '1 kilómetro',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 1500,
        maintainedZeonCost: 300,
        effect:
          '5 kilómetro / Tras un minuto, cualquiera es afectado por  Mutación Caótica',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 2400,
        maintainedZeonCost: 480,
        effect:
          '25 kilómetros/ A menos de un kilómetro del núcleo, RM 150 o descreación',
      },
    },
  },
  {
    id: '512',
    name: 'Moral',
    magicPath: MagicPath.War,
    level: 4,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        effect: 'Da +40 a Frialdad / 10m radio',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        effect: 'Da +80 a Frialdad / 50m radio',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 90,
        effect: 'Da +120 a Frialdad / 250m radio',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        effect: 'Da +180 a Frialdad / 500m radio',
      },
    },
  },
  {
    id: '513',
    name: 'Ira ancestral',
    magicPath: MagicPath.War,
    level: 14,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: '10m radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 70,
        maintainedZeonCost: 10,
        effect: '50m radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '250m radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 130,
        maintainedZeonCost: 15,
        effect: '500m radio',
      },
    },
  },
  {
    id: '514',
    name: 'Velocidad en la batalla',
    magicPath: MagicPath.War,
    level: 24,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'Da +10 Turno / 10m radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: 'Da +10 Turno / 50m radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Da +20 Turno / 250m radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Da +20 Turno / 500m radio',
      },
    },
  },
  {
    id: '515',
    name: 'Destrucción desencadenada',
    magicPath: MagicPath.War,
    level: 34,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'Da +20 Daño / 50m radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Da +20 Daño / 10m radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'Da +30 Daño / 250m radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Da +40 Daño / 500m radio',
      },
    },
  },
  {
    id: '516',
    name: 'Protección final',
    magicPath: MagicPath.War,
    level: 44,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'Da +10 a Todas las Resistencias / +1 TA / 10m radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Da +10 a Todas las Resistencias / +2 TA / 50m radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: 'Da +20 a Todas las Resistencias / +2 TA / 250m radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Da +30 a Todas las Resistencias / +3 TA / 500m radio',
      },
    },
  },
  {
    id: '517',
    name: 'Marcha implacable',
    magicPath: MagicPath.War,
    level: 54,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 150,
        maintainedZeonCost: 30,
        effect: '10m radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect: '50m radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 50,
        effect: '100m radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 100,
        effect: '500m radio',
      },
    },
  },
  {
    id: '518',
    name: 'Campeón',
    magicPath: MagicPath.War,
    level: 64,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'Da +10 a Toda Acción / +10 Daño / +10 Turno / +2 TA',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        maintainedZeonCost: 15,
        effect: 'Da +10 a Toda Acción / +20 Daño / +20 Turno / +3 TA',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        maintainedZeonCost: 20,
        effect: 'Da +20 a Toda Acción / +20 Daño / +20 Turno / +4 TA',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 350,
        maintainedZeonCost: 30,
        effect: 'Da +30 a Toda Acción / +30 Daño / +30 Turno / +5 TA',
      },
    },
  },
  {
    id: '519',
    name: 'Órdenes precisas',
    magicPath: MagicPath.War,
    level: 74,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        effect: '25m radio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: '100m radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        effect: '250m radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        effect: '500m radio',
      },
    },
  },
  {
    id: '520',
    name: 'Maestro de la guerra',
    magicPath: MagicPath.War,
    level: 84,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: '200 Habilidad',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: '250 Habilidad',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 400,
        maintainedZeonCost: 20,
        effect: '300 Habilidad',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 500,
        maintainedZeonCost: 25,
        effect: 'Sin Límite',
      },
    },
  },
  {
    id: '521',
    name: 'Hasta más allá del fin',
    magicPath: MagicPath.War,
    level: 94,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 14,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: '25m radio',
      },
      intermediate: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        maintainedZeonCost: 50,
        effect: '50m radio',
      },
      advanced: {
        intelligenceRequirement: 18,
        zeonCost: 750,
        maintainedZeonCost: 75,
        effect: '250m radio',
      },
      arcane: {
        intelligenceRequirement: 20,
        zeonCost: 1000,
        maintainedZeonCost: 100,
        effect:
          '500m radio / Puede seguir luchando  hasta 2 asaltos después de muerto',
      },
    },
  },
  {
    id: '522',
    name: 'Pluma de la realidad',
    magicPath: MagicPath.Literae,
    level: 4,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: 'Afecta a conjuros Literae de grado base.',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Afecta a conjuros Literae de grado intermedio.',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Afecta a conjuros Literae de grado avanzado.',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'Afecta a conjuros Literae de grado arcano.',
      },
    },
  },
  {
    id: '523',
    name: 'Misiva',
    magicPath: MagicPath.Literae,
    level: 14,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        effect: '10 kilómetros',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        effect: '100 kilómetros',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        effect: '1000 kilómetros',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 280,
        effect: 'Cualquier Lugar',
      },
    },
  },
  {
    id: '524',
    name: 'Diario de viaje',
    magicPath: MagicPath.Literae,
    level: 24,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 100,
        maintainedZeonCost: 15,
        effect: '1 día',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '1 semana',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '1 mes',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '1 año',
      },
    },
  },
  {
    id: '525',
    name: 'Orden escrita',
    magicPath: MagicPath.Literae,
    level: 34,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        effect: 'RM o RP 120',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 240,
        effect: 'RM o RP 140',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 360,
        effect: 'RM o RP 160',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 480,
        effect: 'RM o RP 180',
      },
    },
  },
  {
    id: '526',
    name: 'Escudo de palabras',
    magicPath: MagicPath.Literae,
    level: 44,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '200 puntos de resistencia',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '500 puntos de resistencia',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: '800 puntos de resistencia',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '1.200 puntos de resistencia',
      },
    },
  },
  {
    id: '527',
    name: 'Teatro de la vida',
    magicPath: MagicPath.Literae,
    level: 54,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: 'RM o RP 120/ Límite de Presencia 100/ 20 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 360,
        maintainedZeonCost: 20,
        effect: 'RM o RP 140/ Límite de Presencia 180/ 40 metros de radio.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 480,
        maintainedZeonCost: 25,
        effect: 'RM o RP 160/ Límite de Presencia 240/ 80 metros de radio.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 600,
        maintainedZeonCost: 30,
        effect: 'RM o RP 180/ Límite de Presencia 320/ 150 metros de radio.',
      },
    },
  },
  {
    id: '528',
    name: 'Compendio',
    magicPath: MagicPath.Literae,
    level: 64,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '120 puntos de habilidad intelectual',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 260,
        maintainedZeonCost: 20,
        effect: '240 puntos de habilidad intelectual',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 380,
        maintainedZeonCost: 30,
        effect: '320 puntos de habilidad intelectual',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        maintainedZeonCost: 40,
        effect: '440 puntos de habilidad intelectual',
      },
    },
  },
  {
    id: '529',
    name: 'Dramaturgo',
    magicPath: MagicPath.Literae,
    level: 74,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 280,
        effect: 'Presencia máxima 40',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 320,
        effect: 'Presencia máxima 80',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 480,
        effect: 'Presencia máxima 120',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 700,
        effect: 'Presencia máxima 160',
      },
    },
  },
  {
    id: '530',
    name: 'Secundario',
    magicPath: MagicPath.Literae,
    level: 84,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: 'Nivel 2',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: 'Nivel 5',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 800,
        maintainedZeonCost: 80,
        effect: 'Nivel 7',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 1200,
        maintainedZeonCost: 120,
        effect: 'Nivel 10',
      },
    },
  },
  {
    id: '531',
    name: 'Tragedia',
    magicPath: MagicPath.Literae,
    level: 94,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 500,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 15,
        zeonCost: 900,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 17,
        zeonCost: 1400,
        effect: 'RM 160',
      },
      arcane: {
        intelligenceRequirement: 19,
        zeonCost: 2500,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '532',
    name: 'Autopsia',
    magicPath: MagicPath.Death,
    level: 4,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 4,
        zeonCost: 50,
        effect: '1 hora',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: '1 día',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: '1 semana',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: '1 mes',
      },
    },
  },
  {
    id: '533',
    name: 'Resistencia a la muerte',
    magicPath: MagicPath.Death,
    level: 14,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: '+20 a resistencias',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 10,
        effect: '+40 a resistencias',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '+60 a resistencias',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '+80 a resistencias',
      },
    },
  },
  {
    id: '534',
    name: 'Exterminio menor',
    magicPath: MagicPath.Death,
    level: 24,
    isDaily: false,
    activationType: [SpellActivationType.Effect, SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        effect: 'RM 60 / 10 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        effect: 'RM 80 / 25 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        effect: 'RM 100 / 50 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 140,
        effect: 'RM 120 / 75 metros de radio',
      },
    },
  },
  {
    id: '535',
    name: 'Golpe de gracia',
    magicPath: MagicPath.Death,
    level: 34,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 60,
        effect: 'RM 140',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        effect: 'RM 160',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 100,
        effect: 'RM 180',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        effect: 'RM 200',
      },
    },
  },
  {
    id: '536',
    name: 'Poner en reposo',
    magicPath: MagicPath.Death,
    level: 44,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 60,
        effect: 'RM 120 / 10 m. de radio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        effect: 'RM 140 / 25 m. de radio',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        effect: 'RM 160 / 50 m. de radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        effect: 'RM 180 / 75 m. de radio',
      },
    },
  },
  {
    id: '537',
    name: 'Sentir la muerte (Muerte)',
    magicPath: MagicPath.Death,
    level: 54,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect:
          'En 100 m detecta muertes hace < 1 hora, o moribundo morirá < 1 minuto',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect:
          '150 m, muertes hace < 6 horas, o si alguien  va a morir en diez minutos.',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect:
          'Percibe tiempo de vida de una persona, y si alguien ha muerto en la zona',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Percibe las causas de muertes pasadas y futuras',
      },
    },
  },
  {
    id: '538',
    name: 'Memento mori',
    magicPath: MagicPath.Death,
    level: 64,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        effect: 'RM 80 / 5 m. radio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 150,
        effect: 'RM 100 / 10 m. radio',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 200,
        effect: 'RM 120 / 25 m. radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 260,
        effect: 'RM 140 / 50 m. radio',
      },
    },
  },
  {
    id: '539',
    name: 'Maldición mortal',
    magicPath: MagicPath.Death,
    level: 74,
    isDaily: false,
    activationType: [SpellActivationType.Animic, SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'RM 160',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: 'RM 200',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        maintainedZeonCost: 50,
        effect: 'RM 240',
      },
    },
  },
  {
    id: '540',
    name: 'Detener la muerte',
    magicPath: MagicPath.Death,
    level: 84,
    isDaily: false,
    activationType: [SpellActivationType.Effect, SpellActivationType.Animic],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 350,
        maintainedZeonCost: 15,
        effect: 'El personaje ignora  cualquier efecto de muerte automática',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 500,
        maintainedZeonCost: 25,
        effect: 'El personaje se vuelve inmortal ante el daño físico.',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 800,
        maintainedZeonCost: 40,
        effect: 'El afectado no puede morir a consecuencia de críticos.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 1200,
        maintainedZeonCost: 60,
        effect:
          'Inmune a cualquier negativo a la acción derivado del deterioro físico.',
      },
    },
  },
  {
    id: '541',
    name: 'La muerte',
    magicPath: MagicPath.Death,
    level: 94,
    isDaily: false,
    activationType: [SpellActivationType.Animic, SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: '500 m. de radio / RF o RM 80',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 750,
        maintainedZeonCost: 75,
        effect: '1 km. de radio / RF o RM 100',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 1200,
        maintainedZeonCost: 120,
        effect: '5 km. de radio / RF o RM 120',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 2000,
        maintainedZeonCost: 200,
        effect: '10 km. de radio / RF o RM 140',
      },
    },
  },
  {
    id: '542',
    name: 'Tempo',
    magicPath: MagicPath.Musical,
    level: 4,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        effect: 'Da + 40 a buscar',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 50,
        effect: 'Da +60 a buscar',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 70,
        effect: 'Da +80 a buscar',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 100,
        effect: 'Da +100 a buscar',
      },
    },
  },
  {
    id: '543',
    name: 'Cantábile',
    magicPath: MagicPath.Musical,
    level: 14,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: '100 metros',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: '250 metros',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: '500 metros',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '1 kilometro',
      },
    },
  },
  {
    id: '544',
    name: 'Plagio',
    magicPath: MagicPath.Musical,
    level: 24,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '120 Música',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 70,
        maintainedZeonCost: 5,
        effect: '180 Música',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: '240 Música',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '280 Música',
      },
    },
  },
  {
    id: '545',
    name: 'Mezzo forte',
    magicPath: MagicPath.Musical,
    level: 34,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'Daño 10',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect: 'Daño 20',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 5,
        effect: 'Daño 30',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: 'Daño 40',
      },
    },
  },
  {
    id: '546',
    name: 'Adagio',
    magicPath: MagicPath.Musical,
    level: 44,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: '120 RM/ 20 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 160,
        maintainedZeonCost: 10,
        effect: '140 RM/ 50 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 220,
        maintainedZeonCost: 15,
        effect: '160 RM/ 100 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 280,
        maintainedZeonCost: 20,
        effect: '180 RM 150 metros de radio',
      },
    },
  },
  {
    id: '547',
    name: 'Allegro',
    magicPath: MagicPath.Musical,
    level: 54,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: '120 RM/ 10 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 160,
        maintainedZeonCost: 10,
        effect: '140 RM/ 25 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 220,
        maintainedZeonCost: 15,
        effect: '160 RM/ 50 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 280,
        maintainedZeonCost: 20,
        effect: '180 RM/ 100 metros de radio',
      },
    },
  },
  {
    id: '548',
    name: 'Presstisimo',
    magicPath: MagicPath.Musical,
    level: 64,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        maintainedZeonCost: 5,
        effect: '10 metros de radio/ +1 al tipo de movimiento/ +30 al Turno',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: '20 metros de radio/ +2 al turno/ +30 al Turno',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect: '30 metros de radio/ +2 al tipo de movimiento/ +40 al Turno',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 300,
        maintainedZeonCost: 20,
        effect: '40 metros de radio/ +3 al tipo de movimiento/ +50 al Turno',
      },
    },
  },
  {
    id: '549',
    name: 'Fortísimo',
    magicPath: MagicPath.Musical,
    level: 74,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '10 metros de radio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 15,
        effect: '25 metros de radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 20,
        effect: '50 metros de radio',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '100 metros de radio',
      },
    },
  },
  {
    id: '550',
    name: 'Marziale',
    magicPath: MagicPath.Musical,
    level: 84,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 120,
        maintainedZeonCost: 5,
        effect: '10 metros radio/ +2 a la TA/ +20 RM',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: '25 metros de radio/ +3 a la TA/ +20 RM y RF',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 15,
        effect:
          '50 metros de radio/ +5 a la TA/ +20 a la RM, RF y RP/ Barrera de daño 50',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 300,
        maintainedZeonCost: 20,
        effect:
          '100 metros de radio/ +5 a la TA/ +30 RM, RF y RP/ Barrera de daño 100',
      },
    },
  },
  {
    id: '551',
    name: 'Anima',
    magicPath: MagicPath.Musical,
    level: 94,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 300,
        maintainedZeonCost: 5,
        effect: 'Afecta a conjuros de grado base',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 450,
        maintainedZeonCost: 10,
        effect: 'Afecta a conjuros de grado intermedio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 600,
        maintainedZeonCost: 15,
        effect: 'Afecta a conjuros de grado avanzado',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 800,
        maintainedZeonCost: 20,
        effect: 'Afecta a conjuros de grado arcano',
      },
    },
  },
  {
    id: '552',
    name: 'Rostro',
    magicPath: MagicPath.Nobility,
    level: 4,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect: 'Las habilidades descritas',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect:
          'El personaje incrementa un punto su apariencia (hasta un máximo de 9)',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect:
          'El personaje incrementa dos puntos su apariencia (hasta un máximo de 10)',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect:
          'El personaje incrementa tres puntos su apariencia (hasta un máximo de 10)',
      },
    },
  },
  {
    id: '553',
    name: 'Perfume',
    magicPath: MagicPath.Nobility,
    level: 14,
    isDaily: true,
    activationType: [SpellActivationType.Effect, SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: 'Las habilidades descritas/ 20 metros radio',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect:
          'Incremente un grado la dif alcanzada por Estilo/ 30 metros radio',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect:
          'Incrementa un grado el nivel de dificultad alcanzado/ 40m radio',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect:
          'Quien huela el perfume durante +5 asaltos, RM 100 o fascinación/ 50m de radio',
      },
    },
  },
  {
    id: '554',
    name: 'Musa',
    magicPath: MagicPath.Nobility,
    level: 24,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        effect: '120 de habilidad',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        effect: '180 de habilidad',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        effect: '240 de habilidad',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 150,
        effect: '280 de habilidad',
      },
    },
  },
  {
    id: '555',
    name: 'Guardarropa',
    magicPath: MagicPath.Nobility,
    level: 34,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'Las habilidades descritas',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect:
          'Mientras lleve las ropas, bono de +50 a su habilidad de estilo.',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 5,
        effect:
          'Las ropas varían de forma y color dependiendo de la situación y el momento.',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect:
          'Las ropas otorgan una TA 4 contra toda clase de ataque, y se reparan solas',
      },
    },
  },
  {
    id: '556',
    name: 'Conversación agradable',
    magicPath: MagicPath.Nobility,
    level: 44,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        effect: '120 de RM o RP',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: '160 de RM o RP',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: '200 de RM o RP',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        effect: '240 de RM o RP',
      },
    },
  },
  {
    id: '557',
    name: 'Grandeza',
    magicPath: MagicPath.Nobility,
    level: 54,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '120 de RM o RP',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '160 de RM o RP',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '200 de RM o RP',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '240 de RM o RP',
      },
    },
  },
  {
    id: '558',
    name: 'Conquistar corazones',
    magicPath: MagicPath.Nobility,
    level: 64,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: '120 de RM o RP',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: '160 de RM o RP',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: '200 de RM o RP',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 250,
        maintainedZeonCost: 15,
        effect: '240 de RM o RP',
      },
    },
  },
  {
    id: '559',
    name: 'Presencia absoluta',
    magicPath: MagicPath.Nobility,
    level: 74,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '120 de RM o RP/ 20 metros radio ',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: '160 de RM o RP/ 50 metros radio',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: '200 de RM o RP/ 100 metros radio',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 250,
        maintainedZeonCost: 20,
        effect: '240 de RM o RP/ 150 metros radio',
      },
    },
  },
  {
    id: '560',
    name: 'Perfección',
    magicPath: MagicPath.Nobility,
    level: 84,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect:
          ' Apariencia 10 y bono de +100 a Estilo, Persuasión, Intimidar y Liderazgo.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: ' +3 al nivel de regeneración base.',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'El bono aumenta a +200',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: ' +1 a todos los atributos.',
      },
    },
  },
  {
    id: '561',
    name: 'El rey del mundo',
    magicPath: MagicPath.Nobility,
    level: 94,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '500 metros de radio/ 120 RM',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: '1 Km de radio/ 160 de RM',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: '2 km de radio/ 200 RM',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 250,
        maintainedZeonCost: 20,
        effect: '5 Km de radio/ 240 RM',
      },
    },
  },
  {
    id: '562',
    name: 'Escudo salvador',
    magicPath: MagicPath.Peace,
    level: 4,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect:
          ' 300 puntos de Resistencia / Protege hasta 2 personas sin penalizador',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 15,
        effect:
          '900 puntos de Resistencia / Protege hasta 5 personas sin penalizador',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect:
          ' 1.500 puntos de Resistencia / Hasta 8 personas sin penalizador',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect:
          '3.000 puntos de Resistencia / Hasta 12 personas sin penalizador',
      },
    },
  },
  {
    id: '563',
    name: 'Equilibrio interior',
    magicPath: MagicPath.Peace,
    level: 14,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'El PJ se vuelve inmune a los estados psicológicos negativos',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect:
          'Obtiene un +40 a sus Resistencias contra efectos desequilibrantes',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'Como Grado Intermedio pero el Bono es de +80',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'Bloquea todo efecto que altere negativamente la conducta',
      },
    },
  },
  {
    id: '564',
    name: 'Defensor',
    magicPath: MagicPath.Peace,
    level: 24,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'Da +20 a la Defensa / Pres. máx. 60',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Da +20 a la Defensa / Pres. máx. 100',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'Da +30 a la Defensa / Pres. máx. 150',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Da +40 a la Defensa / Pres. máx. 250',
      },
    },
  },
  {
    id: '565',
    name: 'Detectar armonía',
    magicPath: MagicPath.Peace,
    level: 34,
    isDaily: false,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '10m radio / 100 RM',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '25m rad / 120 RM',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '50m rad / 140 RM',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '100m rad / 160 RM',
      },
    },
  },
  {
    id: '566',
    name: 'Remanso de paz',
    magicPath: MagicPath.Peace,
    level: 44,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: '10m radio / 100 RM',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 220,
        maintainedZeonCost: 25,
        effect: '25m rad / 120 RM',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 280,
        maintainedZeonCost: 30,
        effect: '50m rad / 140 RM',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '100m rad / 160 RM',
      },
    },
  },
  {
    id: '567',
    name: 'Signo de paz',
    magicPath: MagicPath.Peace,
    level: 54,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 180,
        effect: 'RM 160',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 260,
        effect: 'RM 200',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 340,
        effect: 'RM 240',
      },
    },
  },
  {
    id: '568',
    name: 'Defensa absoluta',
    magicPath: MagicPath.Peace,
    level: 64,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 10,
        effect: 'Da +20 a la Proyección Mágica Defensiva',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Da +30 a la Proyección Mágica Defensiva',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 110,
        maintainedZeonCost: 15,
        effect: 'Da +40 a la Proyección Mágica Defensiva',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'Da +50 a la Proyección Mágica Defensiva',
      },
    },
  },
  {
    id: '569',
    name: 'Alas de salvación',
    magicPath: MagicPath.Peace,
    level: 74,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'El Escudo tiene 500 puntos de Resistencia / 5m radio',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'El Escudo tiene 1.200 puntos de Resistencia / 15m radio',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: 'El Escudo tiene 2.200 puntos de Resistencia / 25m radio',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 450,
        maintainedZeonCost: 45,
        effect: 'El Escudo tiene 5.000 puntos de Resistencia / 50m radio',
      },
    },
  },
  {
    id: '570',
    name: 'Paz absoluta',
    magicPath: MagicPath.Peace,
    level: 84,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        maintainedZeonCost: 20,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM 160',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '571',
    name: 'Pax in terrax',
    magicPath: MagicPath.Peace,
    level: 94,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: '1 Km radio',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: '5 Km radio',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 1000,
        maintainedZeonCost: 100,
        effect: '15 Km radio',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 1600,
        maintainedZeonCost: 160,
        effect: '50 Km radio',
      },
    },
  },
  {
    id: '572',
    name: 'Sentir pecado',
    magicPath: MagicPath.Sin,
    level: 4,
    isDaily: false,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 90,
        effect: 'RM 160',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '573',
    name: 'Imbuir pecado',
    magicPath: MagicPath.Sin,
    level: 14,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 70,
        maintainedZeonCost: 10,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 160',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '574',
    name: 'Gula',
    magicPath: MagicPath.Sin,
    level: 24,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'Absorbe un 10% / 10m radio',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'Absorbe un 20% / 20m radio',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 140,
        maintainedZeonCost: 10,
        effect: 'Absorbe un 30% / 40m radio',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: 'Absorbe un 50% / 60m radio',
      },
    },
  },
  {
    id: '575',
    name: 'Lujuria',
    magicPath: MagicPath.Sin,
    level: 34,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'RM 140',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'RM 160',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM 180',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: 'RM 200',
      },
    },
  },
  {
    id: '576',
    name: 'Avaricia',
    magicPath: MagicPath.Sin,
    level: 44,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 50,
        effect: 'RM 120 / Pres. 50',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 80,
        effect: 'RM 140 / Pres. 80',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: 'RM 160 / Pres. 100',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 260,
        effect: 'RM 180 / Pres. 120',
      },
    },
  },
  {
    id: '577',
    name: 'Pereza',
    magicPath: MagicPath.Sin,
    level: 54,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'RM 100',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'RM 120',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 140',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM 160',
      },
    },
  },
  {
    id: '578',
    name: 'Soberbia',
    magicPath: MagicPath.Sin,
    level: 64,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'RM 120 / +10 a Toda Acción / +50 Estilo',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'RM 140 / +20 a Toda Acción / +100 Estilo',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect:
          'RM 160 / +20 a Toda Acción / +150 Estilo / +100 Resistir el Dolor',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect:
          'RM 180 / +30 a Toda Acción / +200 Estilo / +150 Resistir el Dolor',
      },
    },
  },
  {
    id: '579',
    name: 'Ira',
    magicPath: MagicPath.Sin,
    level: 74,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        maintainedZeonCost: 5,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        maintainedZeonCost: 10,
        effect: 'RM 160',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 220,
        maintainedZeonCost: 10,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '580',
    name: 'Envidia',
    magicPath: MagicPath.Sin,
    level: 84,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 150,
        maintainedZeonCost: 5,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'RM 160',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 250,
        maintainedZeonCost: 10,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '581',
    name: 'La semilla del mal',
    magicPath: MagicPath.Sin,
    level: 94,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        effect: 'RM 120',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        effect: 'RM 140',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 260,
        effect: 'RM 160',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        effect: 'RM 180',
      },
    },
  },
  {
    id: '582',
    name: 'Categorizar',
    magicPath: MagicPath.Knowledge,
    level: 4,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        effect:
          'Sabrá a qué campo de estudio pertenece (mecánica, herrería…) sin más datos.',
      },
      intermediate: {
        intelligenceRequirement: 7,
        zeonCost: 60,
        effect:
          'Obtiene información de los materiales de los que está compuesto.',
      },
      advanced: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        effect:
          'Obtiene una vaga idea de cómo funciona, aunque no para que sirve.',
      },
      arcane: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        effect:
          'El lanzador obtiene una vaga idea de cómo se creó, aunque no podrá recrearlo.',
      },
    },
  },
  {
    id: '583',
    name: MagicPath.Knowledge,
    magicPath: MagicPath.Knowledge,
    level: 14,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        maintainedZeonCost: 5,
        effect: '40 en una habilidad',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '60 en una habilidad',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '80 en una habilidad',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: '100 en una hablilidad',
      },
    },
  },
  {
    id: '584',
    name: 'Saber debilidad',
    magicPath: MagicPath.Knowledge,
    level: 24,
    isDaily: false,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 60,
        effect: '120 RM',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        effect: '140 RM',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 140,
        effect: '160 RM',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 180,
        effect: '180 RM',
      },
    },
  },
  {
    id: '585',
    name: 'Saber la verdad',
    magicPath: MagicPath.Knowledge,
    level: 34,
    isDaily: true,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: '80 RM',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '100 RM',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: '120 RM',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '140 RM',
      },
    },
  },
  {
    id: '586',
    name: 'Conocimiento mágico',
    magicPath: MagicPath.Knowledge,
    level: 44,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        effect: 'Un conjuro de hasta nivel 20',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 150,
        effect: 'Un conjuro de hasta nivel 30',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        effect: 'Un conjuro de hasta nivel 40',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 250,
        effect: 'Un conjuro de hasta nivel 50',
      },
    },
  },
  {
    id: '587',
    name: 'Conocimiento de combate',
    magicPath: MagicPath.Knowledge,
    level: 54,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: '140 RM/ + 20 a toda acción',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '160 RM/ +30 a toda acción',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 160,
        maintainedZeonCost: 20,
        effect: '180 RM/ +30 a toda acción',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '200 RM/ + 40 a toda acción',
      },
    },
  },
  {
    id: '588',
    name: 'Aprendizaje',
    magicPath: MagicPath.Knowledge,
    level: 64,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Disminuye en uno la dificultad del grado de aprendizaje.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 150,
        maintainedZeonCost: 20,
        effect: 'Disminuye en dos la dificultad del grado de aprendizaje.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 30,
        effect: 'Disminuye en tres la dificultad del grado de aprendizaje.',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 250,
        maintainedZeonCost: 40,
        effect: 'Disminuye en cuatro la dificultad del grado de aprendizaje.',
      },
    },
  },
  {
    id: '589',
    name: 'Consejero',
    magicPath: MagicPath.Knowledge,
    level: 74,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect:
          'Un consejo aproximado sobre cual es la mejor decision que ha de tomar. ',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect:
          'Mostrará el espectro de de posibilidades y sus posibles consecuencias.',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'El lanzador obtiene un consejo con índice de acierto elevado.',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect:
          'Obtiene un consejo detallado con todas las posibilidades y consecuencias.',
      },
    },
  },
  {
    id: '590',
    name: 'Otorgar conocimiento',
    magicPath: MagicPath.Knowledge,
    level: 84,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'RM 120/ Habilidad intelectual hasta 50 o Nivel de magia 20.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: 'RM 140/ Habilidad intelectual hasta 100 o Nivel de magia 40.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 15,
        effect: 'RM 160/ Habilidad intelectual hasta 150 o Nivel de magia 60.',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 250,
        maintainedZeonCost: 20,
        effect: 'RM 180/ Habilidad intelectual hasta 200 o Nivel de magia 80.',
      },
    },
  },
  {
    id: '591',
    name: 'Vida',
    magicPath: MagicPath.Knowledge,
    level: 94,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: '+1 Niveles',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: '+2 Niveles',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 800,
        maintainedZeonCost: 80,
        effect: '+3 Niveles',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 1000,
        maintainedZeonCost: 100,
        effect: '+4 Niveles',
      },
    },
  },
  {
    id: '592',
    name: 'Relentizar pulso',
    magicPath: MagicPath.Blood,
    level: 4,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        effect: 'RM 80',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        effect: 'RM 100',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: 'RM 120',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 150,
        effect: 'RM 160',
      },
    },
  },
  {
    id: '593',
    name: 'Coagular',
    magicPath: MagicPath.Blood,
    level: 14,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        effect: 'Elimina automáticamente cualquier desangramiento.',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        effect: 'Recupera un 20% de los PV perdidos por cortes o hemorragias.',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        effect:
          'Recupera un 40% de los Puntos de Vida perdidos por cortes o hemorragias.',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 160,
        effect:
          'Recupera un 60% de los Puntos de Vida perdidos por cortes o hemorragias.',
      },
    },
  },
  {
    id: '594',
    name: 'Sangre fría',
    magicPath: MagicPath.Blood,
    level: 24,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: 'Pres. máxima 60',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 20,
        effect: 'Pres. máxima 100',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 25,
        effect: 'Pres. máxima 180',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 30,
        effect: 'Pres. máxima 300',
      },
    },
  },
  {
    id: '595',
    name: 'Creación de sangre',
    magicPath: MagicPath.Blood,
    level: 34,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'Pérdida de -10 PV / Calidad +0 / Objetos de hasta 2 Kg',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 120,
        maintainedZeonCost: 40,
        effect: 'Pérdida de -20 PV / Calidad +5 / Objetos de hasta 5 Kg',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 160,
        maintainedZeonCost: 50,
        effect: 'Pérdida de -40 PV / Calidad +10 / Objetos de hasta 15 Kg',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 200,
        maintainedZeonCost: 60,
        effect: 'Pérdida de -60 PV / Calidad +15 / Objetos de hasta 25 Kg',
      },
    },
  },
  {
    id: '596',
    name: 'Transfusión',
    magicPath: MagicPath.Blood,
    level: 44,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        effect: 'x2 a los PV Sacrificados',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        effect: 'x5 a los PV Sacrificados',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 80,
        effect: 'x10 a los PV Sacrificados',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 100,
        effect: 'x20 a los PV Sacrificados',
      },
    },
  },
  {
    id: '597',
    name: 'Desangrar',
    magicPath: MagicPath.Blood,
    level: 54,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'Incrementa el Daño Recibido un 50% / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Dobla el Daño Recibido / RM 140',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'Triplica el Daño Recibido / RM 160',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'Cuadruplica el Daño Recibido / RM 180',
      },
    },
  },
  {
    id: '598',
    name: 'Vampirismo',
    magicPath: MagicPath.Blood,
    level: 64,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: 'Drena el 10% de las heridas causadas',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'Drena el 20% de las heridas causadas',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 15,
        effect: 'Drena el 30% de las heridas causadas',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 15,
        effect: 'Drena el 40% de las heridas causadas',
      },
    },
  },
  {
    id: '599',
    name: 'Lágrimas de sangre',
    magicPath: MagicPath.Blood,
    level: 74,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 280,
        effect: 'RM o RF 140 / Daño 50',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 320,
        effect: 'RM o RF 180 / Daño 100',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 360,
        effect: 'RM o RF 220 / Daño 150',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 400,
        effect: 'RM o RF 260 / Daño 200',
      },
    },
  },
  {
    id: '600',
    name: 'Corriente sanguínea',
    magicPath: MagicPath.Blood,
    level: 84,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect: 'Da +10 a Toda Acción / -5 PV por Turno',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 50,
        effect: 'Da +20 a Toda Acción / -15 PV por Turno',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: 'Da +40 a Toda Acción / -30 PV por Turno',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 340,
        maintainedZeonCost: 70,
        effect: 'Da +60 a Toda Acción / -50 PV por Turno',
      },
    },
  },
  {
    id: '601',
    name: 'Un mundo de sangre',
    magicPath: MagicPath.Blood,
    level: 94,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 280,
        effect: 'RM o RF 120 / Daño 60 / 500 Puntos de Resistencia',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 320,
        effect: 'RM o RF 140 / Daño 90 / 1.200 Puntos de Resistencia',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 360,
        effect: 'RM o RF 160 / Daño 120 / 2.400 Puntos de Resistencia',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 400,
        effect: 'RM o RF 180 / Daño 150 / 3.600 Puntos de Resistencia',
      },
    },
  },
  {
    id: '602',
    name: 'Sentir los sueños',
    magicPath: MagicPath.Dreams,
    level: 4,
    isDaily: false,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 40,
        effect: '50 metros / RM 120',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        effect: '150 metros / RM 160',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 80,
        effect: '200 metros / RM 200',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 100,
        effect: '500 metros / RM 240',
      },
    },
  },
  {
    id: '603',
    name: 'Sueño imperturbable',
    magicPath: MagicPath.Dreams,
    level: 14,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: '+40 RM o RP.',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: '+60 RM o RP.',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: '+80 RM o RP.',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: '+100 RM o RP.',
      },
    },
  },
  {
    id: '604',
    name: 'Espiar los sueños',
    magicPath: MagicPath.Dreams,
    level: 24,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 10,
        effect: 'RM 140',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RM 160',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 180',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect:
          'RM 200 / el lanzador puede  manifestar una imagen  de los Sueños',
      },
    },
  },
  {
    id: '605',
    name: 'Alterar los sueños',
    magicPath: MagicPath.Dreams,
    level: 34,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RM 100 / 50 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 120 / 150 metros de radio.',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM 140 / 250 metros de radio.',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM 160 / 500 metros de radio.',
      },
    },
  },
  {
    id: '606',
    name: 'Caminante noctámbulo',
    magicPath: MagicPath.Dreams,
    level: 44,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 30,
        effect: '1 km de distancia máxima',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 30,
        effect:
          '2km de distancia / el afectado puede tornarse visible a voluntad y hablar',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        maintainedZeonCost: 40,
        effect:
          '5 km de distancia máxima / El mago puede alterar su aspecto físico a voluntad',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        maintainedZeonCost: 40,
        effect:
          'Cualquier distancia / puede entrar en los Sueños de cualquiera, con RM de 150',
      },
    },
  },
  {
    id: '607',
    name: 'Sueño eterno',
    magicPath: MagicPath.Dreams,
    level: 54,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM 80',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 140,
        maintainedZeonCost: 20,
        effect: 'RM 100',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM 120',
      },
      arcane: {
        intelligenceRequirement: 13,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM 140',
      },
    },
  },
  {
    id: '608',
    name: 'Rasgar la membrana',
    magicPath: MagicPath.Dreams,
    level: 64,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect:
          'El portal sólo permite pasar al hechicero, y es sólo de una dirección.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 50,
        effect:
          ' Cualquier clase de ser puede cruzar, pero sólo en una dirección.',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 280,
        maintainedZeonCost: 60,
        effect: 'El portal puede ser usado en ambas direcciones.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 320,
        maintainedZeonCost: 70,
        effect:
          'Puede escogerse quienes pueden cruzar el portal, y como abrirlo.',
      },
    },
  },
  {
    id: '609',
    name: 'Desterrar a la Vigiilia',
    magicPath: MagicPath.Dreams,
    level: 74,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        effect: 'RM 100',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        effect: 'RM 120',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 140,
        effect: 'RM 140',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 160,
        effect: 'RM 160',
      },
    },
  },
  {
    id: '610',
    name: 'Entre sueños y realidad',
    magicPath: MagicPath.Dreams,
    level: 84,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 13,
        zeonCost: 400,
        maintainedZeonCost: 80,
        effect: '100m de radio',
      },
      intermediate: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: '500m de radio',
      },
      advanced: {
        intelligenceRequirement: 17,
        zeonCost: 700,
        maintainedZeonCost: 140,
        effect: '1km de radio',
      },
      arcane: {
        intelligenceRequirement: 19,
        zeonCost: 900,
        maintainedZeonCost: 180,
        effect: '3km de radio',
      },
    },
  },
  {
    id: '611',
    name: 'Hacer los sueños realidad',
    magicPath: MagicPath.Dreams,
    level: 94,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 50,
        effect: 'Presencia máxima 80/ Nivel 3.',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: 'Presencia máxima 120/ Nivel 6.',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 900,
        maintainedZeonCost: 65,
        effect: 'Presencia máxima 160/ Nivel 9.',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1200,
        maintainedZeonCost: 75,
        effect: 'Presencia máxima 200/ Nivel 12.',
      },
    },
  },
  {
    id: '612',
    name: 'Conocimiento temporal',
    magicPath: MagicPath.Time,
    level: 4,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        effect: 'Permite percibir sobrenaturalmente la hora y la fecha.',
      },
      intermediate: {
        intelligenceRequirement: 7,
        zeonCost: 50,
        effect:
          'Siente las milésimas de segundo, y toda alternación del fluir del tiempo.',
      },
      advanced: {
        intelligenceRequirement: 10,
        zeonCost: 70,
        effect:
          'Ell personaje puede fijar una alarma, que sonará cuando lo desee. ',
      },
      arcane: {
        intelligenceRequirement: 12,
        zeonCost: 90,
        effect:
          'Ell lanzador puede calcular el tiempo que va a tardar algo en concluir.',
      },
    },
  },
  {
    id: '613',
    name: 'Aceleración temporal',
    magicPath: MagicPath.Time,
    level: 14,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '+1 Tipo de Movimiento / +20 Turno / +10 Toda Acción',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: '+2 Tipo de Movimiento / +30 Turno / +15 Toda Acción',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '+2 Tipo de Movimiento / +40 Turno / +20 Toda Acción',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '+3 Tipo de Movimiento / +50 Turno / +25 Toda Acción',
      },
    },
  },
  {
    id: '614',
    name: 'Lentitud temporal',
    magicPath: MagicPath.Time,
    level: 24,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: '-2 Tipos Movimiento / -40 Turno / 10m radio / 100RM',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect:
          '-4 Tipos Movimiento / -60 Turno / -10 Toda Acción / 25m radio / 120RM',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect:
          '-6 Tipos Movimiento / -80 Turno / -20 Toda Acción / 50m radio / 140RM',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect:
          '-8 Tipos Movimiento / -100 Turno / -30 Toda Acción / 100m radio / 160RM',
      },
    },
  },
  {
    id: '615',
    name: 'Estancar el tiempo',
    magicPath: MagicPath.Time,
    level: 34,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: '5m',
      },
      intermediate: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: '10m',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 280,
        maintainedZeonCost: 30,
        effect: '25m',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 380,
        maintainedZeonCost: 40,
        effect: '50m',
      },
    },
  },
  {
    id: '616',
    name: 'Detener el tiempo',
    magicPath: MagicPath.Time,
    level: 44,
    isDaily: true,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 9,
        zeonCost: 200,
        maintainedZeonCost: 40,
        effect: '120RM / 10m',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        maintainedZeonCost: 60,
        effect: '140RM / 25m',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        maintainedZeonCost: 80,
        effect: '160RM / 50m',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        maintainedZeonCost: 100,
        effect: '180RM / 100m',
      },
    },
  },
  {
    id: '617',
    name: 'Retroevolucionar',
    magicPath: MagicPath.Time,
    level: 54,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        effect: '120RM / Retrocede hasta 1 día',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 350,
        effect: '140RM / Retrocede hasta 1 semana',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 450,
        effect: '160RM / Retrocede hasta 1 año',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 600,
        effect: '180RM / Retrocede hasta 1 década',
      },
    },
  },
  {
    id: '618',
    name: 'Deshacer el tiempo',
    magicPath: MagicPath.Time,
    level: 64,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 11,
        zeonCost: 200,
        effect: '15 segundos (5 asaltos) / 50m',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 350,
        effect: '30 segundos (10 asaltos) / 150m',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        effect: '1 minuto / 250m',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 800,
        effect: '1 hora / 1 Km',
      },
    },
  },
  {
    id: '619',
    name: 'Salto temporal',
    magicPath: MagicPath.Time,
    level: 74,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 11,
        zeonCost: 250,
        effect: 'Hasta 10 minutos / 120RM',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 350,
        effect: 'Hasta 1 día / 160RM',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 450,
        effect: 'Hasta 1 mes / 200RM',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 600,
        effect: 'Hasta 1 año / 240RM',
      },
    },
  },
  {
    id: '620',
    name: 'Bucle temporal',
    magicPath: MagicPath.Time,
    level: 84,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 11,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect: '250m radio / Hasta 1 día',
      },
      intermediate: {
        intelligenceRequirement: 14,
        zeonCost: 600,
        maintainedZeonCost: 60,
        effect: '500m radio / Hasta 1 semana',
      },
      advanced: {
        intelligenceRequirement: 16,
        zeonCost: 900,
        maintainedZeonCost: 90,
        effect: '1 Km radio / Hasta 1 mes',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1400,
        maintainedZeonCost: 140,
        effect: '5 Km radio / Hasta 1 año',
      },
    },
  },
  {
    id: '621',
    name: 'Solapar el tiempo',
    magicPath: MagicPath.Time,
    level: 94,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 12,
        zeonCost: 500,
        effect: '5m radio / Hasta 1 año en el pasado',
      },
      intermediate: {
        intelligenceRequirement: 15,
        zeonCost: 800,
        effect: '25m radio / Hasta 1 década en el pasado',
      },
      advanced: {
        intelligenceRequirement: 17,
        zeonCost: 1250,
        effect: '500m radio / Hasta 1 siglo en el pasado',
      },
      arcane: {
        intelligenceRequirement: 19,
        zeonCost: 2500,
        effect: '1 Km radio / Hasta mil años en el pasado',
      },
    },
  },
  {
    id: '622',
    name: 'Ojos del otro lado',
    magicPath: MagicPath.Shift,
    level: 4,
    isDaily: false,
    activationType: [SpellActivationType.Detection],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        maintainedZeonCost: 5,
        effect:
          'El personaje puede sentir si una persona está asustada o no / RM o RP 120.',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect:
          'Percibe cual es el origen del miedo que siente una persona / RM o RP 140',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 90,
        maintainedZeonCost: 5,
        effect:
          'El mago percibe los miedos de alguien, aunque no esté asustada /RM o RP 160.',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect:
          'El personaje percibe representacion visual de los miedos / RM o RP 180.',
      },
    },
  },
  {
    id: '623',
    name: 'Sombra del miedo',
    magicPath: MagicPath.Shift,
    level: 14,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 40,
        effect: '50 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 70,
        effect: '250 metros de radio.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 100,
        effect: '500 metros de radio.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 130,
        effect: '1 kilómetro de radio.',
      },
    },
  },
  {
    id: '624',
    name: 'Susurros del otro lado',
    magicPath: MagicPath.Shift,
    level: 24,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 60,
        maintainedZeonCost: 5,
        effect: 'RM o RP 80 / 10 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'RM o RP 100 / 25 metros de radio.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 160,
        maintainedZeonCost: 10,
        effect: 'RM o RP 120 / 50 metros de radio.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'RM o RP 140 / 100 metros de radio.',
      },
    },
  },
  {
    id: '625',
    name: 'Senda de la locura',
    magicPath: MagicPath.Shift,
    level: 34,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'RM o RP 120.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM o RP 140.',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'RM o RP 160.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 140,
        maintainedZeonCost: 20,
        effect: 'RM o RP 180.',
      },
    },
  },
  {
    id: '626',
    name: 'Acechar en los sueños',
    magicPath: MagicPath.Shift,
    level: 44,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 100,
        maintainedZeonCost: 10,
        effect: 'RM o RP 120.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM o RP 160.',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'RM o RP 200.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM o RP 240.',
      },
    },
  },
  {
    id: '627',
    name: 'Agudizar',
    magicPath: MagicPath.Shift,
    level: 54,
    isDaily: true,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: '50 metros / RM o RP 120.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: '100 metros / RM o RP 140.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: '250 metros / RM o RP 160.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '500 metros / RM o RP 180.',
      },
    },
  },
  {
    id: '628',
    name: 'Terror',
    magicPath: MagicPath.Shift,
    level: 64,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 80,
        maintainedZeonCost: 10,
        effect: 'RM o PR 120 / 10 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'RM o PR 140 / 50 metros de radio.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect: 'RM o PR 180 / 100 metros de radio.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'RM o PR 200 / 250 metros de radio.',
      },
    },
  },
  {
    id: '629',
    name: 'Señor de los locos',
    magicPath: MagicPath.Shift,
    level: 74,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 200,
        effect: 'RM o RP 140 / 100 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 300,
        effect: 'RM o RP 160 / 250 metros de radio.',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 400,
        effect: 'RM o RP 180 / 1 kilómetro de radio.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        effect: 'RM o RP 200 / 5 kilómetros de radio.',
      },
    },
  },
  {
    id: '630',
    name: 'Encarnación del miedo',
    magicPath: MagicPath.Shift,
    level: 84,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Nivel 4 / RM 140.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'Nivel 8 / RM 160.',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        maintainedZeonCost: 50,
        effect: 'Nivel 12 / RM 180.',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 700,
        maintainedZeonCost: 70,
        effect: 'Nivel 15 / RM 200.',
      },
    },
  },
  {
    id: '631',
    name: 'El miedo hecho carne',
    magicPath: MagicPath.Shift,
    level: 94,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 11,
        zeonCost: 250,
        effect: 'RM o RP 120',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 350,
        effect: 'RM o RP 140',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 450,
        effect: 'RM o RP 200',
      },
      arcane: {
        intelligenceRequirement: 17,
        zeonCost: 600,
        effect: 'RM o RP 240',
      },
    },
  },
  {
    id: '632',
    name: 'Sombra de vacío',
    magicPath: MagicPath.Void,
    level: 4,
    isDaily: false,
    activationType: [SpellActivationType.Animic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 5,
        zeonCost: 30,
        effect: 'RM 80 / Área 5 metros',
      },
      intermediate: {
        intelligenceRequirement: 8,
        zeonCost: 60,
        effect: 'RM 100 / Área 10 metros.',
      },
      advanced: {
        intelligenceRequirement: 11,
        zeonCost: 90,
        effect: 'RM 120 / Área 15 metros',
      },
      arcane: {
        intelligenceRequirement: 14,
        zeonCost: 120,
        effect: 'RM 150 / Área 30 metros.',
      },
    },
  },
  {
    id: '633',
    name: 'Onda vacua',
    magicPath: MagicPath.Void,
    level: 14,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 30,
        effect: 'Daño 120 contra escudos.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 60,
        effect: 'Daño 250 contra escudos.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 90,
        effect: 'Daño 400 contra escudos.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 120,
        effect: 'Daño 600 contra escudos.',
      },
    },
  },
  {
    id: '634',
    name: 'Escudo de vacío',
    magicPath: MagicPath.Void,
    level: 24,
    isDaily: false,
    activationType: [SpellActivationType.Defense],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 50,
        maintainedZeonCost: 5,
        effect: '200 Puntos de Resistencia / Dificultad 14 / RF 100.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 90,
        maintainedZeonCost: 20,
        effect: '300 Puntos de Resistencia / Dificultad 16 / RF 120.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 120,
        maintainedZeonCost: 25,
        effect: '400 Puntos de Resistencia / Dificultad 18 / RF 140.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 150,
        maintainedZeonCost: 30,
        effect: '500 Puntos de Resistencia / Dificultad 20 / RF 160.',
      },
    },
  },
  {
    id: '635',
    name: 'Vórtice de realidad',
    magicPath: MagicPath.Void,
    level: 34,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 7,
        zeonCost: 80,
        maintainedZeonCost: 5,
        effect: 'RM 100 / 5 metros de radio.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 120,
        maintainedZeonCost: 10,
        effect: 'RM 120 / 10 metros de radio.',
      },
      advanced: {
        intelligenceRequirement: 13,
        zeonCost: 180,
        maintainedZeonCost: 15,
        effect: 'RM 140 / 20 metros de radio.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 240,
        maintainedZeonCost: 20,
        effect: 'RM 160 / 40 metros de radio.',
      },
    },
  },
  {
    id: '636',
    name: 'Negra hoja de perdición',
    magicPath: MagicPath.Void,
    level: 44,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 100,
        maintainedZeonCost: 5,
        effect: 'Daño 80',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 150,
        maintainedZeonCost: 10,
        effect: 'Daño 120.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 10,
        effect: 'Daño 160.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 250,
        maintainedZeonCost: 15,
        effect: 'Daño 200.',
      },
    },
  },
  {
    id: '637',
    name: 'Aura de vacío',
    magicPath: MagicPath.Void,
    level: 54,
    isDaily: true,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 6,
        zeonCost: 120,
        maintainedZeonCost: 15,
        effect: 'TA 4 / Valor Zeónico 60 / Potencial Psíquico 80.',
      },
      intermediate: {
        intelligenceRequirement: 9,
        zeonCost: 180,
        maintainedZeonCost: 20,
        effect: 'TA 6 / Valor Zeónico 90/ Potencial Psíquico 120.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 240,
        maintainedZeonCost: 25,
        effect: 'TA 8 / Valor Zeónico 120/ Potencial Psíquico 140.',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: 'TA 10 / Valor Zeónico 160/ Potencial Psíquico 180.',
      },
    },
  },
  {
    id: '638',
    name: 'Puntos negros',
    magicPath: MagicPath.Void,
    level: 64,
    isDaily: false,
    activationType: [SpellActivationType.Automatic],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 8,
        zeonCost: 150,
        maintainedZeonCost: 15,
        effect: 'Dificultad 120 / 10 metros de radio / RM o RF 120.',
      },
      intermediate: {
        intelligenceRequirement: 10,
        zeonCost: 250,
        maintainedZeonCost: 25,
        effect: 'Dificultad 140 / 20 metros de radio / RM o RF 140.',
      },
      advanced: {
        intelligenceRequirement: 12,
        zeonCost: 350,
        maintainedZeonCost: 35,
        effect: 'Dificultad 180 / 50 metros de radio / RM o RF 160',
      },
      arcane: {
        intelligenceRequirement: 15,
        zeonCost: 500,
        maintainedZeonCost: 50,
        effect: 'Dificultad 240 / 100 metros de radio / RM o RF 180.',
      },
    },
  },
  {
    id: '639',
    name: 'Protección contra el vacío',
    magicPath: MagicPath.Void,
    level: 74,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 10,
        zeonCost: 140,
        maintainedZeonCost: 15,
        effect: 'Protege contra conjuros de esta sub-vía.',
      },
      intermediate: {
        intelligenceRequirement: 12,
        zeonCost: 200,
        maintainedZeonCost: 20,
        effect:
          'El personaje es  inmune a los efectos y ataques de seres basados en el vacío.',
      },
      advanced: {
        intelligenceRequirement: 14,
        zeonCost: 280,
        maintainedZeonCost: 30,
        effect:
          'Inmune a cualquier efecto relaciona con el vacío, salvo la nada primordial.',
      },
      arcane: {
        intelligenceRequirement: 16,
        zeonCost: 400,
        maintainedZeonCost: 40,
        effect:
          'El personaje es capaz de sobrevivir incluso en la nada primordial.',
      },
    },
  },
  {
    id: '640',
    name: 'Implosión',
    magicPath: MagicPath.Void,
    level: 84,
    isDaily: false,
    activationType: [SpellActivationType.Attack],
    actionType: SpellActionType.Active,
    casts: {
      base: {
        intelligenceRequirement: 14,
        zeonCost: 250,
        effect: 'Daño 200 / Crítico +20.',
      },
      intermediate: {
        intelligenceRequirement: 16,
        zeonCost: 500,
        effect: 'Daño 400 / Crítico +40.',
      },
      advanced: {
        intelligenceRequirement: 18,
        zeonCost: 750,
        effect: 'Daño 500 / Crítico +60.',
      },
      arcane: {
        intelligenceRequirement: 20,
        zeonCost: 1000,
        effect: 'Daño 800 / Crítico +100.',
      },
    },
  },
  {
    id: '641',
    name: 'Puerta a ninguna parte',
    magicPath: MagicPath.Void,
    level: 94,
    isDaily: false,
    activationType: [SpellActivationType.Effect],
    actionType: SpellActionType.Passive,
    casts: {
      base: {
        intelligenceRequirement: 11,
        zeonCost: 300,
        maintainedZeonCost: 30,
        effect: '2 metros de diámetro.',
      },
      intermediate: {
        intelligenceRequirement: 13,
        zeonCost: 500,
        maintainedZeonCost: 50,
        effect: '5 metros de diámetro.',
      },
      advanced: {
        intelligenceRequirement: 15,
        zeonCost: 800,
        maintainedZeonCost: 80,
        effect: '15 metros de diámetro',
      },
      arcane: {
        intelligenceRequirement: 18,
        zeonCost: 1200,
        maintainedZeonCost: 120,
        effect: '50 metros de diámetro.',
      },
    },
  },
];

export const DefaultFreeAccessSpells: MagicSpell[] = DefaultMagicSpells.filter(
  (s) => s.magicPath === MagicPath.FreeAccess,
);
