import { Archetype, CategoryTemplate, CategoryType } from './CategoryTemplate';

export class MentalistCategoryTemplate extends CategoryTemplate {
  name = 'Mentalista';
  archetypes = [Archetype.Psychic];
  type = CategoryType.Mentalist;

  readonly lifeMultiplier = 20;
  lifePoints = 5;
  protected turn = 5;
  protected martialKnowledge = 10;
  protected nativePsychicPoints = 1;

  calculateNativePsychicPoints(): number {
    return this.level * this.nativePsychicPoints;
  }

  offensiveSkillsLimitPercentage = 0.5;
  attackPDsCost = 3;
  blockPDsCost = 3;
  dodgePDsCost = 2;
  wearArmorPDsCost = 3;
  kiPDsCost = 3;
  kiAccumMultiplierPDsCost = 30;

  magicSkillsLimitPercentage = 0.5;
  zeonPDsCost = 3;
  aCTMultiplierPDsCost = 70;
  magicProjectionPDsCost = 3;
  summonPDsCost = 3;
  controlPDsCost = 3;
  bindPDsCost = 3;
  banishPDsCost = 3;

  psychicSkillsLimitPercentage = 0.6;
  psychicPointsPDsCost = 10;
  psychicProjectionPDsCost = 2;

  athleticSecondariesPDsCost = 2;
  socialSecondariesPDsCost = 2;
  perceptionSecondariesPDsCost = 2;
  intellectualSecondariesPDsCost = 2;
  vigorSecondariesPDsCost = 3;
  subterfugeSecondariesPDsCost = 2;
  creativeSecondariesPDsCost = 2;
}
