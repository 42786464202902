import { FolderDatabaseRow } from '../../infrastructure/FolderService';

export type FolderConstructorParams = {
  id: string;
  name: string;
  parentFolderId?: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
};

export class Folder {
  public id: string;
  public name: string;
  public parentFolderId: string | undefined;
  public userId: string;
  public createdAt: Date;
  public updatedAt: Date;

  constructor(args: FolderConstructorParams) {
    this.id = args.id;
    this.name = args.name;
    this.parentFolderId = args.parentFolderId;
    this.userId = args.userId;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;
  }

  toPrimitives(): FolderDatabaseRow {
    return {
      id: parseInt(this.id),
      name: this.name,
      parent_folder_id: this.parentFolderId
        ? parseInt(this.parentFolderId)
        : null,
      user_id: this.userId,
      created_at: this.createdAt.toISOString(),
      updated_at: this.updatedAt.toISOString(),
    };
  }

  static fromPrimitives(args: FolderDatabaseRow): Folder {
    return new Folder({
      id: args.id.toString(),
      name: args.name,
      parentFolderId: args.parent_folder_id?.toString() ?? undefined,
      userId: args.user_id,
      createdAt: args.created_at ? new Date(args.created_at) : new Date(),
      updatedAt: args.updated_at ? new Date(args.updated_at) : new Date(),
    });
  }
}
