import { Character } from '../../../character/domain/character/Character';
import { FoundryVTTActor } from '../../../embed/infrastructure/mutators/anima/foundry/FoundryVTTActor';
import { FoundryMessagingService } from '../../../embed/infrastructure/FoundryMessagingService';

export const useCharacterSync = () => {
  const foundryMessageService = FoundryMessagingService.instance;

  const createActor = async (character: Character) => {
    await foundryMessageService.createNewActor(character);
  };

  const updateActor = async ({
    character,
    actor,
  }: {
    character: Character;
    actor: FoundryVTTActor;
    isNew?: boolean;
  }) => {
    await foundryMessageService.updateActor(character, actor);
  };

  const getActors = async () => {
    return foundryMessageService.getActors();
  };

  return {
    createActor,
    updateActor,
    getActors,
  };
};
