import { Avatar } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import Logo from '../../../../../shared/application/assets/logo.png';
import { useUserAuthorization } from '../../../../../shared/application/hooks/useUserAuthorization';
import {
  Routes,
  useNavigate,
} from '../../../../../shared/application/hooks/useNavigate';
import { Loading } from '../../../../../shared/application/design-system/components/Loading/Loading';
import { LoadingContainer } from '../../../../../shared/application/design-system/components/PageLayout/PageLayout.styled';

import {
  Container,
  Header,
  SignInOptionsTabContainer,
  SignInOptionsTabName,
  WhiteParagraph,
  WhiteTitle,
  Wrapper,
} from './Autorization.styled';
import { SocialLogin } from './components/SocialLogin/SocialLogin';
import { SignUp } from './components/SignUp/SignUp';
import { SignIn } from './components/SignIn/SignIn';
import { BetaPage } from '../../../../../beta/aplication/pages/Beta/BetaPage';

enum AuthorizationTabs {
  SignIn = 'login',
  SignUp = 'signUp',
}

export const Authorization = ({
  disableSocialLogin,
}: {
  disableSocialLogin?: boolean;
}) => {
  const { navigate } = useNavigate();

  const { session, loading, isBeta } = useUserAuthorization({
    redirect: false,
  });

  const [selectedTab, setSelectedTab] = useState(AuthorizationTabs.SignIn);

  const navigateToMyCharacters = useCallback(
    () =>
      navigate(Routes.MyCharacters, {
        replace: true,
        params: { folderId: '' },
      }),
    [navigate],
  );

  useEffect(() => {
    if (loading) return;

    if (session) {
      navigateToMyCharacters();
    }
  }, [navigateToMyCharacters, session, loading]);

  const onChangeTabClick = () => {
    setSelectedTab(
      selectedTab === AuthorizationTabs.SignIn
        ? AuthorizationTabs.SignUp
        : AuthorizationTabs.SignIn,
    );
  };

  if (loading) {
    return (
      <LoadingContainer>
        <Loading centered />
      </LoadingContainer>
    );
  }

  const buttonText =
    selectedTab === AuthorizationTabs.SignIn ? 'Entrar' : 'Registrarse';

  return (
    <Wrapper>
      <Container>
        <Header>
          <Avatar src={Logo} sx={{ width: 56, height: 56 }} />
          <WhiteTitle>Characters Vault</WhiteTitle>
        </Header>
        <SignInOptionsTabContainer $isApplicationBeta={isBeta}>
          <SignInOptionsTabName
            $selected={selectedTab === AuthorizationTabs.SignIn}
            onClick={onChangeTabClick}
          >
            Entrar
          </SignInOptionsTabName>
          <WhiteParagraph>o</WhiteParagraph>
          <SignInOptionsTabName
            $selected={selectedTab === AuthorizationTabs.SignUp}
            onClick={onChangeTabClick}
          >
            {isBeta ? 'Solicitar la beta' : 'Registrarse'}
          </SignInOptionsTabName>
        </SignInOptionsTabContainer>
        {selectedTab === AuthorizationTabs.SignIn && (
          <SocialLogin
            buttonText={buttonText}
            disableSocialLogin={disableSocialLogin}
          />
        )}
        {selectedTab === AuthorizationTabs.SignIn ? (
          <SignIn buttonText={buttonText} />
        ) : isBeta ? (
          <BetaPage />
        ) : (
          <SignUp buttonText={buttonText} />
        )}
      </Container>
    </Wrapper>
  );
};
