import { model } from 'mobx-keystone';
import { SecondarySkill } from '../../../../../types/SecondarySkill';
import { getTotalOf } from '../../../../../utils/getTotalOf';
import { SecondarySkillPDFieldModel } from '../../../../pd/parts/categories/parts/secondaries/SecondarySkillPDFieldModel';
import { SecondarySkillFieldAffectedByNaturalPenaltyModel } from '../../../utils/fields/SecondarySkillFieldAffectedByNaturalPenaltyModel';

@model('Character/Secondaries/AthleticSkills/Swim')
export class SwimSkillModel extends SecondarySkillFieldAffectedByNaturalPenaltyModel {
  type = SecondarySkill.Swim;

  get primariesBonus(): number {
    if (!this.character) return 0;

    return this.character.primaries.agility.modifier.final;
  }

  get naturalPenalty(): number {
    if (!this.character) return 0;

    return this.character.modifiers.naturalPenalty.final;
  }

  getPDFields(): SecondarySkillPDFieldModel[] {
    return (
      this.character?.pd.categories.map((c) => c.secondaries.athletic.swim) ??
      []
    );
  }

  get fromCategories(): number {
    return getTotalOf(this.character, (c) => c.secondaries.athletic.swim.final);
  }
}
