import {
  Input,
  InputProps,
  TextareaAutosize,
  TextareaAutosizeProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import styled from 'styled-components';
import { theme } from '../../../../../../../../../shared/application/design-system/theme/theme';
import { styleMui } from '../../../../../../../../../shared/application/design-system/utils/styleMui';
import {
  StringInputSize,
  StringInputVariant,
  StringLabelPosition,
} from './StringInput.types';

export const Wrapper = styled.div<{
  $labelPosition: StringLabelPosition | undefined;
  $hasLabel: boolean;
}>(({ $labelPosition, $hasLabel }) => {
  if (!$hasLabel) return {};

  return {
    display: 'flex',
    justifyContent:
      $labelPosition === StringLabelPosition.Top ? 'start' : 'space-between',
    alignItems: 'center',

    flexDirection:
      $labelPosition === StringLabelPosition.Top ? 'column' : 'row',

    gap: $labelPosition === StringLabelPosition.Top ? '0.5rem' : undefined,
  };
});

export const Label = styleMui(Typography)<
  TypographyProps & { $labelPosition: StringLabelPosition }
>(({ $labelPosition }) => ({
  color: theme.color.black(),
  fontWeight: 'bold',
  marginRight: $labelPosition === StringLabelPosition.Left ? '1rem' : undefined,

  fontSize: '0.75rem',

  textAlign: 'center',

  paddingRight:
    $labelPosition === StringLabelPosition.Left ? '1rem' : undefined,
}));

export const InputWrapper = styled.div({
  position: 'relative',
});

const commonInputStyles = ({
  $size,
  $variant,
}: {
  $size: StringInputSize;
  $variant: StringInputVariant;
}) => {
  const getFontSize = () => {
    switch ($size) {
      case StringInputSize.Big:
        return '2.5rem';
      default:
        return '0.75rem';
    }
  };

  return {
    width: '10rem',
    fontSize: getFontSize(),
    borderRadius: '0.5rem',

    paddingLeft: '0.125rem',
    fontWeight: 'bold',
    overflow: 'hidden',
    backgroundColor:
      $variant === StringInputVariant.Outline ? undefined : 'white',

    '& input': {
      textAlign: 'center',
      padding: 0,
    },
    '&:before': {
      borderStyle: 'none',
      content: 'none',
    },
    '&:after': {
      borderColor: theme.color.black(),
    },

    '& input:disabled': {
      cursor: 'not-allowed',
    },
  };
};

export const StyledInput = styleMui(Input)<
  InputProps & {
    $size: StringInputSize;
    $variant: StringInputVariant;
  }
>(({ $size, $variant }) => ({
  ...commonInputStyles({ $size, $variant }),
}));

export const StyledTextArea = styleMui(TextareaAutosize)<
  TextareaAutosizeProps & {
    $size: StringInputSize;
    $variant: StringInputVariant;
  }
>(({ $size, $variant }) => ({
  ...commonInputStyles({ $size, $variant }),

  width: '10rem',
}));
