import { AddSquare } from '@emotion-icons/fluentui-system-filled/AddSquare';
import styled from 'styled-components';
import { theme } from '../../../../../../../../../shared/application/design-system/theme/theme';

export const Wrapper = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledIconButton = styled(AddSquare)<{ $disabled?: boolean }>(
  ({ $disabled }) => ({
    width: '1.5rem',
    height: '1.5rem',
    color: $disabled ? theme.color.gray() : theme.color.secondary(),

    cursor: $disabled ? 'not-allowed' : 'pointer',
    transition: 'color 0.2s ease-in-out',

    '&:hover': {
      color: $disabled ? undefined : theme.color.primary(),
    },
  }),
);
