import { fromSnapshot, getSnapshot } from 'mobx-keystone';
import { CharacterDatabaseRow } from '../../infrastructure/CharacterService';
import { Json } from '../../../shared/infrastructure/supabase/Supabase.types';
import { getSystemFromString, System } from '../system/System';
import { CharacterModel } from './model/CharacterModel';
import { getAuthorizedUser } from '../../../auth/infrastructure/AuthorizationService';
import type { CharacterHit } from './CharacterHit';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  migrateCharacterModel,
  NoMigratedCharacter,
} from './model/migrations/migrateCharacterModel';

export enum CharacterPrivacy {
  Public = 'public',
  Private = 'private',
}

export type CharacterConstructorParams = {
  id: string;

  userId: string;
  folderId?: string;

  isCollaborative?: boolean;

  createdAt?: Date;

  privacy: CharacterPrivacy;

  system: System;

  content: CharacterModel;
};

export class Character {
  public id: string;

  public userId: string;
  public folderId: string | undefined;
  public isCollaborative: boolean;

  public privacy: CharacterPrivacy;

  public system: System;

  public createdAt: Date;

  public content: CharacterModel;

  public blocked = false;

  constructor(args: CharacterConstructorParams) {
    makeObservable(this, {
      content: observable,
      name: computed,
      canBeEdited: computed,
      setContent: action,
      blocked: observable,
      blockEditing: action,
    });

    this.id = args.id;
    this.userId = args.userId;
    this.isCollaborative = args.isCollaborative ?? false;
    this.createdAt = args.createdAt ?? new Date();
    this.privacy = args.privacy;
    this.system = args.system;
    this.content = args.content;
    this.folderId = args.folderId;
  }

  get name() {
    return this.content.name;
  }

  get isMine() {
    return this.userId === getAuthorizedUser().id;
  }

  get canBeEdited() {
    if (this.blocked) {
      return false;
    }

    return this.isMine || this.isCollaborative;
  }

  get isPublic() {
    return this.privacy === CharacterPrivacy.Public;
  }

  get isPrivate() {
    return this.privacy === CharacterPrivacy.Private;
  }

  static fromPrimitives(args: any) {
    return new Character({
      id: args.id.toString(),
      userId: args.userId,
      folderId: args.folderId ?? undefined,
      createdAt: args.createdAt ? new Date(args.createdAt) : new Date(),
      privacy: args.privacy as CharacterPrivacy,
      isCollaborative: args.isCollaborative,
      system: getSystemFromString(args.system),
      content: fromSnapshot<CharacterModel>(
        migrateCharacterModel(args.content as NoMigratedCharacter),
      ),
    });
  }

  blockEditing() {
    this.blocked = true;
  }

  setPrivacy(privacy: CharacterPrivacy) {
    this.privacy = privacy;
  }

  setContent(content: CharacterModel) {
    this.content = content;
  }

  isCharacterHit(): this is CharacterHit {
    return 'user' in this;
  }

  toPrimitives(): CharacterDatabaseRow {
    return {
      id: parseInt(this.id, 10),
      user_id: this.userId,
      folder_id: this.folderId ? parseInt(this.folderId, 10) : null,
      privacy_type: this.privacy,
      created_at: this.createdAt.toISOString(),
      system: this.system.toString(),
      content: JSON.parse(JSON.stringify(getSnapshot(this.content))) as Json,
    };
  }
}
