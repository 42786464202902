import { Typography } from '@mui/material';
import styled from 'styled-components';
import { theme } from '../../../../../../../../../shared/application/design-system/theme/theme';
import { StyledTable } from '../Table/Table.styled';
import { styleMui } from '../../../../../../../../../shared/application/design-system/utils/styleMui';

export const Divider = styled.div(({ theme: { size, color } }) => ({
  width: '100%',
  height: '1px',
  background: color.black(),
  margin: `${size.units(1)} 0`,
}));

export const Wrapper = styled.div<{
  $direction: 'row' | 'column';
  $maxHeight?: string;
}>(({ $direction, $maxHeight }) => ({
  border: `2px solid ${theme.color.black()}`,
  position: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: '-1',
    top: '2px',
    left: '2px',
    right: '2px',
    bottom: '2px',
    border: `2px dashed ${theme.color.black()}`,
  },
  borderRadius: '6px',

  display: 'flex',
  flexDirection: $direction,

  width: '100%',

  ...theme.mixins.responsive.xs.downward({
    width: '100%',
  }),

  height:
    $maxHeight === 'fit'
      ? '100%'
      : $maxHeight !== undefined
      ? `${$maxHeight}rem`
      : 'auto',

  padding: '1.5rem',
  paddingTop: '1rem',
  boxSizing: 'border-box',

  ...theme.mixins.responsive.md.downward({
    padding: '1.25rem',
  }),

  gap: $direction !== 'column' ? '1.5rem' : '0.5rem',
  justifyContent: $direction === 'row' ? 'space-between' : undefined,

  [StyledTable]: {
    width: 'fit-content',
    alignSelf: 'center',
  },
}));

export const SectionTitle = styleMui(Typography)({
  color: theme.color.primary(),
  textTransform: 'uppercase',

  fontWeight: 'bold',

  fontSize: '1.2rem',

  width: '100%',
  textAlign: 'center',
});

export const SectionSubtitle = styleMui(SectionTitle)({
  fontSize: '0.95rem',
});

export const SectionParagraph = styleMui(Typography)({
  fontSize: '0.85rem',

  width: '100%',

  fontWeight: 'bold',
  color: theme.color.black(),
});
