import { ClickAwayListener, Popper, Tooltip } from '@mui/material';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { PrimaryFieldModel } from '../../../../../../../../domain/character/model/parts/primaries/fields/common/PrimaryFieldModel';
import { BaseFinalFieldModel } from '../../../../../../../../domain/character/model/utils/fields/BaseFinalFieldModel';
import { CurrentMaxField } from '../../../../../../../../domain/character/model/utils/fields/CurrentMaxField';
import { NumberField } from '../../../../../../../../domain/character/model/utils/fields/NumberField';
import { PrecalculatedFieldModel } from '../../../../../../../../domain/character/model/utils/fields/PrecalculatedFieldModel';
import { vibrate } from '../../../../../../../../../shared/utils/vibrate';

import {
  InputWrapper,
  Label,
  StyledLeftInput,
  StyledRightInput,
  StyledSpecialButton,
  Wrapper,
} from './DoubleInput.styled';
import {
  DoubleInputDigits,
  DoubleInputSize,
  DoubleInputVariant,
  InputsOrientation,
  LabelPosition,
} from './DoubleInput.types';
import { getTooltips } from './utils/getTooltips';
import { useCharacterContext } from '../../../hooks/useCharacterContext';

export type NumericCharacterField =
  | BaseFinalFieldModel
  | CurrentMaxField
  | NumberField
  | PrimaryFieldModel
  | PrecalculatedFieldModel;

type DoubleInputProps = {
  field: NumericCharacterField;
  label?: string;
  variant?: DoubleInputVariant;
  size?: DoubleInputSize;
  labelPosition?: LabelPosition;
  orientation?: InputsOrientation;

  disableBase?: boolean;
  disableSpecial?: boolean;
  disableFinal?: boolean;

  step?: number;
  max?: number;
  min?: number;
};

const getValuesFromField = (
  field: NumericCharacterField,
):
  | { leftValue: number; rightValue: number | undefined }
  | { leftValue: undefined | number; rightValue: number }
  | { leftValue: number; rightValue: number } => {
  if (field instanceof CurrentMaxField) {
    return {
      leftValue: field.current,
      rightValue: field.max,
    };
  }

  if (field instanceof PrecalculatedFieldModel) {
    return {
      leftValue: field.base,
      rightValue: field.special === 0 ? undefined : field.final,
    };
  }

  if (field instanceof BaseFinalFieldModel) {
    return {
      leftValue: field.base,
      rightValue: field.final,
    };
  }

  return {
    leftValue: undefined,
    rightValue: field.final,
  };
};

const DoubleInput = ({
  field,
  label,
  labelPosition = LabelPosition.Left,
  variant = DoubleInputVariant.Normal,
  orientation = InputsOrientation.Horizontal,
  size = DoubleInputSize.Normal,
  disableBase,
  disableSpecial,
  disableFinal,
  step = 1,
  max,
  min,
}: DoubleInputProps) => {
  const { character } = useCharacterContext();

  const [individualInputSize, setIndividualInputSize] =
    useState<DoubleInputDigits>(DoubleInputDigits.TENS);

  useEffect(() => {
    const { leftValue, rightValue } = getValuesFromField(field);

    if (leftValue === undefined || rightValue === undefined) {
      setIndividualInputSize(DoubleInputDigits.TENS);
      return;
    }

    const leftValueLength = leftValue.toFixed(0).length;
    const rightValueLength = rightValue.toFixed(0).length;

    if (leftValueLength > 3 || rightValueLength > 3) {
      setIndividualInputSize(DoubleInputDigits.THOUSANDS);
    } else if (leftValueLength > 2 || rightValueLength > 2) {
      setIndividualInputSize(DoubleInputDigits.HUNDREDS);
    } else {
      setIndividualInputSize(DoubleInputDigits.TENS);
    }
  }, [field]);

  const setLeftValue = (value: number) => {
    if (field instanceof CurrentMaxField) {
      field.setCurrent(value);
    } else if (field instanceof BaseFinalFieldModel) {
      field.setBase(value);
    }
  };

  const onChange =
    (fn: (value: number) => void) => (e: ChangeEvent<HTMLInputElement>) => {
      let value = 0;

      try {
        value = parseInt(e.currentTarget.value);
      } catch {
        console.warn(
          `Cannot parse value ${
            e.currentTarget.value
          } to number in component with field ${field.toString()}`,
        );
      }

      if (isNaN(value)) {
        fn(0);
      } else {
        if (max !== undefined) {
          value = Math.min(value, max);
        }

        if (min !== undefined) {
          value = Math.max(value, min);
        }

        fn(value);
      }
    };

  const onLeftChange = (value: number) => {
    setLeftValue(value);
  };

  const onSpecialChange = (value: number) => {
    field.setSpecial(value);
  };

  const { leftValue, rightValue } = getValuesFromField(field);

  const { leftTooltip, rightTooltip } = getTooltips(field);

  const inputWrapperRef = useRef<HTMLDivElement>(null);

  const [popoverOpen, setPopoverOpen] = useState(false);

  const handlePopoverOpen = () => {
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

  const onKeyUp =
    (fieldName: 'left' | 'special') =>
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const currentValue = fieldName === 'left' ? leftValue : field.special;

      if (currentValue === undefined) return;

      const setValue = (val: number) => {
        let newValue = val;

        if (max !== undefined) {
          newValue = Math.min(newValue, max);
        }

        if (min !== undefined) {
          newValue = Math.max(newValue, min);
        }

        if (fieldName === 'left') {
          onLeftChange(newValue);
        } else {
          onSpecialChange(newValue);
        }
      };

      const isCtrl = e.ctrlKey || e.metaKey;

      if (isCtrl) {
        e.preventDefault();
      }

      const isAlt = e.altKey;

      if (e.key === 'ArrowUp') {
        setValue(
          currentValue +
            step +
            (isCtrl ? step * 9 : 0) +
            (isAlt ? step * 99 : 0),
        );
      }

      if (e.key === 'ArrowDown') {
        setValue(
          currentValue -
            step -
            (isCtrl ? step * 9 : 0) -
            (isAlt ? step * 99 : 0),
        );
      }
    };

  const handleOnFocus = () => {
    vibrate();
  };

  const showRightInput = rightValue !== undefined && !disableFinal;
  const showLeftInput = leftValue !== undefined && !disableBase;

  const inputDisabled = !character.canBeEdited;

  return (
    <Wrapper $labelPosition={labelPosition}>
      {label && (
        <Label $labelPosition={labelPosition} variant="body1">
          {label}
        </Label>
      )}
      <InputWrapper ref={inputWrapperRef} $orientation={orientation}>
        {!disableSpecial ? (
          <Tooltip title="Añadir valor especial" placement="top">
            <StyledSpecialButton
              $showAlways={field.special !== 0}
              $size={size}
              onClick={handlePopoverOpen}
            />
          </Tooltip>
        ) : undefined}

        <Popper
          open={popoverOpen}
          anchorEl={inputWrapperRef.current}
          placement="top"
          style={{ zIndex: 1501 }}
        >
          <ClickAwayListener onClickAway={handlePopoverClose}>
            <StyledLeftInput
              value={field.special}
              onKeyUp={onKeyUp('special')}
              onChange={onChange(onSpecialChange)}
              onFocus={handleOnFocus}
              inputProps={{
                inputMode: 'numeric',
                pattern: '^-?[0-9]\\d*',
                min: min ? min.toString() : undefined,
                max: max ? max.toString() : undefined,
              }}
              $width={DoubleInputDigits.THOUSANDS}
              $hasSibling={false}
              disabled={inputDisabled}
            />
          </ClickAwayListener>
        </Popper>

        {showLeftInput ? (
          <Tooltip title={leftTooltip}>
            <StyledLeftInput
              value={leftValue}
              onKeyUp={onKeyUp('left')}
              onChange={onChange(onLeftChange)}
              onFocus={handleOnFocus}
              inputProps={{
                inputMode: 'numeric',
                pattern: '^-?[0-9]\\d*',
                min: min ? min.toString() : undefined,
                max: max ? max.toString() : undefined,
              }}
              $width={individualInputSize}
              $size={size}
              $variant={variant}
              $hasSibling={showRightInput}
              $orientation={orientation}
              disabled={inputDisabled}
            />
          </Tooltip>
        ) : undefined}
        {showRightInput ? (
          <Tooltip title={rightTooltip}>
            <StyledRightInput
              value={rightValue}
              onFocus={handleOnFocus}
              disabled
              $size={size}
              $variant={variant}
              $hasSibling={showLeftInput}
              $width={individualInputSize}
              $orientation={orientation}
            />
          </Tooltip>
        ) : undefined}
      </InputWrapper>
    </Wrapper>
  );
};

DoubleInput.LabelPosition = LabelPosition;
DoubleInput.Variant = DoubleInputVariant;
DoubleInput.Size = DoubleInputSize;
DoubleInput.Orientation = InputsOrientation;

export default observer(DoubleInput);
