import axios from 'axios';
import { SupabaseClient } from '../supabase/supabaseClient';
import { config, Environment } from '../../../bootstrap/infrastructure/config';

const client = axios.create({
  baseURL:
    config.environment === Environment.Development
      ? 'http://localhost:3030'
      : 'https://api.charactersvault.com',
  withCredentials: true,
});

const getAuthorizationHeader = async () => {
  const response = await SupabaseClient().auth.getSession();

  const { data, error } = response;

  if (error) {
    console.error(error);

    return undefined;
  }

  if (!data.session) {
    return undefined;
  }

  return `Bearer ${data.session.access_token}`;
};

export const ApiClient = {
  get: async <T>(url: string) => {
    return client.get<T>(url, {
      headers: {
        Authorization: await getAuthorizationHeader(),
      },
    });
  },
  post: async <T>(url: string, body?: unknown) => {
    return client.post<T>(url, body, {
      headers: {
        Authorization: await getAuthorizationHeader(),
      },
    });
  },
  patch: async <T>(url: string, body?: unknown) => {
    return client.patch<T>(url, body, {
      headers: {
        Authorization: await getAuthorizationHeader(),
      },
    });
  },
  put: async <T>(url: string, body?: unknown) => {
    return client.put<T>(url, body, {
      headers: {
        Authorization: await getAuthorizationHeader(),
      },
    });
  },
  delete: async <T>(url: string) => {
    return client.delete<T>(url, {
      headers: {
        Authorization: await getAuthorizationHeader(),
      },
    });
  },
};
