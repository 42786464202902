import { model } from 'mobx-keystone';
import { PDFieldModel } from '../PDFieldModel';

@model('Character/PD/Mystic/BindPDField')
export class BindPDFieldModel extends PDFieldModel {
  get pdsCost(): number {
    return this.template.bindPDsCost;
  }

  get perCategory(): number {
    return this.template.calculateBindBonus();
  }
}
