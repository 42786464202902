import { config } from '../../bootstrap/infrastructure/config';
import { NotFoundError } from '../../shared/infrastructure/errors/NotFoundError';
import { UnauthorizedError } from '../../shared/infrastructure/errors/UnauthorizedError';
import { SupabaseClient } from '../../shared/infrastructure/supabase/supabaseClient';
import { AuthorizedUser } from '../domain/AuthorizedUser';
import { Session } from '@supabase/supabase-js';
import { migrateUserMetadata } from './migrations/UserMetadataMigrations';
import { Routes } from '../../shared/application/hooks/useNavigate';

export let authorizedUser: AuthorizedUser | undefined;

export const isLoggedIn = () => {
  return !!authorizedUser;
};

export const getAuthorizedUser = () => {
  if (!authorizedUser) {
    throw new UnauthorizedError();
  }

  return authorizedUser;
};

const migrateUser = async (session: Session) => {
  const { needToMigrate, user } = migrateUserMetadata(session.user);

  if (needToMigrate) {
    await SupabaseClient().auth.updateUser({ data: user.user_metadata });
  }

  authorizedUser = AuthorizedUser.fromPrimitives(user);
};

const getCurrentSession = async () => {
  const { data, error } = await SupabaseClient().auth.getSession();

  if (!data.session) {
    throw new UnauthorizedError();
  }

  if (error) {
    if (error.status === 401) {
      throw new UnauthorizedError();
    } else if (error.status === 404) {
      throw new NotFoundError();
    } else {
      throw error;
    }
  }

  await migrateUser(data.session);

  return authorizedUser;
};

const login = async (email: string, password: string) => {
  const { error, data } = await SupabaseClient().auth.signInWithPassword({
    email,
    password,
  });

  if (error) {
    throw error;
  }

  if (!data.session) {
    throw new UnauthorizedError();
  }

  await migrateUser(data.session);
};

const loginWithGoogle = async (embed?: boolean) => {
  const url = new URLSearchParams();

  if (embed) {
    url.append('after-embed-login', 'true');
  }

  const redirectTo = `${config.url}${Routes.Auth}?${url.toString()}`;

  const { error } = await SupabaseClient().auth.signInWithOAuth({
    provider: 'google',
    options: {
      redirectTo: redirectTo,
    },
  });

  if (error) {
    throw error;
  }

  await getCurrentSession();
};

const logout = async () => {
  const { error } = await SupabaseClient().auth.signOut();

  if (error) {
    throw error;
  }

  authorizedUser = undefined;
};

const signIn = async (email: string, password: string) => {
  const { error, data } = await SupabaseClient().auth.signUp({
    email,
    password,
    options: {
      emailRedirectTo: config.url,
    },
  });

  if (error) {
    throw error;
  }

  if (!data.session) {
    throw new UnauthorizedError();
  }

  await migrateUser(data.session);
};

export const AuthorizationService = {
  getCurrentSession,
  login,
  loginWithGoogle,
  logout,
  signIn,
};
