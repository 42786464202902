import { model, Model, modelAction, prop } from 'mobx-keystone';
import { ActPDFieldModel } from './ActPDFieldModel';
import { BanishPDFieldModel } from './BanishPDFieldModel';
import { BindPDFieldModel } from './BindPDFieldModel';
import { ControlPDFieldModel } from './ControlPDFieldModel';
import { MagicLevelPDFieldModel } from './MagicLevelPDFieldModel';
import { MagicProjectionPDFieldModel } from './MagicProjectionPDFieldModel';
import { SummonPDFieldModel } from './SummonPDFieldModel';
import { ZeonPointsPDFieldModel } from './ZeonPointsPDFieldModel';
import { ZeonRegenerationPDFieldModel } from './ZeonRegenerationPDFieldModel';
import { getParentCharacter } from '../../../../../../../../utils/parenting/getParentCharacter';
import { MysticTableModel } from '../../../../../mystic/MysticTableModel';
import { MysticTableType } from '../../../../../../../../aggregations/tables/mystic-tables/MysticTable';

@model('Character/PD/MysticPD')
export class MysticPDModel extends Model({
  zeonPoints: prop(() => new ZeonPointsPDFieldModel({})),
  act: prop(() => new ActPDFieldModel({})),
  zeonRegeneration: prop(() => new ZeonRegenerationPDFieldModel({})),
  magicProjection: prop(() => new MagicProjectionPDFieldModel({})),
  magicLevel: prop(() => new MagicLevelPDFieldModel({})),
  summon: prop(() => new SummonPDFieldModel({})),
  control: prop(() => new ControlPDFieldModel({})),
  bind: prop(() => new BindPDFieldModel({})),
  banish: prop(() => new BanishPDFieldModel({})),

  tables: prop<MysticTableModel[]>(() => []),
}) {
  get pdsSpent(): number {
    if (!this.character) return 0;

    return (
      this.zeonPoints.base +
      this.act.base +
      this.zeonRegeneration.base +
      this.magicProjection.base +
      this.magicLevel.base +
      this.summon.base +
      this.control.base +
      this.bind.base +
      this.banish.base +
      this.tables.reduce((acc, table) => acc + table.cost, 0)
    );
  }

  @modelAction
  addMysticTable(type: MysticTableType) {
    this.tables.push(new MysticTableModel({ type }));
  }

  @modelAction
  removeMysticTable(table: MysticTableModel) {
    this.tables = this.tables.filter((t) => t !== table);
  }

  get character() {
    return getParentCharacter(this);
  }
}
