import { model } from 'mobx-keystone';
import { PDFieldModel } from '../PDFieldModel';
import { getACTBaseFromPower } from '../../../../../mystic/utils/getACTBaseFromPower';

@model('ZeonRegenerationField')
export class ZeonRegenerationPDFieldModel extends PDFieldModel {
  get pdsCost(): number {
    return this.template.aCTMultiplierPDsCost / 2;
  }

  get bought(): number {
    if (!this.character) return 0;

    const actBase = getACTBaseFromPower(this.character.primaries.power.final);

    return Math.floor((this.base + this.special) / this.pdsCost) * actBase;
  }

  get perCategory(): number | undefined {
    return undefined;
  }
}
