import { Difficulty } from '../../character/model/types/Difficulty';
import { PsychicDisciplineType } from '../psychic-disciplines/PsychicDiscipline';

export enum PsychicPowerActionType {
  Active = 'Active',
  Passive = 'Passive',
}

export type PsychicPowerCast = Record<Difficulty, string>;

export type PsychicPowerId = string;

export type PsychicPower = {
  id: PsychicPowerId;
  discipline: PsychicDisciplineType;
  name: string;
  description?: string;
  level?: number;
  hasMaintenance: boolean;
  actionType: PsychicPowerActionType;

  minimalDifficultyToSuccess: Difficulty;
  casts: PsychicPowerCast;
};
