import {
  BasicMartialArtGradeIds,
  buildAdvancedMartialArtArcaneGrade,
  buildAdvancedMartialArtBasicGrade,
} from '../../MartialArtGrades';
import {
  AdvancedMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { calculateHighestDamageFromKnownMartialArts } from './utils/calculateHighestDamageFromKnownMartialArts';
import { HitType } from '../../../../character/model/types/HitType';
import { calculateMartialArtsRequirements } from './utils/calculateMartialArtsRequirements';
import { KiSkillType } from '../../../ki-skill/KiSkill';

const BasicMartialArtsRequirement = [
  {
    type: MartialArtType.Shotokan,
    grade: BasicMartialArtGradeIds.Advanced,
  },
  {
    type: MartialArtType.MoaiThai,
    grade: BasicMartialArtGradeIds.Advanced,
  },
];

const ArcaneMartialArtsRequirement = [
  {
    type: MartialArtType.Shotokan,
    grade: BasicMartialArtGradeIds.Supreme,
  },
  {
    type: MartialArtType.MoaiThai,
    grade: BasicMartialArtGradeIds.Supreme,
  },
];

export class HakyoukukenMartialArt extends AdvancedMartialArt {
  readonly name = 'Hakyoukuken';

  readonly complexity = 5;

  readonly type = MartialArtType.Hakyoukuken;

  readonly level = MartialArtLevelType.Advanced;

  readonly grades = [
    buildAdvancedMartialArtBasicGrade({
      cmBonus: 10,
      effects:
        '-2 a la TA en armaduras blandas. +20 a la tirada de crítico. Sólo para enemigos organicos.',
      combatBonus: {
        initiative: 20,
        attack: 10,
      },
      calculateDamage: (character) => {
        return (
          calculateHighestDamageFromKnownMartialArts(
            character,
            BasicMartialArtsRequirement,
          ) + 20
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          BasicMartialArtsRequirement,
        );

        if (!character.ki.hasKiSkill(KiSkillType.NeededEnergyUse)) {
          errors.push('Habilidad de Ki: Uso de energía necesaria');
        }

        if (character.combat.attackAbility.final < 200) {
          errors.push('Ataque 200+');
        }

        return errors;
      },
    }),
    buildAdvancedMartialArtArcaneGrade({
      cmBonus: 20,
      effects:
        'Reduce toda la TA de las armaduras blandas excepto las dadas por calidad. El bono de crítico se incrementa a +40.',
      combatBonus: {
        initiative: 20,
      },
      masterBonus: {
        attack: 20,
      },
      calculateDamage: (character) => {
        return (
          calculateHighestDamageFromKnownMartialArts(
            character,
            BasicMartialArtsRequirement,
          ) + 30
        );
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          ArcaneMartialArtsRequirement,
        );

        if (!character.hasInhumanity) {
          errors.push('Inhumanidad');
        }

        if (character.combat.attackAbility.final < 300) {
          errors.push('Ataque 300+');
        }

        return errors;
      },
    }),
  ];
}
