import { model } from 'mobx-keystone';
import {
  CombatSenseAdvantage,
  CombatSenseAdvantageVariation,
} from '../../../../../aggregations/advantage/CommonAdvantages';
import { getTotalOf } from '../../../utils/getTotalOf';
import { DevelopedPDPhysicalFieldModel } from '../../../utils/fields/DevelopedPDPhysicalFieldModel';
import { computed } from 'mobx';
import { Calculation } from '../../../utils/fields/NumberField';

@model('Character/Combat/BlockAbility')
export class BlockAbilityFieldModel extends DevelopedPDPhysicalFieldModel {
  get primariesBonus(): number {
    if (!this.character) return 0;

    return this.character.primaries.dexterity.modifier.final;
  }

  get boughtFromCategories(): number {
    return getTotalOf(this.character, (c) => c.combat.blockAbility.bought);
  }

  get fromCategories(): number {
    return this.calculateFromCalculations(this.fromCategoriesCalculations);
  }

  get fromCategoriesCalculations(): Calculation[] {
    if (!this.character) return [];

    const calculations: Calculation[] = [];

    const categoryCalculations: Calculation[] = [];

    const totalFromPerCategory = getTotalOf(
      this.character,
      (c) => c.combat.blockAbility.perCategory,
    );

    categoryCalculations.push(this.sum('Por categoría', totalFromPerCategory));

    calculations.push(...categoryCalculations);
    calculations.push(this.max('Por categoría', 50, categoryCalculations));

    const bought = getTotalOf(
      this.character,
      (c) => c.combat.blockAbility.bought,
    );

    calculations.push(this.sum('Comprados', bought));

    const bonus = getTotalOf(
      this.character,
      (c) => c.combat.blockAbility.bonus,
    );

    calculations.push(this.sum('Bonos', bonus));

    calculations.push(
      this.sum(
        'Bonos por artes marciales avanzadas',
        this.character.combat.martialArtsHighestMasterBonus.block,
        { hideOnZero: true },
      ),
    );

    return calculations;
  }

  @computed
  get isDeveloped(): boolean {
    return this.boughtFromCategories > 0;
  }

  get bonusFromAdvantages(): number {
    if (!this.character) return 0;

    let value = 0;

    if (
      this.character.creationPoints.containsAdvantage(CombatSenseAdvantage, {
        variation: CombatSenseAdvantageVariation.Block,
      })
    ) {
      value += 5 * this.character.level;
    }

    return value;
  }
}
