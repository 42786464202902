import {
  BasicMartialArtGradeIds,
  buildAdvancedMartialArtArcaneGrade,
  buildAdvancedMartialArtBasicGrade,
} from '../../MartialArtGrades';
import {
  AdvancedMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { calculateHighestDamageFromKnownMartialArts } from './utils/calculateHighestDamageFromKnownMartialArts';
import { HitType } from '../../../../character/model/types/HitType';
import { calculateMartialArtsRequirements } from './utils/calculateMartialArtsRequirements';
import { KiSkillType } from '../../../ki-skill/KiSkill';

const BasicMartialArtsRequirement = [
  {
    type: MartialArtType.SooBahk,
    grade: BasicMartialArtGradeIds.Advanced,
  },
];

const ArcaneMartialArtsRequirement = [
  {
    type: MartialArtType.SooBahk,
    grade: BasicMartialArtGradeIds.Supreme,
  },
];

export class HanjaMartialArt extends AdvancedMartialArt {
  readonly name = 'Hanja';

  readonly complexity = 3;

  readonly type = MartialArtType.Hanja;

  readonly level = MartialArtLevelType.Advanced;

  readonly grades = [
    buildAdvancedMartialArtBasicGrade({
      cmBonus: 10,
      effects:
        'No tiene penalizador al defender su espalda o espacios reducidos. Permite atacarles sin encararlos.',
      combatBonus: {
        block: 10,
        dodge: 10,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          BasicMartialArtsRequirement,
        );

        if (!character.ki.hasKiSkill(KiSkillType.KiDetection)) {
          errors.push('Habilidad de Ki: Detección de Ki');
        }

        if (character.secondaries.perceptive.notice.final < 200) {
          errors.push('Advertir 200+');
        }

        if (character.combat.defensiveAbility < 200) {
          errors.push('Bloqueo o esquiva 200+');
        }

        return errors;
      },
    }),
    buildAdvancedMartialArtArcaneGrade({
      cmBonus: 20,
      effects:
        'No sufre penalizador por el entorno en ningún caso a la hora de defenderse. Además tampoco sufre penalizador en caso de parálisis menor o parcial, en presa o amenazado.',
      masterBonus: {
        attack: 20,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          ArcaneMartialArtsRequirement,
        );

        if (!character.hasInhumanity) {
          errors.push('Inhumanidad');
        }

        if (!character.ki.hasKiSkill(KiSkillType.Erudition)) {
          errors.push('Habilidad de Ki: Erudición');
        }

        if (character.secondaries.perceptive.notice.final < 240) {
          errors.push('Advertir 240+');
        }

        if (character.combat.defensiveAbility < 300) {
          errors.push('Bloqueo o esquiva 300+');
        }

        return errors;
      },
    }),
  ];
}
