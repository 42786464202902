import { useCallback, useEffect, useState } from 'react';
import { Character } from '../../../../../../domain/character/Character';
import { CharacterService } from '../../../../../../infrastructure/CharacterService';
import { NotificationManager } from '../../../../../../../shared/application/shared/NotificationManager';

export const useFetchCharacters = (folderId?: string) => {
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<Error | undefined>(undefined);

  const [characters, setCharacters] = useState<Character[]>([]);

  const fetch = useCallback(
    (params?: { keepLoadingOnFalse?: boolean }) => {
      if (error) {
        return;
      }

      setLoading(!params?.keepLoadingOnFalse);

      CharacterService.getCharacters(folderId !== 'root' ? folderId : undefined)
        .then((data) => {
          const fetchedCharacters = data.characters;

          if (data.characterErrorNames.length > 0) {
            NotificationManager.error(
              `Los siguientes personajes no se han podido cargar: ${data.characterErrorNames.join(
                ', ',
              )}`,
            );
          }

          setCharacters(fetchedCharacters);
        })
        .catch((error) => {
          NotificationManager.error(
            'Ha ocurrido un error al cargar los personajes',
          );

          console.error(error);

          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [error, folderId],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    characters,
    errorLoadingCharacters: error,
    loadingCharacters: loading,
    fetchCharacters: fetch,
  };
};
