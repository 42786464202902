import { useModal } from '../design-system/components/Modal/hooks/useModal';
import { ConfirmModal } from '../design-system/components/ConfirmModal/ConfirmModal';

export const useConfirmModal = () => {
  const { Modal, openModal, closeModal } = useModal();

  return {
    openConfirmModal: async ({
      title,
      content,

      buttons,
    }: {
      title: string;
      content: string;

      buttons?: {
        confirm: string;
        reject: string;
      };
    }) => {
      return new Promise<boolean>((resolve) => {
        const handleConfirm = () => {
          closeModal();

          resolve(true);
        };

        const handleReject = () => {
          closeModal();

          resolve(false);
        };

        openModal(
          <ConfirmModal
            title={title}
            content={content}
            buttons={{
              confirm: buttons?.confirm || 'Confirmar',
              reject: buttons?.reject || 'Cancelar',
            }}
            onConfirm={handleConfirm}
            onReject={handleReject}
          />,
        );
      });
    },
    closeConfirmModal: closeModal,
    ConfirmModal: Modal,
  };
};
