import { ExtendedModel, model, prop } from 'mobx-keystone';
import { SecondarySkill } from '../../../../../../../types/SecondarySkill';
import { SecondarySkillPDFieldModel } from '../SecondarySkillPDFieldModel';
import { SecondarySkillBonusType } from '../SecondarySkillPDFieldModel.types';
import { SecondarySkillsPDModel } from './SecondarySkillsPDModel';

@model('Character/Development/SecondarySkills/IntellectualSkillsPD')
export class IntellectualSkillsPDModel extends ExtendedModel(
  SecondarySkillsPDModel,
  {
    animals: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Animals,
          type: SecondarySkillBonusType.Intelligence,
        }),
    ),
    science: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Science,
          type: SecondarySkillBonusType.Intelligence,
        }),
    ),
    law: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Law,
          type: SecondarySkillBonusType.Intelligence,
        }),
    ),
    herbalism: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Herbalism,
          type: SecondarySkillBonusType.Intelligence,
        }),
    ),
    history: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.History,
          type: SecondarySkillBonusType.Intelligence,
        }),
    ),
    tactics: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Tactics,
          type: SecondarySkillBonusType.Intelligence,
        }),
    ),
    medicine: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Medicine,
          type: SecondarySkillBonusType.Intelligence,
        }),
    ),
    memorize: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Memorize,
          type: SecondarySkillBonusType.Intelligence,
        }),
    ),
    navigation: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Navigation,
          type: SecondarySkillBonusType.Intelligence,
        }),
    ),
    occultism: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Occultism,
          type: SecondarySkillBonusType.Intelligence,
        }),
    ),
    appraisal: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Appraisal,
          type: SecondarySkillBonusType.Intelligence,
        }),
    ),
    magicAppraisal: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.MagicAppraisal,
          type: SecondarySkillBonusType.Power,
        }),
    ),
  },
) {
  get fields(): SecondarySkillPDFieldModel[] {
    return [
      this.animals,
      this.science,
      this.law,
      this.herbalism,
      this.history,
      this.tactics,
      this.medicine,
      this.memorize,
      this.navigation,
      this.occultism,
      this.appraisal,
      this.magicAppraisal,
    ];
  }
}
