import { model } from 'mobx-keystone';
import { ExhaustedDisadvantage } from '../../../../../aggregations/disadvantage/CommonDisadvantages';
import { BaseFinalFieldModel } from '../../../utils/fields/BaseFinalFieldModel';

@model('AllActionsModifierField')
export class AllActionsModifierFieldModel extends BaseFinalFieldModel {
  get final(): number {
    if (!this.character) return 0;

    const currentFatigue = this.character.fatigue.current;
    const maxFatigue = this.character.fatigue.max;

    const finalValue = this.base + this.special;

    if (currentFatigue >= maxFatigue) return finalValue;

    const penalty = this.fatiguePenalty;

    return finalValue - penalty;
  }

  get fatiguePenalty(): number {
    if (!this.character) return 0;

    let penalty = 0;

    const currentFatigue = this.character.fatigue.current;

    switch (currentFatigue) {
      case 0:
        penalty = 120;
        break;
      case 1:
        penalty = 80;
        break;
      case 2:
        penalty = 40;
        break;
      case 3:
        penalty = 20;
        break;
      case 4:
        penalty = 10;
        break;
    }

    if (
      this.character.creationPoints.containsDisadvantage(ExhaustedDisadvantage)
    ) {
      penalty *= 2;
    }
    return penalty;
  }
}
