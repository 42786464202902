import {
  ExportToFoundryIconImage,
  ExportToFoundryIconsContainer,
  ExportToFoundryImage,
  Section,
  StyledButton,
  StyledTextArea,
  Wrapper,
} from './HowToImport.styled';
import {
  BasicParagraph,
  BasicTitle,
  BigTitle,
} from '../../../../../../shared/application/design-system/components/Texts/Texts.styled';

import ExportToFoundry from './assets/ExportToFoundry.png';
import ExportToFoundryAsNewCharacterIcon from './assets/ExportToFoundryAsNewCharacter.png';
import ExportToFoundryUpdatingCharacterIcon from './assets/ExportToFoundryUpdatingCharacter.png';
import { ClipboardService } from '../../../../../../shared/application/shared/ClipboardService';
import { NotificationManager } from '../../../../../../shared/application/shared/NotificationManager';

const ExportToFoundryScript =
  'fetch("https://raw.githubusercontent.com/Linkaynn/characters-vault-foundry-vtt/main/dist/character-vault-foundry.production.js")\n.then(r => r.text())\n.then(eval)\n.catch(e => {\n    console.error(e);\n    new Dialog(\n      {\n        title: "Error",\n        content: "<div>An error occurred trying to execute the script.</div>",\n        buttons: {},\n        default: \'\',\n      },\n    ).render(true);\n});';

export const HowToImport = () => {
  const handleCopyExportToFoundryScript = () => {
    ClipboardService.copyText(ExportToFoundryScript)
      .then(() => {
        NotificationManager.success('Script copiado al portapapeles');
      })
      .catch(() => {
        NotificationManager.error('Error al copiar el script al portapapeles');
      });
  };

  return (
    <Wrapper>
      <BigTitle>Importación y exportación de personajes</BigTitle>
      <Section>
        <BasicTitle>VTTs</BasicTitle>
        <BasicParagraph>
          Actualmente sólo soportamos FoundryVTT pero tenemos planteados añadir
          más Virtual Table Tops en el futuro.
        </BasicParagraph>
        <BasicParagraph>
          Desde Characters Vault podemos exportar nuestros personajes
          directamente a nuestra campaña en Foundry. También podemos importar
          desde Foundry hacia Characters Vault pero con ciertos matices.
        </BasicParagraph>
      </Section>

      <Section>
        <BasicTitle>Exportando a Foundry</BasicTitle>
        <BasicParagraph>
          <b>
            Nota importante: Hay un bug conocido que hace que en Firefox no
            importe correctamente, estamos trabajando en ello. Por ahora
            recomendamos usar Google Chrome.
          </b>
        </BasicParagraph>
        <BasicParagraph>
          Para exportar lo primero que debemos hacer es crearnos una macro de
          tipo script en la cual añadimos lo siguiente:
        </BasicParagraph>
        <StyledTextArea disabled value={ExportToFoundryScript} rows={10} />
        <StyledButton onClick={handleCopyExportToFoundryScript}>
          Copiar script
        </StyledButton>
        <BasicParagraph>
          Una vez añadido, si lo ejecutamos, nos pedirá que iniciemos sesión en
          Foundry, nos abrirá una pestaña nueva y tras habernos logeado,
          cerramos la pestaña.
        </BasicParagraph>
        <BasicParagraph>
          Si todo ha ido bien deberíamos ver lo siguiente:
        </BasicParagraph>
        <ExportToFoundryImage src={ExportToFoundry} />
        <BasicParagraph>Una vez aquí vemos dos botones:</BasicParagraph>
        <ExportToFoundryIconsContainer>
          <ExportToFoundryIconImage src={ExportToFoundryAsNewCharacterIcon} />
          <BasicParagraph>
            <b>Exportar como nuevo personaje:</b> Esto nos generará un nuevo
            personaje con los datos exportados desde Characters Vault
          </BasicParagraph>
        </ExportToFoundryIconsContainer>
        <ExportToFoundryIconsContainer>
          <ExportToFoundryIconImage
            src={ExportToFoundryUpdatingCharacterIcon}
          />
          <BasicParagraph>
            <b>Exportar datos hacia un personaje pre-existente:</b> Este botón
            nos abrirá una ventana en la que podremos ver los personajes que hay
            en nuestra campaña. Al hacer click en uno de ellos, los datos de
            Characters Vault se importarán en dicho personaje.
          </BasicParagraph>
        </ExportToFoundryIconsContainer>
      </Section>

      <Section>
        <BasicTitle>Importando desde Foundry</BasicTitle>
        <BasicParagraph>
          Tenemos que destacar que esta importación es <b>parcial</b> ya que en
          Foundry mucha de la información sobre los personajes no es
          estandarizada o los cálculos que aparecen no se pueden descomponer.
        </BasicParagraph>
        <BasicParagraph>
          Debido a esto los datos que importamos desde Foundry son:
        </BasicParagraph>

        <ul>
          <li>Características primarias</li>
          <li>Ojos, género, edad, nombre, altura, pelo...</li>
          <li>Notas</li>
          <li>
            Categorías (
            <b>
              Sólo si la categoría coincide perfectamente con el nombre de la
              categoría en Characters Vault
            </b>
            )
          </li>
        </ul>
      </Section>

      <Section>
        <BasicTitle>Importar o exportar desde Characters Vault</BasicTitle>
        <BasicParagraph>
          Desde la ficha del personaje podemos exportar la información del
          personaje en un fichero <b>cvt</b>. De la misma manera{' '}
          <b>
            si un amigo nos comparte su personaje en este tipo de fichero,
            nosotros lo podemos importar y posteriorimente importarlo en nuestra
            campaña.
          </b>
        </BasicParagraph>
      </Section>
    </Wrapper>
  );
};
