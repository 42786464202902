import { model } from 'mobx-keystone';
import { DevelopedPDFieldModel } from '../../../utils/fields/DevelopedPDFieldModel';
import { getTotalOf } from '../../../utils/getTotalOf';
import { Calculation } from '../../../utils/fields/NumberField';
import { Devah } from '../../../../../aggregations/nephilim-type/Nephilim';

@model('Character/Mystic/ControlField')
export class ControlFieldModel extends DevelopedPDFieldModel {
  get primariesBonus(): number {
    if (!this.character) return 0;

    return this.character.primaries.willPower.modifier.final;
  }

  get allActionsPenalty(): number {
    const allActionsPenalty = super.allActionsPenalty;

    if (allActionsPenalty > 0) return 0;

    return allActionsPenalty;
  }

  get allBonuses(): Calculation[] {
    if (!this.character) return [];

    const calculations = super.allBonuses;

    if (this.character.isNephilim(Devah)) {
      calculations.push(this.sum(Devah.name, 10));
    }

    return calculations;
  }

  get fromCategories(): number {
    return getTotalOf(this.character, (c) => c.mystic.control.final);
  }
}
