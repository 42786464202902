import { AllBackgroundDisadvantages } from './BackgroundDisadvantages';
import { AllCommonDisadvantages } from './CommonDisadvantages';
import {
  Disadvantage,
  DisadvantageId,
  DisadvantageType,
} from './Disadvantage.types';
import { AllDonDisadvantages } from './DonDisadvantages';
import { AllPsychicDisadvantages } from './PsychicDisadvantages';

export const getUnusedDisadvantageOfType = (
  advantagesIds: DisadvantageId[],
  type: DisadvantageType,
): Disadvantage | undefined => {
  let advantages: Disadvantage[] = [];

  switch (type) {
    case DisadvantageType.Common:
      advantages = AllCommonDisadvantages;
      break;
    case DisadvantageType.Don:
      advantages = AllDonDisadvantages;
      break;
    case DisadvantageType.Psychics:
      advantages = AllPsychicDisadvantages;
      break;
    case DisadvantageType.Background:
      advantages = AllBackgroundDisadvantages;
      break;
  }

  return advantages.find((a) => !advantagesIds.some((b) => b === a.id));
};
