import { v4 as uuid } from 'uuid';
import { Model, model, modelAction, prop } from 'mobx-keystone';
import {
  KiTechnique,
  KiTechniqueCost,
} from '../../../../../aggregations/ki-technique/KiTechnique';

@model('Character/Ki/KiTechnique')
export class KiTechniqueModel extends Model({
  id: prop<string>().withSetter(),

  originalId: prop<string | undefined>(undefined).withSetter(),
  isCustom: prop<boolean>(false).withSetter(),

  name: prop<string>().withSetter(),

  type: prop<string>().withSetter(),

  mpCost: prop<number>().withSetter(),

  kiCost: prop<KiTechniqueCost>().withSetter(),

  effect: prop<string>().withSetter(),

  level: prop<number>().withSetter(),

  notes: prop<string | undefined>(undefined).withSetter(),
}) {
  @modelAction
  transformToCustom() {
    if (!this.isCustom) {
      this.isCustom = true;
      this.originalId = this.id;
      this.id = uuid();
    }
  }
}

export const createModelFromKiTechnique = (
  kiTechnique: KiTechnique,
): KiTechniqueModel =>
  new KiTechniqueModel({
    id: kiTechnique.id,
    name: kiTechnique.name,
    type: kiTechnique.type,
    mpCost: kiTechnique.mpCost,
    kiCost: kiTechnique.kiCost,
    effect: kiTechnique.effect,
    level: kiTechnique.level,
    notes: kiTechnique.notes,
  });
