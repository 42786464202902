import { model } from 'mobx-keystone';
import { DevelopedPDFieldModel } from '../../../utils/fields/DevelopedPDFieldModel';
import { getTotalOf } from '../../../utils/getTotalOf';
import { getACTBaseFromPower } from '../utils/getACTBaseFromPower';

@model('Character/Mystic/ActField')
export class ActFieldModel extends DevelopedPDFieldModel {
  get fromCategories(): number {
    if (!this.character) return 0;

    return (
      getTotalOf(this.character, (c) => c.mystic.act.final) +
      this.character.mystic.zeonAmplifier
    );
  }

  get allActionsPenalty(): number {
    const allActionsPenalty = super.allActionsPenalty;

    if (allActionsPenalty > 0) return 0;

    return Math.floor(allActionsPenalty / 2);
  }

  get primariesBonus(): number {
    if (!this.character) return 0;

    return getACTBaseFromPower(this.character.primaries.power.final);
  }
}
