export enum NemesisSkillType {
  NemesisUse = 'NemesisUse',
  VoidArmor = 'VoidArmor',
  NohtArmor = 'NohtArmor',
  KiAnnulation = 'KiAnnulation',
  MajorKiAnnulation = 'MajorKiAnnulation',
  MagicAnnulation = 'MagicAnnulation',
  MajorMagicAnnulation = 'MajorMagicAnnulation',
  MatrixAnnulation = 'MatrixAnnulation',
  MajorMatrixAnnulation = 'MajorMatrixAnnulation',
  BindingAnnulation = 'BindingAnnulation',
  VoidExtrusion = 'VoidExtrusion',
  VoidForm = 'VoidForm',
  VoidBody = 'VoidBody',
  NoNeeds = 'NoNeeds',
  VoidMovement = 'VoidMovement',
  VoidEssence = 'VoidEssence',
  OneWithVoid = 'OneWithVoid',
  VoidAura = 'VoidAura',
  Undetectability = 'Undetectability',
  Inhumanity = 'Inhumanity',
  Zen = 'Zen',
}

export type NemesisSkill = {
  name: string;

  type: NemesisSkillType;

  cost: number;

  parents?: NemesisSkill[];
};

export const NemesisUseSkill: NemesisSkill = {
  name: 'Uso del Nemesis',
  type: NemesisSkillType.NemesisUse,
  cost: 70,
};

export const VoidArmorSkill: NemesisSkill = {
  name: 'Armadura de Vacío',
  type: NemesisSkillType.VoidArmor,
  cost: 20,
  parents: [NemesisUseSkill],
};

export const NohtArmorSkill: NemesisSkill = {
  name: 'Armadura Noht',
  type: NemesisSkillType.NohtArmor,
  cost: 30,
  parents: [VoidArmorSkill],
};

export const KiAnnulationSkill: NemesisSkill = {
  name: 'Anulación de Ki',
  type: NemesisSkillType.KiAnnulation,
  cost: 30,
  parents: [NemesisUseSkill],
};

export const MajorKiAnnulationSkill: NemesisSkill = {
  name: 'Anulación de Ki Mayor',
  type: NemesisSkillType.MajorKiAnnulation,
  cost: 20,
  parents: [KiAnnulationSkill],
};

export const MagicAnnulationSkill: NemesisSkill = {
  name: 'Anulación de Magia',
  type: NemesisSkillType.MagicAnnulation,
  cost: 30,
  parents: [NemesisUseSkill],
};

export const MajorMagicAnnulationSkill: NemesisSkill = {
  name: 'Anulación de Magia Mayor',
  type: NemesisSkillType.MajorMagicAnnulation,
  cost: 20,
  parents: [MagicAnnulationSkill],
};

export const MatrixAnnulationSkill: NemesisSkill = {
  name: 'Anulación de Matrices',
  type: NemesisSkillType.MatrixAnnulation,
  cost: 30,
  parents: [NemesisUseSkill],
};

export const MajorMatrixAnnulationSkill: NemesisSkill = {
  name: 'Anulación de Matrices Mayor',
  type: NemesisSkillType.MajorMatrixAnnulation,
  cost: 20,
  parents: [MatrixAnnulationSkill],
};

export const BindingAnnulationSkill: NemesisSkill = {
  name: 'Anulación de lazos',
  type: NemesisSkillType.BindingAnnulation,
  cost: 30,
  parents: [NemesisUseSkill],
};

export const VoidExtrusionSkill: NemesisSkill = {
  name: 'Extrusión de Vacío',
  type: NemesisSkillType.VoidExtrusion,
  cost: 30,
  parents: [NemesisUseSkill],
};

export const VoidBodySkill: NemesisSkill = {
  name: 'Cuerpo de Vacío',
  type: NemesisSkillType.VoidBody,
  cost: 10,
  parents: [NemesisUseSkill],
};

export const VoidFormSkill: NemesisSkill = {
  name: 'Forma de Vacío',
  type: NemesisSkillType.VoidForm,
  cost: 30,
  parents: [VoidBodySkill, VoidExtrusionSkill],
};

export const NoNeedsSkill: NemesisSkill = {
  name: 'Sin Necesidades',
  type: NemesisSkillType.NoNeeds,
  cost: 10,
  parents: [VoidBodySkill],
};

export const VoidMovementSkill: NemesisSkill = {
  name: 'Movimiento de Vacío',
  type: NemesisSkillType.VoidMovement,
  cost: 20,
  parents: [VoidBodySkill],
};

export const VoidEssenceSkill: NemesisSkill = {
  name: 'Esencia de Vacío',
  type: NemesisSkillType.VoidEssence,
  cost: 20,
  parents: [VoidBodySkill],
};

export const OneWithVoidSkill: NemesisSkill = {
  name: 'Uno con la nada',
  type: NemesisSkillType.OneWithVoid,
  cost: 40,
  parents: [VoidEssenceSkill],
};

export const VoidAuraSkill: NemesisSkill = {
  name: 'Aura de Vacío',
  type: NemesisSkillType.VoidAura,
  cost: 30,
  parents: [NemesisUseSkill],
};

export const UndetectabilitySkill: NemesisSkill = {
  name: 'Indetección',
  type: NemesisSkillType.Undetectability,
  cost: 10,
  parents: [NemesisUseSkill],
};

export const InhumanitySkill: NemesisSkill = {
  name: 'Inhumanidad',
  type: NemesisSkillType.Inhumanity,
  cost: 20,
  parents: [NemesisUseSkill],
};

export const ZenSkill: NemesisSkill = {
  name: 'Zen',
  type: NemesisSkillType.Zen,
  cost: 40,
  parents: [InhumanitySkill],
};

export const NemesisSkills: NemesisSkill[] = [
  NemesisUseSkill,
  VoidArmorSkill,
  NohtArmorSkill,
  KiAnnulationSkill,
  MajorKiAnnulationSkill,
  MagicAnnulationSkill,
  MajorMagicAnnulationSkill,
  MatrixAnnulationSkill,
  MajorMatrixAnnulationSkill,
  BindingAnnulationSkill,
  VoidExtrusionSkill,
  VoidFormSkill,
  VoidBodySkill,
  NoNeedsSkill,
  VoidMovementSkill,
  VoidEssenceSkill,
  OneWithVoidSkill,
  VoidAuraSkill,
  UndetectabilitySkill,
  InhumanitySkill,
  ZenSkill,
];

export const getNemesisSkillFromType = (
  type: NemesisSkillType,
): NemesisSkill => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return NemesisSkills.find((skill) => skill.type === type)!;
};
