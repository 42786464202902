import { useEffect } from 'react';
import {
  config,
  Environment,
} from '../../../../../../../bootstrap/infrastructure/config';
import { getSnapshot, onSnapshot } from 'mobx-keystone';
import { Character } from '../../../../../../domain/character/Character';

export const useAddCharacterToDevScope = (character?: Character) => {
  useEffect(() => {
    if (!character) return;

    if (config.environment === Environment.Development) {
      (window as any).character = character.content;
      (window as any).characterSnapshot = getSnapshot(character.content);

      return onSnapshot(character.content, (snapshot) => {
        (window as any).characterSnapshot = snapshot;
      });
    }
  }, [character]);
};
