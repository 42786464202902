import {
  SocialLoginContainer,
  WhiteParagraph,
} from '../../Autorization.styled';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { useSearchParams } from 'react-router-dom';
import { useUserAuthorization } from '../../../../../../../shared/application/hooks/useUserAuthorization';

export const SocialLogin = ({
  disableSocialLogin,
  buttonText,
}: {
  disableSocialLogin?: boolean;
  buttonText: string;
}) => {
  const queryParams = useSearchParams();

  const hasEmbeddedQueryParam = queryParams[0]?.get?.('embed') === 'true';

  const { loginWithGoogle } = useUserAuthorization({
    redirect: false,
  });

  const handleLoginWithGoogle = () => {
    return loginWithGoogle(hasEmbeddedQueryParam);
  };
  return (
    <>
      {!disableSocialLogin ? (
        <SocialLoginContainer>
          <GoogleLoginButton
            onClick={handleLoginWithGoogle}
            text={`${buttonText} con Google`}
          />
          <WhiteParagraph>o si prefieres</WhiteParagraph>
        </SocialLoginContainer>
      ) : undefined}
    </>
  );
};
