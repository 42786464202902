import { CharacterModel } from '../../../../character/model/CharacterModel';
import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class AikidoMartialArt extends BasicMartialArt {
  readonly name = 'Aikido';

  readonly complexity = 2;

  readonly type = MartialArtType.Aikido;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      effects:
        'En contraataque contra humanoides, añade el doble bono de fuerza del contrincante (min +5)',
      calculateDamage: (character: CharacterModel) => {
        return character.primaries.strength.modifier.final + 10;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character: CharacterModel) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 20) {
          errors.push('Trucos de manos 20+');
        }

        return errors;
      },
    }),

    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      effects:
        'No aplica penalizadores en presa o derribo durante contraataque',
      combatBonus: {
        block: 10,
        dodge: 10,
      },
      getRequirementsErrors: (character: CharacterModel) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 40) {
          errors.push('Trucos de manos 40+');
        }

        if (character.combat.attackAbility.final < 100) {
          errors.push('Ataque 100+');
        }

        if (character.combat.defensiveAbility < 120) {
          errors.push('Bloqueo o esquiva 120+');
        }

        return errors;
      },
    }),

    buildBasicMartialArtSupremeGrade({
      cmBonus: 10,
      effects:
        'Bonificador de +2 a los controles en presa o derribo y cuadriplica el bono del adversario en contraataque. Si se usa otro arte marcial, se puede añadir el bono de fuerza (sin multiplicar) al daño base de cualquier otro arte marcial.',
      combatBonus: {
        block: 10,
        dodge: 10,
      },
      getRequirementsErrors: (character: CharacterModel) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 40) {
          errors.push('Trucos de manos 80+');
        }

        if (character.combat.defensiveAbility < 200) {
          errors.push('Bloqueo o esquiva 200+');
        }

        return errors;
      },
    }),
  ];
}
