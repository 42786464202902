import { Tooltip } from '@mui/material';
import { vibrate } from '../../../../../../../../../shared/utils/vibrate';
import { StyledDeleteIcon } from './RemoveItemButton.styled';
import { useCharacterContext } from '../../../hooks/useCharacterContext';

type RemoveItemButtonProps = {
  onClick: () => void;
  children?: string;
};

export const RemoveItemButton = ({
  children,
  onClick,
}: RemoveItemButtonProps) => {
  const { character } = useCharacterContext();

  const handleButtonClick = () => {
    vibrate();
    onClick();
  };

  const inputDisabled = !character.canBeEdited;

  const button = (
    <StyledDeleteIcon
      $disabled={inputDisabled}
      role="button"
      onClick={handleButtonClick}
    />
  );

  if (children) {
    return <Tooltip title={children}>{button}</Tooltip>;
  }

  return button;
};
