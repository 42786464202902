import { PhysicalSecondarySkillFieldModel } from './PhysicalSecondarySkillFieldModel';
import { Calculation } from '../../../../utils/fields/NumberField';

export abstract class SecondarySkillFieldAffectedByNaturalPenaltyModel extends PhysicalSecondarySkillFieldModel {
  get naturalPenalty(): number {
    if (!this.character) return 0;

    return this.character.modifiers.naturalPenalty.finalWithBonus;
  }

  get finalWithoutNaturalPenalty(): number {
    return this.final + this.naturalPenalty;
  }

  getCalculations(): Calculation[] {
    const calculations = super.getCalculations();

    calculations.push(this.sub('Penalizador natural', this.naturalPenalty));

    return calculations;
  }
}
