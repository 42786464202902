import styled from 'styled-components';

export const Wrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',

  gap: size.units(1),
}));

export const NephilimEffectsList = styled.ul({});

export const NephilimEffect = styled.li({});

export const NephilimEffectsContainer = styled.div({});
