import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';
import { KiSkillType } from '../../../ki-skill/KiSkill';

export class TaiChiMartialArt extends BasicMartialArt {
  readonly name = 'Tai Chi';

  readonly complexity = 2;

  readonly type = MartialArtType.TaiChi;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      cmBonus: 10,
      calculateDamage: (character) => {
        return character.primaries.power.modifier.final + 20;
      },
      calculateHitType: () => [HitType.Impact],
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 30,
      calculateDamage: (character) => {
        return character.primaries.power.modifier.final * 2 + 20;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (!character.ki.hasKiSkill(KiSkillType.KiUse)) {
          errors.push('Habilidad del Ki: Uso del Ki');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 60,
      effects:
        'Si se usa con otro arte marcial, se puede añadir al daño de dicho arte el bono de poder.',
      calculateDamage: (character) => {
        return character.primaries.power.modifier.final * 3 + 20;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (!character.ki.hasKiSkill(KiSkillType.NeededEnergyUse)) {
          errors.push('Habilidad del Ki: Uso de la energía necesaria');
        }

        if (character.combat.attackAbility.final < 180) {
          errors.push('Ataque 180+');
        }

        if (character.combat.defensiveAbility < 180) {
          errors.push('Bloqueo o esquiva 180+');
        }

        return errors;
      },
    }),
  ];
}
