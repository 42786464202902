import {
  ArmorLocation,
  ArmorType,
} from '../../../../../../../character/domain/aggregations/armors/Armor.types';
import { PsychicPowerActionType } from '../../../../../../../character/domain/aggregations/psychic-power/PsychicPower';
import {
  WeaponEquippedHandType,
  WeaponKnowledgeType,
  WeaponManageabilityType,
  WeaponShotType,
  WeaponSize,
  WeaponSizeProportion,
} from '../../../../../../../character/domain/aggregations/weapon/Weapon.types';
import { HitType } from '../../../../../../../character/domain/character/model/types/HitType';
import {
  ABFArmorLocation,
  ABFArmorType,
  ABFPsychicPowerActionTypes,
  NoneWeaponCritic as ABFNoneWeaponCritic,
  OptionalWeaponCritic as ABFOptionalWeaponCritic,
  WeaponCritic as ABFWeaponCritic,
  WeaponEquippedHandType as ABFWeaponEquippedHandType,
  WeaponKnowledgeType as ABFWeaponKnowledgeType,
  WeaponManageabilityType as ABFWeaponManageabilityType,
  WeaponShotType as ABFWeaponShotType,
  WeaponSize as ABFWeaponSize,
  WeaponSizeProportion as ABFWeaponSizeProportion,
} from '../../FoundryVTTActor.types';

const getCritic = (critic: HitType): ABFWeaponCritic => {
  switch (critic) {
    case HitType.Cut:
      return ABFWeaponCritic.CUT;
    case HitType.Impact:
      return ABFWeaponCritic.IMPACT;
    case HitType.Thrust:
      return ABFWeaponCritic.THRUST;
    case HitType.Heat:
      return ABFWeaponCritic.HEAT;
    case HitType.Electricity:
      return ABFWeaponCritic.ELECTRICITY;
    case HitType.Cold:
      return ABFWeaponCritic.COLD;
    case HitType.Energy:
      return ABFWeaponCritic.ENERGY;
  }
};

const getOptionalCritic = (
  critic: HitType | undefined,
): ABFOptionalWeaponCritic => {
  switch (critic) {
    case HitType.Cut:
      return ABFWeaponCritic.CUT;
    case HitType.Impact:
      return ABFWeaponCritic.IMPACT;
    case HitType.Thrust:
      return ABFWeaponCritic.THRUST;
    case HitType.Heat:
      return ABFWeaponCritic.HEAT;
    case HitType.Electricity:
      return ABFWeaponCritic.ELECTRICITY;
    case HitType.Cold:
      return ABFWeaponCritic.COLD;
    case HitType.Energy:
      return ABFWeaponCritic.ENERGY;
    case undefined:
      return ABFNoneWeaponCritic.NONE;
  }
};

const getSize = (size: WeaponSize): ABFWeaponSize => {
  switch (size) {
    case WeaponSize.Small:
      return ABFWeaponSize.SMALL;
    case WeaponSize.Medium:
      return ABFWeaponSize.MEDIUM;
    case WeaponSize.Big:
      return ABFWeaponSize.BIG;
  }
};

const getKnowledgeType = (
  type: WeaponKnowledgeType,
): ABFWeaponKnowledgeType => {
  switch (type) {
    case WeaponKnowledgeType.Known:
      return ABFWeaponKnowledgeType.KNOWN;
    case WeaponKnowledgeType.Similar:
      return ABFWeaponKnowledgeType.SIMILAR;
    case WeaponKnowledgeType.Mixed:
      return ABFWeaponKnowledgeType.MIXED;
    case WeaponKnowledgeType.Different:
      return ABFWeaponKnowledgeType.DIFFERENT;
  }
};

const getManageabilityType = (
  type: WeaponManageabilityType,
): ABFWeaponManageabilityType => {
  switch (type) {
    case WeaponManageabilityType.OneHand:
      return ABFWeaponManageabilityType.ONE_HAND;
    case WeaponManageabilityType.TwoHand:
      return ABFWeaponManageabilityType.TWO_HAND;
    case WeaponManageabilityType.OneOrTwoHand:
      return ABFWeaponManageabilityType.ONE_OR_TWO_HAND;
  }
};

const getOneOrTwoHanded = (
  type: WeaponEquippedHandType,
): ABFWeaponEquippedHandType => {
  switch (type) {
    case WeaponEquippedHandType.OneHanded:
      return ABFWeaponEquippedHandType.ONE_HANDED;
    case WeaponEquippedHandType.TwoHanded:
      return ABFWeaponEquippedHandType.TWO_HANDED;
  }
};

const getShotType = (type: WeaponShotType | unknown): ABFWeaponShotType => {
  switch (type) {
    case WeaponShotType.Shot:
      return ABFWeaponShotType.SHOT;
    case WeaponShotType.Throw:
      return ABFWeaponShotType.THROW;
    default:
      return ABFWeaponShotType.SHOT;
  }
};

const getSizeProportion = (
  size: WeaponSizeProportion,
): ABFWeaponSizeProportion => {
  switch (size) {
    case WeaponSizeProportion.Normal:
      return ABFWeaponSizeProportion.NORMAL;
    case WeaponSizeProportion.Enormous:
      return ABFWeaponSizeProportion.ENORMOUS;
    case WeaponSizeProportion.Giant:
      return ABFWeaponSizeProportion.GIANT;
  }
};

const getArmorType = (type: ArmorType): ABFArmorType => {
  switch (type) {
    case ArmorType.Natural:
      return ABFArmorType.NATURAL;
    case ArmorType.Hard:
      return ABFArmorType.HARD;
    case ArmorType.Soft:
      return ABFArmorType.SOFT;
  }
};

const getArmorLocation = (location: ArmorLocation): ABFArmorLocation => {
  switch (location) {
    case ArmorLocation.Complete:
      return ABFArmorLocation.COMPLETE;
    case ArmorLocation.Nightdress:
      return ABFArmorLocation.NIGHTDRESS;
    case ArmorLocation.Breastplate:
      return ABFArmorLocation.BREASTPLATE;
    case ArmorLocation.Head:
      return ABFArmorLocation.HEAD;
  }
};

const getPsychicPowerActionType = (
  actionType: PsychicPowerActionType,
): ABFPsychicPowerActionTypes => {
  switch (actionType) {
    case PsychicPowerActionType.Active:
      return ABFPsychicPowerActionTypes.ACTIVE;
    case PsychicPowerActionType.Passive:
      return ABFPsychicPowerActionTypes.PASSIVE;
  }
};

export const FoundryVTTEnumTransforms = {
  getArmorLocation,
  getArmorType,
  getCritic,
  getKnowledgeType,
  getManageabilityType,
  getOneOrTwoHanded,
  getOptionalCritic,
  getShotType,
  getSize,
  getSizeProportion,
  getPsychicPowerActionType,
};
