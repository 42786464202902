import { Wrapper } from './NewsList.styled';
import { NewsItem } from './components/NewsItem/NewsItem';
import { News } from '../../assets/LastNews';

interface NewsProps {
  news: News[];
}

export const NewsList = ({ news }: NewsProps) => {
  return (
    <Wrapper>
      {news.map((news) => (
        <NewsItem key={news.version} news={news} />
      ))}
    </Wrapper>
  );
};
