import { Model, model, prop } from 'mobx-keystone';
import { BanishFieldModel } from './BanishFieldModel';
import { BindFieldModel } from './BindFieldModel';
import { ControlFieldModel } from './ControlFieldModel';
import { SummonFieldModel } from './SummonFieldModel';

export enum SummoningSpecialty {
  Light = 'Luz',
  Darkness = 'Oscuridad',
  Fire = 'Fuego',
  Water = 'Agua',
  Air = 'Aire',
  Earth = 'Tierra',
  Summon = 'Summon',
}

@model('Character/Mystic/Summoning')
export class SummoningModel extends Model({
  summon: prop(() => new SummonFieldModel({})),
  control: prop(() => new ControlFieldModel({})),
  bind: prop(() => new BindFieldModel({})),
  banish: prop(() => new BanishFieldModel({})),

  specialty: prop<SummoningSpecialty | undefined>().withSetter(),
}) {}
