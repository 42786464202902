import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class ShotokanMartialArt extends BasicMartialArt {
  readonly name = 'Shotokan';

  readonly complexity = 1;

  readonly type = MartialArtType.Shotokan;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 20;
      },
      calculateHitType: () => [HitType.Impact],
      combatBonus: {
        attack: 5,
      },
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 30;
      },
      combatBonus: {
        attack: 5,
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.combat.attackAbility.final < 120) {
          errors.push('Ataque 120+');
        }

        if (character.combat.defensiveAbility < 120) {
          errors.push('Bloqueo o esquiva 120+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 50;
      },
      combatBonus: {
        attack: 10,
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.combat.attackAbility.final < 200) {
          errors.push('Bloqueo o esquiva 200+');
        }

        return errors;
      },
    }),
  ];
}
