import { model, SnapshotInOf, SnapshotOutOf } from 'mobx-keystone';
import { BaseFinalFieldModel } from '../../../utils/fields/BaseFinalFieldModel';
import { ArmorType } from '../../../../../aggregations/armors/Armor.types';
import type { ArmorItemModel } from '../../../../../aggregations/armors/ArmorItemModel';
import { computed } from 'mobx';

@model('NaturalPenalty')
export class NaturalPenaltyFieldModel extends BaseFinalFieldModel {
  get penaltyByAdditionalArmors(): number {
    const nonNaturalArmors = this.getNonNaturalArmors();

    return Math.max(0, nonNaturalArmors.length - 1) * 20;
  }

  get penaltyOfEquippedArmors(): number {
    const nonNaturalArmors = this.getNonNaturalArmors();

    const value = nonNaturalArmors.reduce(
      (acc, armor) => acc + armor.naturalPenalty.final,
      0,
    );

    // We apply this negative operator because the penalty in armors are negative value
    // we need it to be positive to be able to sum it
    return -value;
  }

  get bonusByWearArmorAbility(): number {
    if (!this.character) return 0;

    return this.character.modifiers.wearArmorRequirement
      .bonusByWearArmorAbility;
  }

  get final(): number {
    const value =
      this.base +
      this.special +
      this.penaltyByAdditionalArmors +
      this.penaltyOfEquippedArmors;

    return value;
  }

  @computed
  get finalWithBonus(): number {
    let value = this.final;

    value -= this.bonusByWearArmorAbility;

    return Math.max(this.penaltyByAdditionalArmors, value);
  }

  private getNonNaturalArmors(): ArmorItemModel[] {
    if (!this.character) return [];

    const nonNaturalArmors = this.character.inventory.equippedArmors.filter(
      (a) => a.type !== ArmorType.Natural,
    );
    return nonNaturalArmors;
  }

  static get snapshotProcessor(): {
    fromSnapshot?(
      sn: SnapshotInOf<NaturalPenaltyFieldModel>,
    ): SnapshotInOf<NaturalPenaltyFieldModel>;
    toSnapshot?(
      sn: SnapshotOutOf<NaturalPenaltyFieldModel>,
    ): SnapshotOutOf<NaturalPenaltyFieldModel>;
  } {
    return {
      fromSnapshot: (
        sn: SnapshotInOf<NaturalPenaltyFieldModel>,
      ): SnapshotInOf<NaturalPenaltyFieldModel> => ({
        base: sn.base ?? 0,
        special: sn.special ?? 0,
        $modelType: 'NaturalPenalty',
      }),
    };
  }
}
