import styled from 'styled-components';
import { SmallParagraph } from '../../../../../../../../../shared/application/design-system/components/Texts/Texts.styled';

export const CalculationText = styled(SmallParagraph)(
  ({ theme: { size, color } }) => ({
    color: color.white(),
    fontSize: size.units(1.5),
  }),
);

export const Wrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: size.units(0.5),
}));
