import { Model, model, prop } from 'mobx-keystone';
import { MagicPath } from '../../../../../../aggregations/magic-path/MagicPath';
import {
  MagicSpell,
  MagicSpellId,
  SpellActionType,
  SpellActivationType,
} from '../../../../../../aggregations/magic-spell/MagicSpell';
import {
  createModelFromMagicSpellCasts,
  MagicSpellCastsModel,
} from './MagicSpellCastsModel';

@model('Character/Mystic/MagicSpell')
export class MagicSpellModel extends Model({
  id: prop<MagicSpellId>().withSetter(),
  name: prop<string>().withSetter(),
  magicPath: prop<MagicPath>().withSetter(),
  level: prop<number>().withSetter(),
  isDaily: prop<boolean>().withSetter(),
  activationType: prop<SpellActivationType[]>().withSetter(),
  actionType: prop<SpellActionType>().withSetter(),

  description: prop<string | undefined>().withSetter(),

  casts: prop<MagicSpellCastsModel>(),
}) {
  toObject(): MagicSpell {
    return {
      id: this.id,
      name: this.name,
      magicPath: this.magicPath,
      level: this.level,
      isDaily: this.isDaily,
      activationType: this.activationType,
      actionType: this.actionType,
      description: this.description,

      casts: this.casts.toObject(),
    };
  }
}

export const createModelFromMagicSpell = (spell: MagicSpell): MagicSpellModel =>
  new MagicSpellModel({
    id: spell.id,
    name: spell.name,
    magicPath: spell.magicPath,
    level: spell.level,
    isDaily: spell.isDaily,
    activationType: spell.activationType,
    actionType: spell.actionType,
    description: spell.description,

    casts: createModelFromMagicSpellCasts(spell.casts),
  });
