import { useState } from 'react';
import { Character } from '../../../character/domain/character/Character';
import { CharacterService } from '../../../character/infrastructure/CharacterService';
import { NotificationManager } from '../shared/NotificationManager';
import { useSaveCharacter } from './useSaveCharacter';

export const useSaveCharacterToken = () => {
  const { savingCharacter, saveCharacter } = useSaveCharacter();

  const [uploadingCharacterToken, setUploadingCharacterToken] = useState(false);

  const saveCharacterToken = (
    character: Character,
    image: Blob,
  ): Promise<void> => {
    setUploadingCharacterToken(true);

    return CharacterService.uploadCharacterImage(character, image)
      .then((value) => {
        character.content.setTokenUrl(value.signedUrl);

        return saveCharacter(character);
      })
      .catch((error) => {
        NotificationManager.error('No se ha podido almacenar la el token');

        console.error(error);
      })
      .finally(() => {
        setUploadingCharacterToken(false);
      });
  };

  return {
    savingCharacterToken: uploadingCharacterToken || savingCharacter,
    saveCharacterToken,
  };
};
