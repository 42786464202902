import { MouseEvent, ReactNode } from 'react';
import { StyledCellProps } from '../../../TableCell/TableCell.styled';
import { StyledRowCell } from './TableRowCell.styled';

type TableRowCellProps = {
  children?: ReactNode | ReactNode[];

  onClick?: (e: MouseEvent<HTMLDivElement>) => void;

  colSpan?: number;
} & StyledCellProps;

export const TableRowCell = ({
  children,
  colSpan,
  onClick,
  $interactable,
  ...cellProps
}: TableRowCellProps) => {
  return (
    <StyledRowCell
      colSpan={colSpan}
      $interactable={$interactable || !!onClick}
      {...cellProps}
      onClick={onClick}
    >
      {children}
    </StyledRowCell>
  );
};
