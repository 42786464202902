import {
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
} from '@mui/material';
import { useCharacterContext } from '../../../hooks/useCharacterContext';

type CheckboxProps = MUICheckboxProps & { isChecked: boolean };

export const Checkbox = ({ isChecked, ...rest }: CheckboxProps) => {
  const { character } = useCharacterContext();

  const inputDisabled = rest.disabled || !character.canBeEdited;

  return (
    <MUICheckbox
      value={isChecked}
      checked={isChecked}
      disabled={inputDisabled}
      {...rest}
    />
  );
};
