import {
  BasicMartialArtGradeIds,
  buildAdvancedMartialArtArcaneGrade,
  buildAdvancedMartialArtBasicGrade,
} from '../../MartialArtGrades';
import {
  AdvancedMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { calculateHighestDamageFromKnownMartialArts } from './utils/calculateHighestDamageFromKnownMartialArts';
import { HitType } from '../../../../character/model/types/HitType';
import { calculateMartialArtsRequirements } from './utils/calculateMartialArtsRequirements';
import { KiSkillType } from '../../../ki-skill/KiSkill';

const BasicMartialArtsRequirement = [
  {
    type: MartialArtType.Shotokan,
    grade: BasicMartialArtGradeIds.Advanced,
  },
  {
    type: MartialArtType.Kenpo,
    grade: BasicMartialArtGradeIds.Advanced,
  },
];

const ArcaneMartialArtsRequirement = [
  {
    type: MartialArtType.Shotokan,
    grade: BasicMartialArtGradeIds.Supreme,
  },
  {
    type: MartialArtType.Kenpo,
    grade: BasicMartialArtGradeIds.Supreme,
  },
];

export class SeraphiteMartialArt extends AdvancedMartialArt {
  readonly name = 'Seraphite';

  readonly complexity = 4;

  readonly type = MartialArtType.Seraphite;

  readonly level = MartialArtLevelType.Advanced;

  readonly grades = [
    buildAdvancedMartialArtBasicGrade({
      cmBonus: 10,
      effects:
        'Permite aumentar temporalmente ataque +20 a cambio de -30 en defensa. Se debe declarar antes de la iniciativa.',
      combatBonus: {
        attack: 20,
      },
      calculateDamage: (character) => {
        return (
          calculateHighestDamageFromKnownMartialArts(
            character,
            BasicMartialArtsRequirement,
          ) + 10
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          BasicMartialArtsRequirement,
        );

        if (!character.ki.hasKiSkill(KiSkillType.PresenceExtrusion)) {
          errors.push('Habilidad de Ki: Extrusión de presencia');
        }

        if (character.combat.attackAbility.final < 180) {
          errors.push('Ataque 180+');
        }

        return errors;
      },
    }),
    buildAdvancedMartialArtArcaneGrade({
      cmBonus: 20,
      effects:
        'Igual que antes pero +30 al ataque y -50 a la defensa. Permite atacar incluso estando a la defensiva. Sólo si le han hecho crítico no podrá ejercer esta ventaja.',
      masterBonus: {
        attack: 20,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          ArcaneMartialArtsRequirement,
        );

        if (character.combat.attackAbility.final < 280) {
          errors.push('Ataque 280+');
        }

        return errors;
      },
    }),
  ];
}
