import { observer } from 'mobx-react';
import { MouseEvent, useState } from 'react';
import { DefaultFreeAccessSpells } from '../../../../../../../../../domain/aggregations/magic-spell/DefaultMagicSpells';
import { MagicSpell } from '../../../../../../../../../domain/aggregations/magic-spell/MagicSpell';
import { getUnusedFreeAccessMagicSpell } from '../../../../../../../../../domain/aggregations/magic-spell/utils/getUnusedMagicSpell';
import {
  createModelFromMagicSpell,
  MagicSpellModel,
} from '../../../../../../../../../domain/character/model/parts/mystic/parts/magic-spell/MagicSpellModel';
import { useCharacterContext } from '../../../../hooks/useCharacterContext';
import { AddItemButton } from '../../../common/AddItemButton/AddItemButton';
import DoubleInput from '../../../common/DoubleInput/DoubleInput';
import { useCharacterMenu } from '../../../common/CharacterMenu/useCharacterMenu';
import { transformToMenuItems } from '../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { RemoveItemButton } from '../../../common/RemoveItemButton/RemoveItemButton';
import { Section } from '../../../common/Section/Section';
import { Table } from '../../../common/Table/Table';
import { MenuItem } from '../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';

export const FreeAccessSpells = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { handleMenuOpen, Menu } = useCharacterMenu<MagicSpell>();

  const [selectedFreeAccessSpell, setSelectedFreeAccessSpell] = useState<
    MagicSpellModel | undefined
  >(undefined);

  const addFreeAccessSpell = () => {
    const unusedSpell = getUnusedFreeAccessMagicSpell(
      character.mystic.freeAccessSpells.map((s) => s.toObject()),
    );

    if (!unusedSpell) return;

    character.mystic.addFreeAccessSpell(unusedSpell);
  };

  const changeFreeAccessSpell = (
    from: MagicSpellModel,
    to: MagicSpellModel,
  ) => {
    character.mystic.replaceFreeAccessSpell(from, to);
  };

  const removeFreeAccessSpell = (spell: MagicSpellModel) => () => {
    character.mystic.removeFreeAccessSpell(spell);
  };

  const spellItems: MenuItem<MagicSpell>[] = transformToMenuItems({
    fromItems: DefaultFreeAccessSpells,
    generateItem: (item) => ({
      key: item.id,
      name: item.name,
      value: item,
      onClick: () => {
        if (selectedFreeAccessSpell !== undefined) {
          changeFreeAccessSpell(
            selectedFreeAccessSpell,
            createModelFromMagicSpell(item),
          );
        }
      },
    }),
    generateDivider: (item) => item.magicPath,
  });

  const handleFreeAccessSpellClick =
    (spell: MagicSpellModel) => (e: MouseEvent<HTMLDivElement>) => {
      setSelectedFreeAccessSpell(spell);
      handleMenuOpen(spell)(e);
    };

  return (
    <Section maxHeight="fit">
      <Section.Title>Conjuros de libre acceso</Section.Title>
      <DoubleInput
        label="Disponibles"
        field={character.mystic.freeAccessSpellsLimit}
      />
      <Table>
        <Table.Header>
          <Table.Header.Cell>Nombre</Table.Header.Cell>
          <Table.Header.Cell>Vía</Table.Header.Cell>
          <Table.Header.Cell>Nivel</Table.Header.Cell>
          <Table.Header.Cell />
        </Table.Header>
        {character.mystic.freeAccessSpells.map((spell) => (
          <Table.Row key={spell.id}>
            <Table.Row.Cell
              $interactable
              onClick={handleFreeAccessSpellClick(spell)}
            >
              {spell.name}
            </Table.Row.Cell>
            <Table.Row.Cell>{spell.magicPath}</Table.Row.Cell>
            <Table.Row.Cell>{spell.level}</Table.Row.Cell>
            <Table.Row.Cell>
              <RemoveItemButton onClick={removeFreeAccessSpell(spell)} />
            </Table.Row.Cell>
          </Table.Row>
        ))}
      </Table>

      <AddItemButton
        disabled={
          character.mystic.freeAccessSpellsLimit.final <=
          character.mystic.freeAccessSpells.length
        }
        onClick={addFreeAccessSpell}
      />

      <Menu items={spellItems} />
    </Section>
  );
});
