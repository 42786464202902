import { model } from 'mobx-keystone';
import { BaseFinalFieldModel } from '../../../character/model/utils/fields/BaseFinalFieldModel';
import { getParentArmor } from '../../../utils/parenting/getParentArmor';

@model('ArmorItem/ATField')
export class ATFieldModel extends BaseFinalFieldModel {
  get final(): number {
    if (!this.armor) return 0;

    const ta = this.base + this.special;

    return Math.max(Math.floor(this.armor.quality.final / 5) + ta, 0);
  }

  get armor() {
    return getParentArmor(this);
  }
}
