import { fromSnapshot } from 'mobx-keystone';
import { openFileSelectDialog } from './openFileSelectDialog';
import { CharacterModel } from '../../../../../../../../../../domain/character/model/CharacterModel';
import { Character } from '../../../../../../../../../../domain/character/Character';
import {
  migrateCharacterModel,
  NoMigratedCharacter,
} from '../../../../../../../../../../domain/character/model/migrations/migrateCharacterModel';

const readFile = async (file: File): Promise<string | undefined> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');

    reader.onload = (evt) => {
      if (evt.target) {
        resolve(evt.target.result as string);
      }
    };

    reader.onerror = () => {
      reject(new Error('Can not read the file'));
    };
  });
};

export const importToCharacter = async (
  characterToBeReplaced: Character,
): Promise<void> => {
  const file = await openFileSelectDialog();

  if (file) {
    const snapshot = await readFile(file);

    if (snapshot) {
      const characterToReplace = fromSnapshot<CharacterModel>(
        migrateCharacterModel(JSON.parse(snapshot) as NoMigratedCharacter),
      );

      if (characterToReplace) {
        characterToBeReplaced.setContent(characterToReplace);
      }
    } else {
      throw new Error('Can not read the character file');
    }
  }
};
