import { FoundryVTTCharacterList } from './components/FoundryVTTCharacterList/FoundryVTTCharacterList';
import { ModalTitle, Wrapper } from './VTTCharacterListModal.styled';
import { FoundryVTTActor } from '../../../../../../embed/infrastructure/mutators/anima/foundry/FoundryVTTActor';

export const VTTCharacterListModal = ({
  actors,
  onActorSelected,
}: {
  actors: FoundryVTTActor[];
  onActorSelected: (actor: FoundryVTTActor) => void;
}) => {
  const handleSelectActor = (actor: FoundryVTTActor) => {
    onActorSelected(actor);
  };

  return (
    <Wrapper>
      <ModalTitle>Personajes remotos</ModalTitle>

      <FoundryVTTCharacterList
        actors={actors}
        onSelectActor={handleSelectActor}
      />
    </Wrapper>
  );
};
