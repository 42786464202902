import styled from 'styled-components';

export const BigTitle = styled.h1(
  ({ theme: { size, font, color, mixins } }) => ({
    fontWeight: font.weight.medium,
    fontSize: size.units(5),
    margin: 0,
    color: color.black(),

    ...mixins.responsive.md.downward({
      fontSize: size.units(4),
    }),
  }),
);

export const BasicTitle = styled.h2(
  ({ theme: { size, font, color, mixins } }) => ({
    fontWeight: font.weight.bold,
    fontSize: size.units(2.8),
    margin: 0,
    color: color.black(),

    ...mixins.responsive.md.downward({
      fontSize: size.units(2.4),
    }),
  }),
);

export const SmallTitle = styled.h2(
  ({ theme: { size, font, color, mixins } }) => ({
    fontWeight: font.weight.semiBold,
    fontSize: size.units(2.2),
    margin: 0,
    color: color.black(),

    ...mixins.responsive.md.downward({
      fontSize: size.units(2),
    }),
  }),
);

export const BasicParagraph = styled.p(
  ({ theme: { size, font, color, mixins } }) => ({
    fontWeight: font.weight.medium,
    fontSize: size.units(2),
    margin: 0,
    color: color.black(),

    ...mixins.responsive.md.downward({
      fontSize: size.units(1.75),
    }),
  }),
);

export const SmallParagraph = styled.p(
  ({ theme: { size, font, color, mixins } }) => ({
    fontWeight: font.weight.semiLight,
    fontSize: size.units(1.75),
    margin: 0,
    color: color.black(),

    ...mixins.responsive.md.downward({
      fontSize: size.units(1.5),
    }),
  }),
);
