import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import { MenuItem } from '../../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';
import { getMysticTableFromType } from '../../../../../../../../../../../domain/aggregations/tables/mystic-tables/getMysticTableFromType';
import { MysticTableType } from '../../../../../../../../../../../domain/aggregations/tables/mystic-tables/MysticTable';
import { MysticTableModel } from '../../../../../../../../../../../domain/character/model/parts/mystic/MysticTableModel';
import { AddItemButton } from '../../../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../../../common/CharacterMenu/useCharacterMenu';
import { RemoveItemButton } from '../../../../../common/RemoveItemButton/RemoveItemButton';
import { Table } from '../../../../../common/Table/Table';
import { MysticPDModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/parts/mystic/MysticPDModel';

type MysticSelectorProps = {
  magic: MysticPDModel;
};

export const MysticTableSelector = observer(
  ({ magic }: MysticSelectorProps) => {
    const { handleMenuOpen: handleTableMenuOpen, Menu: MysticOfMenu } =
      useCharacterMenu<MysticTableType>();

    const [selectedTable, setSelectedTable] = useState<MysticTableModel>();

    const handleAddMystic = () => {
      magic.addMysticTable(MysticTableType.AttackAsMagicProjection);
    };

    const handleRemoveMystic = (table: MysticTableModel) => () => {
      magic.removeMysticTable(table);
    };

    const mysticTables = (Object.values(MysticTableType) as MysticTableType[])
      .map(getMysticTableFromType)
      .map((t) => {
        const item: MenuItem<MysticTableType> = {
          key: t.type,
          name: t.name,
          value: t.type,
          onClick: () => {
            selectedTable?.setType(t.type);
          },
        };
        return item;
      });

    return (
      <>
        <Table>
          <Table.Header>
            <Table.Header.Cell $width={5}>Tabla</Table.Header.Cell>
            <Table.Header.Cell>Coste</Table.Header.Cell>
            <Table.Header.Cell>Efecto</Table.Header.Cell>
          </Table.Header>
          {magic.tables.map((table, i) => {
            const handleTableSelection = (e: MouseEvent<HTMLDivElement>) => {
              handleTableMenuOpen(table.type)(e);
              setSelectedTable(table);
            };

            return (
              <Table.Row key={`${i}-${table.type}`}>
                <Table.Row.Cell onClick={handleTableSelection}>
                  {table.name}
                </Table.Row.Cell>
                <Table.Row.Cell>{table.cost}</Table.Row.Cell>
                <Table.Row.Cell>{table.effect}</Table.Row.Cell>

                <RemoveItemButton onClick={handleRemoveMystic(table)} />

                <MysticOfMenu items={mysticTables} />
              </Table.Row>
            );
          })}
        </Table>

        <AddItemButton onClick={handleAddMystic} />
      </>
    );
  },
);
