import {
  CollaborationsContainer,
  CollaboratorName,
  CollaboratorRow,
  DeleteIcon,
  IconContainer,
  InputContainer,
  OwnerCollaborationName,
  Wrapper,
} from './CharacterCollaborationModal.styled';
import { useModal } from '../../../../shared/application/design-system/components/Modal/hooks/useModal';
import { Character } from '../../../../character/domain/character/Character';
import { useFetchCharacterCollaboration } from './hooks/useFetchCharacterCollaboration';
import { Loading } from '../../../../shared/application/design-system/components/Loading/Loading';
import {
  BasicTitle,
  SmallTitle,
} from '../../../../shared/application/design-system/components/Texts/Texts.styled';
import { Input } from '../../../../shared/application/design-system/components/Input/Input';
import { getAuthorizedUser } from '../../../../auth/infrastructure/AuthorizationService';
import { useAddCharacterCollaboration } from './hooks/useAddCharacterCollaboration';
import { Button } from '../../../../shared/application/design-system/components/Button/Button';
import { ChangeEvent, useState } from 'react';
import { useRemoveCharacterCollaboration } from './hooks/useRemoveCharacterCollaboration';

export const CharacterCollaborationModal = ({
  character,
}: {
  character: Character;
}) => {
  const {
    characterCollaborations,
    loadingCharacterCollaborations,

    refreshingCharacterCollaborations,
    refreshCharacterCollaborations,
  } = useFetchCharacterCollaboration(character.id);

  const { addCharacterCollaboration, addingCharacterCollaboration } =
    useAddCharacterCollaboration();

  const { removingCharacterCollaboration, removeCharacterCollaboration } =
    useRemoveCharacterCollaboration();

  const [collaboratorEmail, setCollaboratorEmail] = useState<string>('');

  if (loadingCharacterCollaborations) {
    return (
      <Wrapper>
        <Loading color="primary" size={3} />
      </Wrapper>
    );
  }

  const validCollaboratorEmail =
    collaboratorEmail.includes('@') &&
    collaboratorEmail !== getAuthorizedUser().email;

  const handleAddCharacterCollaborationClick = async () => {
    if (!validCollaboratorEmail) {
      return;
    }

    await addCharacterCollaboration({
      characterId: character.id,
      collaboratorEmail: collaboratorEmail,
    });

    await refreshCharacterCollaborations();

    setCollaboratorEmail('');
  };

  const loading =
    addingCharacterCollaboration ||
    refreshingCharacterCollaborations ||
    removingCharacterCollaboration;

  const handleRemoveCharacterCollaborationClick =
    (characterCollaborationId: string) => async () => {
      if (loading) return;

      await removeCharacterCollaboration({
        collaborationId: characterCollaborationId,
        characterId: character.id,
      });

      await refreshCharacterCollaborations();
    };

  const handleCollaboratorEmailChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setCollaboratorEmail(event.target.value);
  };

  return (
    <Wrapper>
      <BasicTitle>Colaboraciones de {character.name}</BasicTitle>
      <InputContainer>
        <Input
          disabled={loading}
          placeholder="Email del nuevo colaborador"
          value={collaboratorEmail}
          onChange={handleCollaboratorEmailChange}
        />
        <Button
          loading={loading}
          onClick={handleAddCharacterCollaborationClick}
          disabled={
            !validCollaboratorEmail ||
            addingCharacterCollaboration ||
            refreshingCharacterCollaborations
          }
        >
          Añadir
        </Button>
      </InputContainer>
      <SmallTitle>Colaboradores</SmallTitle>
      <CollaborationsContainer>
        {character.isMine ? (
          <CollaboratorRow>
            <OwnerCollaborationName>
              {getAuthorizedUser().username} (Tú)
            </OwnerCollaborationName>
          </CollaboratorRow>
        ) : undefined}
        {characterCollaborations.map((characterCollaboration) => (
          <CollaboratorRow key={characterCollaboration.id}>
            <CollaboratorName>
              {characterCollaboration.collaborator.username} (
              {characterCollaboration.collaborator.email})
            </CollaboratorName>
            <IconContainer
              onClick={handleRemoveCharacterCollaborationClick(
                characterCollaboration.id,
              )}
            >
              {loading ? <Loading size={1} color="white" /> : <DeleteIcon />}
            </IconContainer>
          </CollaboratorRow>
        ))}
      </CollaborationsContainer>
    </Wrapper>
  );
};

export const useCharacterCollaborationModal = () => {
  const { Modal, openModal, closeModal } = useModal();

  return {
    openCharacterCollaborationModal: async (character: Character) => {
      return openModal(<CharacterCollaborationModal character={character} />);
    },
    closeCharacterCollaborationModal: closeModal,
    CharacterCollaborationModal: Modal,
  };
};
