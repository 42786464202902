import { model } from 'mobx-keystone';
import { Add1ToPrimaryCharacteristicAdvantageVariation } from '../../../../../aggregations/advantage/CommonAdvantages';
import { Rest2ToPrimaryCharacteristicDisadvantageVariation } from '../../../../../aggregations/disadvantage/CommonDisadvantages';
import { PrimaryFieldModel } from './common/PrimaryFieldModel';
import {
  Devah,
  DukZaristFemale,
  DukZaristMale,
  Jayan,
  Sylvain,
} from '../../../../../aggregations/nephilim-type/Nephilim';

@model('PrimaryCharacteristicsFields/Power')
export class PowerPrimaryFieldModel extends PrimaryFieldModel {
  advantageVariation = Add1ToPrimaryCharacteristicAdvantageVariation.Power;
  disadvantageVariation =
    Rest2ToPrimaryCharacteristicDisadvantageVariation.Power;

  get final(): number {
    if (!this.character) return 0;

    let value = super.final;

    if (this.character.isNephilim(Devah)) {
      value += 1;
    }

    if (this.character.isNephilim(Sylvain)) {
      value += 1;
    }

    if (this.character.isNephilim(Jayan)) {
      value -= 1;
    }

    if (this.character.isNephilim(DukZaristMale)) {
      value += 1;
    }

    if (this.character.isNephilim(DukZaristFemale)) {
      value += 1;
    }

    return value;
  }

  getPrimaryBonus(): number {
    return this.character?.creationPoints.primaryBonuses.power ?? 0;
  }
}
