type NephilimId = string;

export type NephilimType = {
  id: NephilimId;
  name: string;

  effects?: Array<string | string[]>;
};

export const NephilimSylvain: NephilimType = {
  id: '1',
  name: 'Nephilim Sylvain',
  effects: [
    '+10 RM y RP. +20 a RE y +5 a RF y RV. No puede elegir desventajas: Salud enfermiza, enfermedad incurable y vulnerabilidad a la magia',
    'Desequilibrio hacia la luz. +10 a las resistencias contra efectos de luz. No puede elegir ventaja de desequilibrio hacia la oscuridad', // TODO: Crear issue
    '+1 a la regeneración de vida',
    'Presiente a otros nephilim Duk`zarist o Sylvain pero no puede identificarlos como tal. No hace efecto si el otro nephilim es oculto por un conjuro o mediante Ki',
    'Sus necesidades se dividen a la mitad',
    '-4 a la experiencia',
  ],
};

export const NephilimJayan: NephilimType = {
  id: '2',
  name: 'Nephilim Jayán',
  effects: [
    '2 puntos al tamaño y no pueden elegir tamaño desigual para disminuirlo', // TODO: Crear issue
    '+1 su cansancio',
    '+15 a la RF',
    '+1 su Fuerza',
    'Al cerrar los ojos permite ver de manera inconsciente los espíritus',
    '-10 a la RM',
    '-3 a la experiencia',
  ],
};

export const NephilimDAnjayni: NephilimType = {
  id: '3',
  name: 'Nephilim D`Anjayni',
  effects: [
    '+30 a las resistencias contra efectos de detección sobrenatural',
    'Desarrollo innato de Ocultación del Ki con bono de +30',
    'Permite hacer que aquellas personas con las que haya mantenido una conversación, no sean capaces de recordar su rostro ni el tema del que hablaban, en el juego: cualquiera que vea o hable con el PJ debe superar RM > 100 para recordarlo. Los afectados no se darán cuenta de que es algo innatural, simplemente son incapaces de acordarse. Si Valoración mágica supera Imposible, se puede percatar. Esta habilidad deja de surtir efecto si se presenta con su nombre.',
    'Si el nephilim habla a alguien directamente, el resto tiene un -60 a sus controles a la hora de escuchar',
    'Si va descalzo, no deja huellas, es decir, un -40 al rastrear del contrario.',
    'Su apariencia no debe ser inferior a 3 o superior a 7',
    '-3 a la experiencia',
  ],
};

export const NephilimEbudan: NephilimType = {
  id: '4',
  name: 'Nephilim Ebudan',
  effects: [
    '30 de resistencia contra efectos que puedan impedirle realizar su Sue`Aman',
    'Con Sue`Aman cumplido:',
    [
      'Le salen dos alas a voluntad que da Tipo de Vuelo 12',
      'Es intangible a todos los daños excepto a los que dañan energía',
    ],
    '-3 a la experiencia',
  ],
};
export const NephilimDaimah: NephilimType = {
  id: '5',
  name: 'Nephilim Daimah',
  effects: [
    'Percibe los sentimientos de la naturaleza. Detectan miedo, intranquilidad o situaciones extrañas',
    'Sin penalizador en vegetaciones densas o bosques',
    'Permite identificar lazos elementales o anímicos de cualquier tipo de ser. Se puede resistir con 140 a la RM',
    '-1 a su tamaño',
    'Si se encuentra en un bosque, +3 a la regeneración de vida',
    '-2 a la experiencia',
  ],
};

export const NephilimDukZaristMale: NephilimType = {
  id: '6',
  name: 'Nephilim Duk`zarist (♂)',
  effects: [
    '+15 a todas las resistencias excepto +20 a RF',
    'Desequilibrio hacia la oscuridad. +10 a las resistencias contra efectos de oscuridad. No puede elegir ventaja de desequilibrio hacia la luz',
    'Superan control de RF para sobrevivir al estado entre la vida  la muerte',
    '+1 a la regeneración de vida',
    'Disminuye la necesidad de dormir y comer una tercera parte que un humano',
    'Presiente a otros nephilim Duk`zarist o Sylvain pero no puede identificarlos como tal. No hace efecto si el otro nephilim es oculto por un conjuro o mediante Ki',
    'Mitad de penalizador a la vision nocturna no sobrenatural',
    'Si es psíquico, debe adquirir Piroquinesis como primera disciplina', // TODO: Crear issue
    'Contacto con aleación de hierro, control usando su presencia base contra 60, si falla, negativo a toda acción por cada punto fallado. Si es hierro puro, dificultad de 80. Se recupera a 10 puntos por minutos.',
    '-5 a la experiencia',
  ],
};

export const NephilimDukZaristFemale: NephilimType = {
  id: '6.5',
  name: 'Nephilim Duk`zarist (♀)',
  effects: [
    '+15 a todas las resistencias excepto +20 a RM',
    'Desequilibrio hacia la oscuridad. +10 a las resistencias contra efectos de oscuridad. No puede elegir ventaja de desequilibrio hacia la luz',
    'Superan control de RF para sobrevivir al estado entre la vida  la muerte',
    '+1 a la regeneración de vida',
    'Disminuye la necesidad de dormir y comer una tercera parte que un humano',
    'Presiente a otros nephilim Duk`zarist o Sylvain pero no puede identificarlos como tal. No hace efecto si el otro nephilim es oculto por un conjuro o mediante Ki',
    'Mitad de penalizador a la vision nocturna no sobrenatural',
    'Si es psíquico, debe adquirir Piroquinesis como primera disciplina', // TODO: Crear issue
    'Contacto con aleación de hierro, control usando su presencia base contra 60, si falla, negativo a toda acción por cada punto fallado. Si es hierro puro, dificultad de 80. Se recupera a 10 puntos por minutos.',
    '-5 a la experiencia',
  ],
};

export const NephilimDevah: NephilimType = {
  id: '7',
  name: 'Nephilim Devah',
  effects: [
    '+10 a RM y RP si intenta leer su mente o provocar algún sentimiento o emoción',
    'Al cerrar los ojos "ve" criaturas anímicas y sentir innatamente sus afinidades elementales. Si contemplan a un ser sobrenatural entre 2 y 3 asaltos pueden saber su nombre. No funciona con criaturas de Gnosis > 30',
    '+10 a convocar y desconvocar',
    '-10 a RF y RE',
    '-3 a la experiencia',
  ],
};

export const NephilimVetala: NephilimType = {
  id: '8',
  name: 'Nephilim Vetala',
  effects: [
    '+50 al control de RF a la hora de calcular un crítico que se dirija a el personaje',
    'Tras 1 o 5 minutos después de ingestar sangre, obtiene +10 a toda acción y +1 a un atributo. No puede volver a obtener los beneficios hasta que no haya pasado entre 2 y 3 horas.',
    'Si se alimentan diariamente de sangre, +1 a la regeneración de vida',
    'Al exponerse a fuertes luces, debe superar un control de RF contra 40 y 140, si falla por menos de 30, sufre 10 puntos de daño y -20 a toda acción, si es mayor el daño es 20 y el penalizador es -40. Se mantiene durante 3 asaltos hasta que se haya apartado de la luz',
    'Si está con los efectos de ingesta de sangre, desarrolla una dependencia hacia la sangre como si tuviera la desventaja de adicción grave. En caso de tenerla, dobla los penalizadores y tendría que superar un control de voluntad para evitar perder el control y tratar de beberla',
    '-20 a su RE',
    '-3 a la experiencia',
  ],
};

export const NephilimTurak: NephilimType = {
  id: '9',
  name: 'Nephilim Turak',
  effects: [
    'En situaciones de peligro tiene armas naturales con daño de 30 más bono de fuerza y atacan a filo. Es necesario haber desarrollado su habilidad de combate con ellas',
    'Permite recordar cualquier idioma que supiera en su vida anterior',
    '+1 a la TA en filo, contundente, penetrante y calor',
    '-10 a las resistencias basadas en efectos de frío.',
    '-10 al turno',
    '-3 a la experiencia',
  ],
};

export const Human: NephilimType = {
  id: '10',
  name: 'Humano',
};

export const Sylvain: NephilimType = {
  id: '11',
  name: 'Sylvain',
  effects: [
    '+1 a destreza, agilidad, poder e inteligencia',
    '-1 a fuerza y constitución',
    '+30 a RM y RP', // TODO: Crear issue
    '+20 a su RE y +10 a su RV',
    'Puede alcanzar dificultad de Inhumano de forma innata',
    'Disminuye sus necesidades de sueño hasta una quinta parte de lo normal',
    '+3 a su regeneración de vida',
    'Presiente a otros Duk`zarist o Sylvain pero no puede identificarlos como tal. No hace efecto si el otro nephilim es oculto por un conjuro o mediante Ki',
    'Desequilibrio hacia la luz. +10 a las resistencias contra efectos de luz y -10 si es de oscuridad. No puede elegir ventaja de desequilibrio hacia la oscuridad',
    'Necesitan elegir la ventaja del Don', // TODO: Crear issue
    '-3 a la experiencia',
    'No puede elegir las desventajas:',
    [
      'Vulnerabilidad a la magia',
      'Fácil posesión',
      'Vulnerable a los venenos',
      'Enfermedad grave',
    ],
  ],
};

export const Jayan: NephilimType = {
  id: '12',
  name: 'Jayán',
  effects: [
    '2 puntos al tamaño y no pueden elegir tamaño desigual para disminuirlo', // TODO: Crear issue
    '+2 a fuerza y 1 a constitución. No puede elegir Reducir en dos puntos a una característica para rebajar fuerza', // TODO: Crear issue
    '+3 a su cansancio',
    '+2 a su RF',
    'Tiene un tercer ojo físico que permite ver seres espirituales',
    'Puede tener garras y cuernos que producen un daño de 40 o 60 más bono de fuerza dependiendo de su tamaño (Tabla 82). Es necesario haber desarrollado su habilidad de combate con ellas',
    'Permite realzar acciones Inhumanas relacionadas con la característica de fuerza',
    '-20 a su RM',
    '-1 a poder',
  ],
};

export const DAnjayni: NephilimType = {
  id: '13',
  name: 'D`Anjayni',
  effects: [
    'El efecto de Olvido es igual que el de los Nephilim pero con resistencia de 120',
    'La resistencia a ser detectados sobrenaturalmente aumenta a 50',
    'Permite adaptar la fisionomía de otra persona arrancando el rostro a sustituir y colocarlo sobre el suyo. El tamaño no puede cambiar +-10 centímetros ni kilos. No altera su sexo. Si recibe daño en el rostro o pronuncian su nombre desaparece el efecto. La habilidad de olvido se resiente disminuyendo su resistencia a 80',
    'Si va descalzo aumenta a -60 el penalizador al rastreo de quien intente seguirlo',
    'Si intenta hablar con alguien, los que no sean objetivos de la conversación aplican un -80 a su percepción',
    '-3 a la experiencia',
  ],
};

export const Ebudan: NephilimType = {
  id: '14',
  name: 'Ebudan',
  effects: [
    'Al cumplir su Sue`Aman pueden elegir entre renacer como entidad espiritual con Gnosis 30 o permanecer en el mundo. En ambos casos, +2 a su poder y voluntad',
    'Sus alas son reales con tipo de vuelo 12, permite cambiar el tamaño de las mismas pero no puede hacerlas desaparecer completamente',
    'Puede atacar con sus alas: +20 turno, daño de 60 más bono de fuerza, ataque a filo, tipo mandoble con entereza y rotura de 20 y 5 respectivamente. No puede usarlas en combinación con armas empuñadas. Detienen proyectiles como si fueran escudos. Si cumplen con su Sue`Aman, puede parar energía',
    'Si cumple con su Sue`Aman, se vuelve intangible a todos los daños excepto a los que dañan energía',
  ],
};

export const Daimah: NephilimType = {
  id: '15',
  name: 'Daimah',
  effects: [
    '+1 a su agilidad y destreza',
    '-1 a su constitución y voluntad',
    'Percibe los sentimientos de la naturaleza. Detectan miedo, intranquilidad o situaciones extrañas',
    'Sin penalizador en vegetaciones densas o bosques',
    'Las garras y dientes se pueden usar como arma natural de daño base 30 más bono de fuerza. Es necesario haber desarrollado su habilidad de combate con ellas',
    'Permiten alcanzar dificultades Inhumanas en características y controles relacionadas con Agilidad y Destreza',
    '-1 a su tamaño',
    'Dentro de una zona boscosa o selvática espesa suma 8 puntos a su regeneración de vida',
    'Resta dos grados a la dificultad de evitar daños por caídas',
    'Inmune a todos los venenos y enfermedades de origen natural',
    'Están obligados a elegir la ventaja del Don', // TODO: Crear issue
    'Permite identificar lazos elementales o anímicos de cualquier tipo de ser. Se puede resistir con 140 a la RM',
  ],
};

export const DukZaristMale: NephilimType = {
  id: '16',
  name: 'Duk`zarist (♂)',
  effects: [
    '+1 a todas sus características',
    'Mitad de penalizador en la oscuridad. También puede detectar fuentes de calor',
    'Puede alcanzar controles de inhumanidad en todas las características',
    'Presiente a otros nephilim Duk`zarist o Sylvain pero no puede identificarlos como tal. No hace efecto si el otro nephilim es oculto por un conjuro o mediante Ki',
    '+10 a conjuros y habilidades a controles relacionados con la oscuridad y el fuego',
    '+15 a todas sus resistencias excepto física que obtienen un +20',
    'Pasan todos los controles entre la vida y la muerte y además siempre permanence conscientes con un -40 a toda la acción mientras tenga puntos de vida negativos',
    '+5 a la regeneración de vida',
    'Disminuye su necesidad de dormir y comer hasta una séptima parte de lo normal',
    'Debe obtener o el Dón o Acceso a una habilidad psíquica', // TODO: Crear issue
    'En caso de desarrollar una habilidad psíquica, debe obtener como primera disciplina la piroquinésis', // TODO: Crear issue
    'En contacto con algo que contenga hierro necesita tirar una resistencia usando su presencia contra dificultad de 40 o 60 en caso de que sea hierro puro. Si su presencia base es 20 puntos por encima de la dificultad, ya no hace falta tirarla. Si falla, el Duk`zarist desaparece completamente dejando caer sus pertenencias y ropas vacías.',
    'No puede elegir las desventajas:',
    [
      'Desequilibrio hacia la luz',
      'Miembro atrofiado',
      'Salud enfermiza',
      'Vulnerabilidad a los venenos',
      'Miopía',
      'Debilidad física',
      'Enfermedad grave',
      'Mudo',
      'Ciego',
      'Sordo',
    ],
  ],
};

export const DukZaristFemale: NephilimType = {
  id: '16.5',
  name: 'Duk`zarist (♀)',
  effects: [
    '+1 a todas sus características',
    'Mitad de penalizador en la oscuridad. También puede detectar fuentes de calor',
    'Puede alcanzar controles de inhumanidad en todas las características',
    'Presiente a otros nephilim Duk`zarist o Sylvain pero no puede identificarlos como tal. No hace efecto si el otro nephilim es oculto por un conjuro o mediante Ki',
    '+10 a conjuros y habilidades a controles relacionados con la oscuridad y el fuego',
    '+15 a todas sus resistencias excepto mágica que obtienen un +20',
    'Pasan todos los controles entre la vida y la muerte y además siempre permanence conscientes con un -40 a toda la acción mientras tenga puntos de vida negativos',
    '+5 a la regeneración de vida',
    'Disminuye su necesidad de dormir y comer hasta una séptima parte de lo normal',
    'Debe obtener o el Dón o Acceso a una habilidad psíquica', // TODO: Crear issue
    'En caso de desarrollar una habilidad psíquica, debe obtener como primera disciplina la piroquinésis', // TODO: Crear issue
    'En contacto con algo que contenga hierro necesita tirar una resistencia usando su presencia contra dificultad de 40 o 60 en caso de que sea hierro puro. Si su presencia base es 20 puntos por encima de la dificultad, ya no hace falta tirarla. Si falla, el Duk`zarist desaparece completamente dejando caer sus pertenencias y ropas vacías.',
    'No puede elegir las desventajas:',
    [
      'Desequilibrio hacia la luz',
      'Miembro atrofiado',
      'Salud enfermiza',
      'Vulnerabilidad a los venenos',
      'Miopía',
      'Debilidad física',
      'Enfermedad grave',
      'Mudo',
      'Ciego',
      'Sordo',
    ],
  ],
};

export const Devah: NephilimType = {
  id: '17',
  name: 'Devah',
  effects: [
    '+1 a inteligencia, voluntad y poder',
    '-1 a fuerza y -2 a constitución',
    '+2 a su tamaño',
    'Permiten comunicarse con otros Devah con su tercer ojo, permite enviar palabras, emociones, ideas, recuerdos, etc.',
    '+15 a RM y RP si intenta leer su mente o provocar algún sentimiento o emoción',
    '-10 a RF y RE',
    'Con su tercer ojo puede ver criaturas anímicas y sentir innatamente sus afinidades elementales. Si contemplan a un ser sobrenatural entre 2 y 3 asaltos pueden saber su nombre. No funciona con criaturas de Gnosis > 30',
    '+10 a convocar, controlar, atar y desconvocar',
    'Obligados a usar Don o Acceso a Poderes Psíquicos', // TODO: Crear issue
  ],
};

export const Vetala: NephilimType = {
  id: '18',
  name: 'Vetala',
  effects: [
    '+1 a fuerza y poder durante la noche',
    'Ignora los efectos críticos en cuerpo o cabeza excepto decapitación',
    'Permite generar ultrasonido que influyen a criaturas de nivel 9 con inteligencia menor a 4 o nivel superior pero con misma inteligencia y cuya suma de presencias no supere a la del Vetala. Sólo con percepción 11 o control de Advertir contra Absurdo puede sentirlos',
    'Entre 5 y 30 minutos después de consumir sangre obtiene +10 a toda accion y +1 a un atributo determinado. Reducen a la mitad negativos de dolor y cansancio. 2 o 3 horas después sufren un -30 a toda acción durante 5 veces el tiempo del bono positivo',
    'Si se alimentan de sangre a diario, +4 a la regeneración de vida',
    'Si se expone a luces fuertes debe superar un RF entre 80 y 160 o sufrir un daño equivalente al nivel de fracaso. Si falla por más de 50, se prende en llamas.',
    '-2 a la experiencia',
  ],
};

export const TuanDalyr: NephilimType = {
  id: '19',
  name: 'Tuan Dalyr',
  effects: [
    'Al transformarse: Tiene armas naturales con daño de 40 más bono de fuerza. Es necesario haber desarrollado su habilidad de combate con ellas',
    'Al transformarse: +3 a repartir entre fuerza, destreza, agilidad y percepción (con máx. +2 por característica). Una vez elegidas, no se pueden cambiar',
    'Al transformarse: -2 a su inteligencia y voluntad',
    'Al transformarse: -10 a su RM y RP',
    '+1 a su percepción',
    'Con sentidos agudos: +40 a su advertir y +30 a buscar',
    'En su apogeo del ciclo lunar (elegido previamente) obtiene +1 a cada uno de sus atributos como si estuviera transformado.',
    'En el momento contrario a su apogeo del ciclo lunar, es incapaz de transformarse y pierde toda estas ventajas',
    'Si tienen emociones fuertes no pueden evitar que sus pupilas adquieran rasgos animalescos',
  ],
};

export const Turak: NephilimType = {
  id: '20',
  name: 'Turak',
  effects: [
    '+1 a su fuerza y constitución',
    '-1 a su inteligencia',
    'Tiene armas naturales con daño de 40 más bono de fuerza. Es necesario haber desarrollado su habilidad de combate con ellas',
    '+2 a su TA de filo, contundente, penetrante y calor', // TODO: Add as armor on export
    '+1 a la regeneración de vida. Obtienen +2 si han comido carne en abundancia y están descansados. Independientemente del tipo de regeneración, sus extremidades vuelven a crecer lentamente, recuperándose por completo en año o año y medio',
    '-20 a las resistencias basadas en efectos de frío.',
    '-20 al turno',
    '-3 a la experiencia',
    'Pueden desarrollar varias de las siguientes cualidades',
    [
      'Descomunal: +1 a su fuerza y constitución, +2 a su tamaño y -1 a su agilidad',
      'Alas: Pequeñas alas rudimentarias que permiten planear si se lanza desde gran altura con tipo de vuelo 10',
      'Ojos de cazador: +20 a advertir',
      'Cola: cuenta como arma grande y -40 si se usa como ataque en área',
      'Anfibio: otorga movimiento acuático y pueden permanecer sin respirar 10 minutos',
      'Piel escamosa: +3 a sus TAs',
    ],
  ],
};

export const AllNephilimTypes: NephilimType[] = [
  Human,
  NephilimSylvain,
  Sylvain,
  NephilimJayan,
  Jayan,
  NephilimDAnjayni,
  DAnjayni,
  NephilimEbudan,
  Ebudan,
  NephilimDaimah,
  Daimah,
  NephilimDukZaristMale,
  NephilimDukZaristFemale,
  DukZaristMale,
  DukZaristFemale,
  NephilimDevah,
  Devah,
  NephilimVetala,
  Vetala,
  NephilimTurak,
  Turak,
  TuanDalyr,
];
