import { model, Model, prop } from 'mobx-keystone';
import { KiType } from '../../../../../../../types/KiType';
import { KiAccumPointsFieldModel } from './KiAccumPointsFieldModel';
import { KiPointsPDFieldModel } from './KiPointsPDFieldModel';

@model('Character/Development/Category/Combat/Ki')
export class KiModel extends Model({
  agilityKiPoints: prop(
    () => new KiPointsPDFieldModel({ type: KiType.Agility }),
  ),
  strengthKiPoints: prop(
    () => new KiPointsPDFieldModel({ type: KiType.Strength }),
  ),
  dexterityKiPoints: prop(
    () => new KiPointsPDFieldModel({ type: KiType.Dexterity }),
  ),
  constitutionKiPoints: prop(
    () => new KiPointsPDFieldModel({ type: KiType.Constitution }),
  ),
  powerKiPoints: prop(() => new KiPointsPDFieldModel({ type: KiType.Power })),
  willPowerKiPoints: prop(
    () => new KiPointsPDFieldModel({ type: KiType.WillPower }),
  ),

  agilityKiAccumPoints: prop(
    () => new KiAccumPointsFieldModel({ type: KiType.Agility }),
  ),
  strengthKiAccumPoints: prop(
    () => new KiAccumPointsFieldModel({ type: KiType.Strength }),
  ),
  dexterityKiAccumPoints: prop(
    () => new KiAccumPointsFieldModel({ type: KiType.Dexterity }),
  ),
  constitutionKiAccumPoints: prop(
    () => new KiAccumPointsFieldModel({ type: KiType.Constitution }),
  ),
  powerKiAccumPoints: prop(
    () => new KiAccumPointsFieldModel({ type: KiType.Power }),
  ),
  willPowerKiAccumPoints: prop(
    () => new KiAccumPointsFieldModel({ type: KiType.WillPower }),
  ),
}) {
  get pdsSpent(): number {
    return this.pdsSpentOnKiPoints + this.pdsSpentOnKiAccumPoints;
  }

  get pdsSpentOnKiPoints(): number {
    return (
      this.agilityKiPoints.base +
      this.strengthKiPoints.base +
      this.dexterityKiPoints.base +
      this.constitutionKiPoints.base +
      this.powerKiPoints.base +
      this.willPowerKiPoints.base
    );
  }

  get pdsSpentOnKiAccumPoints(): number {
    return (
      this.agilityKiAccumPoints.base +
      this.strengthKiAccumPoints.base +
      this.dexterityKiAccumPoints.base +
      this.constitutionKiAccumPoints.base +
      this.powerKiAccumPoints.base +
      this.willPowerKiAccumPoints.base
    );
  }
}
