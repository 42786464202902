import { model, Model, prop } from 'mobx-keystone';
import { NoticeSkillModel } from './fields/NoticeSkillModel';
import { SearchSkillModel } from './fields/SearchSkillModel';
import { TrackSkillModel } from './fields/TrackSkillModel';

@model('Character/Secondaries/PerceptiveSkills')
export class PerceptiveSkillsModel extends Model({
  notice: prop(() => new NoticeSkillModel({})),
  search: prop(() => new SearchSkillModel({})),
  track: prop(() => new TrackSkillModel({})),
}) {
  get usedPhysicalNaturalBonusPoints(): number {
    return 0;
  }

  get usedMentalNaturalBonusPoints(): number {
    return (
      this.notice.naturalBonusPoints.base +
      this.search.naturalBonusPoints.base +
      this.track.naturalBonusPoints.base
    );
  }

  get usedNaturalAbilityPoints(): number {
    return (
      this.notice.naturalAbilityPoints +
      this.search.naturalAbilityPoints +
      this.track.naturalAbilityPoints
    );
  }

  get usedNovelBonusPoints(): number {
    return (
      this.notice.novelBonus + this.search.novelBonus + this.track.novelBonus
    );
  }
}
