import { useNavigate as useInternalNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { BaseRoutes } from '../../../bootstrap/application/Routes';

export const Routes = {
  Auth: BaseRoutes.Auth,

  MyCharacters: `${BaseRoutes.MyCharacters}/:folderId`,

  EditCharacter: `${BaseRoutes.EditCharacter}/:characterId`,
  ViewCharacter: `${BaseRoutes.ViewCharacter}/:characterId`,

  CharactersRoom: BaseRoutes.CharactersRoom,

  MyProfile: BaseRoutes.MyProfile,

  HowToImport: BaseRoutes.HowToImport,

  News: BaseRoutes.News,
} as const;

export type Route = typeof Routes[keyof typeof Routes];

export const useNavigate = () => {
  const internalNavigate = useInternalNavigate();

  const navigate = useCallback(
    (
      route: Route,
      options: {
        params?: Record<string, string | number | undefined>;
        queryParams?: Record<string, string>;
        replace?: boolean;
      } = {},
    ) => {
      let routeWithParams = route.toString();

      if (options.params) {
        Object.entries(options.params).forEach(([key, value]) => {
          routeWithParams = routeWithParams.replace(
            `:${key}`,
            value !== undefined ? value.toString() : '',
          );
        });
      }

      if (options.queryParams) {
        const queryParams = new URLSearchParams(options.queryParams);

        routeWithParams = `${routeWithParams}?${queryParams.toString()}`;
      }

      return internalNavigate(routeWithParams, options);
    },
    [internalNavigate],
  );

  return {
    navigate: navigate,
    navigateBack: useCallback(() => internalNavigate(-1), [internalNavigate]),
  };
};
