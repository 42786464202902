import { CharacterModel } from '../../../../../character/model/CharacterModel';
import { MartialArtType } from '../../../MartialArt';
import { MartialArtGradeId } from '../../../MartialArtGrades';
import { characterKnownMartialArts } from './characterKnownMartialArts';

export const calculateMartialArtsRequirements = (
  character: CharacterModel,
  martialArts: { type: MartialArtType; grade: MartialArtGradeId }[],
  options?: { needToKnowAll: boolean },
): string[] => {
  const knownMartialArts = characterKnownMartialArts(character, martialArts);

  const errors = martialArts.reduce((acc, martialArt) => {
    const knownMartialArt = knownMartialArts.find(
      (ma) => ma.type === martialArt.type,
    );

    if (!knownMartialArt) {
      return [
        ...acc,
        `Conocer ${martialArt.type} en grado ${martialArt.grade}`,
      ];
    }

    if (!knownMartialArt.containsGrade(martialArt.grade)) {
      return [
        ...acc,
        `Conocer el grado ${martialArt.grade} de ${martialArt.type}`,
      ];
    }

    return acc;
  }, [] as string[]);

  if (options?.needToKnowAll && errors.length > 0) {
    return errors;
  }

  if (errors.length === martialArts.length) {
    return [
      `Conocer: ${martialArts
        .map((ma) => `${ma.type} (${ma.grade})`)
        .join(', ')}`,
    ];
  }

  return [];
};
