import { v4 as uuid } from 'uuid';
import { Difficulty } from '../../../../../../character/domain/character/model/types/Difficulty';
import {
  CreateAction,
  DeleteAction,
} from '../../../../IframeMessagingService.types';
import { ActorMutator } from '../../../ActorCharacterTransformer';
import { FoundryVTTActor } from '../FoundryVTTActor';
import { ABFItems, PsychicPowerDataSource } from '../FoundryVTTActor.types';
import { FoundryVTTEnumTransforms } from './utils/FoundryVTTEnumTransforms';

export const mutateActorPsychicPowers: ActorMutator<FoundryVTTActor> = ({
  actor,
  character,
}) => {
  const deleteAction: DeleteAction = {
    type: 'delete',
    ids: actor.data.psychic.psychicPowers.reduce(
      (acc, i) => [...acc, i._id],
      [] as string[],
    ),
  };

  const createAction: CreateAction<PsychicPowerDataSource> = {
    type: 'create',
    data: character.content.psychic.knownPsychicPowers.map((i) => ({
      _id: uuid(),
      type: ABFItems.PSYCHIC_POWER,
      name: i.name,
      data: {
        actionType: {
          value: FoundryVTTEnumTransforms.getPsychicPowerActionType(
            i.actionType,
          ),
        },
        description: {
          value: i.description ?? '',
        },
        level: {
          value: i.level ?? 0,
        },
        bonus: {
          value:
            character.content.psychic.powers.find((p) => p.name === i.name)
              ?.bonusFromPsychicPointsUsedToIncrease ?? 0,
        },
        effects: {
          '20': {
            value: i.casts[Difficulty.Routine] ?? '',
          },
          '40': {
            value: i.casts[Difficulty.Easy] ?? '',
          },
          '80': {
            value: i.casts[Difficulty.Medium] ?? '',
          },
          '120': {
            value: i.casts[Difficulty.Hard] ?? '',
          },
          '140': {
            value: i.casts[Difficulty.VeryHard] ?? '',
          },
          '180': {
            value: i.casts[Difficulty.Absurd] ?? '',
          },
          '240': {
            value: i.casts[Difficulty.AlmostImpossible] ?? '',
          },
          '280': {
            value: i.casts[Difficulty.Impossible] ?? '',
          },
          '320': {
            value: i.casts[Difficulty.Inhuman] ?? '',
          },
          '440': {
            value: i.casts[Difficulty.Zen] ?? '',
          },
        },
        hasMaintenance: {
          value: i.hasMaintenance,
        },
      },
    })),
  };

  actor.data.psychic.psychicPowers = [];

  return {
    actor,
    actions: [deleteAction, createAction],
  };
};
