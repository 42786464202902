import { Model, model, prop } from 'mobx-keystone';
import { TotalColdATField } from './fields/TotalColdATField';
import { TotalCutATField } from './fields/TotalCutATField';
import { TotalElectricityATField } from './fields/TotalElectricityATField';
import { TotalEnergyATField } from './fields/TotalEnergyATField';
import { TotalHeatATField } from './fields/TotalHeatATField';
import { TotalImpactATField } from './fields/TotalImpactATField';
import { TotalThrustATField } from './fields/TotalThrustATField';

@model('Character/TotalAT')
export class TotalATModel extends Model({
  cut: prop(() => new TotalCutATField({})),
  impact: prop(() => new TotalImpactATField({})),
  thrust: prop(() => new TotalThrustATField({})),
  heat: prop(() => new TotalHeatATField({})),
  cold: prop(() => new TotalColdATField({})),
  electricity: prop(() => new TotalElectricityATField({})),
  energy: prop(() => new TotalEnergyATField({})),
}) {}
