import { model } from 'mobx-keystone';
import { BaseFinalFieldModel } from '../../../character/model/utils/fields/BaseFinalFieldModel';
import { getParentArmor } from '../../../utils/parenting/getParentArmor';

@model('ArmorItem/ArmorMovementRestrictionField')
export class ArmorMovementRestrictionFieldModel extends BaseFinalFieldModel {
  get final(): number {
    if (!this.armor) return 0;

    return Math.max(
      this.base + this.special - Math.floor(this.armor.quality.final / 5),
      0,
    );
  }

  get armor() {
    return getParentArmor(this);
  }
}
