export const calculateRegeneration = (value: number): number => {
  if (value <= 2) {
    return 0;
  }

  if (value <= 7) {
    return 1;
  }

  if (value <= 9) {
    return 2;
  }

  if (value >= 19) {
    return 12;
  }

  return value - 7;
};
