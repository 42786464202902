import { ExtendedModel, modelAction, prop } from 'mobx-keystone';
import { NumberField } from './NumberField';

export abstract class BaseFinalFieldModel extends ExtendedModel(NumberField, {
  base: prop(0),
}) {
  @modelAction
  setBase(value: number): void {
    this.base = value;
  }
}
