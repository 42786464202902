import styled from 'styled-components';
import { theme } from '../../../../../../../../../../../shared/application/design-system/theme/theme';
import { InputWrapper } from '../../../DoubleInput/DoubleInput.styled';

export type StyledCellProps = {
  $bold?: boolean;
  $textAlign?: 'left' | 'center' | 'right';
  $danger?: boolean;

  $width?: number;

  $verticalAlign?: 'sub' | 'middle' | 'bottom';
  $hideOnMobile?: boolean;
  $important?: boolean;
  $interactable?: boolean;
};

export const StyledCell = styled.td<StyledCellProps>(
  ({
    theme: { color, mixins },
    $bold,
    $textAlign = 'center',
    $danger,
    $hideOnMobile,
    $important,
    $interactable,
    $verticalAlign,
    $width,
  }) => ({
    ...mixins.responsive.xs.downward({
      display: $hideOnMobile ? 'none' : undefined,
    }),

    ...mixins.responsive.sm.downward({
      fontSize: $important ? '1rem' : '0.8rem',
    }),

    width: $width !== undefined ? `${$width}rem` : undefined,

    verticalAlign: $verticalAlign ?? undefined,

    fontSize: $important ? '1.2rem' : '0.9rem',
    fontWeight: $bold || $important ? 'bold' : undefined,
    textAlign: $textAlign,

    color: $danger ? theme.color.primaryLight() : undefined,

    transition: 'all 0.2s ease-in-out',

    [InputWrapper]: {
      width: '100%',
    },

    ...($interactable ? mixins.interactable(color) : undefined),
  }),
);
