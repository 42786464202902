export enum StringInputVariant {
  Normal = 'Normal',
  Outline = 'Outline',
}

export enum StringInputSize {
  Big = 'Big',
  Normal = 'Normal',
}

export enum StringLabelPosition {
  Top = 'Top',
  Left = 'Left',
}
