import { observer } from 'mobx-react';
import { ChangeEvent } from 'react';
import { Token } from './components/Token/Token';
import {
  Categories,
  CharacterInfo,
  StyledInput,
  Wrapper,
} from './CharacterHeader.styled';
import { useCharacterContext } from '../../hooks/useCharacterContext';

export const CharacterHeader = observer(() => {
  const { character } = useCharacterContext();

  const handleCharacterNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    character.content.setName(e.target.value);
  };

  const disabled = !character.canBeEdited;

  return (
    <Wrapper>
      <Token />
      <CharacterInfo>
        <StyledInput
          value={character.content.name}
          disabled={disabled}
          onChange={handleCharacterNameChange}
        />
        <Categories variant="overline" fontSize="0.65rem" fontWeight="bold">
          {character.content.categories
            .map((c) => `${c.template.name} (${c.level.final})`)
            .join(' / ')}
        </Categories>
      </CharacterInfo>
    </Wrapper>
  );
});
