import { Draft, draft } from 'mobx-keystone';
import { observer } from 'mobx-react';
import {
  Container,
  Section,
  SectionTitle,
  StyledInput,
  StyledTitle,
  Wrapper,
} from './EditArmor.styled';
import { ChangeEvent, useState } from 'react';
import { Button } from '../../../../../../../../../../../../../../../shared/application/design-system/components/Button/Button';
import { useConfirmModal } from '../../../../../../../../../../../../../../../shared/application/hooks/useConfirmModal';
import { ArmorItemModel } from '../../../../../../../../../../../../../../domain/aggregations/armors/ArmorItemModel';

export const EditArmor = observer(
  ({
    armor: originalArmor,
    onSaveArmor,
  }: {
    armor: ArmorItemModel;
    onSaveArmor: () => void;
  }) => {
    const [armorDraft] = useState<Draft<ArmorItemModel>>(draft(originalArmor));

    const { openConfirmModal, ConfirmModal } = useConfirmModal();

    const armor = armorDraft.data;

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
      armor.setName(event.target.value);
    };

    const handleResetArmor = async () => {
      const response = await openConfirmModal({
        title: 'Esta acción no se puede deshacer',
        content:
          '¿Estás seguro de que quieres resetear esta armadura a su estado por defecto?',
        buttons: {
          confirm: 'Resetear',
          reject: 'Cancelar',
        },
      });

      if (response) {
        armor.resetToDefault();

        armorDraft.commit();

        onSaveArmor();
      }
    };

    const handleSaveArmor = () => {
      armor.transformToCustom();

      armorDraft.commit();

      onSaveArmor();
    };

    return (
      <Wrapper>
        <Container>
          <StyledTitle>Editar armadura</StyledTitle>
          <Section>
            <SectionTitle>Nombre</SectionTitle>
            <StyledInput value={armor.name} onChange={handleNameChange} />
          </Section>

          <Button onClick={handleResetArmor} disabled={!armor.isCustom}>
            Resetear por defecto
          </Button>
          <Button onClick={handleSaveArmor} disabled={!armorDraft.isDirty}>
            Guardar
          </Button>
        </Container>
        <ConfirmModal />
      </Wrapper>
    );
  },
);
