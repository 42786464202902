import styled from 'styled-components';

export const Wrapper = styled.div(({ theme: { size, mixins } }) => ({
  display: 'flex',
  flexDirection: 'column',

  gap: size.units(4),

  padding: size.units(2),
  boxSizing: 'border-box',

  ...mixins.responsive.xs.downward({
    width: '100%',

    paddingLeft: size.units(3),
    paddingRight: size.units(3),
  }),
}));

export const Container = styled.div(({ theme: { size, mixins } }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',

  gap: size.units(2),

  ...mixins.responsive.xs.downward({
    flexDirection: 'column',

    gap: size.units(2),
  }),

  width: '100%',
  height: '100%',
}));
