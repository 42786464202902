export enum PsychicTableType {
  PsychicProjection = 'PsychicProjection',
}

export abstract class PsychicTable {
  abstract readonly name: string;

  abstract type: PsychicTableType;

  abstract cost: number;

  abstract readonly effect: string;
}

export class PsychicProjectionTable extends PsychicTable {
  name = 'Tabla de Proyección Psíquica';

  type = PsychicTableType.PsychicProjection;

  cost = 100;

  effect = 'Permite usar Ataque y Defensa como Proyección Psíquica.';
}

export const mapPsychicTypeToTable: Record<
  PsychicTableType,
  { new (): PsychicTable }
> = {
  [PsychicTableType.PsychicProjection]: PsychicProjectionTable,
};
