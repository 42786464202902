import { model } from 'mobx-keystone';
import {
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
} from '../../../../../aggregations/advantage/Advantage.types';
import { ArmorUseAdvantage } from '../../../../../aggregations/advantage/CommonAdvantages';
import { DevelopedPDFieldModel } from '../../../utils/fields/DevelopedPDFieldModel';
import { getTotalOf } from '../../../utils/getTotalOf';

@model('Character/Combat/WearArmorAbility')
export class WearArmorAbilityFieldModel extends DevelopedPDFieldModel {
  get primariesBonus(): number {
    if (!this.character) return 0;

    return this.character.primaries.strength.modifier.final;
  }

  get allActionsPenalty(): number {
    return 0;
  }

  get fromCategories(): number {
    return getTotalOf(this.character, (c) => c.combat.wearArmorAbility.final);
  }

  get bonusFromAdvantages(): number {
    if (!this.character) return 0;

    let value = 0;

    if (
      this.character.creationPoints.containsAdvantage(ArmorUseAdvantage, {
        level: AdvantageLevel1,
      })
    ) {
      value += 5 * this.character.level;
    }

    if (
      this.character.creationPoints.containsAdvantage(ArmorUseAdvantage, {
        level: AdvantageLevel2,
      })
    ) {
      value += 10 * this.character.level;
    }

    if (
      this.character.creationPoints.containsAdvantage(ArmorUseAdvantage, {
        level: AdvantageLevel3,
      })
    ) {
      value += 15 * this.character.level;
    }

    return value;
  }
}
