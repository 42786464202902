import { SecondarySkill } from '../../character/model/types/SecondarySkill';
import { Archetype, CategoryTemplate, CategoryType } from './CategoryTemplate';

export class PaladinCategoryTemplate extends CategoryTemplate {
  name = 'Paladin';
  archetypes = [Archetype.Fighter];
  type = CategoryType.Paladin;

  readonly lifeMultiplier = 15;
  lifePoints = 15;
  protected turn = 5;
  protected martialKnowledge = 20;
  protected nativePsychicPoints = 1;

  offensiveSkillsLimitPercentage = 0.6;
  attackPDsCost = 2;
  blockPDsCost = 2;
  dodgePDsCost = 2;
  wearArmorPDsCost = 2;
  kiPDsCost = 2;
  kiAccumMultiplierPDsCost = 20;

  magicSkillsLimitPercentage = 0.5;
  zeonPDsCost = 2;
  aCTMultiplierPDsCost = 60;
  magicProjectionPDsCost = 3;
  summonPDsCost = 3;
  controlPDsCost = 3;
  bindPDsCost = 3;
  banishPDsCost = 1;

  psychicSkillsLimitPercentage = 0.5;
  psychicPointsPDsCost = 20;
  psychicProjectionPDsCost = 3;

  athleticSecondariesPDsCost = 2;
  socialSecondariesPDsCost = 1;
  perceptionSecondariesPDsCost = 2;
  intellectualSecondariesPDsCost = 2;
  vigorSecondariesPDsCost = 2;
  subterfugeSecondariesPDsCost = 3;
  creativeSecondariesPDsCost = 2;

  blockBonus = 5;
  wearArmorBonus = 10;
  banishBonus = 10;
  zeonBonus = 20;

  calculateSecondarySkillPDReduction(skill: SecondarySkill): number {
    if (skill === SecondarySkill.WithstandPain) return 1;

    return 0;
  }

  calculateSecondarySkillBonusPerCategory(skill: SecondarySkill): number {
    if (skill === SecondarySkill.Leadership) return 10;
    if (skill === SecondarySkill.WithstandPain) return 10;
    if (skill === SecondarySkill.Style) return 5;

    return 0;
  }
}
