import { AuthorizedPageLayout } from '../../../../shared/application/design-system/components/PageLayout/PageLayout';
import { DefaultNavbar } from '../../../../shared/application/design-system/components/TopNavbar/implementations/DefaultNavbar';
import { LastNews } from './components/LastNews/LastNews';

export const NewsPage = () => {
  return (
    <AuthorizedPageLayout Header={<DefaultNavbar />}>
      <LastNews />
    </AuthorizedPageLayout>
  );
};
