import {
  BasicMartialArtGradeIds,
  buildAdvancedMartialArtArcaneGrade,
  buildAdvancedMartialArtBasicGrade,
} from '../../MartialArtGrades';
import {
  AdvancedMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';
import { calculateHighestDamageFromKnownMartialArts } from './utils/calculateHighestDamageFromKnownMartialArts';
import { calculateMartialArtsRequirements } from './utils/calculateMartialArtsRequirements';

const BasicMartialArtsRequirement = [
  {
    type: MartialArtType.Kenpo,
    grade: BasicMartialArtGradeIds.Advanced,
  },
  {
    type: MartialArtType.Kuan,
    grade: BasicMartialArtGradeIds.Advanced,
  },
  {
    type: MartialArtType.MallaYuddha,
    grade: BasicMartialArtGradeIds.Advanced,
  },
];

const ArcaneMartialArtsRequirement = [
  {
    type: MartialArtType.Kenpo,
    grade: BasicMartialArtGradeIds.Supreme,
  },
  {
    type: MartialArtType.Kuan,
    grade: BasicMartialArtGradeIds.Supreme,
  },
  {
    type: MartialArtType.MallaYuddha,
    grade: BasicMartialArtGradeIds.Supreme,
  },
];

export class EmpMartialArt extends AdvancedMartialArt {
  readonly name = 'Emp';

  readonly complexity = 3;

  readonly type = MartialArtType.Emp;

  readonly level = MartialArtLevelType.Advanced;

  readonly grades = [
    buildAdvancedMartialArtBasicGrade({
      cmBonus: 10,
      effects:
        '+3 controles enfrentados y sin penalizador para desarmar. Si PJ conoce Malla-yuddha en supremo, el bono es de +4',
      combatBonus: {
        attack: 20,
        initiative: 10,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          BasicMartialArtsRequirement,
        );

        if (character.combat.attackAbility.final < 200) {
          errors.push('Ataque 200+');
        }

        return errors;
      },
    }),
    buildAdvancedMartialArtArcaneGrade({
      cmBonus: 20,
      effects:
        'Al contraataque puede elegir hacer un control de desarmar de forma directa sin realizar ataque. No se aplican bonificadores a este control de ningun tipo.',
      combatBonus: {
        initiative: 10,
      },
      masterBonus: {
        attack: 20,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          ArcaneMartialArtsRequirement,
        );

        if (character.combat.attackAbility.final < 280) {
          errors.push('Ataque 280+');
        }

        if (character.combat.defensiveAbility < 260) {
          errors.push('Bloqueo o esquiva 260+');
        }

        return errors;
      },
    }),
  ];
}
