import { useEffect } from 'react';

const useScript = () => {
  const urlScript = 'https://c6.patreon.com/becomePatronButton.bundle.js';
  const isAsync = true;
  const crossOrigin = 'anonymous';
  useEffect(() => {
    const script = document.createElement('script');

    script.src = urlScript;

    script.async = isAsync;

    script.crossOrigin = crossOrigin;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [crossOrigin, isAsync, urlScript]);
};

export default useScript;
