import styled from 'styled-components';
import {
  BasicParagraph,
  BasicTitle,
} from '../../../../../../../../shared/application/design-system/components/Texts/Texts.styled';

export const ParagraphInformation = styled(BasicParagraph)(
  ({ theme: { color, size } }) => ({
    fontSize: size.units(2.5),
    color: color.white(),
    marginBottom: size.units(2),
  }),
);
export const SmallParagraphInformation = styled(BasicParagraph)(
  ({ theme: { color, size } }) => ({
    fontSize: size.units(1.5),
    marginTop: size.units(1),
    marginBottom: size.units(1),
    color: color.white(),
  }),
);

export const TitleInformation = styled(BasicTitle)(
  ({ theme: { color, size } }) => ({
    fontSize: size.units(3.5),
    color: color.white(),
    marginBottom: size.units(2),
    marginTop: size.units(2),
  }),
);
export const EmailInformation = styled(BasicTitle)(
  ({ theme: { color, size } }) => ({
    fontSize: size.units(2.5),
    color: color.white(),
    marginBottom: size.units(0.5),
  }),
);

export const Wrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: size.units(2),
  paddingRight: size.units(2),
}));

export const ContainerSubscription = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [SmallParagraphInformation]: {
    fontSize: size.units(1.75),
    marginTop: size.units(0),
    marginBottom: size.units(2),
  },
}));

export const FooterContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: size.units(4),
  borderTop: '2px solid white',
  borderBottom: '2px solid white',

  [EmailInformation]: {
    fontSize: size.units(1.75),
  },
}));

export const PatreonContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  iframe: {
    width: size.units(23),
  },
}));
