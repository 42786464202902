import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { NotificationManager } from '../../../../../../shared/application/shared/NotificationManager';
import { BetaService } from '../../../../../infrastructure/SignUpBetaService';
import {
  GamerSelect,
  InputContainer,
  InputsContainer,
  StyledInput,
  TitleInformation,
  WhiteParagraphInformation,
  WhiteSmallParagraph,
} from '../../../../../../auth/application/AuthorizationPage/components/Authorization/Autorization.styled';
import { Button } from '../../../../../../shared/application/design-system/components/Button/Button';
import { ButtonSize } from '../../../../../../shared/application/design-system/components/Button/Button.types';
import { SubscriptionConfirmation } from './components/SubscriptionConfirmation/SubscriptionConfirmation';

const errorMessages = {
  message: 'Ya hay un usuario registrado con ese email',
};
const ParagraphInformation = {
  information: 'Ayudarnos a mejorar para ti',
  titleInformation: 'Solicita ya tu acceso a la Beta',
};

enum Roles {
  Default = 'Selecciona el tipo de jugador',
  Master = 'Game Master',
  Player = 'Jugador',
  Interested = 'Interesado',
}

export const SignUpBeta = () => {
  const { saveBetaUserEmail, IsBetaSubscription, signUpBeta } = BetaService;

  const [role, setRole] = useState<string>('');
  const [email, setEmail] = useState('');
  const [isSignUpBeta, setIsSignUpBeta] = useState(IsBetaSubscription());

  const validParams = email.trim().length > 0;

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSignUpBeta = () => {
    if (!validParams) return;

    signUpBeta(email, role)
      .then(() => {
        saveBetaUserEmail(email);
        setIsSignUpBeta(IsBetaSubscription());
      })
      .catch((e) => {
        if (e) {
          NotificationManager.error(errorMessages.message);
        }

        console.error(e);
      });
  };
  const handleEnterKey = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSignUpBeta();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    if (event.currentTarget.value !== '') {
      setRole(event.currentTarget.value);
    }
  };

  return (
    <>
      {!isSignUpBeta ? (
        <>
          <WhiteParagraphInformation
            dangerouslySetInnerHTML={{
              __html: ParagraphInformation.information,
            }}
          />

          <TitleInformation
            dangerouslySetInnerHTML={{
              __html: ParagraphInformation.titleInformation,
            }}
          />
          <InputsContainer>
            <InputContainer>
              <WhiteSmallParagraph>Tipo de Usuario</WhiteSmallParagraph>
              <GamerSelect onChange={handleChange} required={true}>
                <option value={''}>{`${Roles.Default}`}</option>
                <option value={`${Roles.Master}`}>{Roles.Master}</option>
                <option value={`${Roles.Player}`}>{Roles.Player}</option>
                <option value={`${Roles.Interested}`}>
                  {Roles.Interested}
                </option>
              </GamerSelect>
            </InputContainer>
            <InputContainer>
              <WhiteSmallParagraph>Email</WhiteSmallParagraph>
              <StyledInput
                name="email"
                type={'email'}
                value={email}
                onChange={handleEmailChange}
                onKeyDown={handleEnterKey}
              />
            </InputContainer>
          </InputsContainer>
          <Button
            size={ButtonSize.Big}
            onClick={handleSignUpBeta}
            disabled={!validParams}
          >
            Solicitar acceso
          </Button>
        </>
      ) : (
        <SubscriptionConfirmation />
      )}
    </>
  );
};
