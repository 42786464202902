import styled from 'styled-components';
import { theme } from '../../../../../../../../../shared/application/design-system/theme/theme';
import { Tabs } from '../../../../../../../../../shared/application/design-system/components/Tabs/Tabs';
import { SmallParagraph } from '../../../../../../../../../shared/application/design-system/components/Texts/Texts.styled';

export const Wrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1rem',

  width: '100%',
  maxWidth: '50rem',

  justifyContent: 'center',

  paddingTop: '0.5rem',
});

export const StyledTabs = styled(Tabs)({
  ...theme.mixins.responsive.xs.downward({
    justifyContent: 'center',
  }),
});

export const PDsRowSections = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',

  width: '100%',
  height: '100%',

  gap: '1rem',

  ...theme.mixins.responsive.xs.downward({
    justifyContent: 'center',
  }),
});

export const PDsColumnSections = styled(PDsRowSections)({
  flexDirection: 'column',
});

export const PDSectionDivider = styled.div({
  height: '1px',
  marginTop: '0.5rem',
  marginBottom: '0.5rem',
});

export const CenteredContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StateSmallParagraph = styled(SmallParagraph)<{
  $errored: boolean;
}>(({ theme: { color }, $errored }) => ({
  color: $errored ? color.error() : undefined,

  fontWeight: 'bold',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));
