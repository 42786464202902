import { model } from 'mobx-keystone';
import { MysticArmorAdvantage } from '../../../../../aggregations/advantage/CommonAdvantages';
import { calculateTotalAT } from '../utils/calculateTotalAT';
import { TotalATField } from './TotalATField';

@model('Character/TotalEnergyAT')
export class TotalEnergyATField extends TotalATField {
  get final(): number {
    if (!this.character) return 0;

    let ta = super.final;

    if (this.character.creationPoints.containsAdvantage(MysticArmorAdvantage)) {
      ta += 4;
    }

    return (
      ta +
      calculateTotalAT(
        this.character.inventory.equippedArmors.map((a) => a.at.energy.final),
      )
    );
  }
}
