import styled from 'styled-components';
import { theme } from '../../../../../../../../../shared/application/design-system/theme/theme';
import { BasicParagraph } from '../../../../../../../../../shared/application/design-system/components/Texts/Texts.styled';

export const Node = styled(BasicParagraph)<{
  $deep: number;
  $selected: boolean;
}>(({ $deep, $selected }) => {
  const calculateFontSize = () => {
    const fontSize = 1;

    return fontSize - fontSize * 0.075 * $deep;
  };

  return {
    cursor: 'pointer',

    fontWeight: 800,

    fontSize: `${calculateFontSize()}rem`,

    color: $selected ? theme.color.primaryLight() : theme.color.black(),
    transition: 'color 0.2s',

    '&:hover': {
      color: theme.color.primaryLight(),
      textDecoration: 'underline',
    },
  };
});

export const TreeNode = styled.div({
  display: 'flex',
  flexDirection: 'column',

  gap: '0.5rem',
});

export const NodeWrapper = styled.div<{ $deep: number }>(({ $deep }) => {
  const calculatePaddingLeft = () => {
    return $deep * 0.5;
  };

  const calculatePaddingBottom = () => {
    const paddingBottom = 0.5;
    return paddingBottom - paddingBottom * 0.1 * $deep;
  };

  return {
    display: 'flex',
    flexDirection: 'column',

    paddingLeft: `${calculatePaddingLeft()}rem`,

    paddingBottom: `${calculatePaddingBottom()}rem`,
  };
});
