import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';
import { getPsychicTableFromType } from '../../../../../../../../aggregations/tables/psychic-tables/getPsychicTableFromType';
import {
  PsychicTable,
  PsychicTableType,
} from '../../../../../../../../aggregations/tables/psychic-tables/PsychicTable';

@model('PsychicTable')
export class PsychicTableModel extends Model({
  type: prop<PsychicTableType>().withSetter(),
}) {
  @computed
  get table(): PsychicTable {
    return getPsychicTableFromType(this.type);
  }

  @computed
  get name(): string {
    return this.table.name;
  }

  @computed
  get cost(): number {
    return this.table.cost;
  }

  @computed
  get effect(): string {
    return this.table.effect;
  }
}
