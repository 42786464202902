import styled from 'styled-components';
import { Edit } from '@styled-icons/material';

export const EditIcon = styled(Edit)(({ theme: { color } }) => ({
  color: color.white(),
}));

export const Background = styled.div(({ theme: { size, color } }) => ({
  transition: 'all 0.3s',
  backgroundColor: color.black(0.5),

  width: size.units(8),
  height: size.units(8),

  borderRadius: '50%',
}));

export const Wrapper = styled.div<{ $disabled?: boolean }>(
  ({ theme: { size }, $disabled }) => ({
    width: size.units(8),
    height: size.units(8),

    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    cursor: 'pointer',

    transition: 'all 0.3s',

    position: 'relative',

    [EditIcon]: {
      transition: 'all 0.3s',

      width: size.units(3),
      opacity: 0,

      position: 'absolute',

      zIndex: 1,
    },

    [Background]: {
      position: 'absolute',
      opacity: 0,
    },

    '&:hover': {
      [`${EditIcon}, ${Background}`]: {
        opacity: $disabled ? undefined : 1,
      },
    },
  }),
);
