import { useState } from 'react';
import { Character } from '../../../character/domain/character/Character';
import { CharacterService } from '../../../character/infrastructure/CharacterService';

export const useFetchCharacter = () => {
  const [character, setCharacter] = useState<Character | undefined>(undefined);

  const [loading, setLoading] = useState(false);

  const fetchCharacter = (id: string) => {
    setLoading(true);

    return CharacterService.getCharacter(id)
      .then((data) => {
        setCharacter(data);
      })
      .catch((error) => {
        console.error(error);

        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    character,

    loadingCharacter: loading,
    fetchCharacter,
  };
};
