import { Model, modelAction, prop } from 'mobx-keystone';
import { getParentCharacter } from '../../../../utils/parenting/getParentCharacter';

export abstract class CurrentMaxField extends Model({
  current: prop(0),
  special: prop(0),
}) {
  protected onAttachedToRootStore(rootStore: object): (() => void) | void {
    this.current = this.max;

    return super.onAttachedToRootStore?.(rootStore);
  }

  @modelAction
  setSpecial(value: number): void {
    this.special = value;
  }

  @modelAction
  setCurrent(value: number): void {
    this.current = value;
  }

  abstract get max(): number;

  get character() {
    return getParentCharacter(this);
  }
}
