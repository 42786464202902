import { observer } from 'mobx-react';
import { SummoningSpecialty } from '../../../../../../../../../domain/character/model/parts/mystic/parts/SummoningModel';
import DoubleInput from '../../../common/DoubleInput/DoubleInput';
import { useCharacterMenu } from '../../../common/CharacterMenu/useCharacterMenu';
import { Section } from '../../../common/Section/Section';
import { Table } from '../../../common/Table/Table';
import { useCharacterContext } from '../../../../hooks/useCharacterContext';
import { MenuItem } from '../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';

export const MagicSummoning = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { handleMenuOpen, Menu } = useCharacterMenu<
    SummoningSpecialty | undefined
  >();

  const summoningSpecialtyItems: MenuItem<SummoningSpecialty | undefined>[] = [
    {
      key: 'none',
      value: undefined,
      name: 'Ninguna',
      onClick: () => {
        character.mystic.summoning.setSpecialty(undefined);
      },
    },
    ...Object.entries(SummoningSpecialty).map(([key, value]) => {
      const item: MenuItem<SummoningSpecialty> = {
        key,
        name: value,
        value,
        onClick: () => {
          character.mystic.summoning.setSpecialty(value);
        },
      };

      return item;
    }),
  ];

  return (
    <Section maxHeight="fit">
      <Section.Title>Convocatoria</Section.Title>
      <DoubleInput label="Convocar" field={character.mystic.summoning.summon} />
      <DoubleInput label="Dominar" field={character.mystic.summoning.control} />
      <DoubleInput label="Atar" field={character.mystic.summoning.bind} />
      <DoubleInput
        label="Desconvocar"
        field={character.mystic.summoning.banish}
      />
      <Table>
        <Table.Header>
          <Table.Header.Cell>Especialidad</Table.Header.Cell>
        </Table.Header>
        <Table.Row>
          <Table.Row.Cell
            $interactable
            onClick={handleMenuOpen(character.mystic.summoning.specialty)}
          >
            {character.mystic.summoning.specialty ?? 'Ninguna'}
          </Table.Row.Cell>
        </Table.Row>
      </Table>

      <Menu items={summoningSpecialtyItems} />
    </Section>
  );
});
