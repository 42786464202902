import { Fab } from '@mui/material';
import { CheckCircle } from '@styled-icons/material';
import { getRootStore, onPatches, registerRootStore } from 'mobx-keystone';
import { observer } from 'mobx-react';
import { ComponentType, MouseEvent, useEffect, useState } from 'react';
import { useFetchCharacter } from '../../../../../../shared/application/hooks/useFetchCharacter';
import { useSaveCharacter } from '../../../../../../shared/application/hooks/useSaveCharacter';
import { Loading } from '../../../../../../shared/application/design-system/components/Loading/Loading';
import { Tabs } from '../../../../../../shared/application/design-system/components/Tabs/Tabs';
import {
  Container,
  FabContainer,
  StyledTabs,
  Wrapper,
} from './CharacterSheet.styled';
import { CharacterHeader } from './components/CharacterHeader/CharacterHeader';
import { AboutTab } from './components/Tabs/About/AboutTab';
import { GeneralTab } from './components/Tabs/General/GeneralTab';
import { InventoryTab } from './components/Tabs/Inventory/Inventory';
import { KiTab } from './components/Tabs/Ki/Ki';
import { MysticTab } from './components/Tabs/Mystic/Mystic';
import { PDsTab } from './components/Tabs/PDs/PDsTab';
import { PsychicTab } from './components/Tabs/Psychic/Psychic';
import { CharacterContext } from './hooks/useCharacterContext';
import { useKeyboardShortcuts } from './hooks/useKeyboardShortcuts';
import { useAddCharacterToDevScope } from './hooks/useAddCharacterToDevScope';
import { usePreventNavigationWithUnsavedChanges } from './hooks/usePreventNavigationWithUnsavedChanges';
import { useConfirmModal } from '../../../../../../shared/application/hooks/useConfirmModal';
import { TrackingService } from '../../../../../../shared/infrastructure/tracking/TrackingService';
import { NotificationManager } from '../../../../../../shared/application/shared/NotificationManager';
import {
  Routes,
  useNavigate,
} from '../../../../../../shared/application/hooks/useNavigate';
import { CharacterToolbar } from './components/CharacterToolbar/CharacterToolbar';
import { useCharacterCollaboration } from './hooks/useCharacterCollaboration';

enum Tab {
  About = 'Información',
  General = 'General',
  PDs = 'PDs',
  Ki = 'Ki',
  Mystic = 'Místico',
  Psychic = 'Psíquico',
  Inventory = 'Inventario',
}

export const CharacterSheet = observer(
  ({ characterId, readonly }: { characterId: string; readonly?: boolean }) => {
    const { character, loadingCharacter, fetchCharacter } = useFetchCharacter();
    const { savingCharacter, saveCharacter } = useSaveCharacter();

    const { startCollaboration, stopCollaboration } =
      useCharacterCollaboration(characterId);

    const { navigate } = useNavigate();

    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
      if (!character) return;

      return onPatches(character.content, () => {
        setIsDirty(true);
      });
    });

    useEffect(() => {
      if (readonly)
        return () => {
          // do nothing
        };

      if (character?.isCollaborative) {
        startCollaboration().then((response) => {
          if (response !== undefined && response.someOneIsEditing) {
            NotificationManager.warning(
              'Alguien está editando este personaje. Se te mostrará la última versión guardada.',
            );

            character.blockEditing();
          }
        });
      }

      return () => {
        if (character?.isCollaborative) {
          stopCollaboration();
        }
      };
    }, [character, startCollaboration, stopCollaboration]);

    const { openConfirmModal, ConfirmModal } = useConfirmModal();

    useKeyboardShortcuts([
      {
        key: 'Save',
        fn: (e) => {
          e.preventDefault();

          handleSaveCharacter().then(() => {
            TrackingService.sendEvent('character_saved');
          });
        },
      },
    ]);
    useAddCharacterToDevScope(character);

    const blocker = usePreventNavigationWithUnsavedChanges(isDirty);

    useEffect(() => {
      if (!character) return;

      if (!getRootStore(character.content)) {
        registerRootStore(character.content);
      }
    }, [character]);

    useEffect(() => {
      fetchCharacter(characterId).catch(() => {
        NotificationManager.error('No se ha podido cargar el personaje');

        navigate(Routes.MyCharacters, { params: { folderId: '' } });
      });
    }, [characterId]);

    useEffect(() => {
      if (!character) return;

      if (!character.canBeEdited && !readonly) {
        navigate(Routes.ViewCharacter, {
          params: { characterId },
          replace: true,
        });
      }
    }, [character]);

    useEffect(() => {
      if (!character) return;

      if (!character.canBeEdited) {
        blocker.proceed?.();

        return;
      }

      if (blocker.state === 'blocked') {
        openConfirmModal({
          title: '¿Estás seguro?',
          content:
            'Al volver, se perderán los cambios que no hayas guardado. ¿Estás seguro de que quieres volver?',
        }).then((confirm) => {
          if (confirm) {
            blocker.proceed?.();
          }
        });
      }
    }, [blocker]);

    const handleSaveCharacterClick = async (e: Event | MouseEvent) => {
      e.preventDefault();

      handleSaveCharacter().then(() => {
        TrackingService.sendEvent('character_saved');
      });
    };

    const handleSaveCharacter = async () => {
      if (!character || !character.canBeEdited) return;

      const dirty = isDirty;

      setIsDirty(false);

      saveCharacter(character, { notify: false })
        .catch(() => {
          setIsDirty(dirty);
        })
        .finally(() => {
          blocker.reset?.();
        });
    };

    const mapTabToComponent: Record<Tab, ComponentType> = {
      [Tab.About]: AboutTab,
      [Tab.General]: GeneralTab,
      [Tab.PDs]: PDsTab,
      [Tab.Ki]: KiTab,
      [Tab.Mystic]: MysticTab,
      [Tab.Psychic]: PsychicTab,
      [Tab.Inventory]: InventoryTab,
    };

    const [selectedTab, setSelectedTab] = useState<Tab>(Tab.About);

    const Component = mapTabToComponent[selectedTab];

    if (loadingCharacter || !character) return <Loading centered />;

    return (
      <CharacterContext.Provider value={{ character }}>
        <Wrapper>
          <CharacterHeader />
          {character.canBeEdited ? <CharacterToolbar /> : undefined}
          <StyledTabs>
            {Object.values(Tab).map((tab) => {
              const handleSetTab = () => setSelectedTab(tab);

              return (
                <Tabs.Button
                  key={tab}
                  name={tab.toString()}
                  selected={tab === selectedTab}
                  onClick={handleSetTab}
                />
              );
            })}
          </StyledTabs>
          <Container>
            <Component />
          </Container>

          {character?.canBeEdited ? (
            <FabContainer>
              <Fab
                aria-label="save"
                size="small"
                onClick={handleSaveCharacterClick}
              >
                {savingCharacter ? (
                  <Loading size={2} color="primary-light" />
                ) : (
                  <CheckCircle />
                )}
              </Fab>
            </FabContainer>
          ) : undefined}
        </Wrapper>
        <ConfirmModal />
      </CharacterContext.Provider>
    );
  },
);
