import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';
import { DisadvantageLevel } from '../../../../aggregations/disadvantage/CommonDisadvantages';
import {
  Disadvantage,
  DisadvantageId,
  DisadvantageType,
  DisadvantageVariation,
} from '../../../../aggregations/disadvantage/Disadvantage.types';

@model('Disadvantage')
export class DisadvantageModel<T extends string = string> extends Model(<
  T extends string = string,
>() => ({
  id: prop<DisadvantageId | undefined>().withSetter(),

  type: prop<DisadvantageType>().withSetter(),

  name: prop<string>().withSetter(),

  levels: prop<DisadvantageLevel[]>().withSetter(),
  levelId: prop<string | undefined>().withSetter(),

  variations: prop<DisadvantageVariation<T>[] | undefined>().withSetter(),
  variationId: prop<DisadvantageId | undefined>().withSetter(),
}))<T> {
  @computed
  get variation(): DisadvantageVariation<T> | undefined {
    return this.variations?.find((v) => v.id === this.variationId);
  }

  @computed
  get level(): DisadvantageLevel | undefined {
    return this.levels.find((l) => l.id === this.levelId);
  }

  @computed
  get isCustom() {
    return this.id === undefined;
  }
}

export const createModelFromDisadvantage = (disadvantage: Disadvantage) =>
  new DisadvantageModel({
    id: disadvantage.id,
    name: disadvantage.name,
    type: disadvantage.type,
    levels: disadvantage.levels,
    levelId: disadvantage.levels[0]?.id,
    variations: disadvantage.variations,
    variationId: disadvantage.variations?.[0]?.id,
  });
