import { useModal } from '../../../shared/application/design-system/components/Modal/hooks/useModal';
import { CreateFolderModal } from '../components/CreateFolderModal/CreateFolderModal';
import { useParams } from 'react-router-dom';

export const useCreateFolderModal = () => {
  const { Modal, openModal, closeModal } = useModal();

  const { folderId } = useParams<{ folderId: string }>();

  return {
    openCreateFolderModal: async () => {
      return new Promise<boolean>((resolve) => {
        const handleSave = () => {
          closeModal();

          resolve(true);
        };

        const handleCancel = () => {
          closeModal();

          resolve(false);
        };

        openModal(
          <CreateFolderModal
            parentFolderId={folderId}
            onSave={handleSave}
            onCancel={handleCancel}
          />,
        );
      });
    },
    closeConfirmModal: closeModal,
    CreateFolderModal: Modal,
  };
};
