import styled from 'styled-components';
import { BasicParagraph } from '../Texts/Texts.styled';
import { ButtonSize, ButtonVariant } from './Button.types';

export const StyledParagraph = styled(BasicParagraph)({});

type StyledButtonProps = {
  $size: ButtonSize;
  $rounded: boolean;
  $variant: ButtonVariant;

  $disabled?: boolean;
};

export const StyledButton = styled.div<StyledButtonProps>(
  ({
    theme: { color, size, font, borderRadius, transition },
    $size,
    $rounded,
    $variant,
    $disabled,
  }) => {
    const getHeight = () => {
      switch ($size) {
        case ButtonSize.Small:
          return size.units(2.5);
        case ButtonSize.Medium:
        case ButtonSize.Big:
          return size.units(5);
      }
    };

    const getWidth = () => {
      switch ($size) {
        case ButtonSize.Small:
          return size.units(10);
        case ButtonSize.Medium:
          return size.units(20);
        case ButtonSize.Big:
          return '100%';
      }
    };

    const getBorderRadius = () => {
      if ($rounded) {
        return size.units(6);
      }
      switch ($size) {
        case ButtonSize.Small:
          return borderRadius.small;
        case ButtonSize.Medium:
        case ButtonSize.Big:
          return borderRadius.medium;
      }
    };

    const getPadding = () => {
      switch ($size) {
        case ButtonSize.Small:
          return `${size.units(0.5)} ${size.units(1)}`;
        case ButtonSize.Medium:
        case ButtonSize.Big:
          return `0 ${size.units(1.5)}`;
      }
    };

    const getBackgroundColor = () => {
      if ($disabled) {
        return color.primary(0.8);
      }

      if ($variant === ButtonVariant.Outline) {
        return undefined;
      }

      return color.primary();
    };

    const getBackgroundColorOnHover = () => {
      if ($disabled) {
        return undefined;
      }

      if ($variant === ButtonVariant.Outline) {
        return color.silver(0.2);
      }

      return color.primaryDark();
    };

    const getFontColor = () => {
      if ($disabled) {
        return color.silver();
      }

      if ($variant === ButtonVariant.Outline) {
        return color.primary();
      }

      return color.white();
    };

    const getBorder = () => {
      if ($variant === ButtonVariant.Outline) {
        return `1px solid ${color.primary()}`;
      }

      return undefined;
    };

    const getBorderOnHover = () => {
      if ($disabled) {
        return undefined;
      }

      if ($variant === ButtonVariant.Outline) {
        return `1px solid ${color.primaryDark()}`;
      }

      return undefined;
    };

    return {
      boxSizing: 'border-box',

      minHeight: getHeight(),
      minWidth: getWidth(),

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      padding: getPadding(),
      borderRadius: getBorderRadius(),
      backgroundColor: getBackgroundColor(),
      border: getBorder(),
      cursor: $disabled ? 'not-allowed' : 'pointer',
      transition: transition.standard,
      '&:hover': {
        backgroundColor: getBackgroundColorOnHover(),
        border: getBorderOnHover(),
      },

      [BasicParagraph]: {
        color: getFontColor(),
        fontWeight: font.weight.semiBold,
      },
    };
  },
);
