import { model, Model, prop } from 'mobx-keystone';
import { ComposureSkillModel } from './fields/ComposureSkillModel';
import { FeatsOfStrengthSkillModel } from './fields/FeatsOfStrengthSkillModel';
import { WithstandPainSkillModel } from './fields/WithstandPainSkillModel';

@model('Character/Secondaries/VigorSkills')
export class VigorSkillsModel extends Model({
  composure: prop(() => new ComposureSkillModel({})),
  featsOfStrength: prop(() => new FeatsOfStrengthSkillModel({})),
  withstandPain: prop(() => new WithstandPainSkillModel({})),
}) {
  get usedPhysicalNaturalBonusPoints(): number {
    return this.featsOfStrength.naturalBonusPoints.base;
  }

  get usedMentalNaturalBonusPoints(): number {
    return (
      this.composure.naturalBonusPoints.base +
      this.withstandPain.naturalBonusPoints.base
    );
  }

  get usedNaturalAbilityPoints(): number {
    return (
      this.composure.naturalAbilityPoints +
      this.featsOfStrength.naturalAbilityPoints +
      this.withstandPain.naturalAbilityPoints
    );
  }

  get usedNovelBonusPoints(): number {
    return (
      this.composure.novelBonus +
      this.featsOfStrength.novelBonus +
      this.withstandPain.novelBonus
    );
  }
}
