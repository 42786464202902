import styled from 'styled-components';
import {
  BigTitle,
  SmallParagraph,
} from '../../../../../../shared/application/design-system/components/Texts/Texts.styled';
import { Input } from '../../../../../../shared/application/design-system/components/Input/Input';

export const InputContainerLabel = styled(SmallParagraph)(
  ({ theme: { font, color } }) => ({
    color: color.primary(),
    fontWeight: font.weight.bold,
  }),
);

export const InputLabel = styled(SmallParagraph)(({ theme: { font } }) => ({
  fontWeight: font.weight.bold,
}));

export const StyledInput = styled(Input)(({ theme: { size } }) => ({
  maxWidth: size.units(40),
}));

export const InputContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',

  marginBottom: size.units(2),

  [`& > ${InputContainerLabel}`]: {
    marginBottom: size.units(1.5),
  },

  [`& > ${InputLabel}`]: {
    marginTop: size.units(1),
    marginBottom: size.units(1),
  },
}));

export const Wrapper = styled.div(({ theme: { size, mixins } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',

  paddingLeft: size.units(2),
  paddingRight: size.units(2),

  boxSizing: 'border-box',

  ...mixins.responsive.xs.downward({
    alignItems: 'center',
  }),

  width: '100%',
  height: '100%',

  [`& > ${BigTitle}`]: {
    marginBottom: size.units(3.5),
  },
}));
