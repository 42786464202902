import { ReactNode } from 'react';
import { TabButton } from './components/TabButton/TabButton';
import { TabsContainer } from './Tabs.styled';

type TabsProps = {
  className?: string;
  children: ReactNode[];

  centered?: boolean;
};

export const Tabs = ({ children, centered, className }: TabsProps) => {
  return (
    <TabsContainer className={className} $centered={centered}>
      {children}
    </TabsContainer>
  );
};

Tabs.Button = TabButton;
