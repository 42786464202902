import styled from 'styled-components';

export const Image = styled.img(({ theme: { size } }) => ({
  width: size.units(12),
  height: size.units(12),

  justifySelf: 'center',
}));

export const Content = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',

  justifyContent: 'space-evenly',

  gap: size.units(2),

  height: '100%',
}));

export const ButtonContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'row',

  justifySelf: 'flex-end',

  gap: size.units(2),
}));

export const Wrapper = styled.div(({ theme: { size, mixins } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: size.units(2),

  width: size.units(40),
  height: size.units(30),

  [Image]: {
    marginTop: size.units(3),
  },

  ...mixins.responsive.xs.downward({
    width: size.units(40),
    height: size.units(20),
  }),
}));
