import { model } from 'mobx-keystone';
import { PDFieldModel } from '../PDFieldModel';

@model('PsychicProjectionField')
export class PsychicProjectionPDFieldModel extends PDFieldModel {
  get pdsCost(): number {
    return this.template.psychicProjectionPDsCost;
  }

  get perCategory(): undefined {
    return undefined;
  }
}
