import { HitType } from '../../character/model/types/HitType';

export enum WeaponEquippedHandType {
  OneHanded = 'One handed',
  TwoHanded = 'Two handed',
}

export const weaponEquippedHandTypeTranslations: Record<
  WeaponEquippedHandType,
  string
> = {
  [WeaponEquippedHandType.OneHanded]: 'Una mano',
  [WeaponEquippedHandType.TwoHanded]: 'Dos manos',
};

export enum WeaponKnowledgeType {
  Known = 'Known',
  Similar = 'Similar',
  Mixed = 'Mixed',
  Different = 'Different',
}

export const weaponKnowledgeTypeTranslations: Record<
  WeaponKnowledgeType,
  string
> = {
  [WeaponKnowledgeType.Known]: 'Conocida',
  [WeaponKnowledgeType.Similar]: 'Similar',
  [WeaponKnowledgeType.Mixed]: 'Mixta',
  [WeaponKnowledgeType.Different]: 'Distinta',
};

export const weaponHitTypeTranslations: Record<HitType, string> = {
  [HitType.Cut]: 'Filo',
  [HitType.Impact]: 'Contundente',
  [HitType.Thrust]: 'Penetrante',
  [HitType.Heat]: 'Calor',
  [HitType.Electricity]: 'Electricidad',
  [HitType.Cold]: 'Frio',
  [HitType.Energy]: 'Energía',
};

export enum WeaponManageabilityType {
  OneHand = 'One hand',
  TwoHand = 'Two hands',
  OneOrTwoHand = 'One or two hands',
}

export const weaponManageabilityTypeTranslations: Record<
  WeaponManageabilityType,
  string
> = {
  [WeaponManageabilityType.OneHand]: 'Una mano',
  [WeaponManageabilityType.TwoHand]: 'Dos manos',
  [WeaponManageabilityType.OneOrTwoHand]: 'Una o dos manos',
};

export enum WeaponShotType {
  Shot = 'Shot',
  Throw = 'Throw',
}

export const weaponShotTypeTranslations: Record<WeaponShotType, string> = {
  [WeaponShotType.Shot]: 'Disparo',
  [WeaponShotType.Throw]: 'Lanzamiento',
};

export enum WeaponSize {
  Small = 'Small',
  Medium = 'Medium',
  Big = 'Big',
}

export const weaponSizeTranslations: Record<WeaponSize, string> = {
  [WeaponSize.Small]: 'Pequeño',
  [WeaponSize.Medium]: 'Mediano',
  [WeaponSize.Big]: 'Grande',
};

export enum WeaponSizeProportion {
  Normal = 'Normal',
  Enormous = 'Enormous',
  Giant = 'Giant',
}

export const weaponSizeProportionTranslations: Record<
  WeaponSizeProportion,
  string
> = {
  [WeaponSizeProportion.Normal]: 'Normal',
  [WeaponSizeProportion.Enormous]: 'Enorme',
  [WeaponSizeProportion.Giant]: 'Gigante',
};

export enum WeaponType {
  Pole = 'Pole',
  GreatSword = 'GreatSword',
  Mace = 'Mace',
  Launch = 'Launch',
  Sword = 'Sword',
  Rope = 'Rope',
  Short = 'Short',
  Axe = 'Axe',
  Shield = 'Shield',
  Projectiles = 'Projectiles',
  Natural = 'Natural',
}

export const weaponTypeTranslations: Record<WeaponType, string> = {
  [WeaponType.Axe]: 'Hacha',
  [WeaponType.GreatSword]: 'Mandoble',
  [WeaponType.Launch]: 'Lanzamiento',
  [WeaponType.Mace]: 'Maza',
  [WeaponType.Natural]: 'Natural',
  [WeaponType.Pole]: 'Asta',
  [WeaponType.Projectiles]: 'Proyectiles',
  [WeaponType.Rope]: 'Cuerda',
  [WeaponType.Shield]: 'Escudo',
  [WeaponType.Short]: 'Corta',
  [WeaponType.Sword]: 'Espada',
};
