import styled from 'styled-components';

export const Wrapper = styled.div(({ theme: { size, color } }) => ({
  width: '100%',
  height: '100%',

  '.ql-editor': {
    minHeight: size.units(20),
  },

  '.ql-toolbar.ql-snow': {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: `1px solid ${color.primaryLight()}`,
  },

  '.ql-container.ql-snow': {
    border: 'none',
  },

  border: `1px solid ${color.black()}`,
  borderRadius: size.units(0.5),
}));
