import { observer } from 'mobx-react';

import {
  ElegiblePredefinedWeapons,
  PredefinedWeapon,
} from '../../../../../../../../../../domain/aggregations/weapon/PredefinedWeapons';
import { createWeaponItemModelFromPredefinedWeapon } from '../../../../../../../../../../domain/aggregations/weapon/WeaponItemModel';
import { PenaltyRegenerationPeriod } from '../../../../../../../../../../domain/character/model/parts/regeneration/Regeneration.types';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import DoubleInput from '../../../../common/DoubleInput/DoubleInput';
import { Input } from '../../../../common/Input/Input';
import { useCharacterMenu } from '../../../../common/CharacterMenu/useCharacterMenu';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';
import {
  ATsContainer,
  Info,
  MovementInfoContainer,
} from './CharacterGenericFields.styled';
import { MenuItem } from '../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';
import { Checkbox } from '../../../../common/Checkbox/Checkbox';
import React from 'react';

export const CharacterGenericFields = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { handleMenuOpen: handleWeaponsMenuOpen, Menu: WeaponsMenu } =
    useCharacterMenu<PredefinedWeapon | undefined>();

  const weapons: MenuItem<PredefinedWeapon | undefined>[] = [
    undefined,
    ...ElegiblePredefinedWeapons,
  ].map((w) => {
    if (!w) {
      return {
        key: 'empty',
        name: 'Desarmado',
        value: undefined,
        onClick: () => {
          character.setDevelopedWeapon(undefined);
        },
      };
    }

    const item: MenuItem<PredefinedWeapon> = {
      key: w.id,
      name: w.name,
      value: w,
      onClick: () => {
        character.setDevelopedWeapon(
          createWeaponItemModelFromPredefinedWeapon(w),
        );
      },
    };

    return item;
  });

  const lifeRegenerationInfo = character.regeneration.info;
  const developedWeapon = character.developedWeapon;

  const handleIsAccumulationCreatureCheckbox = () =>
    character.options.setIsAccumulationCreature(
      !character.options.isAccumulationCreature,
    );

  return (
    <>
      <Section maxHeight="fit">
        <DoubleInput label="Presencia" field={character.presence} />
        <Section.Divider />
        <Section.Title>Iniciativa</Section.Title>
        <DoubleInput label="Iniciativa base" field={character.initiative} />
        <Input
          label="Iniciativa con equipo"
          value={character.initiative.withEquipment}
          disabled
        />

        <Input
          label="Desarmado"
          value={character.initiative.unarmed}
          disabled
        />
        {character.inventory.weapons.map((w) => (
          <Input
            key={w.id}
            label={`Con ${w.name}`}
            value={character.initiative.calculateWithWeapon(w)}
            disabled
          />
        ))}
        <Section.Divider />
        <Section.Title>Daño</Section.Title>
        <Input label="Desarmado" value={character.damage.unarmed} disabled />
        {character.inventory.weapons.map((w) => (
          <Input
            key={w.id}
            label={`Con ${w.name}`}
            value={character.damage.calculateWithWeapon(w)}
            disabled
          />
        ))}
        <DoubleInput label="Bonus en daño" field={character.damage} />
      </Section>
      <Section maxHeight="fit">
        <Section.Title>TA total</Section.Title>
        <ATsContainer>
          <DoubleInput
            labelPosition={DoubleInput.LabelPosition.Top}
            size={DoubleInput.Size.Small}
            orientation={DoubleInput.Orientation.Vertical}
            label="Fil"
            field={character.at.cut}
          />
          <DoubleInput
            labelPosition={DoubleInput.LabelPosition.Top}
            size={DoubleInput.Size.Small}
            orientation={DoubleInput.Orientation.Vertical}
            label="Imp"
            field={character.at.impact}
          />
          <DoubleInput
            labelPosition={DoubleInput.LabelPosition.Top}
            size={DoubleInput.Size.Small}
            orientation={DoubleInput.Orientation.Vertical}
            label="Pen"
            field={character.at.thrust}
          />
          <DoubleInput
            labelPosition={DoubleInput.LabelPosition.Top}
            size={DoubleInput.Size.Small}
            orientation={DoubleInput.Orientation.Vertical}
            label="Cal"
            field={character.at.heat}
          />
          <DoubleInput
            labelPosition={DoubleInput.LabelPosition.Top}
            size={DoubleInput.Size.Small}
            orientation={DoubleInput.Orientation.Vertical}
            label="Fri"
            field={character.at.cold}
          />
          <DoubleInput
            labelPosition={DoubleInput.LabelPosition.Top}
            size={DoubleInput.Size.Small}
            orientation={DoubleInput.Orientation.Vertical}
            label="Ele"
            field={character.at.electricity}
          />
          <DoubleInput
            labelPosition={DoubleInput.LabelPosition.Top}
            size={DoubleInput.Size.Small}
            orientation={DoubleInput.Orientation.Vertical}
            label="Ene"
            field={character.at.energy}
          />
        </ATsContainer>
      </Section>
      <Section maxHeight="fit">
        <Section.Title>Movimiento</Section.Title>
        <DoubleInput
          label="Tipo de movimiento"
          min={0}
          field={character.movementType}
        />
        <MovementInfoContainer>
          <Info variant="caption">
            Movim. máximo:{' '}
            <b>{character.movementType.info.maximumMovementInMeters}m</b>
          </Info>
          <Info variant="caption">
            Corriendo:{' '}
            <b>{character.movementType.info.runningMovementInMeters}m</b>
          </Info>
          <Info variant="caption">
            Pasivo:{' '}
            <b>{character.movementType.info.passiveMovementInMeters}m</b>
          </Info>
        </MovementInfoContainer>
      </Section>
      <Section maxHeight="fit">
        <Section.Title>Modificadores</Section.Title>
        <DoubleInput label="Cansancio" min={0} field={character.fatigue} />
        <DoubleInput
          label="A todas las acciones"
          field={character.modifiers.allActions}
        />
        <DoubleInput
          label="A acciones físicas"
          field={character.modifiers.allPhysicalActions}
        />
      </Section>
      <Section maxHeight="fit">
        <Section.Title>Vida</Section.Title>
        <Input
          label="Puntos de vida"
          value={character.lifePoints.final}
          disabled
        />
        <DoubleInput
          label="Regeneración"
          min={0}
          field={character.regeneration}
        />
        <MovementInfoContainer>
          <Info variant="caption">
            Descansando:{' '}
            <b>{`
                ${lifeRegenerationInfo.lifeRegeneratedResting.value} / ${lifeRegenerationInfo.lifeRegeneratedResting.per}
              `}</b>
          </Info>
          {lifeRegenerationInfo.lifeRegeneratedWithoutResting ? (
            <Info variant="caption">
              Sin descansar:{' '}
              <b>{`
                  ${lifeRegenerationInfo.lifeRegeneratedWithoutResting.value} / ${lifeRegenerationInfo.lifeRegeneratedWithoutResting.per}
                `}</b>
            </Info>
          ) : undefined}
          <Info variant="caption">
            Reducción de penalizador:{' '}
            <b>
              {lifeRegenerationInfo.penaltyReduction.per !==
              PenaltyRegenerationPeriod.Instant
                ? `
                ${lifeRegenerationInfo.penaltyReduction.value} / ${lifeRegenerationInfo.penaltyReduction.per}
              `
                : 'No afecta'}
            </b>
          </Info>
          {lifeRegenerationInfo.specialCapabilities ? (
            <Info variant="caption" fontWeight="bold" textAlign="justify">
              {lifeRegenerationInfo.specialCapabilities}
            </Info>
          ) : undefined}
        </MovementInfoContainer>
      </Section>
      <Section maxHeight="fit">
        <Section.Title>Arma desarrollada</Section.Title>
        <Table>
          <Table.Header>
            <Table.Header.Cell />
          </Table.Header>
          <Table.Row>
            <Table.Row.Cell
              onClick={handleWeaponsMenuOpen(
                developedWeapon?.template ?? ElegiblePredefinedWeapons[0],
              )}
            >
              {developedWeapon?.name ?? 'Desarmado'}
            </Table.Row.Cell>
          </Table.Row>
        </Table>
        <WeaponsMenu items={weapons} />
      </Section>
      <Section maxHeight="fit">
        <Section.Title>Otras opciones</Section.Title>
        <Table>
          <Table.Header>
            <Table.Header.Cell />
            <Table.Header.Cell />
          </Table.Header>
          <Table.Row>
            <Table.Row.Cell>Ser de acumulación</Table.Row.Cell>
            <Table.Row.Cell>
              <Checkbox
                isChecked={character.options.isAccumulationCreature}
                onClick={handleIsAccumulationCreatureCheckbox}
              />
            </Table.Row.Cell>
          </Table.Row>
        </Table>
        <WeaponsMenu items={weapons} />
      </Section>
    </>
  );
});
