import { HitType } from '../../character/model/types/HitType';
import { WeaponType } from './Weapon.types';

export type PredefinedWeapon = {
  id: string;
  name: string;
  damage: number;
  initiative: number;
  integrity: number;
  breaking: number;
  presence: number;
  ownStrength: number;
  special: string;
  cadence?: number;
  range?: number;
  reload?: number;
  requiredStrength: {
    oneHand: number;
    twoHands: number;
  };
  critic: {
    primary: HitType;
    secondary?: HitType;
  };
  types: WeaponType[];
};

export const UnarmedWeapon: PredefinedWeapon = {
  id: '0',
  name: 'Desarmado',
  damage: 0,
  initiative: 0,
  integrity: 0,
  breaking: 0,
  presence: 0,
  ownStrength: 0,
  special: 'A 1 o 2 manos',
  requiredStrength: {
    oneHand: 0,
    twoHands: 0,
  },
  critic: {
    primary: HitType.Impact,
  },
  types: [WeaponType.Natural],
};

export const HalberdWeapon: PredefinedWeapon = {
  id: '1',
  name: 'Alabarda',
  damage: 60,
  initiative: -15,
  integrity: 15,
  breaking: 4,
  presence: 20,
  ownStrength: 0,
  special: 'A 1 o 2 manos',
  requiredStrength: {
    oneHand: 11,
    twoHands: 6,
  },
  critic: {
    primary: HitType.Cut,
    secondary: HitType.Impact,
  },
  types: [WeaponType.Pole, WeaponType.GreatSword],
};

export const HarpoonWeapon: PredefinedWeapon = {
  id: '2',
  name: 'Arpón',
  damage: 35,
  initiative: -5,
  integrity: 11,
  breaking: 0,
  presence: 15,
  cadence: 100,
  range: 20,
  ownStrength: 0,
  special: 'A 1 o 2 manos, Lanzable (100/XXXm)',
  requiredStrength: {
    oneHand: 5,
    twoHands: 5,
  },
  critic: {
    primary: HitType.Thrust,
  },
  types: [WeaponType.Pole],
};

export const CombatStaffWeapon: PredefinedWeapon = {
  id: '3',
  name: 'Bastón de Combate',
  damage: 25,
  initiative: 15,
  integrity: 10,
  breaking: -1,
  presence: 15,
  ownStrength: 0,
  special: '-',
  requiredStrength: {
    oneHand: 5,
    twoHands: 5,
  },
  critic: {
    primary: HitType.Impact,
  },
  types: [WeaponType.Pole],
};

export const ShortSwordWeapon: PredefinedWeapon = {
  id: '24',
  name: 'Espada corta',
  damage: 40,
  initiative: 15,
  integrity: 12,
  breaking: 1,
  presence: 20,
  ownStrength: 0,
  special: 'Precisa',
  requiredStrength: {
    oneHand: 4,
    twoHands: 4,
  },
  critic: {
    primary: HitType.Thrust,
    secondary: HitType.Cut,
  },
  types: [WeaponType.Short],
};

export const HandCrossbowWeapon: PredefinedWeapon = {
  id: '118',
  name: 'Ballesta de mano',
  damage: 0,
  initiative: 10,
  integrity: 5,
  breaking: -4,
  presence: 15,
  reload: 2,
  range: 30,
  ownStrength: 5,
  special: 'Ver',
  requiredStrength: {
    oneHand: 3,
    twoHands: 3,
  },
  critic: {
    primary: HitType.Impact,
  },
  types: [WeaponType.Projectiles],
};

export const ClubWeapon: PredefinedWeapon = {
  id: '31',
  name: 'Garrote',
  damage: 30,
  initiative: 0,
  integrity: 11,
  breaking: -2,
  presence: 15,
  ownStrength: 0,
  special: '-',
  requiredStrength: {
    oneHand: 5,
    twoHands: 5,
  },
  critic: {
    primary: HitType.Impact,
  },
  types: [WeaponType.Mace],
};

export const BlowgunWeapon: PredefinedWeapon = {
  id: '121',
  name: 'Cerbatana',
  damage: 0,
  initiative: -10,
  integrity: 3,
  breaking: -3,
  presence: 15,
  reload: 1,
  range: 50,
  ownStrength: 0,
  special: '-',
  requiredStrength: {
    oneHand: 4,
    twoHands: 4,
  },
  critic: {
    primary: HitType.Impact,
  },
  types: [WeaponType.Projectiles],
};

export const StyletWeapon: PredefinedWeapon = {
  id: '26',
  name: 'Estilete',
  damage: 25,
  initiative: 20,
  integrity: 8,
  breaking: -3,
  presence: 15,
  cadence: 30,
  range: 30,
  ownStrength: 0,
  special: 'Lanzable (30/XXXm), Precisa',
  requiredStrength: {
    oneHand: 3,
    twoHands: 3,
  },
  critic: {
    primary: HitType.Thrust,
  },
  types: [WeaponType.Short],
};

export const WarAxeWeapon = {
  id: '39',
  name: 'Hacha de guerra',
  damage: 70,
  initiative: -30,
  integrity: 15,
  breaking: 5,
  presence: 25,
  cadence: 100,
  range: 5,
  ownStrength: 0,
  special: 'Lanzable (100/XXXm)',
  requiredStrength: {
    oneHand: 7,
    twoHands: 7,
  },
  critic: {
    primary: HitType.Cut,
    secondary: HitType.Impact,
  },
  types: [WeaponType.Axe],
};

export const TwoHandsAxeWeapon = {
  id: '38',
  name: 'Hacha a dos manos',
  damage: 100,
  initiative: -70,
  integrity: 17,
  breaking: 7,
  presence: 30,
  ownStrength: 0,
  special: 'A 1 o 2 manos',
  requiredStrength: {
    oneHand: 11,
    twoHands: 9,
  },
  critic: {
    primary: HitType.Cut,
    secondary: HitType.Impact,
  },
  types: [WeaponType.Axe, WeaponType.GreatSword],
};

export const GreatSwordWeapon = {
  id: '21',
  name: 'Espada a dos manos',
  damage: 90,
  initiative: -60,
  integrity: 18,
  breaking: 6,
  presence: 30,
  ownStrength: 0,
  special: 'A 1 o 2 manos',
  requiredStrength: {
    oneHand: 10,
    twoHands: 8,
  },
  critic: {
    primary: HitType.Cut,
    secondary: HitType.Impact,
  },
  types: [WeaponType.GreatSword],
};

export const BastardSwordWrapon = {
  id: '23',
  name: 'Espada bastarda',
  damage: 70,
  initiative: -30,
  integrity: 15,
  breaking: 5,
  presence: 25,
  ownStrength: 0,
  special: 'A 1 o 2 manos',
  requiredStrength: {
    oneHand: 9,
    twoHands: 7,
  },
  critic: {
    primary: HitType.Cut,
    secondary: HitType.Impact,
  },
  types: [WeaponType.Sword, WeaponType.GreatSword],
};

export const HeavyMaceWeapon = {
  id: '58',
  name: 'Maza pesada',
  damage: 60,
  initiative: -15,
  integrity: 16,
  breaking: 5,
  presence: 15,
  ownStrength: 0,
  special: 'A 1 o 2 manos',
  requiredStrength: {
    oneHand: 10,
    twoHands: 6,
  },
  critic: {
    primary: HitType.Impact,
  },
  types: [WeaponType.Mace, WeaponType.GreatSword],
};

export const LongSwordWeapon = {
  id: '25',
  name: 'Espada larga',
  damage: 50,
  initiative: 0,
  integrity: 13,
  breaking: 3,
  presence: 25,
  ownStrength: 0,
  special: '-',
  requiredStrength: {
    oneHand: 6,
    twoHands: 6,
  },
  critic: {
    primary: HitType.Cut,
  },
  types: [WeaponType.Sword],
};

export const KnightSpearWeapon = {
  id: '48',
  name: 'Lanza de caballería',
  damage: 80,
  initiative: -30,
  integrity: 12,
  breaking: 7,
  presence: 25,
  ownStrength: 0,
  special: 'Ver',
  requiredStrength: {
    oneHand: 8,
    twoHands: 8,
  },
  critic: {
    primary: HitType.Thrust,
  },
  types: [WeaponType.Pole],
};

export const MaceWeapon = {
  id: '57',
  name: 'Maza ligera',
  damage: 40,
  initiative: 0,
  integrity: 14,
  breaking: 4,
  presence: 15,
  ownStrength: 0,
  special: '-',
  requiredStrength: {
    oneHand: 6,
    twoHands: 6,
  },
  critic: {
    primary: HitType.Impact,
  },
  types: [WeaponType.Mace],
};

export const MediumShieldWeapon = {
  id: '73',
  name: 'Escudo medio',
  damage: 20,
  initiative: -25,
  integrity: 16,
  breaking: 0,
  presence: 25,
  ownStrength: 0,
  special: 'Escudo. -20 HP/-10 HE si se usa para atacar.',
  requiredStrength: {
    oneHand: 7,
    twoHands: 7,
  },
  critic: {
    primary: HitType.Impact,
  },
  types: [WeaponType.Shield],
};

export const DaggerWeapon = {
  id: '16',
  name: 'Daga',
  damage: 30,
  initiative: 20,
  integrity: 10,
  breaking: -2,
  presence: 15,
  cadence: 50,
  range: 20,
  ownStrength: 0,
  special: 'Lanzable (50/XXXm), Precisa',
  requiredStrength: {
    oneHand: 3,
    twoHands: 3,
  },
  critic: {
    primary: HitType.Thrust,
    secondary: HitType.Cut,
  },
  types: [WeaponType.Short],
};

export const TurcusWeapon = {
  id: '68',
  name: 'Turcus',
  damage: 40,
  initiative: 0,
  integrity: 9,
  breaking: 2,
  presence: 20,
  cadence: 80,
  range: 30,
  ownStrength: 0,
  special: 'Lanzable (80/XXXm), Ver',
  requiredStrength: {
    oneHand: 6,
    twoHands: 6,
  },
  critic: {
    primary: HitType.Cut,
  },
  types: [WeaponType.Launch],
};

export const LongBowWeapon = {
  id: '115',
  name: 'Arco largo',
  damage: 0,
  initiative: -30,
  integrity: 8,
  breaking: -2,
  presence: 20,
  reload: 1,
  range: 60,
  ownStrength: 0,
  special: 'A 2 manos',
  requiredStrength: {
    oneHand: 0,
    twoHands: 7,
  },
  critic: {
    primary: HitType.Impact,
  },
  types: [WeaponType.Projectiles],
};

export const ScimitarWeapon = {
  id: '13',
  name: 'Cimitarra',
  damage: 50,
  initiative: -5,
  integrity: 13,
  breaking: 4,
  presence: 20,
  ownStrength: 0,
  special: '-',
  requiredStrength: {
    oneHand: 5,
    twoHands: 5,
  },
  critic: {
    primary: HitType.Cut,
  },
  types: [WeaponType.Sword],
};

export const SpearWeapon = {
  id: '47',
  name: 'Lanza',
  damage: 40,
  initiative: 5,
  integrity: 13,
  breaking: 2,
  presence: 25,
  cadence: 80,
  range: 30,
  ownStrength: 0,
  special: 'Lanzable (80/XXXm), A 1 o 2 manos',
  requiredStrength: {
    oneHand: 9,
    twoHands: 4,
  },
  critic: {
    primary: HitType.Thrust,
  },
  types: [WeaponType.Pole],
};

export const NetWeapon = {
  id: '63',
  name: 'Red',
  damage: 5,
  initiative: 0,
  integrity: 13,
  breaking: -4,
  presence: 15,
  cadence: 100,
  range: 5,
  ownStrength: 0,
  special: 'Lanzable (100/XXXm), Presa (FUE 10), Ver',
  requiredStrength: {
    oneHand: 4,
    twoHands: 4,
  },
  critic: {
    primary: HitType.Impact,
    secondary: HitType.Cut,
  },
  types: [WeaponType.Rope],
};

export const BucklerShieldWeapon = {
  id: '74',
  name: 'Rodela',
  damage: 15,
  initiative: -15,
  integrity: 14,
  breaking: 0,
  presence: 20,
  ownStrength: 0,
  special: 'Escudo. -10 HP/-5 HE si se usa para atacar.',
  requiredStrength: {
    oneHand: 5,
    twoHands: 5,
  },
  critic: {
    primary: HitType.Impact,
  },
  types: [WeaponType.Shield],
};

export const TridentWeapon = {
  id: '67',
  name: 'Tridente',
  damage: 40,
  initiative: -10,
  integrity: 12,
  breaking: 3,
  presence: 15,
  cadence: 100,
  range: 15,
  ownStrength: 0,
  special: 'Lanzable (100/XXXm), A 1 o 2 manos',
  requiredStrength: {
    oneHand: 6,
    twoHands: 6,
  },
  critic: {
    primary: HitType.Thrust,
  },
  types: [WeaponType.Pole],
};

export const WhipWeapon = {
  id: '49',
  name: 'Látigo',
  damage: 35,
  initiative: -20,
  integrity: 9,
  breaking: -3,
  presence: 20,
  ownStrength: 0,
  special: 'Compleja, Presa (FUE 8)',
  requiredStrength: {
    oneHand: 4,
    twoHands: 4,
  },
  critic: {
    primary: HitType.Cut,
    secondary: HitType.Impact,
  },
  types: [WeaponType.Rope],
};

export const JavelinWeapon = {
  id: '42',
  name: 'Jabalina',
  damage: 35,
  initiative: 5,
  integrity: 10,
  breaking: -2,
  presence: 20,
  cadence: 80,
  range: 30,
  ownStrength: 0,
  special: 'Lanzable (100/XXXm)',
  requiredStrength: {
    oneHand: 4,
    twoHands: 4,
  },
  critic: {
    primary: HitType.Thrust,
  },
  types: [WeaponType.Pole],
};

export const BowWeapon = {
  id: '113',
  name: 'Arco corto',
  damage: 0,
  initiative: -10,
  integrity: 7,
  breaking: -3,
  presence: 15,
  reload: 1,
  range: 40,
  ownStrength: 0,
  special: 'A 2 manos',
  requiredStrength: {
    oneHand: 0,
    twoHands: 4,
  },
  critic: {
    primary: HitType.Impact,
  },
  types: [WeaponType.Projectiles],
};

export const BolasWeapon = {
  id: '7',
  name: 'Boleadoras',
  damage: 30,
  initiative: -10,
  integrity: 6,
  breaking: 2,
  presence: 15,
  cadence: 80,
  range: 20,
  ownStrength: 0,
  special: 'Presa (FUE 10), Compleja, Ver, Lanzable (80/XXXm)',
  requiredStrength: {
    oneHand: 5,
    twoHands: 5,
  },
  critic: {
    primary: HitType.Impact,
  },
  types: [WeaponType.Launch],
};

export const CrossbowWeapon = {
  id: '117',
  name: 'Ballesta',
  damage: 0,
  initiative: 0,
  integrity: 8,
  breaking: -2,
  presence: 20,
  reload: 2,
  range: 60,
  ownStrength: 8,
  special: '1 o 2 manos, Ver',
  requiredStrength: {
    oneHand: 8,
    twoHands: 4,
  },
  critic: {
    primary: HitType.Impact,
  },
  types: [WeaponType.Projectiles],
};

export const KatanaWeapon = {
  id: '80',
  name: 'Katana',
  damage: 50,
  initiative: 0,
  integrity: 11,
  breaking: 3,
  presence: 40,
  ownStrength: 0,
  special: 'A 1 o 2 manos',
  requiredStrength: {
    oneHand: 6,
    twoHands: 5,
  },
  critic: {
    primary: HitType.Cut,
  },
  types: [WeaponType.Sword],
};

export const TantoWeapon = {
  id: '90',
  name: 'Tanto',
  damage: 40,
  initiative: 20,
  integrity: 9,
  breaking: 1,
  presence: 40,
  ownStrength: 0,
  special: 'Precisa',
  requiredStrength: {
    oneHand: 3,
    twoHands: 3,
  },
  critic: {
    primary: HitType.Cut,
  },
  types: [WeaponType.Short],
};

export const ClawsWeapon = {
  id: '79',
  name: 'Garras',
  damage: 30,
  initiative: 15,
  integrity: 12,
  breaking: 2,
  presence: 15,
  ownStrength: 0,
  special: '-',
  requiredStrength: {
    oneHand: 4,
    twoHands: 4,
  },
  critic: {
    primary: HitType.Cut,
    secondary: HitType.Thrust,
  },
  types: [WeaponType.Short],
};

export const ShurikenWeapon = {
  id: '89',
  name: 'Shuriken',
  damage: 25,
  initiative: 20,
  integrity: 10,
  breaking: 1,
  presence: 20,
  cadence: 30,
  range: 20,
  ownStrength: 0,
  special: 'Lanzable (30/XXXm)',
  requiredStrength: {
    oneHand: 4,
    twoHands: 4,
  },
  critic: {
    primary: HitType.Cut,
    secondary: HitType.Thrust,
  },
  types: [WeaponType.Short],
};

export const KusariGamaWeapon = {
  id: '83',
  name: 'Kusari-Gama',
  damage: 40,
  initiative: 5,
  integrity: 12,
  breaking: 4,
  presence: 25,
  ownStrength: 0,
  special: 'A 2 manos, Presa (FUE 8), Ver',
  requiredStrength: {
    oneHand: 5,
    twoHands: 5,
  },
  critic: {
    primary: HitType.Cut,
    secondary: HitType.Impact,
  },
  types: [WeaponType.Short, WeaponType.Rope],
};

export const RapierWeapon = {
  id: '27',
  name: 'Estoque',
  damage: 40,
  initiative: 15,
  integrity: 11,
  breaking: 2,
  presence: 20,
  ownStrength: 0,
  special: 'Precisa',
  requiredStrength: {
    oneHand: 4,
    twoHands: 4,
  },
  critic: {
    primary: HitType.Thrust,
    secondary: HitType.Cut,
  },
  types: [WeaponType.Sword],
};

export const FoilWeapon = {
  id: '29',
  name: 'Florete',
  damage: 35,
  initiative: 15,
  integrity: 9,
  breaking: -2,
  presence: 20,
  ownStrength: 0,
  special: 'Precisa',
  requiredStrength: {
    oneHand: 3,
    twoHands: 3,
  },
  critic: {
    primary: HitType.Thrust,
  },
  types: [WeaponType.Sword, WeaponType.Short],
};

export const BlockDaggerWeapon = {
  id: '17',
  name: 'Daga de parada',
  damage: 30,
  initiative: 15,
  integrity: 12,
  breaking: 0,
  presence: 20,
  cadence: 50,
  range: 15,
  ownStrength: 0,
  special: 'Lanzable (50/XXXm), Precisa, Traba',
  requiredStrength: {
    oneHand: 3,
    twoHands: 3,
  },
  critic: {
    primary: HitType.Thrust,
    secondary: HitType.Cut,
  },
  types: [WeaponType.Short],
};

export const SaberWeapon = {
  id: '64',
  name: 'Sable',
  damage: 45,
  initiative: 10,
  integrity: 12,
  breaking: 3,
  presence: 20,
  ownStrength: 0,
  special: '-',
  requiredStrength: {
    oneHand: 6,
    twoHands: 6,
  },
  critic: {
    primary: HitType.Cut,
    secondary: HitType.Thrust,
  },
  types: [WeaponType.Sword],
};

export const BodyShieldWeapon = {
  id: '72',
  name: 'Escudo corporal',
  damage: 25,
  initiative: -40,
  integrity: 18,
  breaking: 1,
  presence: 25,
  ownStrength: 0,
  special: 'Escudo. -30 HP/-15 HE si se usa para atacar.',
  requiredStrength: {
    oneHand: 10,
    twoHands: 10,
  },
  critic: {
    primary: HitType.Impact,
  },
  types: [WeaponType.Shield],
};

export const HookWeapon = {
  id: '30',
  name: 'Garfio',
  damage: 30,
  initiative: 10,
  integrity: 11,
  breaking: -2,
  presence: 15,
  ownStrength: 0,
  special: '-',
  requiredStrength: {
    oneHand: 3,
    twoHands: 3,
  },
  critic: {
    primary: HitType.Thrust,
  },
  types: [WeaponType.Short],
};

export const AxeWeapon = {
  id: '40',
  name: 'Hacha de mano',
  damage: 45,
  initiative: 0,
  integrity: 13,
  breaking: 4,
  presence: 15,
  cadence: 80,
  range: 10,
  ownStrength: 0,
  special: 'Lanzable (80/XXXm)',
  requiredStrength: {
    oneHand: 5,
    twoHands: 5,
  },
  critic: {
    primary: HitType.Cut,
  },
  types: [WeaponType.Axe],
};
export const ElegiblePredefinedWeapons: PredefinedWeapon[] = [
  HalberdWeapon,
  HarpoonWeapon,
  CombatStaffWeapon,
  {
    id: '4',
    name: 'Bec deCorbin',
    damage: 50,
    initiative: -10,
    integrity: 12,
    breaking: 2,
    presence: 20,
    ownStrength: 0,
    special: 'A 2 manos',
    requiredStrength: {
      oneHand: 0,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Thrust,
    },
    types: [WeaponType.Pole, WeaponType.Mace],
  },
  {
    id: '5',
    name: 'Berdiche',
    damage: 50,
    initiative: -15,
    integrity: 12,
    breaking: 4,
    presence: 20,
    ownStrength: 0,
    special: 'A 2 manos',
    requiredStrength: {
      oneHand: 0,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Impact,
    },
    types: [WeaponType.Pole],
  },
  {
    id: '6',
    name: 'Bolas',
    damage: 20,
    initiative: 0,
    integrity: 10,
    breaking: 2,
    presence: 15,
    cadence: 50,
    range: 20,
    ownStrength: 0,
    special: 'Lanzable (50/20m)',
    requiredStrength: {
      oneHand: 5,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Launch],
  },
  BolasWeapon,
  {
    id: '8',
    name: 'Bracamarte',
    damage: 60,
    initiative: -10,
    integrity: 15,
    breaking: 4,
    presence: 20,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 7,
      twoHands: 7,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.Sword],
  },
  {
    id: '9',
    name: 'Brandistock',
    damage: 40,
    initiative: 0,
    integrity: 9,
    breaking: 3,
    presence: 25,
    ownStrength: 0,
    special: 'A 2 manos, Ver',
    requiredStrength: {
      oneHand: 0,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Thrust,
      secondary: HitType.Impact,
    },
    types: [WeaponType.Pole],
  },
  {
    id: '10',
    name: 'Cadena',
    damage: 25,
    initiative: 0,
    integrity: 13,
    breaking: 2,
    presence: 15,
    ownStrength: 0,
    special: 'Presa (FUE 8), Compleja',
    requiredStrength: {
      oneHand: 6,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Rope],
  },
  {
    id: '11',
    name: 'Cestus',
    damage: 25,
    initiative: 10,
    integrity: 11,
    breaking: -2,
    presence: 15,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 3,
      twoHands: 3,
    },
    critic: {
      primary: HitType.Thrust,
      secondary: HitType.Cut,
    },
    types: [WeaponType.Short],
  },
  {
    id: '12',
    name: 'Chui',
    damage: 40,
    initiative: 5,
    integrity: 13,
    breaking: 3,
    presence: 15,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 6,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Mace],
  },
  ScimitarWeapon,
  {
    id: '14',
    name: 'Cinquedea',
    damage: 35,
    initiative: 0,
    integrity: 12,
    breaking: 0,
    presence: 20,
    ownStrength: 0,
    special: 'Precisa',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Thrust,
      secondary: HitType.Cut,
    },
    types: [WeaponType.Short],
  },
  {
    id: '15',
    name: 'Claymore',
    damage: 65,
    initiative: -20,
    integrity: 16,
    breaking: 5,
    presence: 25,
    ownStrength: 0,
    special: 'A 2 manos',
    requiredStrength: {
      oneHand: 0,
      twoHands: 7,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Impact,
    },
    types: [WeaponType.GreatSword],
  },
  DaggerWeapon,
  BlockDaggerWeapon,
  {
    id: '18',
    name: 'Dardos de mano',
    damage: 20,
    initiative: 20,
    integrity: 3,
    breaking: -4,
    presence: 15,
    cadence: 40,
    range: 20,
    ownStrength: 0,
    special: 'Lanzable (40/XXXm)',
    requiredStrength: {
      oneHand: 3,
      twoHands: 3,
    },
    critic: {
      primary: HitType.Thrust,
    },
    types: [WeaponType.Launch],
  },
  {
    id: '19',
    name: 'Dirk',
    damage: 35,
    initiative: 15,
    integrity: 10,
    breaking: -2,
    presence: 15,
    ownStrength: 0,
    special: 'Precisa',
    requiredStrength: {
      oneHand: 5,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Thrust,
    },
    types: [WeaponType.Short],
  },
  {
    id: '20',
    name: 'Escramasajón',
    damage: 45,
    initiative: 0,
    integrity: 14,
    breaking: 4,
    presence: 20,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Thrust,
    },
    types: [WeaponType.Short],
  },
  GreatSwordWeapon,
  {
    id: '22',
    name: 'Espada ancha',
    damage: 55,
    initiative: -5,
    integrity: 15,
    breaking: 3,
    presence: 25,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 5,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.Sword],
  },
  BastardSwordWrapon,
  ShortSwordWeapon,
  LongSwordWeapon,
  StyletWeapon,
  RapierWeapon,
  {
    id: '28',
    name: 'Falcata',
    damage: 60,
    initiative: -10,
    integrity: 12,
    breaking: 5,
    presence: 20,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 7,
      twoHands: 7,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.Sword, WeaponType.Axe],
  },
  FoilWeapon,
  HookWeapon,
  ClubWeapon,
  {
    id: '32',
    name: 'Glavius',
    damage: 40,
    initiative: 0,
    integrity: 12,
    breaking: 1,
    presence: 20,
    ownStrength: 0,
    special: 'A 1 o 2 manos',
    requiredStrength: {
      oneHand: 7,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Thrust,
      secondary: HitType.Cut,
    },
    types: [WeaponType.Pole],
  },
  {
    id: '33',
    name: 'Goedendag',
    damage: 35,
    initiative: 5,
    integrity: 10,
    breaking: 2,
    presence: 20,
    ownStrength: 0,
    special: 'A 1 o 2 manos, Ver',
    requiredStrength: {
      oneHand: 6,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Thrust,
      secondary: HitType.Impact,
    },
    types: [WeaponType.Pole],
  },
  {
    id: '34',
    name: 'Gosse Messer',
    damage: 75,
    initiative: -30,
    integrity: 12,
    breaking: 3,
    presence: 20,
    ownStrength: 0,
    special: 'A 2 manos',
    requiredStrength: {
      oneHand: 9,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.GreatSword],
  },
  {
    id: '35',
    name: 'Gran martillo guerra',
    damage: 70,
    initiative: -35,
    integrity: 16,
    breaking: 6,
    presence: 20,
    ownStrength: 0,
    special: 'A 1 o 2 manos',
    requiredStrength: {
      oneHand: 10,
      twoHands: 7,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Mace],
  },
  {
    id: '36',
    name: 'Guadaña',
    damage: 35,
    initiative: 0,
    integrity: 12,
    breaking: 2,
    presence: 25,
    ownStrength: 0,
    special: 'A 1 o 2 manos',
    requiredStrength: {
      oneHand: 9,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Impact,
    },
    types: [WeaponType.Pole, WeaponType.GreatSword],
  },
  {
    id: '37',
    name: 'Guandao',
    damage: 65,
    initiative: -20,
    integrity: 13,
    breaking: 3,
    presence: 20,
    ownStrength: 0,
    special: 'A 2 manos',
    requiredStrength: {
      oneHand: 11,
      twoHands: 7,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Thrust,
    },
    types: [WeaponType.Pole],
  },
  TwoHandsAxeWeapon,
  WarAxeWeapon,
  AxeWeapon,
  {
    id: '41',
    name: 'Hu Die Dao',
    damage: 30,
    initiative: 15,
    integrity: 10,
    breaking: -2,
    presence: 20,
    ownStrength: 0,
    special: 'Precisa, Ver',
    requiredStrength: {
      oneHand: 5,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Impact,
    },
    types: [WeaponType.Short],
  },
  JavelinWeapon,
  {
    id: '43',
    name: 'Katzbalger',
    damage: 45,
    initiative: 10,
    integrity: 13,
    breaking: 2,
    presence: 20,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 5,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.Short],
  },
  {
    id: '44',
    name: 'Kerambit',
    damage: 25,
    initiative: 20,
    integrity: 10,
    breaking: -2,
    presence: 15,
    ownStrength: 0,
    special: 'Precisa, Ver',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Impact,
    },
    types: [WeaponType.Short],
  },
  {
    id: '45',
    name: 'Koncerz',
    damage: 60,
    initiative: -30,
    integrity: 9,
    breaking: 2,
    presence: 20,
    ownStrength: 0,
    special: 'Ver',
    requiredStrength: {
      oneHand: 8,
      twoHands: 8,
    },
    critic: {
      primary: HitType.Thrust,
    },
    types: [WeaponType.Sword],
  },
  {
    id: '46',
    name: 'Kukri',
    damage: 35,
    initiative: 10,
    integrity: 12,
    breaking: 3,
    presence: 20,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 6,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.Short],
  },
  SpearWeapon,
  KnightSpearWeapon,
  WhipWeapon,
  {
    id: '50',
    name: 'Lazo',
    damage: 5,
    initiative: 10,
    integrity: 9,
    breaking: -4,
    presence: 20,
    ownStrength: 0,
    special: 'Compleja, Presa (FUE 9), Ver',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Rope],
  },
  {
    id: '51',
    name: 'Liu Xing Chui',
    damage: 30,
    initiative: 15,
    integrity: 8,
    breaking: 1,
    presence: 15,
    ownStrength: 0,
    special: 'A 2 manos,Presa (FUE 8),Compleja',
    requiredStrength: {
      oneHand: 5,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Rope],
  },
  {
    id: '52',
    name: 'Lujiaodao',
    damage: 25,
    initiative: 20,
    integrity: 12,
    breaking: 5,
    presence: 15,
    ownStrength: 0,
    special: 'Traba el Arma',
    requiredStrength: {
      oneHand: 5,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.Short],
  },
  {
    id: '53',
    name: 'Mangual',
    damage: 80,
    initiative: -50,
    integrity: 14,
    breaking: 6,
    presence: 20,
    ownStrength: 0,
    special: 'Compleja',
    requiredStrength: {
      oneHand: 10,
      twoHands: 8,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Mace, WeaponType.GreatSword],
  },
  {
    id: '54',
    name: 'Martillo de guerra',
    damage: 50,
    initiative: -5,
    integrity: 15,
    breaking: 4,
    presence: 15,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 6,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Mace],
  },
  {
    id: '55',
    name: 'Mayal',
    damage: 40,
    initiative: 0,
    integrity: 13,
    breaking: 4,
    presence: 15,
    ownStrength: 0,
    special: 'Compleja',
    requiredStrength: {
      oneHand: 6,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Mace, WeaponType.Rope],
  },
  {
    id: '56',
    name: 'Maza Barra',
    damage: 50,
    initiative: 0,
    integrity: 14,
    breaking: 4,
    presence: 20,
    ownStrength: 0,
    special: 'A 1 o 2 manos',
    requiredStrength: {
      oneHand: 7,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Mace],
  },
  MaceWeapon,
  HeavyMaceWeapon,
  {
    id: '59',
    name: 'Montante',
    damage: 80,
    initiative: -50,
    integrity: 18,
    breaking: 5,
    presence: 25,
    ownStrength: 0,
    special: 'A 2 manos',
    requiredStrength: {
      oneHand: 11,
      twoHands: 8,
    },
    critic: {
      primary: HitType.Thrust,
      secondary: HitType.Cut,
    },
    types: [WeaponType.GreatSword],
  },
  {
    id: '60',
    name: 'Pata',
    damage: 40,
    initiative: 15,
    integrity: 13,
    breaking: 2,
    presence: 20,
    ownStrength: 0,
    special: 'Precisa',
    requiredStrength: {
      oneHand: 6,
      twoHands: 0,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Thrust,
    },
    types: [WeaponType.Short],
  },
  {
    id: '61',
    name: 'Pica',
    damage: 60,
    initiative: -30,
    integrity: 12,
    breaking: 4,
    presence: 15,
    ownStrength: 0,
    special: 'A 2  manos, Ver',
    requiredStrength: {
      oneHand: 0,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Thrust,
    },
    types: [WeaponType.Pole],
  },
  {
    id: '62',
    name: 'Pudao',
    damage: 40,
    initiative: -5,
    integrity: 12,
    breaking: 2,
    presence: 20,
    ownStrength: 0,
    special: 'A 1 o 2 manos',
    requiredStrength: {
      oneHand: 7,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Impact,
    },
    types: [WeaponType.Pole],
  },
  NetWeapon,
  SaberWeapon,
  {
    id: '65',
    name: 'Shang Gou',
    damage: 40,
    initiative: 5,
    integrity: 12,
    breaking: 1,
    presence: 20,
    ownStrength: 0,
    special: 'Compleja, Traba el arma, Ver',
    requiredStrength: {
      oneHand: 5,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.Sword],
  },
  {
    id: '66',
    name: 'Spatha',
    damage: 45,
    initiative: 5,
    integrity: 14,
    breaking: 3,
    presence: 20,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 6,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Thrust,
      secondary: HitType.Cut,
    },
    types: [WeaponType.Sword],
  },
  TridentWeapon,
  TurcusWeapon,
  {
    id: '69',
    name: 'Urumi',
    damage: 25,
    initiative: 15,
    integrity: 7,
    breaking: -3,
    presence: 15,
    ownStrength: 0,
    special: 'Compleja, Ver',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.Rope],
  },
  {
    id: '70',
    name: 'Valaska',
    damage: 40,
    initiative: 0,
    integrity: 11,
    breaking: 2,
    presence: 20,
    ownStrength: 0,
    special: 'A 1 o 2 manos',
    requiredStrength: {
      oneHand: 8,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Impact,
    },
    types: [WeaponType.Pole, WeaponType.Axe],
  },
  {
    id: '71',
    name: 'Vara',
    damage: 30,
    initiative: 10,
    integrity: 11,
    breaking: 0,
    presence: 30,
    ownStrength: 0,
    special: 'A 2 manos',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Pole],
  },
  BodyShieldWeapon,
  MediumShieldWeapon,
  BucklerShieldWeapon,
  {
    id: '75',
    name: 'Abanico de combate',
    damage: 30,
    initiative: 20,
    integrity: 8,
    breaking: 0,
    presence: 25,
    cadence: 40,
    range: 20,
    ownStrength: 0,
    special: 'Precisa, Lanzable (40/XXXm)',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Impact,
    },
    types: [WeaponType.Short],
  },
  {
    id: '76',
    name: 'Anciano de primavera',
    damage: 35,
    initiative: 15,
    integrity: 12,
    breaking: 2,
    presence: 25,
    ownStrength: 0,
    special: 'Compleja, A 2 manos, Ver',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Thrust,
    },
    types: [WeaponType.Pole],
  },
  {
    id: '77',
    name: 'Bumerang',
    damage: 30,
    initiative: 10,
    integrity: 10,
    breaking: 0,
    presence: 15,
    cadence: 60,
    range: 20,
    ownStrength: 0,
    special: 'Lanzable (60/XXXm), Ver',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Impact,
      secondary: HitType.Cut,
    },
    types: [WeaponType.Short],
  },
  {
    id: '78',
    name: 'Cuervo',
    damage: 35,
    initiative: 10,
    integrity: 11,
    breaking: 2,
    presence: 25,
    ownStrength: 0,
    special: 'Compleja, Precisa, Ver',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Impact,
      secondary: HitType.Cut,
    },
    types: [WeaponType.Short],
  },
  ClawsWeapon,
  KatanaWeapon,
  {
    id: '81',
    name: 'Katana de doble hoja',
    damage: 55,
    initiative: -5,
    integrity: 11,
    breaking: 3,
    presence: 40,
    ownStrength: 0,
    special: 'A 2 manos. Permite ataque extra con -10',
    requiredStrength: {
      oneHand: 8,
      twoHands: 8,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.Sword],
  },
  {
    id: '82',
    name: 'Katar',
    damage: 40,
    initiative: 10,
    integrity: 13,
    breaking: 3,
    presence: 25,
    ownStrength: 0,
    special: 'Compleja, Ver',
    requiredStrength: {
      oneHand: 8,
      twoHands: 8,
    },
    critic: {
      primary: HitType.Thrust,
      secondary: HitType.Cut,
    },
    types: [WeaponType.Short],
  },
  KusariGamaWeapon,
  {
    id: '84',
    name: 'Nodachi',
    damage: 80,
    initiative: -35,
    integrity: 14,
    breaking: 4,
    presence: 40,
    ownStrength: 0,
    special: 'A 1 o 2 manos',
    requiredStrength: {
      oneHand: 10,
      twoHands: 8,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.GreatSword],
  },
  {
    id: '85',
    name: 'Nunchaku',
    damage: 30,
    initiative: 15,
    integrity: 11,
    breaking: 0,
    presence: 15,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 5,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Rope],
  },
  {
    id: '86',
    name: 'Quebradora',
    damage: 50,
    initiative: -20,
    integrity: 16,
    breaking: 8,
    presence: 25,
    ownStrength: 0,
    special: 'A 1 o 2 manos',
    requiredStrength: {
      oneHand: 10,
      twoHands: 8,
    },
    critic: {
      primary: HitType.Impact,
      secondary: HitType.Cut,
    },
    types: [WeaponType.GreatSword],
  },
  {
    id: '87',
    name: 'Sai',
    damage: 35,
    initiative: 15,
    integrity: 12,
    breaking: 0,
    presence: 25,
    ownStrength: 0,
    special: 'Precisa, Traba',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Thrust,
      secondary: HitType.Cut,
    },
    types: [WeaponType.Short],
  },
  {
    id: '88',
    name: 'Shuko',
    damage: 20,
    initiative: 10,
    integrity: 9,
    breaking: -2,
    presence: 25,
    ownStrength: 0,
    special: 'Ver',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Thrust,
    },
    types: [WeaponType.Short],
  },
  ShurikenWeapon,
  TantoWeapon,
  {
    id: '91',
    name: 'Tonfa',
    damage: 30,
    initiative: 20,
    integrity: 13,
    breaking: 0,
    presence: 25,
    ownStrength: 0,
    special: 'Precisa',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Short],
  },
  {
    id: '92',
    name: 'Boku-to',
    damage: 25,
    initiative: 0,
    integrity: 8,
    breaking: -1,
    presence: 15,
    ownStrength: 0,
    special: '1 o 2 manos, Ver',
    requiredStrength: {
      oneHand: 6,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Sword],
  },
  {
    id: '93',
    name: 'Jutte',
    damage: 20,
    initiative: 15,
    integrity: 13,
    breaking: 2,
    presence: 20,
    ownStrength: 0,
    special: 'Traba el arma',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Short],
  },
  {
    id: '94',
    name: 'Kama',
    damage: 40,
    initiative: 5,
    integrity: 12,
    breaking: 1,
    presence: 20,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Thrust,
    },
    types: [WeaponType.Short],
  },
  {
    id: '95',
    name: 'Kau Sin Ke',
    damage: 30,
    initiative: 0,
    integrity: 13,
    breaking: 3,
    presence: 20,
    ownStrength: 0,
    special: 'Compleja,Presa (FUE 8)',
    requiredStrength: {
      oneHand: 6,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Rope],
  },
  {
    id: '96',
    name: 'Kiseru',
    damage: 20,
    initiative: 15,
    integrity: 13,
    breaking: 3,
    presence: 20,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Mace],
  },
  {
    id: '97',
    name: 'Kris',
    damage: 30,
    initiative: 15,
    integrity: 11,
    breaking: 1,
    presence: 20,
    ownStrength: 0,
    special: 'Precisa',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Thrust,
      secondary: HitType.Cut,
    },
    types: [WeaponType.Short],
  },
  {
    id: '98',
    name: 'Kumade',
    damage: 35,
    initiative: -10,
    integrity: 13,
    breaking: 2,
    presence: 25,
    ownStrength: 0,
    special: 'Ver',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Thrust,
    },
    types: [WeaponType.Pole],
  },
  {
    id: '99',
    name: 'Kunai',
    damage: 25,
    initiative: 20,
    integrity: 10,
    breaking: 2,
    presence: 15,
    cadence: 40,
    range: 20,
    ownStrength: 0,
    special: 'Lanzable (40/XXXm)',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Thrust,
    },
    types: [WeaponType.Short],
  },
  {
    id: '100',
    name: 'Kyoketsu-shogui',
    damage: 25,
    initiative: 10,
    integrity: 9,
    breaking: -3,
    presence: 20,
    ownStrength: 0,
    special: 'Presa (Fuerza 7),Ver',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Thrust,
    },
    types: [WeaponType.Short, WeaponType.Rope],
  },
  {
    id: '101',
    name: 'Lajatang',
    damage: 40,
    initiative: -5,
    integrity: 13,
    breaking: 3,
    presence: 25,
    ownStrength: 0,
    special: 'Compleja, 2 manos, Ver',
    requiredStrength: {
      oneHand: 6,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.Pole],
  },
  {
    id: '102',
    name: 'Nagimaki',
    damage: 40,
    initiative: 10,
    integrity: 12,
    breaking: 2,
    presence: 20,
    ownStrength: 0,
    special: 'A 2  manos',
    requiredStrength: {
      oneHand: 6,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.Pole],
  },
  {
    id: '103',
    name: 'Naginata',
    damage: 55,
    initiative: -10,
    integrity: 13,
    breaking: 3,
    presence: 25,
    ownStrength: 0,
    special: 'A 1 o 2 manos',
    requiredStrength: {
      oneHand: 8,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Thrust,
    },
    types: [WeaponType.Pole],
  },
  {
    id: '104',
    name: 'Ninja-to',
    damage: 45,
    initiative: 10,
    integrity: 12,
    breaking: 1,
    presence: 25,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Thrust,
    },
    types: [WeaponType.Sword],
  },
  {
    id: '105',
    name: 'Ram Dao',
    damage: 60,
    initiative: -20,
    integrity: 16,
    breaking: 7,
    presence: 30,
    ownStrength: 0,
    special: 'A 1 o 2 manos',
    requiredStrength: {
      oneHand: 9,
      twoHands: 7,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.Sword, WeaponType.GreatSword],
  },
  {
    id: '106',
    name: 'Sang Kauw',
    damage: 50,
    initiative: -10,
    integrity: 10,
    breaking: 2,
    presence: 25,
    ownStrength: 0,
    special: 'Ver',
    requiredStrength: {
      oneHand: 6,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Thrust,
    },
    types: [WeaponType.Pole],
  },
  {
    id: '107',
    name: 'Sode Garami',
    damage: 20,
    initiative: 0,
    integrity: 13,
    breaking: 2,
    presence: 20,
    ownStrength: 0,
    special: 'A 2 manos, Presa (FUE 7), Ver',
    requiredStrength: {
      oneHand: 5,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Pole],
  },
  {
    id: '108',
    name: 'Tetsubo',
    damage: 80,
    initiative: -50,
    integrity: 13,
    breaking: 4,
    presence: 30,
    ownStrength: 0,
    special: 'A 1 o 2 manos',
    requiredStrength: {
      oneHand: 10,
      twoHands: 8,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Mace],
  },
  {
    id: '109',
    name: 'Uchi-ne',
    damage: 25,
    initiative: 10,
    integrity: 10,
    breaking: -2,
    presence: 20,
    cadence: 100,
    range: 30,
    ownStrength: 0,
    special: 'Lanzable (100/XXXm)',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Thrust,
    },
    types: [WeaponType.Pole],
  },
  {
    id: '110',
    name: 'Wakizashi',
    damage: 45,
    initiative: 0,
    integrity: 11,
    breaking: 3,
    presence: 40,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Cut,
    },
    types: [WeaponType.Sword],
  },
  {
    id: '111',
    name: 'Arcabuz',
    damage: 0,
    initiative: -20,
    integrity: 9,
    breaking: -3,
    presence: 20,
    reload: 4,
    range: 80,
    ownStrength: 11,
    special: 'Compleja, Ver',
    requiredStrength: {
      oneHand: 6,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Projectiles],
  },
  {
    id: '112',
    name: 'Arco compuesto',
    damage: 0,
    initiative: -30,
    integrity: 8,
    breaking: -2,
    presence: 25,
    reload: 1,
    range: 90,
    ownStrength: 0,
    special: 'A 2 manos',
    requiredStrength: {
      oneHand: 0,
      twoHands: 7,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Projectiles],
  },
  BowWeapon,
  {
    id: '114',
    name: 'Arco de balas',
    damage: 5,
    initiative: -10,
    integrity: 6,
    breaking: -3,
    presence: 15,
    reload: 1,
    range: 40,
    ownStrength: 0,
    special: 'A 2 manos, Ver',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Projectiles],
  },
  LongBowWeapon,
  {
    id: '116',
    name: 'Atlatl',
    damage: 0,
    initiative: 0,
    integrity: 8,
    breaking: -4,
    presence: 10,
    reload: 1,
    range: 30,
    ownStrength: 7,
    special: 'Ver',
    requiredStrength: {
      oneHand: 5,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Projectiles],
  },
  CrossbowWeapon,
  HandCrossbowWeapon,
  {
    id: '119',
    name: 'Ballesta de repetición',
    damage: 0,
    initiative: 0,
    integrity: 6,
    breaking: -2,
    presence: 20,
    reload: 3,
    range: 60,
    ownStrength: 8,
    special: '1 o 2 manos, Ver',
    requiredStrength: {
      oneHand: 8,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Projectiles],
  },
  {
    id: '120',
    name: 'Ballesta pesada',
    damage: 0,
    initiative: -20,
    integrity: 8,
    breaking: -1,
    presence: 20,
    reload: 2,
    range: 80,
    ownStrength: 10,
    special: '1 o 2 manos, Ver',
    requiredStrength: {
      oneHand: 10,
      twoHands: 7,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Projectiles],
  },
  BlowgunWeapon,
  {
    id: '122',
    name: 'Daikyu',
    damage: 0,
    initiative: -30,
    integrity: 8,
    breaking: -2,
    presence: 20,
    reload: 1,
    range: 90,
    ownStrength: 0,
    special: 'A 2 manos',
    requiredStrength: {
      oneHand: 7,
      twoHands: 7,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Projectiles],
  },
  {
    id: '123',
    name: 'Honda',
    damage: 0,
    initiative: -40,
    integrity: 3,
    breaking: -6,
    presence: 10,
    reload: 1,
    range: 50,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Projectiles],
  },
  {
    id: '124',
    name: 'Pistola',
    damage: 0,
    initiative: 0,
    integrity: 8,
    breaking: -3,
    presence: 20,
    reload: 4,
    range: 50,
    ownStrength: 9,
    special: 'Compleja, Ver',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Projectiles],
  },
  {
    id: '128',
    name: 'Umbra',
    damage: 20,
    initiative: 20,
    breaking: 5,
    presence: 20,
    cadence: 0,
    reload: 0,
    range: 0,
    ownStrength: 0,
    integrity: 10,
    special:
      'Sorpresa, Pantalla, At. área, Presa (DES), Uso indirecto, Ofuscación',
    requiredStrength: {
      oneHand: 0,
      twoHands: 0,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [WeaponType.Natural],
  },
  {
    id: '129',
    name: 'Aries',
    damage: 100,
    initiative: -20,
    integrity: 16,
    breaking: 6,
    presence: 30,
    ownStrength: 0,
    special: 'Compleja, Presa (FUE 10), Alcance (8m), Arma Grande, A 2 manos',
    requiredStrength: {
      oneHand: 0,
      twoHands: 0,
    },
    critic: {
      primary: HitType.Thrust,
      secondary: HitType.Cut,
    },
    types: [],
  },
  {
    id: '130',
    name: 'Taurus',
    damage: 120,
    initiative: -80,
    integrity: 16,
    breaking: 8,
    presence: 25,
    ownStrength: 0,
    special: 'Lanzable (-/20m), Compleja, A 1 o 2 manos',
    requiredStrength: {
      oneHand: 12,
      twoHands: 10,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Impact,
    },
    types: [],
  },
  {
    id: '131',
    name: 'Cáncer',
    damage: 50,
    initiative: 15,
    integrity: 10,
    breaking: 0,
    presence: 30,
    ownStrength: 0,
    special:
      'Alcance (5m), Presa (FUE 12 mitad penalizador), Casi invisible (MDF), -2 TA blandas (duras mitad), A 2 manos',
    requiredStrength: {
      oneHand: 4,
      twoHands: 4,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Thrust,
    },
    types: [],
  },
  {
    id: '132',
    name: 'Leo (Espada-Pistola)',
    damage: 70,
    initiative: -30,
    integrity: 13,
    breaking: 4,
    presence: 35,
    ownStrength: 0,
    special: 'Cargador, A 1 o 2 manos',
    requiredStrength: {
      oneHand: 8,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Impact,
    },
    types: [],
  },
  {
    id: '133',
    name: 'Leo (Espada-Pistola) P.',
    damage: 0,
    initiative: 0,
    integrity: 13,
    breaking: 4,
    presence: 35,
    reload: 2,
    range: 50,
    ownStrength: 10,
    special: 'Cargador, A 1 o 2 manos',
    requiredStrength: {
      oneHand: 8,
      twoHands: 6,
    },
    critic: {
      primary: HitType.Thrust,
    },
    types: [],
  },
  {
    id: '134',
    name: 'Leo (Lanza-Rifle)',
    damage: 40,
    initiative: 5,
    integrity: 12,
    breaking: 2,
    presence: 35,
    ownStrength: 0,
    special: 'Cargador, A 1 o 2 manos',
    requiredStrength: {
      oneHand: 5,
      twoHands: 3,
    },
    critic: {
      primary: HitType.Thrust,
    },
    types: [],
  },
  {
    id: '135',
    name: 'Leo (Lanza-Rifle) R.',
    damage: 0,
    initiative: 0,
    integrity: 12,
    breaking: 2,
    presence: 35,
    reload: 2,
    range: 80,
    ownStrength: 11,
    special: 'Cargador, A 1 o 2 manos',
    requiredStrength: {
      oneHand: 5,
      twoHands: 3,
    },
    critic: {
      primary: HitType.Thrust,
    },
    types: [],
  },
  {
    id: '136',
    name: 'Leo (Maza-Cañón) C.',
    damage: 0,
    initiative: -40,
    integrity: 15,
    breaking: 5,
    presence: 35,
    reload: 10,
    range: 250,
    ownStrength: 12,
    special: 'At. área automático (2m), A 2 manos',
    requiredStrength: {
      oneHand: 0,
      twoHands: 7,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [],
  },
  {
    id: '137',
    name: 'Leo (Maza-Cañón)',
    damage: 60,
    initiative: -15,
    integrity: 15,
    breaking: 5,
    presence: 35,
    ownStrength: 0,
    special: 'At. área automático (2m), A 2 manos',
    requiredStrength: {
      oneHand: 0,
      twoHands: 7,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [],
  },
  {
    id: '139',
    name: 'Virgo (Viento pequeño)',
    damage: 50,
    initiative: -10,
    integrity: 0,
    breaking: 2,
    presence: 20,
    range: 0,
    ownStrength: 0,
    special: 'Alcance 40m, Fascinación, Ver',
    requiredStrength: {
      oneHand: 0,
      twoHands: 0,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [],
  },
  {
    id: '140',
    name: 'Virgo (Viento grande)',
    damage: 70,
    initiative: -10,
    integrity: 0,
    breaking: 2,
    presence: 20,
    range: 0,
    ownStrength: 0,
    special: 'Alcance 40m, Ver',
    requiredStrength: {
      oneHand: 0,
      twoHands: 0,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [],
  },
  {
    id: '141',
    name: 'Virgo (Cuerda)',
    damage: 50,
    initiative: -10,
    integrity: 0,
    breaking: 2,
    presence: 20,
    range: 0,
    ownStrength: 0,
    special: 'Alcance 40m, Resonancia mejorada, Ver',
    requiredStrength: {
      oneHand: 0,
      twoHands: 0,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [],
  },
  {
    id: '142',
    name: 'Virgo (Percusión)',
    damage: 50,
    initiative: -10,
    integrity: 0,
    breaking: 2,
    presence: 20,
    range: 0,
    ownStrength: 0,
    special: 'Alcance 40m, Impacto FUE 12, Ver',
    requiredStrength: {
      oneHand: 0,
      twoHands: 0,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [],
  },
  {
    id: '143',
    name: 'Libra',
    damage: 50,
    initiative: 0,
    integrity: 15,
    breaking: 2,
    presence: 20,
    ownStrength: 0,
    special: 'Capacidades variables',
    requiredStrength: {
      oneHand: 5,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [],
  },
  {
    id: '144',
    name: 'Scorpio (Espada)',
    damage: 50,
    initiative: 0,
    integrity: 13,
    breaking: 2,
    presence: 35,
    ownStrength: 0,
    special: '-',
    requiredStrength: {
      oneHand: 5,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Thrust,
    },
    types: [],
  },
  {
    id: '145',
    name: 'Scorpio (Látigo)',
    damage: 40,
    initiative: -10,
    integrity: 13,
    breaking: 2,
    presence: 35,
    ownStrength: 0,
    special:
      'Presa (FUE 10), Alcance (10m), Arma Grande, Torbellino envolvente',
    requiredStrength: {
      oneHand: 5,
      twoHands: 5,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Thrust,
    },
    types: [],
  },
  {
    id: '147',
    name: 'Capricornius',
    damage: 100,
    initiative: 20,
    integrity: 8,
    breaking: -5,
    presence: 20,
    cadence: 30,
    range: 10,
    ownStrength: 0,
    special: '1/10 Daño, Lanzable (30/XXXm), Precisa',
    requiredStrength: {
      oneHand: 3,
      twoHands: 3,
    },
    critic: {
      primary: HitType.Thrust,
    },
    types: [],
  },
  {
    id: '148',
    name: 'Aquarius',
    damage: 60,
    initiative: 10,
    integrity: 20,
    breaking: 5,
    presence: 30,
    ownStrength: 0,
    special: 'Escudo contra proyectiles, Presa (FUE 8)',
    requiredStrength: {
      oneHand: 3,
      twoHands: 3,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Impact,
    },
    types: [],
  },
  {
    id: '149',
    name: 'Piscis',
    damage: 30,
    initiative: 20,
    integrity: 8,
    breaking: -2,
    presence: 20,
    ownStrength: 0,
    special: 'Presa (FUE 8), Alcance (8m)',
    requiredStrength: {
      oneHand: 0,
      twoHands: 0,
    },
    critic: {
      primary: HitType.Impact,
    },
    types: [],
  },
  {
    id: '150',
    name: 'Ophiucos',
    damage: 50,
    initiative: 0,
    integrity: 10,
    breaking: 11,
    presence: 40,
    ownStrength: 0,
    special:
      'Liberación absoluta, Liberación en área, Uso sellado, Comb. con Ki',
    requiredStrength: {
      oneHand: 0,
      twoHands: 0,
    },
    critic: {
      primary: HitType.Cut,
      secondary: HitType.Thrust,
    },
    types: [],
  },
];

export const PredefinedWeapons = [UnarmedWeapon, ...ElegiblePredefinedWeapons];
