import styled from 'styled-components';

export const Wrapper = styled.div(({ theme: { size } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  justifyItems: 'center',

  gridGap: size.units(2),

  maxHeight: size.units(60),
  width: '100%',

  overflowY: 'auto',
}));
