import { v4 as uuid } from 'uuid';
import {
  CreateAction,
  DeleteAction,
} from '../../../../IframeMessagingService.types';
import { ActorMutator } from '../../../ActorCharacterTransformer';
import { FoundryVTTActor } from '../FoundryVTTActor';
import { ABFItems, ArmorDataSource } from '../FoundryVTTActor.types';
import { createBaseFinalField } from './utils/createBaseFinalField';
import { FoundryVTTEnumTransforms } from './utils/FoundryVTTEnumTransforms';
import { keepCustomItems } from './utils/keepCustomItems';

export const mutateActorArmors: ActorMutator<FoundryVTTActor> = ({
  actor,
  character,
}) => {
  const originalArmors = actor.data.combat.armors;

  const armorsInCharacter: ArmorDataSource[] =
    character.content.inventory.armors.map((w) => ({
      _id: uuid(),
      type: ABFItems.ARMOR,
      name: w.name,
      data: {
        cut: createBaseFinalField(w.at.cut.base + w.at.cut.special),
        impact: createBaseFinalField(w.at.impact.base + w.at.impact.special),
        thrust: createBaseFinalField(w.at.thrust.base + w.at.thrust.special),
        cold: createBaseFinalField(w.at.cold.base + w.at.cold.special),
        energy: createBaseFinalField(w.at.energy.base + w.at.energy.special),
        electricity: createBaseFinalField(
          w.at.electricity.base + w.at.electricity.special,
        ),
        heat: createBaseFinalField(w.at.heat.base + w.at.heat.special),
        type: {
          value: FoundryVTTEnumTransforms.getArmorType(w.type),
        },
        isEnchanted: {
          value: w.isEnchanted,
        },
        localization: {
          value: FoundryVTTEnumTransforms.getArmorLocation(w.localization),
        },
        movementRestriction: createBaseFinalField(
          w.movementRestriction.base + w.movementRestriction.special,
        ),
        naturalPenalty: createBaseFinalField(
          w.naturalPenalty.base + w.naturalPenalty.special,
        ),
        wearArmorRequirement: createBaseFinalField(
          w.wearArmorRequirement.base + w.wearArmorRequirement.special,
        ),
        equipped: {
          value: w.equipped,
        },
        integrity: createBaseFinalField(w.integrity.base + w.integrity.special),
        quality: {
          value: w.quality.final,
        },
        presence: createBaseFinalField(w.presence.base + w.presence.special),
      },
      flags: {
        'characters-vault': {
          originalItemId: w.id,
        },
      },
    }));

  if (actor.syncVersion > 0) {
    const {
      notOwnedItems,
      toDelete: armorsToDelete,
      toCreate: armorsToCreate,
    } = keepCustomItems({
      itemsInCharacter: armorsInCharacter,
      itemsInActor: originalArmors,
    });

    actor.data.combat.armors = notOwnedItems;

    const createAction: CreateAction<ArmorDataSource> = {
      type: 'create',
      data: armorsToCreate,
    };

    const deleteAction: DeleteAction = {
      type: 'delete',
      ids: armorsToDelete.reduce((acc, w) => [...acc, w._id], [] as string[]),
    };

    return {
      actor,
      actions: [deleteAction, createAction],
    };
  } else {
    actor.data.combat.armors = [];

    const createAction: CreateAction<ArmorDataSource> = {
      type: 'create',
      data: armorsInCharacter,
    };

    const deleteAction: DeleteAction = {
      type: 'delete',
      ids: originalArmors.reduce((acc, w) => [...acc, w._id], [] as string[]),
    };

    return {
      actor,
      actions: [deleteAction, createAction],
    };
  }
};
