import { User } from '@supabase/supabase-js';

export class AuthorizedUser {
  id: string;

  username: string;

  email: string;

  avatarUrl?: string;

  version: number;

  constructor({
    id,
    username,
    email,
    avatarUrl,
  }: {
    id: string;
    username: string;
    email: string;
    avatarUrl?: string;
  }) {
    this.id = id;
    this.username = username;
    this.email = email;
    this.avatarUrl = avatarUrl;
    this.version = 1;
  }

  static fromPrimitives(user: User) {
    const metadata = user.user_metadata;

    if (!metadata.username) throw new Error('Username is required');
    if (!user.email) throw new Error('Email is required');

    return new AuthorizedUser({
      id: user.id,
      username: metadata.username,
      email: user.email,
      avatarUrl: metadata.avatar_url,
    });
  }
}
