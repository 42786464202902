import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TrackingService } from '../../../infrastructure/tracking/TrackingService';

let lastPath: string | null = null;

export const TrackPageView = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  useEffect(() => {
    if (lastPath !== location.pathname) {
      TrackingService.sendPageView();

      lastPath = location.pathname;
    }
  }, [location.pathname]);

  return <>{children}</>;
};
