import { CharacterModel } from '../../../../../character/model/CharacterModel';
import { MartialArtType } from '../../../MartialArt';
import { MartialArtGradeId } from '../../../MartialArtGrades';
import { characterKnownMartialArts } from './characterKnownMartialArts';

export const calculateHighestDamageFromKnownMartialArts = (
  character: CharacterModel,
  martialArts: { type: MartialArtType; grade: MartialArtGradeId }[],
) => {
  const knownMartialArts = characterKnownMartialArts(character, martialArts);
  if (knownMartialArts.length === 0) {
    return 0;
  }

  const highestDamage = knownMartialArts.reduce((acc, martialArt) => {
    const damage = martialArt.damage;

    return Math.max(acc, damage);
  }, 0);

  return highestDamage;
};
