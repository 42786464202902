import { Characters } from './components/Characters/Characters';
import { AuthorizedPageLayout } from '../../../../shared/application/design-system/components/PageLayout/PageLayout';
import { DefaultNavbar } from '../../../../shared/application/design-system/components/TopNavbar/implementations/DefaultNavbar';
import { useState } from 'react';
import { Tabs } from '../../../../shared/application/design-system/components/Tabs/Tabs';
import { CharactersCollaborations } from './components/CharactersCollaborations/CharactersCollaborations';
import { TabsContainer } from './CharactersPage.styled';

enum CharactersPageTabs {
  Characters = 'Characters',
  CharactersCollaborations = 'CharactersCollaborations',
}

export const CharactersPage = () => {
  const [currentTab, setCurrentTab] = useState<CharactersPageTabs>(
    CharactersPageTabs.Characters,
  );

  const handleChangeTab = (tab: CharactersPageTabs) => () => {
    setCurrentTab(tab);
  };

  const getTabComponent = () => {
    switch (currentTab) {
      case CharactersPageTabs.Characters:
        return <Characters />;
      case CharactersPageTabs.CharactersCollaborations:
        return <CharactersCollaborations />;
    }
  };

  return (
    <AuthorizedPageLayout Header={<DefaultNavbar />}>
      <TabsContainer>
        <Tabs>
          <Tabs.Button
            name="Tus personajes"
            onClick={handleChangeTab(CharactersPageTabs.Characters)}
            selected={currentTab === CharactersPageTabs.Characters}
          />
          <Tabs.Button
            name="Tus colaboraciones"
            onClick={handleChangeTab(
              CharactersPageTabs.CharactersCollaborations,
            )}
            selected={
              currentTab === CharactersPageTabs.CharactersCollaborations
            }
          />
        </Tabs>
      </TabsContainer>
      {getTabComponent()}
    </AuthorizedPageLayout>
  );
};
