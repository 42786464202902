import { BasicParagraph } from '../../../../../../../../shared/application/design-system/components/Texts/Texts.styled';
import { Wrapper } from './EmptyCharacterList.styled';
import { Actions } from '../../../../../../components/CharacterList/components/Actions/Actions';

interface EmptyCharacterListProps {
  onFolderCreated: () => void;
}

export const EmptyCharacterList = ({
  onFolderCreated,
}: EmptyCharacterListProps) => {
  return (
    <Wrapper>
      <BasicParagraph>Parece que no tienes nada aquí</BasicParagraph>
      <Actions onFolderCreated={onFolderCreated} />
    </Wrapper>
  );
};
