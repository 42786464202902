import styled from 'styled-components';
import {
  BasicTitle,
  SmallTitle,
} from '../../../../../../../../../../../../../../../shared/application/design-system/components/Texts/Texts.styled';
import { Input } from '../../../../../../../../../../../../../../../shared/application/design-system/components/Input/Input';
import { TextArea } from '../../../../../../../../../../../../../../../shared/application/design-system/components/TextArea/TextArea';

export const Wrapper = styled.div({
  minWidth: '50vw',
  height: '100%',
});

export const StyledTitle = styled(BasicTitle)(({ theme: { color } }) => ({
  color: color.primaryDark(),
}));

export const SectionTitle = styled(SmallTitle)(({ theme: { color } }) => ({
  color: color.primaryDark(),
}));

export const Container = styled.div(({ theme: { size } }) => ({
  display: 'flex',

  flexDirection: 'column',

  gap: size.units(2),

  width: '100%',

  [StyledTitle]: {
    marginBottom: size.units(2),
  },
}));

export const StyledInput = styled(Input)({
  width: '100%',
});

export const StyledTextArea = styled(TextArea)({
  width: '100%',
});

export const Section = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start',

  flexDirection: 'column',

  gap: size.units(1),
}));
