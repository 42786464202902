import { model } from 'mobx-keystone';
import {
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
} from '../../../../../aggregations/advantage/Advantage.types';
import { MartialMasterAdvantage } from '../../../../../aggregations/advantage/CommonAdvantages';
import { DevelopedPDFieldModel } from '../../../utils/fields/DevelopedPDFieldModel';
import { getTotalOf } from '../../../utils/getTotalOf';

@model('Character/Combat/MartialKnowledge')
export class MartialKnowledgeFieldModel extends DevelopedPDFieldModel {
  get fromCategories(): number {
    return getTotalOf(this.character, (c) => c.combat.martialKnowledge.final);
  }

  get allActionsPenalty(): number {
    return 0;
  }

  get other(): number {
    if (!this.character) return 0;

    return getTotalOf(this.character, (c) =>
      c.combat.martialArts.reduce(
        (total, martialArt) => total + martialArt.martialKnowledgeBonus,
        0,
      ),
    );
  }

  get bonusFromAdvantages(): number {
    if (!this.character) return 0;

    let value = 0;

    if (
      this.character.creationPoints.containsAdvantage(MartialMasterAdvantage, {
        level: AdvantageLevel1,
      })
    ) {
      value += 40;
    }

    if (
      this.character.creationPoints.containsAdvantage(MartialMasterAdvantage, {
        level: AdvantageLevel2,
      })
    ) {
      value += 80;
    }

    if (
      this.character.creationPoints.containsAdvantage(MartialMasterAdvantage, {
        level: AdvantageLevel3,
      })
    ) {
      value += 120;
    }

    return value;
  }
}
