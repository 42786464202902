import { Archetype, CategoryTemplate, CategoryType } from './CategoryTemplate';

export class MentalistWarriorCategoryTemplate extends CategoryTemplate {
  name = 'Guerrero Mentalista';
  archetypes = [Archetype.Fighter, Archetype.Psychic];
  type = CategoryType.MentalistWarrior;

  readonly lifeMultiplier = 20;
  lifePoints = 10;
  protected turn = 5;
  protected martialKnowledge = 20;
  protected nativePsychicPoints = 1;

  calculateNativePsychicPoints(): number {
    return this.level * this.nativePsychicPoints;
  }

  offensiveSkillsLimitPercentage = 0.5;
  attackPDsCost = 2;
  blockPDsCost = 2;
  dodgePDsCost = 2;
  wearArmorPDsCost = 2;
  kiPDsCost = 2;
  kiAccumMultiplierPDsCost = 25;

  magicSkillsLimitPercentage = 0.5;
  zeonPDsCost = 3;
  aCTMultiplierPDsCost = 70;
  magicProjectionPDsCost = 3;
  summonPDsCost = 3;
  controlPDsCost = 3;
  bindPDsCost = 3;
  banishPDsCost = 3;

  psychicSkillsLimitPercentage = 0.5;
  psychicPointsPDsCost = 15;
  psychicProjectionPDsCost = 2;

  athleticSecondariesPDsCost = 2;
  socialSecondariesPDsCost = 2;
  perceptionSecondariesPDsCost = 2;
  intellectualSecondariesPDsCost = 3;
  vigorSecondariesPDsCost = 2;
  subterfugeSecondariesPDsCost = 2;
  creativeSecondariesPDsCost = 2;

  attackBonus = 5;
  blockBonus = 5;
  dodgeBonus = 5;
}
