import { useState } from 'react';
import { Character } from '../../../character/domain/character/Character';
import { CharacterService } from '../../../character/infrastructure/CharacterService';
import { NotificationManager } from '../shared/NotificationManager';

export const useSaveCharacter = () => {
  const [saving, setSaving] = useState(false);

  const saveCharacter = (
    character: Character,
    options?: { notify?: boolean },
  ): Promise<void> => {
    setSaving(true);

    const shouldNotify = options?.notify ?? true;

    return CharacterService.saveCharacter(character)
      .then(() => {
        if (shouldNotify) {
          NotificationManager.success('Personaje guardado');
        }
      })
      .catch((error) => {
        NotificationManager.error('No se ha podido guardar el personaje');

        console.error(error);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return {
    savingCharacter: saving,
    saveCharacter,
  };
};
