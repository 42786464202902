import { WeaponTable, WeaponTableType } from './WeaponTable';
import {
  AxeWeapon,
  BastardSwordWrapon,
  BlockDaggerWeapon,
  BlowgunWeapon,
  BodyShieldWeapon,
  BolasWeapon,
  BowWeapon,
  BucklerShieldWeapon,
  ClawsWeapon,
  ClubWeapon,
  CrossbowWeapon,
  DaggerWeapon,
  FoilWeapon,
  GreatSwordWeapon,
  HalberdWeapon,
  HandCrossbowWeapon,
  HarpoonWeapon,
  HeavyMaceWeapon,
  HookWeapon,
  JavelinWeapon,
  KatanaWeapon,
  KnightSpearWeapon,
  KusariGamaWeapon,
  LongBowWeapon,
  LongSwordWeapon,
  MaceWeapon,
  MediumShieldWeapon,
  NetWeapon,
  RapierWeapon,
  SaberWeapon,
  ScimitarWeapon,
  ShortSwordWeapon,
  ShurikenWeapon,
  SpearWeapon,
  StyletWeapon,
  TantoWeapon,
  TridentWeapon,
  TurcusWeapon,
  TwoHandsAxeWeapon,
  WarAxeWeapon,
  WhipWeapon,
} from '../../weapon/PredefinedWeapons';

export const BarbarianArchetypeWeaponTable = {
  id: '1',
  name: 'Barbaro',
  weapons: [
    WarAxeWeapon,
    TwoHandsAxeWeapon,
    GreatSwordWeapon,
    BastardSwordWrapon,
    HeavyMaceWeapon,
  ],
};

export const KnightArchetypeWeaponTable = {
  id: '2',
  name: 'Caballero',
  weapons: [
    LongSwordWeapon,
    KnightSpearWeapon,
    MaceWeapon,
    BastardSwordWrapon,
    MediumShieldWeapon,
  ],
};

export const NomadArchetypeWeaponTable = {
  id: '3',
  name: 'Nómada',
  weapons: [
    DaggerWeapon,
    TurcusWeapon,
    LongBowWeapon,
    ScimitarWeapon,
    SpearWeapon,
  ],
};

export const GladiatorArchetypeWeaponTable = {
  id: '4',
  name: 'Gladiador',
  weapons: [
    ShortSwordWeapon,
    NetWeapon,
    BucklerShieldWeapon,
    TridentWeapon,
    WhipWeapon,
  ],
};

export const HunterArchetypeWeaponTable = {
  id: '5',
  name: 'Cazador',
  weapons: [
    JavelinWeapon,
    BowWeapon,
    ShortSwordWeapon,
    SpearWeapon,
    BolasWeapon,
  ],
};

export const NinjaArchetypeWeaponTable = {
  id: '6',
  name: 'Ninja',
  weapons: [
    KatanaWeapon,
    TantoWeapon,
    ClawsWeapon,
    ShurikenWeapon,
    KusariGamaWeapon,
  ],
};

export const AssassinArchetypeWeaponTable = {
  id: '7',
  name: 'Asesino',
  weapons: [
    ShortSwordWeapon,
    HandCrossbowWeapon,
    ClubWeapon,
    BlowgunWeapon,
    StyletWeapon,
  ],
};

export const SoldierArchetypeWeaponTable = {
  id: '8',
  name: 'Soldado',
  weapons: [
    CrossbowWeapon,
    LongSwordWeapon,
    HalberdWeapon,
    SpearWeapon,
    MediumShieldWeapon,
  ],
};

export const DuelistArchetypeWeaponTable = {
  id: '9',
  name: 'Duelista',
  weapons: [
    RapierWeapon,
    FoilWeapon,
    BlockDaggerWeapon,
    SaberWeapon,
    LongSwordWeapon,
  ],
};

export const AboriginalArchetypeWeaponTable = {
  id: '10',
  name: 'Aborigen',
  weapons: [
    JavelinWeapon,
    SpearWeapon,
    BodyShieldWeapon,
    BowWeapon,
    BlowgunWeapon,
  ],
};

export const PirateArchetypeWeaponTable = {
  id: '11',
  name: 'Pirata',
  weapons: [HarpoonWeapon, NetWeapon, HookWeapon, SaberWeapon, AxeWeapon],
};

export const BanditArchetypeWeaponTable = {
  id: '12',
  name: 'Bandido',
  weapons: [
    DaggerWeapon,
    CrossbowWeapon,
    ShortSwordWeapon,
    MaceWeapon,
    ClubWeapon,
  ],
};

export const ArchetypeWeaponTables = [
  BarbarianArchetypeWeaponTable,
  KnightArchetypeWeaponTable,
  NomadArchetypeWeaponTable,
  GladiatorArchetypeWeaponTable,
  HunterArchetypeWeaponTable,
  NinjaArchetypeWeaponTable,
  AssassinArchetypeWeaponTable,
  SoldierArchetypeWeaponTable,
  DuelistArchetypeWeaponTable,
  AboriginalArchetypeWeaponTable,
  PirateArchetypeWeaponTable,
  BanditArchetypeWeaponTable,
];

export type ArchetypeWeaponTable = typeof ArchetypeWeaponTables[number];

export class ArchetypeWeaponsTable extends WeaponTable<ArchetypeWeaponTable> {
  name = 'Tabla de Armas Arquetípicas';

  type = WeaponTableType.ArchetypeWeapon;

  cost = 50;

  getItems(): ArchetypeWeaponTable[] {
    return ArchetypeWeaponTables;
  }
}
