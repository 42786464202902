import { CharacterModel } from '../../../../../../../../../../../../domain/character/model/CharacterModel';
import { CategoryModel } from '../../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/CategoryModel';

export const getMaxPDsUntilThisCategory = (
  character: CharacterModel,
  category: CategoryModel,
): number => {
  const currentCategoryIndex = character.categories.findIndex(
    (c) => c === category,
  );

  return character.categories.reduce((prev, curr, currentIndex) => {
    if (currentIndex > currentCategoryIndex) return prev;

    return prev + curr.combat.maxPdsThatCanBeSpentWithinCategory;
  }, 0);
};
