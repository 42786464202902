import { createClient } from '@supabase/supabase-js';
import { config, Environment } from '../../../bootstrap/infrastructure/config';
import { Database } from './Supabase.types';

// eslint-disable-next-line prettier/prettier
let cachedClient: ReturnType<typeof createClient<Database>> | undefined;

export const SupabaseClient = () => {
  if (cachedClient) {
    return cachedClient;
  }

  if (config.environment === Environment.Development) {
    cachedClient = createClient<Database>(
      'http://localhost:54321',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0',
    );
  }

  if (config.environment === Environment.Production) {
    cachedClient = createClient<Database>(
      'https://idefklebtuobhvtamtjb.supabase.co',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlkZWZrbGVidHVvYmh2dGFtdGpiIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzcwMTYzODAsImV4cCI6MTk5MjU5MjM4MH0.I1R7WU9tne9U1PevisgBXr2bnlKiATCmUJ5Tb0a6L5g',
    );
  }

  if (!cachedClient) {
    throw new Error(`Unknown environment ${config.environment}`);
  }

  return cachedClient;
};
