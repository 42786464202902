import { toast } from 'react-toastify';
import { Breakpoint } from '../design-system/theme/mixins/responsive';
import { getCurrentBreakpoint } from '../design-system/utils/getBreakpointBySize';

const getPosition = () => {
  if ([Breakpoint.sm, Breakpoint.xs].includes(getCurrentBreakpoint())) {
    return toast.POSITION.BOTTOM_CENTER;
  }

  return toast.POSITION.TOP_RIGHT;
};

export const NotificationManager = {
  success: (message: string) => {
    toast.success(message, {
      toastId: message,
      position: getPosition(),
    });
  },
  error: (message: string) => {
    toast.error(message, {
      toastId: message,
      position: getPosition(),
    });
  },
  warning: (message: string) => {
    toast.warn(message, {
      toastId: message,
      position: getPosition(),
    });
  },
};
