export enum Difficulty {
  Routine = 20,
  Easy = 40,
  Medium = 80,
  Hard = 120,
  VeryHard = 140,
  Absurd = 180,
  AlmostImpossible = 240,
  Impossible = 280,
  Inhuman = 320,
  Zen = 440,
}

export const getNextDifficultyOf = (difficulty: Difficulty): Difficulty => {
  switch (difficulty) {
    case Difficulty.Routine:
      return Difficulty.Easy;
    case Difficulty.Easy:
      return Difficulty.Medium;
    case Difficulty.Medium:
      return Difficulty.Hard;
    case Difficulty.Hard:
      return Difficulty.VeryHard;
    case Difficulty.VeryHard:
      return Difficulty.Absurd;
    case Difficulty.Absurd:
      return Difficulty.AlmostImpossible;
    case Difficulty.AlmostImpossible:
      return Difficulty.Impossible;
    case Difficulty.Impossible:
      return Difficulty.Inhuman;
    case Difficulty.Inhuman:
      return Difficulty.Zen;
  }

  return Difficulty.Zen;
};

export const getDifficultyOf = (number: number): Difficulty => {
  if (number < 40) return Difficulty.Routine;
  if (number < 80) return Difficulty.Easy;
  if (number < 120) return Difficulty.Medium;
  if (number < 140) return Difficulty.Hard;
  if (number < 180) return Difficulty.VeryHard;
  if (number < 240) return Difficulty.Absurd;
  if (number < 280) return Difficulty.AlmostImpossible;
  if (number < 320) return Difficulty.Impossible;
  if (number < 440) return Difficulty.Inhuman;

  return Difficulty.Zen;
};

export const getDifficultyAsString = (number: number): string => {
  const difficulty = getDifficultyOf(number);

  switch (difficulty) {
    case Difficulty.Routine:
      return 'Rutinaria';
    case Difficulty.Easy:
      return 'Fácil';
    case Difficulty.Medium:
      return 'Media';
    case Difficulty.Hard:
      return 'Difícil';
    case Difficulty.VeryHard:
      return 'Muy Difícil';
    case Difficulty.Absurd:
      return 'Absurda';
    case Difficulty.AlmostImpossible:
      return 'Casi Imposible';
    case Difficulty.Impossible:
      return 'Imposible';
    case Difficulty.Inhuman:
      return 'Inhumana';
    case Difficulty.Zen:
      return 'Zen';
  }
};
