import { Draft, draft } from 'mobx-keystone';
import { observer } from 'mobx-react';
import {
  Container,
  Section,
  SectionTitle,
  StyledInput,
  StyledTitle,
  Wrapper,
} from './EditWeapon.styled';
import { ChangeEvent, useState } from 'react';
import { Button } from '../../../../../../../../../../../../../../../shared/application/design-system/components/Button/Button';
import { WeaponItemModel } from '../../../../../../../../../../../../../../domain/aggregations/weapon/WeaponItemModel';
import { WeaponTypeList } from './components/WeaponTypeList/WeaponTypeList';
import { useConfirmModal } from '../../../../../../../../../../../../../../../shared/application/hooks/useConfirmModal';

export const EditWeapon = observer(
  ({
    weapon: originalWeapon,
    onSaveWeapon,
  }: {
    weapon: WeaponItemModel;
    onSaveWeapon: () => void;
  }) => {
    const [weaponDraft] = useState<Draft<WeaponItemModel>>(
      draft(originalWeapon),
    );

    const { openConfirmModal, ConfirmModal } = useConfirmModal();

    const weapon = weaponDraft.data;

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
      weapon.setName(event.target.value);
    };

    const handleResetWeapon = async () => {
      const response = await openConfirmModal({
        title: 'Esta acción no se puede deshacer',
        content:
          '¿Estás seguro de que quieres resetear esta arma a su estado por defecto?',
        buttons: {
          confirm: 'Resetear',
          reject: 'Cancelar',
        },
      });

      if (response) {
        weapon.resetToDefault();

        weaponDraft.commit();

        onSaveWeapon();
      }
    };

    const handleSaveWeapon = () => {
      weapon.transformToCustom();

      weaponDraft.commit();

      onSaveWeapon();
    };

    return (
      <Wrapper>
        <Container>
          <StyledTitle>Editar arma</StyledTitle>
          <Section>
            <SectionTitle>Nombre</SectionTitle>
            <StyledInput value={weapon.name} onChange={handleNameChange} />
          </Section>
          <Section>
            <SectionTitle>Tipo de arma</SectionTitle>
            <WeaponTypeList weapon={weapon} />
          </Section>

          <Button onClick={handleResetWeapon} disabled={!weapon.isCustom}>
            Resetear por defecto
          </Button>
          <Button onClick={handleSaveWeapon} disabled={!weaponDraft.isDirty}>
            Guardar
          </Button>
        </Container>
        <ConfirmModal />
      </Wrapper>
    );
  },
);
