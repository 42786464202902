import styled from 'styled-components';

export const Explanation = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  width: '100%',
  gap: size.units(2),
}));

export const CheckboxContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  gap: size.units(1),
}));
