import {
  mapPsychicTypeToTable,
  PsychicTable,
  PsychicTableType,
} from './PsychicTable';

export const getPsychicTableFromType = (
  type: PsychicTableType,
): PsychicTable => {
  return new mapPsychicTypeToTable[type]();
};
