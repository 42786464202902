import { Calculation } from '../../../../utils/fields/NumberField';
import { SecondarySkillFieldModel } from './SecondarySkillFieldModel';

export abstract class PhysicalSecondarySkillFieldModel extends SecondarySkillFieldModel {
  get allPhysicalActionsPenalty(): number {
    if (!this.character) return 0;

    return this.character.modifiers.allPhysicalActions.final;
  }

  getCalculations(): Calculation[] {
    const calculations = super.getCalculations();

    calculations.push(
      this.sum(
        'Penalizador a las acciones físicas',
        this.allPhysicalActionsPenalty,
      ),
    );

    return calculations;
  }
}
