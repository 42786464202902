import { ModelAutoTypeCheckingMode, setGlobalConfig } from 'mobx-keystone';
import React from 'react';
import ReactDOM from 'react-dom/client';

import 'react-quill/dist/quill.snow.css';

import App from './bootstrap/application/App';

setGlobalConfig({
  modelAutoTypeChecking: ModelAutoTypeCheckingMode.AlwaysOff,
});

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('No root element');
}

const root = ReactDOM.createRoot(rootElement);

root.render(<App />);
