import {
  SecondarySkill,
  SecondarySkillCategory,
} from '../../character/model/types/SecondarySkill';
import { AllPsychicPowers } from '../psychic-power/AllPsychicPowers';
import { PsychicPowerId } from '../psychic-power/PsychicPower';
import {
  Advantage,
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
  AdvantageType,
} from './Advantage.types';

export enum Add1ToPrimaryCharacteristicAdvantageVariation {
  Perception = 'Perception',
  Strength = 'Strength',
  Constitution = 'Constitution',
  Agility = 'Agility',
  Dexterity = 'Dexterity',
  Intelligence = 'Intelligence',
  Power = 'Power',
  WillPower = 'WillPower',
}

export const Add1ToPrimaryCharacteristicAdvantage: Advantage<Add1ToPrimaryCharacteristicAdvantageVariation> =
  {
    id: 'advantage-1',

    name: '+1 a característica',

    type: AdvantageType.Common,

    levels: [AdvantageLevel1],

    variations: [
      {
        id: Add1ToPrimaryCharacteristicAdvantageVariation.Strength,
        name: 'Fuerza',
      },
      {
        id: Add1ToPrimaryCharacteristicAdvantageVariation.Constitution,
        name: 'Constitución',
      },
      {
        id: Add1ToPrimaryCharacteristicAdvantageVariation.Perception,
        name: 'Percepción',
      },
      {
        id: Add1ToPrimaryCharacteristicAdvantageVariation.Agility,
        name: 'Agilidad',
      },
      {
        id: Add1ToPrimaryCharacteristicAdvantageVariation.Dexterity,
        name: 'Destreza',
      },
      {
        id: Add1ToPrimaryCharacteristicAdvantageVariation.Intelligence,
        name: 'Inteligencia',
      },
      {
        id: Add1ToPrimaryCharacteristicAdvantageVariation.Power,
        name: 'Poder',
      },
      {
        id: Add1ToPrimaryCharacteristicAdvantageVariation.WillPower,
        name: 'Voluntad',
      },
    ],
  };

export const KiAccumulationIncrementedAdvantage: Advantage = {
  id: 'advantage-2',

  name: 'Acumulación de Ki incrementada',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2],
};

export const NaturalPsychicAccessAdvantage: Advantage = {
  id: 'advantage-3',

  name: 'Acceso psíquico natural',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const AccessTo1PsychicDisciplineAdvantage: Advantage = {
  id: 'advantage-4',

  name: 'Acceso a 1 disciplina psíquica',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const FreeAccessToPsychicDisciplinesAdvantage: Advantage = {
  id: 'advantage-5',

  name: 'Acceso libre a disciplinas psíquicas',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const FullAccumulationAdvantage: Advantage = {
  id: 'advantage-6',

  name: 'Acumulación plena',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const AnimalAffinityAdvantage: Advantage = {
  id: 'advantage-7',

  name: 'Afinidad animal',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const UseAffinityAdvantage: Advantage = {
  id: 'advantage-8',

  name: 'Afinidad de uso',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const LuckyAdvantage: Advantage = {
  id: 'advantage-9',

  name: 'Afortunado',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const IntoLimitAdvantage: Advantage = {
  id: 'advantage-10',

  name: 'Al límite',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const AmbidextrousAdvantage: Advantage = {
  id: 'advantage-11',

  name: 'Ambidestría',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

const SecondarySkillsVariations = [
  { id: SecondarySkill.Acrobatics, name: 'Acrobacias' },
  { id: SecondarySkill.Athleticism, name: 'Atletismo' },
  { id: SecondarySkill.Ride, name: 'Montar' },
  { id: SecondarySkill.Swim, name: 'Nadar' },
  { id: SecondarySkill.Climb, name: 'Trepar' },
  { id: SecondarySkill.Jump, name: 'Saltar' },
  { id: SecondarySkill.Piloting, name: 'Pilotar' },
  { id: SecondarySkill.Style, name: 'Estilo' },
  { id: SecondarySkill.Intimidate, name: 'Intimidar' },
  { id: SecondarySkill.Leadership, name: 'Liderazgo' },
  { id: SecondarySkill.Persuasion, name: 'Persuasión' },
  { id: SecondarySkill.Trading, name: 'Comercio' },
  { id: SecondarySkill.Streetwise, name: 'Callejeo' },
  { id: SecondarySkill.Etiquette, name: 'Etiqueta' },
  { id: SecondarySkill.Notice, name: 'Advertir' },
  { id: SecondarySkill.Search, name: 'Buscar' },
  { id: SecondarySkill.Track, name: 'Rastrear' },
  { id: SecondarySkill.Animals, name: 'Animales' },
  { id: SecondarySkill.Science, name: 'Ciencia' },
  { id: SecondarySkill.Law, name: 'Leyes' },
  { id: SecondarySkill.Herbalism, name: 'Herbolaria' },
  { id: SecondarySkill.History, name: 'Historia' },
  { id: SecondarySkill.Tactics, name: 'Táctica' },
  { id: SecondarySkill.Medicine, name: 'Medicina' },
  { id: SecondarySkill.Memorize, name: 'Memorizar' },
  { id: SecondarySkill.Navigation, name: 'Navegación' },
  { id: SecondarySkill.Occultism, name: 'Ocultismo' },
  { id: SecondarySkill.Appraisal, name: 'Tasación' },
  { id: SecondarySkill.MagicAppraisal, name: 'Valoración mágica' },
  { id: SecondarySkill.Composure, name: 'Frialdad' },
  { id: SecondarySkill.FeatsOfStrength, name: 'Proeza de fuerza' },
  { id: SecondarySkill.WithstandPain, name: 'Resistir el dolor' },
  { id: SecondarySkill.LockPicking, name: 'Cerrajería' },
  { id: SecondarySkill.Disguise, name: 'Disfraz' },
  { id: SecondarySkill.Hide, name: 'Ocultarse' },
  { id: SecondarySkill.Steal, name: 'Robar' },
  { id: SecondarySkill.Stealth, name: 'Sigilo' },
  { id: SecondarySkill.Traps, name: 'Trampas' },
  { id: SecondarySkill.Poisons, name: 'Venenos' },
  { id: SecondarySkill.Art, name: 'Arte' },
  { id: SecondarySkill.Dance, name: 'Bailar' },
  { id: SecondarySkill.Forging, name: 'Forja' },
  { id: SecondarySkill.Runes, name: 'Runas' },
  { id: SecondarySkill.Alchemy, name: 'Alquimia' },
  { id: SecondarySkill.Animism, name: 'Animismo' },
  { id: SecondarySkill.Music, name: 'Música' },
  { id: SecondarySkill.SleightOfHand, name: 'Trucos de manos' },
  { id: SecondarySkill.RitualCalligraphy, name: 'Caligrafía ritual' },
  { id: SecondarySkill.Jewelry, name: 'Orfebrería' },
  { id: SecondarySkill.Tailoring, name: 'Confección' },
  { id: SecondarySkill.PuppetMaking, name: 'Confección de marionetas' },
];

export const NaturalLearningAdvantage: Advantage<SecondarySkill> = {
  id: 'advantage-12',

  name: 'Aprendizaje natural',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2],

  variations: SecondarySkillsVariations,
};

const SecondarySkillsCategoriesVariations = [
  {
    id: SecondarySkillCategory.Athletic,
    name: 'Atléticas',
  },
  { id: SecondarySkillCategory.Social, name: 'Social' },
  {
    id: SecondarySkillCategory.Perception,
    name: 'Percepción',
  },
  {
    id: SecondarySkillCategory.Intellectual,
    name: 'Intelectual',
  },
  { id: SecondarySkillCategory.Vigor, name: 'Vigor' },
  {
    id: SecondarySkillCategory.Subterfuge,
    name: 'Subterfugio',
  },
  {
    id: SecondarySkillCategory.Creative,
    name: 'Creativo',
  },
];
export const NaturalLearningInOneFieldAdvantage: Advantage<SecondarySkillCategory> =
  {
    id: 'advantage-13',

    name: 'Aprendizaje natural en un campo',

    type: AdvantageType.Common,

    levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],

    variations: SecondarySkillsCategoriesVariations,
  };

export const LearningAdvantage: Advantage = {
  id: 'advantage-14',

  name: 'Aprendizaje',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const MartialLearningAdvantage: Advantage = {
  id: 'advantage-15',

  name: 'Aprendizaje marcial',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const FieldCategorySkilledAdvantage: Advantage<SecondarySkillCategory> =
  {
    id: 'advantage-16',

    name: 'Apto en un campo',

    type: AdvantageType.Common,

    levels: [AdvantageLevel2],

    variations: SecondarySkillsCategoriesVariations,
  };

export const FieldSkilledAdvantage: Advantage = {
  id: 'advantage-17',

  name: 'Apto en una materia',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],

  variations: SecondarySkillsVariations,
};

export const MysticArmorAdvantage: Advantage = {
  id: 'advantage-18',

  name: 'Armadura mística',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

// TODO: Add as armor on export
export const NaturalArmorAdvantage: Advantage = {
  id: 'advantage-19',

  name: 'Armadura natural',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const NaturalBonusIncrementedAdvantage: Advantage = {
  id: 'advantage-20',

  name: 'Bono natural incrementado',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const GoodLuckAdvantage: Advantage = {
  id: 'advantage-21',

  name: 'Buena suerte',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const AllFieldsSkilledAdvantage: Advantage = {
  id: 'advantage-22',

  name: 'Conocedor de todas las materias',

  type: AdvantageType.Common,

  levels: [AdvantageLevel2],
};

export const MassSummonerAdvantage: Advantage = {
  id: 'advantage-23',

  name: 'Convocador de masas',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const HardToKillAdvantage: Advantage = {
  id: 'advantage-24',

  name: 'Difícil de matar',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const DonAdvantage: Advantage = {
  id: 'advantage-25',

  name: 'Don',

  type: AdvantageType.Common,

  levels: [AdvantageLevel2],
};

export const IncompleteDonAdvantage: Advantage = {
  id: 'advantage-26',

  name: 'Don incompleto',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const ElanAdvantage: Advantage = {
  id: 'advantage-27',

  name: 'Elan',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const CharmAdvantage: Advantage = {
  id: 'advantage-28',

  name: 'Encanto',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const SheeleEssenceAdvantage: Advantage = {
  id: 'advantage-29',

  name: 'Esencia Sheele',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const FamiliarAdvantage: Advantage = {
  id: 'advantage-30',

  name: 'Familiar',

  type: AdvantageType.Common,

  levels: [AdvantageLevel2, AdvantageLevel3],
};

export const SkilledAdvantage: Advantage = {
  id: 'advantage-31',

  name: 'Habilidoso',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const InfatigableAdvantage: Advantage = {
  id: 'advantage-32',

  name: 'Infatigable',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const PainAndFatigueImmunityAdvantage: Advantage = {
  id: 'advantage-33',

  name: 'Inmunidad a dolor y cansancio',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const PsychicImmunityAdvantage: Advantage = {
  id: 'advantage-34',

  name: 'Inmunidad psíquica',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const SupernaturalImmunityAdvantage: Advantage = {
  id: 'advantage-35',

  name: 'Inmunidad sobrenatural',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const DisturbingAdvantage: Advantage = {
  id: 'advantage-36',

  name: 'Inquietante',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const UselessGestureAdvantage: Advantage = {
  id: 'advantage-37',

  name: 'Inutilidad gestual',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const ImperceptibleKiAdvantage: Advantage = {
  id: 'advantage-38',

  name: 'Ki imperceptible',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const FreedomChoiceAdvantage: Advantage = {
  id: 'advantage-39',

  name: 'Libre albedrío',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const DualLimitAdvantage: Advantage = {
  id: 'advantage-40',

  name: 'Límite dual',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const MartialMasterAdvantage: Advantage = {
  id: 'advantage-41',

  name: 'Maestro marcial',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const KiPerceptionAdvantage: Advantage = {
  id: 'advantage-42',

  name: 'Percepción del Ki',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const NaturalPowerAdvantage: Advantage = {
  id: 'advantage-43',

  name: 'Poder innato',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const KiRecoverAdvantage: Advantage = {
  id: 'advantage-44',

  name: 'Recuperación de Ki',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const QuickReflexesAdvantage: Advantage = {
  id: 'advantage-45',

  name: 'Reflejos rápidos',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const RegenerationAdvantage: Advantage = {
  id: 'advantage-46',

  name: 'Regeneración básica, avanzada o mayor',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const CharacteristicRollRepeatAdvantage: Advantage = {
  id: 'advantage-49',

  name: 'Repetición de tirada de característica',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const ExceptionalPhysicalResistanceAdvantage: Advantage = {
  id: 'advantage-50',

  name: 'Resistencia física excepcional',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2],
};

export const ExceptionalMagicResistanceAdvantage: Advantage = {
  id: 'advantage-51',

  name: 'Resistencia mágica excepcional',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2],
};

export const ExceptionalPsychicResistanceAdvantage: Advantage = {
  id: 'advantage-52',

  name: 'Resistencia psíquica excepcional',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2],
};

export const SeductiveAdvantage: Advantage = {
  id: 'advantage-53',

  name: 'Seductor',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const MagistralSealAdvantage: Advantage = {
  id: 'advantage-54',

  name: 'Sellos magistrales',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export enum CombatSenseAdvantageVariation {
  Attack = 'Attack',
  Block = 'Block',
  Dodge = 'Dodge',
}

export const CombatSenseAdvantage: Advantage<CombatSenseAdvantageVariation> = {
  id: 'advantage-55',

  name: 'Sentido del combate',

  variations: [
    { id: CombatSenseAdvantageVariation.Attack, name: 'Ataque' },
    { id: CombatSenseAdvantageVariation.Block, name: 'Parada' },
    { id: CombatSenseAdvantageVariation.Dodge, name: 'Esquiva' },
  ],

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const DangerSenseAdvantage: Advantage = {
  id: 'advantage-56',

  name: 'Sentido del peligro',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const AcuteSensesAdvantage: Advantage = {
  id: 'advantage-57',

  name: 'Sentidos agudos',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const NoFamiliarLimitAdvantage: Advantage = {
  id: 'advantage-58',

  name: 'Sin límite de familiares',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const SoftDreamAdvantage: Advantage = {
  id: 'advantage-59',

  name: 'Sueño ligero',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const SurvivorAdvantage: Advantage = {
  id: 'advantage-60',

  name: 'Superviviente',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const SubstituteCharacteristicBy9Advantage: Advantage = {
  id: 'advantage-61',

  name: 'Substituir característica por 9',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export enum UnnaturalSizeAdvantageVariation {
  Minus5 = '-5',
  Minus4 = '-4',
  Minus3 = '-3',
  Minus2 = '-2',
  Minus1 = '-1',
  Plus1 = '+1',
  Plus2 = '+2',
  Plus3 = '+3',
  Plus4 = '+4',
  Plus5 = '+5',
}

export const UnnaturalSizeAdvantage: Advantage<UnnaturalSizeAdvantageVariation> =
  {
    id: 'advantage-62',

    name: 'Tamaño no natural',

    variations: [
      { id: UnnaturalSizeAdvantageVariation.Minus5, name: '-5' },
      { id: UnnaturalSizeAdvantageVariation.Minus4, name: '-4' },
      { id: UnnaturalSizeAdvantageVariation.Minus3, name: '-3' },
      { id: UnnaturalSizeAdvantageVariation.Minus2, name: '-2' },
      { id: UnnaturalSizeAdvantageVariation.Minus1, name: '-1' },
      { id: UnnaturalSizeAdvantageVariation.Plus1, name: '+1' },
      { id: UnnaturalSizeAdvantageVariation.Plus2, name: '+2' },
      { id: UnnaturalSizeAdvantageVariation.Plus3, name: '+3' },
      { id: UnnaturalSizeAdvantageVariation.Plus4, name: '+4' },
      { id: UnnaturalSizeAdvantageVariation.Plus5, name: '+5' },
    ],

    type: AdvantageType.Common,

    levels: [AdvantageLevel1],
  };

export const UnlinkedTechniquesAdvantage: Advantage = {
  id: 'advantage-63',

  name: 'Técnicas desvinculadas',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const DestinyChosenAdvantage: Advantage = {
  id: 'advantage-64',

  name: 'Tocado por el destino',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const ArmorUseAdvantage: Advantage = {
  id: 'advantage-65',

  name: 'Uso de armadura',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const SupernaturalVisionAdvantage: Advantage = {
  id: 'advantage-66',

  name: 'Ver lo sobrenatural',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const VersatileAdvantage: Advantage = {
  id: 'advantage-67',

  name: 'Versátil',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const NightVisionAdvantage: Advantage = {
  id: 'advantage-68',

  name: 'Visión nocturna',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1],
};

export const NaturalPsychicPowerAccessAdvantage: Advantage<PsychicPowerId> = {
  id: 'advantage-69',

  name: 'Acceso a poderes psíquicos naturales',

  type: AdvantageType.Common,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],

  variations: AllPsychicPowers.map((p) => ({
    id: p.id,
    name: p.name,
  })),
};

export const AllCommonAdvantages: Advantage[] = [
  AccessTo1PsychicDisciplineAdvantage,
  AcuteSensesAdvantage,
  Add1ToPrimaryCharacteristicAdvantage,
  AllFieldsSkilledAdvantage,
  AmbidextrousAdvantage,
  AnimalAffinityAdvantage,
  ArmorUseAdvantage,
  CharacteristicRollRepeatAdvantage,
  CharmAdvantage,
  CombatSenseAdvantage,
  DangerSenseAdvantage,
  DestinyChosenAdvantage,
  DisturbingAdvantage,
  DonAdvantage,
  DualLimitAdvantage,
  ElanAdvantage,
  ExceptionalMagicResistanceAdvantage,
  ExceptionalPhysicalResistanceAdvantage,
  ExceptionalPsychicResistanceAdvantage,
  FamiliarAdvantage,
  FieldCategorySkilledAdvantage,
  FieldSkilledAdvantage,
  FreeAccessToPsychicDisciplinesAdvantage,
  FreedomChoiceAdvantage,
  FullAccumulationAdvantage,
  GoodLuckAdvantage,
  HardToKillAdvantage,
  ImperceptibleKiAdvantage,
  IncompleteDonAdvantage,
  InfatigableAdvantage,
  IntoLimitAdvantage,
  KiAccumulationIncrementedAdvantage,
  KiPerceptionAdvantage,
  KiRecoverAdvantage,
  LearningAdvantage,
  LuckyAdvantage,
  MagistralSealAdvantage,
  MartialLearningAdvantage,
  MartialMasterAdvantage,
  MassSummonerAdvantage,
  MysticArmorAdvantage,
  NaturalArmorAdvantage,
  NaturalBonusIncrementedAdvantage,
  NaturalLearningAdvantage,
  NaturalLearningInOneFieldAdvantage,
  NaturalPowerAdvantage,
  NaturalPsychicAccessAdvantage,
  NaturalPsychicPowerAccessAdvantage,
  NightVisionAdvantage,
  NoFamiliarLimitAdvantage,
  PainAndFatigueImmunityAdvantage,
  PsychicImmunityAdvantage,
  QuickReflexesAdvantage,
  RegenerationAdvantage,
  SeductiveAdvantage,
  SheeleEssenceAdvantage,
  SkilledAdvantage,
  SoftDreamAdvantage,
  SubstituteCharacteristicBy9Advantage,
  SupernaturalImmunityAdvantage,
  SupernaturalVisionAdvantage,
  SurvivorAdvantage,
  UnlinkedTechniquesAdvantage,
  UnnaturalSizeAdvantage,
  UseAffinityAdvantage,
  UselessGestureAdvantage,
  VersatileAdvantage,
];
