import { model } from 'mobx-keystone';
import { SecondarySkill } from '../../../../../types/SecondarySkill';
import { getTotalOf } from '../../../../../utils/getTotalOf';
import { SecondarySkillPDFieldModel } from '../../../../pd/parts/categories/parts/secondaries/SecondarySkillPDFieldModel';
import { PhysicalSecondarySkillFieldModel } from '../../../utils/fields/PhysicalSecondarySkillFieldModel';

@model('Character/Secondaries/CreativeSkills/Jewelry')
export class JewelrySkillModel extends PhysicalSecondarySkillFieldModel {
  type = SecondarySkill.Jewelry;

  getPDFields(): SecondarySkillPDFieldModel[] {
    return (
      this.character?.pd.categories.map(
        (c) => c.secondaries.creative.jewelry,
      ) ?? []
    );
  }

  get primariesBonus(): number {
    if (!this.character) return 0;

    return this.character.primaries.dexterity.modifier.final;
  }

  get fromCategories(): number {
    return getTotalOf(
      this.character,
      (c) => c.secondaries.creative.jewelry.final,
    );
  }
}
