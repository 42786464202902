import {
  BasicMartialArtGradeIds,
  buildAdvancedMartialArtArcaneGrade,
  buildAdvancedMartialArtBasicGrade,
} from '../../MartialArtGrades';
import {
  AdvancedMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { calculateHighestDamageFromKnownMartialArts } from './utils/calculateHighestDamageFromKnownMartialArts';
import { HitType } from '../../../../character/model/types/HitType';
import { calculateMartialArtsRequirements } from './utils/calculateMartialArtsRequirements';
import { KiSkillType } from '../../../ki-skill/KiSkill';

const BasicMartialArtsRequirement = [
  {
    type: MartialArtType.Boxing,
    grade: BasicMartialArtGradeIds.Advanced,
  },
  {
    type: MartialArtType.Shotokan,
    grade: BasicMartialArtGradeIds.Advanced,
  },
];

const ArcaneMartialArtsRequirement = [
  {
    type: MartialArtType.Boxing,
    grade: BasicMartialArtGradeIds.Supreme,
  },
  {
    type: MartialArtType.Shotokan,
    grade: BasicMartialArtGradeIds.Supreme,
  },
];

export class GodhandMartialArt extends AdvancedMartialArt {
  readonly name = 'Godhand';

  readonly complexity = 4;

  readonly type = MartialArtType.Godhand;

  readonly level = MartialArtLevelType.Advanced;

  readonly grades = [
    buildAdvancedMartialArtBasicGrade({
      cmBonus: 10,
      effects:
        'Reduce en uno sus ataques para aumentar en +30 el HA y el +50 el daño producido al primer golpe del siguiente asalto. No funciona si está a la defensiva o le faltan ataques.',
      combatBonus: {
        attack: 10,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          BasicMartialArtsRequirement,
        );

        if (character.combat.attackAbility.final < 200) {
          errors.push('Ataque 200+');
        }

        return errors;
      },
    }),
    buildAdvancedMartialArtArcaneGrade({
      cmBonus: 20,
      effects: 'Los bonos ascienden a +60 y +100 respectivamente.',
      masterBonus: {
        attack: 15,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          ArcaneMartialArtsRequirement,
        );

        if (!character.hasInhumanity) {
          errors.push('Inhumanidad');
        }

        if (!character.ki.hasKiSkill(KiSkillType.PresenceExtrusion)) {
          errors.push('Habilidad de Ki: Extrusión de presencia');
        }

        if (character.combat.attackAbility.final < 300) {
          errors.push('Ataque 300+');
        }

        return errors;
      },
    }),
  ];
}
