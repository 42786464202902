import { model } from 'mobx-keystone';
import { PDFieldModel } from '../../PDFieldModel';

@model('Character/PD/Combat/WearArmorAbilityPD')
export class WearArmorAbilityPDFieldModel extends PDFieldModel {
  get pdsCost(): number {
    return this.template.wearArmorPDsCost;
  }

  get perCategory(): number {
    return this.template.calculateWearArmorBonus();
  }
}
