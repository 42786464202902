import {
  FolderIcon,
  FolderName,
  FolderNameContainer,
  FolderNavigationContainer,
  FolderNavigationWrapper,
  StyledBackIcon,
} from './FolderNavigation.styled';
import {
  Routes,
  useNavigate,
} from '../../../../shared/application/hooks/useNavigate';
import { Folder } from '../../../domain/folder/Folder';
import { FolderDragging } from '../FolderDragging/FolderDragging';
import { useMoveFolder } from '../../hooks/useMoveFolder';
import { Loading } from '../../../../shared/application/design-system/components/Loading/Loading';
import { useMoveCharacter } from '../../hooks/useMoveCharacter';
import { Tooltip } from '@mui/material';
import { TrackingService } from '../../../../shared/infrastructure/tracking/TrackingService';

export const FolderNavigation = ({
  folder,
  afterMoveContentToParentFolder,
}: {
  folder: Folder;
  afterMoveContentToParentFolder: () => void;
}) => {
  const { navigate } = useNavigate();

  const { movingFolder, moveFolder, moveFolderToRoot } = useMoveFolder();
  const { movingCharacter, moveCharacter, moveCharacterToRoot } =
    useMoveCharacter();

  const handleGoBack = () => {
    navigate(Routes.MyCharacters, {
      params: { folderId: folder.parentFolderId?.toString() },
    });
  };

  const moveFolderToFolder = (params: {
    destinationFolderId: string;
    draggedFolderId: string;
  }) => {
    if (folder.parentFolderId !== undefined) {
      moveFolder(params.destinationFolderId, params.draggedFolderId).then(
        () => {
          afterMoveContentToParentFolder();
        },
      );
    } else {
      moveFolderToRoot(params.draggedFolderId).then(() => {
        afterMoveContentToParentFolder();
      });
    }

    TrackingService.sendEvent('folder_moved');
  };

  const moveCharacterToFolder = (params: {
    characterId: string;
    folderId: string;
  }) => {
    if (folder.parentFolderId !== undefined) {
      moveCharacter(params.characterId, folder.parentFolderId).then(() => {
        afterMoveContentToParentFolder();
      });
    } else {
      moveCharacterToRoot(params.characterId).then(() => {
        afterMoveContentToParentFolder();
      });
    }

    TrackingService.sendEvent('character_moved', {
      characterId: params.characterId,
    });
  };

  return (
    <FolderNavigationWrapper>
      <FolderDragging
        folderId={folder.id}
        onFolderDrop={moveFolderToFolder}
        onCharacterDrop={moveCharacterToFolder}
        disableDrag
      >
        {({ isOver }) => (
          <Tooltip title="Volver a la carpeta anterior">
            <FolderNavigationContainer
              $showBorder={isOver}
              onClick={handleGoBack}
            >
              {movingFolder ? <Loading size={1} /> : <StyledBackIcon />}
              <FolderNameContainer>
                <FolderIcon />
                <FolderName>{folder.name}</FolderName>
              </FolderNameContainer>
            </FolderNavigationContainer>
          </Tooltip>
        )}
      </FolderDragging>
    </FolderNavigationWrapper>
  );
};
