import { color } from '../colors/color';
import { size } from '../size/size';

export const border = {
  small: `1px solid ${color.lightGray()}`,
  invertedSmall: `1px solid ${color.white()}`,
  double: `2px solid ${color.whiteSmoke()}`,
} as const;

export const borderRadius = {
  small: size.units(0.5),
  medium: size.units(1),
  large: size.units(2),
};
