import { ExtendedModel, model, prop } from 'mobx-keystone';
import { KiType } from '../../../../../../../types/KiType';
import { PDFieldModel } from '../../PDFieldModel';

@model('KiPointsField')
export class KiPointsPDFieldModel extends ExtendedModel(PDFieldModel, {
  type: prop<KiType>(),
}) {
  get pdsCost(): number {
    return this.template.kiPDsCost;
  }

  get perCategory(): number | undefined {
    return undefined;
  }
}
