import { model } from 'mobx-keystone';
import { Calculation } from '../../../character/model/utils/fields/NumberField';
import { calculateShieldBlockBonus } from '../utils/calculateShieldBlockBonus';
import { calculateStrengthRequiredPenalty } from '../utils/calculateStrengthRequiredPenalty';
import { getWeaponItemModel } from '../utils/getWeaponItemModel';
import { getWeaponKnowledgePenalty } from '../utils/getWeaponKnowledgePenalty';
import { WeaponItemModel } from '../WeaponItemModel';
import { BaseFinalFieldModel } from '../../../character/model/utils/fields/BaseFinalFieldModel';

@model('WeaponItem/WeaponBlockField')
export class WeaponBlockFieldModel extends BaseFinalFieldModel {
  get weapon(): WeaponItemModel | undefined {
    return getWeaponItemModel(this);
  }

  getCalculations(): Calculation[] {
    if (!this.character || !this.weapon) return [];

    const calculations: Calculation[] = [];

    calculations.push(
      this.sum(
        'Habilidad de bloqueo',
        this.character.combat.blockAbility.final,
      ),
    );

    calculations.push(this.sum('Base', this.base));

    calculations.push(this.sum('Especial', this.special));

    calculations.push(this.sum('Calidad del arma', this.weapon.quality.final));

    if (this.weapon.isShield && this.weapon.equipped) {
      calculations.push(
        this.sum(
          'Bonificación de escudo',
          calculateShieldBlockBonus(this.weapon),
        ),
      );
    }

    calculations.push(
      this.sum(
        'Penalización por conocimiento',
        getWeaponKnowledgePenalty(this.weapon.knowledgeType),
      ),
    );

    calculations.push(
      this.sum(
        'Penalización por fuerza',
        calculateStrengthRequiredPenalty(this.weapon, this.character),
      ),
    );

    return calculations;
  }
}
