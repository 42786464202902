import { Model } from 'mobx-keystone';
import { SecondarySkillPDFieldModel } from '../SecondarySkillPDFieldModel';

export abstract class SecondarySkillsPDModel extends Model({}) {
  abstract get fields(): SecondarySkillPDFieldModel[];

  get pdsSpent(): number {
    return this.fields.reduce((total, field) => total + field.base, 0);
  }
}
