import { ThreeCircles } from 'react-loader-spinner';
import { useTheme } from 'styled-components';

import { Wrapper } from './Loading.styled';

type LoadingProps = {
  size?: number;
  color?: 'white' | 'primary' | 'primary-light';

  centered?: boolean;
};

export const Loading = ({ size = 5, color, centered }: LoadingProps) => {
  const theme = useTheme();

  let spinnerColor = theme.color.primary();

  if (color === 'white') {
    spinnerColor = theme.color.white();
  }

  if (color === 'primary-light') {
    spinnerColor = theme.color.primaryLight();
  }

  return (
    <Wrapper $fullSize={centered}>
      <ThreeCircles
        height={size ? `${size}rem` : '100%'}
        width={size ? `${size}rem` : '100%'}
        color={spinnerColor}
        visible
        ariaLabel="three-circles-rotating"
        outerCircleColor={spinnerColor}
        innerCircleColor={spinnerColor}
        middleCircleColor={spinnerColor}
      />
    </Wrapper>
  );
};
