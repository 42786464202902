import {
  BasicMartialArtGradeIds,
  buildAdvancedMartialArtArcaneGrade,
  buildAdvancedMartialArtBasicGrade,
} from '../../MartialArtGrades';
import {
  AdvancedMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { calculateHighestDamageFromKnownMartialArts } from './utils/calculateHighestDamageFromKnownMartialArts';
import { HitType } from '../../../../character/model/types/HitType';
import { calculateMartialArtsRequirements } from './utils/calculateMartialArtsRequirements';

const BasicMartialArtsRequirement = [
  {
    type: MartialArtType.Grappling,
    grade: BasicMartialArtGradeIds.Advanced,
  },
  {
    type: MartialArtType.Pankration,
    grade: BasicMartialArtGradeIds.Advanced,
  },
];

const ArcaneMartialArtsRequirement = [
  {
    type: MartialArtType.Grappling,
    grade: BasicMartialArtGradeIds.Supreme,
  },
  {
    type: MartialArtType.Pankration,
    grade: BasicMartialArtGradeIds.Supreme,
  },
];

export class MelkaiahMartialArt extends AdvancedMartialArt {
  readonly name = 'Melkaiah';

  readonly complexity = 3;

  readonly type = MartialArtType.Melkaiah;

  readonly level = MartialArtLevelType.Advanced;

  readonly grades = [
    buildAdvancedMartialArtBasicGrade({
      cmBonus: 10,
      effects: '+3 al control de fuerza o destreza al ejecutar derribo o presa',
      combatBonus: {
        attack: 10,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          BasicMartialArtsRequirement,
        );

        if (!character.hasInhumanity) {
          errors.push('Inhumanidad');
        }

        if (character.combat.attackAbility.final < 160) {
          errors.push('Ataque 160+');
        }

        if (character.combat.defensiveAbility < 160) {
          errors.push('Bloqueo o esquiva 160+');
        }

        return errors;
      },
    }),
    buildAdvancedMartialArtArcaneGrade({
      cmBonus: 20,
      effects:
        'Bono de +50 al ataque cuando realiza una maniobra de liberar a una presa (se denomina Ataque Completo). +3 a los controles en Aplastar o Estrangular.',
      masterBonus: {
        attack: 10,
        block: 10,
        dodge: 10,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          ArcaneMartialArtsRequirement,
        );
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          ArcaneMartialArtsRequirement,
        );

        if (character.combat.attackAbility.final < 260) {
          errors.push('Ataque 260+');
        }

        if (character.combat.defensiveAbility < 260) {
          errors.push('Bloqueo o esquiva 260+');
        }

        return errors;
      },
    }),
  ];
}
