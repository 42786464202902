export enum ArmorLocation {
  Complete = 'Complete',
  Nightdress = 'Nightdress',
  Breastplate = 'Breastplate',
  Head = 'Head',
}

export const armorLocationTranslation: Record<ArmorLocation, string> = {
  [ArmorLocation.Complete]: 'Completa',
  [ArmorLocation.Nightdress]: 'Camisola',
  [ArmorLocation.Breastplate]: 'Peto',
  [ArmorLocation.Head]: 'Cabeza',
};

export enum ArmorType {
  Soft = 'Soft',
  Hard = 'Hard',
  Natural = 'Natural',
}

export const armorTypeTranslation: Record<ArmorType, string> = {
  [ArmorType.Soft]: 'Blanda',
  [ArmorType.Hard]: 'Dura',
  [ArmorType.Natural]: 'Natural',
};
