import { Delete } from '@styled-icons/material';
import styled from 'styled-components';
import { BasicParagraph } from '../../../../shared/application/design-system/components/Texts/Texts.styled';

export const DeleteIcon = styled(Delete)(({ theme: { color } }) => ({
  color: color.white(),
}));

export const IconContainer = styled.div(
  ({ theme: { size, color, transition } }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    width: size.units(3.5),
    height: size.units(3.5),

    backgroundColor: color.primaryDark(),

    ':hover': {
      backgroundColor: color.primaryLight(),
    },

    transition: transition.standard,

    cursor: 'pointer',

    [`${DeleteIcon}`]: {
      cursor: 'pointer',

      width: size.units(2.5),
      height: size.units(2.5),
    },

    borderRadius: '50%',
    border: `1px solid ${color.darkGray()}`,
  }),
);

export const InputContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  gap: size.units(2),

  width: '100%',
}));

export const CollaboratorRow = styled.div(({ theme: { size, color } }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  width: '100%',
  height: size.units(3),

  paddingTop: size.units(1),
  paddingBottom: size.units(1),

  borderBottom: `1px solid ${color.darkGray()}`,
}));

export const CollaboratorName = styled(BasicParagraph)({});

export const OwnerCollaborationName = styled(CollaboratorName)(
  ({ theme: { color } }) => ({
    color: color.primaryDark(),

    fontWeight: 'bold',
  }),
);

export const CollaborationsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',

  width: '100%',
});

export const Wrapper = styled.div(({ theme: { size, mixins } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',

  gap: size.units(2),

  width: '30vw',
  height: '30vh',

  ...mixins.responsive.xs.downward({
    width: '100%',
    height: '100%',
  }),
}));
