import { observer } from 'mobx-react';
import { CategoryModel } from '../../../../../../../../../../domain/character/model/parts/pd/parts/categories/CategoryModel';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';
import { CenteredContainer, PDSectionDivider } from '../../PDsTab.styled';
import { FieldHeader } from '../common/FieldRow/components/FieldHeader/FieldHeader';
import { PDFieldRow } from '../common/FieldRow/PDFieldRow';
import { MysticTableSelector } from './MysticTableSelector/MysticTableSelector';
import { MagicInfo } from './MagicInfo/MagicInfo';

type MagicPDsProps = {
  category: CategoryModel;
};

export const MagicPDs = observer(({ category }: MagicPDsProps) => {
  const {
    character: { content: character },
  } = useCharacterContext();

  return (
    <Section>
      <Section.Title>Magia</Section.Title>
      <Table>
        <FieldHeader />
        <PDFieldRow
          name="Zeón"
          pdField={category.mystic.zeonPoints}
          field={character.mystic.zeon}
        />
        <PDFieldRow
          name="ACT"
          pdField={category.mystic.act}
          field={character.mystic.act}
        />
        <PDFieldRow
          name="Regeneración de Zeón"
          pdField={category.mystic.zeonRegeneration}
          field={character.mystic.zeonRegeneration}
        />
        <PDFieldRow
          name="Proyección mágica"
          pdField={category.mystic.magicProjection}
          field={character.mystic.magicProjection}
        />
        <PDFieldRow
          name="Nivel de magia"
          pdField={category.mystic.magicLevel}
          field={character.mystic.magicLevel}
        />
        <PDSectionDivider />
        <PDFieldRow
          name="Invocar"
          pdField={category.mystic.summon}
          field={character.mystic.summon}
        />
        <PDFieldRow
          name="Controlar"
          pdField={category.mystic.control}
          field={character.mystic.control}
        />
        <PDFieldRow
          name="Atar"
          pdField={category.mystic.bind}
          field={character.mystic.bind}
        />
        <PDFieldRow
          name="Expulsar"
          pdField={category.mystic.banish}
          field={character.mystic.banish}
        />
      </Table>

      <Section.Subtitle>Tablas místicas</Section.Subtitle>
      <MysticTableSelector magic={category.mystic} />

      <PDSectionDivider />

      <CenteredContainer>
        <MagicInfo category={category} />
      </CenteredContainer>
    </Section>
  );
});
