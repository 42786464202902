import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class LamaMartialArt extends BasicMartialArt {
  readonly name = 'Lama';

  readonly complexity = 2;

  readonly type = MartialArtType.Lama;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      combatBonus: {
        block: 10,
        dodge: 10,
      },
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 10;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.social.style.final < 20) {
          errors.push('Estilo 20+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      effects: 'Segunda defensa sin penalizador. Quedaría: 0/0/-30/-50...',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 10;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.social.style.final < 40) {
          errors.push('Estilo 40+');
        }

        if (character.combat.defensiveAbility < 130) {
          errors.push('Bloqueo o esquiva 130+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      effects: 'Tercera defensa sin penalizador. Quedaría: 0/0/0/-30/-50...',
      combatBonus: {
        block: 10,
        dodge: 10,
      },
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 10;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.social.style.final < 80) {
          errors.push('Estilo 80+');
        }

        if (character.combat.defensiveAbility < 200) {
          errors.push('Bloqueo o esquiva 200+');
        }

        return errors;
      },
    }),
  ];
}
