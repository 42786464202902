import { CharacterModel } from '../../../CharacterModel';

// CharactersCollaborations can develop the half of the PDs available to use in each category
// on defense abilities (dodge, block)
export const calculateOnlyDefenseDevelopmentExceedIssue = (
  character: CharacterModel,
): boolean => {
  const { dodgeAbility, attackAbility, blockAbility } = character.combat;

  const isAttackDeveloped = attackAbility.isDeveloped;

  const isDefenseDeveloped =
    dodgeAbility.isDeveloped || blockAbility.isDeveloped;

  if (isAttackDeveloped) return false;

  if (!isDefenseDeveloped) return false;

  return character.pd.categories.some((category) => {
    const maxPDsOnDefense = category.combat.maxPdsThatCanBeSpent / 2;

    const dodgePdsSpent = category.combat.dodgeAbility.pdsSpent;
    const blockPdsSpent = category.combat.blockAbility.pdsSpent;

    return dodgePdsSpent > maxPDsOnDefense || blockPdsSpent > maxPDsOnDefense;
  });
};
