import { model } from 'mobx-keystone';
import { getBreakingBonusFromStrength } from '../utils/getBreakingBonusFromStrength';
import { WeaponSizeProportion } from '../Weapon.types';
import { WeaponFieldModel } from './WeaponFieldModel';

@model('WeaponItem/WeaponBreakingFieldModel')
export class WeaponBreakingFieldModel extends WeaponFieldModel {
  get final(): number {
    if (!this.character || !this.weapon) return 0;

    let breaking = this.base + this.special;

    breaking += getBreakingBonusFromStrength(
      this.character.primaries.strength.modifier.final,
    );

    breaking += Math.floor((this.weapon.quality.final / 5) * 2);

    if (this.weapon.sizeProportion === WeaponSizeProportion.Enormous) {
      breaking += 3;
    }

    if (this.weapon.sizeProportion === WeaponSizeProportion.Enormous) {
      breaking += 8;
    }

    return breaking;
  }
}
