import { BaseFinalFieldModel } from '../../../character/model/utils/fields/BaseFinalFieldModel';
import { getWeaponItemModel } from '../utils/getWeaponItemModel';
import type { WeaponItemModel } from '../WeaponItemModel';

export class WeaponFieldModel extends BaseFinalFieldModel {
  get final(): number {
    return this.base + this.special;
  }

  get weapon(): WeaponItemModel | undefined {
    return getWeaponItemModel(this);
  }
}
