import {
  LocalStorageKeys,
  LocalStorageManager,
} from '../../shared/infrastructure/LocalStorageManager';
import { SupabaseClient } from '../../shared/infrastructure/supabase/supabaseClient';

const saveBetaUserEmail = (email: string) => {
  LocalStorageManager.setItem(LocalStorageKeys.BetaSignUpEmail, email);
};

const IsBetaSubscription = () => {
  return LocalStorageManager.getItem(LocalStorageKeys.BetaSignUpEmail) !== null;
};
const getBetaUserEmail = () => {
  return String(LocalStorageManager.getItem(LocalStorageKeys.BetaSignUpEmail));
};

const signUpBeta = async (email: string, role: string) => {
  const { error } = await SupabaseClient().from('beta_users').insert({
    email,
    role,
  });
  if (error) {
    if (error) {
      throw error;
    }
  }
};

export const BetaService = {
  saveBetaUserEmail,
  getBetaUserEmail,
  IsBetaSubscription,
  signUpBeta,
};
