import { computed } from 'mobx';
import { model, Model, modelAction, prop } from 'mobx-keystone';
import { VersatileAdvantage } from '../../../../../../../aggregations/advantage/CommonAdvantages';
import {
  CategoryTemplate,
  CategoryType,
} from '../../../../../../../aggregations/category-templates/CategoryTemplate';
import { getCategoryTemplateFromType } from '../../../../../../../aggregations/category-templates/utils/getCategoryTemplateFromType';
import { getParentCharacter } from '../../../../../../../utils/parenting/getParentCharacter';

@model('Character/Development/Category/CategoryChange')
export class CategoryChangeModel extends Model({
  from: prop<CategoryType>(() => CategoryType.Warrior),
  to: prop<CategoryType>(() => CategoryType.AcrobaticWarrior),

  expendFrom: prop(0),
  expendTo: prop(0),
}) {
  get fromTemplate(): CategoryTemplate {
    return getCategoryTemplateFromType(this.from);
  }

  get toTemplate(): CategoryTemplate {
    return getCategoryTemplateFromType(this.to);
  }

  @modelAction
  setExpendFrom(value: number): void {
    this.expendFrom = value;
  }

  @modelAction
  setExpendTo(value: number): void {
    this.expendTo = value;
  }

  @computed
  get cost(): number {
    const fromArchetypes = this.fromTemplate.archetypes;
    const toArchetypes = this.toTemplate.archetypes;

    let costs = {
      Common: 20,
      Mixed: 40,
      Different: 60,
    };

    if (this.character?.creationPoints.containsAdvantage(VersatileAdvantage)) {
      costs = {
        Common: 10,
        Mixed: 20,
        Different: 30,
      };
    }

    if (
      this.fromTemplate.type === CategoryType.Novel ||
      this.toTemplate.type === CategoryType.Novel
    ) {
      return costs.Common;
    }

    if (fromArchetypes.some((a) => toArchetypes.includes(a))) {
      if (fromArchetypes.length === 1 && toArchetypes.length === 1) {
        return costs.Common;
      }

      return costs.Mixed;
    }

    return costs.Different;
  }

  @computed
  get total(): number {
    return this.expendFrom + this.expendTo;
  }

  get character() {
    return getParentCharacter(this);
  }
}
