export enum MysticTableType {
  AttackAsMagicProjection = 'AttackAsMagicProjection',
  DefenseAsMagicProjection = 'DefenseAsMagicProjection',
}

export type MysticTable = {
  name: string;

  type: MysticTableType;

  cost: number;

  effect: string;
};

export const AttackAsMagicProjectionTable: MysticTable = {
  name: 'Proyección Mágica como Ataque',

  type: MysticTableType.AttackAsMagicProjection,

  cost: 75,

  effect: 'Permite usar la H. Ataque como Proyección Mágica ofensiva.',
};

export const DefenseAsMagicProjectionTable: MysticTable = {
  name: 'Proyección Mágica como Defensa',

  type: MysticTableType.DefenseAsMagicProjection,

  cost: 75,

  effect: 'Permite usar la H. Defensa como Proyección Mágica defensiva.',
};

export const mapMysticTypeToTable: Record<MysticTableType, MysticTable> = {
  [MysticTableType.AttackAsMagicProjection]: AttackAsMagicProjectionTable,
  [MysticTableType.DefenseAsMagicProjection]: DefenseAsMagicProjectionTable,
};
