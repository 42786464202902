import { Archetype, CategoryTemplate, CategoryType } from './CategoryTemplate';

export class TechnicianCategoryTemplate extends CategoryTemplate {
  name = 'Tecnicista';
  archetypes = [Archetype.Domine];
  type = CategoryType.Technician;

  readonly lifeMultiplier = 20;
  lifePoints = 5;
  protected turn = 5;
  protected martialKnowledge = 50;
  protected nativePsychicPoints = 1;

  offensiveSkillsLimitPercentage = 0.6;
  attackPDsCost = 2;
  blockPDsCost = 2;
  dodgePDsCost = 2;
  wearArmorPDsCost = 2;
  kiPDsCost = 1;
  kiAccumMultiplierPDsCost = 10;

  magicSkillsLimitPercentage = 0.5;
  zeonPDsCost = 3;
  aCTMultiplierPDsCost = 70;
  magicProjectionPDsCost = 3;
  summonPDsCost = 3;
  controlPDsCost = 3;
  bindPDsCost = 3;
  banishPDsCost = 3;

  psychicSkillsLimitPercentage = 0.5;
  psychicPointsPDsCost = 20;
  psychicProjectionPDsCost = 3;

  athleticSecondariesPDsCost = 2;
  socialSecondariesPDsCost = 2;
  perceptionSecondariesPDsCost = 2;
  intellectualSecondariesPDsCost = 3;
  vigorSecondariesPDsCost = 2;
  subterfugeSecondariesPDsCost = 2;
  creativeSecondariesPDsCost = 2;

  attackBonus = 5;
}
