import { model } from 'mobx-keystone';
import { BaseFinalFieldModel } from '../../../utils/fields/BaseFinalFieldModel';
import { ArmorItemModel } from '../../../../../aggregations/armors/ArmorItemModel';
import { ArmorType } from '../../../../../aggregations/armors/Armor.types';
import { computed } from 'mobx';

@model('NaturalPenaltyByWearArmorRequirement')
export class MovementRestrictionModel extends BaseFinalFieldModel {
  get penaltyOfEquippedArmors(): number {
    if (!this.character) return 0;

    const value = this.getNonNaturalArmors().reduce(
      (prev, curr) => prev + curr.movementRestriction.final,
      0,
    );

    return value;
  }

  get bonusByWearArmorAbility(): number {
    if (!this.character) return 0;

    const bonus =
      this.character.modifiers.wearArmorRequirement.bonusByWearArmorAbility;

    return Math.floor(bonus / 50);
  }

  get final(): number {
    let value = this.base + this.special;

    value += this.penaltyOfEquippedArmors;

    return value;
  }

  @computed
  get finalWithBonus(): number {
    let value = this.final;

    value -= this.bonusByWearArmorAbility;

    return Math.min(0, value);
  }

  private getNonNaturalArmors(): ArmorItemModel[] {
    if (!this.character) return [];

    const nonNaturalArmors = this.character.inventory.equippedArmors.filter(
      (a) => a.type !== ArmorType.Natural,
    );
    return nonNaturalArmors;
  }
}
