import { model } from 'mobx-keystone';
import { Calculation, NumberField } from '../../utils/fields/NumberField';
import { KiSkillType } from '../../../../aggregations/ki-skill/KiSkill';
import { WeaponItemModel } from '../../../../aggregations/weapon/WeaponItemModel';
import { calculateWeaponStrengthModifier } from '../../../../aggregations/weapon/utils/calculateWeaponStrengthModifier';
import { computed } from 'mobx';
import { MartialArtModel } from '../combat/parts/MartialArtModel';

@model('Character/DamageBonusField')
export class DamageModel extends NumberField {
  @computed
  get unarmed(): number {
    if (!this.character) return 0;

    const martialArtsDamages = this.character.combat.martialArts.map((ma) =>
      this.calculateWithMartialArt(ma),
    );

    if (martialArtsDamages.length === 0) {
      return this.final + 10;
    }

    return Math.max(...martialArtsDamages);
  }

  calculateWithWeapon = (weapon: WeaponItemModel): number => {
    if (!this.character) return 0;

    let damage = this.final;

    damage += weapon.damage.final;

    damage += calculateWeaponStrengthModifier(weapon, this.character);

    return damage;
  };

  calculateWithMartialArt = (martialArt: MartialArtModel): number => {
    if (!this.character) return 0;

    let damage = this.final;

    damage += martialArt.damage;

    return damage;
  };

  getCalculations(): Calculation[] {
    if (!this.character) return [];

    const calculations: Calculation[] = [];

    calculations.push(this.sum('Especial', this.special));

    if (this.character.ki.hasKiSkill(KiSkillType.DamageIncremented)) {
      calculations.push(this.sum('Ki: Incremento de daño', 10));
    }

    return calculations;
  }
}
