import { DisadvantageLevel1, DisadvantageLevel2 } from './CommonDisadvantages';
import { Disadvantage, DisadvantageType } from './Disadvantage.types';

export const OralObligationDisadvantage: Disadvantage = {
  id: 'disadvantage-don-1',

  name: 'Obligación oral',

  type: DisadvantageType.Don,

  levels: [DisadvantageLevel1],
};

export const SomaticObligationDisadvantage: Disadvantage = {
  id: 'disadvantage-don-2',

  name: 'Obligación somática',

  type: DisadvantageType.Don,

  levels: [DisadvantageLevel1],
};

export const MagicExhaustionDisadvantage: Disadvantage = {
  id: 'disadvantage-don-3',

  name: 'Extenuación mágica',

  type: DisadvantageType.Don,

  levels: [DisadvantageLevel1],
};

export const MagicBoundToActionDisadvantage: Disadvantage = {
  id: 'disadvantage-don-4',

  name: 'Magia atada a la acción',

  type: DisadvantageType.Don,

  levels: [DisadvantageLevel1],
};

export const SlowRecoveryMagicDisadvantage: Disadvantage = {
  id: 'disadvantage-don-5',

  name: 'Lenta recuperación de magia',

  type: DisadvantageType.Don,

  levels: [DisadvantageLevel1],
};

export const FixedMagicDisadvantage: Disadvantage = {
  id: 'disadvantage-don-5.1',

  name: 'Magia estancada',

  type: DisadvantageType.Don,

  levels: [DisadvantageLevel2],
};

export const ExistentialBoundDisadvantage: Disadvantage = {
  id: 'disadvantage-don-6',

  name: 'Lazo existencial',

  type: DisadvantageType.Don,

  levels: [DisadvantageLevel1],
};

export const WitchcraftDisadvantage: Disadvantage = {
  id: 'disadvantage-don-7',

  name: 'Brujería',

  type: DisadvantageType.Don,

  levels: [DisadvantageLevel2],
};

export const AllDonDisadvantages: Disadvantage[] = [
  OralObligationDisadvantage,
  SomaticObligationDisadvantage,
  MagicExhaustionDisadvantage,
  MagicBoundToActionDisadvantage,
  SlowRecoveryMagicDisadvantage,
  FixedMagicDisadvantage,
  ExistentialBoundDisadvantage,
  WitchcraftDisadvantage,
];
