import { model, Model, prop } from 'mobx-keystone';
import { AllActionsModifierFieldModel } from './fields/AllActionsModifierFieldModel';
import { NaturalPenaltyFieldModel } from './fields/NaturalPenaltyFieldModel';
import { MovementRestrictionModel } from './fields/MovementRestrictionModel';
import { WearArmorRequirementModel } from './fields/WearArmorRequirementModel';
import { AllPhysicalActionsModifierFieldModel } from './fields/AllPhysicalActionsModifierFieldModel';

const Version = 21;

@model('ModifierFields')
export class ModifierFieldsModel extends Model({
  _version: prop(Version),
  allPhysicalActions: prop(() => new AllPhysicalActionsModifierFieldModel({})),
  allActions: prop(() => new AllActionsModifierFieldModel({})),
  naturalPenalty: prop(
    () => new NaturalPenaltyFieldModel({}),
  ).withSnapshotProcessor(NaturalPenaltyFieldModel.snapshotProcessor),
  movementRestriction: prop(() => new MovementRestrictionModel({})),
  wearArmorRequirement: prop(() => new WearArmorRequirementModel({})),
}) {}
