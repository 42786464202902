import { ReactElement, useState } from 'react';
import { Tabs } from '../../../../../../../../../shared/application/design-system/components/Tabs/Tabs';
import { Advantages } from './components/Advantages/Advantages';
import { CharacterGenericFields } from './components/CharacterGenericFields/CharacterGenericFields';
import { CharacterPrimaries } from './components/CharacterPrimaries/CharacterPrimaries';
import { CharacterResistances } from './components/CharacterResistances/CharacterResistances';
import { CreationPoints } from './components/CreationPoints/CreationPoints';
import { Disadvantages } from './components/Disadvantages/components/Disadvantages';
import { Wrapper } from './GeneralTab.styled';

enum GeneralSubTab {
  Principal = 'Principal',
  CreationPoints = 'Puntos de creación',
}

export const GeneralTab = () => {
  const [subTab, setSubTab] = useState(GeneralSubTab.Principal);

  const handleChangeSubTab = (subTab: GeneralSubTab) => () => {
    setSubTab(subTab);
  };

  const PrincipalTab = () => (
    <>
      <CharacterGenericFields />
      <CharacterResistances />
    </>
  );

  const CreationPointsTab = () => (
    <>
      <CreationPoints />
      <Advantages />
      <Disadvantages />
    </>
  );

  const SubTabComponentMap: Record<GeneralSubTab, ReactElement> = {
    [GeneralSubTab.Principal]: <PrincipalTab />,
    [GeneralSubTab.CreationPoints]: <CreationPointsTab />,
  };

  return (
    <Wrapper>
      <CharacterPrimaries />

      <Tabs centered>
        {Object.values(GeneralSubTab).map((t) => (
          <Tabs.Button
            key={t}
            selected={subTab === t}
            name={t}
            onClick={handleChangeSubTab(t)}
          />
        ))}
      </Tabs>

      {SubTabComponentMap[subTab]}
    </Wrapper>
  );
};
