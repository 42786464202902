import styled from 'styled-components';

export const Wrapper = styled.div(
  ({ theme: { size, color, borderRadius, transition, shadow } }) => ({
    display: 'flex',
    flexDirection: 'column',

    gap: size.units(1),

    width: size.units(30),
    height: size.units(15),

    padding: size.units(1),

    border: `2px solid ${color.black()}`,
    borderRadius: borderRadius.medium,

    transition: transition.standard,

    boxShadow: shadow.small,

    '&:hover': {
      boxShadow: shadow.smallHover,
      border: `2px solid ${color.primaryLight()}`,
    },

    cursor: 'pointer',
  }),
);
