import styled from 'styled-components';

export const Wrapper = styled.div(({ theme: { size, mixins } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',

  paddingLeft: size.units(2),
  paddingRight: size.units(2),
  margin: size.units(2),
  boxSizing: 'border-box',

  width: '100%',
  height: 'auto',

  ...mixins.responsive.xs.downward({
    margin: size.units(1),
    paddingLeft: size.units(1),
  }),
}));
