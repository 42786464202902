import { User as SupabaseUser } from '@supabase/supabase-js';

export class User {
  public readonly id: string;

  public readonly username: string;

  public readonly email: string;

  constructor(params: { id: string; username: string; email: string }) {
    this.id = params.id;
    this.username = params.username;
    this.email = params.email;
  }

  static fromPrimitives(params: SupabaseUser) {
    return new User({
      id: params.id,
      username: params.user_metadata.username,
      email: params.email || '',
    });
  }
}
