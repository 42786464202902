import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';
import { ArchetypeWeaponsTable } from '../../../../../aggregations/tables/weapon-tables/ArchetypeWeaponsTable';
import { DistinctOrDisarmedWeaponTable } from '../../../../../aggregations/tables/weapon-tables/DistinctOrDisarmedWeaponTable';
import { ImprovisedWeaponsTable } from '../../../../../aggregations/tables/weapon-tables/ImprovisedWeaponsTable';
import { MixedWeaponTable } from '../../../../../aggregations/tables/weapon-tables/MixedWeaponTable';
import { SimilarWeaponTable } from '../../../../../aggregations/tables/weapon-tables/SimilarWeaponTable';
import { TypologicalWeaponsTable } from '../../../../../aggregations/tables/weapon-tables/TypologicalWeaponsTable';
import {
  AnyWeaponTableType,
  WeaponTableType,
} from '../../../../../aggregations/tables/weapon-tables/WeaponTable';
import { CategoryType } from '../../../../../aggregations/category-templates/CategoryTemplate';
import { getParentCharacter } from '../../../../../utils/parenting/getParentCharacter';

const weaponTableFromTypeMap = {
  [WeaponTableType.SimilarWeapon]: SimilarWeaponTable,
  [WeaponTableType.MixedWeapon]: MixedWeaponTable,
  [WeaponTableType.ArchetypeWeapon]: ArchetypeWeaponsTable,
  [WeaponTableType.DistinctOrDisarmedWeapon]: DistinctOrDisarmedWeaponTable,
  [WeaponTableType.TypologicalWeapon]: TypologicalWeaponsTable,
  [WeaponTableType.ImprovisedWeapons]: ImprovisedWeaponsTable,
};

type WeaponTableFromTypeMap = typeof weaponTableFromTypeMap;

export const getWeaponTableFromType = (type: WeaponTableType) => {
  return new weaponTableFromTypeMap[type]();
};

@model('Character/Development/Category/Combat/CombatTables/WeaponTable')
export class WeaponTableModel<
  T extends AnyWeaponTableType = AnyWeaponTableType,
> extends Model(<T extends AnyWeaponTableType = AnyWeaponTableType>() => ({
  type: prop<T>().withSetter(),

  tableItemSelected: prop<string>('').withSetter(),
}))<T> {
  @computed
  get selection():
    | ReturnType<
        InstanceType<WeaponTableFromTypeMap[T]>['getFilteredItems']
      >[number]
    | undefined {
    if (!this.character) return undefined;

    const items = this.table.getFilteredItems(this.character);

    return items.find((item) => item.id === this.tableItemSelected) as
      | ReturnType<
          InstanceType<WeaponTableFromTypeMap[T]>['getFilteredItems']
        >[number]
      | undefined;
  }

  @computed
  get table(): InstanceType<WeaponTableFromTypeMap[T]> {
    return getWeaponTableFromType(this.type) as InstanceType<
      WeaponTableFromTypeMap[T]
    >;
  }

  @computed
  get name() {
    return this.table.name;
  }

  @computed
  get cost(): number {
    if (!this.character) return 0;

    if (this.character.pd.containsCategory(CategoryType.WeaponMaster)) {
      return Math.floor(this.table.cost / 2);
    }

    return this.table.cost;
  }

  get character() {
    return getParentCharacter(this);
  }
}
