import { BaseFinalFieldModel } from '../../../utils/fields/BaseFinalFieldModel';

export abstract class TotalATField extends BaseFinalFieldModel {
  get final(): number {
    if (!this.character) return 0;

    let ta = this.base + this.special;

    if (this.character.options.isAccumulationCreature) {
      ta += this.calculateAccumulationCreatureATModifier();
    }

    return ta;
  }

  private calculateAccumulationCreatureATModifier() {
    if (!this.character) return 0;

    const size = this.character.description.size.final;

    if (size <= 3) return 1;
    if (size <= 8) return 2;
    if (size <= 22) return 3;
    if (size <= 24) return 4;
    if (size <= 28) return 6;
    if (size <= 33) return 8;

    return 10;
  }
}
