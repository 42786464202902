import { WeaponItemModel } from '../../../../../../../../../../../../../../domain/aggregations/weapon/WeaponItemModel';
import { observer } from 'mobx-react';
import { weaponHitTypeTranslations } from '../../../../../../../../../../../../../../domain/aggregations/weapon/Weapon.types';
import { transformToMenuItems } from '../../../../../../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { Selector } from '../../../../../../../../common/Selector/Selector';
import { HitType } from '../../../../../../../../../../../../../../domain/character/model/types/HitType';

export const SecondaryHitTypeSelector = observer(
  ({ weapon }: { weapon: WeaponItemModel }) => {
    const items = transformToMenuItems({
      fromItems: [undefined, ...Object.values(HitType)],
      generateItem: (hit) => ({
        key: hit ?? 'none',
        name: hit ? weaponHitTypeTranslations[hit] : '—',
        value: hit,
        onClick: () => {
          weapon.critic.setSecondary(hit);
        },
      }),
    });

    const secondary = weapon.critic.secondary;

    return (
      <Selector
        value={secondary}
        valueAsText={secondary ? weaponHitTypeTranslations[secondary] : '-'}
        items={items}
      />
    );
  },
);
