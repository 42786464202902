import { useEffect, useRef } from 'react';

export const useOpenWindow = ({
  url,
  onClose,
}: {
  url: string;
  onClose: () => void;
}) => {
  const timer = useRef<NodeJS.Timeout>();

  const handleWindowPopup = () => {
    const windowPopup = window.open(url, '_blank');

    timer.current = setInterval(() => {
      if (windowPopup?.closed && timer.current) {
        clearInterval(timer.current);
        onClose();
      }
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  return { openWindow: handleWindowPopup };
};
