import { Model, model, prop } from 'mobx-keystone';
import { PsychicDisciplineType } from '../../../../../aggregations/psychic-disciplines/PsychicDiscipline';
import {
  PsychicPower,
  PsychicPowerActionType,
  PsychicPowerCast,
  PsychicPowerId,
} from '../../../../../aggregations/psychic-power/PsychicPower';
import { Difficulty } from '../../../types/Difficulty';

@model('Character/Psychic/PsychicPower')
export class PsychicPowerModel extends Model({
  id: prop<PsychicPowerId>().withSetter(),
  name: prop<string>().withSetter(),
  discipline: prop<PsychicDisciplineType>().withSetter(),
  level: prop<number | undefined>().withSetter(),
  hasMaintenance: prop<boolean>().withSetter(),
  actionType: prop<PsychicPowerActionType>().withSetter(),

  minimalDifficultyToSuccess: prop<Difficulty>().withSetter(),
  casts: prop<PsychicPowerCast>().withSetter(),

  psychicPointsUsedToIncrease: prop(0).withSetter(),
}) {
  get minimumDifficultyToSuccess(): number {
    return this.minimalDifficultyToSuccess;
  }

  get bonusFromPsychicPointsUsedToIncrease(): number {
    return Math.min(100, this.psychicPointsUsedToIncrease * 10);
  }

  toObject(): PsychicPower {
    return {
      id: this.id,
      name: this.name,
      discipline: this.discipline,
      level: this.level,
      hasMaintenance: this.hasMaintenance,
      actionType: this.actionType,

      minimalDifficultyToSuccess: this.minimalDifficultyToSuccess,
      casts: this.casts,
    };
  }
}

export const createModelFromPsychicPower = (
  power: PsychicPower,
): PsychicPowerModel =>
  new PsychicPowerModel({
    id: power.id,
    name: power.name,
    discipline: power.discipline,
    level: power.level,
    hasMaintenance: power.hasMaintenance,
    actionType: power.actionType,

    minimalDifficultyToSuccess: power.minimalDifficultyToSuccess,
    casts: power.casts,

    psychicPointsUsedToIncrease: 0,
  });
