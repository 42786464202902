import { Model, model, prop } from 'mobx-keystone';
import {
  MagicMainPath,
  MagicSubPath,
} from '../../../../../../aggregations/magic-path/MagicPath';
import { DefaultMagicSpells } from '../../../../../../aggregations/magic-spell/DefaultMagicSpells';
import { MagicSpell } from '../../../../../../aggregations/magic-spell/MagicSpell';
import { MagicPathLevelModel } from './MagicPathLevelModel';

@model('Character/Mystic/MagicPath')
export class MagicPathModel extends Model({
  path: prop<MagicMainPath>().withSetter(),
  subPath: prop<MagicSubPath | undefined>().withSetter(),

  level: prop(() => new MagicPathLevelModel({})),
}) {
  get knownSpells(): MagicSpell[] {
    return DefaultMagicSpells.filter(
      (m) => m.magicPath === this.path || m.magicPath === this.subPath,
    ).filter((m) => m.level <= this.level.final);
  }
}
