export enum PsychicDisciplineType {
  Causality = 'Causality',
  Cryokinesis = 'Cryokinesis',
  Energy = 'Energy',
  Electromagnetism = 'Electromagnetism',
  Hypersensitivity = 'Hypersensitivity',
  PhysicalIncrease = 'PhysicalIncrease',
  Light = 'Light',
  Pyrokinesis = 'Pyrokinesis',
  Sentient = 'Sentient',
  Telemetry = 'Telemetry',
  Telepathy = 'Telepathy',
  Telekinesis = 'Telekinesis',
  Teleportation = 'Teleportation',
  MatrixPowers = 'MatrixPowers',
}

export type MainPsychicDiscipline = Exclude<
  PsychicDisciplineType,
  PsychicDisciplineType.MatrixPowers
>;

export type PsychicDiscipline = {
  name: string;

  type: PsychicDisciplineType;

  effect?: string;
};

export const AllPsychicDisciplines: PsychicDiscipline[] = [
  {
    name: 'Causalidad',
    type: PsychicDisciplineType.Causality,
  },
  {
    name: 'Crioquinesis',
    type: PsychicDisciplineType.Cryokinesis,
    effect:
      'El entorno modifica el Potencial: Volcán -30, Incendio de gran tamaño -10, Terreno frío y lluvioso +10, Frío intenso +20, Zona helada +30',
  },
  {
    name: 'Energía',
    type: PsychicDisciplineType.Energy,
  },
  {
    name: 'Electromagnetismo',
    type: PsychicDisciplineType.Electromagnetism,
    effect:
      'Añade +20 en ambientes ionizados. Aplica -20 en zonas sin magnetismo',
  },
  {
    name: 'Hipersensibilidad',
    type: PsychicDisciplineType.Hypersensitivity,
    effect: 'Modificadores como las Habilidades Perceptivas',
  },
  {
    name: 'Incremento Físico',
    type: PsychicDisciplineType.PhysicalIncrease,
  },
  {
    name: 'Luz',
    type: PsychicDisciplineType.Light,
  },
  {
    name: 'Piroquinesis',
    type: PsychicDisciplineType.Pyrokinesis,
    effect:
      'El entorno modifica el Potencial: Zona helada -30, Frío intenso -10, Gran hoguera +10, Incendio de gran tamaño +20, Volcán +30',
  },
  {
    name: 'Sentiente',
    type: PsychicDisciplineType.Sentient,
    effect:
      'No requiere Proyección Psíquica, pero si no alcanza ni el 10%, el objetivo tiene +60 a su RP. Añade 20 a su Potencial si está en contacto físico con el objetivo',
  },
  {
    name: 'Telemetría',
    type: PsychicDisciplineType.Telemetry,
    effect:
      'Añade 10 a su Potencial si está en contacto físico con el objetivo',
  },
  {
    name: 'Telepatía',
    type: PsychicDisciplineType.Telepathy,
    effect:
      'No requiere Proyección Psíquica, pero si no alcanza ni el 10%, el objetivo tiene +60 a su RP. Añade 20 a su Potencial si está en contacto físico con el objetivo',
  },
  {
    name: 'Telequinesis',
    type: PsychicDisciplineType.Telekinesis,
  },
  {
    name: 'Teletransporte',
    type: PsychicDisciplineType.Teleportation,
  },
];

export const getPsychicDisciplineFromType = (
  type: PsychicDisciplineType,
): PsychicDiscipline => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return AllPsychicDisciplines.find((m) => m.type === type)!;
};

export const getUnusedPsychicDiscipline = (
  disciplines: PsychicDiscipline[],
): PsychicDiscipline | undefined => {
  return AllPsychicDisciplines.find((m) => {
    return !disciplines.find((s) => s.type === m.type);
  });
};
