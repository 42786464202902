import { computed } from 'mobx';
import { Model, model, modelAction, prop } from 'mobx-keystone';
import { filterUndefined } from '../../../../../../shared/utils/filterUndefined';
import {
  Advantage,
  AdvantageLevel,
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
  AdvantageType,
  AdvantageVariation,
} from '../../../../aggregations/advantage/Advantage.types';
import {
  DisadvantageLevel,
  DisadvantageLevel1,
  DisadvantageLevel2,
} from '../../../../aggregations/disadvantage/CommonDisadvantages';
import {
  Disadvantage,
  DisadvantageType,
  DisadvantageVariation,
} from '../../../../aggregations/disadvantage/Disadvantage.types';
import { getParentCharacter } from '../../../../utils/parenting/getParentCharacter';
import { AdvantageModel, createModelFromAdvantage } from './AdvantageModel';
import {
  createModelFromDisadvantage,
  DisadvantageModel,
} from './DisadvantageModel';
import { PrimaryFieldsCreationPointBonusesModel } from './parts/PrimaryFieldsCreationPointBonusesModel';

@model('Character/Development/CreationPoints')
export class CreationPointsModel extends Model({
  advantages: prop<AdvantageModel[]>(() => []),
  disadvantages: prop<DisadvantageModel[]>(() => []),

  liberalizedPoints: prop<number>(0).withSetter(),

  primaryBonuses: prop<PrimaryFieldsCreationPointBonusesModel>(
    () => new PrimaryFieldsCreationPointBonusesModel({}),
  ),
}) {
  @computed
  get bonusPoints(): number {
    if (!this.character) return 0;

    return Math.floor(this.character.level / 2);
  }

  @computed
  get remainingPoints(): number {
    return (
      3 +
      this.liberalizedPoints +
      this.disadvantagesGainedPoints() -
      this.advantageSpentPoints()
    );
  }

  private disadvantagesGainedPoints() {
    return this.disadvantages.reduce((sum, d) => {
      if (d.level?.id === DisadvantageLevel1.id) {
        return sum + 1;
      }

      if (d.level?.id === DisadvantageLevel2.id) {
        return sum + 2;
      }

      return sum;
    }, 0);
  }

  private advantageSpentPoints() {
    return this.advantages.reduce((sum, a) => {
      if (a.level?.id === AdvantageLevel1.id) {
        return sum + 1;
      }

      if (a.level?.id === AdvantageLevel2.id) {
        return sum + 2;
      }

      if (a.level?.id === AdvantageLevel3.id) {
        return sum + 3;
      }

      return sum;
    }, 0);
  }

  @modelAction
  addAdvantage(advantage: Advantage) {
    this.advantages.push(createModelFromAdvantage(advantage));
  }

  @modelAction
  replaceAdvantage(from: AdvantageModel, to: AdvantageModel) {
    this.advantages = this.advantages.map((a) => (a === from ? to : a));
  }

  @modelAction
  removeAdvantage(advantage: AdvantageModel) {
    this.advantages = this.advantages.filter((a) => a !== advantage);
  }

  getAdvantage<T extends string>(
    advantage: Advantage<T>,
  ): AdvantageModel | undefined {
    return this.advantages.find((a) => a.id === advantage.id);
  }

  getAdvantages<T extends string>(
    advantage: Advantage<T>,
    filterParams?: {
      level?: AdvantageLevel;
      variation?: AdvantageVariation<T>['id'];
    },
  ): AdvantageModel[] {
    let advantages = this.advantages.filter((a) => a.id === advantage.id);

    if (filterParams?.level !== undefined) {
      advantages = advantages.filter(
        (a) => a.level?.id === filterParams.level?.id,
      );
    }

    if (filterParams?.variation !== undefined) {
      advantages = advantages.filter(
        (a) => a.variation?.id === filterParams.variation,
      );
    }

    return advantages;
  }

  containsAdvantage<T extends string>(
    advantage: Advantage<T>,
    filterParams?: {
      level?: AdvantageLevel;
      variation?: AdvantageVariation<T>['id'];
    },
  ): boolean {
    const foundAdvantage = this.getAdvantage(advantage);

    if (!foundAdvantage) return false;

    if (
      filterParams?.level?.id &&
      foundAdvantage.level?.id !== filterParams.level.id
    )
      return false;

    if (
      filterParams?.variation &&
      foundAdvantage.variation?.id !== filterParams.variation
    )
      return false;

    return true;
  }

  getAdvantagesOfType(type: AdvantageType): AdvantageModel[] {
    return this.advantages
      .filter((a) => a?.type === type)
      .filter(filterUndefined());
  }

  @modelAction
  addDisadvantage(disadvantage: Disadvantage) {
    this.disadvantages.push(createModelFromDisadvantage(disadvantage));
  }

  @modelAction
  replaceDisadvantage(from: DisadvantageModel, to: DisadvantageModel) {
    this.disadvantages = this.disadvantages.map((a) => (a === from ? to : a));
  }

  @modelAction
  removeDisadvantage(disadvantage: DisadvantageModel) {
    this.disadvantages = this.disadvantages.filter((a) => a !== disadvantage);
  }

  getDisadvantage<T extends string>(
    disadvantage: Disadvantage<T>,
  ): DisadvantageModel | undefined {
    return this.disadvantages.find((a) => a.id === disadvantage.id);
  }

  getDisadvantages<T extends string>(
    disadvantage: Disadvantage<T>,
    filterParams?: {
      level?: DisadvantageLevel;
      variation?: DisadvantageVariation<T>['id'];
    },
  ): DisadvantageModel[] {
    let disadvantages = this.disadvantages.filter(
      (a) => a.id === disadvantage.id,
    );

    if (filterParams?.level !== undefined) {
      disadvantages = disadvantages.filter(
        (a) => a.level?.id === filterParams.level?.id,
      );
    }

    if (filterParams?.variation !== undefined) {
      disadvantages = disadvantages.filter(
        (a) => a.variation?.id === filterParams.variation,
      );
    }

    return disadvantages;
  }

  containsDisadvantage<T extends string>(
    disadvantage: Disadvantage<T>,
    filterParams?: {
      level?: DisadvantageLevel;
      variation?: DisadvantageVariation<T>['id'];
    },
  ): boolean {
    const foundDisadvantage = this.getDisadvantage(disadvantage);

    if (!foundDisadvantage) return false;

    if (
      filterParams?.level?.id &&
      foundDisadvantage.level?.id !== filterParams.level.id
    )
      return false;

    if (
      filterParams?.variation &&
      foundDisadvantage.variation?.id !== filterParams.variation
    )
      return false;

    return true;
  }

  getDisadvantagesOfType(type: DisadvantageType): DisadvantageModel[] {
    return this.disadvantages
      .filter((a) => a?.type === type)
      .filter(filterUndefined());
  }

  get character() {
    return getParentCharacter(this);
  }
}
