import { action, observable } from 'mobx';
import { Action } from './IframeMessagingService.types';
import { Connection, connectToParent } from 'penpal';
import { AsyncMethodReturns } from 'penpal/lib/types';
import { Character } from '../../character/domain/character/Character';
import {
  FoundryVTTActor,
  FoundryVTTActorData,
} from './mutators/anima/foundry/FoundryVTTActor';
import { transformImageUrlToBase64 } from '../../shared/application/shared/utils/transformImageUrlToBase64';
import { FoundryVTTActorTransformer } from './mutators/anima/foundry/FoundryVTTActorTransformer';

type ParentMethods = {
  createActor: () => Promise<FoundryVTTActorData>;
  updateActor: (message: {
    isNew: boolean;
    tokenAsBase64?: string;
    actor: FoundryVTTActorData;
    actions: Action[];
  }) => Promise<FoundryVTTActorData>;
  getActors: () => Promise<FoundryVTTActorData[]>;
};

export class FoundryMessagingService {
  public static isActive = observable.box(false);

  private static _instance: FoundryMessagingService | undefined;

  private connection: Connection<ParentMethods> | undefined;

  private parent: AsyncMethodReturns<ParentMethods> | undefined;

  protected constructor() {
    // ignore
  }

  static get instance(): FoundryMessagingService {
    if (!this._instance) {
      this._instance = new FoundryMessagingService();
    }

    return this._instance;
  }

  async start() {
    this.connection = connectToParent<ParentMethods>({});

    this.parent = await this.connection.promise;

    this.setIsActive(true);
  }

  async createNewActor(character: Character) {
    if (!this.parent) return;

    const newActor = FoundryVTTActor.fromPrimitives(
      await this.parent.createActor(),
    );

    await this.parent.updateActor(
      await this.createActorData({
        isNew: true,
        character: character,
        actor: newActor,
      }),
    );
  }

  async updateActor(character: Character, actor: FoundryVTTActor) {
    if (!this.parent) return;

    await this.parent.updateActor(
      await this.createActorData({ character, actor: actor, isNew: false }),
    );
  }

  async getActors(): Promise<FoundryVTTActor[]> {
    if (!this.parent) return [];

    return (await this.parent.getActors()).map((a) =>
      FoundryVTTActor.fromPrimitives(a),
    );
  }

  private async createActorData({
    character,
    actor,
    isNew,
  }: {
    character: Character;
    actor: FoundryVTTActor;
    isNew: boolean;
  }) {
    const actorData = FoundryVTTActorTransformer({
      character,
      actor,
    }).toActor();

    return {
      isNew,
      tokenAsBase64: character.content.tokenUrl
        ? await transformImageUrlToBase64(character.content.tokenUrl)
        : undefined,
      ...actorData,
    };
  }

  @action
  private setIsActive(value: boolean) {
    FoundryMessagingService.isActive.set(value);
  }
}
