import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class PankrationMartialArt extends BasicMartialArt {
  readonly name = 'Pankration';

  readonly complexity = 1;

  readonly type = MartialArtType.Pankration;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      effects: 'Mitad penalizador para presa',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 20;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.athletic.athleticism.final < 30) {
          errors.push('Atletismo 30+');
        }

        if (character.secondaries.vigor.featsOfStrength.final < 30) {
          errors.push('Proezas de fuerza 30+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      effects:
        'Sólo tiene el penalizador del tipo de movimiento cuando el adversario está apresado, se eliminan el resto.',
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.athletic.athleticism.final < 50) {
          errors.push('Atletismo 50+');
        }

        if (character.secondaries.vigor.featsOfStrength.final < 50) {
          errors.push('Proezas de fuerza 50+');
        }

        if (character.combat.attackAbility.final < 120) {
          errors.push('Ataque 120+');
        }

        if (character.combat.defensiveAbility < 120) {
          errors.push('Bloqueo o esquiva 120+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      effects:
        'Oponente apresado -5 a la característica usada al tratar de liberarse de la presa.',
      combatBonus: {
        attack: 10,
        dodge: 10,
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.vigor.featsOfStrength.final < 120) {
          errors.push('Proezas de fuerza 120+');
        }

        if (character.combat.defensiveAbility < 200) {
          errors.push('Ataque 200+');
        }

        return errors;
      },
    }),
  ];
}
