import { observer } from 'mobx-react';
import { useState } from 'react';
import { Tabs } from '../../../../../../../../../shared/application/design-system/components/Tabs/Tabs';
import { InnatePsychicPowers } from './components/InnatePsychicPowers';
import { KnownPsychicPowers } from './components/KnownPsychicPowers/KnownPsychicPowers';
import { PsychicDisciplines } from './components/PsychicDisciplines';
import { PsychicPotentialAndProject } from './components/PsychicPotentialAndProject';
import { PsychicPowers } from './components/PsychicPowers';
import { TotalPsychicPoints } from './components/TotalPsychicPoints';
import { Wrapper } from './Psychic.styled';

enum PsychicSubTab {
  Principal = 'Principal',
  KnownPowers = 'Poderes conocidos',
}

export const PsychicTab = observer(() => {
  const [subTab, setSubTab] = useState(PsychicSubTab.Principal);

  const handleChangeSubTab = (subTab: PsychicSubTab) => () => {
    setSubTab(subTab);
  };

  const PrincipalTab = () => (
    <>
      <TotalPsychicPoints />
      <PsychicPotentialAndProject />
      <PsychicDisciplines />
      <PsychicPowers />
      <InnatePsychicPowers />
    </>
  );

  const KnownPowersTab = () => (
    <>
      <KnownPsychicPowers />
    </>
  );

  const SubTabComponentMap: Record<PsychicSubTab, JSX.Element> = {
    [PsychicSubTab.Principal]: <PrincipalTab />,
    [PsychicSubTab.KnownPowers]: <KnownPowersTab />,
  };

  return (
    <Wrapper>
      <Tabs centered>
        {Object.values(PsychicSubTab).map((tab) => (
          <Tabs.Button
            key={tab}
            name={tab.toString()}
            selected={tab === subTab}
            onClick={handleChangeSubTab(tab)}
          />
        ))}
      </Tabs>
      {SubTabComponentMap[subTab]}
    </Wrapper>
  );
});
