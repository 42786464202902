import { MenuItem } from '../../../../../../../../../shared/application/design-system/components/Menu/useMenu';
import { useCharacterMenu } from '../CharacterMenu/useCharacterMenu';
import { SelectorValue } from './Selector.styled';

export const Selector = <T,>({
  value,
  valueAsText,
  items,
}: {
  value: T;
  valueAsText: string;
  items: MenuItem<T>[];
}) => {
  const { handleMenuOpen, Menu: Menu } = useCharacterMenu<T>();

  return (
    <>
      <SelectorValue onClick={handleMenuOpen(value)}>
        {valueAsText}
      </SelectorValue>
      <Menu items={items} />
    </>
  );
};
