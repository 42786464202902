import {
  BasicMartialArtGradeIds,
  buildAdvancedMartialArtArcaneGrade,
  buildAdvancedMartialArtBasicGrade,
} from '../../MartialArtGrades';
import {
  AdvancedMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { calculateHighestDamageFromKnownMartialArts } from './utils/calculateHighestDamageFromKnownMartialArts';
import { HitType } from '../../../../character/model/types/HitType';
import { calculateMartialArtsRequirements } from './utils/calculateMartialArtsRequirements';

const BasicMartialArtsRequirement = [
  {
    type: MartialArtType.Aikido,
    grade: BasicMartialArtGradeIds.Advanced,
  },
  {
    type: MartialArtType.Kuan,
    grade: BasicMartialArtGradeIds.Advanced,
  },
];

const ArcaneMartialArtsRequirement = [
  {
    type: MartialArtType.Aikido,
    grade: BasicMartialArtGradeIds.Supreme,
  },
  {
    type: MartialArtType.Kuan,
    grade: BasicMartialArtGradeIds.Supreme,
  },
];

export class ShephonMartialArt extends AdvancedMartialArt {
  readonly name = 'Shephon';

  readonly complexity = 4;

  readonly type = MartialArtType.Shephon;

  readonly level = MartialArtLevelType.Advanced;

  readonly grades = [
    buildAdvancedMartialArtBasicGrade({
      cmBonus: 10,
      effects: 'Aumenta el bono en defensa total a +60',
      combatBonus: {
        block: 20,
        dodge: 20,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          BasicMartialArtsRequirement,
        );

        if (character.combat.defensiveAbility < 200) {
          errors.push('Bloqueo o esquiva 200+');
        }

        return errors;
      },
    }),
    buildAdvancedMartialArtArcaneGrade({
      cmBonus: 20,
      effects: 'El bono asciende a +100.',
      masterBonus: {
        block: 20,
        dodge: 20,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          ArcaneMartialArtsRequirement,
        );

        if (character.combat.defensiveAbility < 300) {
          errors.push('Bloqueo o esquiva 300+');
        }

        return errors;
      },
    }),
  ];
}
