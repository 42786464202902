import { CharacterModel } from '../../../CharacterModel';

// CharactersCollaborations can develop offensive skills (attack, dodge, block) on each category
// up to the percentage of the total PDs spent on that category
export const calculateOffensiveSkillsExceedIssue = (
  character: CharacterModel,
): boolean => {
  return character.pd.categories.some((category) => {
    return category.combat.pdsSpent > category.combat.maxPdsThatCanBeSpent;
  });
};
