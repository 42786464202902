import { Tooltip } from '@mui/material';
import { observer } from 'mobx-react';
import { CategoryType } from '../../../../../../../../../../domain/aggregations/category-templates/CategoryTemplate';
import { SecondarySkillPDFieldModel } from '../../../../../../../../../../domain/character/model/parts/pd/parts/categories/parts/secondaries/SecondarySkillPDFieldModel';
import { SecondarySkillFieldModel } from '../../../../../../../../../../domain/character/model/parts/secondaries/utils/fields/SecondarySkillFieldModel';
import DoubleInput from '../../../../common/DoubleInput/DoubleInput';
import { Input } from '../../../../common/Input/Input';
import { Table } from '../../../../common/Table/Table';
import { SkillName } from '../common/FieldRow/PDFieldRow.styled';
import { Calculations } from '../../../../common/Calculations/Calculations';

type Props = {
  name: string;
  field: SecondarySkillFieldModel;

  pdField: SecondarySkillPDFieldModel;
};

export const SecondarySkillPDFieldRow = observer(
  ({ name, pdField, field }: Props) => {
    const isNovel = pdField.template.type === CategoryType.Novel;

    return (
      <Table.Row>
        <Table.Row.Cell>
          {name}
          <Tooltip title={pdField.type.toUpperCase()}>
            <SkillName>{pdField.type.substr(0, 3).toUpperCase()}</SkillName>
          </Tooltip>
        </Table.Row.Cell>
        <Table.Row.Cell>{pdField.pdsCost}</Table.Row.Cell>
        <Table.Row.Cell>
          <DoubleInput step={pdField.pdsCost} field={pdField} disableFinal />
        </Table.Row.Cell>
        <Table.Row.Cell $hideOnMobile>{field.allBonuses}</Table.Row.Cell>
        <Table.Row.Cell $hideOnMobile>
          {pdField.perCategory ? field.fromCategories : '-'}
        </Table.Row.Cell>
        <Table.Row.Cell>
          <Input value={field.special} setValue={(v) => field.setSpecial(v)} />
        </Table.Row.Cell>
        <Table.Row.Cell $hideOnMobile>
          <DoubleInput field={field.naturalBonusPoints} />
        </Table.Row.Cell>
        <Table.Row.Cell $hideOnMobile>
          <Input
            value={field.naturalAbilityPoints}
            setValue={(v) => field.setNaturalAbilityPoints(v)}
          />
        </Table.Row.Cell>
        {isNovel ? (
          <Table.Row.Cell $hideOnMobile>
            <Input
              value={field.novelBonus}
              setValue={(v) => field.setNovelBonus(v)}
            />
          </Table.Row.Cell>
        ) : undefined}
        <Table.Row.Cell>
          {field.needToBeDeveloped && !field.isDeveloped ? (
            '-'
          ) : (
            <Tooltip
              title={<Calculations calculations={field.getCalculations()} />}
            >
              <span>{field.final}</span>
            </Tooltip>
          )}
        </Table.Row.Cell>
      </Table.Row>
    );
  },
);
