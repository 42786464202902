import { SecondarySkill } from '../../character/model/types/SecondarySkill';
import { Archetype, CategoryTemplate, CategoryType } from './CategoryTemplate';

export class AssassinCategoryTemplate extends CategoryTemplate {
  name = 'Asesino';
  archetypes = [Archetype.Hunter];
  type = CategoryType.Assassin;

  readonly lifeMultiplier = 20;
  lifePoints = 5;
  protected turn = 10;
  protected martialKnowledge = 20;
  protected nativePsychicPoints = 1;

  offensiveSkillsLimitPercentage = 0.5;
  attackPDsCost = 2;
  blockPDsCost = 3;
  dodgePDsCost = 2;
  wearArmorPDsCost = 3;
  kiPDsCost = 2;
  kiAccumMultiplierPDsCost = 25;

  magicSkillsLimitPercentage = 0.5;
  zeonPDsCost = 3;
  aCTMultiplierPDsCost = 70;
  magicProjectionPDsCost = 3;
  summonPDsCost = 3;
  controlPDsCost = 3;
  bindPDsCost = 3;
  banishPDsCost = 3;

  psychicSkillsLimitPercentage = 0.5;
  psychicPointsPDsCost = 20;
  psychicProjectionPDsCost = 3;

  athleticSecondariesPDsCost = 2;
  socialSecondariesPDsCost = 2;
  perceptionSecondariesPDsCost = 1;
  intellectualSecondariesPDsCost = 3;
  vigorSecondariesPDsCost = 3;
  subterfugeSecondariesPDsCost = 2;
  creativeSecondariesPDsCost = 2;

  attackBonus = 5;

  calculateSecondarySkillPDReduction(skill: SecondarySkill): number {
    if (skill === SecondarySkill.Stealth) return 1;
    if (skill === SecondarySkill.Composure) return 2;
    if (skill === SecondarySkill.Memorize) return 2;

    return 0;
  }

  calculateSecondarySkillBonusPerCategory(skill: SecondarySkill): number {
    if (skill === SecondarySkill.Notice) return 10;
    if (skill === SecondarySkill.Search) return 10;
    if (skill === SecondarySkill.Hide) return 10;
    if (skill === SecondarySkill.Stealth) return 10;
    if (skill === SecondarySkill.Poisons) return 10;
    if (skill === SecondarySkill.Composure) return 10;
    if (skill === SecondarySkill.Traps) return 10;

    return 0;
  }
}
