import { model } from 'mobx-keystone';
import { KiPerceptionAdvantage } from '../../../../../aggregations/advantage/CommonAdvantages';
import { KiSkillType } from '../../../../../aggregations/ki-skill/KiSkill';
import { Calculation, NumberField } from '../../../utils/fields/NumberField';
import { CategoryType } from '../../../../../aggregations/category-templates/CategoryTemplate';

@model('Character/Ki/KiDetectionField')
export class KiDetectionFieldModel extends NumberField {
  get isDeveloped(): boolean {
    return !!this.character?.ki?.hasKiSkill(KiSkillType.KiDetection);
  }

  getCalculations(): Calculation[] {
    if (!this.character) return [];

    const calculations: Calculation[] = [];

    calculations.push(this.sum('Especial', this.special));

    calculations.push(
      this.sum('Advertir', this.character.secondaries.perceptive.notice.final),
    );

    calculations.push(
      this.sum(
        'Conocimiento marcial',
        this.character.ki.martialKnowledge.final,
      ),
    );

    calculations.push(this.div('Dividido entre 2', 2));

    calculations.push(this.floor(calculations, { hideOnZero: true }));

    if (
      this.character.creationPoints.containsAdvantage(KiPerceptionAdvantage)
    ) {
      calculations.push(
        this.sum('Ventaja: Percepción del Ki', 10 * this.character.level),
      );
    }

    const explorerCategory = this.character.categories.find(
      (c) => c.category === CategoryType.Explorer,
    );

    if (explorerCategory) {
      calculations.push(
        this.sum(
          'Por categoría de Explorer',
          explorerCategory.level.final * 10,
        ),
      );
    }

    return calculations;
  }
}
