import { ABFActorDataSourceData } from './FoundryVTTActor.types';

export type FoundryVTTActorData = {
  id: string;
  name: string;

  vtt: 'foundry';

  data: ABFActorDataSourceData;

  flags: Record<string, any>;
};

type FoundryVTTActorFlags =
  | {
      syncVersion: number;

      // CharactersCollaborations Vault character ID
      fromCharacterId: string;
    }
  | undefined;

export class FoundryVTTActor {
  public readonly id: string;

  public readonly vtt = 'foundry';

  private _name: string;

  public readonly data: ABFActorDataSourceData;

  private readonly _flags: Record<string, any>;

  static readonly CurrentSyncVersion = 1;

  constructor({
    id,
    name,
    data,
    flags,
  }: {
    id: string;
    name: string;
    data: ABFActorDataSourceData;
    flags: Record<string, any>;
  }) {
    this.id = id;
    this._name = name;
    this.data = data;
    this._flags = flags;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get syncVersion(): number {
    return this.flags?.syncVersion ?? 0;
  }

  get fromCharacterId(): string | undefined {
    return this.flags?.fromCharacterId;
  }

  get flags(): FoundryVTTActorFlags {
    return this._flags['characters-vault'];
  }

  setFlags(flags: FoundryVTTActorFlags) {
    this._flags['characters-vault'] = flags;
  }

  static fromPrimitives({
    id,
    name,
    data,
    flags,
  }: FoundryVTTActorData): FoundryVTTActor {
    return new FoundryVTTActor({ id, name, data, flags });
  }

  toPrimitives(): FoundryVTTActorData {
    return {
      id: this.id,
      name: this.name,
      vtt: this.vtt,
      data: this.data,
      flags: this._flags,
    };
  }

  asRemoteActor() {
    return {
      id: this.id,
      name: this.name,

      level: this.data.general.levels
        .map((l: any) => l.data.level)
        .reduce((a, b) => a + b, 0),

      categories: this.data.general.levels.map((c: any) => c.name).join(', '),

      primaries: {
        agility: this.data.characteristics.primaries.agility.value,
        strength: this.data.characteristics.primaries.strength.value,
        dexterity: this.data.characteristics.primaries.dexterity.value,
        constitution: this.data.characteristics.primaries.constitution.value,
        intelligence: this.data.characteristics.primaries.intelligence.value,
        perception: this.data.characteristics.primaries.perception.value,
        power: this.data.characteristics.primaries.power.value,
        willPower: this.data.characteristics.primaries.willPower.value,
      },
    };
  }
}
