import { Typography } from '@mui/material';
import styled from 'styled-components';
import { theme } from '../../../../../../../../shared/application/design-system/theme/theme';
import { styleMui } from '../../../../../../../../shared/application/design-system/utils/styleMui';

export const Wrapper = styled.div(
  ({ theme: { size, color, borderRadius, mixins } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    gridColumnGap: size.units(1),

    width: '100%',

    '& > *': {
      justifySelf: 'center',
      alignSelf: 'center',
    },

    padding: '0.5rem',

    boxSizing: 'border-box',

    backgroundColor: color.black(),

    borderRadius: borderRadius.medium,

    ...mixins.responsive.xs.downward({
      borderRadius: '0',
    }),
  }),
);

export const CharacterInfo = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
});

export const Name = styleMui(Typography)({
  color: theme.color.white(),

  textAlign: 'center',
});

export const Categories = styleMui(Typography)({
  color: theme.color.gray(),

  textAlign: 'center',
});

export const StyledInput = styled.input(({ theme: { size, color, font } }) => ({
  fontWeight: font.weight.bold,
  fontSize: size.units(2.5),
  fontFamily: font.family,
  backgroundColor: color.black(),
  color: color.white(),
  border: 'none',

  textAlign: 'center',

  '&:focus': {
    outline: 'none',
  },
}));
