import { useCallback, useEffect, useState } from 'react';
import { CharacterCollaboration } from '../../../../domain/CharacterCollaboration';
import { CharacterCollaborationService } from '../../../../infrastructure/CharacterCollaborationService';

export type UseCharacterCollaboration =
  | {
      loadingCharacterCollaborations: true;
      characterCollaborations: undefined;

      refreshingCharacterCollaborations: boolean;
      refreshCharacterCollaborations: () => Promise<void>;
    }
  | {
      loadingCharacterCollaborations: false;
      characterCollaborations: CharacterCollaboration[];

      refreshingCharacterCollaborations: boolean;
      refreshCharacterCollaborations: () => Promise<void>;
    };

export const useFetchCharacterCollaboration = (
  characterId: string,
): UseCharacterCollaboration => {
  const [characterCollaborations, setCharacterCollaborations] = useState<
    CharacterCollaboration[] | undefined
  >();

  const [loadingCharacterCollaborations, setLoadingCharacterCollaborations] =
    useState(true);

  const [
    refreshingCharacterCollaborations,
    setRefreshingCharacterCollaborations,
  ] = useState(false);

  const getCharacterCollaborations = useCallback(
    async (characterId: string) => {
      setLoadingCharacterCollaborations(characterCollaborations === undefined);

      try {
        const characterCollaborations =
          await CharacterCollaborationService.getCharacterCollaborationsByCharacterId(
            characterId,
          );
        setCharacterCollaborations(characterCollaborations);
      } finally {
        setLoadingCharacterCollaborations(false);
      }
    },
    [characterCollaborations],
  );

  useEffect(() => {
    getCharacterCollaborations(characterId);
  }, [characterId, getCharacterCollaborations]);

  const refreshCharacterCollaborations = async () => {
    setRefreshingCharacterCollaborations(true);

    try {
      await getCharacterCollaborations(characterId);
    } finally {
      setRefreshingCharacterCollaborations(false);
    }
  };

  if (loadingCharacterCollaborations || characterCollaborations === undefined) {
    return {
      loadingCharacterCollaborations: true,
      characterCollaborations: undefined,

      refreshingCharacterCollaborations: false,
      refreshCharacterCollaborations: refreshCharacterCollaborations,
    };
  }

  return {
    loadingCharacterCollaborations: false,
    characterCollaborations: characterCollaborations,

    refreshingCharacterCollaborations,
    refreshCharacterCollaborations: refreshCharacterCollaborations,
  };
};
