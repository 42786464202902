import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';
import {
  Advantage,
  AdvantageId,
  AdvantageLevel,
  AdvantageType,
  AdvantageVariation,
} from '../../../../aggregations/advantage/Advantage.types';

@model('Advantage')
export class AdvantageModel<T extends string = string> extends Model(<
  T extends string = string,
>() => ({
  id: prop<AdvantageId | undefined>().withSetter(),

  type: prop<AdvantageType>().withSetter(),

  name: prop<string>().withSetter(),

  levels: prop<AdvantageLevel[]>().withSetter(),
  levelId: prop<string | undefined>().withSetter(),

  variations: prop<AdvantageVariation<T>[] | undefined>().withSetter(),
  variationId: prop<string | undefined>().withSetter(),
}))<T> {
  @computed
  get variation(): AdvantageVariation<T> | undefined {
    return this.variations?.find((v) => v.id === this.variationId);
  }

  @computed
  get level(): AdvantageLevel | undefined {
    return this.levels.find((l) => l.id === this.levelId);
  }

  @computed
  get isCustom() {
    return this.id === undefined;
  }
}

export const createModelFromAdvantage = (advantage: Advantage) => {
  return new AdvantageModel({
    id: advantage.id,
    name: advantage.name,
    type: advantage.type,
    levels: advantage.levels,
    levelId: advantage.levels[0]?.id,
    variations: advantage.variations,
    variationId: advantage.variations?.[0]?.id,
  });
};
