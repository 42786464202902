export const adjetives = [
  'Amazing',
  'Awesome',
  'Blithesome',
  'Excellent',
  'Fabulous',
  'Fantastic',
  'Favorable',
  'Fortuitous',
  'Great',
  'Incredible',
  'Ineffable',
  'Mirthful',
  'Outstanding',
  'Perfect',
  'Propitious',
  'Remarkable',
  'Smart',
  'Spectacular',
  'Splendid',
  'Stellar',
  'Stupendous',
  'Super',
  'Ultimate',
  'Unbelievable',
  'Wondrous',
  'adaptable',
  'adventurous',
  'affable',
  'affectionate',
  'agreeable',
  'ambitious',
  'amiable',
  'amicable',
  'amusing',
  'brave',
  'bright',
  'broad-minded',
  'calm',
  'careful',
  'charming',
  'communicative',
  'compassionate',
  'conscientious',
  'considerate',
  'convivial',
  'courageous',
  'courteous',
  'creative',
  'decisive',
  'determined',
  'diligent',
  'diplomatic',
  'discreet',
  'dynamic',
  'easygoing',
  'emotional',
  'energetic',
  'enthusiastic',
  'exuberant',
  'fair-minded',
  'faithful',
  'fearless',
  'forceful',
  'frank',
  'friendly',
  'funny',
  'generous',
  'gentle',
  'good',
  'gregarious',
  'hard-working',
  'helpful',
  'honest',
  'humorous',
  'imaginative',
  'impartial',
  'independent',
  'intellectual',
  'intelligent',
  'intuitive',
  'inventive',
  'kind',
  'loving',
  'loyal',
  'modest',
  'neat',
  'nice',
  'optimistic',
  'passionate',
  'patient',
  'persistent ',
  'pioneering',
  'philosophical',
  'placid',
  'plucky',
  'polite',
  'powerful',
  'practical',
  'quick-witted',
  'quiet',
  'rational',
  'reliable',
  'reserved',
  'resourceful',
  'romantic',
  'sincere',
  'sociable',
  'straightforward',
  'sympathetic',
  'thoughtful',
  'tidy',
  'tough',
  'unassuming',
  'understanding',
  'versatile',
  'warmhearted',
  'willing',
  'witty',
  'other',
  'new',
  'good',
  'old',
  'little',
  'great',
  'small',
  'young',
  'long',
  'black',
  'high',
  'only',
  'big',
  'white',
  'political',
  'right',
  'large',
  'real',
  'sure',
  'different',
  'important',
  'public',
  'possible',
  'full',
  'whole',
  'certain',
  'human',
  'major',
  'military',
  'bad',
  'social',
  'dead',
  'true',
  'economic',
  'open',
  'early',
  'free',
  'national',
  'strong',
  'hard',
  'special',
  'clear',
  'local',
  'private',
  'wrong',
  'late',
  'short',
  'poor',
  'recent',
  'dark',
  'fine',
  'foreign',
  'ready',
  'red',
  'cold',
  'low',
  'heavy',
  'serious',
  'single',
  'personal',
  'difficult',
  'left',
  'blue',
  'federal',
  'necessary',
  'general',
  'easy',
  'likely',
  'beautiful',
  'happy',
  'past',
  'hot',
  'close',
  'common',
  'afraid',
  'simple',
  'natural',
  'main',
  'various',
  'available',
  'nice',
  'present',
  'final',
  'sorry',
  'entire',
  'current',
  'similar',
  'deep',
  'huge',
  'rich',
  'nuclear',
  'empty',
  'strange',
  'quiet',
  'front',
  'wide',
  'modern',
  'concerned',
  'green',
  'very',
  'alone',
  'particular',
  'bright',
  'supposed',
  'basic',
  'medical',
  'aware',
  'total',
  'financial',
  'legal',
  'original',
  'international',
  'soft',
  'alive',
  'interested',
  'tall',
  'warm',
  'popular',
  'tiny',
  'top',
  'normal',
  'powerful',
  'silent',
  'religious',
  'impossible',
  'quick',
  'safe',
  'thin',
  'familiar',
  'gray',
  'fresh',
  'physical',
  'individual',
  'willing',
  'crazy',
  'sick',
  'angry',
  'perfect',
  'tired',
  'wild',
  'moral',
  'brown',
  'dangerous',
  'famous',
  'married',
  'terrible',
  'successful',
  'fair',
  'professional',
  'official',
  'obvious',
  'glad',
  'central',
  'chief',
  'effective',
  'light',
  'complete',
  'interesting',
  'thick',
  'proper',
  'involved',
  'responsible',
  'narrow',
  'civil',
  'industrial',
  'dry',
  'yellow',
  'specific',
  'sharp',
  'sudden',
  'direct',
  'following',
  'growing',
  'significant',
  'traditional',
  'slow',
  'previous',
  'vast',
  'surprised',
  'busy',
  'usual',
  'clean',
  'funny',
  'regular',
  'scientific',
  'ordinary',
  'ancient',
  'senior',
  'sweet',
  'future',
  'annual',
  'secret',
  'equal',
  'independent',
  'wonderful',
  'tough',
  'broad',
  'additional',
  'careful',
  'domestic',
  'brief',
  'enormous',
  'commercial',
  'grand',
  'average',
  'sexual',
  'nervous',
  'pale',
  'immediate',
  'critical',
  'proud',
  'like',
  'complex',
  'separate',
  'considerable',
  'still',
  'extra',
  'expensive',
  'guilty',
  'active',
  'mad',
  'asleep',
  'wooden',
  'cool',
  'presidential',
  'apparent',
  'weak',
  'essential',
  'living',
  'pretty',
  'cultural',
  'useful',
  'actual',
  'unusual',
  'daily',
  'potential',
  'wet',
  'solid',
  'lovely',
  'comfortable',
  'formal',
  'outside',
  'massive',
  'sad',
  'corporate',
  'distant',
  'loose',
  'rare',
  'stupid',
  'visible',
  'liberal',
  'flat',
  'pleased',
  'pure',
  'curious',
  'practical',
  'upper',
  'technical',
  'male',
  'appropriate',
  'fat',
  'just',
  'due',
  'mere',
  'handsome',
  'mental',
  'conservative',
  'positive',
  'leading',
  'naked',
  'false',
  'drunk',
  'dirty',
  'friendly',
  'constant',
  'well',
  'used',
  'emotional',
  'internal',
  'odd',
  'historical',
  'female',
  'ill',
  'broken',
  'capable',
  'southern',
  'pleasant',
  'bare',
  'minor',
  'eager',
  'lucky',
  'urban',
  'steady',
  'fiscal',
  'rough',
  'primary',
  'reasonable',
  'typical',
  'inner',
  'favorite',
  'attractive',
  'slight',
  'innocent',
  'limited',
  'straight',
  'pink',
  'excellent',
  'double',
  'dramatic',
  'violent',
  'honest',
  'electric',
  'fellow',
  'substantial',
  'opposite',
  'awful',
  'severe',
  'joint',
  'armed',
  'hungry',
  'remarkable',
  'increased',
  'gentle',
  'illegal',
  'middle',
  'bitter',
  'mass',
  'permanent',
  'increasing',
  'damn',
  'golden',
  'correct',
  'intense',
  'round',
  'northern',
  'proposed',
  'so-called',
  'criminal',
  'healthy',
  'plain',
  'vital',
  'blind',
  'native',
  'intellectual',
  'unknown',
  'extreme',
  'existing',
  'raw',
  'prime',
  'brilliant',
  'sensitive',
  'extraordinary',
  'sufficient',
  'remaining',
  'ultimate',
  'unique',
  'royal',
  'initial',
  'negative',
  'fundamental',
  'nearby',
  'smart',
  'strategic',
  'educational',
  'unlikely',
  'smooth',
  'modest',
  'conventional',
  'giant',
  'scared',
  'cheap',
  'dear',
  'delicate',
  'anxious',
  'valuable',
  'standard',
  'desperate',
  'lonely',
  'diplomatic',
  'firm',
  'wise',
  'principal',
  'congressional',
  'occasional',
  'ugly',
  'vice',
  'radical',
  'faint',
  'working',
  'absolute',
  'intelligent',
  'racial',
  'mutual',
  'silly',
  'fast',
  'musical',
  'tight',
  'complicated',
  'numerous',
  'crucial',
  'square',
  'contemporary',
  'bloody',
  'western',
  'endless',
  'inevitable',
  'environmental',
  'constitutional',
  'rapid',
  'worried',
  'lost',
  'genuine',
  'temporary',
  'democratic',
  'rural',
  'regional',
  'given',
  'painful',
  'literary',
  'chemical',
  'sophisticated',
  'decent',
  'academic',
  'awake',
  'conscious',
  'revolutionary',
  'surprising',
  'elderly',
  'agricultural',
  'psychological',
  'pregnant',
  'live',
  'adequate',
  'superior',
  'grateful',
  'prominent',
  'frightened',
  'remote',
  'overall',
  'stiff',
  'harsh',
  'electronic',
  'spiritual',
  'okay',
  'closed',
  'excited',
  'convinced',
  'long-term',
  'unexpected',
  'dull',
  'evident',
  'civilian',
  'mysterious',
  'romantic',
  'impressive',
  'continuing',
  'exciting',
  'logical',
  'peculiar',
  'exact',
  'widespread',
  'foolish',
  'extensive',
  'evil',
  'continued',
  'confident',
  'generous',
  'legislative',
  'stable',
  'vulnerable',
  'elegant',
  'embarrassed',
  'hostile',
  'efficient',
  'blond',
  'dumb',
  'advanced',
  'defensive',
  'outer',
  'neat',
  'estimated',
  'wealthy',
  'dying',
  'loud',
  'creative',
  'acceptable',
  'unhappy',
  'sheer',
  'competitive',
  'concrete',
  'reluctant',
  'fucking',
  'precious',
  'tremendous',
  'burning',
  'precise',
  'uncertain',
  'holy',
  'artificial',
  'vague',
  'ideal',
  'universal',
  'moderate',
  'subtle',
  'mild',
  'peaceful',
  'assistant',
  'invisible',
  'casual',
  'crowded',
  'crude',
  'running',
  'classic',
  'controversial',
  'ridiculous',
  'frequent',
  'grim',
  'accurate',
  'detailed',
  'goddamn',
  'fun',
  'fierce',
  'cruel',
  'incredible',
  'blank',
  'dim',
  'suitable',
  'classical',
  'elaborate',
  'collective',
  'eastern',
  'legitimate',
  'aggressive',
  'rear',
  'administrative',
  'automatic',
  'dependent',
  'ashamed',
  'distinct',
  'fit',
  'clever',
  'brave',
  'ethnic',
  'maximum',
  'relative',
  'primitive',
  'uncomfortable',
  'profound',
  'sacred',
  'biological',
  'identical',
  'furious',
  'loyal',
  'rational',
  'mechanical',
  'mean',
  'naval',
  'noble',
  'ambitious',
  'purple',
  'historic',
  'dominant',
  'suburban',
  'developing',
  'calm',
  'frozen',
  'subsequent',
  'charming',
  'damp',
  'fixed',
  'rigid',
  'offensive',
  'electrical',
  'shy',
  'continuous',
  'urgent',
  'weary',
  'immense',
  'splendid',
  'downtown',
  'uneasy',
  'disappointed',
  'helpless',
  'voluntary',
  'polite',
  'junior',
  'gross',
  'striking',
  'overwhelming',
  'unconscious',
  'steep',
  'outstanding',
  'tender',
  'tragic',
  'costly',
  'miserable',
  'near',
  'useless',
  'welcome',
  'external',
  'helpful',
  'weekly',
  'middle-aged',
  'suspicious',
  'old-fashioned',
  'technological',
  'damned',
  'awkward',
  'visual',
  'organized',
  'ideological',
  'orange',
  'horrible',
  'strict',
  'magnificent',
  'deadly',
  'dusty',
  'mighty',
  'puzzled',
  'bold',
  'global',
  'passing',
  'magic',
  'fond',
  'judicial',
  'missing',
  'definite',
  'changing',
  'rubber',
  'theoretical',
  'satisfied',
  'promising',
  'abstract',
  'excessive',
  'comparable',
  'fatal',
  'distinguished',
  'inadequate',
  'slender',
  'artistic',
  'known',
  'sympathetic',
  'favorable',
  'cheerful',
  'faithful',
  'delighted',
  'unnecessary',
  'sole',
  'cautious',
  'productive',
  'reliable',
  'patient',
  'sensible',
  'desirable',
  'depressed',
  'atomic',
  'able',
  'instant',
  'relevant',
  'alien',
  'spectacular',
  'lesser',
  'swift',
  'comic',
  'enthusiastic',
  'marvelous',
  'experimental',
  'weird',
  'retired',
  'fascinating',
  'content',
  'medieval',
  'inclined',
  'bored',
  'ruling',
  'flying',
  'consistent',
  'organic',
  'alleged',
  'grave',
  'smiling',
  'realistic',
  'amazing',
  'exotic',
  'symbolic',
  'confused',
  'underground',
  'spare',
  'philosophical',
  'vigorous',
  'troubled',
  'shallow',
  'amused',
  'lively',
  'genetic',
  'impatient',
  'brutal',
  'solar',
  'unfair',
  'formidable',
  'tense',
  'unfortunate',
  'minimum',
  'sleeping',
  'secondary',
  'shiny',
  'jealous',
  'insane',
  'gay',
  'vivid',
  'wounded',
  'hurt',
  'intimate',
  'monthly',
  'sour',
  'socialist',
  'worthy',
  'preliminary',
  'colonial',
  'middle-class',
  'alternative',
  'influential',
  'unpleasant',
  'comprehensive',
  'devoted',
  'upset',
  'secure',
  'absurd',
  'neutral',
  'frightening',
  'profitable',
  'fragile',
  'civilized',
  'slim',
  'partial',
  'added',
  'fearful',
  'optimistic',
  'isolated',
  'eternal',
  'vocal',
  'beloved',
  'alert',
  'verbal',
  'rising',
  'skilled',
  'antique',
  'municipal',
  'written',
  'restless',
  'outdoor',
  'governmental',
  'driving',
  'sore',
  'informal',
  'loving',
  'retail',
  'hidden',
  'determined',
  'monetary',
  'convenient',
  'thoughtful',
  'colored',
  'progressive',
  'bizarre',
  'sweeping',
  'fancy',
  'expected',
  'fantastic',
  'editorial',
  'intact',
  'bottom',
  'multiple',
  'well-known',
  'nasty',
  'protective',
  'acute',
  'combined',
  'related',
  'fortunate',
  'earnest',
  'divine',
  'passionate',
  'icy',
  'noisy',
  'vicious',
  'dreadful',
  'apt',
  'boring',
  'unprecedented',
  'decisive',
  'sunny',
  'marked',
  'experienced',
  'disturbing',
  'satisfactory',
  'sober',
  'random',
  'electoral',
  'shocked',
  'deliberate',
  'coming',
  'orderly',
  'surrounding',
  'unwilling',
  'inherent',
  'mixed',
  'naive',
  'dense',
  'hopeless',
  'aesthetic',
  'supreme',
  'encouraging',
  'institutional',
  'solemn',
  'stubborn',
  'required',
  'relaxed',
  'bald',
  'frantic',
  'exclusive',
  'rotten',
  'filthy',
  'flexible',
  'explicit',
  'glorious',
  'lean',
  'ignorant',
  'extended',
  'embarrassing',
  'architectural',
  'mortal',
  'corrupt',
  'hopeful',
  'regulatory',
  'valid',
  'characteristic',
  'tribal',
  'capitalist',
  'diverse',
  'functional',
  'improved',
  'ironic',
  'graceful',
  'unaware',
  'respectable',
  'eligible',
  'lousy',
  'established',
  'postwar',
  'objective',
  'wary',
  'elementary',
  'moving',
  'superb',
  'cute',
  'minimal',
  'meaningful',
  'notable',
  'structural',
  'developed',
  'rolling',
  'fashionable',
  'persistent',
  'distinctive',
  'terrific',
  'thorough',
  'skeptical',
  'secular',
  'chronic',
  'level',
  'everyday',
  'visiting',
  'infinite',
  'short-term',
  'terrorist',
  'youthful',
  'unemployed',
  'forced',
  'liquid',
  'explosive',
  'rude',
  'colorful',
  'renewed',
  'semantic',
  'astonishing',
  'passive',
  'heroic',
  'gleaming',
  'indifferent',
  'vertical',
  'prior',
  'anonymous',
  'absent',
  'customary',
  'mobile',
  'uniform',
  'solitary',
  'probable',
  'amazed',
  'petty',
  'bleak',
  'athletic',
  'tentative',
  'harmless',
  'ample',
  'right-wing',
  'polished',
  'obscure',
  'sincere',
  'dried',
  'intensive',
  'equivalent',
  'convincing',
  'idle',
  'vacant',
  'mature',
  'amusing',
  'competent',
  'ominous',
  'savage',
  'motionless',
  'tropical',
  'blunt',
  'drunken',
  'delicious',
  'lazy',
  'ragged',
  'longtime',
  'nationwide',
  'startling',
  'civic',
  'freezing',
  'muscular',
  'circular',
  'imperial',
  'irrelevant',
  'countless',
  'gloomy',
  'startled',
  'disastrous',
  'skinny',
  'hollow',
  'upward',
  'ethical',
  'underlying',
  'careless',
  'wholesale',
  'abandoned',
  'unfamiliar',
  'mandatory',
  'imaginary',
  'bewildered',
  'annoyed',
  'magnetic',
  'dazzling',
  'lengthy',
  'stern',
  'surgical',
  'clinical',
  'full-time',
  'metropolitan',
  'moist',
  'unlike',
  'doubtful',
  'prosperous',
  'keen',
  'awesome',
  'humble',
  'interior',
  'psychiatric',
  'clumsy',
  'outraged',
  'theatrical',
  'educated',
  'gigantic',
  'scattered',
  'privileged',
  'sleepy',
  'battered',
  'meaningless',
  'predictable',
  'gradual',
  'miniature',
  'radioactive',
  'prospective',
  'aging',
  'destructive',
  'authentic',
  'portable',
  'bearded',
  'balanced',
  'shining',
  'spontaneous',
  'bureaucratic',
  'inferior',
  'sturdy',
  'cynical',
  'exquisite',
  'talented',
  'immune',
  'imaginative',
  'ripe',
  'shared',
  'kind',
  'parliamentary',
  'glowing',
  'frail',
  'astonished',
  'forward',
  'inside',
  'operational',
  'faded',
  'closing',
  'pro',
  'coastal',
  'shrewd',
  'preoccupied',
  'celebrated',
  'wicked',
  'bourgeois',
  'marginal',
  'transparent',
  'dynamic',
  'psychic',
  'plump',
  'coarse',
  'bleeding',
  'striped',
  'eventual',
  'residential',
  'hysterical',
  'pathetic',
  'planned',
  'fake',
  'imminent',
  'sentimental',
  'stunning',
  'worldwide',
  'militant',
  'sizable',
  'representative',
  'incapable',
  'provincial',
  'poetic',
  'injured',
  'tactical',
  'selfish',
  'winning',
  'foul',
  'repeated',
  'novel',
  'dubious',
  'part-time',
  'abrupt',
  'lone',
  'overseas',
  'grey',
  'varied',
  'cooperative',
  'muddy',
  'scheduled',
  'legendary',
  'arrogant',
  'conspicuous',
  'varying',
  'devastating',
  'vulgar',
  'martial',
  'amateur',
  'mathematical',
  'deaf',
  'scarce',
  'specialized',
  'honorable',
  'outrageous',
  'confidential',
  'fallen',
  'goddamned',
  'five-year',
  'feminine',
  'monstrous',
  'brisk',
  'systematic',
  'exhausted',
  'frank',
  'lunar',
  'daring',
  'shadowy',
  'respected',
  'stark',
  'accepted',
  'successive',
  'pending',
  'prolonged',
  'unseen',
  'uniformed',
  'wretched',
  'sullen',
  'arbitrary',
  'drastic',
  'crooked',
  'resulting',
  'intricate',
  'unpredictable',
  'printed',
  'utter',
  'satisfying',
  'delightful',
  'linguistic',
  'shabby',
  'statistical',
  'accessible',
  'prestigious',
  'trivial',
  'waiting',
  'futile',
  'prepared',
  'aged',
  'misleading',
  'cognitive',
  'shocking',
  'childish',
  'elected',
  'magical',
  'forthcoming',
  'exceptional',
  'gifted',
  'stricken',
  'fiery',
  'cardboard',
  'shaky',
  'conflicting',
  'commanding',
  'starving',
  'accustomed',
  'rocky',
  'long-range',
  'floating',
  'sinister',
  'potent',
  'phony',
  'lasting',
  'understandable',
  'curved',
  'barren',
  'lethal',
  'toxic',
  'deserted',
  'ambiguous',
  'notorious',
  'synthetic',
  'worthwhile',
  'imported',
  'intent',
  'reduced',
  'painted',
  'taut',
  'sociological',
  'questionable',
  'crisp',
  'pointed',
  'harmful',
  'horizontal',
  'rival',
  'somber',
  'benign',
  'prevailing',
  'selected',
  'organizational',
  'excess',
  'dedicated',
  'veteran',
  'implicit',
  'prudent',
  'plausible',
  'confusing',
  'smoking',
  'large-scale',
  'subdued',
  'constructive',
  'marital',
  'scarlet',
  'rugged',
  'darkened',
  'untouched',
  'above',
  'matching',
  'covert',
  'communal',
  'sticky',
  'affluent',
  'energetic',
  'stale',
  'controlled',
  'qualified',
  'reminiscent',
  'shut',
  'blonde',
  'handy',
  'ritual',
  'straightforward',
  'terminal',
  'dizzy',
  'sane',
  'twisted',
  'occupied',
  'finished',
  'opposing',
  'sly',
  'depressing',
  'irregular',
  'marine',
  'communist',
  'obscene',
  'wrinkled',
  'unsuccessful',
  'gracious',
  'static',
  'consecutive',
  'reserve',
  'exposed',
  'scholarly',
  'sleek',
  'reckless',
  'oral',
  'comforting',
  'pressing',
  'swollen',
  'viable',
  'carved',
  'obsessed',
  'projected',
  'hideous',
  'unthinkable',
  'mock',
  'susceptible',
  'respective',
  'goddam',
  'downward',
  'memorable',
  'worn',
  'raised',
  'glittering',
  'beneficial',
  'lingering',
  'patriotic',
  'stunned',
  'hairy',
  'worrying',
  'lighted',
  'sexy',
  'abundant',
  'tangled',
  'perpetual',
  'irresistible',
  'terrified',
  'compelling',
  'unmistakable',
  'feeble',
  'uneven',
  'trained',
  'folded',
  'relentless',
  'killed',
  'gorgeous',
  'conservation',
  'serene',
  'eerie',
  'premature',
  'dismal',
  'competing',
  'risky',
  'unacceptable',
  'indirect',
  'witty',
  'muffled',
  'feasible',
  'interstate',
  'heated',
  'uncommon',
  'accidental',
  'queer',
  'innovative',
  'parallel',
  'fried',
  'unnatural',
  'cracked',
  'persuasive',
  'integrated',
  'ongoing',
  'homosexual',
  'sound',
  'fertile',
  'canned',
  'preceding',
  'worldly',
  'onstage',
  'declining',
  'advisory',
  'juvenile',
  'slippery',
  'numb',
  'postal',
  'olive',
  'eccentric',
  'lay',
  'chilly',
  'shrill',
  'ceremonial',
  'registered',
  'boiling',
  'contradictory',
  'irresponsible',
  'then',
  'industrialized',
  'obsolete',
  'rusty',
  'inflationary',
  'split',
  'discreet',
  'intolerable',
  'barefoot',
  'territorial',
  'outspoken',
  'audible',
  'adverse',
  'associate',
  'impending',
  'decorative',
  'luminous',
  'two-year',
  'expanding',
  'unchanged',
  'outstretched',
  'momentary',
  'good-looking',
  'cunning',
  'overnight',
  'sprawling',
  'unbelievable',
  'bland',
  'liable',
  'terrifying',
  'televised',
  'appealing',
  'breathless',
  'alarming',
  'supporting',
  'greasy',
  'affirmative',
  'guiding',
  'homeless',
  'triumphant',
  'rainy',
  'stolen',
  'empirical',
  'timid',
  'provocative',
  'knowledgeable',
  'pragmatic',
  'touching',
  'desired',
  'amiable',
  'attempted',
  'humane',
  'adjacent',
  'superficial',
  'greedy',
  'assorted',
  'elusive',
  'ruthless',
  'lush',
  'soothing',
  'imposing',
  'preferred',
  'lavish',
  'pervasive',
  'managing',
  'sandy',
  'inappropriate',
  'desolate',
  'nude',
  'reassuring',
  'shimmering',
  'first-class',
  'unfinished',
  'insistent',
  'comparative',
  'conceivable',
  'admirable',
  'courageous',
  'aristocratic',
  'meager',
  'subjective',
  'vain',
  'disgusted',
  'dual',
  'towering',
  'responsive',
  'ailing',
  'compact',
  'torn',
  'sortal',
  'entertaining',
  'dreary',
  'metallic',
  'tedious',
  'irrational',
  'immoral',
  'teen-age',
  'interim',
  'jagged',
  'selective',
  'volatile',
  'cozy',
  'unanimous',
  'unlimited',
  'hired',
  'cosmic',
  'indoor',
  'retarded',
  'gold',
  'fabulous',
  'dignified',
  'long-distance',
  'high-school',
  'classified',
  'luxurious',
  'insufficient',
  'pious',
  'incomplete',
  'oblivious',
  'imperialist',
  'stately',
  'lifelong',
  'subordinate',
  'extravagant',
  'intrinsic',
  'unpopular',
  'scant',
  'surplus',
  'radiant',
  'ruined',
  'grotesque',
  'hazardous',
  'disabled',
  'intriguing',
  'worthless',
  'reported',
  'hoarse',
  'utmost',
  'muted',
  'bony',
  'disgusting',
  'monumental',
  'pleasing',
  'sterile',
  'agreeable',
  'three-year',
  'tricky',
  'lucrative',
  'respectful',
  'inexpensive',
  'bulky',
  'troublesome',
  'affectionate',
  'coherent',
  'unreasonable',
  'nineteenth-century',
  'curly',
  'indispensable',
  'nursing',
  'incompetent',
  'governing',
  'alternate',
  'suspected',
  'left-wing',
  'refined',
  'overt',
  'chilling',
  'virtual',
  'devoid',
  'perverse',
  'enduring',
  'outright',
  'overhead',
  'unnoticed',
  'nonprofit',
  'pointless',
  'appalling',
  'dental',
  'chosen',
  'enlightened',
  'robust',
  'commonplace',
  'damaging',
  'conscientious',
  'eloquent',
  'erratic',
  'applied',
  'merry',
  'ardent',
  'flowing',
  'incoming',
  'chaotic',
  'noticeable',
  'pitiful',
  'locked',
  'swelling',
  'definitive',
  'homemade',
  'super',
  'pronounced',
  'kindly',
  'prone',
  'attentive',
  'unstable',
  'unrelated',
  'charitable',
  'armored',
  'unclear',
  'tangible',
  'medium',
  'winding',
  'slick',
  'credible',
  'frustrating',
  'shifting',
  'spacious',
  'day-to-day',
  'surviving',
  'expanded',
  'arid',
  'unwanted',
  'unbearable',
  'hesitant',
  'recognizable',
  'multinational',
  'abdominal',
  'murderous',
  'glossy',
  'mute',
  'working-class',
  'insignificant',
  'ingenious',
  'masculine',
  'blessed',
  'gaunt',
  'miraculous',
  'unconstitutional',
  'parental',
  'rigorous',
  'bodily',
  'impersonal',
  'backward',
  'computerized',
  'four-year',
  'unmarried',
  'wry',
  'resident',
  'luxury',
  'high-level',
  'partisan',
  'powerless',
  'seasonal',
  'self-conscious',
  'triple',
  'onetime',
  'ecological',
  'periodic',
  'racist',
  'exaggerated',
  'facial',
  'erotic',
  'unreal',
  'durable',
  'manual',
  'rounded',
  'concentrated',
  'literal',
  'mystical',
  'stimulating',
  'staggering',
  'tempting',
  'last-minute',
  'erect',
  'feudal',
  'head',
  'emerging',
  'hind',
  'brooding',
  'candid',
  'paranoid',
  'defective',
  'linear',
  'immortal',
  'shattered',
  'unsure',
  'swinging',
  'compatible',
  'ghastly',
  'investigative',
  'rosy',
  'convicted',
  'sensational',
  'committed',
  'makeshift',
  'tolerant',
  'forceful',
  'supernatural',
  'joyous',
  'limp',
  'improper',
  'hanging',
  'sliding',
  'renowned',
  'tattered',
  'nonexistent',
  'supportive',
  'frustrated',
  'undercover',
  'handicapped',
  'apprehensive',
  'plentiful',
  'authoritative',
  'sustained',
  'disappointing',
  'hereditary',
  'photographic',
  'impoverished',
  'ornate',
  'respiratory',
  'substantive',
  'acting',
  'nutritional',
  'unofficial',
  'innumerable',
  'prevalent',
  'dire',
  'menacing',
  'outward',
  'brittle',
  'hasty',
  'sparkling',
  'sled',
  'geographical',
  'therapeutic',
  'melancholy',
  'adolescent',
  'hearty',
  'disturbed',
  'sweaty',
  'poisonous',
  'paid',
  'ineffective',
  'humorous',
  'burly',
  'rebellious',
  'reddish',
  'stout',
  'teenage',
  'eminent',
  'rhythmic',
  'physiological',
  'guaranteed',
  'opaque',
  'folding',
  'fleeting',
  'full-scale',
  'low-income',
  'infectious',
  'stringent',
  'stained',
  'beige',
  'stirring',
  'soaring',
  'glamorous',
  'airborne',
  'improbable',
  'austere',
  'anticipated',
  'designated',
  'oval',
  'restrictive',
  'yearly',
  'precarious',
  'relieved',
  'said',
  'feverish',
  'occupational',
  'holding',
  'speculative',
  'abnormal',
  'challenging',
  'healing',
  'boyish',
  'forbidding',
  'divorced',
  'famed',
  'sluggish',
  'struggling',
  'united',
  'undesirable',
  'steaming',
  'consulting',
  'answering',
  'recreational',
  'accompanying',
  'cramped',
  'journalistic',
  'neighboring',
  'fictional',
  'chopped',
  'phenomenal',
  'bankrupt',
  'illicit',
  'advancing',
  'upcoming',
  'racing',
  'protected',
  'padded',
  'venerable',
  'fuzzy',
  'behavioral',
  'roast',
  'mocking',
  'reactionary',
  'inefficient',
  'packed',
  'sloppy',
  'sparse',
  'foster',
  'revealing',
  'reverse',
  'gaping',
  'blue-collar',
  'thankful',
  'down',
  'unimportant',
  'traveling',
  'corresponding',
  'maternal',
  'autonomous',
  'conceptual',
  'smoky',
  'baked',
  'stuffed',
  'murky',
  'totalitarian',
  'ghostly',
  'seeming',
  'flickering',
  'sensual',
  'clenched',
  'offshore',
  'stinging',
  'oppressive',
  'strained',
  'messy',
  'executive',
  'evolutionary',
  'theological',
  'damaged',
  'unrealistic',
  'rectangular',
  'off',
  'mainstream',
  'benevolent',
  'thirsty',
  'blinding',
  'loaded',
  'applicable',
  'unused',
  'crushed',
  'tan',
  'factual',
  'involuntary',
  'brand-new',
  'akin',
  'scary',
  'modified',
  'mindless',
  'born',
  'feminist',
  'integral',
  'uncanny',
  'aloof',
  'spreading',
  'watery',
  'playful',
  'stocky',
  'wasted',
  'compulsory',
  'indignant',
  'pertinent',
  'incredulous',
  'simultaneous',
  'turbulent',
  'framed',
  'aching',
  'falling',
  'cardiac',
  'trim',
  'silvery',
  'accused',
  'pastoral',
  'barbed',
  'adjoining',
  'inspired',
  'courteous',
  'skillful',
  'majestic',
  'gilded',
  'published',
  'perennial',
  'upright',
  'seasoned',
  'continual',
  'papal',
  'victorious',
  'optical',
  'ecstatic',
  'agonizing',
  'shameful',
  'expressive',
  'inconsistent',
  'insulting',
  'cloudy',
  'defiant',
  'restricted',
  'approaching',
  'aggregate',
  'orthodox',
  'unified',
  'all-out',
  'wooded',
  'nationalist',
  'favored',
  'lofty',
  'assured',
  'smug',
  'earthly',
  'improving',
  'instrumental',
  'stray',
  'clandestine',
  'managerial',
  'animated',
  'intended',
  'flawed',
  'bent',
  'clerical',
  'outgoing',
  'righteous',
  'unspoken',
  'poignant',
  'faulty',
  'defeated',
  'authoritarian',
  'treacherous',
  'catastrophic',
  'refreshing',
  'unidentified',
  'suicidal',
  'sickly',
  'disciplined',
  'meticulous',
  'preferable',
  'trusted',
  'hectic',
  'husky',
  'distraught',
  'select',
  'snowy',
  'ferocious',
  'crumpled',
  'humiliating',
  'divided',
  'crippled',
  'infamous',
  'chic',
  'broke',
  'sovereign',
  'continental',
  'idealistic',
  'first-rate',
  'guarded',
  'learned',
  'nameless',
  'runaway',
  'metaphysical',
  'senseless',
  'boiled',
  'needy',
  'silver',
  'recorded',
  'polar',
  'real-estate',
  'stormy',
  'incomprehensible',
  'wiry',
  'raging',
  'composite',
  'flamboyant',
  'crimson',
  'reproductive',
  'intermediate',
  'ubiquitous',
  'repressive',
  'hefty',
  'listening',
  'good-natured',
  'parochial',
  'stylish',
  'high-tech',
  'flaming',
  'coronary',
  'overweight',
  'bathing',
  'three-day',
  'tidy',
  'beleaguered',
  'manifest',
  'ludicrous',
  'indigenous',
  'adamant',
  'placid',
  'inept',
  'exuberant',
  'stony',
  'salty',
  'seductive',
  'accomplished',
  'impassive',
  'grazing',
  'congenial',
  'misguided',
  'wide-eyed',
  'revised',
  'bass',
  'sonic',
  'budgetary',
  'halfway',
  'ensuing',
  'admiring',
  'palpable',
  'nightly',
  'hooded',
  'best-known',
  'eighteenth-century',
  'dissident',
  'morbid',
  'incumbent',
  'demanding',
  'inexperienced',
  'hazy',
  'revolving',
  'rented',
  'disadvantaged',
  'innate',
  'dietary',
  'minute',
  'cultivated',
  'sealed',
  'contemptuous',
  'rhetorical',
  'conciliatory',
  'articulate',
  'jobless',
  'macho',
  'forgotten',
  'lifeless',
  'proven',
  'latent',
  'secretive',
  'perilous',
  'token',
  'graphic',
  'alcoholic',
  'overdue',
  'permissible',
  'shattering',
  'preventive',
  'illiterate',
  'back',
  'atmospheric',
  'thermal',
  'quaint',
  'negotiated',
  'preposterous',
  'temporal',
  'restrained',
  'triangular',
  'mayoral',
  'spatial',
  'heady',
  'biblical',
  'fitting',
  'pessimistic',
  'mammoth',
  'allied',
  'failed',
  'intuitive',
  'nagging',
  'tidal',
  'angular',
  'speechless',
  'finishing',
  'protracted',
  'watchful',
  'businesslike',
  'automated',
  'versatile',
  'booming',
  'pouring',
  'misty',
  'deceptive',
  'sunken',
  'singular',
  'suspended',
  'unworthy',
  'immigrant',
  'expressionless',
  'airy',
  'mournful',
  'neurotic',
  'cubic',
  'unauthorized',
  'economical',
  'fund-raising',
  'captive',
  'blatant',
  'far-reaching',
  'subversive',
  'imperfect',
  'jolly',
  'inaccurate',
  'resentful',
  'strenuous',
  'suffering',
  'hardened',
  'malicious',
  'unjust',
  'perceptive',
  'newborn',
  'promised',
  'differing',
  'virgin',
  'alarmed',
  'grassy',
  'frivolous',
  'apologetic',
  'wasteful',
  'endangered',
  'unarmed',
  'adept',
  'unavoidable',
  'approved',
  'trembling',
  'stuck',
  'high-ranking',
  'crushing',
  'prescribed',
  'dependable',
  'fragrant',
  'expansive',
  'unfriendly',
  'covered',
  'bemused',
  'digital',
  'probing',
  'sloping',
  'man-made',
  'festive',
  'unilateral',
  'unmarked',
  'bipartisan',
  'statewide',
  'burgeoning',
  'devout',
  'sickening',
  'mediocre',
  'adventurous',
  'elevated',
  'suggestive',
  'accountable',
  'virtuous',
  'lame',
  'heavenly',
  'bruised',
  'unbroken',
  'irritable',
  'affected',
  'inconceivable',
  'sometime',
  'vile',
  'baggy',
  'timely',
  'glistening',
  'imagined',
  'unprepared',
  'unresolved',
  'windy',
  'humanitarian',
  'overriding',
  'detached',
  'annoying',
  'narrative',
  'interminable',
  'appalled',
  'penal',
  'unsatisfactory',
  'instinctive',
  'variable',
  'cumulative',
  'obedient',
  'deficient',
  'colossal',
  'unaffected',
  'extinct',
  'routine',
  'microscopic',
  'compassionate',
  'nominal',
  'forlorn',
  'distorted',
  'mistaken',
  'enclosed',
  'infected',
  'fervent',
  'analogous',
  'frigid',
  'instructive',
  'appointed',
  'one-way',
  'gnarled',
  'problematic',
  'sardonic',
  'two-hour',
  'hypothetical',
  'prompt',
  'anguished',
  'electromagnetic',
  'sensuous',
  'homely',
  'beaten',
  'malignant',
  'rotting',
  'concealed',
  'peripheral',
  'creaking',
  'impeccable',
  'khaki',
  'grinning',
  'irreversible',
  'rampant',
  'wondrous',
  'inward',
  'manufactured',
  'grisly',
  'cooked',
  'discriminatory',
  'cerebral',
  'knowing',
  'auxiliary',
  'operative',
  'losing',
  'genial',
  'phonetic',
  'ecclesiastical',
  'sarcastic',
  'incorrect',
  'ruddy',
  'well-to-do',
  'inexplicable',
  'unreliable',
  'developmental',
  'woolen',
  'agitated',
  'lyrical',
  'consequent',
  'calculated',
  'molecular',
  'pompous',
  'present-day',
  'shaggy',
  'even',
  'inhuman',
  'sublime',
  'diagnostic',
  'manly',
  'raucous',
  'balding',
  'after',
  'bilateral',
  'mounted',
  'blackened',
  'assembled',
  'separated',
  'gaudy',
  'evangelical',
  'darling',
  'juicy',
  'impotent',
  'receptive',
  'irritating',
  'pulmonary',
  'dazed',
  'cross-country',
  'unavailable',
  'parked',
  'habitual',
  'lexical',
  'lowered',
  'unwise',
  'planetary',
  'throbbing',
  'enigmatic',
  'superstitious',
  'threatening',
  'manned',
  'childlike',
  'sporting',
  'right-hand',
  'adult',
  'reflective',
  'white-haired',
  'discernible',
  'celestial',
  'prodigious',
  'translucent',
  'equitable',
  'epic',
  'frayed',
  'arduous',
  'flimsy',
  'penetrating',
  'howling',
  'disparate',
  'alike',
  'all-time',
  'deformed',
  'comical',
  'inert',
  'procedural',
  'resistant',
  'vibrant',
  'geographic',
  'wistful',
  'specified',
  'rightful',
  'spirited',
  'unborn',
  'enjoyable',
  'regal',
  'cumbersome',
  'burned',
  'frenzied',
  'gubernatorial',
  'deteriorating',
  'haunted',
  'evasive',
  'neglected',
  'anthropological',
  'inescapable',
  'clear-cut',
  'visionary',
  'bloated',
  'accumulated',
  'agrarian',
  'pained',
  'dwindling',
  'heightened',
  'gray-haired',
  'distressing',
  'grinding',
  'insecure',
  'archaic',
  'piercing',
  'fluent',
  'leisurely',
  'giddy',
  'slimy',
  'oncoming',
  'short-lived',
  'spinal',
  'wholesome',
  'unanswered',
  'illegitimate',
  'staunch',
  'two-day',
  'rumpled',
  'speedy',
  'soaked',
  'rocking',
  'invaluable',
  'gallant',
  'tacit',
  'finite',
  'inviting',
  'sporadic',
  'powdered',
  'cheery',
  'volcanic',
  'optional',
  'mischievous',
  'flowered',
  'contagious',
  'automotive',
  'inflated',
  'mythic',
  'analytical',
  'infrared',
  'two-week',
  'binding',
  'ancestral',
  'dissatisfied',
  'upstate',
  'veritable',
  'unaccustomed',
  'oily',
  'monotonous',
  'seated',
  'feeding',
  'fluorescent',
  'undue',
  'impassioned',
  'picturesque',
  'vocational',
  'tranquil',
  'tumultuous',
  'rustic',
  'patterned',
  'two-story',
  'pagan',
  'flash',
  'playing',
  'exhilarating',
  'maiden',
  'three-dimensional',
  'mythical',
  'thriving',
  'drab',
  'black-and-white',
  'honorary',
  'dingy',
  'founding',
  'imperative',
  'indistinguishable',
  'lightweight',
  'avid',
  'dreamy',
  'everlasting',
  'obsessive',
  'tional',
  'homogeneous',
  'inner-city',
  'changed',
  'tame',
  'colorless',
  'haggard',
  'implacable',
  'altered',
  'unequal',
  'focal',
  'perceptual',
  'literate',
  'priceless',
  'diminishing',
  'harmonious',
  'dark-haired',
  'fatty',
  'squat',
  'undecided',
  'banal',
  'fruitful',
  'pioneering',
  'innocuous',
  'cordial',
  'rewarding',
  'unsafe',
  'maritime',
  'overcrowded',
  'timeless',
  'fledgling',
  'nostalgic',
  'abreast',
  'one-time',
  'humid',
  'astronomical',
  'one-man',
  'deepening',
  'blazing',
  'fleshy',
  'dishonest',
  'succeeding',
  'qualitative',
  'needless',
  'rickety',
  'joyful',
  'stated',
  'ambivalent',
  'hybrid',
  'six-month',
  'limiting',
  'workable',
  'sleepless',
  'unpaid',
  'mundane',
  'flashy',
  'stagnant',
  'bumper',
  'recurring',
  'sinful',
  'immaculate',
  'synonymous',
  'measured',
  'thrilling',
  'long-standing',
  'unruly',
  'bewildering',
  'unfit',
  'edgy',
  'numerical',
  'sumptuous',
  'fragmented',
  'puffy',
  'elastic',
  'high-pitched',
  'momentous',
  'woven',
  'unsteady',
  'unnamed',
  'cosmetic',
  'snap',
  'impenetrable',
  'floral',
  'waving',
  'promotional',
  'tenuous',
  'lonesome',
  'embroidered',
  'strident',
  'cherished',
  'aghast',
  'fundamentalist',
  'white-collar',
  'afloat',
  'disruptive',
  'law-enforcement',
  'gathered',
  'indefinite',
  'intervening',
  'publicized',
  'geometric',
  'disciplinary',
  'descriptive',
  'wavy',
  'edible',
  'disgruntled',
  'obligatory',
  'untrue',
  'amber',
  'snug',
  'resolute',
  'awed',
  'simplistic',
  'grandiose',
  'crippling',
  'high-speed',
  'mounting',
  'glaring',
  'small-town',
  'cavernous',
  'hushed',
  'wage-price',
  'demographic',
  'diseased',
  'unpublished',
  'causal',
  'defenseless',
  'sheltered',
  'dormant',
  'compulsive',
  'loved',
  'willful',
  'truthful',
  'punitive',
  'disposable',
  'ajar',
  'drowsy',
  'statutory',
  'tanned',
  'proprietary',
  'informed',
  'unheard',
  'decision-making',
  'transient',
  'unlawful',
  'dour',
  'negligible',
  'underwater',
  'optimum',
  'illusory',
  'imaginable',
  'borrowed',
  'divergent',
  'looking',
  'exempt',
  'contentious',
  'forbidden',
  'cowardly',
  'masked',
  'crazed',
  'silken',
  'parched',
  'furry',
  'wandering',
  'insensitive',
  'over-all',
  'elated',
  'waxed',
  'veiled',
  'envious',
  'insidious',
  'scrawny',
  'unwarranted',
  'lithe',
  'abrasive',
  'pretentious',
  'far-off',
  'murdered',
  'deft',
  'prickly',
  'musty',
  'shapeless',
  'incongruous',
  'gruesome',
  'honored',
  'perceived',
  'grieving',
  'unspecified',
  'dizzying',
  'privy',
  'noteworthy',
  'charred',
  'median',
  'fearless',
  'twisting',
  'unattractive',
  'flawless',
  'welcoming',
  'flushed',
  'hardy',
  'glum',
  'scenic',
  'devious',
  'recurrent',
  'distasteful',
  'jubilant',
  'ballistic',
  'hilarious',
  'naughty',
  'bustling',
  'discarded',
  'pristine',
  'exemplary',
  'fading',
  'complacent',
  'incessant',
  'engaging',
  'twentieth-century',
  'protectionist',
  'rudimentary',
  'traumatic',
  'steamy',
  'emphatic',
  'hard-line',
  'teeming',
  'generating',
  'stuffy',
  'connecting',
  'stationary',
  'genteel',
  'populist',
  'supple',
  'hateful',
  'retrospective',
  'glazed',
  'lawful',
  'arched',
  'tiresome',
  'lucid',
  'reserved',
  'pivotal',
  'grimy',
  'surly',
  'anti-Soviet',
  'contrary',
  'quarterly',
  'old-time',
  'residual',
  'spiral',
  'decaying',
  'threatened',
  'docile',
  'appreciative',
  'jovial',
  'fascist',
  'worrisome',
  'red-haired',
  'undisturbed',
  'creamy',
  'well-dressed',
  'serial',
  'existential',
  'mountainous',
  'pastel',
  'self-sufficient',
  'spoken',
  'express',
  'tasty',
  'maroon',
  'infrequent',
  'deceased',
  'full-fledged',
  'transitional',
  'leafy',
  'gravitational',
  'furtive',
  'prophetic',
  'nasal',
  'unwelcome',
  'troubling',
  'immobile',
  'merciful',
  'uncontrollable',
  'impartial',
  'unfavorable',
  'attendant',
  'associated',
  'high-rise',
  'vascular',
  'fateful',
  'concerted',
  'rash',
  'stubby',
  'paramount',
  'impulsive',
  'fraudulent',
  'drooping',
  'reciprocal',
  'usable',
  'fast-food',
  'touchy',
  'astute',
  'oversized',
  'mottled',
  'slack',
  'fruitless',
  'unhealthy',
  'decorated',
  'shady',
  'shaped',
  'fanciful',
  'quivering',
  'charismatic',
  'sordid',
  'oppressed',
  'inaccessible',
  'fastidious',
  'brazen',
  'gloved',
  'crumbling',
  'underdeveloped',
  'scarred',
  'rambling',
  'incipient',
  'remedial',
  'derelict',
  'incompatible',
  'fanatical',
  'smoked',
  'secondhand',
  'hypnotic',
  'failing',
  'marching',
  'flattened',
  'paradoxical',
  'unskilled',
  'esthetic',
  'tolerable',
  'pungent',
  'substitute',
  'soggy',
  'terse',
  'tiring',
  'fictitious',
  'manageable',
  'inventive',
  'haughty',
  'normative',
  'premier',
  'grudging',
  'vested',
  'exhausting',
  'cross-legged',
  'self-evident',
  'away',
  'horrified',
  'prolific',
  'incoherent',
  'quantitative',
  'full-length',
  'year-round',
  'unkind',
  'provisional',
  'exterior',
  'brash',
  'inconclusive',
  'landed',
  'breathtaking',
  'acrid',
  'noted',
  'resultant',
  'long-time',
  'resounding',
  'lovable',
  'hypocritical',
  'plush',
  'foggy',
  'acknowledged',
  'idiotic',
  'tracking',
  'ceramic',
  'taxable',
  'enterprising',
  'flashing',
  'wee',
  'barbaric',
  'deafening',
  'orbital',
  'lurid',
  'dated',
  'hated',
  'buoyant',
  'mating',
  'pictorial',
  'overlapping',
  'lax',
  'archetypal',
  'manic',
  'limitless',
  'puzzling',
  'condescending',
  'hapless',
  'meek',
  'faceless',
  'uncommitted',
  'horrid',
  'greenish',
  'unorthodox',
  'unending',
  'accelerated',
  'day-care',
  'undeniable',
  'bushy',
  'searing',
  'fearsome',
  'unharmed',
  'divisive',
  'overpowering',
  'diving',
  'telling',
  'determining',
  'uptight',
  'cast',
  'enlarged',
  'ebullient',
  'disagreeable',
  'insatiable',
  'grown-up',
  'demented',
  'puffing',
  'inconvenient',
  'uncontrolled',
  'inland',
  'repulsive',
  'unintelligible',
  'blue-eyed',
  'pallid',
  'nonviolent',
  'dilapidated',
  'unyielding',
  'astounded',
  'marvellous',
  'low-cost',
  'purposeful',
  'courtly',
  'predominant',
  'conversational',
  'erroneous',
  'resourceful',
  'converted',
  'disconcerting',
  'oblique',
  'dreaded',
  'indicative',
  'silky',
  'six-year',
  'front-page',
  'biting',
  'flowering',
  'sunlit',
  'licensed',
  'unspeakable',
  'adrift',
  'awash',
  'identifiable',
  'girlish',
  'zealous',
  'spooky',
  'uncompromising',
  'deserving',
  'driven',
  'certified',
  'unlucky',
  'temperate',
  'budding',
  'impractical',
  'public-relations',
  'inflexible',
  'sensory',
  'pornographic',
  'outlandish',
  'resonant',
  'belligerent',
  'wan',
  'leftover',
  'spotted',
  'soybean',
  'easygoing',
  'vengeful',
  'proportional',
  'inaugural',
  'dank',
  'screaming',
  'heterosexual',
  'sliced',
  'year-old',
  'considerate',
  'thunderous',
  'distressed',
  'warring',
  'assertive',
  'foreseeable',
  'psychotic',
  'intermittent',
  'anti-Communist',
  'generalized',
  'unable',
  'molten',
  'excruciating',
  'illustrious',
  'voluminous',
  'offending',
  'trustworthy',
  'grating',
  'laughing',
  'one-year',
  'industrious',
  'uninterrupted',
  'dashing',
  'speaking',
  'metabolic',
  'flattering',
  'one-sided',
  'ineffectual',
  'primal',
  'digestive',
  'taped',
  'floppy',
  'jaunty',
  'practiced',
  'walled',
  'hospitable',
  'dutiful',
  'melodramatic',
  'intestinal',
  'cluttered',
  'conclusive',
  'complementary',
  'unprotected',
  'buzzing',
  'attributable',
  'tasteless',
  'forthright',
  'wily',
  'hourly',
  'delayed',
  'sweating',
  'affable',
  'studied',
  'chubby',
  'thyroid',
  'chilled',
  'conducive',
  'childless',
  'faltering',
  'authorized',
  'buried',
  'land-based',
  'observable',
  'hurried',
  'curving',
  'dismayed',
  'pernicious',
  'upturned',
  'believable',
  'questioning',
  'syndicated',
  'pharmaceutical',
  'high-risk',
  'resigned',
  'discrete',
  'likable',
  'imprisoned',
  'cocky',
  'outdated',
  'autocratic',
  'ablaze',
  'askew',
  'grammatical',
  'wintry',
  'incidental',
  'matter-of-fact',
  'disputed',
  'exorbitant',
  'low-level',
  'sodden',
  'skeletal',
  'disproportionate',
  'soiled',
  'cellular',
  'ephemeral',
  'perfunctory',
  'inconsequential',
  'flourishing',
  'intentional',
  'two-way',
  'elemental',
  'whispered',
  'four-day',
  'stinking',
  'informative',
  'tenacious',
  'outlying',
  'virulent',
  'horrendous',
  'horrifying',
  'burnt',
  'longstanding',
  'senile',
  'unmoving',
  'deprived',
  'interpersonal',
  'intimidating',
  'posh',
  'dainty',
  'portly',
  'nondescript',
  'inquisitive',
  'exiled',
  'capricious',
  'scandalous',
  'severed',
  'debilitating',
  'widowed',
  'horny',
  'sallow',
  'up-to-date',
  'self-contained',
  'carefree',
  'boisterous',
  'coordinated',
  'anti-Semitic',
  'superfluous',
  'metric',
  'expressed',
  'enchanting',
  'disorderly',
  'paternal',
  'wanton',
  'frightful',
  'free-lance',
  'extremist',
  'lined',
  'scornful',
  'inseparable',
  'obese',
  'ponderous',
  'imperious',
  'indistinct',
  'adrenal',
  'belated',
  'rippling',
  'valiant',
  'livid',
  'mystic',
  'cracking',
  'subterranean',
  'invading',
  'rusted',
  'esoteric',
  'red-faced',
  'segregated',
  'lanky',
  'departmental',
  'allergic',
  'predatory',
  'enforced',
  'anti-inflation',
  'implied',
  'flagrant',
  'best-selling',
  'haphazard',
  'trailing',
  'seedy',
  'real-life',
  'unannounced',
  'utilitarian',
  'roving',
  'despairing',
  'immature',
  'simulated',
  'embattled',
  'poisoned',
  'patronizing',
  'baffled',
  'centralized',
  'weathered',
  'weeping',
  'mutilated',
  'painstaking',
  'tax-exempt',
  'socioeconomic',
  'tearful',
  'stringy',
  'projecting',
  'low-key',
  'single-minded',
  'shadowed',
  'vehement',
  'darn',
  'fluffy',
  'apocalyptic',
  'completed',
  'intelligible',
  'furnished',
  'elongated',
  'worsening',
  'eclectic',
  'bacterial',
  'earthy',
  'sagging',
  'wide-ranging',
  'face-to-face',
  'settled',
  'dogmatic',
  'anti',
  'secluded',
  'baffling',
  'coy',
  'pathological',
  'echoing',
  'bridal',
  'autobiographical',
  'instantaneous',
  'ornamental',
  'satirical',
  'voluptuous',
  'movable',
  'kinetic',
  'merciless',
  'tireless',
  'three-month',
  'unconcerned',
  'impromptu',
  'turning',
  'follow-up',
  'retaliatory',
  'arcane',
  'waterproof',
  'justifiable',
  'glassy',
  'unearthly',
  'shuttered',
  'inverted',
  'bogus',
  'petrified',
  'simmering',
  'guided',
  'gritty',
  'widening',
  'generic',
  'pretrial',
  'returning',
  'boundless',
  'swirling',
  'northeastern',
  'swell',
  'tive',
  'minuscule',
  'estranged',
  'upbeat',
  'explanatory',
  'repetitive',
  'repressed',
  'vindictive',
  'shrinking',
  'canny',
  'little-known',
  'hydraulic',
  'unrelenting',
  'looming',
  'supersonic',
  'justified',
  'lukewarm',
  'unmoved',
  'blurred',
  'double-breasted',
  'sanitary',
  'unforgettable',
  'diligent',
  'unconventional',
  'ashen',
  'wordless',
  'stainless',
  'inlaid',
  'irritated',
  'spotless',
  'pudgy',
  'yellowish',
  'lateral',
  'adopted',
  'lowly',
  'obnoxious',
  'utopian',
  'called',
  'unimaginable',
  'hairless',
  'foregoing',
  'opulent',
  'garish',
  'nocturnal',
  'rousing',
  'unexplained',
  'cosmopolitan',
  'milky',
  'medium-sized',
  'all-night',
  'bloodshot',
  'rueful',
  'hard-working',
  'crafty',
  'familial',
  'iced',
  'violet',
  'arctic',
  'ceaseless',
  'exasperated',
  'warped',
  'aquatic',
  'gruff',
  'terrestrial',
  'contrasting',
  'egalitarian',
  'needful',
  'spent',
  'untrained',
  'escalating',
  'liberated',
  'long-haired',
  'abortive',
  'syntactic',
  'consummate',
  'lumpy',
  'spoiled',
  'ten-year-old',
  'talkative',
  'whimsical',
  'weighty',
  'audio',
  'inflammatory',
  'deplorable',
  'spicy',
  'corrugated',
  'morose',
  'sobering',
  'southwestern',
  'three-year-old',
  'methodical',
  'prehistoric',
  'carpeted',
  'smelly',
  'processed',
  'overheated',
  'interstellar',
  'agile',
  'approximate',
  'sadistic',
  'living-room',
  'irate',
  'smashed',
  'frontal',
  'venereal',
  'indiscriminate',
  'suggested',
  'cultured',
  'creeping',
  'recognized',
  'toothless',
  'handmade',
  'mellow',
  'fetal',
  'disinterested',
  'gratifying',
  'trusting',
  'small-scale',
  'intravenous',
  'crashing',
  'exhaustive',
  'afire',
  'clammy',
  'sleazy',
  'florid',
  'heartless',
  'transcendent',
  'restored',
  'demonic',
  'abusive',
  'avowed',
  'shrunken',
  'objectionable',
  'tailored',
  'arms-control',
  'listless',
  'polluted',
  'palatable',
  'funded',
  'elective',
  'entrenched',
  'classy',
  'operatic',
  'daunting',
  'roaring',
  'preferential',
  'languid',
  'three-hour',
  'virile',
  'inspiring',
  'enhanced',
  'scrupulous',
  'bottomless',
  'ginger',
  'wispy',
  'advantageous',
  'rapt',
  'umbilical',
  'uphill',
  'ordered',
  'enraged',
  'detrimental',
  'curt',
  'exalted',
  'hard-pressed',
  'intangible',
  'fussy',
  'forgiving',
  'facile',
  'populous',
  'condemned',
  'mashed',
  'hard-boiled',
  'introductory',
  'rowdy',
  'switching',
  'perplexing',
  'spilled',
  'southeastern',
  'undulating',
  'fractured',
  'inherited',
  'inscrutable',
  'measurable',
  'stunted',
  'hormonal',
  'stylized',
  'hierarchical',
  'air-conditioned',
  'aimless',
  'subsidized',
  'paying',
  'symmetrical',
  'nomadic',
  'cloudless',
  'reigning',
  'thatched',
  'perceptible',
  'anesthetic',
  'anti-American',
  'miscellaneous',
  'homesick',
  'preparatory',
  'seven-year',
  'big-city',
  'decadent',
  'searching',
  'all-important',
  'inanimate',
  'senatorial',
  'diminutive',
  'soft-spoken',
  'contingent',
  'dusky',
  'smashing',
  'precipitous',
  'bulging',
  'standardized',
  'biographical',
  'restive',
  'indecent',
  'upper-class',
  'ecumenical',
  'interchangeable',
  'lumbering',
  'fascinated',
  'untidy',
  'indulgent',
  'leaden',
  'wanted',
  'endemic',
  'doomed',
  'wanting',
  'receiving',
  'engaged',
  'unparalleled',
  'abbreviated',
  'malevolent',
  'wishful',
  'carnival',
  'world-wide',
  'protruding',
  'resplendent',
  'stranded',
  'structured',
  'biased',
  'frosty',
  'northwestern',
  'viral',
  'mindful',
  'paved',
  'indeterminate',
  'painless',
  'second-floor',
  'geological',
  'permissive',
  'downhill',
  'unsuspecting',
  'expectant',
  'fabled',
  'jittery',
  'windowless',
  'evocative',
  'unsolved',
  'disoriented',
  'monastic',
  'soluble',
  'misshapen',
  'antiquated',
  'repugnant',
  'non-Communist',
  'retiring',
  'shaded',
  'combative',
  'high-powered',
  'resilient',
  'antagonistic',
  'starched',
  'vice-presidential',
  'speckled',
  'lopsided',
  'bluish',
  'late-night',
  'prim',
  'unrestrained',
  'almighty',
  'tyrannical',
  'unkempt',
  'menstrual',
  'bleached',
  'overgrown',
  'idiosyncratic',
  'shoddy',
  'hallowed',
  'trying',
  'halting',
  'princely',
  'drugged',
  'gratuitous',
  'descending',
  'fatherly',
  'avant-garde',
  'laborious',
  'pinched',
  'disguised',
  'caustic',
  'bespectacled',
  'handwritten',
  'goodly',
  'itinerant',
  'cryptic',
  'undisclosed',
  'affordable',
  'outmoded',
  'expedient',
  'moody',
  'tepid',
  'firsthand',
  'digging',
  'elitist',
  'observed',
  'chartered',
  'slain',
  'five-day',
  'unimpressed',
  'tactful',
  'idyllic',
  'prostrate',
  'ramshackle',
  'expert',
  'deferred',
  'undistinguished',
  'prized',
  'transatlantic',
  'crystalline',
  'tacky',
  'haunting',
  'nutritious',
  'bereft',
  'turquoise',
  'time-consuming',
  'sanguine',
  'culinary',
  'fraught',
  'precocious',
  'assigned',
  'scrambled',
  'advisable',
  'nationalistic',
  'long-awaited',
  'unwrapped',
  'unchallenged',
  'circumstantial',
  'pleasurable',
  'compressed',
  'humanistic',
  'unforeseen',
  'diversified',
  'frenetic',
  'disapproving',
  'proletarian',
  'conspiratorial',
  'featureless',
  'going',
  'commendable',
  'no-nonsense',
  'chipped',
  'surreal',
  'salient',
  'pissed',
  'insurmountable',
  'backstage',
  'contented',
  'indebted',
  'adoring',
  'one-room',
  'prewar',
  'potted',
  'accelerating',
  'thorny',
  'possessive',
  'abiding',
  'ever-increasing',
  'bloodless',
  'high-technology',
  'counterproductive',
  'attracting',
  'entrepreneurial',
  'cooling',
  'unoccupied',
  'craggy',
  'leathery',
  'degenerate',
  'additive',
  'weakened',
  'quilted',
  'untold',
  'incandescent',
  'intractable',
  'middle-income',
  'abject',
  'self-made',
  'gaseous',
  'anal',
  'displaced',
  'unabashed',
  'immutable',
  'fluttering',
  'ten-year',
  'bearable',
  'stamped',
  'darkening',
  'beefy',
  'petite',
  'charging',
  'high-quality',
  'left-hand',
  'age-old',
  'checkered',
  'stupendous',
  'priestly',
  'loath',
  'endearing',
  'exacting',
  'correctional',
  'freak',
  'sneaky',
  'disgraceful',
  'unholy',
  'oriental',
  'wayward',
  'societal',
  'hard-core',
  'bilingual',
  'flipping',
  'staid',
  'paramilitary',
  'heartfelt',
  'shapely',
  'kosher',
  'heedless',
  'incurable',
  'controlling',
  'in-house',
  'choral',
  'manicured',
  'cardinal',
  'inconspicuous',
  'steely',
  'vanishing',
  'misplaced',
  'centre-fire',
  'enchanted',
  'unfounded',
  'wrecked',
  'womanly',
  'delirious',
  'deposed',
  'panicky',
  'differential',
  'tawny',
  'articulated',
  'coded',
  'wide-open',
  'unregulated',
  'lenient',
  'feathered',
  'simplified',
  'beguiling',
  'sectarian',
  'producing',
  'tiled',
  'inorganic',
  'frosted',
  'lusty',
  'scented',
  'rotating',
  'grievous',
  'dissimilar',
  'salaried',
  'unequivocal',
  'strangled',
  'grubby',
  'alluring',
  'downcast',
  'restraining',
  'unjustified',
  'contaminated',
  'lacy',
  'cinematic',
  'second-class',
  'splintered',
  'adorable',
  'derisive',
  'state-owned',
  'requisite',
  'fleeing',
  'uncomplicated',
  'motherly',
  'inter',
  'high-heeled',
  'climatic',
  'republican',
  'unqualified',
  'leveraged',
  'intercontinental',
  'uncharacteristic',
  'compositional',
  'unwritten',
  'patriarchal',
  'brusque',
  'unresponsive',
  'replete',
  'corrective',
  'reflected',
  'scraping',
  'doctoral',
  'premium',
  'deductible',
  'alternating',
  'amorous',
  'overjoyed',
  'recalcitrant',
  'presumptuous',
  'vaulted',
  'declared',
  'inexorable',
  'groggy',
  'diminished',
  'restful',
  'retroactive',
  'presumed',
  'monolithic',
  'curtained',
  'tortured',
  'ground',
  'trendy',
  'brassy',
  'prosaic',
  'inactive',
  'chaste',
  'bumpy',
  'aggrieved',
  'corny',
  'centrist',
  'trapped',
  'noxious',
  'jerky',
  'concomitant',
  'withholding',
  'poorly',
  'stolid',
  'unguarded',
  'methodological',
  'primordial',
  'retreating',
  'telescopic',
  'sidelong',
  'off-duty',
  'pleated',
  'dissenting',
  'agreed',
  'double-action',
  'optimal',
  'plaintive',
  'banned',
  'kindred',
  'quintessential',
  'impervious',
  'jumping',
  'disenchanted',
  'observant',
  'congested',
  'second-rate',
  'reasoned',
  'extrinsic',
  'infantile',
  'transitory',
  'coveted',
  'small-time',
  'doctrinal',
  'incomparable',
  'jaded',
  'special-interest',
  'sociable',
  'shameless',
  'coloured',
  'ascending',
  'fraternal',
  'queasy',
  'wont',
  'exhilarated',
  'salted',
  'disquieting',
  'listed',
  'unchanging',
  'nine-year-old',
  'unrestricted',
  'uppermost',
  'reputable',
  'dummy',
  'skimpy',
  'crusty',
  'corrosive',
  'bubbling',
  'decrepit',
  'unsuitable',
  'snarling',
  'destitute',
  'illuminating',
  'systemic',
  'material',
  'unwashed',
  'rushing',
  'dialectical',
  'jeweled',
  'attached',
  'liberating',
  'judicious',
  'errant',
  'vanished',
  'worn-out',
  'erstwhile',
  'uninformed',
  'twelve-year-old',
  'longterm',
  'petulant',
  'twin',
  'self-righteous',
  'afflicted',
  'snappy',
  'tantamount',
  'sworn',
  'unethical',
  'drained',
  'hydroelectric',
  'perplexed',
  'logistical',
  'concentric',
  'unifying',
  'lunatic',
  'invincible',
  'diffident',
  'inexhaustible',
  'discouraging',
  'dreamlike',
  'artful',
  'rolled',
  'suppressed',
  'secretarial',
  'smoldering',
  'redundant',
  'forensic',
  'million-dollar',
  'self-styled',
  'earned',
  'weightless',
  'signed',
  'compensatory',
  'glacial',
  'unmanned',
  'stalwart',
  'funky',
  'intensified',
  'uninterested',
  'submerged',
  'urbane',
  'glib',
  'ascetic',
  'contractual',
  'warlike',
  'high-priced',
  'diagonal',
  'cylindrical',
  'gargantuan',
  'illuminated',
  'unconditional',
  'hulking',
  'supplementary',
  'dictatorial',
  'puny',
  'sedate',
  'moonlit',
  'eight-year-old',
  'gullible',
  'counterfeit',
  'alienated',
  'spinning',
  'analytic',
  'nimble',
  'adaptive',
  'individualistic',
  'numbered',
  'blissful',
  'insolent',
  'supplemental',
  'delectable',
  'inordinate',
  'unbalanced',
  'tormented',
  'unchecked',
  'aspiring',
  'punishing',
  'self-serving',
  'crossed',
  'discretionary',
  'box-office',
  'snow-covered',
  'improvised',
  'squalid',
  'orphaned',
  'grizzled',
  'unsmiling',
  'disappearing',
  'affiliated',
  'readable',
  'blocking',
  'bullish',
  'contending',
  'burned-out',
  'bloodied',
  'subsidiary',
  'complimentary',
  'unclean',
  'scanty',
  'uprooted',
  'farfetched',
  'solicitous',
  'regulated',
  'threadbare',
  'choppy',
  'ever-present',
  'negligent',
  'nonstop',
  'one-day',
  'wild-eyed',
  'infuriating',
  'vivacious',
  'abominable',
  'wrought',
  'inaudible',
  'braided',
  'transcendental',
  'desultory',
  'climactic',
  'appellate',
  'interlocking',
  'submissive',
  'unmatched',
  'dapper',
  'demeaning',
  'adaptable',
  'well-meaning',
  'lustrous',
  'tax-free',
  'ungrateful',
  'gentlemanly',
  'missed',
  'loathsome',
  'incalculable',
  'blistering',
  'amenable',
  'tremulous',
  'massed',
  'nonpartisan',
  'unsettled',
  'three-story',
  'succulent',
  'trite',
  'masterful',
  'reticent',
  'unsettling',
  'proverbial',
  'strapping',
  'spurious',
  'invulnerable',
  'paltry',
  'embryonic',
  'repeating',
  'neural',
  'sultry',
  'metaphorical',
  'foreign-policy',
  'linked',
  'pubic',
  'beaming',
  'ministerial',
  'phantom',
  'quizzical',
  'hilly',
  'cold-blooded',
  'gregarious',
  'three-piece',
  'untroubled',
  'bisexual',
  'pensive',
  'unpretentious',
  'exploratory',
  'unscathed',
  'irrepressible',
  'pelvic',
  'newfound',
  'starry',
  'corned',
  'overworked',
  'illogical',
  'unfaithful',
  'interrelated',
  'saintly',
  'overcast',
  'connected',
  'ungainly',
  'organizing',
  'carnal',
  'philosophic',
  'nationalized',
  'fickle',
  'ultraviolet',
  'crass',
  'undeveloped',
  'unprofitable',
  'sheepish',
  'archaeological',
  'out-of-town',
  'balmy',
  'spongy',
  'infallible',
  'callous',
  'scathing',
  'rheumatic',
  'audacious',
  'participating',
  'swarthy',
  'hand-held',
  'comatose',
  'modernist',
  'stellar',
  'antinuclear',
  'delinquent',
  'time-honored',
  'presiding',
  'relaxing',
  'high-pressure',
  'impetuous',
  'hypodermic',
  'fringed',
  'favourite',
  'unscrupulous',
  'inspirational',
  'mystified',
  'wobbly',
  'intrepid',
  'deferential',
  'burdensome',
  'stored',
  'supervisory',
  'seventeenth-century',
  'six-day',
  'interdependent',
  'updated',
  'all-powerful',
  'unitary',
  'stand-up',
  'laconic',
  'penniless',
  'steadfast',
  'dogged',
  'scholastic',
  'convertible',
  'mingled',
  'sorrowful',
  'symptomatic',
  'stylistic',
  'well-intentioned',
  'consuming',
  'sketchy',
  'weakening',
  'generative',
  'atrocious',
  'first-quarter',
  'irrevocable',
  'charged',
  'stoned',
  'dividing',
  'apathetic',
  'debatable',
  'uncomprehending',
  'overhanging',
  'galloping',
  'kinky',
  'uncritical',
  'suave',
  'undisputed',
  'spiky',
  'inarticulate',
  'extracurricular',
  'guttural',
  'impressed',
  'departing',
  'yellowed',
  'discontented',
  'adroit',
  'high-fiber',
  'second-hand',
  'blinking',
  'formless',
  'unsavory',
  'new-found',
  'withered',
  'collected',
  'menial',
  'unobserved',
  'flabby',
  'afterward',
  'vanquished',
  'stained-glass',
  'hour-long',
  'bittersweet',
  'invalid',
  'incriminating',
  'commensurate',
  'all-American',
  'assumed',
  'tried',
  'cursory',
  'absorbing',
  'clearing',
  'confirmed',
  'stressful',
  'depleted',
  'eight-year',
  'participatory',
  'stripped',
  'concave',
  'regrettable',
  'fortified',
  'effortless',
  'regressive',
  'irreverent',
  'collegiate',
  'defunct',
  'grainy',
  'inhospitable',
  'gripping',
  'grizzly',
  'restoring',
  'arterial',
  'busted',
  'indomitable',
  'demure',
  'rabid',
  'headlong',
  'blue-green',
  'bound',
  'breezy',
  'materialistic',
  'uneducated',
  'scruffy',
  'cohesive',
  'full-blown',
  'cranky',
  'motivated',
  'mauve',
  'hardworking',
  'melodic',
  'genital',
  'decorous',
  'comely',
  'rife',
  'purported',
  'hurtful',
  'six-foot',
  'macabre',
  'odious',
  'convulsive',
  'well-trained',
  'heterogeneous',
  'curled',
  'pearly',
  'spindly',
  'latter-day',
  'innermost',
  'clipped',
  'checked',
  'masterly',
  'laughable',
  'naturalistic',
  'tinkling',
  'impudent',
  'fitful',
  'illustrated',
  'speeding',
  'roasted',
  'in-depth',
  'helluva',
  'vigilant',
  'empty-handed',
  'forged',
  'wrought-iron',
  'disgraced',
  'agonized',
  'infirm',
  'preserving',
  'tasteful',
  'onerous',
  'shredded',
  'impregnable',
  'slanted',
  'tainted',
  'opened',
  'first-time',
  'machine-gun',
  'bottled',
  'seismic',
  'fetid',
  'saturated',
  'insubstantial',
  'full-page',
  'aromatic',
  'stingy',
  'promiscuous',
  'unlit',
  'regimental',
  'spellbound',
  'streamlined',
  'bereaved',
  'ruffled',
  'creepy',
  'treasured',
  'ensconced',
  'one-party',
  'well-educated',
  'pert',
  'mercantile',
  'all-purpose',
  'voracious',
  'tortuous',
  'despised',
  'unadorned',
  'offhand',
  'qualifying',
  'manipulative',
  'indelible',
  'well-established',
  'revolting',
  'ethereal',
  'roasting',
  'prohibitive',
  'domed',
  'whipped',
  'overstuffed',
  'garrulous',
  'skittish',
  'revived',
  'heartening',
  'jumpy',
  'grilled',
  'melted',
  'unfocused',
  'spectral',
  'unproductive',
  'top-level',
  'life-size',
  'three-way',
  'negotiable',
  'disloyal',
  'turn-of-the-century',
  'four-hour',
  'unopened',
  'devilish',
  'amorphous',
  'antiseptic',
  'sharpened',
  'primeval',
  'unrecognizable',
  'ineligible',
  'expendable',
  'deathly',
  'auspicious',
  'insoluble',
  'inimical',
  'unquestioned',
  'far-flung',
  'medicinal',
  'deep-seated',
  'formative',
  'iridescent',
  'fragmentary',
  'distinguishable',
  'auburn',
  'closed-circuit',
  'emeritus',
  'third-floor',
  'hazel',
  'tumbling',
  'departed',
  'obstinate',
  'portentous',
  'quixotic',
  'scorched',
  'adjustable',
  'winged',
  'intrusive',
  'taxing',
  'high-ceilinged',
  'barbarous',
  'decreasing',
  'sleeveless',
  'unattended',
  'tight-lipped',
  'concluding',
  'unobtrusive',
  'starved',
  'quirky',
  'big-time',
  'sooty',
  'copious',
  'stalled',
  'scriptural',
  'unconvincing',
  'earthen',
  'throaty',
  'august',
  'extant',
  'sexist',
  'exultant',
  'cancerous',
  'psychedelic',
  'yielding',
  'matched',
  'chunky',
  'unfathomable',
  'concise',
  'admitting',
  'knitted',
  'projective',
  'euphoric',
  'garbled',
  'divisional',
  'despondent',
  'recommended',
  'passable',
  'vegetarian',
  'indefatigable',
  'irreparable',
  'feisty',
  'untenable',
  'contrite',
  'angelic',
  'reputed',
  'untimely',
  'dejected',
  'appreciable',
  'remembered',
  'hellish',
  'rear-view',
  'open-air',
  'ill-fated',
  'nonpolitical',
  'factional',
  'separatist',
  'contributing',
  'post-war',
  'uneventful',
  'metaphoric',
  'unsound',
  'unwitting',
  'venomous',
  'harried',
  'engraved',
  'collapsing',
  'reformist',
  'thematic',
  'inclusive',
  'cheering',
  'springy',
  'obliging',
  'contemplative',
  'unbridled',
  'state-run',
  'reflex',
  'allegorical',
  'geopolitical',
  'disembodied',
  'issuing',
  'bountiful',
  'alright',
  'overbearing',
  'muddled',
  'congenital',
  'distinguishing',
  'absorbed',
  'tart',
  'french',
  'autumnal',
  'verifiable',
  'grueling',
  'crackling',
  'aft',
  'punishable',
  'freckled',
  'indestructible',
  'imprecise',
  'hard-nosed',
  'thoughtless',
  'through',
  'proficient',
  'pent-up',
  'never-ending',
  'hunted',
  'defensible',
  'arresting',
  'across-the-board',
  'spotty',
  'orchestral',
  'undefined',
  'stacked',
  'implausible',
  'antitank',
  'unwary',
  'inflamed',
  'sacrificial',
  'oil-producing',
  'leaky',
  'mint',
  'chronological',
  'conquering',
  'jumbo',
  'three-week',
  'addictive',
  'uninhibited',
  'substandard',
  'contracting',
  'degenerative',
  'triumphal',
  'flowery',
  'cardiovascular',
  'shaken',
  'undefeated',
  'unassuming',
  'luscious',
  'unperturbed',
  'gleeful',
  'sentencing',
  'brawny',
  'perfumed',
  'mild-mannered',
  'healthful',
  'left-handed',
  'rancid',
  'well-defined',
  'unmanageable',
  'drowning',
  'clinging',
  'anachronistic',
  'revered',
  'enriched',
  'capitalistic',
  'good-by',
  'invigorating',
  'practicing',
  'unsold',
  'long-legged',
  'unruffled',
  'aboriginal',
  'inane',
  'bedraggled',
  'early-morning',
  'run-down',
  'straight-backed',
  'reverent',
  'acquired',
  'bestselling',
  'top-secret',
  'woolly',
  'foolhardy',
  'sticking',
  'blue-black',
  'impassable',
  'overcome',
  'coiled',
  'front-line',
  'tinted',
  'acquisitive',
  'slatted',
  'octagonal',
  'receding',
  'investing',
  'doctrinaire',
  'all-white',
  'caring',
  'prejudiced',
  'slow-moving',
  'circulating',
  'science-fiction',
  'shortsighted',
  'disaffected',
  'lawless',
  'chastened',
  'lewd',
  'rubbery',
  'foaming',
  'unsympathetic',
  'ladylike',
  'betrayed',
  'neurological',
  'shouting',
  'good-sized',
  'electrostatic',
  'untoward',
  'flabbergasted',
  'citywide',
  'unanticipated',
  'knotted',
  'whitewashed',
  'year-end',
  'enticing',
  'migratory',
  'multicolored',
  'hashish',
  'ascorbic',
  'topless',
  'heathen',
  'spherical',
  'filmy',
  'deviant',
  'centennial',
  'proportionate',
  'instructional',
  'contrived',
  'savvy',
  'over-the-counter',
  'fast-moving',
  'measuring',
  'uptown',
  'compliant',
  'favourable',
  'unforgivable',
  'undamaged',
  'psychoanalytic',
  'gebling',
  'bubbly',
  'ready-made',
  'caged',
  'ostentatious',
  'superhuman',
  'busing',
  'cream-colored',
  'self-destructive',
  'ostensible',
  'cobbled',
  'whirling',
  'released',
  'showy',
  'baleful',
  'red-hot',
  'named',
  'monogamous',
  'fallow',
  'disdainful',
  'cyclical',
  'long-running',
  'pitiless',
  'diffuse',
  'omnipresent',
  'mossy',
  'cutting',
  'astounding',
  'lyric',
  'dark-blue',
  'unsophisticated',
  'indigent',
  'coincidental',
  'imperceptible',
  'veterinary',
  'coercive',
  'multilateral',
  'ageless',
  'law-abiding',
  'functioning',
  'beneficent',
  'crawling',
  'overturned',
  'steamed',
  'comprehensible',
  'oil-rich',
  'undetected',
  'ribbed',
  'nautical',
  'textured',
  'fast-growing',
  'nauseous',
  'vaunted',
  'paralyzed',
  'maimed',
  'short-range',
  'impure',
  'unintended',
  'practicable',
  'intermediate-range',
  'unfulfilled',
  'behind-the-scenes',
  'backhand',
  'voluble',
  'goofy',
  'apolitical',
  'contraceptive',
  'waning',
  'blasted',
  'sundry',
  'profane',
  'binary',
  'rock-and-roll',
  'ruinous',
  'open-ended',
  'next-door',
  'withering',
  'conical',
  'flustered',
  'decided',
  'able-bodied',
  'round-trip',
  'decreased',
  'half-empty',
  'sponsored',
  'riotous',
  'stereotyped',
  'five-minute',
  'irreplaceable',
  'harrowing',
  'uninteresting',
  'salutary',
  'frugal',
  'disjointed',
  'cupped',
  'freshwater',
  'shaven',
  'ravenous',
  'bulbous',
  'stepped-up',
  'swaying',
  'two-room',
  'valued',
  'planted',
  'bright-eyed',
  'unreadable',
  'trucking',
  'infatuated',
  'dysfunctional',
  'pinkish',
  'futuristic',
  'airtight',
  'unseemly',
  'vaginal',
  'sizzling',
  'mercurial',
  'conic',
  'unfettered',
  'undisciplined',
  'unrecognized',
  'well-publicized',
  'income-tax',
  'self-appointed',
  'ice-cold',
  'biochemical',
  'contemptible',
  'barefooted',
  'droll',
  'mythological',
  'tree-lined',
  'rearing',
  'luxuriant',
  'heartbreaking',
  'tufted',
  'well-organized',
  'selfless',
  'world-class',
  'unwieldy',
  'contested',
  'rasping',
  'downright',
  'ingratiating',
  'self-proclaimed',
  'parasitic',
  'graying',
  'reformed',
  'cautionary',
  'untested',
  'beaded',
  'maniacal',
  'eucalyptus',
  'pliable',
  'air-conditioning',
  'moot',
  'traceable',
  'anti-abortion',
  'antisocial',
  'reprehensible',
  'self-imposed',
  'yellowing',
  'teasing',
  'porous',
  'ersatz',
  'unwavering',
  'untouchable',
  'underprivileged',
  'auditory',
  'escaping',
  'subservient',
  'unspoiled',
  'anterior',
  'fatuous',
  'lordly',
  'infernal',
  'bouncing',
  'taboo',
  'orthopedic',
  'spiteful',
  'surging',
  'nuts',
  'esteemed',
  'outlawed',
  'pushy',
  'displeased',
  'self-confident',
  'attainable',
  'bowed',
  'cast-iron',
  'despicable',
  'unconvinced',
  'famished',
  'coed',
  'bygone',
  'nonaligned',
  'sectional',
  'typed',
  'squeaky',
  'disparaging',
  'cut-rate',
  'heart-shaped',
  'offbeat',
  'velvety',
  'well-worn',
  'upsetting',
  'leery',
  'long-lost',
  'horse-drawn',
  'puritanical',
  'payable',
  'fertilized',
  'predicted',
  'allowable',
  'peaceable',
  'soundless',
  'marshy',
  'discordant',
  'intoxicating',
  'concurrent',
  'uncut',
  'tantalizing',
  'shitty',
  'pedagogical',
  'accursed',
  'two-man',
  'connective',
  'hawkish',
  'ripped',
  'cleared',
  'double-digit',
  'unencumbered',
  'yawning',
  'manifold',
  'stopped',
  'untreated',
  'subliminal',
  'grayish',
  'gory',
  'upper-middle-class',
  'avenging',
  'self-fulfilling',
  'equatorial',
  'saucy',
  'barred',
  'arch',
  'midwestern',
  'blue-gray',
  'tarnished',
  'leafless',
  'incisive',
  'unearned',
  'botanical',
  'feline',
  'extraneous',
  'prep',
  'intransigent',
  'change-minimizing',
  'insurgent',
  'acrimonious',
  'thermonuclear',
  'blue-chip',
  'crummy',
  'acoustic',
  'oversize',
  'fated',
  'galactic',
  'cantankerous',
  'ill-advised',
  'detectable',
  'lower-class',
  'sacrosanct',
  'palatial',
  'conditional',
  'insulated',
  'step-by-step',
  'nebulous',
  'two-dimensional',
  'well-heeled',
  'bronchial',
  'subatomic',
  'semifinal',
  'first-year',
  'dark-eyed',
  'tinny',
  'attacking',
  'indecisive',
  'anatomical',
  'brotherly',
  'blooming',
  'sinuous',
  'meditative',
  'socalled',
  'rheumatoid',
  'received',
  'bleary',
  'half-naked',
  'leaded',
  'woody',
  'averse',
  'shuddering',
  'door-to-door',
  'heretical',
  'suspect',
  'untapped',
  'ravaged',
  'decentralized',
  'rutted',
  'ineffable',
  'intolerant',
  'mechanized',
  'fortuitous',
  'equestrian',
  'seven-year-old',
  'darting',
  'consoling',
  'modern-day',
  'ground-floor',
  'emblematic',
  'lurking',
  'two-year-old',
  'purplish',
  'disorganized',
  'vaudeville',
  'circulatory',
  'eight-hour',
  'presentable',
  'anarchic',
  'unsatisfied',
  'labored',
  'maudlin',
  'trampled',
  'gibberish',
  'unaccountable',
  'sedentary',
  'heavy-duty',
  'thrilled',
  'tutoring',
  'self-centered',
  'do-it-yourself',
  'inquiring',
  'uncaring',
  'disillusioned',
  'bloodstained',
  'surface-to-air',
  'consular',
  'subconscious',
  'four-year-old',
  'collaborative',
  'terraced',
  'figurative',
  'sinewy',
  'horn-rimmed',
  'impertinent',
  'hit-and-run',
  'standby',
  'medium-size',
  'peremptory',
  'incremental',
  'first-aid',
  'dyed',
  'centrifugal',
  'omnipotent',
  'lascivious',
  'two-month',
  'unionized',
  'discredited',
  'mass-produced',
  'feathery',
  'self-indulgent',
  'liturgical',
  'enviable',
  'fifteen-year-old',
  'buxom',
  'abashed',
  'urinary',
  'newsworthy',
  'flailing',
  'beastly',
  'undiscovered',
  'strong-willed',
  'prenatal',
  'brownish',
  'announced',
  'flaky',
  'washed',
  'nightmarish',
  'broad-shouldered',
  'short-sleeved',
  'two-bit',
  'self-assured',
  'whitish',
  'suffocating',
  'black-haired',
  'full-size',
  'self-help',
  'created',
  'uninhabited',
  'smokeless',
  'no-fault',
  'unfashionable',
  'mushy',
  'forested',
  'adhesive',
  'creased',
  'insufferable',
  'down-to-earth',
  'trifling',
  'landless',
  'disreputable',
  'self-effacing',
  'sporty',
  'confined',
  'adoptive',
  'monogrammed',
  'motley',
  'duplicate',
  'silver-haired',
  'rejected',
  'undifferentiated',
  'blasphemous',
  'institutionalized',
  'blue-and-white',
  'hip',
  'winsome',
  'button-down',
  'discerning',
  'abused',
  'clean-cut',
  'bracing',
  'self-supporting',
  'unsupported',
  'premarital',
  'flattered',
  'studious',
  'repetitious',
  'marketable',
  'anemic',
  'meaty',
  'airless',
  'unhurried',
  'galvanized',
  'feal',
  'peace-keeping',
  'rapacious',
  'bulletproof',
  'well-placed',
  'helmeted',
  'packaged',
  'court-ordered',
  'aggravated',
  'gastrointestinal',
  'hand-to-hand',
  'sixteen-year-old',
  'fretful',
  'fourth-quarter',
  'conquered',
  'satiric',
  'nutty',
  'befuddled',
  'humorless',
  'pitched',
  'burnished',
  'mirrored',
  'fishy',
  'fluted',
  'conditioned',
  'military-industrial',
  'one-story',
  'barbarian',
  'branching',
  'dynastic',
  'unthinking',
  'unconscionable',
  'hunched',
  'post-World',
  'capital',
  'putative',
  'incendiary',
  'shaving',
  'topical',
  'self-satisfied',
  'farcical',
  'narcissistic',
  'kneeling',
  'born-again',
  'old-line',
  'amateurish',
  'ill-fitting',
  'scaly',
  'unpainted',
  'eroding',
];
