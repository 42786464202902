import { model } from 'mobx-keystone';
import { PDFieldModel } from '../PDFieldModel';

@model('Character/Development/Category/LifePoints')
export class LifePDModel extends PDFieldModel {
  get pdsCost(): number {
    return this.template.lifeMultiplier;
  }

  get bought(): number {
    if (!this.character) return 0;

    return (
      Math.floor((this.base + this.special) / this.pdsCost) *
      this.character.primaries.constitution.final
    );
  }

  get perCategory(): number {
    if (!this.character) return 0;

    const template = this.template;

    let value = template.lifePoints * template.level;

    if (this.character.options.isAccumulationCreature) {
      value = value * this.calculateAccumulationCreatureLifeModifier();
    }

    return value;
  }

  private calculateAccumulationCreatureLifeModifier() {
    if (!this.character) return 0;

    const size = this.character.description.size.final;

    if (size <= 3) return 1;
    if (size <= 8) return 2;
    if (size <= 24) return 5;
    if (size <= 28) return 10;
    if (size <= 33) return 15;

    return 20;
  }
}
