import styled from 'styled-components';

export const Wrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1rem',

  maxWidth: '50rem',

  justifyContent: 'center',

  paddingTop: '0.5rem',
});
