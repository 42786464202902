import { model } from 'mobx-keystone';
import {
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
} from '../../../../../aggregations/advantage/Advantage.types';
import { BetterInnateMagicAdvantage } from '../../../../../aggregations/advantage/DonAdvantages';
import { NumberField } from '../../../utils/fields/NumberField';

@model('Character/Mystic/InnateMagicField')
export class InnateMagicFieldModel extends NumberField {
  get final(): number {
    return this.special + this.fromAdvantages + this.calculateBonus();
  }

  get fromAdvantages(): number {
    if (!this.character) return 0;

    let value = 0;

    if (
      this.character.creationPoints.containsAdvantage(
        BetterInnateMagicAdvantage,
        { level: AdvantageLevel1 },
      )
    ) {
      value += 10;
    }

    if (
      this.character.creationPoints.containsAdvantage(
        BetterInnateMagicAdvantage,
        { level: AdvantageLevel2 },
      )
    ) {
      value += 20;
    }

    if (
      this.character.creationPoints.containsAdvantage(
        BetterInnateMagicAdvantage,
        { level: AdvantageLevel3 },
      )
    ) {
      value += 30;
    }

    return value;
  }

  private calculateBonus(): number {
    if (!this.character) return 0;

    const act = this.character.mystic.act.final;

    if (act < 10) return 0;
    if (act < 55) return 10;
    if (act < 75) return 20;
    if (act < 95) return 30;
    if (act < 115) return 40;
    if (act < 135) return 50;
    if (act < 155) return 60;
    if (act < 185) return 70;
    if (act < 200) return 80;

    return 90;
  }
}
