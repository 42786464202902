import { model } from 'mobx-keystone';
import { DevelopedPDFieldModel } from '../../../utils/fields/DevelopedPDFieldModel';
import { getTotalOf } from '../../../utils/getTotalOf';

@model('Character/Mystic/MagicProjection')
export class MagicProjectionFieldModel extends DevelopedPDFieldModel {
  get primariesBonus(): number {
    if (!this.character) return 0;

    return this.character.primaries.dexterity.modifier.final;
  }

  get fromCategories(): number {
    return getTotalOf(this.character, (c) => c.mystic.magicProjection.final);
  }
}
