import { model } from 'mobx-keystone';
import {
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
} from '../../../../../aggregations/advantage/Advantage.types';
import { MagicNatureAdvantage } from '../../../../../aggregations/advantage/DonAdvantages';
import { DevelopedPDFieldModel } from '../../../utils/fields/DevelopedPDFieldModel';
import { getTotalOf } from '../../../utils/getTotalOf';

@model('Character/Mystic/ZeonField')
export class ZeonFieldModel extends DevelopedPDFieldModel {
  get fromCategories(): number {
    return getTotalOf(this.character, (c) => c.mystic.zeonPoints.final);
  }

  get allActionsPenalty(): number {
    return 0;
  }

  get bonusFromAdvantages(): number {
    let value = 0;

    if (
      this.character?.creationPoints.containsAdvantage(MagicNatureAdvantage, {
        level: AdvantageLevel1,
      })
    ) {
      value += 50 * this.character.level;
    }

    if (
      this.character?.creationPoints.containsAdvantage(MagicNatureAdvantage, {
        level: AdvantageLevel2,
      })
    ) {
      value += 100 * this.character.level;
    }

    if (
      this.character?.creationPoints.containsAdvantage(MagicNatureAdvantage, {
        level: AdvantageLevel3,
      })
    ) {
      value += 150 * this.character.level;
    }

    return value;
  }

  get primariesBonus(): number {
    if (!this.character) return 0;

    const bonusPerPower = [
      0, 5, 20, 40, 55, 70, 85, 95, 110, 120, 135, 150, 160, 175, 185, 200, 215,
      225, 240, 250, 265,
    ];

    return bonusPerPower[this.character.primaries.power.final] ?? 0;
  }

  get other(): number {
    if (!this.character) return 0;

    return this.character.mystic.zeonContainer;
  }
}
