import { model } from 'mobx-keystone';
import { PDFieldModel } from '../../PDFieldModel';

@model('Character/PD/Combat/AttackAbilityPDField')
export class AttackAbilityPDFieldModel extends PDFieldModel {
  get pdsCost(): number {
    return this.template.attackPDsCost;
  }

  get perCategory(): number {
    return this.template.calculateAttackBonus();
  }
}
