import { model } from 'mobx-keystone';
import { PDFieldModel } from '../PDFieldModel';

@model('Character/PD/Psychic/PsychicPointsField')
export class PsychicPointsPDFieldModel extends PDFieldModel {
  get pdsCost(): number {
    return this.template.psychicPointsPDsCost;
  }

  get perCategory(): number {
    return this.template.calculateNativePsychicPoints();
  }
}
