import { ArrowBack } from '@styled-icons/material';
import styled from 'styled-components';
import { SmallParagraph, SmallTitle } from '../Texts/Texts.styled';

export const VersionText = styled(SmallParagraph)(
  ({ theme: { color, size } }) => ({
    color: color.white(),

    fontSize: size.units(1.25),

    fontStyle: 'italic',
  }),
);

export const Wrapper = styled.div(({ theme: { size, color } }) => ({
  backgroundColor: color.black(),
  width: '100%',
  height: '100%',

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  minHeight: size.units(7),

  paddingLeft: size.units(2),
  paddingRight: size.units(4),

  boxSizing: 'border-box',

  position: 'relative',

  [VersionText]: {
    position: 'absolute',
    top: '2px',
    left: '2px',
  },
}));

export const NavbarItemsContainer = styled.div(
  ({ theme: { size, mixins } }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    paddingLeft: size.units(12),

    gap: size.units(3),

    ...mixins.responsive.sm.downward({
      paddingLeft: size.units(2),
    }),

    height: '100%',
  }),
);

export const NavbarItem = styled(SmallTitle)<{
  $selected?: boolean;
  $label?: string;
}>(({ theme: { color, transition, mixins }, $selected, $label }) => ({
  color: color.white(),
  height: '100%',
  boxSizing: 'border-box',
  borderBottom: `3px solid ${$selected ? color.primaryLight() : 'transparent'}`,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  textAlign: 'center',

  cursor: 'pointer',
  transition: transition.standard,

  '&:hover': {
    borderBottom: `3px solid ${color.primaryLight()}`,
  },

  ...mixins.responsive.xs.downward({
    display: `${$label === 'FAQ' ? 'none' : 'flex'}`,
  }),
}));
export const NotificationBadge = styled.div(({ theme: { size } }) => ({
  position: 'absolute',
  top: size.units(1.5),
  left: size.units(7.5),

  width: size.units(1),
  height: size.units(1),
  backgroundColor: 'red',
  borderRadius: '50%',
}));

export const UserManagementContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',

  height: '100%',
  width: size.units(40),
}));

export const AvatarContainer = styled.div({
  cursor: 'pointer',
});

export const StyledBackIcon = styled(ArrowBack)(
  ({ theme: { size, color } }) => ({
    width: size.units(3),
    height: size.units(3),

    color: color.white(),

    cursor: 'pointer',
  }),
);
