import styled from 'styled-components';

export const HeaderContainer = styled.div({
  width: '100%',
});

export const Wrapper = styled.div({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',

  height: '100%',
  boxSizing: 'border-box',
});

export const Container = styled.div(({ theme: { size, mixins } }) => ({
  height: '100%',
  width: '100%',

  paddingBottom: size.units(2),

  ...mixins.responsive.md.upward({
    maxWidth: '75vw',
    margin: '0 auto',
  }),
}));

export const LoadingContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',

  minHeight: '100vh',
});
