import { model } from 'mobx-keystone';
import { AcuteSensesAdvantage } from '../../../../../../../aggregations/advantage/CommonAdvantages';
import { SecondarySkill } from '../../../../../types/SecondarySkill';
import { getTotalOf } from '../../../../../utils/getTotalOf';
import { SecondarySkillPDFieldModel } from '../../../../pd/parts/categories/parts/secondaries/SecondarySkillPDFieldModel';
import { SecondarySkillFieldModel } from '../../../utils/fields/SecondarySkillFieldModel';
import { Calculation } from '../../../../../utils/fields/NumberField';
import { TuanDalyr } from '../../../../../../../aggregations/nephilim-type/Nephilim';

@model('Character/Secondaries/PerceptiveSkills/Notice')
export class NoticeSkillModel extends SecondarySkillFieldModel {
  type = SecondarySkill.Notice;

  get primariesBonus(): number {
    if (!this.character) return 0;

    return this.character.primaries.perception.modifier.final;
  }

  get fromCategories(): number {
    return getTotalOf(
      this.character,
      (c) => c.secondaries.perceptive.notice.final,
    );
  }

  get bonusFromAdvantages(): Calculation[] {
    const calculations = super.bonusFromAdvantages;

    if (
      this.character?.creationPoints.containsAdvantage(AcuteSensesAdvantage)
    ) {
      if (this.character?.isNephilim(TuanDalyr)) {
        calculations.push(
          this.sum(
            `${AcuteSensesAdvantage.name} (siendo ${TuanDalyr.name})`,
            90,
          ),
        );
      } else {
        calculations.push(this.sum(AcuteSensesAdvantage.name, 50));
      }
    }

    return calculations;
  }

  getPDFields(): SecondarySkillPDFieldModel[] {
    return (
      this.character?.pd.categories.map(
        (c) => c.secondaries.perceptive.notice,
      ) ?? []
    );
  }
}
