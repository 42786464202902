import { AuthorizedPageLayout } from '../../../../shared/application/design-system/components/PageLayout/PageLayout';
import { DefaultNavbar } from '../../../../shared/application/design-system/components/TopNavbar/implementations/DefaultNavbar';
import { CharactersRoom } from './components/CharactersRoom/CharactersRoom';

export const CharactersRoomPage = () => {
  return (
    <AuthorizedPageLayout Header={<DefaultNavbar />}>
      <CharactersRoom />
    </AuthorizedPageLayout>
  );
};
