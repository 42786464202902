import {
  BasicMartialArtGradeIds,
  buildAdvancedMartialArtArcaneGrade,
  buildAdvancedMartialArtBasicGrade,
} from '../../MartialArtGrades';
import {
  AdvancedMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { calculateHighestDamageFromKnownMartialArts } from './utils/calculateHighestDamageFromKnownMartialArts';
import { HitType } from '../../../../character/model/types/HitType';
import { calculateMartialArtsRequirements } from './utils/calculateMartialArtsRequirements';
import { KiSkillType } from '../../../ki-skill/KiSkill';

const BasicMartialArtsRequirement = [
  {
    type: MartialArtType.TaiChi,
    grade: BasicMartialArtGradeIds.Advanced,
  },
  {
    type: MartialArtType.KungFu,
    grade: BasicMartialArtGradeIds.Advanced,
  },
];

const ArcaneMartialArtsRequirement = [
  {
    type: MartialArtType.TaiChi,
    grade: BasicMartialArtGradeIds.Supreme,
  },
  {
    type: MartialArtType.KungFu,
    grade: BasicMartialArtGradeIds.Supreme,
  },
];

export class VelezMartialArt extends AdvancedMartialArt {
  readonly name = 'Velez';

  readonly complexity = 5;

  readonly type = MartialArtType.Velez;

  readonly level = MartialArtLevelType.Advanced;

  readonly grades = [
    buildAdvancedMartialArtBasicGrade({
      cmBonus: 20,
      effects: 'Permite atacar a la TA de energía',
      combatBonus: {
        block: 20,
        dodge: 20,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      calculateHitType: () => [HitType.Impact, HitType.Energy],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          BasicMartialArtsRequirement,
        );

        if (!character.ki.hasKiSkill(KiSkillType.PresenceExtrusion)) {
          errors.push('Habilidad de Ki: Extrusión de presencia');
        }

        return errors;
      },
    }),
    buildAdvancedMartialArtArcaneGrade({
      cmBonus: 40,
      effects:
        'Sus ataques se consideran intangibles e invisibles para aquellos que no puedan ver o sentir la energía (Detección del Ki, por ejemplo). Puede usar poder para los controles enfrentados para derribar, presa, desarmar...',
      masterBonus: {
        block: 15,
        dodge: 15,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          ArcaneMartialArtsRequirement,
        );

        if (character.combat.attackAbility.final < 280) {
          errors.push('Ataque 280+');
        }

        return errors;
      },
    }),
  ];
}
