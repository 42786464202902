import styled from 'styled-components';
import {
  BasicParagraph,
  BasicTitle,
  BigTitle,
} from '../../../../../../shared/application/design-system/components/Texts/Texts.styled';
import { TextArea } from '../../../../../../shared/application/design-system/components/TextArea/TextArea';
import { Button } from '../../../../../../shared/application/design-system/components/Button/Button';

export const ExportToFoundryImage = styled.img({
  maxWidth: '100%',
});

export const ExportToFoundryIconImage = styled.img(({ theme: { size } }) => ({
  minWidth: size.units(5),
}));

export const ExportToFoundryIconsContainer = styled.div(
  ({ theme: { size } }) => ({
    display: 'flex',
    flexDirection: 'row',

    justifyContent: 'start',
    alignItems: 'center',

    gap: size.units(2),
  }),
);

export const StyledTextArea = styled(TextArea)({});
export const StyledButton = styled(Button)({});

export const Section = styled.div(({ theme: { size } }) => ({
  [`& > ${BasicParagraph}`]: {
    marginBottom: size.units(1),
  },

  [`& > ${BasicTitle}`]: {
    marginBottom: size.units(2),
  },

  [`& > ${BigTitle}`]: {
    marginBottom: size.units(3),
  },

  [`& > ${ExportToFoundryImage}`]: {
    marginBottom: size.units(2),
  },

  [`& > ${StyledTextArea}`]: {
    marginBottom: size.units(2),
  },

  [`& > ${StyledButton}`]: {
    marginBottom: size.units(2),
  },

  [`& > ${ExportToFoundryIconsContainer}`]: {
    marginBottom: size.units(2),
  },
}));

export const Wrapper = styled.div(({ theme: { size, mixins } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',

  paddingLeft: size.units(2),
  paddingRight: size.units(2),

  boxSizing: 'border-box',

  ...mixins.responsive.xs.downward({
    alignItems: 'center',
  }),

  width: '100%',
  height: 'auto',

  [`& > ${Section}`]: {
    marginTop: size.units(1.5),
    marginBottom: size.units(1.5),
  },
}));
