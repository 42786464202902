import {
  mapMentalPatternTypeToTable,
  MentalPattern,
  MentalPatternType,
} from './MentalPattern';

export const getMentalPatternFromType = (
  type: MentalPatternType,
): MentalPattern => {
  return new mapMentalPatternTypeToTable[type]();
};
