import { ReactNode } from 'react';
import { StyledCellProps } from '../../../TableCell/TableCell.styled';
import { StyledHeaderCell } from './TableHeaderCell.styled';

type TableHeaderCellProps = {
  children?: ReactNode | ReactNode[];
} & StyledCellProps;

export const TableHeaderCell = ({
  children,
  ...cellProps
}: TableHeaderCellProps) => {
  return (
    <StyledHeaderCell as="th" {...cellProps}>
      {children}
    </StyledHeaderCell>
  );
};
