export enum AdvantageType {
  Common = 'Common',
  Don = 'Don',
  Psychics = 'Psychics',
  Background = 'Background',
  BloodLegacy = 'BloodLegacy',
}

export type AdvantageVariation<T extends string> = { id: T; name: string };

export type AdvantageId = string;

export const AdvantageLevel1 = {
  id: 'Level1',

  name: 'Nivel 1',
} as const;
export const AdvantageLevel2 = {
  id: 'Level2',

  name: 'Nivel 2',
} as const;
export const AdvantageLevel3 = {
  id: 'Level3',

  name: 'Nivel 3',
} as const;

export type AdvantageLevel =
  | typeof AdvantageLevel1
  | typeof AdvantageLevel2
  | typeof AdvantageLevel3;

export type Advantage<Variation extends string = string> = {
  readonly id: AdvantageId;

  readonly type: AdvantageType;

  readonly name: string;

  readonly levels: AdvantageLevel[];

  readonly variations?: AdvantageVariation<Variation>[];
};
