import { AuthorizedPageLayout } from '../../../../shared/application/design-system/components/PageLayout/PageLayout';
import { DefaultNavbar } from '../../../../shared/application/design-system/components/TopNavbar/implementations/DefaultNavbar';
import { HowToImport } from './components/HowToImport/HowToImport';

export const HowToImportPage = () => {
  return (
    <AuthorizedPageLayout Header={<DefaultNavbar />}>
      <HowToImport />
    </AuthorizedPageLayout>
  );
};
