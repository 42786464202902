import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';
import { ArsMagnus } from '../../../../../aggregations/ars-magnus/ArgsMagnus';

@model('Character/Development/Category/Combat/ArsMagnus')
export class ArsMagnusModel extends Model({
  id: prop<string | undefined>().withSetter(),

  name: prop<string>().withSetter(),

  pdCost: prop<number>().withSetter(),

  cmCost: prop<number>().withSetter(),

  effect: prop<string>(),
}) {
  @computed
  get isCustom(): boolean {
    return this.id === undefined;
  }
}

export const createModelFromArsMagnus = (
  arsMagnus: ArsMagnus,
): ArsMagnusModel =>
  new ArsMagnusModel({
    id: arsMagnus.id,
    name: arsMagnus.name,
    pdCost: arsMagnus.pdCost,
    cmCost: arsMagnus.cmCost,
    effect: arsMagnus.effect,
  });
