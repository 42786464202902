import { observer } from 'mobx-react';
import { Armors } from './components/Armors/Armors';
import { Weapons } from './components/Weapons/Weapons';
import { Wrapper } from './Inventory.styled';

export const InventoryTab = observer(() => {
  return (
    <Wrapper>
      <Weapons />
      <Armors />
    </Wrapper>
  );
});
