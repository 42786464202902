import { User as SupabaseUser } from '@supabase/supabase-js';

import { User } from '../../user/domain/User';

export class CharacterCollaboration {
  public readonly id: string;

  public readonly characterId: string;

  public readonly owner: User;

  public readonly collaborator: User;

  constructor(params: {
    id: string;
    characterId: string;
    owner: User;
    collaborator: User;
  }) {
    this.id = params.id;
    this.characterId = params.characterId;
    this.owner = params.owner;
    this.collaborator = params.collaborator;
  }

  static fromPrimitives(params: {
    id: string;
    characterId: string;
    owner: SupabaseUser;
    collaborator: SupabaseUser;
  }) {
    return new CharacterCollaboration({
      id: params.id,
      characterId: params.characterId,
      owner: User.fromPrimitives(params.owner),
      collaborator: User.fromPrimitives(params.collaborator),
    });
  }
}
