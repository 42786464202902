import { Action } from '../../../IframeMessagingService.types';
import {
  ActorCharacterTransformer,
  ActorMutator,
  CharacterMutator,
} from '../../ActorCharacterTransformer';
import { FoundryVTTActor, FoundryVTTActorData } from './FoundryVTTActor';
import { mutateActorArmors } from './character-to-actor-mutations/mutateActorArmors';
import { mutateActorCharacteristics } from './character-to-actor-mutations/mutateActorCharacteristics';
import { mutateActorCombat } from './character-to-actor-mutations/mutateActorCombat';
import { mutateActorGeneral } from './character-to-actor-mutations/mutateActorGeneral';
import { mutateActorKi } from './character-to-actor-mutations/mutateActorKi';
import { mutateActorMystic } from './character-to-actor-mutations/mutateActorMystic';
import { mutateActorPsychic } from './character-to-actor-mutations/mutateActorPsychic';
import { mutateActorPsychicPowers } from './character-to-actor-mutations/mutateActorPsychicPowers';
import { mutateActorSecondaries } from './character-to-actor-mutations/mutateActorSecondaries';
import { mutateActorSpells } from './character-to-actor-mutations/mutateActorSpells';
import { mutateActorWeapons } from './character-to-actor-mutations/mutateActorWeapons';
import { mutateCharacterGeneral } from './actor-to-character-mutations/mutateCharacterGeneral';
import { mutateCharacterCharacteristics } from './actor-to-character-mutations/mutateCharacterCharacteristics';
import { mutateActorFlags } from './character-to-actor-mutations/mutateActorFlags';

const CharacterToActorMutators: ActorMutator<FoundryVTTActor>[] = [
  mutateActorCharacteristics,
  mutateActorSecondaries,
  mutateActorGeneral,
  mutateActorCombat,
  mutateActorWeapons,
  mutateActorArmors,
  mutateActorKi,
  mutateActorMystic,
  mutateActorSpells,
  mutateActorPsychic,
  mutateActorPsychicPowers,
  mutateActorFlags,
];

const ActorToCharacterMutators: CharacterMutator<FoundryVTTActor>[] = [
  mutateCharacterCharacteristics,
  mutateCharacterGeneral,
];

export const FoundryVTTActorTransformer: ActorCharacterTransformer<
  FoundryVTTActor,
  FoundryVTTActorData
> = ({ character, actor }) => {
  const toActor = () => {
    const transformation = CharacterToActorMutators.reduce(
      ({ actor, actions }, mutator) => {
        const { actor: newActor, actions: newActions } = mutator({
          actor,
          character,
        });

        return {
          actor: newActor,
          actions: actions.concat(newActions ?? []),
        };
      },
      { actor, actions: [] as unknown as Action[] },
    );

    return {
      actor: transformation.actor.toPrimitives(),
      actions: transformation.actions,
    };
  };

  const toCharacter = () => {
    return ActorToCharacterMutators.reduce(
      ({ character }, mutator) => {
        const { character: newCharacter } = mutator({
          actor,
          character,
        });

        return {
          character: newCharacter,
        };
      },
      { character },
    );
  };

  return {
    toActor,
    toCharacter,
  };
};
