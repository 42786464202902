import { Character } from '../../../../../../../../../domain/character/Character';
import { Tooltip } from '@mui/material';
import { Button } from '../../../../../../../../../../shared/application/design-system/components/Button/Button';
import { ButtonSize } from '../../../../../../../../../../shared/application/design-system/components/Button/Button.types';
import { useSaveCharacter } from '../../../../../../../../../../shared/application/hooks/useSaveCharacter';
import { makeCharacterPrivate } from './utils/makeCharacterPrivate';
import { makeCharacterPublic } from './utils/makeCharacterPublic';
import { NotificationManager } from '../../../../../../../../../../shared/application/shared/NotificationManager';
import { TrackingService } from '../../../../../../../../../../shared/infrastructure/tracking/TrackingService';

export const ToolbarPrivacyIcon = ({ character }: { character: Character }) => {
  const { saveCharacter } = useSaveCharacter();

  const handleSaveCharacter = (character: Character) => {
    const message = character.isPublic
      ? 'Ahora se muestra en la sala de reunión'
      : 'Ahora sólo lo puedes ver tu';

    return saveCharacter(character, { notify: false }).then(() => {
      NotificationManager.success(message);
    });
  };

  if (character.isPublic) {
    return (
      <Tooltip title="El personaje actualmente se muestra en la sala de reunión">
        <Button
          size={ButtonSize.Small}
          onClick={() => {
            makeCharacterPrivate(character, handleSaveCharacter);

            TrackingService.sendEvent('character_privacy_changed', {
              characterId: character.id,
              privacy: 'private',
            });
          }}
        >
          Personaje público
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="El personaje sólo lo puedes ver tu">
      <Button
        size={ButtonSize.Small}
        onClick={() => {
          makeCharacterPublic(character, handleSaveCharacter);

          TrackingService.sendEvent('character_privacy_changed', {
            characterId: character.id,
            privacy: 'public',
          });
        }}
      >
        Personaje privado
      </Button>
    </Tooltip>
  );
};
