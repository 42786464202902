import { SecondarySkill } from '../../character/model/types/SecondarySkill';
import { Archetype, CategoryTemplate, CategoryType } from './CategoryTemplate';

export class ConjurerCategoryTemplate extends CategoryTemplate {
  name = 'Conjurador';
  archetypes = [Archetype.Mystic];
  type = CategoryType.Conjurer;

  readonly lifeMultiplier = 20;
  lifePoints = 5;
  protected turn = 5;
  protected martialKnowledge = 10;
  protected nativePsychicPoints = 1;

  offensiveSkillsLimitPercentage = 0.5;
  attackPDsCost = 3;
  blockPDsCost = 3;
  dodgePDsCost = 2;
  wearArmorPDsCost = 3;
  kiPDsCost = 3;
  kiAccumMultiplierPDsCost = 30;

  magicSkillsLimitPercentage = 0.6;
  zeonPDsCost = 1;
  aCTMultiplierPDsCost = 60;
  magicProjectionPDsCost = 3;
  summonPDsCost = 1;
  controlPDsCost = 1;
  bindPDsCost = 1;
  banishPDsCost = 1;

  psychicSkillsLimitPercentage = 0.5;
  psychicPointsPDsCost = 20;
  psychicProjectionPDsCost = 3;

  athleticSecondariesPDsCost = 2;
  socialSecondariesPDsCost = 2;
  perceptionSecondariesPDsCost = 2;
  intellectualSecondariesPDsCost = 2;
  vigorSecondariesPDsCost = 3;
  subterfugeSecondariesPDsCost = 2;
  creativeSecondariesPDsCost = 2;

  zeonBonus = 50;
  summonBonus = 10;
  controlBonus = 10;
  bindBonus = 10;
  banishBonus = 10;

  calculateSecondarySkillPDReduction(skill: SecondarySkill): number {
    if (skill === SecondarySkill.Occultism) return 1;

    return 0;
  }

  calculateSecondarySkillBonusPerCategory(skill: SecondarySkill): number {
    if (skill === SecondarySkill.Occultism) return 10;
    if (skill === SecondarySkill.MagicAppraisal) return 5;

    return 0;
  }
}
