import { observer } from 'mobx-react';
import { CategoryModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/CategoryModel';
import { Wrapper } from './PsychicInfo.styled';
import { StateSmallParagraph } from '../../../PDsTab.styled';

export const PsychicInfo = observer(
  ({ category }: { category: CategoryModel }) => {
    const psychicLimit = Math.floor(
      category.pds * category.template.psychicSkillsLimitPercentage,
    );
    const psychicPDsUsed = category.psychic.pdsSpent;

    return (
      <Wrapper>
        <StateSmallParagraph $errored={psychicLimit < psychicPDsUsed}>
          PDs de Hab. Psíquicas ({psychicPDsUsed}/{psychicLimit})
        </StateSmallParagraph>
      </Wrapper>
    );
  },
);
