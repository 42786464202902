import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class KardadMartialArt extends BasicMartialArt {
  readonly name = 'Kardad';

  readonly complexity = 2;

  readonly type = MartialArtType.Kardad;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      effects:
        '+1 al valor de los controles a la hora de defenderse de presa o derribo. Incluso si está sometido.',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 10;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.athletic.athleticism.final < 40) {
          errors.push('Atletismo 40+');
        }

        if (character.secondaries.creative.sleightOfHand.final < 20) {
          errors.push('Trucos de manos 20+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      effects: 'El bono se incrementa a +3',
      combatBonus: {
        block: 10,
        dodge: 10,
      },
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 20;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.athletic.athleticism.final < 60) {
          errors.push('Atletismo 60+');
        }

        if (character.secondaries.creative.sleightOfHand.final < 40) {
          errors.push('Trucos de manos 40+');
        }

        if (character.combat.defensiveAbility < 120) {
          errors.push('Bloqueo o esquiva 120+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      combatBonus: {
        block: 10,
        dodge: 10,
      },
      effects:
        'Permite repetir una vez por asalto cualquier control enfrentado fallado para defenderse de presa, derribo o liberarse de un agarre.',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 30;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.athletic.athleticism.final < 120) {
          errors.push('Atletismo 120+');
        }

        if (character.secondaries.creative.sleightOfHand.final < 100) {
          errors.push('Trucos de manos 100+');
        }

        return errors;
      },
    }),
  ];
}
