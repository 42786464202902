import { Button } from '../../../../../../../../../../../../shared/application/design-system/components/Button/Button';
import { Modal } from '../../../../../../../../../../../../shared/application/design-system/components/Modal/Modal';
import { useEffect, useRef, useState } from 'react';
import { readFile } from '../../utils/readFile';
import { CropperContainer, Wrapper } from './AvatarCrop.styled';

import 'cropperjs/dist/cropper.css';

import { Cropper, ReactCropperElement } from 'react-cropper';
import { getRoundedImage } from './utils/getRoundedImage';
import { createGlobalStyle } from 'styled-components';

const CropperJSOverrideCSS = createGlobalStyle`
  .cropper-view-box,
  .cropper-face {
    border-radius: 50%;
  }
`;

export const AvatarCrop = ({
  file,
  onFinishCrop,
}: {
  file: File;
  onFinishCrop: (b: Blob) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState('');

  const cropperRef = useRef<ReactCropperElement>(null);

  useEffect(() => {
    const handleSetUrl = async () => {
      const url = await readFile(file);

      setUrl(url);
      setOpen(true);
    };

    handleSetUrl();
  }, [file]);

  const handleCloseModal = () => {
    setUrl('');
    setOpen(false);
  };

  const handleCropSave = async () => {
    const cropper = cropperRef.current?.cropper;

    if (!cropper) return;

    const file = await getRoundedImage(cropper.getCroppedCanvas());

    onFinishCrop(file);

    handleCloseModal();
  };

  return (
    <Modal open={open} onCloseClick={handleCloseModal}>
      <CropperJSOverrideCSS />

      <Wrapper>
        <CropperContainer>
          <Cropper
            src={url}
            style={{ height: '100%', width: '100%' }}
            initialAspectRatio={1}
            aspectRatio={1}
            viewMode={2}
            guides
            ref={cropperRef}
          />
        </CropperContainer>
        <Button onClick={handleCropSave}>Guardar</Button>
      </Wrapper>
    </Modal>
  );
};
