import { model } from 'mobx-keystone';
import { Calculation, NumberField } from '../../utils/fields/NumberField';

@model('PresenceField')
export class PresenceField extends NumberField {
  getCalculations(): Calculation[] {
    if (!this.character) return [];

    const calculations: Calculation[] = [];

    calculations.push(this.sum('Especial', this.special));

    if (this.character.level === 0) {
      calculations.push(this.sum('Base por ser nivel 0', 20));
    } else {
      calculations.push(this.sum('Base', 25));

      calculations.push(this.sum('5 por cada nivel', this.character.level * 5));
    }

    return calculations;
  }
}
