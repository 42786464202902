import { AllPsychicPowers } from '../AllPsychicPowers';
import { PsychicPower } from '../PsychicPower';

export const getUnusedPsychicPower = (
  spell: PsychicPower[],
): PsychicPower | undefined => {
  return AllPsychicPowers.find((psychicPower) => {
    return !spell.find((s) => s.id === psychicPower.id);
  });
};
