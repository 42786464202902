import { Model, model, prop } from 'mobx-keystone';
import { MagicSpell } from '../../../../../../aggregations/magic-spell/MagicSpell';
import {
  createModelFromMagicSpellCast,
  MagicSpellCastModel,
} from './MagicSpellCastModel';

@model('Character/Mystic/MagicSpell/Casts')
export class MagicSpellCastsModel extends Model({
  base: prop<MagicSpellCastModel>().withSetter(),
  intermediate: prop<MagicSpellCastModel>().withSetter(),
  advanced: prop<MagicSpellCastModel>().withSetter(),
  arcane: prop<MagicSpellCastModel>().withSetter(),
}) {
  toObject(): MagicSpell['casts'] {
    return {
      base: this.base.toObject(),
      intermediate: this.intermediate.toObject(),
      advanced: this.advanced.toObject(),
      arcane: this.arcane.toObject(),
    };
  }
}

export const createModelFromMagicSpellCasts = (
  casts: MagicSpell['casts'],
): MagicSpellCastsModel =>
  new MagicSpellCastsModel({
    base: createModelFromMagicSpellCast(casts.base),
    intermediate: createModelFromMagicSpellCast(casts.intermediate),
    advanced: createModelFromMagicSpellCast(casts.advanced),
    arcane: createModelFromMagicSpellCast(casts.arcane),
  });
