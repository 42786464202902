export enum Environment {
  Development = 'development',
  Production = 'production',
}

const environment =
  (process.env.REACT_APP_ENVIRONMENT as Environment) ?? Environment.Production;

export const config = {
  environment: environment,
  url:
    environment === Environment.Development
      ? 'http://localhost:3000'
      : 'https://app.charactersvault.com',

  buildNumber: process.env.REACT_APP_BUILD_NUMBER,
};
