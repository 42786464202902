import { computed } from 'mobx';
import {
  applySnapshot,
  getSnapshot,
  model,
  Model,
  modelAction,
  prop,
} from 'mobx-keystone';
import { v4 as uuid } from 'uuid';
import { HitType } from '../../character/model/types/HitType';
import { PrecalculatedFieldModel } from '../../character/model/utils/fields/PrecalculatedFieldModel';
import { getParentCharacter } from '../../utils/parenting/getParentCharacter';
import { AmmoItem } from '../ammo/AmmoItem';
import { WeaponAttackFieldModel } from './fields/WeaponAttackFieldModel';
import { WeaponBlockFieldModel } from './fields/WeaponBlockFieldModel';
import { WeaponBreakingFieldModel } from './fields/WeaponBreakingFieldModel';
import { WeaponCriticModel } from './fields/WeaponCriticModel';
import { WeaponDamageFieldModel } from './fields/WeaponDamageFieldModel';
import { WeaponInitiativeFieldModel } from './fields/WeaponInitiativeFieldModel';
import { WeaponIntegrityFieldModel } from './fields/WeaponIntegrityFieldModel';
import { WeaponPresenceFieldModel } from './fields/WeaponPresenceFieldModel';
import { WeaponRangeFieldModel } from './fields/WeaponRangeFieldModel';
import { WeaponReloadFieldModel } from './fields/WeaponReloadFieldModel';
import { weaponCtx } from './Weapon.context';
import {
  WeaponEquippedHandType,
  WeaponKnowledgeType,
  WeaponManageabilityType,
  WeaponShotType,
  WeaponSize,
  WeaponSizeProportion,
  WeaponType,
} from './Weapon.types';
import {
  ElegiblePredefinedWeapons,
  PredefinedWeapon,
} from './PredefinedWeapons';

@model('Items/Weapon')
export class WeaponItemModel extends Model(
  {
    id: prop(() => uuid()),

    originalId: prop<string | undefined>(undefined).withSetter(),
    isCustom: prop<boolean>(false).withSetter(),

    customKnowledgeType: prop<WeaponKnowledgeType | undefined>().withSetter(),

    name: prop<string>().withSetter(),
    equipped: prop(() => false).withSetter(),
    types: prop<WeaponType[]>(() => []).withSetter(),
    special: prop('').withSetter(),
    integrity: prop(() => new WeaponIntegrityFieldModel({})),
    breaking: prop(() => new WeaponBreakingFieldModel({})),
    attack: prop(() => new WeaponAttackFieldModel({})),
    block: prop(() => new WeaponBlockFieldModel({})),
    damage: prop(() => new WeaponDamageFieldModel({})),
    initiative: prop(() => new WeaponInitiativeFieldModel({})),
    presence: prop(() => new WeaponPresenceFieldModel({})),
    size: prop<WeaponSize>(WeaponSize.Small).withSetter(),
    sizeProportion: prop<WeaponSizeProportion>(
      WeaponSizeProportion.Normal,
    ).withSetter(),
    requiredStrength: prop(() => ({
      oneHand: new PrecalculatedFieldModel({}),
      twoHands: new PrecalculatedFieldModel({}),
    })),
    quality: prop(() => new PrecalculatedFieldModel({})),
    oneOrTwoHanded: prop<WeaponEquippedHandType>(
      WeaponEquippedHandType.OneHanded,
    ).withSetter(),
    manageabilityType: prop<WeaponManageabilityType>(
      WeaponManageabilityType.OneHand,
    ).withSetter(),
    shotType: prop<WeaponShotType | undefined>().withSetter(),
    critic: prop(
      () => new WeaponCriticModel({ primary: HitType.Cut }),
    ).withSetter(),

    ownStrength: prop<number | undefined>().withSetter(),
    range: prop(() => new WeaponRangeFieldModel({})),
    cadence: prop<number | undefined>(undefined).withSetter(),
    reload: prop(() => new WeaponReloadFieldModel({})),
    ammo: prop<AmmoItem | undefined>(),
  },
  { valueType: true },
) {
  get isRanged(): boolean {
    return this.types.includes(WeaponType.Projectiles);
  }

  get isShield(): boolean {
    return this.types.includes(WeaponType.Shield);
  }

  @computed
  get knowledgeType(): WeaponKnowledgeType {
    if (this.customKnowledgeType !== undefined) {
      return this.customKnowledgeType;
    }

    return this.calculatedKnowledgeType;
  }

  @computed
  get calculatedKnowledgeType(): WeaponKnowledgeType {
    if (!this.character) {
      return WeaponKnowledgeType.Different;
    }

    return this.character.calculateKnowledgeTypeOf(this);
  }

  @computed
  get template(): PredefinedWeapon | undefined {
    return ElegiblePredefinedWeapons.find(
      (w) => w.id === (this.isCustom ? this.originalId : this.id),
    );
  }

  get character() {
    return getParentCharacter(this);
  }

  @modelAction
  replaceType(oldType: WeaponType, newType: WeaponType) {
    const index = this.types.indexOf(oldType);

    if (index !== -1) {
      this.types[index] = newType;
    }
  }

  @modelAction
  addType(type: WeaponType) {
    if (!this.types.includes(type)) {
      this.types.push(type);
    }
  }

  @modelAction
  removeType(type: WeaponType) {
    this.types = this.types.filter((t) => t !== type);
  }

  @modelAction
  transformToCustom() {
    if (!this.isCustom) {
      this.isCustom = true;
      this.originalId = this.id;
      this.id = uuid();
    }
  }

  @modelAction
  resetToDefault() {
    if (!this.template) {
      return;
    }

    applySnapshot<WeaponItemModel>(
      this,
      getSnapshot(createWeaponItemModelFromPredefinedWeapon(this.template)),
    );
  }

  protected onInit() {
    weaponCtx.setComputed(this, () => this);
  }
}

export const createWeaponItemModelFromPredefinedWeapon = (
  predefinedWeapon: PredefinedWeapon,
): WeaponItemModel => {
  return new WeaponItemModel({
    id: predefinedWeapon.id,
    name: predefinedWeapon.name,
    types: predefinedWeapon.types,
    special: predefinedWeapon.special,
    integrity: new WeaponIntegrityFieldModel({
      base: predefinedWeapon.integrity,
    }),
    breaking: new WeaponBreakingFieldModel({
      base: predefinedWeapon.breaking,
    }),
    damage: new WeaponDamageFieldModel({
      base: predefinedWeapon.damage,
    }),
    presence: new WeaponPresenceFieldModel({
      base: predefinedWeapon.presence,
    }),
    ownStrength: predefinedWeapon.ownStrength,
    critic: new WeaponCriticModel({
      primary: predefinedWeapon.critic.primary,
      secondary: predefinedWeapon.critic.secondary,
    }),
    cadence: predefinedWeapon.cadence,
    reload: new WeaponReloadFieldModel({
      base: predefinedWeapon.reload,
    }),
    range: new WeaponRangeFieldModel({
      base: predefinedWeapon.range,
    }),
    requiredStrength: {
      oneHand: new PrecalculatedFieldModel({
        base: predefinedWeapon.requiredStrength.oneHand,
      }),
      twoHands: new PrecalculatedFieldModel({
        base: predefinedWeapon.requiredStrength.twoHands,
      }),
    },
  });
};
