import { model } from 'mobx-keystone';
import { calculateStrengthRequiredPenalty } from '../utils/calculateStrengthRequiredPenalty';
import { getWeaponItemModel } from '../utils/getWeaponItemModel';
import { getWeaponKnowledgePenalty } from '../utils/getWeaponKnowledgePenalty';
import { WeaponItemModel } from '../WeaponItemModel';
import { BaseFinalFieldModel } from '../../../character/model/utils/fields/BaseFinalFieldModel';

@model('WeaponItem/WeaponAttackField')
export class WeaponAttackFieldModel extends BaseFinalFieldModel {
  get final(): number {
    if (!this.character || !this.weapon) return 0;

    let attack = this.character.combat.attackAbility.final;

    attack += this.base;

    attack += this.special;

    attack += this.weapon.quality.final;

    attack += getWeaponKnowledgePenalty(this.weapon.knowledgeType);

    attack += calculateStrengthRequiredPenalty(this.weapon, this.character);

    return attack;
  }

  get weapon(): WeaponItemModel | undefined {
    return getWeaponItemModel(this);
  }
}
