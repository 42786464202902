import { observer } from 'mobx-react';
import { transformToMenuItems } from '../../../../../../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { Selector } from '../../../../../../../../common/Selector/Selector';
import { ArmorItemModel } from '../../../../../../../../../../../../../../domain/aggregations/armors/ArmorItemModel';
import {
  ArmorLocation,
  armorLocationTranslation,
} from '../../../../../../../../../../../../../../domain/aggregations/armors/Armor.types';

export const ArmorLocationSelector = observer(
  ({ armor }: { armor: ArmorItemModel }) => {
    const items = transformToMenuItems({
      fromItems: Object.values(ArmorLocation),
      generateItem: (armorLocation) => ({
        key: armorLocation,
        name: armorLocationTranslation[armorLocation],
        value: armorLocation,
        onClick: () => {
          armor.setLocalization(armorLocation);
        },
      }),
    });

    return (
      <Selector
        value={armor.localization}
        valueAsText={armorLocationTranslation[armor.localization]}
        items={items}
      />
    );
  },
);
