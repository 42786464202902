import {
  SmallParagraph,
  SmallTitle,
} from '../../../../../../../../../shared/application/design-system/components/Texts/Texts.styled';

import { Wrapper } from './RemoteActorItem.styled';
import { FoundryVTTActor } from '../../../../../../../../../embed/infrastructure/mutators/anima/foundry/FoundryVTTActor';

type RemoteActorData = {
  id: string;
  name: string;

  level: number;
  categories: string;

  primaries: {
    strength: number;
    agility: number;
    dexterity: number;
    constitution: number;
    perception: number;
    willPower: number;
    intelligence: number;
    power: number;
  };
};

const getDataFromActor = (actor: FoundryVTTActor): RemoteActorData => {
  return {
    id: actor.id,
    name: actor.name,

    level: actor.data.general.levels
      .map((l: any) => l.data.level)
      .reduce((a, b) => a + b, 0),

    categories: actor.data.general.levels.map((c: any) => c.name).join(', '),

    primaries: {
      agility: actor.data.characteristics.primaries.agility.value,
      strength: actor.data.characteristics.primaries.strength.value,
      dexterity: actor.data.characteristics.primaries.dexterity.value,
      constitution: actor.data.characteristics.primaries.constitution.value,
      intelligence: actor.data.characteristics.primaries.intelligence.value,
      perception: actor.data.characteristics.primaries.perception.value,
      power: actor.data.characteristics.primaries.power.value,
      willPower: actor.data.characteristics.primaries.willPower.value,
    },
  };
};

type RemoteActorItemProps = {
  actor: FoundryVTTActor;
  onClick: () => void;
};

export const RemoteActorItem = ({ actor, onClick }: RemoteActorItemProps) => {
  const item = getDataFromActor(actor);

  return (
    <Wrapper onClick={onClick}>
      <SmallTitle>{item.name}</SmallTitle>

      <SmallParagraph>
        Nivel: {item.level} ({item.categories})
      </SmallParagraph>
      <SmallParagraph>
        FUE ({item.primaries.strength}) / DES ({item.primaries.dexterity}) / CON
        ({item.primaries.constitution}) / INT ({item.primaries.intelligence}) /
        PER ({item.primaries.perception}) / AGI ({item.primaries.agility}) / POD
        ({item.primaries.power}) / VOL ({item.primaries.willPower})
      </SmallParagraph>
    </Wrapper>
  );
};
