export type News = {
  version: number;
  publishedAt: string;
  changes: Array<string | string[]>;
};

export const LastNews: News[] = [
  {
    version: 75,
    publishedAt: '13-06-2023',
    changes: ['Se ha añadido la sección de noticias'],
  },
  {
    version: 76,
    publishedAt: '13-06-2023',
    changes: [
      'Ahora se pueden compartir personajes individualmente desde el nuevo botón en la tarjeta del personaje. Los colaboradores tendrán acceso a la edición de los personajes compartidos.',
    ],
  },
  {
    version: 77,
    publishedAt: '13-06-2023',
    changes: [
      'Ahora si un personaje desarmado tiene más de un tipo de crítico, se importará a Foundry por separado. Tanto el ataque, la parada y la iniciativa se importan desde el mayor valor de las Artes Marciales aprendidas. <b>El valor de esquiva no es posible importarlo debido a que Foundry no permite designar una esquiva determinada por arma.</b>',
    ],
  },
  {
    version: 78,
    publishedAt: '18-06-2023',
    changes: [
      'Se han añadido las características automatizables de las siguientes razas: ',
      [
        '<b>Nephilim Sylvain</b>',
        '<b>Nephilim Jayán</b>',
        '<b>Nephilim D`Anjayni</b>',
        '<b>Nephilim Ebudan</b>',
        '<b>Nephilim Daimah</b>',
        '<b>Nephilim Duk`zarist (♂)</b>',
        '<b>Nephilim Duk`zarist (♀)</b>',
        '<b>Nephilim Devah</b>',
        '<b>Nephilim Vetala</b>',
        '<b>Nephilim Turak</b>',
        '<b>Sylvain, Jayán</b>',
        '<b>D`Anjayni</b>',
        '<b>Ebudan</b>',
        '<b>Daimah</b>',
        '<b>Duk`zarist (♂)</b>',
        '<b>Duk`zarist (♀)</b>',
        '<b>Devah</b>',
        '<b>Vetala</b>',
        '<b>Tuan Dalyr</b>',
        '<b>Turak</b>',
      ],
    ],
  },
  {
    version: 79,
    publishedAt: '18-06-2023',
    changes: [
      'Corrección: no se mostraba bien la información de los creadores de los personajes en la sala de reunión',
    ],
  },
  {
    version: 80,
    publishedAt: '29-06-2023',
    changes: [
      'Ahora se pueden editar armas',
      'El conocimiento de las armas se calcula automáticamente en base a el arma desarrollada o las tablas de armas aprendidas (quedan fuera las tablas de armas de artes marciales)',
    ],
  },
  {
    version: 81,
    publishedAt: '04-07-2023',
    changes: ['Ahora se pueden editar las armaduras'],
  },
  {
    version: 82,
    publishedAt: '07-07-2023',
    changes: [
      'Se ha añadido un check para marcar personajes como seres de acumulación',
    ],
  },
  {
    version: 83,
    publishedAt: '07-07-2023',
    changes: [
      'Se ha arreglado el acceso a Characters Vault en Foundry VTT desde el navegador de Firefox',
    ],
  },
  {
    version: 84,
    publishedAt: '12-07-2023',
    changes: [
      '¡Carpetas!',
      [
        'Ahora se pueden crear carpetas',
        'Puedes meter carpetas y personajes arrastrando y soltando a otras carpetas',
      ],
    ],
  },
].sort((a, b) => b.version - a.version);
