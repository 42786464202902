import { Container, Wrapper } from './FolderList.styled';
import { Folder } from '../../../domain/folder/Folder';
import { FolderItem } from './components/FolderItem/FolderItem';

interface FolderListProps {
  folders: Folder[];

  afterMoveFolderToFolder: () => void;

  onDeleteFolder: (folder: Folder) => void;
  onClickFolder: (folder: Folder) => void;
}

export const FolderList = ({
  folders,
  afterMoveFolderToFolder,
  onDeleteFolder,
  onClickFolder,
}: FolderListProps) => {
  return (
    <Wrapper>
      <Container>
        {folders.map((folder) => {
          const handleDeletion = () => {
            onDeleteFolder(folder);
          };

          const handlerFolderClick = () => {
            onClickFolder(folder);
          };

          return (
            <FolderItem
              key={folder.id}
              folder={folder}
              onDelete={handleDeletion}
              onClick={handlerFolderClick}
              afterMoveFolderToFolder={afterMoveFolderToFolder}
            />
          );
        })}
      </Container>
    </Wrapper>
  );
};
