export type MetamagicId = string;

export type Metamagic = {
  id: MetamagicId;

  name: string;

  levelReq?: number;

  cost: number;
};

export const AllMetamagics: Metamagic[] = [
  {
    id: '1',
    name: 'Eliminar protección (Nv 0)',
    cost: 5,
  },
  {
    id: '2',
    name: 'Erudición ofensiva (Nv 5)',
    levelReq: 5,
    cost: 10,
  },
  {
    id: '3',
    name: 'Conjuro especializado Nv50',
    levelReq: 2,
    cost: 5,
  },
  {
    id: '4',
    name: 'Distancia incrementada (Nv 0)',
    cost: 5,
  },
  {
    id: '5',
    name: 'Conjuro especializado Nv30',
    cost: 5,
  },
  {
    id: '6',
    name: 'Concentración mística',
    cost: 5,
  },
  {
    id: '7',
    name: 'Conjuro especializado Nv30',
    cost: 5,
  },
  {
    id: '8',
    name: 'Distancia incrementada (Nv 0)',
    cost: 5,
  },
  {
    id: '9',
    name: 'Conjuro especializado Nv60',
    levelReq: 3,
    cost: 5,
  },
  {
    id: '10',
    name: 'Maximización de conjuros',
    levelReq: 5,
    cost: 10,
  },
  {
    id: '11',
    name: 'Romper resistencias (Nv 2)',
    levelReq: 2,
    cost: 5,
  },
  {
    id: '12',
    name: 'Conjuro especializado Nv60',
    levelReq: 3,
    cost: 5,
  },
  {
    id: '13',
    name: 'Doble conjuro',
    levelReq: 7,
    cost: 20,
  },
  {
    id: '14',
    name: 'Conjuro especializado Nv80',
    levelReq: 8,
    cost: 5,
  },
  {
    id: '15',
    name: 'Enlazar conjuros',
    levelReq: 8,
    cost: 25,
  },
  {
    id: '16',
    name: 'Romper resistencias (Nv 4)',
    levelReq: 4,
    cost: 5,
  },
  {
    id: '17',
    name: 'Conjuro innato superior',
    levelReq: 9,
    cost: 20,
  },
  {
    id: '18',
    name: 'Alta Magia',
    levelReq: 10,
    cost: 20,
  },
  {
    id: '19',
    name: 'Conjuro especializado Nv70',
    levelReq: 5,
    cost: 5,
  },
  {
    id: '20',
    name: 'Zeón ilimitado',
    levelReq: 10,
    cost: 20,
  },
  {
    id: '21',
    name: 'Avatar',
    levelReq: 9,
    cost: 20,
  },
  {
    id: '22',
    name: 'Proyección mágica determinada (Nv 3)',
    levelReq: 3,
    cost: 5,
  },
  {
    id: '23',
    name: 'Regeneración zeónica avanzada (Nv 8)',
    levelReq: 8,
    cost: 5,
  },
  {
    id: '24',
    name: 'Elevación',
    levelReq: 6,
    cost: 5,
  },
  {
    id: '25',
    name: 'Proyección mágica determinada (Nv 10)',
    levelReq: 10,
    cost: 5,
  },
  {
    id: '26',
    name: 'Magia combinada',
    levelReq: 3,
    cost: 5,
  },
  {
    id: '27',
    name: 'Proyección mágica determinada (Nv 6)',
    levelReq: 6,
    cost: 5,
  },
  {
    id: '28',
    name: 'Efectos persistentes',
    levelReq: 3,
    cost: 5,
  },
  {
    id: '29',
    name: 'Explotación de la energía física (Nv 3)',
    levelReq: 3,
    cost: 5,
  },
  {
    id: '30',
    name: 'Proyección mágica determinada (Nv 0)',
    cost: 5,
  },
  {
    id: '31',
    name: 'Regeneración zeónica avanzada (Nv 0)',
    cost: 5,
  },
  {
    id: '32',
    name: 'Proyección mágica determinada (Nv 0)',
    cost: 5,
  },
  {
    id: '33',
    name: 'Explotación de la energía física (Nv 0)',
    cost: 5,
  },
  {
    id: '34',
    name: 'Proyección mágica determinada (Nv 4)',
    levelReq: 4,
    cost: 5,
  },
  {
    id: '35',
    name: 'Forzar velocidad (Nv 2)',
    levelReq: 2,
    cost: 5,
  },
  {
    id: '36',
    name: 'Proyección mágica determinada (Nv 5)',
    levelReq: 5,
    cost: 5,
  },
  {
    id: '37',
    name: 'Regeneración zeónica avanzada (Nv 5)',
    levelReq: 5,
    cost: 5,
  },
  {
    id: '38',
    name: 'Magia oculta',
    cost: 5,
  },
  {
    id: '39',
    name: 'Escudos potenciados (Nv 0)',
    cost: 5,
  },
  {
    id: '40',
    name: 'Incremento destructivo (Nv 0)',
    cost: 10,
  },
  {
    id: '41',
    name: 'Eliminar protección (Nv 0)',
    cost: 5,
  },
  {
    id: '42',
    name: 'Erudición defensiva (Nv 0)',
    cost: 10,
  },
  {
    id: '43',
    name: 'Eliminar protección (Nv 0)',
    cost: 5,
  },
  {
    id: '44',
    name: 'Área potenciada (Nv 3)',
    levelReq: 3,
    cost: 10,
  },
  {
    id: '45',
    name: 'Erudición ofensiva  (Nv 4)',
    levelReq: 4,
    cost: 10,
  },
  {
    id: '46',
    name: 'Precisión mística (Nv 2)',
    levelReq: 2,
    cost: 5,
  },
  {
    id: '47',
    name: 'Incremento destructivo (Nv 4)',
    levelReq: 4,
    cost: 10,
  },
  {
    id: '48',
    name: 'Escudos potenciados (Nv 4)',
    levelReq: 4,
    cost: 5,
  },
  {
    id: '49',
    name: 'Erudición defensiva (Nv 6)',
    levelReq: 6,
    cost: 10,
  },
  {
    id: '50',
    name: 'Precisión mística (Nv 6)',
    levelReq: 6,
    cost: 10,
  },
  {
    id: '51',
    name: 'Área potenciada (Nv 6)',
    levelReq: 6,
    cost: 10,
  },
  {
    id: '52',
    name: 'Erudición ofensiva  (Nv 7)',
    levelReq: 7,
    cost: 10,
  },
  {
    id: '53',
    name: 'Doble daño',
    levelReq: 10,
    cost: 20,
  },
  {
    id: '54',
    name: 'Erudición defensiva (Nv 5)',
    levelReq: 5,
    cost: 10,
  },
  {
    id: '55',
    name: 'Forzar velocidad (Nv 2)',
    levelReq: 2,
    cost: 5,
  },
  {
    id: '56',
    name: 'Doble conjuro innato',
    levelReq: 7,
    cost: 20,
  },
  {
    id: '57',
    name: 'Mantenimiento añadido',
    levelReq: 10,
    cost: 20,
  },
  {
    id: '58',
    name: 'Control del espacio',
    levelReq: 6,
    cost: 10,
  },
  {
    id: '59',
    name: 'Bucle existencial (Nv 6)',
    levelReq: 6,
    cost: 15,
  },
  {
    id: '60',
    name: 'Bucle existencial (Nv 9)',
    levelReq: 9,
    cost: 25,
  },
  {
    id: '61',
    name: 'Sentir la magia',
    cost: 5,
  },
  {
    id: '62',
    name: 'Transmisión de magia',
    levelReq: 3,
    cost: 10,
  },
  {
    id: '63',
    name: 'Magia vital (Nv 3)',
    levelReq: 3,
    cost: 5,
  },
  {
    id: '64',
    name: 'Aguante al daño sobrenatural',
    levelReq: 3,
    cost: 10,
  },
  {
    id: '65',
    name: 'Control de la energía',
    cost: 10,
  },
  {
    id: '66',
    name: 'Seguridad defensiva',
    cost: 5,
  },
  {
    id: '67',
    name: 'Magia vital (Nv 0)',
    cost: 5,
  },
  {
    id: '68',
    name: 'Forzar velocidad (Nv 0)',
    cost: 5,
  },
];

export const getMetamagicFromId = (id: MetamagicId): Metamagic => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return AllMetamagics.find((m) => m.id === id)!;
};

export const getUnusedMetamagic = (
  metamagics: MetamagicId[],
): Metamagic | undefined => {
  return AllMetamagics.find((m) => {
    return !metamagics.find((s) => s === m.id);
  });
};
