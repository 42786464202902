import { MouseEvent } from 'react';
import { useMenu } from '../../../../../../../../../shared/application/design-system/components/Menu/useMenu';
import { useCharacterContext } from '../../../hooks/useCharacterContext';

export const useCharacterMenu = <T,>(options?: { sorted?: boolean }) => {
  const { character } = useCharacterContext();

  const { handleMenuOpen, Menu } = useMenu<T>(options);

  return {
    handleMenuOpen: (item?: T) => (e: MouseEvent) => {
      if (!character.canBeEdited) return;

      handleMenuOpen(item)(e);
    },
    Menu,
  };
};
