import { DisadvantageLevel } from './CommonDisadvantages';

export enum DisadvantageType {
  Common = 'Common',
  Don = 'Don',
  Psychics = 'Psychics',
  Background = 'Background',
}

export type DisadvantageVariation<T extends string> = { id: T; name: string };

export type DisadvantageId = string;

export type Disadvantage<Variation extends string = string> = {
  readonly id: DisadvantageId;

  readonly type: DisadvantageType;

  readonly name: string;

  readonly levels: DisadvantageLevel[];

  readonly variations?: DisadvantageVariation<Variation>[];
};
