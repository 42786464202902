import {
  BasicMartialArtGradeIds,
  buildAdvancedMartialArtArcaneGrade,
  buildAdvancedMartialArtBasicGrade,
} from '../../MartialArtGrades';
import {
  AdvancedMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { calculateHighestDamageFromKnownMartialArts } from './utils/calculateHighestDamageFromKnownMartialArts';
import { HitType } from '../../../../character/model/types/HitType';
import { calculateMartialArtsRequirements } from './utils/calculateMartialArtsRequirements';

const BasicMartialArtsRequirement = [
  {
    type: MartialArtType.MallaYuddha,
    grade: BasicMartialArtGradeIds.Advanced,
  },
  {
    type: MartialArtType.MoaiThai,
    grade: BasicMartialArtGradeIds.Advanced,
  },
];

const ArcaneMartialArtsRequirement = [
  {
    type: MartialArtType.MallaYuddha,
    grade: BasicMartialArtGradeIds.Supreme,
  },
  {
    type: MartialArtType.MoaiThai,
    grade: BasicMartialArtGradeIds.Supreme,
  },
];

export class RexFrameMartialArt extends AdvancedMartialArt {
  readonly name = 'Rex Frame';

  readonly complexity = 3;

  readonly type = MartialArtType.RexFrame;

  readonly level = MartialArtLevelType.Advanced;

  readonly grades = [
    buildAdvancedMartialArtBasicGrade({
      cmBonus: 10,
      effects:
        'Obtiene armadura natural de +3 a todas las TAs. Barrera de daño 60.',
      combatBonus: {
        block: 10,
        dodge: 10,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          BasicMartialArtsRequirement,
        );

        if (!character.hasInhumanity) {
          errors.push('Inhumanidad');
        }

        if (character.combat.defensiveAbility < 200) {
          errors.push('Bloqueo o esquiva 200+');
        }

        return errors;
      },
    }),
    buildAdvancedMartialArtArcaneGrade({
      cmBonus: 30,
      effects: 'La TA aumenta a 6 y la barrera de daño a 200.',
      masterBonus: {
        block: 20,
        dodge: 20,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          ArcaneMartialArtsRequirement,
        );

        if (!character.hasZen) {
          errors.push('Zen');
        }

        if (character.combat.defensiveAbility < 300) {
          errors.push('Bloqueo o esquiva 300+');
        }

        return errors;
      },
    }),
  ];
}
