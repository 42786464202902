import { SupabaseClient } from '../../shared/infrastructure/supabase/supabaseClient';
import { ApiClient } from '../../shared/infrastructure/api/ApiClient';

const changeUsername = async (username: string) => {
  await SupabaseClient().auth.updateUser({ data: { username } });
};

const checkIfUsernameExist = async (username: string): Promise<boolean> => {
  return ApiClient.get<boolean>(`/users/exist/${username}`).then((r) => r.data);
};

const changePassword = async (password: string) => {
  await SupabaseClient().auth.updateUser({ password });
};

export const UserService = {
  changeUsername,
  checkIfUsernameExist,
  changePassword,
};
