import { Tooltip } from '@mui/material';
import { Table } from '../../../../../../../common/Table/Table';
import { StyledWarning } from './FieldTotals.styled';

type DevelopedFieldTotalsProps = {
  total: number;
  maximum?: number;
};

export const FieldTotals = ({ total, maximum }: DevelopedFieldTotalsProps) => {
  return (
    <Table.Row>
      {maximum !== undefined ? (
        <Table.Row.Cell>
          <Tooltip title="Maximum">
            <span>Max. {maximum}</span>
          </Tooltip>
        </Table.Row.Cell>
      ) : undefined}
      <Table.Row.Cell>
        <Tooltip title="Total spent">
          <span>Total: {total}</span>
        </Tooltip>
      </Table.Row.Cell>
      {maximum !== undefined && total > maximum ? (
        <Table.Row.Cell>
          <Tooltip title="El total gastado supera el permitido">
            <StyledWarning />
          </Tooltip>
        </Table.Row.Cell>
      ) : undefined}
    </Table.Row>
  );
};
