import { observer } from 'mobx-react';
import DoubleInput from '../../../common/DoubleInput/DoubleInput';
import { Input } from '../../../common/Input/Input';
import { Section } from '../../../common/Section/Section';
import { useCharacterContext } from '../../../../hooks/useCharacterContext';

export const PsychicPotentialAndProject = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  return (
    <Section maxHeight="fit">
      <Section.Title>Potencial y proyección</Section.Title>

      <DoubleInput
        label="Proyección psíquica"
        field={character.psychic.psychicProjection}
      />
      <DoubleInput
        label="Potencial psíquico"
        field={character.psychic.psychicPotential}
      />
      <Input
        label="CVs usados para mejorar el potencial"
        value={character.psychic.psychicPotential.ppUsedToIncrease}
        setValue={(v) =>
          character.psychic.psychicPotential.setPpUsedToIncrease(v)
        }
      />

      <Input
        label="Valor psíquico por cristales psíquicos"
        value={character.psychic.psychicCrystalPotential}
        setValue={(v) => character.psychic.setPsychicCrystalPotential(v)}
      />
    </Section>
  );
});
