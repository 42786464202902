import { NephilimSelectorText } from '../Description/Description.styled';
import { useCharacterMenu } from '../../../../common/CharacterMenu/useCharacterMenu';
import {
  AllNephilimTypes,
  NephilimType,
} from '../../../../../../../../../../domain/aggregations/nephilim-type/Nephilim';
import { transformToMenuItems } from '../../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { observer } from 'mobx-react';
import { NephilimEffects } from './components/NephilimEffects/NephilimEffects';

export const NephilimSelector = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { handleMenuOpen: handleNephilimMenuOpen, Menu: NephilimMenu } =
    useCharacterMenu<NephilimType | undefined>();

  const noNephilimOption = {
    key: 'undefined',
    value: undefined,
    name: 'Ninguno',
    onClick: () => {
      character.description.setNephilim(undefined);
    },
  };

  const nephilimMenuItems = transformToMenuItems({
    fromItems: [undefined, ...AllNephilimTypes],
    generateItem: (i) => {
      if (i === undefined) {
        return noNephilimOption;
      }

      return {
        key: i.id,
        value: i,
        name: i.name,
        onClick: () => {
          character.description.setNephilim(i);
        },
      };
    },
  });

  return (
    <>
      <NephilimSelectorText
        onClick={handleNephilimMenuOpen(character.description.nephilim)}
      >
        {character.description.nephilim?.name ?? noNephilimOption.name}
      </NephilimSelectorText>
      <NephilimMenu items={nephilimMenuItems} />
      {character.description.nephilim !== undefined ? (
        <NephilimEffects nephilim={character.description.nephilim} />
      ) : undefined}
    </>
  );
});
