import { observer } from 'mobx-react';
import { MouseEvent, useState } from 'react';
import { armorTypeTranslation } from '../../../../../../../../../../domain/aggregations/armors/Armor.types';
import { ArmorItemModel } from '../../../../../../../../../../domain/aggregations/armors/ArmorItemModel';
import {
  PredefinedArmor,
  PredefinedArmors,
} from '../../../../../../../../../../domain/aggregations/armors/PredefinedArmors';
import { AddItemButton } from '../../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../../common/CharacterMenu/useCharacterMenu';
import { transformToMenuItems } from '../../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { ArmorRow } from './components/ArmorRow/ArmorRow';
import { MenuItem } from '../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';

export const Armors = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { handleMenuOpen: handleArmorMenuOpen, Menu: ArmorMenu } =
    useCharacterMenu<PredefinedArmor>();

  const [selectedArmor, setSelectedArmor] = useState<ArmorItemModel | null>(
    null,
  );

  const predefinedArmors: MenuItem<PredefinedArmor>[] = transformToMenuItems({
    fromItems: PredefinedArmors,
    generateItem: (i) => ({
      key: i.id,
      name: i.name,
      value: i,
      onClick: () => {
        if (selectedArmor) {
          character.inventory.updateArmor(selectedArmor, i);
        }
      },
    }),
    generateDivider: (i) => armorTypeTranslation[i.type],
  });

  const handleArmorSelection =
    (armor: ArmorItemModel) => (e: MouseEvent<HTMLDivElement>) => {
      setSelectedArmor(armor);
      handleArmorMenuOpen(armor.template)(e);
    };

  const handleAddArmor = () => {
    character.inventory.addArmor(PredefinedArmors[0]);
  };

  const handleRemoveArmor = (armorModel: ArmorItemModel) => {
    character.inventory.removeArmor(armorModel);
  };

  return (
    <Section>
      <Section.Title>Armaduras</Section.Title>

      <Table>
        <Table.Header>
          <Table.Header.Cell $width={0} $hideOnMobile />
          <Table.Header.Cell $width={2}>Equipada</Table.Header.Cell>
          <Table.Header.Cell $width={5}>Nombre</Table.Header.Cell>
          <Table.Header.Cell $hideOnMobile $width={6}>
            FIL
          </Table.Header.Cell>
          <Table.Header.Cell $hideOnMobile $width={6}>
            CON
          </Table.Header.Cell>
          <Table.Header.Cell $hideOnMobile $width={6}>
            PEN
          </Table.Header.Cell>
          <Table.Header.Cell $hideOnMobile $width={6}>
            CAL
          </Table.Header.Cell>
          <Table.Header.Cell $hideOnMobile $width={6}>
            FRI
          </Table.Header.Cell>
          <Table.Header.Cell $hideOnMobile $width={6}>
            ELE
          </Table.Header.Cell>
          <Table.Header.Cell $hideOnMobile $width={6}>
            ENE
          </Table.Header.Cell>
          <Table.Header.Cell $width={1} />
        </Table.Header>

        {character.inventory.armors.map((armor) => (
          <ArmorRow
            key={armor.id}
            armor={armor}
            onNameClick={handleArmorSelection}
            onRemove={handleRemoveArmor}
          />
        ))}
      </Table>

      <AddItemButton onClick={handleAddArmor} />

      <ArmorMenu items={predefinedArmors} />
    </Section>
  );
});
