import { model, Model, prop } from 'mobx-keystone';
import { AgilityPrimaryFieldModel } from './fields/AgilityPrimaryFieldModel';
import { ConstitutionPrimaryFieldModel } from './fields/ConstitutionPrimaryFieldModel';
import { DexterityPrimaryFieldModel } from './fields/DexterityPrimaryFieldModel';
import { IntelligencePrimaryFieldModel } from './fields/IntelligencePrimaryFieldModel';
import { PerceptionPrimaryFieldModel } from './fields/PerceptionPrimaryFieldModel';
import { PowerPrimaryFieldModel } from './fields/PowerPrimaryFieldModel';
import { StrengthPrimaryFieldModel } from './fields/StrengthPrimaryFieldModel';
import { WillPowerPrimaryFieldModel } from './fields/WillPowerPrimaryFieldModel';

@model('PrimaryCharacteristicsFields')
export class PrimaryFields extends Model({
  power: prop(() => new PowerPrimaryFieldModel({})),
  willPower: prop(() => new WillPowerPrimaryFieldModel({})),
  agility: prop(() => new AgilityPrimaryFieldModel({})),
  constitution: prop(() => new ConstitutionPrimaryFieldModel({})),
  dexterity: prop(() => new DexterityPrimaryFieldModel({})),
  intelligence: prop(() => new IntelligencePrimaryFieldModel({})),
  perception: prop(() => new PerceptionPrimaryFieldModel({})),
  strength: prop(() => new StrengthPrimaryFieldModel({})),
}) {}
