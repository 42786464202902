export const getRoundedImage = (sourceCanvas: HTMLCanvasElement) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const width = sourceCanvas.width;
  const height = sourceCanvas.height;

  if (!context) {
    throw new Error('Canvas context not found');
  }

  canvas.width = width;
  canvas.height = height;
  context.imageSmoothingEnabled = true;
  context.drawImage(sourceCanvas, 0, 0, width, height);
  context.globalCompositeOperation = 'destination-in';
  context.beginPath();
  context.arc(
    width / 2,
    height / 2,
    Math.min(width, height) / 2,
    0,
    2 * Math.PI,
    true,
  );
  context.fill();

  return new Promise<Blob>((resolve) => {
    canvas.toBlob((file) => {
      if (!file) {
        throw new Error('File not found');
      }

      resolve(file as Blob);
    }, 'image/png');
  });
};
