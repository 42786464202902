import { Difficulty } from '../../character/model/types/Difficulty';
import { PsychicDisciplineType } from '../psychic-disciplines/PsychicDiscipline';
import { PsychicPower, PsychicPowerActionType } from './PsychicPower';

export const AllPsychicPowers: PsychicPower[] = [
  {
    id: '1',
    name: 'Escudo psíquico',
    discipline: PsychicDisciplineType.Telepathy,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Passive,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: 'RP +10',
      [Difficulty.Hard]: 'RP +30',
      [Difficulty.VeryHard]: 'RP +50',
      [Difficulty.Absurd]: 'RP +80',
      [Difficulty.AlmostImpossible]: 'RP +120',
      [Difficulty.Impossible]: 'RP +160',
      [Difficulty.Inhuman]: 'RP +200',
      [Difficulty.Zen]: 'RP +240',
    },
  },
  {
    id: '2',
    name: 'Comunicación mental',
    discipline: PsychicDisciplineType.Telepathy,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '100 m',
      [Difficulty.Hard]: '500 m',
      [Difficulty.VeryHard]: '1000 m',
      [Difficulty.Absurd]: '10 km',
      [Difficulty.AlmostImpossible]: '100 km',
      [Difficulty.Impossible]: '1000 km',
      [Difficulty.Inhuman]: '5000 km',
      [Difficulty.Zen]: 'Cualquier distancia',
    },
  },
  {
    id: '3',
    name: 'Lectura mental',
    discipline: PsychicDisciplineType.Telepathy,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '100 RP',
      [Difficulty.Hard]: '120 RP',
      [Difficulty.VeryHard]: '140 RP',
      [Difficulty.Absurd]: '160 RP',
      [Difficulty.AlmostImpossible]: '180 RP',
      [Difficulty.Impossible]: '200 RP',
      [Difficulty.Inhuman]: '220 RP',
      [Difficulty.Zen]: '240 RP',
    },
  },
  {
    id: '4',
    name: 'Escaneo de zona',
    discipline: PsychicDisciplineType.Telepathy,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2 ',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '100 RP y 10 m de radio',
      [Difficulty.Hard]: '120 RP y 50 m de radio',
      [Difficulty.VeryHard]: '140 RP y 100 m de radio',
      [Difficulty.Absurd]: '160 RP y 250 m de radio',
      [Difficulty.AlmostImpossible]: '180 RP y 500 m de radio',
      [Difficulty.Impossible]: '200 RP y 1 km de radio',
      [Difficulty.Inhuman]: '220 RP y 10 km de radio',
      [Difficulty.Zen]: '260 RP y 100 km de radio',
    },
  },
  {
    id: '5',
    name: 'Ilusión psíquica',
    discipline: PsychicDisciplineType.Telepathy,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '80 RP',
      [Difficulty.Hard]: '100 RP',
      [Difficulty.VeryHard]: '120 RP',
      [Difficulty.Absurd]: '140 RP',
      [Difficulty.AlmostImpossible]: '160 RP',
      [Difficulty.Impossible]: '180 RP',
      [Difficulty.Inhuman]: '200 RP',
      [Difficulty.Zen]: '220 RP',
    },
  },
  {
    id: '6',
    name: 'Prohibición mental',
    discipline: PsychicDisciplineType.Telepathy,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: '80 RP',
      [Difficulty.Hard]: '100 RP',
      [Difficulty.VeryHard]: '120 RP',
      [Difficulty.Absurd]: '140 RP',
      [Difficulty.AlmostImpossible]: '160 RP',
      [Difficulty.Impossible]: '180 RP',
      [Difficulty.Inhuman]: '200 RP',
      [Difficulty.Zen]: '220 RP',
    },
  },
  {
    id: '7',
    name: 'Análisis mental',
    discipline: PsychicDisciplineType.Telepathy,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: '100 RP',
      [Difficulty.VeryHard]: '120 RP',
      [Difficulty.Absurd]: '140 RP',
      [Difficulty.AlmostImpossible]: '160 RP',
      [Difficulty.Impossible]: '180 RP',
      [Difficulty.Inhuman]: '200 RP',
      [Difficulty.Zen]: '240 RP',
    },
  },
  {
    id: '8',
    name: 'Asalto psíquico',
    discipline: PsychicDisciplineType.Telepathy,
    level: 2,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: '120 RP',
      [Difficulty.VeryHard]: '140 RP',
      [Difficulty.Absurd]: '160 RP',
      [Difficulty.AlmostImpossible]: '180 RP',
      [Difficulty.Impossible]: '200 RP',
      [Difficulty.Inhuman]: '220 RP',
      [Difficulty.Zen]: '260 RP',
    },
  },
  {
    id: '9',
    name: 'Conexión psíquica',
    discipline: PsychicDisciplineType.Telepathy,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: '100 m',
      [Difficulty.VeryHard]: '500 m',
      [Difficulty.Absurd]: '1000 m',
      [Difficulty.AlmostImpossible]: '10 km',
      [Difficulty.Impossible]: '100 km',
      [Difficulty.Inhuman]: '1000 km',
      [Difficulty.Zen]: 'Cualquier distancia',
    },
  },
  {
    id: '10',
    name: 'Modificación de recuerdos',
    discipline: PsychicDisciplineType.Telepathy,
    level: 2,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 6',
      [Difficulty.Medium]: 'Fatiga 4',
      [Difficulty.Hard]: 'Fatiga 2',
      [Difficulty.VeryHard]: '100 RP',
      [Difficulty.Absurd]: '120 RP',
      [Difficulty.AlmostImpossible]: '140 RP',
      [Difficulty.Impossible]: '160 RP',
      [Difficulty.Inhuman]: '180 RP',
      [Difficulty.Zen]: '200 RP',
    },
  },
  {
    id: '11',
    name: 'Forma astral',
    discipline: PsychicDisciplineType.Telepathy,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 6',
      [Difficulty.Medium]: 'Fatiga 4',
      [Difficulty.Hard]: 'Fatiga 2',
      [Difficulty.VeryHard]: 'Hasta 10 km de distancia',
      [Difficulty.Absurd]: 'Hasta 100 km de distancia',
      [Difficulty.AlmostImpossible]: 'Hasta 500 km de distancia',
      [Difficulty.Impossible]: 'Hasta 1000 km de distancia',
      [Difficulty.Inhuman]: 'Hasta 5000 km de distancia',
      [Difficulty.Zen]: 'Cualquier distancia',
    },
  },
  {
    id: '12',
    name: 'Localización psíquica',
    discipline: PsychicDisciplineType.Telepathy,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 6',
      [Difficulty.Medium]: 'Fatiga 4',
      [Difficulty.Hard]: 'Fatiga 2',
      [Difficulty.VeryHard]: '10 km de distancia / 140 RP',
      [Difficulty.Absurd]: '100 km de distancia / 160 RP',
      [Difficulty.AlmostImpossible]: '500 km de distancia / 180 RP',
      [Difficulty.Impossible]: '1000 km de distancia / 200 RP',
      [Difficulty.Inhuman]: '5000 km de distancia / 220 RP',
      [Difficulty.Zen]: 'Cualquier distancia / 260 RP',
    },
  },
  {
    id: '13',
    name: 'Control mental',
    discipline: PsychicDisciplineType.Telepathy,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 12',
      [Difficulty.Easy]: 'Fatiga 8',
      [Difficulty.Medium]: 'Fatiga 6',
      [Difficulty.Hard]: 'Fatiga 4',
      [Difficulty.VeryHard]: '100 RP',
      [Difficulty.Absurd]: '120 RP',
      [Difficulty.AlmostImpossible]: '140 RP',
      [Difficulty.Impossible]: '160 RP',
      [Difficulty.Inhuman]: '180 RP',
      [Difficulty.Zen]: '220 RP',
    },
  },
  {
    id: '14',
    name: 'Muerte psíquica',
    discipline: PsychicDisciplineType.Telepathy,
    level: 3,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: '140 RP',
      [Difficulty.AlmostImpossible]: '160 RP',
      [Difficulty.Impossible]: '180 RP',
      [Difficulty.Inhuman]: '220 RP',
      [Difficulty.Zen]: '240 RP',
    },
  },
  {
    id: '15',
    name: 'Área (Telepatía)',
    discipline: PsychicDisciplineType.Telepathy,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 4',
      [Difficulty.VeryHard]: '10 m',
      [Difficulty.Absurd]: '100 m',
      [Difficulty.AlmostImpossible]: '1000 m',
      [Difficulty.Impossible]: '10 km',
      [Difficulty.Inhuman]: '100 km',
      [Difficulty.Zen]: '500 km',
    },
  },
  {
    id: '16',
    name: 'Telequinesis menor',
    discipline: PsychicDisciplineType.Telekinesis,
    level: 1,
    minimalDifficultyToSuccess: Difficulty.Easy,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 1',
      [Difficulty.Easy]: '1 Kg / Tipo de vuelo 4',
      [Difficulty.Medium]: '2 Kg / Tipo de vuelo 6',
      [Difficulty.Hard]: '5 Kg / Tipo de vuelo 8',
      [Difficulty.VeryHard]: '10 Kg / Tipo de vuelo 10',
      [Difficulty.Absurd]: '20 Kg / Tipo de vuelo 12',
      [Difficulty.AlmostImpossible]: '40 Kg / Tipo de vuelo 14',
      [Difficulty.Impossible]: '100 Kg / Tipo de vuelo 16',
      [Difficulty.Inhuman]: '200 Kg / Tipo de vuelo 18',
      [Difficulty.Zen]: '500 Kg / Tipo de vuelo 20',
    },
  },
  {
    id: '17',
    name: 'Impacto telequinético',
    discipline: PsychicDisciplineType.Telekinesis,
    level: 1,
    hasMaintenance: false,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: 'Fuerza 8',
      [Difficulty.Hard]: 'Fuerza 10',
      [Difficulty.VeryHard]: 'Fuerza 12',
      [Difficulty.Absurd]: 'Fuerza 14',
      [Difficulty.AlmostImpossible]: 'Fuerza 15',
      [Difficulty.Impossible]: 'Fuerza 16',
      [Difficulty.Inhuman]: 'Fuerza 18',
      [Difficulty.Zen]: 'Fuerza 20',
    },
  },
  {
    id: '18',
    name: 'Escudo telequinético',
    discipline: PsychicDisciplineType.Telekinesis,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Passive,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '300 PVs',
      [Difficulty.Hard]: '500 PVs',
      [Difficulty.VeryHard]: '700 PVs',
      [Difficulty.Absurd]: '1000 PVs',
      [Difficulty.AlmostImpossible]: '1500 PVs / Barrera de daño 60',
      [Difficulty.Impossible]: '2000 PVs / BD 80 Para energía',
      [Difficulty.Inhuman]: '3000 PVs / BD 120 Para energía',
      [Difficulty.Zen]: '5000 PVs / BD 160 Para energía',
    },
  },
  {
    id: '19',
    name: 'Armadura  telequinética',
    discipline: PsychicDisciplineType.Telekinesis,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Passive,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: 'TA 1',
      [Difficulty.Hard]: 'TA 2',
      [Difficulty.VeryHard]: 'TA 4',
      [Difficulty.Absurd]: 'TA 6',
      [Difficulty.AlmostImpossible]: 'TA 8',
      [Difficulty.Impossible]: 'TA 10',
      [Difficulty.Inhuman]: 'TA 12',
      [Difficulty.Zen]: 'TA 14',
    },
  },
  {
    id: '20',
    name: 'Presa telequinética',
    discipline: PsychicDisciplineType.Telekinesis,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: 'Fuerza 6',
      [Difficulty.Hard]: 'Fuerza 8',
      [Difficulty.VeryHard]: 'Fuerza 10',
      [Difficulty.Absurd]: 'Fuerza 12 / Radio 5 m',
      [Difficulty.AlmostImpossible]: 'Fuerza 14 / Radio 10 m',
      [Difficulty.Impossible]: 'Fuerza 15 / Radio 50 m',
      [Difficulty.Inhuman]: 'Fuerza 16 / Radio 100 m',
      [Difficulty.Zen]: 'Fuerza 18 / Radio 500 m',
    },
  },
  {
    id: '21',
    name: 'Balística',
    discipline: PsychicDisciplineType.Telekinesis,
    level: 2,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: '+0 Proyección / 5 metros',
      [Difficulty.VeryHard]: '+10 Proyección / 10 metros',
      [Difficulty.Absurd]: '+20 Proyección / 15 metros',
      [Difficulty.AlmostImpossible]: '+30 Proyección / 25 metros',
      [Difficulty.Impossible]: ' +40 Proyección / 40 metros',
      [Difficulty.Inhuman]: ' +50 Proyección / 80 metros',
      [Difficulty.Zen]: '+60 Proyección / 150 metros',
    },
  },
  {
    id: '22',
    name: 'Detección de movimiento',
    discipline: PsychicDisciplineType.Telekinesis,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: '120 RP y 10 m de radio',
      [Difficulty.VeryHard]: '160 RP y 50 m de radio',
      [Difficulty.Absurd]: '200 RP y 100 m de radio',
      [Difficulty.AlmostImpossible]: '240 RP y 500 m de radio',
      [Difficulty.Impossible]: '280 RP y 1000 m de radio',
      [Difficulty.Inhuman]: '320 RP y 10 km de radio',
      [Difficulty.Zen]: '400 RP y 100 km de radio',
    },
  },
  {
    id: '23',
    name: 'Repulsión',
    discipline: PsychicDisciplineType.Telekinesis,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 6',
      [Difficulty.Medium]: 'Fatiga 4',
      [Difficulty.Hard]: 'Fatiga 2',
      [Difficulty.VeryHard]: 'Fuerza 6 /Línea de 2 m',
      [Difficulty.Absurd]: 'Fuerza 8 / Línea de 5 m',
      [Difficulty.AlmostImpossible]: 'Fuerza 10 / Línea de 10 m',
      [Difficulty.Impossible]: 'Fuerza 12 / Línea de 20 m',
      [Difficulty.Inhuman]: 'Fuerza 14 / Línea de 50 m',
      [Difficulty.Zen]: 'Fuerza 18 / Línea de 100 m',
    },
  },
  {
    id: '24',
    name: 'Destrozar',
    discipline: PsychicDisciplineType.Telekinesis,
    level: 2,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 6',
      [Difficulty.Medium]: 'Fatiga 4',
      [Difficulty.Hard]: 'Fatiga 2',
      [Difficulty.VeryHard]: '100 RF',
      [Difficulty.Absurd]: '120 RF',
      [Difficulty.AlmostImpossible]: '140 RF',
      [Difficulty.Impossible]: '160 RF',
      [Difficulty.Inhuman]: '180 RF',
      [Difficulty.Zen]: '220 RF',
    },
  },
  {
    id: '25',
    name: 'Vuelo telequinético',
    discipline: PsychicDisciplineType.Telekinesis,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: 'Tipo de vuelo 6',
      [Difficulty.VeryHard]: 'Tipo de vuelo 8',
      [Difficulty.Absurd]: 'Tipo de vuelo 10',
      [Difficulty.AlmostImpossible]: 'Tipo de vuelo 12',
      [Difficulty.Impossible]: 'Tipo de vuelo 14',
      [Difficulty.Inhuman]: 'Tipo de vuelo 16',
      [Difficulty.Zen]: 'Tipo de vuelo 18',
    },
  },
  {
    id: '26',
    name: 'Telequinesis orgánica',
    discipline: PsychicDisciplineType.Telekinesis,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: '100 Kg / Tipo vuelo 4 / 100 RF',
      [Difficulty.VeryHard]: '250 Kg / Tipo vuelo 6 / 120 RF',
      [Difficulty.Absurd]: '500 Kg / Tipo vuelo 8 / 140 RF',
      [Difficulty.AlmostImpossible]: '1 Tn / Tipo vuelo 10 / 160 RF',
      [Difficulty.Impossible]: '2,5 Tn / Tipo vuelo 12 / 180 RF',
      [Difficulty.Inhuman]: '5 Tn / Tipo vuelo 14 /  200 RF',
      [Difficulty.Zen]: '10 Tn / Tipo vuelo 16 / 220 RF',
    },
  },
  {
    id: '27',
    name: 'Control del terreno',
    discipline: PsychicDisciplineType.Telekinesis,
    level: 3,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: '10 m / Barrera de daño 40',
      [Difficulty.AlmostImpossible]: '100 m / Barrera de daño 60',
      [Difficulty.Impossible]: '250 m / Barrera de daño 80',
      [Difficulty.Inhuman]: '500 m / Barrera de daño 100',
      [Difficulty.Zen]: '1 km / Barrera de daño 140',
    },
  },
  {
    id: '28',
    name: 'Telequinesis mayor',
    discipline: PsychicDisciplineType.Telekinesis,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.AlmostImpossible,
    casts: {
      [Difficulty.Routine]: 'Fatiga 24',
      [Difficulty.Easy]: 'Fatiga 20',
      [Difficulty.Medium]: 'Fatiga 16',
      [Difficulty.Hard]: 'Fatiga 12',
      [Difficulty.VeryHard]: 'Fatiga 8',
      [Difficulty.Absurd]: 'Fatiga 4',
      [Difficulty.AlmostImpossible]: '500 Tn / Tipo de vuelo 4',
      [Difficulty.Impossible]: '10000 Tn / Tipo de vuelo 6',
      [Difficulty.Inhuman]: '100000 Tn / Tipo de vuelo 8',
      [Difficulty.Zen]: '1000000 Tn / Tipo de vuelo 10',
    },
  },
  {
    id: '29',
    name: 'Reestructuración atómica',
    discipline: PsychicDisciplineType.Telekinesis,
    level: 3,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Impossible,
    casts: {
      [Difficulty.Routine]: 'Fatiga 24',
      [Difficulty.Easy]: 'Fatiga 20',
      [Difficulty.Medium]: 'Fatiga 16',
      [Difficulty.Hard]: 'Fatiga 12',
      [Difficulty.VeryHard]: 'Fatiga 8',
      [Difficulty.Absurd]: 'Fatiga 6',
      [Difficulty.AlmostImpossible]: 'Fatiga 4',
      [Difficulty.Impossible]: '140 RF / 100 Kg',
      [Difficulty.Inhuman]: '160 RF / 10 toneladas',
      [Difficulty.Zen]: '200 RF / 100 toneladas',
    },
  },
  {
    id: '30',
    name: 'Crear fuego',
    discipline: PsychicDisciplineType.Pyrokinesis,
    level: 1,
    minimalDifficultyToSuccess: Difficulty.Easy,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 1',
      [Difficulty.Easy]: '1 intensidad',
      [Difficulty.Medium]: '3 intensidades',
      [Difficulty.Hard]: '5 intensidades',
      [Difficulty.VeryHard]: '7 intensidades',
      [Difficulty.Absurd]: '10 intensidades',
      [Difficulty.AlmostImpossible]: '13 intensidades',
      [Difficulty.Impossible]: '16 intensidades',
      [Difficulty.Inhuman]: '20 intensidades',
      [Difficulty.Zen]: '25 intensidades',
    },
  },
  {
    id: '31',
    name: 'Mitigar fuego',
    discipline: PsychicDisciplineType.Pyrokinesis,
    level: 1,
    minimalDifficultyToSuccess: Difficulty.Easy,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 1',
      [Difficulty.Easy]: '80 RF / -1 intensidad',
      [Difficulty.Medium]: '100 RF / -3 intensidades',
      [Difficulty.Hard]: '120 RF / -5 intensidades',
      [Difficulty.VeryHard]: '140 RF / -7 intensidades',
      [Difficulty.Absurd]: '160 RF / -10 intensidades',
      [Difficulty.AlmostImpossible]: '180 RF / -15 intensidades',
      [Difficulty.Impossible]: '200 RF / -20 intensidades',
      [Difficulty.Inhuman]: '220 RF / -30 intensidades',
      [Difficulty.Zen]: '260 RF / -40 intensidades',
    },
  },
  {
    id: '32',
    name: 'Controlar el fuego',
    discipline: PsychicDisciplineType.Pyrokinesis,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '80 RF / 4 intensidades',
      [Difficulty.Hard]: '100 RF / 6 intensidades',
      [Difficulty.VeryHard]: '120 RF / 8 intensidades',
      [Difficulty.Absurd]: '140 RF / 12 intensidades',
      [Difficulty.AlmostImpossible]: '160 RF / 16 intensidades',
      [Difficulty.Impossible]: '180 RF / 20 intensidades',
      [Difficulty.Inhuman]: '200 RF / 25 intensidades',
      [Difficulty.Zen]: '240 RF / 30 intensidades',
    },
  },
  {
    id: '33',
    name: 'Inmolar',
    discipline: PsychicDisciplineType.Pyrokinesis,
    level: 1,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: 'Daño 60 / 5 m de Radio',
      [Difficulty.VeryHard]: 'Daño 80 / 10 m de Radio',
      [Difficulty.Absurd]: 'Daño 100 / 20 m de Radio',
      [Difficulty.AlmostImpossible]: 'Daño 120 / 30 m de Radio',
      [Difficulty.Impossible]: 'Daño 150 / 50 m de Radio',
      [Difficulty.Inhuman]: 'Daño 200 / 100 m de Radio',
      [Difficulty.Zen]: 'Daño 250 / 200 m de Radio',
    },
  },
  {
    id: '34',
    name: 'Mantenimiento ígneo',
    discipline: PsychicDisciplineType.Pyrokinesis,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: '5 intensidades',
      [Difficulty.VeryHard]: '10 intensidades',
      [Difficulty.Absurd]: '15 intensidades',
      [Difficulty.AlmostImpossible]: '20 intensidades',
      [Difficulty.Impossible]: '30 intensidades',
      [Difficulty.Inhuman]: '40 intensidades',
      [Difficulty.Zen]: '50 intensidades',
    },
  },
  {
    id: '35',
    name: 'Inmunidad al fuego',
    discipline: PsychicDisciplineType.Pyrokinesis,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Passive,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: '5 intensidades',
      [Difficulty.VeryHard]: '10 intensidades',
      [Difficulty.Absurd]: '15 intensidades',
      [Difficulty.AlmostImpossible]: '20 intensidades',
      [Difficulty.Impossible]: '30 intensidades',
      [Difficulty.Inhuman]: '40 intensidades',
      [Difficulty.Zen]: '50 intensidades',
    },
  },
  {
    id: '36',
    name: 'Barrera ígnea',
    discipline: PsychicDisciplineType.Pyrokinesis,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: 'Fatiga 1',
      [Difficulty.VeryHard]: 'Daño 60 / 5 m de Radio',
      [Difficulty.Absurd]: 'Daño 80 / 10 m de Radio',
      [Difficulty.AlmostImpossible]: 'Daño 120 / 20 m de Radio',
      [Difficulty.Impossible]: 'Daño 160 / 30 m de Radio',
      [Difficulty.Inhuman]: 'Daño 200 / 40 m de Radio',
      [Difficulty.Zen]: 'Daño 240 / 50 m de Radio',
    },
  },
  {
    id: '37',
    name: 'Aumentar temperatura ambiente',
    discipline: PsychicDisciplineType.Pyrokinesis,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: 'Fatiga 1',
      [Difficulty.VeryHard]: '1 km de Radio / +5ºC',
      [Difficulty.Absurd]: '5 km de Radio / +10ºC',
      [Difficulty.AlmostImpossible]: '10 km de Radio / +15ºC',
      [Difficulty.Impossible]: '25 km de Radio / +20ºC',
      [Difficulty.Inhuman]: '50 km de Radio / +30ºC',
      [Difficulty.Zen]: '100 km de Radio / +40ºC',
    },
  },
  {
    id: '38',
    name: 'Consumir',
    discipline: PsychicDisciplineType.Pyrokinesis,
    level: 3,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: '120 RF / Daño automático 80',
      [Difficulty.AlmostImpossible]: '140 RF / Daño automático 120',
      [Difficulty.Impossible]: '160 RF / Daño automático 160',
      [Difficulty.Inhuman]: '180 RF / Daño automático 200',
      [Difficulty.Zen]: '220 RF / Daño automático 250',
    },
  },
  {
    id: '39',
    name: 'Nova',
    discipline: PsychicDisciplineType.Pyrokinesis,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: '10 Puntos de vida',
      [Difficulty.VeryHard]: '20 Puntos de vida',
      [Difficulty.Absurd]: '30 Puntos de vida',
      [Difficulty.AlmostImpossible]: '40 Puntos de vida',
      [Difficulty.Impossible]: '60 Puntos de vida',
      [Difficulty.Inhuman]: '80 Puntos de vida',
      [Difficulty.Zen]: '120 Puntos de vida',
    },
  },
  {
    id: '40',
    name: 'Fuego mayor',
    discipline: PsychicDisciplineType.Pyrokinesis,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.AlmostImpossible,
    casts: {
      [Difficulty.Routine]: 'Fatiga 20',
      [Difficulty.Easy]: 'Fatiga 16',
      [Difficulty.Medium]: 'Fatiga 12',
      [Difficulty.Hard]: 'Fatiga 8',
      [Difficulty.VeryHard]: 'Fatiga 6',
      [Difficulty.Absurd]: 'Fatiga 4',
      [Difficulty.AlmostImpossible]: '30 intensidades',
      [Difficulty.Impossible]: '40 intensidades',
      [Difficulty.Inhuman]: '50 intensidades',
      [Difficulty.Zen]: '60 intensidades',
    },
  },
  {
    id: '41',
    name: 'Crear frío',
    discipline: PsychicDisciplineType.Cryokinesis,
    level: 1,
    minimalDifficultyToSuccess: Difficulty.Easy,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 1',
      [Difficulty.Easy]: '1 intensidad',
      [Difficulty.Medium]: '3 intensidades',
      [Difficulty.Hard]: '5 intensidades',
      [Difficulty.VeryHard]: '7 intensidades',
      [Difficulty.Absurd]: '10 intensidades',
      [Difficulty.AlmostImpossible]: '13 intensidades',
      [Difficulty.Impossible]: '16 intensidades',
      [Difficulty.Inhuman]: '20 intensidades',
      [Difficulty.Zen]: '25 intensidades',
    },
  },
  {
    id: '42',
    name: 'Percibir temperatura',
    discipline: PsychicDisciplineType.Cryokinesis,
    level: 1,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: '10 m de radio',
      [Difficulty.VeryHard]: '50 m de radio',
      [Difficulty.Absurd]: '100 m de radio',
      [Difficulty.AlmostImpossible]: '500 m de radio',
      [Difficulty.Impossible]: '1 km de radio',
      [Difficulty.Inhuman]: '10 km de radio',
      [Difficulty.Zen]: '100 km de radio',
    },
  },
  {
    id: '43',
    name: 'Eliminar frío',
    discipline: PsychicDisciplineType.Cryokinesis,
    level: 1,
    minimalDifficultyToSuccess: Difficulty.Easy,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 1',
      [Difficulty.Easy]: '80 RF / -1 intensidad',
      [Difficulty.Medium]: '100 RF / -3 intensidades',
      [Difficulty.Hard]: '120 RF / -5 intensidades',
      [Difficulty.VeryHard]: '140 RF / -7 intensidades',
      [Difficulty.Absurd]: '160 RF / -10 intensidades',
      [Difficulty.AlmostImpossible]: '180 RF / -15 intensidades',
      [Difficulty.Impossible]: '200 RF / -20 intensidades',
      [Difficulty.Inhuman]: '220 RF / -30 intensidades',
      [Difficulty.Zen]: '260 RF / -40 intensidades',
    },
  },
  {
    id: '44',
    name: 'Control sobre el frío',
    discipline: PsychicDisciplineType.Cryokinesis,
    level: 1,
    hasMaintenance: false,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '80 RF / 4 intensidades',
      [Difficulty.Hard]: '100 RF / 6 intensidades',
      [Difficulty.VeryHard]: '120 RF / 8 intensidades',
      [Difficulty.Absurd]: '140 RF / 12 intensidades',
      [Difficulty.AlmostImpossible]: '160 RF / 16 intensidades',
      [Difficulty.Impossible]: '180 RF / 20 intensidades',
      [Difficulty.Inhuman]: '200 RF / 25 intensidades',
      [Difficulty.Zen]: '240 RF / 30 intensidades',
    },
  },
  {
    id: '45',
    name: 'Congelar',
    discipline: PsychicDisciplineType.Cryokinesis,
    level: 1,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 6',
      [Difficulty.Medium]: 'Fatiga 4',
      [Difficulty.Hard]: '80 RF',
      [Difficulty.VeryHard]: '100 RF',
      [Difficulty.Absurd]: '120 RF',
      [Difficulty.AlmostImpossible]: '140 RF',
      [Difficulty.Impossible]: '160 RF',
      [Difficulty.Inhuman]: '180 RF',
      [Difficulty.Zen]: '220 RF',
    },
  },
  {
    id: '46',
    name: 'Esquirlas de hielo',
    discipline: PsychicDisciplineType.Cryokinesis,
    level: 2,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 6',
      [Difficulty.Medium]: 'Fatiga 4',
      [Difficulty.Hard]: 'Fatiga 2',
      [Difficulty.VeryHard]: 'Fatiga 1',
      [Difficulty.Absurd]: 'Daño 80',
      [Difficulty.AlmostImpossible]: 'Daño 100',
      [Difficulty.Impossible]: 'Daño 120',
      [Difficulty.Inhuman]: 'Daño 160 / área 5 m',
      [Difficulty.Zen]: 'Daño 200 / área 25 m',
    },
  },
  {
    id: '47',
    name: 'Disminuir temperatura ambiente',
    discipline: PsychicDisciplineType.Cryokinesis,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: 'Fatiga 1',
      [Difficulty.VeryHard]: '1 km de Radio / -5ºC',
      [Difficulty.Absurd]: '5 km de Radio / -10ºC',
      [Difficulty.AlmostImpossible]: '10 km de Radio / -15ºC',
      [Difficulty.Impossible]: '25 km de Radio / -20ºC',
      [Difficulty.Inhuman]: '50 km de Radio / -30ºC',
      [Difficulty.Zen]: '100 km de Radio / -40ºC',
    },
  },
  {
    id: '48',
    name: 'Escudo de hielo',
    discipline: PsychicDisciplineType.Cryokinesis,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Passive,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: '600 PVs',
      [Difficulty.VeryHard]: '800 PVs',
      [Difficulty.Absurd]: '1200 PVs',
      [Difficulty.AlmostImpossible]: '1800 PVs',
      [Difficulty.Impossible]: '2500 PVs',
      [Difficulty.Inhuman]: '4000 PVs',
      [Difficulty.Zen]: '6000 PVs',
    },
  },
  {
    id: '49',
    name: 'Cristalizar',
    discipline: PsychicDisciplineType.Cryokinesis,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 12',
      [Difficulty.Easy]: 'Fatiga 8',
      [Difficulty.Medium]: 'Fatiga 6',
      [Difficulty.Hard]: 'Fatiga 4',
      [Difficulty.VeryHard]: 'Fatiga 2',
      [Difficulty.Absurd]: '120 RF',
      [Difficulty.AlmostImpossible]: '140 RF',
      [Difficulty.Impossible]: '160 RF',
      [Difficulty.Inhuman]: '180 RF',
      [Difficulty.Zen]: '220 RF',
    },
  },
  {
    id: '50',
    name: 'Inmunidad al frío',
    discipline: PsychicDisciplineType.Cryokinesis,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Passive,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: '5 intensidades',
      [Difficulty.VeryHard]: '10 intensidades',
      [Difficulty.Absurd]: '15 intensidades',
      [Difficulty.AlmostImpossible]: '20 intensidades',
      [Difficulty.Impossible]: '30 intensidades',
      [Difficulty.Inhuman]: '40 intensidades',
      [Difficulty.Zen]: '50 intensidades',
    },
  },
  {
    id: '51',
    name: 'Cero absoluto',
    discipline: PsychicDisciplineType.Cryokinesis,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: '5 m de radio',
      [Difficulty.AlmostImpossible]: '10 m de radio',
      [Difficulty.Impossible]: '20 m de radio',
      [Difficulty.Inhuman]: '50 m de radio',
      [Difficulty.Zen]: '100 m de radio',
    },
  },
  {
    id: '52',
    name: 'Un instante eterno',
    discipline: PsychicDisciplineType.Cryokinesis,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: '120 RF / 5 m de radio',
      [Difficulty.AlmostImpossible]: '140 RF / 10 m de radio',
      [Difficulty.Impossible]: '160 RF / 20 m de radio',
      [Difficulty.Inhuman]: '180 RF / 50 m de radio',
      [Difficulty.Zen]: '200 RF / 100 m de radio',
    },
  },
  {
    id: '53',
    name: 'Frío mayor',
    discipline: PsychicDisciplineType.Cryokinesis,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.AlmostImpossible,
    casts: {
      [Difficulty.Routine]: 'Fatiga 20',
      [Difficulty.Easy]: 'Fatiga 16',
      [Difficulty.Medium]: 'Fatiga 12',
      [Difficulty.Hard]: 'Fatiga 8',
      [Difficulty.VeryHard]: 'Fatiga 6',
      [Difficulty.Absurd]: 'Fatiga 4',
      [Difficulty.AlmostImpossible]: '30 intensidades',
      [Difficulty.Impossible]: '40 intensidades',
      [Difficulty.Inhuman]: '50 intensidades',
      [Difficulty.Zen]: '60 intensidades',
    },
  },
  {
    id: '54',
    name: 'Incrementar capacidad de salto',
    discipline: PsychicDisciplineType.PhysicalIncrease,
    level: 1,
    minimalDifficultyToSuccess: Difficulty.Easy,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Saltar +10',
      [Difficulty.Medium]: 'Saltar +20',
      [Difficulty.Hard]: 'Saltar +40',
      [Difficulty.VeryHard]: 'Saltar +80',
      [Difficulty.Absurd]: 'Saltar +120 / Inhumanidad',
      [Difficulty.AlmostImpossible]: 'Saltar +180 / Inhumanidad',
      [Difficulty.Impossible]: 'Saltar +220 / Inhumanidad',
      [Difficulty.Inhuman]: 'Saltar +280 / Zen',
      [Difficulty.Zen]: 'Saltar +320 / Zen',
    },
  },
  {
    id: '55',
    name: 'Incrementar sentido acrobático',
    discipline: PsychicDisciplineType.PhysicalIncrease,
    level: 1,
    minimalDifficultyToSuccess: Difficulty.Easy,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Acrobacias +10',
      [Difficulty.Medium]: 'Acrobacias +20',
      [Difficulty.Hard]: 'Acrobacias +40',
      [Difficulty.VeryHard]: 'Acrobacias +80',
      [Difficulty.Absurd]: 'Acrobacias +120 / Inhumanidad',
      [Difficulty.AlmostImpossible]: 'Acrobacias +180 / Inhumanidad',
      [Difficulty.Impossible]: 'Acrobacias +220 / Inhumanidad',
      [Difficulty.Inhuman]: 'Acrobacias +280 / Zen',
      [Difficulty.Zen]: 'Acrobacias +320 / Zen',
    },
  },
  {
    id: '56',
    name: 'Incrementar fuerza',
    discipline: PsychicDisciplineType.PhysicalIncrease,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fuerza +1',
      [Difficulty.Hard]: 'Fuerza +2',
      [Difficulty.VeryHard]: 'Fuerza +3',
      [Difficulty.Absurd]: 'Fuerza +4',
      [Difficulty.AlmostImpossible]: 'Fuerza +5',
      [Difficulty.Impossible]: 'Fuerza +6',
      [Difficulty.Inhuman]: 'Fuerza +8',
      [Difficulty.Zen]: 'Fuerza +10',
    },
  },
  {
    id: '57',
    name: 'Inhumanidad',
    discipline: PsychicDisciplineType.PhysicalIncrease,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Inhumanidad',
      [Difficulty.Hard]: 'Inhumanidad / +05 Hab Atléticas',
      [Difficulty.VeryHard]: 'Inhumanidad / +10 Hab Atléticas',
      [Difficulty.Absurd]: 'Inhumanidad / +20 Hab Atléticas',
      [Difficulty.AlmostImpossible]: 'Inhumanidad / +30 Hab Atléticas',
      [Difficulty.Impossible]: 'Zen / +40 Hab Atléticas',
      [Difficulty.Inhuman]: 'Zen / +60 Hab Atléticas',
      [Difficulty.Zen]: 'Zen / +80 Hab Atléticas',
    },
  },
  {
    id: '58',
    name: 'Incrementar desplazamiento',
    discipline: PsychicDisciplineType.PhysicalIncrease,
    level: 1,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: 'Tipo de movimiento +1',
      [Difficulty.VeryHard]: 'Tipo de movimiento +2',
      [Difficulty.Absurd]: 'Tipo de movimiento +3',
      [Difficulty.AlmostImpossible]: 'Tipo de movimiento +4',
      [Difficulty.Impossible]: 'Tipo de movimiento +5',
      [Difficulty.Inhuman]: 'Tipo de movimiento +6',
      [Difficulty.Zen]: 'Tipo de movimiento +8',
    },
  },
  {
    id: '59',
    name: 'Incrementar habilidad',
    discipline: PsychicDisciplineType.PhysicalIncrease,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Destreza o Agilidad +1',
      [Difficulty.Hard]: 'Destreza o Agilidad +2',
      [Difficulty.VeryHard]: 'Destreza o Agilidad +3',
      [Difficulty.Absurd]: 'Destreza o Agilidad +4',
      [Difficulty.AlmostImpossible]: 'Destreza o Agilidad +5',
      [Difficulty.Impossible]: 'Destreza o Agilidad +6',
      [Difficulty.Inhuman]: 'Destreza o Agilidad +8',
      [Difficulty.Zen]: 'Destreza o Agilidad +10',
    },
  },
  {
    id: '60',
    name: 'Incremento de reacción',
    discipline: PsychicDisciplineType.PhysicalIncrease,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: 'Turno +20',
      [Difficulty.VeryHard]: 'Turno +40',
      [Difficulty.Absurd]: 'Turno +60',
      [Difficulty.AlmostImpossible]: 'Turno +80',
      [Difficulty.Impossible]: 'Turno +120',
      [Difficulty.Inhuman]: 'Turno +160',
      [Difficulty.Zen]: 'Turno +200',
    },
  },
  {
    id: '61',
    name: 'Incremento de percepción',
    discipline: PsychicDisciplineType.PhysicalIncrease,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: 'Percepción +1',
      [Difficulty.VeryHard]: 'Percepción +2',
      [Difficulty.Absurd]: 'Percepción +3',
      [Difficulty.AlmostImpossible]: 'Percepción +4',
      [Difficulty.Impossible]: 'Percepción +5',
      [Difficulty.Inhuman]: 'Percepción +6',
      [Difficulty.Zen]: 'Percepción +8',
    },
  },
  {
    id: '62',
    name: 'Incrementar aguante',
    discipline: PsychicDisciplineType.PhysicalIncrease,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Passive,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: 'RF +10',
      [Difficulty.VeryHard]: 'RF +20',
      [Difficulty.Absurd]: 'RF +40',
      [Difficulty.AlmostImpossible]: 'RF +80',
      [Difficulty.Impossible]: 'RF +120',
      [Difficulty.Inhuman]: 'RF +160',
      [Difficulty.Zen]: 'RF +200',
    },
  },
  {
    id: '63',
    name: 'Regeneración',
    discipline: PsychicDisciplineType.PhysicalIncrease,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 6',
      [Difficulty.Medium]: 'Fatiga 4',
      [Difficulty.Hard]: 'Regeneración +1',
      [Difficulty.VeryHard]: 'Regeneración +2',
      [Difficulty.Absurd]: 'Regeneración +4',
      [Difficulty.AlmostImpossible]: 'Regeneración +6',
      [Difficulty.Impossible]: 'Regeneración +8',
      [Difficulty.Inhuman]: 'Regeneración +10',
      [Difficulty.Zen]: 'Regeneración +12',
    },
  },
  {
    id: '64',
    name: 'Eliminación de cansancio',
    discipline: PsychicDisciplineType.PhysicalIncrease,
    level: 3,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: 'Recupera 2 puntos',
      [Difficulty.AlmostImpossible]: 'Recupera 4 puntos',
      [Difficulty.Impossible]: 'Recupera 6 puntos',
      [Difficulty.Inhuman]: 'Recupera 10 puntos',
      [Difficulty.Zen]: 'Recuperación completa',
    },
  },
  {
    id: '65',
    name: 'Incremento total',
    discipline: PsychicDisciplineType.PhysicalIncrease,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: 'Características físicas +1',
      [Difficulty.AlmostImpossible]: 'Características físicas +2',
      [Difficulty.Impossible]: 'Características físicas +4',
      [Difficulty.Inhuman]: 'Características físicas +6',
      [Difficulty.Zen]: 'Características físicas +8',
    },
  },
  {
    id: '66',
    name: 'Imbuir',
    discipline: PsychicDisciplineType.PhysicalIncrease,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: 'Poderes de nivel Muy Difícil',
      [Difficulty.AlmostImpossible]: 'Poderes de nivel Absurdo',
      [Difficulty.Impossible]: 'Poderes de nivel Casi Imposible',
      [Difficulty.Inhuman]: 'Poderes de nivel Imposible',
      [Difficulty.Zen]: 'Poderes de nivel Inhumano',
    },
  },
  {
    id: '67',
    name: 'Percibir energía',
    discipline: PsychicDisciplineType.Energy,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '10 m de radio',
      [Difficulty.Hard]: '50 m de radio',
      [Difficulty.VeryHard]: '100 m de radio',
      [Difficulty.Absurd]: '250 m de radio',
      [Difficulty.AlmostImpossible]: '500 m de radio',
      [Difficulty.Impossible]: '1 km de radio',
      [Difficulty.Inhuman]: '10 km de radio',
      [Difficulty.Zen]: '100 km de radio',
    },
  },
  {
    id: '68',
    name: 'Crear energía',
    discipline: PsychicDisciplineType.Energy,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '1 intensidad',
      [Difficulty.Hard]: '3 intensidad',
      [Difficulty.VeryHard]: '5 intensidad',
      [Difficulty.Absurd]: '7 intensidad',
      [Difficulty.AlmostImpossible]: '10 intensidad',
      [Difficulty.Impossible]: '13 intensidad',
      [Difficulty.Inhuman]: '16 intensidad',
      [Difficulty.Zen]: '20 intensidad',
    },
  },
  {
    id: '69',
    name: 'Escudo de energía',
    discipline: PsychicDisciplineType.Energy,
    level: 1,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Passive,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: '300 PVs',
      [Difficulty.VeryHard]: '500 PVs',
      [Difficulty.Absurd]: '800 PVs',
      [Difficulty.AlmostImpossible]: '1000 PVs',
      [Difficulty.Impossible]: '1400 PVs',
      [Difficulty.Inhuman]: '2000 PVs',
      [Difficulty.Zen]: '3000 PVs',
    },
  },
  {
    id: '70',
    name: 'Creación de energía',
    discipline: PsychicDisciplineType.Energy,
    level: 1,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: '1 m cúbico',
      [Difficulty.VeryHard]: '2 m cúbicos',
      [Difficulty.Absurd]: '3 m cúbicos',
      [Difficulty.AlmostImpossible]: '4 m cúbicos',
      [Difficulty.Impossible]: '5 m cúbicos',
      [Difficulty.Inhuman]: '10 m cúbicos',
      [Difficulty.Zen]: '20 m cúbicos',
    },
  },
  {
    id: '71',
    name: 'Descarga de energía',
    discipline: PsychicDisciplineType.Energy,
    level: 1,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: 'Daño 50',
      [Difficulty.VeryHard]: 'Daño 70',
      [Difficulty.Absurd]: 'Daño 100',
      [Difficulty.AlmostImpossible]: 'Daño 120',
      [Difficulty.Impossible]: 'Daño 140 Daña energía',
      [Difficulty.Inhuman]: 'Daño 180 Daña energía',
      [Difficulty.Zen]: 'Daño 220 Daña energía',
    },
  },
  {
    id: '72',
    name: 'Deshacer energía',
    discipline: PsychicDisciplineType.Energy,
    level: 2,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: '100 RF / -1 intensidad',
      [Difficulty.VeryHard]: '120 RF / -3 intensidades',
      [Difficulty.Absurd]: '140 RF / -5 intensidades',
      [Difficulty.AlmostImpossible]: '160 RF / -8 intensidades',
      [Difficulty.Impossible]: '180 RF / -12 intensidades',
      [Difficulty.Inhuman]: '220 RF / -18 intensidades',
      [Difficulty.Zen]: '240 RF / -24 intensidades',
    },
  },
  {
    id: '73',
    name: 'Inmunidad',
    discipline: PsychicDisciplineType.Energy,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Passive,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 12',
      [Difficulty.Easy]: 'Fatiga 8',
      [Difficulty.Medium]: 'Fatiga 6',
      [Difficulty.Hard]: 'Fatiga 4',
      [Difficulty.VeryHard]: 'Fatiga 2',
      [Difficulty.Absurd]: '10 intensidades',
      [Difficulty.AlmostImpossible]: '15 intensidades',
      [Difficulty.Impossible]: '20 intensidades',
      [Difficulty.Inhuman]: '30 intensidades',
      [Difficulty.Zen]: '40 intensidades',
    },
  },
  {
    id: '74',
    name: 'Controlar energía',
    discipline: PsychicDisciplineType.Energy,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: '4 intensidades / 80 RF',
      [Difficulty.VeryHard]: '6 intensidades / 100 RF',
      [Difficulty.Absurd]: '8 intensidades / 120 RF',
      [Difficulty.AlmostImpossible]: '12 intensidades / 140 RF',
      [Difficulty.Impossible]: '16 intensidades / 160 RF',
      [Difficulty.Inhuman]: '20 intensidades / 180 RF',
      [Difficulty.Zen]: '25 intensidades / 220 RF',
    },
  },
  {
    id: '75',
    name: 'Modificar naturaleza',
    discipline: PsychicDisciplineType.Energy,
    level: 2,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 6',
      [Difficulty.Medium]: 'Fatiga 4',
      [Difficulty.Hard]: 'Fatiga 2',
      [Difficulty.VeryHard]: '6 intensidades / 100 RF',
      [Difficulty.Absurd]: '8 intensidades / 120 RF',
      [Difficulty.AlmostImpossible]: '12 intensidades / 140 RF',
      [Difficulty.Impossible]: '16 intensidades / 160 RF',
      [Difficulty.Inhuman]: '20 intensidades / 180 RF',
      [Difficulty.Zen]: '25 intensidades / 220 RF',
    },
  },
  {
    id: '76',
    name: 'Cúpula de energía ',
    discipline: PsychicDisciplineType.Energy,
    level: 3,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: 'Daño 100 / 25 m de Radio',
      [Difficulty.AlmostImpossible]: 'Daño 120 / 50 m de Radio',
      [Difficulty.Impossible]: 'Daño 140 / 100 m de Radio',
      [Difficulty.Inhuman]: 'Daño 160 / 200 m daña energía',
      [Difficulty.Zen]: 'Daño 200 / 500 m daña energía',
    },
  },
  {
    id: '77',
    name: 'Energía mayor',
    discipline: PsychicDisciplineType.Energy,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.AlmostImpossible,
    casts: {
      [Difficulty.Routine]: 'Fatiga 20',
      [Difficulty.Easy]: 'Fatiga 16',
      [Difficulty.Medium]: 'Fatiga 12',
      [Difficulty.Hard]: 'Fatiga 8',
      [Difficulty.VeryHard]: 'Fatiga 6',
      [Difficulty.Absurd]: 'Fatiga 4',
      [Difficulty.AlmostImpossible]: '25 intensidades',
      [Difficulty.Impossible]: '35 intensidades',
      [Difficulty.Inhuman]: '45 intensidades',
      [Difficulty.Zen]: '55 intensidades',
    },
  },
  {
    id: '78',
    name: 'Percibir sentimientos',
    discipline: PsychicDisciplineType.Sentient,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '100 RP',
      [Difficulty.Hard]: '120 RP',
      [Difficulty.VeryHard]: '140 RP',
      [Difficulty.Absurd]: '160 RP',
      [Difficulty.AlmostImpossible]: '180 RP',
      [Difficulty.Impossible]: '200 RP',
      [Difficulty.Inhuman]: '220 RP',
      [Difficulty.Zen]: '240 RP',
    },
  },
  {
    id: '79',
    name: 'Intensificar sentimientos',
    discipline: PsychicDisciplineType.Sentient,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '100 RP',
      [Difficulty.Hard]: '120 RP',
      [Difficulty.VeryHard]: '140 RP',
      [Difficulty.Absurd]: '160 RP',
      [Difficulty.AlmostImpossible]: '180 RP',
      [Difficulty.Impossible]: '200 RP',
      [Difficulty.Inhuman]: '220 RP',
      [Difficulty.Zen]: '240 RP',
    },
  },
  {
    id: '80',
    name: 'Detectar sentimientos',
    discipline: PsychicDisciplineType.Sentient,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '80 RP / 10 m de radio',
      [Difficulty.Hard]: '100 RP / 50 m de radio',
      [Difficulty.VeryHard]: '120 RP / 100 m de radio',
      [Difficulty.Absurd]: '140 RP / 250 m de radio',
      [Difficulty.AlmostImpossible]: '160 RP / 500 m de radio',
      [Difficulty.Impossible]: '180 RP / 1 km de radio',
      [Difficulty.Inhuman]: '200 RP / 10 km de radio',
      [Difficulty.Zen]: '220 RP / 100 km de radio',
    },
  },
  {
    id: '81',
    name: 'Conectar sentidos',
    discipline: PsychicDisciplineType.Sentient,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '60 RP / 10 m de radio',
      [Difficulty.Hard]: '80 RP / 100 m',
      [Difficulty.VeryHard]: '100 RP / 500 m',
      [Difficulty.Absurd]: '120 RP / 1000 m',
      [Difficulty.AlmostImpossible]: '140 RP / 10 km',
      [Difficulty.Impossible]: '160 RP / 100 km',
      [Difficulty.Inhuman]: '180 RP / 1000 km',
      [Difficulty.Zen]: '200 RP / Cualquier distancia',
    },
  },
  {
    id: '82',
    name: 'Crear sentimientos',
    discipline: PsychicDisciplineType.Sentient,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: '80 RP',
      [Difficulty.VeryHard]: '100 RP',
      [Difficulty.Absurd]: '120 RP',
      [Difficulty.AlmostImpossible]: '140 RP',
      [Difficulty.Impossible]: '160 RP',
      [Difficulty.Inhuman]: '180 RP',
      [Difficulty.Zen]: '200 RP',
    },
  },
  {
    id: '83',
    name: 'Cargar con sentimientos',
    discipline: PsychicDisciplineType.Sentient,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 6',
      [Difficulty.Medium]: 'Fatiga 4',
      [Difficulty.Hard]: 'Fatiga 2',
      [Difficulty.VeryHard]: '100 RP / 5 m de radio',
      [Difficulty.Absurd]: '120 RP / 10 m de radio',
      [Difficulty.AlmostImpossible]: '140 RP / 25 m de radio',
      [Difficulty.Impossible]: '160 RP / 50 m de radio',
      [Difficulty.Inhuman]: '180 RP / 100 m de radio',
      [Difficulty.Zen]: '220 RP / 500 m de radio',
    },
  },
  {
    id: '84',
    name: 'Eliminar sentidos',
    discipline: PsychicDisciplineType.Sentient,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 6',
      [Difficulty.Medium]: 'Fatiga 4',
      [Difficulty.Hard]: 'Fatiga 2',
      [Difficulty.VeryHard]: '100 RP',
      [Difficulty.Absurd]: '120 RP',
      [Difficulty.AlmostImpossible]: '140 RP',
      [Difficulty.Impossible]: '160 RP',
      [Difficulty.Inhuman]: '180 RP',
      [Difficulty.Zen]: '220 RP',
    },
  },
  {
    id: '85',
    name: 'Trasladar los sentidos',
    discipline: PsychicDisciplineType.Sentient,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 6',
      [Difficulty.Medium]: 'Fatiga 4',
      [Difficulty.Hard]: 'Fatiga 2',
      [Difficulty.VeryHard]: '1 km',
      [Difficulty.Absurd]: '10 km',
      [Difficulty.AlmostImpossible]: '50 km',
      [Difficulty.Impossible]: '100 km',
      [Difficulty.Inhuman]: '1000 km',
      [Difficulty.Zen]: 'Cualquier distancia',
    },
  },
  {
    id: '86',
    name: 'Destruir sentimientos',
    discipline: PsychicDisciplineType.Sentient,
    level: 3,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: '120 RP',
      [Difficulty.AlmostImpossible]: '140 RP',
      [Difficulty.Impossible]: '160 RP',
      [Difficulty.Inhuman]: '180 RP',
      [Difficulty.Zen]: '200 RP',
    },
  },
  {
    id: '87',
    name: 'Área (Sintiente)',
    discipline: PsychicDisciplineType.Sentient,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 4',
      [Difficulty.VeryHard]: '10 m',
      [Difficulty.Absurd]: '100 m',
      [Difficulty.AlmostImpossible]: '1000 m',
      [Difficulty.Impossible]: '10 km',
      [Difficulty.Inhuman]: '100 km',
      [Difficulty.Zen]: '500 km',
    },
  },
  {
    id: '88',
    name: 'Percibir residuos',
    discipline: PsychicDisciplineType.Telemetry,
    level: 1,
    minimalDifficultyToSuccess: Difficulty.Easy,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 1',
      [Difficulty.Easy]: 'Una hora',
      [Difficulty.Medium]: 'Seis horas',
      [Difficulty.Hard]: 'Un día',
      [Difficulty.VeryHard]: 'Tres días',
      [Difficulty.Absurd]: 'Una semana',
      [Difficulty.AlmostImpossible]: 'Un mes',
      [Difficulty.Impossible]: 'Un año',
      [Difficulty.Inhuman]: 'Una década',
      [Difficulty.Zen]: 'Un siglo',
    },
  },
  {
    id: '89',
    name: 'Leer el pasado',
    discipline: PsychicDisciplineType.Telemetry,
    level: 2,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 6',
      [Difficulty.Medium]: 'Fatiga 4',
      [Difficulty.Hard]: 'Fatiga 2',
      [Difficulty.VeryHard]: 'Una hora',
      [Difficulty.Absurd]: 'Seis horas',
      [Difficulty.AlmostImpossible]: 'Un día',
      [Difficulty.Impossible]: 'Una semana',
      [Difficulty.Inhuman]: 'Un mes',
      [Difficulty.Zen]: 'Un año',
    },
  },
  {
    id: '90',
    name: 'Erudición humana',
    discipline: PsychicDisciplineType.Telemetry,
    level: 2,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: 'Un día / 80 RP',
      [Difficulty.VeryHard]: 'Una semana / 100 RP',
      [Difficulty.Absurd]: 'Un mes / 120 RP',
      [Difficulty.AlmostImpossible]: 'Un año / 140 RP',
      [Difficulty.Impossible]: 'Una década / 160 RP',
      [Difficulty.Inhuman]: 'Cincuenta años / 180 RP',
      [Difficulty.Zen]: 'Toda su vida / 200 RP',
    },
  },
  {
    id: '91',
    name: 'Ver en la historia',
    discipline: PsychicDisciplineType.Telemetry,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: 'Un año',
      [Difficulty.AlmostImpossible]: 'Diez años',
      [Difficulty.Impossible]: 'Un siglo',
      [Difficulty.Inhuman]: 'Un mileno',
      [Difficulty.Zen]: 'Cualquier periodo',
    },
  },
  {
    id: '92',
    name: 'Crear caos',
    discipline: PsychicDisciplineType.Causality,
    level: 1,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 12',
      [Difficulty.Easy]: 'Fatiga 8',
      [Difficulty.Medium]: 'Fatiga 6',
      [Difficulty.Hard]: 'Fatiga 4',
      [Difficulty.VeryHard]: 'Fatiga 2',
      [Difficulty.Absurd]: '50 m',
      [Difficulty.AlmostImpossible]: '500 m',
      [Difficulty.Impossible]: '1 km',
      [Difficulty.Inhuman]: '2 km',
      [Difficulty.Zen]: '5 km',
    },
  },
  {
    id: '93',
    name: 'Eliminar ley de la causalidad',
    discipline: PsychicDisciplineType.Causality,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 12',
      [Difficulty.Easy]: 'Fatiga 8',
      [Difficulty.Medium]: 'Fatiga 6',
      [Difficulty.Hard]: 'Fatiga 4',
      [Difficulty.VeryHard]: 'Fatiga 2',
      [Difficulty.Absurd]: '3 chequeos / 30 habilidades',
      [Difficulty.AlmostImpossible]: '4 chequeos / 40 habilidades',
      [Difficulty.Impossible]: '5 chequeos / 50 habilidades',
      [Difficulty.Inhuman]: '6 chequeos / 60 habilidades',
      [Difficulty.Zen]: '8 chequeos / 80 habilidades',
    },
  },
  {
    id: '94',
    name: 'Alterar el clima',
    discipline: PsychicDisciplineType.Causality,
    level: 2,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: 'Fatiga 1',
      [Difficulty.VeryHard]: 'Cambio menor posible / 1 km',
      [Difficulty.Absurd]: 'Cambio menor raro / 2 km',
      [Difficulty.AlmostImpossible]: 'Cambio intermedio / 5 km',
      [Difficulty.Impossible]: 'Cambio mayor / 10 km',
      [Difficulty.Inhuman]: 'Cambio radical / 15 km',
      [Difficulty.Zen]: 'Alteración completa / 20 km',
    },
  },
  {
    id: '95',
    name: 'Crear orden',
    discipline: PsychicDisciplineType.Causality,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 12',
      [Difficulty.Easy]: 'Fatiga 8',
      [Difficulty.Medium]: 'Fatiga 6',
      [Difficulty.Hard]: 'Fatiga 4',
      [Difficulty.VeryHard]: 'Fatiga 2',
      [Difficulty.Absurd]: '50 m',
      [Difficulty.AlmostImpossible]: '500 m',
      [Difficulty.Impossible]: ' -10 ACT / -20 Conv. / 1 km',
      [Difficulty.Inhuman]: ' -20 ACT / -40 Conv. / 2 km',
      [Difficulty.Zen]: ' -40 ACT / -80 Conv. / 5 km',
    },
  },
  {
    id: '96',
    name: 'Controlar la causalidad',
    discipline: PsychicDisciplineType.Causality,
    level: 3,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.AlmostImpossible,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: 'Fatiga 2',
      [Difficulty.AlmostImpossible]: 'Sucesos menores posibles',
      [Difficulty.Impossible]: 'Sucesos medios no habituales',
      [Difficulty.Inhuman]: 'Sucesos mayores extraños',
      [Difficulty.Zen]: 'Sucesos increíbles',
    },
  },
  {
    id: '97',
    name: 'Percibir electricidad',
    discipline: PsychicDisciplineType.Electromagnetism,
    level: 1,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: '10 m de radio',
      [Difficulty.VeryHard]: '25 m de radio',
      [Difficulty.Absurd]: '50 m de radio',
      [Difficulty.AlmostImpossible]: '100 m / RF 120',
      [Difficulty.Impossible]: '500 m / RF 140',
      [Difficulty.Inhuman]: '1 km / RF 160',
      [Difficulty.Zen]: '5 km / RF 180',
    },
  },
  {
    id: '98',
    name: 'Crear electricidad',
    discipline: PsychicDisciplineType.Electromagnetism,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '1 intensidad',
      [Difficulty.Hard]: '3 intensidades',
      [Difficulty.VeryHard]: '5 intensidades',
      [Difficulty.Absurd]: '7 intensidades',
      [Difficulty.AlmostImpossible]: '10 intensidades',
      [Difficulty.Impossible]: '13 intensidades',
      [Difficulty.Inhuman]: '16 intensidades',
      [Difficulty.Zen]: '20 intensidades',
    },
  },
  {
    id: '99',
    name: 'Controlar electricidad',
    discipline: PsychicDisciplineType.Electromagnetism,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '4 intensidades / RF 80',
      [Difficulty.Hard]: '6 intensidades / RF 100',
      [Difficulty.VeryHard]: '8 intensidades / RF 120',
      [Difficulty.Absurd]: '12 intensidades / RF 140',
      [Difficulty.AlmostImpossible]: '16 intensidades / RF 160',
      [Difficulty.Impossible]: '20 intensidades / RF 180',
      [Difficulty.Inhuman]: '25 intensidades / RF 200',
      [Difficulty.Zen]: '30 intensidades / RF 240',
    },
  },
  {
    id: '100',
    name: 'Manipulación magnética',
    discipline: PsychicDisciplineType.Electromagnetism,
    level: 1,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: '1 kg',
      [Difficulty.VeryHard]: '5 kg',
      [Difficulty.Absurd]: '10 kg',
      [Difficulty.AlmostImpossible]: '25 kg',
      [Difficulty.Impossible]: '50 kg',
      [Difficulty.Inhuman]: '100 kg',
      [Difficulty.Zen]: '500 kg',
    },
  },
  {
    id: '101',
    name: 'Escudo magnético',
    discipline: PsychicDisciplineType.Electromagnetism,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Passive,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: '600 PVs',
      [Difficulty.VeryHard]: '800 PVs',
      [Difficulty.Absurd]: '1200 PVs',
      [Difficulty.AlmostImpossible]: '1800 PVs / Todo at. Físico',
      [Difficulty.Impossible]: '2500 PVs / Todo at. Físico',
      [Difficulty.Inhuman]: '4000 PVs / Todo at. Físico',
      [Difficulty.Zen]: '6000 PVs / Todo at. Físico',
    },
  },
  {
    id: '102',
    name: 'Leer impulsos eléctricos',
    discipline: PsychicDisciplineType.Electromagnetism,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: 'RF 100 / 10 m radio',
      [Difficulty.VeryHard]: 'RF 120 / 25 m radio',
      [Difficulty.Absurd]: 'RF 140 / 50 m radio',
      [Difficulty.AlmostImpossible]: 'RF 160 / 100 m radio',
      [Difficulty.Impossible]: 'RF 180 / 150 m radio',
      [Difficulty.Inhuman]: 'RF 200 / 250 m radio',
      [Difficulty.Zen]: 'RF 220 / 1 km radio',
    },
  },
  {
    id: '103',
    name: 'Arco eléctrico',
    discipline: PsychicDisciplineType.Electromagnetism,
    level: 2,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: 'Daño 60',
      [Difficulty.VeryHard]: 'Daño 80',
      [Difficulty.Absurd]: 'Daño 120',
      [Difficulty.AlmostImpossible]: 'Daño 140',
      [Difficulty.Impossible]: 'Daño 160',
      [Difficulty.Inhuman]: 'Daño 180',
      [Difficulty.Zen]: 'Daño 200',
    },
  },
  {
    id: '104',
    name: 'Ataque de aceleración magnética',
    discipline: PsychicDisciplineType.Electromagnetism,
    level: 3,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.AlmostImpossible,
    casts: {
      [Difficulty.Routine]: 'Fatiga 12',
      [Difficulty.Easy]: 'Fatiga 10',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: 'Fatiga 2',
      [Difficulty.AlmostImpossible]: '+20 Daño / R 14 / 50m / 500gr',
      [Difficulty.Impossible]: '+40 Daño / R 18 / 100m / 1kg',
      [Difficulty.Inhuman]: '+80 Daño / R 26 / 150m / 10kg',
      [Difficulty.Zen]: '+120 Daño / R 30 / 300m / 100kg',
    },
  },
  {
    id: '105',
    name: 'Controlar impulsos eléctricos',
    discipline: PsychicDisciplineType.Electromagnetism,
    level: 3,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.AlmostImpossible,
    casts: {
      [Difficulty.Routine]: 'Fatiga 12',
      [Difficulty.Easy]: 'Fatiga 10',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: 'Fatiga 2',
      [Difficulty.AlmostImpossible]: '-40 acción / RF 120',
      [Difficulty.Impossible]: '-80 acción / RF 140',
      [Difficulty.Inhuman]: 'Control parcial / RF 160',
      [Difficulty.Zen]: 'Control total / RF 140',
    },
  },
  {
    id: '106',
    name: 'Recolocar objeto',
    discipline: PsychicDisciplineType.Teleportation,
    level: 1,
    hasMaintenance: false,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '1 kg / 1 m / RF 60',
      [Difficulty.Hard]: '2 kg / 5 m / RF 80',
      [Difficulty.VeryHard]: '5 kg / 100 m / RF 100',
      [Difficulty.Absurd]: '10 kg / 500 m / RF 120',
      [Difficulty.AlmostImpossible]: '25 kg / 1 km / RF 140',
      [Difficulty.Impossible]: '50 kg / 2 km / RF 160',
      [Difficulty.Inhuman]: '100 kg / 5 km / RF 180',
      [Difficulty.Zen]: '500 kg / 10 km / RF 200',
    },
  },
  {
    id: '107',
    name: 'Autorrecolocación',
    discipline: PsychicDisciplineType.Teleportation,
    level: 1,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: '1 m',
      [Difficulty.VeryHard]: '5 m',
      [Difficulty.Absurd]: '10 m',
      [Difficulty.AlmostImpossible]: '25 m',
      [Difficulty.Impossible]: '50 m',
      [Difficulty.Inhuman]: '100 m',
      [Difficulty.Zen]: '250 m',
    },
  },
  {
    id: '108',
    name: 'Transporte defensivo',
    discipline: PsychicDisciplineType.Teleportation,
    level: 1,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Passive,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: '1 Esquiva / 5 m',
      [Difficulty.VeryHard]: '2 Esquivas / 10 m',
      [Difficulty.Absurd]: '3 Esquivas / 15 m',
      [Difficulty.AlmostImpossible]: '4 Esquivas / 25 m',
      [Difficulty.Impossible]: '6 Esquivas / 50 m',
      [Difficulty.Inhuman]: '8 Esquivas / 100 m',
      [Difficulty.Zen]: 'Esquivas ilimitadas / Sin área',
    },
  },
  {
    id: '109',
    name: 'Autorrecolocación mayor',
    discipline: PsychicDisciplineType.Teleportation,
    level: 2,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 12',
      [Difficulty.Easy]: 'Fatiga 8',
      [Difficulty.Medium]: 'Fatiga 6',
      [Difficulty.Hard]: 'Fatiga 4',
      [Difficulty.VeryHard]: 'Fatiga 2',
      [Difficulty.Absurd]: '1 km',
      [Difficulty.AlmostImpossible]: '10 km',
      [Difficulty.Impossible]: '100 km',
      [Difficulty.Inhuman]: '1000 km',
      [Difficulty.Zen]: 'Cualquier distancia',
    },
  },
  {
    id: '110',
    name: 'Aleph',
    discipline: PsychicDisciplineType.Teleportation,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: '10 km / PER 20, RP 120',
      [Difficulty.AlmostImpossible]: '100 km / PER 18, RP 160',
      [Difficulty.Impossible]: '1000 km / PER 16, RP 180',
      [Difficulty.Inhuman]: '10000 km / PER 14, RP 200',
      [Difficulty.Zen]: 'Ilimitado / PER 12, RP 240',
    },
  },
  {
    id: '111',
    name: 'Recolocar objeto mayor',
    discipline: PsychicDisciplineType.Teleportation,
    level: 3,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.AlmostImpossible,
    casts: {
      [Difficulty.Routine]: 'Fatiga 24',
      [Difficulty.Easy]: 'Fatiga 20',
      [Difficulty.Medium]: 'Fatiga 16',
      [Difficulty.Hard]: 'Fatiga 12',
      [Difficulty.VeryHard]: 'Fatiga 8',
      [Difficulty.Absurd]: 'Fatiga 4',
      [Difficulty.AlmostImpossible]: '1 000 Tm / 1 km / RF 140',
      [Difficulty.Impossible]: '10 000 Tm / 10 km / RF 160',
      [Difficulty.Inhuman]: '100 000 Tm / 100 km / RF 180',
      [Difficulty.Zen]: '1 000 000 Tm / Ilimitado / RF 200',
    },
  },
  {
    id: '112',
    name: 'Teletransporte',
    discipline: PsychicDisciplineType.Teleportation,
    level: 3,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 12',
      [Difficulty.Easy]: 'Fatiga 8',
      [Difficulty.Medium]: 'Fatiga 6',
      [Difficulty.Hard]: 'Fatiga 4',
      [Difficulty.VeryHard]: 'Fatiga 2',
      [Difficulty.Absurd]: '1 km / RF 120 / 100 kg',
      [Difficulty.AlmostImpossible]: '10 km / RF 140 / 500 kg',
      [Difficulty.Impossible]: '100 km / RF 160 / 1 Tm',
      [Difficulty.Inhuman]: '1 000 km / RF 180 / 10 Tm',
      [Difficulty.Zen]: '10 000 km / RF 200 / 100 Tm',
    },
  },
  {
    id: '113',
    name: 'Manipular la luz',
    discipline: PsychicDisciplineType.Light,
    level: 1,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: 'Fatiga 1',
      [Difficulty.Hard]: '1 m / RF 100',
      [Difficulty.VeryHard]: '5 m / RF 120',
      [Difficulty.Absurd]: '10 m / RF 140',
      [Difficulty.AlmostImpossible]: '25 m / RF 160',
      [Difficulty.Impossible]: '50 m / RF 180',
      [Difficulty.Inhuman]: '250 m / RF 200',
      [Difficulty.Zen]: '500 m / RF 240',
    },
  },
  {
    id: '114',
    name: 'Crear luz',
    discipline: PsychicDisciplineType.Light,
    level: 1,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '1 m de radio',
      [Difficulty.Hard]: '5 m de radio',
      [Difficulty.VeryHard]: '10 m de radio',
      [Difficulty.Absurd]: '25 m de radio',
      [Difficulty.AlmostImpossible]: '50 m de radio',
      [Difficulty.Impossible]: '100 m de radio',
      [Difficulty.Inhuman]: '250 m de radio',
      [Difficulty.Zen]: '500 m de radio',
    },
  },
  {
    id: '115',
    name: 'Flash de luz',
    discipline: PsychicDisciplineType.Light,
    level: 1,
    hasMaintenance: false,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: '5 m de radio / RF 80',
      [Difficulty.Hard]: '10 m de radio / RF 100',
      [Difficulty.VeryHard]: '15 m de radio / RF 120',
      [Difficulty.Absurd]: '25 m de radio / RF 140',
      [Difficulty.AlmostImpossible]: '50 m de radio / RF 160',
      [Difficulty.Impossible]: '75 m de radio / RF 180',
      [Difficulty.Inhuman]: '100 m de radio / RF 200',
      [Difficulty.Zen]: '150 m de radio / RF 220',
    },
  },
  {
    id: '116',
    name: 'Pantalla de luz',
    discipline: PsychicDisciplineType.Light,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Passive,
    minimalDifficultyToSuccess: Difficulty.VeryHard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: 'Fatiga 1',
      [Difficulty.VeryHard]: '500 PVs',
      [Difficulty.Absurd]: '800 PVs',
      [Difficulty.AlmostImpossible]: '1000 PVs',
      [Difficulty.Impossible]: '1500 PVs',
      [Difficulty.Inhuman]: '2000 PVs',
      [Difficulty.Zen]: '3000 PVs',
    },
  },
  {
    id: '117',
    name: 'Holograma',
    discipline: PsychicDisciplineType.Light,
    level: 2,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 8',
      [Difficulty.Easy]: 'Fatiga 6',
      [Difficulty.Medium]: 'Fatiga 4',
      [Difficulty.Hard]: 'Fatiga 2',
      [Difficulty.VeryHard]: 'Fatiga 1',
      [Difficulty.Absurd]: '1 m2',
      [Difficulty.AlmostImpossible]: '5 m2',
      [Difficulty.Impossible]: '10 m2',
      [Difficulty.Inhuman]: '25 m2',
      [Difficulty.Zen]: '50 m2',
    },
  },
  {
    id: '118',
    name: 'Láser',
    discipline: PsychicDisciplineType.Light,
    level: 3,
    hasMaintenance: false,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 16',
      [Difficulty.Easy]: 'Fatiga 12',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: '160 Daño / -2 TA',
      [Difficulty.AlmostImpossible]: '180 Daño / -4 TA',
      [Difficulty.Impossible]: '200 Daño / -6 TA',
      [Difficulty.Inhuman]: '220 Daño / -8 TA',
      [Difficulty.Zen]: '240 Daño / -10 TA',
    },
  },
  {
    id: '119',
    name: 'Filtrar sentidos',
    discipline: PsychicDisciplineType.Hypersensitivity,
    level: 1,
    minimalDifficultyToSuccess: Difficulty.Easy,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Passive,
    casts: {
      [Difficulty.Routine]: 'Fatiga 1',
      [Difficulty.Easy]: 'Hasta -10 / 1 sentido',
      [Difficulty.Medium]: 'Hasta -20 / 1 sentido',
      [Difficulty.Hard]: 'Hasta -30 / 1 sentido',
      [Difficulty.VeryHard]: 'Hasta -40 / 2 sentidos',
      [Difficulty.Absurd]: 'Hasta -50 / 2 sentidos',
      [Difficulty.AlmostImpossible]: 'Hasta -60 / 3 sentidos',
      [Difficulty.Impossible]: 'Hasta -70 / 3 sentidos',
      [Difficulty.Inhuman]: 'Hasta -80 / 4 sentidos',
      [Difficulty.Zen]: 'Hasta -100 / 5 sentidos',
    },
  },
  {
    id: '120',
    name: 'Desplazar sentidos',
    discipline: PsychicDisciplineType.Hypersensitivity,
    level: 2,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 4',
      [Difficulty.Easy]: 'Fatiga 2',
      [Difficulty.Medium]: '1 m',
      [Difficulty.Hard]: '5 m',
      [Difficulty.VeryHard]: '10 m',
      [Difficulty.Absurd]: '25 m',
      [Difficulty.AlmostImpossible]: '50 m',
      [Difficulty.Impossible]: '100 m',
      [Difficulty.Inhuman]: '250 m',
      [Difficulty.Zen]: '500 m',
    },
  },
  {
    id: '121',
    name: 'Sentidos superiores',
    discipline: PsychicDisciplineType.Hypersensitivity,
    level: 3,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Absurd,
    casts: {
      [Difficulty.Routine]: 'Fatiga 12',
      [Difficulty.Easy]: 'Fatiga 10',
      [Difficulty.Medium]: 'Fatiga 8',
      [Difficulty.Hard]: 'Fatiga 6',
      [Difficulty.VeryHard]: 'Fatiga 4',
      [Difficulty.Absurd]: 'Sentir electromagnetismo',
      [Difficulty.AlmostImpossible]: 'Sentir matrices',
      [Difficulty.Impossible]: 'Sentir magia',
      [Difficulty.Inhuman]: 'Sentir ki',
      [Difficulty.Zen]: 'Sentir todo',
    },
  },
  {
    id: '122',
    name: 'Destruir matrices',
    discipline: PsychicDisciplineType.MatrixPowers,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: 'Poderes de nivel Medio',
      [Difficulty.VeryHard]: 'Poderes de nivel Difícil',
      [Difficulty.Absurd]: 'Poderes de nivel Muy Difícil',
      [Difficulty.AlmostImpossible]: 'Poderes de nivel Absurdo',
      [Difficulty.Impossible]: 'Poderes de nivel Casi Imposible',
      [Difficulty.Inhuman]: 'Poderes de nivel Imposible',
      [Difficulty.Zen]: 'Poderes de nivel Inhumano',
    },
  },
  {
    id: '123',
    name: 'Sentir matrices',
    discipline: PsychicDisciplineType.MatrixPowers,
    minimalDifficultyToSuccess: Difficulty.Easy,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    casts: {
      [Difficulty.Routine]: 'Fatiga 1',
      [Difficulty.Easy]: '10m / Ver matrices activas',
      [Difficulty.Medium]: '25m / Detecta poder latente',
      [Difficulty.Hard]: '50m / Reconocer poder',
      [Difficulty.VeryHard]: '100 m',
      [Difficulty.Absurd]: '250m Detecta disciplinas',
      [Difficulty.AlmostImpossible]: '500m Mide potencial',
      [Difficulty.Impossible]: '1km Detecta los CV libres ',
      [Difficulty.Inhuman]: '5km Poderes de otro psíquico',
      [Difficulty.Zen]: '100km',
    },
  },
  {
    id: '124',
    name: 'Ocultar matrices',
    discipline: PsychicDisciplineType.MatrixPowers,
    hasMaintenance: true,
    minimalDifficultyToSuccess: Difficulty.Medium,
    actionType: PsychicPowerActionType.Passive,
    casts: {
      [Difficulty.Routine]: 'Fatiga 2',
      [Difficulty.Easy]: 'Fatiga 1',
      [Difficulty.Medium]: ' -2 grados de dificultad',
      [Difficulty.Hard]: ' -3 grados de dificultad',
      [Difficulty.VeryHard]: ' -4 grados de dificultad',
      [Difficulty.Absurd]: ' -5 grados de dificultad',
      [Difficulty.AlmostImpossible]: ' -6 grados de dificultad',
      [Difficulty.Impossible]: ' -7 grados de dificultad',
      [Difficulty.Inhuman]: ' -8 grados de dificultad',
      [Difficulty.Zen]: ' -9 grados de dificultad',
    },
  },
  {
    id: '125',
    name: 'Conectar matrices',
    discipline: PsychicDisciplineType.MatrixPowers,
    hasMaintenance: true,
    actionType: PsychicPowerActionType.Active,
    minimalDifficultyToSuccess: Difficulty.Hard,
    casts: {
      [Difficulty.Routine]: 'Fatiga 6',
      [Difficulty.Easy]: 'Fatiga 4',
      [Difficulty.Medium]: 'Fatiga 2',
      [Difficulty.Hard]: '2 individuos',
      [Difficulty.VeryHard]: '3 individuos',
      [Difficulty.Absurd]: '4 individuos',
      [Difficulty.AlmostImpossible]: '6 individuos',
      [Difficulty.Impossible]: '8 individuos',
      [Difficulty.Inhuman]: '10 individuos',
      [Difficulty.Zen]: '20 individuos',
    },
  },
];
