import { useUserAuthorization } from '../../../../../../../shared/application/hooks/useUserAuthorization';
import { useState } from 'react';
import { UserService } from '../../../../../../infrastructure/UserService';

export type MyUserProfile = {
  email: string;
  username: string;
};

export const useUserProfile = () => {
  const { session, refreshSession } = useUserAuthorization();

  const [changingUsername, setChangingUsername] = useState<boolean>(false);

  const changeUsername = async (username: string) => {
    setChangingUsername(true);

    try {
      const existUsername = await UserService.checkIfUsernameExist(username);

      if (existUsername) {
        return false;
      }

      await UserService.changeUsername(username);

      await refreshSession();

      return true;
    } finally {
      setChangingUsername(false);
    }
  };

  const [changingPassword, setChangingPassword] = useState<boolean>(false);

  const changePassword = async (password: string) => {
    setChangingPassword(true);

    try {
      await UserService.changePassword(password);
    } finally {
      setChangingPassword(false);
    }
  };

  const profile: MyUserProfile = {
    email: session?.email ?? 'idk@unknown.com',
    username: session?.username ?? '',
  };

  return {
    profile,

    changeUsername,
    changingUsername,

    changePassword,
    changingPassword,
  };
};
