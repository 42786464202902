import { MouseEvent, ReactElement } from 'react';
import { Modal as MuiModal } from '@mui/material';
import { StyledClose, Wrapper } from './Modal.styled';

export type OpenModalOptions = {
  fullScreenInMobile?: boolean;
};

type ModalProps = {
  children: ReactElement | ReactElement[];
  open: boolean;
  onCloseClick: (e: MouseEvent) => void;

  options?: OpenModalOptions;
};

export const Modal = ({
  children,
  open,
  onCloseClick,
  options,
}: ModalProps) => {
  return (
    <MuiModal open={open} onBackdropClick={onCloseClick}>
      <Wrapper fullScreenInMobile={options?.fullScreenInMobile}>
        <StyledClose onClick={onCloseClick} />
        {children}
      </Wrapper>
    </MuiModal>
  );
};
