import { model } from 'mobx-keystone';
import { BaseFinalFieldModel } from '../../../utils/fields/BaseFinalFieldModel';

@model('AllPhysicalActionsModifierField')
export class AllPhysicalActionsModifierFieldModel extends BaseFinalFieldModel {
  get final(): number {
    if (!this.character) return 0;

    const penalty = this.character.modifiers.wearArmorRequirement.final;

    const ability = this.character.combat.wearArmorAbility.final;

    return Math.min(0, ability - penalty);
  }
}
