import { observer } from 'mobx-react';
import { Section } from '../../../common/Section/Section';
import { Table } from '../../../common/Table/Table';
import { useCharacterContext } from '../../../../hooks/useCharacterContext';
import { CharacterIssue } from '../../../../../../../../../domain/character/model/utils/issues/CharacterIssue';

export const TotalPsychicPoints = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  return (
    <Section maxHeight="fit">
      <Section.Title>CVs</Section.Title>
      <Table>
        <Table.Header>
          <Table.Header.Cell>Totales</Table.Header.Cell>
          <Table.Header.Cell>Usados</Table.Header.Cell>
        </Table.Header>
        <Table.Row>
          <Table.Row.Cell $important>
            {character.psychic.psychicPoints.final}
          </Table.Row.Cell>
          <Table.Row.Cell $important>
            {character.psychic.usedPsychicPoints}
          </Table.Row.Cell>
        </Table.Row>
        {character.hasIssue(CharacterIssue.PsychicPointsUsedExceeded) && (
          <Table.Row>
            <Table.Row.Cell colSpan={2} $danger $bold>
              Exceso de uso de CVs
            </Table.Row.Cell>
          </Table.Row>
        )}
      </Table>
    </Section>
  );
});
