import styled from 'styled-components';

import { SmallParagraph } from '../Texts/Texts.styled';
import { TextAreaSize, TextAreaState } from './TextArea.types';

export const StyledTextArea = styled.textarea<{
  $state: TextAreaState;
  $size: TextAreaSize;
}>(
  ({
    theme: { size, color, borderRadius, border, transition, font },
    $state,
    $size,
  }) => {
    const getHeight = () => {
      if ($size === TextAreaSize.Small) {
        return 3;
      }

      return 5;
    };

    const getFontSize = () => {
      if ($size === TextAreaSize.Small) {
        return 1.5;
      }

      return 2;
    };

    return {
      fontFamily: font.family,

      resize: 'none',

      boxSizing: 'border-box',

      fontSize: size.units(getFontSize()),
      width: '100%',

      borderRadius: borderRadius.medium,
      border: border.small,
      boxShadow: 'none',
      minHeight: size.units(getHeight()),
      padding: size.units(1),

      transition: transition.standard,

      outline: 'none',

      borderColor: $state === TextAreaState.Error ? color.error() : undefined,

      '&:focus': {
        borderColor: color.primaryDark(),
      },

      '&:hover': {
        borderColor: color.primary(),
      },
    };
  },
);

export const Info = styled(SmallParagraph)<{ $state: TextAreaState }>(
  ({ theme: { color }, $state }) => ({
    color: $state === TextAreaState.Error ? color.error() : undefined,
    textAlign: 'right',
    fontStyle: 'italic',
  }),
);

export const Wrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: size.units(0.5),

  width: '100%',
}));
