import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import {
  Advantage,
  AdvantageType,
} from '../../../../../../../../../../../../domain/aggregations/advantage/Advantage.types';
import { getUnusedAdvantageOfType } from '../../../../../../../../../../../../domain/aggregations/advantage/Advantages';
import { AllBackgroundAdvantages } from '../../../../../../../../../../../../domain/aggregations/advantage/BackgroundAdvantages';
import { AllCommonAdvantages } from '../../../../../../../../../../../../domain/aggregations/advantage/CommonAdvantages';
import { AllDonAdvantages } from '../../../../../../../../../../../../domain/aggregations/advantage/DonAdvantages';
import { AllPsychicAdvantages } from '../../../../../../../../../../../../domain/aggregations/advantage/PsychicAdvantages';
import {
  AdvantageModel,
  createModelFromAdvantage,
} from '../../../../../../../../../../../../domain/character/model/parts/creation-points/AdvantageModel';
import { filterUndefined } from '../../../../../../../../../../../../../shared/utils/filterUndefined';
import { useCharacterContext } from '../../../../../../../hooks/useCharacterContext';
import { Selector } from '../../../common/Selector/Selector';
import { AllBloodLegacyAdvantages } from '../../../../../../../../../../../../domain/aggregations/advantage/BloodLegacyAdvantages';

type AdvantageSelectorProps = {
  type: AdvantageType;
};

export const AdvantageSelector = observer(
  ({ type }: AdvantageSelectorProps) => {
    const {
      character: { content: character },
    } = useCharacterContext();

    const allAdvantages: Advantage[] = useMemo(() => {
      switch (type) {
        case AdvantageType.Common:
          return AllCommonAdvantages;
        case AdvantageType.Don:
          return AllDonAdvantages;
        case AdvantageType.Psychics:
          return AllPsychicAdvantages;
        case AdvantageType.Background:
          return AllBackgroundAdvantages;
        case AdvantageType.BloodLegacy:
          return AllBloodLegacyAdvantages;
      }
    }, [type]);

    const handleAddAdvantage = () => {
      const unusedAdvantage = getUnusedAdvantageOfType(
        character.creationPoints.advantages
          .map((a) => a.id)
          .filter(filterUndefined()),
        type,
      );

      if (unusedAdvantage) {
        character.creationPoints.addAdvantage(unusedAdvantage);
      }
    };

    const handleRemoveAdvantage = (table: AdvantageModel) => {
      character.creationPoints.removeAdvantage(table);
    };

    const handleAdvantageChange = (from: AdvantageModel, to: Advantage) => {
      character.creationPoints.replaceAdvantage(
        from,
        createModelFromAdvantage(to),
      );
    };

    const advantages = character.creationPoints.getAdvantagesOfType(type);

    return (
      <Selector
        elements={advantages}
        allElements={allAdvantages}
        onElementAdd={handleAddAdvantage}
        onElementRemove={handleRemoveAdvantage}
        onElementChange={handleAdvantageChange}
      />
    );
  },
);

export const CommonAdvantageSelector = observer(() => {
  return <AdvantageSelector type={AdvantageType.Common} />;
});

export const DonAdvantageSelector = observer(() => {
  return <AdvantageSelector type={AdvantageType.Don} />;
});

export const PsychicAdvantageSelector = observer(() => {
  return <AdvantageSelector type={AdvantageType.Psychics} />;
});

export const BackgroundAdvantageSelector = observer(() => {
  return <AdvantageSelector type={AdvantageType.Background} />;
});

export const BloodLegacyAdvantageSelector = observer(() => {
  return <AdvantageSelector type={AdvantageType.BloodLegacy} />;
});
