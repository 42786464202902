import { ExtendedModel, model, prop } from 'mobx-keystone';
import {
  AllFieldsSkilledAdvantage,
  FieldCategorySkilledAdvantage,
  FieldSkilledAdvantage,
} from '../../../../../../../../aggregations/advantage/CommonAdvantages';
import {
  getCategoryForSecondarySkill,
  SecondarySkill,
  SecondarySkillCategory,
} from '../../../../../../types/SecondarySkill';
import { PDFieldModel } from '../PDFieldModel';
import { SecondarySkillBonusType } from './SecondarySkillPDFieldModel.types';

@model('Character/PD/SecondarySkillField')
export class SecondarySkillPDFieldModel extends ExtendedModel(PDFieldModel, {
  skill: prop(SecondarySkill.SleightOfHand),
  type: prop(SecondarySkillBonusType.Agility),
}) {
  get isDeveloped() {
    if (
      this.character?.creationPoints.containsAdvantage(
        AllFieldsSkilledAdvantage,
      )
    ) {
      return true;
    }

    return this.bought >= 5;
  }

  get pdsCost(): number {
    let cost = 99;

    const category = getCategoryForSecondarySkill(this.skill);

    switch (category) {
      case SecondarySkillCategory.Athletic:
        cost = this.template.athleticSecondariesPDsCost;
        break;
      case SecondarySkillCategory.Creative:
        cost = this.template.creativeSecondariesPDsCost;
        break;
      case SecondarySkillCategory.Intellectual:
        cost = this.template.intellectualSecondariesPDsCost;
        break;
      case SecondarySkillCategory.Perception:
        cost = this.template.perceptionSecondariesPDsCost;
        break;
      case SecondarySkillCategory.Social:
        cost = this.template.socialSecondariesPDsCost;
        break;
      case SecondarySkillCategory.Subterfuge:
        cost = this.template.subterfugeSecondariesPDsCost;
        break;
      case SecondarySkillCategory.Vigor:
        cost = this.template.vigorSecondariesPDsCost;
        break;
    }

    if (
      this.character?.creationPoints.containsAdvantage(
        FieldCategorySkilledAdvantage,
        { variation: category },
      )
    ) {
      cost -= 1;
    }
    if (
      this.character?.creationPoints.containsAdvantage(FieldSkilledAdvantage, {
        variation: this.skill,
      })
    ) {
      cost -= 1;
    }

    return Math.max(
      1,
      cost - this.template.calculateSecondarySkillPDReduction(this.skill),
    );
  }

  get perCategory(): number {
    return (
      this.template.calculateSecondarySkillBonusPerCategory(this.skill) *
      this.level
    );
  }
}
