import { model } from 'mobx-keystone';
import { SecondarySkill } from '../../../../../types/SecondarySkill';
import { getTotalOf } from '../../../../../utils/getTotalOf';
import { SecondarySkillPDFieldModel } from '../../../../pd/parts/categories/parts/secondaries/SecondarySkillPDFieldModel';
import { SecondarySkillFieldModel } from '../../../utils/fields/SecondarySkillFieldModel';

@model('Character/Secondaries/CreativeSkills/Art')
export class ArtSkillModel extends SecondarySkillFieldModel {
  type = SecondarySkill.Art;

  get primariesBonus(): number {
    if (!this.character) return 0;

    return this.character.primaries.power.modifier.final;
  }

  getPDFields(): SecondarySkillPDFieldModel[] {
    return (
      this.character?.pd.categories.map((c) => c.secondaries.creative.art) ?? []
    );
  }

  get fromCategories(): number {
    return getTotalOf(this.character, (c) => c.secondaries.creative.art.final);
  }
}
