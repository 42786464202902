import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import { MenuItem } from '../../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';
import { getMartialArtWeaponTableFromType } from '../../../../../../../../../../../domain/aggregations/tables/martial-art-tables/getMartialArtWeaponTableFromType';
import { MartialArtWeaponTableType } from '../../../../../../../../../../../domain/aggregations/tables/martial-art-tables/MartialArtWeaponTable';
import { MartialArtWeaponTableModel } from '../../../../../../../../../../../domain/character/model/parts/combat/parts/MartialArtWeaponTableModel';
import { AddItemButton } from '../../../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../../../common/CharacterMenu/useCharacterMenu';
import { RemoveItemButton } from '../../../../../common/RemoveItemButton/RemoveItemButton';
import { Table } from '../../../../../common/Table/Table';
import { CombatPDModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/parts/combat/CombatPDModel';

type MartialArtWeaponTableSelectorProps = {
  tables: CombatPDModel;
};

export const MartialArtWeaponTableSelector = observer(
  ({ tables }: MartialArtWeaponTableSelectorProps) => {
    const { handleMenuOpen: handleTableMenuOpen, Menu: MartialArtWeaponMenu } =
      useCharacterMenu<MartialArtWeaponTableType>();

    const [selectedTable, setSelectedTable] =
      useState<MartialArtWeaponTableModel>();

    const handleAddMartialArtWeaponTable = () => {
      tables.addMartialArtWeaponTable(MartialArtWeaponTableType.Aikido);
    };

    const handleRemoveMartialArtWeaponTable =
      (table: MartialArtWeaponTableModel) => () => {
        tables.removeMartialArtWeaponTable(table);
      };

    const martialArtWeaponTables = (
      Object.values(MartialArtWeaponTableType) as MartialArtWeaponTableType[]
    )
      .map(getMartialArtWeaponTableFromType)
      .map((t) => {
        const item: MenuItem<MartialArtWeaponTableType> = {
          key: t.type,
          name: t.name,
          value: t.type,
          onClick: () => {
            selectedTable?.setType(t.type);
          },
        };
        return item;
      });

    return (
      <>
        <Table>
          <Table.Header>
            <Table.Header.Cell $width={5}>Tabla</Table.Header.Cell>
            <Table.Header.Cell>Coste</Table.Header.Cell>
          </Table.Header>
          {tables.martialArtWeaponTables.map((developedTable, i) => {
            const handleTableSelection = (e: MouseEvent<HTMLDivElement>) => {
              handleTableMenuOpen(developedTable.type)(e);
              setSelectedTable(developedTable);
            };

            return (
              <Table.Row key={`${i}-${developedTable.type}`}>
                <Table.Row.Cell onClick={handleTableSelection}>
                  {developedTable.name}
                </Table.Row.Cell>
                <Table.Row.Cell>{developedTable.cost}</Table.Row.Cell>

                <RemoveItemButton
                  onClick={handleRemoveMartialArtWeaponTable(developedTable)}
                />

                <MartialArtWeaponMenu items={martialArtWeaponTables} />
              </Table.Row>
            );
          })}
        </Table>
        <AddItemButton onClick={handleAddMartialArtWeaponTable} />
      </>
    );
  },
);
