import { WeaponTable, WeaponTableType } from './WeaponTable';
import {
  PredefinedWeapon,
  PredefinedWeapons,
} from '../../weapon/PredefinedWeapons';
import { WeaponKnowledgeType } from '../../weapon/Weapon.types';
import { CharacterModel } from '../../../character/model/CharacterModel';

export class SimilarWeaponTable extends WeaponTable<PredefinedWeapon> {
  name = 'Tabla de Armas Similares';
  type = WeaponTableType.SimilarWeapon;
  cost = 10;

  getItems(): PredefinedWeapon[] {
    return PredefinedWeapons;
  }

  getFilteredItems(character: CharacterModel): PredefinedWeapon[] {
    return PredefinedWeapons.filter(
      (weapon) =>
        character.calculateKnowledgeTypeOf(weapon) ===
        WeaponKnowledgeType.Similar,
    );
  }
}
