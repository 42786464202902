import { model } from 'mobx-keystone';
import {
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
} from '../../../../../aggregations/advantage/Advantage.types';
import { MagicSuperiorRecoveryAdvantage } from '../../../../../aggregations/advantage/DonAdvantages';
import {
  FixedMagicDisadvantage,
  SlowRecoveryMagicDisadvantage,
} from '../../../../../aggregations/disadvantage/DonDisadvantages';
import { DevelopedPDFieldModel } from '../../../utils/fields/DevelopedPDFieldModel';
import { getTotalOf } from '../../../utils/getTotalOf';

@model('Character/Mystic/ZeonRegenerationField')
export class ZeonRegenerationFieldModel extends DevelopedPDFieldModel {
  get fromCategories(): number {
    return getTotalOf(this.character, (c) => c.mystic.zeonRegeneration.final);
  }

  get allActionsPenalty(): number {
    return 0;
  }

  get other(): number {
    if (!this.character) return 0;

    return this.character.mystic.zeonAmplifier;
  }

  get final(): number {
    if (
      this.character?.creationPoints.containsDisadvantage(
        FixedMagicDisadvantage,
      )
    ) {
      return 0;
    }

    let value = super.final;

    if (
      this.character?.creationPoints.containsAdvantage(
        MagicSuperiorRecoveryAdvantage,
        {
          level: AdvantageLevel1,
        },
      )
    ) {
      value *= 2;
    }

    if (
      this.character?.creationPoints.containsAdvantage(
        MagicSuperiorRecoveryAdvantage,
        {
          level: AdvantageLevel2,
        },
      )
    ) {
      value *= 3;
    }

    if (
      this.character?.creationPoints.containsAdvantage(
        MagicSuperiorRecoveryAdvantage,
        {
          level: AdvantageLevel3,
        },
      )
    ) {
      value *= 4;
    }

    if (
      this.character?.creationPoints.containsDisadvantage(
        SlowRecoveryMagicDisadvantage,
      )
    ) {
      value /= 2;
    }

    return Math.floor(value);
  }

  get primariesBonus(): number {
    if (!this.character) return 0;

    return this.character.mystic.act.final;
  }
}
