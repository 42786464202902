import { model, Model, prop } from 'mobx-keystone';
import { EtiquetteSkillModel } from './fields/EtiquetteSkillModel';
import { IntimidateSkillModel } from './fields/IntimidateSkillModel';
import { LeadershipSkillModel } from './fields/LeadershipSkillModel';
import { PersuasionSkillModel } from './fields/PersuasionSkillModel';
import { StreetwiseSkillModel } from './fields/StreetwiseSkillModel';
import { StyleSkillModel } from './fields/StyleSkillModel';
import { TradingSkillModel } from './fields/TradingSkillModel';

@model('Character/Secondaries/SocialSkills')
export class SocialSkillsModel extends Model({
  style: prop(() => new StyleSkillModel({})),
  intimidate: prop(() => new IntimidateSkillModel({})),
  leadership: prop(() => new LeadershipSkillModel({})),
  persuasion: prop(() => new PersuasionSkillModel({})),
  trading: prop(() => new TradingSkillModel({})),
  streetwise: prop(() => new StreetwiseSkillModel({})),
  etiquette: prop(() => new EtiquetteSkillModel({})),
}) {
  get usedPhysicalNaturalBonusPoints(): number {
    return 0;
  }

  get usedMentalNaturalBonusPoints(): number {
    return (
      this.style.naturalBonusPoints.base +
      this.intimidate.naturalBonusPoints.base +
      this.leadership.naturalBonusPoints.base +
      this.persuasion.naturalBonusPoints.base +
      this.trading.naturalBonusPoints.base +
      this.streetwise.naturalBonusPoints.base +
      this.etiquette.naturalBonusPoints.base
    );
  }

  get usedNaturalAbilityPoints(): number {
    return (
      this.style.naturalAbilityPoints +
      this.intimidate.naturalAbilityPoints +
      this.leadership.naturalAbilityPoints +
      this.persuasion.naturalAbilityPoints +
      this.trading.naturalAbilityPoints +
      this.streetwise.naturalAbilityPoints +
      this.etiquette.naturalAbilityPoints
    );
  }

  get usedNovelBonusPoints(): number {
    return (
      this.style.novelBonus +
      this.intimidate.novelBonus +
      this.leadership.novelBonus +
      this.persuasion.novelBonus +
      this.trading.novelBonus +
      this.streetwise.novelBonus +
      this.etiquette.novelBonus
    );
  }
}
