import { model } from 'mobx-keystone';
import { PDFieldModel } from '../PDFieldModel';

@model('MagicLevelField')
export class MagicLevelPDFieldModel extends PDFieldModel {
  protected readonly scaleByMultiples = true;

  get pdsCost(): number {
    return 5;
  }

  get perCategory(): undefined {
    return undefined;
  }
}
