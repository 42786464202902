import { WeaponItemModel } from '../../../../../../../../../../../../../../domain/aggregations/weapon/WeaponItemModel';
import { observer } from 'mobx-react';
import {
  WeaponShotType,
  weaponShotTypeTranslations,
} from '../../../../../../../../../../../../../../domain/aggregations/weapon/Weapon.types';
import { transformToMenuItems } from '../../../../../../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { Selector } from '../../../../../../../../common/Selector/Selector';

export const ShotTypeSelector = observer(
  ({ weapon }: { weapon: WeaponItemModel }) => {
    const items = transformToMenuItems({
      fromItems: Object.values(WeaponShotType),
      generateItem: (shotType) => ({
        key: shotType,
        name: weaponShotTypeTranslations[shotType],
        value: shotType,
        onClick: () => {
          weapon.setShotType(shotType);
        },
      }),
    });

    return (
      <Selector
        value={weapon.shotType}
        valueAsText={
          weapon.shotType ? weaponShotTypeTranslations[weapon.shotType] : '-'
        }
        items={items}
      />
    );
  },
);
