import React, { ChangeEvent, useEffect, useState } from 'react';
import { vibrate } from '../../../../../../../../../shared/utils/vibrate';
import { InputWrapper, Label, StyledInput, Wrapper } from './Input.styled';
import {
  InputDigits,
  InputSize,
  InputVariant,
  LabelPosition,
} from './Input.types';
import { useCharacterContext } from '../../../hooks/useCharacterContext';

export type InputProps = {
  label?: string;

  value: number;
  setValue?: (value: number) => void;

  variant?: InputVariant;
  size?: InputSize;
  labelPosition?: LabelPosition;

  step?: number;
  max?: number;

  disabled?: boolean;
};

export const Input = ({
  value,
  setValue,
  variant,
  max,
  labelPosition = LabelPosition.Left,
  label,
  size,
  step = 1,
  disabled = false,
}: InputProps) => {
  const [individualInputSize, setIndividualInputSize] = useState<InputDigits>(
    InputDigits.TENS,
  );

  const { character } = useCharacterContext();

  useEffect(() => {
    const valueLength = value.toFixed(0).length;

    if (valueLength > 3) {
      setIndividualInputSize(InputDigits.THOUSANDS);
    } else if (valueLength > 2) {
      setIndividualInputSize(InputDigits.HUNDREDS);
    } else {
      setIndividualInputSize(InputDigits.TENS);
    }
  }, [value]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!setValue) return;

    let value = 0;

    try {
      value = parseInt(e.currentTarget.value);
    } catch {
      console.warn(`Cannot parse value ${e.currentTarget.value} to number`);
    }

    if (isNaN(value)) {
      setValue(0);
    } else {
      setValue(value);
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!setValue) return;

    const isCtrl = e.ctrlKey || e.metaKey;

    if (isCtrl) {
      e.preventDefault();
    }

    const isAlt = e.altKey;

    if (e.key === 'ArrowUp') {
      setValue(
        value + step + (isCtrl ? step * 9 : 0) + (isAlt ? step * 99 : 0),
      );
    }

    if (e.key === 'ArrowDown') {
      setValue(
        value - step - (isCtrl ? step * 9 : 0) - (isAlt ? step * 99 : 0),
      );
    }
  };

  const handleOnFocus = () => {
    vibrate();
  };

  const inputDisabled = disabled || !character.canBeEdited;

  return (
    <Wrapper $hasLabel={!!label} $labelPosition={labelPosition}>
      {label && (
        <Label $labelPosition={labelPosition} variant="body1">
          {label}
        </Label>
      )}
      <InputWrapper>
        <StyledInput
          value={value}
          onFocus={handleOnFocus}
          onKeyUp={onKeyUp}
          onChange={onChange}
          disabled={inputDisabled}
          inputProps={{
            inputMode: 'numeric',
            pattern: '^-?[0-9]\\d*',
            max: max ? max.toString() : undefined,
          }}
          $width={individualInputSize}
          $size={size}
          $variant={variant}
        />
      </InputWrapper>
    </Wrapper>
  );
};
