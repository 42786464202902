import { Tooltip } from '@mui/material';
import { observer } from 'mobx-react';
import { PDFieldModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/parts/PDFieldModel';
import { DevelopedPDFieldModel } from '../../../../../../../../../../../domain/character/model/utils/fields/DevelopedPDFieldModel';
import { Calculations } from '../../../../../common/Calculations/Calculations';
import DoubleInput from '../../../../../common/DoubleInput/DoubleInput';
import { Input } from '../../../../../common/Input/Input';
import { Table } from '../../../../../common/Table/Table';

type Props = {
  name: string;
  pdField: PDFieldModel;
  field: DevelopedPDFieldModel;

  maxPerCategory?: number;
};

export const PDFieldRow = observer(
  ({ name, field, pdField, maxPerCategory }: Props) => {
    const calculatePerCategory = () => {
      if (pdField.perCategory) {
        return maxPerCategory
          ? Math.min(maxPerCategory, pdField.perCategory)
          : pdField.perCategory;
      }

      return '-';
    };

    return (
      <Table.Row>
        <Table.Row.Cell>{name}</Table.Row.Cell>
        <Table.Row.Cell>{pdField.pdsCost}</Table.Row.Cell>
        <Table.Row.Cell>
          <DoubleInput step={pdField.pdsCost} field={pdField} disableFinal />
        </Table.Row.Cell>
        <Table.Row.Cell $hideOnMobile>
          {field.calculateFromCalculations(field.allBonuses)}
        </Table.Row.Cell>
        <Table.Row.Cell $hideOnMobile>{calculatePerCategory()}</Table.Row.Cell>
        <Table.Row.Cell>
          <Input value={field.special} setValue={(v) => field.setSpecial(v)} />
        </Table.Row.Cell>
        <Table.Row.Cell>
          <Tooltip
            title={<Calculations calculations={field.getCalculations()} />}
          >
            <span>{field.final}</span>
          </Tooltip>
        </Table.Row.Cell>
      </Table.Row>
    );
  },
);
