import { WeaponSize } from '../Weapon.types';
import { WeaponItemModel } from '../WeaponItemModel';

export const calculateShieldBlockBonus = (weapon: WeaponItemModel): number => {
  switch (weapon.size) {
    case WeaponSize.Small:
      return 10;
    case WeaponSize.Medium:
      return 20;
    case WeaponSize.Big:
      return 30;
    default:
      return 0;
  }
};
