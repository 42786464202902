import { model, Model, prop } from 'mobx-keystone';
import { getParentCharacter } from '../../../../utils/parenting/getParentCharacter';
import { AttackAbilityFieldModel } from './parts/AttackAbilityFieldModel';
import { BlockAbilityFieldModel } from './parts/BlockAbilityFieldModel';
import { DodgeAbilityFieldModel } from './parts/DodgeAbilityFieldModel';
import { WearArmorAbilityFieldModel } from './parts/WearArmorAbilityFieldModel';
import { computed } from 'mobx';
import { getAllItemsOf, getTotalOf } from '../../utils/getTotalOf';
import { MartialArtModel } from './parts/MartialArtModel';
import { WeaponTableModel } from './parts/WeaponTableModel';
import { StyleTableModel } from './parts/StyleTableModel';
import { MartialArtWeaponTableModel } from './parts/MartialArtWeaponTableModel';
import { ArsMagnusModel } from './parts/ArsMagnusModel';
import { HitType } from '../../types/HitType';
import { WeaponTableType } from '../../../../aggregations/tables/weapon-tables/WeaponTable';

@model('Character/Combat')
export class CombatModel extends Model({
  attackAbility: prop(() => new AttackAbilityFieldModel({})),
  blockAbility: prop(() => new BlockAbilityFieldModel({})),
  dodgeAbility: prop(() => new DodgeAbilityFieldModel({})),
  wearArmorAbility: prop(() => new WearArmorAbilityFieldModel({})),
}) {
  @computed
  get defensiveAbility(): number {
    return Math.max(this.dodgeAbility.final, this.blockAbility.final);
  }

  get pdsSpent(): number {
    if (!this.character) return 0;

    return getTotalOf(this.character, (c) => c.combat.pdsSpent);
  }

  @computed
  get martialArts(): MartialArtModel[] {
    return getAllItemsOf(this.character, (c) => c.combat.martialArts);
  }

  @computed
  get unarmed(): {
    attack: number;
    block: number;
    dodge: number;
    initiative: number;
    damage: number;
    hitTypes: HitType[];
  } {
    if (!this.character)
      return {
        attack: 0,
        block: 0,
        dodge: 0,
        initiative: 0,
        damage: 0,
        hitTypes: [],
      };

    const getValueOf = <T>(fn: (ma: MartialArtModel) => T) => {
      return this.martialArts.map(fn);
    };

    const maxAttack = Math.max(
      ...getValueOf((ma) => ma.combatBonus.attack + ma.masterBonus.attack),
    );

    const maxBlock = Math.max(
      ...getValueOf((ma) => ma.combatBonus.block + ma.masterBonus.block),
    );

    const maxDodge = Math.max(
      ...getValueOf((ma) => ma.combatBonus.dodge + ma.masterBonus.dodge),
    );

    const hitTypes = [
      HitType.Impact,
      ...getValueOf((ma) => ma.hitTypes).flat(),
    ];

    const uniqueHitTypes = [...new Set(hitTypes)];

    return {
      attack: this.attackAbility.final + maxAttack,
      block: this.blockAbility.final + maxBlock,
      dodge: this.dodgeAbility.final + maxDodge,
      initiative: this.character.initiative.unarmed,
      damage: this.character.damage.unarmed,
      hitTypes: uniqueHitTypes,
    };
  }

  @computed
  get martialArtsHighestMasterBonus() {
    const attackMasterBonus = this.martialArts
      .filter((ma) => ma.grade.isAdvanced())
      .reduce((prev, curr) => {
        return Math.max(prev, curr.masterBonus.attack);
      }, 0);

    const blockMasterBonus = this.martialArts
      .filter((ma) => ma.grade.isAdvanced())
      .reduce((prev, curr) => {
        return Math.max(prev, curr.masterBonus.block);
      }, 0);

    const dodgeMasterBonus = this.martialArts
      .filter((ma) => ma.grade.isAdvanced())
      .reduce((prev, curr) => {
        return Math.max(prev, curr.masterBonus.dodge);
      }, 0);

    const initiativeMasterBonus = this.martialArts
      .filter((ma) => ma.grade.isAdvanced())
      .reduce((prev, curr) => {
        return Math.max(prev, curr.masterBonus.initiative);
      }, 0);

    return {
      attack: attackMasterBonus,
      block: blockMasterBonus,
      dodge: dodgeMasterBonus,
      initiative: initiativeMasterBonus,
    };
  }

  @computed
  get weaponTables(): WeaponTableModel[] {
    return getAllItemsOf(this.character, (c) => c.combat.weaponTables);
  }

  @computed
  get similarWeaponTables(): WeaponTableModel<WeaponTableType.SimilarWeapon>[] {
    return this.weaponTables.filter(
      (wt) => wt.type === WeaponTableType.SimilarWeapon,
    ) as WeaponTableModel<WeaponTableType.SimilarWeapon>[];
  }

  @computed
  get mixedWeaponTables(): WeaponTableModel<WeaponTableType.MixedWeapon>[] {
    return this.weaponTables.filter(
      (wt) => wt.type === WeaponTableType.MixedWeapon,
    ) as WeaponTableModel<WeaponTableType.MixedWeapon>[];
  }

  @computed
  get distinctWeaponTables(): WeaponTableModel<WeaponTableType.DistinctOrDisarmedWeapon>[] {
    return this.weaponTables.filter(
      (wt) => wt.type === WeaponTableType.DistinctOrDisarmedWeapon,
    ) as WeaponTableModel<WeaponTableType.DistinctOrDisarmedWeapon>[];
  }

  @computed
  get typologicalTables(): WeaponTableModel<WeaponTableType.TypologicalWeapon>[] {
    return this.weaponTables.filter(
      (wt) => wt.type === WeaponTableType.TypologicalWeapon,
    ) as WeaponTableModel<WeaponTableType.TypologicalWeapon>[];
  }

  @computed
  get archetypeTables(): WeaponTableModel<WeaponTableType.ArchetypeWeapon>[] {
    return this.weaponTables.filter(
      (wt) => wt.type === WeaponTableType.ArchetypeWeapon,
    ) as WeaponTableModel<WeaponTableType.ArchetypeWeapon>[];
  }

  @computed
  get styleTables(): StyleTableModel[] {
    return getAllItemsOf(this.character, (c) => c.combat.styleTables);
  }

  @computed
  get martialArtWeaponTables(): MartialArtWeaponTableModel[] {
    return getAllItemsOf(
      this.character,
      (c) => c.combat.martialArtWeaponTables,
    );
  }

  @computed
  get arsMagnus(): ArsMagnusModel[] {
    return getAllItemsOf(this.character, (c) => c.combat.arsMagnus);
  }

  get character() {
    return getParentCharacter(this);
  }
}
