import { User } from '@supabase/supabase-js';
import { getRandomUsername } from './utils/getRandomUsername';

export type UserV0 = Omit<User, 'user_metadata'> & {
  user_metadata: { avatar_url?: string };
};

export type UserV1 = Omit<User, 'user_metadata'> & {
  user_metadata: { avatar_url?: string; username: string; version: 1 };
};

const isUserV0 = (user: User): user is UserV0 => {
  return (
    !('username' in user.user_metadata) || user.user_metadata.username === ''
  );
};

const migrateFromV0ToV1 = (user: UserV0): UserV1 => {
  const metadata: UserV1['user_metadata'] = {
    ...user.user_metadata,
    username: getRandomUsername(),
    version: 1,
  };

  user.user_metadata = metadata;

  return user as UserV1;
};

export const migrateUserMetadata = (
  user: User,
): { needToMigrate: boolean; user: UserV1 } => {
  if (isUserV0(user)) {
    return { needToMigrate: true, user: migrateFromV0ToV1(user) };
  }

  return { needToMigrate: false, user: user as UserV1 };
};
