import { size } from '../size/size';
import { color } from '../colors/color';

export const shadow = {
  small: `0 1px ${size.units(0.25)} ${color.black(0.2)}`,
  smallHover: `0 1px ${size.units(0.25)} ${color.black(0.5)}`,
  smallDarker: `0 1px ${size.units(0.5)} ${color.black(0.3)}`,

  modal: `0 ${size.units(0.75)} ${size.units(2)} ${color.black(0.15)}`,
} as const;
