import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import {
  AllArsMagnus,
  ArsMagnusId,
  getUnusedArsMagnus,
} from '../../../../../../../../../../../domain/aggregations/ars-magnus/ArgsMagnus';
import {
  ArsMagnusModel,
  createModelFromArsMagnus,
} from '../../../../../../../../../../../domain/character/model/parts/combat/parts/ArsMagnusModel';
import { filterUndefined } from '../../../../../../../../../../../../shared/utils/filterUndefined';
import { AddItemButton } from '../../../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../../../common/CharacterMenu/useCharacterMenu';
import { RemoveItemButton } from '../../../../../common/RemoveItemButton/RemoveItemButton';
import { Table } from '../../../../../common/Table/Table';
import { MenuItem } from '../../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';
import { CombatPDModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/parts/combat/CombatPDModel';

type ArsMagnusSelectorProps = {
  combat: CombatPDModel;
};

export const ArsMagnusSelector = observer(
  ({ combat }: ArsMagnusSelectorProps) => {
    const { handleMenuOpen: handleTableMenuOpen, Menu: ArsMagnusOfMenu } =
      useCharacterMenu<ArsMagnusId>();

    const [selectedTable, setSelectedTable] = useState<ArsMagnusModel>();

    const handleAddArsMagnus = () => {
      const unusedArsMagnus = getUnusedArsMagnus(
        combat.arsMagnus.map((a) => a.id).filter(filterUndefined()),
      );

      if (unusedArsMagnus) {
        combat.addArsMagnus(unusedArsMagnus);
      }
    };

    const handleRemoveArsMagnus = (table: ArsMagnusModel) => () => {
      combat.removeArsMagnus(table);
    };

    const arsMagnus = AllArsMagnus.map((a) => ({
      key: a.id,
      name: a.name,
      value: a.id,
      onClick: () => {
        if (selectedTable) {
          combat.replaceArsMagnus(selectedTable, createModelFromArsMagnus(a));
        }
      },
    })) as MenuItem<ArsMagnusId>[];

    return (
      <>
        <Table>
          <Table.Header>
            <Table.Header.Cell $width={5}>Ars Magnus</Table.Header.Cell>
            <Table.Header.Cell>Coste PD</Table.Header.Cell>
            <Table.Header.Cell>Coste CM</Table.Header.Cell>
            <Table.Header.Cell $width={15}>Efecto</Table.Header.Cell>
          </Table.Header>
          {combat.arsMagnus.map((developedArsMagnus, i) => {
            const handleTableSelection = (e: MouseEvent<HTMLDivElement>) => {
              handleTableMenuOpen(developedArsMagnus.id)(e);
              setSelectedTable(developedArsMagnus);
            };

            return (
              <Table.Row key={`${i}-${developedArsMagnus.$modelId}`}>
                <Table.Row.Cell onClick={handleTableSelection}>
                  {developedArsMagnus.name}
                </Table.Row.Cell>
                <Table.Row.Cell>{developedArsMagnus.pdCost}</Table.Row.Cell>
                <Table.Row.Cell>{developedArsMagnus.cmCost}</Table.Row.Cell>
                <Table.Row.Cell>{developedArsMagnus.effect}</Table.Row.Cell>

                <RemoveItemButton
                  onClick={handleRemoveArsMagnus(developedArsMagnus)}
                />
                <ArsMagnusOfMenu items={arsMagnus} />
              </Table.Row>
            );
          })}
        </Table>
        <AddItemButton onClick={handleAddArsMagnus} />
      </>
    );
  },
);
