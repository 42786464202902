import styled from 'styled-components';

export const FabsContainer = styled.div(({ theme: { size, mixins } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',

  gap: size.units(2),

  ...mixins.responsive.md.upward({ display: 'none' }),
}));

export const FabContainer = styled.div(({ theme: { size, color } }) => ({
  borderRadius: '50%',
  width: size.units(4),
  height: size.units(4),

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  padding: size.units(1),

  backgroundColor: color.silver(0.6),
}));

export const Wrapper = styled.div(({ theme: { size, mixins } }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',

  ...mixins.responsive.ipad.downward({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),

  [FabsContainer]: {
    position: 'fixed',
    bottom: size.units(2),
    right: size.units(2),
  },
}));
