import { model } from 'mobx-keystone';
import { PDFieldModel } from '../PDFieldModel';

@model('MartialKnowledgeField')
export class MartialKnowledgePDFieldModel extends PDFieldModel {
  get pdsCost(): number {
    return 5;
  }

  get bonus(): number {
    return 0;
  }

  get bought(): number {
    if (!this.character) return 0;

    return Math.floor((this.base + this.special) / this.pdsCost) * this.pdsCost;
  }

  get perCategory(): number | undefined {
    return this.template.calculateMartialKnowledge();
  }
}
