import { Avatar, Tooltip } from '@mui/material';
import { observer } from 'mobx-react';
import { MouseEvent, useState } from 'react';
import { Character } from '../../../../../domain/character/Character';
import {
  Routes,
  useNavigate,
} from '../../../../../../shared/application/hooks/useNavigate';
import { BasicParagraph } from '../../../../../../shared/application/design-system/components/Texts/Texts.styled';
import {
  Action,
  ActionsContainer,
  AddIcon,
  AvatarContainer,
  CardBody,
  CardHeader,
  CharacterName,
  CharacterToken,
  CharacterTokenContainer,
  CollaborationIcon,
  DeleteIcon,
  DuplicateIcon,
  EditIcon,
  PrimaryCharacteristic,
  PrimaryCharacteristics,
  PrimaryCharacteristicValue,
  SyncIcon,
  ViewIcon,
  Wrapper,
} from './CharacterItem.styled';
import { useDeleteCharacter } from '../../../../../../shared/application/hooks/useDeleteCharacter';
import { useDuplicateCharacter } from '../../../../../../shared/application/hooks/useDuplicateCharacter';
import { Loading } from '../../../../../../shared/application/design-system/components/Loading/Loading';
import { CharacterModel } from '../../../../../domain/character/model/CharacterModel';
import { useConfirmModal } from '../../../../../../shared/application/hooks/useConfirmModal';
import { CharacterIssuesButton } from '../../../CharacterIssuesButton/CharacterIssuesButton';
import { FoundryMessagingService } from '../../../../../../embed/infrastructure/FoundryMessagingService';
import { useCharacterCollaborationModal } from '../../../../../../character-collaboration/application/components/CharacterCollaborationModal/CharacterCollaborationModal';
import { CharacterDragging } from './components/CharacterDragging/CharacterDragging';

interface CharacterItemProps {
  character: Character;

  onDelete: () => void;
  onDuplicate: () => void;

  onCreateSyncRequest: (character: Character) => Promise<void>;
  onUpdateSyncRequest: (character: Character) => Promise<void>;
}

export const CharacterItem = observer(
  ({
    character,

    onDelete,
    onDuplicate,

    onCreateSyncRequest,
    onUpdateSyncRequest,
  }: CharacterItemProps) => {
    const { navigate } = useNavigate();

    const { deleteCharacter, deletingCharacter } = useDeleteCharacter();
    const { duplicateCharacter, duplicatingCharacter } =
      useDuplicateCharacter();

    const { openConfirmModal, ConfirmModal } = useConfirmModal();
    const { openCharacterCollaborationModal, CharacterCollaborationModal } =
      useCharacterCollaborationModal();

    const [syncing, setSyncing] = useState(false);

    const handleCreateSyncRequest = async (e: MouseEvent) => {
      e.stopPropagation();

      if (syncing) return;

      setSyncing(true);

      try {
        await onCreateSyncRequest(character);
      } finally {
        setSyncing(false);
      }
    };

    const handleUpdateSyncRequest = async (e: MouseEvent) => {
      e.stopPropagation();

      if (syncing) return;

      setSyncing(true);

      try {
        await onUpdateSyncRequest(character);
      } finally {
        setSyncing(false);
      }
    };

    const handleCharacterClick = (e: MouseEvent) => {
      e.stopPropagation();

      if (character.id) {
        if (character.canBeEdited) {
          navigate(Routes.EditCharacter, {
            params: { characterId: character.id.toString() },
          });
        } else {
          navigate(Routes.ViewCharacter, {
            params: { characterId: character.id.toString() },
          });
        }
      }
    };

    const handleCharacterViewClick = (e: MouseEvent) => {
      e.stopPropagation();

      if (character.id) {
        navigate(Routes.ViewCharacter, {
          params: { characterId: character.id.toString() },
        });
      }
    };

    const handleCharacterRemoveClick = async (e: MouseEvent) => {
      e.stopPropagation();

      const confirm = await openConfirmModal({
        title: '¿Lo confirmas?',
        content: 'Esta acción no se puede deshacer',
      });

      if (!confirm) return;

      deleteCharacter(character).then(() => {
        onDelete();
      });
    };

    const handleCharacterDuplicateClick = async (e: MouseEvent) => {
      e.stopPropagation();

      const confirm = await openConfirmModal({
        title: 'Duplicar personaje',
        content: 'Esta acción duplicará tu personaje. No se puede deshacer',
      });

      if (!confirm) return;

      duplicateCharacter(character).then(() => {
        onDuplicate();
      });
    };

    const handleCharacterCollaborateClick = async (e: MouseEvent) => {
      e.stopPropagation();

      openCharacterCollaborationModal(character);
    };

    const $image = character.content.tokenUrl ?? CharacterModel.defaultTokenUrl;

    const characterIssues = character.content.issues;

    return (
      <>
        <CharacterDragging characterId={character.id} token={$image}>
          {({ isDragging }) => (
            <Wrapper $isBeingDragged={isDragging}>
              <CharacterTokenContainer onClick={handleCharacterClick}>
                <CharacterToken $image={$image} />
              </CharacterTokenContainer>
              <CardHeader>
                <CharacterName onClick={handleCharacterClick}>
                  {character.content.name}
                </CharacterName>
                {characterIssues.length > 0 ? (
                  <CharacterIssuesButton
                    character={character}
                    onClick={(e) => e.stopPropagation()}
                  />
                ) : undefined}
              </CardHeader>
              <CardBody onClick={handleCharacterClick}>
                <BasicParagraph>
                  {character.content.categories.map((c, index) => {
                    const isLast =
                      index === character.content.categories.length - 1;

                    let content = `<b>${c.template.name}</b> (${c.level.final})`;

                    if (!isLast) {
                      content += ' / ';
                    }

                    return (
                      <span
                        key={c.category}
                        dangerouslySetInnerHTML={{
                          __html: content,
                        }}
                      />
                    );
                  })}
                </BasicParagraph>
                <PrimaryCharacteristics>
                  <PrimaryCharacteristic>
                    <b>Fuerza</b>
                    <PrimaryCharacteristicValue>
                      {character.content.primaries.strength.final}
                    </PrimaryCharacteristicValue>
                  </PrimaryCharacteristic>
                  <PrimaryCharacteristic>
                    <b>Agilidad</b>
                    <PrimaryCharacteristicValue>
                      {character.content.primaries.agility.final}
                    </PrimaryCharacteristicValue>
                  </PrimaryCharacteristic>
                  <PrimaryCharacteristic>
                    <b>Destreza</b>
                    <PrimaryCharacteristicValue>
                      {character.content.primaries.dexterity.final}
                    </PrimaryCharacteristicValue>
                  </PrimaryCharacteristic>
                  <PrimaryCharacteristic>
                    <b>Constitución</b>
                    <PrimaryCharacteristicValue>
                      {character.content.primaries.constitution.final}
                    </PrimaryCharacteristicValue>
                  </PrimaryCharacteristic>
                  <PrimaryCharacteristic>
                    <b>Percepción</b>
                    <PrimaryCharacteristicValue>
                      {character.content.primaries.perception.final}
                    </PrimaryCharacteristicValue>
                  </PrimaryCharacteristic>
                  <PrimaryCharacteristic>
                    <b>Inteligencia</b>
                    <PrimaryCharacteristicValue>
                      {character.content.primaries.intelligence.final}
                    </PrimaryCharacteristicValue>
                  </PrimaryCharacteristic>
                  <PrimaryCharacteristic>
                    <b>Poder</b>
                    <PrimaryCharacteristicValue>
                      {character.content.primaries.power.final}
                    </PrimaryCharacteristicValue>
                  </PrimaryCharacteristic>
                  <PrimaryCharacteristic>
                    <b>Voluntad</b>
                    <PrimaryCharacteristicValue>
                      {character.content.primaries.willPower.final}
                    </PrimaryCharacteristicValue>
                  </PrimaryCharacteristic>
                </PrimaryCharacteristics>
              </CardBody>
              <ActionsContainer>
                {FoundryMessagingService.isActive.get() ? (
                  <>
                    <Action>
                      {syncing ? (
                        <Loading size={1} color="white" />
                      ) : (
                        <Tooltip title="Crear nuevo actor">
                          <AddIcon onClick={handleCreateSyncRequest} />
                        </Tooltip>
                      )}
                    </Action>
                    <Action>
                      {syncing ? (
                        <Loading size={1} color="white" />
                      ) : (
                        <Tooltip title="Sincronizar con actor">
                          <SyncIcon onClick={handleUpdateSyncRequest} />
                        </Tooltip>
                      )}
                    </Action>
                  </>
                ) : undefined}
                <Action>
                  {character.canBeEdited ? (
                    <EditIcon onClick={handleCharacterClick} />
                  ) : (
                    <ViewIcon onClick={handleCharacterViewClick} />
                  )}
                </Action>
                {character.isMine ? (
                  <Action>
                    {duplicatingCharacter ? (
                      <Loading size={1} color="white" />
                    ) : (
                      <DuplicateIcon onClick={handleCharacterDuplicateClick} />
                    )}
                  </Action>
                ) : undefined}
                {character.isMine ? (
                  <Action>
                    <>
                      <CollaborationIcon
                        $highlight={character.isCollaborative}
                        onClick={handleCharacterCollaborateClick}
                      />
                      <CharacterCollaborationModal />
                    </>
                  </Action>
                ) : undefined}
                {character.isMine ? (
                  <Action>
                    {deletingCharacter ? (
                      <Loading size={1} color="white" />
                    ) : (
                      <DeleteIcon onClick={handleCharacterRemoveClick} />
                    )}
                  </Action>
                ) : undefined}
              </ActionsContainer>
              {character.isCharacterHit() && !character.isMine ? (
                <AvatarContainer>
                  <Avatar
                    src={character.user.avatarUrl}
                    sx={{ width: '2rem', height: '2rem' }}
                  />
                  <BasicParagraph>{character.user.username}</BasicParagraph>
                </AvatarContainer>
              ) : undefined}
            </Wrapper>
          )}
        </CharacterDragging>
        <ConfirmModal />
      </>
    );
  },
);
