import { model } from 'mobx-keystone';
import { BaseFinalFieldModel } from '../../../utils/fields/BaseFinalFieldModel';
import { Calculation } from '../../../utils/fields/NumberField';
import {
  Daimah,
  Devah,
  Jayan,
  NephilimDaimah,
  NephilimJayan,
} from '../../../../../aggregations/nephilim-type/Nephilim';

@model('Character/Aspect/SizeField')
export class SizeFieldModel extends BaseFinalFieldModel {
  getCalculations(): Calculation[] {
    if (!this.character) return [];

    const calculations: Calculation[] = [];

    calculations.push(this.sum('Base', this.base));

    calculations.push(this.sum('Especial', this.special));

    calculations.push(
      this.sum(
        'Característica de Fuerza',
        this.character.primaries.strength.final,
      ),
    );

    calculations.push(
      this.sum(
        'Característica de Constitución',
        this.character.primaries.constitution.final,
      ),
    );

    if (this.character.isNephilim(Jayan)) {
      calculations.push(this.sum(Jayan.name, 2));
    }

    if (this.character.isNephilim(NephilimJayan)) {
      calculations.push(this.sum(NephilimJayan.name, 2));
    }

    if (this.character.isNephilim(Devah)) {
      calculations.push(this.sum(Devah.name, 2));
    }

    if (this.character.isNephilim(Daimah)) {
      calculations.push(this.sum(Daimah.name, -1));
    }

    if (this.character.isNephilim(NephilimDaimah)) {
      calculations.push(this.sum(NephilimDaimah.name, -1));
    }

    return calculations;
  }
}
