import { ExtendedModel, model, prop } from 'mobx-keystone';
import { SecondarySkill } from '../../../../../../../types/SecondarySkill';
import { SecondarySkillPDFieldModel } from '../SecondarySkillPDFieldModel';
import { SecondarySkillBonusType } from '../SecondarySkillPDFieldModel.types';
import { SecondarySkillsPDModel } from './SecondarySkillsPDModel';

@model('Character/Development/SecondarySkills/AthleticSkillsPD')
export class AthleticSkillsPDModel extends ExtendedModel(
  SecondarySkillsPDModel,
  {
    acrobatics: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Acrobatics,
          type: SecondarySkillBonusType.Agility,
        }),
    ),
    athleticism: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Athleticism,
          type: SecondarySkillBonusType.Agility,
        }),
    ),
    ride: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Ride,
          type: SecondarySkillBonusType.Agility,
        }),
    ),
    swim: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Swim,
          type: SecondarySkillBonusType.Agility,
        }),
    ),
    climb: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Climb,
          type: SecondarySkillBonusType.Agility,
        }),
    ),
    jump: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Jump,
          type: SecondarySkillBonusType.Strength,
        }),
    ),
    piloting: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Piloting,
          type: SecondarySkillBonusType.Dexterity,
        }),
    ),
  },
) {
  get fields(): SecondarySkillPDFieldModel[] {
    return [
      this.acrobatics,
      this.athleticism,
      this.ride,
      this.swim,
      this.climb,
      this.jump,
      this.piloting,
    ];
  }
}
