import { observer } from 'mobx-react';
import { Section } from '../../../../common/Section/Section';
import {
  BackgroundAdvantageSelector,
  BloodLegacyAdvantageSelector,
  CommonAdvantageSelector,
  DonAdvantageSelector,
  PsychicAdvantageSelector,
} from './components/AdvantageSelector/AdvantageSelector';

export const Advantages = observer(() => {
  return (
    <Section>
      <Section.Title>Ventajas</Section.Title>
      <Section.Subtitle>Comunes</Section.Subtitle>
      <CommonAdvantageSelector />

      <Section.Divider />

      <Section.Subtitle>Don</Section.Subtitle>
      <DonAdvantageSelector />

      <Section.Divider />

      <Section.Subtitle>Psíquicos</Section.Subtitle>
      <PsychicAdvantageSelector />

      <Section.Divider />

      <Section.Subtitle>Trasfondo</Section.Subtitle>
      <BackgroundAdvantageSelector />

      <Section.Divider />

      <Section.Subtitle>Legados de Sangre</Section.Subtitle>
      <BloodLegacyAdvantageSelector />
    </Section>
  );
});
