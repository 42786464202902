import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { AuthorizationPage } from '../../auth/application/AuthorizationPage/AuthorizationPage';
import { CharacterPage } from '../../character/application/pages/CharacterPage/CharacterPage';
import { CharactersPage } from '../../character/application/pages/CharactersPage/CharactersPage';
import { HowToImportPage } from '../../faq/application/pages/HowToImportPage/HowToImportPage';
import { CharactersRoomPage } from '../../character/application/pages/CharactersRoomPage/CharactersRoomPage';
import { TrackPageView } from '../../shared/application/components/TrackPageView/TrackPageView';
import { MyProfilePage } from '../../user/application/pages/MyProfilePage/MyProfilePage';
import { NewsPage } from '../../news/aplication/pages/News/NewsPage';
import { BaseRoutes } from './Routes';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        path={BaseRoutes.Auth}
        element={
          <TrackPageView>
            <AuthorizationPage />
          </TrackPageView>
        }
      />
      <Route
        path={BaseRoutes.MyCharacters}
        element={
          <TrackPageView>
            <CharactersPage />
          </TrackPageView>
        }
      >
        <Route
          path=":folderId"
          element={
            <TrackPageView>
              <CharactersPage />
            </TrackPageView>
          }
        />
      </Route>
      <Route path={BaseRoutes.EditCharacter}>
        <Route
          path=":characterId"
          element={
            <TrackPageView>
              <CharacterPage />
            </TrackPageView>
          }
        />
      </Route>
      <Route path={BaseRoutes.ViewCharacter}>
        <Route
          path=":characterId"
          element={
            <TrackPageView>
              <CharacterPage />
            </TrackPageView>
          }
        />
      </Route>
      <Route
        path={BaseRoutes.CharactersRoom}
        element={
          <TrackPageView>
            <CharactersRoomPage />
          </TrackPageView>
        }
      />
      <Route
        path={BaseRoutes.MyProfile}
        element={
          <TrackPageView>
            <MyProfilePage />
          </TrackPageView>
        }
      />
      <Route
        path={BaseRoutes.HowToImport}
        element={
          <TrackPageView>
            <HowToImportPage />
          </TrackPageView>
        }
      />
      <Route
        path={BaseRoutes.News}
        element={
          <TrackPageView>
            <NewsPage />
          </TrackPageView>
        }
      />
      <Route index element={<Navigate to={BaseRoutes.Auth} replace />} />
      <Route path="*" element={<Navigate to={BaseRoutes.Auth} replace />} />
    </Route>,
  ),
);

export const Router = () => {
  return <RouterProvider router={router} />;
};
