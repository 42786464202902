import styled from 'styled-components';

export const TabsContainer = styled.div<{ $centered?: boolean }>(
  ({ $centered }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '1.5rem',
    width: '100%',

    justifyContent: $centered ? 'center' : 'flex-start',

    boxSizing: 'border-box',
  }),
);
