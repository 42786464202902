import { BasicParagraph } from '../../../../../../../../shared/application/design-system/components/Texts/Texts.styled';
import { Wrapper } from './EmptyCharacterCollaborationsList.styled';

export const EmptyCharacterCollaborationList = () => {
  return (
    <Wrapper>
      <BasicParagraph>Nadie te ha compartido un personaje</BasicParagraph>
    </Wrapper>
  );
};
