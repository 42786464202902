import { model } from 'mobx-keystone';
import {
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
} from '../../../../aggregations/advantage/Advantage.types';
import { HardToKillAdvantage } from '../../../../aggregations/advantage/CommonAdvantages';
import { DevelopedPDFieldModel } from '../../utils/fields/DevelopedPDFieldModel';
import { getTotalOf } from '../../utils/getTotalOf';

@model('Character/Life')
export class LifeModel extends DevelopedPDFieldModel {
  get fromCategories(): number {
    let value = getTotalOf(this.character, (c) => c.lifePoints.final);

    if (
      this.character?.creationPoints.containsAdvantage(HardToKillAdvantage, {
        level: AdvantageLevel1,
      })
    ) {
      value += 10 * this.character?.level;
    }

    if (
      this.character?.creationPoints.containsAdvantage(HardToKillAdvantage, {
        level: AdvantageLevel2,
      })
    ) {
      value += 20 * this.character?.level;
    }

    if (
      this.character?.creationPoints.containsAdvantage(HardToKillAdvantage, {
        level: AdvantageLevel3,
      })
    ) {
      value += 30 * this.character?.level;
    }

    return Math.max(5, value);
  }

  get allActionsPenalty(): number {
    return 0;
  }

  get primariesBonus(): number {
    if (!this.character) return 0;

    const constitution = this.character.primaries.constitution;

    return 20 + constitution.final * 10 + constitution.modifier.final;
  }
}
