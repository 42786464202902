import { Model, model, prop } from 'mobx-keystone';

@model('Character/Development/CreationPoints/PrimaryFieldsCreationPointBonuses')
export class PrimaryFieldsCreationPointBonusesModel extends Model({
  agility: prop<number>(0).withSetter(),
  dexterity: prop<number>(0).withSetter(),
  perception: prop<number>(0).withSetter(),
  strength: prop<number>(0).withSetter(),
  constitution: prop<number>(0).withSetter(),
  intelligence: prop<number>(0).withSetter(),
  power: prop<number>(0).withSetter(),
  willPower: prop<number>(0).withSetter(),
}) {}
