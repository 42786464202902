export enum SecondarySkill {
  // Athletic
  Acrobatics = 'acrobatics',
  Athleticism = 'athleticism',
  Ride = 'ride',
  Swim = 'swim',
  Climb = 'climb',
  Jump = 'jump',
  Piloting = 'piloting',

  // Social
  Style = 'style',
  Intimidate = 'intimidate',
  Leadership = 'leadership',
  Persuasion = 'persuasion',
  Trading = 'trading',
  Streetwise = 'streetwise',
  Etiquette = 'etiquette',

  // Perception
  Notice = 'notice',
  Search = 'search',
  Track = 'track',

  // Intellectual
  Animals = 'animals',
  Science = 'science',
  Law = 'law',
  Herbalism = 'herbalism',
  History = 'history',
  Tactics = 'tactics',
  Medicine = 'medicine',
  Memorize = 'memorize',
  Navigation = 'navigation',
  Occultism = 'occultism',
  Appraisal = 'appraisal',
  MagicAppraisal = 'magicAppraisal',

  // Vigor
  Composure = 'composure',
  FeatsOfStrength = 'featsOfStrength',
  WithstandPain = 'withstandPain',

  // Subterfuge
  LockPicking = 'lockPicking',
  Disguise = 'disguise',
  Hide = 'hide',
  Steal = 'steal',
  Stealth = 'stealth',
  Traps = 'traps',
  Poisons = 'poisons',

  // Creative
  Art = 'art',
  Dance = 'dance',
  Forging = 'forging',
  Runes = 'runes',
  Alchemy = 'alchemy',
  Animism = 'animism',
  Music = 'music',
  SleightOfHand = 'sleightOfHand',
  RitualCalligraphy = 'ritualCalligraphy',
  Jewelry = 'jewelry',
  Tailoring = 'tailoring',
  PuppetMaking = 'puppetMaking',
}

export enum SecondarySkillCategory {
  Athletic = 'athletic',
  Creative = 'creative',
  Intellectual = 'intellectual',
  Perception = 'perception',
  Social = 'social',
  Subterfuge = 'subterfuge',
  Vigor = 'vigor',
}

export const secondarySkillCategories: Record<
  SecondarySkillCategory,
  SecondarySkill[]
> = {
  [SecondarySkillCategory.Athletic]: [
    SecondarySkill.Acrobatics,
    SecondarySkill.Athleticism,
    SecondarySkill.Climb,
    SecondarySkill.Jump,
    SecondarySkill.Piloting,
    SecondarySkill.Ride,
    SecondarySkill.Swim,
  ],
  [SecondarySkillCategory.Creative]: [
    SecondarySkill.Alchemy,
    SecondarySkill.Animism,
    SecondarySkill.Art,
    SecondarySkill.Dance,
    SecondarySkill.Forging,
    SecondarySkill.Jewelry,
    SecondarySkill.Music,
    SecondarySkill.PuppetMaking,
    SecondarySkill.RitualCalligraphy,
    SecondarySkill.Runes,
    SecondarySkill.SleightOfHand,
    SecondarySkill.Tailoring,
  ],
  [SecondarySkillCategory.Intellectual]: [
    SecondarySkill.Animals,
    SecondarySkill.Appraisal,
    SecondarySkill.Herbalism,
    SecondarySkill.History,
    SecondarySkill.Law,
    SecondarySkill.MagicAppraisal,
    SecondarySkill.Medicine,
    SecondarySkill.Memorize,
    SecondarySkill.Navigation,
    SecondarySkill.Occultism,
    SecondarySkill.Science,
    SecondarySkill.Tactics,
  ],
  [SecondarySkillCategory.Perception]: [
    SecondarySkill.Notice,
    SecondarySkill.Search,
    SecondarySkill.Track,
  ],
  [SecondarySkillCategory.Social]: [
    SecondarySkill.Etiquette,
    SecondarySkill.Intimidate,
    SecondarySkill.Leadership,
    SecondarySkill.Persuasion,
    SecondarySkill.Streetwise,
    SecondarySkill.Style,
    SecondarySkill.Trading,
  ],
  [SecondarySkillCategory.Subterfuge]: [
    SecondarySkill.Disguise,
    SecondarySkill.Hide,
    SecondarySkill.LockPicking,
    SecondarySkill.Poisons,
    SecondarySkill.Stealth,
    SecondarySkill.Steal,
    SecondarySkill.Traps,
  ],
  [SecondarySkillCategory.Vigor]: [
    SecondarySkill.Composure,
    SecondarySkill.FeatsOfStrength,
    SecondarySkill.WithstandPain,
  ],
};

export const getCategoryForSecondarySkill = (
  secondarySkill: SecondarySkill,
): SecondarySkillCategory => {
  const category = Object.entries(secondarySkillCategories).find(([, skills]) =>
    skills.includes(secondarySkill),
  )?.[0] as SecondarySkillCategory;

  if (!category) {
    throw new Error(`No category found for secondary skill ${secondarySkill}`);
  }

  return category;
};
