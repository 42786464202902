import { ApiErrorCode } from './ApiErrorCode';
import { isAxiosError } from 'axios';

export type ApiError = {
  statusCode: number;
  message: string;
  error: ApiErrorCode;
};

export const decodeApiErrorMessage = (error: any): string => {
  const err = isAxiosError(error) ? error.response?.data : error;

  if ('error' in err) {
    const apiError = err as ApiError;

    switch (apiError.error) {
      case ApiErrorCode.UserNotFound:
        return 'Usuario no encontrado';
      case ApiErrorCode.UnauthorizedCharacterAccess:
        return 'No tienes permisos para acceder a este personaje';
      case ApiErrorCode.CollaboratorNotFound:
        return 'Colaborador no encontrado';
      case ApiErrorCode.CharacterNotFound:
        return 'Personaje no encontrado';
      case ApiErrorCode.CharacterCollaborationAlreadyExists:
        return 'Ya tienes acceso a este personaje';

      default:
        return 'Error desconocido';
    }
  } else {
    return 'Error desconocido';
  }
};
