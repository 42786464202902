export const color = {
  primaryLight: (alpha = 1) => `rgba(209,45,5, ${alpha})`,
  primary: (alpha = 1) => `rgba(116,28,3, ${alpha})`,
  primaryDark: (alpha = 1) => `rgba(80,10,3, ${alpha})`,

  secondary: (alpha = 1) => `rgba(187,85,56, ${alpha})`,
  tertiary: (alpha = 1) => `rgba(223,128,101, ${alpha})`,

  black: (alpha = 1) => `rgba(49,46,46, ${alpha})`,

  lightGray: (alpha = 1) => `rgba(169,169,169, ${alpha})`,
  gray: (alpha = 1) => `rgba(105,105,105, ${alpha})`,
  darkGray: (alpha = 1) => `rgba(90,90,90, ${alpha})`,

  silver: (alpha = 1) => `rgba(192,192,192, ${alpha})`,

  whiteSmoke: (alpha = 1) => `rgba(245,245,245, ${alpha})`,

  white: (alpha = 1) => `rgba(247,250,250, ${alpha})`,

  error: (alpha = 1) => `rgba(255,0,0, ${alpha})`,
};
