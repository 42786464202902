export type ArsMagnusId = string;

export type ArsMagnus = {
  id: ArsMagnusId;

  name: string;

  pdCost: number;

  cmCost: number;

  effect: string;
};

export const FinalAttackArsMagnus: ArsMagnus = {
  id: '1',

  name: 'Ataque Final',

  pdCost: 30,

  cmCost: 10,

  effect:
    'Permite realizar un Ataque con +20 y cada Cansancio  añade +20 en lugar de +15. Sólo una vez al día y si los PVs son menos de la mitad',
};

export const BerserkerArsMagnus: ArsMagnus = {
  id: '2',

  name: 'Berserker',

  pdCost: 30,

  cmCost: 10,

  effect:
    ' +10 al Ataque, -20 a Defensa e ignora penalizador por Dolor o Cansancio. Chequeo de VOL para dejar de luchar (-2 si está a 1/4 de PVs)',
};

export const GuardianArsMagnus: ArsMagnus = {
  id: '3',

  name: 'Guardián',

  pdCost: 40,

  cmCost: 10,

  effect:
    'No hace falta tener acción para proteger a otro personaje. Penalizador por defender a otro pasa a -10',
};

export const KiaiArsMagnus: ArsMagnus = {
  id: '4',

  name: 'Kiai',

  pdCost: 10,

  cmCost: 10,

  effect:
    'Añade +10 al Daño de Artes Marciales y permite atacar energía. Consume 1 de Ki y sólo puede usarse cada 5 asaltos o consume Cansancio',
};

export const SpecialManeuverArsMagnus: ArsMagnus = {
  id: '5',

  name: 'Maniobra Especial',

  pdCost: 30,

  cmCost: 10,

  effect: 'Bono especial condicionado de +20 al Ataque',
};

export const YuuseBatojutsuArsMagnus: ArsMagnus = {
  id: '6',

  name: 'Yuuse Batojutsu',

  pdCost: 20,

  cmCost: 10,

  effect:
    ' +10 a Ataque y Turno por cada asalto con el arma enfundada y concentrándose (Max +30). No puede atacar ni desenfundar pero sí defenderse y acumular Ki',
};

export const AgnitumAbsoluteEyeArsMagnus: ArsMagnus = {
  id: '7',

  name: 'Agnitum: Ojo Absoluto',

  pdCost: 30,

  cmCost: 30,

  effect:
    'Permite desplazar los sentidos a objetos disparados o lanzados y controlarlos a distancia',
};

export const AshuriamDimensionalAssaultArsMagnus: ArsMagnus = {
  id: '8',

  name: 'Ashuriam: Asalto Dimensional',

  pdCost: 80,

  cmCost: 80,

  effect:
    'Permite cambiar de dimensión y atacar a través de las rendijas entre los dos mundos',
};

export const ChaosMeisterChaosControlArsMagnus: ArsMagnus = {
  id: '9',

  name: 'Caos Meister: Control del Caos',

  pdCost: 80,

  cmCost: 100,

  effect: 'Alteración Existencial, Dualidad del Destino, Null Tempus',
};

export const EtherialInfiniteAttacksArsMagnus: ArsMagnus = {
  id: '10',

  name: 'Etherial: Ataques Infinitos',

  pdCost: 40,

  cmCost: 40,

  effect: 'Crítico Seguro, Ataque Absoluto, Mil Golpes, Sello de Poder',
};

export const HaimaShadowOfBloodArsMagnus: ArsMagnus = {
  id: '11',

  name: 'Haima: Sombra de Sangre',

  pdCost: 30,

  cmCost: 30,

  effect: 'Permite crear dobles instantáneos que absorben el daño recibido',
};

export const InfiniumFluctuationOfSpaceArsMagnus: ArsMagnus = {
  id: '12',

  name: 'Infinium: Fluctuación del Espacio',

  pdCost: 40,

  cmCost: 40,

  effect: 'Permite el movimiento instantáneo para obtener Flanco o Espalda',
};

export const MagnumReleaseOfPowerArsMagnus: ArsMagnus = {
  id: '13',

  name: 'Magnum: Liberación de Poder',

  pdCost: 30,

  cmCost: 50,

  effect:
    'Permite concentrar energía (de 1 a 5 asaltos) para un ataque potenciado (+20 HA/asalto). -20 Defensa en concentración',
};

export const MatrixExistentialDivisionArsMagnus: ArsMagnus = {
  id: '14',

  name: 'Matrix: División Existencial',

  pdCost: 50,

  cmCost: 50,

  effect: 'Permite crear dobles perfectos del usuario',
};

export const MundusControlOftheEnvironmentArsMagnus: ArsMagnus = {
  id: '15',

  name: 'Mundus: Control del Entorno',

  pdCost: 30,

  cmCost: 50,

  effect: 'Control del entorno como arma, Usos especializados',
};

export const RaikouLightningBladeArsMagnus: ArsMagnus = {
  id: '16',

  name: 'Raikou: Hoja Relámpago',

  pdCost: 60,

  cmCost: 60,

  effect:
    'Permite encadenar ataques obteniendo bonificadores por cada ataque exitoso',
};

export const RemTheEyesOfDestinyArsMagnus: ArsMagnus = {
  id: '17',

  name: 'Rem: Los Ojos del Destino',

  pdCost: 40,

  cmCost: 60,

  effect:
    'Permite obtener bonificadores tras estudiar ataques y técnicas de los oponentes',
};

export const SamielFinalRetributionArsMagnus: ArsMagnus = {
  id: '18',

  name: 'Samiel: Retribución Final',

  pdCost: 60,

  cmCost: 60,

  effect:
    'Permite obtener bonificadores contra un oponente tras haber sido dañado por él',
};

export const ShinkyouTheMirrorPositionArsMagnus: ArsMagnus = {
  id: '19',

  name: 'Shinkyou: La Posición Espejo',

  pdCost: 60,

  cmCost: 80,

  effect:
    'La Posición Espejo: Bono de +50 a Defensa y Contraataque automático durante 5 asaltos. Sin Acciones activas',
};

export const SummunEnergyAccumulationArsMagnus: ArsMagnus = {
  id: '20',

  name: 'Summun: Acumulación de Energía',

  pdCost: 30,

  cmCost: 30,

  effect:
    'Permite atacar a distancia deformando el espacio entre los luchadores',
};

export const TransformationTheAwakeningArsMagnus: ArsMagnus = {
  id: '21',

  name: 'Transformación: El Despertar',

  pdCost: 50,

  cmCost: 50,

  effect:
    'Transformación que otorga el bono de POD a toda acción y un bono variable a las Acumulaciones de Ki. Concede Zen',
};

export const TransformationEliodonArsMagnus: ArsMagnus = {
  id: '22',

  name: 'Transformación: Eliodón',

  pdCost: 20,

  cmCost: 40,

  effect: 'Transformación temporal obteniendo Poderes de Criatura adicionales',
};

export const TransformationTheDragonSealsArsMagnus: ArsMagnus = {
  id: '23',

  name: 'Transformación: Los Sellos del Dragón',

  pdCost: 20,

  cmCost: 50,

  effect: 'Sellos del Dragón, Las Puertas',
};

export const TransformationPhysicalPowerArsMagnus: ArsMagnus = {
  id: '24',

  name: 'Transformación: Poder Físico',

  pdCost: 30,

  cmCost: 50,

  effect: 'Primer Estado, Segundo Estado',
};

export const TransformationRuptureOfDestinyArsMagnus: ArsMagnus = {
  id: '25',

  name: 'Transformación: Ruptura del Destino',

  pdCost: 100,

  cmCost: 100,

  effect:
    'Transformación que permite obtener un +100 a la actividad, que decae en -10 por turno hasta -100',
};

export const UmbraShadowAttackArsMagnus: ArsMagnus = {
  id: '26',

  name: 'Umbra: Ataque de Sombras',

  pdCost: 30,

  cmCost: 50,

  effect: 'Control de la propia sombra como arma',
};

export const AquariusCapesandGarmentsArsMagnus: ArsMagnus = {
  id: '27',

  name: 'Aquarius: Capas y prendas de vestir',

  pdCost: 20,

  cmCost: 20,

  effect: 'Uso de Aquarius, Escudo contra proyectiles, Posición defensiva',
};

export const AriesMassificationOfArmsAndChainsArsMagnus: ArsMagnus = {
  id: '28',

  name: 'Aries: Masificación armas y cadenas',

  pdCost: 40,

  cmCost: 40,

  effect: 'Uso de Aries, Elección de armas, Ataque masivo ',
};

export const CancerMagisterMonofilosArsMagnus: ArsMagnus = {
  id: '29',

  name: 'Cáncer Magister: Monofilos',

  pdCost: 30,

  cmCost: 30,

  effect:
    'Sin penalizador por Presa, Visibilidad menor, Perforación incrementada, Imparable, Ataque en área a todos los enemigos a 10m de radio',
};

export const CancerMonophylesArsMagnus: ArsMagnus = {
  id: '30',

  name: 'Cáncer: Monofilos',

  pdCost: 40,

  cmCost: 20,

  effect:
    'Uso de Cáncer, Casi invisible, Presa mitad penalizador, Perforadora, Parada -10',
};

export const CapricorniusNeedlesArsMagnus: ArsMagnus = {
  id: '31',

  name: 'Capricornius: Agujas',

  pdCost: 20,

  cmCost: 20,

  effect:
    'Uso de Capricornius, Crítico incrementado +20, Daño completo para calculo de Crítico',
};

export const GeminiPuppetsArsMagnus: ArsMagnus = {
  id: '32',

  name: 'Gémini: Marionetas',

  pdCost: 50,

  cmCost: 50,

  effect: 'Uso de Marionetas de combate, Ver reglas',
};

export const LeoWeaponspistolArsMagnus: ArsMagnus = {
  id: '33',

  name: 'Leo: Armas-pistola',

  pdCost: 20,

  cmCost: 10,

  effect: 'Uso de Leo, Ataque combinado',
};

export const LibraVariableWeaponArsMagnus: ArsMagnus = {
  id: '34',

  name: 'Libra: Arma variable',

  pdCost: 30,

  cmCost: 20,

  effect: 'Uso de Libra, Capacidades variables',
};

export const OphiucosLordOfInfiniteSwordsArsMagnus: ArsMagnus = {
  id: '35',

  name: 'Ophiucos: Señor de Espadas Infinitas',

  pdCost: 50,

  cmCost: 100,

  effect:
    'Uso de Ophiucos, Liberación absoluta, Liberación en área, Uso sellado, Combinación con Ki',
};

export const PiscesYoyoArsMagnus: ArsMagnus = {
  id: '36',

  name: 'Piscis: Yoyó',

  pdCost: 10,

  cmCost: 20,

  effect: 'Uso de Piscis, Cuchillas (opcional), Ataque en área (5m)',
};

export const SagittariusMagisterThrownObjectsArsMagnus: ArsMagnus = {
  id: '37',

  name: 'Sagittarius Magister: Obj. arrojadizos',

  pdCost: 30,

  cmCost: 30,

  effect:
    'Escoger 2 (o 3 con especialización): Destructor, Penetrante, Área, Precisión absoluta, Exterminador, Quebrantador de escudos',
};

export const SagittariusThrownObjectsArsMagnus: ArsMagnus = {
  id: '38',

  name: 'Sagittarius: Objetos arrojadizos',

  pdCost: 40,

  cmCost: 30,

  effect: 'Uso de Sagittarius, Proyectiles disparados',
};

export const ScorpioSwordwhipArsMagnus: ArsMagnus = {
  id: '39',

  name: 'Scorpio: Espada-látigo',

  pdCost: 10,

  cmCost: 10,

  effect:
    'Uso de Scorpio, Forma dual, Presa con Daño completo, Torbellino envolvente',
};

export const TaurusHugeDoubleArmsArsMagnus: ArsMagnus = {
  id: '40',

  name: 'Taurus: Enormes armas dobles',

  pdCost: 20,

  cmCost: 30,

  effect:
    'Uso de Taurus, Lanzamiento en arco, Parar el ataque, Recoger el arma',
};

export const VirgoStringInstrumentsArsMagnus: ArsMagnus = {
  id: '41',

  name: 'Virgo: Instrumentos de cuerda',

  pdCost: 50,

  cmCost: 50,

  effect:
    'Uso de Virgo, Daño por resonancia, Ignora protecciones, Ataque sónico, Ataque en área -10 (5m), Ataque único, Resonancia, Cáncer reducido',
};

export const VirgoPercussionInstrumentsArsMagnus: ArsMagnus = {
  id: '42',

  name: 'Virgo: Instrumentos de percusión',

  pdCost: 50,

  cmCost: 50,

  effect:
    'Uso de Virgo, Daño por resonancia, Ignora protecciones, Ataque sónico, Ataque en área -10 (5m), Ataque único, Impacto FUE 12',
};

export const VirgoLargeWindInstrumentsArsMagnus: ArsMagnus = {
  id: '43',

  name: 'Virgo: Instrumentos grandes de viento',

  pdCost: 40,

  cmCost: 40,

  effect: '',
};

export const VirgoSmallWindInstrumentsArsMagnus: ArsMagnus = {
  id: '44',

  name: 'Virgo: Instrumentos pequeños de viento',

  pdCost: 50,

  cmCost: 50,

  effect:
    'Uso de Virgo, Daño por resonancia, Ignora protecciones, Ataque sónico, Ataque en área -10 (5m), Ataque único, Fascinación',
};

export const RangedAttackArsMagnus: ArsMagnus = {
  id: '48',

  name: 'Ataque a distancia',

  pdCost: 20,

  cmCost: 20,

  effect:
    'Ver poder requerido. Permite proyectar intensidades de energía a distancia. Consumo: 1 Ki o 10 Zeón. Ver tiempo de reutilización',
};

export const CatchArsMagnus: ArsMagnus = {
  id: '49',

  name: 'Atrapar',

  pdCost: 20,

  cmCost: 10,

  effect:
    'Ver poder requerido. Permite considerar que el arma tiene Presa o si la tiene sólo -10 de penalizador',
};

export const RemotecontrolArsMagnus: ArsMagnus = {
  id: '50',

  name: 'Controlar a distancia',

  pdCost: 20,

  cmCost: 20,

  effect:
    'Ver poder requerido. Aplica sólo un -20 a su Ataque y Defensa, para luchar con una arma movida a distancia',
};

export const ProtectiveShieldArsMagnus: ArsMagnus = {
  id: '51',

  name: 'Escudo protector',

  pdCost: 10,

  cmCost: 10,

  effect:
    'Ver poder requerido. Crea un escudo elemental para bloquear ataques a distancia',
};

export const DamageIncreaseArsMagnus: ArsMagnus = {
  id: '52',

  name: 'Incremento de daño',

  pdCost: 20,

  cmCost: 20,

  effect: 'Ver poder requerido. Permite incrementar el Daño',
};

export const AcceleratedmMvementArsMagnus: ArsMagnus = {
  id: '53',

  name: 'Movimiento acelerado',

  pdCost: 20,

  cmCost: 0,

  effect: 'Ver poder requerido. Permite incrementar el Tipo de Movimiento',
};

export const PhysicalReinforcementArsMagnus: ArsMagnus = {
  id: '45',

  name: 'Refuerzo físico',

  pdCost: 20,

  cmCost: 10,

  effect: 'Ver poder requerido. Bono a la FUE',
};

export const CharacteristicTechniqueArsMagnus: ArsMagnus = {
  id: '46',

  name: 'Técnica característica',

  pdCost: 40,

  cmCost: 30,

  effect:
    'Ver poder requerido. Efectos variables a definir. Consumo: 1 Ki o 10 Zeón. Ver tiempo de reutilización',
};

export const MajorTechniqueArsMagnus: ArsMagnus = {
  id: '47',

  name: 'Técnica mayor',

  pdCost: 20,

  cmCost: 20,

  effect: 'Ver poder requerido. Consumo: 2 Ki o 20 Zeón',
};

export const AllArsMagnus: ArsMagnus[] = [
  FinalAttackArsMagnus,
  BerserkerArsMagnus,
  GuardianArsMagnus,
  KiaiArsMagnus,
  SpecialManeuverArsMagnus,
  YuuseBatojutsuArsMagnus,
  AgnitumAbsoluteEyeArsMagnus,
  AshuriamDimensionalAssaultArsMagnus,
  ChaosMeisterChaosControlArsMagnus,
  EtherialInfiniteAttacksArsMagnus,
  HaimaShadowOfBloodArsMagnus,
  InfiniumFluctuationOfSpaceArsMagnus,
  MagnumReleaseOfPowerArsMagnus,
  MatrixExistentialDivisionArsMagnus,
  MundusControlOftheEnvironmentArsMagnus,
  RaikouLightningBladeArsMagnus,
  RemTheEyesOfDestinyArsMagnus,
  SamielFinalRetributionArsMagnus,
  ShinkyouTheMirrorPositionArsMagnus,
  SummunEnergyAccumulationArsMagnus,
  TransformationTheAwakeningArsMagnus,
  TransformationEliodonArsMagnus,
  TransformationTheDragonSealsArsMagnus,
  TransformationPhysicalPowerArsMagnus,
  TransformationRuptureOfDestinyArsMagnus,
  UmbraShadowAttackArsMagnus,
  AquariusCapesandGarmentsArsMagnus,
  AriesMassificationOfArmsAndChainsArsMagnus,
  CancerMagisterMonofilosArsMagnus,
  CancerMonophylesArsMagnus,
  CapricorniusNeedlesArsMagnus,
  GeminiPuppetsArsMagnus,
  LeoWeaponspistolArsMagnus,
  LibraVariableWeaponArsMagnus,
  OphiucosLordOfInfiniteSwordsArsMagnus,
  PiscesYoyoArsMagnus,
  SagittariusMagisterThrownObjectsArsMagnus,
  SagittariusThrownObjectsArsMagnus,
  ScorpioSwordwhipArsMagnus,
  TaurusHugeDoubleArmsArsMagnus,
  VirgoStringInstrumentsArsMagnus,
  VirgoPercussionInstrumentsArsMagnus,
  VirgoLargeWindInstrumentsArsMagnus,
  VirgoSmallWindInstrumentsArsMagnus,
  RangedAttackArsMagnus,
  CatchArsMagnus,
  RemotecontrolArsMagnus,
  ProtectiveShieldArsMagnus,
  DamageIncreaseArsMagnus,
  AcceleratedmMvementArsMagnus,
  PhysicalReinforcementArsMagnus,
  CharacteristicTechniqueArsMagnus,
  MajorTechniqueArsMagnus,
];

export const getUnusedArsMagnus = (
  arsMagnus: ArsMagnusId[],
): ArsMagnus | undefined => {
  return AllArsMagnus.find((a) => !arsMagnus.includes(a.id));
};
