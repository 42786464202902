import { observer } from 'mobx-react';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';

export const KiPoints = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const {
    agility: agilityKiPoints,
    strength: constitutionKiPoints,
    dexterity: dexterityKiPoints,
    constitution: strengthKiPoints,
    power: powerKiPoints,
    willPower: willPowerKiPoints,
  } = character.ki.kiPoints;

  const {
    agility: agilityKiAccumPoints,
    strength: constitutionKiAccumPoints,
    dexterity: dexterityKiAccumPoints,
    constitution: strengthKiAccumPoints,
    power: powerKiAccumPoints,
    willPower: willPowerKiAccumPoints,
  } = character.ki.kiAccumulationPoints;

  const totalKiPoints = character.ki.kiPoints.total;

  const totalAccumulatedKiPoints = character.ki.kiAccumulationPoints.total;

  const totalHalfAccumulatedKiPoints =
    Math.ceil(agilityKiAccumPoints.final / 2) +
    Math.ceil(constitutionKiAccumPoints.final / 2) +
    Math.ceil(dexterityKiAccumPoints.final / 2) +
    Math.ceil(strengthKiAccumPoints.final / 2) +
    Math.ceil(powerKiAccumPoints.final / 2) +
    Math.ceil(willPowerKiAccumPoints.final / 2);

  return (
    <Section maxHeight="fit">
      <Section.Title>Acumulación y puntos de Ki</Section.Title>
      <Table>
        <Table.Header>
          <Table.Header.Cell />
          <Table.Header.Cell>Acu.</Table.Header.Cell>
          <Table.Header.Cell>Mitad</Table.Header.Cell>
          <Table.Header.Cell>Ki</Table.Header.Cell>
        </Table.Header>
        <Table.Row>
          <Table.Row.Cell $bold $textAlign="left">
            Agilidad
          </Table.Row.Cell>
          <Table.Row.Cell>{agilityKiAccumPoints.final}</Table.Row.Cell>
          <Table.Row.Cell>
            {Math.ceil(agilityKiAccumPoints.final / 2)}
          </Table.Row.Cell>
          <Table.Row.Cell>{agilityKiPoints.final}</Table.Row.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Row.Cell $bold $textAlign="left">
            Constitución
          </Table.Row.Cell>
          <Table.Row.Cell>{constitutionKiAccumPoints.final}</Table.Row.Cell>
          <Table.Row.Cell>
            {Math.ceil(constitutionKiAccumPoints.final / 2)}
          </Table.Row.Cell>
          <Table.Row.Cell>{constitutionKiPoints.final}</Table.Row.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Row.Cell $bold $textAlign="left">
            Destreza
          </Table.Row.Cell>
          <Table.Row.Cell>{dexterityKiAccumPoints.final}</Table.Row.Cell>
          <Table.Row.Cell>
            {Math.ceil(dexterityKiAccumPoints.final / 2)}
          </Table.Row.Cell>
          <Table.Row.Cell>{dexterityKiPoints.final}</Table.Row.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Row.Cell $bold $textAlign="left">
            Fuerza
          </Table.Row.Cell>
          <Table.Row.Cell>{strengthKiAccumPoints.final}</Table.Row.Cell>
          <Table.Row.Cell>
            {Math.ceil(strengthKiAccumPoints.final / 2)}
          </Table.Row.Cell>
          <Table.Row.Cell>{strengthKiPoints.final}</Table.Row.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Row.Cell $bold $textAlign="left">
            Poder
          </Table.Row.Cell>
          <Table.Row.Cell>{powerKiAccumPoints.final}</Table.Row.Cell>
          <Table.Row.Cell>
            {Math.ceil(powerKiAccumPoints.final / 2)}
          </Table.Row.Cell>
          <Table.Row.Cell>{powerKiPoints.final}</Table.Row.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Row.Cell $bold $textAlign="left">
            Voluntad
          </Table.Row.Cell>
          <Table.Row.Cell>{willPowerKiAccumPoints.final}</Table.Row.Cell>
          <Table.Row.Cell>
            {Math.ceil(willPowerKiAccumPoints.final / 2)}
          </Table.Row.Cell>
          <Table.Row.Cell>{willPowerKiPoints.final}</Table.Row.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Row.Cell $bold $textAlign="left">
            Ki total
          </Table.Row.Cell>
          <Table.Row.Cell $bold>{totalAccumulatedKiPoints}</Table.Row.Cell>
          <Table.Row.Cell $bold>{totalHalfAccumulatedKiPoints}</Table.Row.Cell>
          <Table.Row.Cell $bold>{totalKiPoints}</Table.Row.Cell>
        </Table.Row>
      </Table>
    </Section>
  );
});
