import { CharacterModel } from '../../CharacterModel';
import { CharacterIssue } from './CharacterIssue';
import { calculateAttackDefenseUnbalanceIssue } from './utils/calculateAttackDefenseUnbalanceIssue';
import { filterUndefined } from '../../../../../../shared/utils/filterUndefined';
import { calculateOffensiveSkillsExceedIssue } from './utils/calculateOffensiveSkillsExceedIssue';
import { calculateOnlyDefenseDevelopmentExceedIssue } from './utils/calculateOnlyDefenseDevelopmentExceedIssue';
import { calculateCMUsedExceededIssue } from './utils/calculateCMUsedExceededIssue';
import { calculatePDUsedExceededIssue } from './utils/calculatePDUsedExceededIssue';
import { calculatePsychicPointsUsedExceededIssue } from './utils/calculatePsychicPointsUsedExceededIssue';

const possibleIssues: Record<
  CharacterIssue,
  (character: CharacterModel) => boolean
> = {
  [CharacterIssue.AttackDefenseUnbalance]: calculateAttackDefenseUnbalanceIssue,
  [CharacterIssue.OffensiveSkillsExceed]: calculateOffensiveSkillsExceedIssue,
  [CharacterIssue.OnlyDefenseDevelopmentExceed]:
    calculateOnlyDefenseDevelopmentExceedIssue,
  [CharacterIssue.CMUsedExceeded]: calculateCMUsedExceededIssue,
  [CharacterIssue.PDUsedExceeded]: calculatePDUsedExceededIssue,
  [CharacterIssue.PsychicPointsUsedExceeded]:
    calculatePsychicPointsUsedExceededIssue,
};

export const getCharacterIssues = (
  character: CharacterModel,
): CharacterIssue[] => {
  const characterIssues = Object.entries(possibleIssues).map(
    ([issue, calculateIssue]) => {
      if (calculateIssue(character)) {
        return issue as CharacterIssue;
      }
    },
    [],
  );

  return characterIssues.filter(filterUndefined()) as CharacterIssue[];
};
