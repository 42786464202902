import { Avatar } from '@mui/material';
import { useEffect, useState } from 'react';
import { config } from '../../../../../bootstrap/infrastructure/config';

import Logo from '../../../../../shared/application/assets/logo.png';
import { useUserAuthorization } from '../../../../../shared/application/hooks/useUserAuthorization';
import {
  Routes,
  useNavigate,
} from '../../../../../shared/application/hooks/useNavigate';
import { useOpenWindow } from '../../../../../shared/application/hooks/useOpenWindow';
import { Loading } from '../../../../../shared/application/design-system/components/Loading/Loading';
import { LoadingContainer } from '../../../../../shared/application/design-system/components/PageLayout/PageLayout.styled';

import {
  Container,
  Header,
  StyledButton,
  WhiteParagraph,
  WhiteTitle,
  Wrapper,
} from '../Authorization/Autorization.styled';
import { ButtonSize } from '../../../../../shared/application/design-system/components/Button/Button.types';

export const EmbeddedAuthorization = () => {
  const { navigate } = useNavigate();
  const { session, loading } = useUserAuthorization({
    redirect: false,
  });

  const [redirecting, setRedirecting] = useState(false);

  const { openWindow } = useOpenWindow({
    url: `${config.url}${Routes.Auth}?embed=true`,
    onClose: async () => {
      if (!redirecting) {
        setRedirecting(true);

        setTimeout(() => {
          setRedirecting(false);
          window.location.reload();
        }, 1500);
      }
    },
  });

  useEffect(() => {
    if (session) {
      navigate(Routes.MyCharacters, {
        replace: true,
        params: { folderId: '' },
      });
    }
  }, [navigate, session]);

  if (loading) {
    return (
      <LoadingContainer>
        <Loading centered />
      </LoadingContainer>
    );
  }

  const handleGoToAppClick = () => {
    openWindow();
  };

  return (
    <Wrapper>
      <Container>
        <Header>
          <Avatar src={Logo} sx={{ width: 56, height: 56 }} />
          <WhiteTitle>Characters Vault</WhiteTitle>
        </Header>
        {redirecting ? (
          <WhiteParagraph>
            Te estamos redirigiendo a tus personajes...
          </WhiteParagraph>
        ) : (
          <WhiteParagraph>
            Para poder acceder a la aplicación, debes iniciar sesión.
          </WhiteParagraph>
        )}

        <StyledButton
          loading={redirecting}
          size={ButtonSize.Big}
          onClick={handleGoToAppClick}
        >
          Ir a la aplicación
        </StyledButton>
      </Container>
    </Wrapper>
  );
};
