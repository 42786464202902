/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { computed, reaction } from 'mobx';
import { getSnapshot, Model, model, prop } from 'mobx-keystone';
import { getMartialArtFromType } from '../../../../../aggregations/martial-arts/getMartialArtFromType';
import {
  MartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../../../../aggregations/martial-arts/MartialArt';
import {
  AdvancedMartialArtGradeIds,
  BasicMartialArtGrade,
  BasicMartialArtGradeIds,
  MartialArtGrade,
  MartialArtGradeId,
} from '../../../../../aggregations/martial-arts/MartialArtGrades';
import { getParentCharacter } from '../../../../../utils/parenting/getParentCharacter';
import { CategoryType } from '../../../../../aggregations/category-templates/CategoryTemplate';
import { CategoryModel } from '../../pd/parts/categories/CategoryModel';
import { getParentDevelopedCategory } from '../../../../../utils/parenting/getParentDevelopedCategory';
import { filterUndefined } from '../../../../../../../shared/utils/filterUndefined';
import { HitType } from '../../../types/HitType';

@model('Character/Development/Category/Combat/MartialArt')
export class MartialArtModel extends Model(() => ({
  type: prop<MartialArtType>(MartialArtType.Aikido).withSetter(),

  gradeId: prop<MartialArtGradeId>().withSetter(),
})) {
  protected onAttachedToRootStore() {
    return reaction(
      () => getSnapshot(this),
      () => {
        if (
          !this.martialArt.grades.find((grade) => grade.id === this.gradeId)
        ) {
          this.setGradeId(this.martialArt.grades[0].id);
        }
      },
    );
  }

  @computed
  get martialArtName(): MartialArt['name'] {
    return this.martialArt.name;
  }

  @computed
  get gradeName(): BasicMartialArtGrade['name'] {
    return this.grade.name;
  }

  @computed
  get damage(): number {
    if (!this.character) return 0;

    for (const grade of this.relatedGrades.reverse()) {
      if (grade.calculateDamage !== undefined) {
        return grade.calculateDamage(this.character);
      }
    }

    return 0;
  }

  @computed
  get hitTypes(): HitType[] {
    if (!this.character) return [];

    const uniqueHitTypes = new Set<HitType>(
      this.relatedGrades
        .map((grade) => grade.calculateHitType?.(this.character!))
        .flat()
        .filter(filterUndefined()),
    );

    return Array.from(uniqueHitTypes);
  }

  @computed
  get combatBonus() {
    return {
      attack: this.relatedGrades.reduce(
        (bonus, grade) => bonus + grade.combatBonus.attack,
        0,
      ),
      block: this.relatedGrades.reduce(
        (bonus, grade) => bonus + grade.combatBonus.block,
        0,
      ),
      dodge: this.relatedGrades.reduce(
        (bonus, grade) => bonus + grade.combatBonus.dodge,
        0,
      ),
      initiative: this.relatedGrades.reduce(
        (bonus, grade) => bonus + grade.combatBonus.initiative,
        0,
      ),
    };
  }

  @computed
  get masterBonus() {
    return {
      attack: this.relatedGrades.reduce(
        (bonus, grade) =>
          bonus + (grade.isAdvanced() ? grade.masterBonus.attack : 0),
        0,
      ),
      block: this.relatedGrades.reduce(
        (bonus, grade) =>
          bonus + (grade.isAdvanced() ? grade.masterBonus.block : 0),
        0,
      ),
      dodge: this.relatedGrades.reduce(
        (bonus, grade) =>
          bonus + (grade.isAdvanced() ? grade.masterBonus.dodge : 0),
        0,
      ),
      initiative: this.relatedGrades.reduce(
        (bonus, grade) =>
          bonus + (grade.isAdvanced() ? grade.masterBonus.initiative : 0),
        0,
      ),
    };
  }

  @computed
  get cost(): number {
    if (!this.character || !this.category) return 0;

    let cost = this.grade.cost;

    if (this.category.category === CategoryType.Tao) {
      if (this.grade.id === BasicMartialArtGradeIds.Basic) {
        cost = 10;
      } else if (this.grade.id === BasicMartialArtGradeIds.Advanced) {
        cost = 20;
      } else if (this.grade.id === BasicMartialArtGradeIds.Supreme) {
        cost = 40;
      } else if (this.grade.id === AdvancedMartialArtGradeIds.Basic) {
        cost = 20;
      } else {
        cost = 40;
      }
    }

    const isFirstMartialArt = this.isFirstMartialArt;

    const hasUnarmedAsDevelopedWeapon =
      this.character.isUnarmedAsDevelopedWeapon;

    const isBasicMartialArt =
      this.martialArt.level === MartialArtLevelType.Basic;

    if (isBasicMartialArt && isFirstMartialArt && hasUnarmedAsDevelopedWeapon) {
      cost /= 2;
    }

    return cost;
  }

  @computed
  get grade(): BasicMartialArtGrade {
    return (
      this.martialArt.grades.find((grade) => grade.id === this.gradeId) ??
      this.martialArt.grades[0]
    );
  }

  @computed
  get gradeLevel(): number {
    return (
      this.martialArt.grades.findIndex((grade) => grade.id === this.gradeId) + 1
    );
  }

  @computed
  get martialKnowledgeBonus(): number {
    return this.grade.cmBonus;
  }

  @computed
  get requirementsErrors(): Record<MartialArtGradeId, string[]> {
    if (!this.character) return {};

    return this.relatedGrades.reduce((acc, grade) => {
      const errors = this.character
        ? grade.getRequirementsErrors(this.character)
        : [];

      return {
        ...acc,
        [grade.id]: errors,
      };
    }, {} as Record<MartialArtGradeId, string[]>);
  }

  @computed
  get effects(): string[] {
    return this.relatedGrades
      .map((grade) => grade.effects)
      .filter(filterUndefined());
  }

  @computed
  get areRequirementsFulfilled(): boolean {
    return Object.values(this.requirementsErrors).every(
      (errors) => errors.length === 0,
    );
  }

  @computed
  get martialArt(): MartialArt {
    return getMartialArtFromType(this.type);
  }

  @computed
  get isFirstMartialArt(): boolean {
    if (!this.character) return false;

    return this.character.combat.martialArts[0] === this;
  }

  public containsGrade(gradeId: MartialArtGradeId): boolean {
    return this.relatedGrades.some((grade) => grade.id === gradeId);
  }

  get character() {
    return getParentCharacter(this);
  }

  @computed
  private get relatedGrades(): MartialArtGrade[] {
    const currentGrade = this.grade;

    const martialArtGrades = this.martialArt.grades;

    switch (currentGrade.id) {
      case BasicMartialArtGradeIds.Basic:
        return [martialArtGrades[0]];
      case BasicMartialArtGradeIds.Advanced:
        return martialArtGrades.slice(0, 2);
      case BasicMartialArtGradeIds.Supreme:
        return martialArtGrades;
      case AdvancedMartialArtGradeIds.Basic:
        return [martialArtGrades[0]];
      case AdvancedMartialArtGradeIds.Arcane:
        return martialArtGrades;
    }

    return [];
  }

  get category(): CategoryModel | undefined {
    return getParentDevelopedCategory(this);
  }
}
