import { Hide, Show } from 'emotion-icons/boxicons-solid';
import styled from 'styled-components';
import { theme } from '../../../../../../../../../../../shared/application/design-system/theme/theme';

export const ShowButton = styled(Show)({
  width: '1rem',
  height: '1rem',
  color: theme.color.gray(),

  cursor: 'pointer',
  transition: 'color 0.2s ease-in-out',

  '&:hover': {
    color: theme.color.primary(),
  },
});

export const HideButton = styled(Hide)({
  width: '1rem',
  height: '1rem',
  color: theme.color.gray(),

  cursor: 'pointer',
  transition: 'color 0.2s ease-in-out',

  '&:hover': {
    color: theme.color.primary(),
  },
});
