import { SecondarySkill } from '../../character/model/types/SecondarySkill';
import { Archetype, CategoryTemplate, CategoryType } from './CategoryTemplate';

export class WarlockCategoryTemplate extends CategoryTemplate {
  name = 'Warlock';
  archetypes = [Archetype.Fighter, Archetype.Mystic];
  type = CategoryType.Warlock;

  readonly lifeMultiplier = 20;
  lifePoints = 10;
  protected turn = 5;
  protected martialKnowledge = 20;
  protected nativePsychicPoints = 1;

  offensiveSkillsLimitPercentage = 0.5;
  attackPDsCost = 2;
  blockPDsCost = 2;
  dodgePDsCost = 2;
  wearArmorPDsCost = 2;
  kiPDsCost = 2;
  kiAccumMultiplierPDsCost = 25;

  magicSkillsLimitPercentage = 0.5;
  zeonPDsCost = 1;
  aCTMultiplierPDsCost = 50;
  magicProjectionPDsCost = 2;
  summonPDsCost = 2;
  controlPDsCost = 2;
  bindPDsCost = 2;
  banishPDsCost = 2;

  psychicSkillsLimitPercentage = 0.5;
  psychicPointsPDsCost = 20;
  psychicProjectionPDsCost = 3;

  athleticSecondariesPDsCost = 2;
  socialSecondariesPDsCost = 2;
  perceptionSecondariesPDsCost = 2;
  intellectualSecondariesPDsCost = 2;
  vigorSecondariesPDsCost = 2;
  subterfugeSecondariesPDsCost = 2;
  creativeSecondariesPDsCost = 2;

  attackBonus = 5;
  dodgeBonus = 5;
  blockBonus = 5;
  zeonBonus = 20;

  calculateSecondarySkillBonusPerCategory(skill: SecondarySkill): number {
    if (skill === SecondarySkill.MagicAppraisal) return 5;

    return 0;
  }
}
