import { AddSquare } from '@emotion-icons/fluentui-system-filled/AddSquare';
import { Input, InputProps, Typography, TypographyProps } from '@mui/material';
import styled from 'styled-components';
import { theme } from '../../../../../../../../../shared/application/design-system/theme/theme';
import { styleMui } from '../../../../../../../../../shared/application/design-system/utils/styleMui';
import {
  DoubleInputDigits,
  DoubleInputSize,
  DoubleInputVariant,
  InputsOrientation,
  LabelPosition,
} from './DoubleInput.types';

export const Wrapper = styled.div<{
  $labelPosition: LabelPosition;
}>(({ $labelPosition }) => ({
  display: 'flex',
  justifyContent:
    $labelPosition === LabelPosition.Top ? 'start' : 'space-between',
  alignItems: 'center',

  flexDirection: $labelPosition === LabelPosition.Top ? 'column' : 'row',

  gap: $labelPosition === LabelPosition.Top ? '0.5rem' : undefined,
}));

export const Label = styleMui(Typography)<
  TypographyProps & { $labelPosition: LabelPosition }
>(({ $labelPosition }) => ({
  color: theme.color.black(),
  fontWeight: 'bold',
  marginRight: $labelPosition === LabelPosition.Left ? '1rem' : undefined,

  fontSize: '0.75rem',

  textAlign: 'center',
}));

export const StyledSpecialButton = styled(AddSquare)<{
  $size: DoubleInputSize;
  $showAlways: boolean;
}>(({ $size, $showAlways }) => {
  const getSize = () => {
    if ($size === DoubleInputSize.Big) return '1rem';
    if ($size === DoubleInputSize.Normal) return '0.7rem';

    return '0.5rem';
  };

  const getRight = () => {
    if ($size === DoubleInputSize.Big) return '1rem';
    if ($size === DoubleInputSize.Normal) return '-0.25rem';

    return '-0.25rem';
  };

  return {
    color: theme.color.black(),
    width: getSize(),
    height: getSize(),

    position: 'absolute',
    top: '0',
    right: getRight(),
    zIndex: 1,

    cursor: 'pointer',

    opacity: $showAlways ? 1 : 0,

    '&:hover': {
      color: theme.color.primary(),
    },
  };
});

export const InputWrapper = styled.div<{
  $orientation: InputsOrientation;
}>(({ $orientation }) => ({
  position: 'relative',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  flexDirection: $orientation === InputsOrientation.Vertical ? 'column' : 'row',

  gap: $orientation === InputsOrientation.Vertical ? '0.5rem' : undefined,

  [`&:hover ${StyledSpecialButton}`]: {
    opacity: 1,
  },
}));

export const StyledLeftInput = styleMui(Input)<
  InputProps & {
    $width: DoubleInputDigits;
    $hasSibling?: boolean;
    $size: DoubleInputSize;
    $variant: DoubleInputVariant;
    $orientation: InputsOrientation;
  }
>(({ $width, $hasSibling, $size, $variant, $orientation }) => {
  const getWith = () => {
    if ($size === DoubleInputSize.Big) return 'auto';

    if ($size === DoubleInputSize.Normal) {
      switch ($width) {
        case DoubleInputDigits.THOUSANDS:
          return '3.5rem';
        case DoubleInputDigits.HUNDREDS:
          return '2.5rem';
        default:
          return '2rem';
      }
    }

    switch ($width) {
      case DoubleInputDigits.THOUSANDS:
        return '3rem';
      case DoubleInputDigits.HUNDREDS:
        return '2rem';
      default:
        return '1.5rem';
    }
  };

  const getFontSize = (isMobile: boolean) => {
    switch ($size) {
      case DoubleInputSize.Big:
        return isMobile ? '1.5rem' : '2.5rem';
      case DoubleInputSize.Normal:
        return '0.75rem';
      default:
        return '0.65rem';
    }
  };

  const getBorderRadius = () => {
    if ($orientation === InputsOrientation.Vertical) {
      return {
        all: '0.5rem',
        corners: undefined,
      };
    }
    return {
      all: !$hasSibling ? '0.5rem' : undefined,
      corners: $hasSibling ? '0.5rem' : undefined,
    };
  };

  return {
    width: getWith(),
    fontSize: getFontSize(false),

    ...theme.mixins.responsive.md.downward({
      fontSize: getFontSize(true),
    }),

    borderBottomLeftRadius: getBorderRadius().corners,
    borderTopLeftRadius: getBorderRadius().corners,
    borderRadius: getBorderRadius().all,

    paddingLeft: '0.125rem',
    fontWeight: 'bold',
    overflow: 'hidden',
    backgroundColor:
      $variant === DoubleInputVariant.Outline ? undefined : 'white',

    '& input': {
      textAlign: 'center',
      padding: 0,
    },
    '&:before': {
      borderStyle: 'none',
      content: 'none',
    },
    '&:after': {
      borderColor: theme.color.black(),
    },
  };
});

export const StyledRightInput = styleMui(StyledLeftInput)(
  ({ $hasSibling, $orientation }) => {
    const getBorderRadius = () => {
      if ($orientation === InputsOrientation.Vertical) return '0.5rem';

      return $hasSibling ? '0 0.5rem 0.5rem 0' : undefined;
    };

    return {
      backgroundColor: theme.color.primary(),

      borderRadius: getBorderRadius(),

      paddingLeft: '0',
      paddingRight: $hasSibling ? '0.125rem' : undefined,

      '& input': {
        color: 'white',
        ['-webkit-text-fill-color']: 'white !important',
      },
    };
  },
);
