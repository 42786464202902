import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import { MenuItem } from '../../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';
import { getPsychicTableFromType } from '../../../../../../../../../../../domain/aggregations/tables/psychic-tables/getPsychicTableFromType';
import { PsychicTableType } from '../../../../../../../../../../../domain/aggregations/tables/psychic-tables/PsychicTable';
import { PsychicTableModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/parts/psychic/PsychicTableModel';
import { AddItemButton } from '../../../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../../../common/CharacterMenu/useCharacterMenu';
import { RemoveItemButton } from '../../../../../common/RemoveItemButton/RemoveItemButton';
import { Table } from '../../../../../common/Table/Table';
import { PsychicPDModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/parts/psychic/PsychicPDModel';

type PsychicTableSelectorProps = {
  psychic: PsychicPDModel;
};

export const PsychicTableSelector = observer(
  ({ psychic }: PsychicTableSelectorProps) => {
    const { handleMenuOpen: handleTableMenuOpen, Menu: PsychicMenu } =
      useCharacterMenu<PsychicTableType>();

    const [selectedTable, setSelectedTable] = useState<PsychicTableModel>();

    const handleAddPsychicTable = () => {
      psychic.addPsychicTable(PsychicTableType.PsychicProjection);
    };

    const handleRemovePsychicTable = (table: PsychicTableModel) => () => {
      psychic.removePsychicTable(table);
    };

    const psychicTables = (
      Object.values(PsychicTableType) as PsychicTableType[]
    )
      .map(getPsychicTableFromType)
      .map((t) => {
        const item: MenuItem<PsychicTableType> = {
          key: t.type,
          name: t.name,
          value: t.type,
          onClick: () => {
            selectedTable?.setType(t.type);
          },
        };
        return item;
      });

    return (
      <>
        <Table>
          <Table.Header>
            <Table.Header.Cell>Tabla</Table.Header.Cell>
            <Table.Header.Cell>Coste PD</Table.Header.Cell>
          </Table.Header>
          {psychic.tables.map((table, i) => {
            const handleTableSelection = (e: MouseEvent<HTMLDivElement>) => {
              handleTableMenuOpen(table.type)(e);
              setSelectedTable(table);
            };

            return (
              <Table.Row key={`${i}-${table.type}`}>
                <Table.Row.Cell onClick={handleTableSelection}>
                  {table.name}
                </Table.Row.Cell>
                <Table.Row.Cell>{table.cost}</Table.Row.Cell>
                <RemoveItemButton onClick={handleRemovePsychicTable(table)} />
                <PsychicMenu items={psychicTables} />
              </Table.Row>
            );
          })}
        </Table>

        <AddItemButton onClick={handleAddPsychicTable} />
      </>
    );
  },
);
