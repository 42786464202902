import { model, Model, prop } from 'mobx-keystone';
import { NephilimType } from '../../../../aggregations/nephilim-type/Nephilim';
import { SizeFieldModel } from './fields/SizeFieldModel';

@model('Character/Description')
export class DescriptionModel extends Model({
  hair: prop<string>('').withSetter(),
  eyes: prop('').withSetter(),
  height: prop('').withSetter(),
  weight: prop('').withSetter(),
  age: prop('').withSetter(),
  gender: prop('').withSetter(),
  race: prop('').withSetter(),
  size: prop(() => new SizeFieldModel({})),

  nephilim: prop<NephilimType | undefined>().withSetter(),

  notes: prop('').withSetter(),

  physicalDescription: prop('').withSetter(),
  personality: prop('').withSetter(),
  dreamsAndGoal: prop('').withSetter(),
  history: prop('').withSetter(),
}) {}
