import { Database } from '../../shared/infrastructure/supabase/Supabase.types';
import { ApiClient } from '../../shared/infrastructure/api/ApiClient';
import { Folder } from '../domain/folder/Folder';

const createFolder = async (name: string, parentFolderId?: string) => {
  await ApiClient.post('folder/create', { name, parentFolderId });
};

const deleteFolder = async (id: string) => {
  await ApiClient.delete(`folder/${id}`);
};

const getFolderNavigation = async (
  parentFolderId?: string,
): Promise<{ folder: Folder | undefined; children: Folder[] }> => {
  const queryParams = new URLSearchParams(
    parentFolderId ? { folder_id: parentFolderId?.toString() } : {},
  );

  const folders = await ApiClient.get<{
    folder: FolderDatabaseRow | undefined;
    children: FolderDatabaseRow[];
  }>(`folder/navigation?${queryParams.toString()}`).then((r) => r.data);

  const children = folders.children.map((f) => Folder.fromPrimitives(f));

  if (folders.folder) {
    return {
      folder: Folder.fromPrimitives(folders.folder),
      children,
    };
  }

  return { children, folder: undefined };
};

const moveFolderToFolder = async (
  folderId: string,
  parentFolderId?: string,
) => {
  await ApiClient.put('folder/move-folder-to', { folderId, parentFolderId });
};

const moveCharacterToFolder = async (
  characterId: string,
  folderId?: string,
) => {
  await ApiClient.put('folder/move-character-to', {
    characterId,
    parentFolderId: folderId,
  });
};

export const FolderService = {
  createFolder,
  getFolderNavigation,
  moveFolderToFolder,
  moveCharacterToFolder,
  deleteFolder,
};

export type FolderDatabaseRow = Database['public']['Tables']['folder']['Row'];
