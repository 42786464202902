import { model } from 'mobx-keystone';
import {
  AdvantageLevel1,
  AdvantageLevel2,
} from '../../../../../aggregations/advantage/Advantage.types';
import { ExceptionalPsychicResistanceAdvantage } from '../../../../../aggregations/advantage/CommonAdvantages';
import { Calculation, NumberField } from '../../../utils/fields/NumberField';
import { NemesisSkillType } from '../../../../../aggregations/nemesis-skill/NemesisSkill';
import {
  DukZaristFemale,
  DukZaristMale,
  NephilimDukZaristFemale,
  NephilimDukZaristMale,
  NephilimSylvain,
  Sylvain,
} from '../../../../../aggregations/nephilim-type/Nephilim';

@model('PsychicResistanceField')
export class PsychicResistanceField extends NumberField {
  getCalculations(): Calculation[] {
    if (!this.character) return [];

    const calculations: Calculation[] = [];

    calculations.push(this.sum('Especial', this.special));

    calculations.push(this.sum('Presencia', this.character.presence.final));

    calculations.push(
      this.sum(
        'Modificador de voluntad',
        this.character.primaries.willPower.modifier.finalClampedTo0,
      ),
    );

    if (
      this.character.creationPoints.containsAdvantage(
        ExceptionalPsychicResistanceAdvantage,
        { level: AdvantageLevel1 },
      )
    ) {
      calculations.push(
        this.sum('Resistencia psíquica excepcional (nivel 1)', 25),
      );
    }

    if (
      this.character.creationPoints.containsAdvantage(
        ExceptionalPsychicResistanceAdvantage,
        { level: AdvantageLevel2 },
      )
    ) {
      calculations.push(
        this.sum('Resistencia psíquica excepcional (nivel 2)', 50),
      );
    }

    if (this.character.ki.hasNemesisSkill(NemesisSkillType.VoidBody)) {
      calculations.push(this.sum('Cuerpo de vacío', 20));
    }

    if (this.character.isNephilim(NephilimSylvain)) {
      calculations.push(this.sum('Nephilim Sylvain', 10));
    }

    if (this.character.isNephilim(NephilimDukZaristMale)) {
      calculations.push(this.sum(NephilimDukZaristMale.name, 15));
    }

    if (this.character.isNephilim(NephilimDukZaristFemale)) {
      calculations.push(this.sum(NephilimDukZaristFemale.name, 15));
    }

    if (this.character.isNephilim(DukZaristMale)) {
      calculations.push(this.sum(DukZaristMale.name, 15));
    }

    if (this.character.isNephilim(DukZaristFemale)) {
      calculations.push(this.sum(DukZaristFemale.name, 15));
    }

    if (this.character.isNephilim(Sylvain)) {
      calculations.push(this.sum(Sylvain.name, 30));
    }

    return calculations;
  }
}
