/* eslint-disable @typescript-eslint/no-unused-vars */
import { model } from 'mobx-keystone';
import { MagicPath } from '../../../../../aggregations/magic-path/MagicPath';
import { NumberField } from '../../../utils/fields/NumberField';

@model('Character/Mystic/FreeAccessSpellsLimitFieldModel')
export class FreeAccessSpellsLimitFieldModel extends NumberField {
  private readonly table = {
    4: { major: 1, minor: 1 },
    8: { major: 1, minor: 2 },
    14: { major: 2, minor: 3 },
    18: { major: 2, minor: 4 },
    24: { major: 3, minor: 5 },
    28: { major: 3, minor: 6 },
    34: { major: 4, minor: 7 },
    38: { major: 4, minor: 8 },
    44: { major: 5, minor: 9 },
    48: { major: 5, minor: 10 },
    54: { major: 6, minor: 11 },
    58: { major: 6, minor: 12 },
    64: { major: 7, minor: 13 },
    68: { major: 7, minor: 14 },
    74: { major: 8, minor: 15 },
    78: { major: 8, minor: 16 },
    84: { major: 9, minor: 17 },
    88: { major: 9, minor: 18 },
    94: { major: 10, minor: 19 },
    98: { major: 10, minor: 20 },
  };

  private readonly majorMagicPaths: MagicPath[] = [
    MagicPath.Light,
    MagicPath.Darkness,
    MagicPath.Creation,
    MagicPath.Destruction,
    MagicPath.Necromancy,
  ];

  private readonly minorMagicPaths: MagicPath[] = [
    MagicPath.Fire,
    MagicPath.Water,
    MagicPath.Air,
    MagicPath.Earth,
    MagicPath.Essence,
    MagicPath.Illusion,
  ];

  get final(): number {
    return this.special + this.calculateBonus();
  }

  private calculateBonus(): number {
    if (!this.character) return 0;

    let bonus = 0;

    for (const pathModel of this.character.mystic.paths) {
      const tableHit = Object.entries(this.table).find(
        ([key, _]) => pathModel.level.final <= Number(key),
      );

      if (tableHit) {
        const values = tableHit[1];

        if (
          this.minorMagicPaths.includes(pathModel.path) ||
          (pathModel.subPath &&
            this.minorMagicPaths.includes(pathModel.subPath))
        ) {
          bonus += values.minor;
        } else if (this.majorMagicPaths.includes(pathModel.path)) {
          bonus += values.major;
        }
      }
    }

    return bonus;
  }
}
