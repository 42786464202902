import { Avatar, Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';

import Logo from '../../../../../shared/application/assets/logo.png';
import { useUserAuthorization } from '../../../../../shared/application/hooks/useUserAuthorization';
import {
  Routes,
  useNavigate,
} from '../../../../../shared/application/hooks/useNavigate';
import { Loading } from '../../../../../shared/application/design-system/components/Loading/Loading';
import { LoadingContainer } from '../../../../../shared/application/design-system/components/PageLayout/PageLayout.styled';

import {
  Container,
  Header,
  StyledButton,
  WhiteParagraph,
  WhiteTitle,
  Wrapper,
} from '../Authorization/Autorization.styled';
import { ButtonSize } from '../../../../../shared/application/design-system/components/Button/Button.types';
import {
  LocalStorageKeys,
  LocalStorageManager,
} from '../../../../../shared/infrastructure/LocalStorageManager';
import { Authorization } from '../Authorization/Authorization';
import {
  CheckboxContainer,
  Explanation,
} from './EmbeddedFirefoxAuthorization.styled';

export const EmbeddedFirefoxAuthorization = () => {
  const { navigate } = useNavigate();
  const { session, loading } = useUserAuthorization({
    redirect: false,
  });

  const [showLogin, setShowLogin] = useState(
    LocalStorageManager.getItem(
      LocalStorageKeys.DoNotShowEmbeddedFirefoxWarning,
    ) === 'true',
  );
  const [doNotRepeatAgain, setDoNotRepeatAgain] = useState(false);

  const openLogin = () => {
    setShowLogin(true);
  };

  useEffect(() => {
    if (session) {
      navigate(Routes.MyCharacters, {
        replace: true,
        params: { folderId: '' },
      });
    }
  }, [session]);

  if (loading) {
    return (
      <LoadingContainer>
        <Loading centered />
      </LoadingContainer>
    );
  }

  const handleDoNotRepeatAgainCheckbox = () => {
    const newValue = !doNotRepeatAgain;
    setDoNotRepeatAgain(newValue);

    if (newValue) {
      LocalStorageManager.setItem(
        LocalStorageKeys.DoNotShowEmbeddedFirefoxWarning,
        true,
      );
    } else {
      LocalStorageManager.removeItem(
        LocalStorageKeys.DoNotShowEmbeddedFirefoxWarning,
      );
    }
  };

  const handleGoToAppClick = () => {
    openLogin();
  };

  if (showLogin) {
    return <Authorization disableSocialLogin />;
  }

  return (
    <Wrapper>
      <Container>
        <Header>
          <Avatar src={Logo} sx={{ width: 56, height: 56 }} />
          <WhiteTitle>Characters Vault</WhiteTitle>
        </Header>
        <Explanation>
          <WhiteParagraph>
            Firefox no permite la autenticación en ventanas emergentes. Por lo
            que la autenticación con Google no está disponible.
          </WhiteParagraph>
          <WhiteParagraph>
            Si has usado Google para autenticarte anteriormente, puedes hacerlo
            desde Google Chrome o asignarte una contraseña desde tu perfil de
            usuario.
          </WhiteParagraph>
        </Explanation>

        <CheckboxContainer>
          <WhiteParagraph>No volver a mostrar este mensaje</WhiteParagraph>
          <Checkbox
            checked={doNotRepeatAgain}
            onChange={handleDoNotRepeatAgainCheckbox}
          />
        </CheckboxContainer>

        <StyledButton size={ButtonSize.Big} onClick={handleGoToAppClick}>
          Entendido
        </StyledButton>
      </Container>
    </Wrapper>
  );
};
