import { Menu } from '@mui/material';
import React, { MouseEvent, useState } from 'react';
import { ListRowText, MenuItemRow } from './Menu.styled';
import { vibrate } from '../../../../utils/vibrate';

export type MenuItem<T = any> =
  | {
      key: string;
      name: string;
      value: T;
      onClick: (e: MouseEvent) => void;
    }
  | {
      key: string;
      name: string;
      onClick?: (e: MouseEvent) => void;

      isDivider: true;
    };

export const useMenu = <T,>(options?: { sorted?: boolean }) => {
  const [selectedElement, setSelectedElement] = useState<Element | undefined>();

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<T | undefined>();

  const handleMenuOpen = (item?: T) => (e: MouseEvent) => {
    vibrate();

    setOpen(!open);
    setSelectedItem(item);
    setSelectedElement(e.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    handleMenuOpen,
    Menu: ({ items: rawItems }: { items: MenuItem<T>[] }) => {
      const items = options?.sorted
        ? rawItems.sort((a, b) => a.name.localeCompare(b.name))
        : rawItems;

      return (
        <Menu
          anchorEl={selectedElement}
          open={open}
          onClose={handleClose}
          anchorPosition={{
            top: 10,
            left: 10,
          }}
        >
          {items.map((item) => {
            const handleMenuItemClick = (e: MouseEvent) => {
              if (item?.onClick) {
                vibrate();
                item.onClick(e);
              }

              setOpen(false);
            };

            if ('isDivider' in item) {
              return (
                <MenuItemRow key={item.key} $divider onClick={item.onClick}>
                  <ListRowText
                    dangerouslySetInnerHTML={{ __html: item.name }}
                  />
                </MenuItemRow>
              );
            }

            return (
              <MenuItemRow
                key={item.key}
                onClick={handleMenuItemClick}
                $selected={item.value === selectedItem}
              >
                <ListRowText dangerouslySetInnerHTML={{ __html: item.name }} />
              </MenuItemRow>
            );
          })}
        </Menu>
      );
    },
  };
};
