// This file it's replicated in backend until we create our own mono-repo
// The file its called ErrorCodes.ts

export enum ApiErrorCode {
  UserNotFound = 'UserNotFound',

  UnauthorizedCharacterAccess = 'UnauthorizedCharacterAccess',

  CollaboratorNotFound = 'CollaboratorNotFound',
  CharacterNotFound = 'CharacterNotFound',
  CharacterCollaborationAlreadyExists = 'CharacterCollaborationAlreadyExists',
}
