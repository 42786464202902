import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import { MenuItem } from '../../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';
import { getMentalPatternFromType } from '../../../../../../../../../../../domain/aggregations/mental-patterns/getMentalPatternFromType';
import { MentalPatternType } from '../../../../../../../../../../../domain/aggregations/mental-patterns/MentalPattern';
import { MentalPatternModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/parts/psychic/MentalPatternModel';
import { PsychicPDModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/parts/psychic/PsychicPDModel';
import { AddItemButton } from '../../../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../../../common/CharacterMenu/useCharacterMenu';
import { RemoveItemButton } from '../../../../../common/RemoveItemButton/RemoveItemButton';
import { Table } from '../../../../../common/Table/Table';

type MentalPatternSelectorProps = {
  psychic: PsychicPDModel;
};

export const MentalPatternSelector = observer(
  ({ psychic }: MentalPatternSelectorProps) => {
    const { handleMenuOpen: handleTableMenuOpen, Menu: MentalPatternMenu } =
      useCharacterMenu<MentalPatternType>();

    const [selectedTable, setSelectedTable] = useState<MentalPatternModel>();

    const handleAddMentalPattern = () => {
      psychic.addMentalPattern(MentalPatternType.Courage);
    };

    const handleRemoveMentalPattern = (table: MentalPatternModel) => () => {
      psychic.removeMentalPattern(table);
    };

    const items = (Object.values(MentalPatternType) as MentalPatternType[])
      .map(getMentalPatternFromType)
      .map((t) => {
        const item: MenuItem<MentalPatternType> = {
          key: t.type,
          name: t.name,
          value: t.type,
          onClick: () => {
            selectedTable?.setType(t.type);
          },
        };
        return item;
      });

    return (
      <>
        <Table>
          <Table.Header>
            <Table.Header.Cell>Patrón</Table.Header.Cell>
            <Table.Header.Cell>Coste PD</Table.Header.Cell>
          </Table.Header>
          {psychic.mentalPatterns.map((mentalPattern, i) => {
            const handleTableSelection = (e: MouseEvent<HTMLDivElement>) => {
              handleTableMenuOpen(mentalPattern.type)(e);
              setSelectedTable(mentalPattern);
            };

            return (
              <Table.Row key={`${i}-${mentalPattern.type}`}>
                <Table.Row.Cell onClick={handleTableSelection}>
                  {mentalPattern.table.name}
                </Table.Row.Cell>
                <Table.Row.Cell>{mentalPattern.cost}</Table.Row.Cell>

                <RemoveItemButton
                  onClick={handleRemoveMentalPattern(mentalPattern)}
                />
                <MentalPatternMenu items={items} />
              </Table.Row>
            );
          })}
        </Table>
        <AddItemButton onClick={handleAddMentalPattern} />
      </>
    );
  },
);
