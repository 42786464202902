import { MenuItem } from '../useMenu';

export const transformToMenuItems = <T, E>({
  fromItems,
  generateItem,
  generateDivider,
}: {
  fromItems: E[];
  generateItem: (i: E) => MenuItem<T>;
  generateDivider?: (i: E) => string;
}) => {
  if (!generateDivider) {
    return fromItems.map(generateItem);
  }

  const mappedItems = new Map<string, MenuItem<T>[]>();

  for (const fromItem of fromItems) {
    const itemsPerDivider = mappedItems.get(generateDivider(fromItem));

    if (itemsPerDivider) {
      itemsPerDivider.push(generateItem(fromItem));
    } else {
      mappedItems.set(generateDivider(fromItem), [generateItem(fromItem)]);
    }
  }

  const items: MenuItem<T>[] = [];

  Array.from(mappedItems.keys()).forEach((key) => {
    const itemsToPush = mappedItems.get(key);

    if (itemsToPush) {
      items.push({ isDivider: true, key: key, name: key });
      items.push(...itemsToPush);
    }
  });

  return items;
};
