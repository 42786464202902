import { observable, reaction } from 'mobx';
import { getSnapshot, model, Model, modelAction, prop } from 'mobx-keystone';
import {
  CategoryTemplate,
  CategoryType,
} from '../../../../../../aggregations/category-templates/CategoryTemplate';
import { getCategoryTemplateFromType } from '../../../../../../aggregations/category-templates/utils/getCategoryTemplateFromType';
import { getParentCharacter } from '../../../../../../utils/parenting/getParentCharacter';
import { PrecalculatedFieldModel } from '../../../../utils/fields/PrecalculatedFieldModel';
import { CategoryModelName } from './CategoryModel.constants';
import { CategoryChangeModel } from './parts/CategoryChangeModel';
import { CombatPDModel } from './parts/combat/CombatPDModel';
import { LifePDModel } from './parts/life/LifePDModel';
import { MysticPDModel } from './parts/mystic/MysticPDModel';
import { PsychicPDModel } from './parts/psychic/PsychicPDModel';
import { SecondarySkillsPDModel } from './parts/secondaries/SecondarySkillsPDModel';

@model(CategoryModelName)
export class CategoryModel extends Model({
  category: prop<CategoryType>(CategoryType.Warrior),
  level: prop(() => new PrecalculatedFieldModel({})),

  lifePoints: prop(() => new LifePDModel({})),

  categoryChange: prop<CategoryChangeModel | undefined>(),

  combat: prop(() => new CombatPDModel({})),

  mystic: prop(() => new MysticPDModel({})),

  psychic: prop(() => new PsychicPDModel({})),

  secondaries: prop(() => new SecondarySkillsPDModel({})),
}) {
  @observable
  private _template: CategoryTemplate = getCategoryTemplateFromType(
    this.category,
  );

  get template(): CategoryTemplate {
    this._template.setLevel(this.level.final);

    return this._template;
  }

  protected onAttachedToRootStore(): (() => void) | void {
    const disposer = reaction(
      () => this.character && getSnapshot(this.character.categories),
      () => {
        if (!this.character) return 0;

        const myIndex = this.character.categories.findIndex((c) => c === this);

        if (myIndex <= 0) {
          if (this.categoryChange !== undefined) {
            this.setCategoryChange(undefined);
          }

          return;
        }

        const previousCategory = this.character.categories[myIndex - 1];

        const categoryChange = this.categoryChange;

        if (
          categoryChange?.from !== previousCategory.category ||
          categoryChange?.to !== this.category
        ) {
          this.setCategoryChange(
            new CategoryChangeModel({
              from: previousCategory.category,
              to: this.category,
            }),
          );
        }
      },
      { fireImmediately: true },
    );

    return () => disposer();
  }

  @modelAction
  setCategoryChange(categoryChange: CategoryChangeModel | undefined) {
    this.categoryChange = categoryChange;
  }

  @modelAction
  setCategory(category: CategoryType): void {
    this.category = category;

    this._template = getCategoryTemplateFromType(this.category);
  }

  get pds(): number {
    return this.calculatePDsFromLevel();
  }

  get pdsSpent(): number {
    let pdsSpentFromCategoryChange = 0;

    if (this.isFirstCategory) {
      const secondCategory = this.getSecondCategory();

      if (secondCategory) {
        pdsSpentFromCategoryChange =
          secondCategory.categoryChange?.expendFrom ?? 0;
      }
    } else if (this.categoryChange) {
      pdsSpentFromCategoryChange = this.categoryChange.expendTo;
    }

    return (
      pdsSpentFromCategoryChange +
      this.lifePoints.base +
      this.combat.pdsSpent +
      this.mystic.pdsSpent +
      this.psychic.pdsSpent +
      this.secondaries.pdsSpent
    );
  }

  private get isFirstCategory(): boolean {
    if (!this.character) return true;

    return this.character.categories[0] === this;
  }

  private getSecondCategory(): CategoryModel | undefined {
    if (!this.character) return undefined;

    return this.character.categories[1];
  }

  private calculatePDsFromLevel(): number {
    const level = this.level.final;

    if (this.isFirstCategory) {
      if (level === 0) return 400;
      if (level === 1) return 600;

      return 600 + (level - 1) * 100;
    }

    const alreadyHasInitialPds =
      this.getLevelsWithoutFirstCategory > 0 ||
      this.getLevelBeforeThisCategory > 1;

    if (!alreadyHasInitialPds) {
      if (this.getLevelBeforeThisCategory + level === 0) return 0;
      if (this.getLevelBeforeThisCategory + level === 1) return 200;

      if (this.getLevelBeforeThisCategory === 0) {
        return 200 + (this.getLevelBeforeThisCategory + level - 1) * 100;
      }
    }

    return level * 100;
  }

  private get getLevelBeforeThisCategory(): number {
    if (!this.character) return 0;

    const currentCategoryIndex = this.character.categories.findIndex(
      (category) => category === this,
    );

    return this.character.categories.reduce((prev, curr, currentIndex) => {
      if (currentIndex >= currentCategoryIndex) return prev;

      return prev + curr.level.final;
    }, 0);
  }

  private get getLevelsWithoutFirstCategory(): number {
    if (!this.character) return 0;

    const currentCategoryIndex = this.character.categories.findIndex(
      (category) => category === this,
    );

    return this.character.categories.reduce((prev, curr, currentIndex) => {
      if (currentIndex === 0) return prev;
      if (currentIndex >= currentCategoryIndex) return prev;

      return prev + curr.level.final;
    }, 0);
  }

  get character() {
    return getParentCharacter(this);
  }
}
