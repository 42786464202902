import {
  WeaponEquippedHandType,
  WeaponManageabilityType,
} from '../Weapon.types';
import { WeaponItemModel } from '../WeaponItemModel';

export const getStrengthRequirement = (weapon: WeaponItemModel) => {
  switch (weapon.manageabilityType) {
    case WeaponManageabilityType.OneHand:
      return weapon.requiredStrength.oneHand.final;
    case WeaponManageabilityType.TwoHand:
      return weapon.requiredStrength.twoHands.final;
    default:
      if (weapon.oneOrTwoHanded === WeaponEquippedHandType.OneHanded) {
        return weapon.requiredStrength.oneHand.final;
      }

      return weapon.requiredStrength.twoHands.final;
  }
};
