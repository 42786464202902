import { model, Model, prop } from 'mobx-keystone';
import { getParentCharacter } from '../../../../../../utils/parenting/getParentCharacter';
import { AgilityKiPointsFieldModel } from './fields/AgilityKiPointsFieldModel';
import { ConstitutionKiPointsFieldModel } from './fields/ConstitutionKiPointsFieldModel';
import { DexterityKiPointsFieldModel } from './fields/DexterityKiPointsFieldModel';
import { PowerKiPointsFieldModel } from './fields/PowerKiPointsFieldModel';
import { StrengthKiPointsFieldModel } from './fields/StrengthKiPointsFieldModel';
import { WillPowerKiPointsFieldModel } from './fields/WillPowerKiPointsFieldModel';

@model('Character/Ki/KiPoints')
export class KiPointsModel extends Model({
  agility: prop(() => new AgilityKiPointsFieldModel({})),
  strength: prop(() => new StrengthKiPointsFieldModel({})),
  dexterity: prop(() => new DexterityKiPointsFieldModel({})),
  constitution: prop(() => new ConstitutionKiPointsFieldModel({})),
  power: prop(() => new PowerKiPointsFieldModel({})),
  willPower: prop(() => new WillPowerKiPointsFieldModel({})),
}) {
  get total() {
    return (
      this.power.final +
      this.agility.final +
      this.strength.final +
      this.dexterity.final +
      this.willPower.final +
      this.constitution.final
    );
  }

  get character() {
    return getParentCharacter(this);
  }
}
