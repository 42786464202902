export enum MentalPatternType {
  Extroversion = 'Extroversion',
  Craziness = 'Craziness',
  Psychopathy = 'Psychopathy',
  Compassion = 'Compassion',
  Cowardice = 'Cowardice',
  Courage = 'Courage',
  CancellationIntroversion = 'CancellationIntroversion',
  CancellationExtroversion = 'CancellationExtroversion',
  CancellationMadness = 'CancellationMadness',
  CancellationPsychopathy = 'CancellationPsychopathy',
  CancellationCompassion = 'CancellationCompassion',
  CancellationCowardice = 'CancellationCowardice',
  CancellationValentia = 'CancellationValentia',
}

export abstract class MentalPattern {
  abstract readonly name: string;

  abstract type: MentalPatternType;

  abstract cost: number;
}

export class ExtroversionMentalPattern extends MentalPattern {
  name = 'Extroversión';

  type = MentalPatternType.Extroversion;

  cost = 30;
}

export class CrazinessMentalPattern extends MentalPattern {
  name = 'Locura';

  type = MentalPatternType.Craziness;

  cost = 30;
}

export class PsychopathyMentalPattern extends MentalPattern {
  name = 'Psicopatía';

  type = MentalPatternType.Psychopathy;

  cost = 30;
}

export class CompassionMentalPattern extends MentalPattern {
  name = 'Compasión';

  type = MentalPatternType.Compassion;

  cost = 30;
}

export class CowardiceMentalPattern extends MentalPattern {
  name = 'Cobardía';

  type = MentalPatternType.Cowardice;

  cost = 30;
}

export class CourageMentalPattern extends MentalPattern {
  name = 'Valentía';

  type = MentalPatternType.Courage;

  cost = 30;
}

export class CancellationIntroversionMentalPattern extends MentalPattern {
  name = 'Cancelación: Introversión';

  type = MentalPatternType.CancellationIntroversion;

  cost = 10;
}

export class CancellationExtroversionMentalPattern extends MentalPattern {
  name = 'Cancelación: Extroversión';

  type = MentalPatternType.CancellationExtroversion;

  cost = 10;
}

export class CancellationMadnessMentalPattern extends MentalPattern {
  name = 'Cancelación: Locura';

  type = MentalPatternType.CancellationMadness;

  cost = 10;
}

export class CancellationPsychopathyMentalPattern extends MentalPattern {
  name = 'Cancelación: Psicopatía';

  type = MentalPatternType.CancellationPsychopathy;

  cost = 10;
}

export class CancellationCompassionMentalPattern extends MentalPattern {
  name = 'Cancelación: Compasión';

  type = MentalPatternType.CancellationCompassion;

  cost = 10;
}

export class CancellationCowardiceMentalPattern extends MentalPattern {
  name = 'Cancelación: Cobardía';

  type = MentalPatternType.CancellationCowardice;

  cost = 10;
}

export class CancellationValentiaMentalPattern extends MentalPattern {
  name = 'Cancelación: Valentía';

  type = MentalPatternType.CancellationValentia;

  cost = 10;
}

export const mapMentalPatternTypeToTable: Record<
  MentalPatternType,
  { new (): MentalPattern }
> = {
  [MentalPatternType.Extroversion]: ExtroversionMentalPattern,
  [MentalPatternType.Craziness]: CrazinessMentalPattern,
  [MentalPatternType.Psychopathy]: PsychopathyMentalPattern,
  [MentalPatternType.Compassion]: CompassionMentalPattern,
  [MentalPatternType.Cowardice]: CowardiceMentalPattern,
  [MentalPatternType.Courage]: CourageMentalPattern,
  [MentalPatternType.CancellationIntroversion]:
    CancellationIntroversionMentalPattern,
  [MentalPatternType.CancellationExtroversion]:
    CancellationExtroversionMentalPattern,
  [MentalPatternType.CancellationMadness]: CancellationMadnessMentalPattern,
  [MentalPatternType.CancellationPsychopathy]:
    CancellationPsychopathyMentalPattern,
  [MentalPatternType.CancellationCompassion]:
    CancellationCompassionMentalPattern,
  [MentalPatternType.CancellationCowardice]: CancellationCowardiceMentalPattern,
  [MentalPatternType.CancellationValentia]: CancellationValentiaMentalPattern,
};
