import { Calculation } from './NumberField';
import { DevelopedPDFieldModel } from './DevelopedPDFieldModel';

export abstract class DevelopedPDPhysicalFieldModel extends DevelopedPDFieldModel {
  get allPhysicalActionsPenalty(): number {
    if (!this.character) return 0;

    return this.character.modifiers.allPhysicalActions.final;
  }

  get finalWithoutPenalties(): number {
    return super.finalWithoutPenalties - this.allPhysicalActionsPenalty;
  }

  getCalculations(): Calculation[] {
    const calculations: Calculation[] = super.getCalculations();

    if (this.allPhysicalActionsPenalty !== 0) {
      calculations.push(
        this.sum(
          'Penalizador las acciones físicas',
          this.allPhysicalActionsPenalty,
        ),
      );
    }

    return calculations;
  }
}
