import styled from 'styled-components';
import { Wrapper as CharacterItem } from './components/CharacterItem/CharacterItem.styled';

export const Wrapper = styled.div(({ theme: { size, mixins } }) => ({
  display: 'flex',
  flexDirection: 'column',

  gap: size.units(4),

  padding: size.units(2),
  boxSizing: 'border-box',

  ...mixins.responsive.xs.downward({
    width: '100%',

    paddingLeft: size.units(3),
    paddingRight: size.units(3),
  }),
}));

export const Container = styled.div(({ theme: { size, mixins } }) => ({
  display: 'grid',
  gridGap: size.units(6),

  gridTemplateColumns: 'repeat(5, 1fr)',

  paddingBottom: size.units(7),

  ...mixins.responsive.custom('3000').downward({
    gridTemplateColumns: 'repeat(4, 1fr)',
  }),

  ...mixins.responsive.custom('2400').downward({
    gridTemplateColumns: 'repeat(3, 1fr)',
  }),

  ...mixins.responsive.custom('1785').downward({
    gridTemplateColumns: 'repeat(2, 1fr)',
  }),

  ...mixins.responsive.ipad.downward({
    [CharacterItem]: {
      width: size.units(40),
    },
  }),

  ...mixins.responsive.sm.downward({
    gridTemplateColumns: 'repeat(1, 1fr)',
    justifyItems: 'center',
  }),

  width: '100%',
  height: '100%',
}));
