import { DisadvantageLevel1, DisadvantageLevel2 } from './CommonDisadvantages';
import { Disadvantage, DisadvantageType } from './Disadvantage.types';

export const BehaviourCodeDisadvantage: Disadvantage = {
  id: 'disadvantage-background-1',

  name: 'Código de conducta',

  type: DisadvantageType.Background,

  levels: [DisadvantageLevel1],
};

export const PowerfulEnemyDisadvantage: Disadvantage = {
  id: 'disadvantage-background-2',

  name: 'Enemigo poderoso',

  type: DisadvantageType.Background,

  levels: [DisadvantageLevel1, DisadvantageLevel2],
};

export const NoobDisadvantage: Disadvantage = {
  id: 'disadvantage-background-3',

  name: 'Novato',

  type: DisadvantageType.Background,

  levels: [DisadvantageLevel1],
};

export const PariahDisadvantage: Disadvantage = {
  id: 'disadvantage-background-4',

  name: 'Paria',

  type: DisadvantageType.Background,

  levels: [DisadvantageLevel1],
};

export const InconfesableSecretCodeDisadvantage: Disadvantage = {
  id: 'disadvantage-background-5',

  name: 'Secreto inconfesable',

  type: DisadvantageType.Background,

  levels: [DisadvantageLevel1],
};

export const DebtsDisadvantage: Disadvantage = {
  id: 'disadvantage-background-6',

  name: 'Deudas',

  type: DisadvantageType.Background,

  levels: [DisadvantageLevel1],
};

export const AllBackgroundDisadvantages: Disadvantage[] = [
  BehaviourCodeDisadvantage,
  PowerfulEnemyDisadvantage,
  NoobDisadvantage,
  PariahDisadvantage,
  InconfesableSecretCodeDisadvantage,
  DebtsDisadvantage,
];
