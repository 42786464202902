import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import { MenuItem } from '../../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';
import { getMartialArtFromType } from '../../../../../../../../../../../domain/aggregations/martial-arts/getMartialArtFromType';
import { MartialArtType } from '../../../../../../../../../../../domain/aggregations/martial-arts/MartialArt';
import {
  BasicMartialArtGrade,
  MartialArtGradeId,
} from '../../../../../../../../../../../domain/aggregations/martial-arts/MartialArtGrades';
import { MartialArtModel } from '../../../../../../../../../../../domain/character/model/parts/combat/parts/MartialArtModel';
import { AddItemButton } from '../../../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../../../common/CharacterMenu/useCharacterMenu';
import { RemoveItemButton } from '../../../../../common/RemoveItemButton/RemoveItemButton';
import { Table } from '../../../../../common/Table/Table';
import { CombatPDModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/parts/combat/CombatPDModel';

type MartialArtSelectorProps = {
  combat: CombatPDModel;
};

const parseMartialArtErrors = (
  errors: Record<MartialArtGradeId, string[]>,
): string => {
  const allErrors = Object.values(errors);

  return allErrors.reduce((acc, curr, index) => {
    const isLast = index === allErrors.length - 1;

    if (curr.length === 0) {
      return acc;
    }

    if (!isLast) {
      return acc + curr.join(', ') + ', ';
    } else {
      return acc + curr.join(', ');
    }
  }, '');
};

export const MartialArtSelector = observer(
  ({ combat }: MartialArtSelectorProps) => {
    const { handleMenuOpen: handleMenuOpen, Menu: MartialArtMenu } =
      useCharacterMenu<MartialArtType>();

    const {
      handleMenuOpen: handleMartialArtGradesMenuOpen,
      Menu: MartialArtGradesMenu,
    } = useCharacterMenu<BasicMartialArtGrade>();

    const [selected, setSelected] = useState<MartialArtModel>();

    const handleAddMartialArt = () => {
      combat.addMartialArt(MartialArtType.Aikido);
    };

    const handleRemoveMartialArt = (table: MartialArtModel) => () => {
      combat.removeMartialArt(table);
    };

    const martialArts = (Object.values(MartialArtType) as MartialArtType[])
      .map(getMartialArtFromType)
      .map((t) => ({
        key: t.type,
        name: t.name,
        value: t.type,
        onClick: () => {
          selected?.setType(t.type);
        },
      })) as MenuItem<MartialArtType>[];

    const martialArtGrades = selected
      ? selected.martialArt.grades.map((grade) => ({
          key: grade.name,
          name: grade.name,
          value: grade,
          onClick: () => {
            selected?.setGradeId(grade.id);
          },
        }))
      : ([] as MenuItem<BasicMartialArtGrade>[]);

    const someMartialArtsHasErrors = combat.martialArts.some(
      (martialArt) => !martialArt.areRequirementsFulfilled,
    );

    return (
      <>
        <Table>
          <Table.Header>
            <Table.Header.Cell $width={5}>Arte marcial</Table.Header.Cell>
            <Table.Header.Cell>Nivel</Table.Header.Cell>
            <Table.Header.Cell>Coste</Table.Header.Cell>
            <Table.Header.Cell>Bono CM</Table.Header.Cell>
            {someMartialArtsHasErrors ? (
              <Table.Header.Cell $danger $width={10}>
                Errores
              </Table.Header.Cell>
            ) : undefined}
          </Table.Header>
          {combat.martialArts.map((developed, i) => {
            const handleSelection = (e: MouseEvent<HTMLDivElement>) => {
              handleMenuOpen(developed.type)(e);
              setSelected(developed);
            };

            const handleMartialArtGradeSelection = (
              e: MouseEvent<HTMLDivElement>,
            ) => {
              handleMartialArtGradesMenuOpen(developed.grade)(e);
              setSelected(developed);
            };

            const areRequirementsFulfilled = developed.areRequirementsFulfilled;

            return (
              <Table.Row key={`${i}-${developed.type}`}>
                <Table.Row.Cell
                  $danger={!areRequirementsFulfilled}
                  onClick={handleSelection}
                >
                  {developed.martialArtName}
                </Table.Row.Cell>

                <Table.Row.Cell
                  $danger={!areRequirementsFulfilled}
                  onClick={handleMartialArtGradeSelection}
                >
                  {developed.gradeName}
                </Table.Row.Cell>

                <Table.Row.Cell $danger={!areRequirementsFulfilled}>
                  {developed.cost}
                </Table.Row.Cell>

                <Table.Row.Cell $danger={!areRequirementsFulfilled}>
                  {developed.martialKnowledgeBonus !== 0
                    ? developed.martialKnowledgeBonus
                    : '-'}
                </Table.Row.Cell>

                {areRequirementsFulfilled ? undefined : (
                  <Table.Row.Cell $danger>
                    {parseMartialArtErrors(developed.requirementsErrors)}
                  </Table.Row.Cell>
                )}

                <RemoveItemButton onClick={handleRemoveMartialArt(developed)} />
                <MartialArtMenu items={martialArts} />
              </Table.Row>
            );
          })}
          <MartialArtGradesMenu items={martialArtGrades} />
        </Table>
        <AddItemButton onClick={handleAddMartialArt} />
      </>
    );
  },
);
