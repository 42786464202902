import { observer } from 'mobx-react';
import { CategoryModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/CategoryModel';
import { Wrapper } from './MagicInfo.styled';
import { StateSmallParagraph } from '../../../PDsTab.styled';

export const MagicInfo = observer(
  ({ category }: { category: CategoryModel }) => {
    const magicLimit = Math.floor(
      category.pds * category.template.magicSkillsLimitPercentage,
    );
    const magicPDsUsed = category.mystic.pdsSpent;

    return (
      <Wrapper>
        <StateSmallParagraph $errored={magicLimit < magicPDsUsed}>
          PDs de Hab. Místicas ({magicPDsUsed}/{magicLimit})
        </StateSmallParagraph>
      </Wrapper>
    );
  },
);
