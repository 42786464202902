import { Loading } from '../../../../../../shared/application/design-system/components/Loading/Loading';
import { Wrapper } from './CharactersCollaborations.styled';
import { CharacterList } from '../../../../components/CharacterList/CharacterList';
import { EmptyCharacterCollaborationList } from './components/EmptyCharacterList/EmptyCharacterCollaborationList';
import { useFetchCharactersCollaborations } from './hooks/useFetchCharactersCollaborations';

export const CharactersCollaborations = () => {
  const { characters, loadingCharacters } = useFetchCharactersCollaborations();

  return (
    <Wrapper>
      {loadingCharacters ? (
        <Loading centered />
      ) : characters.length > 0 ? (
        <CharacterList characters={characters} />
      ) : (
        <EmptyCharacterCollaborationList />
      )}
    </Wrapper>
  );
};
