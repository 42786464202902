import { v4 as uuid } from 'uuid';
import { getKiSkillFromType } from '../../../../../../character/domain/aggregations/ki-skill/KiSkill';
import { getNemesisSkillFromType } from '../../../../../../character/domain/aggregations/nemesis-skill/NemesisSkill';
import { mergeDeep } from '../../../../../../shared/utils/mergeDeep';
import { ActorMutator } from '../../../ActorCharacterTransformer';
import { FoundryVTTActor } from '../FoundryVTTActor';
import { ABFItems } from '../FoundryVTTActor.types';
import { getAllItemsOf } from '../../../../../../character/domain/character/model/utils/getTotalOf';

export const mutateActorKi: ActorMutator<FoundryVTTActor> = ({
  actor,
  character,
}) => {
  actor.data.domine.kiAccumulation.agility = mergeDeep(
    actor.data.domine.kiAccumulation.agility,
    {
      accumulated: {
        value: actor.data.domine.kiAccumulation.agility.accumulated.value,
      },
      base: {
        value:
          character.content.ki.kiAccumulationPoints.agility
            .finalWithoutPenalties,
      },
    },
  );

  actor.data.domine.kiAccumulation.strength = mergeDeep(
    actor.data.domine.kiAccumulation.strength,
    {
      accumulated: {
        value: actor.data.domine.kiAccumulation.strength.accumulated.value,
      },
      base: {
        value:
          character.content.ki.kiAccumulationPoints.strength
            .finalWithoutPenalties,
      },
    },
  );

  actor.data.domine.kiAccumulation.constitution = mergeDeep(
    actor.data.domine.kiAccumulation.constitution,
    {
      accumulated: {
        value: actor.data.domine.kiAccumulation.constitution.accumulated.value,
      },
      base: {
        value:
          character.content.ki.kiAccumulationPoints.constitution
            .finalWithoutPenalties,
      },
    },
  );

  actor.data.domine.kiAccumulation.dexterity = mergeDeep(
    actor.data.domine.kiAccumulation.dexterity,
    {
      accumulated: {
        value: actor.data.domine.kiAccumulation.dexterity.accumulated.value,
      },
      base: {
        value:
          character.content.ki.kiAccumulationPoints.dexterity
            .finalWithoutPenalties,
      },
    },
  );

  actor.data.domine.kiAccumulation.power = mergeDeep(
    actor.data.domine.kiAccumulation.power,
    {
      accumulated: {
        value: actor.data.domine.kiAccumulation.power.accumulated.value,
      },
      base: {
        value:
          character.content.ki.kiAccumulationPoints.power.finalWithoutPenalties,
      },
    },
  );

  actor.data.domine.kiAccumulation.willPower = mergeDeep(
    actor.data.domine.kiAccumulation.willPower,
    {
      accumulated: {
        value: actor.data.domine.kiAccumulation.willPower.accumulated.value,
      },
      base: {
        value:
          character.content.ki.kiAccumulationPoints.willPower
            .finalWithoutPenalties,
      },
    },
  );

  actor.data.domine.kiAccumulation.generic = {
    value: actor.data.domine.kiAccumulation.generic.value,
    max: character.content.ki.kiPoints.total,
  };

  actor.data.domine.arsMagnus = getAllItemsOf(
    character.content,
    (c) => c.combat.arsMagnus,
  ).map((arsMagnus) => ({
    _id: uuid(),
    type: ABFItems.ARS_MAGNUS,
    name: arsMagnus.name,
    data: {},
  }));

  actor.data.domine.kiSkills = character.content.ki.knownKiSkills.map(
    (kiSkill) => ({
      _id: uuid(),
      type: ABFItems.KI_SKILL,
      name: getKiSkillFromType(kiSkill).name,
      data: {},
    }),
  );

  actor.data.domine.martialArts = getAllItemsOf(
    character.content,
    (c) => c.combat.martialArts,
  ).map((martialArt) => ({
    _id: uuid(),
    type: ABFItems.MARTIAL_ART,
    name: martialArt.martialArt.name,
    data: {
      grade: {
        value: martialArt.grade.name,
      },
    },
  }));

  actor.data.domine.martialKnowledge = {
    used: {
      value: character.content.ki.usedMartialKnowledge,
    },
    max: {
      value: character.content.ki.martialKnowledge.finalWithoutPenalties,
    },
  };

  actor.data.domine.nemesisSkills = character.content.ki.nemesisSkills.map(
    (nemesisSkill) => ({
      _id: uuid(),
      type: ABFItems.NEMESIS_SKILL,
      name: getNemesisSkillFromType(nemesisSkill).name,
      data: {},
    }),
  );

  actor.data.domine.techniques = character.content.ki.kiTechniques.map(
    (technique) => ({
      _id: uuid(),
      type: ABFItems.TECHNIQUE,
      name: technique.name,
      data: {
        agility: {
          value: technique.kiCost.agility?.cost ?? 0,
        },
        strength: {
          value: technique.kiCost.strength?.cost ?? 0,
        },
        constitution: {
          value: technique.kiCost.constitution?.cost ?? 0,
        },
        dexterity: {
          value: technique.kiCost.dexterity?.cost ?? 0,
        },
        power: {
          value: technique.kiCost.power?.cost ?? 0,
        },
        willPower: {
          value: technique.kiCost.willPower?.cost ?? 0,
        },
        level: {
          value: technique.level,
        },
        martialKnowledge: {
          value: technique.mpCost,
        },
        description: { value: technique.effect },
      },
    }),
  );

  return { actor };
};
