import { ReactNode } from 'react';
import { useUserAuthorization } from '../../../hooks/useUserAuthorization';
import { Loading } from '../Loading/Loading';
import {
  Container,
  HeaderContainer,
  LoadingContainer,
  Wrapper,
} from './PageLayout.styled';

interface PageLayoutProps {
  children: ReactNode;

  Header?: ReactNode;

  hasHeader?: boolean;
}

export const PageLayout = ({ children, Header }: PageLayoutProps) => {
  return (
    <Wrapper>
      {Header ? <HeaderContainer>{Header}</HeaderContainer> : undefined}
      <Container>{children}</Container>
    </Wrapper>
  );
};

export const AuthorizedPageLayout = ({
  children,
  ...rest
}: PageLayoutProps) => {
  const { loading } = useUserAuthorization();

  if (loading) {
    return (
      <PageLayout>
        <LoadingContainer>
          <Loading centered />
        </LoadingContainer>
      </PageLayout>
    );
  }

  return <PageLayout {...rest}>{children}</PageLayout>;
};
