import { useState } from 'react';
import {
  Character,
  CharacterPrivacy,
} from '../../../character/domain/character/Character';
import { CharacterModel } from '../../../character/domain/character/model/CharacterModel';
import { AgilityPrimaryFieldModel } from '../../../character/domain/character/model/parts/primaries/fields/AgilityPrimaryFieldModel';
import { ConstitutionPrimaryFieldModel } from '../../../character/domain/character/model/parts/primaries/fields/ConstitutionPrimaryFieldModel';
import { DexterityPrimaryFieldModel } from '../../../character/domain/character/model/parts/primaries/fields/DexterityPrimaryFieldModel';
import { IntelligencePrimaryFieldModel } from '../../../character/domain/character/model/parts/primaries/fields/IntelligencePrimaryFieldModel';
import { PerceptionPrimaryFieldModel } from '../../../character/domain/character/model/parts/primaries/fields/PerceptionPrimaryFieldModel';
import { PowerPrimaryFieldModel } from '../../../character/domain/character/model/parts/primaries/fields/PowerPrimaryFieldModel';
import { StrengthPrimaryFieldModel } from '../../../character/domain/character/model/parts/primaries/fields/StrengthPrimaryFieldModel';
import { WillPowerPrimaryFieldModel } from '../../../character/domain/character/model/parts/primaries/fields/WillPowerPrimaryFieldModel';
import { PrimaryFields } from '../../../character/domain/character/model/parts/primaries/PrimaryFields';
import { System } from '../../../character/domain/system/System';
import { CharacterService } from '../../../character/infrastructure/CharacterService';
import { Routes, useNavigate } from './useNavigate';
import { TrackingService } from '../../infrastructure/tracking/TrackingService';
import { getAuthorizedUser } from '../../../auth/infrastructure/AuthorizationService';

export const useCreateCharacter = () => {
  const { navigate } = useNavigate();

  const [creating, setCreating] = useState(false);

  const createCharacter = (options?: {
    noNavigate?: boolean;
    folderId?: string;
  }) => {
    setCreating(true);

    const character = new Character({
      id: '-1',
      userId: getAuthorizedUser().id,
      system: System.AnimaBeyondFantasy,
      privacy: CharacterPrivacy.Private,
      folderId: options?.folderId,
      content: new CharacterModel({
        primaries: new PrimaryFields({
          power: new PowerPrimaryFieldModel({ base: 5 }),
          willPower: new WillPowerPrimaryFieldModel({ base: 5 }),
          agility: new AgilityPrimaryFieldModel({ base: 5 }),
          constitution: new ConstitutionPrimaryFieldModel({ base: 5 }),
          dexterity: new DexterityPrimaryFieldModel({ base: 5 }),
          intelligence: new IntelligencePrimaryFieldModel({ base: 5 }),
          perception: new PerceptionPrimaryFieldModel({ base: 5 }),
          strength: new StrengthPrimaryFieldModel({ base: 5 }),
        }),
      }),
    });

    return CharacterService.createCharacter(character)
      .then((c) => {
        if (!options?.noNavigate) {
          navigate(Routes.EditCharacter, {
            params: { characterId: c.id.toString() },
          });
        }

        TrackingService.sendEvent('character_created');

        return c;
      })
      .catch((error) => {
        console.error(error);

        throw error;
      })
      .finally(() => {
        setCreating(false);
      });
  };

  return {
    creatingCharacter: creating,
    createCharacter,
  };
};
