import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import { MenuItem } from '../../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';
import {
  WeaponTableItem,
  WeaponTableType,
} from '../../../../../../../../../../../domain/aggregations/tables/weapon-tables/WeaponTable';
import {
  getWeaponTableFromType,
  WeaponTableModel,
} from '../../../../../../../../../../../domain/character/model/parts/combat/parts/WeaponTableModel';
import { AddItemButton } from '../../../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../../../common/CharacterMenu/useCharacterMenu';
import { RemoveItemButton } from '../../../../../common/RemoveItemButton/RemoveItemButton';
import { Table } from '../../../../../common/Table/Table';
import { CombatPDModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/parts/combat/CombatPDModel';

type WeaponTableSelectorProps = {
  combat: CombatPDModel;
};

export const WeaponTableSelector = observer(
  ({ combat }: WeaponTableSelectorProps) => {
    const { handleMenuOpen: handleTableMenuOpen, Menu: WeaponMenu } =
      useCharacterMenu<WeaponTableType>();

    const { handleMenuOpen: handleTableItemsMenuOpen, Menu: WeaponItemsMenu } =
      useCharacterMenu<WeaponTableItem | undefined>();

    const [selectedTable, setSelectedTable] = useState<WeaponTableModel>();

    const handleAddWeaponTable = () => {
      combat.addWeaponTable(WeaponTableType.SimilarWeapon);
    };

    const handleRemoveWeaponTable = (table: WeaponTableModel) => () => {
      combat.removeWeaponTable(table);
    };

    const weaponTables = (Object.values(WeaponTableType) as WeaponTableType[])
      .map(getWeaponTableFromType)
      .map((t) => ({
        key: t.type,
        name: t.name,
        value: t.type,
        onClick: () => {
          selectedTable?.setType(t.type);
        },
      })) as MenuItem<WeaponTableType>[];

    const filteredWeaponTableItems: Array<WeaponTableItem> =
      selectedTable?.table.getFilteredItems(combat.character!) ?? [];

    const weaponTableItems: MenuItem<WeaponTableItem | undefined>[] =
      filteredWeaponTableItems.map((item) => ({
        key: item.name,
        name: item.name,
        value: item,
        onClick: () => {
          selectedTable?.setTableItemSelected(item.id);
        },
      }));
    if (weaponTableItems.length === 0) {
      weaponTableItems.push({
        key: 'none',
        name: 'No cumples condiciones suficientes para elegir una tabla todavía',
        value: undefined,
        onClick: () => {
          // Nothing
        },
      });
    }

    return (
      <>
        <Table>
          <Table.Header>
            <Table.Header.Cell $width={12}>Tabla</Table.Header.Cell>
            <Table.Header.Cell>Coste</Table.Header.Cell>
            <Table.Header.Cell>Tipo</Table.Header.Cell>
          </Table.Header>

          {combat.weaponTables.map((developedTable, i) => {
            const handleTableSelection = (e: MouseEvent<HTMLDivElement>) => {
              handleTableMenuOpen(developedTable.type)(e);
              setSelectedTable(developedTable);
            };

            const handleTableItemSelection = (
              e: MouseEvent<HTMLDivElement>,
            ) => {
              handleTableItemsMenuOpen(developedTable.selection)(e);
              setSelectedTable(developedTable);
            };

            return (
              <Table.Row key={`${i}-${developedTable.type}`}>
                <Table.Row.Cell onClick={handleTableSelection}>
                  {developedTable.name}
                </Table.Row.Cell>
                <Table.Row.Cell>{developedTable.cost}</Table.Row.Cell>
                <Table.Row.Cell onClick={handleTableItemSelection}>
                  {developedTable.selection?.name ?? '--'}
                </Table.Row.Cell>
                <RemoveItemButton
                  onClick={handleRemoveWeaponTable(developedTable)}
                />

                <WeaponMenu items={weaponTables} />
              </Table.Row>
            );
          })}

          <WeaponItemsMenu items={weaponTableItems} />
        </Table>
        <AddItemButton onClick={handleAddWeaponTable} />
      </>
    );
  },
);
