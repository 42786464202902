import { Character, CharacterConstructorParams } from './Character';
import { CharacterDatabaseRow } from '../../infrastructure/CharacterService';
import { User } from '@supabase/supabase-js';

export type CharacterHitUser = {
  avatarUrl?: string;
  username: string;
};

export type CharacterHitConstructorParams = CharacterConstructorParams & {
  user: CharacterHitUser;
};

export class CharacterHit extends Character {
  user: CharacterHitUser;

  constructor(args: CharacterHitConstructorParams) {
    super(args);

    this.user = args.user;
  }

  static fromPrimitives(
    args: CharacterDatabaseRow & {
      user: User;
    },
  ) {
    return new CharacterHit({
      ...Character.fromPrimitives(args),
      user: {
        username: args.user.user_metadata.username,
        avatarUrl: args.user.user_metadata.avatar_url,
      },
    });
  }
}
