import { ReactNode } from 'react';
import { TableHeaderCell } from './components/TableHeaderCell/TableHeaderCell';
import { StyledHeaderRow } from './TableHeaderRow.styled';

type TableCellProps = {
  children: ReactNode | ReactNode[];
};

export const TableHeaderRow = ({ children }: TableCellProps) => {
  return <StyledHeaderRow>{children}</StyledHeaderRow>;
};

TableHeaderRow.Cell = TableHeaderCell;
