import { model } from 'mobx-keystone';
import { PDFieldModel } from '../../PDFieldModel';

@model('Character/PD/Combat/DodgeAbilityPD')
export class DodgeAbilityPDFieldModel extends PDFieldModel {
  get pdsCost(): number {
    return this.template.dodgePDsCost;
  }

  get perCategory(): number {
    return this.template.calculateDodgeBonus();
  }
}
