import { CharacterIssue } from '../../../../character/domain/character/model/utils/issues/CharacterIssue';

const CharacterIssueTranslations: Record<CharacterIssue, string> = {
  [CharacterIssue.AttackDefenseUnbalance]:
    'No puede haber más de 50 puntos de diferencia entre la habilidad de ataque y defensa',
  [CharacterIssue.OffensiveSkillsExceed]:
    'Exceso de puntos en las habilidades ofensivas',
  [CharacterIssue.OnlyDefenseDevelopmentExceed]:
    'La defensa no puede superar la mitad de los puntos de desarrollo en las habilidades ofensivas',
  [CharacterIssue.CMUsedExceeded]: 'Exceso de puntos de CM usados',
  [CharacterIssue.PDUsedExceeded]:
    'Exceso de puntos de PD usados en alguna categoría',
  [CharacterIssue.PsychicPointsUsedExceeded]: 'Exceso de CV usados',
};

export const translateCharacterIssue = (issue: CharacterIssue): string => {
  return CharacterIssueTranslations[issue];
};
