import styled from 'styled-components';
import { SmallParagraph } from '../../../Texts/Texts.styled';

export const ButtonText = styled(SmallParagraph)<{ $selected: boolean }>(
  ({ theme: { size, color, font }, $selected }) => ({
    fontSize: size.units(1.5),
    fontWeight: font.weight.semiBold,
    color: $selected ? color.white() : color.primary(),
  }),
);

export const Wrapper = styled.div<{ $selected: boolean }>(
  ({ theme: { size, color, transition }, $selected }) => ({
    width: 'auto',
    height: size.units(2),
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: '1',
      top: '3px',
      left: '3px',
      right: '3px',
      bottom: '3px',
      borderRadius: '6px',
      border: `${$selected ? '2px' : '1px'} solid ${
        $selected ? color.whiteSmoke() : color.primary()
      }`,
    },
    borderRadius: '6px',
    backgroundColor: $selected ? color.primary() : color.whiteSmoke(),

    cursor: 'pointer',

    '&:hover': {
      '&:before': {
        border: `2px solid ${color.white()}`,
      },
      backgroundColor: color.primary(),

      [ButtonText]: {
        color: color.white(),
      },
    },

    transition: transition.standard,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    padding: `${size.units(1)} ${size.units(2)}`,
  }),
);
