import { CharacterModel } from '../../character/model/CharacterModel';
import { NephilimDAnjayni } from '../nephilim-type/Nephilim';

export enum KiSkillType {
  KiUse = 'KiUse',
  KiControl = 'KiControl',
  KiDetection = 'KiDetection',
  Erudition = 'Erudition',
  CombatAura = 'CombatAura',
  PhysicDomain = 'PhysicDomain',
  PhysicChange = 'PhysicChange',
  MajorPhysicChange = 'MajorPhysicChange',
  BodyCloning = 'BodyCloning',
  MajorBodyCloning = 'MajorBodyCloning',
  ArcaneBodyCloning = 'ArcaneBodyCloning',
  Magnitude = 'Magnitude',
  ArcaneMagnitude = 'ArcaneMagnitude',
  AgeControl = 'AgeControl',
  TechniqueImitation = 'TechniqueImitation',
  ForceTechnique = 'ForceTechnique',
  WeightRemoval = 'WeightRemoval',
  Levitation = 'Levitation',
  ObjectMovement = 'ObjectMovement',
  MassMovement = 'MassMovement',
  Fly = 'Fly',
  PresenceExtrusion = 'PresenceExtrusion',
  EnergyArmour = 'EnergyArmour',
  MajorEnergyArmour = 'MajorEnergyArmour',
  ArcaneEnergyArmour = 'ArcaneEnergyArmour',
  AuraExtensionToWeapon = 'AuraExtensionToWeapon',
  FireElementalAttack = 'FireElementalAttack',
  AirElementalAttack = 'AirElementalAttack',
  WaterElementalAttack = 'WaterElementalAttack',
  EarthElementalAttack = 'EarthElementalAttack',
  LightElementalAttack = 'LightElementalAttack',
  DarkElementalAttack = 'DarkElementalAttack',
  DamageIncremented = 'DamageIncremented',
  RangedIncremented = 'RangedIncremented',
  SpeedIncremented = 'SpeedIncremented',
  KiDestruction = 'KiDestruction',
  EnergyAbsorption = 'EnergyAbsorption',
  PhysicShield = 'PhysicShield',
  KiTransmission = 'KiTransmission',
  KiCure = 'KiCure',
  MajorKiCure = 'MajorKiCure',
  Stabilize = 'Stabilize',
  VitalSacrifice = 'VitalSacrifice',
  NeededEnergyUse = 'NeededEnergyUse',
  KiHide = 'KiHide',
  HiddenAura = 'HiddenAura',
  FakeDeath = 'FakeDeath',
  NeededRemoval = 'NeededRemoval',
  FireImmunity = 'FireImmunity',
  ColdImmunity = 'ColdImmunity',
  ElectricityImmunity = 'ElectricityImmunity',
  PenalizationRemoval = 'PenalizationRemoval',
  Recovery = 'Recovery',
  OtherRecovery = 'OtherRecovery',
  CharacteristicIncrement = 'CharacteristicIncrement',
  MajorCharacteristicIncrement = 'MajorCharacteristicIncrement',
  ImprovisedCombatTechniques = 'ImprovisedCombatTechniques',
  Inhumanity = 'Inhumanity',
  Zen = 'Zen',
}

export type KiSkill = {
  name: string;

  type: KiSkillType;

  calculateCost: (character: CharacterModel) => number;

  parents?: KiSkill[];
};

export const KiUseSkill: KiSkill = {
  name: 'Uso del Ki',
  type: KiSkillType.KiUse,
  calculateCost: () => 40,
};

export const KiControlSkill: KiSkill = {
  name: 'Control del Ki',
  type: KiSkillType.KiControl,
  calculateCost: () => 30,
  parents: [KiUseSkill],
};

export const KiDetectionSkill: KiSkill = {
  name: 'Detección del Ki',
  type: KiSkillType.KiDetection,
  calculateCost: () => 20,
  parents: [KiControlSkill],
};

export const EruditionSkill: KiSkill = {
  name: 'Erudición',
  type: KiSkillType.Erudition,
  calculateCost: () => 10,
  parents: [KiDetectionSkill],
};

export const CombatAuraSkill: KiSkill = {
  name: 'Aura de combate',
  type: KiSkillType.CombatAura,
  calculateCost: () => 40,
  parents: [KiControlSkill],
};

export const PhysicDomainSkill: KiSkill = {
  name: 'Dominio físico',
  type: KiSkillType.PhysicDomain,
  calculateCost: () => 10,
  parents: [KiControlSkill],
};

export const PhysicChangeSkill: KiSkill = {
  name: 'Cambio físico',
  type: KiSkillType.PhysicChange,
  calculateCost: () => 30,
  parents: [PhysicDomainSkill],
};

export const MajorPhysicChangeSkill: KiSkill = {
  name: 'Cambio físico mayor',
  type: KiSkillType.MajorPhysicChange,
  calculateCost: () => 20,
  parents: [PhysicChangeSkill],
};

export const BodyCloningSkill: KiSkill = {
  name: 'Multiplicación de cuerpos',
  type: KiSkillType.BodyCloning,
  calculateCost: () => 30,
  parents: [PhysicDomainSkill],
};

export const MajorBodyCloningSkill: KiSkill = {
  name: 'Multiplicación de cuerpos mayor',
  type: KiSkillType.MajorBodyCloning,
  calculateCost: () => 30,
  parents: [BodyCloningSkill],
};

export const ArcaneBodyCloningSkill: KiSkill = {
  name: 'Multiplicación de cuerpos arcana',
  type: KiSkillType.ArcaneBodyCloning,
  calculateCost: () => 40,
  parents: [MajorBodyCloningSkill],
};

export const MagnitudeSkill: KiSkill = {
  name: 'Magnitud',
  type: KiSkillType.Magnitude,
  calculateCost: () => 30,
  parents: [BodyCloningSkill],
};

export const ArcaneMagnitudeSkill: KiSkill = {
  name: 'Magnitud arcana',
  type: KiSkillType.ArcaneMagnitude,
  calculateCost: () => 40,
  parents: [MagnitudeSkill],
};

export const AgeControlSkill: KiSkill = {
  name: 'Control de la edad',
  type: KiSkillType.AgeControl,
  calculateCost: () => 20,
  parents: [PhysicDomainSkill],
};

export const TechniqueImitationSkill: KiSkill = {
  name: 'Imitación de técnicas',
  type: KiSkillType.TechniqueImitation,
  calculateCost: () => 50,
  parents: [KiControlSkill],
};

export const ForceTechniqueSkill: KiSkill = {
  name: 'Forzar técnica',
  type: KiSkillType.ForceTechnique,
  calculateCost: () => 20,
  parents: [KiControlSkill],
};

export const WeightRemovalSkill: KiSkill = {
  name: 'Eliminación de peso',
  type: KiSkillType.WeightRemoval,
  calculateCost: () => 10,
  parents: [KiUseSkill],
};

export const LevitationSkill: KiSkill = {
  name: 'Levitación',
  type: KiSkillType.Levitation,
  calculateCost: () => 20,
  parents: [WeightRemovalSkill],
};

export const ObjectMovementSkill: KiSkill = {
  name: 'Movimiento de objetos',
  type: KiSkillType.ObjectMovement,
  calculateCost: () => 10,
  parents: [LevitationSkill],
};

export const MassMovementSkill: KiSkill = {
  name: 'Movimiento de masas',
  type: KiSkillType.MassMovement,
  calculateCost: () => 20,
  parents: [ObjectMovementSkill],
};

export const FlySkill: KiSkill = {
  name: 'Vuelo',
  type: KiSkillType.Fly,
  calculateCost: () => 20,
  parents: [LevitationSkill],
};

export const PresenceExtrusionSkill: KiSkill = {
  name: 'Extrusión de presencia',
  type: KiSkillType.PresenceExtrusion,
  calculateCost: () => 10,
  parents: [KiUseSkill],
};

export const EnergyArmourSkill: KiSkill = {
  name: 'Armadura de energía',
  type: KiSkillType.EnergyArmour,
  calculateCost: () => 10,
  parents: [PresenceExtrusionSkill],
};

export const MajorEnergyArmourSkill: KiSkill = {
  name: 'Armadura de energía mayor',
  type: KiSkillType.MajorEnergyArmour,
  calculateCost: () => 10,
  parents: [EnergyArmourSkill],
};

export const ArcaneEnergyArmourSkill: KiSkill = {
  name: 'Armadura de energía arcana',
  type: KiSkillType.ArcaneEnergyArmour,
  calculateCost: () => 10,
  parents: [MajorEnergyArmourSkill],
};

export const AuraExtensionToWeaponSkill: KiSkill = {
  name: 'Extensión de aura al arma',
  type: KiSkillType.AuraExtensionToWeapon,
  calculateCost: () => 10,
  parents: [PresenceExtrusionSkill],
};

export const FireElementalAttackSkill: KiSkill = {
  name: 'Ataque elemental de fuego',
  type: KiSkillType.FireElementalAttack,
  calculateCost: () => 10,
  parents: [AuraExtensionToWeaponSkill],
};

export const AirElementalAttackSkill: KiSkill = {
  name: 'Ataque elemental de aire',
  type: KiSkillType.AirElementalAttack,
  calculateCost: () => 10,
  parents: [AuraExtensionToWeaponSkill],
};

export const WaterElementalAttackSkill: KiSkill = {
  name: 'Ataque elemental de agua',
  type: KiSkillType.WaterElementalAttack,
  calculateCost: () => 10,
  parents: [AuraExtensionToWeaponSkill],
};

export const EarthElementalAttackSkill: KiSkill = {
  name: 'Ataque elemental de tierra',
  type: KiSkillType.EarthElementalAttack,
  calculateCost: () => 10,
  parents: [AuraExtensionToWeaponSkill],
};

export const LightElementalAttackSkill: KiSkill = {
  name: 'Ataque elemental de luz',
  type: KiSkillType.LightElementalAttack,
  calculateCost: () => 10,
  parents: [AuraExtensionToWeaponSkill],
};

export const DarkElementalAttackSkill: KiSkill = {
  name: 'Ataque elemental de oscuridad',
  type: KiSkillType.DarkElementalAttack,
  calculateCost: () => 10,
  parents: [AuraExtensionToWeaponSkill],
};

export const DamageIncrementedSkill: KiSkill = {
  name: 'Daño incrementado',
  type: KiSkillType.DamageIncremented,
  calculateCost: () => 10,
  parents: [AuraExtensionToWeaponSkill],
};

export const RangedIncrementedSkill: KiSkill = {
  name: 'Alcance incrementado',
  type: KiSkillType.RangedIncremented,
  calculateCost: () => 10,
  parents: [AuraExtensionToWeaponSkill],
};

export const SpeedIncrementedSkill: KiSkill = {
  name: 'Velocidad incrementada',
  type: KiSkillType.SpeedIncremented,
  calculateCost: () => 10,
  parents: [AuraExtensionToWeaponSkill],
};

export const KiDestructionSkill: KiSkill = {
  name: 'Destrucción por ki',
  type: KiSkillType.KiDestruction,
  calculateCost: () => 20,
  parents: [PresenceExtrusionSkill],
};

export const EnergyAbsorptionSkill: KiSkill = {
  name: 'Absorción de energía',
  type: KiSkillType.EnergyAbsorption,
  calculateCost: () => 30,
  parents: [PresenceExtrusionSkill],
};

export const PhysicShieldSkill: KiSkill = {
  name: 'Escudo físico',
  type: KiSkillType.PhysicShield,
  calculateCost: () => 10,
  parents: [PresenceExtrusionSkill],
};

export const KiTransmissionSkill: KiSkill = {
  name: 'Transmisión del Ki',
  type: KiSkillType.KiTransmission,
  calculateCost: () => 10,
  parents: [KiUseSkill],
};

export const KiCureSkill: KiSkill = {
  name: 'Curación por Ki',
  type: KiSkillType.KiCure,
  calculateCost: () => 10,
  parents: [KiTransmissionSkill],
};

export const MajorKiCureSkill: KiSkill = {
  name: 'Curación por Ki superior',
  type: KiSkillType.MajorKiCure,
  calculateCost: () => 10,
  parents: [KiCureSkill],
};

export const StabilizeSkill: KiSkill = {
  name: 'Estabilizar',
  type: KiSkillType.Stabilize,
  calculateCost: () => 10,
  parents: [KiCureSkill],
};

export const VitalSacrificeSkill: KiSkill = {
  name: 'Sacrificio vital',
  type: KiSkillType.VitalSacrifice,
  calculateCost: () => 10,
  parents: [KiTransmissionSkill],
};

export const NeededEnergyUseSkill: KiSkill = {
  name: 'Uso de la energía necesaria',
  type: KiSkillType.NeededEnergyUse,
  calculateCost: () => 10,
  parents: [KiUseSkill],
};

export const KiHideSkill: KiSkill = {
  name: 'Ocultación del Ki',
  type: KiSkillType.KiHide,
  calculateCost: (c) => (c.isNephilim(NephilimDAnjayni) ? 0 : 10),
  parents: [NeededEnergyUseSkill],
};

export const HiddenAuraSkill: KiSkill = {
  name: 'Aura de ocultación',
  type: KiSkillType.HiddenAura,
  calculateCost: () => 10,
  parents: [KiHideSkill],
};

export const FakeDeathSkill: KiSkill = {
  name: 'Falsa muerte',
  type: KiSkillType.FakeDeath,
  calculateCost: () => 10,
  parents: [KiHideSkill],
};

export const NeededRemovalSkill: KiSkill = {
  name: 'Eliminación de necesidades',
  type: KiSkillType.NeededRemoval,
  calculateCost: () => 10,
  parents: [NeededEnergyUseSkill],
};

export const FireImmunitySkill: KiSkill = {
  name: 'Inmunidad al fuego',
  type: KiSkillType.FireImmunity,
  calculateCost: () => 20,
  parents: [NeededRemovalSkill],
};

export const ColdImmunitySkill: KiSkill = {
  name: 'Inmunidad al frío',
  type: KiSkillType.ColdImmunity,
  calculateCost: () => 20,
  parents: [NeededRemovalSkill],
};

export const ElectricityImmunitySkill: KiSkill = {
  name: 'Inmunidad a la electricidad',
  type: KiSkillType.ElectricityImmunity,
  calculateCost: () => 20,
  parents: [NeededRemovalSkill],
};

export const PenalizationRemovalSkill: KiSkill = {
  name: 'Eliminación de penalizadores',
  type: KiSkillType.PenalizationRemoval,
  calculateCost: () => 20,
  parents: [NeededEnergyUseSkill],
};

export const RecoverySkill: KiSkill = {
  name: 'Recuperación',
  type: KiSkillType.Recovery,
  calculateCost: () => 20,
  parents: [PenalizationRemovalSkill],
};

export const OtherRecoverySkill: KiSkill = {
  name: 'Restituir a otros',
  type: KiSkillType.OtherRecovery,
  calculateCost: () => 10,
  parents: [RecoverySkill],
};

export const CharacteristicIncrementSkill: KiSkill = {
  name: 'Aumento de características',
  type: KiSkillType.CharacteristicIncrement,
  calculateCost: () => 20,
  parents: [NeededEnergyUseSkill],
};

export const MajorCharacteristicIncrementSkill: KiSkill = {
  name: 'Aumento de características superior',
  type: KiSkillType.MajorCharacteristicIncrement,
  calculateCost: () => 20,
  parents: [NeededEnergyUseSkill],
};

export const ImprovisedCombatTechniquesSkill: KiSkill = {
  name: 'Técnicas de combate improvisadas',
  type: KiSkillType.ImprovisedCombatTechniques,
  calculateCost: () => 50,
  parents: [KiUseSkill],
};

export const InhumanitySkill: KiSkill = {
  name: 'Inhumanidad',
  type: KiSkillType.Inhumanity,
  calculateCost: () => 30,
  parents: [KiUseSkill],
};

export const ZenSkill: KiSkill = {
  name: 'Zen',
  type: KiSkillType.Zen,
  calculateCost: () => 50,
  parents: [InhumanitySkill],
};

export const KiSkills: KiSkill[] = [
  KiUseSkill,
  KiControlSkill,
  KiDetectionSkill,
  EruditionSkill,
  CombatAuraSkill,
  PhysicDomainSkill,
  PhysicChangeSkill,
  MajorPhysicChangeSkill,
  BodyCloningSkill,
  MajorBodyCloningSkill,
  ArcaneBodyCloningSkill,
  MagnitudeSkill,
  ArcaneMagnitudeSkill,
  AgeControlSkill,
  TechniqueImitationSkill,
  ForceTechniqueSkill,
  WeightRemovalSkill,
  LevitationSkill,
  ObjectMovementSkill,
  MassMovementSkill,
  FlySkill,
  PresenceExtrusionSkill,
  EnergyArmourSkill,
  MajorEnergyArmourSkill,
  ArcaneEnergyArmourSkill,
  AuraExtensionToWeaponSkill,
  FireElementalAttackSkill,
  AirElementalAttackSkill,
  WaterElementalAttackSkill,
  EarthElementalAttackSkill,
  LightElementalAttackSkill,
  DarkElementalAttackSkill,
  DamageIncrementedSkill,
  RangedIncrementedSkill,
  SpeedIncrementedSkill,
  KiDestructionSkill,
  EnergyAbsorptionSkill,
  PhysicShieldSkill,
  KiTransmissionSkill,
  KiCureSkill,
  MajorKiCureSkill,
  StabilizeSkill,
  VitalSacrificeSkill,
  NeededEnergyUseSkill,
  KiHideSkill,
  HiddenAuraSkill,
  FakeDeathSkill,
  NeededRemovalSkill,
  FireImmunitySkill,
  ColdImmunitySkill,
  ElectricityImmunitySkill,
  PenalizationRemovalSkill,
  RecoverySkill,
  OtherRecoverySkill,
  CharacteristicIncrementSkill,
  MajorCharacteristicIncrementSkill,
  ImprovisedCombatTechniquesSkill,
  InhumanitySkill,
  ZenSkill,
];

export const getKiSkillFromType = (type: KiSkillType): KiSkill => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return KiSkills.find((skill) => skill.type === type)!;
};
