import { CharacterModelV0 } from '../v0/CharacterModelV0';
import { CharacterModelV1 } from './CharacterModelV1';

export const migrateFromCharacterModelV0ToV1 = (
  characterModelV0: CharacterModelV0,
): CharacterModelV1 => {
  const migration: CharacterModelV1 =
    characterModelV0 as unknown as CharacterModelV1;

  migration.version = 1;

  migration.pd.categories[0] = {
    ...migration.pd.categories[0],

    combat: {
      ...migration.pd.categories[0].combat,

      weaponTables: characterModelV0.combat.weaponTables,
      styleTables: characterModelV0.combat.styleTables,
      martialArts: characterModelV0.combat.martialArts,
      martialArtWeaponTables: characterModelV0.combat.martialArtWeaponTables,
      arsMagnus: characterModelV0.combat.arsMagnus,
    },
  };

  return migration;
};
