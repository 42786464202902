import { observer } from 'mobx-react';
import {
  KiSkill,
  KiSkills,
  KiSkillType,
} from '../../../../../../../../../../domain/aggregations/ki-skill/KiSkill';
import { Section } from '../../../../common/Section/Section';
import { SkillTree } from '../../../../common/SkillTree/SkillTree';
import {
  ElementNode,
  ElementTree,
  TreeElement,
} from '../../../../common/SkillTree/utils/ElementTree';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { useMemo } from 'react';
import { CharacterModel } from '../../../../../../../../../../domain/character/model/CharacterModel';

export type CalculatedKiSkill = Omit<KiSkill, 'calculateCost' | 'parents'> & {
  cost: number;
  parents?: CalculatedKiSkill[];
};

export const calculateTreeElementFromKiSkill = (
  character: CharacterModel,
): TreeElement<CalculatedKiSkill>[] => {
  const transformKiSkillToTreeElementCalculatedKiSkill = (
    skill: KiSkill,
  ): TreeElement<CalculatedKiSkill> => {
    const cost = skill.calculateCost(character);

    const item: TreeElement<CalculatedKiSkill> = {
      id: skill.type,
      name: skill.name,
      cost,
      parents:
        skill.parents?.map(transformKiSkillToTreeElementCalculatedKiSkill) ??
        [],
    };

    return item;
  };

  return KiSkills.map(transformKiSkillToTreeElementCalculatedKiSkill);
};

export const KiSkillsTree = observer(() => {
  const { character } = useCharacterContext();

  const calculatedKiSkills = useMemo(
    () => calculateTreeElementFromKiSkill(character.content),
    [character.content],
  );

  const KiTree = useMemo(() => {
    return new ElementTree(calculatedKiSkills);
  }, [calculatedKiSkills]);

  const handleNodeClick = (
    node: ElementNode<TreeElement<CalculatedKiSkill>>,
  ) => {
    if (!character.canBeEdited) return;

    const skillType = node.element.id as KiSkillType;

    if (character.content.ki.hasKiSkill(skillType)) {
      if (skillType === KiSkillType.KiUse) {
        character.content.ki.removeAllNemesisSkills();
      }

      character.content.ki.removeKiSkill(skillType);

      node.getAllChildren().forEach((child) => {
        character.content.ki.removeKiSkill(child.element.id as KiSkillType);
      });
    } else {
      node.getParentsUntilRoot().forEach((parent) => {
        character.content.ki.addKiSkill(parent.element.id as KiSkillType);
      });

      character.content.ki.addKiSkill(skillType);
    }
  };

  return (
    <Section maxHeight="fit">
      <Section.Title>Habilidades del Ki</Section.Title>
      <SkillTree
        tree={KiTree}
        selectedElements={calculatedKiSkills.filter((s) =>
          character.content.ki.hasKiSkill(s.id as KiSkillType),
        )}
        onNodeClick={handleNodeClick}
      />
    </Section>
  );
});
