type ItemWithFlags = { flags?: Record<string, any> };

const getFlags = (item: ItemWithFlags) => item.flags?.['characters-vault'];

export const keepCustomItems = <I extends ItemWithFlags>({
  itemsInCharacter,
  itemsInActor,
}: {
  itemsInCharacter: I[];
  itemsInActor: I[];
}): { notOwnedItems: I[]; toCreate: I[]; toDelete: I[] } => {
  const itemsNotCreatedByCharactersVault = itemsInActor.filter(
    (itemInActor) => {
      const itemInActorFlags = getFlags(itemInActor);

      if (!itemInActorFlags) return true;
    },
  );

  const itemsCreatedByCharactersVault = itemsInActor.filter((itemInActor) => {
    const itemInActorFlags = getFlags(itemInActor);

    if (!itemInActorFlags) return false;

    return itemInActorFlags.originalItemId;
  });

  return {
    notOwnedItems: itemsNotCreatedByCharactersVault,
    toCreate: itemsInCharacter,
    toDelete: itemsCreatedByCharactersVault,
  };
};
