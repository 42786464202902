import { Typography } from '@mui/material';
import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const MenuItemElement = styled(Typography)({
  '&&': {
    color: theme.color.black(),
    fontSize: '0.8rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    fontWeight: 'bold',

    ...theme.mixins.responsive.xs.downward({
      fontSize: '0.75rem',
      textAlign: 'center',
    }),

    cursor: 'pointer',
    transition: 'color 0.2s ease-in-out',

    '&:hover': {
      color: theme.color.primary(),
    },
  },
});

export const ListRowText = styled(Typography)({
  '&&': {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: theme.color.black(),
    cursor: 'pointer',

    transition: 'color 0.2s ease-in-out',

    '&:hover': {
      color: theme.color.primary(),
    },
  },
});

export const MenuItemRow = styled.div<{
  $selected?: boolean;
  $divider?: boolean;
}>(({ $selected, $divider }) => {
  const hoverStyles = {
    backgroundColor: theme.color.primary(),

    [`${ListRowText}`]: {
      color: theme.color.white(),
    },
  };

  return {
    padding: '0.25rem 0.75rem',
    transition: 'color 0.2s ease-in-out',

    paddingTop: $divider ? '1rem' : undefined,

    [ListRowText]: {
      fontSize: $divider ? '1rem' : undefined,
      borderBottom: $divider ? `1px solid ${theme.color.primary()}` : undefined,
    },

    ...($selected ? hoverStyles : {}),

    '&:hover': hoverStyles,
  };
});
