import {
  Advantage,
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
  AdvantageType,
} from './Advantage.types';

export enum AeonBloodAdvantageVariation {
  Kagutsuchi = 'Kagutsuchi',
  Boreas = 'Boreas',
  Niflheim = 'Niflheim',
  Rudraksha = 'Rudraksha',
  Ran = 'Ran',
  Toad = 'Toad',
  Phandemonium = 'Phandemonium',
  Shephirah = 'Shephirah',
}

export const AeonBloodAdvantage: Advantage<AeonBloodAdvantageVariation> = {
  id: 'advantage-blood-legacy-1',

  name: 'Sangre de Aeon',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel1],

  variations: [
    {
      id: AeonBloodAdvantageVariation.Kagutsuchi,
      name: 'Kagutsuchi',
    },
    {
      id: AeonBloodAdvantageVariation.Boreas,
      name: 'Boreas',
    },
    {
      id: AeonBloodAdvantageVariation.Niflheim,
      name: 'Niflheim',
    },
    {
      id: AeonBloodAdvantageVariation.Rudraksha,
      name: 'Rudraksha',
    },
    {
      id: AeonBloodAdvantageVariation.Ran,
      name: 'Ran',
    },
    {
      id: AeonBloodAdvantageVariation.Toad,
      name: 'Toad',
    },
    {
      id: AeonBloodAdvantageVariation.Phandemonium,
      name: 'Phandemonium',
    },
    {
      id: AeonBloodAdvantageVariation.Shephirah,
      name: 'Shephirah',
    },
  ],
};

export const DeathEyesAdvantage: Advantage = {
  id: 'advantage-blood-legacy-2',

  name: 'Los Ojos de la Muerte',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel2],
};

export const SoulEyesAdvantage: Advantage = {
  id: 'advantage-blood-legacy-3',

  name: 'Ojos del Alma',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel1],
};

export const MoonLegacyTsukiyomiAdvantage: Advantage = {
  id: 'advantage-blood-legacy-4',

  name: 'Legado de la Luna (Tsukiyomi)',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel1],
};

export const UroborosBloodAdvantage: Advantage = {
  id: 'advantage-blood-legacy-5',

  name: 'La Sangre de Uroboros',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel1],
};

export const GreatBeastsBloodAdvantage: Advantage = {
  id: 'advantage-blood-legacy-6',

  name: 'Sangre de las Grandes Bestias',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const DestinyEyesAdvantage: Advantage = {
  id: 'advantage-blood-legacy-7',

  name: 'Ojos del Destino',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel2],
};

export const EternalBloodAdvantage: Advantage = {
  id: 'advantage-blood-legacy-8',

  name: 'Sangre Eterna',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel1],
};

export const OrochiBloodAdvantage: Advantage = {
  id: 'advantage-blood-legacy-9',

  name: 'Sangre de Orochi',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel1],
};

export const ExistenceEaterAdvantage: Advantage = {
  id: 'advantage-blood-legacy-10',

  name: 'Devorador de Existencia',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel2],
};

export const PoisonEssenceAdvantage: Advantage = {
  id: 'advantage-blood-legacy-11',

  name: 'Esencia Venenosa',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel1],
};

export const NaturalWeaponsAdvantage: Advantage = {
  id: 'advantage-blood-legacy-12',

  name: 'Armas Naturales',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel1, AdvantageLevel2],
};

export const DeadBloodAdvantage: Advantage = {
  id: 'advantage-blood-legacy-13',

  name: 'Sangre de los Muertos',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel1],
};

export enum KamiBloodAdvantageVariation {
  GenusLoci = 'Genus Loci',
  WarLord = 'War Lord',
}

export const KamiBloodAdvantage: Advantage = {
  id: 'advantage-blood-legacy-14',

  name: 'Sangre de Kami',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel1],

  variations: [
    {
      id: KamiBloodAdvantageVariation.GenusLoci,
      name: 'Genus Loci',
    },
    {
      id: KamiBloodAdvantageVariation.WarLord,
      name: 'War Lord',
    },
  ],
};

export const DragonBloodAdvantage: Advantage = {
  id: 'advantage-blood-legacy-14',

  name: 'Sangre de El Dragón',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel1],
};

export const LatentBloodAdvantage: Advantage = {
  id: 'advantage-blood-legacy-15',

  name: 'Sangre Latente',

  type: AdvantageType.BloodLegacy,

  levels: [AdvantageLevel1],
};

export const AllBloodLegacyAdvantages: Advantage[] = [
  AeonBloodAdvantage,
  DeathEyesAdvantage,
  SoulEyesAdvantage,
  MoonLegacyTsukiyomiAdvantage,
  UroborosBloodAdvantage,
  GreatBeastsBloodAdvantage,
  DestinyEyesAdvantage,
  EternalBloodAdvantage,
  OrochiBloodAdvantage,
  ExistenceEaterAdvantage,
  PoisonEssenceAdvantage,
  NaturalWeaponsAdvantage,
  DeadBloodAdvantage,
  KamiBloodAdvantage,
  DragonBloodAdvantage,
  LatentBloodAdvantage,
];
