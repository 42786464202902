import { model, Model, modelAction, prop } from 'mobx-keystone';
import { PsychicPointsPDFieldModel } from './PsychicPointsPDFieldModel';
import { PsychicProjectionPDFieldModel } from './PsychicProjectionPDFieldModel';
import { getParentCharacter } from '../../../../../../../../utils/parenting/getParentCharacter';
import { PsychicTableModel } from './PsychicTableModel';
import { MentalPatternModel } from './MentalPatternModel';
import { PsychicTableType } from '../../../../../../../../aggregations/tables/psychic-tables/PsychicTable';
import { MentalPatternType } from '../../../../../../../../aggregations/mental-patterns/MentalPattern';

@model('Character/PD/PsychicPD')
export class PsychicPDModel extends Model({
  psychicPoints: prop(() => new PsychicPointsPDFieldModel({})),
  psychicProjection: prop(() => new PsychicProjectionPDFieldModel({})),

  tables: prop<PsychicTableModel[]>(() => []),
  mentalPatterns: prop<MentalPatternModel[]>(() => []),
}) {
  @modelAction
  addPsychicTable(type: PsychicTableType) {
    this.tables.push(new PsychicTableModel({ type }));
  }

  @modelAction
  removePsychicTable(table: PsychicTableModel) {
    this.tables = this.tables.filter((t) => t !== table);
  }

  @modelAction
  addMentalPattern(type: MentalPatternType) {
    this.mentalPatterns.push(new MentalPatternModel({ type }));
  }

  @modelAction
  removeMentalPattern(mentalPattern: MentalPatternModel) {
    this.mentalPatterns = this.mentalPatterns.filter(
      (mt) => mt !== mentalPattern,
    );
  }

  get pdsSpent(): number {
    if (!this.character) return 0;

    return (
      this.psychicPoints.base +
      this.psychicProjection.base +
      this.tables.reduce((acc, table) => acc + table.cost, 0) +
      this.mentalPatterns.reduce((acc, pattern) => acc + pattern.cost, 0)
    );
  }

  get character() {
    return getParentCharacter(this);
  }
}
