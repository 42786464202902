import { model, Model, prop } from 'mobx-keystone';
import { AnimalsSkillModel } from './fields/AnimalsSkillModel';
import { AppraisalSkillModel } from './fields/AppraisalSkillModel';
import { HerbalismSkillModel } from './fields/HerbalismSkillModel';
import { HistorySkillModel } from './fields/HistorySkillModel';
import { LawSkillModel } from './fields/LawSkillModel';
import { MagicAppraisalSkillModel } from './fields/MagicAppraisalSkillModel';
import { MedicineSkillModel } from './fields/MedicineSkillModel';
import { MemorizeSkillModel } from './fields/MemorizeSkillModel';
import { NavigationSkillModel } from './fields/NavigationSkillModel';
import { OccultismSkillModel } from './fields/OccultismSkillModel';
import { ScienceSkillModel } from './fields/ScienceSkillModel';
import { TacticsSkillModel } from './fields/TacticsSkillModel';

@model('Character/Secondaries/IntellectualSkills')
export class IntellectualSkillsModel extends Model({
  animals: prop(() => new AnimalsSkillModel({})),
  science: prop(() => new ScienceSkillModel({})),
  law: prop(() => new LawSkillModel({})),
  herbalism: prop(() => new HerbalismSkillModel({})),
  history: prop(() => new HistorySkillModel({})),
  tactics: prop(() => new TacticsSkillModel({})),
  medicine: prop(() => new MedicineSkillModel({})),
  memorize: prop(() => new MemorizeSkillModel({})),
  navigation: prop(() => new NavigationSkillModel({})),
  occultism: prop(() => new OccultismSkillModel({})),
  appraisal: prop(() => new AppraisalSkillModel({})),
  magicAppraisal: prop(() => new MagicAppraisalSkillModel({})),
}) {
  get usedPhysicalNaturalBonusPoints(): number {
    return 0;
  }

  get usedMentalNaturalBonusPoints(): number {
    return (
      this.animals.naturalBonusPoints.base +
      this.science.naturalBonusPoints.base +
      this.law.naturalBonusPoints.base +
      this.herbalism.naturalBonusPoints.base +
      this.history.naturalBonusPoints.base +
      this.tactics.naturalBonusPoints.base +
      this.medicine.naturalBonusPoints.base +
      this.memorize.naturalBonusPoints.base +
      this.navigation.naturalBonusPoints.base +
      this.occultism.naturalBonusPoints.base +
      this.appraisal.naturalBonusPoints.base +
      this.magicAppraisal.naturalBonusPoints.base
    );
  }

  get usedNaturalAbilityPoints(): number {
    return (
      this.animals.naturalAbilityPoints +
      this.science.naturalAbilityPoints +
      this.law.naturalAbilityPoints +
      this.herbalism.naturalAbilityPoints +
      this.history.naturalAbilityPoints +
      this.tactics.naturalAbilityPoints +
      this.medicine.naturalAbilityPoints +
      this.memorize.naturalAbilityPoints +
      this.navigation.naturalAbilityPoints +
      this.occultism.naturalAbilityPoints +
      this.appraisal.naturalAbilityPoints +
      this.magicAppraisal.naturalAbilityPoints
    );
  }

  get usedNovelBonusPoints(): number {
    return (
      this.animals.novelBonus +
      this.science.novelBonus +
      this.law.novelBonus +
      this.herbalism.novelBonus +
      this.history.novelBonus +
      this.tactics.novelBonus +
      this.medicine.novelBonus +
      this.memorize.novelBonus +
      this.navigation.novelBonus +
      this.occultism.novelBonus +
      this.appraisal.novelBonus +
      this.magicAppraisal.novelBonus
    );
  }
}
