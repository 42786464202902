import WarningImage from './assets/warning.png';
import {
  ButtonContainer,
  Content,
  Image,
  Wrapper,
} from './ConfirmModal.styled';
import { BasicParagraph, BasicTitle } from '../Texts/Texts.styled';
import { Button } from '../Button/Button';
import { ButtonVariant } from '../Button/Button.types';

export const ConfirmModal = ({
  title,
  content,

  buttons,

  onConfirm,
  onReject,
}: {
  title: string;
  content: string;

  buttons: {
    confirm: string;
    reject: string;
  };

  onConfirm: () => void;
  onReject: () => void;
}) => {
  return (
    <Wrapper>
      <Image src={WarningImage} />
      <Content>
        <BasicTitle>{title}</BasicTitle>
        <BasicParagraph>{content}</BasicParagraph>
        <ButtonContainer>
          <Button variant={ButtonVariant.Outline} onClick={onReject}>
            {buttons.reject}
          </Button>
          <Button onClick={onConfirm}>{buttons.confirm}</Button>
        </ButtonContainer>
      </Content>
    </Wrapper>
  );
};
