import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class SamboMartialArt extends BasicMartialArt {
  readonly name = 'Sambo';

  readonly complexity = 2;

  readonly type = MartialArtType.Sambo;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      effects: 'Penalizadores a la mitad para derribo y desarmar',
      calculateDamage: (character) =>
        character.primaries.strength.modifier.final + 10,
      calculateHitType: () => [HitType.Impact],
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      effects: 'Penalizadores a la mitad para presa y ataque en área',
      combatBonus: {
        block: 10,
      },
      calculateDamage: (character) =>
        character.primaries.strength.modifier.final + 20,
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.combat.attackAbility.final < 130) {
          errors.push('Ataque 130+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      effects:
        'Penalizadores a la mitad para ataques apuntados. Se acumula con la regla de precisa que tienen los ataques desarmados.',
      combatBonus: {
        block: 10,
      },
      calculateDamage: (character) =>
        character.primaries.strength.modifier.final + 30,
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.combat.attackAbility.final < 200) {
          errors.push('Ataque 200+');
        }

        if (character.combat.defensiveAbility < 200) {
          errors.push('Bloqueo o esquiva 200+');
        }

        return errors;
      },
    }),
  ];
}
