import { ExtendedModel, model, prop } from 'mobx-keystone';
import { SecondarySkill } from '../../../../../../../types/SecondarySkill';
import { SecondarySkillPDFieldModel } from '../SecondarySkillPDFieldModel';
import { SecondarySkillBonusType } from '../SecondarySkillPDFieldModel.types';
import { SecondarySkillsPDModel } from './SecondarySkillsPDModel';

@model('Character/Development/SecondarySkills/CreativeSkillsPD')
export class CreativeSkillsPDModel extends ExtendedModel(
  SecondarySkillsPDModel,
  {
    art: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Art,
          type: SecondarySkillBonusType.WillPower,
        }),
    ),
    dance: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Dance,
          type: SecondarySkillBonusType.Agility,
        }),
    ),
    forging: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Forging,
          type: SecondarySkillBonusType.Dexterity,
        }),
    ),
    runes: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Runes,
          type: SecondarySkillBonusType.Dexterity,
        }),
    ),
    alchemy: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Alchemy,
          type: SecondarySkillBonusType.Intelligence,
        }),
    ),
    animism: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Animism,
          type: SecondarySkillBonusType.WillPower,
        }),
    ),
    music: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Music,
          type: SecondarySkillBonusType.WillPower,
        }),
    ),
    sleightOfHand: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.SleightOfHand,
          type: SecondarySkillBonusType.Dexterity,
        }),
    ),
    ritualCalligraphy: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.RitualCalligraphy,
          type: SecondarySkillBonusType.Dexterity,
        }),
    ),
    jewelry: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Jewelry,
          type: SecondarySkillBonusType.Dexterity,
        }),
    ),
    tailoring: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Tailoring,
          type: SecondarySkillBonusType.Dexterity,
        }),
    ),
    puppetMaking: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.PuppetMaking,
          type: SecondarySkillBonusType.WillPower,
        }),
    ),
  },
) {
  get fields(): SecondarySkillPDFieldModel[] {
    return [
      this.art,
      this.dance,
      this.forging,
      this.runes,
      this.alchemy,
      this.animism,
      this.music,
      this.sleightOfHand,
      this.ritualCalligraphy,
      this.jewelry,
      this.tailoring,
      this.puppetMaking,
    ];
  }
}
