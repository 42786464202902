export const font = {
  family: 'Source Sans Pro' as const,
  weight: {
    light: 300,
    semiLight: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 900,
  } as const,
};
