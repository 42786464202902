import { model } from 'mobx-keystone';
import { DevelopedPDFieldModel } from '../../../utils/fields/DevelopedPDFieldModel';
import { getTotalOf } from '../../../utils/getTotalOf';

@model('Character/Psychic/PsychicPointsField')
export class PsychicPointsModel extends DevelopedPDFieldModel {
  get allActionsPenalty(): number {
    return 0;
  }

  get fromCategories(): number {
    return 1 + getTotalOf(this.character, (c) => c.psychic.psychicPoints.final);
  }
}
