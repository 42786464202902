import { observer } from 'mobx-react';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';
import { CharacterIssue } from '../../../../../../../../../../domain/character/model/utils/issues/CharacterIssue';

export const TotalMartialKnowledgeUsed = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { martialKnowledge } = character.ki;
  const { usedMartialKnowledge } = character.ki;

  return (
    <Section maxHeight="fit">
      <Section.Title>Conocimiento Marcial</Section.Title>
      <Table>
        <Table.Header>
          <Table.Header.Cell>Total</Table.Header.Cell>
          <Table.Header.Cell>Usado</Table.Header.Cell>
        </Table.Header>
        <Table.Row>
          <Table.Row.Cell>{martialKnowledge.final}</Table.Row.Cell>
          <Table.Row.Cell>{usedMartialKnowledge}</Table.Row.Cell>
        </Table.Row>
        {character.hasIssue(CharacterIssue.CMUsedExceeded) && (
          <Table.Row>
            <Table.Row.Cell colSpan={2} $danger $bold>
              Exceso de uso de CM
            </Table.Row.Cell>
          </Table.Row>
        )}
      </Table>
    </Section>
  );
});
