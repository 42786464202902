import { v4 as uuid } from 'uuid';
import { ActorMutator } from '../../../ActorCharacterTransformer';
import { FoundryVTTActor } from '../FoundryVTTActor';
import { ABFItems } from '../FoundryVTTActor.types';
import { createBaseFinalField } from './utils/createBaseFinalField';

export const mutateActorGeneral: ActorMutator<FoundryVTTActor> = ({
  actor,
  character,
}) => {
  const content = character.content;

  actor.name = content.name;

  actor.data.general = {
    ...actor.data.general,
    advantages: content.creationPoints.advantages.map((i) => ({
      _id: uuid(),
      type: ABFItems.ADVANTAGE,
      name: i.name ?? 'Unknown',
      data: {},
    })),
    disadvantages: content.creationPoints.disadvantages.map((i) => ({
      _id: uuid(),
      type: ABFItems.DISADVANTAGE,
      name: i.name ?? 'Unknown',
      data: {},
    })),
    aspect: {
      ...actor.data.general.aspect,
      age: {
        value: content.description.age,
      },
      eyes: {
        value: content.description.eyes,
      },
      gender: {
        value: content.description.gender,
      },
      hair: {
        value: content.description.hair,
      },
      height: {
        value: content.description.height,
      },
      race: {
        value: content.description.race,
      },
      size: {
        value: content.description.size.final,
      },
      weight: {
        value: content.description.weight,
      },
    },
    description: {
      value: content.description.physicalDescription,
    },
    notes: [
      {
        _id: uuid(),
        type: ABFItems.NOTE,
        name: content.description.notes,
        data: {},
      },
    ],
    levels: content.categories.map((category) => ({
      _id: uuid(),
      type: ABFItems.LEVEL,
      name: category.template.name,
      data: {
        level: category.level.final,
      },
    })),
    presence: createBaseFinalField(content.presence.final),
    settings: {
      ...actor.data.general.settings,
      defenseType: {
        value: content.options.isAccumulationCreature ? 'resistance' : '',
      },
    },
  };

  return { actor };
};
