import { observer } from 'mobx-react';
import { MouseEvent } from 'react';
import {
  Action,
  FolderIcon,
  FolderIconAndNameContainer,
  FolderName,
  OptionsIcon,
  Wrapper,
} from './FolderItem.styled';
import { useConfirmModal } from '../../../../../../shared/application/hooks/useConfirmModal';
import { Folder } from '../../../../../domain/folder/Folder';
import { Loading } from '../../../../../../shared/application/design-system/components/Loading/Loading';
import { useDeleteFolder } from '../../../../hooks/useDeleteFolder';
import {
  MenuItem,
  useMenu,
} from '../../../../../../shared/application/design-system/components/Menu/useMenu';
import { FolderDragging } from '../../../FolderDragging/FolderDragging';
import { useMoveFolder } from '../../../../hooks/useMoveFolder';
import { useMoveCharacter } from '../../../../hooks/useMoveCharacter';
import { TrackingService } from '../../../../../../shared/infrastructure/tracking/TrackingService';

interface FolderItemProps {
  folder: Folder;

  onClick: () => void;
  onDelete: () => void;

  afterMoveFolderToFolder: () => void;
}

export const FolderItem = observer(
  ({
    folder,

    onDelete,
    onClick,

    afterMoveFolderToFolder,
  }: FolderItemProps) => {
    const { movingFolder, moveFolder } = useMoveFolder();
    const { movingCharacter, moveCharacter } = useMoveCharacter();
    const { deletingFolder, deleteFolder } = useDeleteFolder();

    const { Menu, handleMenuOpen } = useMenu();
    const { openConfirmModal, ConfirmModal } = useConfirmModal();

    const handleFolderClick = (e: MouseEvent) => {
      e.stopPropagation();

      onClick();
    };

    const handleFolderRemoveClick = async (e: MouseEvent) => {
      e.stopPropagation();

      const confirm = await openConfirmModal({
        title: '¿Lo confirmas?',
        content:
          'Esta acción eliminará todo el contenido de la carpeta. No se puede deshacer',
      });

      if (!confirm) return;

      deleteFolder(folder.id).then(() => onDelete());
    };

    const moveFolderToFolder = (params: {
      destinationFolderId: string;
      draggedFolderId: string;
    }) => {
      moveFolder(params.draggedFolderId, params.destinationFolderId).then(
        () => {
          afterMoveFolderToFolder();

          TrackingService.sendEvent('folder_moved');
        },
      );
    };

    const moveCharacterToFolder = (params: {
      characterId: string;
      folderId: string;
    }) => {
      moveCharacter(params.characterId, params.folderId).then(() => {
        afterMoveFolderToFolder();

        TrackingService.sendEvent('character_moved', {
          characterId: params.characterId,
        });
      });
    };

    const menuItems: MenuItem[] = [
      {
        key: 'delete',
        name: 'Eliminar',
        value: 'delete',
        onClick: (e) => handleFolderRemoveClick(e),
      },
    ];

    const loading = movingFolder || movingCharacter || deletingFolder;

    const handleMenuClick = (e: MouseEvent) => {
      if (loading) return;

      handleMenuOpen()(e);
    };

    return (
      <>
        <FolderDragging
          folderId={folder.id}
          onFolderDrop={moveFolderToFolder}
          onCharacterDrop={moveCharacterToFolder}
        >
          {({ isDragging, isOver }) => (
            <Wrapper $isDragging={isDragging} $isOver={isOver}>
              <FolderIconAndNameContainer onClick={handleFolderClick}>
                {loading ? <Loading size={1} /> : <FolderIcon />}
                <FolderName onClick={handleFolderClick}>
                  {folder.name}
                </FolderName>
              </FolderIconAndNameContainer>
              <Action>
                {loading ? (
                  <Loading size={1} />
                ) : (
                  <OptionsIcon onClick={handleMenuClick} />
                )}
              </Action>
            </Wrapper>
          )}
        </FolderDragging>
        <ConfirmModal />
        <Menu items={menuItems} />
      </>
    );
  },
);
