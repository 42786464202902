import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import { MenuItem } from '../../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';
import { Advantage } from '../../../../../../../../../../../domain/aggregations/advantage/Advantage.types';
import { Disadvantage } from '../../../../../../../../../../../domain/aggregations/disadvantage/Disadvantage.types';
import { AdvantageModel } from '../../../../../../../../../../../domain/character/model/parts/creation-points/AdvantageModel';
import { DisadvantageModel } from '../../../../../../../../../../../domain/character/model/parts/creation-points/DisadvantageModel';
import { AddItemButton } from '../../../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../../../common/CharacterMenu/useCharacterMenu';
import { RemoveItemButton } from '../../../../../common/RemoveItemButton/RemoveItemButton';
import { Table } from '../../../../../common/Table/Table';

type SelectorProps<
  M extends AdvantageModel | DisadvantageModel,
  A extends Advantage | Disadvantage,
> = {
  elements: M[];

  onElementAdd: () => void;
  onElementChange: (element: M, selection: A) => void;
  onElementRemove: (element: M) => void;

  allElements: A[];
};

export const Selector = observer(
  <
    M extends AdvantageModel | DisadvantageModel,
    A extends Advantage | Disadvantage,
  >({
    elements,
    allElements,

    onElementAdd,
    onElementRemove,
    onElementChange,
  }: SelectorProps<M, A>) => {
    const { handleMenuOpen: handleMenuOpen, Menu: AdvantageMenu } =
      useCharacterMenu<A['id']>({ sorted: true });

    const {
      handleMenuOpen: handleAdvantageLevelsMenuOpen,
      Menu: AdvantageLevelsMenu,
    } = useCharacterMenu<A['levels'][number]>();

    const {
      handleMenuOpen: handleAdvantageVariationsMenuOpen,
      Menu: AdvantageVariationsMenu,
    } = useCharacterMenu<any>();

    const [selectedAdvantage, setSelectedAdvantage] = useState<M>();

    const handleAddAdvantage = () => {
      onElementAdd();
    };

    const handleRemoveAdvantage = (table: M) => () => {
      onElementRemove(table);
    };

    const advantages = allElements.map((t) => {
      const buildName = () => {
        if (t.levels.length <= 1) {
          return t.name;
        }

        return `${t.name} (${t.levels
          .map((l) => l.name.split('').pop())
          .join(', ')})`;
      };

      return {
        key: t.id,
        name: buildName(),
        value: t.id,
        onClick: () => {
          if (selectedAdvantage) {
            onElementChange(selectedAdvantage, t);
          }
        },
      };
    }) as MenuItem<A['id']>[];

    const advantageLevels = selectedAdvantage
      ? selectedAdvantage.levels.map((level) => ({
          key: level.id,
          name: level.name,
          value: level,
          onClick: () => {
            selectedAdvantage?.setLevelId(level.id);
          },
        }))
      : ([] as MenuItem<A['levels'][number]>[]);

    const advantageVariations = selectedAdvantage?.variations
      ? selectedAdvantage.variations.map((variation) => ({
          key: variation.id,
          name: variation.name,
          value: variation,
          onClick: () => {
            selectedAdvantage?.setVariationId(variation.id);
          },
        })) ?? []
      : ([] as MenuItem<any>[]);

    return (
      <>
        <Table>
          <Table.Header>
            <Table.Header.Cell $width={13} />
            <Table.Header.Cell $width={5}>Nivel</Table.Header.Cell>
            <Table.Header.Cell $width={5}>Variación</Table.Header.Cell>
          </Table.Header>
          {elements.map((element, i) => {
            const hasMoreThan1Level =
              element.levels !== undefined && element.levels.length > 1;
            const hasVariations =
              element.variations !== undefined && element.variations.length > 1;

            const handleSelection = (e: MouseEvent<HTMLDivElement>) => {
              handleMenuOpen(element.id)(e);
              setSelectedAdvantage(element);
            };

            const handleAdvantageLevelSelection = (
              e: MouseEvent<HTMLDivElement>,
            ) => {
              if (hasMoreThan1Level && element.level) {
                handleAdvantageLevelsMenuOpen(element.level)(e);
                setSelectedAdvantage(element);
              }
            };

            const handleAdvantageVariationSelection = (
              e: MouseEvent<HTMLDivElement>,
            ) => {
              if (element.variation) {
                handleAdvantageVariationsMenuOpen(element.variation)(e);
                setSelectedAdvantage(element);
              }
            };

            return (
              <Table.Row key={`${i}-${element.id}`}>
                <Table.Row.Cell onClick={handleSelection}>
                  {element.name}
                </Table.Row.Cell>

                <Table.Row.Cell
                  onClick={
                    hasMoreThan1Level
                      ? handleAdvantageLevelSelection
                      : undefined
                  }
                >
                  {element.level?.name ?? '--'}
                </Table.Row.Cell>
                <Table.Row.Cell
                  onClick={
                    hasVariations
                      ? handleAdvantageVariationSelection
                      : undefined
                  }
                >
                  {element.variation?.name ?? '--'}
                </Table.Row.Cell>

                <RemoveItemButton onClick={handleRemoveAdvantage(element)} />
                <AdvantageMenu items={advantages} />
              </Table.Row>
            );
          })}

          <AdvantageLevelsMenu items={advantageLevels} />
          <AdvantageVariationsMenu items={advantageVariations} />
        </Table>

        <AddItemButton onClick={handleAddAdvantage} />
      </>
    );
  },
);
