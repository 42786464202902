import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class SooBahkMartialArt extends BasicMartialArt {
  readonly name = 'Soo Bahk';

  readonly complexity = 2;

  readonly type = MartialArtType.SooBahk;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      effects: 'Mitad de penalizador por ser atacado desde flancos.',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 10;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.perceptive.notice.final < 50) {
          errors.push('Advertir 50+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      effects:
        'Sin penalizador por ser atacado desde flancos. Puede atacar a sus flancos sin encarar a su enemigo.',
      combatBonus: {
        block: 10,
        dodge: 10,
      },
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 20;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.perceptive.notice.final < 90) {
          errors.push('Advertir 90+');
        }

        if (character.combat.defensiveAbility < 120) {
          errors.push('Bloqueo o esquiva 120+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      effects: 'Sin penalizador si está derribado.',
      combatBonus: {
        block: 10,
        dodge: 10,
      },
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 30;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.perceptive.notice.final < 120) {
          errors.push('Advertir 120+');
        }

        if (character.secondaries.athletic.acrobatics.final < 60) {
          errors.push('Acrobacias 60+');
        }

        if (character.combat.defensiveAbility < 200) {
          errors.push('Bloqueo o esquiva 200+');
        }

        return errors;
      },
    }),
  ];
}
