import { Model, prop } from 'mobx-keystone';
import { getParentCharacter } from '../../../../utils/parenting/getParentCharacter';
import { computed } from 'mobx';

export type Calculation = {
  reason: string;
  value: number;
  operator: '+' | '-' | '*' | '/';

  options?: {
    hideOnZero?: boolean;
  };
};

export abstract class NumberField extends Model({
  special: prop(0).withSetter(),
}) {
  @computed
  get final(): number {
    const calculations = this.getCalculations();

    return this.calculateFromCalculations(calculations);
  }

  get character() {
    return getParentCharacter(this);
  }

  sum(
    reason: string,
    value: number,
    options?: Calculation['options'],
  ): Calculation {
    return {
      reason,
      value,
      operator: '+',
      options,
    };
  }

  sub(
    reason: string,
    value: number,
    options?: Calculation['options'],
  ): Calculation {
    return {
      reason,
      value,
      operator: '-',
      options,
    };
  }

  mul(
    reason: string,
    value: number,
    options?: Calculation['options'],
  ): Calculation {
    return {
      reason,
      value,
      operator: '*',
      options,
    };
  }

  div(
    reason: string,
    value: number,
    options?: Calculation['options'],
  ): Calculation {
    return {
      reason,
      value,
      operator: '/',
      options,
    };
  }

  floor(
    calculations: Calculation[],
    options?: Calculation['options'],
  ): Calculation {
    const value = this.calculateFromCalculations(calculations);

    return {
      reason: 'Redondeo hacia abajo',
      value: value - Math.floor(value),
      operator: '-',
      options,
    };
  }

  max(
    reason: string,
    max: number,
    calculations: Calculation[],
    options?: Calculation['options'],
  ): Calculation {
    const value = this.calculateFromCalculations(calculations);

    if (value > max) {
      return {
        reason: `${reason} (máximo ${max})`,
        value: value - max,
        operator: '-',
        options,
      };
    }

    return {
      reason: 'No se aplica',
      value: 0,
      operator: '+',
      options: {
        ...options,
        hideOnZero: true,
      },
    };
  }

  min(
    reason: string,
    min: number,
    calculations: Calculation[],
    options?: Calculation['options'],
  ): Calculation {
    const value = this.calculateFromCalculations(calculations);

    if (value < min) {
      return {
        reason: `${reason} (mínimo ${min})`,
        value: value - value + min,
        operator: '+',
        options,
      };
    }

    return {
      reason: 'No se aplica',
      value: 0,
      operator: '+',
      options: {
        ...options,
        hideOnZero: true,
      },
    };
  }

  getCalculations(): Calculation[] {
    // Override this method to add custom calculations
    return [];
  }

  public calculateFromCalculations(calculations: Calculation[]): number {
    return calculations.reduce((acc, { value, operator }) => {
      switch (operator) {
        case '+':
          return acc + value;
        case '-':
          return acc - value;
        case '*':
          return acc * value;
        case '/':
          return acc / value;
      }
    }, 0);
  }
}
