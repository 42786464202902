import { observer } from 'mobx-react';
import { MouseEvent, useState } from 'react';
import {
  AllPsychicDisciplines,
  getPsychicDisciplineFromType,
  getUnusedPsychicDiscipline,
  PsychicDiscipline,
} from '../../../../../../../../../domain/aggregations/psychic-disciplines/PsychicDiscipline';
import { AddItemButton } from '../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../common/CharacterMenu/useCharacterMenu';
import { transformToMenuItems } from '../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { RemoveItemButton } from '../../../common/RemoveItemButton/RemoveItemButton';
import { Section } from '../../../common/Section/Section';
import { Table } from '../../../common/Table/Table';
import { useCharacterContext } from '../../../../hooks/useCharacterContext';
import { MenuItem } from '../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';

export const PsychicDisciplines = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { handleMenuOpen, Menu } = useCharacterMenu<PsychicDiscipline>();

  const [selectedPsychicDisciplineIndex, setSelectedPsychicDisciplineIndex] =
    useState<number | undefined>(undefined);

  const addPsychicDiscipline = () => {
    const unusedDiscipline = getUnusedPsychicDiscipline(
      character.psychic.disciplines.map(getPsychicDisciplineFromType),
    );

    if (!unusedDiscipline) return;

    character.psychic.addPsychicDiscipline(unusedDiscipline.type);
  };

  const changePsychicDiscipline = (
    index: number,
    discipline: PsychicDiscipline,
  ) => {
    character.psychic.replacePsychicDiscipline(index, discipline.type);
  };

  const removePsychicDiscipline = (discipline: PsychicDiscipline) => () => {
    character.psychic.removePsychicDiscipline(discipline.type);
  };

  const disciplineItems: MenuItem<PsychicDiscipline>[] = transformToMenuItems({
    fromItems: AllPsychicDisciplines,
    generateItem: (item) => ({
      key: item.type,
      name: item.name,
      value: item,
      onClick: () => {
        if (selectedPsychicDisciplineIndex !== undefined) {
          changePsychicDiscipline(selectedPsychicDisciplineIndex, item);
        }
      },
    }),
  });

  const handlePsychicDisciplineClick =
    (discipline: PsychicDiscipline, index: number) =>
    (e: MouseEvent<HTMLDivElement>) => {
      setSelectedPsychicDisciplineIndex(index);
      handleMenuOpen(discipline)(e);
    };

  return (
    <Section maxHeight="fit">
      <Section.Title>Disciplinas psíquicas</Section.Title>
      <Table>
        <Table.Header>
          <Table.Header.Cell $width={5}>Nombre</Table.Header.Cell>
          <Table.Header.Cell $width={20}>Efecto</Table.Header.Cell>
        </Table.Header>
        {character.psychic.disciplines
          .map(getPsychicDisciplineFromType)
          .map((discipline, index) => (
            <Table.Row key={discipline.type}>
              <Table.Row.Cell
                $interactable
                onClick={handlePsychicDisciplineClick(discipline, index)}
              >
                {discipline.name}
              </Table.Row.Cell>
              <Table.Row.Cell $textAlign="left">
                {discipline.effect ?? 'Ninguno'}
              </Table.Row.Cell>
              <Table.Row.Cell>
                <RemoveItemButton
                  onClick={removePsychicDiscipline(discipline)}
                />
              </Table.Row.Cell>
            </Table.Row>
          ))}
      </Table>

      <AddItemButton onClick={addPsychicDiscipline} />

      <Menu items={disciplineItems} />
    </Section>
  );
});
