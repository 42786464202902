import { useState } from 'react';
import { NotificationManager } from '../../../shared/application/shared/NotificationManager';
import { FolderService } from '../../infrastructure/FolderService';

export const useMoveFolder = () => {
  const [isMoving, setIsMoving] = useState(false);

  async function moveFolder(
    folderToBeMoved: string,
    folderDestinationId?: string,
  ) {
    setIsMoving(true);

    try {
      await FolderService.moveFolderToFolder(
        folderToBeMoved,
        folderDestinationId,
      );
      NotificationManager.success('La carpeta se ha movido correctamente');
    } catch (e) {
      NotificationManager.error('Ha ocurrido un error al mover la carpeta');

      console.error(e);
    } finally {
      setIsMoving(false);
    }
  }

  const moveFolderToFolder = (
    folderToBeMoved: string,
    folderDestinationId: string,
  ) => {
    return moveFolder(folderToBeMoved, folderDestinationId);
  };

  const moveFolderToRoot = (folderToBeMoved: string) => {
    return moveFolder(folderToBeMoved);
  };

  return {
    movingFolder: isMoving,
    moveFolder: moveFolderToFolder,
    moveFolderToRoot,
  };
};
