import { observer } from 'mobx-react';
import { KiSkillType } from '../../../../../../../../../../domain/aggregations/ki-skill/KiSkill';
import {
  NemesisSkill,
  NemesisSkills,
  NemesisSkillType,
} from '../../../../../../../../../../domain/aggregations/nemesis-skill/NemesisSkill';
import { Section } from '../../../../common/Section/Section';
import { SkillTree } from '../../../../common/SkillTree/SkillTree';
import {
  ElementNode,
  ElementTree,
  TreeElement,
} from '../../../../common/SkillTree/utils/ElementTree';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { useMemo } from 'react';

export type CalculatedNemesisSkill = Omit<NemesisSkill, 'parents'> & {
  parents?: CalculatedNemesisSkill[];
};

export const calculateTreeElementFromNemesisSkill =
  (): TreeElement<CalculatedNemesisSkill>[] => {
    const transformNemesisSkillToCalculatedNemesisSkill = (
      skill: NemesisSkill,
    ): TreeElement<CalculatedNemesisSkill> => {
      const item: TreeElement<CalculatedNemesisSkill> = {
        id: skill.type,
        name: skill.name,
        cost: skill.cost,
        parents:
          skill.parents?.map(transformNemesisSkillToCalculatedNemesisSkill) ??
          [],
      };

      return item;
    };

    return NemesisSkills.map(transformNemesisSkillToCalculatedNemesisSkill);
  };

export const NemesisSkillsTree = observer(() => {
  const { character } = useCharacterContext();

  const calculatedNemesisSkills = useMemo(
    () => calculateTreeElementFromNemesisSkill(),
    [character.content],
  );

  const NemesisTree = useMemo(() => {
    return new ElementTree(calculatedNemesisSkills);
  }, [calculatedNemesisSkills]);

  const handleNodeClick = (
    node: ElementNode<TreeElement<CalculatedNemesisSkill>>,
  ) => {
    if (!character.canBeEdited) return;

    const skillType = node.element.id as NemesisSkillType;

    if (character.content.ki.hasNemesisSkill(skillType)) {
      if (skillType === NemesisSkillType.NemesisUse) {
        character.content.ki.removeKiSkill(KiSkillType.KiUse);
      }

      character.content.ki.removeNemesisSkill(skillType);

      node.getAllChildren().forEach((child) => {
        character.content.ki.removeNemesisSkill(
          child.element.id as NemesisSkillType,
        );
      });
    } else {
      character.content.ki.addKiSkill(KiSkillType.KiUse);

      node.getParentsUntilRoot().forEach((parent) => {
        character.content.ki.addNemesisSkill(
          parent.element.id as NemesisSkillType,
        );
      });

      character.content.ki.addNemesisSkill(skillType);
    }
  };

  return (
    <Section maxHeight="fit">
      <Section.Title>Habilidades del Némesis</Section.Title>
      <SkillTree
        tree={NemesisTree}
        selectedElements={calculatedNemesisSkills.filter((s) =>
          character.content.ki.hasNemesisSkill(s.id as NemesisSkillType),
        )}
        onNodeClick={handleNodeClick}
      />
    </Section>
  );
});
