import { observer } from 'mobx-react';
import { useCharacterContext } from '../../../hooks/useCharacterContext';
import { HtmlEditor } from '../../common/HtmlEditor/HtmlEditor';
import { Section } from '../../common/Section/Section';
import { Wrapper } from './AboutTab.styled';
import { Description } from './components/Description/Description';
import { NephilimSelector } from './components/NephilimSelector/NephilimSelector';

export const AboutTab = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  return (
    <Wrapper>
      <Description />
      <Section>
        <Section.Title>Nephilim</Section.Title>
        <NephilimSelector />
      </Section>
      <Section>
        <Section.Title>Notas</Section.Title>
        <HtmlEditor
          value={character.description.notes}
          setValue={(v) => character.description.setNotes(v)}
        />
      </Section>
      <Section>
        <Section.Title>Descripción física</Section.Title>
        <HtmlEditor
          value={character.description.physicalDescription}
          setValue={(v) => character.description.setPhysicalDescription(v)}
        />
      </Section>
      <Section>
        <Section.Title>Sueños y objetivos</Section.Title>
        <HtmlEditor
          value={character.description.dreamsAndGoal}
          setValue={(v) => character.description.setDreamsAndGoal(v)}
        />
      </Section>
      <Section>
        <Section.Title>Personalidad. Cosas que aprecia o detesta</Section.Title>
        <HtmlEditor
          value={character.description.personality}
          setValue={(v) => character.description.setPersonality(v)}
        />
      </Section>
      <Section>
        <Section.Title>Resumen de su historia</Section.Title>
        <HtmlEditor
          value={character.description.history}
          setValue={(v) => character.description.setHistory(v)}
        />
      </Section>
    </Wrapper>
  );
});
