import { useState } from 'react';
import { NotificationManager } from '../../../shared/application/shared/NotificationManager';
import { FolderService } from '../../infrastructure/FolderService';

export const useMoveCharacter = () => {
  const [isMoving, setIsMoving] = useState(false);

  async function moveCharacter(
    folderToBeMoved: string,
    folderDestinationId?: string,
  ) {
    setIsMoving(true);

    try {
      await FolderService.moveCharacterToFolder(
        folderToBeMoved,
        folderDestinationId,
      );
      NotificationManager.success('El personaje se ha movido correctamente');
    } catch (e) {
      NotificationManager.error('Ha ocurrido un error al mover el personaje');

      console.error(e);
    } finally {
      setIsMoving(false);
    }
  }

  const moveCharacterToFolder = (
    characterToBeMoved: string,
    folderDestinationId: string,
  ) => {
    return moveCharacter(characterToBeMoved, folderDestinationId);
  };

  const moveCharacterToRoot = (characterToBeMoved: string) => {
    return moveCharacter(characterToBeMoved);
  };

  return {
    movingCharacter: isMoving,
    moveCharacter: moveCharacterToFolder,
    moveCharacterToRoot,
  };
};
