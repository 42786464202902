import { v4 as uuid } from 'uuid';
import {
  WeaponEquippedHandType,
  WeaponManageabilityType,
  WeaponShotType,
  WeaponSize,
  WeaponSizeProportion,
  WeaponType,
} from '../../../../../../character/domain/aggregations/weapon/Weapon.types';
import {
  CreateAction,
  DeleteAction,
} from '../../../../IframeMessagingService.types';
import { ActorMutator } from '../../../ActorCharacterTransformer';
import { FoundryVTTActor } from '../FoundryVTTActor';
import { ABFItems, WeaponDataSource } from '../FoundryVTTActor.types';
import { FoundryVTTEnumTransforms } from './utils/FoundryVTTEnumTransforms';
import { HitType } from '../../../../../../character/domain/character/model/types/HitType';

export const mutateActorWeapons: ActorMutator<FoundryVTTActor> = ({
  actor,
  character,
}) => {
  const deleteAction: DeleteAction = {
    type: 'delete',
    ids: actor.data.combat.weapons.reduce(
      (acc, w) => [...acc, w._id],
      [] as string[],
    ),
  };

  const content = character.content;

  const fromWeapons: WeaponDataSource[] = content.inventory.weapons.map((w) => {
    const wp: WeaponDataSource = {
      _id: uuid(),
      type: ABFItems.WEAPON,
      name: w.name,
      data: {
        attack: {
          special: {
            value: w.attack.special,
          },
          final: {
            value: w.attack.final,
          },
        },
        special: {
          value: w.special,
        },
        block: {
          special: {
            value: w.block.special,
          },
          final: {
            value: w.block.final,
          },
        },
        breaking: {
          base: {
            value: w.breaking.special,
          },
          final: {
            value: w.breaking.final,
          },
        },
        cadence: {
          value: w.cadence?.toString() ?? '',
        },
        critic: {
          primary: {
            value: FoundryVTTEnumTransforms.getCritic(w.critic.primary),
          },
          secondary: {
            value: FoundryVTTEnumTransforms.getOptionalCritic(
              w.critic.secondary,
            ),
          },
        },
        damage: {
          base: {
            value: w.damage.base + w.damage.special,
          },
          final: {
            value: w.damage.final,
          },
        },
        initiative: {
          base: {
            value: w.initiative.base + w.initiative.special,
          },
          final: {
            value: w.initiative.final,
          },
        },
        size: {
          value: FoundryVTTEnumTransforms.getSize(w.size),
        },
        integrity: {
          base: {
            value: w.integrity.base + w.integrity.special,
          },
          final: {
            value: w.integrity.final,
          },
        },
        presence: {
          base: {
            value: w.presence.base + w.presence.special,
          },
          final: {
            value: w.presence.final,
          },
        },
        quality: {
          value: w.quality.final,
        },
        hasOwnStr: {
          value: w.ownStrength !== undefined,
        },
        isRanged: {
          value: w.isRanged,
        },
        reload: {
          base: {
            value: w.reload.base + w.reload.special,
          },
          final: {
            value: w.reload.final,
          },
        },
        weaponStrength: {
          base: {
            value: w.ownStrength ?? 0,
          },
          final: {
            value: w.ownStrength ?? 0,
          },
        },
        equipped: {
          value: w.equipped,
        },
        isShield: {
          value: w.types.includes(WeaponType.Shield),
        },
        knowledgeType: {
          value: FoundryVTTEnumTransforms.getKnowledgeType(w.knowledgeType),
        },
        manageabilityType: {
          value: FoundryVTTEnumTransforms.getManageabilityType(
            w.manageabilityType,
          ),
        },
        oneOrTwoHanded: {
          value: FoundryVTTEnumTransforms.getOneOrTwoHanded(w.oneOrTwoHanded),
        },
        shotType: {
          value: FoundryVTTEnumTransforms.getShotType(w.shotType),
        },
        range: {
          base: {
            value: w.range.base + w.range.special,
          },
          final: {
            value: w.range.final,
          },
        },
        sizeProportion: {
          value: FoundryVTTEnumTransforms.getSizeProportion(w.sizeProportion),
        },
        strRequired: {
          oneHand: {
            base: {
              value:
                w.requiredStrength.oneHand.base +
                w.requiredStrength.oneHand.special,
            },
            final: {
              value: w.requiredStrength.oneHand.final,
            },
          },
          twoHands: {
            base: {
              value:
                w.requiredStrength.twoHands.base +
                w.requiredStrength.twoHands.special,
            },
            final: {
              value: w.requiredStrength.twoHands.final,
            },
          },
        },
      },
    };
    return wp;
  });

  const unarmedValues = content.combat.unarmed;

  const hitTypes = unarmedValues.hitTypes;
  const hasMoreThanOneHitType = hitTypes.length > 1;

  const commonData: WeaponDataSource['data'] = {
    attack: {
      special: {
        value: 0,
      },
      final: {
        value: unarmedValues.attack,
      },
    },
    special: {
      value: '',
    },
    block: {
      special: {
        value: 0,
      },
      final: {
        value: unarmedValues.block,
      },
    },
    breaking: {
      base: {
        value: 0,
      },
      final: {
        value: 0,
      },
    },
    cadence: {
      value: '',
    },
    critic: {
      primary: {
        value: FoundryVTTEnumTransforms.getCritic(HitType.Impact),
      },
      secondary: {
        value: FoundryVTTEnumTransforms.getOptionalCritic(undefined),
      },
    },
    damage: {
      base: {
        value: unarmedValues.damage,
      },
      final: {
        value: 0,
      },
    },
    initiative: {
      base: {
        value: content.initiative.unarmed,
      },
      final: {
        value: content.initiative.unarmed,
      },
    },
    size: {
      value: FoundryVTTEnumTransforms.getSize(WeaponSize.Small),
    },
    integrity: {
      base: {
        value: 0,
      },
      final: {
        value: 0,
      },
    },
    presence: {
      base: {
        value: 0,
      },
      final: {
        value: 0,
      },
    },
    quality: {
      value: 0,
    },
    hasOwnStr: {
      value: false,
    },
    isRanged: {
      value: false,
    },
    reload: {
      base: {
        value: 0,
      },
      final: {
        value: 0,
      },
    },
    weaponStrength: {
      base: {
        value: 0,
      },
      final: {
        value: 0,
      },
    },
    equipped: {
      value: false,
    },
    isShield: {
      value: false,
    },
    knowledgeType: {
      value: FoundryVTTEnumTransforms.getKnowledgeType(
        content.calculateKnowledgeTypeOf(undefined),
      ),
    },
    manageabilityType: {
      value: FoundryVTTEnumTransforms.getManageabilityType(
        WeaponManageabilityType.OneHand,
      ),
    },
    oneOrTwoHanded: {
      value: FoundryVTTEnumTransforms.getOneOrTwoHanded(
        WeaponEquippedHandType.TwoHanded,
      ),
    },
    shotType: {
      value: FoundryVTTEnumTransforms.getShotType(WeaponShotType.Shot),
    },
    range: {
      base: {
        value: 0,
      },
      final: {
        value: 0,
      },
    },
    sizeProportion: {
      value: FoundryVTTEnumTransforms.getSizeProportion(
        WeaponSizeProportion.Normal,
      ),
    },
    strRequired: {
      oneHand: {
        base: {
          value: 0,
        },
        final: {
          value: 0,
        },
      },
      twoHands: {
        base: {
          value: 0,
        },
        final: {
          value: 0,
        },
      },
    },
  };

  const unarmed: WeaponDataSource[] = hitTypes.map((hitType) => {
    return {
      _id: uuid(),
      type: ABFItems.WEAPON,
      name: hasMoreThanOneHitType ? `Desarmado (${hitType})` : 'Desarmado',
      data: {
        ...commonData,
        critic: {
          ...commonData.critic,
          primary: {
            value: FoundryVTTEnumTransforms.getCritic(hitType),
          },
        },
      },
    };
  });

  const createAction: CreateAction<WeaponDataSource> = {
    type: 'create',
    data: [...unarmed, ...fromWeapons],
  };

  actor.data.combat.weapons = [];

  return {
    actor,
    actions: [deleteAction, createAction],
  };
};
