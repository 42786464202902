import { useMemo } from 'react';
import { Routes, useNavigate } from '../../../../hooks/useNavigate';
import { NavbarItem, TopNavbar } from '../TopNavbar';
import { NewsService } from '../../../../../../news/infrastructure/NewsService';

export const DefaultNavbar = () => {
  const { navigate } = useNavigate();

  const notificationBadge = NewsService.hasPendingNewsToRead();

  const items: NavbarItem[] = useMemo(() => {
    return [
      {
        label: 'Lobby',
        onClick: () => {
          navigate(Routes.MyCharacters, { params: { folderId: '' } });
        },
        selected: window.location.pathname === Routes.MyCharacters,
      },
      {
        label: 'Sala de personajes',
        onClick: () => {
          navigate(Routes.CharactersRoom);
        },
        selected: window.location.pathname === Routes.CharactersRoom,
      },
      {
        label: 'Noticias',
        onClick: () => {
          navigate(Routes.News);
        },
        selected: window.location.pathname === Routes.News,

        hasNotificationBadge: notificationBadge,
      },
      {
        label: 'FAQ',
        onClick: () => {
          navigate(Routes.HowToImport);
        },
        selected: window.location.pathname === Routes.HowToImport,
      },
    ];
  }, [notificationBadge]);

  return <TopNavbar items={items} />;
};
