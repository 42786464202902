import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class XingQuanMartialArt extends BasicMartialArt {
  readonly name = 'Xing Quan';

  readonly complexity = 2;

  readonly type = MartialArtType.XingQuan;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      effects:
        'Con iniciativa en el asalto y declarado un ataque a un enemigo, tiene +10 al ataque contra ese enemigo. Si se usa el bono, no se puede ataque a ningún otro personaje en el asalto.',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 10;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 20) {
          errors.push('Trucos de manos 20+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      effects: 'El bono se incrementa a +20',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 20;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 50) {
          errors.push('Trucos de manos 50+');
        }

        if (character.initiative.withEquipment < 100) {
          errors.push('Iniciativa 100+');
        }

        if (character.combat.attackAbility.final < 120) {
          errors.push('Ataque 120+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      effects: 'El bono se incrementa a +30',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 30;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 100) {
          errors.push('Trucos de manos 100+');
        }

        if (character.initiative.withEquipment < 120) {
          errors.push('Iniciativa 120+');
        }

        if (character.combat.attackAbility.final < 200) {
          errors.push('Ataque 200+');
        }

        return errors;
      },
    }),
  ];
}
