import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import { weaponTypeTranslations } from '../../../../../../../../../../../../domain/aggregations/weapon/Weapon.types';
import { WeaponItemModel } from '../../../../../../../../../../../../domain/aggregations/weapon/WeaponItemModel';
import { Checkbox } from '../../../../../../common/Checkbox/Checkbox';
import DoubleInput from '../../../../../../common/DoubleInput/DoubleInput';
import { HideIcon, ShowIcon } from '../../../../../../common/Icons/Icons';
import { RemoveItemButton } from '../../../../../../common/RemoveItemButton/RemoveItemButton';
import { Table } from '../../../../../../common/Table/Table';
import { SizeProportionSelector } from './components/SizeProportionSelector/SizeProportionSelector';
import { SizeSelector } from './components/SizeSelector/SizeSelector';
import { ManageabilityTypeSelector } from './components/ManageabilityTypeSelector/ManageabilityTypeSelector';
import { PrimaryHitTypeSelector } from './components/HitTypeSelector/PrimaryHitTypeSelector';
import { SecondaryHitTypeSelector } from './components/HitTypeSelector/SecondaryHitTypeSelector';
import { StringInput } from '../../../../../../common/StringInput/StringInput';
import { Input } from '../../../../../../common/Input/Input';
import { ShotTypeSelector } from './components/ShotTypeSelector/ShotTypeSelector';
import { EditWeapon } from './components/EditWeapon/EditWeapon';
import { useModal } from '../../../../../../../../../../../../../shared/application/design-system/components/Modal/hooks/useModal';
import { WeaponKnowledgeTypeSelector } from './components/WeaponKnowledgeTypeSelector/WeaponKnowledgeTypeSelector';
import { StyledEditItemButton } from './WeaponRow.styled';
import { Tooltip } from '@mui/material';

interface WeaponRowProps {
  weapon: WeaponItemModel;

  onNameClick: (
    weapon: WeaponItemModel,
  ) => (e: MouseEvent<HTMLDivElement>) => void;

  onRemove: (weapon: WeaponItemModel) => void;
}

export const WeaponRow = observer(
  ({ weapon, onNameClick, onRemove }: WeaponRowProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const { Modal, openModal, closeModal } = useModal();

    const handleEditWeapon = (weapon: WeaponItemModel) => () => {
      openModal(
        <EditWeapon weapon={weapon} onSaveWeapon={() => closeModal()} />,
        { fullScreenInMobile: true },
      );
    };

    const handleEquippedClick = () => {
      weapon.setEquipped(!weapon.equipped);
    };

    const handleNameClick = (e: MouseEvent<HTMLDivElement>) => {
      onNameClick(weapon)(e);
    };

    const handleWeaponRemove = () => {
      onRemove(weapon);
    };

    const handleShowClick = () => {
      setIsExpanded(true);
    };

    const handleHideClick = () => {
      setIsExpanded(false);
    };

    return (
      <>
        <Table.Row>
          <Table.Row.Cell $hideOnMobile>
            {isExpanded ? (
              <HideIcon onClick={handleHideClick} />
            ) : (
              <ShowIcon onClick={handleShowClick} />
            )}
          </Table.Row.Cell>
          <Table.Row.Cell>
            <Checkbox
              isChecked={weapon.equipped}
              onClick={handleEquippedClick}
            />
          </Table.Row.Cell>
          <Table.Row.Cell onClick={handleNameClick}>
            {weapon.name}
          </Table.Row.Cell>
          <Table.Row.Cell $hideOnMobile>
            <WeaponKnowledgeTypeSelector weapon={weapon} />
          </Table.Row.Cell>
          <Table.Row.Cell>
            <DoubleInput disableBase field={weapon.attack} />
          </Table.Row.Cell>
          <Table.Row.Cell>
            <DoubleInput field={weapon.block} />
          </Table.Row.Cell>
          <Table.Row.Cell>
            <DoubleInput field={weapon.damage} />
          </Table.Row.Cell>
          <Table.Row.Cell $hideOnMobile>
            <DoubleInput field={weapon.initiative} />
          </Table.Row.Cell>
          <Table.Row.Cell>
            <Tooltip
              title={
                weapon.isCustom
                  ? 'Este arma está modificada, esto puede influir en ciertos cálculos automáticos'
                  : ''
              }
            >
              <div>
                <StyledEditItemButton
                  $highlight={weapon.isCustom}
                  onClick={handleEditWeapon(weapon)}
                />
              </div>
            </Tooltip>
          </Table.Row.Cell>
          <Table.Row.Cell>
            <RemoveItemButton onClick={handleWeaponRemove} />
          </Table.Row.Cell>
        </Table.Row>

        {isExpanded ? (
          <>
            <Table.Header>
              <Table.Header.Cell $width={1} />
              <Table.Header.Cell $width={2.5}>Tipo</Table.Header.Cell>
              <Table.Header.Cell $width={2.5}>Especial</Table.Header.Cell>
              <Table.Header.Cell $width={2.5}>Entereza</Table.Header.Cell>
              <Table.Header.Cell $width={2.5}>Rotura</Table.Header.Cell>
              <Table.Header.Cell $width={2.5}>Presencia</Table.Header.Cell>
              <Table.Header.Cell $width={2.5}>Tamaño</Table.Header.Cell>
            </Table.Header>
            <Table.Row>
              <Table.Row.Cell />
              <Table.Row.Cell>
                {weapon.types.map((t) => weaponTypeTranslations[t]).join(', ')}
              </Table.Row.Cell>
              <Table.Row.Cell>
                <StringInput
                  value={weapon.special}
                  setValue={(v) => weapon.setSpecial(v)}
                />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <DoubleInput field={weapon.integrity} />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <DoubleInput field={weapon.breaking} />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <DoubleInput field={weapon.presence} />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <SizeSelector weapon={weapon} />
              </Table.Row.Cell>
            </Table.Row>
            <Table.Header>
              <Table.Header.Cell $width={1} />
              <Table.Header.Cell $width={2.5}>Proporción</Table.Header.Cell>
              <Table.Header.Cell $width={2.5}>F req. (1 m.)</Table.Header.Cell>
              <Table.Header.Cell $width={2.5}>F req. (2 m.)</Table.Header.Cell>
              <Table.Header.Cell $width={2.5}>Calidad</Table.Header.Cell>
              <Table.Header.Cell $width={2.5}>Manejo</Table.Header.Cell>
              <Table.Header.Cell $width={2.5}>Críticos</Table.Header.Cell>
            </Table.Header>
            <Table.Row>
              <Table.Row.Cell />
              <Table.Row.Cell>
                <SizeProportionSelector weapon={weapon} />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <DoubleInput field={weapon.requiredStrength.oneHand} />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <DoubleInput field={weapon.requiredStrength.twoHands} />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <DoubleInput step={5} field={weapon.quality} />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <ManageabilityTypeSelector weapon={weapon} />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <PrimaryHitTypeSelector weapon={weapon} />
                <SecondaryHitTypeSelector weapon={weapon} />
              </Table.Row.Cell>
            </Table.Row>
            {weapon.isRanged ? (
              <>
                <Table.Header>
                  <Table.Header.Cell $width={1} />
                  <Table.Header.Cell $width={2.5}>
                    Tipo de disparo
                  </Table.Header.Cell>
                  <Table.Header.Cell $width={2.5}>Fuerza</Table.Header.Cell>
                  <Table.Header.Cell $width={2.5}>Rango</Table.Header.Cell>
                  <Table.Header.Cell $width={2.5}>Cadencia</Table.Header.Cell>
                  <Table.Header.Cell $width={2.5}>Recarga</Table.Header.Cell>
                </Table.Header>
                <Table.Row>
                  <Table.Row.Cell />
                  <Table.Row.Cell>
                    <ShotTypeSelector weapon={weapon} />
                  </Table.Row.Cell>
                  <Table.Row.Cell>
                    <Input
                      value={weapon.ownStrength ?? 0}
                      setValue={(v) => weapon.setOwnStrength(v)}
                    />
                  </Table.Row.Cell>
                  <Table.Row.Cell>
                    <DoubleInput field={weapon.range} />
                  </Table.Row.Cell>
                  <Table.Row.Cell>
                    <Input
                      value={weapon.cadence ?? 0}
                      setValue={(v) => weapon.setCadence(v)}
                    />
                  </Table.Row.Cell>
                  <Table.Row.Cell>
                    <DoubleInput field={weapon.reload} />
                  </Table.Row.Cell>
                </Table.Row>
              </>
            ) : undefined}
          </>
        ) : undefined}
        <Modal />
      </>
    );
  },
);
