import styled from 'styled-components';

export const Wrapper = styled.div(({ theme: { size, mixins } }) => ({
  flexDirection: 'row',

  gap: size.units(2),

  padding: size.units(2),

  display: 'none',

  ...mixins.responsive.md.upward({ display: 'flex' }),
}));
