import { useState } from 'react';
import { NotificationManager } from '../../../shared/application/shared/NotificationManager';
import { FolderService } from '../../infrastructure/FolderService';
import { TrackingService } from '../../../shared/infrastructure/tracking/TrackingService';

export const useDeleteFolder = () => {
  const [loading, setLoading] = useState(false);

  const deleteFolder = (folderId: string): Promise<void> => {
    setLoading(true);

    return FolderService.deleteFolder(folderId)
      .then(() => {
        NotificationManager.success('Se ha eliminado la carpeta');
        TrackingService.sendEvent('folder_deleted');
      })
      .catch((error) => {
        NotificationManager.error('No se ha podido eliminar la carpeta');

        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    deletingFolder: loading,
    deleteFolder,
  };
};
