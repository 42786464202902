import { useState } from 'react';
import { NotificationManager } from '../../../shared/application/shared/NotificationManager';
import { FolderService } from '../../infrastructure/FolderService';
import { TrackingService } from '../../../shared/infrastructure/tracking/TrackingService';

export const useCreateFolder = () => {
  const [creating, setCreating] = useState(false);

  const createFolder = (
    name: string,
    parentFolderId?: string,
  ): Promise<void> => {
    setCreating(true);

    return FolderService.createFolder(
      name,
      parentFolderId === 'root' ? undefined : parentFolderId,
    )
      .then(() => {
        NotificationManager.success('Se ha creado la carpeta');
        TrackingService.sendEvent('folder_created');
      })
      .catch((error) => {
        NotificationManager.error('No se ha podido crear la carpeta');

        console.error(error);
      })
      .finally(() => {
        setCreating(false);
      });
  };

  return {
    creatingFolder: creating,
    createFolder,
  };
};
