import { PrimaryFieldModel } from '../../../../../../../../../../../../domain/character/model/parts/primaries/fields/common/PrimaryFieldModel';
import { Modifier, Name, Wrapper } from './PrimaryCharacteristicField.styled';
import DoubleInput from '../../../../../../common/DoubleInput/DoubleInput';
import { observer } from 'mobx-react';
import { Tooltip } from '@mui/material';

type PrimaryCharacteristicFieldProps = {
  name: string;
  field: PrimaryFieldModel;
};

export const PrimaryCharacteristicField = observer(
  ({ name, field }: PrimaryCharacteristicFieldProps) => {
    return (
      <Wrapper>
        <Name variant="subtitle2">{name}</Name>
        <DoubleInput
          field={field}
          size={DoubleInput.Size.Big}
          variant={DoubleInput.Variant.Outline}
          disableSpecial
          max={20}
        />
        <Tooltip title="Total / Bono">
          <Modifier variant="h6">
            {field.final} / {field.modifier.final}
          </Modifier>
        </Tooltip>
      </Wrapper>
    );
  },
);
