import styled from 'styled-components';
import { theme } from '../../../../../../../../../../../../../shared/application/design-system/theme/theme';
import { StyledCell } from '../../../TableCell/TableCell.styled';

export const StyledHeaderCell = styled(StyledCell)({
  fontSize: '0.9rem',

  ...theme.mixins.responsive.sm.downward({
    fontSize: '0.8rem',
  }),
});
