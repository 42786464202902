import { WeaponTable, WeaponTableType } from './WeaponTable';

const AllImprovisedWeaponTables = [
  {
    id: '0',
    name: 'Armas improvisadas',
  },
];

type ImprovisedWeaponTable = typeof AllImprovisedWeaponTables[number];

export class ImprovisedWeaponsTable extends WeaponTable<ImprovisedWeaponTable> {
  name = 'Tabla de Armas Improvisadas';

  type = WeaponTableType.ImprovisedWeapons;

  cost = 50;

  getItems(): ImprovisedWeaponTable[] {
    return AllImprovisedWeaponTables;
  }
}
