import { model } from 'mobx-keystone';
import { PDFieldModel } from '../../PDFieldModel';

@model('Character/PD/Combat/BlockAbilityPD')
export class BlockAbilityPDFieldModel extends PDFieldModel {
  get pdsCost(): number {
    return this.template.blockPDsCost;
  }

  get perCategory(): number {
    return this.template.calculateBlockBonus();
  }
}
