import styled from 'styled-components';

export const SelectorRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

export const Wrapper = styled.div(({ theme: { size } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gridTemplateRows: 'auto',

  gap: size.units(2),
}));
