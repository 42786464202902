import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class KuanMartialArt extends BasicMartialArt {
  readonly name = 'Kuan';

  readonly complexity = 1;

  readonly type = MartialArtType.Kuan;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      effects: 'Mitad de penalizador para parar proyectiles lanzados.',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 10;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 40) {
          errors.push('Trucos de manos 40+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      effects:
        'Mitad penalizadores por parar o esquivar proyectiles disparados.',
      combatBonus: {
        block: 10,
      },
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 20;
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 60) {
          errors.push('Trucos de manos 60+');
        }

        if (character.combat.defensiveAbility < 120) {
          errors.push('Bloqueo o esquiva 120+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      effects:
        'Sin penalizadores por parar o esquivar proyectiles disparados o lanzados.',
      combatBonus: {
        block: 10,
      },
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 20;
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 140) {
          errors.push('Trucos de manos 140+');
        }

        if (character.combat.defensiveAbility < 200) {
          errors.push('Bloqueo o esquiva 200+');
        }

        return errors;
      },
    }),
  ];
}
