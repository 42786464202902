import { Avatar } from '@mui/material';
import { useCharacterContext } from '../../../../hooks/useCharacterContext';
import { ChangeEvent, useRef, useState } from 'react';
import { Background, EditIcon, Wrapper } from './Token.styled';
import { AvatarCrop } from './components/AvatarCrop/AvatarCrop';
import { useSaveCharacterToken } from '../../../../../../../../../../shared/application/hooks/useSaveCharacterToken';
import { Loading } from '../../../../../../../../../../shared/application/design-system/components/Loading/Loading';
import { CharacterModel } from '../../../../../../../../../domain/character/model/CharacterModel';

export const Token = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | undefined>(undefined);

  const { savingCharacterToken, saveCharacterToken } = useSaveCharacterToken();

  const { character } = useCharacterContext();

  const disabled = !character.canBeEdited;

  const handleAvatarClick = () => {
    if (disabled) return;

    inputRef.current?.click();
  };

  const handleAvatarChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setFile(e.target.files[0]);
    }

    e.target.value = '';
  };

  const handleCropSave = (blob: Blob) => {
    saveCharacterToken(character, blob);
  };

  const image = character.content.tokenUrl ?? CharacterModel.defaultTokenUrl;

  return (
    <>
      <Wrapper $disabled={disabled} onClick={handleAvatarClick} role="button">
        {savingCharacterToken ? (
          <Loading size={3} color="white" />
        ) : (
          <Avatar
            variant="circular"
            alt={character.content.name}
            src={image}
            sx={{ width: '4rem', height: '4rem' }}
          />
        )}
        <Background />
        <EditIcon />
        <input
          ref={inputRef}
          onChange={handleAvatarChange}
          type="file"
          hidden
        />
      </Wrapper>
      {file ? (
        <AvatarCrop file={file} onFinishCrop={handleCropSave} />
      ) : undefined}
    </>
  );
};
