import { WeaponTable, WeaponTableType } from './WeaponTable';
import { WeaponType } from '../../weapon/Weapon.types';

export const PoleTypologyWeaponTableType = {
  id: '1',
  name: 'Asta',
  weaponType: WeaponType.Pole,
};

export const ShortTypologyWeaponTableType = {
  id: '2',
  name: 'Corta',
  weaponType: WeaponType.Short,
};

export const RopeTypologyWeaponTableType = {
  id: '3',
  name: 'Cuerda',
  weaponType: WeaponType.Rope,
};

export const ShieldTypologyWeaponTableType = {
  id: '4',
  name: 'Escudo',
  weaponType: WeaponType.Shield,
};

export const SwordTypologyWeaponTableType = {
  id: '5',
  name: 'Espada',
  weaponType: WeaponType.Sword,
};

export const AxeTypologyWeaponTableType = {
  id: '6',
  name: 'Hacha',
  weaponType: WeaponType.Axe,
};

export const GreatSwordTypologyWeaponTableType = {
  id: '7',
  name: 'Mandoble',
  weaponType: WeaponType.GreatSword,
};

export const MaceTypologyWeaponTableType = {
  id: '8',
  name: 'Maza',
  weaponType: WeaponType.Mace,
};

export const LaunchTypologyWeaponTableType = {
  id: '9',
  name: 'Lanzamiento',
  weaponType: WeaponType.Launch,
};

export const ProjectilesTypologyWeaponTableType = {
  id: '10',
  name: 'Proyectiles',
  weaponType: WeaponType.Projectiles,
};

export const AmmoTypologyWeaponTableType = {
  id: '11',
  name: 'Munición',
  weaponType: WeaponType.Projectiles,
};

export const AllWeaponTypology = [
  PoleTypologyWeaponTableType,
  ShortTypologyWeaponTableType,
  RopeTypologyWeaponTableType,
  ShieldTypologyWeaponTableType,
  SwordTypologyWeaponTableType,
  AxeTypologyWeaponTableType,
  GreatSwordTypologyWeaponTableType,
  MaceTypologyWeaponTableType,
  LaunchTypologyWeaponTableType,
  ProjectilesTypologyWeaponTableType,
  AmmoTypologyWeaponTableType,
];

export type TypologicalWeaponTable = typeof AllWeaponTypology[number];

export class TypologicalWeaponsTable extends WeaponTable<TypologicalWeaponTable> {
  name = 'Tabla de Armas de Tipología';

  type = WeaponTableType.TypologicalWeapon;

  cost = 50;

  getItems(): TypologicalWeaponTable[] {
    return AllWeaponTypology;
  }
}
