import { CharacterCollaborationService } from '../../../../infrastructure/CharacterCollaborationService';
import { useState } from 'react';
import { NotificationManager } from '../../../../../shared/application/shared/NotificationManager';
import { decodeApiErrorMessage } from '../../../../../shared/infrastructure/api/decodeApiErrorMessage';
import { TrackingService } from '../../../../../shared/infrastructure/tracking/TrackingService';

export const useAddCharacterCollaboration = () => {
  const [addingCharacterCollaboration, setAddingCharacterCollaboration] =
    useState(false);

  const addCharacterCollaboration = async ({
    characterId,
    collaboratorEmail,
  }: {
    characterId: string;
    collaboratorEmail: string;
  }) => {
    setAddingCharacterCollaboration(true);

    try {
      await CharacterCollaborationService.addCharacterCollaboration({
        characterId: characterId,
        collaboratorEmail: collaboratorEmail,
      });

      TrackingService.sendEvent('character_collaboration_added', {
        characterId: characterId,
      });
    } catch (e) {
      NotificationManager.error(decodeApiErrorMessage(e));
    } finally {
      setAddingCharacterCollaboration(false);
    }
  };

  return {
    addCharacterCollaboration,
    addingCharacterCollaboration,
  };
};
