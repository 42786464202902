import { model } from 'mobx-keystone';
import { getWeaponItemModel } from '../utils/getWeaponItemModel';
import { WeaponShotType, WeaponSizeProportion } from '../Weapon.types';
import { WeaponItemModel } from '../WeaponItemModel';
import { WeaponFieldModel } from './WeaponFieldModel';

@model('WeaponItem/WeaponDamageField')
export class WeaponDamageFieldModel extends WeaponFieldModel {
  get final(): number {
    if (!this.weapon) return 0;

    let damage = this.base + this.special;

    if (this.weapon.isRanged && this.weapon.shotType === WeaponShotType.Shot) {
      return damage + this.getAmmoDamage();
    }

    damage += this.addSizeModifier(this.base);

    damage += this.weapon.quality.final * 2;

    return Math.max(0, damage);
  }

  private getAmmoDamage(): number {
    if (!this.weapon) return 0;

    const { ammo } = this.weapon;

    if (ammo) {
      let ammoDamage = ammo.damage.final - ammo.quality.final * 2;

      ammoDamage = this.addSizeModifier(ammoDamage);

      ammoDamage += ammo.quality.final * 2;

      return ammoDamage;
    }

    return 0;
  }

  private addSizeModifier(damage: number): number {
    if (!this.weapon) return 0;

    if (this.weapon.sizeProportion === WeaponSizeProportion.Enormous) {
      damage *= 1.5;

      damage = Math.floor(damage / 5) * 5;
    }

    if (this.weapon.sizeProportion === WeaponSizeProportion.Giant) {
      damage *= 2;
    }

    return damage;
  }

  get weapon(): WeaponItemModel | undefined {
    return getWeaponItemModel(this);
  }
}
