import { Table } from '../../../../common/Table/Table';

export const SecondarySkillHeader = ({ isNovel }: { isNovel?: boolean }) => {
  return (
    <Table.Header>
      <Table.Header.Cell $width={5} />
      <Table.Header.Cell>Coste</Table.Header.Cell>
      <Table.Header.Cell>Gasto</Table.Header.Cell>
      <Table.Header.Cell $hideOnMobile>Bono</Table.Header.Cell>
      <Table.Header.Cell $hideOnMobile>Categoría</Table.Header.Cell>
      <Table.Header.Cell>Especial</Table.Header.Cell>
      <Table.Header.Cell $hideOnMobile>
        Bono
        <br />
        natural
      </Table.Header.Cell>
      <Table.Header.Cell $hideOnMobile>
        Habilidad
        <br />
        natural
      </Table.Header.Cell>
      {isNovel ? (
        <Table.Header.Cell $hideOnMobile>
          Bono de
          <br />
          Novel
        </Table.Header.Cell>
      ) : undefined}
      <Table.Header.Cell>Total</Table.Header.Cell>
    </Table.Header>
  );
};
