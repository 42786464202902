import { model, Model, prop } from 'mobx-keystone';
import type { WeaponItemModel } from '../../aggregations/weapon/WeaponItemModel';
import { createWeaponItemModelFromPredefinedWeapon } from '../../aggregations/weapon/WeaponItemModel';
import { characterCtx } from './Character.context';
import { DescriptionModel } from './parts/aspect/DescriptionModel';
import { CombatModel } from './parts/combat/CombatModel';
import { CreationPointsModel } from './parts/creation-points/CreationPointsModel';
import { FatigueFieldModel } from './parts/fatigue/FatigueFieldModel';
import { InitiativeModel } from './parts/initiative/InitiativeModel';
import { InventoryModel } from './parts/inventory/InventoryModel';
import { KiModel } from './parts/ki/KiModel';
import { LifeModel } from './parts/life/LifeModel';
import { ModifierFieldsModel } from './parts/modifiers/ModifierFieldsModel';
import { MovementTypeField } from './parts/movement-type/MovementTypeField';
import { MysticModel } from './parts/mystic/MysticModel';
import { CategoryModel } from './parts/pd/parts/categories/CategoryModel';
import { PDModel } from './parts/pd/PDModel';
import { PresenceField } from './parts/presence/PresenceField';
import { PrimaryFields } from './parts/primaries/PrimaryFields';
import { PsychicModel } from './parts/psychic/PsychicModel';
import { RegenerationFieldModel } from './parts/regeneration/RegenerationFieldModel';
import { ResistanceFields } from './parts/resistances/ResistanceFields';
import { SecondariesSkillsModel } from './parts/secondaries/SecondariesSkillsModel';
import { TotalATModel } from './parts/total-at/TotalATModel';
import { computed } from 'mobx';
import { getCharacterIssues } from './utils/issues/getCharacterIssues';
import { CharacterIssue } from './utils/issues/CharacterIssue';
import { DamageModel } from './parts/damage/DamageModel';
import { KiSkillType } from '../../aggregations/ki-skill/KiSkill';
import { NemesisSkillType } from '../../aggregations/nemesis-skill/NemesisSkill';
import { NephilimType } from '../../aggregations/nephilim-type/Nephilim';
import { WeaponKnowledgeType } from '../../aggregations/weapon/Weapon.types';
import { getWeaponKnowledgeType } from '../../aggregations/weapon/utils/getWeaponKnowledgeType';
import { filterUndefined } from '../../../../shared/utils/filterUndefined';
import {
  PredefinedWeapon,
  PredefinedWeapons,
  UnarmedWeapon,
} from '../../aggregations/weapon/PredefinedWeapons';
import { CharacterOptionsModel } from './parts/options/CharacterOptionsModel';

export const CurrentCharacterVersion = 1;

@model('Character')
export class CharacterModel extends Model({
  version: prop<number>(CurrentCharacterVersion).withSetter(),

  name: prop<string>('New character').withSetter(),
  tokenUrl: prop<string | undefined>().withSetter(),
  description: prop(() => new DescriptionModel({})),

  primaries: prop(() => new PrimaryFields({})),
  secondaries: prop(() => new SecondariesSkillsModel({})),

  lifePoints: prop(() => new LifeModel({})),

  at: prop(() => new TotalATModel({})),

  developedWeapon: prop<WeaponItemModel | undefined>().withSetter(),
  pd: prop(() => new PDModel({})),
  creationPoints: prop<CreationPointsModel>(() => new CreationPointsModel({})),

  combat: prop(() => new CombatModel({})),
  ki: prop(() => new KiModel({})),
  mystic: prop(() => new MysticModel({})),
  psychic: prop(() => new PsychicModel({})),

  fatigue: prop(() => new FatigueFieldModel({})),
  initiative: prop(() => new InitiativeModel({})),
  damage: prop(() => new DamageModel({})),
  movementType: prop(() => new MovementTypeField({})),
  regeneration: prop(() => new RegenerationFieldModel({})),
  presence: prop(() => new PresenceField({})),
  resistances: prop(() => new ResistanceFields({})),

  modifiers: prop(() => new ModifierFieldsModel({})),
  inventory: prop(() => new InventoryModel({})),
  options: prop(() => new CharacterOptionsModel({})),
}) {
  static defaultTokenUrl =
    'https://firebasestorage.googleapis.com/v0/b/anima-beyond-the-beyond-static/o/default_token.png?alt=media';

  get level(): number {
    return this.pd.categories.reduce(
      (acc, category) => acc + category.level.final,
      0,
    );
  }

  get categories(): CategoryModel[] {
    return this.pd.categories;
  }

  @computed
  get developedWeapons(): WeaponItemModel[] {
    const developedWeapons: WeaponItemModel[] = [];

    if (this.developedWeapon) {
      developedWeapons.push(this.developedWeapon);
    }

    const typologicalTableWeaponTypes = this.combat.typologicalTables
      .map((t) => t.selection?.weaponType)
      .filter(filterUndefined());

    const typologicalPredefinedWeaponIds = PredefinedWeapons.filter(
      (w) =>
        w.types.length === 1 &&
        w.types.some((t) => typologicalTableWeaponTypes.includes(t)),
    ).map((w) => w.id);

    const archetypePredefinedWeaponIds = this.combat.archetypeTables
      .flatMap((t) => t.selection?.weapons)
      .filter(filterUndefined())
      .map((w) => w.id);

    const predefinedWeaponIds: string[] = [
      ...new Set([
        ...this.combat.similarWeaponTables.map((t) => t.tableItemSelected),
        ...this.combat.mixedWeaponTables.map((t) => t.tableItemSelected),
        ...this.combat.distinctWeaponTables.map((t) => t.tableItemSelected),
        ...typologicalPredefinedWeaponIds,
        ...archetypePredefinedWeaponIds,
      ]),
    ];

    const predefinedWeapons = PredefinedWeapons.filter((w) =>
      predefinedWeaponIds.includes(w.id),
    );

    const weapons = predefinedWeapons.map(
      createWeaponItemModelFromPredefinedWeapon,
    );

    return [...developedWeapons, ...weapons];
  }

  @computed
  get hasInhumanity(): boolean {
    return (
      this.ki.hasKiSkill(KiSkillType.Inhumanity) ||
      this.ki.hasNemesisSkill(NemesisSkillType.Inhumanity)
    );
  }

  @computed
  get hasZen(): boolean {
    return (
      this.ki.hasKiSkill(KiSkillType.Zen) ||
      this.ki.hasNemesisSkill(NemesisSkillType.Zen)
    );
  }

  @computed
  get isUnarmedAsDevelopedWeapon(): boolean {
    return (
      this.developedWeapon === undefined ||
      this.combat.distinctWeaponTables.some(
        (t) => t.tableItemSelected === UnarmedWeapon.id,
      )
    );
  }

  @computed
  get issues() {
    return getCharacterIssues(this);
  }

  isNephilim(type: NephilimType): boolean {
    return this.description.nephilim?.id === type.id;
  }

  hasIssue(issue: CharacterIssue) {
    return this.issues.includes(issue);
  }

  calculateKnowledgeTypeOf(
    weaponToCheck: WeaponItemModel | PredefinedWeapon | undefined,
  ): WeaponKnowledgeType {
    const unarmedWeaponKnowledgeType =
      this.checkUnarmedWeaponKnowledgeType(weaponToCheck);

    if (unarmedWeaponKnowledgeType) {
      return unarmedWeaponKnowledgeType;
    }

    if (!weaponToCheck) {
      return WeaponKnowledgeType.Different;
    }

    const knownWeapons = this.developedWeapons;

    const originalWeapons = knownWeapons.filter((w) => !w.isCustom);

    if (originalWeapons.some((w) => w.id === weaponToCheck.id)) {
      return WeaponKnowledgeType.Known;
    }

    const weaponToCheckTypes = weaponToCheck.types;

    const predefinedWeapons = originalWeapons
      .map((d) => d.template)
      .filter(filterUndefined());

    const knownWeaponTypes = predefinedWeapons
      .map((w) => w?.types)
      .filter(filterUndefined());

    return getWeaponKnowledgeType(knownWeaponTypes, weaponToCheckTypes);
  }

  protected onInit() {
    characterCtx.setComputed(this, () => this);
  }

  private checkUnarmedWeaponKnowledgeType(
    weaponToCheck: WeaponItemModel | PredefinedWeapon | undefined,
  ): WeaponKnowledgeType | undefined {
    const isWeaponToCheckUnarmed =
      weaponToCheck === undefined || weaponToCheck.id === UnarmedWeapon.id;

    if (isWeaponToCheckUnarmed) {
      return this.isUnarmedAsDevelopedWeapon
        ? WeaponKnowledgeType.Known
        : WeaponKnowledgeType.Different;
    }
  }
}
