import { model } from 'mobx-keystone';
import { MagicGradualLearningAdvantage } from '../../../../../aggregations/advantage/DonAdvantages';
import { DevelopedPDFieldModel } from '../../../utils/fields/DevelopedPDFieldModel';
import { getTotalOf } from '../../../utils/getTotalOf';

@model('Character/Mystic/MagicLevelField')
export class MagicLevelFieldModel extends DevelopedPDFieldModel {
  get fromCategories(): number {
    return getTotalOf(this.character, (c) => c.mystic.magicLevel.final);
  }

  get allActionsPenalty(): number {
    return 0;
  }

  get bonusFromAdvantages(): number {
    if (!this.character) return 0;

    let value = 0;

    if (
      this.character.creationPoints.containsAdvantage(
        MagicGradualLearningAdvantage,
      )
    ) {
      value += 5 * this.character.level;
    }

    return value;
  }

  get primariesBonus(): number {
    if (!this.character) return 0;

    const intelligence = this.character.primaries.intelligence.final;

    if (intelligence <= 5) return 0;
    if (intelligence <= 6) return 10;
    if (intelligence <= 7) return 20;
    if (intelligence <= 8) return 30;
    if (intelligence <= 9) return 40;
    if (intelligence <= 10) return 50;
    if (intelligence <= 11) return 75;
    if (intelligence <= 12) return 100;
    if (intelligence <= 13) return 150;
    if (intelligence <= 14) return 200;
    if (intelligence <= 15) return 300;
    if (intelligence <= 16) return 400;
    if (intelligence <= 17) return 500;
    if (intelligence <= 18) return 600;
    if (intelligence <= 19) return 700;

    return 800;
  }
}
