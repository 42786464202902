import {
  BasicMartialArtGradeIds,
  buildAdvancedMartialArtArcaneGrade,
  buildAdvancedMartialArtBasicGrade,
} from '../../MartialArtGrades';
import {
  AdvancedMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { calculateHighestDamageFromKnownMartialArts } from './utils/calculateHighestDamageFromKnownMartialArts';
import { HitType } from '../../../../character/model/types/HitType';
import { calculateMartialArtsRequirements } from './utils/calculateMartialArtsRequirements';

const BasicMartialArtsRequirement = [
  {
    type: MartialArtType.KungFu,
    grade: BasicMartialArtGradeIds.Advanced,
  },
];

const ArcaneMartialArtsRequirement = [
  {
    type: MartialArtType.KungFu,
    grade: BasicMartialArtGradeIds.Supreme,
  },
];

export class AsakusenMartialArt extends AdvancedMartialArt {
  readonly name = 'Asakusen';

  readonly complexity = 4;

  readonly type = MartialArtType.Asakusen;

  readonly level = MartialArtLevelType.Advanced;

  readonly grades = [
    buildAdvancedMartialArtBasicGrade({
      cmBonus: 10,
      combatBonus: {
        attack: 10,
        block: 10,
        dodge: 10,
        initiative: 10,
      },
      calculateDamage: (character) => {
        return (
          calculateHighestDamageFromKnownMartialArts(
            character,
            BasicMartialArtsRequirement,
          ) + 10
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          BasicMartialArtsRequirement,
        );

        if (character.combat.attackAbility.final < 160) {
          errors.push('Ataque 160+');
        }

        if (character.combat.defensiveAbility < 160) {
          errors.push('Bloqueo o esquiva 160+');
        }

        return errors;
      },
    }),
    buildAdvancedMartialArtArcaneGrade({
      cmBonus: 20,
      effects:
        'Permite usar un bono de +40 en vez del +20 del grado supremo de Kung Fu. +20 al crítico a ataques apuntados a puntos vitales y si no se realiza para inutilizar o dejar inconsciente',
      masterBonus: {
        attack: 10,
        block: 10,
        dodge: 10,
      },
      calculateDamage: (character) => {
        return (
          calculateHighestDamageFromKnownMartialArts(
            character,
            BasicMartialArtsRequirement,
          ) + 10
        );
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          ArcaneMartialArtsRequirement,
        );

        if (character.combat.attackAbility.final < 280) {
          errors.push('Ataque 280+');
        }

        if (character.combat.defensiveAbility < 280) {
          errors.push('Bloqueo o esquiva 280+');
        }

        if (!character.hasInhumanity) {
          errors.push('Inhumanidad');
        }

        return errors;
      },
    }),
  ];
}
