import { WeaponTable, WeaponTableType } from './WeaponTable';
import {
  PredefinedWeapon,
  PredefinedWeapons,
} from '../../weapon/PredefinedWeapons';
import { CharacterModel } from '../../../character/model/CharacterModel';
import { WeaponKnowledgeType } from '../../weapon/Weapon.types';

export class DistinctOrDisarmedWeaponTable extends WeaponTable<PredefinedWeapon> {
  name = 'Tabla de Armas Distintas o Desarmadas';

  type = WeaponTableType.DistinctOrDisarmedWeapon;

  cost = 20;

  getItems(): PredefinedWeapon[] {
    return PredefinedWeapons;
  }

  getFilteredItems(character: CharacterModel): PredefinedWeapon[] {
    return PredefinedWeapons.filter(
      (weapon) =>
        character.calculateKnowledgeTypeOf(weapon) ===
        WeaponKnowledgeType.Different,
    );
  }
}
