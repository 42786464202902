import { computed, reaction } from 'mobx';
import { getSnapshot, model } from 'mobx-keystone';
import {
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
} from '../../../../aggregations/advantage/Advantage.types';
import { InfatigableAdvantage } from '../../../../aggregations/advantage/CommonAdvantages';
import { ExhaustedDisadvantage } from '../../../../aggregations/disadvantage/CommonDisadvantages';
import { CurrentMaxField } from '../../utils/fields/CurrentMaxField';
import {
  Jayan,
  NephilimJayan,
} from '../../../../aggregations/nephilim-type/Nephilim';

@model('Character/Fatigue')
export class FatigueFieldModel extends CurrentMaxField {
  private lastMax: number | undefined;

  @computed
  get max(): number {
    if (!this.character) return 0;

    let max = this.special + this.character.primaries.constitution.final;

    if (
      this.character.creationPoints.containsAdvantage(InfatigableAdvantage, {
        level: AdvantageLevel1,
      })
    ) {
      max += 3;
    }

    if (
      this.character.creationPoints.containsAdvantage(InfatigableAdvantage, {
        level: AdvantageLevel2,
      })
    ) {
      max += 6;
    }

    if (
      this.character.creationPoints.containsAdvantage(InfatigableAdvantage, {
        level: AdvantageLevel3,
      })
    ) {
      max += 9;
    }

    if (
      this.character.creationPoints.containsDisadvantage(ExhaustedDisadvantage)
    ) {
      max -= 1;
    }

    if (this.character.isNephilim(Jayan)) {
      max += 3;
    }

    if (this.character.isNephilim(NephilimJayan)) {
      max += 1;
    }

    return max;
  }

  protected onAttachedToRootStore(rootStore: object): (() => void) | void {
    const disposers = [super.onAttachedToRootStore(rootStore)];

    let lastMax = 0;

    disposers.push(
      reaction(
        () => getSnapshot(this),
        () => {
          const max = this.max;

          if (max === lastMax) return;

          if (
            this.lastMax !== undefined &&
            this.current === this.lastMax &&
            this.current !== max
          ) {
            this.setCurrent(max);
          }

          lastMax = max;
        },
      ),
    );

    return () => {
      disposers.forEach((disposer) => {
        if (typeof disposer === 'function') disposer();
      });
    };
  }
}
