export const calculateTotalAT = (ATs: number[]): number => {
  if (ATs.length === 0) return 0;

  const orderedTas = [...ATs].sort().reverse();

  const maxAT = orderedTas[0] ?? 0;

  const sumOtherTas = orderedTas
    .slice(1)
    .reduce((prev, curr) => prev + curr, 0);

  return maxAT + Math.floor(sumOtherTas / 2);
};
