import styled from 'styled-components';
import {
  BasicParagraph,
  BasicTitle,
} from '../../../../shared/application/design-system/components/Texts/Texts.styled';

export const Title = styled(BasicTitle)(() => ({}));

export const Paragraph = styled(BasicParagraph)(() => ({}));

export const IssueList = styled.ul(() => ({}));

export const IssueRow = styled.li(() => ({}));

export const Content = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',

  gap: size.units(2),

  height: '100%',
}));

export const ButtonContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',

  gap: size.units(2),
}));

export const Wrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',

  width: '100%',

  height: size.units(50),
}));
