import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class BoxingMartialArt extends BasicMartialArt {
  readonly name = 'Boxing';

  readonly complexity = 1;

  readonly type = MartialArtType.Boxing;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final * 2 + 10;
      },
      calculateHitType: () => [HitType.Impact],
      combatBonus: {
        initiative: 5,
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.vigor.featsOfStrength.final < 30) {
          errors.push('Proezas de fuerza 30+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      effects: '+10 HA en contraataque',
      combatBonus: {
        initiative: 5,
      },
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final * 2 + 20;
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.vigor.featsOfStrength.final < 60) {
          errors.push('Proezas de fuerza 60+');
        }

        if (character.combat.attackAbility.final < 120) {
          errors.push('Ataque 120+');
        }

        if (character.combat.defensiveAbility < 120) {
          errors.push('Bloqueo o esquiva 120+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      combatBonus: {
        initiative: 10,
        attack: 10,
      },
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final * 2 + 30;
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.vigor.featsOfStrength.final < 120) {
          errors.push('Proezas de fuerza 120+');
        }

        if (character.combat.attackAbility.final < 200) {
          errors.push('Ataque 200+');
        }

        if (character.combat.defensiveAbility < 200) {
          errors.push('Bloqueo o esquiva 200+');
        }

        return errors;
      },
    }),
  ];
}
