import { BaseFinalFieldModel } from '../../../../../../../../../domain/character/model/utils/fields/BaseFinalFieldModel';
import { CurrentMaxField } from '../../../../../../../../../domain/character/model/utils/fields/CurrentMaxField';
import { Calculations } from '../../Calculations/Calculations';
import type { NumericCharacterField } from '../DoubleInput';

const getLeftTooltip = (field: NumericCharacterField) => {
  if (field instanceof CurrentMaxField) {
    return 'Valor actual';
  }

  if (field instanceof BaseFinalFieldModel) {
    return 'Valor base';
  }

  return 'Valor especial';
};

const getRightTooltip = (field: NumericCharacterField) => {
  if (field instanceof CurrentMaxField) {
    return 'Máx. valor';
  }

  const calculations = field.getCalculations?.() ?? [];

  if (calculations.length > 0) {
    return <Calculations calculations={calculations} />;
  }

  if (field instanceof BaseFinalFieldModel) {
    return 'Valor final';
  }

  return 'Valor final';
};

export const getTooltips = (field: NumericCharacterField) => {
  return {
    leftTooltip: getLeftTooltip(field),
    rightTooltip: getRightTooltip(field),
  };
};
