import { v4 as uuid } from 'uuid';
import { ActorMutator } from '../../../ActorCharacterTransformer';
import { FoundryVTTActor } from '../FoundryVTTActor';
import { ABFItems, CombatTableDataSource } from '../FoundryVTTActor.types';
import { createBaseFinalField } from './utils/createBaseFinalField';
import { getAllItemsOf } from '../../../../../../character/domain/character/model/utils/getTotalOf';

export const mutateActorCombat: ActorMutator<FoundryVTTActor> = ({
  actor,
  character,
}) => {
  const weaponTables: CombatTableDataSource[] = getAllItemsOf(
    character.content,
    (c) => c.combat.weaponTables,
  ).map((c) => ({
    _id: uuid(),
    name: c.table.name,
    type: ABFItems.COMBAT_TABLE,
    data: {},
  }));

  const martialArtsWeaponTables: CombatTableDataSource[] = getAllItemsOf(
    character.content,
    (c) => c.combat.martialArtWeaponTables,
  ).map((c) => ({
    _id: uuid(),
    name: c.table.name,
    type: ABFItems.COMBAT_TABLE,
    data: {},
  }));

  const styleTables: CombatTableDataSource[] = getAllItemsOf(
    character.content,
    (c) => c.combat.styleTables,
  ).map((c) => ({
    _id: uuid(),
    name: c.table.name,
    type: ABFItems.COMBAT_TABLE,
    data: {},
  }));

  actor.data.combat = {
    ...actor.data.combat,
    attack: createBaseFinalField(
      character.content.combat.attackAbility.finalWithoutPenalties,
    ),
    dodge: createBaseFinalField(
      character.content.combat.dodgeAbility.finalWithoutPenalties,
    ),
    block: createBaseFinalField(
      character.content.combat.blockAbility.finalWithoutPenalties,
    ),
    wearArmor: {
      value: character.content.combat.wearArmorAbility.final,
    },
    combatTables: [...weaponTables, ...martialArtsWeaponTables, ...styleTables],
  };

  return { actor };
};
