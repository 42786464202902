import { model, Model, prop } from 'mobx-keystone';
import { DiseaseResistanceField } from './fields/DiseaseResistanceField';
import { MagicResistanceField } from './fields/MagicResistanceField';
import { PhysicalResistanceField } from './fields/PhysicalResistanceField';
import { PoisonResistanceField } from './fields/PoisonResistanceField';
import { PsychicResistanceField } from './fields/PsychicResistanceField';

@model('ResistanceFields')
export class ResistanceFields extends Model({
  physical: prop(() => new PhysicalResistanceField({})),
  disease: prop(() => new DiseaseResistanceField({})),
  poison: prop(() => new PoisonResistanceField({})),
  magic: prop(() => new MagicResistanceField({})),
  psychic: prop(() => new PsychicResistanceField({})),
}) {}
