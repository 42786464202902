import { Character } from '../domain/character/Character';
import { getAuthorizedUser } from '../../auth/infrastructure/AuthorizationService';
import { NotFoundError } from '../../shared/infrastructure/errors/NotFoundError';
import { CacheWrapper } from '../../shared/infrastructure/RequestMapCache';
import { SupabaseClient } from '../../shared/infrastructure/supabase/supabaseClient';
import { Database } from '../../shared/infrastructure/supabase/Supabase.types';
import { ApiClient } from '../../shared/infrastructure/api/ApiClient';
import { CharacterHit } from '../domain/character/CharacterHit';
import { OptionalLevel } from '../application/pages/CharactersRoomPage/components/CharactersRoom/components/LevelSelectorFilter/LevelSelectorFilter';
import { OptionalCategory } from '../application/pages/CharactersRoomPage/components/CharactersRoom/components/CategorySelectorFilter/CategorySelectorFilter';

const getCharacters = async (
  folderId?: string,
): Promise<{ characters: Character[]; characterErrorNames: string[] }> => {
  const queryParams = new URLSearchParams(
    folderId ? { folder_id: folderId } : {},
  );

  const charactersRaw = await ApiClient.get<CharacterDatabaseRow[]>(
    `/character/my-characters?${queryParams.toString()}`,
  ).then((r) => r.data);

  const characters: Character[] = [];
  const characterErrorNames: string[] = [];

  for (const row of charactersRaw) {
    try {
      characters.push(Character.fromPrimitives(row));
    } catch (e) {
      console.error(e);

      const characterName = (row.content as any)?.name;

      if (characterName) {
        characterErrorNames.push(characterName);
      } else {
        characterErrorNames.push('¿Personaje sin nombre?');
      }
    }
  }

  return { characters, characterErrorNames };
};

const getCharactersCollaborations = async () => {
  const charactersRaw = await ApiClient.get<CharacterDatabaseRow[]>(
    '/character/my-characters-collaborations',
  ).then((r) => r.data);

  const characters: Character[] = [];
  const characterErrorNames: string[] = [];

  for (const row of charactersRaw) {
    try {
      characters.push(Character.fromPrimitives(row));
    } catch (e) {
      console.error(e);

      const characterName = (row.content as any)?.name;

      if (characterName) {
        characterErrorNames.push(characterName);
      } else {
        characterErrorNames.push('¿Personaje sin nombre?');
      }
    }
  }

  return { characters, characterErrorNames };
};

const getCharacter = CacheWrapper('getCharacter', async (id: string) => {
  const rawCharacter = await ApiClient.get<CharacterDatabaseRow>(
    `character/get/${id}`,
  ).then((r) => r.data);

  if (!rawCharacter) {
    throw new NotFoundError(`Character with id ${id} not found`);
  }

  return Character.fromPrimitives(rawCharacter);
});

const createCharacter = async (character: Character) => {
  const { data, error } = await SupabaseClient()
    .from('character')
    .upsert({
      ...character.toPrimitives(),
      id: undefined,
    })
    .select('*');

  if (error) {
    throw new Error(error.message);
  }
  return Character.fromPrimitives(data[0]);
};

const saveCharacter = async (character: Character) => {
  await ApiClient.put(
    `character/update/${character.id}`,
    character.toPrimitives(),
  );
};

const deleteCharacter = async (character: Character) => {
  await ApiClient.delete(`character/delete/${character.id}`);
};

const uploadCharacterImage = async (
  character: Character,
  image: File | Blob,
) => {
  const authorizedUser = getAuthorizedUser();

  const path = `${authorizedUser.id}/${character.id}.png`;

  const { error: uploadError } = await SupabaseClient()
    .storage.from('character-tokens')
    .upload(path, image, {
      upsert: true,
    });

  if (uploadError) {
    throw uploadError;
  }

  const expiresIn = Number.MAX_SAFE_INTEGER;

  const { data, error } = await SupabaseClient()
    .storage.from('character-tokens')
    .createSignedUrl(path, expiresIn);

  if (error) {
    throw error;
  }

  return data;
};

const searchCharacters = async (search: {
  level: OptionalLevel;
  needle: string;
  category: OptionalCategory;
}): Promise<CharacterHit[]> => {
  const searchParams = new URLSearchParams();

  if (search.needle) {
    searchParams.set('needle', search.needle);
  }

  if (search.category) {
    searchParams.set('category', search.category.toString());
  }

  if (search.level) {
    searchParams.set('level', search.level.toString());
  }

  const characters = await ApiClient.get<any[]>(
    `/character/search?${searchParams.toString()}`,
  ).then((r) => r.data);

  return characters.map((c) => CharacterHit.fromPrimitives(c));
};

const markCharacterAsEditing = async (characterId: string) => {
  return ApiClient.put(
    `/character-collaboration/mark-as-editing/${characterId}`,
  );
};

const markCharacterAsNotEditing = async (characterId: string) => {
  return ApiClient.delete(
    `/character-collaboration/mark-as-not-editing/${characterId}`,
  );
};

export const CharacterService = {
  createCharacter,
  getCharacters,
  getCharactersCollaborations,
  getCharacter,
  markCharacterAsEditing,
  markCharacterAsNotEditing,
  saveCharacter,
  searchCharacters,
  deleteCharacter,
  uploadCharacterImage,
};

export type CharacterDatabaseRow =
  Database['public']['Tables']['character']['Row'];
