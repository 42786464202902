import styled from 'styled-components';
import { Button } from '../../../../../shared/application/design-system/components/Button/Button';
import { Input } from '../../../../../shared/application/design-system/components/Input/Input';
import {
  BasicParagraph,
  BasicTitle,
} from '../../../../../shared/application/design-system/components/Texts/Texts.styled';

export const WhiteTitle = styled(BasicTitle)(({ theme: { color } }) => ({
  color: color.white(),
}));

export const WhiteParagraph = styled(BasicParagraph)(
  ({ theme: { color } }) => ({
    color: color.white(),
  }),
);

export const Header = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: size.units(10),

  gap: size.units(4),
}));

export const SignInOptionsTabContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',

  maxWidth: size.units(20),

  gap: size.units(1),
}));

export const InputsContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',

  width: '100%',

  gap: size.units(2),
}));

export const StyledInput = styled(Input)({});

export const SocialLoginContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100%',

  gap: size.units(2),
}));

export const StyledButton = styled(Button)({});

export const Container = styled.div(
  ({ theme: { size, color, borderRadius, mixins } }) => ({
    borderRadius: borderRadius.medium,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    ...mixins.responsive.xs.downward({
      width: '100%',
      height: '100%',
    }),

    minWidth: size.units(50),

    backgroundColor: color.black(),

    padding: size.units(3),
    paddingTop: size.units(5),
    paddingBottom: size.units(5),

    boxSizing: 'border-box',

    [Header]: {
      marginBottom: size.units(5),
    },

    [SignInOptionsTabContainer]: {
      marginBottom: size.units(3),
    },

    [SocialLoginContainer]: {
      marginBottom: size.units(3),
    },

    [InputsContainer]: {
      marginBottom: size.units(1.5),
    },

    [StyledButton]: {
      marginTop: size.units(1.5),
    },
  }),
);

export const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100%',
  height: '100%',
});
