import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';
import { getMartialArtWeaponTableFromType } from '../../../../../aggregations/tables/martial-art-tables/getMartialArtWeaponTableFromType';
import {
  MartialArtWeaponTable,
  MartialArtWeaponTableType,
} from '../../../../../aggregations/tables/martial-art-tables/MartialArtWeaponTable';

@model(
  'Character/Development/Category/Combat/CombatTables/MartialArtWeaponTable',
)
export class MartialArtWeaponTableModel extends Model({
  type: prop<MartialArtWeaponTableType>(
    MartialArtWeaponTableType.Aikido,
  ).withSetter(),
}) {
  @computed
  get table(): MartialArtWeaponTable {
    return getMartialArtWeaponTableFromType(this.type);
  }

  @computed
  get name() {
    return this.table.name;
  }

  @computed
  get cost(): number {
    return this.table.cost;
  }
}
