import { getParent, model } from 'mobx-keystone';
import { MagicPathNaturalKnowledgeAdvantage } from '../../../../../../aggregations/advantage/DonAdvantages';
import { BaseFinalFieldModel } from '../../../../utils/fields/BaseFinalFieldModel';
import { MagicPathModel } from './MagicPathModel';

@model('Character/Mystic/MagicPath/Level')
export class MagicPathLevelModel extends BaseFinalFieldModel {
  get final(): number {
    return this.used + this.fromAdvantages;
  }

  get used() {
    return this.base + this.special;
  }

  get fromAdvantages(): number {
    if (!this.character) return 0;

    let value = 0;

    const advantage = this.character.creationPoints.getAdvantage(
      MagicPathNaturalKnowledgeAdvantage,
    );

    const variationId = advantage?.variationId;

    if (variationId === this.magicPath?.path) {
      value += 40;
    }

    return value;
  }

  get magicPath(): MagicPathModel | undefined {
    return getParent<MagicPathModel>(this);
  }
}
