import {
  InputContainer,
  InputsContainer,
  StyledInput,
  WhiteSmallParagraph,
} from '../../Autorization.styled';
import { Button } from '../../../../../../../shared/application/design-system/components/Button/Button';
import { ButtonSize } from '../../../../../../../shared/application/design-system/components/Button/Button.types';
import {
  Routes,
  useNavigate,
} from '../../../../../../../shared/application/hooks/useNavigate';
import { useSearchParams } from 'react-router-dom';
import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';
import {
  LocalStorageKeys,
  LocalStorageManager,
} from '../../../../../../../shared/infrastructure/LocalStorageManager';
import { AuthError } from '@supabase/supabase-js';
import { NotificationManager } from '../../../../../../../shared/application/shared/NotificationManager';
import { useUserAuthorization } from '../../../../../../../shared/application/hooks/useUserAuthorization';

const errorMessages = {
  signIn: [
    {
      status: 400,
      needle: 'Invalid login credentials',
      message: 'Email o contraseña incorrectos',
    },
  ],
};
export const SignIn = ({ buttonText }: { buttonText: string }) => {
  const { navigate } = useNavigate();
  const queryParams = useSearchParams();

  const hasEmbeddedQueryParam = queryParams[0]?.get?.('embed') === 'true';
  const [email, setEmail] = useState(
    LocalStorageManager.getItem(LocalStorageKeys.LoginEmail) ?? '',
  );

  const [password, setPassword] = useState('');

  const [isSignInOrSigningUp, setIsSignInOrSigningUp] = useState(false);

  const { login } = useUserAuthorization({
    redirect: false,
  });

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const navigateToMyCharacters = useCallback(
    () =>
      navigate(Routes.MyCharacters, {
        replace: true,
        params: { folderId: '' },
      }),
    [navigate],
  );

  const handleNavigation = () => {
    if (hasEmbeddedQueryParam) {
      window.close();
    } else {
      navigateToMyCharacters();
    }
  };
  const validParams = email.trim().length > 0 && password.trim().length > 3;

  const handleEnterKey = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSignIn();
    }
  };

  const handleSignIn = () => {
    if (!validParams) return;

    setIsSignInOrSigningUp(true);

    LocalStorageManager.setItem(LocalStorageKeys.LoginEmail, email);

    login(email, password)
      .then(() => {
        handleNavigation();
      })
      .catch((e) => {
        if (e instanceof AuthError) {
          const error = errorMessages.signIn.find(
            (error) =>
              error.status === e.status && e.message.includes(error.needle),
          );

          if (error) {
            NotificationManager.error(error.message);
          }
        }

        console.error(e);
        setIsSignInOrSigningUp(false);
      });
  };
  return (
    <>
      <InputsContainer>
        <InputContainer>
          <WhiteSmallParagraph>Email</WhiteSmallParagraph>
          <StyledInput
            name="email"
            value={email}
            onChange={handleEmailChange}
            onKeyDown={handleEnterKey}
          />
        </InputContainer>
        <InputContainer>
          <WhiteSmallParagraph>Contraseña</WhiteSmallParagraph>
          <StyledInput
            name="password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={handleEnterKey}
          />
        </InputContainer>
      </InputsContainer>
      <Button
        loading={isSignInOrSigningUp}
        size={ButtonSize.Big}
        onClick={handleSignIn}
        disabled={!validParams}
      >
        {buttonText}
      </Button>
    </>
  );
};
