import { ReactNode } from 'react';
import { TableRowCell } from './components/TableRowCell/TableRowCell';
import { StyledRow } from './TableRow.styled';

type TableCellProps = {
  children: ReactNode | ReactNode[];
};

export const TableRow = ({ children }: TableCellProps) => {
  return <StyledRow>{children}</StyledRow>;
};

TableRow.Cell = TableRowCell;
