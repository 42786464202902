import { BigTitle } from '../../../../../../shared/application/design-system/components/Texts/Texts.styled';
import {
  InputContainer,
  InputContainerLabel,
  InputLabel,
  StyledInput,
  Wrapper,
} from './MyProfile.styled';
import { useUserProfile } from './hooks/useUserProfile';
import { ChangeEvent, useState } from 'react';
import { Button } from '../../../../../../shared/application/design-system/components/Button/Button';
import { NotificationManager } from '../../../../../../shared/application/shared/NotificationManager';

const isValidUsername = (username: string) => username.length > 3;
const isValidPassword = (password: string) =>
  password.length === 0 || password.length >= 6;

export const MyProfile = () => {
  const {
    profile,
    changeUsername,
    changingUsername,
    changePassword,
    changingPassword,
  } = useUserProfile();

  const [username, setUsername] = useState<string>(profile.username);

  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const handleSavePassword = async () => {
    if (password.length === 0) return;
    if (!isValidPassword(password)) return;

    if (password !== confirmPassword) {
      return;
    }

    await changePassword(password);

    NotificationManager.success('Contraseña actualizada');

    setPassword('');
    setConfirmPassword('');

    return Promise.resolve();
  };

  const handleSaveUsername = async () => {
    if (username === profile.username) return;
    if (!isValidUsername(username)) return;

    const successful = await changeUsername(username);

    if (successful) {
      NotificationManager.success('Nombre de usuario actualizado');

      return;
    }

    NotificationManager.error('El usuario ya existe');
  };

  const handleSaveProfile = async () => {
    try {
      await handleSaveUsername();

      await handleSavePassword();
    } catch (e) {
      NotificationManager.error('Ha ocurrido un error guardando el perfil');
    }
  };

  return (
    <Wrapper>
      <BigTitle>Tu perfil</BigTitle>
      <InputContainer>
        <InputContainerLabel>Correo</InputContainerLabel>
        <StyledInput value={profile.email} disabled />
      </InputContainer>
      <InputContainer>
        <InputContainerLabel>Username</InputContainerLabel>
        <StyledInput value={username} onChange={handleUsernameChange} />
      </InputContainer>
      <InputContainer>
        <InputContainerLabel>Cambiar contraseña</InputContainerLabel>
        <InputLabel>Nueva contraseña</InputLabel>
        <StyledInput
          type="password"
          value={password}
          onChange={handlePasswordChange}
          info={
            !isValidPassword(password) ? 'La contraseña es demasiado corta' : ''
          }
        />
        <InputLabel>Confirmar contraseña</InputLabel>
        <StyledInput
          type="password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          info={
            confirmPassword.length > 0 && password !== confirmPassword
              ? 'Las contraseñas no coinciden'
              : ''
          }
        />
      </InputContainer>
      <Button
        loading={changingUsername || changingPassword}
        onClick={handleSaveProfile}
        disabled={
          !isValidPassword(password) ||
          password !== confirmPassword ||
          !isValidUsername(username)
        }
      >
        Guardar
      </Button>
    </Wrapper>
  );
};
