import { createContext, useContext } from 'react';
import { Character } from '../../../../../../domain/character/Character';

export type CharacterContextType = { character: Character };

export const CharacterContext = createContext({} as CharacterContextType);

export const useCharacterContext = () => {
  const context = useContext(CharacterContext) as CharacterContextType;

  if (!context) {
    throw new Error('useCharacter must be used within a CharacterProvider');
  }

  return context;
};
