import {
  LifeRegenerationPeriod,
  PenaltyRegenerationPeriod,
} from '../Regeneration.types';
import type { RegenerationFieldModel } from '../RegenerationFieldModel';

const calculateRestingLifeRegeneration = (
  value: number,
): RegenerationFieldModel['info']['lifeRegeneratedResting'] => {
  const values = [
    [0, LifeRegenerationPeriod.Day],
    [10, LifeRegenerationPeriod.Day],
    [20, LifeRegenerationPeriod.Day],
    [30, LifeRegenerationPeriod.Day],
    [40, LifeRegenerationPeriod.Day],
    [50, LifeRegenerationPeriod.Day],
    [75, LifeRegenerationPeriod.Day],
    [100, LifeRegenerationPeriod.Day],
    [250, LifeRegenerationPeriod.Day],
    [500, LifeRegenerationPeriod.Day],
    [1, LifeRegenerationPeriod.Minute],
    [2, LifeRegenerationPeriod.Minute],
    [5, LifeRegenerationPeriod.Minute],
    [10, LifeRegenerationPeriod.Minute],
    [1, LifeRegenerationPeriod.Turn],
    [5, LifeRegenerationPeriod.Turn],
    [10, LifeRegenerationPeriod.Turn],
    [25, LifeRegenerationPeriod.Turn],
    [50, LifeRegenerationPeriod.Turn],
    [100, LifeRegenerationPeriod.Turn],
    [250, LifeRegenerationPeriod.Turn],
  ] as const;

  const info = values[Math.max(0, Math.min(value, values.length - 1))];

  return {
    value: info[0],
    per: info[1],
  };
};

const calculateRestingLifeRegenerationWithoutResting = (
  value: number,
): RegenerationFieldModel['info']['lifeRegeneratedWithoutResting'] => {
  const values = [
    [0, LifeRegenerationPeriod.Day],
    [5, LifeRegenerationPeriod.Day],
    [10, LifeRegenerationPeriod.Day],
    [15, LifeRegenerationPeriod.Day],
    [20, LifeRegenerationPeriod.Day],
    [25, LifeRegenerationPeriod.Day],
    [30, LifeRegenerationPeriod.Day],
    [50, LifeRegenerationPeriod.Day],
    [100, LifeRegenerationPeriod.Day],
    [200, LifeRegenerationPeriod.Day],
  ] as const;

  const info = values[value];

  if (!info) return undefined;

  return {
    value: info[0],
    per: info[1],
  };
};

const calculatePenaltyReduction = (
  value: number,
): RegenerationFieldModel['info']['penaltyReduction'] => {
  const values = [
    [0, PenaltyRegenerationPeriod.Day],
    [-5, PenaltyRegenerationPeriod.Day],
    [-5, PenaltyRegenerationPeriod.Day],
    [-5, PenaltyRegenerationPeriod.Day],
    [-10, PenaltyRegenerationPeriod.Day],
    [-10, PenaltyRegenerationPeriod.Day],
    [-15, PenaltyRegenerationPeriod.Day],
    [-20, PenaltyRegenerationPeriod.Day],
    [-25, PenaltyRegenerationPeriod.Day],
    [-30, PenaltyRegenerationPeriod.Day],
    [-40, PenaltyRegenerationPeriod.Day],
    [-50, PenaltyRegenerationPeriod.Day],
    [-5, PenaltyRegenerationPeriod.Hour],
    [-10, PenaltyRegenerationPeriod.Hour],
    [-15, PenaltyRegenerationPeriod.Hour],
    [-20, PenaltyRegenerationPeriod.Hour],
    [-10, PenaltyRegenerationPeriod.Minute],
    [-10, PenaltyRegenerationPeriod.Turn],
    [-25, PenaltyRegenerationPeriod.Turn],
    [9999, PenaltyRegenerationPeriod.Instant],
    [9999, PenaltyRegenerationPeriod.Instant],
  ] as const;

  const info = values[Math.max(0, Math.min(value, values.length - 1))];

  return {
    value: info[0],
    per: info[1],
  };
};

const calculateSpecialCapabilities = (
  value: number,
): RegenerationFieldModel['info']['specialCapabilities'] => {
  if (value <= 4) return undefined;

  const values = [
    'No quedan cicatrices',
    'El personaje no sufre los efectos del desangramiento',
    'Los miembros limpiamente amputados se recuperan si se unen al muñón durante una semana',
    'Los miembros limpiamente amputados se recuperan si se unen al muñón durante cinco días',
    'Los miembros limpiamente amputados se recuperan si se unen al muñón durante tres días. El personaje supera automáticamente el estado entre la vida y la muerte.',
    'Los miembros limpiamente amputados se recuperan si se unen al muñón durante 1 día. El personaje supera automáticamente el estado entre la vida y la muerte.',
    'Cualquier miembro amputado se recupera, uniendo los restos al muñón durante una semana.',
    'Cualquier miembro amputado se recupera, uniendo los restos al muñón durante tres días',
    'Cualquier miembro amputado se recupera, uniendo los restos al muñón durante un día.',
    'Cualquier miembro amputado se recupera, uniendolo al muñón durante unas pocas horas',
    'Cualquier miembro se recupera inmediatamente si se une al muñón durante un asalto. Salvo la cabeza, cualquier miembro amputado vuelve a crecer en una semana.',
    'Salvo la cabeza cualquier miembro amputado vuelve a crecer en un día.',
    'Salvo la cabeza cualquier miembro amputado vuelve a crecer en pocos minutos.',
    'Salvo la cabeza cualquier miembro amputado vuelve a crecer en pocos asaltos.',
    'Crece cualquier miembro seccionado y es completamente funcional en un asalto. Se requiere Gnosis +40.',
    'Todos los críticos físicos son anulados. Se requiere Gnosis +45.',
  ] as const;

  return values[Math.max(0, Math.min(value - 5, values.length - 1))];
};

export const calculateRegenerationInfo = (
  value: number,
): RegenerationFieldModel['info'] => {
  return {
    lifeRegeneratedResting: calculateRestingLifeRegeneration(value),
    lifeRegeneratedWithoutResting:
      calculateRestingLifeRegenerationWithoutResting(value),
    penaltyReduction: calculatePenaltyReduction(value),
    specialCapabilities: calculateSpecialCapabilities(value),
  };
};
