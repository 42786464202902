import { WeaponKnowledgeType } from '../Weapon.types';

export const getWeaponKnowledgePenalty = (knowledge: WeaponKnowledgeType) => {
  switch (knowledge) {
    case WeaponKnowledgeType.Similar:
      return -20;
    case WeaponKnowledgeType.Mixed:
      return -40;
    case WeaponKnowledgeType.Different:
      return -60;
    default:
      return 0;
  }
};
