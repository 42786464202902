import { model } from 'mobx-keystone';
import {
  CombatSenseAdvantage,
  CombatSenseAdvantageVariation,
} from '../../../../../aggregations/advantage/CommonAdvantages';
import { getTotalCalculationsOf, getTotalOf } from '../../../utils/getTotalOf';
import { DevelopedPDPhysicalFieldModel } from '../../../utils/fields/DevelopedPDPhysicalFieldModel';
import { computed } from 'mobx';
import { Calculation } from '../../../utils/fields/NumberField';

@model('Character/Combat/AttackAbility')
export class AttackAbilityFieldModel extends DevelopedPDPhysicalFieldModel {
  get primariesBonus(): number {
    if (!this.character) return 0;

    return this.character.primaries.dexterity.modifier.final;
  }

  get boughtFromCategories(): number {
    return getTotalOf(this.character, (c) => c.combat.attackAbility.bought);
  }

  get fromCategories(): number {
    return this.calculateFromCalculations(this.fromCategoriesCalculations);
  }

  get fromCategoriesCalculations(): Calculation[] {
    if (!this.character) return [];

    const calculations: Calculation[] = [];

    const bought = getTotalCalculationsOf(
      'Puntos comprados',
      this.character,
      (c) => c.combat.attackAbility.bought,
    );

    calculations.push(...bought);

    const bonus = getTotalCalculationsOf(
      'Bonos',
      this.character,
      (c) => c.combat.attackAbility.bonus,
    );

    calculations.push(
      this.sum(
        'Bonos por artes marciales avanzadas',
        this.character.combat.martialArtsHighestMasterBonus.attack,
        { hideOnZero: true },
      ),
    );

    calculations.push(...bonus);

    const categoryCalculations: Calculation[] = [];

    const totalFromPerCategory = getTotalCalculationsOf(
      'Por categoría',
      this.character,
      (c) => c.combat.attackAbility.perCategory,
    );

    categoryCalculations.push(...totalFromPerCategory);

    calculations.push(...categoryCalculations);
    calculations.push(this.max('Por categoría', 50, categoryCalculations));

    return calculations;
  }

  @computed
  get isDeveloped(): boolean {
    return this.boughtFromCategories > 0;
  }

  get bonusFromAdvantages(): number {
    if (!this.character) return 0;

    let value = 0;

    if (
      this.character.creationPoints.containsAdvantage(CombatSenseAdvantage, {
        variation: CombatSenseAdvantageVariation.Attack,
      })
    ) {
      value += 5 * this.character.level;
    }

    return value;
  }
}
