export const vibrate = function (
  options = { duration: 100, interval: 100, count: 1 },
) {
  if (options.duration < 0) {
    throw new RangeError(
      'Expected options.duration to be greater or equal to zero.',
    );
  }

  if (options.interval < 0) {
    throw new RangeError(
      'Expected options.interval to be greater or equal to zero.',
    );
  }

  if (options.count < 0) {
    throw new RangeError(
      'Expected options.count to be greater or equal to zero.',
    );
  }

  if (!window) {
    return;
  }

  if (!window.navigator) {
    return;
  }

  if (!window.navigator.vibrate) {
    return;
  }

  const pattern = [];

  for (let index = 0; index < options.count; index++) {
    pattern.push(options.duration);
    pattern.push(options.interval);
  }

  window.navigator.vibrate(pattern);
};
