import { RemoteActorItem } from './components/RemoteActorItem';
import { Wrapper } from './FoundryVTTCharacterList.styled';
import { FoundryVTTActor } from '../../../../../../../../embed/infrastructure/mutators/anima/foundry/FoundryVTTActor';

interface FoundryVTTCharacterListProps {
  actors: FoundryVTTActor[];

  onSelectActor: (actor: FoundryVTTActor) => void;
}

export const FoundryVTTCharacterList = ({
  actors,
  onSelectActor,
}: FoundryVTTCharacterListProps) => {
  return (
    <Wrapper>
      {actors.map((actor) => {
        const handleActorClick = () => onSelectActor(actor);

        return (
          <RemoteActorItem
            key={actor.id}
            actor={actor}
            onClick={handleActorClick}
          />
        );
      })}
    </Wrapper>
  );
};
