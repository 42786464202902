import { Node, NodeWrapper, TreeNode } from '../SkillTree.styled';

export type TreeElement<T> = {
  id: string;
  name: string;
  cost: number;
  parents?: TreeElement<T>[];
};

export class ElementNode<T extends TreeElement<T>> {
  public element: T;

  public parents: ElementNode<T>[] | undefined;

  public children: ElementNode<T>[];

  constructor(params: {
    element: T;
    parents?: ElementNode<T>[];
    children?: ElementNode<T>[];
  }) {
    this.element = params.element;
    this.parents = params.parents;
    this.children = params.children || [];
  }

  public addChild(child: ElementNode<T>) {
    this.children.push(child);
  }

  public getAllChildren(): ElementNode<T>[] {
    return this.children.reduce(
      (acc, child) => [...acc, child, ...child.getAllChildren()],
      [] as ElementNode<T>[],
    );
  }

  getParentsUntilRoot(): ElementNode<T>[] {
    if (this.parents) {
      return [
        ...this.parents,
        ...this.parents.flatMap((p) => p.getParentsUntilRoot()),
      ];
    }

    return [];
  }

  get deep(): number {
    if (this.parents) {
      const deep = this.parents
        .map((p) => p.deep)
        .reduce((a, b) => Math.max(a, b), 0);

      return deep + 1;
    } else {
      return 0;
    }
  }

  public setParent(parent: ElementNode<T>[]) {
    this.parents = parent;
  }

  public lookupChild(element: T): ElementNode<T> | undefined {
    let found = this.children.find((child) => child.element.id === element.id);

    if (found) return found;

    for (const child of this.children) {
      found = child.lookupChild(element);

      if (found) {
        return found;
      }
    }

    for (const parent of this.parents ?? []) {
      found = parent.lookupChild(element);

      if (found) {
        return found;
      }
    }

    return undefined;
  }

  public buildComponent(
    selectedElements: T[],
    onNodeClick: (element: ElementNode<T>) => void,
  ): JSX.Element {
    const deep = this.deep;

    return (
      <NodeWrapper key={this.element.name} $deep={deep}>
        <Node
          $deep={deep}
          $selected={selectedElements.includes(this.element)}
          onClick={() => onNodeClick(this)}
        >
          {this.element.name} - {this.element.cost}
        </Node>
        {this.children.map((child) =>
          child.buildComponent(selectedElements, onNodeClick),
        )}
      </NodeWrapper>
    );
  }
}

export class ElementTree<T extends TreeElement<T>> {
  root: ElementNode<T>;

  constructor(elements: T[]) {
    const nodes: ElementNode<T>[] = elements.map(
      (element) => new ElementNode<T>({ element }),
    );

    for (const element of elements) {
      const node = nodes.find((n) => n.element.id === element.id);
      const parents = nodes.filter((n) =>
        element.parents?.some((p) => p.id === n.element.id),
      );

      if (parents.length > 0 && node) {
        node.setParent(parents);
        parents.forEach((parent) => parent.addChild(node));
      }
    }

    const root = nodes.find((n) => !n.parents);

    if (!root) {
      throw new Error('No root node found');
    }

    this.root = root;
  }

  public lookup(element: T): ElementNode<T> | undefined {
    return this.root.lookupChild(element);
  }

  public buildComponent(
    selectedElements: T[],
    onNodeClick: (element: ElementNode<T>) => void,
  ): JSX.Element {
    return (
      <TreeNode>
        {this.root.buildComponent(selectedElements, onNodeClick)}
      </TreeNode>
    );
  }
}
