import styled from 'styled-components';
import { Input } from '../../../../../../shared/application/design-system/components/Input/Input';

export const LevelSelectorFilterContainer = styled.div(
  ({ theme: { size } }) => ({
    width: size.units(15),
  }),
);

export const CategorySelectorFilterContainer = styled.div(
  ({ theme: { size } }) => ({
    width: size.units(20),
  }),
);

export const FiltersContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',

  gap: size.units(2),
}));

export const StyledInput = styled(Input)(({ theme: { size, mixins } }) => ({
  width: size.units(60),

  ...mixins.responsive.sm.downward({
    width: '100%',
  }),
}));

export const SearchSection = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',

  gap: size.units(2),

  paddingLeft: size.units(2),
  paddingRight: size.units(2),
}));

export const Wrapper = styled.div(({ theme: { size } }) => ({
  width: '100%',
  height: '100%',

  [SearchSection]: {
    marginTop: size.units(2),
    marginBottom: size.units(3),
  },
}));
