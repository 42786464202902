import { observer } from 'mobx-react';
import { MouseEvent, useState } from 'react';
import { AllPsychicPowers } from '../../../../../../../../../domain/aggregations/psychic-power/AllPsychicPowers';
import { PsychicPower } from '../../../../../../../../../domain/aggregations/psychic-power/PsychicPower';
import { getUnusedPsychicPower } from '../../../../../../../../../domain/aggregations/psychic-power/utils/getUnusedPsychicPower';
import { InnatePsychicPowerModel } from '../../../../../../../../../domain/character/model/parts/psychic/parts/InnatePsychicPowerModel';
import { AddItemButton } from '../../../common/AddItemButton/AddItemButton';
import { Input } from '../../../common/Input/Input';
import { useCharacterMenu } from '../../../common/CharacterMenu/useCharacterMenu';
import { transformToMenuItems } from '../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { RemoveItemButton } from '../../../common/RemoveItemButton/RemoveItemButton';
import { Section } from '../../../common/Section/Section';
import { Table } from '../../../common/Table/Table';
import { useCharacterContext } from '../../../../hooks/useCharacterContext';
import { MenuItem } from '../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';

export const InnatePsychicPowers = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { handleMenuOpen, Menu } = useCharacterMenu<PsychicPower>();

  const [selectedInnatePsychicPowerModel, setSelectedInnatePsychicPowerModel] =
    useState<InnatePsychicPowerModel | undefined>(undefined);

  const addInnatePsychicPower = () => {
    const unusedPower = getUnusedPsychicPower(
      character.psychic.innatePowers.map((p) => p.power),
    );

    if (!unusedPower) return;

    character.psychic.addInnatePsychicPower(unusedPower);
  };

  const removeInnatePsychicPower = (power: InnatePsychicPowerModel) => () => {
    character.psychic.removeInnatePsychicPower(power);
  };

  const powerItems: MenuItem<PsychicPower>[] = transformToMenuItems({
    fromItems: AllPsychicPowers,
    generateItem: (item) => ({
      key: item.id,
      name: item.name,
      value: item,
      onClick: () => {
        if (selectedInnatePsychicPowerModel !== undefined) {
          selectedInnatePsychicPowerModel.setPower(item);
        }
      },
    }),
    generateDivider: (i) => i.discipline,
  });

  const handleInnatePsychicPowerClick =
    (powerModel: InnatePsychicPowerModel) =>
    (e: MouseEvent<HTMLDivElement>) => {
      setSelectedInnatePsychicPowerModel(powerModel);
      handleMenuOpen(powerModel.power)(e);
    };

  const availableInnatePowers =
    character.psychic.innatePowersSlots - character.psychic.innatePowers.length;

  return (
    <Section maxHeight="fit">
      <Section.Title>Poderes psíquicos innatos</Section.Title>
      <Input
        label="Espacios de poder psíquico innatos"
        value={character.psychic.innatePowersSlots}
        setValue={(v) => character.psychic.setInnatePowersSlots(v)}
      />
      <Section.Divider />
      <Table>
        <Table.Header>
          <Table.Header.Cell>Nombre</Table.Header.Cell>
          <Table.Header.Cell>Disciplina</Table.Header.Cell>
          <Table.Header.Cell>Nivel</Table.Header.Cell>
          <Table.Header.Cell>CVs</Table.Header.Cell>
          <Table.Header.Cell>Dificultad mínima</Table.Header.Cell>
        </Table.Header>
        {character.psychic.innatePowers.map((powerModel) => (
          <Table.Row key={powerModel.$modelId}>
            <Table.Row.Cell
              $interactable
              onClick={handleInnatePsychicPowerClick(powerModel)}
            >
              {powerModel.power.name}
            </Table.Row.Cell>
            <Table.Row.Cell>{powerModel.power.discipline}</Table.Row.Cell>
            <Table.Row.Cell>{powerModel.power.level}</Table.Row.Cell>
            <Table.Row.Cell>
              <Input
                value={powerModel.psychicPointsUsedToIncrease}
                setValue={(v) => powerModel.setPsychicPointsUsedToIncrease(v)}
              />
            </Table.Row.Cell>
            <Table.Row.Cell $bold>
              {powerModel.maintainedDifficulty}
            </Table.Row.Cell>
            <Table.Row.Cell>
              <RemoveItemButton
                onClick={removeInnatePsychicPower(powerModel)}
              />
            </Table.Row.Cell>
          </Table.Row>
        ))}
      </Table>

      <AddItemButton
        disabled={availableInnatePowers <= 0}
        onClick={addInnatePsychicPower}
      />

      <Menu items={powerItems} />
    </Section>
  );
});
