export enum MartialArtWeaponTableType {
  Aikido = 'Aikido',
  Boxing = 'Boxing',
  Capoeira = 'Capoeira',
  Grappling = 'Grappling',
  Kardad = 'Kardad',
  Kenpo = 'Kenpo',
  Kuan = 'Kuan',
  KungFu = 'KungFu',
  Lama = 'Lama',
  MallaYuddha = 'MallaYuddha',
  MoaiThai = 'MoaiThai',
  Pankration = 'Pankration',
  Sambo = 'Sambo',
  Shotokan = 'Shotokan',
  SooBahk = 'SooBahk',
  TaeKwonDo = 'TaeKwonDo',
  TaiChi = 'TaiChi',
  ZingQuan = 'ZingQuan',

  Emp = 'Emp',
  Enuth = 'Enuth',
  Hakyoukuken = 'Hakyoukuken',
  Hanja = 'Hanja',
  Melkaiah = 'Melkaiah',
  Mushin = 'Mushin',
  RexFrame = 'RexFrame',
  Selene = 'Selene',
  Seraphite = 'Seraphite',
  Shephon = 'Shephon',
}

export abstract class MartialArtWeaponTable {
  abstract readonly name: string;

  abstract type: MartialArtWeaponTableType;

  abstract cost: number;

  abstract get requirementsFulfilled(): boolean;
}

export class AikidoWeaponTable extends MartialArtWeaponTable {
  name = 'Aikido';

  type = MartialArtWeaponTableType.Aikido;

  cost = 40;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class BoxingWeaponTable extends MartialArtWeaponTable {
  name = 'Boxing';

  type = MartialArtWeaponTableType.Boxing;

  cost = 10;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class CapoeiraWeaponTable extends MartialArtWeaponTable {
  name = 'Capoeira';

  type = MartialArtWeaponTableType.Capoeira;

  cost = 30;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class GrapplingWeaponTable extends MartialArtWeaponTable {
  name = 'Grappling';

  type = MartialArtWeaponTableType.Grappling;

  cost = 10;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class KardadWeaponTable extends MartialArtWeaponTable {
  name = 'Kardad';

  type = MartialArtWeaponTableType.Kardad;

  cost = 40;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class KenpoWeaponTable extends MartialArtWeaponTable {
  name = 'Kenpo';

  type = MartialArtWeaponTableType.Kenpo;

  cost = 10;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class KuanWeaponTable extends MartialArtWeaponTable {
  name = 'Kuan';

  type = MartialArtWeaponTableType.Kuan;

  cost = 30;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class KungFuWeaponTable extends MartialArtWeaponTable {
  name = 'Kung Fu';

  type = MartialArtWeaponTableType.KungFu;

  cost = 60;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class LamaWeaponTable extends MartialArtWeaponTable {
  name = 'Lama';

  type = MartialArtWeaponTableType.Lama;

  cost = 10;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class MallaYuddhaWeaponTable extends MartialArtWeaponTable {
  name = 'Malla-Yuddha';

  type = MartialArtWeaponTableType.MallaYuddha;

  cost = 20;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class MoaiThaiWeaponTable extends MartialArtWeaponTable {
  name = 'Moai Thai';

  type = MartialArtWeaponTableType.MoaiThai;

  cost = 20;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class PankrationWeaponTable extends MartialArtWeaponTable {
  name = 'Pankration';

  type = MartialArtWeaponTableType.Pankration;

  cost = 20;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class SamboWeaponTable extends MartialArtWeaponTable {
  name = 'Sambo';

  type = MartialArtWeaponTableType.Sambo;

  cost = 30;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class ShotokanWeaponTable extends MartialArtWeaponTable {
  name = 'Shotokan';

  type = MartialArtWeaponTableType.Shotokan;

  cost = 20;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class SooBahkWeaponTable extends MartialArtWeaponTable {
  name = 'Soo Bahk';

  type = MartialArtWeaponTableType.SooBahk;

  cost = 10;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class TaeKwonDoWeaponTable extends MartialArtWeaponTable {
  name = 'Tae Kwon Do';

  type = MartialArtWeaponTableType.TaeKwonDo;

  cost = 40;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class TaiChiWeaponTable extends MartialArtWeaponTable {
  name = 'Tai Chi';

  type = MartialArtWeaponTableType.TaiChi;

  cost = 30;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class ZingQuanWeaponTable extends MartialArtWeaponTable {
  name = 'Zing Quan';

  type = MartialArtWeaponTableType.ZingQuan;

  cost = 40;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class EmpWeaponTable extends MartialArtWeaponTable {
  name = 'Emp';

  type = MartialArtWeaponTableType.Emp;

  cost = 40;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class EnuthWeaponTable extends MartialArtWeaponTable {
  name = 'Enuth';

  type = MartialArtWeaponTableType.Enuth;

  cost = 10;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class HakyoukukenWeaponTable extends MartialArtWeaponTable {
  name = 'Hakyoukuken';

  type = MartialArtWeaponTableType.Hakyoukuken;

  cost = 20;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class HanjaWeaponTable extends MartialArtWeaponTable {
  name = 'Hanja';

  type = MartialArtWeaponTableType.Hanja;

  cost = 20;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class MelkaiahWeaponTable extends MartialArtWeaponTable {
  name = 'Melkaiah';

  type = MartialArtWeaponTableType.Melkaiah;

  cost = 20;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class MushinWeaponTable extends MartialArtWeaponTable {
  name = 'Mushin';

  type = MartialArtWeaponTableType.Mushin;

  cost = 10;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class RexFrameWeaponTable extends MartialArtWeaponTable {
  name = 'Rex Frame';

  type = MartialArtWeaponTableType.RexFrame;

  cost = 40;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class SeleneWeaponTable extends MartialArtWeaponTable {
  name = 'Selene';

  type = MartialArtWeaponTableType.Selene;

  cost = 40;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class SeraphiteWeaponTable extends MartialArtWeaponTable {
  name = 'Seraphite';

  type = MartialArtWeaponTableType.Seraphite;

  cost = 30;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export class ShephonWeaponTable extends MartialArtWeaponTable {
  name = 'Shephon';

  type = MartialArtWeaponTableType.Shephon;

  cost = 20;

  get requirementsFulfilled(): boolean {
    return true;
  }
}

export const mapMartialArtWeaponTypeToTable: Record<
  MartialArtWeaponTableType,
  { new (): MartialArtWeaponTable }
> = {
  [MartialArtWeaponTableType.Aikido]: AikidoWeaponTable,
  [MartialArtWeaponTableType.Boxing]: BoxingWeaponTable,
  [MartialArtWeaponTableType.Capoeira]: CapoeiraWeaponTable,
  [MartialArtWeaponTableType.Grappling]: GrapplingWeaponTable,
  [MartialArtWeaponTableType.Kardad]: KardadWeaponTable,
  [MartialArtWeaponTableType.Kenpo]: KenpoWeaponTable,
  [MartialArtWeaponTableType.Kuan]: KuanWeaponTable,
  [MartialArtWeaponTableType.KungFu]: KungFuWeaponTable,
  [MartialArtWeaponTableType.Lama]: LamaWeaponTable,
  [MartialArtWeaponTableType.MallaYuddha]: MallaYuddhaWeaponTable,
  [MartialArtWeaponTableType.MoaiThai]: MoaiThaiWeaponTable,
  [MartialArtWeaponTableType.Pankration]: PankrationWeaponTable,
  [MartialArtWeaponTableType.Sambo]: SamboWeaponTable,
  [MartialArtWeaponTableType.Shotokan]: ShotokanWeaponTable,
  [MartialArtWeaponTableType.SooBahk]: SooBahkWeaponTable,
  [MartialArtWeaponTableType.TaeKwonDo]: TaeKwonDoWeaponTable,
  [MartialArtWeaponTableType.TaiChi]: TaiChiWeaponTable,
  [MartialArtWeaponTableType.ZingQuan]: ZingQuanWeaponTable,
  [MartialArtWeaponTableType.Emp]: EmpWeaponTable,
  [MartialArtWeaponTableType.Enuth]: EnuthWeaponTable,
  [MartialArtWeaponTableType.Hakyoukuken]: HakyoukukenWeaponTable,
  [MartialArtWeaponTableType.Hanja]: HanjaWeaponTable,
  [MartialArtWeaponTableType.Melkaiah]: MelkaiahWeaponTable,
  [MartialArtWeaponTableType.Mushin]: MushinWeaponTable,
  [MartialArtWeaponTableType.RexFrame]: RexFrameWeaponTable,
  [MartialArtWeaponTableType.Selene]: SeleneWeaponTable,
  [MartialArtWeaponTableType.Seraphite]: SeraphiteWeaponTable,
  [MartialArtWeaponTableType.Shephon]: ShephonWeaponTable,
};
