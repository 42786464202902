import { model } from 'mobx-keystone';
import { NaturalArmorAdvantage } from '../../../../../aggregations/advantage/CommonAdvantages';
import { calculateTotalAT } from '../utils/calculateTotalAT';
import { TotalATField } from './TotalATField';

@model('Character/TotalElectricityAT')
export class TotalElectricityATField extends TotalATField {
  get final(): number {
    if (!this.character) return 0;

    let ta = super.final;

    if (
      this.character.creationPoints.containsAdvantage(NaturalArmorAdvantage)
    ) {
      ta += 2;
    }

    return (
      ta +
      calculateTotalAT(
        this.character.inventory.equippedArmors.map(
          (a) => a.at.electricity.final,
        ),
      )
    );
  }
}
