import { observer } from 'mobx-react';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import DoubleInput from '../../../../common/DoubleInput/DoubleInput';
import { Section } from '../../../../common/Section/Section';
import { StringInput } from '../../../../common/StringInput/StringInput';
import { DescriptionRow } from './Description.styled';

export const Description = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  return (
    <Section>
      <Section.Title>Personaje</Section.Title>
      <DescriptionRow>
        <StringInput
          label="Sexo"
          value={character.description.gender}
          setValue={(v) => character.description.setGender(v)}
        />
        <StringInput
          label="Ojos"
          value={character.description.eyes}
          setValue={(v) => character.description.setEyes(v)}
        />
        <StringInput
          label="Pelo"
          value={character.description.hair}
          setValue={(v) => character.description.setHair(v)}
        />
      </DescriptionRow>
      <DescriptionRow>
        <StringInput
          label="Raza"
          value={character.description.race}
          setValue={(v) => character.description.setRace(v)}
        />
        <StringInput
          label="Peso (kg)"
          value={character.description.weight}
          setValue={(v) => character.description.setWeight(v)}
        />
        <StringInput
          label="Altura (m)"
          value={character.description.height}
          setValue={(v) => character.description.setHeight(v)}
        />
      </DescriptionRow>
      <DescriptionRow>
        <DoubleInput label="Tamaño" field={character.description.size} />
        <StringInput
          label="Edad"
          value={character.description.age}
          setValue={(v) => character.description.setAge(v)}
        />
      </DescriptionRow>
    </Section>
  );
});
