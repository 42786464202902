import { KiTechnique } from '../../../../../../../../../../../domain/aggregations/ki-technique/KiTechnique';
import React from 'react';

export const getReadableKiCost = (kiCost: KiTechnique['kiCost']) => {
  return Object.entries(kiCost).map(([key, value]) => (
    <>
      <b>{key.substring(0, 3).toUpperCase()}</b>: {value.cost}
      {value.maintainedCost ? ` (${value.maintainedCost})` : ''}
      <br />
    </>
  ));
};
