import { Typography } from '@mui/material';

import styled from 'styled-components';
import { theme } from '../../../../../../../../../../../../../shared/application/design-system/theme/theme';
import { styleMui } from '../../../../../../../../../../../../../shared/application/design-system/utils/styleMui';

export const Wrapper = styled.div({
  width: '8.5rem',
  height: '8rem',
  border: `2px solid ${theme.color.black()}`,
  backgroundColor: theme.color.whiteSmoke(),
  position: 'relative',
  zIndex: 1,
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: '-1',
    top: '2px',
    left: '2px',
    right: '2px',
    bottom: '2px',
    border: `2px dashed ${theme.color.primary()}`,
  },
  borderRadius: '6px',

  overflow: 'hidden',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  paddingTop: '1.5rem',

  gap: '0.5rem',

  ...theme.mixins.responsive.md.downward({
    width: '7rem',
    height: '6rem',

    paddingTop: '1rem',
    gap: '0.25rem',
  }),
});

export const Name = styleMui(Typography)({
  color: theme.color.primary(),
  textTransform: 'uppercase',

  fontWeight: 'bold',

  ...theme.mixins.responsive.md.downward({
    fontSize: '0.75rem',
  }),
});

export const Modifier = styleMui(Typography)({
  color: theme.color.gray(),
  fontWeight: 'bold',
});
