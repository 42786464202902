import { WeaponItemModel } from '../WeaponItemModel';
import { CharacterModel } from '../../../character/model/CharacterModel';
import { getStrengthRequirement } from './getStrengthRequirement';

export const calculateStrengthRequiredPenalty = (
  weapon: WeaponItemModel,
  character: CharacterModel,
) => {
  const actorStrength = character.primaries.strength.final;

  const strengthDifference = getStrengthRequirement(weapon) - actorStrength;

  return strengthDifference > 0 ? -(strengthDifference * 10) : 0;
};
