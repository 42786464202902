import {
  WeaponType,
  weaponTypeTranslations,
} from '../../../../../../../../../../../../../../../../../../domain/aggregations/weapon/Weapon.types';
import { Selector } from '../../../../../../../../../../../../common/Selector/Selector';
import { MenuItem } from '../../../../../../../../../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';
import { transformToMenuItems } from '../../../../../../../../../../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { observer } from 'mobx-react';

export const WeaponTypeSelector = observer(
  ({
    selectedWeaponType,
    onSelectWeaponType,
  }: {
    selectedWeaponType: WeaponType;
    onSelectWeaponType: (s: WeaponType) => void;
  }) => {
    const allWeaponTypes = Object.values(WeaponType) as WeaponType[];

    const items: MenuItem<WeaponType>[] = transformToMenuItems({
      fromItems: allWeaponTypes,
      generateItem: (i) => ({
        key: i,
        value: i,
        name: weaponTypeTranslations[i],
        onClick: () => onSelectWeaponType(i),
      }),
    });

    return (
      <Selector
        value={selectedWeaponType}
        valueAsText={weaponTypeTranslations[selectedWeaponType]}
        items={items}
      />
    );
  },
);
