import { CharacterModel } from '../../../CharacterModel';

// When attack and defense ability are both developed, the difference between
// the biggest defensive ability and the attack ability must be less than 50.
export const calculateAttackDefenseUnbalanceIssue = (
  character: CharacterModel,
): boolean => {
  const { dodgeAbility, attackAbility, blockAbility } = character.combat;

  const isAttackDeveloped = attackAbility.isDeveloped;

  const isDefenseDeveloped =
    dodgeAbility.isDeveloped || blockAbility.isDeveloped;

  if (!isAttackDeveloped || !isDefenseDeveloped) return false;

  const attack = attackAbility.final;
  const dodge = dodgeAbility.final;
  const block = blockAbility.final;

  const biggestDefensiveAbility = Math.max(dodge, block);

  return Math.abs(attack - biggestDefensiveAbility) > 50;
};
