import { model } from 'mobx-keystone';
import { BaseFinalFieldModel } from '../../../character/model/utils/fields/BaseFinalFieldModel';
import { getParentArmor } from '../../../utils/parenting/getParentArmor';

@model('ArmorItem/ArmorNaturalPenaltyField')
export class ArmorNaturalPenaltyFieldModel extends BaseFinalFieldModel {
  get final(): number {
    if (!this.armor) return 0;

    return Math.min(this.base + this.special + this.armor.quality.final, 0);
  }

  get armor() {
    return getParentArmor(this);
  }
}
