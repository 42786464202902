/* eslint-disable @typescript-eslint/no-empty-function */
import type { TrackingService } from './TrackingService';

export const DumbTrackingService: TrackingService = {
  init() {
    console.log('[TrackingService] Init in dev mode');
  },
  setUser(params: { id: string; email: string }): void {
    console.log('[TrackingService] Set user info', params);
  },
  sendPageView(): void {
    console.log('[TrackingService] Send page view');
  },
  sendEvent(eventName, params): void {
    console.log(`[TrackingService] Send event "${eventName}"`, params);
  },
};
