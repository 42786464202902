import { observer } from 'mobx-react';
import React from 'react';
import { CombatPDModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/parts/combat/CombatPDModel';
import {
  MartialArtEffect,
  MartialArtEffectsContainer,
  MartialArtEffectsList,
  MartialArtTitle,
  Wrapper,
} from './MartialArtEffects.styled';

type MartialArtSelectorProps = {
  combat: CombatPDModel;
};

export const MartialArtEffects = observer(
  ({ combat }: MartialArtSelectorProps) => {
    const martialArtsWithEffects = combat.martialArts.filter(
      (martialArt) => martialArt.effects.length > 0,
    );

    return (
      <Wrapper>
        {martialArtsWithEffects.map((martialArt) => (
          <MartialArtEffectsContainer key={martialArt.type}>
            <MartialArtTitle>{martialArt.martialArtName}</MartialArtTitle>
            <MartialArtEffectsList>
              {martialArt.effects.map((effect) => (
                <MartialArtEffect key={effect}>{effect}</MartialArtEffect>
              ))}
            </MartialArtEffectsList>
          </MartialArtEffectsContainer>
        ))}
      </Wrapper>
    );
  },
);
