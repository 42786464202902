import { CategoryType } from '../../../../../../../domain/aggregations/category-templates/CategoryTemplate';
import { getCategoryTemplateFromType } from '../../../../../../../domain/aggregations/category-templates/utils/getCategoryTemplateFromType';
import React from 'react';
import { Selector } from './CategorySelectorFilter.styled';
import {
  MenuItem,
  useMenu,
} from '../../../../../../../../shared/application/design-system/components/Menu/useMenu';

export type OptionalCategory = CategoryType | undefined;

export const CategorySelectorFilter = ({
  onCategorySelected,
  selectedCategory,
}: {
  selectedCategory: OptionalCategory;
  onCategorySelected: (category: OptionalCategory) => void;
}) => {
  const { handleMenuOpen, Menu } = useMenu<OptionalCategory>();

  const categories = Object.values(CategoryType) as CategoryType[];

  const allCategoriesMenuItem: MenuItem<undefined> = {
    key: 'undefined',
    name: 'Todas las categorías',
    value: undefined,
    onClick: () => {
      onCategorySelected(undefined);
    },
  };

  const menuItemCategories: MenuItem<OptionalCategory>[] = [
    undefined,
    ...categories,
  ].map((category) => {
    if (category === undefined) {
      return allCategoriesMenuItem;
    }

    const item: MenuItem<CategoryType> = {
      key: category,
      name: getCategoryTemplateFromType(category).name,
      value: category as CategoryType,
      onClick: () => {
        onCategorySelected(category);
      },
    };
    return item;
  });

  const categoryName = selectedCategory
    ? getCategoryTemplateFromType(selectedCategory)?.name
    : allCategoriesMenuItem.name;

  return (
    <>
      <Selector onClick={handleMenuOpen(selectedCategory)}>
        {categoryName}
      </Selector>
      <Menu items={menuItemCategories} />
    </>
  );
};
