import { AcrobaticWarriorCategoryTemplate } from '../AcrobaticWarriorCategoryTemplate';
import { AssassinCategoryTemplate } from '../AssassinCategoryTemplate';
import { CategoryTemplate, CategoryType } from '../CategoryTemplate';
import { ConjurerCategoryTemplate } from '../ConjurerCategoryTemplate';
import { ConjurerWarriorCategoryTemplate } from '../ConjurerWarriorCategoryTemplate';
import { DarkPaladinCategoryTemplate } from '../DarkPaladinCategoryTemplate';
import { ExplorerCategoryTemplate } from '../ExplorerCategoryTemplate';
import { IllusionistCategoryTemplate } from '../IlussionistCategoryTemplate';
import { MentalistCategoryTemplate } from '../MentalistCategoryTemplate';
import { MentalistWarriorCategoryTemplate } from '../MentalistWarriorCategoryTemplate';
import { MentalistWizardCategoryTemplate } from '../MentalistWizardCategoryTemplate';
import { NovelCategoryTemplate } from '../NovelCategoryTemplate';
import { PaladinCategoryTemplate } from '../PaladinCategoryTemplate';
import { ShadowCategoryTemplate } from '../ShadowCategoryTemplate';
import { TaoCategoryTemplate } from '../TaoCategoryTemplate';
import { TechnicianCategoryTemplate } from '../TechnicianCategoryTemplate';
import { TheftCategoryTemplate } from '../TheftCategoryTemplate';
import { WarlockCategoryTemplate } from '../WarlockCategoryTemplate';
import { WarriorCategoryTemplate } from '../WarriorCategoryTemplate';
import { WeaponMasterCategoryTemplate } from '../WeaponMasterCategoryTemplate';
import { WizardCategoryTemplate } from '../WizardCategoryTemplate';

const mapCategoryTypeToTemplate: Record<CategoryType, () => CategoryTemplate> =
  {
    [CategoryType.ConjurerWarrior]: () =>
      new ConjurerWarriorCategoryTemplate(0),
    [CategoryType.DarkPaladin]: () => new DarkPaladinCategoryTemplate(0),
    [CategoryType.MentalistWarrior]: () =>
      new MentalistWarriorCategoryTemplate(0),
    [CategoryType.MentalistWizard]: () =>
      new MentalistWizardCategoryTemplate(0),
    [CategoryType.WeaponMaster]: () => new WeaponMasterCategoryTemplate(0),
    [CategoryType.Assassin]: () => new AssassinCategoryTemplate(0),
    [CategoryType.Conjurer]: () => new ConjurerCategoryTemplate(0),
    [CategoryType.Explorer]: () => new ExplorerCategoryTemplate(0),
    [CategoryType.Illusionist]: () => new IllusionistCategoryTemplate(0),
    [CategoryType.Mentalist]: () => new MentalistCategoryTemplate(0),
    [CategoryType.Novel]: () => new NovelCategoryTemplate(0),
    [CategoryType.Shadow]: () => new ShadowCategoryTemplate(0),
    [CategoryType.Tao]: () => new TaoCategoryTemplate(0),
    [CategoryType.Technician]: () => new TechnicianCategoryTemplate(0),
    [CategoryType.Theft]: () => new TheftCategoryTemplate(0),
    [CategoryType.Warlock]: () => new WarlockCategoryTemplate(0),
    [CategoryType.Wizard]: () => new WizardCategoryTemplate(0),
    [CategoryType.Warrior]: () => new WarriorCategoryTemplate(0),
    [CategoryType.AcrobaticWarrior]: () =>
      new AcrobaticWarriorCategoryTemplate(0),
    [CategoryType.Paladin]: () => new PaladinCategoryTemplate(0),
  };

export const AllCategories = Object.values(CategoryType).map((category) =>
  mapCategoryTypeToTemplate[category](),
);

export const getCategoryTemplateFromType = (
  category: CategoryType,
): CategoryTemplate => {
  return mapCategoryTypeToTemplate[category]();
};
