import { config, Environment } from '../../../bootstrap/infrastructure/config';
import { GoogleAnalyticsTrackingService } from './GoogleAnalyticsTrackingService';
import { DumbTrackingService } from './DumbTrackingService';

export type EventName =
  | 'login'
  | 'sign_in'
  | 'logout'
  | 'character_saved'
  | 'character_deleted'
  | 'character_created'
  | 'character_duplicated'
  | 'character_downloaded'
  | 'character_uploaded'
  | 'character_moved'
  | 'character_collaboration_added'
  | 'character_collaboration_removed'
  | 'character_privacy_changed'
  | 'character_imported_as_new'
  | 'character_imported_as_update'
  | 'folder_created'
  | 'folder_deleted'
  | 'folder_moved';

export interface TrackingService {
  init(): void;

  setUser(params: { id: string; email: string }): void;

  sendPageView(): void;

  sendEvent(eventName: EventName, params?: Record<string, string>): void;
}

export const TrackingService: TrackingService =
  config.environment === Environment.Development
    ? DumbTrackingService
    : GoogleAnalyticsTrackingService;
