import { CSSProperties, ReactNode, useEffect, useRef } from 'react';
import { Wrapper } from './CharacterDragging.styled';
import { useDraggable } from '@dnd-kit/core';
import { DraggableItemTypes } from '../../../../../../../../shared/application/shared/constants/DraggableItemTypes';

export type CharacterDraggingProps = {
  characterId: string;

  token: string;

  children: ({ isDragging }: { isDragging: boolean }) => ReactNode;

  disableDrag?: boolean;
};

export const CharacterDragging = ({
  characterId,

  token,

  children,

  disableDrag,
}: CharacterDraggingProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const { setNodeRef, isDragging, attributes, listeners, transform } =
    useDraggable({
      id: characterId,
      data: {
        characterId,
        type: DraggableItemTypes.Character,
      },
      disabled: disableDrag,
    });

  const style: CSSProperties = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : {};

  useEffect(() => {
    if (ref.current) {
      setNodeRef(ref.current);
    }
  }, [setNodeRef]);

  return (
    <>
      <Wrapper ref={ref} style={style} {...attributes} {...listeners}>
        {children({ isDragging })}
      </Wrapper>
    </>
  );
};
