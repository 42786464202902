import {
  Character,
  CharacterPrivacy,
} from '../../../../../../../../../../domain/character/Character';

export const makeCharacterPublic = (
  character: Character,
  saveCharacter: (character: Character) => Promise<void>,
) => {
  character.setPrivacy(CharacterPrivacy.Public);

  saveCharacter(character);
};
