import { observer } from 'mobx-react';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import DoubleInput from '../../../../common/DoubleInput/DoubleInput';
import { Section } from '../../../../common/Section/Section';

export const KiCalculatedSkills = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  return (
    <Section maxHeight="fit">
      <Section.Title>Detección y ocultación</Section.Title>
      {character.ki.kiDetection.isDeveloped ? (
        <DoubleInput
          label="Detección del Ki"
          field={character.ki.kiDetection}
        />
      ) : (
        <Section.Paragraph>
          La habilidad detección del Ki no está desarrollada
        </Section.Paragraph>
      )}
      {character.ki.kiHide.isDeveloped ? (
        <DoubleInput label="Ocultación del Ki" field={character.ki.kiHide} />
      ) : (
        <Section.Paragraph>
          La habilidad ocultación del Ki no está desarrollada
        </Section.Paragraph>
      )}
    </Section>
  );
});
