import { observer } from 'mobx-react';
import { CategoryModel } from '../../../../../../../../../../domain/character/model/parts/pd/parts/categories/CategoryModel';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';
import { CenteredContainer, PDSectionDivider } from '../../PDsTab.styled';
import { FieldHeader } from '../common/FieldRow/components/FieldHeader/FieldHeader';
import { PDFieldRow } from '../common/FieldRow/PDFieldRow';
import { FieldTotals } from '../common/FieldRow/components/FieldTotals/FieldTotals';
import { ArsMagnusSelector } from './ArsMagnusSelector/ArsMagnusSelector';
import { MartialArtSelector } from './MartialArtSelector/MartialArtSelector';
import { MartialArtWeaponTableSelector } from './MartialArtWeaponTableSelector/MartialArtWeaponTableSelector';
import { StyleTableSelector } from './StyleTableSelector/StyleTableSelector';
import { WeaponTableSelector } from './WeaponTableSelector/WeaponTableSelector';
import { CombatInfo } from './CombatInfo/CombatInfo';
import { MartialArtEffects } from './MartialArtEffects/MartialArtEffects';

type CombatPDsProps = {
  category: CategoryModel;
};

export const CombatPDs = observer(({ category }: CombatPDsProps) => {
  const {
    character: { content: character },
  } = useCharacterContext();

  return (
    <Section>
      <Section.Title>Combate</Section.Title>

      <Section.Subtitle>Habilidades básicas</Section.Subtitle>
      <Table>
        <FieldHeader />

        <PDFieldRow
          name="Ataque"
          pdField={category.combat.attackAbility}
          field={character.combat.attackAbility}
          maxPerCategory={50}
        />
        <PDFieldRow
          name="Bloqueo"
          pdField={category.combat.blockAbility}
          field={character.combat.blockAbility}
          maxPerCategory={50}
        />
        <PDFieldRow
          name="Esquiva"
          pdField={category.combat.dodgeAbility}
          field={character.combat.dodgeAbility}
          maxPerCategory={50}
        />
        <PDFieldRow
          name="Llevar armadura"
          pdField={category.combat.wearArmorAbility}
          field={character.combat.wearArmorAbility}
        />
      </Table>

      <CenteredContainer>
        <CombatInfo category={category} />
      </CenteredContainer>

      <PDSectionDivider />

      <Section.Subtitle>Reserva de Ki</Section.Subtitle>
      <Table>
        <FieldHeader />
        <PDFieldRow
          name="Agilidad"
          pdField={category.combat.ki.agilityKiPoints}
          field={character.ki.kiPoints.agility}
        />
        <PDFieldRow
          name="Constitución"
          pdField={category.combat.ki.constitutionKiPoints}
          field={character.ki.kiPoints.constitution}
        />
        <PDFieldRow
          name="Destreza"
          pdField={category.combat.ki.dexterityKiPoints}
          field={character.ki.kiPoints.dexterity}
        />
        <PDFieldRow
          name="Fuerza"
          pdField={category.combat.ki.strengthKiPoints}
          field={character.ki.kiPoints.strength}
        />
        <PDFieldRow
          name="Poder"
          pdField={category.combat.ki.powerKiPoints}
          field={character.ki.kiPoints.power}
        />
        <PDFieldRow
          name="Voluntad"
          pdField={category.combat.ki.willPowerKiPoints}
          field={character.ki.kiPoints.willPower}
        />
        <FieldTotals total={category.combat.ki.pdsSpentOnKiPoints} />
      </Table>
      <Section.Subtitle>Acumulación de Ki</Section.Subtitle>
      <Table>
        <FieldHeader />
        <PDFieldRow
          name="Agilidad"
          pdField={category.combat.ki.agilityKiAccumPoints}
          field={character.ki.kiAccumulationPoints.agility}
        />
        <PDFieldRow
          name="Constitución"
          pdField={category.combat.ki.constitutionKiAccumPoints}
          field={character.ki.kiAccumulationPoints.constitution}
        />
        <PDFieldRow
          name="Destreza"
          pdField={category.combat.ki.dexterityKiAccumPoints}
          field={character.ki.kiAccumulationPoints.dexterity}
        />
        <PDFieldRow
          name="Fuerza"
          pdField={category.combat.ki.strengthKiAccumPoints}
          field={character.ki.kiAccumulationPoints.strength}
        />
        <PDFieldRow
          name="Poder"
          pdField={category.combat.ki.willPowerKiAccumPoints}
          field={character.ki.kiAccumulationPoints.willPower}
        />
        <PDFieldRow
          name="Voluntad"
          pdField={category.combat.ki.powerKiAccumPoints}
          field={character.ki.kiAccumulationPoints.power}
        />
        <FieldTotals total={category.combat.ki.pdsSpentOnKiAccumPoints} />
        <PDSectionDivider />
        <PDFieldRow
          name="Conocimiento Marcial"
          pdField={category.combat.martialKnowledge}
          field={character.ki.martialKnowledge}
        />
      </Table>
      <Section.Subtitle>Tabla de armas</Section.Subtitle>
      <WeaponTableSelector combat={category.combat} />
      <Section.Divider />
      <Section.Subtitle>Tabla de estilos</Section.Subtitle>
      <StyleTableSelector combat={category.combat} />
      <Section.Divider />
      <Section.Subtitle>Artes marciales</Section.Subtitle>
      <MartialArtSelector combat={category.combat} />
      <MartialArtEffects combat={category.combat} />
      <Section.Divider />
      <Section.Subtitle>Tabla de armas de artes marciales</Section.Subtitle>
      <MartialArtWeaponTableSelector tables={category.combat} />
      <Section.Divider />
      <Section.Subtitle>Ars Magnus</Section.Subtitle>
      <ArsMagnusSelector combat={category.combat} />
      <PDSectionDivider />

      <CenteredContainer>
        <CombatInfo category={category} />
      </CenteredContainer>

      <PDSectionDivider />

      <PDSectionDivider />
    </Section>
  );
});
