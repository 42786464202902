import { getParent, model } from 'mobx-keystone';
import { BaseFinalFieldModel } from '../../../../utils/fields/BaseFinalFieldModel';
import { PrimaryFieldModel } from './PrimaryFieldModel';

@model('PrimaryCharacteristicModifierField')
export class PrimaryCharacteristicModifierFieldModel extends BaseFinalFieldModel {
  get final(): number {
    return (
      this.base +
      this.special +
      PrimaryCharacteristicModifierFieldModel.calculateModifier(
        this.getPrimaryValue().final,
      )
    );
  }

  public static calculateModifier(value: number): number {
    if (value <= 1) return -30;
    if (value <= 2) return -20;
    if (value <= 3) return -10;
    if (value <= 4) return -5;
    if (value <= 5) return 0;
    if (value <= 7) return 5;
    if (value <= 9) return 10;
    if (value <= 10) return 15;
    if (value <= 12) return 20;
    if (value <= 14) return 25;
    if (value <= 15) return 30;
    if (value <= 17) return 35;
    if (value <= 19) return 40;

    return 45;
  }

  get finalClampedTo0(): number {
    return Math.max(0, this.final);
  }

  private getPrimaryValue(): PrimaryFieldModel {
    const parent = getParent<PrimaryFieldModel>(this);

    if (!parent) {
      throw new Error('Primary field associated to modifier not found');
    }

    return parent;
  }
}
