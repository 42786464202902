import { v4 as uuid } from 'uuid';
import { getPsychicDisciplineFromType } from '../../../../../../character/domain/aggregations/psychic-disciplines/PsychicDiscipline';
import { ActorMutator } from '../../../ActorCharacterTransformer';
import { FoundryVTTActor } from '../FoundryVTTActor';
import { ABFItems } from '../FoundryVTTActor.types';
import { createBaseFinalField } from './utils/createBaseFinalField';

export const mutateActorPsychic: ActorMutator<FoundryVTTActor> = ({
  actor,
  character,
}) => {
  actor.data.psychic = {
    ...actor.data.psychic,
    innatePsychicPower: {
      amount: {
        value: character.content.psychic.innatePowers.length,
      },
    },
    innatePsychicPowers: character.content.psychic.innatePowers.map(
      (power) => ({
        _id: uuid(),
        name: power.power.name,
        type: ABFItems.INNATE_PSYCHIC_POWER,
        data: {
          value: {
            value: power.psychicPointsUsedToIncrease,
          },
          effect: {
            value: power.power.description ?? '',
          },
        },
      }),
    ),
    mentalPatterns: character.content.psychic.mentalPatterns.map((m) => ({
      _id: uuid(),
      name: m.table.name,
      type: ABFItems.MENTAL_PATTERN,
      data: {
        bonus: {
          value: 0,
        },
        penalty: {
          value: 0,
        },
      },
    })),
    psychicDisciplines: character.content.psychic.disciplines
      .map(getPsychicDisciplineFromType)
      .map((d) => ({
        _id: uuid(),
        name: d.name,
        type: ABFItems.PSYCHIC_DISCIPLINE,
        data: {},
      })),
    psychicPoints: {
      value: actor.data.psychic.psychicPoints.value,
      max: character.content.psychic.psychicPoints.finalWithoutPenalties,
    },
    psychicPotential: createBaseFinalField(
      actor.data.psychic.psychicPotential.base.value,
      character.content.psychic.psychicPotential.final,
    ),
    psychicProjection: {
      base: {
        value:
          character.content.psychic.psychicProjection.finalWithoutPenalties,
      },
      final: {
        value:
          character.content.psychic.psychicProjection.finalWithoutPenalties,
      },
      imbalance: {
        defensive: createBaseFinalField(
          character.content.psychic.psychicProjection.finalWithoutPenalties,
          character.content.psychic.psychicProjection.finalWithoutPenalties,
        ),
        offensive: createBaseFinalField(
          character.content.psychic.psychicProjection.finalWithoutPenalties,
          character.content.psychic.psychicProjection.finalWithoutPenalties,
        ),
      },
    },
  };

  return { actor };
};
