import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import { ArmorItemModel } from '../../../../../../../../../../../../domain/aggregations/armors/ArmorItemModel';
import { Checkbox } from '../../../../../../common/Checkbox/Checkbox';
import DoubleInput from '../../../../../../common/DoubleInput/DoubleInput';
import { HideIcon, ShowIcon } from '../../../../../../common/Icons/Icons';
import { RemoveItemButton } from '../../../../../../common/RemoveItemButton/RemoveItemButton';
import { Table } from '../../../../../../common/Table/Table';
import { ArmorLocationSelector } from './components/ArmorLocationSelector/ArmorLocationSelector';
import { ArmorTypeSelector } from './components/ArmorTypeSelector/ArmorTypeSelector';
import { useModal } from '../../../../../../../../../../../../../shared/application/design-system/components/Modal/hooks/useModal';
import { EditArmor } from './components/EditArmor/EditArmor';
import { EditItemButton } from '../../../../../../common/EditItemButton/EditItemButton';

interface ArmorProps {
  armor: ArmorItemModel;

  onNameClick: (
    armor: ArmorItemModel,
  ) => (e: MouseEvent<HTMLDivElement>) => void;

  onRemove: (armor: ArmorItemModel) => void;
}

export const ArmorRow = observer(
  ({ armor, onNameClick, onRemove }: ArmorProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const { Modal, openModal, closeModal } = useModal();

    const handleEditArmor = (armor: ArmorItemModel) => () => {
      openModal(<EditArmor armor={armor} onSaveArmor={() => closeModal()} />, {
        fullScreenInMobile: true,
      });
    };

    const handleEquippedClick = () => {
      armor.setEquipped(!armor.equipped);
    };

    const handleNameClick = (e: MouseEvent<HTMLDivElement>) => {
      onNameClick(armor)(e);
    };

    const handleArmorRemove = () => {
      onRemove(armor);
    };

    const handleShowClick = () => {
      setIsExpanded(true);
    };

    const handleHideClick = () => {
      setIsExpanded(false);
    };

    return (
      <>
        <Table.Row>
          <Table.Row.Cell $hideOnMobile>
            {isExpanded ? (
              <HideIcon onClick={handleHideClick} />
            ) : (
              <ShowIcon onClick={handleShowClick} />
            )}
          </Table.Row.Cell>
          <Table.Row.Cell>
            <Checkbox
              isChecked={armor.equipped}
              onClick={handleEquippedClick}
            />
          </Table.Row.Cell>
          <Table.Row.Cell onClick={handleNameClick}>
            {armor.name}
          </Table.Row.Cell>
          <Table.Row.Cell $hideOnMobile>
            <DoubleInput size={DoubleInput.Size.Small} field={armor.at.cut} />
          </Table.Row.Cell>
          <Table.Row.Cell $hideOnMobile>
            <DoubleInput
              size={DoubleInput.Size.Small}
              field={armor.at.impact}
            />
          </Table.Row.Cell>
          <Table.Row.Cell $hideOnMobile>
            <DoubleInput
              size={DoubleInput.Size.Small}
              field={armor.at.thrust}
            />
          </Table.Row.Cell>
          <Table.Row.Cell $hideOnMobile>
            <DoubleInput size={DoubleInput.Size.Small} field={armor.at.heat} />
          </Table.Row.Cell>
          <Table.Row.Cell $hideOnMobile>
            <DoubleInput size={DoubleInput.Size.Small} field={armor.at.cold} />
          </Table.Row.Cell>
          <Table.Row.Cell $hideOnMobile>
            <DoubleInput
              size={DoubleInput.Size.Small}
              field={armor.at.electricity}
            />
          </Table.Row.Cell>
          <Table.Row.Cell $hideOnMobile>
            <DoubleInput
              size={DoubleInput.Size.Small}
              field={armor.at.energy}
            />
          </Table.Row.Cell>
          <Table.Row.Cell>
            <EditItemButton onClick={handleEditArmor(armor)} />
          </Table.Row.Cell>
          <Table.Row.Cell>
            <RemoveItemButton onClick={handleArmorRemove} />
          </Table.Row.Cell>
        </Table.Row>

        {isExpanded ? (
          <>
            <Table.Header>
              <Table.Header.Cell $width={1} />
              <Table.Header.Cell>Localización</Table.Header.Cell>
              <Table.Header.Cell>Tipo</Table.Header.Cell>
              <Table.Header.Cell>Req.</Table.Header.Cell>
              <Table.Header.Cell>Pen. Natural</Table.Header.Cell>
              <Table.Header.Cell>Pen. movimiento</Table.Header.Cell>
              <Table.Header.Cell>Calidad</Table.Header.Cell>
              <Table.Header.Cell>Entereza</Table.Header.Cell>
              <Table.Header.Cell>Presencia</Table.Header.Cell>
            </Table.Header>
            <Table.Row>
              <Table.Row.Cell />
              <Table.Row.Cell>
                <ArmorLocationSelector armor={armor} />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <ArmorTypeSelector armor={armor} />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <DoubleInput
                  orientation={DoubleInput.Orientation.Vertical}
                  field={armor.wearArmorRequirement}
                />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <DoubleInput field={armor.naturalPenalty} />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <DoubleInput
                  orientation={DoubleInput.Orientation.Vertical}
                  field={armor.movementRestriction}
                />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <DoubleInput step={5} field={armor.quality} />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <DoubleInput field={armor.integrity} />
              </Table.Row.Cell>
              <Table.Row.Cell>
                <DoubleInput field={armor.presence} />
              </Table.Row.Cell>
            </Table.Row>
          </>
        ) : undefined}
        <Modal />
      </>
    );
  },
);
