import { useSearchCharacters } from './hooks/useSearchCharacters';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { CharacterList } from '../../../../components/CharacterList/CharacterList';
import { NotificationManager } from '../../../../../../shared/application/shared/NotificationManager';
import {
  BasicTitle,
  SmallParagraph,
} from '../../../../../../shared/application/design-system/components/Texts/Texts.styled';
import {
  CategorySelectorFilterContainer,
  FiltersContainer,
  LevelSelectorFilterContainer,
  SearchSection,
  StyledInput,
  Wrapper,
} from './CharactersRoom.styled';
import { Loading } from '../../../../../../shared/application/design-system/components/Loading/Loading';
import {
  CategorySelectorFilter,
  OptionalCategory,
} from './components/CategorySelectorFilter/CategorySelectorFilter';
import {
  LevelSelectorFilter,
  OptionalLevel,
} from './components/LevelSelectorFilter/LevelSelectorFilter';

let lastSearch = '';

export const CharactersRoom = () => {
  const [needle, setNeedle] = useState(lastSearch);
  const [selectedCategory, setSelectedCategory] = useState<OptionalCategory>();
  const [selectedLevel, setSelectedLevel] = useState<OptionalLevel>();

  const { characters, searchingCharacters, searchCharacters } =
    useSearchCharacters();

  const handleEnterKey = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      search();
    }
  };

  const search = async () => {
    lastSearch = needle;

    try {
      if (searchingCharacters) return;

      await searchCharacters({
        needle,
        category: selectedCategory,
        level: selectedLevel,
      });
    } catch (e) {
      console.log(e);

      NotificationManager.error('Error al buscar personajes');
    }
  };

  useEffect(() => {
    search();
  }, [selectedCategory, selectedLevel]);

  const handleNeedleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNeedle(event.target.value);
  };

  return (
    <Wrapper>
      <SearchSection>
        <BasicTitle>Zona de búsqueda de personajes</BasicTitle>
        <SmallParagraph>
          Aquí se congregan todos los personajes cuyos creadores han decidido
          compartirlos con la comunidad.
        </SmallParagraph>
        <FiltersContainer>
          <StyledInput
            value={needle}
            placeholder="Busca por nombre, p.e: Gandalf el gris"
            onChange={handleNeedleChange}
            onKeyDown={handleEnterKey}
          />
          <CategorySelectorFilterContainer>
            <CategorySelectorFilter
              selectedCategory={selectedCategory}
              onCategorySelected={setSelectedCategory}
            />
          </CategorySelectorFilterContainer>
          <LevelSelectorFilterContainer>
            <LevelSelectorFilter
              selectedLevel={selectedLevel}
              onLevelSelected={setSelectedLevel}
            />
          </LevelSelectorFilterContainer>
        </FiltersContainer>
      </SearchSection>
      <hr />
      {searchingCharacters ? (
        <Loading size={3} />
      ) : (
        <CharacterList characters={characters} />
      )}
    </Wrapper>
  );
};
