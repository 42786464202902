import {
  BasicMartialArtGradeIds,
  buildAdvancedMartialArtArcaneGrade,
  buildAdvancedMartialArtBasicGrade,
} from '../../MartialArtGrades';
import {
  AdvancedMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { calculateHighestDamageFromKnownMartialArts } from './utils/calculateHighestDamageFromKnownMartialArts';
import { HitType } from '../../../../character/model/types/HitType';
import { calculateMartialArtsRequirements } from './utils/calculateMartialArtsRequirements';
import { KiSkillType } from '../../../ki-skill/KiSkill';

const BasicMartialArtsRequirement = [
  {
    type: MartialArtType.TaiChi,
    grade: BasicMartialArtGradeIds.Advanced,
  },
];

const ArcaneMartialArtsRequirement = [
  {
    type: MartialArtType.TaiChi,
    grade: BasicMartialArtGradeIds.Supreme,
  },
];

export class SuyantaMartialArt extends AdvancedMartialArt {
  readonly name = 'Suyanta';

  readonly complexity = 4;

  readonly type = MartialArtType.Suyanta;

  readonly level = MartialArtLevelType.Advanced;

  readonly grades = [
    buildAdvancedMartialArtBasicGrade({
      cmBonus: 15,
      effects:
        'Permite atacar a la reserva de ki en vez de a la vida. Sin unificación de ki, el atacante elige la característica. En caso de seres de acumulación, se divide entre su múltiplo.',
      combatBonus: {
        attack: 10,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          BasicMartialArtsRequirement,
        );

        if (!character.ki.hasKiSkill(KiSkillType.PresenceExtrusion)) {
          errors.push('Habilidad de Ki: Extrusión de presencia');
        }

        if (character.combat.attackAbility.final < 200) {
          errors.push('Ataque 200+');
        }

        return errors;
      },
    }),
    buildAdvancedMartialArtArcaneGrade({
      cmBonus: 35,
      effects:
        'Ya no necesita elegir: el ataque se realiza a la vida y a la reserva de ki al mismo tiempo',
      masterBonus: {
        attack: 15,
      },
      calculateDamage: (character) => {
        return (
          calculateHighestDamageFromKnownMartialArts(
            character,
            BasicMartialArtsRequirement,
          ) + character.primaries.power.modifier.final
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          ArcaneMartialArtsRequirement,
        );

        if (character.combat.attackAbility.final < 300) {
          errors.push('Ataque 300+');
        }

        if (!character.hasInhumanity) {
          errors.push('Inhumanidad');
        }

        return errors;
      },
    }),
  ];
}
