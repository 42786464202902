import { model } from 'mobx-keystone';
import {
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
} from '../../../../aggregations/advantage/Advantage.types';
import { QuickReflexesAdvantage } from '../../../../aggregations/advantage/CommonAdvantages';
import {
  DisadvantageLevel1,
  DisadvantageLevel2,
  SlowReactionDisadvantage,
} from '../../../../aggregations/disadvantage/CommonDisadvantages';
import { WeaponSize } from '../../../../aggregations/weapon/Weapon.types';
import type { WeaponItemModel } from '../../../../aggregations/weapon/WeaponItemModel';
import { Calculation, NumberField } from '../../utils/fields/NumberField';
import { KiSkillType } from '../../../../aggregations/ki-skill/KiSkill';
import { MartialArtModel } from '../combat/parts/MartialArtModel';
import {
  NephilimTurak,
  Turak,
} from '../../../../aggregations/nephilim-type/Nephilim';

@model('InitiativeField')
export class InitiativeModel extends NumberField {
  get initiativePerCategories(): number {
    if (!this.character) return 0;

    let initiative = 0;

    this.character.categories.forEach((category) => {
      initiative += category.template.calculateTurn();
    });

    return initiative;
  }

  get withEquipment(): number {
    if (!this.character) return 0;

    const equippedWeapons = this.character.inventory.equippedWeapons;

    let initiative = equippedWeapons.length === 0 ? this.unarmed : this.final;

    const firstTwoWeapons = equippedWeapons.slice(0, 2);

    const equippedShield = this.character.inventory.equippedShields[0];

    if (equippedShield) {
      if (equippedShield.size === WeaponSize.Small) {
        initiative -= 15;
      } else if (equippedShield.size === WeaponSize.Medium) {
        initiative -= 25;
      } else {
        initiative -= 40;
      }
    }

    if (firstTwoWeapons.length === 1) {
      initiative += firstTwoWeapons[0].initiative.final;
    } else if (firstTwoWeapons.length === 2) {
      const leftWeapon = firstTwoWeapons[0];
      const rightWeapon = firstTwoWeapons[1];

      initiative += Math.min(
        leftWeapon.initiative.final,
        rightWeapon.initiative.final,
      );

      if (leftWeapon.size === rightWeapon.size) {
        if (
          Math.min(leftWeapon.initiative.base, rightWeapon.initiative.base) < 0
        ) {
          initiative -= 20;
        } else {
          initiative -= 10;
        }
      }
    }

    return initiative;
  }

  get unarmed(): number {
    if (!this.character) return 0;

    const martialArtsInitiatives = this.character.combat.martialArts.map((ma) =>
      this.calculateWithMartialArt(ma),
    );

    let initiative: number;
    if (martialArtsInitiatives.length === 0) {
      initiative = this.final;

      initiative += 20;
    } else {
      initiative = Math.max(...martialArtsInitiatives);

      if (this.character.isNephilim(Turak)) {
        initiative -= 20;
      }

      if (this.character.isNephilim(NephilimTurak)) {
        initiative -= 10;
      }
    }

    return initiative;
  }

  get finalWithoutPenalties(): number {
    return this.final - this.allActionsPenalty + this.naturalPenalty;
  }

  get naturalPenalty(): number {
    if (!this.character) return 0;

    return this.character.modifiers.naturalPenalty.finalWithBonus;
  }

  get allActionsPenalty(): number {
    if (!this.character) return 0;

    return Math.ceil(this.character.modifiers.allActions.final / 2);
  }

  calculateWithWeapon = (weapon: WeaponItemModel): number => {
    if (!this.character) return 0;

    let initiative = this.final;

    initiative += weapon.initiative.final;

    return initiative;
  };

  calculateWithMartialArt = (martialArt: MartialArtModel): number => {
    if (!this.character) return 0;

    let initiative = this.final;

    initiative += martialArt.combatBonus.initiative;

    return initiative;
  };

  getCalculations(): Calculation[] {
    if (!this.character) return [];

    const calculations: Calculation[] = [];

    calculations.push(this.sum('Base', 20));

    calculations.push(this.sum('Especial', this.special));

    calculations.push(
      this.sum(
        'Bono de agilidad',
        this.character.primaries.agility.modifier.final,
      ),
    );

    calculations.push(
      this.sum(
        'Bono de destreza',
        this.character.primaries.dexterity.modifier.final,
      ),
    );

    if (this.character.isNephilim(Turak)) {
      calculations.push(this.sub(`Penalización por raza ${Turak.name}`, 20));
    }

    if (this.character.isNephilim(NephilimTurak)) {
      calculations.push(
        this.sub(`Penalización por raza ${NephilimTurak.name}`, 10),
      );
    }

    calculations.push(
      this.sum(
        'Bonos por artes marciales avanzadas',
        this.character.combat.martialArtsHighestMasterBonus.initiative,
        { hideOnZero: true },
      ),
    );

    calculations.push(
      this.sum('Penalización por acciones', this.allActionsPenalty),
    );

    calculations.push(this.sub('Penalización natural', this.naturalPenalty));

    calculations.push(this.sum('Categorías', this.initiativePerCategories));

    if (
      this.character.creationPoints.containsAdvantage(QuickReflexesAdvantage, {
        level: AdvantageLevel1,
      })
    ) {
      calculations.push(this.sum('Reflejos rápidos (Nivel 1)', 25));
    }

    if (
      this.character.creationPoints.containsAdvantage(QuickReflexesAdvantage, {
        level: AdvantageLevel2,
      })
    ) {
      calculations.push(this.sum('Reflejos rápidos (Nivel 2)', 45));
    }

    if (
      this.character.creationPoints.containsAdvantage(QuickReflexesAdvantage, {
        level: AdvantageLevel3,
      })
    ) {
      calculations.push(this.sum('Reflejos rápidos (Nivel 3)', 60));
    }

    if (
      this.character.creationPoints.containsDisadvantage(
        SlowReactionDisadvantage,
        {
          level: DisadvantageLevel1,
        },
      )
    ) {
      calculations.push(this.sub('Reacción lenta (Nivel 1)', 30));
    }

    if (
      this.character.creationPoints.containsDisadvantage(
        SlowReactionDisadvantage,
        {
          level: DisadvantageLevel2,
        },
      )
    ) {
      calculations.push(this.sub('Reacción lenta (Nivel 2)', 60));
    }

    if (this.character.ki.hasKiSkill(KiSkillType.SpeedIncremented)) {
      calculations.push(this.sum('Ki: Incremento de velocidad', 10));
    }

    return calculations;
  }
}
