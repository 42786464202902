import {
  MainPsychicDiscipline,
  PsychicDisciplineType,
} from '../psychic-disciplines/PsychicDiscipline';
import {
  Advantage,
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
  AdvantageType,
} from './Advantage.types';

export const PsychicAmbivalentAdvantage: Advantage = {
  id: 'advantage-psychic-1',

  name: 'Ambivalente psíquico',

  type: AdvantageType.Psychics,

  levels: [AdvantageLevel1],
};

export const GaugeAdvantage: Advantage = {
  id: 'advantage-psychic-2',

  name: 'Calibre',

  type: AdvantageType.Psychics,

  levels: [AdvantageLevel1],
};

export const ExtremeFocusAdvantage: Advantage = {
  id: 'advantage-psychic-3',

  name: 'Concentración extrema',

  type: AdvantageType.Psychics,

  levels: [AdvantageLevel2],
};

export const PassiveFocusAdvantage: Advantage = {
  id: 'advantage-psychic-4',

  name: 'Concentración pasiva',

  type: AdvantageType.Psychics,

  levels: [AdvantageLevel2],
};

export const PsychicUnbalanceAdvantage: Advantage<MainPsychicDiscipline> = {
  id: 'advantage-psychic-5',

  name: 'Desequilibrio psíquico',

  type: AdvantageType.Psychics,

  levels: [AdvantageLevel2],

  variations: [
    {
      id: PsychicDisciplineType.Causality,
      name: 'Causalidad',
    },
    {
      id: PsychicDisciplineType.Cryokinesis,
      name: 'Crioquinésis',
    },
    {
      id: PsychicDisciplineType.Energy,
      name: 'Energía',
    },
    {
      id: PsychicDisciplineType.Electromagnetism,
      name: 'Electromagnetismo',
    },
    {
      id: PsychicDisciplineType.Hypersensitivity,
      name: 'Hipersensibilidad',
    },
    {
      id: PsychicDisciplineType.PhysicalIncrease,
      name: 'Incremento físico',
    },
    {
      id: PsychicDisciplineType.Light,
      name: 'Luz',
    },
    {
      id: PsychicDisciplineType.Pyrokinesis,
      name: 'Piroquinésis',
    },
    {
      id: PsychicDisciplineType.Sentient,
      name: 'Sientiente',
    },
    {
      id: PsychicDisciplineType.Telemetry,
      name: 'Telemedición',
    },
    {
      id: PsychicDisciplineType.Telepathy,
      name: 'Telepatía',
    },
    {
      id: PsychicDisciplineType.Telekinesis,
      name: 'Telequinesis',
    },
    {
      id: PsychicDisciplineType.Teleportation,
      name: 'Teletransporte',
    },
  ],
};

export const AddedMaintenanceAdvantage: Advantage = {
  id: 'advantage-psychic-6',

  name: 'Mantenimiento añadido',

  type: AdvantageType.Psychics,

  levels: [AdvantageLevel2],
};

export const IncrementedPsychicModifierAdvantage: Advantage = {
  id: 'advantage-psychic-7',

  name: 'Modificador psíquico incrementado',

  type: AdvantageType.Psychics,

  levels: [AdvantageLevel1],
};

export const PPRecoveryAdvantage: Advantage = {
  id: 'advantage-psychic-8',

  name: 'Recuperación de CVs',

  type: AdvantageType.Psychics,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const PsychicFatigueResistanceAdvantage: Advantage = {
  id: 'advantage-psychic-9',

  name: 'Resistencia a la fatiga psíquica',

  type: AdvantageType.Psychics,

  levels: [AdvantageLevel2],
};

export const AllPsychicAdvantages: Advantage[] = [
  PsychicAmbivalentAdvantage,
  GaugeAdvantage,
  ExtremeFocusAdvantage,
  PassiveFocusAdvantage,
  PsychicUnbalanceAdvantage,
  AddedMaintenanceAdvantage,
  IncrementedPsychicModifierAdvantage,
  PPRecoveryAdvantage,
  PsychicFatigueResistanceAdvantage,
];
