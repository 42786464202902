import { Advantage, AdvantageId, AdvantageType } from './Advantage.types';
import { AllBackgroundAdvantages } from './BackgroundAdvantages';
import { AllCommonAdvantages } from './CommonAdvantages';
import { AllDonAdvantages } from './DonAdvantages';
import { AllPsychicAdvantages } from './PsychicAdvantages';
import { AllBloodLegacyAdvantages } from './BloodLegacyAdvantages';

export const getUnusedAdvantageOfType = (
  advantagesIds: AdvantageId[],
  type: AdvantageType,
): Advantage | undefined => {
  let advantages: Advantage[];

  switch (type) {
    case AdvantageType.Common:
      advantages = AllCommonAdvantages;
      break;
    case AdvantageType.Don:
      advantages = AllDonAdvantages;
      break;
    case AdvantageType.Psychics:
      advantages = AllPsychicAdvantages;
      break;
    case AdvantageType.Background:
      advantages = AllBackgroundAdvantages;
      break;
    case AdvantageType.BloodLegacy:
      advantages = AllBloodLegacyAdvantages;
      break;
  }

  return advantages.find((a) => !advantagesIds.some((b) => b === a.id));
};
