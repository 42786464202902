import { CharacterModelV0 } from './v0/CharacterModelV0';
import { CharacterModelV1 } from './v1/CharacterModelV1';
import { CharacterModel } from '../CharacterModel';
import { migrateFromCharacterModelV0ToV1 } from './v1/migrateFromCharacterModelV0ToV1';
import { CharacterModelV2 } from './v2/CharacterModelV2';
import { migrateFromCharacterModelV1ToV2 } from './v2/migrateFromCharacterModelV1ToV2';

export type NoMigratedCharacter =
  | CharacterModelV0
  | CharacterModelV1
  | CharacterModelV2;

export const migrateCharacterModel = (
  characterModelRaw: NoMigratedCharacter,
): CharacterModel => {
  let characterModel = characterModelRaw;

  if (!('version' in characterModel)) {
    characterModel = migrateFromCharacterModelV0ToV1(characterModel);
  }

  if (characterModel.version === 1) {
    characterModel = migrateFromCharacterModelV1ToV2(characterModel);
  }

  return characterModel as unknown as CharacterModel;
};
