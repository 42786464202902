import { observer } from 'mobx-react';
import { ReactElement, useState } from 'react';
import { CategoryModel } from '../../../../../../../../domain/character/model/parts/pd/parts/categories/CategoryModel';
import { Tabs } from '../../../../../../../../../shared/application/design-system/components/Tabs/Tabs';
import { useCharacterContext } from '../../../hooks/useCharacterContext';
import { CharacterCategoriesList } from './components/CharacterCategoriesList/CharacterCategoriesList';
import { CombatPDs } from './components/CombatPDs/CombatPDs';
import { LifePDs } from './components/LifePDs/LifePDs';
import { MagicPDs } from './components/MagicPDs/MagicPDs';
import { PsychicPDs } from './components/PsychicPDs/PsychicPDs';
import { SecondarySkillsPDs } from './components/SecondarySkillsPDs/SecondarySkillsPDs';
import { StyledTabs, Wrapper } from './PDsTab.styled';

enum PDsSubTab {
  Combat = 'Combate',
  Magic = 'Magia',
  Psychic = 'Psíquica',
  SecondarySkills = 'Habilidades secundarias',
}

export const PDsTab = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const [selectedCategory, setSelectedCategory] = useState(
    character.categories[0],
  );

  const handleChangeTab = (category: CategoryModel) => () => {
    setSelectedCategory(category);
  };

  const [subTab, setSubTab] = useState(PDsSubTab.Combat);

  const handleChangeSubTab = (subTab: PDsSubTab) => () => {
    setSubTab(subTab);
  };

  const SubTabComponentMap: Record<PDsSubTab, ReactElement> = {
    [PDsSubTab.Combat]: <CombatPDs category={selectedCategory} />,
    [PDsSubTab.Magic]: <MagicPDs category={selectedCategory} />,
    [PDsSubTab.Psychic]: <PsychicPDs category={selectedCategory} />,
    [PDsSubTab.SecondarySkills]: (
      <SecondarySkillsPDs category={selectedCategory} />
    ),
  };

  return (
    <Wrapper>
      <CharacterCategoriesList />
      <StyledTabs centered>
        {character.categories.map((category, index) => (
          <Tabs.Button
            key={`${category.template.name}-${index}`}
            name={category.template.name}
            onClick={handleChangeTab(category)}
            selected={category === selectedCategory}
          />
        ))}
      </StyledTabs>
      <LifePDs category={selectedCategory} />
      <Tabs centered>
        {Object.values(PDsSubTab).map((t) => (
          <Tabs.Button
            key={t}
            selected={subTab === t}
            name={t}
            onClick={handleChangeSubTab(t)}
          />
        ))}
      </Tabs>
      {SubTabComponentMap[subTab]}
    </Wrapper>
  );
});
