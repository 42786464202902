import { model } from 'mobx-keystone';
import { PDFieldModel } from '../PDFieldModel';

@model('Character/PD/Mystic/ControlPDField')
export class ControlPDFieldModel extends PDFieldModel {
  get pdsCost(): number {
    return this.template.controlPDsCost;
  }

  get perCategory(): number {
    return this.template.calculateControlBonus();
  }
}
