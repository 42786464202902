import { observer } from 'mobx-react';
import {
  Divider,
  StyledDownloadIcon,
  StyledUpgradeIcon,
  Wrapper,
} from './CharacterToolbar.styled';
import { useCharacterContext } from '../../hooks/useCharacterContext';
import { ToolbarPrivacyIcon } from './components/ToolbarPrivacyIcon/ToolbarPrivacyIcon';
import { Tooltip } from '@mui/material';
import { importToCharacter } from './components/ToolbarPrivacyIcon/utils/importToCharacter';
import { downloadCharacter } from './components/ToolbarPrivacyIcon/utils/downloadCharacter';
import { CharacterIssuesButton } from '../../../../../../components/CharacterIssuesButton/CharacterIssuesButton';
import { TrackingService } from '../../../../../../../../shared/infrastructure/tracking/TrackingService';

export const CharacterToolbar = observer(() => {
  const { character } = useCharacterContext();

  const characterIssues = character.content.issues;

  return (
    <Wrapper>
      <Divider />

      <Tooltip title="Descargar personaje">
        <StyledDownloadIcon
          onClick={() => {
            downloadCharacter(character);
            TrackingService.sendEvent('character_downloaded', {
              characterId: character.id,
            });
          }}
        />
      </Tooltip>

      <Tooltip title="Cargar personaje desde tu dispositivo">
        <StyledUpgradeIcon
          onClick={async () => {
            await importToCharacter(character);
            TrackingService.sendEvent('character_uploaded', {
              characterId: character.id,
            });
          }}
        />
      </Tooltip>

      <Divider />

      {characterIssues.length > 0 ? (
        <>
          <CharacterIssuesButton character={character} />

          <Divider />
        </>
      ) : undefined}

      <ToolbarPrivacyIcon character={character} />
    </Wrapper>
  );
});
