import { Model, model, prop } from 'mobx-keystone';
import { PsychicPower } from '../../../../../aggregations/psychic-power/PsychicPower';
import {
  getDifficultyAsString,
  getDifficultyOf,
  getNextDifficultyOf,
} from '../../../types/Difficulty';
import { getParentCharacter } from '../../../../../utils/parenting/getParentCharacter';
import { AddedMaintenanceAdvantage } from '../../../../../aggregations/advantage/PsychicAdvantages';

@model('Character/Psychic/InnatePsychicPower')
export class InnatePsychicPowerModel extends Model({
  power: prop<PsychicPower>().withSetter(),

  psychicPointsUsedToIncrease: prop(0).withSetter(),
}) {
  get bonusFromPsychicPointsUsedToIncrease(): number {
    return this.psychicPointsUsedToIncrease * 20;
  }

  get maintainedDifficulty(): string {
    if (!this.character) return '';

    const psychicPotential = this.character.psychic.psychicPotential.final;

    let fromCharacter =
      psychicPotential + this.bonusFromPsychicPointsUsedToIncrease;

    const nonInnatePower = this.character.psychic.powers.find(
      (p) => p.id === this.power.id,
    );

    if (nonInnatePower) {
      fromCharacter += nonInnatePower.bonusFromPsychicPointsUsedToIncrease;
    }

    if (fromCharacter >= this.power.minimalDifficultyToSuccess) {
      if (
        this.character.creationPoints.containsAdvantage(
          AddedMaintenanceAdvantage,
        )
      ) {
        return getDifficultyAsString(
          getNextDifficultyOf(getDifficultyOf(fromCharacter)),
        );
      }

      return getDifficultyAsString(fromCharacter);
    }

    return getDifficultyAsString(
      Math.max(this.power.minimalDifficultyToSuccess, fromCharacter),
    );
  }

  get character() {
    return getParentCharacter(this);
  }
}
