import { observer } from 'mobx-react';
import { KiCalculatedSkills } from './components/KiCalculatedSkills/KiCalculatedSkills';
import { KiPoints } from './components/KiPoints/KiPoints';
import { KiSkillsTree } from './components/KiSkillsTree/KiSkillsTree';
import { KiTechniques } from './components/KiTechniques/KiTechniques';
import { NemesisSkillsTree } from './components/NemesisSkillsTree/NemesisSkillsTree';
import { TotalMartialKnowledgeUsed } from './components/TotalMartialKnowledgeUsed/TotalKnowledgeUsed';
import { Wrapper } from './Ki.styled';

export const KiTab = observer(() => {
  return (
    <Wrapper>
      <KiPoints />
      <TotalMartialKnowledgeUsed />
      <KiCalculatedSkills />
      <KiTechniques />
      <KiSkillsTree />
      <NemesisSkillsTree />
    </Wrapper>
  );
});
