import { model } from 'mobx-keystone';
import { NaturalPowerAdvantage } from '../../../../../../../aggregations/advantage/CommonAdvantages';
import { DevelopedPDFieldModel } from '../../../../../utils/fields/DevelopedPDFieldModel';
import { getTotalOf } from '../../../../../utils/getTotalOf';

@model('Character/Ki/KiPoints/Agility')
export class AgilityKiPointsFieldModel extends DevelopedPDFieldModel {
  get fromCategories(): number {
    return getTotalOf(this.character, (c) => c.combat.ki.agilityKiPoints.final);
  }

  get allActionsPenalty(): number {
    return 0;
  }

  get primariesBonus(): number {
    if (!this.character) return 0;

    if (
      this.character?.creationPoints.containsAdvantage(NaturalPowerAdvantage)
    ) {
      return this.character.primaries.power.final;
    }

    let primary = this.character.primaries.agility.final;

    if (primary > 10) {
      primary += primary - 10;
    }

    return primary;
  }
}
