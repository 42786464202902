import { observer } from 'mobx-react';
import { CategoryType } from '../../../../../../../../../../domain/aggregations/category-templates/CategoryTemplate';
import { CategoryModel } from '../../../../../../../../../../domain/character/model/parts/pd/parts/categories/CategoryModel';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';
import { CenteredContainer, PDSectionDivider } from '../../PDsTab.styled';
import { SecondarySkillPDFieldRow } from './SecondarySkillPDFieldRow';
import { SecondarySkillHeader } from './SecondarySkillHeader';
import { SecondarySkillBonusesInfo } from './SecondarySkillBonusesInfo';

type SecondarySkillsPDsProps = {
  category: CategoryModel;
};

export const SecondarySkillsPDs = observer(
  ({ category }: SecondarySkillsPDsProps) => {
    const {
      character: { content: character },
    } = useCharacterContext();

    const isNovel = category.template.type === CategoryType.Novel;

    return (
      <Section>
        <Section.Title>Habilidades secundarias</Section.Title>
        <Section.Subtitle>Atléticas</Section.Subtitle>
        <Table>
          <SecondarySkillHeader isNovel={isNovel} />
          <SecondarySkillPDFieldRow
            name="Acrobacias"
            pdField={category.secondaries.athletic.acrobatics}
            field={character.secondaries.athletic.acrobatics}
          />
          <SecondarySkillPDFieldRow
            name="Atletismo"
            pdField={category.secondaries.athletic.athleticism}
            field={character.secondaries.athletic.athleticism}
          />
          <SecondarySkillPDFieldRow
            name="Montar"
            pdField={category.secondaries.athletic.ride}
            field={character.secondaries.athletic.ride}
          />
          <SecondarySkillPDFieldRow
            name="Nadar"
            pdField={category.secondaries.athletic.swim}
            field={character.secondaries.athletic.swim}
          />
          <SecondarySkillPDFieldRow
            name="Trepar"
            pdField={category.secondaries.athletic.climb}
            field={character.secondaries.athletic.climb}
          />
          <SecondarySkillPDFieldRow
            name="Saltar"
            pdField={category.secondaries.athletic.jump}
            field={character.secondaries.athletic.jump}
          />
          <SecondarySkillPDFieldRow
            name="Pilotar"
            pdField={category.secondaries.athletic.piloting}
            field={character.secondaries.athletic.piloting}
          />
        </Table>

        <CenteredContainer>
          <SecondarySkillBonusesInfo isNovel={isNovel} />
        </CenteredContainer>

        <PDSectionDivider />
        <Section.Subtitle>Sociales</Section.Subtitle>
        <Table>
          <SecondarySkillHeader isNovel={isNovel} />
          <SecondarySkillPDFieldRow
            name="Estilo"
            pdField={category.secondaries.social.style}
            field={character.secondaries.social.style}
          />
          <SecondarySkillPDFieldRow
            name="Intimidar"
            pdField={category.secondaries.social.intimidate}
            field={character.secondaries.social.intimidate}
          />
          <SecondarySkillPDFieldRow
            name="Liderazgo"
            pdField={category.secondaries.social.leadership}
            field={character.secondaries.social.leadership}
          />
          <SecondarySkillPDFieldRow
            name="Persuasión"
            pdField={category.secondaries.social.persuasion}
            field={character.secondaries.social.persuasion}
          />
          <SecondarySkillPDFieldRow
            name="Comercio"
            pdField={category.secondaries.social.trading}
            field={character.secondaries.social.trading}
          />
          <SecondarySkillPDFieldRow
            name="Callejeo"
            pdField={category.secondaries.social.streetwise}
            field={character.secondaries.social.streetwise}
          />
          <SecondarySkillPDFieldRow
            name="Etiqueta"
            pdField={category.secondaries.social.etiquette}
            field={character.secondaries.social.etiquette}
          />
        </Table>

        <CenteredContainer>
          <SecondarySkillBonusesInfo isNovel={isNovel} />
        </CenteredContainer>

        <PDSectionDivider />
        <Section.Subtitle>Percepción</Section.Subtitle>
        <Table>
          <SecondarySkillHeader isNovel={isNovel} />
          <SecondarySkillPDFieldRow
            name="Advertir"
            pdField={category.secondaries.perceptive.notice}
            field={character.secondaries.perceptive.notice}
          />
          <SecondarySkillPDFieldRow
            name="Buscar"
            pdField={category.secondaries.perceptive.search}
            field={character.secondaries.perceptive.search}
          />
          <SecondarySkillPDFieldRow
            name="Rastrear"
            pdField={category.secondaries.perceptive.track}
            field={character.secondaries.perceptive.track}
          />
        </Table>

        <CenteredContainer>
          <SecondarySkillBonusesInfo isNovel={isNovel} />
        </CenteredContainer>

        <PDSectionDivider />
        <Section.Subtitle>Intelectuales</Section.Subtitle>
        <Table>
          <SecondarySkillHeader isNovel={isNovel} />
          <SecondarySkillPDFieldRow
            name="Animales"
            pdField={category.secondaries.intellectual.animals}
            field={character.secondaries.intellectual.animals}
          />
          <SecondarySkillPDFieldRow
            name="Ciencia"
            pdField={category.secondaries.intellectual.science}
            field={character.secondaries.intellectual.science}
          />
          <SecondarySkillPDFieldRow
            name="Ley"
            pdField={category.secondaries.intellectual.law}
            field={character.secondaries.intellectual.law}
          />
          <SecondarySkillPDFieldRow
            name="Herbolaria"
            pdField={category.secondaries.intellectual.herbalism}
            field={character.secondaries.intellectual.herbalism}
          />
          <SecondarySkillPDFieldRow
            name="Historia"
            pdField={category.secondaries.intellectual.history}
            field={character.secondaries.intellectual.history}
          />
          <SecondarySkillPDFieldRow
            name="Táctica"
            pdField={category.secondaries.intellectual.tactics}
            field={character.secondaries.intellectual.tactics}
          />
          <SecondarySkillPDFieldRow
            name="Medicina"
            pdField={category.secondaries.intellectual.medicine}
            field={character.secondaries.intellectual.medicine}
          />
          <SecondarySkillPDFieldRow
            name="Memorizar"
            pdField={category.secondaries.intellectual.memorize}
            field={character.secondaries.intellectual.memorize}
          />
          <SecondarySkillPDFieldRow
            name="Navegación"
            pdField={category.secondaries.intellectual.navigation}
            field={character.secondaries.intellectual.navigation}
          />
          <SecondarySkillPDFieldRow
            name="Ocultismo"
            pdField={category.secondaries.intellectual.occultism}
            field={character.secondaries.intellectual.occultism}
          />
          <SecondarySkillPDFieldRow
            name="Tasación"
            pdField={category.secondaries.intellectual.appraisal}
            field={character.secondaries.intellectual.appraisal}
          />
          <SecondarySkillPDFieldRow
            name="Valoración Mágica"
            pdField={category.secondaries.intellectual.magicAppraisal}
            field={character.secondaries.intellectual.magicAppraisal}
          />
        </Table>

        <CenteredContainer>
          <SecondarySkillBonusesInfo isNovel={isNovel} />
        </CenteredContainer>

        <PDSectionDivider />
        <Section.Subtitle>Vigor</Section.Subtitle>
        <Table>
          <SecondarySkillHeader isNovel={isNovel} />
          <SecondarySkillPDFieldRow
            name="Frialdad"
            pdField={category.secondaries.vigor.composure}
            field={character.secondaries.vigor.composure}
          />
          <SecondarySkillPDFieldRow
            name="Proeza de fuerza"
            pdField={category.secondaries.vigor.featsOfStrength}
            field={character.secondaries.vigor.featsOfStrength}
          />
          <SecondarySkillPDFieldRow
            name="Resis. al dolor"
            pdField={category.secondaries.vigor.withstandPain}
            field={character.secondaries.vigor.withstandPain}
          />
        </Table>

        <CenteredContainer>
          <SecondarySkillBonusesInfo isNovel={isNovel} />
        </CenteredContainer>

        <PDSectionDivider />
        <Section.Subtitle>Subterfugio</Section.Subtitle>
        <Table>
          <SecondarySkillHeader isNovel={isNovel} />
          <SecondarySkillPDFieldRow
            name="Cerrajería"
            pdField={category.secondaries.subterfuge.lockPicking}
            field={character.secondaries.subterfuge.lockPicking}
          />
          <SecondarySkillPDFieldRow
            name="Disfraz"
            pdField={category.secondaries.subterfuge.disguise}
            field={character.secondaries.subterfuge.disguise}
          />
          <SecondarySkillPDFieldRow
            name="Ocultarse"
            pdField={category.secondaries.subterfuge.hide}
            field={character.secondaries.subterfuge.hide}
          />
          <SecondarySkillPDFieldRow
            name="Robo"
            pdField={category.secondaries.subterfuge.steal}
            field={character.secondaries.subterfuge.steal}
          />
          <SecondarySkillPDFieldRow
            name="Sigilo"
            pdField={category.secondaries.subterfuge.stealth}
            field={character.secondaries.subterfuge.stealth}
          />
          <SecondarySkillPDFieldRow
            name="Trampería"
            pdField={category.secondaries.subterfuge.traps}
            field={character.secondaries.subterfuge.traps}
          />
          <SecondarySkillPDFieldRow
            name="Venenos"
            pdField={category.secondaries.subterfuge.poisons}
            field={character.secondaries.subterfuge.poisons}
          />
        </Table>

        <CenteredContainer>
          <SecondarySkillBonusesInfo isNovel={isNovel} />
        </CenteredContainer>

        <PDSectionDivider />
        <Section.Subtitle>Creativas</Section.Subtitle>
        <Table>
          <SecondarySkillHeader isNovel={isNovel} />
          <SecondarySkillPDFieldRow
            name="Arte"
            pdField={category.secondaries.creative.art}
            field={character.secondaries.creative.art}
          />
          <SecondarySkillPDFieldRow
            name="Baile"
            pdField={category.secondaries.creative.dance}
            field={character.secondaries.creative.dance}
          />
          <SecondarySkillPDFieldRow
            name="Forja"
            pdField={category.secondaries.creative.forging}
            field={character.secondaries.creative.forging}
          />
          <SecondarySkillPDFieldRow
            name="Runas"
            pdField={category.secondaries.creative.runes}
            field={character.secondaries.creative.runes}
          />
          <SecondarySkillPDFieldRow
            name="Alquimia"
            pdField={category.secondaries.creative.alchemy}
            field={character.secondaries.creative.alchemy}
          />
          <SecondarySkillPDFieldRow
            name="Animismo"
            pdField={category.secondaries.creative.animism}
            field={character.secondaries.creative.animism}
          />
          <SecondarySkillPDFieldRow
            name="Música"
            pdField={category.secondaries.creative.music}
            field={character.secondaries.creative.music}
          />
          <SecondarySkillPDFieldRow
            name="Trucos de manos"
            pdField={category.secondaries.creative.sleightOfHand}
            field={character.secondaries.creative.sleightOfHand}
          />
          <SecondarySkillPDFieldRow
            name="Caligrafía ritual"
            pdField={category.secondaries.creative.ritualCalligraphy}
            field={character.secondaries.creative.ritualCalligraphy}
          />
          <SecondarySkillPDFieldRow
            name="Orfebrería"
            pdField={category.secondaries.creative.jewelry}
            field={character.secondaries.creative.jewelry}
          />
          <SecondarySkillPDFieldRow
            name="Confección"
            pdField={category.secondaries.creative.tailoring}
            field={character.secondaries.creative.tailoring}
          />
          <SecondarySkillPDFieldRow
            name="Conf. marionetas"
            pdField={category.secondaries.creative.puppetMaking}
            field={character.secondaries.creative.puppetMaking}
          />
        </Table>

        <CenteredContainer>
          <SecondarySkillBonusesInfo isNovel={isNovel} />
        </CenteredContainer>
      </Section>
    );
  },
);
