/* eslint-disable @typescript-eslint/no-unused-vars */
import { CharacterModel } from '../../../character/model/CharacterModel';

export enum WeaponTableType {
  SimilarWeapon = 'SimilarWeapon',
  MixedWeapon = 'MixedWeapon',
  DistinctOrDisarmedWeapon = 'DistinctOrDisarmedWeapon',
  ArchetypeWeapon = 'ArchetypicWeapon',
  TypologicalWeapon = 'TypologicalWeapon',
  ImprovisedWeapons = 'ImprovisedWeapons',
}

export type AnyWeaponTableType =
  typeof WeaponTableType[keyof typeof WeaponTableType];

export type WeaponTableItem = {
  id: string;
  name: string;
};

export abstract class WeaponTable<
  R extends WeaponTableItem | undefined = undefined,
> {
  abstract name: string;

  abstract type: WeaponTableType;

  abstract cost: number;

  abstract getItems(): R[];

  getFilteredItems(_character: CharacterModel): R[] {
    return this.getItems();
  }
}
