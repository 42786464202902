import { model } from 'mobx-keystone';
import { WeaponSizeProportion } from '../Weapon.types';
import { WeaponFieldModel } from './WeaponFieldModel';

@model('WeaponItem/WeaponInitiativeField')
export class WeaponInitiativeFieldModel extends WeaponFieldModel {
  get final(): number {
    if (!this.weapon) return 0;

    let initiative = this.special + this.base + this.weapon.quality.final;

    // This depends on the size of the character but right now is not automatized
    if (this.weapon.sizeProportion !== WeaponSizeProportion.Normal) {
      initiative -= 40;
    }

    return initiative;
  }
}
