import { ExtendedModel, model, prop } from 'mobx-keystone';
import { KiType } from '../../../../../../../types/KiType';
import { PDFieldModel } from '../../PDFieldModel';

@model('KiAccumPointsField')
export class KiAccumPointsFieldModel extends ExtendedModel(PDFieldModel, {
  type: prop<KiType>(KiType.Agility),
}) {
  get pdsCost(): number {
    return this.template.kiAccumMultiplierPDsCost;
  }

  get perCategory(): number | undefined {
    return undefined;
  }
}
