import styled from 'styled-components';
import { BasicTitle } from '../../../../../../../../../../shared/application/design-system/components/Texts/Texts.styled';

export const StyledBasicTitle = styled(BasicTitle)({});
export const StyleNewsList = styled.ul({});
export const StyleNewsChange = styled.li({});
export const Wrapper = styled.div(({ theme: { size } }) => ({
  [StyledBasicTitle]: { marginTop: size.units(2) },
  [StyleNewsList]: { paddingLeft: size.units(2) },
  [StyleNewsChange]: { margin: size.units(1) },
}));
