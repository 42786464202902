import { useEffect } from 'react';
import { useUserAuthorization } from '../../../../../shared/application/hooks/useUserAuthorization';
import {
  Routes,
  useNavigate,
} from '../../../../../shared/application/hooks/useNavigate';
import { Loading } from '../../../../../shared/application/design-system/components/Loading/Loading';
import { LoadingContainer } from '../../../../../shared/application/design-system/components/PageLayout/PageLayout.styled';

import {
  Container,
  Header,
  WhiteParagraph,
  WhiteTitle,
  Wrapper,
} from './AfterEmbeddedAuthorization.styled';
import { Avatar } from '@mui/material';
import Logo from '../../../../../shared/application/assets/logo.png';

export const AfterEmbeddedAuthorization = () => {
  const { navigate } = useNavigate();
  const { session, loading } = useUserAuthorization({
    redirect: false,
  });

  useEffect(() => {
    if (!loading && !session) {
      navigate(Routes.Auth);
    }
  }, [loading, navigate, session]);

  if (loading) {
    return (
      <LoadingContainer>
        <Loading centered />
      </LoadingContainer>
    );
  }

  return (
    <Wrapper>
      <Container>
        <Header>
          <Avatar src={Logo} sx={{ width: 56, height: 56 }} />
          <WhiteTitle>Characters Vault</WhiteTitle>
        </Header>
        <WhiteParagraph>
          ¡Listo! Ya puedes cerrar esta pestaña y volver a la aventura.
        </WhiteParagraph>
      </Container>
    </Wrapper>
  );
};
