import {
  BasicMartialArtGradeIds,
  buildAdvancedMartialArtArcaneGrade,
  buildAdvancedMartialArtBasicGrade,
} from '../../MartialArtGrades';
import {
  AdvancedMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { calculateHighestDamageFromKnownMartialArts } from './utils/calculateHighestDamageFromKnownMartialArts';
import { HitType } from '../../../../character/model/types/HitType';
import { calculateMartialArtsRequirements } from './utils/calculateMartialArtsRequirements';
import { KiSkillType } from '../../../ki-skill/KiSkill';

const BasicMartialArtsRequirement = [
  {
    type: MartialArtType.Sambo,
    grade: BasicMartialArtGradeIds.Advanced,
  },
  {
    type: MartialArtType.Shotokan,
    grade: BasicMartialArtGradeIds.Advanced,
  },
];

const ArcaneMartialArtsRequirement = [
  {
    type: MartialArtType.Sambo,
    grade: BasicMartialArtGradeIds.Supreme,
  },
  {
    type: MartialArtType.Shotokan,
    grade: BasicMartialArtGradeIds.Supreme,
  },
];

export class EnuthMartialArt extends AdvancedMartialArt {
  readonly name = 'Enuth';

  readonly complexity = 3;

  readonly type = MartialArtType.Enuth;

  readonly level = MartialArtLevelType.Advanced;

  readonly grades = [
    buildAdvancedMartialArtBasicGrade({
      cmBonus: 10,
      effects:
        '+20 a la maniobra de inconsciencia. Permite reducir el daño producido libremente incluso posterior al haber lanzado los dados.',
      combatBonus: {
        block: 20,
        dodge: 20,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          BasicMartialArtsRequirement,
        );

        if (character.combat.defensiveAbility < 160) {
          errors.push('Bloqueo o esquiva 160+');
        }

        return errors;
      },
    }),
    buildAdvancedMartialArtArcaneGrade({
      cmBonus: 20,
      effects:
        'Todos los golpes se pueden usar para dejar inconsciente, sin importar el daño ni el lugar de impacto. En seres de acumulación, todos los puntos se consideran vulnerables. +50 al crítico para inconsciencia. Si consigue realizar daño, permite conocer cuanta vida tiene el adversario.',
      masterBonus: {
        attack: 15,
        block: 15,
        dodge: 15,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          ArcaneMartialArtsRequirement,
        );

        if (!character.hasInhumanity) {
          errors.push('Inhumanidad');
        }

        if (!character.ki.hasKiSkill(KiSkillType.Erudition)) {
          errors.push('Habilidad de Ki: Erudición');
        }

        if (character.combat.attackAbility.final < 280) {
          errors.push('Ataque 280+');
        }

        if (character.combat.defensiveAbility < 280) {
          errors.push('Bloqueo o esquiva 280+');
        }

        return errors;
      },
    }),
  ];
}
