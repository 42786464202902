import { SecondarySkill } from '../../character/model/types/SecondarySkill';
import { Archetype, CategoryTemplate, CategoryType } from './CategoryTemplate';

export class ConjurerWarriorCategoryTemplate extends CategoryTemplate {
  name = 'Guerrero conjurador';
  archetypes = [Archetype.Fighter, Archetype.Mystic];
  type = CategoryType.ConjurerWarrior;

  readonly lifeMultiplier = 20;
  lifePoints = 10;
  protected turn = 5;
  protected martialKnowledge = 20;
  protected nativePsychicPoints = 1;

  offensiveSkillsLimitPercentage = 0.5;
  attackPDsCost = 2;
  blockPDsCost = 2;
  dodgePDsCost = 2;
  wearArmorPDsCost = 2;
  kiPDsCost = 2;
  kiAccumMultiplierPDsCost = 20;

  magicSkillsLimitPercentage = 0.5;
  zeonPDsCost = 1;
  aCTMultiplierPDsCost = 60;
  magicProjectionPDsCost = 3;
  summonPDsCost = 1;
  controlPDsCost = 1;
  bindPDsCost = 1;
  banishPDsCost = 1;

  psychicSkillsLimitPercentage = 0.5;
  psychicPointsPDsCost = 20;
  psychicProjectionPDsCost = 3;

  athleticSecondariesPDsCost = 2;
  socialSecondariesPDsCost = 2;
  perceptionSecondariesPDsCost = 2;
  intellectualSecondariesPDsCost = 2;
  vigorSecondariesPDsCost = 2;
  subterfugeSecondariesPDsCost = 2;
  creativeSecondariesPDsCost = 2;

  attackBonus = 5;
  blockBonus = 5;
  dodgeBonus = 5;
  zeonBonus = 20;
  summonBonus = 5;
  controlBonus = 5;
  bindBonus = 5;
  banishBonus = 5;

  calculateSecondarySkillBonusPerCategory(skill: SecondarySkill): number {
    if (skill === SecondarySkill.Occultism) return 5;

    return 0;
  }
}
