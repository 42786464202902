export enum DoubleInputVariant {
  Normal = 'Normal',
  Outline = 'Outline',
}

export enum DoubleInputSize {
  Big = 'Big',
  Normal = 'Normal',
  Small = 'Small',
}

export enum LabelPosition {
  Top = 'Top',
  Left = 'Left',
}

export enum InputsOrientation {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

export enum DoubleInputDigits {
  TENS,
  HUNDREDS,
  THOUSANDS,
}
