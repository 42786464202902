import styled from 'styled-components';
import { ArrowBack, Folder } from '@styled-icons/material';
import { BasicParagraph } from '../../../../shared/application/design-system/components/Texts/Texts.styled';

export const FolderNavigationWrapper = styled.div({
  width: 'fit-content',
});

export const FolderNameContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',

  gap: size.units(0.5),
}));

export const FolderName = styled(BasicParagraph)(
  ({ theme: { transition } }) => ({
    transition: transition.standard,
  }),
);

export const FolderIcon = styled(Folder)(
  ({ theme: { color, size, transition } }) => ({
    color: color.silver(),

    width: size.units(3),
    height: size.units(3),

    transition: transition.standard,
  }),
);

export const StyledBackIcon = styled(ArrowBack)(
  ({ theme: { size, color } }) => ({
    width: size.units(3),
    height: size.units(3),

    color: color.black(),

    cursor: 'pointer',
  }),
);

export const FolderNavigationContainer = styled.div<{ $showBorder?: boolean }>(
  ({ theme: { size, color, borderRadius }, $showBorder }) => ({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',

    padding: size.units(1),

    border: $showBorder ? `2px dashed ${color.primaryLight()}` : 'none',
    borderRadius: borderRadius.small,

    boxSizing: 'border-box',

    gap: size.units(1),

    '&:hover': {
      [FolderIcon]: {
        color: color.black(),
      },

      [FolderName]: {
        fontWeight: 'bold',
      },
    },
  }),
);
