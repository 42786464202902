import { computed } from 'mobx';
import { model, Model, modelAction, prop } from 'mobx-keystone';
import {
  ArmorItemModel,
  createArmorItemModelFromPredefinedArmor,
} from '../../../../aggregations/armors/ArmorItemModel';
import { WeaponType } from '../../../../aggregations/weapon/Weapon.types';
import {
  createWeaponItemModelFromPredefinedWeapon,
  WeaponItemModel,
} from '../../../../aggregations/weapon/WeaponItemModel';
import { PredefinedWeapon } from '../../../../aggregations/weapon/PredefinedWeapons';
import { PredefinedArmor } from '../../../../aggregations/armors/PredefinedArmors';

@model('Character/Inventory')
export class InventoryModel extends Model({
  weapons: prop<WeaponItemModel[]>(() => []),
  armors: prop<ArmorItemModel[]>(() => []),
}) {
  @computed
  get equippedWeapons(): WeaponItemModel[] {
    return this.weapons.filter(
      (w) => w.equipped && !w.types.includes(WeaponType.Shield),
    );
  }

  @computed
  get equippedArmors(): ArmorItemModel[] {
    return this.armors.filter((a) => a.equipped);
  }

  @computed
  get equippedShields(): WeaponItemModel[] {
    return this.weapons.filter(
      (w) => w.equipped && w.types.includes(WeaponType.Shield),
    );
  }

  @modelAction
  addWeapon(weapon: PredefinedWeapon | WeaponItemModel): void {
    if (weapon instanceof WeaponItemModel) {
      this.weapons.push(weapon);
    } else {
      this.weapons.push(createWeaponItemModelFromPredefinedWeapon(weapon));
    }
  }

  @modelAction
  updateWeapon(weapon: WeaponItemModel, update: PredefinedWeapon): void {
    const index = this.weapons.findIndex((w) => w === weapon);

    if (index !== -1) {
      this.weapons[index] = createWeaponItemModelFromPredefinedWeapon(update);
    }
  }

  @modelAction
  removeWeapon(weapon: WeaponItemModel): void {
    this.weapons = this.weapons.filter((w) => w !== weapon);
  }

  @modelAction
  addArmor(armor: PredefinedArmor | ArmorItemModel): void {
    if (armor instanceof ArmorItemModel) {
      this.armors.push(armor);
    } else {
      this.armors.push(createArmorItemModelFromPredefinedArmor(armor));
    }
  }

  @modelAction
  updateArmor(armor: ArmorItemModel, update: PredefinedArmor): void {
    const index = this.armors.findIndex((w) => w === armor);

    if (index !== -1) {
      this.armors[index] = createArmorItemModelFromPredefinedArmor(update);
    }
  }

  @modelAction
  removeArmor(armor: ArmorItemModel): void {
    this.armors = this.armors.filter((w) => w !== armor);
  }
}
