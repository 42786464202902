import { Typography } from '@mui/material';
import styled from 'styled-components';
import { theme } from '../../../../../../../../../../../shared/application/design-system/theme/theme';
import { styleMui } from '../../../../../../../../../../../shared/application/design-system/utils/styleMui';

export const MovementInfoContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',

  justifyContent: 'center',
  alignItems: 'center',
});

export const Info = styleMui(Typography)({
  fontSize: '0.65rem',
  color: theme.color.black(),
  maxWidth: '12rem',
});

export const ATsContainer = styled.div(({ theme: { size } }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',

  gridGap: size.units(1),
}));
