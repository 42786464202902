import { WeaponItemModel } from '../../../../../../../../../../../../../../domain/aggregations/weapon/WeaponItemModel';
import { observer } from 'mobx-react';
import { weaponHitTypeTranslations } from '../../../../../../../../../../../../../../domain/aggregations/weapon/Weapon.types';
import { transformToMenuItems } from '../../../../../../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { Selector } from '../../../../../../../../common/Selector/Selector';
import { HitType } from '../../../../../../../../../../../../../../domain/character/model/types/HitType';

export const PrimaryHitTypeSelector = observer(
  ({ weapon }: { weapon: WeaponItemModel }) => {
    const items = transformToMenuItems({
      fromItems: Object.values(HitType),
      generateItem: (hit) => ({
        key: hit,
        name: weaponHitTypeTranslations[hit],
        value: hit,
        onClick: () => {
          weapon.critic.setPrimary(hit);
        },
      }),
    });

    return (
      <Selector
        value={weapon.critic.primary}
        valueAsText={weaponHitTypeTranslations[weapon.critic.primary]}
        items={items}
      />
    );
  },
);
