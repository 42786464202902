import { Model, model, prop } from 'mobx-keystone';
import { MagicSpellCast } from '../../../../../../aggregations/magic-spell/MagicSpell';

@model('Character/Mystic/MagicSpell/Cast')
export class MagicSpellCastModel extends Model({
  intelligenceRequirement: prop<number>().withSetter(),
  zeonCost: prop<number>().withSetter(),
  maintainedZeonCost: prop<number | undefined>().withSetter(),
  effect: prop<string>().withSetter(),
}) {
  toObject(): MagicSpellCast {
    return {
      intelligenceRequirement: this.intelligenceRequirement,
      zeonCost: this.zeonCost,
      maintainedZeonCost: this.maintainedZeonCost,
      effect: this.effect,
    };
  }
}

export const createModelFromMagicSpellCast = (
  cast: MagicSpellCast,
): MagicSpellCastModel =>
  new MagicSpellCastModel({
    intelligenceRequirement: cast.intelligenceRequirement,
    zeonCost: cast.zeonCost,
    maintainedZeonCost: cast.maintainedZeonCost,
    effect: cast.effect,
  });
