import { ExtendedModel, model, prop } from 'mobx-keystone';
import { SecondarySkill } from '../../../../../../../types/SecondarySkill';
import { SecondarySkillPDFieldModel } from '../SecondarySkillPDFieldModel';
import { SecondarySkillBonusType } from '../SecondarySkillPDFieldModel.types';
import { SecondarySkillsPDModel } from './SecondarySkillsPDModel';

@model('Character/Development/SecondarySkills/PerceptiveSkillsPD')
export class PerceptiveSkillsPDModel extends ExtendedModel(
  SecondarySkillsPDModel,
  {
    notice: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Notice,
          type: SecondarySkillBonusType.Perceptive,
        }),
    ),
    search: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Search,
          type: SecondarySkillBonusType.Perceptive,
        }),
    ),
    track: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Track,
          type: SecondarySkillBonusType.Perceptive,
        }),
    ),
  },
) {
  get fields(): SecondarySkillPDFieldModel[] {
    return [this.notice, this.search, this.track];
  }
}
