import {
  BasicMartialArtGradeIds,
  buildAdvancedMartialArtArcaneGrade,
  buildAdvancedMartialArtBasicGrade,
} from '../../MartialArtGrades';
import {
  AdvancedMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { calculateHighestDamageFromKnownMartialArts } from './utils/calculateHighestDamageFromKnownMartialArts';
import { HitType } from '../../../../character/model/types/HitType';
import { calculateMartialArtsRequirements } from './utils/calculateMartialArtsRequirements';

const BasicMartialArtsRequirement = [
  {
    type: MartialArtType.KungFu,
    grade: BasicMartialArtGradeIds.Advanced,
  },
  {
    type: MartialArtType.XingQuan,
    grade: BasicMartialArtGradeIds.Advanced,
  },
];

const ArcaneMartialArtsRequirement = [
  {
    type: MartialArtType.KungFu,
    grade: BasicMartialArtGradeIds.Supreme,
  },
  {
    type: MartialArtType.XingQuan,
    grade: BasicMartialArtGradeIds.Supreme,
  },
];

export class MushinMartialArt extends AdvancedMartialArt {
  readonly name = 'Mushin';

  readonly complexity = 4;

  readonly type = MartialArtType.Mushin;

  readonly level = MartialArtLevelType.Advanced;

  readonly grades = [
    buildAdvancedMartialArtBasicGrade({
      cmBonus: 15,
      effects:
        'Permite atacar a los puntos de presión, si lo hace, control de RF contra el daño más 20 (máx. 220). El oponente obtiene un penalizador a la acción equivalente al nivel de fracaso y provoca su doble en daño. Se debe declarar antes de lanzar los dados.',
      combatBonus: {
        attack: 10,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          BasicMartialArtsRequirement,
        );

        if (character.secondaries.intellectual.medicine.final < 100) {
          errors.push('Medicina 100+ o Anatomía 60+');
        }

        if (character.combat.attackAbility.final < 200) {
          errors.push('Ataque 200+');
        }

        return errors;
      },
    }),
    buildAdvancedMartialArtArcaneGrade({
      cmBonus: 30,
      effects:
        'Aumenta en 20 la RF de los ataques a los puntos de presión. Ahora su máximo es 240. Efectos especiales Mushin (ver libro).',
      masterBonus: {
        attack: 20,
      },
      calculateDamage: (character) => {
        return calculateHighestDamageFromKnownMartialArts(
          character,
          BasicMartialArtsRequirement,
        );
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = calculateMartialArtsRequirements(
          character,
          ArcaneMartialArtsRequirement,
        );

        if (!character.hasInhumanity) {
          errors.push('Inhumanidad');
        }

        if (character.secondaries.intellectual.medicine.final < 120) {
          errors.push('Medicina 120+ o Anatomía 80+');
        }

        if (character.combat.attackAbility.final < 300) {
          errors.push('Ataque 300+');
        }

        return errors;
      },
    }),
  ];
}
