import { model } from 'mobx-keystone';
import { DevelopedPDFieldModel } from '../../../utils/fields/DevelopedPDFieldModel';
import { getTotalOf } from '../../../utils/getTotalOf';

@model('Character/Psychic/PsychicProjection')
export class PsychicProjectionModel extends DevelopedPDFieldModel {
  get fromCategories(): number {
    return getTotalOf(this.character, (c) => c.psychic.psychicProjection.final);
  }

  get primariesBonus(): number {
    if (!this.character) return 0;

    return this.character.primaries.dexterity.modifier.final;
  }
}
