/* eslint-disable @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars */
import { SecondarySkill } from '../../character/model/types/SecondarySkill';

export enum Archetype {
  Fighter = 'Fighter',
  Domine = 'Domine',
  Hunter = 'Hunter',
  Mystic = 'Mystic',
  Psychic = 'Psychic',
}

export enum CategoryType {
  Warrior = 'Warrior',
  AcrobaticWarrior = 'Acrobatic Warrior',
  Paladin = 'Paladin',
  DarkPaladin = 'Dark Paladin',
  WeaponMaster = 'Weapon Master',
  Technician = 'Technician',
  Tao = 'Tao',
  Explorer = 'Explorer',
  Shadow = 'Shadow',
  Theft = 'Theft',
  Assassin = 'Assassin',
  Wizard = 'Wizard',
  Warlock = 'Warlock',
  Illusionist = 'Illusionist',
  MentalistWizard = 'Mentalist Wizard',
  Conjurer = 'Conjurer',
  ConjurerWarrior = 'Conjurer Warrior',
  Mentalist = 'Mentalist',
  MentalistWarrior = 'Mentalist Warrior',
  Novel = 'Novel',
}

export abstract class CategoryTemplate {
  constructor(public level: number) {}

  setLevel(level: number): void {
    this.level = level;
  }

  abstract name: string;
  abstract type: CategoryType;
  abstract archetypes: Archetype[];

  abstract readonly lifeMultiplier: number;

  abstract readonly lifePoints: number;

  calculateLifePointsBonus(): number {
    return this.lifePoints * this.level;
  }

  protected abstract readonly turn: number;

  calculateTurn(): number {
    return this.turn * this.level;
  }

  protected abstract readonly martialKnowledge: number;

  calculateMartialKnowledge(): number {
    return this.martialKnowledge * this.level;
  }

  protected abstract readonly nativePsychicPoints: number;

  calculateNativePsychicPoints(): number {
    return (
      Math.floor(Math.max(this.level - 1, 0) / 3) * this.nativePsychicPoints
    );
  }

  abstract readonly offensiveSkillsLimitPercentage: number;

  abstract readonly attackPDsCost: number;

  abstract readonly blockPDsCost: number;

  abstract readonly dodgePDsCost: number;

  abstract readonly wearArmorPDsCost: number;

  abstract readonly kiPDsCost: number;

  abstract readonly kiAccumMultiplierPDsCost: number;

  abstract readonly magicSkillsLimitPercentage: number;

  abstract readonly zeonPDsCost: number;

  abstract readonly aCTMultiplierPDsCost: number;

  abstract readonly magicProjectionPDsCost: number;

  abstract readonly summonPDsCost: number;

  abstract readonly controlPDsCost: number;

  abstract readonly bindPDsCost: number;

  abstract readonly banishPDsCost: number;

  abstract readonly psychicSkillsLimitPercentage: number;

  abstract readonly psychicPointsPDsCost: number;

  abstract readonly psychicProjectionPDsCost: number;

  abstract readonly athleticSecondariesPDsCost: number;

  abstract readonly socialSecondariesPDsCost: number;

  abstract readonly perceptionSecondariesPDsCost: number;

  abstract readonly intellectualSecondariesPDsCost: number;

  abstract readonly vigorSecondariesPDsCost: number;

  abstract readonly subterfugeSecondariesPDsCost: number;

  abstract readonly creativeSecondariesPDsCost: number;

  protected readonly attackBonus: number = 0;

  calculateAttackBonus(): number {
    return this.level * this.attackBonus;
  }

  protected readonly blockBonus: number = 0;

  calculateBlockBonus(): number {
    return this.level * this.blockBonus;
  }

  protected readonly dodgeBonus: number = 0;

  calculateDodgeBonus(): number {
    return this.level * this.dodgeBonus;
  }

  protected readonly wearArmorBonus: number = 0;

  calculateWearArmorBonus(): number {
    return this.level * this.wearArmorBonus;
  }

  protected readonly controlBonus: number = 0;

  calculateControlBonus(): number {
    return this.level * this.controlBonus;
  }

  protected readonly bindBonus: number = 0;

  calculateBindBonus(): number {
    return this.level * this.bindBonus;
  }

  protected readonly summonBonus: number = 0;

  calculateSummonBonus(): number {
    return this.level * this.summonBonus;
  }

  protected readonly banishBonus: number = 0;

  calculateBanishBonus(): number {
    return this.level * this.banishBonus;
  }

  protected readonly zeonBonus: number = 0;

  calculateZeonBonus(): number {
    return this.level * this.zeonBonus;
  }

  calculateSecondarySkillPDReduction(skill: SecondarySkill): number {
    return 0;
  }

  calculateSecondarySkillBonusPerCategory(skill: SecondarySkill): number {
    return 0;
  }
}
