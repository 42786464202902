import { model } from 'mobx-keystone';
import { PDFieldModel } from '../PDFieldModel';

@model('ZeonPointsField')
export class ZeonPointsPDFieldModel extends PDFieldModel {
  get pdsCost(): number {
    return this.template.zeonPDsCost;
  }

  get bought(): number {
    if (!this.character) return 0;

    return Math.floor((this.base + this.special) / this.pdsCost) * 5;
  }

  get perCategory(): number {
    return this.template.calculateZeonBonus();
  }
}
