import { Character } from '../../../domain/character/Character';
import { Container, Wrapper } from './CharacterList.styled';
import { CharacterItem } from './components/CharacterItem/CharacterItem';
import { useCharacterSync } from '../../../../shared/application/hooks/useCharacterSync';
import { useModal } from '../../../../shared/application/design-system/components/Modal/hooks/useModal';
import { VTTCharacterListModal } from './components/VTTCharacterListModal/VTTCharacterListModal';
import { NotificationManager } from '../../../../shared/application/shared/NotificationManager';
import { FoundryVTTActor } from '../../../../embed/infrastructure/mutators/anima/foundry/FoundryVTTActor';
import { useConfirmModal } from '../../../../shared/application/hooks/useConfirmModal';
import { TrackingService } from '../../../../shared/infrastructure/tracking/TrackingService';

interface CharacterListProps {
  characters: Character[];

  onDeleteCharacter?: (character: Character) => void;
  onDuplicateCharacter?: (character: Character) => void;
}

const getCollaborationStatus = (character: Character) => {
  if (character.isMine) {
    return 'owner';
  }

  if (character.isCollaborative) {
    return 'collaborator';
  }

  if (character.isPublic) {
    return 'public-access';
  }

  return 'none';
};

export const CharacterList = ({
  characters,
  onDeleteCharacter,
  onDuplicateCharacter,
}: CharacterListProps) => {
  const { openModal, closeModal, Modal } = useModal();
  const { ConfirmModal, openConfirmModal } = useConfirmModal();

  const { getActors, createActor, updateActor } = useCharacterSync();

  const handleUpdateSyncRequest = async (character: Character) => {
    try {
      const actors = await getActors();

      const handleActorSelection = async (actor: FoundryVTTActor) => {
        const confirmation = await openConfirmModal({
          title: 'Confirmar actualización',
          content: `Se va a proceder a actualizar la información de ${actor.name}. Esta acción no se puede deshacer.`,
        });

        if (!confirmation) return;

        await updateActor({
          character,
          actor,
        });

        TrackingService.sendEvent('character_imported_as_update', {
          characterId: character.id,
          relationshipWithCharacter: getCollaborationStatus(character),
        });

        NotificationManager.success('Personaje sincronizado correctamente');

        closeModal();
      };

      openModal(
        <VTTCharacterListModal
          actors={actors}
          onActorSelected={handleActorSelection}
        />,
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleCreateSyncRequest = async (character: Character) => {
    try {
      await createActor(character);

      TrackingService.sendEvent('character_imported_as_new', {
        characterId: character.id,
        relationshipWithCharacter: getCollaborationStatus(character),
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Wrapper>
      <Container>
        {characters.map((character) => {
          const handleDeletion = () => {
            onDeleteCharacter?.(character);
          };

          const handleDuplicate = () => {
            onDuplicateCharacter?.(character);
          };

          return (
            <CharacterItem
              key={character.id}
              character={character}
              onDelete={handleDeletion}
              onDuplicate={handleDuplicate}
              onCreateSyncRequest={handleCreateSyncRequest}
              onUpdateSyncRequest={handleUpdateSyncRequest}
            />
          );
        })}
        <Modal />
        <ConfirmModal />
      </Container>
    </Wrapper>
  );
};
