import styled from 'styled-components';
import { BasicParagraph } from '../../../../../../../../../../../../../../../shared/application/design-system/components/Texts/Texts.styled';
import { Input } from '../../../../../../../../../../../../../../../shared/application/design-system/components/Input/Input';

export const Wrapper = styled.div({
  width: '100%',
  height: '100%',
});

export const Container = styled.div(({ theme: { size } }) => ({
  display: 'grid',

  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: '1fr 1fr',
  rowGap: size.units(2),
  columnGap: size.units(2),
}));

export const CostTitle = styled(BasicParagraph)(({ theme: { color } }) => ({
  color: color.primaryDark(),
}));

export const StyledInput = styled(Input)(({ theme: { size } }) => ({
  width: size.units(7),

  textAlign: 'center',
}));

export const CostContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',

  gap: size.units(1),

  maxWidth: size.units(13),

  justifyContent: 'start',
  alignItems: 'start',
}));
