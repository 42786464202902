import { observer } from 'mobx-react';
import DoubleInput from '../../../common/DoubleInput/DoubleInput';
import { Section } from '../../../common/Section/Section';
import { useCharacterContext } from '../../../../hooks/useCharacterContext';

export const MagicAccumulation = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  return (
    <Section maxHeight="fit">
      <Section.Title>Acumulación</Section.Title>
      <DoubleInput
        label="Regeneración zeónica"
        field={character.mystic.zeonRegeneration}
      />
      <DoubleInput label="ACT" field={character.mystic.act} />
      <DoubleInput label="Magia innata" field={character.mystic.innateMagic} />
    </Section>
  );
});
