import { Disadvantage, DisadvantageType } from './Disadvantage.types';

export const DisadvantageLevel1 = {
  id: 'Level1',
  name: 'Nivel 1',
} as const;

export const DisadvantageLevel2 = {
  id: 'Level2',
  name: 'Nivel 2',
} as const;

export type DisadvantageLevel =
  | typeof DisadvantageLevel1
  | typeof DisadvantageLevel2;

export enum Rest2ToPrimaryCharacteristicDisadvantageVariation {
  Perception = 'Perception',
  Strength = 'Strength',
  Constitution = 'Constitution',
  Agility = 'Agility',
  Dexterity = 'Dexterity',
  Intelligence = 'Intelligence',
  Power = 'Power',
  WillPower = 'WillPower',
}

export const Rest2ToPrimaryCharacteristicDisadvantage: Disadvantage<Rest2ToPrimaryCharacteristicDisadvantageVariation> =
  {
    id: 'disadvantage-1',

    name: '-2 a característica',

    type: DisadvantageType.Common,

    levels: [DisadvantageLevel1],

    variations: [
      {
        id: Rest2ToPrimaryCharacteristicDisadvantageVariation.Strength,
        name: 'Fuerza',
      },
      {
        id: Rest2ToPrimaryCharacteristicDisadvantageVariation.Perception,
        name: 'Percepción',
      },
      {
        id: Rest2ToPrimaryCharacteristicDisadvantageVariation.Constitution,
        name: 'Constitución',
      },
      {
        id: Rest2ToPrimaryCharacteristicDisadvantageVariation.Agility,
        name: 'Agilidad',
      },
      {
        id: Rest2ToPrimaryCharacteristicDisadvantageVariation.Dexterity,
        name: 'Destreza',
      },
      {
        id: Rest2ToPrimaryCharacteristicDisadvantageVariation.Intelligence,
        name: 'Inteligencia',
      },
      {
        id: Rest2ToPrimaryCharacteristicDisadvantageVariation.Power,
        name: 'Poder',
      },
      {
        id: Rest2ToPrimaryCharacteristicDisadvantageVariation.WillPower,
        name: 'Voluntad',
      },
    ],
  };

export const AddictionOViceSeriousDisadvantage: Disadvantage = {
  id: 'disadvantage-2',

  name: 'Adicción o vicio grave',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const SevereAllergyDisadvantage: Disadvantage = {
  id: 'disadvantage-3',

  name: 'Alergia grave',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const SlowLearnerDisadvantage: Disadvantage = {
  id: 'disadvantage-4',

  name: 'Aprendizaje lento',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1, DisadvantageLevel2],
};

export const ExclusiveWeaponDisadvantage: Disadvantage = {
  id: 'disadvantage-5',

  name: 'Arma exclusiva',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const UnsightlyAppearanceDisadvantage: Disadvantage = {
  id: 'disadvantage-6',

  name: 'Aspecto desagradable',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const BlindDisadvantage: Disadvantage = {
  id: 'disadvantage-7',

  name: 'Ciego',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const CowardiceDisadvantage: Disadvantage = {
  id: 'disadvantage-8',

  name: 'Cobardía',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const PhysicalWeaknessDisadvantage: Disadvantage = {
  id: 'disadvantage-9',

  name: 'Debilidad física',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const UnluckyDisadvantage: Disadvantage = {
  id: 'disadvantage-10',

  name: 'Desafortunado',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const FateFateDisadvantage: Disadvantage = {
  id: 'disadvantage-11',

  name: 'Destino aciago',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel2],
};

export const FlimsyDisadvantage: Disadvantage = {
  id: 'disadvantage-12',

  name: 'Endeble',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const SeriousIllnessDisadvantage: Disadvantage = {
  id: 'disadvantage-13',

  name: 'Enfermedad grave',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const ExhaustedDisadvantage: Disadvantage = {
  id: 'disadvantage-14',

  name: 'Exhausto',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const AtrophiedLimbDisadvantage: Disadvantage = {
  id: 'disadvantage-15',

  name: 'Extremidad atrofiada',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const EasyPossessionDisadvantage: Disadvantage = {
  id: 'disadvantage-16',

  name: 'Fácil posesión',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const SeriousPhobiaDisadvantage: Disadvantage = {
  id: 'disadvantage-17',

  name: 'Fobia grave',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const InsufferableDisadvantage: Disadvantage = {
  id: 'disadvantage-18',

  name: 'Insufrible',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const SlowHealingDisadvantage: Disadvantage = {
  id: 'disadvantage-19',

  name: 'Lenta curación',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const BadLuckDisadvantage: Disadvantage = {
  id: 'disadvantage-20',

  name: 'Mala suerte',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const DamnedDisadvantage: Disadvantage = {
  id: 'disadvantage-21',

  name: 'Maldito',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1, DisadvantageLevel2],
};

export const MyopiaDisadvantage: Disadvantage = {
  id: 'disadvantage-22',

  name: 'Miopía',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const DumbDisadvantage: Disadvantage = {
  id: 'disadvantage-23',

  name: 'Mudo',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const ClumsyDisadvantage: Disadvantage = {
  id: 'disadvantage-24',

  name: 'Patoso',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const SlowReactionDisadvantage: Disadvantage = {
  id: 'disadvantage-25',

  name: 'Reacción lenta',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1, DisadvantageLevel2],
};

export const SicklyHealthDisadvantage: Disadvantage = {
  id: 'disadvantage-26',

  name: 'Salud enfermiza',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const NoNaturalBonusDisadvantage: Disadvantage = {
  id: 'disadvantage-27',

  name: 'Sin bonificador natural',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const DeafDisadvantage: Disadvantage = {
  id: 'disadvantage-28',

  name: 'Sordo',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const DeepSleepDisadvantage: Disadvantage = {
  id: 'disadvantage-29',

  name: 'Sueño profundo',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const VulnerableColdOrHeatDisadvantage: Disadvantage = {
  id: 'disadvantage-30',

  name: 'Vulnerable a frío o calor',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const VulnerableToMagicDisadvantage: Disadvantage = {
  id: 'disadvantage-31',

  name: 'Vulnerable a la magia',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const VulnerableToPoisonsDisadvantage: Disadvantage = {
  id: 'disadvantage-32',

  name: 'Vulnerable a los venenos',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const VulnerableToPainDisadvantage: Disadvantage = {
  id: 'disadvantage-33',

  name: 'Vulnerable al dolor',

  type: DisadvantageType.Common,

  levels: [DisadvantageLevel1],
};

export const AllCommonDisadvantages: Disadvantage[] = [
  Rest2ToPrimaryCharacteristicDisadvantage,
  AddictionOViceSeriousDisadvantage,
  SevereAllergyDisadvantage,
  SlowLearnerDisadvantage,
  ExclusiveWeaponDisadvantage,
  UnsightlyAppearanceDisadvantage,
  BlindDisadvantage,
  CowardiceDisadvantage,
  PhysicalWeaknessDisadvantage,
  UnluckyDisadvantage,
  FateFateDisadvantage,
  FlimsyDisadvantage,
  SeriousIllnessDisadvantage,
  ExhaustedDisadvantage,
  AtrophiedLimbDisadvantage,
  EasyPossessionDisadvantage,
  SeriousPhobiaDisadvantage,
  InsufferableDisadvantage,
  SlowHealingDisadvantage,
  BadLuckDisadvantage,
  DamnedDisadvantage,
  MyopiaDisadvantage,
  DumbDisadvantage,
  ClumsyDisadvantage,
  SlowReactionDisadvantage,
  SicklyHealthDisadvantage,
  NoNaturalBonusDisadvantage,
  DeafDisadvantage,
  DeepSleepDisadvantage,
  VulnerableColdOrHeatDisadvantage,
  VulnerableToMagicDisadvantage,
  VulnerableToPoisonsDisadvantage,
  VulnerableToPainDisadvantage,
];
