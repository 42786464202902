import { Folder, MoreVert } from '@styled-icons/material';
import styled, { css, keyframes } from 'styled-components';
import { SmallTitle } from '../../../../../../shared/application/design-system/components/Texts/Texts.styled';

export const FolderIconAndNameContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',

  width: '80%',

  gap: size.units(1),
}));

export const FolderName = styled(SmallTitle)(({ theme: { color } }) => ({
  color: color.black(),

  width: '100%',

  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const FolderIcon = styled(Folder)(
  ({ theme: { size, color, transition } }) => ({
    color: color.silver(),

    width: size.units(4),
    height: size.units(4),

    transition: transition.standard,
  }),
);

export const OptionsIcon = styled(MoreVert)(
  ({ theme: { size, color, transition } }) => ({
    color: color.primary(),

    width: size.units(3.5),
    height: size.units(3.5),

    transition: transition.standard,
  }),
);

export const Action = styled.div(({ theme: { color, transition } }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',

  cursor: 'pointer',

  transition: transition.standard,

  '&:hover': {
    [OptionsIcon]: {
      color: color.black(),
    },
  },
}));

const PulseBoxShadowAnimation = keyframes`
    0% {
      box-shadow: 0 0 5px 0 rgba(209, 45, 5, 0.5);
    }

    100% {
      box-shadow: 0 0 5px 0 rgba(209, 45, 5, 1);
    }
`;

const PulseBorderAnimation = css`
  ${PulseBoxShadowAnimation} 1s infinite;
`;

export const Wrapper = styled(
  styled.div<{ $isDragging?: boolean; $isOver?: boolean }>(
    ({
      theme: { size, color, borderRadius, transition, mixins },
      $isDragging,
    }) => ({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      opacity: $isDragging ? 0.5 : undefined,

      borderRadius: borderRadius.medium,

      boxSizing: 'border-box',

      border: `2px solid ${color.black()}`,

      width: size.units(25),
      height: size.units(6),
      padding: size.units(1),

      boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.5)',

      cursor: 'pointer',

      transition: transition.standard,

      '&:hover': {
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 1)',
      },

      ...mixins.responsive.md.upward({
        width: size.units(30),
      }),

      ...mixins.responsive.xs.downward({
        width: '100%',
      }),
    }),
  ),
)`
  animation: ${({ $isOver }) => ($isOver ? PulseBorderAnimation : undefined)}};
  border-color: ${({ $isOver, theme: { color } }) =>
    $isOver ? color.primaryLight() : undefined};
`;
