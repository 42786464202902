import { useRef } from 'react';
import ReactQuill from 'react-quill';
import { v4 as uuid } from 'uuid';
import { Wrapper } from './HtmlEditor.styled';
import { useCharacterContext } from '../../../hooks/useCharacterContext';

interface HtmlEditorProps {
  value: string;
  setValue: (value: string) => void;
}

export const HtmlEditor = ({ value, setValue }: HtmlEditorProps) => {
  const { character } = useCharacterContext();

  const uuidRef = useRef(uuid());

  const readOnly = !character.canBeEdited;

  return (
    <Wrapper>
      <ReactQuill
        id={uuidRef.current}
        theme="snow"
        value={value}
        readOnly={readOnly}
        onChange={setValue}
      />
    </Wrapper>
  );
};
