import { model } from 'mobx-keystone';
import { ImperceptibleKiAdvantage } from '../../../../../aggregations/advantage/CommonAdvantages';
import { KiSkillType } from '../../../../../aggregations/ki-skill/KiSkill';
import { Calculation, NumberField } from '../../../utils/fields/NumberField';
import { CategoryType } from '../../../../../aggregations/category-templates/CategoryTemplate';
import { NephilimDAnjayni } from '../../../../../aggregations/nephilim-type/Nephilim';

@model('Character/Ki/KiHideField')
export class KiHideFieldModel extends NumberField {
  getCalculations(): Calculation[] {
    if (!this.character) return [];

    const calculations: Calculation[] = [];

    calculations.push(this.sum('Especial', this.special));

    calculations.push(
      this.sum(
        'Ocultarse',
        this.character.secondaries.subterfuge.hide.finalWithoutNaturalPenalty,
      ),
    );

    calculations.push(
      this.sum(
        'Conocimiento marcial',
        this.character.ki.martialKnowledge.final,
      ),
    );

    calculations.push(this.div('Dividido entre 2', 2));

    calculations.push(this.floor(calculations, { hideOnZero: true }));

    if (
      this.character.creationPoints.containsAdvantage(ImperceptibleKiAdvantage)
    ) {
      calculations.push(
        this.sum('Ventaja: Ki Imperceptible', 10 * this.character.level),
      );
    }

    const shadowCategory = this.character.categories.find(
      (c) => c.category === CategoryType.Shadow,
    );

    if (shadowCategory) {
      calculations.push(
        this.sum('Por categoría de Sombra', shadowCategory.level.final * 5),
      );
    }

    if (this.character.isNephilim(NephilimDAnjayni)) {
      calculations.push(this.sum(NephilimDAnjayni.name, 30));
    }

    return calculations;
  }

  get isDeveloped(): boolean {
    return (
      this.character?.isNephilim(NephilimDAnjayni) ||
      !!this.character?.ki?.kiSkills?.includes?.(KiSkillType.KiHide)
    );
  }
}
