import { CharacterModel } from '../../character/model/CharacterModel';
import { HitType } from '../../character/model/types/HitType';

export type MartialArtGradeId = string;

type BasicMartialArtConstructorParams = {
  cmBonus?: number;
  effects?: string;
  combatBonus?: {
    attack?: number;
    block?: number;
    dodge?: number;
    initiative?: number;
  };
  getRequirementsErrors?: (character: CharacterModel) => string[];
  calculateDamage?: (character: CharacterModel) => number;
  calculateHitType?: (character: CharacterModel) => HitType[];
};

type AdvancedMartialArtConstructorParams = BasicMartialArtConstructorParams & {
  masterBonus?: {
    attack?: number;
    block?: number;
    dodge?: number;
    initiative?: number;
  };
};

export abstract class BasicMartialArtGrade {
  abstract readonly id: MartialArtGradeId;

  abstract readonly level: string;

  abstract readonly name: string;

  abstract readonly cost: number;

  readonly effects: string | undefined;

  readonly cmBonus: number;

  readonly combatBonus = {
    attack: 0,
    block: 0,
    dodge: 0,
    initiative: 0,
  };

  readonly calculateDamage: ((character: CharacterModel) => number) | undefined;

  readonly calculateHitType:
    | ((character: CharacterModel) => HitType[])
    | undefined;

  readonly getRequirementsErrors: (character: CharacterModel) => string[];

  constructor(params?: BasicMartialArtConstructorParams) {
    this.cmBonus = params?.cmBonus ?? 0;
    this.effects = params?.effects ?? undefined;
    this.getRequirementsErrors = params?.getRequirementsErrors ?? (() => []);
    this.calculateDamage = params?.calculateDamage;
    this.calculateHitType = params?.calculateHitType;
    this.combatBonus = params?.combatBonus
      ? {
          attack: params.combatBonus.attack ?? 0,
          block: params.combatBonus.block ?? 0,
          dodge: params.combatBonus.dodge ?? 0,
          initiative: params.combatBonus.initiative ?? 0,
        }
      : this.combatBonus;
  }

  isAdvanced(): this is AdvancedMartialArtGrade {
    return 'masterBonus' in this;
  }
}

export abstract class AdvancedMartialArtGrade extends BasicMartialArtGrade {
  readonly masterBonus = {
    attack: 0,
    block: 0,
    dodge: 0,
    initiative: 0,
  };

  constructor(params?: AdvancedMartialArtConstructorParams) {
    super(params);

    this.masterBonus = params?.masterBonus
      ? {
          attack: params.masterBonus.attack ?? 0,
          block: params.masterBonus.block ?? 0,
          dodge: params.masterBonus.dodge ?? 0,
          initiative: params.masterBonus.initiative ?? 0,
        }
      : this.masterBonus;
  }
}

export type MartialArtGrade = BasicMartialArtGrade | AdvancedMartialArtGrade;

const BasicMartialArtBasicGradeID = 'basic-martial-art-basic-grade';

type BasicMartialArtGradeBuilder = (
  params?: BasicMartialArtConstructorParams,
) => BasicMartialArtGrade;

type AdvancedMartialArtGradeBuilder = (
  params?: AdvancedMartialArtConstructorParams,
) => AdvancedMartialArtGrade;

export class BasicMartialArtBasicGrade extends BasicMartialArtGrade {
  id = BasicMartialArtBasicGradeID;
  level = 'basic';
  name = 'Base';
  cost = 20;
}

export const buildBasicMartialArtBasicGrade: BasicMartialArtGradeBuilder = (
  params,
) => new BasicMartialArtBasicGrade(params);

const BasicMartialArtAdvancedGradeID = 'basic-martial-art-advanced-grade';

export class BasicMartialArtAdvancedGrade extends BasicMartialArtGrade {
  id = BasicMartialArtAdvancedGradeID;
  level = 'advanced';
  name = 'Avanzado';
  cost = 50;
}

export const buildBasicMartialArtAdvancedGrade: BasicMartialArtGradeBuilder = (
  params,
) => new BasicMartialArtAdvancedGrade(params);

const BasicMartialArtSupremeGradeID = 'basic-martial-art-supreme-grade';

export class BasicMartialArtSupremeGrade extends BasicMartialArtGrade {
  id = BasicMartialArtSupremeGradeID;
  level = 'supreme';
  name = 'Supremo';
  cost = 100;
}

export const BasicMartialArtGradeIds = {
  Basic: BasicMartialArtBasicGradeID,
  Advanced: BasicMartialArtAdvancedGradeID,
  Supreme: BasicMartialArtSupremeGradeID,
};

export const buildBasicMartialArtSupremeGrade: BasicMartialArtGradeBuilder = (
  params,
) => new BasicMartialArtSupremeGrade(params);

const AdvancedMartialArtBasicGradeID = 'advanced-martial-art-basic-grade';

export class AdvancedMartialArtBasicGrade extends AdvancedMartialArtGrade {
  id = AdvancedMartialArtBasicGradeID;
  level = 'basic';
  name = 'Base';
  cost = 50;
}

export const buildAdvancedMartialArtBasicGrade: AdvancedMartialArtGradeBuilder =
  (params) => new AdvancedMartialArtBasicGrade(params);

const AdvancedMartialArtArcaneGradeID = 'advanced-martial-art-arcane-grade';

export class AdvancedMartialArtArcaneGrade extends AdvancedMartialArtGrade {
  id = AdvancedMartialArtArcaneGradeID;
  level = 'arcane';
  name = 'Arcano';
  cost = 100;
}

export const AdvancedMartialArtGradeIds = {
  Basic: AdvancedMartialArtBasicGradeID,
  Arcane: AdvancedMartialArtArcaneGradeID,
};

export const buildAdvancedMartialArtArcaneGrade: AdvancedMartialArtGradeBuilder =
  (params) => new AdvancedMartialArtArcaneGrade(params);
