import { Theme } from '../theme';

export const interactable: (color: Theme['color']) => Record<string, any> = (
  color: Theme['color'],
) => ({
  fontWeight: 'bold',
  cursor: 'pointer',

  textAlign: 'center',

  transition: 'all 0.2s ease-in-out',

  '&:after': {
    display: 'block',
    content: '""',
    borderBottom: `solid 1px ${color.primaryLight()}`,
    transform: 'scaleX(0.25)',
    transition: 'transform 100ms ease-in-out',
  },
  '&:hover': {
    color: color.secondary(),

    '&:after': {
      transform: 'scaleX(1)',
    },
  },
});
