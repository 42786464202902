import { KiTechniqueModel } from '../../../../../../../../../../../../domain/character/model/parts/ki/parts/KiTechniqueModel';
import { Draft, draft } from 'mobx-keystone';
import { observer } from 'mobx-react';
import {
  Container,
  Section,
  SectionTitle,
  StyledInput,
  StyledTextArea,
  StyledTitle,
  Wrapper,
} from './EditKiTechnique.styled';
import { ChangeEvent, useState } from 'react';
import { KiTechniqueCost } from '../../../../../../../../../../../../domain/aggregations/ki-technique/KiTechnique';
import { KiTechniqueCostInput } from './components/KiTechniqueCostInput/KiTechniqueCostInput';
import { Button } from '../../../../../../../../../../../../../shared/application/design-system/components/Button/Button';

export const EditKiTechnique = observer(
  ({
    technique: originalTechnique,
    onSaveTechnique,
  }: {
    technique: KiTechniqueModel;
    onSaveTechnique: () => void;
  }) => {
    const [techniqueDraft] = useState<Draft<KiTechniqueModel>>(
      draft(originalTechnique),
    );

    const technique = techniqueDraft.data;

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
      technique.setName(event.target.value);
    };

    const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
      technique.setType(event.target.value);
    };

    const handleMartialPointsChange = (
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      technique.setMpCost(event.target.valueAsNumber);
    };

    const handleLevelChange = (event: ChangeEvent<HTMLInputElement>) => {
      technique.setLevel(event.target.valueAsNumber);
    };

    const handleEffectChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      technique.setEffect(event.target.value);
    };

    const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      technique.setNotes(event.target.value);
    };

    const handleKiCostChange = (kiCost: KiTechniqueCost) => {
      technique.setKiCost(kiCost);
    };

    const handleSaveTechnique = () => {
      technique.transformToCustom();

      techniqueDraft.commit();

      onSaveTechnique();
    };

    return (
      <Wrapper>
        <Container>
          <StyledTitle>Editar técnica de Ki</StyledTitle>
          <Section>
            <SectionTitle>Nombre</SectionTitle>
            <StyledInput value={technique.name} onChange={handleNameChange} />
          </Section>
          <Section>
            <SectionTitle>Árbol</SectionTitle>
            <StyledInput value={technique.type} onChange={handleTypeChange} />
          </Section>
          <Section>
            <SectionTitle>Coste de conocimiento marcial</SectionTitle>
            <StyledInput
              type="number"
              value={technique.mpCost}
              onChange={handleMartialPointsChange}
            />
          </Section>
          <Section>
            <SectionTitle>Nivel</SectionTitle>
            <StyledInput
              type="number"
              value={technique.level}
              onChange={handleLevelChange}
            />
          </Section>
          <Section>
            <SectionTitle>Costes de Ki</SectionTitle>
            <KiTechniqueCostInput
              currentCost={technique.kiCost}
              onCostChange={handleKiCostChange}
            />
          </Section>
          <Section>
            <SectionTitle>Efecto</SectionTitle>
            <StyledTextArea
              value={technique.effect}
              onChange={handleEffectChange}
              rows={3}
            />
          </Section>
          <Section>
            <SectionTitle>Notas</SectionTitle>
            <StyledTextArea
              value={technique.notes}
              onChange={handleNotesChange}
              rows={3}
            />
          </Section>
          <Button
            onClick={handleSaveTechnique}
            disabled={!techniqueDraft.isDirty}
          >
            Guardar
          </Button>
        </Container>
      </Wrapper>
    );
  },
);
