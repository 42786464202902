export enum InputDigits {
  TENS,
  HUNDREDS,
  THOUSANDS,
}

export enum InputVariant {
  Normal = 'Normal',
  Outline = 'Outline',
}

export enum InputSize {
  Big = 'Big',
  Normal = 'Normal',
}

export enum LabelPosition {
  Top = 'Top',
  Left = 'Left',
}
