import styled from 'styled-components';

export const Wrapper = styled.div<{ $fullSize?: boolean }>(({ $fullSize }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: $fullSize ? '100%' : 'fit-content',
  height: $fullSize ? '100%' : 'fit-content',
}));
