import { useCallback, useState } from 'react';
import { CharacterService } from '../../../../../../infrastructure/CharacterService';

export const useCharacterCollaboration = (characterId: string) => {
  const [refreshPid, setRefreshPid] = useState<NodeJS.Timer | undefined>();

  const startCollaboration = useCallback((): Promise<
    | {
        someOneIsEditing: boolean;
      }
    | undefined
  > => {
    if (refreshPid) return Promise.resolve(undefined);

    async function markAsEditing() {
      await CharacterService.markCharacterAsEditing(characterId);
    }

    return new Promise<{
      someOneIsEditing: boolean;
    }>((resolve) => {
      markAsEditing()
        .then(() => resolve({ someOneIsEditing: false }))
        .catch(() => resolve({ someOneIsEditing: true }));

      setRefreshPid(
        setInterval(async () => {
          await markAsEditing();
        }, 30000),
      );
    });
  }, [refreshPid, characterId]);

  const stopCollaboration = useCallback(async () => {
    if (refreshPid) {
      clearInterval(refreshPid);
      setRefreshPid(undefined);

      await CharacterService.markCharacterAsNotEditing(characterId);
    }
  }, [refreshPid, characterId]);

  return {
    startCollaboration,
    stopCollaboration,
  };
};
