import ReactGA from 'react-ga4';

import type { TrackingService } from './TrackingService';

let commonParams: { userUuid: string } | undefined;

export const GoogleAnalyticsTrackingService: TrackingService = {
  init() {
    ReactGA.initialize('G-9NSS4N6GXY');
  },
  setUser(params: { id: string; email: string }): void {
    ReactGA.gtag('set', 'user_properties', {
      user_id: params.id,
      user_email: params.email,
    });

    ReactGA.gtag('config', 'G-9NSS4N6GXY', {
      user_id: params.id,
    });

    commonParams = {
      userUuid: params.id,
    };
  },
  sendPageView(): void {
    ReactGA.event('page_view', {
      ...commonParams,
    });
  },
  sendEvent(eventName, params): void {
    if (!commonParams) {
      console.warn('User not set. Event not sent.');
    } else {
      ReactGA.event(eventName, {
        ...commonParams,
        ...params,
      });
    }
  },
};
