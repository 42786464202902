import { ExtendedModel, model, prop } from 'mobx-keystone';
import { SecondarySkill } from '../../../../../../../types/SecondarySkill';
import { SecondarySkillPDFieldModel } from '../SecondarySkillPDFieldModel';
import { SecondarySkillBonusType } from '../SecondarySkillPDFieldModel.types';
import { SecondarySkillsPDModel } from './SecondarySkillsPDModel';

@model('Character/Development/SecondarySkills/VigorSkillsPD')
export class VigorSkillsPDModel extends ExtendedModel(SecondarySkillsPDModel, {
  composure: prop(
    () =>
      new SecondarySkillPDFieldModel({
        skill: SecondarySkill.Composure,
        type: SecondarySkillBonusType.WillPower,
      }),
  ),
  featsOfStrength: prop(
    () =>
      new SecondarySkillPDFieldModel({
        skill: SecondarySkill.FeatsOfStrength,
        type: SecondarySkillBonusType.Strength,
      }),
  ),
  withstandPain: prop(
    () =>
      new SecondarySkillPDFieldModel({
        skill: SecondarySkill.WithstandPain,
        type: SecondarySkillBonusType.WillPower,
      }),
  ),
}) {
  get fields(): SecondarySkillPDFieldModel[] {
    return [this.composure, this.featsOfStrength, this.withstandPain];
  }
}
