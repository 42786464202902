import { observer } from 'mobx-react';
import { MouseEvent, useState } from 'react';
import {
  isMainPath,
  isSubPath,
  MagicPath,
  MagicSubPath,
} from '../../../../../../../../../../domain/aggregations/magic-path/MagicPath';
import { MagicPathModel } from '../../../../../../../../../../domain/character/model/parts/mystic/parts/MagicPathModel/MagicPathModel';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { AddItemButton } from '../../../../common/AddItemButton/AddItemButton';
import DoubleInput from '../../../../common/DoubleInput/DoubleInput';
import { useCharacterMenu } from '../../../../common/CharacterMenu/useCharacterMenu';
import { transformToMenuItems } from '../../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { RemoveItemButton } from '../../../../common/RemoveItemButton/RemoveItemButton';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';

export const MagicLevel = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const [selectedMagicPath, setSelectedMagicPath] = useState<MagicPathModel>();

  const { handleMenuOpen: handleMagicPathMenuOpen, Menu: MagicPathMenu } =
    useCharacterMenu<MagicPath>();

  const { handleMenuOpen: handleMagicSubPathMenuOpen, Menu: MagicSubPathMenu } =
    useCharacterMenu<MagicSubPath | undefined>();

  const magicPathItems = transformToMenuItems({
    fromItems: Object.values(MagicPath).filter(isMainPath),
    generateItem: (magicPath) => ({
      key: magicPath,
      name: magicPath,
      value: magicPath,
      onClick: () => {
        selectedMagicPath?.setPath(magicPath);
      },
    }),
  });

  const magicSubPathItems = transformToMenuItems({
    fromItems: [undefined, ...Object.values(MagicPath).filter(isSubPath)],
    generateItem: (magicSubPath) => {
      if (magicSubPath === undefined) {
        return {
          key: 'none',
          name: 'Ninguna',
          value: undefined,
          onClick: () => {
            selectedMagicPath?.setSubPath(undefined);
          },
        };
      }

      return {
        key: magicSubPath,
        name: magicSubPath,
        value: magicSubPath,
        onClick: () => {
          selectedMagicPath?.setSubPath(magicSubPath);
        },
      };
    },
  });

  const handleMagicPathClick =
    (magicPath: MagicPathModel) => (e: MouseEvent<HTMLDivElement>) => {
      setSelectedMagicPath(magicPath);
      handleMagicPathMenuOpen(magicPath.path)(e);
    };

  const handleMagicSubPathClick =
    (magicPath: MagicPathModel) => (e: MouseEvent<HTMLDivElement>) => {
      setSelectedMagicPath(magicPath);
      handleMagicSubPathMenuOpen(magicPath.subPath)(e);
    };

  const addPath = () => {
    character.mystic.addPath();
  };

  const removePath = (path: MagicPathModel) => () => {
    character.mystic.removePath(path);
  };

  return (
    <Section maxHeight="fit">
      <Section.Title>Nivel de Magia</Section.Title>
      <Table>
        <Table.Header>
          <Table.Header.Cell>Máximo</Table.Header.Cell>
          <Table.Header.Cell>Usado</Table.Header.Cell>
        </Table.Header>
        <Table.Row>
          <Table.Row.Cell $important>
            {character.mystic.magicLevel.final}
          </Table.Row.Cell>
          <Table.Row.Cell $important>
            {character.mystic.usedMagicLevel}
          </Table.Row.Cell>
        </Table.Row>
      </Table>

      <Table>
        <Table.Header>
          <Table.Header.Cell>Vía</Table.Header.Cell>
          <Table.Header.Cell>Sub-Via</Table.Header.Cell>
          <Table.Header.Cell>Nivel</Table.Header.Cell>
          <Table.Header.Cell />
        </Table.Header>
        {character.mystic.paths.map((path) => (
          <Table.Row key={path.$modelId}>
            <Table.Row.Cell $interactable onClick={handleMagicPathClick(path)}>
              {path.path}
            </Table.Row.Cell>
            <Table.Row.Cell
              $interactable
              onClick={handleMagicSubPathClick(path)}
            >
              {path.subPath ?? 'Ninguna'}
            </Table.Row.Cell>
            <Table.Row.Cell>
              <DoubleInput field={path.level} />
            </Table.Row.Cell>
            <Table.Row.Cell>
              <RemoveItemButton onClick={removePath(path)} />
            </Table.Row.Cell>
          </Table.Row>
        ))}
      </Table>

      <AddItemButton onClick={addPath} />

      <MagicPathMenu items={magicPathItems} />
      <MagicSubPathMenu items={magicSubPathItems} />
    </Section>
  );
});
