import { model } from 'mobx-keystone';
import { PDFieldModel } from '../PDFieldModel';

@model('Character/PD/Mystic/SummonPDField')
export class SummonPDFieldModel extends PDFieldModel {
  get pdsCost(): number {
    return this.template.summonPDsCost;
  }

  get perCategory(): number {
    return this.template.calculateSummonBonus();
  }
}
