import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import {
  ElegiblePredefinedWeapons,
  PredefinedWeapon,
} from '../../../../../../../../../../domain/aggregations/weapon/PredefinedWeapons';
import { weaponTypeTranslations } from '../../../../../../../../../../domain/aggregations/weapon/Weapon.types';
import { WeaponItemModel } from '../../../../../../../../../../domain/aggregations/weapon/WeaponItemModel';
import { AddItemButton } from '../../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../../common/CharacterMenu/useCharacterMenu';
import { transformToMenuItems } from '../../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { WeaponRow } from './components/WeaponRow/WeaponRow';
import { MenuItem } from '../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';

export const Weapons = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { handleMenuOpen: handleWeaponMenuOpen, Menu: WeaponMenu } =
    useCharacterMenu<PredefinedWeapon>();

  const [selectedWeapon, setSelectedWeapon] = useState<WeaponItemModel | null>(
    null,
  );

  const predefinedWeapons: MenuItem<PredefinedWeapon>[] = transformToMenuItems({
    fromItems: ElegiblePredefinedWeapons,
    generateItem: (i) => ({
      key: i.id,
      name: i.name,
      value: i,
      onClick: () => {
        if (selectedWeapon) {
          character.inventory.updateWeapon(selectedWeapon, i);
        }
      },
    }),
    generateDivider: (i) => weaponTypeTranslations[i.types[0]] ?? 'Especiales',
  });

  const handleWeaponSelection =
    (weapon: WeaponItemModel) => (e: MouseEvent<HTMLDivElement>) => {
      setSelectedWeapon(weapon);
      handleWeaponMenuOpen(weapon.template)(e);
    };

  const handleAddWeapon = () => {
    character.inventory.addWeapon(ElegiblePredefinedWeapons[0]);
  };

  const handleRemoveWeapon = (weaponModel: WeaponItemModel) => {
    character.inventory.removeWeapon(weaponModel);
  };

  return (
    <Section>
      <Section.Title>Armas</Section.Title>

      <Table>
        <Table.Header>
          <Table.Header.Cell $width={1} $hideOnMobile />
          <Table.Header.Cell $width={2.5}>Equipada</Table.Header.Cell>
          <Table.Header.Cell $width={5}>Nombre</Table.Header.Cell>
          <Table.Header.Cell $width={5} $hideOnMobile>
            Conocimiento
          </Table.Header.Cell>
          <Table.Header.Cell $width={5}>Ataque</Table.Header.Cell>
          <Table.Header.Cell $width={5}>Bloqueo</Table.Header.Cell>
          <Table.Header.Cell $width={5}>Daño</Table.Header.Cell>
          <Table.Header.Cell $width={5} $hideOnMobile>
            Iniciativa
          </Table.Header.Cell>
          <Table.Header.Cell $width={1} />
        </Table.Header>

        {character.inventory.weapons.map((weapon) => (
          <WeaponRow
            key={weapon.id}
            weapon={weapon}
            onNameClick={handleWeaponSelection}
            onRemove={handleRemoveWeapon}
          />
        ))}
      </Table>

      <AddItemButton onClick={handleAddWeapon} />

      <WeaponMenu items={predefinedWeapons} />
    </Section>
  );
});
