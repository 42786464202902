import { model, Model, modelAction, prop } from 'mobx-keystone';
import { CategoryModel } from './parts/categories/CategoryModel';
import { CategoryType } from '../../../../aggregations/category-templates/CategoryTemplate';
import { computed } from 'mobx';

@model('Character/PD')
export class PDModel extends Model({
  categories: prop<CategoryModel[]>(() => [new CategoryModel({})]),
}) {
  @modelAction
  addCategory(category: CategoryModel): void {
    this.categories.push(category);
  }

  @modelAction
  removeCategory(category: CategoryModel): void {
    this.categories = this.categories.filter((c) => c !== category);
  }

  containsCategory(type: CategoryType): boolean {
    return this.categories.some((c) => c.category === type);
  }

  @computed
  get totalPds(): number {
    return this.categories.reduce((acc, c) => acc + c.pds, 0);
  }
}
