import { SecondarySkill } from '../../character/model/types/SecondarySkill';
import { Archetype, CategoryTemplate, CategoryType } from './CategoryTemplate';

export class IllusionistCategoryTemplate extends CategoryTemplate {
  name = 'Ilusionista';
  archetypes = [Archetype.Mystic, Archetype.Hunter];
  type = CategoryType.Illusionist;

  readonly lifeMultiplier = 20;
  lifePoints = 5;
  protected turn = 5;
  protected martialKnowledge = 20;
  protected nativePsychicPoints = 1;

  offensiveSkillsLimitPercentage = 0.5;
  attackPDsCost = 3;
  blockPDsCost = 3;
  dodgePDsCost = 2;
  wearArmorPDsCost = 3;
  kiPDsCost = 2;
  kiAccumMultiplierPDsCost = 25;

  magicSkillsLimitPercentage = 0.6;
  zeonPDsCost = 1;
  aCTMultiplierPDsCost = 60;
  magicProjectionPDsCost = 2;
  summonPDsCost = 3;
  controlPDsCost = 3;
  bindPDsCost = 3;
  banishPDsCost = 3;

  psychicSkillsLimitPercentage = 0.5;
  psychicPointsPDsCost = 20;
  psychicProjectionPDsCost = 3;

  athleticSecondariesPDsCost = 2;
  socialSecondariesPDsCost = 2;
  perceptionSecondariesPDsCost = 2;
  intellectualSecondariesPDsCost = 2;
  vigorSecondariesPDsCost = 3;
  subterfugeSecondariesPDsCost = 2;
  creativeSecondariesPDsCost = 2;

  zeonBonus = 75;

  calculateSecondarySkillPDReduction(skill: SecondarySkill): number {
    if (skill === SecondarySkill.SleightOfHand) return 1;
    if (skill === SecondarySkill.Persuasion) return 1;

    return 0;
  }

  calculateSecondarySkillBonusPerCategory(skill: SecondarySkill): number {
    if (skill === SecondarySkill.Stealth) return 10;
    if (skill === SecondarySkill.Hide) return 10;
    if (skill === SecondarySkill.SleightOfHand) return 10;
    if (skill === SecondarySkill.MagicAppraisal) return 5;
    if (skill === SecondarySkill.Disguise) return 5;
    if (skill === SecondarySkill.Steal) return 5;
    if (skill === SecondarySkill.Persuasion) return 5;

    return 0;
  }
}
