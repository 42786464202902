import { observer } from 'mobx-react';
import { useState } from 'react';
import { Tabs } from '../../../../../../../../../shared/application/design-system/components/Tabs/Tabs';
import { FreeAccessSpells } from './components/FreeAccessSpells';
import { KnownSpells } from './components/KwnownSpells/KnownSpells';
import { MagicAccumulation } from './components/MagicAccumulation';
import { MagicLevel } from './components/MagicLevel/MagicLevel';
import { MagicProjection } from './components/MagicProjection';
import { MagicSummoning } from './components/MagicSummoning';
import { MagicSummons } from './components/MagicSummons';
import { MagicZeon } from './components/MagicZeon';
import { Metamagics } from './components/Metamagics';
import { SelectedSpells } from './components/SelectedSpells';
import { Wrapper } from './Mystic.styled';

enum MysticSubTab {
  Principal = 'Principal',
  KnownSpells = 'Conjuros conocidos',
}

enum MysticTypeSubTab {
  Summoning = 'Invocación',
  Spells = 'Conjuros',
  Metamagics = 'Metamagias',
}

export const MysticTab = observer(() => {
  const [subTab, setSubTab] = useState(MysticSubTab.Principal);

  const handleChangeSubTab = (subTab: MysticSubTab) => () => {
    setSubTab(subTab);
  };

  const [subTypeTab, setSubTypeTab] = useState(MysticTypeSubTab.Summoning);

  const handleChangeSubTypeTab = (subTypeTab: MysticTypeSubTab) => () => {
    setSubTypeTab(subTypeTab);
  };

  const SummoningTab = () => (
    <>
      <MagicSummoning />
      <MagicSummons />
    </>
  );

  const SpellsTab = () => (
    <>
      <SelectedSpells />
      <FreeAccessSpells />
    </>
  );

  const MetamagicsTab = () => (
    <>
      <Metamagics />
    </>
  );

  const TypeSubTabComponentMap: Record<MysticTypeSubTab, JSX.Element> = {
    [MysticTypeSubTab.Summoning]: <SummoningTab />,
    [MysticTypeSubTab.Spells]: <SpellsTab />,
    [MysticTypeSubTab.Metamagics]: <MetamagicsTab />,
  };

  const PrincipalTab = () => (
    <>
      <MagicLevel />
      <MagicAccumulation />
      <MagicProjection />
      <MagicZeon />
      {Object.values(MysticTypeSubTab).map((t) => (
        <Tabs.Button
          key={t}
          selected={subTypeTab === t}
          name={t}
          onClick={handleChangeSubTypeTab(t)}
        />
      ))}
      {TypeSubTabComponentMap[subTypeTab]}
    </>
  );

  const KnownSpellsTab = () => (
    <>
      <KnownSpells />
    </>
  );

  const SubTabComponentMap: Record<MysticSubTab, JSX.Element> = {
    [MysticSubTab.Principal]: <PrincipalTab />,
    [MysticSubTab.KnownSpells]: <KnownSpellsTab />,
  };

  return (
    <Wrapper>
      <Tabs centered>
        {Object.values(MysticSubTab).map((t) => (
          <Tabs.Button
            key={t}
            selected={subTab === t}
            name={t}
            onClick={handleChangeSubTab(t)}
          />
        ))}
      </Tabs>

      {SubTabComponentMap[subTab]}
    </Wrapper>
  );
});
