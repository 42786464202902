import { WeaponItemModel } from '../../../../../../../../../../../../../../../../domain/aggregations/weapon/WeaponItemModel';
import { WeaponTypeSelector } from './components/WeaponTypeSelector/WeaponTypeSelector';
import { SelectorRow, Wrapper } from './WeaponTypeList.styled';
import { AddItemButton } from '../../../../../../../../../../common/AddItemButton/AddItemButton';
import { WeaponType } from '../../../../../../../../../../../../../../../../domain/aggregations/weapon/Weapon.types';
import { observer } from 'mobx-react';
import { RemoveItemButton } from '../../../../../../../../../../common/RemoveItemButton/RemoveItemButton';

export const WeaponTypeList = observer(
  ({ weapon }: { weapon: WeaponItemModel }) => {
    const unusedWeaponTypes = Object.values(WeaponType).filter(
      (t) => !weapon.types.includes(t),
    );

    const handleWeaponTypeSelect =
      (oldWeaponType: WeaponType) => (newWeaponType: WeaponType) => {
        weapon.replaceType(oldWeaponType, newWeaponType);
      };

    const handleAddWeaponType = () => {
      weapon.addType(unusedWeaponTypes[0]);
    };

    const handleRemoveWeaponType = (weaponType: WeaponType) => () => {
      weapon.removeType(weaponType);
    };

    return (
      <Wrapper>
        {weapon.types.map((t) => (
          <SelectorRow key={t}>
            <WeaponTypeSelector
              selectedWeaponType={t}
              onSelectWeaponType={handleWeaponTypeSelect(t)}
            />
            {weapon.types.length > 1 ? (
              <RemoveItemButton onClick={handleRemoveWeaponType(t)} />
            ) : undefined}
          </SelectorRow>
        ))}
        {unusedWeaponTypes.length > 0 ? (
          <AddItemButton onClick={handleAddWeaponType} />
        ) : undefined}
      </Wrapper>
    );
  },
);
