import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class GrapplingMartialArt extends BasicMartialArt {
  readonly name = 'Grappling';

  readonly complexity = 1;

  readonly type = MartialArtType.Grappling;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      effects: 'Mitad de penalizador en presa y derribo.',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 10;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.vigor.featsOfStrength.final < 20) {
          errors.push('Proezas de fuerza 20+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      effects: 'Sin de penalizador en presa y derribo.',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 20;
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.vigor.featsOfStrength.final < 40) {
          errors.push('Proezas de fuerza 40+');
        }

        if (character.combat.attackAbility.final < 130) {
          errors.push('Ataque 130+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      effects: 'Puede elegir hacer el daño completo al hacer presa o derribo.',
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.vigor.featsOfStrength.final < 120) {
          errors.push('Proezas de fuerza 120+');
        }

        if (character.combat.attackAbility.final < 200) {
          errors.push('Ataque 200+');
        }

        return errors;
      },
    }),
  ];
}
