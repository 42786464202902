import { MouseEvent, ReactElement, useState } from 'react';
import { Modal, OpenModalOptions } from '../Modal';

export const useModal = () => {
  const [open, setOpen] = useState(false);

  const [openingPromise, setOpeningPromise] =
    useState<() => void | undefined>();

  const [children, setChildren] = useState<ReactElement>(<></>);

  const [openOptions, setOpenOptions] = useState<OpenModalOptions>({});

  const handleOpenModal = (
    children: ReactElement,
    options?: OpenModalOptions,
  ) => {
    setOpenOptions(options || {});
    setChildren(children);
    setOpen(true);

    return new Promise<void>((resolve) => {
      setOpeningPromise(() => resolve);
    });
  };

  const handleCloseModal = (e?: MouseEvent) => {
    e?.stopPropagation?.();

    setChildren(<></>);
    setOpen(false);

    if (openingPromise) {
      openingPromise();
      setOpeningPromise(undefined);
    }
  };

  return {
    openModal: handleOpenModal,
    closeModal: handleCloseModal,
    Modal: () => (
      <Modal open={open} options={openOptions} onCloseClick={handleCloseModal}>
        {children}
      </Modal>
    ),
  };
};
