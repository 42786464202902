import { Authorization } from './components/Authorization/Authorization';
import { PageLayout } from '../../../shared/application/design-system/components/PageLayout/PageLayout';
import { isEmbedded } from './utils/isEmbedded';
import { useSearchParams } from 'react-router-dom';
import { AfterEmbeddedAuthorization } from './components/AfterEmbeddedAuthorization/AfterEmbeddedAuthorization';
import { isChrome, isFirefox } from 'react-device-detect';
import { EmbeddedFirefoxAuthorization } from './components/EmbeddedFirefoxAuthorization/EmbeddedFirefoxAuthorization';
import { EmbeddedAuthorization } from './components/EmbeddedAuthorization/EmbeddedAuthorization';

export const AuthorizationPage = () => {
  const queryParams = useSearchParams();

  if (isChrome) {
    const showAfterEmbedLogin =
      queryParams[0]?.get?.('after-embed-login') === 'true';

    if (showAfterEmbedLogin) {
      return (
        <PageLayout>
          <AfterEmbeddedAuthorization />
        </PageLayout>
      );
    }
  }

  const EmbeddedAuthorizationPage = () => {
    if (isFirefox) {
      return <EmbeddedFirefoxAuthorization />;
    }

    return <EmbeddedAuthorization />;
  };

  return (
    <PageLayout>
      {isEmbedded ? <EmbeddedAuthorizationPage /> : <Authorization />}
    </PageLayout>
  );
};
