import { Character } from '../../../../../../../../../../domain/character/Character';
import { downloadTextAsFile } from './downloadTextAsFile';
import { getSnapshot } from 'mobx-keystone';

export const downloadCharacter = (character: Character) => {
  downloadTextAsFile(
    JSON.stringify(getSnapshot(character.content)),
    `${character.content.name}.cvt`,
  );
};
