export enum BaseRoutes {
  Auth = '/auth',

  MyCharacters = '/my-characters',

  EditCharacter = '/character/edit',
  ViewCharacter = '/character/view',

  CharactersRoom = '/characters-room',

  MyProfile = '/my-profile',

  HowToImport = '/faq/how-to-import',

  News = '/news',
}
