export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Big = 'big',
}

export enum ButtonVariant {
  Normal = 'normal',
  Outline = 'outline',
}
