import { useCallback, useEffect } from 'react';

export const KeyboardShortcut = {
  Save: { ctrlKey: true, keys: ['KeyS'] },
};

export type KeyboardShortcutKeys = keyof typeof KeyboardShortcut;

export type ShortcutEvent = (e: Event) => void;

const ActiveKeys = new Map<KeyboardShortcutKeys, ShortcutEvent>();

export const useKeyboardShortcuts = (
  shortcuts: {
    key: KeyboardShortcutKeys;
    fn: ShortcutEvent;
  }[],
) => {
  const activate = useCallback(() => {
    for (const shortcut of shortcuts) {
      ActiveKeys.set(shortcut.key, shortcut.fn);
    }
  }, [shortcuts]);

  const deactivate = useCallback(() => {
    for (const shortcut of shortcuts) {
      ActiveKeys.delete(shortcut.key);
    }
  }, [shortcuts]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      for (const [key, fn] of ActiveKeys) {
        const shortcut = KeyboardShortcut[key];
        if (shortcut.ctrlKey === e.ctrlKey && shortcut.keys.includes(e.code)) {
          e.preventDefault();
          fn(e);
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  useEffect(() => {
    activate();

    return () => {
      deactivate();
    };
  }, [activate, deactivate]);
};
