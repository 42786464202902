import { CharacterModel } from '../../../../../character/model/CharacterModel';
import { MartialArtType } from '../../../MartialArt';
import { MartialArtGradeId } from '../../../MartialArtGrades';
import { MartialArtModel } from '../../../../../character/model/parts/combat/parts/MartialArtModel';

export const characterKnownMartialArts = (
  character: CharacterModel,
  martialArts: { type: MartialArtType; grade: MartialArtGradeId }[],
): MartialArtModel[] => {
  const knownMartialArts = character.combat.martialArts.filter(
    (martialArt: MartialArtModel) =>
      martialArts.some(
        (ma) =>
          ma.type === martialArt.type && martialArt.containsGrade(ma.grade),
      ),
  );

  return knownMartialArts;
};
