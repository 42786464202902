import { KiType } from '../../character/model/types/KiType';

export type KiTechniqueId = string;

export type KiTechniqueCost = {
  [key in KiType]?: { cost: number; maintainedCost?: number };
};

export type KiTechnique = {
  id: KiTechniqueId;

  name: string;

  type: string;

  mpCost: number;

  kiCost: KiTechniqueCost;

  effect: string;

  level: number;

  notes?: string;
};

export const AllKiTechniques: KiTechnique[] = [
  {
    id: '1',
    name: 'Excisum Aeris',
    type: 'Celéritas',
    level: 1,
    mpCost: 25,
    kiCost: {
      [KiType.Agility]: {
        cost: 4,
      },
      [KiType.Dexterity]: {
        cost: 4,
      },
    },
    effect: 'Ataque a Distancia (50 metros), Incrementar Turno (+50)',
  },
  {
    id: '2',
    name: 'Velocitas Ventus',
    type: 'Celéritas',
    level: 1,
    mpCost: 25,
    kiCost: {
      [KiType.Agility]: {
        cost: 7,
      },
      [KiType.Dexterity]: {
        cost: 7,
      },
    },
    effect: 'Ataque Adicional Limitado (+3), Incrementar Turno (+50)',
  },
  {
    id: '3',
    name: 'Excisum Magister',
    type: 'Celéritas',
    level: 2,
    mpCost: 45,
    kiCost: {
      [KiType.Agility]: {
        cost: 8,
      },
      [KiType.Dexterity]: {
        cost: 8,
      },
    },
    effect: 'Habilidad de Ataque (+75), Incrementar Turno (+125)',
  },
  {
    id: '4',
    name: 'Magnus Exactor',
    type: 'Celéritas',
    level: 2,
    mpCost: 45,
    kiCost: {
      [KiType.Agility]: {
        cost: 7,
      },
      [KiType.Dexterity]: {
        cost: 7,
      },
    },
    effect: 'Ataque con Área (10 metros de radio), Incrementar Turno (+150)',
  },
  {
    id: '5',
    name: 'Summun',
    type: 'Celéritas',
    level: 3,
    mpCost: 85,
    kiCost: {
      [KiType.Agility]: {
        cost: 15,
      },
      [KiType.Dexterity]: {
        cost: 17,
      },
    },
    effect: 'Ataque Adicional Limitado (+8), Incrementar Turno (+175)',
  },
  {
    id: '6',
    name: 'Feuer',
    type: 'Ignis',
    level: 1,
    mpCost: 20,
    kiCost: {
      [KiType.WillPower]: {
        cost: 5,
        maintainedCost: 1,
      },
      [KiType.Power]: {
        cost: 1,
      },
      [KiType.Strength]: {
        cost: 3,
        maintainedCost: 1,
      },
    },
    effect: 'Aumento de Daño (+25, Mantenido), Ataque Elemental (Fuego)',
  },
  {
    id: '7',
    name: 'Le Feu',
    type: 'Ignis',
    level: 1,
    mpCost: 20,
    kiCost: {
      [KiType.WillPower]: {
        cost: 5,
      },
      [KiType.Dexterity]: {
        cost: 3,
      },
      [KiType.Power]: {
        cost: 3,
      },
    },
    effect: 'Habilidad de Ataque (+40), Ataque a Distancia (20 metros)',
  },
  {
    id: '8',
    name: 'Horecka',
    type: 'Ignis',
    level: 2,
    mpCost: 40,
    kiCost: {
      [KiType.WillPower]: {
        cost: 10,
      },
      [KiType.Dexterity]: {
        cost: 6,
      },
      [KiType.Power]: {
        cost: 6,
      },
    },
    effect: 'Multiplicador al Daño (x2), Ataque con Área (50 metros de radio)',
    notes: 'Técnica Mantenida (Feuer)',
  },
  {
    id: '9',
    name: 'Vatra',
    type: 'Ignis',
    level: 2,
    mpCost: 40,
    kiCost: {
      [KiType.WillPower]: {
        cost: 11,
      },
      [KiType.Dexterity]: {
        cost: 6,
      },
      [KiType.Power]: {
        cost: 6,
      },
      [KiType.Strength]: {
        cost: 1,
      },
    },
    effect:
      'Habilidad de Ataque (+75), Aumento de Daño (+75), Potenciar Crítico (+40)',
    notes: 'Técnica Mantenida (Feuer)',
  },
  {
    id: '10',
    name: 'Eld',
    type: 'Ignis',
    level: 3,
    mpCost: 60,
    kiCost: {
      [KiType.WillPower]: {
        cost: 19,
      },
      [KiType.Dexterity]: {
        cost: 10,
      },
    },
    effect:
      'Habilidad de Ataque (+150), Aumento de Daño (Sacrificio Vital Doble)',
    notes: 'Técnica Mantenida (Feuer)',
  },
  {
    id: '11',
    name: 'Las escamas',
    type: 'El Dragón',
    level: 1,
    mpCost: 20,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 3,
      },
      [KiType.Constitution]: {
        cost: 3,
      },
    },
    effect: 'Defensas Adicionales (+6)',
  },
  {
    id: '12',
    name: 'Las garras',
    type: 'El Dragón',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 4,
      },
      [KiType.Constitution]: {
        cost: 4,
      },
      [KiType.Agility]: {
        cost: 4,
      },
    },
    effect: 'Ataque Adicional (+1), Aumento de Daño (+40)',
  },
  {
    id: '13',
    name: 'El colmillo',
    type: 'El Dragón',
    level: 2,
    mpCost: 45,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 5,
      },
      [KiType.Constitution]: {
        cost: 8,
      },
      [KiType.Agility]: {
        cost: 6,
      },
    },
    effect:
      'Destruir Armadura (-6 ta), Maniobras de Combate y Apuntar (-50), Habilidad de Ataque (+50)',
  },
  {
    id: '14',
    name: 'La cola',
    type: 'El Dragón',
    level: 2,
    mpCost: 55,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 6,
      },
      [KiType.Constitution]: {
        cost: 8,
      },
      [KiType.Agility]: {
        cost: 6,
      },
    },
    effect:
      'Habilidad de Ataque (+40), Ataque con Área (25 metros de radio), Impacto (FUE 16)',
  },
  {
    id: '15',
    name: 'El aliento del Dragón',
    type: 'El Dragón',
    level: 3,
    mpCost: 80,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 10,
      },
      [KiType.Constitution]: {
        cost: 10,
      },
      [KiType.Agility]: {
        cost: 10,
      },
      [KiType.Power]: {
        cost: 6,
      },
    },
    effect:
      'Ataque a Distancia (1 kilómetro), Ataque con Área (100 metros de radio), Ataque capaz de Dañar Energía, Multiplicador al Daño (x2)',
    notes: 'Predeterminación',
  },
  {
    id: '16',
    name: 'Primera Cadena: Volgarath',
    type: 'Las Cadenas',
    level: 1,
    mpCost: 35,
    kiCost: {
      [KiType.Strength]: {
        cost: 5,
      },
      [KiType.Dexterity]: {
        cost: 5,
      },
    },
    effect:
      'Habilidad de Ataque (+25), Aumento de Daño (+40), Ataque a Distancia (100 metros)',
  },
  {
    id: '17',
    name: 'Segunda Cadena: Exelion',
    type: 'Las Cadenas',
    level: 1,
    mpCost: 50,
    kiCost: {
      [KiType.Strength]: {
        cost: 5,
      },
      [KiType.Dexterity]: {
        cost: 6,
      },
    },
    effect:
      'Aumento de Daño (+50), Ataque con Área (10 metros de radio), Ataque a Distancia (100 metros)',
  },
  {
    id: '18',
    name: 'Tercera Cadena: Dedalus',
    type: 'Las Cadenas',
    level: 2,
    mpCost: 60,
    kiCost: {
      [KiType.Strength]: {
        cost: 8,
      },
      [KiType.Dexterity]: {
        cost: 8,
      },
    },
    effect:
      'Habilidad de Ataque (+50), Apresamiento (FUE 14), Ataque a Distancia (100 metros)',
  },
  {
    id: '19',
    name: 'Cuarta Cadena: Oberon',
    type: 'Las Cadenas',
    level: 2,
    mpCost: 70,
    kiCost: {
      [KiType.Strength]: {
        cost: 10,
      },
      [KiType.Dexterity]: {
        cost: 11,
      },
    },
    effect:
      'Habilidad de Parada (+40), Escudo de Energía (1.000 PV), Reflectar el Ataque',
  },
  {
    id: '20',
    name: 'Quinta Cadena: Garuda',
    type: 'Las Cadenas',
    level: 3,
    mpCost: 90,
    kiCost: {
      [KiType.Strength]: {
        cost: 9,
      },
      [KiType.Dexterity]: {
        cost: 12,
      },
      [KiType.Power]: {
        cost: 12,
      },
      [KiType.WillPower]: {
        cost: 6,
      },
    },
    effect:
      'Ataque Adicional (+4), Destruir Armadura (-6 ta), Ataque a Distancia (100 metros)',
  },
  {
    id: '21',
    name: 'Itami',
    type: 'Sirius',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 4,
      },
      [KiType.Power]: {
        cost: 4,
      },
    },
    effect:
      'Habilidad de Ataque (+40), Estados Sobrenaturales (RF 100, Estado añadido: Pen. a la Acción Menor)',
    notes: 'Daño reducido (Ningún Daño)',
  },
  {
    id: '22',
    name: 'Hakai',
    type: 'Sirius',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 6,
      },
      [KiType.Power]: {
        cost: 6,
      },
    },
    effect:
      'Habilidad de Ataque (+40), Estados Sobrenaturales (RF 120, Estado añadido: Daño)',
  },
  {
    id: '23',
    name: 'Tomeru',
    type: 'Sirius',
    level: 2,
    mpCost: 40,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 8,
      },
      [KiType.Power]: {
        cost: 8,
      },
    },
    effect:
      'Habilidad de Ataque (+50), Estados Sobrenaturales (RF 140, Estado añadido: Paralización Total)',
    notes: 'Daño reducido (Ningún Daño)',
  },
  {
    id: '24',
    name: 'Yowai',
    type: 'Sirius',
    level: 2,
    mpCost: 40,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 8,
      },
      [KiType.Power]: {
        cost: 7,
      },
    },
    effect:
      'Habilidad de Ataque (+50), Estados Sobrenaturales (RF 180, Estado añadido: Reducción de RF)',
    notes: 'Daño reducido (Ningún Daño)',
  },
  {
    id: '25',
    name: 'Korosu',
    type: 'Sirius',
    level: 3,
    mpCost: 70,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 11,
      },
      [KiType.Power]: {
        cost: 11,
      },
    },
    effect:
      'Habilidad de Ataque (+75), Estados Sobrenaturales (RF 140, Estado añadido: Muerte)',
    notes: 'Daño reducido (Ningún Daño)',
  },
  {
    id: '26',
    name: 'Escudo del alba',
    type: 'Basis',
    level: 1,
    mpCost: 25,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 4,
      },
      [KiType.Strength]: {
        cost: 5,
      },
    },
    effect: 'Habilidad de Parada (+40), Armadura (TA 4)',
  },
  {
    id: '27',
    name: 'Tajo carmesí',
    type: 'Basis',
    level: 1,
    mpCost: 20,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 4,
      },
      [KiType.Strength]: {
        cost: 4,
      },
    },
    effect: 'Habilidad de Ataque (+40), Destruir Armadura (-4 ta)',
  },
  {
    id: '28',
    name: 'Destello Final',
    type: 'Basis',
    level: 2,
    mpCost: 50,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 5,
      },
      [KiType.Strength]: {
        cost: 6,
      },
      [KiType.Constitution]: {
        cost: 6,
      },
    },
    effect:
      'Habilidad de Ataque (+50), Aumento de Daño (+50), Ataque con Área (25 metros de radio)',
  },
  {
    id: '29',
    name: 'Implosión',
    type: 'Basis',
    level: 2,
    mpCost: 50,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 6,
      },
      [KiType.Strength]: {
        cost: 6,
      },
    },
    effect: 'Multiplicador al Daño (x3)',
  },
  {
    id: '30',
    name: 'El latido del trueno',
    type: 'Basis',
    level: 3,
    mpCost: 60,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 12,
      },
      [KiType.Strength]: {
        cost: 11,
      },
    },
    effect:
      'Habilidad de Parada (+90), Habilidad de Contraataque (+75), Aumento de Daño (+75)',
  },
  {
    id: '31',
    name: 'Libera Me',
    type: 'Ormus',
    level: 1,
    mpCost: 45,
    kiCost: {
      [KiType.Agility]: {
        cost: 4,
      },
      [KiType.Dexterity]: {
        cost: 5,
      },
      [KiType.Strength]: {
        cost: 5,
      },
      [KiType.Power]: {
        cost: 5,
      },
    },
    effect:
      'Ataque Indirecto (Nv 1), Camuflar Ataque (Muy Difícil (140)), Aumento de Daño (+25, Sacrificio Vital)',
  },
  {
    id: '32',
    name: 'Sanctus',
    type: 'Ormus',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Agility]: {
        cost: 4,
      },
      [KiType.Dexterity]: {
        cost: 5,
      },
      [KiType.Strength]: {
        cost: 5,
      },
    },
    effect:
      'Escudo de Energía (400 PV), Defensa Predeterminada (Absurdo (180))',
  },
  {
    id: '33',
    name: 'Kyrie eleison',
    type: 'Ormus',
    level: 2,
    mpCost: 75,
    kiCost: {
      [KiType.Agility]: {
        cost: 9,
      },
      [KiType.Dexterity]: {
        cost: 9,
      },
      [KiType.Strength]: {
        cost: 9,
      },
    },
    effect:
      'Maniobras de Combate y Apuntar (-75), Potenciar Crítico (+125, Crítico Automático)',
  },
  {
    id: '34',
    name: 'Dies Irae',
    type: 'Ormus',
    level: 2,
    mpCost: 55,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 8,
      },
      [KiType.Power]: {
        cost: 8,
      },
      [KiType.WillPower]: {
        cost: 5,
      },
    },
    effect:
      'Ataque Elemental (Luz), Ataque Sobrenatural, Ataque con Área (50 metros de radio), Habilidad de Ataque (+50)',
  },
  {
    id: '35',
    name: 'Réquiem',
    type: 'Ormus',
    level: 3,
    mpCost: 110,
    kiCost: {
      [KiType.Agility]: {
        cost: 10,
      },
      [KiType.Constitution]: {
        cost: 5,
      },
      [KiType.Dexterity]: {
        cost: 10,
      },
      [KiType.Strength]: {
        cost: 10,
      },
      [KiType.Power]: {
        cost: 10,
      },
    },
    effect:
      'Ataque Adicional Limitado (+5, Ataque Continuo), Multiplicador al Daño (x2), Ataque Elemental (Luz)',
  },
  {
    id: '36',
    name: 'Yuki',
    type: 'Hyousetsu Jigoku',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Agility]: {
        cost: 5,
        maintainedCost: 1,
      },
      [KiType.Dexterity]: {
        cost: 5,
        maintainedCost: 1,
      },
      [KiType.Power]: {
        cost: 6,
        maintainedCost: 2,
      },
    },
    effect: 'Habilidad de Esquiva (+75, Mantenido)',
  },
  {
    id: '37',
    name: 'Samui',
    type: 'Hyousetsu Jigoku',
    level: 1,
    mpCost: 20,
    kiCost: {
      [KiType.Agility]: {
        cost: 3,
        maintainedCost: 1,
      },
      [KiType.Dexterity]: {
        cost: 3,
        maintainedCost: 1,
      },
      [KiType.Power]: {
        cost: 4,
        maintainedCost: 1,
      },
    },
    effect: 'Habilidad de Contraataque (+40, Mantenido)',
  },
  {
    id: '38',
    name: 'Kagami',
    type: 'Hyousetsu Jigoku',
    level: 2,
    mpCost: 65,
    kiCost: {
      [KiType.Agility]: {
        cost: 11,
      },
      [KiType.Dexterity]: {
        cost: 11,
      },
    },
    effect:
      'Reflectar el Ataque (Reflectar Habilidades Esotéricas), Defensa Predeterminada (Casi Imposible (240))',
  },
  {
    id: '39',
    name: 'Kagami No Kakera',
    type: 'Hyousetsu Jigoku',
    level: 2,
    mpCost: 70,
    kiCost: {
      [KiType.Agility]: {
        cost: 10,
      },
      [KiType.Dexterity]: {
        cost: 10,
      },
    },
    effect: 'Ataque Adicional Limitado (+5, Sostenimiento Menor)',
  },
  {
    id: '40',
    name: 'Shitsunen No Sekai',
    type: 'Hyousetsu Jigoku',
    level: 3,
    mpCost: 115,
    kiCost: {
      [KiType.Agility]: {
        cost: 24,
      },
      [KiType.Dexterity]: {
        cost: 24,
      },
    },
    effect:
      'Estados Sobrenaturales (RF 180, Estado añadido: Paralización Total, Distancia: 50 metros, Condición: Por el Aire)',
    notes: 'Predeterminación',
  },
  {
    id: '41',
    name: 'Ether',
    type: 'Hypnos Requiem',
    level: 1,
    mpCost: 25,
    kiCost: {
      [KiType.Power]: {
        cost: 4,
        maintainedCost: 1,
      },
      [KiType.WillPower]: {
        cost: 4,
        maintainedCost: 1,
      },
    },
    effect: 'Intangibilidad',
    notes: 'Combinable,Atadura Elemental (Oscuridad)',
  },
  {
    id: '42',
    name: 'El Paso De La Vigilia',
    type: 'Hypnos Requiem',
    level: 1,
    mpCost: 25,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 5,
        maintainedCost: 1,
      },
      [KiType.Power]: {
        cost: 5,
        maintainedCost: 1,
      },
    },
    effect: 'Transporte Automático (20 metros, Mantenido)',
    notes: 'Combinable,Atadura Elemental (Oscuridad)',
  },
  {
    id: '43',
    name: 'Marca Espectral',
    type: 'Hypnos Requiem',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Power]: {
        cost: 6,
      },
      [KiType.WillPower]: {
        cost: 6,
      },
    },
    effect:
      'Marca (Menor), Ataque Sobrenatural, Ataque con Área (10 metros de radio)',
    notes: 'Atadura Elemental (Oscuridad)',
  },
  {
    id: '44',
    name: 'La Sombra De La Muerte',
    type: 'Hypnos Requiem',
    level: 2,
    mpCost: 60,
    kiCost: {
      [KiType.Power]: {
        cost: 12,
      },
      [KiType.WillPower]: {
        cost: 12,
      },
    },
    effect:
      'Estados Sobrenaturales (RF 160, Estado añadido: Drenaje de Ki), Ataque Indirecto (Nv 2)',
    notes: 'Atadura Elemental (Oscuridad)',
  },
  {
    id: '45',
    name: 'Horda Espectral',
    type: 'Hypnos Requiem',
    level: 2,
    mpCost: 45,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 10,
        maintainedCost: 4,
      },
      [KiType.Power]: {
        cost: 10,
        maintainedCost: 4,
      },
      [KiType.WillPower]: {
        cost: 10,
        maintainedCost: 5,
      },
    },
    effect:
      'Espejismo (10 Imágenes, RP 180, Indetección Inhumano (320), Mantenido)',
    notes: 'Atadura Elemental (Oscuridad), Sacrificio de Características (CON)',
  },
  {
    id: '46',
    name: 'La Última Pesadilla',
    type: 'Hypnos Requiem',
    level: 2,
    mpCost: 80,
    kiCost: {
      [KiType.Power]: {
        cost: 17,
      },
      [KiType.WillPower]: {
        cost: 17,
      },
    },
    effect:
      'Estados Sobrenaturales (RF 160, Estado añadido: Terror, Estado añadido: Dolor extremo, Condición: Contacto Visual (Un Blanco), Distancia: 25 metros)',
    notes: 'Atadura Elemental (Oscuridad), Condición (Nocturno)',
  },
  {
    id: '47',
    name: 'Le Tige, El Tallo',
    type: 'Le Rose Du Sang',
    level: 1,
    mpCost: 20,
    kiCost: {
      [KiType.Agility]: {
        cost: 3,
        maintainedCost: 1,
      },
      [KiType.Dexterity]: {
        cost: 3,
        maintainedCost: 1,
      },
      [KiType.Constitution]: {
        cost: 3,
        maintainedCost: 1,
      },
    },
    effect: 'Defensas Adicionales (+3, Mantenido)',
  },
  {
    id: '48',
    name: 'Les Feuilles, Las Hojas',
    type: 'Le Rose Du Sang',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Agility]: {
        cost: 3,
        maintainedCost: 1,
      },
      [KiType.Constitution]: {
        cost: 3,
        maintainedCost: 1,
      },
    },
    effect: 'Armadura (TA 3, Mantenido)',
  },
  {
    id: '49',
    name: 'Le Pétale, El Pétalo',
    type: 'Le Rose Du Sang',
    level: 2,
    mpCost: 40,
    kiCost: {
      [KiType.Agility]: {
        cost: 10,
        maintainedCost: 2,
      },
      [KiType.Dexterity]: {
        cost: 10,
        maintainedCost: 2,
      },
      [KiType.Power]: {
        cost: 10,
        maintainedCost: 2,
      },
      [KiType.Constitution]: {
        cost: 10,
        maintainedCost: 2,
      },
    },
    effect: 'Escudo de Energía (1.000 PV, Mantenido)',
    notes: 'Circunstancia Límite (Recibir Daño)',
  },
  {
    id: '50',
    name: 'Les Epines, Las Espinas',
    type: 'Le Rose Du Sang',
    level: 2,
    mpCost: 55,
    kiCost: {
      [KiType.Agility]: {
        cost: 4,
        maintainedCost: 1,
      },
      [KiType.Constitution]: {
        cost: 4,
        maintainedCost: 1,
      },
      [KiType.Dexterity]: {
        cost: 4,
        maintainedCost: 1,
      },
    },
    effect: 'Ataque Adicional Limitado (+3)',
    notes: 'Daño reducido (Mitad de Daño)',
  },
  {
    id: '51',
    name: 'Mille Pétales De Rose, Mil Pétalos De Rosa',
    type: 'Le Rose Du Sang',
    level: 3,
    mpCost: 90,
    kiCost: {
      [KiType.Agility]: {
        cost: 12,
      },
      [KiType.Constitution]: {
        cost: 12,
      },
      [KiType.Dexterity]: {
        cost: 8,
      },
    },
    effect:
      'Ataque con Área (100 metros de radio, Elección de Blanco), Ataque Adicional (+2), Ataque Indirecto (Nv 3)',
    notes: 'Técnica Mantenida (Le Pétale)',
  },
  {
    id: '52',
    name: 'Shuryudo, El Camino De Las Corrientes',
    type: 'Arashido, El Camino De Las Tormentas',
    level: 1,
    mpCost: 25,
    kiCost: {
      [KiType.Agility]: {
        cost: 6,
        maintainedCost: 2,
      },
    },
    effect: 'Incrementar Turno (+75, Mantenido)',
  },
  {
    id: '53',
    name: 'Kazedo, El Camino Del Viento',
    type: 'Arashido, El Camino De Las Tormentas',
    level: 1,
    mpCost: 40,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 5,
        maintainedCost: 1,
      },
      [KiType.Constitution]: {
        cost: 4,
        maintainedCost: 1,
      },
      [KiType.Strength]: {
        cost: 5,
        maintainedCost: 1,
      },
      [KiType.Power]: {
        cost: 5,
        maintainedCost: 1,
      },
      [KiType.WillPower]: {
        cost: 4,
        maintainedCost: 1,
      },
    },
    effect:
      'Ataque a Distancia (5 metros, Mantenido), Multiplicador al Daño (x2, Mantenido)',
  },
  {
    id: '54',
    name: 'Denkido, El Camino De La Electricidad',
    type: 'Arashido, El Camino De Las Tormentas',
    level: 2,
    mpCost: 75,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 7,
      },
      [KiType.Constitution]: {
        cost: 7,
      },
      [KiType.Strength]: {
        cost: 7,
      },
      [KiType.Power]: {
        cost: 7,
      },
    },
    effect:
      'Ataque con Área (50 metros de radio, Elección de Blanco), Ataque Elemental (Aire), Multiplicador al Daño (x3)',
    notes: 'Penalizador A Toda Acción (-75)',
  },
  {
    id: '55',
    name: 'Tatsumakido, El Camino Del Tornado',
    type: 'Arashido, El Camino De Las Tormentas',
    level: 2,
    mpCost: 75,
    kiCost: {
      [KiType.Agility]: {
        cost: 9,
      },
      [KiType.Constitution]: {
        cost: 8,
      },
      [KiType.Dexterity]: {
        cost: 9,
      },
      [KiType.Strength]: {
        cost: 9,
      },
    },
    effect:
      'Escudo de Energía (500 PV, Regeneración 100, Sostenimiento Menor), Habilidad de esquiva (+75)',
    notes: 'Circunstancia Límite (Recibir Daño)',
  },
  {
    id: '56',
    name: 'Raikoudo, El Camino Del Rayo',
    type: 'Arashido, El Camino De Las Tormentas',
    level: 3,
    mpCost: 95,
    kiCost: {
      [KiType.Power]: {
        cost: 8,
      },
      [KiType.WillPower]: {
        cost: 8,
      },
      [KiType.Constitution]: {
        cost: 8,
      },
      [KiType.Dexterity]: {
        cost: 9,
      },
      [KiType.Strength]: {
        cost: 9,
      },
    },
    effect:
      'Habilidad de Ataque (+50), Ataque a Distancia (Proyección, Estela de destrucción), Ataque Elemental (Aire), Interrupción (RF = Daño, Interrumpe Ki, Interrumpe Magia, Interrumpe Psíquica)',
    notes: 'Sobrecarga (Espera de 20 asaltos), Sin Defensa',
  },
  {
    id: '57',
    name: 'Yue',
    type: 'Di Quiu',
    level: 1,
    mpCost: 35,
    kiCost: {
      [KiType.Power]: {
        cost: 2,
      },
      [KiType.WillPower]: {
        cost: 2,
      },
    },
    effect: 'Previsión (Completo)',
    notes: 'Condición (Terreno determinado: Contacto con la tierra)',
  },
  {
    id: '58',
    name: 'Shi Tou',
    type: 'Di Quiu',
    level: 1,
    mpCost: 50,
    kiCost: {
      [KiType.Power]: {
        cost: 4,
        maintainedCost: 1,
      },
      [KiType.Strength]: {
        cost: 4,
        maintainedCost: 1,
      },
      [KiType.WillPower]: {
        cost: 4,
        maintainedCost: 1,
      },
      [KiType.Constitution]: {
        cost: 5,
        maintainedCost: 1,
      },
    },
    effect: 'Armadura (TA 4, Inmodificable, Lentitud)',
  },
  {
    id: '59',
    name: 'Yan',
    type: 'Di Quiu',
    level: 2,
    mpCost: 40,
    kiCost: {
      [KiType.Constitution]: {
        cost: 6,
        maintainedCost: 2,
      },
      [KiType.WillPower]: {
        cost: 6,
        maintainedCost: 2,
      },
      [KiType.Power]: {
        cost: 6,
        maintainedCost: 2,
      },
    },
    effect: 'Acumulación (600 PV, Regeneración 250)',
    notes: 'Predeterminación',
  },
  {
    id: '60',
    name: 'Jin Shu',
    type: 'Di Quiu',
    level: 2,
    mpCost: 65,
    kiCost: {
      [KiType.Power]: {
        cost: 8,
      },
      [KiType.WillPower]: {
        cost: 9,
        maintainedCost: 3,
      },
      [KiType.Strength]: {
        cost: 9,
        maintainedCost: 4,
      },
    },
    effect:
      'Ataque con área (25 metros de radio, Elección de Blanco), Apresamiento (FUE 16)',
    notes: 'Daño reducido (Ningún Daño), Agotamiento (-2 Cansancio)',
  },
  {
    id: '61',
    name: 'Guan Shi',
    type: 'Di Quiu',
    level: 2,
    mpCost: 60,
    kiCost: {
      [KiType.Power]: {
        cost: 8,
      },
      [KiType.WillPower]: {
        cost: 8,
      },
    },
    effect:
      'Ataque con área (25 metros de radio, Elección de Blanco), Potenciar Crítico (+50, Crítico Automático)',
    notes: 'Técnica Mantenida (Jin Shu), Circunstancia de Combate (Apresado)',
  },
  {
    id: '62',
    name: 'Ren Zhi Shu, El Pilar Del Mundo',
    type: 'Di Quiu',
    level: 3,
    mpCost: 90,
    kiCost: {
      [KiType.Power]: {
        cost: 16,
      },
      [KiType.WillPower]: {
        cost: 17,
      },
    },
    effect:
      'Ataque con Área (50 metros de radio), Aumento de Daño (+100), Ataque indirecto, Destruir Armadura (-8 TA)',
    notes: 'Sobrecarga (Espera de 20 asaltos), Agotamiento (-4 Cansancio)',
  },
  {
    id: '63',
    name: 'Oculus',
    type: 'Obscuritas',
    level: 1,
    mpCost: 20,
    kiCost: {
      [KiType.Agility]: {
        cost: 3,
      },
      [KiType.Dexterity]: {
        cost: 3,
      },
    },
    effect: 'Maniobras de Combate y Apuntar (-50)',
    notes: 'Combinable',
  },
  {
    id: '64',
    name: 'Sensus',
    type: 'Obscuritas',
    level: 1,
    mpCost: 20,
    kiCost: {
      [KiType.Agility]: {
        cost: 3,
        maintainedCost: 1,
      },
    },
    effect: 'Incrementar Turno (+50, Mantenido)',
  },
  {
    id: '65',
    name: 'Luctus',
    type: 'Obscuritas',
    level: 1,
    mpCost: 40,
    kiCost: {
      [KiType.Power]: {
        cost: 4,
      },
      [KiType.WillPower]: {
        cost: 4,
      },
      [KiType.Strength]: {
        cost: 4,
      },
      [KiType.Dexterity]: {
        cost: 4,
      },
    },
    effect: 'Potenciar Crítico (+75)',
    notes: 'Combinable',
  },
  {
    id: '66',
    name: 'Ego',
    type: 'Obscuritas',
    level: 2,
    mpCost: 40,
    kiCost: {
      [KiType.WillPower]: {
        cost: 5,
      },
      [KiType.Power]: {
        cost: 5,
      },
    },
    effect: 'Espejismo (Indetección Inhumano (320), Ilusión fantasmal rp 180)',
  },
  {
    id: '67',
    name: 'Obitus',
    type: 'Obscuritas',
    level: 2,
    mpCost: 45,
    kiCost: {
      [KiType.Agility]: {
        cost: 5,
      },
      [KiType.Dexterity]: {
        cost: 5,
      },
      [KiType.WillPower]: {
        cost: 6,
      },
    },
    effect: 'Camuflar Ataque (Zen (440))',
    notes: 'Combinable,Sin defensa, Exceso de energía',
  },
  {
    id: '68',
    name: 'Apertura Espacial',
    type: 'Cosmos',
    level: 1,
    mpCost: 35,
    kiCost: {
      [KiType.Agility]: {
        cost: 7,
        maintainedCost: 2,
      },
      [KiType.Dexterity]: {
        cost: 6,
        maintainedCost: 1,
      },
      [KiType.Power]: {
        cost: 7,
        maintainedCost: 2,
      },
    },
    effect:
      'Ataque adicional (+1), Ataque a Distancia (20 metros), Ataque indirecto',
    notes: 'Condición (Terreno Determinado: Terreno abierto)',
  },
  {
    id: '69',
    name: 'Viento Estelar',
    type: 'Cosmos',
    level: 1,
    mpCost: 45,
    kiCost: {
      [KiType.Agility]: {
        cost: 7,
        maintainedCost: 3,
      },
      [KiType.Dexterity]: {
        cost: 6,
        maintainedCost: 1,
      },
      [KiType.Power]: {
        cost: 7,
        maintainedCost: 2,
      },
    },
    effect:
      'Habilidad de Esquiva limitada (+75), Transporte Automático (50 metros)',
  },
  {
    id: '70',
    name: 'El Portal Oscuro',
    type: 'Cosmos',
    level: 2,
    mpCost: 75,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 13,
      },
      [KiType.Power]: {
        cost: 14,
      },
      [KiType.WillPower]: {
        cost: 7,
      },
      [KiType.Constitution]: {
        cost: 7,
      },
    },
    effect:
      'Estados Sobrenaturales (Estado añadido: Drenaje de vida, Condición: Por superficie, Distancia: 10 metros, RF 140)',
  },
  {
    id: '71',
    name: 'Armageddon',
    type: 'Cosmos',
    level: 2,
    mpCost: 70,
    kiCost: {
      [KiType.Agility]: {
        cost: 11,
      },
      [KiType.Dexterity]: {
        cost: 11,
      },
    },
    effect:
      'Ataque con área (100 metros de radio), Aumento de Daño (+50), Habilidad de Ataque (+90)',
    notes: 'Técnica Mantenida (Apertura Espacial)',
  },
  {
    id: '72',
    name: 'Supernova',
    type: 'Cosmos',
    level: 3,
    mpCost: 105,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 15,
      },
      [KiType.Agility]: {
        cost: 15,
      },
      [KiType.Power]: {
        cost: 15,
      },
      [KiType.Strength]: {
        cost: 7,
      },
    },
    effect:
      'Habilidad de Ataque (+100), Estados Sobrenaturales (RF 200, Estado añadido: Doble daño)',
    notes: 'Sobrecarga (Espera de 20 asaltos), Sacrificio (-25 PV)',
  },
  {
    id: '73',
    name: 'Onibi, Fuego Fantasmal',
    type: 'Kagami',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 6,
      },
      [KiType.WillPower]: {
        cost: 5,
      },
    },
    effect:
      'Habilidad de Ataque (+40), Ataque a Distancia (50 metros), Ataque elemental (Fuego)',
  },
  {
    id: '74',
    name: 'Kawarimi, Intercambio',
    type: 'Kagami',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 6,
      },
      [KiType.WillPower]: {
        cost: 5,
      },
    },
    effect: 'Habilidad de esquiva (+75), Transporte Automático (20 metros)',
  },
  {
    id: '75',
    name: 'Genmai, Danza De Las Ilusiones',
    type: 'Kagami',
    level: 2,
    mpCost: 70,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 8,
      },
      [KiType.WillPower]: {
        cost: 8,
      },
    },
    effect:
      'Espejismo (4 imágenes, Indetección Inhumano (320), Ilusión Fantasmal RP 180), Ataque adicional limitado (+2)',
  },
  {
    id: '76',
    name: 'Maboroshi, El Sueño',
    type: 'Kagami',
    level: 2,
    mpCost: 60,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 12,
      },
      [KiType.WillPower]: {
        cost: 13,
      },
    },
    effect:
      'Habilidad de Ataque (+75), Estados Sobrenaturales (RF 160, Estado añadido: Ilusión mayor)',
    notes: 'Daño reducido (Ningún daño)',
  },
  {
    id: '77',
    name: 'Genzou No Sekai, Mundo De Las Ilusiones',
    type: 'Kagami',
    level: 3,
    mpCost: 115,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 19,
      },
      [KiType.WillPower]: {
        cost: 20,
      },
    },
    effect:
      'Estados Sobrenaturales (RF 160, Estado añadido: Ilusión Fantasmal, Condición: por el Aire, Distancia: 25 metros)',
  },
  {
    id: '78',
    name: 'Spiritum',
    type: 'Magus Abstergo',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Power]: {
        cost: 4,
        maintainedCost: 2,
      },
      [KiType.WillPower]: {
        cost: 4,
        maintainedCost: 2,
      },
    },
    effect: 'Incremento de Resistencia Mágica (+40 RM)',
  },
  {
    id: '79',
    name: 'Necis',
    type: 'Magus Abstergo',
    level: 1,
    mpCost: 25,
    kiCost: {
      [KiType.Power]: {
        cost: 7,
      },
      [KiType.WillPower]: {
        cost: 7,
      },
    },
    effect: 'Habilidad de ataque (+75), Aumento de daño (+50)',
    notes: 'Ataque especializado (Parada)',
  },
  {
    id: '80',
    name: 'Patrocinium',
    type: 'Magus Abstergo',
    level: 2,
    mpCost: 70,
    kiCost: {
      [KiType.Power]: {
        cost: 7,
      },
      [KiType.WillPower]: {
        cost: 7,
      },
    },
    effect: 'Armadura (TA 6)',
    notes: 'Defensa especializada (Conjuros)',
  },
  {
    id: '81',
    name: 'Aeternum',
    type: 'Magus Abstergo',
    level: 2,
    mpCost: 65,
    kiCost: {
      [KiType.Power]: {
        cost: 8,
        maintainedCost: 2,
      },
      [KiType.WillPower]: {
        cost: 8,
        maintainedCost: 2,
      },
      [KiType.Constitution]: {
        cost: 8,
        maintainedCost: 2,
      },
    },
    effect: 'Habilidad de Parada (+75), Defensas Adicionales (+2)',
    notes: 'Defensa especializada (Conjuros)',
  },
  {
    id: '82',
    name: 'Expello',
    type: 'Magus Abstergo',
    level: 2,
    mpCost: 100,
    kiCost: {
      [KiType.Power]: {
        cost: 16,
      },
      [KiType.WillPower]: {
        cost: 16,
      },
      [KiType.Constitution]: {
        cost: 14,
      },
      [KiType.Dexterity]: {
        cost: 5,
      },
    },
    effect:
      'Habilidad de Ataque (+90), Interrupción (RF = Daño +120, Interrumpe magia), Aumento de Daño (+100)',
  },
  {
    id: '83',
    name: 'Gunslinger',
    type: 'Gunhell',
    level: 1,
    mpCost: 35,
    kiCost: {
      [KiType.Power]: {
        cost: 8,
        maintainedCost: 2,
      },
      [KiType.Dexterity]: {
        cost: 8,
        maintainedCost: 2,
      },
    },
    effect: 'Armas físicas de ki (+10, Arma de proyectil, 1 arma adicional)',
  },
  {
    id: '84',
    name: 'Barrel Shot',
    type: 'Gunhell',
    level: 1,
    mpCost: 45,
    kiCost: {
      [KiType.Power]: {
        cost: 6,
      },
      [KiType.Dexterity]: {
        cost: 6,
      },
    },
    effect: 'Ataque adicional limitado (+5)',
    notes: 'Técnica mantenida (Gunslinger)',
  },
  {
    id: '85',
    name: 'Bounce Shot',
    type: 'Gunhell',
    level: 1,
    mpCost: 25,
    kiCost: {
      [KiType.Power]: {
        cost: 4,
      },
      [KiType.Dexterity]: {
        cost: 4,
      },
    },
    effect: 'Ataque indirecto, Camuflar Ataque (Casi Imposible (240))',
    notes: 'Técnica mantenida (Gunslinger)',
  },
  {
    id: '86',
    name: 'Bullet Time',
    type: 'Gunhell',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Power]: {
        cost: 4,
      },
      [KiType.Dexterity]: {
        cost: 4,
      },
    },
    effect: 'Habilidad de esquiva completa (+40)',
    notes: 'Defensa especializada (Proyectiles)',
  },
  {
    id: '87',
    name: 'Perfect Shot',
    type: 'Gunhell',
    level: 2,
    mpCost: 85,
    kiCost: {
      [KiType.Power]: {
        cost: 6,
      },
      [KiType.Dexterity]: {
        cost: 6,
      },
    },
    effect: 'Habilidad de Ataque (+75), Marca (Mayor)',
    notes: 'Técnica mantenida (Gunslinger)',
  },
  {
    id: '88',
    name: 'Sniper Shot',
    type: 'Gunhell',
    level: 2,
    mpCost: 65,
    kiCost: {
      [KiType.Power]: {
        cost: 10,
      },
      [KiType.Dexterity]: {
        cost: 10,
      },
      [KiType.Agility]: {
        cost: 10,
      },
    },
    effect:
      'Maniobras de Combate y Apuntar (-50), Ataque a Distancia (1 kilómetro), Multiplicador al Daño (x2)',
    notes: 'Técnica mantenida (Gunslinger), Predeterminación',
  },
  {
    id: '89',
    name: 'Storm Waltz',
    type: 'Gunhell',
    level: 3,
    mpCost: 90,
    kiCost: {
      [KiType.Power]: {
        cost: 14,
      },
      [KiType.Dexterity]: {
        cost: 14,
      },
      [KiType.Agility]: {
        cost: 14,
      },
      [KiType.Constitution]: {
        cost: 4,
      },
    },
    effect:
      'Habilidad de ataque (+150), Ataque con área (100 metros de radio, Elección de Blanco), Ataque sobrenatural',
    notes:
      'Técnica mantenida (Gunslinger), Sin defensa, Requerimiento (Piezas metálicas)',
  },
  {
    id: '90',
    name: 'Bhakti',
    type: 'Sattyagraha',
    level: 1,
    mpCost: 20,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 5,
      },
      [KiType.Agility]: {
        cost: 5,
      },
    },
    effect: 'Habilidad de Parada limitada (+75), Defensas Adicionales (+2)',
  },
  {
    id: '91',
    name: 'Ajna',
    type: 'Sattyagraha',
    level: 1,
    mpCost: 35,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 7,
        maintainedCost: 2,
      },
      [KiType.Agility]: {
        cost: 7,
        maintainedCost: 1,
      },
      [KiType.Power]: {
        cost: 7,
        maintainedCost: 1,
      },
    },
    effect: 'Habilidades perceptivas (Visión radial, Ver lo sobrenatural)',
  },
  {
    id: '92',
    name: 'Anahata',
    type: 'Sattyagraha',
    level: 1,
    mpCost: 45,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 5,
      },
      [KiType.Agility]: {
        cost: 5,
      },
    },
    effect:
      'Parada en área (25 metros de radio), Habilidad de parada limitada (+90)',
  },
  {
    id: '93',
    name: 'Manipura',
    type: 'Sattyagraha',
    level: 2,
    mpCost: 55,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 15,
      },
      [KiType.Agility]: {
        cost: 14,
      },
    },
    effect:
      'Habilidad de ataque (+75), Estados Sobrenaturales (RF 160, Estado añadido: Paralización Total)',
    notes: 'Daño reducido (Ningún daño)',
  },
  {
    id: '94',
    name: 'Muladhara',
    type: 'Sattyagraha',
    level: 2,
    mpCost: 90,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 9,
      },
      [KiType.Agility]: {
        cost: 9,
      },
    },
    effect:
      'Habilidad de Contraataque (+90), Maniobras de Combate y Apuntar (-50), Capacidad incrementada DES (+6)',
  },
  {
    id: '95',
    name: 'Nirvana',
    type: 'Sattyagraha',
    level: 3,
    mpCost: 95,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 11,
      },
      [KiType.Agility]: {
        cost: 11,
      },
      [KiType.Power]: {
        cost: 11,
      },
      [KiType.WillPower]: {
        cost: 11,
      },
      [KiType.Constitution]: {
        cost: 6,
      },
    },
    effect:
      'Habilidad de parada (+175), Escudo de Energía (800 PV), Reflectar el Ataque',
  },
  {
    id: '96',
    name: 'Martirio Eterno',
    type: 'A’Arab Zaraq',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Strength]: {
        cost: 5,
      },
      [KiType.Constitution]: {
        cost: 5,
      },
    },
    effect: 'Recuperar acción, Habilidad de Contraataque (+25)',
    notes: 'Circunstancia límite (Mitad de PV)',
  },
  {
    id: '97',
    name: 'Festín De Sangre',
    type: 'A’Arab Zaraq',
    level: 1,
    mpCost: 25,
    kiCost: {
      [KiType.Strength]: {
        cost: 8,
        maintainedCost: 2,
      },
      [KiType.Constitution]: {
        cost: 8,
        maintainedCost: 3,
      },
      [KiType.Power]: {
        cost: 8,
        maintainedCost: 3,
      },
    },
    effect: 'Potenciar Crítico (+50, Crítico Automático)',
  },
  {
    id: '98',
    name: 'La Mano Del Purgatorio',
    type: 'A’Arab Zaraq',
    level: 1,
    mpCost: 35,
    kiCost: {
      [KiType.Strength]: {
        cost: 8,
        maintainedCost: 4,
      },
      [KiType.Constitution]: {
        cost: 7,
        maintainedCost: 4,
      },
    },
    effect:
      'Ataque a Distancia (20 metros), Apresamiento (FUE 12, Presa existencial)',
    notes: 'Daño reducido (Mitad de daño)',
  },
  {
    id: '99',
    name: 'Llamada Infernal',
    type: 'A’Arab Zaraq',
    level: 2,
    mpCost: 45,
    kiCost: {
      [KiType.Strength]: {
        cost: 7,
      },
      [KiType.Constitution]: {
        cost: 7,
      },
    },
    effect:
      'Ataque con área (25 metros de radio), Sustitución de daño (150), Potenciar Crítico (+40)',
    notes: 'Sacrificio (-25 PV)',
  },
  {
    id: '100',
    name: 'Corrupción Primaria',
    type: 'A’Arab Zaraq',
    level: 2,
    mpCost: 75,
    kiCost: {
      [KiType.Strength]: {
        cost: 12,
        maintainedCost: 4,
      },
      [KiType.Constitution]: {
        cost: 14,
        maintainedCost: 5,
      },
      [KiType.Power]: {
        cost: 13,
        maintainedCost: 5,
      },
    },
    effect: 'Acumulación (1.000 PV, Regeneración 250), Intangibilidad',
  },
  {
    id: '101',
    name: 'La Semilla Del Mal',
    type: 'A’Arab Zaraq',
    level: 3,
    mpCost: 95,
    kiCost: {
      [KiType.Strength]: {
        cost: 18,
      },
      [KiType.Constitution]: {
        cost: 18,
      },
      [KiType.Power]: {
        cost: 18,
      },
    },
    effect:
      'Habilidad de Ataque (+100), Ataque a Distancia (50 metros, Proyección), Estados Sobrenaturales (RF 160, Estado añadido: Terror)',
    notes: 'Daño reducido (Mitad de Daño)',
  },
  {
    id: '102',
    name: 'La Grulla Blanca',
    type: 'Wushu',
    level: 1,
    mpCost: 20,
    kiCost: {
      [KiType.Strength]: {
        cost: 4,
      },
      [KiType.Dexterity]: {
        cost: 4,
      },
    },
    effect: 'Habilidad de esquiva (+40), Habilidad de Contraataque (+50)',
  },
  {
    id: '103',
    name: 'Las Fauces Del Lobo',
    type: 'Wushu',
    level: 1,
    mpCost: 20,
    kiCost: {
      [KiType.Strength]: {
        cost: 5,
      },
      [KiType.Dexterity]: {
        cost: 5,
      },
    },
    effect: 'Habilidad de parada (+50), Ataque adicional limitado (+1)',
  },
  {
    id: '104',
    name: 'La Garra Del Tigre',
    type: 'Wushu',
    level: 1,
    mpCost: 25,
    kiCost: {
      [KiType.Strength]: {
        cost: 5,
      },
      [KiType.Dexterity]: {
        cost: 5,
      },
    },
    effect: 'Aumento de Daño (+50), Destruir Armadura (-3 TA)',
  },
  {
    id: '105',
    name: 'El Puño Del Gato',
    type: 'Wushu',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 5,
      },
      [KiType.Agility]: {
        cost: 5,
      },
    },
    effect: 'Ataque adicional limitado (+4)',
    notes: 'Daño reducido (Mitad de Daño)',
  },
  {
    id: '106',
    name: 'El Abrazo Del Mono',
    type: 'Wushu',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Strength]: {
        cost: 5,
      },
      [KiType.Dexterity]: {
        cost: 5,
      },
    },
    effect: 'Habilidad de ataque (+40), Apresamiento (FUE 12)',
    notes: 'Daño reducido (Ningún daño)',
  },
  {
    id: '107',
    name: 'La Mordedura De La Serpiente',
    type: 'Wushu',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Strength]: {
        cost: 5,
      },
      [KiType.Dexterity]: {
        cost: 5,
      },
    },
    effect:
      'Incrementar Turno (+50), Potenciar Crítico (+40), Maniobras de Combate y Apuntar (-50)',
  },
  {
    id: '108',
    name: 'La Ilusión De La Mantis',
    type: 'Wushu',
    level: 2,
    mpCost: 50,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 9,
      },
      [KiType.Agility]: {
        cost: 9,
      },
    },
    effect:
      'Camuflar Ataque (Inhumano (320)), Espejismo (1 imagen, Indetección Casi Imposible (240)), Incrementar Turno (+50)',
  },
  {
    id: '109',
    name: 'La Garra Del Águila',
    type: 'Wushu',
    level: 2,
    mpCost: 70,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 11,
      },
      [KiType.Agility]: {
        cost: 10,
      },
    },
    effect:
      'Apresamiento (FUE 16), Aumento de Daño (+90), Habilidad de ataque (+50)',
  },
  {
    id: '110',
    name: 'El Último Dragón',
    type: 'Wushu',
    level: 2,
    mpCost: 85,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 11,
      },
      [KiType.Agility]: {
        cost: 11,
      },
    },
    effect:
      'Habilidad de ataque (+90), Ataque adicional (+2, Combo: Hasta 2, Bono de cansancio añadido)',
  },
  {
    id: '111',
    name: 'Ryukanzan',
    type: 'La Vía De Meizo',
    level: 1,
    mpCost: 30,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 4,
      },
    },
    effect: 'Aumentar rotura (+20), Maniobras de Combate y Apuntar (-25)',
    notes: 'Condición (Desenvainar)',
  },
  {
    id: '112',
    name: 'Shoryuzan',
    type: 'La Vía De Meizo',
    level: 1,
    mpCost: 20,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 4,
      },
    },
    effect: 'Incrementar Turno (+75), Maniobras de Combate y Apuntar (-25)',
  },
  {
    id: '113',
    name: 'Fukuryuzan',
    type: 'La Vía De Meizo',
    level: 1,
    mpCost: 20,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 4,
      },
      [KiType.Agility]: {
        cost: 4,
      },
    },
    effect: 'Ataque a Distancia (20 metros), Destruir Armadura (-3 TA)',
  },
  {
    id: '114',
    name: 'Gozuryuzan',
    type: 'La Vía De Meizo',
    level: 2,
    mpCost: 50,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 9,
      },
      [KiType.Agility]: {
        cost: 9,
      },
    },
    effect: 'Ataque adicional limitado (+4, Ataque contínuo)',
  },
  {
    id: '115',
    name: 'Douryuzan',
    type: 'La Vía De Meizo',
    level: 2,
    mpCost: 70,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 7,
      },
      [KiType.Agility]: {
        cost: 7,
      },
    },
    effect:
      'Ataque con área (25 metros de radio), Impacto (FUE 14), Habilidad de Ataque (+50)',
    notes: 'Agotamiento (-2 cansancio)',
  },
  {
    id: '116',
    name: 'Kouryuu',
    type: 'La Vía De Meizo',
    level: 3,
    mpCost: 75,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 14,
      },
      [KiType.Agility]: {
        cost: 14,
      },
    },
    effect:
      'Habilidad de Ataque (+100), Incrementar Turno (+150), Maniobras de Combate y Apuntar (-25), Transporte Automático (50 metros)',
    notes: 'Sin defensa, Condición (Desenvainar)',
  },
  {
    id: '117',
    name: 'Tornado',
    type: 'Maestro De Catástrofes',
    level: 1,
    mpCost: 25,
    kiCost: {
      [KiType.Strength]: {
        cost: 4,
      },
      [KiType.Power]: {
        cost: 4,
      },
    },
    effect: 'Ataque con área (25 metros de radio), Impacto (fue 12)',
    notes: 'Daño reducido (Mitad de daño)',
  },
  {
    id: '118',
    name: 'Seísmo',
    type: 'Maestro De Catástrofes',
    level: 1,
    mpCost: 45,
    kiCost: {
      [KiType.Strength]: {
        cost: 4,
      },
      [KiType.Power]: {
        cost: 4,
      },
    },
    effect: 'Ataque con área (25 metros de radio), Aumento de Daño (+50)',
    notes: 'Condición (Terreno Determinado: Tierra)',
  },
  {
    id: '119',
    name: 'Tsunami',
    type: 'Maestro De Catástrofes',
    level: 2,
    mpCost: 65,
    kiCost: {
      [KiType.Strength]: {
        cost: 8,
      },
      [KiType.Power]: {
        cost: 8,
      },
    },
    effect: 'Ataque con área (50 metros de radio), Habilidad de ataque (+90)',
    notes: 'Requerimientos elementales (Intensidad menor Agua)',
  },
  {
    id: '120',
    name: 'Maelstrom',
    type: 'Maestro De Catástrofes',
    level: 2,
    mpCost: 75,
    kiCost: {
      [KiType.Strength]: {
        cost: 14,
      },
      [KiType.Power]: {
        cost: 15,
      },
    },
    effect:
      'Estados Sobrenaturales (RF 140, Estado añadido: Paralización parcial, Condición: por superficie, Distancia: 50 metros)',
    notes: 'Requerimientos elementales (Intensidad Mayor Agua)',
  },
  {
    id: '121',
    name: 'Erupción Volcánica',
    type: 'Maestro De Catástrofes',
    level: 3,
    mpCost: 110,
    kiCost: {
      [KiType.Strength]: {
        cost: 20,
        maintainedCost: 6,
      },
      [KiType.Power]: {
        cost: 19,
        maintainedCost: 5,
      },
      [KiType.WillPower]: {
        cost: 17,
        maintainedCost: 5,
      },
    },
    effect:
      'Ataque con área (50 metros de radio), Potenciar Crítico (+50), Aumento de Daño (+50), Ataque elemental (Fuego), Ataque indirecto (Nv 3)',
    notes: 'Condición (Terreno Determinado: Tierra)',
  },
  {
    id: '122',
    name: 'Impacto Astronómico',
    type: 'Maestro De Catástrofes',
    level: 3,
    mpCost: 110,
    kiCost: {
      [KiType.Strength]: {
        cost: 26,
      },
      [KiType.Power]: {
        cost: 26,
      },
      [KiType.WillPower]: {
        cost: 26,
      },
      [KiType.Dexterity]: {
        cost: 6,
      },
    },
    effect:
      'Ataque con área (5 kilómetros de radio), Impacto (fue 18), Multiplicador al Daño (x2), Ataque predeterminado (Zen (440))',
    notes: 'Predeterminación, Penalizador A Toda Acción (-150)',
  },
  {
    id: '123',
    name: 'Sirius, Luz Negra',
    type: 'Thanathos',
    level: 1,
    mpCost: 20,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 4,
      },
      [KiType.Strength]: {
        cost: 4,
      },
    },
    effect: 'Ataque a Distancia (50 metros), Habilidad de ataque (+40)',
    notes: 'Requerimientos elementales (Intensidad menor Fuego)',
  },
  {
    id: '124',
    name: 'Jocasta, Filo De Sombras',
    type: 'Thanathos',
    level: 1,
    mpCost: 25,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 4,
      },
      [KiType.Strength]: {
        cost: 5,
      },
    },
    effect: 'Habilidad de ataque (+40), Aumento de Daño (+75)',
    notes: 'Requerimientos elementales (Intensidad menor Fuego)',
  },
  {
    id: '125',
    name: 'Selune, Sombra De La Luna',
    type: 'Thanathos',
    level: 2,
    mpCost: 65,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 5,
      },
      [KiType.Power]: {
        cost: 3,
      },
    },
    effect:
      'Ataque a Distancia (50 metros), Ataque con área (50 metros de radio)',
    notes: 'Requerimientos elementales (Intensidad menor Fuego)',
  },
  {
    id: '126',
    name: 'Lycorys, Sumir En La Oscuridad',
    type: 'Thanathos',
    level: 2,
    mpCost: 50,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 7,
      },
      [KiType.Strength]: {
        cost: 7,
      },
    },
    effect: 'Habilidad de parada (+75), Habilidad de Contraataque (+75)',
    notes: 'Requerimientos elementales (Intensidad menor Fuego)',
  },
  {
    id: '127',
    name: 'Lycorys Korene, Sumir En La Oscuridad',
    type: 'Thanathos',
    level: 2,
    mpCost: 60,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 7,
      },
      [KiType.Strength]: {
        cost: 7,
      },
    },
    effect: 'Habilidad de esquiva (+75), Habilidad de Contraataque (+75)',
    notes: 'Requerimientos elementales (Intensidad menor Fuego)',
  },
  {
    id: '128',
    name: 'Fénix Oscuro',
    type: 'Thanathos',
    level: 3,
    mpCost: 90,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 15,
      },
      [KiType.Strength]: {
        cost: 15,
      },
    },
    effect:
      'Habilidad de Ataque (+150), Ataque a distancia (250 metros, Proyección, Estela de destrucción)',
    notes: 'Requerimientos elementales (Intensidad mayor Fuego)',
  },
  {
    id: '129',
    name: 'Protocolo Defensivo Dt-01: Pantalla',
    type: 'Legado De Sólomon',
    level: 1,
    mpCost: 50,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 8,
        maintainedCost: 2,
      },
      [KiType.Agility]: {
        cost: 8,
        maintainedCost: 2,
      },
      [KiType.Power]: {
        cost: 11,
        maintainedCost: 3,
      },
    },
    effect:
      'Defensa Predeterminada (Absurdo (180)), Escudo de Energía (400 PV, Regeneración 100)',
    notes: 'Requerimiento (Sangre antigua)',
  },
  {
    id: '130',
    name: 'Protocolo Reactivo Ac-01: Exaltación',
    type: 'Legado De Sólomon',
    level: 1,
    mpCost: 25,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 4,
      },
      [KiType.Agility]: {
        cost: 4,
      },
    },
    effect: 'Recuperar acción',
    notes: 'Requerimiento (Sangre antigua)',
  },
  {
    id: '131',
    name: 'Protocolo Ofensivo At-01: Filo De Sólomon',
    type: 'Legado De Sólomon',
    level: 1,
    mpCost: 35,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 7,
      },
      [KiType.Agility]: {
        cost: 7,
      },
    },
    effect:
      'Ataque adicional limitado (+1), Ataque predeterminado (Casi Imposible (240)), Ataque a Distancia (5 metros)',
    notes: 'Requerimiento (Sangre antigua)',
  },
  {
    id: '132',
    name: 'Protocolo Defensivo Dt-02: Pantalla Existencial Mayor',
    type: 'Legado De Sólomon',
    level: 2,
    mpCost: 65,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 12,
        maintainedCost: 4,
      },
      [KiType.Agility]: {
        cost: 12,
        maintainedCost: 4,
      },
      [KiType.Power]: {
        cost: 12,
        maintainedCost: 4,
      },
    },
    effect:
      'Defensa Predeterminada (Casi Imposible (240)), Escudo de Energía (500 pv, regeneración 250)',
    notes: 'Requerimiento (Sangre antigua)',
  },
  {
    id: '133',
    name: 'Protocolo De Búsqueda Sc-02: Ojos De Sólomon',
    type: 'Legado De Sólomon',
    level: 2,
    mpCost: 60,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 9,
      },
      [KiType.Agility]: {
        cost: 10,
      },
    },
    effect:
      'Habilidades perceptivas (Visión radial, Visión nocturna, ver lo sobrenatural)',
    notes: 'Requerimiento (Sangre antigua)',
  },
  {
    id: '134',
    name: 'Protocolo Ofensivo At-02: Mecanus',
    type: 'Legado De Sólomon',
    level: 2,
    mpCost: 100,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 18,
      },
      [KiType.Agility]: {
        cost: 18,
      },
    },
    effect:
      'Ataque adicional limitado (+1), Ataque predeterminado (Casi Imposible (240)), Ataque a Distancia (100 metros)',
    notes: 'Requerimiento (Sangre antigua)',
  },
  {
    id: '135',
    name: 'Protocolo Ofensivo Rx-02: Pandora',
    type: 'Legado De Sólomon',
    level: 2,
    mpCost: 65,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 4,
      },
      [KiType.Agility]: {
        cost: 4,
      },
    },
    effect: 'Ataque con área (50 metros de radio)',
    notes: 'Requerimiento (Sangre antigua), Sacrificio (-25 PV)',
  },
  {
    id: '136',
    name: 'Protocolo Defensivo Final Dt-03: Guardián',
    type: 'Legado De Sólomon',
    level: 3,
    mpCost: 95,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 22,
        maintainedCost: 7,
      },
      [KiType.Agility]: {
        cost: 22,
        maintainedCost: 7,
      },
      [KiType.Power]: {
        cost: 23,
        maintainedCost: 7,
      },
    },
    effect:
      'Defensa Predeterminada (Inhumano (320)), Escudo de Energía (1.000 pv, Regeneración 500)',
    notes: 'Requerimiento (Sangre antigua)',
  },
  {
    id: '137',
    name: 'Protocolo Ofensivo Final At-03: Apocalipsis',
    type: 'Legado De Sólomon',
    level: 3,
    mpCost: 80,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 20,
      },
      [KiType.Agility]: {
        cost: 20,
      },
    },
    effect: 'Ataque predeterminado (Zen (440)), Multiplicador al Daño (x3)',
    notes:
      'Requerimiento (Sangre antigua), Técnica mantenida (Mecanus), Circunstancia límite (Mitad de PV)',
  },
  {
    id: '138',
    name: 'Frostlinder',
    type: 'Frostkolier',
    level: 1,
    mpCost: 35,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 9,
      },
      [KiType.Constitution]: {
        cost: 9,
      },
    },
    effect: 'Ataque a Distancia (20 metros), Ataque adicional (+3)',
    notes: 'Atadura Elemental (Agua)',
  },
  {
    id: '139',
    name: 'Frostvalar',
    type: 'Frostkolier',
    level: 1,
    mpCost: 25,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 3,
      },
      [KiType.Constitution]: {
        cost: 3,
        maintainedCost: 1,
      },
      [KiType.Power]: {
        cost: 3,
      },
    },
    effect: 'Choque físico, Ataque elemental (Agua)',
    notes: 'Atadura Elemental (Agua)',
  },
  {
    id: '140',
    name: 'Kolinger',
    type: 'Frostkolier',
    level: 2,
    mpCost: 45,
    kiCost: {
      [KiType.Constitution]: {
        cost: 7,
        maintainedCost: 2,
      },
      [KiType.Power]: {
        cost: 7,
        maintainedCost: 1,
      },
    },
    effect: 'Armadura (TA 6)',
    notes: 'Atadura Elemental (Agua)',
  },
  {
    id: '141',
    name: 'Arnbjorg',
    type: 'Frostkolier',
    level: 2,
    mpCost: 45,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 11,
      },
      [KiType.Constitution]: {
        cost: 11,
      },
    },
    effect:
      'Habilidad de parada (+50), Habilidad de Contraataque (+50), Ataque indirecto (Nv 2), Ataque adicional limitado (+1)',
    notes: 'Atadura Elemental (Agua)',
  },
  {
    id: '142',
    name: 'Gunnhildur',
    type: 'Frostkolier',
    level: 3,
    mpCost: 75,
    kiCost: {
      [KiType.Dexterity]: {
        cost: 13,
      },
      [KiType.Constitution]: {
        cost: 13,
      },
    },
    effect: 'Ataque adicional limitado (+10, Ataque contínuo)',
    notes: 'Atadura Elemental (Agua)',
  },
  {
    id: '143',
    name: 'Dedisco Deus Crux, La Cruz Del Dios Olvidado',
    type: 'Anima, La Canción De Los Ángeles Caídos',
    level: 1,
    mpCost: 50,
    kiCost: {
      [KiType.Agility]: {
        cost: 7,
      },
      [KiType.Dexterity]: {
        cost: 8,
      },
      [KiType.Power]: {
        cost: 8,
      },
    },
    effect: 'Habilidad de ataque (+100), Ataque adicional (+1)',
  },
  {
    id: '144',
    name: 'Sheraphim Ianua, La Puerta Del Serafín',
    type: 'Anima, La Canción De Los Ángeles Caídos',
    level: 1,
    mpCost: 40,
    kiCost: {
      [KiType.Agility]: {
        cost: 8,
        maintainedCost: 3,
      },
      [KiType.Dexterity]: {
        cost: 9,
        maintainedCost: 3,
      },
      [KiType.Power]: {
        cost: 7,
        maintainedCost: 2,
      },
      [KiType.WillPower]: {
        cost: 7,
        maintainedCost: 1,
      },
    },
    effect: 'Habilidad de esquiva completa (+40), Defensas Adicionales (+3)',
  },
  {
    id: '145',
    name: 'Angelus Ultima, El Último Ángel',
    type: 'Anima, La Canción De Los Ángeles Caídos',
    level: 2,
    mpCost: 100,
    kiCost: {
      [KiType.Agility]: {
        cost: 14,
      },
      [KiType.Dexterity]: {
        cost: 14,
      },
      [KiType.Power]: {
        cost: 15,
      },
    },
    effect:
      'Habilidad de ataque (+150), Ataque a Distancia (1 kilómetro, Estela de destrucción), Ataque con área (50 metros de radio)',
    notes: 'Predeterminación',
  },
  {
    id: '146',
    name: 'Questus Resedi Mundus, El Lamento Del Mundo Que Quedó Atrás',
    type: 'Anima, La Canción De Los Ángeles Caídos',
    level: 2,
    mpCost: 100,
    kiCost: {
      [KiType.Agility]: {
        cost: 14,
      },
      [KiType.Dexterity]: {
        cost: 14,
      },
      [KiType.Power]: {
        cost: 13,
      },
    },
    effect:
      'Habilidad de ataque (+75), Ataque adicional limitado (+8, Ataque contínuo)',
  },
  {
    id: '147',
    name: 'Delevi Deus Anima, Exterminador De Las Almas De Los Dioses',
    type: 'Anima, La Canción De Los Ángeles Caídos',
    level: 3,
    mpCost: 200,
    kiCost: {
      [KiType.Agility]: {
        cost: 26,
      },
      [KiType.Dexterity]: {
        cost: 28,
      },
      [KiType.Power]: {
        cost: 30,
      },
    },
    effect:
      'Habilidad de ataque (+200), Ataque con área (5 kilómetros de radio), Multiplicador al Daño (x3), Ataque sobrenatural, Ataque indirecto (Nv 3), Destruir Armadura (Sin armadura)',
    notes: 'Predeterminación, Sobrecarga (Espera de 20 asaltos)',
  },
];

export const getUnusedKiTechniques = (
  kiTechniques: KiTechniqueId[],
): KiTechnique | undefined => {
  return AllKiTechniques.find((kt) => !kiTechniques.includes(kt.id));
};
