import { WeaponItemModel } from '../../../../../../../../../../../../../../domain/aggregations/weapon/WeaponItemModel';
import { observer } from 'mobx-react';
import {
  WeaponKnowledgeType,
  weaponKnowledgeTypeTranslations,
} from '../../../../../../../../../../../../../../domain/aggregations/weapon/Weapon.types';
import { transformToMenuItems } from '../../../../../../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { Selector } from '../../../../../../../../common/Selector/Selector';

function getWeaponKnowledgeTypeTranslation(weapon: WeaponItemModel) {
  return weapon.customKnowledgeType
    ? weaponKnowledgeTypeTranslations[weapon.customKnowledgeType]
    : `Automático (${
        weaponKnowledgeTypeTranslations[weapon.calculatedKnowledgeType]
      })`;
}

export const WeaponKnowledgeTypeSelector = observer(
  ({ weapon }: { weapon: WeaponItemModel }) => {
    const items = transformToMenuItems({
      fromItems: [undefined, ...Object.values(WeaponKnowledgeType)],
      generateItem: (knowledgeType) => {
        if (knowledgeType === undefined) {
          return {
            key: 'automatic',
            name: `Automático (${
              weaponKnowledgeTypeTranslations[weapon.calculatedKnowledgeType]
            })`,
            value: undefined,
            onClick: () => {
              weapon.setCustomKnowledgeType(undefined);
            },
          };
        }

        return {
          key: knowledgeType,
          name: weaponKnowledgeTypeTranslations[knowledgeType],
          value: knowledgeType,
          onClick: () => {
            weapon.setCustomKnowledgeType(knowledgeType);
          },
        };
      },
    });

    return (
      <Selector
        value={weapon.customKnowledgeType}
        valueAsText={getWeaponKnowledgeTypeTranslation(weapon)}
        items={items}
      />
    );
  },
);
