import { ElementNode, ElementTree, TreeElement } from './utils/ElementTree';

type SkillTreeProps<T extends TreeElement<T>> = {
  tree: ElementTree<T>;

  selectedElements: T[];

  onNodeClick: (element: ElementNode<T>) => void;
};

export const SkillTree = <T extends TreeElement<T>>({
  tree,
  selectedElements,
  onNodeClick,
}: SkillTreeProps<T>) => {
  return tree.buildComponent(selectedElements, onNodeClick);
};
