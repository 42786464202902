import { model } from 'mobx-keystone';
import { BaseFinalFieldModel } from '../../../character/model/utils/fields/BaseFinalFieldModel';
import { getParentArmor } from '../../../utils/parenting/getParentArmor';

@model('ArmorItem/EnergyATField')
export class EnergyATFieldModel extends BaseFinalFieldModel {
  get final(): number {
    if (!this.armor) return 0;

    let ta = this.base + this.special;

    if (this.armor.isEnchanted) {
      ta = Math.max(Math.floor(this.armor.quality.final / 5) + ta, 0);
    }

    return ta;
  }

  get armor() {
    return getParentArmor(this);
  }
}
