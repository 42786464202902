import { observer } from 'mobx-react';

import { useCharacterContext } from '../../../../../../hooks/useCharacterContext';
import { CategoryModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/CategoryModel';
import { Wrapper } from './CombatInfo.styled';
import { StateSmallParagraph } from '../../../PDsTab.styled';
import { CharacterIssue } from '../../../../../../../../../../../domain/character/model/utils/issues/CharacterIssue';
import { translateCharacterIssue } from '../../../../../../../../../../../../shared/application/shared/translations/CharacterIssueTranslations';
import { getMaxPDsUntilThisCategory } from './utils/getMaxPDsUntilThisCategory';
import { getCombatPDsUsedUntilThisCategory } from './utils/getPDsUsedUntilThisCategory';

export const CombatInfo = observer(
  ({ category }: { category: CategoryModel }) => {
    const { character } = useCharacterContext();
    const errors: string[] = [];

    if (character.content.hasIssue(CharacterIssue.AttackDefenseUnbalance)) {
      errors.push(
        translateCharacterIssue(CharacterIssue.AttackDefenseUnbalance),
      );
    }

    if (
      character.content.hasIssue(CharacterIssue.OnlyDefenseDevelopmentExceed)
    ) {
      errors.push(
        translateCharacterIssue(CharacterIssue.OnlyDefenseDevelopmentExceed),
      );
    }

    const combatLimit = getMaxPDsUntilThisCategory(character.content, category);
    const combatPDsUsed = getCombatPDsUsedUntilThisCategory(
      character.content,
      category,
    );

    return (
      <Wrapper>
        <StateSmallParagraph
          $errored={character.content.hasIssue(
            CharacterIssue.OffensiveSkillsExceed,
          )}
        >
          PDs de Hab. Ofensivas ({combatPDsUsed}/{combatLimit})
        </StateSmallParagraph>
        {errors.map((error) => (
          <StateSmallParagraph $errored key={error}>
            {error}
          </StateSmallParagraph>
        ))}
      </Wrapper>
    );
  },
);
