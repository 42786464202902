import { observer } from 'mobx-react';
import { MouseEvent, useState } from 'react';
import {
  AllMetamagics,
  getMetamagicFromId,
  getUnusedMetamagic,
  Metamagic,
  MetamagicId,
} from '../../../../../../../../../domain/aggregations/metamagic/Metamagic';
import { AddItemButton } from '../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../common/CharacterMenu/useCharacterMenu';
import { RemoveItemButton } from '../../../common/RemoveItemButton/RemoveItemButton';
import { Section } from '../../../common/Section/Section';
import { Table } from '../../../common/Table/Table';
import { useCharacterContext } from '../../../../hooks/useCharacterContext';
import { MenuItem } from '../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';

export const Metamagics = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { handleMenuOpen, Menu } = useCharacterMenu<MetamagicId>({
    sorted: true,
  });

  const [selectedMetamagicIndex, setSelectedMetamagicIndex] = useState<
    number | undefined
  >(undefined);

  const addMetamagic = () => {
    const unusedMetamagic = getUnusedMetamagic(character.mystic.metamagics);

    if (unusedMetamagic) {
      character.mystic.addMetamagic(unusedMetamagic);
    }
  };

  const changeMetamagic = (index: number, metamagic: Metamagic) => {
    character.mystic.replaceMetamagic(index, metamagic);
  };

  const removeMetamagic = (metamagicId: Metamagic) => () => {
    character.mystic.removeMetamagic(metamagicId);
  };

  const metamagicItems: MenuItem<MetamagicId>[] = AllMetamagics.map(
    (metamagic) => {
      const item: MenuItem<MetamagicId> = {
        key: metamagic.id,
        name: metamagic.name,
        value: metamagic.id,
        onClick: () => {
          if (selectedMetamagicIndex === undefined) return;

          changeMetamagic(selectedMetamagicIndex, metamagic);
        },
      };

      return item;
    },
  );

  const handleMetamagicClick =
    (metamagic: MetamagicId, index: number) =>
    (e: MouseEvent<HTMLDivElement>) => {
      setSelectedMetamagicIndex(index);
      handleMenuOpen(metamagic)(e);
    };

  return (
    <Section maxHeight="fit">
      <Section.Title>Metamagias</Section.Title>
      <Table>
        <Table.Header>
          <Table.Header.Cell>Nombre</Table.Header.Cell>
          <Table.Header.Cell>Nivel de PJ req.</Table.Header.Cell>
          <Table.Header.Cell>Coste de nivel de magia</Table.Header.Cell>
        </Table.Header>
        {character.mystic.metamagics
          .map(getMetamagicFromId)
          .map((metamagic, index) => (
            <Table.Row key={metamagic.id}>
              <Table.Row.Cell
                $interactable
                onClick={handleMetamagicClick(metamagic.id, index)}
              >
                {metamagic.name}
              </Table.Row.Cell>
              <Table.Row.Cell>
                {metamagic.levelReq ?? 'Sin req.'}
              </Table.Row.Cell>
              <Table.Row.Cell>{metamagic.cost}</Table.Row.Cell>
              <Table.Row.Cell>
                <RemoveItemButton onClick={removeMetamagic(metamagic)} />
              </Table.Row.Cell>
            </Table.Row>
          ))}
      </Table>

      <AddItemButton onClick={addMetamagic} />

      <Menu items={metamagicItems} />
    </Section>
  );
});
