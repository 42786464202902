import {
  DefaultFreeAccessSpells,
  DefaultMagicSpells,
} from '../DefaultMagicSpells';
import { MagicSpell } from '../MagicSpell';

export const getUnusedMagicSpell = (
  spell: MagicSpell[],
): MagicSpell | undefined => {
  return DefaultMagicSpells.find((magicSpell) => {
    return !spell.find((s) => s.id === magicSpell.id);
  });
};

export const getUnusedFreeAccessMagicSpell = (
  spell: MagicSpell[],
): MagicSpell | undefined => {
  return DefaultFreeAccessSpells.find((magicSpell) => {
    return !spell.find((s) => s.id === magicSpell.id);
  });
};
