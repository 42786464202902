import { WeaponKnowledgeType, WeaponType } from '../Weapon.types';

export const isSimilarWeapon = (
  knownWeaponTypes: WeaponType[],
  weaponTypesToCheck: WeaponType[],
) => {
  return (
    knownWeaponTypes.length === 1 &&
    weaponTypesToCheck.length === 1 &&
    knownWeaponTypes.some(
      (weaponTypes) => weaponTypes === weaponTypesToCheck[0],
    )
  );
};

export const isMixedWeapon = (
  knownWeaponTypes: WeaponType[],
  weaponTypes: WeaponType[],
) => {
  return knownWeaponTypes.some((wt) => weaponTypes.includes(wt));
};

export const getWeaponKnowledgeType = (
  knownWeaponTypes: WeaponType[][],
  weaponTypes: WeaponType[],
) => {
  if (weaponTypes.length === 1) {
    const isSimilar = knownWeaponTypes.some((types) =>
      isSimilarWeapon(types, weaponTypes),
    );

    if (isSimilar) {
      return WeaponKnowledgeType.Similar;
    }
  }

  const isMixed = knownWeaponTypes.some((types) =>
    isMixedWeapon(types, weaponTypes),
  );

  if (isMixed) {
    return WeaponKnowledgeType.Mixed;
  }

  return WeaponKnowledgeType.Different;
};
