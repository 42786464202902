import { Character } from '../../../domain/character/Character';
import {
  Content,
  IssueList,
  IssueRow,
  Paragraph,
  Title,
  Wrapper,
} from './CharacterIssuesModal.styled';
import { translateCharacterIssue } from '../../../../shared/application/shared/translations/CharacterIssueTranslations';
import { useModal } from '../../../../shared/application/design-system/components/Modal/hooks/useModal';

export const CharacterIssuesModal = ({
  character,
}: {
  character: Character;
}) => {
  return (
    <Wrapper>
      <Content>
        <Title>Alertas sobre la ficha</Title>
        <Paragraph>
          {character.content.name} contiene los siguientes errores:
        </Paragraph>
        <IssueList>
          {character.content.issues.map((issue) => (
            <IssueRow key={issue}>{translateCharacterIssue(issue)}</IssueRow>
          ))}
        </IssueList>
      </Content>
    </Wrapper>
  );
};

export const useCharacterIssuesModal = () => {
  const { openModal, closeModal, Modal } = useModal();

  return {
    openCharacterIssuesModal: (character: Character) => {
      return openModal(<CharacterIssuesModal character={character} />, {
        fullScreenInMobile: true,
      });
    },
    closeCharacterIssuesModal: closeModal,
    CharacterIssuesModal: Modal,
  };
};
