export enum MagicPath {
  Light = 'Luz',
  Darkness = 'Oscuridad',
  Fire = 'Fuego',
  Water = 'Agua',
  Air = 'Aire',
  Earth = 'Tierra',
  Creation = 'Creación',
  Destruction = 'Destrucción',
  Essence = 'Esencia',
  Illusion = 'Ilusión',
  Necromancy = 'Nigromancia',
  FreeAccess = 'Libre acceso',

  // Subpaths
  Chaos = 'Caos',
  War = 'Guerra',
  Literae = 'Literae',
  Death = 'Muerte',
  Musical = 'Musical',
  Nobility = 'Nobleza',
  Peace = 'Paz',
  Sin = 'Pecado',
  Knowledge = 'Conocimiento',
  Blood = 'Sangre',
  Dreams = 'Sueños',
  Time = 'Tiempo',
  Shift = 'Umbral',
  Void = 'Vacío',
}

export type MagicSubPath =
  | MagicPath.Chaos
  | MagicPath.War
  | MagicPath.Literae
  | MagicPath.Death
  | MagicPath.Musical
  | MagicPath.Nobility
  | MagicPath.Peace
  | MagicPath.Sin
  | MagicPath.Knowledge
  | MagicPath.Blood
  | MagicPath.Dreams
  | MagicPath.Time
  | MagicPath.Shift
  | MagicPath.Void;

const subPaths = [
  MagicPath.Chaos,
  MagicPath.War,
  MagicPath.Literae,
  MagicPath.Death,
  MagicPath.Musical,
  MagicPath.Nobility,
  MagicPath.Peace,
  MagicPath.Sin,
  MagicPath.Knowledge,
  MagicPath.Blood,
  MagicPath.Dreams,
  MagicPath.Time,
  MagicPath.Shift,
  MagicPath.Void,
];

export const isSubPath = (path: MagicPath): path is MagicSubPath =>
  subPaths.includes(path);

export type MagicMainPath = Exclude<
  MagicPath,
  MagicSubPath | MagicPath.FreeAccess
>;

const mainPaths = [
  MagicPath.Light,
  MagicPath.Darkness,
  MagicPath.Fire,
  MagicPath.Water,
  MagicPath.Air,
  MagicPath.Earth,
  MagicPath.Creation,
  MagicPath.Destruction,
  MagicPath.Essence,
  MagicPath.Illusion,
  MagicPath.Necromancy,
];

export const isMainPath = (path: MagicPath): path is MagicMainPath =>
  mainPaths.includes(path);
