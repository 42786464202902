import { model } from 'mobx-keystone';
import { NaturalArmorAdvantage } from '../../../../../aggregations/advantage/CommonAdvantages';
import { calculateTotalAT } from '../utils/calculateTotalAT';
import {
  NephilimTurak,
  Turak,
} from '../../../../../aggregations/nephilim-type/Nephilim';
import { TotalATField } from './TotalATField';

@model('Character/TotalHeatAT')
export class TotalHeatATField extends TotalATField {
  get final(): number {
    if (!this.character) return 0;

    let ta = super.final;

    if (
      this.character.creationPoints.containsAdvantage(NaturalArmorAdvantage)
    ) {
      ta += 2;
    }

    if (this.character.isNephilim(Turak)) {
      ta += 2;
    }

    if (this.character.isNephilim(NephilimTurak)) {
      ta += 1;
    }

    return (
      ta +
      calculateTotalAT(
        this.character.inventory.equippedArmors.map((a) => a.at.heat.final),
      )
    );
  }
}
