const copyTextToClipboard = async (text: string): Promise<void> => {
  if (!navigator.clipboard) {
    // eslint-disable-next-line no-console
    console.error('Clipboard API not available');

    return Promise.reject(new Error('Clipboard API not available'));
  }

  try {
    await navigator.clipboard.writeText(text);

    return Promise.resolve();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error copying text: ', e);

    return Promise.reject(e);
  }
};

export const ClipboardService = {
  copyText: copyTextToClipboard,
};
