import { DisadvantageLevel1, DisadvantageLevel2 } from './CommonDisadvantages';
import { Disadvantage, DisadvantageType } from './Disadvantage.types';

export const PsychicConsumptionDisadvantage: Disadvantage = {
  id: 'disadvantage-psychic-1',

  name: 'Consumición psíquica',

  type: DisadvantageType.Psychics,

  levels: [DisadvantageLevel2],
};

export const PsychicExhaustionDisadvantage: Disadvantage = {
  id: 'disadvantage-psychic-2',

  name: 'Extenuación psíquica',

  type: DisadvantageType.Psychics,

  levels: [DisadvantageLevel1],
};

export const OnePowerDisadvantage: Disadvantage = {
  id: 'disadvantage-psychic-3',

  name: 'Poder único',

  type: DisadvantageType.Psychics,

  levels: [DisadvantageLevel1],
};

export const UnableToFocusDisadvantage: Disadvantage = {
  id: 'disadvantage-psychic-4',

  name: 'Sin concentración',

  type: DisadvantageType.Psychics,

  levels: [DisadvantageLevel1],
};

export const AllPsychicDisadvantages: Disadvantage[] = [
  PsychicConsumptionDisadvantage,
  PsychicExhaustionDisadvantage,
  OnePowerDisadvantage,
  UnableToFocusDisadvantage,
];
