import { model } from 'mobx-keystone';
import { SecondarySkill } from '../../../../../types/SecondarySkill';
import { getTotalOf } from '../../../../../utils/getTotalOf';
import { SecondarySkillPDFieldModel } from '../../../../pd/parts/categories/parts/secondaries/SecondarySkillPDFieldModel';
import { SecondarySkillFieldModel } from '../../../utils/fields/SecondarySkillFieldModel';

@model('Character/Secondaries/IntellectualSkills/Tactics')
export class TacticsSkillModel extends SecondarySkillFieldModel {
  type = SecondarySkill.Tactics;

  get primariesBonus(): number {
    if (!this.character) return 0;

    return this.character.primaries.intelligence.modifier.final;
  }

  getPDFields(): SecondarySkillPDFieldModel[] {
    return (
      this.character?.pd.categories.map(
        (c) => c.secondaries.intellectual.tactics,
      ) ?? []
    );
  }

  get fromCategories(): number {
    return getTotalOf(
      this.character,
      (c) => c.secondaries.intellectual.tactics.final,
    );
  }
}
