import styled from 'styled-components';

export const Wrapper = styled.div(({ theme: { size } }) => ({
  width: size.units(35),
  height: size.units(35),

  padding: size.units(3),

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  gap: size.units(2),
}));

export const CropperContainer = styled.div({
  position: 'relative',

  width: '100%',
  height: '100%',
});
