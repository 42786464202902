import styled from 'styled-components';
import { BasicParagraph } from '../../../../../../../../../../../shared/application/design-system/components/Texts/Texts.styled';

export const NephilimSelectorText = styled(BasicParagraph)(
  ({ theme: { mixins, color } }) => ({
    ...mixins.interactable(color),
  }),
);

export const DescriptionRow = styled.div(({ theme: { size, mixins } }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: size.units(1),

  ...mixins.responsive.sm.upward({
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: size.units(2),
  }),

  ...mixins.responsive.sm.downward({
    flexDirection: 'column',
    gap: size.units(1),
  }),
}));
