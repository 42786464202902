import styled from 'styled-components';

export const PrimaryCharacteristicBonusRow = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  rowGap: '0.5rem',
  columnGap: '1.5rem',

  width: '100%',
});
