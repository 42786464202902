export enum LifeRegenerationPeriod {
  Day = 'day',
  Minute = 'minute',
  Turn = 'turn',
}

export enum PenaltyRegenerationPeriod {
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Turn = 'turn',
  Instant = 'instant',
}
