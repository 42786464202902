import { Input } from '../../../../shared/application/design-system/components/Input/Input';
import { ChangeEvent, useState } from 'react';
import { ButtonContainer, Content, Wrapper } from './CreateFolderModal.styled';
import {
  BasicParagraph,
  BasicTitle,
} from '../../../../shared/application/design-system/components/Texts/Texts.styled';
import { Button } from '../../../../shared/application/design-system/components/Button/Button';
import { ButtonVariant } from '../../../../shared/application/design-system/components/Button/Button.types';
import { useCreateFolder } from '../../hooks/useCreateFolder';

export const CreateFolderModal = ({
  parentFolderId,

  onSave,
  onCancel,
}: {
  parentFolderId?: string;

  onSave: () => void;
  onCancel: () => void;
}) => {
  const [folderName, setFolderName] = useState('');

  const { createFolder, creatingFolder } = useCreateFolder();

  const handlerInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFolderName(e.target.value);
  };

  const handleCreateFolder = () => {
    createFolder(folderName, parentFolderId).then(() => onSave());
  };

  return (
    <Wrapper>
      <Content>
        <BasicTitle>Crear carpeta</BasicTitle>
        <BasicParagraph>
          <Input
            value={folderName}
            placeholder={'Nueva carpeta'}
            onChange={handlerInputChange}
            onEnter={handleCreateFolder}
          />
        </BasicParagraph>
        <ButtonContainer>
          <Button
            variant={ButtonVariant.Outline}
            onClick={onCancel}
            loading={creatingFolder}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleCreateFolder}
            loading={creatingFolder}
            disabled={folderName.trim().length === 0}
          >
            Crear
          </Button>
        </ButtonContainer>
      </Content>
    </Wrapper>
  );
};
