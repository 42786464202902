import { Avatar } from '@mui/material';

import { useUserAuthorization } from '../../../hooks/useUserAuthorization';
import { Routes, useNavigate } from '../../../hooks/useNavigate';
import {
  AvatarContainer,
  NavbarItem,
  NavbarItemsContainer,
  NotificationBadge,
  StyledBackIcon,
  UserManagementContainer,
  VersionText,
  Wrapper,
} from './TopNavbar.styled';
import { config } from '../../../../../bootstrap/infrastructure/config';
import { MenuItem, useMenu } from '../Menu/useMenu';

export type NavbarItem = {
  label: string;
  selected?: boolean;
  onClick: () => void;
  hasNotificationBadge?: boolean;
};

interface TopNavbarProps {
  items?: NavbarItem[];

  goBack?: boolean;
}

export const TopNavbar = ({ items = [], goBack }: TopNavbarProps) => {
  const { navigate, navigateBack } = useNavigate();

  const { session, loading, logout } = useUserAuthorization({
    redirect: false,
  });

  const { handleMenuOpen, Menu } = useMenu();

  const userMenuItems: MenuItem<unknown>[] = [
    {
      key: 'profile',
      value: 'profile',
      name: 'Mi perfil',
      onClick: () => {
        navigate(Routes.MyProfile);
      },
    },
    {
      key: 'logout',
      value: 'logout',
      name: 'Cerrar sesión',
      onClick: () => {
        logout();
      },
    },
  ];

  const handleGoBack = () => {
    navigateBack();
  };

  const avatarSrc = loading ? undefined : session?.avatarUrl;

  return (
    <Wrapper>
      <VersionText>{config.buildNumber}</VersionText>

      {goBack ? <StyledBackIcon onClick={handleGoBack} /> : undefined}
      <NavbarItemsContainer>
        {items.map((item) => (
          <NavbarItem
            key={item.label}
            onClick={item.onClick}
            $selected={item.selected}
            $label={item.label}
          >
            {item.hasNotificationBadge && <NotificationBadge />}
            {item.label}
          </NavbarItem>
        ))}
      </NavbarItemsContainer>
      <UserManagementContainer>
        <AvatarContainer role="button" onClick={handleMenuOpen()}>
          <Avatar src={avatarSrc} />
        </AvatarContainer>
        <Menu items={userMenuItems} />
      </UserManagementContainer>
    </Wrapper>
  );
};
