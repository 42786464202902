import { findParent } from 'mobx-keystone';

export const getParent = <T extends object>(
  parentModelClass: string,
  node: Record<never, never>,
): T | undefined => {
  return findParent<T>(
    node,
    (parentNode: any) => parentNode.$modelType === parentModelClass,
  );
};
