import { computed } from 'mobx';
import { model } from 'mobx-keystone';
import {
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
} from '../../../../aggregations/advantage/Advantage.types';
import { RegenerationAdvantage } from '../../../../aggregations/advantage/CommonAdvantages';
import { SlowHealingDisadvantage } from '../../../../aggregations/disadvantage/CommonDisadvantages';
import { Calculation, NumberField } from '../../utils/fields/NumberField';
import {
  LifeRegenerationPeriod,
  PenaltyRegenerationPeriod,
} from './Regeneration.types';
import { calculateRegeneration } from './utils/calculateRegeneration';
import { calculateRegenerationInfo } from './utils/calculateRegenerationInfo';
import {
  DukZaristFemale,
  DukZaristMale,
  NephilimDukZaristFemale,
  NephilimDukZaristMale,
  NephilimSylvain,
  Sylvain,
  Turak,
} from '../../../../aggregations/nephilim-type/Nephilim';

export type RegenerationInfo = {
  lifeRegeneratedResting: {
    value: number;
    per: LifeRegenerationPeriod;
  };
  lifeRegeneratedWithoutResting?: {
    value: number;
    per: LifeRegenerationPeriod;
  };
  penaltyReduction: {
    value: number;
    per: PenaltyRegenerationPeriod;
  };
  specialCapabilities?: string;
};

@model('RegenerationField')
export class RegenerationFieldModel extends NumberField {
  @computed
  get info(): RegenerationInfo {
    const value = this.final;

    const info = calculateRegenerationInfo(value);

    if (this.character?.isNephilim(Turak)) {
      info.specialCapabilities +=
        ' (Turak) Las extremidades cercioradas crecen a lo largo de año o año y medio';
    }

    const hasSlowHealing =
      this.character &&
      this.character.creationPoints.containsDisadvantage(
        SlowHealingDisadvantage,
      );

    info.lifeRegeneratedResting.value = hasSlowHealing
      ? Math.floor(info.lifeRegeneratedResting.value / 2)
      : info.lifeRegeneratedResting.value;

    if (info.lifeRegeneratedWithoutResting) {
      info.lifeRegeneratedWithoutResting.value = hasSlowHealing
        ? Math.floor(info.lifeRegeneratedWithoutResting.value / 2)
        : info.lifeRegeneratedWithoutResting.value;
    }

    return info;
  }

  getCalculations(): Calculation[] {
    if (!this.character) return [];

    const calculations: Calculation[] = [];

    const base = calculateRegeneration(
      this.character.primaries.constitution.final,
    );

    calculations.push(this.sum('Por constitución', base));

    calculations.push(this.sum('Especial', this.special));
    if (
      this.character.creationPoints.containsAdvantage(RegenerationAdvantage, {
        level: AdvantageLevel1,
      })
    ) {
      calculations.push(this.sum('Regeneración (nivel 1)', 2));
    }

    if (
      this.character.creationPoints.containsAdvantage(RegenerationAdvantage, {
        level: AdvantageLevel2,
      })
    ) {
      calculations.push(this.sum('Regeneración (nivel 2)', 4));
    }

    if (
      this.character.creationPoints.containsAdvantage(RegenerationAdvantage, {
        level: AdvantageLevel3,
      })
    ) {
      calculations.push(this.sum('Regeneración (nivel 3)', 6));
    }

    if (this.character.isNephilim(NephilimSylvain)) {
      calculations.push(this.sum(NephilimSylvain.name, 1));
    }

    if (this.character.isNephilim(NephilimDukZaristMale)) {
      calculations.push(this.sum(NephilimDukZaristMale.name, 1));
    }

    if (this.character.isNephilim(NephilimDukZaristFemale)) {
      calculations.push(this.sum(NephilimDukZaristFemale.name, 1));
    }

    if (this.character.isNephilim(Turak)) {
      calculations.push(this.sum(Turak.name, 1));
    }

    if (this.character.isNephilim(Sylvain)) {
      calculations.push(this.sum(Sylvain.name, 3));
    }

    if (this.character.isNephilim(DukZaristMale)) {
      calculations.push(this.sum(DukZaristMale.name, 5));
    }

    if (this.character.isNephilim(DukZaristFemale)) {
      calculations.push(this.sum(DukZaristFemale.name, 5));
    }

    calculations.push(this.min('No puede ser negativo', 0, calculations));

    return calculations;
  }
}
