import { v4 as uuid } from 'uuid';
import { MagicPath } from '../../../../../../character/domain/aggregations/magic-path/MagicPath';
import { getMetamagicFromId } from '../../../../../../character/domain/aggregations/metamagic/Metamagic';
import { ActorMutator } from '../../../ActorCharacterTransformer';
import { FoundryVTTActor } from '../FoundryVTTActor';
import { ABFItems } from '../FoundryVTTActor.types';
import { createBaseFinalField } from './utils/createBaseFinalField';

export const mutateActorMystic: ActorMutator<FoundryVTTActor> = ({
  actor,
  character,
}) => {
  actor.data.mystic = {
    ...actor.data.mystic,
    act: {
      ...actor.data.mystic.act,
      main: createBaseFinalField(
        character.content.mystic.act.finalWithoutPenalties,
        character.content.mystic.act.finalWithoutPenalties,
      ),
    },
    innateMagic: {
      ...actor.data.mystic.innateMagic,
      main: {
        value: character.content.mystic.innateMagic.final,
      },
    },
    magicLevel: {
      total: {
        value: character.content.mystic.magicLevel.finalWithoutPenalties,
      },
      used: {
        value: character.content.mystic.usedMagicLevel,
      },
      spheres: {
        air: {
          value:
            character.content.mystic.paths.find((p) => p.path === MagicPath.Air)
              ?.level.final ?? 0,
        },
        creation: {
          value:
            character.content.mystic.paths.find(
              (p) => p.path === MagicPath.Creation,
            )?.level.final ?? 0,
        },
        darkness: {
          value:
            character.content.mystic.paths.find(
              (p) => p.path === MagicPath.Darkness,
            )?.level.final ?? 0,
        },
        earth: {
          value:
            character.content.mystic.paths.find(
              (p) => p.path === MagicPath.Earth,
            )?.level.final ?? 0,
        },
        destruction: {
          value:
            character.content.mystic.paths.find(
              (p) => p.path === MagicPath.Destruction,
            )?.level.final ?? 0,
        },
        essence: {
          value:
            character.content.mystic.paths.find(
              (p) => p.path === MagicPath.Essence,
            )?.level.final ?? 0,
        },
        fire: {
          value:
            character.content.mystic.paths.find(
              (p) => p.path === MagicPath.Fire,
            )?.level.final ?? 0,
        },
        illusion: {
          value:
            character.content.mystic.paths.find(
              (p) => p.path === MagicPath.Illusion,
            )?.level.final ?? 0,
        },
        light: {
          value:
            character.content.mystic.paths.find(
              (p) => p.path === MagicPath.Light,
            )?.level.final ?? 0,
        },
        necromancy: {
          value:
            character.content.mystic.paths.find(
              (p) => p.path === MagicPath.Necromancy,
            )?.level.final ?? 0,
        },
        water: {
          value:
            character.content.mystic.paths.find(
              (p) => p.path === MagicPath.Water,
            )?.level.final ?? 0,
        },
      },
    },
    magicProjection: {
      base: {
        value: character.content.mystic.magicProjection.finalWithoutPenalties,
      },
      final: {
        value: character.content.mystic.magicProjection.finalWithoutPenalties,
      },
      imbalance: {
        offensive: createBaseFinalField(
          character.content.mystic.offensiveMagicProjection,
          character.content.mystic.offensiveMagicProjection,
        ),
        defensive: createBaseFinalField(
          character.content.mystic.defensiveMagicProjection,
          character.content.mystic.defensiveMagicProjection,
        ),
      },
    },
    metamagics: character.content.mystic.metamagics
      .map(getMetamagicFromId)
      .map((m) => ({
        _id: uuid(),
        type: ABFItems.METAMAGIC,
        name: m.name,
        data: {
          grade: {
            value: m.cost,
          },
        },
      })),
    zeon: {
      value: actor.data.mystic.zeon.value,
      max: character.content.mystic.zeon.final,
      accumulated: actor.data.mystic.zeon.value,
    },
    summons: character.content.mystic.summons.map((s) => ({
      _id: uuid(),
      type: ABFItems.SUMMON,
      name: s.name,
      data: {},
    })),
    summoning: {
      banish: createBaseFinalField(
        character.content.mystic.summoning.banish.finalWithoutPenalties,
      ),
      summon: createBaseFinalField(
        character.content.mystic.summoning.summon.finalWithoutPenalties,
      ),
      bind: createBaseFinalField(
        character.content.mystic.summoning.bind.finalWithoutPenalties,
      ),
      control: createBaseFinalField(
        character.content.mystic.summoning.control.finalWithoutPenalties,
      ),
    },
    zeonRegeneration: createBaseFinalField(
      character.content.mystic.zeonRegeneration.finalWithoutPenalties,
    ),
    selectedSpells: character.content.mystic.selectedSpells.map((s) => ({
      _id: uuid(),
      type: ABFItems.SELECTED_SPELL,
      name: s.name,
      data: {
        cost: {
          value: s.level,
        },
      },
    })),
  };

  return { actor };
};
