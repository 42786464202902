import { CharacterCollaborationService } from '../../../../infrastructure/CharacterCollaborationService';
import { useState } from 'react';
import { NotificationManager } from '../../../../../shared/application/shared/NotificationManager';
import { decodeApiErrorMessage } from '../../../../../shared/infrastructure/api/decodeApiErrorMessage';
import { TrackingService } from '../../../../../shared/infrastructure/tracking/TrackingService';

export const useRemoveCharacterCollaboration = () => {
  const [removingCharacterCollaboration, setRemovingCharacterCollaboration] =
    useState(false);

  const removeCharacterCollaboration = async ({
    characterId,
    collaborationId,
  }: {
    characterId: string;
    collaborationId: string;
  }) => {
    setRemovingCharacterCollaboration(true);

    try {
      await CharacterCollaborationService.removeCharacterCollaboration({
        characterId: characterId,
        collaborationId: collaborationId,
      });

      TrackingService.sendEvent('character_collaboration_removed', {
        characterId: characterId,
      })
    } catch (e) {
      NotificationManager.error(decodeApiErrorMessage(e));
    } finally {
      setRemovingCharacterCollaboration(false);
    }
  };

  return {
    removeCharacterCollaboration,
    removingCharacterCollaboration,
  };
};
