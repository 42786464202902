import { observer } from 'mobx-react';
import DoubleInput from '../../../common/DoubleInput/DoubleInput';
import { Input } from '../../../common/Input/Input';
import { Section } from '../../../common/Section/Section';
import { useCharacterContext } from '../../../../hooks/useCharacterContext';

export const MagicZeon = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  return (
    <Section maxHeight="fit">
      <Section.Title>Zeón</Section.Title>
      <DoubleInput label="Total" field={character.mystic.zeon} />
      <Input
        label="Contenedor"
        value={character.mystic.zeonContainer}
        setValue={(v) => character.mystic.setZeonContainer(v)}
      />
      <Input
        label="Amplificador"
        value={character.mystic.zeonAmplifier}
        setValue={(v) => character.mystic.setZeonAmplifier(v)}
      />
    </Section>
  );
});
