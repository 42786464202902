import { model } from 'mobx-keystone';
import { BaseFinalFieldModel } from '../../../utils/fields/BaseFinalFieldModel';
import { ArmorItemModel } from '../../../../../aggregations/armors/ArmorItemModel';
import { ArmorType } from '../../../../../aggregations/armors/Armor.types';

@model('WearArmorRequirement')
export class WearArmorRequirementModel extends BaseFinalFieldModel {
  get penaltyOfEquippedArmors(): number {
    if (!this.character) return 0;

    const value = this.getNonNaturalArmors().reduce(
      (prev, curr) => prev + curr.wearArmorRequirement.final,
      0,
    );

    return value;
  }

  get bonusByWearArmorAbility(): number {
    if (!this.character) return 0;

    const penalty = this.penaltyOfEquippedArmors;

    const ability = this.character.combat.wearArmorAbility.final;

    return Math.max(0, ability - penalty);
  }

  get final(): number {
    if (!this.character) return 0;

    const value = this.base + this.special + this.penaltyOfEquippedArmors;

    return value;
  }

  private getNonNaturalArmors(): ArmorItemModel[] {
    if (!this.character) return [];

    const nonNaturalArmors = this.character.inventory.equippedArmors.filter(
      (a) => a.type !== ArmorType.Natural,
    );
    return nonNaturalArmors;
  }
}
