import { ArmorLocation, ArmorType } from './Armor.types';

export type PredefinedArmor = {
  id: string;
  name: string;
  wearArmorRequirement: number;
  naturalPenalty: number;
  movementRestriction: number;
  integrity: number;
  presence: number;
  localization: ArmorLocation;
  type: ArmorType;
  at: {
    cut: number;
    impact: number;
    thrust: number;
    heat: number;
    electricity: number;
    cold: number;
    energy: number;
  };
};

export const PredefinedArmors: PredefinedArmor[] = [
  {
    id: '1',
    name: 'Acolchada',
    wearArmorRequirement: 0,
    naturalPenalty: -5,
    movementRestriction: 0,
    integrity: 10,
    presence: 25,
    localization: ArmorLocation.Nightdress,
    type: ArmorType.Soft,
    at: {
      cut: 1,
      impact: 1,
      thrust: 1,
      heat: 1,
      electricity: 2,
      cold: 2,
      energy: 0,
    },
  },
  {
    id: '2',
    name: 'Cota de cuero',
    wearArmorRequirement: 0,
    naturalPenalty: 0,
    movementRestriction: 0,
    integrity: 12,
    presence: 25,
    localization: ArmorLocation.Nightdress,
    type: ArmorType.Soft,
    at: {
      cut: 1,
      impact: 0,
      thrust: 2,
      heat: 1,
      electricity: 2,
      cold: 1,
      energy: 0,
    },
  },
  {
    id: '3',
    name: 'Gabardina',
    wearArmorRequirement: 0,
    naturalPenalty: -5,
    movementRestriction: 0,
    integrity: 10,
    presence: 25,
    localization: ArmorLocation.Complete,
    type: ArmorType.Soft,
    at: {
      cut: 1,
      impact: 0,
      thrust: 2,
      heat: 1,
      electricity: 2,
      cold: 2,
      energy: 0,
    },
  },
  {
    id: '4',
    name: 'Perpunte',
    wearArmorRequirement: 10,
    naturalPenalty: -10,
    movementRestriction: 0,
    integrity: 11,
    presence: 25,
    localization: ArmorLocation.Nightdress,
    type: ArmorType.Soft,
    at: {
      cut: 2,
      impact: 1,
      thrust: 2,
      heat: 0,
      electricity: 2,
      cold: 2,
      energy: 0,
    },
  },
  {
    id: '5',
    name: 'Piel',
    wearArmorRequirement: 10,
    naturalPenalty: -10,
    movementRestriction: 0,
    integrity: 10,
    presence: 25,
    localization: ArmorLocation.Nightdress,
    type: ArmorType.Soft,
    at: {
      cut: 2,
      impact: 1,
      thrust: 2,
      heat: 1,
      electricity: 2,
      cold: 2,
      energy: 0,
    },
  },
  {
    id: '6',
    name: 'Completa cuero',
    wearArmorRequirement: 10,
    naturalPenalty: 0,
    movementRestriction: 1,
    integrity: 12,
    presence: 25,
    localization: ArmorLocation.Complete,
    type: ArmorType.Soft,
    at: {
      cut: 1,
      impact: 0,
      thrust: 2,
      heat: 1,
      electricity: 2,
      cold: 1,
      energy: 0,
    },
  },
  {
    id: '7',
    name: 'Laminar',
    wearArmorRequirement: 20,
    naturalPenalty: -10,
    movementRestriction: 0,
    integrity: 12,
    presence: 25,
    localization: ArmorLocation.Nightdress,
    type: ArmorType.Soft,
    at: {
      cut: 3,
      impact: 1,
      thrust: 1,
      heat: 0,
      electricity: 2,
      cold: 2,
      energy: 0,
    },
  },
  {
    id: '8',
    name: 'Brigantina',
    wearArmorRequirement: 25,
    naturalPenalty: -10,
    movementRestriction: 0,
    integrity: 12,
    presence: 25,
    localization: ArmorLocation.Nightdress,
    type: ArmorType.Soft,
    at: {
      cut: 3,
      impact: 2,
      thrust: 3,
      heat: 1,
      electricity: 0,
      cold: 1,
      energy: 0,
    },
  },
  {
    id: '9',
    name: 'Mallas',
    wearArmorRequirement: 30,
    naturalPenalty: -15,
    movementRestriction: 1,
    integrity: 15,
    presence: 30,
    localization: ArmorLocation.Complete,
    type: ArmorType.Soft,
    at: {
      cut: 4,
      impact: 2,
      thrust: 1,
      heat: 2,
      electricity: 0,
      cold: 1,
      energy: 0,
    },
  },
  {
    id: '10',
    name: 'Anillas',
    wearArmorRequirement: 60,
    naturalPenalty: -20,
    movementRestriction: 2,
    integrity: 15,
    presence: 30,
    localization: ArmorLocation.Nightdress,
    type: ArmorType.Soft,
    at: {
      cut: 4,
      impact: 3,
      thrust: 1,
      heat: 2,
      electricity: 0,
      cold: 1,
      energy: 0,
    },
  },
  {
    id: '11',
    name: 'Cuero endurecido',
    wearArmorRequirement: 20,
    naturalPenalty: -10,
    movementRestriction: 0,
    integrity: 13,
    presence: 25,
    localization: ArmorLocation.Breastplate,
    type: ArmorType.Hard,
    at: {
      cut: 2,
      impact: 2,
      thrust: 2,
      heat: 2,
      electricity: 2,
      cold: 2,
      energy: 0,
    },
  },
  {
    id: '12',
    name: 'Cuero tachonado',
    wearArmorRequirement: 25,
    naturalPenalty: -10,
    movementRestriction: 1,
    integrity: 14,
    presence: 25,
    localization: ArmorLocation.Breastplate,
    type: ArmorType.Hard,
    at: {
      cut: 3,
      impact: 1,
      thrust: 2,
      heat: 2,
      electricity: 1,
      cold: 2,
      energy: 0,
    },
  },
  {
    id: '13',
    name: 'Segmentada',
    wearArmorRequirement: 30,
    naturalPenalty: -15,
    movementRestriction: 0,
    integrity: 13,
    presence: 25,
    localization: ArmorLocation.Nightdress,
    type: ArmorType.Hard,
    at: {
      cut: 4,
      impact: 3,
      thrust: 3,
      heat: 2,
      electricity: 0,
      cold: 2,
      energy: 0,
    },
  },
  {
    id: '14',
    name: 'Peto',
    wearArmorRequirement: 40,
    naturalPenalty: -15,
    movementRestriction: 1,
    integrity: 16,
    presence: 30,
    localization: ArmorLocation.Breastplate,
    type: ArmorType.Hard,
    at: {
      cut: 4,
      impact: 5,
      thrust: 4,
      heat: 1,
      electricity: 0,
      cold: 1,
      energy: 0,
    },
  },
  {
    id: '15',
    name: 'Piezas',
    wearArmorRequirement: 50,
    naturalPenalty: -20,
    movementRestriction: 2,
    integrity: 15,
    presence: 30,
    localization: ArmorLocation.Complete,
    type: ArmorType.Hard,
    at: {
      cut: 4,
      impact: 3,
      thrust: 2,
      heat: 3,
      electricity: 2,
      cold: 2,
      energy: 0,
    },
  },
  {
    id: '16',
    name: 'Semicompleta',
    wearArmorRequirement: 70,
    naturalPenalty: -20,
    movementRestriction: 3,
    integrity: 16,
    presence: 35,
    localization: ArmorLocation.Complete,
    type: ArmorType.Hard,
    at: {
      cut: 4,
      impact: 4,
      thrust: 4,
      heat: 2,
      electricity: 0,
      cold: 1,
      energy: 1,
    },
  },
  {
    id: '17',
    name: 'Escamas',
    wearArmorRequirement: 80,
    naturalPenalty: -25,
    movementRestriction: 3,
    integrity: 17,
    presence: 35,
    localization: ArmorLocation.Complete,
    type: ArmorType.Hard,
    at: {
      cut: 4,
      impact: 4,
      thrust: 4,
      heat: 3,
      electricity: 0,
      cold: 3,
      energy: 1,
    },
  },
  {
    id: '18',
    name: 'Placas',
    wearArmorRequirement: 90,
    naturalPenalty: -35,
    movementRestriction: 4,
    integrity: 17,
    presence: 40,
    localization: ArmorLocation.Complete,
    type: ArmorType.Hard,
    at: {
      cut: 5,
      impact: 4,
      thrust: 5,
      heat: 3,
      electricity: 0,
      cold: 3,
      energy: 1,
    },
  },
  {
    id: '19',
    name: 'Completa',
    wearArmorRequirement: 100,
    naturalPenalty: -50,
    movementRestriction: 4,
    integrity: 18,
    presence: 45,
    localization: ArmorLocation.Complete,
    type: ArmorType.Hard,
    at: {
      cut: 5,
      impact: 5,
      thrust: 5,
      heat: 4,
      electricity: 0,
      cold: 4,
      energy: 2,
    },
  },
  {
    id: '20',
    name: 'Completa pesada',
    wearArmorRequirement: 120,
    naturalPenalty: -60,
    movementRestriction: 5,
    integrity: 19,
    presence: 45,
    localization: ArmorLocation.Complete,
    type: ArmorType.Hard,
    at: {
      cut: 6,
      impact: 6,
      thrust: 6,
      heat: 4,
      electricity: 0,
      cold: 4,
      energy: 2,
    },
  },
  {
    id: '21',
    name: 'Campaña pesada',
    wearArmorRequirement: 150,
    naturalPenalty: -70,
    movementRestriction: 6,
    integrity: 20,
    presence: 50,
    localization: ArmorLocation.Complete,
    type: ArmorType.Hard,
    at: {
      cut: 7,
      impact: 7,
      thrust: 7,
      heat: 4,
      electricity: 0,
      cold: 4,
      energy: 2,
    },
  },
  {
    id: '22',
    name: 'Kozan-do',
    wearArmorRequirement: 25,
    naturalPenalty: -10,
    movementRestriction: 0,
    integrity: 13,
    presence: 25,
    localization: ArmorLocation.Nightdress,
    type: ArmorType.Soft,
    at: {
      cut: 3,
      impact: 1,
      thrust: 1,
      heat: 2,
      electricity: 0,
      cold: 1,
      energy: 0,
    },
  },
  {
    id: '23',
    name: 'Keiko',
    wearArmorRequirement: 30,
    naturalPenalty: -10,
    movementRestriction: 0,
    integrity: 14,
    presence: 25,
    localization: ArmorLocation.Complete,
    type: ArmorType.Hard,
    at: {
      cut: 3,
      impact: 2,
      thrust: 2,
      heat: 2,
      electricity: 0,
      cold: 1,
      energy: 0,
    },
  },
  {
    id: '24',
    name: 'O-Yoroi',
    wearArmorRequirement: 30,
    naturalPenalty: -10,
    movementRestriction: 1,
    integrity: 15,
    presence: 30,
    localization: ArmorLocation.Complete,
    type: ArmorType.Hard,
    at: {
      cut: 3,
      impact: 2,
      thrust: 3,
      heat: 2,
      electricity: 2,
      cold: 2,
      energy: 0,
    },
  },
  {
    id: '25',
    name: 'Tanko',
    wearArmorRequirement: 35,
    naturalPenalty: -15,
    movementRestriction: 1,
    integrity: 14,
    presence: 25,
    localization: ArmorLocation.Nightdress,
    type: ArmorType.Hard,
    at: {
      cut: 4,
      impact: 2,
      thrust: 3,
      heat: 1,
      electricity: 0,
      cold: 1,
      energy: 0,
    },
  },
  {
    id: '26',
    name: 'Anilla',
    wearArmorRequirement: 0,
    naturalPenalty: 0,
    movementRestriction: 8,
    integrity: 15,
    presence: 0,
    localization: ArmorLocation.Head,
    type: ArmorType.Hard,
    at: {
      cut: 2,
      impact: 1,
      thrust: 1,
      heat: 1,
      electricity: 1,
      cold: 0,
      energy: 0,
    },
  },
  {
    id: '27',
    name: 'Capucha Cuero',
    wearArmorRequirement: 0,
    naturalPenalty: 0,
    movementRestriction: 10,
    integrity: 15,
    presence: 0,
    localization: ArmorLocation.Head,
    type: ArmorType.Soft,
    at: {
      cut: 0,
      impact: 2,
      thrust: 1,
      heat: 3,
      electricity: 1,
      cold: 0,
      energy: 0,
    },
  },
  {
    id: '28',
    name: 'Capucha de Malla',
    wearArmorRequirement: 0,
    naturalPenalty: -10,
    movementRestriction: 13,
    integrity: 20,
    presence: 0,
    localization: ArmorLocation.Head,
    type: ArmorType.Hard,
    at: {
      cut: 2,
      impact: 1,
      thrust: 2,
      heat: 0,
      electricity: 1,
      cold: 0,
      energy: 0,
    },
  },
  {
    id: '29',
    name: 'Coronilla',
    wearArmorRequirement: 0,
    naturalPenalty: 0,
    movementRestriction: 12,
    integrity: 15,
    presence: 0,
    localization: ArmorLocation.Head,
    type: ArmorType.Hard,
    at: {
      cut: 4,
      impact: 3,
      thrust: 2,
      heat: 0,
      electricity: 3,
      cold: 0,
      energy: 0,
    },
  },
  {
    id: '30',
    name: 'Frentón',
    wearArmorRequirement: 0,
    naturalPenalty: 0,
    movementRestriction: 12,
    integrity: 15,
    presence: 0,
    localization: ArmorLocation.Head,
    type: ArmorType.Hard,
    at: {
      cut: 3,
      impact: 3,
      thrust: 1,
      heat: 1,
      electricity: 2,
      cold: 0,
      energy: 0,
    },
  },
  {
    id: '31',
    name: 'Yelmo Abierto',
    wearArmorRequirement: 5,
    naturalPenalty: -20,
    movementRestriction: 16,
    integrity: 25,
    presence: 0,
    localization: ArmorLocation.Head,
    type: ArmorType.Hard,
    at: {
      cut: 4,
      impact: 5,
      thrust: 3,
      heat: 0,
      electricity: 3,
      cold: 1,
      energy: 0,
    },
  },
  {
    id: '32',
    name: 'Yelmo Cerrado',
    wearArmorRequirement: 10,
    naturalPenalty: -30,
    movementRestriction: 16,
    integrity: 25,
    presence: 0,
    localization: ArmorLocation.Head,
    type: ArmorType.Hard,
    at: {
      cut: 5,
      impact: 5,
      thrust: 4,
      heat: 0,
      electricity: 4,
      cold: 2,
      energy: 0,
    },
  },
];
