export enum SecondarySkillBonusType {
  Agility = 'agility',
  Strength = 'strength',
  Dexterity = 'dexterity',
  Perceptive = 'perceptive',
  Intelligence = 'intelligence',
  Constitution = 'constitution',
  Power = 'power',
  WillPower = 'willPower',
}
