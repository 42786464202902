import styled from 'styled-components';

export const StyledTable = styled.table({
  borderSpacing: '0.5rem',
  tableLayout: 'auto',
  width: '100%',
});

export const TableHead = styled.thead({
  borderSpacing: '0.5rem',
});

export const TableBody = styled.tbody({
  borderSpacing: '0.5rem',
});
