import {
  mapMartialArtWeaponTypeToTable,
  MartialArtWeaponTable,
  MartialArtWeaponTableType,
} from './MartialArtWeaponTable';

export const getMartialArtWeaponTableFromType = (
  type: MartialArtWeaponTableType,
): MartialArtWeaponTable => {
  return new mapMartialArtWeaponTypeToTable[type]();
};
