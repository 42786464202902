import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class MoaiThaiMartialArt extends BasicMartialArt {
  readonly name = 'Moai Thai';

  readonly complexity = 1;

  readonly type = MartialArtType.MoaiThai;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final * 2 + 20;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.vigor.featsOfStrength.final < 30) {
          errors.push('Proezas de fuerza 30+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final * 3 + 20;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.vigor.featsOfStrength.final < 40) {
          errors.push('Proezas de fuerza 40+');
        }

        if (character.combat.attackAbility.final < 130) {
          errors.push('Ataque 130+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      effects:
        '+20 a la tirada para calcular nivel de Crítico que use artes marciales',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final * 4 + 20;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.vigor.featsOfStrength.final < 160) {
          errors.push('Proezas de fuerza 160+');
        }

        if (character.combat.attackAbility.final < 200) {
          errors.push('Ataque 200+');
        }

        return errors;
      },
    }),
  ];
}
