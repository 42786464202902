import { model, Model, prop } from 'mobx-keystone';
import { AthleticSkillsModel } from './parts/AthleticSkillsModel/AthleticSkillsModel';
import { CreativeSkillsModel } from './parts/CreativeSkillsModel/CreativeSkillsModel';
import { IntellectualSkillsModel } from './parts/IntellectualSkillsModel/IntellectualSkillsModel';
import { PerceptiveSkillsModel } from './parts/PerceptiveSkillsModel/PerceptiveSkillsModel';
import { SocialSkillsModel } from './parts/SocialSkillsModel/SocialSkillsModel';
import { SubterfugeSkillsModel } from './parts/SubterfugeSkillsModel/SubterfugeSkillsModel';
import { VigorSkillsModel } from './parts/VigorSkillsModel/VigorSkillsModel';
import { getParentCharacter } from '../../../../utils/parenting/getParentCharacter';

@model('Character/Secondaries')
export class SecondariesSkillsModel extends Model({
  athletic: prop(() => new AthleticSkillsModel({})),
  social: prop(() => new SocialSkillsModel({})),
  perceptive: prop(() => new PerceptiveSkillsModel({})),
  intellectual: prop(() => new IntellectualSkillsModel({})),
  vigor: prop(() => new VigorSkillsModel({})),
  subterfuge: prop(() => new SubterfugeSkillsModel({})),
  creative: prop(() => new CreativeSkillsModel({})),
}) {
  get usedPhysicalNaturalBonusPoints(): number {
    return (
      this.athletic.usedPhysicalNaturalBonusPoints +
      this.vigor.usedPhysicalNaturalBonusPoints +
      this.perceptive.usedPhysicalNaturalBonusPoints +
      this.intellectual.usedPhysicalNaturalBonusPoints +
      this.subterfuge.usedPhysicalNaturalBonusPoints +
      this.creative.usedPhysicalNaturalBonusPoints
    );
  }

  get maximumPhysicalNaturalBonusPoints(): number {
    if (!this.character) return 0;

    return this.character.level;
  }

  get usedMentalNaturalBonusPoints(): number {
    return (
      this.athletic.usedMentalNaturalBonusPoints +
      this.vigor.usedMentalNaturalBonusPoints +
      this.social.usedMentalNaturalBonusPoints +
      this.perceptive.usedMentalNaturalBonusPoints +
      this.intellectual.usedMentalNaturalBonusPoints +
      this.subterfuge.usedMentalNaturalBonusPoints +
      this.creative.usedMentalNaturalBonusPoints
    );
  }

  get maximumMentalNaturalBonusPoints(): number {
    if (!this.character) return 0;

    return this.character.level;
  }

  get usedNaturalAbilityPoints(): number {
    return (
      this.athletic.usedNaturalAbilityPoints +
      this.vigor.usedNaturalAbilityPoints +
      this.social.usedNaturalAbilityPoints +
      this.perceptive.usedNaturalAbilityPoints +
      this.intellectual.usedNaturalAbilityPoints +
      this.subterfuge.usedNaturalAbilityPoints +
      this.creative.usedNaturalAbilityPoints
    );
  }

  get maximumNaturalAbilityPoints(): number {
    if (!this.character) return 0;

    return this.character.level * 5;
  }

  get usedNovelBonusPoints(): number {
    return (
      this.athletic.usedNovelBonusPoints +
      this.vigor.usedNovelBonusPoints +
      this.social.usedNovelBonusPoints +
      this.perceptive.usedNovelBonusPoints +
      this.intellectual.usedNovelBonusPoints +
      this.subterfuge.usedNovelBonusPoints +
      this.creative.usedNovelBonusPoints
    );
  }

  get maximumNovelBonusPoints(): number {
    if (!this.character) return 0;

    return this.character.level * 5;
  }

  get character() {
    return getParentCharacter(this);
  }
}
