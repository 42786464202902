import { WeaponItemModel } from '../../../../../../../../../../../../../../domain/aggregations/weapon/WeaponItemModel';
import { observer } from 'mobx-react';
import {
  WeaponManageabilityType,
  weaponManageabilityTypeTranslations,
} from '../../../../../../../../../../../../../../domain/aggregations/weapon/Weapon.types';
import { transformToMenuItems } from '../../../../../../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { Selector } from '../../../../../../../../common/Selector/Selector';

export const ManageabilityTypeSelector = observer(
  ({ weapon }: { weapon: WeaponItemModel }) => {
    const items = transformToMenuItems({
      fromItems: Object.values(WeaponManageabilityType),
      generateItem: (manageabilityType) => ({
        key: manageabilityType,
        name: weaponManageabilityTypeTranslations[manageabilityType],
        value: manageabilityType,
        onClick: () => {
          weapon.setManageabilityType(manageabilityType);
        },
      }),
    });

    return (
      <Selector
        value={weapon.manageabilityType}
        valueAsText={
          weaponManageabilityTypeTranslations[weapon.manageabilityType]
        }
        items={items}
      />
    );
  },
);
