import { CSSObject, CSSPseudos } from 'styled-components';

export enum Breakpoint {
  xs = 'xs',
  sm = 'sm',
  ipad = 'ipad',
  md = 'md',
  lg = 'lg',
  xlg = 'xlg',
  wide = 'wide',
}

export interface Direction {
  downward: (css: CSSObject) => CSSPseudos;
  upward: (css: CSSObject) => CSSPseudos;
  between: (breakpoint: Breakpoint, css: CSSObject) => CSSPseudos;
}

export type Responsive = Record<Breakpoint, Direction> & {
  custom: (px: string) => Direction;
};

export const breakpoints = {
  xs: '575px', // mobile
  sm: '767px', // tablet
  ipad: '769px', // ipad
  md: '991px', // laptop
  lg: '1200px', // desktop,
  xlg: '1600px', // desktop
  wide: '2100px', // desktop
};

const res: any = {};

const directions = (size: string): Direction => ({
  downward: (css) => ({
    [`@media (max-width: ${size})`]: css,
  }),
  upward: (css) => ({
    [`@media (min-width: ${size})`]: css,
  }),
  between: (breakpoint, css) => ({
    [`@media (max-width: ${size}) and (min-width: ${breakpoints[breakpoint]})`]:
      css,
  }),
});

Object.entries(breakpoints).forEach(([breakname, breakpoint]) => {
  res[breakname] = directions(breakpoint);
});

res.custom = (px: string) => directions(`${px}px`);

export const responsive: Responsive = res;
