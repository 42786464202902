import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';
import { getMysticTableFromType } from '../../../../aggregations/tables/mystic-tables/getMysticTableFromType';
import {
  MysticTable,
  MysticTableType,
} from '../../../../aggregations/tables/mystic-tables/MysticTable';

@model('MysticTable')
export class MysticTableModel extends Model({
  type: prop<MysticTableType>(
    MysticTableType.AttackAsMagicProjection,
  ).withSetter(),
}) {
  @computed
  get table(): MysticTable {
    return getMysticTableFromType(this.type);
  }

  @computed
  get name(): string {
    return this.table.name;
  }

  @computed
  get cost(): number {
    return this.table.cost;
  }

  @computed
  get effect(): string {
    return this.table.effect;
  }
}
