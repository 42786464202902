import DoubleInput from '../../../../common/DoubleInput/DoubleInput';
import { Section } from '../../../../common/Section/Section';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';

export const CharacterResistances = () => {
  const {
    character: { content: character },
  } = useCharacterContext();

  return (
    <Section maxHeight="fit">
      <Section.Title>Resistencias</Section.Title>
      <DoubleInput label="Física" field={character.resistances.physical} />
      <DoubleInput label="Enfermedad" field={character.resistances.disease} />
      <DoubleInput label="Veneno" field={character.resistances.poison} />
      <DoubleInput label="Mágica" field={character.resistances.magic} />
      <DoubleInput label="Psíquica" field={character.resistances.psychic} />
    </Section>
  );
};
