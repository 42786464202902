import { ExtendedModel, prop } from 'mobx-keystone';
import {
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
} from '../../../../../../aggregations/advantage/Advantage.types';
import {
  AllFieldsSkilledAdvantage,
  NaturalLearningAdvantage,
  NaturalLearningInOneFieldAdvantage,
} from '../../../../../../aggregations/advantage/CommonAdvantages';
import { CategoryType } from '../../../../../../aggregations/category-templates/CategoryTemplate';
import {
  getCategoryForSecondarySkill,
  SecondarySkill,
} from '../../../../types/SecondarySkill';
import { BaseFinalFieldModel } from '../../../../utils/fields/BaseFinalFieldModel';
import { Calculation } from '../../../../utils/fields/NumberField';
import { SecondarySkillPDFieldModel } from '../../../pd/parts/categories/parts/secondaries/SecondarySkillPDFieldModel';
import { NaturalBonusPoints } from './NaturalBonusPoints';

export abstract class SecondarySkillFieldModel extends ExtendedModel(
  BaseFinalFieldModel,
  {
    naturalBonusPoints: prop(() => new NaturalBonusPoints({})),
    naturalAbilityPoints: prop(0).withSetter(),
    novelBonus: prop(0).withSetter(),
  },
) {
  public readonly needToBeDeveloped: boolean = false;

  abstract type: SecondarySkill;

  abstract get fromCategories(): number;

  get initialPenalty(): number {
    if (
      this.character?.creationPoints.containsAdvantage(
        AllFieldsSkilledAdvantage,
      )
    ) {
      return 0;
    }

    if (!this.isDeveloped) {
      return -30;
    }

    return 0;
  }

  get allActionsPenalty(): number {
    if (!this.character) return 0;

    return this.character.modifiers.allActions.final;
  }

  get isDeveloped(): boolean {
    return this.getPDFields().some((f) => f.isDeveloped);
  }

  get allBonuses(): number {
    return this.calculateFromCalculations(this.allBonusesCalculations);
  }

  get bonusFromAdvantages(): Calculation[] {
    if (!this.character) return [];

    const calculations: Calculation[] = [];

    const naturalLearningAdvantage = this.character.creationPoints.getAdvantage(
      NaturalLearningAdvantage,
    );

    if (naturalLearningAdvantage?.variationId === this.type) {
      if (naturalLearningAdvantage.levelId === AdvantageLevel1.id) {
        calculations.push(this.sum('Aprendizaje natural (Nivel 1)', 10));
      }

      if (naturalLearningAdvantage.levelId === AdvantageLevel2.id) {
        calculations.push(this.sum('Aprendizaje natural (Nivel 2)', 20));
      }
    }

    const naturalLearningInOneFieldAdvantage =
      this.character.creationPoints.getAdvantage(
        NaturalLearningInOneFieldAdvantage,
      );

    if (
      naturalLearningInOneFieldAdvantage?.variationId ===
      getCategoryForSecondarySkill(this.type)
    ) {
      if (naturalLearningInOneFieldAdvantage.levelId === AdvantageLevel2.id) {
        calculations.push(
          this.sum('Aprendizaje natural en un campo (Nivel 1)', 5),
        );
      }

      if (naturalLearningInOneFieldAdvantage.levelId === AdvantageLevel3.id) {
        calculations.push(
          this.sum('Aprendizaje natural en un campo (Nivel 2)', 10),
        );
      }
    }

    if (
      this.character.creationPoints.containsAdvantage(AllFieldsSkilledAdvantage)
    ) {
      calculations.push(this.sum('Conocedor de todas las materias', 10));
    }

    return calculations;
  }

  abstract get primariesBonus(): number;

  private get allBonusesCalculations(): Calculation[] {
    const calculations: Calculation[] = [];

    calculations.push(this.sum('Bonus de primaria', this.primariesBonus));

    calculations.push(...this.bonusFromAdvantages);

    calculations.push(
      this.sum(
        'Puntos de bono natural',
        this.primariesBonus * this.naturalBonusPoints.final,
      ),
    );

    calculations.push(
      this.sum('Puntos de habilidad natural', 10 * this.naturalAbilityPoints),
    );

    return calculations;
  }

  private get isNovel() {
    if (!this.character) return false;

    return this.character.pd.categories.some(
      (c) => c.template.type === CategoryType.Novel,
    );
  }

  abstract getPDFields(): SecondarySkillPDFieldModel[];

  getCalculations(): Calculation[] {
    const calculations = super.getCalculations();

    calculations.push(this.sum('Penalizador inicial', this.initialPenalty));

    calculations.push(this.sum('Especial', this.special));

    calculations.push(this.sum('Por categoría', this.fromCategories));

    calculations.push(...this.allBonusesCalculations);
    calculations.push(
      this.max('Máximo por bonos', 100, this.allBonusesCalculations),
    );

    if (this.isNovel) {
      calculations.push(this.sum('Bonus de novel', 10 * this.novelBonus));
    }

    calculations.push(
      this.sum('Penalizador a todas las acciones', this.allActionsPenalty),
    );

    return calculations;
  }
}
