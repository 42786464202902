import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class TaeKwonDoMartialArt extends BasicMartialArt {
  readonly name = 'Tae Kwon Do';

  readonly complexity = 1;

  readonly type = MartialArtType.TaeKwonDo;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      effects:
        'Ataque adicional con piernas con penalizador de -30. Se puede usar incluso si el PJ ha usado armas en el asalto.',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 10;
      },
      calculateHitType: () => [HitType.Impact],
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      effects: 'Disminuye el penalizador anterior a -20.',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 20;
      },
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.combat.attackAbility.final < 130) {
          errors.push('Ataque 130+');
        }

        if (character.combat.defensiveAbility < 100) {
          errors.push('Bloqueo o esquiva 100+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      effects: 'Elimina el penalizador anterior.',
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.combat.attackAbility.final < 200) {
          errors.push('Ataque 200+');
        }

        return errors;
      },
    }),
  ];
}
