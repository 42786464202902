import { ActorMutator } from '../../../ActorCharacterTransformer';
import { FoundryVTTActor } from '../FoundryVTTActor';
import { createBaseFinalField } from './utils/createBaseFinalField';

export const mutateActorCharacteristics: ActorMutator<FoundryVTTActor> = ({
  character,
  actor,
}) => {
  const content = character.content;

  actor.data.characteristics = {
    primaries: {
      agility: {
        value: content.primaries.agility.final,
        mod: content.primaries.agility.modifier.final,
      },
      strength: {
        value: content.primaries.strength.final,
        mod: content.primaries.strength.modifier.final,
      },
      willPower: {
        value: content.primaries.willPower.final,
        mod: content.primaries.willPower.modifier.final,
      },
      perception: {
        value: content.primaries.perception.final,
        mod: content.primaries.perception.modifier.final,
      },
      constitution: {
        value: content.primaries.constitution.final,
        mod: content.primaries.constitution.modifier.final,
      },
      intelligence: {
        value: content.primaries.intelligence.final,
        mod: content.primaries.intelligence.modifier.final,
      },
      dexterity: {
        value: content.primaries.dexterity.final,
        mod: content.primaries.dexterity.modifier.final,
      },
      power: {
        value: content.primaries.power.final,
        mod: content.primaries.power.modifier.final,
      },
    },
    secondaries: {
      resistances: {
        physical: createBaseFinalField(content.resistances.physical.final),
        psychic: createBaseFinalField(content.resistances.psychic.final),
        poison: createBaseFinalField(content.resistances.poison.final),
        magic: createBaseFinalField(content.resistances.magic.final),
        disease: createBaseFinalField(content.resistances.disease.final),
      },
      lifePoints: {
        value: content.lifePoints.final,
        max: content.lifePoints.final,
      },
      regenerationType: {
        mod: {
          value: 0,
        },
        final: {
          value: content.regeneration.final,
        },
      },
      regeneration: {
        normal: {
          value:
            content.regeneration.info.lifeRegeneratedWithoutResting?.value ?? 0,
          period:
            content.regeneration.info.lifeRegeneratedWithoutResting?.per ?? '',
        },
        resting: {
          value: content.regeneration.info.lifeRegeneratedResting.value,
          period: content.regeneration.info.lifeRegeneratedResting.per,
        },
        recovery: {
          value: content.regeneration.info.penaltyReduction.value,
          period: content.regeneration.info.penaltyReduction.per,
        },
      },
      movementType: {
        mod: {
          value: content.movementType.base + content.movementType.special,
        },
        final: {
          value: content.movementType.finalWithoutPenalties,
        },
      },
      movement: {
        running: {
          value: content.movementType.info.runningMovementInMeters,
        },
        maximum: {
          value: content.movementType.info.maximumMovementInMeters,
        },
      },
      fatigue: {
        value: content.fatigue.current + content.fatigue.special,
        max: content.fatigue.max,
      },
      initiative: createBaseFinalField(
        content.initiative.finalWithoutPenalties - 20,
      ),
    },
  };

  return { actor };
};
