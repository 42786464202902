import { observer } from 'mobx-react';
import { Input } from '../../../common/Input/Input';
import { Section } from '../../../common/Section/Section';
import { Table } from '../../../common/Table/Table';
import { useCharacterContext } from '../../../../hooks/useCharacterContext';

export const MagicProjection = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const pure = character.mystic.magicProjection.final;
  const offensive = character.mystic.offensiveMagicProjection;
  const defensive = character.mystic.defensiveMagicProjection;

  return (
    <Section maxHeight="fit">
      <Section.Title>Proyección mágica</Section.Title>
      <Table>
        <Table.Header>
          <Table.Header.Cell>Pura</Table.Header.Cell>
          <Table.Header.Cell>Ofensiva</Table.Header.Cell>
          <Table.Header.Cell>Defensiva</Table.Header.Cell>
        </Table.Header>
        <Table.Row>
          <Table.Row.Cell $important>{pure}</Table.Row.Cell>
          <Table.Row.Cell $important>{offensive}</Table.Row.Cell>
          <Table.Row.Cell $important>{defensive}</Table.Row.Cell>
        </Table.Row>
      </Table>

      <Input
        label="Desequilibrio mágico"
        value={character.mystic.magicProjectionUnbalance}
        setValue={(v) => character.mystic.setMagicProjectionUnbalance(v)}
      />
    </Section>
  );
});
