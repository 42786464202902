import { HTMLProps, KeyboardEvent, ReactNode } from 'react';
import { Info, StyledInput, Wrapper } from './Input.styled';
import { InputSize, InputState } from './Input.types';

type InputProps = Omit<HTMLProps<HTMLInputElement>, 'size'> & {
  state?: InputState;
  size?: InputSize;

  info?: string | ReactNode;

  onEnter?: (e: KeyboardEvent) => void;

  className?: string;
};

export const Input = ({
  info,
  size = InputSize.Default,
  state = InputState.Default,
  disabled,
  onEnter,
  ...rest
}: InputProps) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    if (e.key === 'Enter') {
      onEnter?.(e);
    }

    rest.onKeyDown?.(e);
  };

  return (
    <Wrapper>
      <StyledInput
        $size={size}
        $state={state}
        value={rest.value}
        onChange={rest.onChange}
        className={rest.className}
        onKeyUp={rest.onKeyUp}
        onKeyDown={handleKeyDown}
        placeholder={rest.placeholder}
        maxLength={rest.maxLength}
        type={rest.type}
        disabled={disabled}
        checked={rest.checked}
        autoFocus={rest.autoFocus}
        inputMode={rest.inputMode}
        readOnly={rest.readOnly}
      />
      {info ? <Info $state={state}>{info}</Info> : undefined}
    </Wrapper>
  );
};

Input.State = InputState;
Input.Size = InputSize;
