import { observer } from 'mobx-react';
import { MouseEvent, useState } from 'react';
import {
  DefaultSummons,
  Summon,
  SummonId,
} from '../../../../../../../../../domain/aggregations/summons/Summon';
import { AddItemButton } from '../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../common/CharacterMenu/useCharacterMenu';
import { RemoveItemButton } from '../../../common/RemoveItemButton/RemoveItemButton';
import { Section } from '../../../common/Section/Section';
import { Table } from '../../../common/Table/Table';
import { useCharacterContext } from '../../../../hooks/useCharacterContext';
import { MenuItem } from '../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';

export const MagicSummons = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { handleMenuOpen, Menu } = useCharacterMenu<SummonId>();

  const [selectedSummonIndex, setSelectedSummonIndex] = useState<
    number | undefined
  >(undefined);

  const addSummon = () => {
    character.mystic.addSummon(DefaultSummons[0]);
  };

  const changeSummon = (index: number, summon: Summon) => {
    character.mystic.replaceSummon(index, summon);
  };

  const removeSummon = (summonId: Summon) => () => {
    character.mystic.removeSummon(summonId);
  };

  const summonItems: MenuItem<SummonId>[] = DefaultSummons.map((summon) => {
    const item: MenuItem<SummonId> = {
      key: summon.id,
      name: summon.name,
      value: summon.id,
      onClick: () => {
        if (selectedSummonIndex === undefined) return;

        changeSummon(selectedSummonIndex, summon);
      },
    };

    return item;
  });

  const handleSummonClick =
    (summon: SummonId, index: number) => (e: MouseEvent<HTMLDivElement>) => {
      setSelectedSummonIndex(index);
      handleMenuOpen(summon)(e);
    };

  return (
    <Section maxHeight="fit">
      <Section.Title>Invocaciones y encarnaciones</Section.Title>
      <Table>
        <Table.Header>
          <Table.Header.Cell>Nombre</Table.Header.Cell>
          <Table.Header.Cell>Dificultad</Table.Header.Cell>
          <Table.Header.Cell>Coste de zeón</Table.Header.Cell>
        </Table.Header>
        {character.mystic.summons.map((summon, index) => (
          <Table.Row key={summon.id}>
            <Table.Row.Cell
              $interactable
              onClick={handleSummonClick(summon.id, index)}
            >
              {summon.name}
            </Table.Row.Cell>
            <Table.Row.Cell>{summon.difficulty}</Table.Row.Cell>
            <Table.Row.Cell>{summon.zeonCost}</Table.Row.Cell>
            <Table.Row.Cell>
              <RemoveItemButton onClick={removeSummon(summon)} />
            </Table.Row.Cell>
          </Table.Row>
        ))}
      </Table>

      <AddItemButton onClick={addSummon} />

      <Menu items={summonItems} />
    </Section>
  );
});
