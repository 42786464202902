import styled from 'styled-components';
import { Button } from '../../../../../shared/application/design-system/components/Button/Button';
import { Input } from '../../../../../shared/application/design-system/components/Input/Input';
import {
  BasicParagraph,
  BasicTitle,
  SmallParagraph,
  SmallTitle,
} from '../../../../../shared/application/design-system/components/Texts/Texts.styled';

export const WhiteTitle = styled(BasicTitle)(({ theme: { color } }) => ({
  color: color.white(),
}));

export const WhiteParagraph = styled(BasicParagraph)(
  ({ theme: { color } }) => ({
    color: color.white(),
  }),
);

export const WhiteSmallParagraph = styled(SmallParagraph)(
  ({ theme: { color } }) => ({
    color: color.white(),
  }),
);

export const WhiteSmallTitle = styled(SmallTitle)(({ theme: { color } }) => ({
  color: color.white(),
}));

export const Header = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: size.units(10),

  gap: size.units(4),
}));

export const SignInOptionsTabContainer = styled.div<{
  $isApplicationBeta: boolean;
}>(({ theme: { size }, $isApplicationBeta }) => ({
  display: 'flex',
  flexDirection: $isApplicationBeta ? 'column' : 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',

  maxWidth: size.units(20),

  gap: size.units(1),
}));

export const SignInOptionsTabName = styled(WhiteSmallTitle)<{
  $selected: boolean;
}>(({ theme: { size, color, font, transition }, $selected }) => ({
  fontWeight: font.weight.extraBold,

  cursor: 'pointer',

  height: size.units(4),
  boxSizing: 'border-box',

  borderBottom: $selected
    ? `2px solid ${color.primaryLight()}`
    : `2px solid ${color.black()}`,

  transition: transition.standard,

  '&:hover': {
    borderBottom: $selected
      ? `2px solid ${color.primaryLight()}`
      : `2px solid ${color.primary()}`,
  },
}));
export const WhiteParagraphInformation = styled(BasicParagraph)(
  ({ theme: { color, size } }) => ({
    fontSize: size.units(2.5),
    color: color.white(),
    paddingLeft: size.units(1),
    paddingRight: size.units(1),
    margin: size.units(2),
  }),
);

export const TitleInformation = styled(BasicTitle)(
  ({ theme: { color, size } }) => ({
    fontSize: size.units(3),
    color: color.white(),
    marginBottom: size.units(4),
  }),
);

export const GamerSelect = styled.select(
  ({ theme: { size, borderRadius, border, font } }) => ({
    fontFamily: font.family,

    boxSizing: 'border-box',

    fontSize: size.units(2),
    width: '100%',

    borderRadius: borderRadius.medium,
    border: border.small,
    boxShadow: 'none',
    padding: size.units(1),

    outline: 'none',
  }),
);

export const InputContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',

  width: '100%',

  gap: size.units(1),
}));

export const InputsContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',

  width: '100%',

  gap: size.units(2),
}));

export const StyledInput = styled(Input)({});

export const SocialLoginContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100%',

  gap: size.units(2),
}));

export const StyledButton = styled(Button)({});

export const Container = styled.div(
  ({ theme: { size, color, borderRadius, mixins } }) => ({
    borderRadius: borderRadius.medium,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    ...mixins.responsive.xs.downward({
      width: '100%',
      height: '100%',
    }),

    minWidth: size.units(50),

    backgroundColor: color.black(),

    padding: size.units(3),
    paddingTop: size.units(5),
    paddingBottom: size.units(5),

    boxSizing: 'border-box',

    [Header]: {
      marginBottom: size.units(5),
    },

    [SignInOptionsTabContainer]: {
      marginBottom: size.units(3),
    },

    [SocialLoginContainer]: {
      marginBottom: size.units(3),
    },

    [InputsContainer]: {
      marginBottom: size.units(1.5),
    },

    [StyledButton]: {
      marginTop: size.units(1.5),
    },
  }),
);

export const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100%',
  height: '100%',
});
