import { Tooltip } from '@mui/material';
import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import { MenuItem } from '../../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';
import { getStyleTableFromType } from '../../../../../../../../../../../domain/aggregations/tables/style-tables/getStyleTableFromType';
import { StyleTableType } from '../../../../../../../../../../../domain/aggregations/tables/style-tables/StyleTable';
import { StyleTableModel } from '../../../../../../../../../../../domain/character/model/parts/combat/parts/StyleTableModel';
import { AddItemButton } from '../../../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../../../common/CharacterMenu/useCharacterMenu';
import { RemoveItemButton } from '../../../../../common/RemoveItemButton/RemoveItemButton';
import { Table } from '../../../../../common/Table/Table';
import { CombatPDModel } from '../../../../../../../../../../../domain/character/model/parts/pd/parts/categories/parts/combat/CombatPDModel';

type StyleTableSelectorProps = {
  combat: CombatPDModel;
};

export const StyleTableSelector = observer(
  ({ combat }: StyleTableSelectorProps) => {
    const { handleMenuOpen: handleTableMenuOpen, Menu: StyleMenu } =
      useCharacterMenu<StyleTableType>();

    const [selectedTable, setSelectedTable] = useState<StyleTableModel>();

    const handleAddStyleTable = () => {
      combat.addStyleTable(StyleTableType.Disarmament);
    };

    const handleRemoveStyleTable = (table: StyleTableModel) => () => {
      combat.removeStyleTable(table);
    };

    const styleTables = (Object.values(StyleTableType) as StyleTableType[])
      .map(getStyleTableFromType)
      .map((t) => {
        const item: MenuItem<StyleTableType> = {
          key: t.type,
          name: t.name,
          value: t.type,
          onClick: () => {
            selectedTable?.setType(t.type);
          },
        };

        return item;
      });

    return (
      <>
        <Table>
          <Table.Header>
            <Table.Header.Cell $width={12}>Tabla</Table.Header.Cell>
            <Table.Header.Cell>Coste</Table.Header.Cell>
            <Table.Header.Cell $width={15}>Efecto</Table.Header.Cell>
          </Table.Header>
          {combat.styleTables.map((developedTable, i) => {
            const handleTableSelection = (e: MouseEvent<HTMLDivElement>) => {
              handleTableMenuOpen(developedTable.type)(e);
              setSelectedTable(developedTable);
            };

            return (
              <Table.Row key={`${i}-${developedTable.type}`}>
                <Table.Row.Cell onClick={handleTableSelection}>
                  {developedTable.name}
                </Table.Row.Cell>
                <Table.Row.Cell>{developedTable.cost}</Table.Row.Cell>
                <Table.Row.Cell>
                  <Tooltip title={developedTable.table.effect}>
                    <span>{developedTable.effect}</span>
                  </Tooltip>
                </Table.Row.Cell>

                <StyleMenu items={styleTables} />
                <RemoveItemButton
                  onClick={handleRemoveStyleTable(developedTable)}
                />
              </Table.Row>
            );
          })}
        </Table>
        <AddItemButton onClick={handleAddStyleTable} />
      </>
    );
  },
);
