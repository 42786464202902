import styled from 'styled-components';

export const FabContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

export const Wrapper = styled.div(({ theme: { size, mixins } }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',

  ...mixins.responsive.ipad.downward({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),

  [FabContainer]: {
    position: 'absolute',
    bottom: size.units(2),
    right: size.units(2),
  },
}));
