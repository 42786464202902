export const cache = new Map<string, Promise<unknown>>();

const RequestMapCache = {
  get: <T>(key: string) => cache.get(key) as T | undefined,
  set: <T>(key: string, value: Promise<T>) => {
    return cache.set(key, value);
  },
  delete: (key: string) => {
    return cache.delete(key);
  },
};

export const CacheWrapper = <R, T = void>(
  cacheKey: string,
  fn: (...args: T[]) => Promise<R>,
): ((...args: T[]) => Promise<R>) => {
  return async (...args: T[]) => {
    const key = JSON.stringify(`${cacheKey}-${args}`);
    const cached = RequestMapCache.get(key);

    if (cached) {
      return cached as R;
    }

    const result = fn(...args).then((r) => {
      RequestMapCache.delete(key);

      return r;
    });

    RequestMapCache.set(key, result);

    return result as R;
  };
};
