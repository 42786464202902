import { Model, model, modelAction, prop } from 'mobx-keystone';
import {
  getKiSkillFromType,
  KiSkillType,
} from '../../../../aggregations/ki-skill/KiSkill';
import { KiTechnique } from '../../../../aggregations/ki-technique/KiTechnique';
import {
  getNemesisSkillFromType,
  NemesisSkillType,
} from '../../../../aggregations/nemesis-skill/NemesisSkill';
import { getParentCharacter } from '../../../../utils/parenting/getParentCharacter';
import { KiAccumulationPointsModel } from './parts/KiAccumulationPointsModel/KiAccumulationPointsModel';
import { KiDetectionFieldModel } from './parts/KiDetectionFieldModel';
import { KiHideFieldModel } from './parts/KiHideFieldModel';
import { KiPointsModel } from './parts/KiPointsModel/KiPointsModel';
import {
  createModelFromKiTechnique,
  KiTechniqueModel,
} from './parts/KiTechniqueModel';
import { MartialKnowledgeFieldModel } from './parts/MartialKnowledgeFieldModel';
import { getTotalOf } from '../../utils/getTotalOf';
import { computed } from 'mobx';
import { NephilimDAnjayni } from '../../../../aggregations/nephilim-type/Nephilim';

@model('Character/Ki')
export class KiModel extends Model({
  kiPoints: prop(() => new KiPointsModel({})),
  kiAccumulationPoints: prop(() => new KiAccumulationPointsModel({})),

  kiSkills: prop<KiSkillType[]>(() => []),
  nemesisSkills: prop<NemesisSkillType[]>(() => []),

  kiTechniques: prop<KiTechniqueModel[]>(() => []),

  kiDetection: prop(() => new KiDetectionFieldModel({})),
  kiHide: prop(() => new KiHideFieldModel({})),

  martialKnowledge: prop(() => new MartialKnowledgeFieldModel({})),
}) {
  @computed
  get knownKiSkills(): KiSkillType[] {
    if (!this.character) return [];

    let kiSkills = this.kiSkills;

    if (this.character.isNephilim(NephilimDAnjayni)) {
      kiSkills = [...new Set([...kiSkills, KiSkillType.KiHide])];
    }

    return kiSkills;
  }

  get usedMartialKnowledge(): number {
    if (!this.character) return 0;

    const kiSkillsCosts = this.kiSkills.reduce((acc, skill) => {
      if (this.character) {
        return acc + getKiSkillFromType(skill).calculateCost(this.character);
      } else {
        return acc + 0;
      }
    }, 0);

    return (
      this.kiTechniques.reduce((acc, t) => acc + t.mpCost, 0) +
      kiSkillsCosts +
      this.nemesisSkills.reduce(
        (acc, skill) => acc + getNemesisSkillFromType(skill).cost,
        0,
      ) +
      getTotalOf(this.character, (c) =>
        c.combat.arsMagnus.reduce((acc, am) => acc + am.cmCost, 0),
      )
    );
  }

  get character() {
    return getParentCharacter(this);
  }

  @modelAction
  addKiSkill(skill: KiSkillType): void {
    if (this.hasKiSkill(skill)) return;

    this.kiSkills.push(skill);
  }

  @modelAction
  removeKiSkill(skill: KiSkillType): void {
    this.kiSkills = this.kiSkills.filter((s) => s !== skill);
  }

  hasKiSkill(skill: KiSkillType): boolean {
    return this.knownKiSkills.includes(skill);
  }

  @modelAction
  addKiTechnique(skill: KiTechnique): void {
    this.kiTechniques.push(createModelFromKiTechnique(skill));
  }

  @modelAction
  replaceKiTechnique(from: KiTechniqueModel, to: KiTechniqueModel): void {
    this.kiTechniques = this.kiTechniques.map((s) => (s === from ? to : s));
  }

  @modelAction
  removeKiTechnique(skill: KiTechniqueModel): void {
    this.kiTechniques = this.kiTechniques.filter((s) => s !== skill);
  }

  @modelAction
  addNemesisSkill(skill: NemesisSkillType): void {
    if (this.hasNemesisSkill(skill)) return;

    this.nemesisSkills.push(skill);
  }

  @modelAction
  removeNemesisSkill(skill: NemesisSkillType): void {
    this.nemesisSkills = this.nemesisSkills.filter((s) => s !== skill);
  }

  @modelAction
  removeAllNemesisSkills(): void {
    this.nemesisSkills = [];
  }

  hasNemesisSkill(skill: NemesisSkillType): boolean {
    return this.nemesisSkills.includes(skill);
  }
}
