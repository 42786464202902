import { model } from 'mobx-keystone';
import { DevelopedPDFieldModel } from '../../../../../utils/fields/DevelopedPDFieldModel';
import { getTotalOf } from '../../../../../utils/getTotalOf';
import { calculateBonusFromPrimary } from '../utils/calculateBonusFromPrimary';

@model('Character/Ki/KiAccumulationPoints/Dexterity')
export class DexterityKiAccumulationPointsFieldModel extends DevelopedPDFieldModel {
  get fromCategories(): number {
    return getTotalOf(
      this.character,
      (c) => c.combat.ki.dexterityKiAccumPoints.final,
    );
  }

  get allActionsPenalty(): number {
    const allActionsPenalty = super.allActionsPenalty;

    if (allActionsPenalty > 0) return 0;

    return allActionsPenalty / 20;
  }

  get primariesBonus(): number {
    if (!this.character) return 0;

    return calculateBonusFromPrimary(this.character.primaries.dexterity.final);
  }
}
