import { CharacterModel } from '../CharacterModel';
import { CategoryModel } from '../parts/pd/parts/categories/CategoryModel';
import { Calculation } from './fields/NumberField';

export const getTotalOf = (
  character: CharacterModel | undefined,
  getValueFn: (c: CategoryModel) => number,
): number => {
  if (!character) return 0;

  return character.categories.reduce(
    (acc, category) => acc + getValueFn(category),
    0,
  );
};
export const getTotalCalculationsOf = (
  text: string,
  character: CharacterModel | undefined,
  getValueFn: (c: CategoryModel) => number,
): Calculation[] => {
  if (!character) return [];

  const calculations: Calculation[] = [];

  character.categories.forEach((category) => {
    const value = getValueFn(category);

    if (value !== 0) {
      calculations.push({
        reason: `${text} (${category.template.name})`,
        value,
        operator: '+',
      });
    }
  });

  return calculations;
};

export const getAllItemsOf = <T>(
  character: CharacterModel | undefined,
  getValueFn: (c: CategoryModel) => T[],
): T[] => {
  if (!character) return [];

  return character.categories.reduce(
    (acc, category) => acc.concat(getValueFn(category)),
    [] as T[],
  );
};
