import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { SupabaseClient } from '../../shared/infrastructure/supabase/supabaseClient';
import { Loading } from '../../shared/application/design-system/components/Loading/Loading';
import { LoadingContainer } from '../../shared/application/design-system/components/PageLayout/PageLayout.styled';
import { theme } from '../../shared/application/design-system/theme/theme';

import { Router } from './Router';
import { TrackingService } from '../../shared/infrastructure/tracking/TrackingService';
import { isEmbedded } from '../../auth/application/AuthorizationPage/utils/isEmbedded';
import { FoundryMessagingService } from '../../embed/infrastructure/FoundryMessagingService';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${() => theme.color.silver(0.2)};

    height: 100vh;

    font-family: 'Source Sans Pro', serif !important;

    margin: 0;
  }

  #root {
    height: inherit;
  }
`;

const muiTheme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        lineHeight: 'inherit',
      },
    },
  },
});

if (isEmbedded) {
  FoundryMessagingService.instance.start();
}

const App = () => {
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    TrackingService.init();
  }, []);

  useEffect(() => {
    SupabaseClient()
      .auth.initialize()
      .finally(() => {
        setInitializing(false);
      });
  }, []);

  const sessionTrackedRef = useRef(false);
  useEffect(() => {
    const stateChange = SupabaseClient().auth.onAuthStateChange(
      (event, session) => {
        if (session && !sessionTrackedRef.current) {
          TrackingService.setUser({
            id: session.user.id,
            email: session.user.email ?? 'unknown',
          });

          sessionTrackedRef.current = true;
        }

        if (event === 'SIGNED_OUT') {
          TrackingService.sendEvent('logout');
        }

        if (event === 'SIGNED_IN') {
          TrackingService.sendEvent('sign_in');
        }
      },
    );

    return () => stateChange.data.subscription.unsubscribe();
  }, []);

  const content = initializing ? (
    <LoadingContainer>
      <Loading centered />
    </LoadingContainer>
  ) : (
    <Router />
  );

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  return (
    <Fragment>
      <ToastContainer
        theme="dark"
        autoClose={1500}
        newestOnTop
        hideProgressBar
      />
      <GlobalStyle />
      <MUIThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme}>
          <DndContext sensors={sensors}>{content}</DndContext>
        </ThemeProvider>
      </MUIThemeProvider>
    </Fragment>
  );
};

export default observer(App);
