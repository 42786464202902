export const openFileSelectDialog = (): Promise<File | undefined> => {
  return new Promise<File | undefined>((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';

    input.onchange = (e) => {
      const target: HTMLInputElement | null = e.target as HTMLInputElement;

      if (target && target.files) {
        resolve(target.files[0]);
      } else {
        resolve(undefined);
      }
    };

    input.click();
  });
};
