import { useState } from 'react';
import { Character } from '../../../character/domain/character/Character';
import { CharacterService } from '../../../character/infrastructure/CharacterService';
import { NotificationManager } from '../shared/NotificationManager';
import { TrackingService } from '../../infrastructure/tracking/TrackingService';

export const useDeleteCharacter = () => {
  const [deleting, setDeleting] = useState(false);

  const deleteCharacter = (character: Character): Promise<void> => {
    setDeleting(true);

    return CharacterService.deleteCharacter(character)
      .then(() => {
        TrackingService.sendEvent('character_deleted');
        NotificationManager.success('Se ha eliminado el personaje');
      })
      .catch((error) => {
        NotificationManager.error('No se ha podido eliminar el personaje');

        console.error(error);
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  return {
    deletingCharacter: deleting,
    deleteCharacter,
  };
};
