import { ExtendedModel, model, prop } from 'mobx-keystone';
import { NumberField } from '../../../utils/fields/NumberField';

@model('Character/Psychic/PsychicPotential')
export class PsychicPotentialModel extends ExtendedModel(NumberField, {
  ppUsedToIncrease: prop(0).withSetter(),
}) {
  get final(): number {
    if (!this.character) return 0;

    return (
      this.calculateBaseFromWillPower() +
      this.special +
      this.character.psychic.psychicCrystalPotential +
      this.calculateBonusFromPPUsedToIncrease()
    );
  }

  private calculateBonusFromPPUsedToIncrease(): number {
    if (this.ppUsedToIncrease < 1) return 0;
    if (this.ppUsedToIncrease < 3) return 10;
    if (this.ppUsedToIncrease < 6) return 20;
    if (this.ppUsedToIncrease < 10) return 30;
    if (this.ppUsedToIncrease < 15) return 40;
    if (this.ppUsedToIncrease < 21) return 50;
    if (this.ppUsedToIncrease < 28) return 60;
    if (this.ppUsedToIncrease < 36) return 70;
    if (this.ppUsedToIncrease < 45) return 80;
    if (this.ppUsedToIncrease < 55) return 90;

    return 100;
  }

  private calculateBaseFromWillPower(): number {
    if (!this.character) return 0;

    const powerWill = this.character.primaries.willPower.final;

    if (powerWill <= 4) return 0;
    if (powerWill === 5) return 10;
    if (powerWill === 6) return 20;
    if (powerWill === 7) return 30;
    if (powerWill === 8) return 40;
    if (powerWill === 9) return 50;
    if (powerWill === 10) return 60;
    if (powerWill === 11) return 70;
    if (powerWill === 12) return 80;
    if (powerWill === 13) return 90;
    if (powerWill === 14) return 100;
    if (powerWill === 15) return 120;
    if (powerWill === 16) return 140;
    if (powerWill === 17) return 160;
    if (powerWill === 18) return 180;
    if (powerWill === 19) return 200;

    return 220;
  }
}
