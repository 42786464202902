import { useState } from 'react';
import {
  Character,
  CharacterPrivacy,
} from '../../../character/domain/character/Character';

import { CharacterService } from '../../../character/infrastructure/CharacterService';
import { TrackingService } from '../../infrastructure/tracking/TrackingService';
import { NotificationManager } from '../shared/NotificationManager';

export const useDuplicateCharacter = () => {
  const [duplicating, setDuplicating] = useState(false);

  const duplicateCharacter = (character: Character) => {
    setDuplicating(true);

    const characterDuplicated = character;
    characterDuplicated.id = '-1';
    characterDuplicated.setPrivacy(CharacterPrivacy.Private);
    characterDuplicated.content.setName(`${character.content.name} copy`);

    return CharacterService.createCharacter(characterDuplicated)
      .then(() => {
        TrackingService.sendEvent('character_duplicated', {
          duplicatedCharacterId: character.id.toString(),
        });
        NotificationManager.success('Se ha duplicado el personaje');
      })
      .catch((error) => {
        console.error(error);
        NotificationManager.error('No se ha podido duplicar el personaje');
        throw error;
      })
      .finally(() => {
        setDuplicating(false);
      });
  };

  return {
    duplicatingCharacter: duplicating,
    duplicateCharacter,
  };
};
