import { Edit } from '@emotion-icons/fluentui-system-filled/Edit';
import styled from 'styled-components';
import { theme } from '../../../../../../../../../shared/application/design-system/theme/theme';

export const StyledEditIcon = styled(Edit)<{ $disabled?: boolean }>(
  ({ $disabled }) => ({
    width: '1.5rem',
    height: '1.5rem',
    minWidth: '1.5rem',
    minHeight: '1.5rem',
    color: $disabled ? theme.color.gray() : theme.color.secondary(),

    cursor: 'pointer',
    transition: 'color 0.2s ease-in-out',

    '&:hover': {
      color: $disabled ? undefined : theme.color.primary(),
    },
  }),
);
