import type { CategoryTemplate } from '../../../../../../../aggregations/category-templates/CategoryTemplate';
import { getParent } from '../../../../../../../utils/parenting/getParent';
import { BaseFinalFieldModel } from '../../../../../utils/fields/BaseFinalFieldModel';
import { CategoryModel } from '../CategoryModel';
import { CategoryModelName } from '../CategoryModel.constants';

export abstract class PDFieldModel extends BaseFinalFieldModel {
  protected readonly scaleByMultiples: boolean = false;

  abstract get pdsCost(): number;

  abstract get perCategory(): number | undefined;

  get bonus(): number {
    return 0;
  }

  get final(): number {
    return this.bought + this.bonus + (this.perCategory ?? 0);
  }

  get pdsSpent(): number {
    return this.base;
  }

  get bought(): number {
    const value = Math.floor((this.base + this.special) / this.pdsCost);

    if (this.scaleByMultiples) {
      return value * this.pdsCost;
    }

    return value;
  }

  get level(): number {
    const category = this.getCategoryModel();

    return category.level.final;
  }

  get template(): CategoryTemplate {
    const category = this.getCategoryModel();

    return category.template;
  }

  private getCategoryModel() {
    const categoryModel = getParent<CategoryModel>(CategoryModelName, this);

    if (!categoryModel) {
      throw new Error('Not bound to a CategoryTemplate');
    }
    return categoryModel;
  }
}
