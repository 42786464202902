import { useState } from 'react';
import { Character } from '../../../../../../domain/character/Character';
import { CharacterService } from '../../../../../../infrastructure/CharacterService';
import { OptionalLevel } from '../components/LevelSelectorFilter/LevelSelectorFilter';
import { OptionalCategory } from '../components/CategorySelectorFilter/CategorySelectorFilter';

export const useSearchCharacters = () => {
  const [search, setSearch] = useState<Character[]>([]);

  const [searching, setSearching] = useState(false);

  const searchCharacters = async (query: {
    level: OptionalLevel;
    needle: string;
    category: OptionalCategory;
  }) => {
    if (searching) return;

    setSearching(true);

    try {
      setSearch(await CharacterService.searchCharacters(query));
    } finally {
      setSearching(false);
    }
  };

  return {
    characters: search,
    searchingCharacters: searching,

    searchCharacters,
  };
};
