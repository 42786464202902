import {
  LastNews,
  News,
} from '../aplication/pages/News/components/LastNews/assets/LastNews';
import {
  LocalStorageKeys,
  LocalStorageManager,
} from '../../shared/infrastructure/LocalStorageManager';

const saveLastNewsVersionRead = (version: number) => {
  LocalStorageManager.setItem(LocalStorageKeys.LastVersionRead, `${version}`);
};

const getLastNewsVersion = () => {
  return Math.max(...LastNews.map((news: News) => news.version));
};

const getLastNewsVersionRead = () => {
  return Number(LocalStorageManager.getItem(LocalStorageKeys.LastVersionRead));
};

const hasPendingNewsToRead = () => {
  const lastNewsVersion = getLastNewsVersion();
  const lastNewsVersionRead = getLastNewsVersionRead();
  return lastNewsVersion > lastNewsVersionRead;
};

export const NewsService = {
  getLastNewsVersion,
  getLastNewsVersionRead,
  saveLastNewsVersionRead,
  hasPendingNewsToRead,
};
