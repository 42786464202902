import { News } from '../../../../assets/LastNews';
import {
  StyledBasicTitle,
  StyleNewsChange,
  StyleNewsList,
  Wrapper,
} from './NewsItem.styled';

interface NewsItemProps {
  news: News;
}

export const NewsItem = ({ news }: NewsItemProps) => {
  return (
    <Wrapper>
      <StyledBasicTitle
        dangerouslySetInnerHTML={{
          __html: `Versión: ${news.version} (${news.publishedAt})`,
        }}
      />
      <StyleNewsList>
        {news.changes.map((change: string | string[], index: number) => {
          if (typeof change === 'string') {
            return (
              <StyleNewsChange
                key={index}
                dangerouslySetInnerHTML={{ __html: change }}
              />
            );
          }

          return (
            <StyleNewsList key={index}>
              {change.map((change: string, index: number) => (
                <StyleNewsChange
                  key={index}
                  dangerouslySetInnerHTML={{ __html: change }}
                />
              ))}
            </StyleNewsList>
          );
        })}
      </StyleNewsList>
    </Wrapper>
  );
};
