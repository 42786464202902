import { CategoryTemplate, CategoryType } from './CategoryTemplate';

export class NovelCategoryTemplate extends CategoryTemplate {
  name = 'Novel';
  archetypes = [];
  type = CategoryType.Novel;

  readonly lifeMultiplier = 20;
  lifePoints = 5;
  protected turn = 5;
  protected martialKnowledge = 20;
  protected nativePsychicPoints = 1;

  calculateNativePsychicPoints(): number {
    return (
      Math.floor(Math.max(this.level - 1, 0) / 2) * this.nativePsychicPoints
    );
  }

  offensiveSkillsLimitPercentage = 0.6;
  attackPDsCost = 2;
  blockPDsCost = 2;
  dodgePDsCost = 2;
  wearArmorPDsCost = 2;
  kiPDsCost = 2;
  kiAccumMultiplierPDsCost = 20;

  magicSkillsLimitPercentage = 0.6;
  zeonPDsCost = 2;
  aCTMultiplierPDsCost = 60;
  magicProjectionPDsCost = 2;
  summonPDsCost = 2;
  controlPDsCost = 2;
  bindPDsCost = 2;
  banishPDsCost = 2;

  psychicSkillsLimitPercentage = 0.6;
  psychicPointsPDsCost = 20;
  psychicProjectionPDsCost = 2;

  athleticSecondariesPDsCost = 2;
  socialSecondariesPDsCost = 2;
  perceptionSecondariesPDsCost = 2;
  intellectualSecondariesPDsCost = 2;
  vigorSecondariesPDsCost = 2;
  subterfugeSecondariesPDsCost = 2;
  creativeSecondariesPDsCost = 2;

  zeonBonus = 10;
}
