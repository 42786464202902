import styled from 'styled-components';

export const Wrapper = styled.div(({ theme: { mixins } }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(4 ,1fr)',
  justifyItems: 'center',
  gap: '0.25rem',

  paddingBottom: '1.5rem',

  ...mixins.responsive.xs.downward({
    gridTemplateColumns: 'repeat(2 ,1fr)',
  }),
}));
