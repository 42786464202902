import { MagicMainPath, MagicPath } from '../magic-path/MagicPath';
import {
  Advantage,
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
  AdvantageType,
  AdvantageVariation,
} from './Advantage.types';

const MagicPathVariations: AdvantageVariation<MagicMainPath>[] = [
  {
    id: MagicPath.Light,
    name: 'Luz',
  },
  {
    id: MagicPath.Darkness,
    name: 'Oscuridad',
  },
  {
    id: MagicPath.Fire,
    name: 'Fuego',
  },
  {
    id: MagicPath.Water,
    name: 'Agua',
  },
  {
    id: MagicPath.Air,
    name: 'Aire',
  },
  {
    id: MagicPath.Earth,
    name: 'Tierra',
  },
  {
    id: MagicPath.Creation,
    name: 'Creación',
  },
  {
    id: MagicPath.Destruction,
    name: 'Destrucción',
  },
  {
    id: MagicPath.Essence,
    name: 'Esencia',
  },
  {
    id: MagicPath.Illusion,
    name: 'Ilusión',
  },
  {
    id: MagicPath.Necromancy,
    name: 'Nigromancia',
  },
];

export const MagicGradualLearningAdvantage: Advantage = {
  id: 'advantage-don-1',

  name: 'Aprendizaje mágico gradual',

  type: AdvantageType.Don,

  levels: [AdvantageLevel2],
};

export const MagicDevelopmentSkilledAdvantage: Advantage = {
  id: 'advantage-don-2',

  name: 'Apto desarrollo de la magia',

  type: AdvantageType.Don,

  levels: [AdvantageLevel1],
};

export const MagicPathNaturalKnowledgeAdvantage: Advantage<MagicMainPath> = {
  id: 'advantage-don-3',

  name: 'Conocimiento natural de Vía',

  type: AdvantageType.Don,

  levels: [AdvantageLevel1],

  variations: MagicPathVariations,
};

export const ElementalUnbalanceAdvantage: Advantage<MagicMainPath> = {
  id: 'advantage-don-4',

  name: 'Desequilibrio elemental',

  type: AdvantageType.Don,

  levels: [AdvantageLevel1],

  variations: MagicPathVariations,
};

export const SephiroticUnbalanceAdvantage: Advantage = {
  id: 'advantage-don-5',

  name: 'Desequilibrio sephirótico',

  type: AdvantageType.Don,

  levels: [AdvantageLevel2],
};

export const MagicDictationAdvantage: Advantage = {
  id: 'advantage-don-6',

  name: 'Dicción mágica',

  type: AdvantageType.Don,

  levels: [AdvantageLevel1],
};

export const InnateWizardAdvantage: Advantage = {
  id: 'advantage-don-7',

  name: 'Hechicero nato',

  type: AdvantageType.Don,

  levels: [AdvantageLevel1],
};

export const OralWorthlessnessAdvantage: Advantage = {
  id: 'advantage-don-8',

  name: 'Inutilidad oral',

  type: AdvantageType.Don,

  levels: [AdvantageLevel1],
};

export const SomaticWorthlessnessAdvantage: Advantage = {
  id: 'advantage-don-9',

  name: 'Inutilidad somática',

  type: AdvantageType.Don,

  levels: [AdvantageLevel1],
};

export const BetterInnateMagicAdvantage: Advantage = {
  id: 'advantage-don-10',

  name: 'Magia innata mejorada',

  type: AdvantageType.Don,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const OpositeMagicAdvantage: Advantage = {
  id: 'advantage-don-11',

  name: 'Magia opuesta',

  type: AdvantageType.Don,

  levels: [AdvantageLevel1],
};

export const MagicNatureAdvantage: Advantage = {
  id: 'advantage-don-12',

  name: 'Naturaleza mágica',

  type: AdvantageType.Don,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const NaturalPowerAdvantage: Advantage = {
  id: 'advantage-don-13',

  name: 'Poder natural',

  type: AdvantageType.Don,

  levels: [AdvantageLevel1],
};

export const FacedPotentialAdvantage: Advantage = {
  id: 'advantage-don-14',

  name: 'Potencial enfrentado',

  type: AdvantageType.Don,

  levels: [AdvantageLevel1],
};

export const MagicSuperiorRecoveryAdvantage: Advantage = {
  id: 'advantage-don-15',

  name: 'Recuperación superior de magia',

  type: AdvantageType.Don,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const MetamagicVersatilityAdvantage: Advantage = {
  id: 'advantage-don-16',

  name: 'Versatilidad metamágica',

  type: AdvantageType.Don,

  levels: [AdvantageLevel1],
};

export const AllDonAdvantages: Advantage[] = [
  MagicGradualLearningAdvantage,
  MagicDevelopmentSkilledAdvantage,
  MagicPathNaturalKnowledgeAdvantage,
  ElementalUnbalanceAdvantage,
  SephiroticUnbalanceAdvantage,
  MagicDictationAdvantage,
  InnateWizardAdvantage,
  OralWorthlessnessAdvantage,
  SomaticWorthlessnessAdvantage,
  BetterInnateMagicAdvantage,
  OpositeMagicAdvantage,
  MagicNatureAdvantage,
  NaturalPowerAdvantage,
  FacedPotentialAdvantage,
  MagicSuperiorRecoveryAdvantage,
  MetamagicVersatilityAdvantage,
];
