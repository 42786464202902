import { model, Model, modelAction, prop } from 'mobx-keystone';
import { AttackAbilityPDFieldModel } from './basic/AttackAbilityPDFieldModel';
import { BlockAbilityPDFieldModel } from './basic/BlockAbilityPDFieldModel';
import { DodgeAbilityPDFieldModel } from './basic/DodgeAbilityPDFieldModel';
import { WearArmorAbilityPDFieldModel } from './basic/WearArmorAbilityPDFieldModel';
import { KiModel } from './ki/KiModel';
import { MartialKnowledgePDFieldModel } from './MartialKnowledgePDFieldModel';
import { getParentCharacter } from '../../../../../../../../utils/parenting/getParentCharacter';
import { CategoryModel } from '../../CategoryModel';
import { getParentDevelopedCategory } from '../../../../../../../../utils/parenting/getParentDevelopedCategory';
import { computed } from 'mobx';
import { WeaponTableModel } from '../../../../../combat/parts/WeaponTableModel';
import { StyleTableModel } from '../../../../../combat/parts/StyleTableModel';
import { MartialArtModel } from '../../../../../combat/parts/MartialArtModel';
import { MartialArtWeaponTableModel } from '../../../../../combat/parts/MartialArtWeaponTableModel';
import {
  ArsMagnusModel,
  createModelFromArsMagnus,
} from '../../../../../combat/parts/ArsMagnusModel';
import { MartialArtType } from '../../../../../../../../aggregations/martial-arts/MartialArt';
import { getMartialArtFromType } from '../../../../../../../../aggregations/martial-arts/getMartialArtFromType';
import { ArsMagnus } from '../../../../../../../../aggregations/ars-magnus/ArgsMagnus';
import { WeaponTableType } from '../../../../../../../../aggregations/tables/weapon-tables/WeaponTable';
import { StyleTableType } from '../../../../../../../../aggregations/tables/style-tables/StyleTable';
import { MartialArtWeaponTableType } from '../../../../../../../../aggregations/tables/martial-art-tables/MartialArtWeaponTable';

@model('Character/PD/CombatPD')
export class CombatPDModel extends Model({
  attackAbility: prop(() => new AttackAbilityPDFieldModel({})),
  blockAbility: prop(() => new BlockAbilityPDFieldModel({})),
  dodgeAbility: prop(() => new DodgeAbilityPDFieldModel({})),
  wearArmorAbility: prop(() => new WearArmorAbilityPDFieldModel({})),

  ki: prop(() => new KiModel({})),

  martialKnowledge: prop(() => new MartialKnowledgePDFieldModel({})),

  weaponTables: prop<WeaponTableModel[]>(() => []),

  styleTables: prop<StyleTableModel[]>(() => []),

  martialArts: prop<MartialArtModel[]>(() => []),

  martialArtWeaponTables: prop<MartialArtWeaponTableModel[]>(() => []),

  arsMagnus: prop<ArsMagnusModel[]>(() => []),
}) {
  get pdsSpent(): number {
    if (!this.character) return 0;

    return (
      this.attackAbility.base +
      this.blockAbility.base +
      this.dodgeAbility.base +
      this.wearArmorAbility.base +
      this.ki.pdsSpent +
      this.martialKnowledge.pdsSpent +
      this.martialArtPDsSpent() +
      this.weaponTablesPDsSpent() +
      this.styleTablesPDsSpent() +
      this.martialArtWeaponTablesPDsSpent() +
      this.arsMagnusPDsSpent()
    );
  }

  @computed
  get maxPdsThatCanBeSpent(): number {
    if (!this.character || !this.category) return 0;

    return Math.floor(
      this.character.pd.totalPds *
        this.category.template.offensiveSkillsLimitPercentage,
    );
  }

  @computed
  get maxPdsThatCanBeSpentWithinCategory(): number {
    if (!this.character || !this.category) return 0;

    return Math.floor(
      this.category.pds * this.category.template.offensiveSkillsLimitPercentage,
    );
  }

  get character() {
    return getParentCharacter(this);
  }

  get category(): CategoryModel | undefined {
    return getParentDevelopedCategory(this);
  }

  @modelAction
  addMartialArt(type: MartialArtType): void {
    this.martialArts.push(
      new MartialArtModel({
        type,
        gradeId: getMartialArtFromType(type).grades[0].id,
      }),
    );
  }

  @modelAction
  removeMartialArt(martialArt: MartialArtModel): void {
    this.martialArts = this.martialArts.filter((ma) => ma !== martialArt);
  }

  @modelAction
  addArsMagnus(arsMagnus: ArsMagnus): void {
    this.arsMagnus.push(createModelFromArsMagnus(arsMagnus));
  }

  @modelAction
  replaceArsMagnus(from: ArsMagnusModel, to: ArsMagnusModel): void {
    this.arsMagnus = this.arsMagnus.map((am) => (am === from ? to : am));
  }

  @modelAction
  removeArsMagnus(arsMagnus: ArsMagnusModel): void {
    this.arsMagnus = this.arsMagnus.filter((ma) => ma !== arsMagnus);
  }

  @modelAction
  addWeaponTable(type: WeaponTableType, selectedItem?: string) {
    this.weaponTables.push(
      new WeaponTableModel({ type, tableItemSelected: selectedItem }),
    );
  }

  @modelAction
  removeWeaponTable(table: WeaponTableModel) {
    this.weaponTables = this.weaponTables.filter((t) => t !== table);
  }

  @modelAction
  addStyleTable(type: StyleTableType) {
    this.styleTables.push(new StyleTableModel({ type }));
  }

  @modelAction
  removeStyleTable(table: StyleTableModel) {
    this.styleTables = this.styleTables.filter((t) => t !== table);
  }

  @modelAction
  addMartialArtWeaponTable(type: MartialArtWeaponTableType) {
    this.martialArtWeaponTables.push(new MartialArtWeaponTableModel({ type }));
  }

  @modelAction
  removeMartialArtWeaponTable(table: MartialArtWeaponTableModel) {
    this.martialArtWeaponTables = this.martialArtWeaponTables.filter(
      (t) => t !== table,
    );
  }

  private martialArtPDsSpent() {
    return this.martialArts.reduce(
      (total, developedTable) => total + developedTable.cost,
      0,
    );
  }

  private martialArtWeaponTablesPDsSpent() {
    return this.martialArtWeaponTables.reduce(
      (total, developedTable) => total + developedTable.cost,
      0,
    );
  }

  private styleTablesPDsSpent() {
    return this.styleTables.reduce(
      (total, developedTable) => total + developedTable.cost,
      0,
    );
  }

  private weaponTablesPDsSpent() {
    return this.weaponTables.reduce(
      (total, developedTable) => total + developedTable.cost,
      0,
    );
  }

  private arsMagnusPDsSpent() {
    return this.arsMagnus.reduce(
      (total, developedArsMagnus) => total + developedArsMagnus.pdCost,
      0,
    );
  }
}
