import { ExtendedModel, prop } from 'mobx-keystone';
import { PrecalculatedFieldModel } from '../../../../utils/fields/PrecalculatedFieldModel';
import { PrimaryCharacteristicModifierFieldModel } from './PrimaryCharacteristicModifierFieldModel';
import {
  Add1ToPrimaryCharacteristicAdvantage,
  Add1ToPrimaryCharacteristicAdvantageVariation,
} from '../../../../../../aggregations/advantage/CommonAdvantages';
import {
  Rest2ToPrimaryCharacteristicDisadvantage,
  Rest2ToPrimaryCharacteristicDisadvantageVariation,
} from '../../../../../../aggregations/disadvantage/CommonDisadvantages';

export abstract class PrimaryFieldModel extends ExtendedModel(
  PrecalculatedFieldModel,
  {
    modifier: prop(() => new PrimaryCharacteristicModifierFieldModel({})),
  },
) {
  abstract advantageVariation: Add1ToPrimaryCharacteristicAdvantageVariation;

  abstract disadvantageVariation: Rest2ToPrimaryCharacteristicDisadvantageVariation;

  abstract getPrimaryBonus(): number;

  setBase(value: number) {
    super.setBase(Math.min(20, Math.max(0, value)));
  }

  calculateAdvantageBonus(): number {
    if (!this.character) return 0;

    const advantages = this.character.creationPoints.getAdvantages(
      Add1ToPrimaryCharacteristicAdvantage,
      { variation: this.advantageVariation },
    );

    return advantages.length;
  }

  calculateDisadvantagePenalty(): number {
    if (!this.character) return 0;

    const advantages = this.character.creationPoints.getDisadvantages(
      Rest2ToPrimaryCharacteristicDisadvantage,
      { variation: this.disadvantageVariation },
    );

    return advantages.length * 2;
  }

  get final(): number {
    if (!this.character) return 0;

    let bonuses = 0;

    bonuses += this.calculateAdvantageBonus();

    bonuses -= this.calculateDisadvantagePenalty();

    bonuses += this.getPrimaryBonus();

    return Math.max(0, Math.min(20, this.base + this.special + bonuses));
  }
}
