import { ReactNode } from 'react';
import {
  Divider,
  SectionParagraph,
  SectionSubtitle,
  SectionTitle,
  Wrapper,
} from './Section.styled';

type SectionProps = {
  className?: string;
  children: ReactNode | ReactNode[];
  direction?: 'row' | 'column';
  maxHeight?: string;
};

export const Section = ({
  className,
  children,
  direction = 'column',
  maxHeight,
}: SectionProps) => {
  return (
    <Wrapper
      className={className}
      $direction={direction}
      $maxHeight={maxHeight}
    >
      {children}
    </Wrapper>
  );
};

// eslint-disable-next-line react/display-name
Section.Title = ({ children }: { children: ReactNode }) => (
  <SectionTitle>{children}</SectionTitle>
);

// eslint-disable-next-line react/display-name
Section.Subtitle = ({ children }: { children: ReactNode }) => (
  <SectionSubtitle>{children}</SectionSubtitle>
);

// eslint-disable-next-line react/display-name
Section.Paragraph = ({ children }: { children: ReactNode }) => (
  <SectionParagraph>{children}</SectionParagraph>
);

Section.Divider = Divider;
