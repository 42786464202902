import { ReactNode } from 'react';
import { TableHeaderRow } from './components/TableHeaderRow/TableHeaderRow';
import { TableRow } from './components/TableRow/TableRow';
import { StyledTable, TableBody, TableHead } from './Table.styled';

type TableProps = {
  children: ReactNode[];
};

export const Table = ({ children }: TableProps) => {
  const header = children[0];
  const rows = children.slice(1);

  return (
    <StyledTable>
      <TableHead>{header}</TableHead>
      <TableBody>{rows}</TableBody>
    </StyledTable>
  );
};

Table.Header = TableHeaderRow;
Table.Row = TableRow;
