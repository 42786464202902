import { SecondarySkill } from '../../character/model/types/SecondarySkill';
import { Archetype, CategoryTemplate, CategoryType } from './CategoryTemplate';

export class WizardCategoryTemplate extends CategoryTemplate {
  name = 'Hechicero';
  archetypes = [Archetype.Mystic];
  type = CategoryType.Wizard;

  readonly lifeMultiplier = 20;
  lifePoints = 5;
  protected turn = 5;
  protected martialKnowledge = 10;
  protected nativePsychicPoints = 1;

  offensiveSkillsLimitPercentage = 0.5;
  attackPDsCost = 3;
  blockPDsCost = 3;
  dodgePDsCost = 2;
  wearArmorPDsCost = 3;
  kiPDsCost = 3;
  kiAccumMultiplierPDsCost = 30;

  magicSkillsLimitPercentage = 0.6;
  zeonPDsCost = 1;
  aCTMultiplierPDsCost = 50;
  magicProjectionPDsCost = 2;
  summonPDsCost = 2;
  controlPDsCost = 2;
  bindPDsCost = 2;
  banishPDsCost = 2;

  psychicSkillsLimitPercentage = 0.5;
  psychicPointsPDsCost = 20;
  psychicProjectionPDsCost = 3;

  athleticSecondariesPDsCost = 2;
  socialSecondariesPDsCost = 2;
  perceptionSecondariesPDsCost = 2;
  intellectualSecondariesPDsCost = 2;
  vigorSecondariesPDsCost = 3;
  subterfugeSecondariesPDsCost = 2;
  creativeSecondariesPDsCost = 2;

  zeonBonus = 100;

  calculateSecondarySkillPDReduction(skill: SecondarySkill): number {
    if (skill === SecondarySkill.MagicAppraisal) return 1;

    return 0;
  }

  calculateSecondarySkillBonusPerCategory(skill: SecondarySkill): number {
    if (skill === SecondarySkill.MagicAppraisal) return 10;
    if (skill === SecondarySkill.Occultism) return 5;

    return 0;
  }
}
