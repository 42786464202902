import { SecondarySkillFieldModel } from '../../../../../../character/domain/character/model/parts/secondaries/utils/fields/SecondarySkillFieldModel';
import { ActorMutator } from '../../../ActorCharacterTransformer';
import { FoundryVTTActor } from '../FoundryVTTActor';
import { createBaseFinalField } from './utils/createBaseFinalField';
import { SecondarySkillFieldAffectedByNaturalPenaltyModel } from '../../../../../../character/domain/character/model/parts/secondaries/utils/fields/SecondarySkillFieldAffectedByNaturalPenaltyModel';
import { PhysicalSecondarySkillFieldModel } from '../../../../../../character/domain/character/model/parts/secondaries/utils/fields/PhysicalSecondarySkillFieldModel';

export const mutateActorSecondaries: ActorMutator<FoundryVTTActor> = ({
  actor,
  character,
}) => {
  const content = character.content;

  const createSecondarySkillField = (
    secondarySkill: SecondarySkillFieldModel,
  ) => {
    if (secondarySkill.needToBeDeveloped && !secondarySkill.isDeveloped) {
      return createBaseFinalField(-999, -999);
    }

    const final = secondarySkill.final;

    if (
      secondarySkill instanceof SecondarySkillFieldAffectedByNaturalPenaltyModel
    ) {
      return createBaseFinalField(
        final +
          secondarySkill.naturalPenalty -
          secondarySkill.allPhysicalActionsPenalty -
          secondarySkill.allActionsPenalty,
      );
    }

    if (secondarySkill instanceof PhysicalSecondarySkillFieldModel) {
      return createBaseFinalField(
        final -
          secondarySkill.allPhysicalActionsPenalty -
          secondarySkill.allActionsPenalty,
      );
    }

    return createBaseFinalField(final);
  };

  actor.data.secondaries = {
    secondarySpecialSkills: actor.data.secondaries.secondarySpecialSkills,

    athletics: {
      acrobatics: createSecondarySkillField(
        content.secondaries.athletic.acrobatics,
      ),
      athleticism: createSecondarySkillField(
        content.secondaries.athletic.athleticism,
      ),
      climb: createSecondarySkillField(content.secondaries.athletic.climb),
      jump: createSecondarySkillField(content.secondaries.athletic.jump),
      piloting: createSecondarySkillField(
        content.secondaries.athletic.piloting,
      ),
      ride: createSecondarySkillField(content.secondaries.athletic.ride),
      swim: createSecondarySkillField(content.secondaries.athletic.swim),
    },
    creative: {
      alchemy: createSecondarySkillField(content.secondaries.creative.alchemy),
      animism: createSecondarySkillField(content.secondaries.creative.animism),
      art: createSecondarySkillField(content.secondaries.creative.art),
      dance: createSecondarySkillField(content.secondaries.creative.dance),
      forging: createSecondarySkillField(content.secondaries.creative.forging),
      jewelry: createSecondarySkillField(content.secondaries.creative.jewelry),
      music: createSecondarySkillField(content.secondaries.creative.music),
      puppetMaking: createSecondarySkillField(
        content.secondaries.creative.puppetMaking,
      ),
      ritualCalligraphy: createSecondarySkillField(
        content.secondaries.creative.ritualCalligraphy,
      ),
      runes: createSecondarySkillField(content.secondaries.creative.runes),
      sleightOfHand: createSecondarySkillField(
        content.secondaries.creative.sleightOfHand,
      ),
      tailoring: createSecondarySkillField(
        content.secondaries.creative.tailoring,
      ),
    },
    perception: {
      notice: createSecondarySkillField(content.secondaries.perceptive.notice),
      search: createSecondarySkillField(content.secondaries.perceptive.search),
      track: createSecondarySkillField(content.secondaries.perceptive.track),
    },
    social: {
      etiquette: createSecondarySkillField(
        content.secondaries.social.etiquette,
      ),
      intimidate: createSecondarySkillField(
        content.secondaries.social.intimidate,
      ),
      leadership: createSecondarySkillField(
        content.secondaries.social.leadership,
      ),
      persuasion: createSecondarySkillField(
        content.secondaries.social.persuasion,
      ),
      streetwise: createSecondarySkillField(
        content.secondaries.social.streetwise,
      ),
      style: createSecondarySkillField(content.secondaries.social.style),
      trading: createSecondarySkillField(content.secondaries.social.trading),
    },
    subterfuge: {
      disguise: createSecondarySkillField(
        content.secondaries.subterfuge.disguise,
      ),
      hide: createSecondarySkillField(content.secondaries.subterfuge.hide),
      lockPicking: createSecondarySkillField(
        content.secondaries.subterfuge.lockPicking,
      ),
      poisons: createSecondarySkillField(
        content.secondaries.subterfuge.poisons,
      ),
      stealth: createSecondarySkillField(
        content.secondaries.subterfuge.stealth,
      ),
      theft: createSecondarySkillField(content.secondaries.subterfuge.steal),
      trapLore: createSecondarySkillField(content.secondaries.subterfuge.traps),
    },
    vigor: {
      composure: createSecondarySkillField(content.secondaries.vigor.composure),
      featsOfStrength: createSecondarySkillField(
        content.secondaries.vigor.featsOfStrength,
      ),
      withstandPain: createSecondarySkillField(
        content.secondaries.vigor.withstandPain,
      ),
    },
    intellectual: {
      animals: createSecondarySkillField(
        content.secondaries.intellectual.animals,
      ),
      appraisal: createSecondarySkillField(
        content.secondaries.intellectual.appraisal,
      ),
      herbalLore: createSecondarySkillField(
        content.secondaries.intellectual.herbalism,
      ),
      history: createSecondarySkillField(
        content.secondaries.intellectual.history,
      ),
      law: createSecondarySkillField(content.secondaries.intellectual.law),
      magicAppraisal: createSecondarySkillField(
        content.secondaries.intellectual.magicAppraisal,
      ),
      medicine: createSecondarySkillField(
        content.secondaries.intellectual.medicine,
      ),
      memorize: createSecondarySkillField(
        content.secondaries.intellectual.memorize,
      ),
      navigation: createSecondarySkillField(
        content.secondaries.intellectual.navigation,
      ),
      occult: createSecondarySkillField(
        content.secondaries.intellectual.occultism,
      ),
      science: createSecondarySkillField(
        content.secondaries.intellectual.science,
      ),
      tactics: createSecondarySkillField(
        content.secondaries.intellectual.tactics,
      ),
    },
  };

  return { actor };
};
