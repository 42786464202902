import styled from 'styled-components';
import { Tabs } from '../../../../../../shared/application/design-system/components/Tabs/Tabs';

export const FabContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

export const Wrapper = styled.div(({ theme: { size } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  maxWidth: '50rem',
  margin: '0 auto',
  paddingTop: size.units(1),
  paddingBottom: size.units(1),

  boxSizing: 'border-box',

  [FabContainer]: {
    position: 'fixed',
    bottom: size.units(2),
    right: size.units(2),
  },
}));

export const Container = styled.div(({ theme: { mixins } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',

  paddingLeft: '1rem',
  paddingRight: '1rem',
  boxSizing: 'border-box',
  marginBottom: '0.75rem',

  ...mixins.responsive.ipad.downward({
    marginBottom: '1rem',
  }),
}));

export const StyledTabs = styled(Tabs)(({ theme: { color, mixins } }) => ({
  backgroundColor: color.black(),
  justifyContent: 'center',

  borderRadius: '0.5rem',

  padding: '0.5rem',

  ...mixins.responsive.sm.downward({
    gap: '0.5rem',
  }),

  ...mixins.responsive.xs.downward({
    borderRadius: '0',
  }),
}));
