import { Tooltip } from '@mui/material';
import { vibrate } from '../../../../../../../../../shared/utils/vibrate';
import { StyledIconButton, Wrapper } from './AddItemButton.styled';
import { useCharacterContext } from '../../../hooks/useCharacterContext';

type AddItemButtonProps = {
  onClick: () => void;
  children?: string;

  disabled?: boolean;
};

export const AddItemButton = ({
  children,
  disabled,
  onClick,
}: AddItemButtonProps) => {
  const { character } = useCharacterContext();

  const handleButtonClick = () => {
    vibrate();
    onClick();
  };

  const inputDisabled = disabled || !character.canBeEdited;

  const button = (
    <StyledIconButton
      $disabled={inputDisabled}
      onClick={inputDisabled ? undefined : handleButtonClick}
    />
  );

  if (children) {
    return <Tooltip title={children}>{button}</Tooltip>;
  }

  return <Wrapper>{button}</Wrapper>;
};
