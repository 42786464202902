import { observer } from 'mobx-react';
import { MouseEvent, useState } from 'react';
import { DefaultMagicSpells } from '../../../../../../../../../domain/aggregations/magic-spell/DefaultMagicSpells';
import { MagicSpell } from '../../../../../../../../../domain/aggregations/magic-spell/MagicSpell';
import { getUnusedMagicSpell } from '../../../../../../../../../domain/aggregations/magic-spell/utils/getUnusedMagicSpell';
import {
  createModelFromMagicSpell,
  MagicSpellModel,
} from '../../../../../../../../../domain/character/model/parts/mystic/parts/magic-spell/MagicSpellModel';
import { useCharacterContext } from '../../../../hooks/useCharacterContext';
import { AddItemButton } from '../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../common/CharacterMenu/useCharacterMenu';
import { transformToMenuItems } from '../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { RemoveItemButton } from '../../../common/RemoveItemButton/RemoveItemButton';
import { Section } from '../../../common/Section/Section';
import { Table } from '../../../common/Table/Table';
import { MenuItem } from '../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';

export const SelectedSpells = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { handleMenuOpen, Menu } = useCharacterMenu<MagicSpell>();

  const [selectedSelectedSpell, setSelectedSelectedSpell] = useState<
    MagicSpellModel | undefined
  >(undefined);

  const addSelectedSpell = () => {
    const unusedSpell = getUnusedMagicSpell(
      character.mystic.selectedSpells.map((s) => s.toObject()),
    );

    if (!unusedSpell) return;

    character.mystic.addSelectedSpell(unusedSpell);
  };

  const changeSelectedSpell = (from: MagicSpellModel, to: MagicSpellModel) => {
    character.mystic.replaceSelectedSpell(from, to);
  };

  const removeSelectedSpell = (spell: MagicSpellModel) => () => {
    character.mystic.removeSelectedSpell(spell);
  };

  const spellItems: MenuItem<MagicSpell>[] = transformToMenuItems({
    fromItems: DefaultMagicSpells,
    generateItem: (item) => ({
      key: item.id,
      name: item.name,
      value: item,
      onClick: () => {
        if (selectedSelectedSpell !== undefined) {
          changeSelectedSpell(
            selectedSelectedSpell,
            createModelFromMagicSpell(item),
          );
        }
      },
    }),
    generateDivider: (item) => item.magicPath,
  });

  const handleSelectedSpellClick =
    (spell: MagicSpellModel) => (e: MouseEvent<HTMLDivElement>) => {
      setSelectedSelectedSpell(spell);
      handleMenuOpen(spell)(e);
    };

  return (
    <Section maxHeight="fit">
      <Section.Title>Conjuros seleccionados</Section.Title>
      <Table>
        <Table.Header>
          <Table.Header.Cell>Nombre</Table.Header.Cell>
          <Table.Header.Cell>Vía</Table.Header.Cell>
          <Table.Header.Cell>Nivel</Table.Header.Cell>
          <Table.Header.Cell />
        </Table.Header>
        {character.mystic.selectedSpells.map((spell, i) => (
          <Table.Row key={`${spell.id}-${i}`}>
            <Table.Row.Cell
              $interactable
              onClick={handleSelectedSpellClick(spell)}
            >
              {spell.name}
            </Table.Row.Cell>
            <Table.Row.Cell>{spell.magicPath}</Table.Row.Cell>
            <Table.Row.Cell>{spell.level}</Table.Row.Cell>
            <Table.Row.Cell>
              <RemoveItemButton onClick={removeSelectedSpell(spell)} />
            </Table.Row.Cell>
          </Table.Row>
        ))}
      </Table>

      <AddItemButton onClick={addSelectedSpell} />

      <Menu items={spellItems} />
    </Section>
  );
});
