import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class MallaYuddhaMartialArt extends BasicMartialArt {
  readonly name = 'Malla-Yuddha';

  readonly complexity = 1;

  readonly type = MartialArtType.MallaYuddha;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      effects: '10 de entereza parando ataques desarmado',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 20;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 20) {
          errors.push('Trucos de manos 20+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      effects: 'No sufre daño al parar desarmado',
      combatBonus: {
        block: 10,
      },
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 30;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 40) {
          errors.push('Trucos de manos 40+');
        }

        if (character.combat.defensiveAbility < 120) {
          errors.push('Bloqueo o esquiva 120+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      effects: 'Permite desarmar sin penalizador en contraataque',
      combatBonus: {
        block: 20,
      },
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 40;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.creative.sleightOfHand.final < 100) {
          errors.push('Trucos de manos 100+');
        }

        if (character.combat.defensiveAbility < 200) {
          errors.push('Bloqueo o esquiva 200+');
        }

        return errors;
      },
    }),
  ];
}
