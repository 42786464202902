import { CharacterSheet } from './components/CharacterSheet/CharacterSheet';
import { AuthorizedPageLayout } from '../../../../shared/application/design-system/components/PageLayout/PageLayout';
import { GoBackNavbar } from '../../../../shared/application/design-system/components/TopNavbar/implementations/GoBackNavbar';
import { useParams } from 'react-router-dom';
import {
  Routes,
  useNavigate,
} from '../../../../shared/application/hooks/useNavigate';

export const CharacterPage = ({ readonly }: { readonly?: boolean }) => {
  const { characterId } = useParams<{ characterId: string }>();
  const navigate = useNavigate();

  if (!characterId) {
    navigate.navigate(Routes.MyCharacters, { params: { folderId: '' } });

    return null;
  }

  return (
    <AuthorizedPageLayout Header={<GoBackNavbar />}>
      <CharacterSheet characterId={characterId} readonly={readonly} />
    </AuthorizedPageLayout>
  );
};
