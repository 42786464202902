import React, { ChangeEvent } from 'react';
import { vibrate } from '../../../../../../../../../shared/utils/vibrate';
import {
  InputWrapper,
  Label,
  StyledInput,
  StyledTextArea,
  Wrapper,
} from './StringInput.styled';
import {
  StringInputSize,
  StringInputVariant,
  StringLabelPosition,
} from './StringInput.types';
import { useCharacterContext } from '../../../hooks/useCharacterContext';

export type StringInputProps = {
  label?: string;

  as?: 'textarea' | 'input';

  value: string;
  setValue: (value: string) => void;

  variant?: StringInputVariant;
  size?: StringInputSize;
  labelPosition?: StringLabelPosition;

  rows?: number;

  disabled?: boolean;
};

export const StringInput = ({
  value,
  setValue,
  as,
  variant,
  labelPosition = StringLabelPosition.Left,
  label,
  size,
  rows,
  disabled = false,
}: StringInputProps) => {
  const { character } = useCharacterContext();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const handleOnFocus = () => {
    vibrate();
  };

  const inputDisabled = disabled || !character.canBeEdited;

  return (
    <Wrapper $hasLabel={!!label} $labelPosition={labelPosition}>
      {label && (
        <Label $labelPosition={labelPosition} variant="body1">
          {label}
        </Label>
      )}
      <InputWrapper>
        {as === 'textarea' ? (
          <StyledTextArea
            onFocus={handleOnFocus}
            onChange={onChange}
            disabled={inputDisabled}
            $size={size}
            $variant={variant}
            minRows={rows}
          >
            {value}
          </StyledTextArea>
        ) : (
          <StyledInput
            value={value}
            onFocus={handleOnFocus}
            onChange={onChange}
            disabled={inputDisabled}
            $size={size}
            $variant={variant}
          />
        )}
      </InputWrapper>
    </Wrapper>
  );
};

StringInput.LabelPosition = StringLabelPosition;
StringInput.Variant = StringInputVariant;
StringInput.Size = StringInputSize;
