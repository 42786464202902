export enum LocalStorageKeys {
  LoginEmail = 'charactersVault.login.email',
  LastVersionRead = 'charactersVault.last.version.read',
  DoNotShowEmbeddedFirefoxWarning = 'charactersVault.embedded.firefox.warning',
  BetaSignUpEmail = 'charactersVault.beta.signup.email',
}

export const LocalStorageManager = {
  setItem: (key: LocalStorageKeys, value: string | boolean | number) => {
    localStorage.setItem(key, value.toString());
  },
  getItem: (key: LocalStorageKeys) => {
    return localStorage.getItem(key);
  },
  hasItem: (key: LocalStorageKeys) => {
    return !!localStorage.getItem(key);
  },
  removeItem: (key: LocalStorageKeys) => {
    localStorage.removeItem(key);
  },
};
