import { observer } from 'mobx-react';
import React, { MouseEvent, useState } from 'react';
import {
  AllKiTechniques,
  getUnusedKiTechniques,
  KiTechniqueId,
} from '../../../../../../../../../../domain/aggregations/ki-technique/KiTechnique';
import {
  createModelFromKiTechnique,
  KiTechniqueModel,
} from '../../../../../../../../../../domain/character/model/parts/ki/parts/KiTechniqueModel';
import { filterUndefined } from '../../../../../../../../../../../shared/utils/filterUndefined';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { AddItemButton } from '../../../../common/AddItemButton/AddItemButton';
import { useCharacterMenu } from '../../../../common/CharacterMenu/useCharacterMenu';
import { transformToMenuItems } from '../../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { RemoveItemButton } from '../../../../common/RemoveItemButton/RemoveItemButton';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';
import { getReadableKiCost } from './utils/getReadableKiCost';
import { EditItemButton } from '../../../../common/EditItemButton/EditItemButton';
import { useModal } from '../../../../../../../../../../../shared/application/design-system/components/Modal/hooks/useModal';
import { EditKiTechnique } from './components/EditKiTechnique/EditKiTechnique';
import { MenuItem } from '../../../../../../../../../../../shared/application/design-system/components/Menu/useMenu';

export const KiTechniques = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const { handleMenuOpen, Menu } = useCharacterMenu<KiTechniqueId>();
  const { Modal, openModal, closeModal } = useModal();

  const [selectedTechnique, setSelectedTechnique] = useState<
    KiTechniqueModel | undefined
  >();

  const handleAddKiTechnique = () => {
    const unknownTechnique = getUnusedKiTechniques(
      character.ki.kiTechniques.map((k) => k.id).filter(filterUndefined()),
    );

    if (unknownTechnique) {
      character.ki.addKiTechnique(unknownTechnique);
    }
  };

  const handleEditKiTechnique = (technique: KiTechniqueModel) => () => {
    openModal(
      <EditKiTechnique
        technique={technique}
        onSaveTechnique={() => closeModal()}
      />,
      { fullScreenInMobile: true },
    );
  };

  const handleRemoveKiTechnique = (technique: KiTechniqueModel) => () => {
    character.ki.removeKiTechnique(technique);
  };

  const menuItems: MenuItem<KiTechniqueId>[] = transformToMenuItems({
    fromItems: AllKiTechniques,
    generateItem: (kiTechnique) => ({
      key: kiTechnique.id,
      name: kiTechnique.name,
      value: kiTechnique.id,
      onClick: () => {
        if (selectedTechnique) {
          character.ki.replaceKiTechnique(
            selectedTechnique,
            createModelFromKiTechnique(kiTechnique),
          );
        }
      },
    }),
    generateDivider: (kiTechnique) => kiTechnique.type,
  });

  const handleItemClick =
    (kiTechnique: KiTechniqueModel) => (e: MouseEvent<HTMLDivElement>) => {
      setSelectedTechnique(kiTechnique);
      handleMenuOpen(kiTechnique.id)(e);
    };

  return (
    <Section maxHeight="fit">
      <Section.Title>Técnicas</Section.Title>
      <Table>
        <Table.Header>
          <Table.Header.Cell>Nombre</Table.Header.Cell>
          <Table.Header.Cell $hideOnMobile>Árbol</Table.Header.Cell>
          <Table.Header.Cell $hideOnMobile>Nivel</Table.Header.Cell>
          <Table.Header.Cell>CM</Table.Header.Cell>
          <Table.Header.Cell>Coste (Mantenido)</Table.Header.Cell>
          <Table.Header.Cell $hideOnMobile>Efectos</Table.Header.Cell>
          <Table.Header.Cell $hideOnMobile>Notas</Table.Header.Cell>
          <Table.Header.Cell />
        </Table.Header>
        {character.ki.kiTechniques.map((t, i) => (
          <Table.Row key={t.id ?? i}>
            <Table.Row.Cell onClick={handleItemClick(t)}>
              {t.name}
            </Table.Row.Cell>
            <Table.Row.Cell $hideOnMobile>{t.type}</Table.Row.Cell>
            <Table.Row.Cell $hideOnMobile>{t.level}</Table.Row.Cell>
            <Table.Row.Cell>{t.mpCost}</Table.Row.Cell>
            <Table.Row.Cell>{getReadableKiCost(t.kiCost)}</Table.Row.Cell>
            <Table.Row.Cell $hideOnMobile>{t.effect}</Table.Row.Cell>
            <Table.Row.Cell $hideOnMobile>{t.notes}</Table.Row.Cell>
            <Table.Row.Cell>
              <EditItemButton onClick={handleEditKiTechnique(t)} />
            </Table.Row.Cell>
            <Table.Row.Cell>
              <RemoveItemButton onClick={handleRemoveKiTechnique(t)} />
            </Table.Row.Cell>
          </Table.Row>
        ))}
      </Table>

      <AddItemButton onClick={handleAddKiTechnique} />

      <Menu items={menuItems} />
      <Modal />
    </Section>
  );
});
