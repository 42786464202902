import {
  Advantage,
  AdvantageLevel1,
  AdvantageLevel2,
  AdvantageLevel3,
  AdvantageType,
} from './Advantage.types';

enum InitialBudgetForBackgroundAdvantages {
  TenThousand = 'TenThousand',
  FiveThousand = 'FiveThousand',
  TwoThousand = 'TwoThousand',
}

export const PowerfulAllieAdvantage: Advantage = {
  id: 'advantage-background-1',

  name: 'Aliado poderoso',

  type: AdvantageType.Background,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const ArtefactAdvantage: Advantage = {
  id: 'advantage-background-2',

  name: 'Artefacto',

  type: AdvantageType.Background,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const ContactsAdvantage: Advantage = {
  id: 'advantage-background-3',

  name: 'Contactos',

  type: AdvantageType.Background,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const ExperiencedAdvantage: Advantage = {
  id: 'advantage-background-4',

  name: 'Curtido',

  type: AdvantageType.Background,

  levels: [AdvantageLevel1, AdvantageLevel2, AdvantageLevel3],
};

export const FamousAdvantage: Advantage = {
  id: 'advantage-background-5',

  name: 'Fama',

  type: AdvantageType.Background,

  levels: [AdvantageLevel1, AdvantageLevel2],
};

export const InitialBudgetAdvantage: Advantage<InitialBudgetForBackgroundAdvantages> =
  {
    id: 'advantage-background-6',

    name: 'Fondos iniciales',

    type: AdvantageType.Background,

    levels: [AdvantageLevel1],

    variations: [
      {
        id: InitialBudgetForBackgroundAdvantages.TenThousand,
        name: '10.000 monedas de oro',
      },
      {
        id: InitialBudgetForBackgroundAdvantages.FiveThousand,
        name: '5.000 monedas de oro',
      },
      {
        id: InitialBudgetForBackgroundAdvantages.TwoThousand,
        name: '2.000 monedas de oro',
      },
    ],
  };

export const SocialPositionAdvantage: Advantage = {
  id: 'advantage-background-7',

  name: 'Posición social',

  type: AdvantageType.Background,

  levels: [AdvantageLevel1, AdvantageLevel2],
};

export const CulturalRootsAdvantage: Advantage = {
  id: 'advantage-background-8',

  name: 'Raíces culturales',

  type: AdvantageType.Background,

  levels: [AdvantageLevel1],
};

export const OldBloodAdvantage: Advantage = {
  id: 'advantage-background-9',

  name: 'Sangre antigua',

  type: AdvantageType.Background,

  levels: [AdvantageLevel2],
};

export const SaintAdvantage: Advantage = {
  id: 'advantage-background-10',

  name: 'Santo',

  type: AdvantageType.Background,

  levels: [AdvantageLevel2],
};

export const AllBackgroundAdvantages: Advantage[] = [
  PowerfulAllieAdvantage,
  ArtefactAdvantage,
  ContactsAdvantage,
  ExperiencedAdvantage,
  FamousAdvantage,
  InitialBudgetAdvantage,
  SocialPositionAdvantage,
  CulturalRootsAdvantage,
  OldBloodAdvantage,
  SaintAdvantage,
];
