import { model } from 'mobx-keystone';
import { WeaponFieldModel } from './WeaponFieldModel';

@model('WeaponItem/WeaponRangeField')
export class WeaponRangeFieldModel extends WeaponFieldModel {
  get final(): number {
    if (!this.character || !this.weapon) return 0;

    const strength =
      this.weapon.ownStrength ??
      this.character.primaries.strength.modifier.final;

    const baseRange = this.base + this.special;

    const rangeFromStrength = this.getWeaponRangeFromStrength(strength);

    if (strength > 10 && this.weapon.quality.final < 5) {
      return baseRange + 50;
    }

    if (
      (strength === 12 || strength === 13) &&
      this.weapon.quality.final < 10
    ) {
      return baseRange + 100;
    }

    if (
      (strength === 14 || strength === 15) &&
      this.weapon.quality.final < 15
    ) {
      return baseRange + 500;
    }

    if (strength > 15 && this.weapon.quality.final < 20) {
      return baseRange + 5000;
    }

    if (rangeFromStrength !== undefined) {
      return Math.max(0, baseRange + rangeFromStrength);
    }

    return 0;
  }

  private getWeaponRangeFromStrength(strength: number): number | undefined {
    if (strength > 15) return 10000;

    switch (strength) {
      case 3:
        return -30;
      case 4:
        return -10;
      case 5:
      case 6:
        return 0;
      case 7:
        return 10;
      case 8:
        return 20;
      case 9:
        return 30;
      case 10:
        return 50;
      case 11:
        return 100;
      case 12:
        return 250;
      case 13:
        return 500;
      case 14:
        return 1000;
      case 15:
        return 5000;
    }

    return undefined;
  }
}
