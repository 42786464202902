import { SecondarySkill } from '../../character/model/types/SecondarySkill';
import { Archetype, CategoryTemplate, CategoryType } from './CategoryTemplate';

export class DarkPaladinCategoryTemplate extends CategoryTemplate {
  name = 'Pala Oscuro';
  archetypes = [Archetype.Fighter];
  type = CategoryType.DarkPaladin;

  readonly lifeMultiplier = 15;
  lifePoints = 15;
  protected turn = 5;
  protected martialKnowledge = 20;
  protected nativePsychicPoints = 1;

  offensiveSkillsLimitPercentage = 0.6;
  attackPDsCost = 2;
  blockPDsCost = 2;
  dodgePDsCost = 2;
  wearArmorPDsCost = 2;
  kiPDsCost = 2;
  kiAccumMultiplierPDsCost = 20;

  magicSkillsLimitPercentage = 0.5;
  zeonPDsCost = 2;
  aCTMultiplierPDsCost = 60;
  magicProjectionPDsCost = 3;
  summonPDsCost = 3;
  controlPDsCost = 1;
  bindPDsCost = 3;
  banishPDsCost = 3;

  psychicSkillsLimitPercentage = 0.5;
  psychicPointsPDsCost = 20;
  psychicProjectionPDsCost = 3;

  athleticSecondariesPDsCost = 2;
  socialSecondariesPDsCost = 1;
  perceptionSecondariesPDsCost = 2;
  intellectualSecondariesPDsCost = 2;
  vigorSecondariesPDsCost = 2;
  subterfugeSecondariesPDsCost = 2;
  creativeSecondariesPDsCost = 2;

  attackBonus = 5;
  wearArmorBonus = 5;
  controlBonus = 10;
  zeonBonus = 20;

  calculateSecondarySkillPDReduction(skill: SecondarySkill): number {
    if (skill === SecondarySkill.Composure) return 1;

    return 0;
  }

  calculateSecondarySkillBonusPerCategory(skill: SecondarySkill): number {
    if (skill === SecondarySkill.Intimidate) return 10;
    if (skill === SecondarySkill.Composure) return 10;
    if (skill === SecondarySkill.Style) return 5;
    if (skill === SecondarySkill.Persuasion) return 5;

    return 0;
  }
}
