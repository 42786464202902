import { SecondarySkill } from '../../character/model/types/SecondarySkill';
import { Archetype, CategoryTemplate, CategoryType } from './CategoryTemplate';

export class WeaponMasterCategoryTemplate extends CategoryTemplate {
  name = 'Maestro de Armas';
  archetypes = [Archetype.Fighter];
  type = CategoryType.WeaponMaster;

  readonly lifeMultiplier = 10;
  lifePoints = 20;
  protected turn = 5;
  protected martialKnowledge = 10;
  protected nativePsychicPoints = 1;

  offensiveSkillsLimitPercentage = 0.6;
  attackPDsCost = 2;
  blockPDsCost = 2;
  dodgePDsCost = 2;
  wearArmorPDsCost = 1;
  kiPDsCost = 3;
  kiAccumMultiplierPDsCost = 30;

  magicSkillsLimitPercentage = 0.5;
  zeonPDsCost = 3;
  aCTMultiplierPDsCost = 70;
  magicProjectionPDsCost = 3;
  summonPDsCost = 3;
  controlPDsCost = 3;
  bindPDsCost = 3;
  banishPDsCost = 3;

  psychicSkillsLimitPercentage = 0.5;
  psychicPointsPDsCost = 20;
  psychicProjectionPDsCost = 3;

  athleticSecondariesPDsCost = 2;
  socialSecondariesPDsCost = 2;
  perceptionSecondariesPDsCost = 2;
  intellectualSecondariesPDsCost = 3;
  vigorSecondariesPDsCost = 1;
  subterfugeSecondariesPDsCost = 3;
  creativeSecondariesPDsCost = 2;

  attackBonus = 5;
  blockBonus = 5;
  wearArmorBonus = 10;

  calculateSecondarySkillBonusPerCategory(skill: SecondarySkill): number {
    if (skill === SecondarySkill.FeatsOfStrength) return 5;

    return 0;
  }
}
