export const getACTBaseFromPower = (power: number): number => {
  if (power <= 4) return 0;
  if (power <= 7) return 5;
  if (power <= 11) return 10;
  if (power <= 14) return 15;
  if (power <= 15) return 20;
  if (power <= 17) return 25;
  if (power <= 19) return 30;

  return 35;
};
