import { CharacterMutator } from '../../../ActorCharacterTransformer';
import { FoundryVTTActor } from '../FoundryVTTActor';
import { findItemsByName } from './utils/findItemsByName';
import { AllCategories } from '../../../../../../character/domain/aggregations/category-templates/utils/getCategoryTemplateFromType';
import { CategoryModel } from '../../../../../../character/domain/character/model/parts/pd/parts/categories/CategoryModel';
import { PrecalculatedFieldModel } from '../../../../../../character/domain/character/model/utils/fields/PrecalculatedFieldModel';

export const mutateCharacterGeneral: CharacterMutator<FoundryVTTActor> = ({
  actor,
  character,
}) => {
  character.content.setName(actor.name);

  character.content.description.setAge(actor.data.general.aspect.age.value);
  character.content.description.setEyes(actor.data.general.aspect.eyes.value);
  character.content.description.setGender(
    actor.data.general.aspect.gender.value,
  );
  character.content.description.setHair(actor.data.general.aspect.hair.value);
  character.content.description.setHeight(
    actor.data.general.aspect.height.value,
  );
  character.content.description.setRace(actor.data.general.aspect.race.value);
  character.content.description.setWeight(
    actor.data.general.aspect.weight.value,
  );

  character.content.description.setPhysicalDescription(
    actor.data.general.description.value,
  );

  character.content.description.setNotes(
    actor.data.general.notes.map((n) => n.name).join('\n'),
  );

  for (const level of actor.data.general.levels) {
    const [category] = findItemsByName(AllCategories, [level.name]);

    if (category) {
      character.content.pd.addCategory(
        new CategoryModel({
          category: category.type,
          level: new PrecalculatedFieldModel({ base: level.data.level }),
        }),
      );
    }
  }

  return { character };
};
