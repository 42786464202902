import {
  BasicMartialArtGrade,
  buildBasicMartialArtAdvancedGrade,
  buildBasicMartialArtBasicGrade,
  buildBasicMartialArtSupremeGrade,
} from '../../MartialArtGrades';
import {
  BasicMartialArt,
  MartialArtLevelType,
  MartialArtType,
} from '../../MartialArt';
import { HitType } from '../../../../character/model/types/HitType';

export class CapoeiraMartialArt extends BasicMartialArt {
  readonly name = 'Capoeira';

  readonly complexity = 1;

  readonly type = MartialArtType.Capoeira;

  readonly level = MartialArtLevelType.Basic;

  readonly grades: BasicMartialArtGrade[] = [
    buildBasicMartialArtBasicGrade({
      effects:
        'Ataque en area se considera arma media y afecta a 3 adversarios',
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 20;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.creative.dance.final < 20) {
          errors.push('Baile 20+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtAdvancedGrade({
      cmBonus: 10,
      effects: 'Igual que anterior pero arma grande y afecta a 5 adversarios',
      combatBonus: {
        dodge: 10,
      },
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 20;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.creative.dance.final < 40) {
          errors.push('Baile 40+');
        }

        if (character.combat.defensiveAbility < 100) {
          errors.push('Bloqueo o esquiva 100+');
        }

        return errors;
      },
    }),
    buildBasicMartialArtSupremeGrade({
      cmBonus: 20,
      effects: 'Penalizador de ataque en area es -10.',
      combatBonus: {
        dodge: 10,
      },
      calculateDamage: (character) => {
        return character.primaries.strength.modifier.final + 20;
      },
      calculateHitType: () => [HitType.Impact],
      getRequirementsErrors: (character) => {
        const errors: string[] = [];

        if (character.secondaries.creative.dance.final < 120) {
          errors.push('Baile 120+');
        }

        if (character.combat.defensiveAbility < 200) {
          errors.push('Bloqueo o esquiva 200+');
        }

        return errors;
      },
    }),
  ];
}
