export type SummonId = string;

export type Summon = {
  id: SummonId;

  name: string;

  type: string;

  difficulty: number;

  zeonCost: number;
};

export const DefaultSummons: Summon[] = [
  {
    id: '1',
    name: 'El Loco',
    difficulty: 120,
    zeonCost: 100,
    type: 'Arcanos',
  },
  {
    id: '2',
    name: 'El Hierofante',
    difficulty: 130,
    zeonCost: 100,
    type: 'Arcanos',
  },
  {
    id: '3',
    name: 'La Suma Sacerdotisa',
    difficulty: 140,
    zeonCost: 150,
    type: 'Arcanos',
  },
  {
    id: '4',
    name: 'La Emperatriz',
    difficulty: 150,
    zeonCost: 200,
    type: 'Arcanos',
  },
  {
    id: '5',
    name: 'El Emperador',
    difficulty: 160,
    zeonCost: 200,
    type: 'Arcanos',
  },
  {
    id: '6',
    name: 'El Sumo Sacerdote',
    difficulty: 170,
    zeonCost: 250,
    type: 'Arcanos',
  },
  {
    id: '7',
    name: 'Los Enamorados',
    difficulty: 180,
    zeonCost: 250,
    type: 'Arcanos',
  },
  {
    id: '8',
    name: 'El Carro',
    difficulty: 190,
    zeonCost: 300,
    type: 'Arcanos',
  },
  {
    id: '9',
    name: 'La Fuerza',
    difficulty: 200,
    zeonCost: 350,
    type: 'Arcanos',
  },
  {
    id: '10',
    name: 'El Ermitaño',
    difficulty: 220,
    zeonCost: 400,
    type: 'Arcanos',
  },
  {
    id: '11',
    name: 'La Rueda',
    difficulty: 240,
    zeonCost: 450,
    type: 'Arcanos',
  },
  {
    id: '12',
    name: 'La Justicia',
    difficulty: 260,
    zeonCost: 500,
    type: 'Arcanos',
  },
  {
    id: '13',
    name: 'El Colgado',
    difficulty: 280,
    zeonCost: 550,
    type: 'Arcanos',
  },
  {
    id: '14',
    name: 'La Muerte',
    difficulty: 300,
    zeonCost: 600,
    type: 'Arcanos',
  },
  {
    id: '15',
    name: 'La Templanza',
    difficulty: 320,
    zeonCost: 650,
    type: 'Arcanos',
  },
  {
    id: '16',
    name: 'El Diablo',
    difficulty: 340,
    zeonCost: 700,
    type: 'Arcanos',
  },
  {
    id: '17',
    name: 'La Torre',
    difficulty: 360,
    zeonCost: 750,
    type: 'Arcanos',
  },
  {
    id: '18',
    name: 'La Estrella',
    difficulty: 380,
    zeonCost: 800,
    type: 'Arcanos',
  },
  {
    id: '19',
    name: 'La Luna',
    difficulty: 400,
    zeonCost: 850,
    type: 'Arcanos',
  },
  {
    id: '20',
    name: 'El Sol',
    difficulty: 420,
    zeonCost: 900,
    type: 'Arcanos',
  },
  {
    id: '21',
    name: 'El Juicio',
    difficulty: 440,
    zeonCost: 950,
    type: 'Arcanos',
  },
  {
    id: '22',
    name: 'El Mundo',
    difficulty: 460,
    zeonCost: 1000,
    type: 'Arcanos',
  },
  {
    id: '23',
    name: 'El Loco invertido',
    difficulty: 120,
    zeonCost: 100,
    type: 'Arcanos',
  },
  {
    id: '24',
    name: 'El Hierofante invertido',
    difficulty: 130,
    zeonCost: 100,
    type: 'Arcanos',
  },
  {
    id: '25',
    name: 'La Suma Sacerdotisa invertida',
    difficulty: 140,
    zeonCost: 150,
    type: 'Arcanos',
  },
  {
    id: '26',
    name: 'La Emperatriz invertida',
    difficulty: 150,
    zeonCost: 200,
    type: 'Arcanos',
  },
  {
    id: '27',
    name: 'El Emperador invertido',
    difficulty: 160,
    zeonCost: 200,
    type: 'Arcanos',
  },
  {
    id: '28',
    name: 'El Sumo Sacerdote invertido',
    difficulty: 170,
    zeonCost: 250,
    type: 'Arcanos',
  },
  {
    id: '29',
    name: 'Los Enamorados invertidos',
    difficulty: 180,
    zeonCost: 250,
    type: 'Arcanos',
  },
  {
    id: '30',
    name: 'El Carro invertido',
    difficulty: 190,
    zeonCost: 300,
    type: 'Arcanos',
  },
  {
    id: '31',
    name: 'La Fuerza invertida',
    difficulty: 200,
    zeonCost: 350,
    type: 'Arcanos',
  },
  {
    id: '32',
    name: 'El Ermitaño invertido',
    difficulty: 240,
    zeonCost: 400,
    type: 'Arcanos',
  },
  {
    id: '33',
    name: 'La Rueda invertida',
    difficulty: 240,
    zeonCost: 450,
    type: 'Arcanos',
  },
  {
    id: '34',
    name: 'La Justicia invertida',
    difficulty: 260,
    zeonCost: 500,
    type: 'Arcanos',
  },
  {
    id: '35',
    name: 'El Colgado invertido',
    difficulty: 280,
    zeonCost: 550,
    type: 'Arcanos',
  },
  {
    id: '36',
    name: 'La Muerte invertida',
    difficulty: 300,
    zeonCost: 600,
    type: 'Arcanos',
  },
  {
    id: '37',
    name: 'La Templanza invertida',
    difficulty: 320,
    zeonCost: 650,
    type: 'Arcanos',
  },
  {
    id: '38',
    name: 'El Diablo invertido',
    difficulty: 340,
    zeonCost: 700,
    type: 'Arcanos',
  },
  {
    id: '39',
    name: 'La Torre invertida',
    difficulty: 340,
    zeonCost: 750,
    type: 'Arcanos',
  },
  {
    id: '40',
    name: 'La Estrella invertida',
    difficulty: 380,
    zeonCost: 800,
    type: 'Arcanos',
  },
  {
    id: '41',
    name: 'La Luna invertida',
    difficulty: 400,
    zeonCost: 850,
    type: 'Arcanos',
  },
  {
    id: '42',
    name: 'El Sol invertido',
    difficulty: 420,
    zeonCost: 900,
    type: 'Arcanos',
  },
  {
    id: '43',
    name: 'El Juicio invertido',
    difficulty: 440,
    zeonCost: 950,
    type: 'Arcanos',
  },
  {
    id: '44',
    name: 'El Mundo invertido',
    difficulty: 460,
    zeonCost: 1000,
    type: 'Arcanos',
  },
  {
    id: '45',
    name: 'Rudraskha: Void nova',
    difficulty: 280,
    zeonCost: 450,
    type: 'Grandes bestias',
  },
  {
    id: '46',
    name: 'Rudraskha: Exodus void nova',
    difficulty: 380,
    zeonCost: 800,
    type: 'Grandes bestias',
  },
  {
    id: '47',
    name: 'Hermod, el Mensajero de los Dioses',
    difficulty: 150,
    zeonCost: 200,
    type: 'Grandes bestias',
  },
  {
    id: '48',
    name: 'Enamon',
    difficulty: 220,
    zeonCost: 500,
    type: 'Grandes bestias',
  },
  {
    id: '49',
    name: 'Naaga, Madre de los Venenos',
    difficulty: 140,
    zeonCost: 200,
    type: 'Grandes bestias',
  },
  {
    id: '50',
    name: 'Huginn y Muninn, Ojos de Cuervo',
    difficulty: 180,
    zeonCost: 200,
    type: 'Grandes bestias',
  },
  {
    id: '51',
    name: 'Huginn y Muninn: Visión verdadera',
    difficulty: 140,
    zeonCost: 120,
    type: 'Grandes bestias',
  },
  {
    id: '52',
    name: 'Huginn y Muninn: Ojo del mundo',
    difficulty: 200,
    zeonCost: 240,
    type: 'Grandes bestias',
  },
  {
    id: '53',
    name: 'Idea, Musa de la Locura',
    difficulty: 240,
    zeonCost: 400,
    type: 'Grandes bestias',
  },
  {
    id: '54',
    name: 'Galgaliel, Ángel de los Dones',
    difficulty: 260,
    zeonCost: 400,
    type: 'Grandes bestias',
  },
  {
    id: '55',
    name: 'Lix Tetrax',
    difficulty: 180,
    zeonCost: 200,
    type: 'Grandes bestias',
  },
  {
    id: '56',
    name: 'Garuda, Alas de Fuego',
    difficulty: 220,
    zeonCost: 350,
    type: 'Grandes bestias',
  },
  {
    id: '57',
    name: 'Raksasha',
    difficulty: 220,
    zeonCost: 400,
    type: 'Grandes bestias',
  },
  {
    id: '58',
    name: 'Idún, Señora de la Juventud Eterna',
    difficulty: 280,
    zeonCost: 500,
    type: 'Grandes bestias',
  },
  {
    id: '59',
    name: 'Idún: Rejuvenecer',
    difficulty: 240,
    zeonCost: 400,
    type: 'Grandes bestias',
  },
  {
    id: '60',
    name: 'Idún: Envejecer',
    difficulty: 280,
    zeonCost: 500,
    type: 'Grandes bestias',
  },
  {
    id: '61',
    name: 'Ethelthryth, Señora de los Nombres',
    difficulty: 280,
    zeonCost: 400,
    type: 'Grandes bestias',
  },
  {
    id: '62',
    name: 'Ethelthryth: In Nomine',
    difficulty: 240,
    zeonCost: 350,
    type: 'Grandes bestias',
  },
  {
    id: '63',
    name: 'Ethelthryth: Ordinare',
    difficulty: 320,
    zeonCost: 450,
    type: 'Grandes bestias',
  },
  {
    id: '64',
    name: 'Eir, la Primera Valkyria',
    difficulty: 280,
    zeonCost: 400,
    type: 'Grandes bestias',
  },
  {
    id: '65',
    name: 'Eir: El ojo de la muerte',
    difficulty: 200,
    zeonCost: 240,
    type: 'Grandes bestias',
  },
  {
    id: '66',
    name: 'Eir: Einjear',
    difficulty: 380,
    zeonCost: 800,
    type: 'Grandes bestias',
  },
  {
    id: '67',
    name: 'Veles, el Poder de la Magia',
    difficulty: 240,
    zeonCost: 350,
    type: 'Grandes bestias',
  },
  {
    id: '68',
    name: 'Thanathos',
    difficulty: 240,
    zeonCost: 400,
    type: 'Grandes bestias',
  },
  {
    id: '69',
    name: 'Caronte, Titiritero de Almas',
    difficulty: 340,
    zeonCost: 700,
    type: 'Grandes bestias',
  },
  {
    id: '70',
    name: 'Pandora, el Castigo Divino',
    difficulty: 280,
    zeonCost: 500,
    type: 'Grandes bestias',
  },
  {
    id: '71',
    name: 'Kagutsuchi: Hi no kodomo',
    difficulty: 200,
    zeonCost: 350,
    type: 'Grandes bestias',
  },
  {
    id: '72',
    name: 'Kagutsuchi: Hi no tsubasa',
    difficulty: 360,
    zeonCost: 650,
    type: 'Grandes bestias',
  },
  {
    id: '73',
    name: 'Forseti, Señor del Equilibrio',
    difficulty: 320,
    zeonCost: 600,
    type: 'Grandes bestias',
  },
  {
    id: '74',
    name: 'Forseti: Equilibirum',
    difficulty: 280,
    zeonCost: 500,
    type: 'Grandes bestias',
  },
  {
    id: '75',
    name: 'Forseti: Equilibirum mundi',
    difficulty: 340,
    zeonCost: 700,
    type: 'Grandes bestias',
  },
  {
    id: '76',
    name: 'Druaga',
    difficulty: 320,
    zeonCost: 550,
    type: 'Grandes bestias',
  },
  {
    id: '77',
    name: 'Druaga: Destructora de demonios',
    difficulty: 280,
    zeonCost: 400,
    type: 'Grandes bestias',
  },
  {
    id: '78',
    name: 'Druaga: Kali',
    difficulty: 340,
    zeonCost: 700,
    type: 'Grandes bestias',
  },
  {
    id: '79',
    name: 'Behemoth, Señor de la Tierra',
    difficulty: 280,
    zeonCost: 500,
    type: 'Grandes bestias',
  },
  {
    id: '80',
    name: 'Behemoth: Comunión con la tierra',
    difficulty: 240,
    zeonCost: 350,
    type: 'Grandes bestias',
  },
  {
    id: '81',
    name: 'Behemoth: Señor de la tierra',
    difficulty: 380,
    zeonCost: 800,
    type: 'Grandes bestias',
  },
  {
    id: '82',
    name: 'Leviathan, Señor de los Océanos',
    difficulty: 280,
    zeonCost: 500,
    type: 'Grandes bestias',
  },
  {
    id: '83',
    name: 'Leviathan: Comunión con el agua',
    difficulty: 240,
    zeonCost: 350,
    type: 'Grandes bestias',
  },
  {
    id: '84',
    name: 'Leviathan: Señor de las aguas',
    difficulty: 380,
    zeonCost: 800,
    type: 'Grandes bestias',
  },
  {
    id: '85',
    name: 'Ziz, Señor de los Cielos',
    difficulty: 280,
    zeonCost: 500,
    type: 'Grandes bestias',
  },
  {
    id: '86',
    name: 'Ziz: Comunión con el viento',
    difficulty: 240,
    zeonCost: 350,
    type: 'Grandes bestias',
  },
  {
    id: '87',
    name: 'Ziz: Señor del viento',
    difficulty: 380,
    zeonCost: 800,
    type: 'Grandes bestias',
  },
  {
    id: '88',
    name: 'Seiryu, el Dragón Celeste',
    difficulty: 400,
    zeonCost: 800,
    type: 'Grandes bestias',
  },
  {
    id: '89',
    name: 'Seiryu: Tormenta',
    difficulty: 220,
    zeonCost: 300,
    type: 'Grandes bestias',
  },
  {
    id: '90',
    name: 'Seiryu: Rayo',
    difficulty: 280,
    zeonCost: 500,
    type: 'Grandes bestias',
  },
  {
    id: '91',
    name: 'Seiryu: Señor del este',
    difficulty: 340,
    zeonCost: 660,
    type: 'Grandes bestias',
  },
  {
    id: '92',
    name: 'Seiryu: La furia de la última tormenta',
    difficulty: 400,
    zeonCost: 880,
    type: 'Grandes bestias',
  },
  {
    id: '93',
    name: 'Genbu, la Tortuga Negra',
    difficulty: 400,
    zeonCost: 800,
    type: 'Grandes bestias',
  },
  {
    id: '94',
    name: 'Genbu: Clarividencia',
    difficulty: 220,
    zeonCost: 300,
    type: 'Grandes bestias',
  },
  {
    id: '95',
    name: 'Genbu: El escudo definitivo',
    difficulty: 280,
    zeonCost: 500,
    type: 'Grandes bestias',
  },
  {
    id: '96',
    name: 'Genbu: Señor del norte',
    difficulty: 340,
    zeonCost: 660,
    type: 'Grandes bestias',
  },
  {
    id: '97',
    name: 'Genbu: Amo de las entrañas de la tierra',
    difficulty: 400,
    zeonCost: 880,
    type: 'Grandes bestias',
  },
  {
    id: '98',
    name: 'Suzaku, el Fénix',
    difficulty: 400,
    zeonCost: 800,
    type: 'Grandes bestias',
  },
  {
    id: '99',
    name: 'Suzaku: Buena fortuna',
    difficulty: 220,
    zeonCost: 300,
    type: 'Grandes bestias',
  },
  {
    id: '100',
    name: 'Suzaku: Calamidad',
    difficulty: 280,
    zeonCost: 500,
    type: 'Grandes bestias',
  },
  {
    id: '101',
    name: 'Suzaku: Señor del sur',
    difficulty: 340,
    zeonCost: 660,
    type: 'Grandes bestias',
  },
  {
    id: '102',
    name: 'Suzaku: El elegido del sol',
    difficulty: 400,
    zeonCost: 880,
    type: 'Grandes bestias',
  },
  {
    id: '103',
    name: 'Byakko, el Tigre Blanco',
    difficulty: 400,
    zeonCost: 800,
    type: 'Grandes bestias',
  },
  {
    id: '104',
    name: 'Byakko: Protección',
    difficulty: 220,
    zeonCost: 300,
    type: 'Grandes bestias',
  },
  {
    id: '105',
    name: 'Byakko: La garra del rey de las bestias',
    difficulty: 280,
    zeonCost: 500,
    type: 'Grandes bestias',
  },
  {
    id: '106',
    name: 'Byakko: Señor del oeste',
    difficulty: 340,
    zeonCost: 660,
    type: 'Grandes bestias',
  },
  {
    id: '107',
    name: 'Byakko: La bestia definitiva',
    difficulty: 400,
    zeonCost: 880,
    type: 'Grandes bestias',
  },
  {
    id: '108',
    name: 'Kirin, el Centro',
    difficulty: 440,
    zeonCost: 800,
    type: 'Grandes bestias',
  },
  {
    id: '109',
    name: 'Kirin: Feng Shui',
    difficulty: 440,
    zeonCost: 1000,
    type: 'Grandes bestias',
  },
  {
    id: '110',
    name: 'El Vendedor',
    difficulty: 240,
    zeonCost: 800,
    type: 'Grandes bestias',
  },
  {
    id: '111',
    name: "Tawil At-U'mr, Señor de las Puertas",
    difficulty: 400,
    zeonCost: 800,
    type: 'Grandes bestias',
  },
  {
    id: '112',
    name: "Tawil At-U'mr: Compresión del tiempo",
    difficulty: 280,
    zeonCost: 500,
    type: 'Grandes bestias',
  },
  {
    id: '113',
    name: "Tawil At-U'mr: La puerta",
    difficulty: 400,
    zeonCost: 900,
    type: 'Grandes bestias',
  },
  {
    id: '114',
    name: "Tawil At-U'mr: Más allá del tiempo y el espacio",
    difficulty: 500,
    zeonCost: 1200,
    type: 'Grandes bestias',
  },
  {
    id: '115',
    name: 'Linx Kazte Hex, la Sombra de la Muerte (Nv 4)',
    difficulty: 260,
    zeonCost: 400,
    type: 'Encarnaciones',
  },
  {
    id: '116',
    name: 'Linx Kazte Hex, la Sombra de la Muerte (Nv 8)',
    difficulty: 300,
    zeonCost: 600,
    type: 'Encarnaciones',
  },
  {
    id: '117',
    name: 'Linx Kazte Hex, la Sombra de la Muerte (Nv 12)',
    difficulty: 380,
    zeonCost: 900,
    type: 'Encarnaciones',
  },
  {
    id: '118',
    name: 'Nerval, Defensor de los Perdidos (Nv 4)',
    difficulty: 280,
    zeonCost: 350,
    type: 'Encarnaciones',
  },
  {
    id: '119',
    name: 'Nerval, Defensor de los Perdidos (Nv 7)',
    difficulty: 340,
    zeonCost: 700,
    type: 'Encarnaciones',
  },
  {
    id: '120',
    name: 'Nerval, Defensor de los Perdidos (Nv 11)',
    difficulty: 400,
    zeonCost: 900,
    type: 'Encarnaciones',
  },
  {
    id: '121',
    name: 'Eiko, la princesa Oni (Nv 3)',
    difficulty: 220,
    zeonCost: 200,
    type: 'Encarnaciones',
  },
  {
    id: '122',
    name: 'Eiko, la princesa Oni (Nv 6)',
    difficulty: 300,
    zeonCost: 480,
    type: 'Encarnaciones',
  },
  {
    id: '123',
    name: 'Eiko, la princesa Oni (Nv 9)',
    difficulty: 360,
    zeonCost: 600,
    type: 'Encarnaciones',
  },
  {
    id: '124',
    name: 'Quezcatli, Hijo del Sol (Nv 2)',
    difficulty: 200,
    zeonCost: 150,
    type: 'Encarnaciones',
  },
  {
    id: '125',
    name: 'Quezcatli, Hijo del Sol (Nv 4)',
    difficulty: 240,
    zeonCost: 300,
    type: 'Encarnaciones',
  },
  {
    id: '126',
    name: 'Quezcatli, Hijo del Sol (Nv 6)',
    difficulty: 260,
    zeonCost: 450,
    type: 'Encarnaciones',
  },
  {
    id: '127',
    name: 'Gygjur, Asesino de Dragones (Nv 5)',
    difficulty: 270,
    zeonCost: 390,
    type: 'Encarnaciones',
  },
  {
    id: '128',
    name: 'Gygjur, Asesino de Dragones (Nv 9)',
    difficulty: 380,
    zeonCost: 750,
    type: 'Encarnaciones',
  },
  {
    id: '129',
    name: 'Gygjur, Asesino de Dragones (Nv 13)',
    difficulty: 490,
    zeonCost: 1400,
    type: 'Encarnaciones',
  },
  {
    id: '130',
    name: 'Arturia, el León Blanco (Nv 1)',
    difficulty: 160,
    zeonCost: 180,
    type: 'Encarnaciones',
  },
  {
    id: '131',
    name: 'Arturia, el León Blanco (Nv 3)',
    difficulty: 200,
    zeonCost: 300,
    type: 'Encarnaciones',
  },
  {
    id: '132',
    name: 'Arturia, el León Blanco (Nv 6)',
    difficulty: 280,
    zeonCost: 400,
    type: 'Encarnaciones',
  },
  {
    id: '133',
    name: 'Kha-nefer, el Sacerdote Impío (Nv 3)',
    difficulty: 230,
    zeonCost: 200,
    type: 'Encarnaciones',
  },
  {
    id: '134',
    name: 'Kha-nefer, el Sacerdote Impío (Nv 6)',
    difficulty: 280,
    zeonCost: 450,
    type: 'Encarnaciones',
  },
  {
    id: '135',
    name: 'Kha-nefer, el Sacerdote Impío (Nv 9)',
    difficulty: 320,
    zeonCost: 550,
    type: 'Encarnaciones',
  },
  {
    id: '136',
    name: 'Anassilish, el Sin Rostro (Nv 2)',
    difficulty: 180,
    zeonCost: 150,
    type: 'Encarnaciones',
  },
  {
    id: '137',
    name: 'Anassilish, el Sin Rostro (Nv 5)',
    difficulty: 280,
    zeonCost: 400,
    type: 'Encarnaciones',
  },
  {
    id: '138',
    name: 'Anassilish, el Sin Rostro (Nv 8)',
    difficulty: 300,
    zeonCost: 600,
    type: 'Encarnaciones',
  },
  {
    id: '139',
    name: 'Ambrose Magus, el Hechicero (Nv 4)',
    difficulty: 240,
    zeonCost: 200,
    type: 'Encarnaciones',
  },
  {
    id: '140',
    name: 'Ambrose Magus, el Hechicero (Nv 6)',
    difficulty: 260,
    zeonCost: 350,
    type: 'Encarnaciones',
  },
  {
    id: '141',
    name: 'Ambrose Magus, el Hechicero (Nv 8)',
    difficulty: 280,
    zeonCost: 500,
    type: 'Encarnaciones',
  },
  {
    id: '142',
    name: 'Ruatha Synn (Nv 4)',
    difficulty: 280,
    zeonCost: 450,
    type: 'Encarnaciones',
  },
  {
    id: '143',
    name: 'Ruatha Synn (Nv 8)',
    difficulty: 360,
    zeonCost: 750,
    type: 'Encarnaciones',
  },
  {
    id: '144',
    name: 'Ruatha Synn (Nv 12)',
    difficulty: 460,
    zeonCost: 1000,
    type: 'Encarnaciones',
  },
  {
    id: '145',
    name: 'Kristoff, el Rey Pirata (Nv 4)',
    difficulty: 250,
    zeonCost: 350,
    type: 'Encarnaciones',
  },
  {
    id: '146',
    name: 'Kristoff, el Rey Pirata (Nv 8)',
    difficulty: 300,
    zeonCost: 500,
    type: 'Encarnaciones',
  },
  {
    id: '147',
    name: 'Kristoff, el Rey Pirata (Nv 11)',
    difficulty: 380,
    zeonCost: 800,
    type: 'Encarnaciones',
  },
  {
    id: '148',
    name: 'Loly Ann (Nv 1)',
    difficulty: 180,
    zeonCost: 150,
    type: 'Encarnaciones',
  },
  {
    id: '149',
    name: 'Loly Ann (Nv 3)',
    difficulty: 220,
    zeonCost: 250,
    type: 'Encarnaciones',
  },
  {
    id: '150',
    name: 'Loly Ann (Nv 5)',
    difficulty: 280,
    zeonCost: 400,
    type: 'Encarnaciones',
  },
  {
    id: '151',
    name: 'Aeriel, el Caballero de Argenta (Nv 6)',
    difficulty: 290,
    zeonCost: 450,
    type: 'Encarnaciones',
  },
  {
    id: '152',
    name: 'Aeriel, el Caballero de Argenta (Nv 9)',
    difficulty: 380,
    zeonCost: 750,
    type: 'Encarnaciones',
  },
  {
    id: '153',
    name: 'Aeriel, el Caballero de Argenta (Nv 12)',
    difficulty: 460,
    zeonCost: 1250,
    type: 'Encarnaciones',
  },
  {
    id: '154',
    name: 'Wang Shou Bo (Nv 5)',
    difficulty: 260,
    zeonCost: 400,
    type: 'Encarnaciones',
  },
  {
    id: '155',
    name: 'Wang Shou Bo (Nv 9)',
    difficulty: 300,
    zeonCost: 600,
    type: 'Encarnaciones',
  },
  {
    id: '156',
    name: 'Wang Shou Bo (Nv 13)',
    difficulty: 500,
    zeonCost: 1200,
    type: 'Encarnaciones',
  },
  {
    id: '157',
    name: 'Holst, Rey de Héroes (Nv 8)',
    difficulty: 360,
    zeonCost: 750,
    type: 'Encarnaciones',
  },
  {
    id: '158',
    name: 'Holst, Rey de Héroes (Nv 12)',
    difficulty: 440,
    zeonCost: 1200,
    type: 'Encarnaciones',
  },
  {
    id: '159',
    name: 'Holst, Rey de Héroes (Nv 15)',
    difficulty: 520,
    zeonCost: 1500,
    type: 'Encarnaciones',
  },
];

export const getSummonFromId = (id: SummonId): Summon => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return DefaultSummons.find((summon) => summon.id === id)!;
};
