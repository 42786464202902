import { CharacterMutator } from '../../../ActorCharacterTransformer';
import { FoundryVTTActor } from '../FoundryVTTActor';

export const mutateCharacterCharacteristics: CharacterMutator<FoundryVTTActor> =
  ({ character, actor }) => {
    character.content.primaries.power.setBase(
      actor.data.characteristics.primaries.power.value,
    );

    character.content.primaries.willPower.setBase(
      actor.data.characteristics.primaries.willPower.value,
    );

    character.content.primaries.agility.setBase(
      actor.data.characteristics.primaries.agility.value,
    );

    character.content.primaries.constitution.setBase(
      actor.data.characteristics.primaries.constitution.value,
    );

    character.content.primaries.dexterity.setBase(
      actor.data.characteristics.primaries.dexterity.value,
    );

    character.content.primaries.intelligence.setBase(
      actor.data.characteristics.primaries.intelligence.value,
    );

    character.content.primaries.perception.setBase(
      actor.data.characteristics.primaries.perception.value,
    );

    character.content.primaries.strength.setBase(
      actor.data.characteristics.primaries.strength.value,
    );

    character.content.movementType.setBase(
      actor.data.characteristics.secondaries.movementType.mod.value,
    );

    return { character };
  };
