import { PersonAdd, CreateNewFolder } from '@styled-icons/material';
import { useCreateCharacter } from '../../../../../../shared/application/hooks/useCreateCharacter';
import { useFetchCharacters } from './hooks/useFetchCharacters';
import { Loading } from '../../../../../../shared/application/design-system/components/Loading/Loading';
import { FabContainer, FabsContainer, Wrapper } from './Characters.styled';
import { CharacterList } from '../../../../components/CharacterList/CharacterList';
import { EmptyCharacterList } from './components/EmptyCharacterList/EmptyCharacterList';
import { Actions } from '../../../../components/CharacterList/components/Actions/Actions';
import { useFetchFolders } from '../../../../../../folder/application/hooks/useFetchFolders';
import { FolderList } from '../../../../../../folder/application/components/FolderList/FolderList';
import { Folder } from '../../../../../../folder/domain/folder/Folder';
import {
  Routes,
  useNavigate,
} from '../../../../../../shared/application/hooks/useNavigate';
import { useParams } from 'react-router-dom';
import { FolderNavigation } from '../../../../../../folder/application/components/FolderNavigation/FolderNavigation';
import { useCreateFolderModal } from '../../../../../../folder/application/hooks/useCreateFolderModal';

export const Characters = () => {
  const { folderId } = useParams<{ folderId: string }>();

  const { navigate } = useNavigate();

  const { characters, loadingCharacters, fetchCharacters } =
    useFetchCharacters(folderId);

  const { folders, loadingFolders, currentFolder, fetchFolders } =
    useFetchFolders(folderId);

  const { creatingCharacter, createCharacter } = useCreateCharacter();

  const { openCreateFolderModal, CreateFolderModal } = useCreateFolderModal();

  const handleCreateFolderClick = () => {
    openCreateFolderModal();
  };

  const handleNewCharacterClick = () => {
    createCharacter({ folderId });
  };

  const handleCharacterDeletion = () => {
    fetchCharacters({ keepLoadingOnFalse: true });
  };

  const handleCharacterDuplicated = () => {
    fetchCharacters({ keepLoadingOnFalse: true });
  };

  const handleFolderClick = (folder: Folder) => {
    navigate(Routes.MyCharacters, {
      params: { folderId: folder.id.toString() },
    });
  };

  const handleFolderDelete = () => {
    fetchFolders({ keepLoadingOnFalse: true });
  };

  const handleFolderCreated = () => {
    fetchFolders({ keepLoadingOnFalse: true });
  };

  if (loadingCharacters || creatingCharacter || loadingFolders) {
    return (
      <Wrapper>
        <Loading centered />
      </Wrapper>
    );
  }

  const hasCharacters = characters.length > 0;
  const hasFolders = folders.length > 0;

  const refreshContent = () => {
    Promise.all([
      fetchCharacters({ keepLoadingOnFalse: true }),
      fetchFolders({ keepLoadingOnFalse: true }),
    ]);
  };

  return (
    <Wrapper>
      {currentFolder ? (
        <FolderNavigation
          folder={currentFolder}
          afterMoveContentToParentFolder={refreshContent}
        />
      ) : undefined}

      {hasCharacters || hasFolders ? (
        <Actions onFolderCreated={handleFolderCreated} />
      ) : undefined}

      {hasFolders ? (
        <FolderList
          folders={folders}
          afterMoveFolderToFolder={refreshContent}
          onClickFolder={handleFolderClick}
          onDeleteFolder={handleFolderDelete}
        />
      ) : undefined}

      {hasCharacters ? (
        <CharacterList
          characters={characters}
          onDeleteCharacter={handleCharacterDeletion}
          onDuplicateCharacter={handleCharacterDuplicated}
        />
      ) : undefined}

      {!hasCharacters && !hasFolders ? (
        <EmptyCharacterList onFolderCreated={handleFolderCreated} />
      ) : undefined}

      <FabsContainer>
        <FabContainer>
          <CreateNewFolder onClick={handleCreateFolderClick} />
        </FabContainer>
        <FabContainer>
          <PersonAdd onClick={handleNewCharacterClick} />
        </FabContainer>
      </FabsContainer>

      <CreateFolderModal />
    </Wrapper>
  );
};
