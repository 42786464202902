import { WeaponItemModel } from '../../../../../../../../../../../../../../domain/aggregations/weapon/WeaponItemModel';
import { observer } from 'mobx-react';
import {
  WeaponSize,
  weaponSizeTranslations,
} from '../../../../../../../../../../../../../../domain/aggregations/weapon/Weapon.types';
import { transformToMenuItems } from '../../../../../../../../../../../../../../../shared/application/design-system/components/Menu/utils/transformToMenuItems';
import { Selector } from '../../../../../../../../common/Selector/Selector';

export const SizeSelector = observer(
  ({ weapon }: { weapon: WeaponItemModel }) => {
    const items = transformToMenuItems({
      fromItems: Object.values(WeaponSize),
      generateItem: (size) => ({
        key: size,
        name: weaponSizeTranslations[size],
        value: size,
        onClick: () => {
          weapon.setSize(size);
        },
      }),
    });

    return (
      <Selector
        value={weapon.size}
        valueAsText={weaponSizeTranslations[weapon.size]}
        items={items}
      />
    );
  },
);
