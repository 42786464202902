import {
  CloudSync,
  CloudUpload,
  ContentCopy,
  Delete,
  Edit,
  Groups,
  Visibility,
} from '@styled-icons/material';
import styled from 'styled-components';
import {
  BasicTitle,
  SmallParagraph,
} from '../../../../../../shared/application/design-system/components/Texts/Texts.styled';

const CharacterTokenSize = 8;

export const CharacterToken = styled.div<{ $image: string }>(
  ({ theme: { size, color }, $image }) => ({
    backgroundImage: `url(${$image})`,
    backgroundSize: 'cover',

    width: size.units(CharacterTokenSize),
    height: size.units(CharacterTokenSize),

    borderRadius: '50%',

    border: `2px solid ${color.black()}`,
    borderRight: `2px solid ${color.primary()}`,
  }),
);

export const CharacterTokenContainer = styled.div(({ theme: { size } }) => ({
  width: size.units(CharacterTokenSize),
  height: size.units(CharacterTokenSize),

  borderRadius: '50%',
}));

export const AvatarContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',

  gap: size.units(2),

  padding: size.units(1),
}));

export const CardHeader = styled.div(({ theme: { size, color } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: color.black(),

  paddingLeft: size.units(8),
  paddingRight: size.units(1),

  height: size.units(8),

  borderBottom: `2px solid ${color.primaryLight()}`,
}));

export const CharacterName = styled(BasicTitle)(
  ({ theme: { size, color } }) => ({
    maxWidth: size.units(30),
    color: color.white(),

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
);

export const CardBody = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: size.units(1),

  padding: size.units(1),
  paddingTop: size.units(2),

  width: '100%',
  height: '100%',

  boxSizing: 'border-box',

  paddingBottom: 0,
}));

export const PrimaryCharacteristicValue = styled.span(
  ({ theme: { color, font } }) => ({
    fontWeight: font.weight.extraBold,
    color: color.primaryLight(),
  }),
);

export const PrimaryCharacteristic = styled(SmallParagraph)(
  ({ theme: { size } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',

    width: size.units(12),

    gap: size.units(1),
  }),
);

export const PrimaryCharacteristics = styled.div(({ theme: { size } }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnGap: size.units(1),

  flexWrap: 'wrap',

  width: '100%',
}));

export const LastModificationDate = styled(SmallParagraph)({});

export const ViewIcon = styled(Visibility)(
  ({ theme: { color, transition } }) => ({
    color: color.whiteSmoke(),

    transition: transition.standard,
  }),
);

export const EditIcon = styled(Edit)(({ theme: { color, transition } }) => ({
  color: color.whiteSmoke(),

  transition: transition.standard,
}));

export const DeleteIcon = styled(Delete)(
  ({ theme: { color, transition } }) => ({
    color: color.whiteSmoke(),

    transition: transition.standard,
  }),
);

export const DuplicateIcon = styled(ContentCopy)(
  ({ theme: { color, transition } }) => ({
    color: color.whiteSmoke(),

    transition: transition.standard,
  }),
);

export const CollaborationIcon = styled(Groups)<{ $highlight: boolean }>(
  ({ theme: { color, transition }, $highlight }) => ({
    color: $highlight ? color.tertiary() : color.whiteSmoke(),

    transition: transition.standard,
  }),
);

export const SyncIcon = styled(CloudSync)(
  ({ theme: { color, transition } }) => ({
    color: color.whiteSmoke(),

    transition: transition.standard,
  }),
);

export const AddIcon = styled(CloudUpload)(
  ({ theme: { color, transition } }) => ({
    color: color.whiteSmoke(),

    transition: transition.standard,
  }),
);

export const Action = styled.div(
  ({ theme: { size, color, transition, mixins } }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: size.units(2.5),
    height: size.units(2.5),

    ...mixins.responsive.xs.downward({
      width: size.units(4),
      height: size.units(4),

      [`${EditIcon}, ${DeleteIcon}, ${DuplicateIcon}, ${CollaborationIcon}, ${SyncIcon}, ${AddIcon}`]:
        {
          width: size.units(3.5),
          height: size.units(3.5),
        },
    }),

    borderRadius: '50%',
    backgroundColor: color.primary(),
    padding: size.units(0.5),

    cursor: 'pointer',

    transition: transition.standard,

    '&:hover': {
      [EditIcon]: {
        color: color.white(),
      },
      [ViewIcon]: {
        color: color.white(),
      },
      [DeleteIcon]: {
        color: color.white(),
      },
      [DuplicateIcon]: {
        color: color.white(),
      },
      [CollaborationIcon]: {
        color: color.white(),
      },
      [SyncIcon]: {
        color: color.white(),
      },
      [AddIcon]: {
        color: color.white(),
      },

      backgroundColor: color.primaryLight(),
    },
  }),
);

export const ActionsContainer = styled.div(({ theme: { size, mixins } }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',

  gap: size.units(1),

  ...mixins.responsive.xs.downward({
    gap: size.units(2),
  }),
}));

export const Wrapper = styled.div<{ $isBeingDragged?: boolean }>(
  ({
    theme: { size, color, borderRadius, transition, mixins },
    $isBeingDragged,
  }) => ({
    display: 'flex',
    flexDirection: 'column',

    opacity: $isBeingDragged ? 0.5 : undefined,

    width: size.units(45),

    borderRadius: borderRadius.medium,

    boxSizing: 'border-box',

    border: `2px solid ${color.black()}`,

    minWidth: size.units(35),
    minHeight: size.units(25),

    position: 'relative',

    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)',

    cursor: 'pointer',

    transition: transition.standard,

    '&:hover': {
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 1)',
    },

    [CharacterTokenContainer]: {
      position: 'absolute',
      top: size.units(-1.5),
      left: size.units(-2),
    },

    [ActionsContainer]: {
      position: 'absolute',
      bottom: size.units(-2),
      right: size.units(-0.5),
    },

    ...mixins.responsive.xs.downward({
      minWidth: '100%',
    }),
  }),
);
