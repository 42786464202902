import { model } from 'mobx-keystone';
import { WeaponFieldModel } from './WeaponFieldModel';

@model('WeaponItem/WeaponPresenceField')
export class WeaponPresenceFieldModel extends WeaponFieldModel {
  get final(): number {
    if (!this.weapon) return 0;

    return this.base + this.special + this.weapon.quality.final * 10;
  }
}
