import styled from 'styled-components';
import { BasicTitle } from '../../../../../../shared/application/design-system/components/Texts/Texts.styled';

export const ModalTitle = styled(BasicTitle)({});

export const Wrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  width: size.units(75),

  padding: size.units(3),

  [ModalTitle]: {
    marginBottom: size.units(5),
  },
}));
