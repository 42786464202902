import styled from 'styled-components';
import { SmallTitle } from '../../../../../../../../../../../../shared/application/design-system/components/Texts/Texts.styled';

export const Wrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',

  gap: size.units(1),
}));

export const MartialArtTitle = styled(SmallTitle)({});

export const MartialArtEffectsList = styled.ul({});

export const MartialArtEffect = styled.li({});

export const MartialArtEffectsContainer = styled.div({});
