import { ExtendedModel, model, prop } from 'mobx-keystone';
import { SecondarySkill } from '../../../../../../../types/SecondarySkill';
import { SecondarySkillPDFieldModel } from '../SecondarySkillPDFieldModel';
import { SecondarySkillBonusType } from '../SecondarySkillPDFieldModel.types';
import { SecondarySkillsPDModel } from './SecondarySkillsPDModel';

@model('Character/Development/SecondarySkills/SubterfugeSkillsPD')
export class SubterfugeSkillsPDModel extends ExtendedModel(
  SecondarySkillsPDModel,
  {
    lockPicking: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.LockPicking,
          type: SecondarySkillBonusType.Dexterity,
        }),
    ),
    disguise: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Disguise,
          type: SecondarySkillBonusType.Dexterity,
        }),
    ),
    hide: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Hide,
          type: SecondarySkillBonusType.Perceptive,
        }),
    ),
    steal: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Steal,
          type: SecondarySkillBonusType.Dexterity,
        }),
    ),
    stealth: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Stealth,
          type: SecondarySkillBonusType.Agility,
        }),
    ),
    traps: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Traps,
          type: SecondarySkillBonusType.Dexterity,
        }),
    ),
    poisons: prop(
      () =>
        new SecondarySkillPDFieldModel({
          skill: SecondarySkill.Poisons,
          type: SecondarySkillBonusType.Intelligence,
        }),
    ),
  },
) {
  get fields(): SecondarySkillPDFieldModel[] {
    return [
      this.lockPicking,
      this.disguise,
      this.hide,
      this.steal,
      this.stealth,
      this.traps,
      this.poisons,
    ];
  }
}
