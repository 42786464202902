import { observer } from 'mobx-react';
import { Fragment, useState } from 'react';
import { MagicPath } from '../../../../../../../../../../domain/aggregations/magic-path/MagicPath';
import {
  MagicSpell,
  MagicSpellId,
} from '../../../../../../../../../../domain/aggregations/magic-spell/MagicSpell';
import { Tabs } from '../../../../../../../../../../../shared/application/design-system/components/Tabs/Tabs';
import { HideIcon, ShowIcon } from '../../../../common/Icons/Icons';
import { Section } from '../../../../common/Section/Section';
import { Table } from '../../../../common/Table/Table';
import { useCharacterContext } from '../../../../../hooks/useCharacterContext';
import { CastDescription } from './KnownSpells.styled';

const translateCastLevel = (castLevel: keyof MagicSpell['casts']) => {
  switch (castLevel) {
    case 'base':
      return 'Base';
    case 'intermediate':
      return 'Intermedio';
    case 'advanced':
      return 'Avanzado';
    case 'arcane':
      return 'Arcano';
    default:
      return 'Desconocido';
  }
};

export const KnownSpells = observer(() => {
  const {
    character: { content: character },
  } = useCharacterContext();

  const [expandedSpellsIds, setExpandedSpellIds] = useState<MagicSpellId[]>([]);

  const sortedSpells = [...character.mystic.knownSpells].sort((a, b) =>
    a.magicPath.localeCompare(b.magicPath),
  );

  const [magicPathTab, setMagicPathTab] = useState<MagicPath | undefined>(
    sortedSpells[0]?.magicPath,
  );

  const handleMagicPathTabChange = (magicPath: MagicPath) => () => {
    setMagicPathTab(magicPath);
  };

  const handleHideSpellCasts = (spell: MagicSpell) => () => {
    setExpandedSpellIds(expandedSpellsIds.filter((id) => id !== spell.id));
  };

  const handleShowSpellCasts = (spell: MagicSpell) => () => {
    setExpandedSpellIds([...expandedSpellsIds, spell.id]);
  };

  const knownMagicPaths = [...new Set(sortedSpells.map((s) => s.magicPath))];

  return (
    <Section maxHeight="fit">
      <Section.Title>Conjuros conocidos</Section.Title>
      <Tabs centered>
        {knownMagicPaths.map((magicPath) => (
          <Tabs.Button
            key={magicPath}
            selected={magicPathTab === magicPath}
            onClick={handleMagicPathTabChange(magicPath)}
            name={magicPath}
          />
        ))}
      </Tabs>
      <Table>
        <Table.Header>
          <Table.Header.Cell />
          <Table.Header.Cell $width={7}>Nombre</Table.Header.Cell>
          <Table.Header.Cell $width={4} $hideOnMobile>
            Vía
          </Table.Header.Cell>
          <Table.Header.Cell $hideOnMobile>Nivel</Table.Header.Cell>
          <Table.Header.Cell $width={4}>Mant.</Table.Header.Cell>
          <Table.Header.Cell $width={4}>Tipo</Table.Header.Cell>
          <Table.Header.Cell>Acción</Table.Header.Cell>
        </Table.Header>
        {sortedSpells
          .filter((s) => !magicPathTab || s.magicPath === magicPathTab)
          .map((spell) => (
            <Fragment key={spell.id}>
              <Table.Row>
                <Table.Row.Cell $textAlign="right">
                  {expandedSpellsIds.includes(spell.id) ? (
                    <HideIcon onClick={handleHideSpellCasts(spell)} />
                  ) : (
                    <ShowIcon onClick={handleShowSpellCasts(spell)} />
                  )}
                </Table.Row.Cell>
                <Table.Row.Cell $textAlign="left">{spell.name}</Table.Row.Cell>
                <Table.Row.Cell $hideOnMobile>{spell.magicPath}</Table.Row.Cell>
                <Table.Row.Cell $hideOnMobile>{spell.level}</Table.Row.Cell>
                <Table.Row.Cell>
                  {spell.isDaily ? 'Diario' : 'Por asalto'}
                </Table.Row.Cell>
                <Table.Row.Cell>{spell.activationType}</Table.Row.Cell>
                <Table.Row.Cell>{spell.actionType}</Table.Row.Cell>
              </Table.Row>
              {expandedSpellsIds.includes(spell.id)
                ? Object.keys(spell.casts).map((castLevel) => {
                    const castKey = castLevel as keyof MagicSpell['casts'];

                    const translatedCastLevel = translateCastLevel(castKey);

                    const cast = spell.casts[castKey];

                    return (
                      <Fragment key={`${spell.id}-${castLevel}`}>
                        <Table.Row>
                          <Table.Row.Cell />
                          <Table.Row.Cell $bold $verticalAlign="sub">
                            {translatedCastLevel}
                          </Table.Row.Cell>
                          <Table.Row.Cell colSpan={3} $textAlign="left">
                            <CastDescription>
                              <b>Inteligencia req.</b>
                              {cast.intelligenceRequirement} <br />
                            </CastDescription>
                            <CastDescription>
                              <b>Zeón</b> {cast.zeonCost} <br />
                            </CastDescription>
                            <CastDescription>
                              <b>Mantenimiento</b>{' '}
                              {cast.maintainedZeonCost ?? 'No'} <br />
                              <br />
                            </CastDescription>
                            <b>Efecto</b>
                            <br />
                            {cast.effect}
                          </Table.Row.Cell>
                        </Table.Row>
                      </Fragment>
                    );
                  })
                : undefined}
            </Fragment>
          ))}
      </Table>
    </Section>
  );
});
